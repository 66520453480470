/**------------------------------------------------------
 * Mapper
 * ------
 * > Info: Helper class for the UtilMapper to provide the
 * > mapping functionalities.
 * ------
 * The mapping can be used in two ways:
 *  1. use the mapping through UtilMapper
 *  2. create a reusable Mapper class for the use case
 * 		=> example: Util.Mapper.create(MAPPING_SPREADSHIRT);
 */
export class UtilMapperMap<TKey extends string, TValue> {

	constructor(
		private mapping		: Record<TKey, TValue>,
		private utilMapper	: UtilMapper = new UtilMapper(false)
	) {
		this.utilMapper.checkMapping<TKey, TValue>(this.mapping);
	}


	/**------------------------------------------------------
	 * Get Mappings
	 */
	getMap(): Record<TKey, TValue> {
		return this.utilMapper.getMap<TKey, TValue>(this.mapping);
	}
	getMapElem(key: TKey): Record<string, TValue> {
		return this.utilMapper.getMapElem<TKey, TValue>(this.mapping, key);
	}
	getMapInclude(include: TKey[]): Record<TKey, TValue> {
		return this.utilMapper.getMapInclude<TKey, TValue>(this.mapping, include);
	}
	getMapExclude(exclude: TKey[]): Record<TKey, TValue> {
		return this.utilMapper.getMapExclude<TKey, TValue>(this.mapping, exclude);
	}


	/**------------------------------------------------------
	 * Get Mappings as Array
	 */
	getArrayMap(): IUtilMappingEntry<TKey, TValue>[] {
		return this.utilMapper.getArrayMap<TKey, TValue>(this.mapping);
	}
	getArrayMapElem(key: TKey): IUtilMappingEntry<TKey, TValue> {
		return this.utilMapper.getArrayMapElem<TKey, TValue>(this.mapping, key);
	}
	getArrayMapInclude(include: TKey[]): IUtilMappingEntry<TKey, TValue>[] {
		return this.utilMapper.getArrayMapInclude<TKey, TValue>(this.mapping, include);
	}
	getArrayMapExclude(exclude: TKey[]): IUtilMappingEntry<TKey, TValue>[] {
		return this.utilMapper.getArrayMapExclude<TKey, TValue>(this.mapping, exclude);
	}

	/**------------------------------------------------------
	 * Get Values / Names of the Mappings
	 */
	getValues(): TValue[] {
		return this.utilMapper.getValues<TKey, TValue>(this.mapping);
	}
	getValueElem(key: TKey): TValue {
		return this.utilMapper.getValueElem<TKey, TValue>(this.mapping, key);
	}
	getValueInclude(include: TKey[]): TValue[] {
		return this.utilMapper.getValueInclude<TKey, TValue>(this.mapping, include);
	}
	getValueExclude(exclude: TKey[]): TValue[] {
		return this.utilMapper.getValueExclude<TKey, TValue>(this.mapping, exclude);
	}
}


/**------------------------------------------------------
 * Mapper Utilities
 * ---------------
 * Info: Providing the mapping functionalities to work
 * with the constants over the application.
 */
export class UtilMapper {

	constructor(
		private defaultChecks: boolean = true
	) {}


	/**------------------------------------------------------
	 * Create Mapper Instance
	 */
	create<TKey extends string, TValue>(mapping: Record<TKey, TValue>): UtilMapperMap<TKey, TValue> {
		return new UtilMapperMap(mapping);
	}


	/**------------------------------------------------------
	 * Get Mappings
	 */
	getMap<TKey extends string, TValue>(mapping: Record<TKey, TValue>): Record<TKey, TValue> {
		if (this.defaultChecks) this.checkMapping(mapping);
		return mapping;
	}

	getMapElem<TKey extends string, TValue>(mapping: Record<TKey, TValue>, key: TKey): Record<TKey, TValue> {
		if (this.defaultChecks) this.checkMapping(mapping);
		if (!Object.keys(mapping).includes(key)) throw new Error(`UtilMapper => getMapElem => FATAL ERROR: provided key was not defined for the mapping (defined values: "${Object.keys(mapping)}")`);
		return { [key] : mapping[key] } as Record<TKey, TValue>;
	}

	getMapInclude<TKey extends string, TValue>(mapping: Record<TKey, TValue>, include: TKey[]): Record<TKey, TValue> {
		if (this.defaultChecks) this.checkMapping(mapping);
		const subMapping: Record<TKey, TValue> = {} as Record<TKey, TValue>;
		for (const key of include) {
			if (!Object.keys(mapping).includes(key)) throw new Error(`UtilMapper => getMapIncluded => FATAL ERROR: provided key was not defined for the mapping (defined values: "${Object.keys(mapping)}")`);
			subMapping[key] = mapping[key];
		}
		return subMapping;
	}

	getMapExclude<TKey extends string, TValue>(mapping: Record<TKey, TValue>, exclude: TKey[]): Record<TKey, TValue> {

		//0 - check if any key in exclude is wrong
		if (this.defaultChecks) this.checkMapping(mapping);
		for (const key of exclude) {
			if (!Object.keys(mapping).includes(key)) throw new Error(`UtilMapper => getMapExclude => FATAL ERROR: provided key was not defined for the mapping (defined values: "${Object.keys(mapping)}")`);
		}

		//1 - add only the ones not in exclude
		const subMapping: Record<TKey, TValue> = {} as Record<TKey, TValue>;
		for (const key of Object.keys(mapping)) {
			if (exclude.includes(key as TKey)) continue;
			subMapping[key as TKey] = mapping[key as TKey];
		}
		return subMapping;
	}


	/**------------------------------------------------------
	 * Get Mappings as Array
	 */
	getArrayMap<TKey extends string, TValue>(mapping: Record<TKey, TValue>): IUtilMappingEntry<TKey, TValue>[] {
		return this.mappingToArray(this.getMap(mapping));
	}

	getArrayMapElem<TKey extends string, TValue>(mapping: Record<TKey, TValue>, key: TKey): IUtilMappingEntry<TKey, TValue> {
		const result: IUtilMappingEntry<TKey, TValue>[] = this.mappingToArray(this.getMapElem(mapping, key));
		if (result.length !== 1) throw new Error(`UtilMapper => getArrayMapElem => FATAL ERROR: none or too many elements found for the key of "${key}" (result.length: ${result.length})`);
		return result[0];
	}

	getArrayMapInclude<TKey extends string, TValue>(mapping: Record<TKey, TValue>, include: TKey[]): IUtilMappingEntry<TKey, TValue>[] {
		return this.mappingToArray(this.getMapInclude(mapping, include));
	}

	getArrayMapExclude<TKey extends string, TValue>(mapping: Record<TKey, TValue>, exclude: TKey[]): IUtilMappingEntry<TKey, TValue>[] {
		return this.mappingToArray(this.getMapExclude(mapping, exclude));
	}


	/**------------------------------------------------------
	 * Get Values / Names of the Mappings
	 */
	getValues<TKey extends string, TValue>(mapping: Record<TKey, TValue>): TValue[] {
		if (this.defaultChecks) this.checkMapping(mapping);
		return this.getMappingValues(mapping);
	}

	getValueElem<TKey extends string, TValue>(mapping: Record<TKey, TValue>, key: TKey): TValue {
		if (this.defaultChecks) this.checkMapping(mapping);
		if (!Object.keys(mapping).includes(key)) throw new Error(`UtilMapper => getNameElem => FATAL ERROR: provided key was not defined for the mapping (defined values: "${Object.keys(mapping)}")`);
		return mapping[key];
	}

	getValueInclude<TKey extends string, TValue>(mapping: Record<TKey, TValue>, include: TKey[]): TValue[] {
		if (this.defaultChecks) this.checkMapping(mapping);
		const subMapping: Record<string, TValue> = this.getMapInclude<TKey, TValue>(mapping, include);
		return this.getMappingValues(subMapping);
	}

	getValueExclude<TKey extends string, TValue>(mapping: Record<TKey, TValue>, exclude: TKey[]): TValue[] {
		if (this.defaultChecks) this.checkMapping(mapping);
		const subMapping: Record<string, TValue> = this.getMapExclude<TKey, TValue>(mapping, exclude);
		return this.getMappingValues(subMapping);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	checkMapping<TKey extends string, TValue>(mapping: Record<TKey, TValue>): void {
		if (Object.keys(mapping).length === 0) throw new Error(`UtilMapper => checkMapping => FATAL ERROR: provided mapping is empty`);
		for (const key of Object.keys(mapping)) {
			if (!mapping[key as TKey]) throw new Error(`UtilMapper => checkMapping => FATAL ERROR: provided mapping value for key of "${key}" is undefined`);
			if (!this.isString(key))   throw new Error(`UtilMapper => checkMapping => FATAL ERROR: provided key of "${key}" is not of type string`);
		}
	}
	getMappingValues<TKey extends string, TValue>(mapping: Record<TKey, TValue>): TValue[] {
		return (Object.keys(mapping) as TKey[]).map((key: string) => (mapping as any)[key]);
	}
	mappingToArray<TKey extends string, TValue>(mapping: Record<TKey, TValue>): IUtilMappingEntry<TKey, TValue>[] {

		//0 - convert the record to an array mapping
		const mappingArray: IUtilMappingEntry<TKey, TValue>[] = Object.entries(mapping).map(([elemKey, elemValue]: [string, unknown]) => {
			const entry: IUtilMappingEntry<TKey, TValue> = {
				key		: elemKey as TKey,
				value 	: elemValue as TValue
			};
			return entry;
		});

		//1 - return the mapping as an array
		return mappingArray;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private isString(value: any): boolean {
		return typeof value === 'string' || value instanceof String;			// duplication of the string util functionality
	}
}


//** Interfaces --------------------------------- */
export interface IUtilMappingEntry<TKey extends string, TValue> {
	key		: TKey;
	value	: TValue;
}
