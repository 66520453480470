//** cSpell:ignore 3csvg, 3ccircle */

/**------------------------------------------------------
 * Styles to be applied on dark mode
 */
export const STYLES_ON_DARK_MODE: string = `
	.form-check-input {
		background-image: var(--bs-form-check-bg-image) !important;
	}

	.form-switch .form-check-input {
		--bs-form-switch-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='white'/%3E%3C/svg%3E") !important;
		background-image: var(--bs-form-switch-bg) !important;
	}

	.nav-tabs {
		--darkreader-border--bs-nav-tabs-link-active-border-color: var(--darkreader-border--bs-border-color) var(--darkreader-border--bs-border-color) var(--darkreader-bg--bs-body-bg) !important;
	}

	.ng-wizard-theme-arrows>ul.step-anchor>li.active>a {
		border-color: var(--gradient-light) !important;
		color: #fff !important;
		background: var(--gradient-light) !important;
	}
	.ng-wizard-theme-arrows>ul.step-anchor>li.active>a:after {
		border-left: 30px solid var(--gradient-light) !important;
	}
	.ng-wizard-theme-arrows::before {
		border: 10px solid #f3f3f3 !important;
		border-top: 10px solid var(--gradient-light);
	}
	.ng-wizard-theme-arrows>ul.step-anchor>li.done>a:after {
		border-left: 30px solid var(--gradient-dark) !important;
	}
	.ng-wizard-theme-arrows>ul.step-anchor>li.done>a {
		border-color: var(--gradient-dark) !important;
		color: #fff !important;
		background: var(--gradient-dark) !important;
	}

	.ng-wizard-theme-arrows::after {
		background: rgb(51, 55, 57) !important;
	}
	.ng-wizard-theme-arrows>ul.step-anchor>li>a {
		background: rgb(51, 55, 57) !important;
	}
	.ng-wizard-theme-arrows>ul.step-anchor>li>a:after {
		border-left: 30px solid rgb(51, 55, 57) !important;
	}

	.ng-wizard-theme-arrows {
		border: 1px solid #454a4d !important;
	}
	.ng-wizard-theme-arrows>ul.step-anchor {
		border-bottom: 1px solid #454a4d !important;
	}
	.ng-wizard-theme-arrows>ul.step-anchor>li>a:before {
		border-left: 30px solid #454a4d !important;
	}

	.pdfHtml {
		background	: #fff !important;
		color		: #000 !important;
	}

	.upload-platform {
		background-color: #dedede !important;
	}

	.snowflake {
		color: white !important;
	}

	.mba_product_iphone_cases > * > .product-guideline::before {
		background: var(--mba_accessories_background_image) no-repeat !important;
		background-color: var(--mba_accessories_background_color_ignore_dark_mode) !important;
	}
	.mba_product_iphone_cases > * > .product-guideline::after {
		background: var(--mba_accessories_background_image) no-repeat !important;
	}

	.mba_product_popsockets > .asset {
		background: var(--mba_accessories_background_image) no-repeat !important;
		background-color: var(--mba_accessories_background_color_ignore_dark_mode) !important;
	}

	.mba_product_samsung_cases > * > .product-guideline::before {
		background: var(--mba_accessories_background_image) no-repeat !important;
		background-color: var(--mba_accessories_background_color_ignore_dark_mode) !important;
	}
	.mba_product_samsung_cases > * > .product-guideline::after {
		background: var(--mba_accessories_background_image) no-repeat !important;
	}

	.mba_product_tote_bag > * > .product-guideline-common {
		background: var(--mba_accessories_background_image) no-repeat !important;
	}
	.mba_product_tote_bag > * > .product-guideline::before {
		background: var(--mba_accessories_background_image) no-repeat !important;
		background-color: var(--mba_accessories_background_color_ignore_dark_mode) !important;
	}

	.mba_product_throw_pillows > * > .product-guideline-common {
		background: var(--mba_accessories_background_image) no-repeat !important;
	}
	.mba_product_throw_pillows > * > .product-guideline::before {
		background: var(--mba_accessories_background_image) no-repeat !important;
		background-color: var(--mba_accessories_background_color_ignore_dark_mode) !important;
	}
`;


/**------------------------------------------------------
 * Ignore elements with css selectors
 */
export const IGNORE_INLINE_STYLES: string[] = [
	'.ignore-dark-mode *',
	'.upload-platform',
	'.product-tour-overlay-element'
];


/**------------------------------------------------------
 * Ignore css variables
 */
export const IGNORE_CSS_VARIABLES: string[] = [
	'--fa-primary',
	'--fa-secondary'
];


/**------------------------------------------------------
 * Ignore images with css selectors
 */
export const IGNORE_IMAGES: string[] = [
	'.upload-platform'
];
