/* eslint-disable import/no-cycle */
import { ValidatorSchemaProcessor } from './shared/schema-processor/schema-processor';
import { ValidatorValueProcessor } from './shared/value-processor/value-processor';
import { ValidatorDomainName } from './validators/domain-name-validator/domain-name-validator';
import { ValidatorEmail } from './validators/email-validator/email-validator';
import { ValidatorName } from './validators/name-validator/name-validator';
import { ValidatorPassword } from './validators/password-validator/password-validator';
import { ValidatorPath } from './validators/path-validator/path-validator';
import { ValidatorValueString } from './values/value-string';
import { ValidatorValueNumber } from './values/value-number';
import { ValidatorValueArray } from './values/value-array';
import { ValidatorValueObject } from './values/value-object';
import { ValidatorValueBoolean } from './values/value-boolean';
import { ValidatorValueEnum } from './values/value-enum';
import { ValidatorValueDate } from './values/value-date';
import { ValidatorValueFunction } from './values/value-function';


/**------------------------------------------------------
 * Validators
 * ----------
 * > Dependencies: none dependencies
 */
export class Validator {

	/**------------------------------------------------------
	 * Process the Schema (based on Object/Class Schema)
	 */
	static readonly Schema		: ValidatorSchemaProcessor 		= new ValidatorSchemaProcessor(new ValidatorValueProcessor());


	/**------------------------------------------------------
	 * Helper Validators
	 */
	static readonly Password	: ValidatorPassword 	= new ValidatorPassword();
	static readonly DomainName	: ValidatorDomainName 	= new ValidatorDomainName();
	static readonly Email		: ValidatorEmail 		= new ValidatorEmail(Validator.DomainName);
	static readonly Name		: ValidatorName 		= new ValidatorName();
	static readonly Path		: ValidatorPath			= new ValidatorPath();


	/**------------------------------------------------------
	 * Value Validators
	 */
	static String 	= (): ValidatorValueString 	=> new ValidatorValueString();
	static Number 	= (): ValidatorValueNumber 	=> new ValidatorValueNumber();
	static Boolean 	= (): ValidatorValueBoolean => new ValidatorValueBoolean();
	static Enum 	= (enumDef: object | object[]): ValidatorValueEnum => new ValidatorValueEnum(enumDef);
	static Date 	= (): ValidatorValueDate 	=> new ValidatorValueDate();
	static Array 	= (): ValidatorValueArray 	=> new ValidatorValueArray();
	static Object 	= (): ValidatorValueObject 	=> new ValidatorValueObject();
	static Function = (): ValidatorValueFunction => new ValidatorValueFunction();
}
