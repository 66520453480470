/**------------------------------------------------------
 * Interfaces for Pbkdf2
 */
export interface ICryptoPbkdf2HashOptions {
	iterations	: number;
	keyLength	: number;
	saltLength	: number;
}

export interface ICryptoPbkdf2HashObject {
	algorithm	: string;
	hash		: string;
	salt		: string;
	iterations	: number;
	keyLength	: number;
}
