/* eslint-disable import/no-cycle */
import { Util } from '@libs/utilities/util';

import { Validator } from '../validator';
import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';
import { EnumValidatorOperationType } from '../shared/validator-value.interface';


export class ValidatorValueString extends AbstractValidatorValue<ValidatorValueString> {

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'string'; }
	protected isValueTypeValid(value: any): boolean { return Util.String.isString(value); }


	/**------------------------------------------------------
	 * Check Empty
	 */
	isEmpty(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.String.isEmpty(value),
				error		: 'The value must be empty'
			})
		});
		return this;
	}

	isNotEmpty(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.String.isNotEmpty(value),
				error		: 'The value can not be empty'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Length Checks
	 */
	isLength(length: number): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: value.length === length,
				error		: `The value must be equal to ${length} chars`
			})
		});
		return this;
	}

	isMinLength(min: number): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: value.length >= min,
				error		: `The value must be longer or equal ${min} chars`
			})
		});
		return this;
	}

	isMaxLength(max: number): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: value.length <= max,
				error		: `The value must be shorter or equal ${max} chars`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Lower- / UpperCase
	 */
	isLowerCase(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.String.isLowerCase(value),
				error		: 'The value is not lower case'
			})
		});
		return this;
	}

	toLowerCase(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => value.toLowerCase()
		});
		return this;
	}

	isUpperCase(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.String.isUpperCase(value),
				error		: 'The value is not upper case'
			})
		});
		return this;
	}

	toUpperCase(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => value.toUpperCase()
		});
		return this;
	}

	isTitleCase(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.String.titleCase(value) === value,
				error		: 'The value is not title case'
			})
		});
		return this;
	}

	toTitleCase(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.titleCase(value)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Name, Email, Password, & Domain
	 */
	isName(nameInfo: string = 'Name'): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Validator.Name.isValidName(value),
				error		: Validator.Name.validateName(value, nameInfo).join(' ')
			})
		});
		return this;
	}

	isFullName(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Validator.Name.isValidFullName(value),
				error		: Validator.Name.validateFullName(value).join(' ')
			})
		});
		return this;
	}

	isEmail(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Validator.Email.isValid(value),
				error	: Validator.Email.validate(value).join(' ')
			})
		});
		return this;
	}

	isPassword(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => {
				return {
					isValid	: Validator.Password.isValid(value),
					error	: Validator.Password.validate(value)[0]
				};
			}
		});
		return this;
	}

	isDomain(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => {
				return {
					isValid	: Validator.DomainName.isValid(value),
					error	: Validator.DomainName.validate(value).join(' ')
				};
			}
		});
		return this;
	}


	/**------------------------------------------------------
	 * Path
	 */
	isRelativePath(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => {
				return {
					isValid	: Validator.Path.isValidRelativePath(value),
					error	: Validator.Path.validateRelativePath(value).join(' ')
				};
			}
		});
		return this;
	}

	isRelativeFilePath(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => {
				return {
					isValid	: Validator.Path.isValidRelativeFilePath(value),
					error	: Validator.Path.validateRelativeFilePath(value).join(' ')
				};
			}
		});
		return this;
	}


	/**------------------------------------------------------
	 * Colors
	 */
	isHexColor(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.Color.isHexColor(value),
				error	: 'The value is not a valid hex color'
			})
		});
		return this;
	}

	isRgbColor(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.Color.isRgbColor(value),
				error	: 'The value is not a valid RGB color'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Hex
	 */
	isHex(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => {
				return {
					isValid	: Util.String.isHex(value),
					error	: 'The value is not a valid hex string'
				};
			}
		});
		return this;
	}

	toHex(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.toHex(value)
		});
		return this;
	}

	fromHex(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.fromHex(value)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Base64 & Base64Url
	 */
	isBase64(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.String.isBase64(value),
				error	: 'The value is not a valid base64 string'
			})
		});
		return this;
	}

	toBase64(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.toBase64(value)
		});
		return this;
	}

	fromBase64(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.fromBase64(value)
		});
		return this;
	}

	isBase64Uri(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.String.isBase64Uri(value),
				error	: 'The value is not a valid base64 URI string'
			})
		});
		return this;
	}

	isBase64Url(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.String.isBase64Url(value),
				error	: 'The value is not a valid base64 URL string'
			})
		});
		return this;
	}

	toBase64Url(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.toBase64Url(value)
		});
		return this;
	}

	fromBase64Url(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.fromBase64Url(value)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Binary
	 */
	isBinary(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.String.isBinary(value),
				error	: 'The value is not a valid hex string'
			})
		});
		return this;
	}

	toBinary(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.toBinary(value)
		});
		return this;
	}

	fromBinary(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.fromBinary(value)
		});
		return this;
	}


	/**------------------------------------------------------
	 * String Value Checks
	 */
	isNumeric(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: !Util.String.hasNonNumeric(value),
				error	: 'The value is not numeric'
			})
		});
		return this;
	}

	isAlphabetic(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: !Util.String.hasNonAlphabetic(value),
				error	: 'The value is not alphabetic'
			})
		});
		return this;
	}

	isAlphaNumeric(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: !Util.String.hasNonAlphanumeric(value),
				error	: 'The value is not alphabetic'
			})
		});
		return this;
	}

	isAscii(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: !Util.String.hasNonAscii(value),
				error	: 'The value is not ASCII'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Whitespaces Helpers
	 */
	isHavingWhitespaces(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: /\s/.test(value),
				error		: 'The value must not have whitespaces'
			})
		});
		return this;
	}

	removeWhitespaces(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.removeWhitespaces(value)
		});
		return this;
	}

	purifyWhitespaces(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.purifyWhitespaces(value)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Special Formats
	 */
	isJson(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.Basic.isStringJson(value),
				error		: 'The value is not a valid JSON string'
			})
		});
		return this;
	}

	isUrl(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.Http.isUrlValid(value),
				error	: 'The value is not a valid URL'
			})
		});
		return this;
	}

	isIp(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid	: Util.Http.isValidIpv4(value) || Util.Http.isValidIpv6(value),
				error	: 'The value is not a valid IPv4/IPv6 address string'
			})
		});
		return this;
	}

	isLocalIp(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => {
				return {
					isValid	: Util.Http.isValidLocalIpv4(value) || Util.Http.isValidLocalIpv6(value),
					error	: 'The value is not a valid local IPv4/IPv6 address string'
				};
			}
		});
		return this;
	}


	/**------------------------------------------------------
	 * Matching
	 */
	includes(text: string): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: value.includes(text),
				error		: `The value does not include ${text}`
			})
		});
		return this;
	}

	notIncludes(text: string): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: !value.includes(text),
				error		: `The value must not include ${text}`
			})
		});
		return this;
	}

	matches(regex: RegExp): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: regex.test(value),
				error		: `The value does not match the regex of ${regex}`
			})
		});
		return this;
	}

	equals(comparison: string): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: value === comparison,
				error		: `The value does not equal ${comparison}`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Whitelist & Blacklist
	 */
	isWhitelisted(whitelist: string[]): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: whitelist.includes(value),
				error		: 'The value is not part of the whitelist'
			})
		});
		return this;
	}

	isNotBlacklisted(blacklisted: string[]): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: !blacklisted.includes(value),
				error		: 'The value is part of the blacklist'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Trim
	 */
	trim(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => value.trim()
		});
		return this;
	}

	trimStart(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => value.trimStart()
		});
		return this;
	}

	trimEnd(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => value.trimEnd()
		});
		return this;
	}


	/**------------------------------------------------------
	 * Replace
	 */
	replace(matcher: RegExp, text: string): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => value.replace(matcher, text)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Url Encoding
	 */
	decodeUrl(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => decodeURI(value)
		});
		return this;
	}

	encodeUrl(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => encodeURI(value)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Transformations
	 */
	reverse(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.reverse(value)
		});
		return this;
	}

	truncate(length: number): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.String.truncate(value, length)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Html
	 */
	isValidHtml(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.RegExp.isValidHtml(value),
				error		: `The value is not valid html`
			})
		});
		return this;
	}

	hasNoHtml(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: !Util.RegExp.hasHtml(value),
				error		: `The value can not have html`
			})
		});
		return this;
	}

	hasNoHtmlScripts(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: !Util.RegExp.hasHtmlScripts(value),
				error		: `The value can not have html scripts`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Escape Html
	 */
	escapeHtml(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.RegExp.escapeHtml(value)
		});
		return this;
	}

	unescapeHtml(): ValidatorValueString {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string) => Util.RegExp.unescapeHtml(value)
		});
		return this;
	}
}
