/* eslint-disable import/no-cycle */
import { Util } from '@libs/utilities/util';

import { IValidatorError } from '../shared/validator-error.interface';
import { ValidatorSchemaRef } from '../shared/validator-schema.interface';
import { Validator } from '../validator';
import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';
import { EnumValidatorOperationType, IValidatorValidationResult } from '../shared/validator-value.interface';


export class ValidatorValueObject extends AbstractValidatorValue<ValidatorValueObject> {

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'object'; }
	protected isValueTypeValid(value: any): boolean { return Util.Basic.isObject(value); }


	/**------------------------------------------------------
	 * Validate Object
	 */
	inspect<T extends object>(objectOrClassSchema: ValidatorSchemaRef<T>): ValidatorValueObject {

		//0 - add validation to pipeline
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: T) => {

				//a. validate the object
				const validationErrors: IValidatorError[] = Validator.Schema.validate<T>(object, objectOrClassSchema);

				//b. prepare the result
				const result: IValidatorValidationResult = {
					isValid		: validationErrors.length === 0,
					error		: 'Object is invalid',
					children	: validationErrors
				};
				return result;
			}
		});

		//1 - add transformation
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (object: T) => Validator.Schema.transform<T>(object, objectOrClassSchema, { disableCheck: true })
		});
		return this;
	}


	/**------------------------------------------------------
	 * Empty
	 */
	isEmpty(): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Util.Object.isEmpty(object),
				error		: 'The object is not empty'
			})
		});
		return this;
	}

	isNotEmpty(): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Util.Object.isNotEmpty(object),
				error		: 'The object is empty'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Equal
	 */
	isEqual(data: object): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Util.Object.isEqual(data, object),
				error		: 'The object is not equal'
			})
		});
		return this;
	}

	isNotEqual(data: object): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Util.Object.isNotEqual(data, object),
				error		: 'The object is equal'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Keys
	 */
	hasKey(key: string): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Util.Object.isObjectKeyValid(object, key),
				error		: `The object does not have the key "${key}"`
			})
		});
		return this;
	}

	hasKeys(keys: string[]): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: keys.every((key: string) => Util.Object.isObjectKeyValid(object, key)),
				error		: `The object does not have the keys "${keys.join(', ')}"`
			})
		});
		return this;
	}

	eachKey(validateFn: (key: string) => boolean): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Object.keys(object).every((key: string) => validateFn(key)),
				error		: 'The object keys are invalid'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Values
	 */
	hasUndefinedValues(): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Util.Object.hasUndefinedValues(object),
				error		: 'The object has undefined values'
			})
		});
		return this;
	}

	hasOnlyStringValues(): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Object.values(object).every((value: unknown) => Util.String.isString(value)),
				error		: 'The object must have only string values'
			})
		});
		return this;
	}

	eachValue<T extends string | boolean | number | object>(validateFn: (value: T) => boolean): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (object: object) => ({
				isValid		: Object.values(object).every((value: unknown) => validateFn(value as T)),
				error		: 'The object values are invalid'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Transformers
	 */
	deepAssignDefault(defaultObject: object): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (object: object) => Util.Basic.deepAssignPartial(defaultObject, object)
		});
		return this;
	}

	deepFreeze(): ValidatorValueObject {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (object: object) => Util.Basic.deepFreezeObject(object)
		});
		return this;
	}
}
