import { ISpreadshirtSearchOptions } from './spreadshirt-search.data';
import { EnumPodMarketplace } from '../../../../../constants/research';
import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { SearchLinksHelper } from '../../../data/search-links.helper';
import { IMarketSearchLinks } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Spreadshirt Search Links
 */
export class SpreadshirtSearch implements IMarketSearchLinks<EnumPodMarketplace> {

	constructor(
		private searchLinksHelper	: SearchLinksHelper,
		private searchHandlers		: AbstractSearchLinks<ISpreadshirtSearchOptions>[]
	) { }


	/**------------------------------------------------------
	 * Get Search Handler
	 */
	getMarketplace(marketplace: EnumPodMarketplace): AbstractSearchLinks<ISpreadshirtSearchOptions> {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers) as AbstractSearchLinks<ISpreadshirtSearchOptions>;
	}


	/**------------------------------------------------------
	 * Links & Search Links
	 */
	home(marketplace: EnumPodMarketplace): string {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers).home();
	}

	login(marketplace: EnumPodMarketplace): string {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers).login();
	}

	//** Search */
	search(marketplace: EnumPodMarketplace, searchKeyword: string, options?: Partial<ISpreadshirtSearchOptions>): string {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers).search(searchKeyword, options);
	}
}
