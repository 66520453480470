/**------------------------------------------------------
 * Image Data Interfaces
 */
export interface ICryptoImagePixelData {
	data	: Uint8ClampedArray;
	width	: number;
	height	: number;
}

export interface ICryptoImageBound {
	top		: number;
	left	: number;
	right	: number;
	bottom	: number;
}

export interface ICryptoPixelVector {
	r 		: number;		// red
	g 		: number;		// green
	b 		: number;		// blue
	a 		: number;		// opacity
}

export interface ICryptoHashResult {
	pHashHex	: string;
	pHashBinary	: string;
}
