import { EnumCountry } from '@libs/constants';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumGoogleCategory {
	All			= 'all',
	Images		= 'image',
	News		= 'news',
	Videos		= 'video',
	Shopping	= 'shopping'
}


/**------------------------------------------------------
 * API Request & Response
 */
export interface IGoogleSuggestionsRequest {
	keyword		: string;
	country		: EnumCountry;
	category	: EnumGoogleCategory;
	prefix		: string[];
	postfix		: string[];
}

export interface IGoogleSuggestionsResponse {
	suggestions	: string[];
}
