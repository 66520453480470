/**------------------------------------------------------
 * Module Names/Id's
 */
export enum EnumSubscriptionModule {

	// -------------------------------------- Free Versions
	FreeVersion 			= 'module_free_version',

	// -------------------------------------- Research
	Research 				= 'module_research',
	AiResearch				= 'module_ai_research',
	Trademark				= 'module_trademark',
	KeywordBasic			= 'module_keyword_basic',
	KeywordPro				= 'module_keyword_pro',

	// -------------------------------------- POD
	DesignManagement25GB	= 'module_design_management_25gb',
	DesignManagement100GB	= 'module_design_management_100gb',
	DesignManagement500GB	= 'module_design_management_500gb',

	UploadPodSpreadshirt	= 'module_upload_pod_spreadshirt',
	UploadPodRedbubble		= 'module_upload_pod_redbubble',
	UploadPodMba			= 'module_upload_pod_mba',
	UploadPodDisplate		= 'module_displate',
	UploadPodFineArtAmerica	= 'module_fine_art_america',
	UploadPodPrintful		= 'module_printful',
	UploadPodPrintify		= 'module_printify',
	UploadPodShirtee		= 'module_shirtee',
	UploadPodSociety6		= 'module_society6',
	UploadPodTeepublic		= 'module_teepublic',
	UploadPodTeespring		= 'module_teespring',
	UploadPodTostadora		= 'module_tostadora',
	UploadPodZazzle			= 'module_zazzle',
	UploadPodAdvanced		= 'module_upload_pod_advanced',
	UploadPodAdvancedShop	= 'module_upload_pod_advanced_shop',
	UploadPodPro			= 'module_upload_pod_pro',

	// -------------------------------------- KDP
	// BookManagement25GB	= 'module_book_management_25gb',
	// BookManagement100GB	= 'module_book_management_100gb',
	// BookManagement500GB	= 'module_book_management_500gb',
	UploadKdp				= 'module_upload_kdp'
}

export enum EnumPricingType {
	Free 		= 'pricing_free',
	Paid 		= 'pricing_paid'
}

export enum EnumModuleType {
	Access 		= 'module_access',
	Storage 	= 'module_storage'
}


/**------------------------------------------------------
 * Subscription Modules
 */
export const SUBSCRIPTION_MODULES : ISubscriptionModule[] = [

	// -------------------------------------- Free Versions
	{
		code			: EnumSubscriptionModule.FreeVersion,
		moduleName		: 'Free Version',
		description		: 'The free version of the research and upload applications with all basic functionalities.',
		pricingType		: EnumPricingType.Free,
		moduleType		: [EnumModuleType.Access]
	},


	// -------------------------------------- Research
	{
		code			: EnumSubscriptionModule.Research,
		moduleName		: 'Research',
		description		: 'The full research from Best Seller, Seller Trends, to Evergreens for your Print-On-Demand business.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.AiResearch,
		moduleName		: 'AI Research',
		description		: 'The full AI Research with Aurora, Reveal, and Mumble for your Print-On-Demand business.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.Trademark,
		moduleName		: 'Trademark',
		description		: 'Trademark are important for the safety of you and your account. Search for Trademarks, see New Registrations, and add phrases to your Watchlist, or get Mail Notifications for more security.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	/* {
		code			: EnumSubscriptionModule.Keyword,
		moduleName		: 'Keyword',
		description		: 'All keywords in one place. Search for the most popular keywords, long-tail keywords and phrases. Research and add the best keywords to your listings to create the best listings.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access],
	}, */


	// -------------------------------------- Research
	{
		code			: EnumSubscriptionModule.KeywordBasic,
		moduleName		: 'Keywords Basic',
		description		: 'Basic keyword pages, including the KW Search with the Amazon search volume.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.KeywordPro,
		moduleName		: 'Keywords Pro',
		description		: 'All keyword and advertisement keyword pages. With all data, including the Amazon search volume.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},


	// -------------------------------------- Design Management
	{
		code			: EnumSubscriptionModule.DesignManagement25GB,
		moduleName		: 'Design Management 25GB',
		description		: 'The design management for all your images and listings. Add and manage all your designs and listings in one place. Manage up to 25GB of designs at once.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access, EnumModuleType.Storage]
	},
	{
		code			: EnumSubscriptionModule.DesignManagement100GB,
		moduleName		: 'Design Management 100GB',
		description		: 'The design management for all your images and listings. Add and manage all your designs and listings in one place. Manage up to 100GB of designs at once.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access, EnumModuleType.Storage]
	},
	{
		code			: EnumSubscriptionModule.DesignManagement500GB,
		moduleName		: 'Design Management 500GB',
		description		: 'The design management for all your images and listings. Add and manage all your designs and listings in one place. Manage up to 500GB of designs at once.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access, EnumModuleType.Storage]
	},

	// -------------------------------------- Book Management
	/* {
		code			: EnumSubscriptionModule.BookManagement25GB,
		moduleName		: 'Book Management 25GB',
		description		: 'The book management for all your kdp books and manuscripts. Add and manage all your books and listings for kdp in one place. Manage up to 25GB of designs at once.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access, EnumModuleType.Storage],
	},
	{
		code			: EnumSubscriptionModule.BookManagement100GB,
		moduleName		: 'Book Management 100GB',
		description		: 'The book management for all your kdp books and manuscripts. Add and manage all your books and listings for kdp in one place. Manage up to 100GB of designs at once.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access, EnumModuleType.Storage],
	},
	{
		code			: EnumSubscriptionModule.BookManagement500GB,
		moduleName		: 'Design Management 500GB',
		description		: 'The book management for all your kdp books and manuscripts. Add and manage all your books and listings for kdp in one place. Manage up to 500GB of designs at once.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access, EnumModuleType.Storage],
	}, */

	// -------------------------------------- Upload POD
	{
		code			: EnumSubscriptionModule.UploadPodSpreadshirt,
		moduleName		: 'Upload Single - Spreadshirt',
		description		: 'Upload all your POD designs automatically and unlimited to Spreadshirt EU and Spreadshirt US.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodRedbubble,
		moduleName		: 'Upload Single - Redbubble',
		description		: 'Upload all your POD designs automatically and unlimited to Redbubble.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodMba,
		moduleName		: 'Upload Single - Merch By Amazon',
		description		: 'Upload all your POD designs automatically and unlimited to Merch By Amazon.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadKdp,
		moduleName		: 'Upload KDP',
		description		: 'Upload all your books automatically to Kindle Direct Publishing.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodDisplate,
		moduleName		: 'Upload Single - Displate',
		description		: 'Upload all your POD designs automatically and unlimited to Displate.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodFineArtAmerica,
		moduleName		: 'Upload Single - Fine Art America',
		description		: 'Upload all your POD designs automatically and unlimited to Fine Art America.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodPrintful,
		moduleName		: 'Upload Single - Printful',
		description		: 'Upload all your POD designs automatically and unlimited to Printful.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodPrintify,
		moduleName		: 'Upload Single - Printify',
		description		: 'Upload all your POD designs automatically and unlimited to Printify.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodShirtee,
		moduleName		: 'Upload Single - Shirtee',
		description		: 'Upload all your POD designs automatically and unlimited to Shirtee.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodSociety6,
		moduleName		: 'Upload Single - Society6',
		description		: 'Upload all your POD designs automatically and unlimited to Society6.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodTeepublic,
		moduleName		: 'Upload Single - Teepublic',
		description		: 'Upload all your POD designs automatically and unlimited to Teepublic.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodTeespring,
		moduleName		: 'Upload Single - Teespring',
		description		: 'Upload all your POD designs automatically and unlimited to Teespring.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodTostadora,
		moduleName		: 'Upload Single - Tostadora',
		description		: 'Upload all your POD designs automatically and unlimited to Tostadora.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodZazzle,
		moduleName		: 'Upload Single - Zazzle',
		description		: 'Upload all your POD designs automatically and unlimited to Zazzle.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodAdvanced,
		moduleName		: 'Upload Advanced - Amazon, Spreadshirt, and Redbubble',
		description		: 'Upload all your POD designs automatically and unlimited to Merch By Amazon, Redbubble and Spreadshirt.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodAdvancedShop,
		moduleName		: 'Upload Advanced - Amazon, Printful, and Printify',
		description		: 'Upload all your POD designs automatically and unlimited to Merch By Amazon, Redbubble and Spreadshirt.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	},
	{
		code			: EnumSubscriptionModule.UploadPodPro,
		moduleName		: 'Upload Pro',
		description		: 'Upload all your designs automatically and unlimited to all available Print On Demand Platforms.',
		pricingType		: EnumPricingType.Paid,
		moduleType		: [EnumModuleType.Access]
	}
];


/**------------------------------------------------------
 * Interfaces Definition
 */
export interface ISubscriptionModule {
	code 			: EnumSubscriptionModule;
	moduleName		: string;
	description 	: string;
	pricingType		: EnumPricingType;
	moduleType		: EnumModuleType[];
}
