/**------------------------------------------------------
 * Hex To Binary
 */
export class UtilHexToBinary {

	//** Binary / Hex Mapping Helpers */
	private readonly HEX_TO_BINARY_MAP = { '0' : '0000', '1' : '0001', '2' : '0010', '3' : '0011', '4' : '0100', '5' : '0101', '6' : '0110', '7' : '0111', '8' : '1000', '9' : '1001', 'a' : '1010', 'b' : '1011', 'c' : '1100', 'd' : '1101', 'e' : '1110', 'f' : '1111' };
	private readonly BINARY_TO_HEX_MAP = { '0000' : '0', '0001' : '1', '0010' : '2', '0011' : '3', '0100' : '4', '0101' : '5', '0110' : '6', '0111' : '7', '1000' : '8', '1001' : '9', '1010' : 'a', '1011' : 'b', '1100' : 'c', '1101' : 'd', '1110' : 'e', '1111' : 'f' };


	/**------------------------------------------------------
	 * Hex to Binary
	 */
	hexToBinary(hexString: string): string {

		//0 - check the provided hex value
		hexString = hexString.replace('0x', '').replace(/\s/g, '').toLowerCase();
		if (this.isEmpty(hexString)) throw new Error(`HexToBinary => hexToBinary => FATAL ERROR: provided hexString string is empty`);

		//1 - convert the hex value to binary
		let binaryResult: string = '';
		for (const hexChar of hexString.split('')) {
			const binaryValue: string | undefined = (this.HEX_TO_BINARY_MAP as any)[hexChar];
			if (!binaryValue) throw new Error(`HexToBinary => hexToBinary => FATAL ERROR: the hex value of "${hexString}" contains a char of "${hexChar}" which can not be interpreted as an hex value`);
			binaryResult += binaryValue;
		}
		return binaryResult;
	}

	binaryToHex(binaryString: string): string {

		//0 - check the provided hex value
		binaryString = binaryString.replace('0b', '').replace(/\s/g, '');
		if (this.isEmpty(binaryString)) throw new Error(`HexToBinary => binaryToHex => FATAL ERROR: provided binaryString string is empty`);

		//1 - divide the string into the binary chunks
		if ((binaryString.length % 4) !== 0) throw new Error(`HexToBinary => binaryToHex => FATAL ERROR: provided binaryString string of "${binaryString}" can not be divided in its chunk sizes of 4 (example: ['0010', '0001', ..])`);
		const binaryChunks: string[] = binaryString.match(/.{1,4}/g) as string[];	// split by every 4 chars

		//2 - convert the binary chunks into hex values
		let hexResult: string = '';
		for (const binaryValue of binaryChunks) {
			const hexValue: string | undefined = (this.BINARY_TO_HEX_MAP as any)[binaryValue];
			if (!hexValue) throw new Error(`HexToBinary => binaryToHex => FATAL ERROR: the binary value of "${binaryString}" contains a char of "${binaryValue}" which can not be interpreted as an binary value`);
			hexResult += hexValue;
		}
		return hexResult;
	}

	/**------------------------------------------------------
	 * Helper Functions
	 */
	private isEmpty(value: string | any): boolean {
		const isString: boolean = typeof value === 'string' || value instanceof String;
		if (!isString || (value as string).trim().length === 0) return true;						// duplication of the string util functionality
		return false;
	}
}
