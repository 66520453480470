import { EnumTmNiceType } from './tm-nice-type.data';


/**------------------------------------------------------
 * Trademark Nice Classes
 */
export enum EnumTmNiceClass {
	Class1		= '1',			// Chemicals used in industry, science, and photography
	Class2		= '2',			// Paints, varnishes, lacquers; preservatives against rust and against deterioration of wood
	Class3		= '3',			// Cosmetics and cleaning preparations
	Class4		= '4',			// Lubricants and fuels
	Class5		= '5',			// Pharmaceuticals
	Class6		= '6',			// Common metals and their alloys; metal building materials; transportable buildings of metal; materials of metal for railway tracks; non-electric cables and wires of common metal
	Class7		= '7',			// Machines and machine tools; motors and engines (except for land vehicles); machine coupling and transmission components (except for land vehicles); agricultural implements other than hand-operated; incubators for eggs
	Class8		= '8',			// Hand tools and implements (hand-operated); cutlery; side arms; razors
	Class9		= '9',			// Scientific, nautical, surveying, photographic, cinematographic, optical, weighing, measuring, signaling, checking (supervision), life-saving, and teaching apparatus and instruments; apparatus for recording, transmission or reproduction of sound or images; magnetic data carriers, recording discs; automatic vending machines and mechanisms for coin-operated apparatus; cash registers, calculating machines, data processing equipment and computers; fire-extinguishing apparatus
	Class10		= '10',			// Medical apparatus and instruments
	Class11		= '11',			// Apparatus for lighting, heating, steam generating, cooking, refrigerating, drying, ventilating, water supply, and sanitary purposes
	Class12		= '12',			// Vehicles; apparatus for locomotion by land, air, or water
	Class13		= '13',			// Firearms; ammunition and projectiles; explosives; fireworks
	Class14		= '14',			// Precious metals and their alloys and goods in precious metals or coated therewith, not included in other classes; jewelry, precious stones; horological and chronometric instruments
	Class15		= '15',			// Musical instruments
	Class16		= '16',			// Paper, cardboard and goods made from these materials, not included in other classes; printed matter; bookbinding material; photographs; stationery; adhesives for stationery or household purposes; artists' materials; paint brushes; typewriters and office requisites (except furniture); instructional and teaching material (except apparatus); plastic materials for packaging (not included in other classes); printers' type; printing blocks
	Class17		= '17',			// Rubber, gutta-percha, gum, asbestos, mica and goods made from these materials and not included in other classes; plastics in extruded form for use in manufacture; packing, stopping and insulating materials; flexible pipes, not of metal
	Class18		= '18',			// Leather and imitations of leather, and goods made of these materials and not included in other classes; animal skins, hides; trunks and traveling bags; umbrellas, parasols and walking sticks; whips, harness and saddlery
	Class19		= '19',			// Non-metallic building materials; monuments, not of metal
	Class20		= '20',			// Furniture, mirrors, picture frames; goods (not included in other classes) of wood, cork, reed, cane, wicker, horn, bone, ivory, whalebone, shell, amber, mother-of-pearl, meerschaum and substitutes for all these materials, or of plastics
	Class21		= '21',			// Household or kitchen utensils and containers; combs and sponges; brushes (except paint brushes); brush-making material
	Class22		= '22',			// Ropes, string, nets, tents, awnings, tarpaulins, sails, sacks and bags (not included in other classes); padding and stuffing materials (except of rubber or plastics); raw fibrous textile materials
	Class23		= '23',			// Yarns and threads, for textile use
	Class24		= '24',			// Fabrics; bed and table covers
	Class25		= '25',			// Clothing, footwear, headgear
	Class26		= '26',			// Fancy goods; buttons, snap fasteners, hooks and eyes, pins and needles; artificial flowers
	Class27		= '27',			// Carpets, rugs, mats and matting, linoleum and other materials for covering existing floors; wall hangings (non-textile)
	Class28		= '28',			// Games and playthings; gymnastic and sporting articles not included in other classes; decorations for Christmas trees
	Class29		= '29',			// Meats and processed foods; preserves; dried and cooked fruits and vegetables; jellies, jams, compotes; eggs, milk and milk products; edible oils and fats
	Class30		= '30',			// Staple foods; coffee, tea, cocoa, sugar, rice, tapioca, sago, artificial coffee; flour and preparations made from cereals, bread, pastry and confectionery, ices; honey, treacle; yeast, baking-powder; salt, mustard; vinegar, sauces (condiments); spices; ice
	Class31		= '31',			// Natural agricultural products; live animals; fresh fruits and vegetables; seeds, natural plants and flowers; foodstuffs for animals; malt
	Class32		= '32',			// Beers; mineral and aerated waters and other nonalcoholic drinks; fruit drinks and fruit juices; syrups and other preparations for making beverages
	Class33		= '33',			// Alcoholic beverages (except beers)
	Class34		= '34',			// Smokers' articles; matches
	Class35		= '35',			// Advertising; business management; business administration; office functions
	Class36		= '36',			// Insurance; financial affairs; monetary affairs; real estate affairs
	Class37		= '37',			// Building construction; repair; installation services
	Class38		= '38',			// Telecommunications
	Class39		= '39',			// Transportation and storage; packaging and storage of goods; travel arrangement
	Class40		= '40',			// Treatment of materials; rental of machinery, apparatus and equipment for use in industry, agriculture, forestry and building construction
	Class41		= '41',			// Education; providing of training; entertainment; sporting and cultural activities
	Class42		= '42',			// Scientific and technological services and research and design relating thereto; industrial analysis and research services; design and development of computer hardware and software
	Class43		= '43',			// Services for providing food and drink; temporary accommodation
	Class44		= '44',			// Medical services; veterinary services; hygienic and beauty care for human beings or animals; agriculture, horticulture and forestry services
	Class45		= '45'			// Legal services; security services for the protection of property and individuals; personal and social services rendered by others to meet the needs of individuals
}


/**------------------------------------------------------
 * Definitions & Configurations
 */
export const TM_NICE_CLASS_INFOS: Record<EnumTmNiceClass, ITmNiceClassInfo> = {
	[EnumTmNiceClass.Class1]: {
		code			: EnumTmNiceClass.Class1,
		niceType		: EnumTmNiceType.Goods,
		number			: '1',
		name			: 'Nice Class 1',
		categorization	: 'Chemicals for use in industry, science and photography, as well as in agriculture, horticulture and forestry; Unprocessed artificial resins, unprocessed plastics; Fire extinguishing and fire prevention compositions; Tempering and soldering preparations; Substances for tanning animal skins and hides; Adhesives for use in industry; Putties and other paste fillers; Compost, manures, fertilizers; Biological preparations for use in industry and science',
		podProducts		: [],
		tmViewValue		: '01'
	},
	[EnumTmNiceClass.Class2]: {
		code			: EnumTmNiceClass.Class2,
		niceType		: EnumTmNiceType.Goods,
		number			: '2',
		name			: 'Nice Class 2',
		categorization	: 'Paints, varnishes, lacquers; Preservatives against rust and against deterioration of wood; Colorants, dyes; Inks for printing, marking and engraving; Raw natural resins; Metals in foil and powder form for use in painting, decorating, printing and art',
		podProducts		: [],
		tmViewValue		: '02'
	},
	[EnumTmNiceClass.Class3]: {
		code			: EnumTmNiceClass.Class3,
		niceType		: EnumTmNiceType.Goods,
		number			: '3',
		name			: 'Nice Class 3',
		categorization	: 'Non-medicated cosmetics and toiletry preparations; Non-medicated dentifrices; Perfumery, essential oils; Bleaching preparations and other substances for laundry use; Cleaning, polishing, scouring and abrasive preparations',
		podProducts		: [],
		tmViewValue		: '03'
	},
	[EnumTmNiceClass.Class4]: {
		code			: EnumTmNiceClass.Class4,
		niceType		: EnumTmNiceType.Goods,
		number			: '4',
		name			: 'Nice Class 4',
		categorization	: 'Industrial oils and greases, wax; Lubricants; Dust absorbing, wetting and binding compositions; Fuels and illuminants; Candles and wicks for lighting',
		podProducts		: [],
		tmViewValue		: '04'
	},
	[EnumTmNiceClass.Class5]: {
		code			: EnumTmNiceClass.Class5,
		niceType		: EnumTmNiceType.Goods,
		number			: '5',
		name			: 'Nice Class 5',
		categorization	: 'Pharmaceuticals, medical and veterinary preparations; Sanitary preparations for medical purposes; Dietetic food and substances adapted for medical or veterinary use, food for babies; Dietary supplements for human beings and animals; Plasters, materials for dressings; Material for stopping teeth, dental wax; Disinfectants; Preparations for destroying vermin; Fungicides, herbicides',
		podProducts		: [],
		tmViewValue		: '05'
	},
	[EnumTmNiceClass.Class6]: {
		code			: EnumTmNiceClass.Class6,
		niceType		: EnumTmNiceType.Goods,
		number			: '6',
		name			: 'Nice Class 6',
		categorization	: 'Common metals and their alloys, ores; Metal materials for building and construction; Transportable buildings of metal; Non-electric cables and wires of common metal; Small items of metal hardware; Metal containers for storage or transport; Safes',
		podProducts		: ['Metal Wall Art'],
		tmViewValue		: '06'
	},
	[EnumTmNiceClass.Class7]: {
		code			: EnumTmNiceClass.Class7,
		niceType		: EnumTmNiceType.Goods,
		number			: '7',
		name			: 'Nice Class 7',
		categorization	: 'Machines, machine tools, power-operated tools; Motors and engines, except for land vehicles; Machine coupling and transmission components, except for land vehicles; Agricultural implements, other than hand-operated hand tools; Incubators for eggs; Automatic vending machines',
		podProducts		: [],
		tmViewValue		: '07'
	},
	[EnumTmNiceClass.Class8]: {
		code			: EnumTmNiceClass.Class8,
		niceType		: EnumTmNiceType.Goods,
		number			: '8',
		name			: 'Nice Class 8',
		categorization	: 'Hand tools and implements, hand-operated; Cutlery; Side arms, except firearms; Razors',
		podProducts		: [],
		tmViewValue		: '08'
	},
	[EnumTmNiceClass.Class9]: {
		code			: EnumTmNiceClass.Class9,
		niceType		: EnumTmNiceType.Goods,
		number			: '9',
		name			: 'Nice Class 9',
		categorization	: 'Scientific, research, navigation, surveying, photographic, cinematographic, audiovisual, optical, weighing, measuring, signalling, detecting, testing, inspecting, life-saving and teaching apparatus and instruments; Apparatus and instruments for conducting, switching, transforming, accumulating, regulating or controlling the distribution or use of electricity; Apparatus and instruments for recording, transmitting, reproducing or processing sound, images or data; Recorded and downloadable media, computer software, blank digital or analogue recording and storage media; Mechanisms for coin-operated apparatus; Cash registers, calculating devices; Computers and computer peripheral devices; Diving suits, divers\' masks, ear plugs for divers, nose clips for divers and swimmers, gloves for divers, breathing apparatus for underwater swimming; Fire-extinguishing apparatus',
		podProducts		: ['Mobile Accessories'],
		tmViewValue		: '09'
	},
	[EnumTmNiceClass.Class10]: {
		code			: EnumTmNiceClass.Class10,
		niceType		: EnumTmNiceType.Goods,
		number			: '10',
		name			: 'Nice Class 10',
		categorization	: 'Surgical, medical, dental and veterinary apparatus and instruments; Artificial limbs, eyes and teeth; Orthopaedic articles; Suture materials; Therapeutic and assistive devices adapted for persons with disabilities; Massage apparatus; Apparatus, devices and articles for nursing infants; Sexual activity apparatus, devices and articles',
		podProducts		: ['Face Masks'],
		tmViewValue		: '10'
	},
	[EnumTmNiceClass.Class11]: {
		code			: EnumTmNiceClass.Class11,
		niceType		: EnumTmNiceType.Goods,
		number			: '11',
		name			: 'Nice Class 11',
		categorization	: 'Apparatus and installations for lighting, heating, cooling, steam generating, cooking, drying, ventilating, water supply and sanitary purposes',
		podProducts		: [],
		tmViewValue		: '11'
	},
	[EnumTmNiceClass.Class12]: {
		code			: EnumTmNiceClass.Class12,
		niceType		: EnumTmNiceType.Goods,
		number			: '12',
		name			: 'Nice Class 12',
		categorization	: 'Vehicles; Apparatus for locomotion by land, air or water',
		podProducts		: [],
		tmViewValue		: '12'
	},
	[EnumTmNiceClass.Class13]: {
		code			: EnumTmNiceClass.Class13,
		niceType		: EnumTmNiceType.Goods,
		number			: '13',
		name			: 'Nice Class 13',
		categorization	: 'Firearms; Ammunition and projectiles; Explosives; Fireworks',
		podProducts		: [],
		tmViewValue		: '13'
	},
	[EnumTmNiceClass.Class14]: {
		code			: EnumTmNiceClass.Class14,
		niceType		: EnumTmNiceType.Goods,
		number			: '14',
		name			: 'Nice Class 14',
		categorization	: 'Precious metals and their alloys; Jewelry, precious and semi-precious stones; Horological and chronometric instruments',
		podProducts		: [],
		tmViewValue		: '14'
	},
	[EnumTmNiceClass.Class15]: {
		code			: EnumTmNiceClass.Class15,
		niceType		: EnumTmNiceType.Goods,
		number			: '15',
		name			: 'Nice Class 15',
		categorization	: 'Musical instruments; Music stands and stands for musical instruments; Conductors\' batons',
		podProducts		: [],
		tmViewValue		: '15'
	},
	[EnumTmNiceClass.Class16]: {
		code			: EnumTmNiceClass.Class16,
		niceType		: EnumTmNiceType.Goods,
		number			: '16',
		name			: 'Nice Class 16',
		categorization	: 'Paper and cardboard; Printed matter; Bookbinding material; Photographs; Stationery and office requisites, except furniture; Adhesives for stationery or household purposes; Drawing materials and materials for artists; Paintbrushes; Instructional and teaching materials; Plastic sheets, films and bags for wrapping and packaging; Printers\' type, printing blocks',
		podProducts		: ['Books'],
		tmViewValue		: '16'
	},
	[EnumTmNiceClass.Class17]: {
		code			: EnumTmNiceClass.Class17,
		niceType		: EnumTmNiceType.Goods,
		number			: '17',
		name			: 'Nice Class 17',
		categorization	: 'Unprocessed and semi-processed rubber, gutta-percha, gum, asbestos, mica and substitutes for all these materials; Plastics and resins in extruded form for use in manufacture; Packing, stopping and insulating materials; Flexible pipes, tubes and hoses, not of metal',
		podProducts		: [],
		tmViewValue		: '17'
	},
	[EnumTmNiceClass.Class18]: {
		code			: EnumTmNiceClass.Class18,
		niceType		: EnumTmNiceType.Goods,
		number			: '18',
		name			: 'Nice Class 18',
		categorization	: 'Leather and imitations of leather; Animal skins and hides; Luggage and carrying bags; Umbrellas and parasols; Walking sticks; Whips, harness and saddlery; Collars, leashes and clothing for animals',
		podProducts		: ['Cloth Bag'],
		tmViewValue		: '18'
	},
	[EnumTmNiceClass.Class19]: {
		code			: EnumTmNiceClass.Class19,
		niceType		: EnumTmNiceType.Goods,
		number			: '19',
		name			: 'Nice Class 19',
		categorization	: 'Materials, not of metal, for building and construction; Rigid pipes, not of metal, for building; Asphalt, pitch, tar and bitumen; Transportable buildings, not of metal; Monuments, not of metal',
		podProducts		: [],
		tmViewValue		: '19'
	},
	[EnumTmNiceClass.Class20]: {
		code			: EnumTmNiceClass.Class20,
		niceType		: EnumTmNiceType.Goods,
		number			: '20',
		name			: 'Nice Class 20',
		categorization	: 'Furniture, mirrors, picture frames; Containers, not of metal, for storage or transport; Unworked or semi-worked bone, horn, whalebone or mother-of-pearl; Shells; Meerschaum; Yellow amber',
		podProducts		: ['Pillows'],
		tmViewValue		: '20'
	},
	[EnumTmNiceClass.Class21]: {
		code			: EnumTmNiceClass.Class21,
		niceType		: EnumTmNiceType.Goods,
		number			: '21',
		name			: 'Nice Class 21',
		categorization	: 'Household or kitchen utensils and containers; Cookware and tableware, except forks, knives and spoons; Combs and sponges; Brushes, except paintbrushes; Brush-making materials; Articles for cleaning purposes; Unworked or semi-worked glass, except building glass; Glassware, porcelain and earthenware',
		podProducts		: ['Cups and Mugs'],
		tmViewValue		: '21'
	},
	[EnumTmNiceClass.Class22]: {
		code			: EnumTmNiceClass.Class22,
		niceType		: EnumTmNiceType.Goods,
		number			: '22',
		name			: 'Nice Class 22',
		categorization	: 'Ropes and string; Nets; Tents and tarpaulins; Awnings of textile or synthetic materials; Sails; Sacks for the transport and storage of materials in bulk; Padding, cushioning and stuffing materials, except of paper, cardboard, rubber or plastics; Raw fibrous textile materials and substitutes therefor',
		podProducts		: [],
		tmViewValue		: '22'
	},
	[EnumTmNiceClass.Class23]: {
		code			: EnumTmNiceClass.Class23,
		niceType		: EnumTmNiceType.Goods,
		number			: '23',
		name			: 'Nice Class 23',
		categorization	: 'Yarns and threads, for textile use',
		podProducts		: [],
		tmViewValue		: '23'
	},
	[EnumTmNiceClass.Class24]: {
		code			: EnumTmNiceClass.Class24,
		niceType		: EnumTmNiceType.Goods,
		number			: '24',
		name			: 'Nice Class 24',
		categorization	: 'Textiles and substitutes for textiles; Household linen; Curtains of textile or plastic',
		podProducts		: ['Shower Curtains'],
		tmViewValue		: '24'
	},
	[EnumTmNiceClass.Class25]: {
		code			: EnumTmNiceClass.Class25,
		niceType		: EnumTmNiceType.Goods,
		number			: '25',
		name			: 'Nice Class 25',
		categorization	: 'Clothing, footwear, headwear',
		podProducts		: ['Clothing'],
		tmViewValue		: '25'
	},
	[EnumTmNiceClass.Class26]: {
		code			: EnumTmNiceClass.Class26,
		niceType		: EnumTmNiceType.Goods,
		number			: '26',
		name			: 'Nice Class 26',
		categorization	: 'Lace, braid and embroidery, and haberdashery ribbons and bows; Buttons, hooks and eyes, pins and needles; Artificial flowers; Hair decorations; False hair',
		podProducts		: ['Buttons'],
		tmViewValue		: '26'
	},
	[EnumTmNiceClass.Class27]: {
		code			: EnumTmNiceClass.Class27,
		niceType		: EnumTmNiceType.Goods,
		number			: '27',
		name			: 'Nice Class 27',
		categorization	: 'Carpets, rugs, mats and matting, linoleum and other materials for covering existing floors; Wall hangings, not of textile',
		podProducts		: ['Bath Mats'],
		tmViewValue		: '27'
	},
	[EnumTmNiceClass.Class28]: {
		code			: EnumTmNiceClass.Class28,
		niceType		: EnumTmNiceType.Goods,
		number			: '28',
		name			: 'Nice Class 28',
		categorization	: 'Games, toys and playthings; Video game apparatus; Gymnastic and sporting articles; Decorations for Christmas trees',
		podProducts		: ['Puzzles'],
		tmViewValue		: '28'
	},
	[EnumTmNiceClass.Class29]: {
		code			: EnumTmNiceClass.Class29,
		niceType		: EnumTmNiceType.Goods,
		number			: '29',
		name			: 'Nice Class 29',
		categorization	: 'Meat, fish, poultry and game; Meat extracts; Preserved, frozen, dried and cooked fruits and vegetables; Jellies, jams, compotes; Eggs; Milk, cheese, butter, yogurt and other milk products; Oils and fats for food',
		podProducts		: [],
		tmViewValue		: '29'
	},
	[EnumTmNiceClass.Class30]: {
		code			: EnumTmNiceClass.Class30,
		niceType		: EnumTmNiceType.Goods,
		number			: '30',
		name			: 'Nice Class 30',
		categorization	: 'Coffee, tea, cocoa and substitutes therefor; Rice, pasta and noodles; Tapioca and sago; Flour and preparations made from cereals; Bread, pastries and confectionery; Chocolate; Ice cream, sorbets and other edible ices; Sugar, honey, treacle; Yeast, baking-powder; Salt, seasonings, spices, preserved herbs; Vinegar, sauces and other condiments; Ice [frozen water]',
		podProducts		: [],
		tmViewValue		: '30'
	},
	[EnumTmNiceClass.Class31]: {
		code			: EnumTmNiceClass.Class31,
		niceType		: EnumTmNiceType.Goods,
		number			: '31',
		name			: 'Nice Class 31',
		categorization	: 'Raw and unprocessed agricultural, aquacultural, horticultural and forestry products; Raw and unprocessed grains and seeds; Fresh fruits and vegetables, fresh herbs; Natural plants and flowers; Bulbs, seedlings and seeds for planting; Live animals; Foodstuffs and beverages for animals; Malt',
		podProducts		: [],
		tmViewValue		: '31'
	},
	[EnumTmNiceClass.Class32]: {
		code			: EnumTmNiceClass.Class32,
		niceType		: EnumTmNiceType.Goods,
		number			: '32',
		name			: 'Nice Class 32',
		categorization	: 'Beers; Non-alcoholic beverages; Mineral and aerated waters; Fruit beverages and fruit juices; Syrups and other preparations for making non-alcoholic beverages',
		podProducts		: [],
		tmViewValue		: '32'
	},
	[EnumTmNiceClass.Class33]: {
		code			: EnumTmNiceClass.Class33,
		niceType		: EnumTmNiceType.Goods,
		number			: '33',
		name			: 'Nice Class 33',
		categorization	: 'Alcoholic beverages, except beers; Alcoholic preparations for making beverages',
		podProducts		: [],
		tmViewValue		: '33'
	},
	[EnumTmNiceClass.Class34]: {
		code			: EnumTmNiceClass.Class34,
		niceType		: EnumTmNiceType.Goods,
		number			: '34',
		name			: 'Nice Class 34',
		categorization	: 'Tobacco and tobacco substitutes; Cigarettes and cigars; Electronic cigarettes and oral vaporizers for smokers; Smokers\' articles; Matches',
		podProducts		: [],
		tmViewValue		: '34'
	},
	[EnumTmNiceClass.Class35]: {
		code			: EnumTmNiceClass.Class35,
		niceType		: EnumTmNiceType.Services,
		number			: '35',
		name			: 'Nice Class 35',
		categorization	: 'Advertising; Business management, organization and administration; Office functions',
		podProducts		: [],
		tmViewValue		: '35'
	},
	[EnumTmNiceClass.Class36]: {
		code			: EnumTmNiceClass.Class36,
		niceType		: EnumTmNiceType.Services,
		number			: '36',
		name			: 'Nice Class 36',
		categorization	: 'Financial, monetary and banking services; Insurance services; Real estate affairs',
		podProducts		: [],
		tmViewValue		: '36'
	},
	[EnumTmNiceClass.Class37]: {
		code			: EnumTmNiceClass.Class37,
		niceType		: EnumTmNiceType.Services,
		number			: '37',
		name			: 'Nice Class 37',
		categorization	: 'Construction services; Installation and repair services; Mining extraction, oil and gas drilling',
		podProducts		: [],
		tmViewValue		: '37'
	},
	[EnumTmNiceClass.Class38]: {
		code			: EnumTmNiceClass.Class38,
		niceType		: EnumTmNiceType.Services,
		number			: '38',
		name			: 'Nice Class 38',
		categorization	: 'Telecommunications services',
		podProducts		: [],
		tmViewValue		: '38'
	},
	[EnumTmNiceClass.Class39]: {
		code			: EnumTmNiceClass.Class39,
		niceType		: EnumTmNiceType.Services,
		number			: '39',
		name			: 'Nice Class 39',
		categorization	: 'Transport; Packaging and storage of goods; Travel arrangement',
		podProducts		: [],
		tmViewValue		: '39'
	},
	[EnumTmNiceClass.Class40]: {
		code			: EnumTmNiceClass.Class40,
		niceType		: EnumTmNiceType.Services,
		number			: '40',
		name			: 'Nice Class 40',
		categorization	: 'Treatment of materials; Recycling of waste and trash; Air purification and treatment of water; Printing services; Food and drink preservation',
		podProducts		: [],
		tmViewValue		: '40'
	},
	[EnumTmNiceClass.Class41]: {
		code			: EnumTmNiceClass.Class41,
		niceType		: EnumTmNiceType.Services,
		number			: '41',
		name			: 'Nice Class 41',
		categorization	: 'Education; Providing of training; Entertainment; Sporting and cultural activities',
		podProducts		: [],
		tmViewValue		: '41'
	},
	[EnumTmNiceClass.Class42]: {
		code			: EnumTmNiceClass.Class42,
		niceType		: EnumTmNiceType.Services,
		number			: '42',
		name			: 'Nice Class 42',
		categorization	: 'Scientific and technological services and research and design relating thereto; Industrial analysis, industrial research and industrial design services; Quality control and authentication services; Design and development of computer hardware and software',
		podProducts		: ['Graphic Arts Designs'],
		tmViewValue		: '42'
	},
	[EnumTmNiceClass.Class43]: {
		code			: EnumTmNiceClass.Class43,
		niceType		: EnumTmNiceType.Services,
		number			: '43',
		name			: 'Nice Class 43',
		categorization	: 'Services for providing food and drink; Temporary accommodation',
		podProducts		: [],
		tmViewValue		: '43'
	},
	[EnumTmNiceClass.Class44]: {
		code			: EnumTmNiceClass.Class44,
		niceType		: EnumTmNiceType.Services,
		number			: '44',
		name			: 'Nice Class 44',
		categorization	: 'Medical services; Veterinary services; Hygienic and beauty care for human beings or animals; Agriculture, aquaculture, horticulture and forestry services',
		podProducts		: [],
		tmViewValue		: '44'
	},
	[EnumTmNiceClass.Class45]: {
		code			: EnumTmNiceClass.Class45,
		niceType		: EnumTmNiceType.Services,
		number			: '45',
		name			: 'Nice Class 45',
		categorization	: 'Legal services; Security services for the physical protection of tangible property and individuals; Personal and social services rendered by others to meet the needs of individuals',
		podProducts		: [],
		tmViewValue		: '45'
	}
};


//** Interfaces --------------------------------- */
export interface ITmNiceClassInfo {
	code			: EnumTmNiceClass;
	niceType		: EnumTmNiceType;
	number			: string;
	name			: string;
	categorization	: string;
	podProducts		: string[];					// used in pod: 6, 9, 10, 16, 18, 20, 21, 24, 25, 26, 42
	tmViewValue		: string;
}
