import JSZip, { JSZipFileOptions } from 'jszip';
import { Subject } from 'rxjs';
import { Util } from '@libs/utilities/util';

import { EnumZipOutputEncoding, IZipCompressOptions, IZipExportFile, IZipInputType, TypeZipInputType } from './zip-compress.interface';


/**------------------------------------------------------
 * Zip Compress Logic
 */
export class ZipCompressCore {

	//** Configurations */
	private readonly ZIP_EXPORT_CONFIG: JSZip.JSZipGeneratorOptions = {
		compression	: 'DEFLATE',
		platform	: 'UNIX'
	};


	/**------------------------------------------------------
	 * Compress To Zip File
	 */
	compressToZipBase64<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options: IZipCompressOptions | null): Promise<string[]> {
		return this.compressToZip<T>(fileList, EnumZipOutputEncoding.Base64, options) as Promise<string[]>;
	}

	compressToZipUint8array<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options: IZipCompressOptions | null): Promise<Uint8Array[]> {
		return this.compressToZip<T>(fileList, EnumZipOutputEncoding.Uint8Array, options) as Promise<Uint8Array[]>;
	}

	compressToZipBlob<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options: IZipCompressOptions | null): Promise<Blob[]> {
		return this.compressToZip<T>(fileList, EnumZipOutputEncoding.Blob, options) as Promise<Blob[]>;
	}


	/**------------------------------------------------------
	 * Zip Compression
	 */
	async compressToZip<T extends TypeZipInputType>(
		fileList: IZipExportFile<T>[], outputEncoding: EnumZipOutputEncoding, options: IZipCompressOptions | null): Promise<(string | Uint8Array | Blob)[]> {

		//0 - should all file be added to one zip
		if (!options?.chunkSize) {
			const zipExport: string | Uint8Array | Blob = await this.compressFilesToSingleZip(fileList, outputEncoding, options!.progress$ || new Subject<number>());
			return [zipExport];
		}

		//1 - chunk files into multiple zip files
		if (options.chunkSize <= 0) throw new Error(`ZipCompressCore => compressToZip => FATAL ERROR: the chunkSize can not be smaller then 0 (provided chunkSize: "${options.chunkSize}")`);

		//2 - create chunk of files and helper variables
		const chunkFileList	   : Array<IZipExportFile<T>[]> = Util.Array.chunk(fileList, options.chunkSize);
		const totalFileCount   : number = fileList.length;
		let progressedFileCount: number = 0;

		//3 - convert into zip
		const zipExports: (string | Uint8Array | Blob)[] = [];
		for (const chunkFiles of chunkFileList) {

			//a. update progress subject
			const trackProgress$: Subject<number> = new Subject<number>();
			trackProgress$.subscribe((chunkPercent: number) => {

				// was the progress subject defined?
				if (Util.Basic.isUndefined(options?.progress$)) return;

				// calculate the percent
				const progressedFilesInChunk: number = chunkFiles.length * (chunkPercent / 100);
				const totalFilesProcessed	: number = progressedFileCount + progressedFilesInChunk;

				// emit the progress on the subject
				const totalPercent: number = Util.Number.percent(totalFilesProcessed, totalFileCount);
				if (!options.progress$) options.progress$ = new Subject<number>();
				options.progress$.next(totalPercent);
			});

			//b. zip export data
			const zipExport: string | Uint8Array | Blob = await this.compressFilesToSingleZip(chunkFiles, outputEncoding, trackProgress$);

			//c. unsubscribe the progress & count up the percent
			trackProgress$.unsubscribe();
			progressedFileCount += chunkFiles.length;

			//c. update all list
			zipExports.push(zipExport);
		}

		//4 - return all zip export
		return zipExports;
	}


	/**------------------------------------------------------
	 * Zip Elements as Single Zip Files
	 */
	async compressFilesToSingleZip<T extends TypeZipInputType>(
		fileList: IZipExportFile<T>[], outputEncoding: EnumZipOutputEncoding, progress$: Subject<number>): Promise<string | Uint8Array | Blob> {

		//0 - create zip instance
		const zipFolder: JSZip = new JSZip();

		//1 - prepare the zip folder with the files
		for (const file of fileList) {
			const fileData: IZipInputType[T] | Promise<IZipInputType[T]> = file.data;
			zipFolder.file(file.path, fileData as any, file.options as JSZipFileOptions);
		}

		//2 - define the export format & options
		const exportOptions: JSZip.JSZipGeneratorOptions = {
			type: outputEncoding as any,
			...this.ZIP_EXPORT_CONFIG
		};

		//3 - export the zip file
		const zipExport: string | Uint8Array | Blob = await (zipFolder.generateAsync as any)(exportOptions, (metadata: IZipExportAsyncMeta) => {

			//a. was the progress subject defined?
			if (Util.Basic.isUndefined(progress$)) return;

			//b. emit the progress on the subject
			const percent: number = Util.Number.toNumber(metadata.percent.toFixed(2));
			progress$.next(percent);

		}) as string | Uint8Array | Blob;

		//4 - return the zip export promise
		return zipExport;
	}
}


//** Interfaces --------------------------------- */
interface IZipExportAsyncMeta {
	currentFile	: string;
	percent		: number;
}
