import { EnumAddition, EnumLanguage } from '@libs/constants';
import { Validator, ValidatorSchema } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_WHITELIST_LIMITS: IFlyingWhitelistConfig = {
	WORD_LENGTH		: 120,
	NOTES_LENGTH	: 400,
	MAX_LIST_SIZE	: 10000
};

interface IFlyingWhitelistConfig {
	WORD_LENGTH		: number;
	NOTES_LENGTH	: number;
	MAX_LIST_SIZE	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingWhitelistLanguage {
	All 	= EnumAddition.All,
	EN 		= EnumLanguage.EN,
	DE 		= EnumLanguage.DE,
	FR 		= EnumLanguage.FR,
	ES 		= EnumLanguage.ES,
	IT 		= EnumLanguage.IT,
	JA 		= EnumLanguage.JA
}


/**------------------------------------------------------
 * TM Whitelist Data Structures
 */
export interface IFlyingTmWhitelist {
	entries		: IFlyingTmWhitelistEntry[];		// is required and all elements have to be a valid 'ITmWhitelistEntry', the array can also be an empty array
}

export interface IFlyingTmWhitelistEntry {
	word		: string;					// should be a valid string and not empty
	note		: string;					// can be empty but has to be a type string
	language	: EnumFlyingWhitelistLanguage;	// has to be valid enum of "EnumWhitelistLanguage"
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingTmWhitelistSchema implements ValidatorSchema<IFlyingTmWhitelist> {
	entries		= Validator.Array().isMaxSize(FLYING_WHITELIST_LIMITS.MAX_LIST_SIZE)
					.inspectEach<IFlyingTmWhitelistEntry>(FlyingTmWhitelistEntrySchema);
}

export class FlyingTmWhitelistEntrySchema implements ValidatorSchema<IFlyingTmWhitelistEntry> {
	word		= Validator.String().isNotEmpty().isMaxLength(FLYING_WHITELIST_LIMITS.WORD_LENGTH);
	note		= Validator.String().isMaxLength(FLYING_WHITELIST_LIMITS.NOTES_LENGTH);
	language 	= Validator.Enum(EnumFlyingWhitelistLanguage);
}
