import { EnumKdpTrimSize } from '../../app-constants/platforms/kindle-direct-publishing/kdp-amazon.constants';


/**------------------------------------------------------
 * Manuscript Data
 */
export interface IKdpManuscript {
	flyId			: string;				// id of the design
	fileName		: string;
	fileUrl         : string;
	info      		: IManuscriptInfo;
	properties      : IManuscriptProperties;
	createdAt	    : Date;
	updatedAt	    : Date;
}


/**------------------------------------------------------
 * Manuscript Information
 */
export interface IManuscriptInfo {
	name    		: string;
	description    	: string;
	category    	: string;
}

export interface IManuscriptProperties {
	trimSize    	: EnumKdpTrimSize;		// example: 6"x9"
	pageCount		: number;
	originalSize   	: {
		height		: number;
		width		: number;
	};
}
