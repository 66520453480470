/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNotification {
	TmWatchlist   = 'tm_watchlist',
	EventReminder = 'event_reminder'
}

export enum EnumReminderInterval {
	OneWeek   	= '1_week',
	OneMonth   	= '1_month',
	ThreeMonth 	= '3_month'
}
