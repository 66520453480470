import { animate, animateChild, group, query, useAnimation, AnimationReferenceMetadata, AnimationQueryMetadata, AnimationGroupMetadata } from '@angular/animations';

import { INgAnimationOptions } from './state-animation.interface';


/**------------------------------------------------------
 * Animate Including Children
 */
export function animateIncludingChildren(easing: 'ease-in' | 'ease-out', options?: INgAnimationOptions): (AnimationQueryMetadata | AnimationGroupMetadata)[] {
	return [
		...options && options.animateChildren === 'before' ? [query('@*', animateChild(), { optional: true })] : [],
		group([
			group([query('@*', animateChild(), { optional: true }), animate(`{{duration}}ms {{delay}}ms ${easing}`)]),
			...!options?.animateChildren || options.animateChildren === 'together'
				? [query('@*', animateChild(), { optional: true })]
				: []
		]),
		...options && options.animateChildren === 'after' ? [query('@*', animateChild(), { optional: true })] : []
	];
}


/**------------------------------------------------------
 * Use Animation Including Children
 */
export function useAnimationIncludingChildren(animation: AnimationReferenceMetadata, options?: INgAnimationOptions): (AnimationQueryMetadata | AnimationGroupMetadata)[] {
	return [
		...options && options.animateChildren === 'before' ? [query('@*', animateChild(), { optional: true })] : [],
		group([
			useAnimation(animation),
			...!options?.animateChildren || options.animateChildren === 'together'
				? [query('@*', animateChild(), { optional: true })]
				: []
		]),
		...options && options.animateChildren === 'after' ? [query('@*', animateChild(), { optional: true })] : []
	];
}
