import { HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { Crypto } from '@libs/utilities/crypto';
import { SECURITY_EXCEPTION_ROUTES } from '@apps/flying/shared';

import { ENVIRONMENT } from '../../../../../../environments';


@Injectable({
	providedIn: 'root'
})
export class ContentDataService {

	//** Environment Variables */
	private readonly SECURITY_CDE				: boolean  = ENVIRONMENT.HTTP_SECURITY.CDE;			// CDE is "Contend Data Encryption"
	private readonly ENCRYPTION_KEY 			: string   = Util.Crypto.quickHash(ENVIRONMENT.HTTP_SECURITY.CDE_KEY);
	private readonly IGNORE_RESPONSE_ENCRYPTION	: string[] = SECURITY_EXCEPTION_ROUTES.IGNORE_RESPONSE_ENCRYPTION;

	constructor() {

		// check the environment variables
		if (Util.Basic.isUndefined(this.SECURITY_CDE)) throw new Error(`ContentDataService => constructor => FATAL ERROR: the environment variable of HTTP_SECURITY.CDE is undefined`);
		if (Util.String.isEmpty(this.ENCRYPTION_KEY))  throw new Error(`ContentDataService => constructor => FATAL ERROR: the environment variable of HTTP_SECURITY.ENCRYPTION_KEY is empty`);
	}


	/**------------------------------------------------------
	 * Encrypt / Decrypt the Body if CDE is active
	 */
	getContentEncryption(req: HttpRequest<any>, newHeaders: HttpHeaders): HttpRequest<any> {

		//0 - should the body be encrypted?
		const matchIgnoreRequestList: RegExp = new RegExp(`^(${SECURITY_EXCEPTION_ROUTES.IGNORE_REQUEST_DECRYPTION.join('|')})`);
		if (!this.SECURITY_CDE || matchIgnoreRequestList.test(req.url)) {
			return req.clone({
				headers		: newHeaders
			});
		}

		//1 - encrypt the data before sending it to backend
		const matchIgnoreResponseList: RegExp = new RegExp(`^(${SECURITY_EXCEPTION_ROUTES.IGNORE_RESPONSE_ENCRYPTION.join('|')})`);
		return req.clone({
			headers		: newHeaders.append('x-cde', 'true'),
			body		: this.encryptBody(JSON.stringify(req.body)),
			responseType: !matchIgnoreResponseList.test(req.url) ? 'text' : 'json'
		});
	}

	getContentDecryption(res: HttpResponse<any>): HttpResponse<any> {

		//0 - is the encryption active
		if (!this.SECURITY_CDE || res.headers.get('x-cde') !== 'true') return res;

		//1 - decrypt the body
		return res.clone({
			body: JSON.parse(this.decryptBody(res.body))
		});
	}

	getErrorDecryption(error: HttpErrorResponse): HttpErrorResponse {

		//0 - is the encryption active
		if (!this.SECURITY_CDE || error.headers.get('x-cde') !== 'true') return error;

		//1 - decrypt the error
		const errClone: HttpErrorResponse = new HttpErrorResponse({
			error		: JSON.parse(this.decryptBody(error.error)),
			headers		: error.headers,
			status		: error.status,
			statusText	: error.statusText,
			url			: error.url
		});
		return errClone;
	}


	/**------------------------------------------------------
	 * Encryption / Decryption Helpers
	 */
	private encryptBody(body: string) {
		return Crypto.SymmetricEncryption.encryptAes128(body, this.ENCRYPTION_KEY);
	}

	private decryptBody(body: string) {
		return Crypto.SymmetricEncryption.decryptAes128(body, this.ENCRYPTION_KEY);
	}
}
