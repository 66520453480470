import { EnumDevice, EnumOperatingSystem, EnumSystemTheme, IDeviceInfo, IOperatingSystemInfo, ISystemThemeInfo } from './device.interface';


/**------------------------------------------------------
 * Device Names
 */
export const DEVICE_INFO: Record<EnumDevice, IDeviceInfo>  = {
	[EnumDevice.Mobile]: {
		code	: EnumDevice.Mobile,
		name	: 'Mobile',
		icon	: 'mobile'
	},
	[EnumDevice.Tablet]: {
		code	: EnumDevice.Tablet,
		name	: 'Tablet',
		icon	: 'tablet'
	},
	[EnumDevice.Desktop]: {
		code	: EnumDevice.Desktop,
		name	: 'Desktop',
		icon	: 'desktop'
	}
};


/**------------------------------------------------------
 * OS Names
 */
export const OPERATING_SYSTEM_INFO: Record<EnumOperatingSystem, IOperatingSystemInfo> = {
	[EnumOperatingSystem.MacOS]: {
		code	: EnumOperatingSystem.MacOS,
		name	: 'MacOS',
		icon	: 'apple'
	},
	[EnumOperatingSystem.Windows]: {
		code	: EnumOperatingSystem.Windows,
		name	: 'Windows',
		icon	: 'windows'
	},
	[EnumOperatingSystem.Linux]: {
		code	: EnumOperatingSystem.Linux,
		name	: 'Linux',
		icon	: 'linux'
	},
	[EnumOperatingSystem.IOS]: {
		code	: EnumOperatingSystem.IOS,
		name	: 'IOS',
		icon	: 'apple'
	},
	[EnumOperatingSystem.IPadOS]: {
		code	: EnumOperatingSystem.IPadOS,
		name	: 'IPadOS',
		icon	: 'apple'
	},
	[EnumOperatingSystem.Android]: {
		code	: EnumOperatingSystem.Android,
		name	: 'Android',
		icon	: 'android'
	},
	[EnumOperatingSystem.Other]: {
		code	: EnumOperatingSystem.Other,
		name	: 'Other',
		icon	: 'computer'
	}
};


/**------------------------------------------------------
 * System Theme Names
 */
export const SYSTEM_THEME_INFO: Record<EnumSystemTheme, ISystemThemeInfo>  = {
	[EnumSystemTheme.Light]: {
		code	: EnumSystemTheme.Light,
		name	: 'Light'
	},
	[EnumSystemTheme.Dark]: {
		code	: EnumSystemTheme.Dark,
		name	: 'Dark'
	},
	[EnumSystemTheme.Unknown]: {
		code	: EnumSystemTheme.Unknown,
		name	: 'Unknown'
	}
};
