import { Util } from '@libs/utilities/util';
import { marked } from 'marked';


export class MarkdownParser {

	//** Configurations */
	private readonly DEFAULT_PARSER_OPTIONS: IMarkedOptions = {
		smartypants	: true,
		gfm			: true,
		breaks		: true,
		silent		: true
	};

	parse(template: string): string {

		//0 - test the input for invalid values
		if (Util.String.isEmpty(template)) throw new Error(`MarkdownParser => parse => FATAL ERROR: Provided template string is not valid.`);

		//1 - parse marked down
		try {
			const mdParser  : TypeMarkedParser = marked.setOptions(this.DEFAULT_PARSER_OPTIONS);
			const htmlOutput: string 		   = mdParser.parse(template);
			return htmlOutput;
		} catch (error) {
			throw new Error(`MarkdownParser => parse => FATAL ERROR: The markdown parsing failed, please check your markdown template if it contains anything that is not supported. (error: ${error?.toString()})`);
		}
	}
}


//** Interfaces --------------------------------- */
interface IMarkedOptions {
	smartypants	: boolean;
	gfm			: boolean;
	breaks		: boolean;
	silent		: boolean;
}

//** Types -------------------------------------- */
type TypeMarkedParser = typeof marked;
