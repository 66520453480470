import { EnumAmazonMarketplace } from '../../../app-constants';


/**------------------------------------------------------
 * Amazon Clothing Bulk Keywords Request API-Interfaces
 */
export interface ISocketLiveAmazonClothingKeywordRequest {
	keywords		: string[];
	marketplace		: EnumAmazonMarketplace;
	options         : ISocketLiveAmazonClothingKeywordOptions;
}

export interface ISocketLiveAmazonClothingKeywordOptions {
	fetchDB     	: boolean;						// if set to false it will not try to get the data from the DB
	scrape      	: boolean;						// if set to false it will not try to scrape
	includeEmpty	: boolean;						// fill up the rest with empty ones (the one not in DB and not scraped)
}
