import { ReplaySubject } from 'rxjs';
import { TypeResolve } from '@libs/constants';

import { EnumDesktopPermission } from '../device-notification.interface';


/**------------------------------------------------------
 * Notification Wrapper
 * > used to work with Notifications
 */
export class NotificationWrapper {

	//** Helper Subjects */
	private readonly onNotSupport$: ReplaySubject<void>	 	= new ReplaySubject<void>();
	private readonly onDenied$	  : ReplaySubject<void> 	= new ReplaySubject<void>();
	private readonly onShow$	  : ReplaySubject<Event> 	= new ReplaySubject<Event>();
	private readonly onClick$	  : ReplaySubject<Event> 	= new ReplaySubject<Event>();
	private readonly onClose$	  : ReplaySubject<Event> 	= new ReplaySubject<Event>();

	//** Main Notification Object */
	private notification		  : Notification = null!;

	constructor(
		message	 : string,
		info	?: NotificationOptions
	) {
		this.showNotification(message, info);
	}


	/**------------------------------------------------------
	 * Show a Notification
	 */
	private async showNotification(title: string, info?: NotificationOptions) {

		//0 - check whether browser supports notifications or not
		if (!('Notification' in window)) {
			this.onNotSupport$.next();
			return;
		}

		//1 - check if we have permission to post notifications
		if (!await this.isPermissionApproved()) {
			this.onDenied$.next();
			return;
		}

		//2 - create / post a new notification
		this.notification = new Notification(title, info);

		//3 - register to all events
		this.notification.onclick = (event: Event) => { this.onClick$.next(event); };
		this.notification.onshow  = (event: Event) => { this.onShow$.next(event); };
		this.notification.onclose = (event: Event) => { this.onClose$.next(event); };
	}


	/**------------------------------------------------------
	 * Notification Interaction Hooks
	 */
	onNotSupport(onNotSupportCallback: () => void): NotificationWrapper {
		this.onNotSupport$.subscribe(() => { onNotSupportCallback(); });
		return this;
	}

	onDenied(onDeniedCallback: () => void): NotificationWrapper {
		this.onDenied$.subscribe(() => { onDeniedCallback(); });
		return this;
	}

	onShow(onShowCallback: () => void): NotificationWrapper {
		this.onShow$.subscribe(() => { onShowCallback(); });
		return this;
	}

	onClick(onClickCallback: () => void): NotificationWrapper {
		this.onClick$.subscribe(() => { onClickCallback(); });
		return this;
	}

	onClose(onCloseCallback: () => void): NotificationWrapper {
		this.onClose$.subscribe(() => { onCloseCallback(); });
		return this;
	}


	/**------------------------------------------------------
	 * Notification Interaction Hooks
	 */
	onNotificationFallback(onFallbackCallback: () => void): NotificationWrapper {
		this.onNotSupport$.subscribe(() => { onFallbackCallback(); });
		this.onDenied$.subscribe(() => { onFallbackCallback(); });
		return this;
	}


	/**------------------------------------------------------
	 * Check User Permission for Notifications
	 */
	private async isPermissionApproved(): Promise<boolean> {

		//0 - check whether browser supports notifications or not
		if (!('Notification' in window)) throw new Error(`NotificationWrapper => isPermissionApproved => FATAL ERROR: The browser does not support Notifications`);

		//1 - create a notification
		switch (Notification.permission) {

			//a. can we post notifications?
			case EnumDesktopPermission.Granted:
				return true;

			//b. do we need to ask the user for permission?
			case EnumDesktopPermission.Default:
				const newPermission: EnumDesktopPermission = await this.requestNotificationPermission();
				if (newPermission !== EnumDesktopPermission.Granted) return false;
				return true;

			//c. did the user deny to show notifications?
			case EnumDesktopPermission.Denied:
				return false;

			default:
				throw new Error(`NotificationWrapper => isPermissionApproved => FATAL ERROR: Permission state of "${Notification.permission}" is not defined`);
		}
	}

	private requestNotificationPermission(): Promise<EnumDesktopPermission> {
		return new Promise((resolve: TypeResolve<EnumDesktopPermission>) => {
			Notification.requestPermission().then((permission: NotificationPermission) => {
				resolve(permission as EnumDesktopPermission);
			}).catch(() => {
				resolve(EnumDesktopPermission.Denied);
			});
		});
	}
}
