/**------------------------------------------------------
 * BSR constants
 */
export enum EnumBsrSorting {
	Bsr 		= 'bsr',
	Review		= 'review',
	Price		= 'price',
	Newest		= 'newest',
	Oldest		= 'oldest',
	Random		= 'random'
}
