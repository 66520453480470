import { EnumCountry } from '../country/country.interface';


/**------------------------------------------------------
 * Continent Definitions
 */
export enum EnumContinent {
	Africa 			= 'af',
	Antarctica 		= 'an',
	Asia 			= 'as',
	Europe 			= 'eu',
	NorthAmerica 	= 'na',
	SouthAmerica 	= 'sa',
	Oceania 		= 'oc'		// also named as Australia
}


/**------------------------------------------------------
 * Continent Infos Interface
 */
export interface IContinentInfo {
	code			: EnumContinent;
	name			: string;
	abbreviation	: string;
	countries		: EnumCountry[];
}
