import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_AI_TEMPLATE_LIMITS: IFlyingAiTemplateConfig = {
	NAME_LENGTH			: 120,
	NOTES_LENGTH		: 400,
	KEYWORDS_MAX		: 20,
	KEYWORD_LENGTH		: 120,
	BLACKLIST_MAX		: 20,
	CUSTOM_RULES_LENGTH	: 250,
	CUSTOM_RULES_MAX	: 10,
	MAX_LIST_SIZE		: 10000
};

interface IFlyingAiTemplateConfig {
	NAME_LENGTH			: number;
	NOTES_LENGTH		: number;
	KEYWORDS_MAX		: number;
	KEYWORD_LENGTH		: number;
	BLACKLIST_MAX		: number;
	CUSTOM_RULES_LENGTH	: number;
	CUSTOM_RULES_MAX	: number;
	MAX_LIST_SIZE		: number;
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingAiTemplateList {
	entries			: IFlyingAiTemplate[];
}

export interface IFlyingAiTemplate {
	name        	: string;
	notes       	: string;
	mainKeywords	: string[];
	fillKeywords	: string[];
	blacklist   	: string[];
	globalRules 	: string[];
	mbaRules		: string[];
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingAiTemplateListSchema implements ValidatorSchema<IFlyingAiTemplateList> {
	entries			= Validator.Array().isMaxSize(FLYING_AI_TEMPLATE_LIMITS.MAX_LIST_SIZE)
						.inspectEach<IFlyingAiTemplate>(FlyingAiTemplateSchema);
}

export class FlyingAiTemplateSchema implements ValidatorSchema<IFlyingAiTemplate> {
	name            = Validator.String().isNotEmpty().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.NAME_LENGTH);
	notes           = Validator.String().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.NOTES_LENGTH);
	mainKeywords    = Validator.Array().isNotEmpty().isMaxSize(FLYING_AI_TEMPLATE_LIMITS.KEYWORDS_MAX)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.KEYWORD_LENGTH).hasNoHtmlScripts().trim());
	fillKeywords    = Validator.Array().isMaxSize(FLYING_AI_TEMPLATE_LIMITS.KEYWORDS_MAX)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.KEYWORD_LENGTH).hasNoHtmlScripts().trim());
	blacklist       = Validator.Array().isMaxSize(FLYING_AI_TEMPLATE_LIMITS.BLACKLIST_MAX)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.KEYWORD_LENGTH).hasNoHtmlScripts().trim());
	globalRules    	= Validator.Array().isMaxSize(FLYING_AI_TEMPLATE_LIMITS.CUSTOM_RULES_MAX)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.CUSTOM_RULES_LENGTH).hasNoHtmlScripts().trim());
	mbaRules    	= Validator.Array().isMaxSize(FLYING_AI_TEMPLATE_LIMITS.CUSTOM_RULES_MAX)
						.each(Validator.String().isNotEmpty().isMaxLength(FLYING_AI_TEMPLATE_LIMITS.CUSTOM_RULES_LENGTH).hasNoHtmlScripts().trim());
}
