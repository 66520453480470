import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Change Detection Wrapper
 * ------------------------
 * > Change Detection API Wrapper. This helper is used in
 * > API calls in the frontend. With its help changes can
 * > be detected in data, and the api only called with the
 * > changed data.
 */
export class ChangeDetector<T extends object> {

	//** Helper to detect changes in listings */
	private changeDetectionHashes: Record<string, string> = {};

	//** Helper to Create Instance */
	static create<T extends object>(getIdentifier: (elements: T) => string): ChangeDetector<T> {
		return new ChangeDetector<T>(getIdentifier);
	}

	private constructor(
		private getIdentifier: (elements: T) => string
	) {}


	/**------------------------------------------------------
	 * Change Detection Functions
	 */
	executeOnChanges(elements: T[], execute: (elements: T[]) => void): void {

		//0 - find designs which have changes in the data
		const elementsWithChanges: T[] = this.getElementsWithChanges(elements);

		//1 - did any changes occur?
		if (elementsWithChanges.length === 0) return;

		//2 - revalidated the change hashes & return changed elements
		this.generateChangeDetectionHashes(elements);
		execute(elementsWithChanges);
	}

	generateChangeDetectionHashes(elements: T[]): void {
		for (const element of elements) {
			const elementId: string = this.getIdentifier(element);
			this.changeDetectionHashes[elementId] = Util.Crypto.quickHashObject(element);
		}
	}

	getElementsWithChanges(elements: T[]): T[] {

		//0 - get the elements that have changed
		const elementsWithChanges: T[] = elements.filter((element: T) => {

			//a. were there any changes in the listing?
			const elementId	  : string = this.getIdentifier(element);
			const previousHash: string = this.changeDetectionHashes[elementId];
			const currentHash : string = Util.Crypto.quickHashObject(element);
			if (previousHash === currentHash) return false;

			//b. if listing exists and was changed save it
			return true;
		});

		//1 - return the changed elements
		return elementsWithChanges;
	}
}
