import { Util } from '@libs/utilities/util';
import { EnumLanguage } from '@libs/constants';

import { JapaneseSegmenter } from './helper/japanese-segmenter';


/**------------------------------------------------------
 * Segmenter for different Languages
 */
export class TextSegmenter {

	//** Configurations */
	private readonly SUPPORTED_LANGUAGES: EnumLanguage[] = [EnumLanguage.JA];

	constructor(
		private japaneseSegmenter: JapaneseSegmenter
	) {}


	isLanguageSupported(language: EnumLanguage): boolean {
		return this.SUPPORTED_LANGUAGES.includes(language);
	}

	segmentText(text: string, language: EnumLanguage): string[] {

		//0 - check if the text is valid
		if (Util.String.isEmpty(text)) throw new Error(`TextSegmenter => segmentText => FATAL ERROR: provided text of "${text}" is not a valid string or is empty`);

		//1 - segment the provided text
		switch (language) {
			case EnumLanguage.JA:
				return this.japaneseSegmenter.segmentText(text);

			default:
				throw new Error(`TextSegmenter => getSegmenter => FATAL ERROR: no segmentation logic was defined for the language of "${language}"`);
		}
	}
}
