import { UtilUnicodeRegex } from './algorithms/unicode-regex';
import { UtilString } from './util-string';


/**------------------------------------------------------
 * Regex Utilities
 * ---------------
 * > Info: Containing all functionalities for dealing with
 * > regex expressions
 */
export class UtilRegex {

	/**------------------------------------------------------
	 * Regex Definitions
	 * > supporting: DE, FR, ES, IT
	 */
	readonly REGEX_SPECIAL_CHAR	  	: RegExp = /[^a-zA-Z0-9\säöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù]/ig;																// cspell:disable-line
	readonly REGEX_TRIM_SPECIAL_CHAR: RegExp = /^([^a-zA-Z0-9\säöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù]*)|([^a-zA-Z0-9\säöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù]*)$/ig;		// trimming the text from left and right | cspell:disable-line
	readonly REGEX_WHITESPACE	  	: RegExp = /\s+/g;
	readonly REGEX_CHECK_BASE64		: RegExp = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;			// https://stackoverflow.com/questions/7860392/determine-if-string-is-in-base64-using-javascript

	readonly TEXT_SPECIAL_CHARACTERS: string = `\\s_\\-\\.,;:%&\\$€@#\\?!\\(\\)\\[\\]\\{\\}\\+\\|`;

	//** Default Option Parameters */
	private readonly SPECIAL_CHAR_COMPARE_OPTIONS: IUtilSpecialCharCompareOptions 	= {
		ignoreCase		: true
	};

	//** Injections for Algorithm Extensions */
	private unicodeRegex: UtilUnicodeRegex = new UtilUnicodeRegex();

	constructor(
		private utilString: UtilString
	) {}


	/**------------------------------------------------------
	 * Regex Escape
	 */
	purifyString(searchText: string): string {
		this.checkString(searchText);
		return searchText.toLowerCase().replace(this.REGEX_SPECIAL_CHAR, '').replace(this.REGEX_WHITESPACE, ' ').trim();
	}
	trimSpecialChar(text: string): string {
		this.checkString(text);
		return text.replace(this.REGEX_TRIM_SPECIAL_CHAR, '').trim();
	}
	equalsIgnoreSpecialChar(specialCharText: string, compareText: string, options: IUtilSpecialCharCompareOptions = this.SPECIAL_CHAR_COMPARE_OPTIONS): boolean {

		//0 - validate the values
		this.checkEmpty(specialCharText);
		this.checkEmpty(compareText);

		//1 - prepare the text value
		specialCharText	= specialCharText.replace(/\s+/g, '_').trim();			// WORKAROUND: to be able to match the full file name as word
		compareText		= compareText.replace(/\s+/g, '_').trim();

		//2 - prepare the regex
		const preparedRegExString: string = specialCharText.replace(/[^\x20-\x7E]/gi, '.{1,2}');
		const regexCompareCheck	 : RegExp = new RegExp(`\\b${preparedRegExString}\\b`, options.ignoreCase ? 'i' : '');

		//3 - compare the strings
		return regexCompareCheck.test(compareText.trim());
	}


	/**------------------------------------------------------
	 * HTML Escape
	 */
	isValidHtml(text: string): boolean {
		const regex: RegExp = /<\/?[a-z][\s\S]*>/i;
		return regex.test(text);
	}
	hasHtml(text: string): boolean {
		return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(text);
	}
	hasHtmlScripts(html: string): boolean {
		return (html.match(/(?:<script\b[^<]*>)|(?:<\/script>)/ig) || []).length !== 0;
	}
	removeHtml(string: string): string {
		this.checkString(string);
		return string.replace(/(<([^>]+)>)/gi, '');
	}
	removeAndPurifyHtml(text: string): string {
		return text.replace(/<[^>]*>?/gm, ' ').replace(/&nbsp;/g, '').replace(/\s\s+/g, ' ').trim();
	}


	/**------------------------------------------------------
	 * Escape Html
	 * -----------
	 * > Code extracted from the "validator.js" lib, this is the library
	 * > that also the "express-validators" are using internally.
	 * > source: https://github.com/validatorjs/validator.js
	 */
	escapeHtml(string: string): string {
		return string
			.replace(/&/g, '&amp;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#x27;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/\//g, '&#x2F;')
			.replace(/\\/g, '&#x5C;')
			.replace(/`/g, '&#96;');
	}
	unescapeHtml(string: string): string {
		return string.replace(/&quot;/g, '"')
			.replace(/&#x27;/g, `'`)
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			.replace(/&#x2F;/g, '/')
			.replace(/&#x5C;/g, '\\')
			.replace(/&#96;/g, '`')
			.replace(/&amp;/g, '&');		// &amp; replacement has to be the last one to prevent bugs with intermediate strings containing escape sequences
	}


	/**------------------------------------------------------
	 * Regex Checks
	 */
	containsSpecialChar(text: string): boolean {
		this.checkString(text);
		return (text.match(this.REGEX_SPECIAL_CHAR) || []).length !== 0;
	}


	/**------------------------------------------------------
	 * Count Matches
	 */
	countMatches(pattern: RegExp, text: string): number {
		return ((text || '').match(pattern) || []).length;
	}


	/**------------------------------------------------------
	 * Regex Helper
	 */

	//** Escape for the Regex Expression */
	escapeRegExp(word: string): string {
		return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
	}

	isBase64Valid(data: string): boolean {
		this.checkEmpty(data);
		return this.REGEX_CHECK_BASE64.test(data);
	}

	createUnicodeRegExp(regexpStr: RegExp | string): RegExp {
		return this.unicodeRegex.createUnicodeRegExp(regexpStr);
	}


	/**------------------------------------------------------
	 * Regex text capture groups
	 * > Need to prefix a space on replace until we have a support for lookbehind operators
	 */
	textBoundCaptureGroup(text: string, flags?: string): RegExp {
		try {
			const textBoundRegex: RegExp = new RegExp(`(?<=^|[${this.TEXT_SPECIAL_CHARACTERS} ])(${text})(?=$|[${this.TEXT_SPECIAL_CHARACTERS} ])`, flags);
			return textBoundRegex;
		} catch {
			const textBoundRegex: RegExp = new RegExp(`(?![${this.TEXT_SPECIAL_CHARACTERS}])(${text})(?=$|[${this.TEXT_SPECIAL_CHARACTERS}])`, flags);
			return textBoundRegex;
		}
	}

	textStartBoundCaptureGroup(text: string, flags?: string): RegExp {
		const textStartBoundRegex: RegExp = new RegExp(`(?<=^|[${this.TEXT_SPECIAL_CHARACTERS} ])(${text})`, flags);
		return textStartBoundRegex;
	}

	textEndBoundCaptureGroup(text: string, flags?: string): RegExp {
		const textEndBoundRegex: RegExp = new RegExp(`(${text})(?=$|[${this.TEXT_SPECIAL_CHARACTERS} ])`, flags);
		return textEndBoundRegex;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private checkString(text: string) {
		if (!this.utilString.isString(text)) throw new Error(`UtilRegex => checkString => FATAL ERROR: provided text of "${text}" is not a valid string`);
	}
	private checkEmpty(text: string) {
		if (!this.utilString.isString(text)) throw new Error(`UtilRegex => checkEmpty => FATAL ERROR: provided text of "${text}" is not a valid string`);
		if (this.utilString.isEmpty(text))   throw new Error(`UtilRegex => checkEmpty => FATAL ERROR: provided text of "${text}" is empty`);
	}
}


//** Interfaces --------------------------------- */
export interface IUtilSpecialCharCompareOptions {
	ignoreCase	: boolean;
}
