import { RxJs } from '@libs/utilities/rxjs';

import { ZipExtractCore } from './core/zip-extract.core';
import { ZipCompressCore } from './core/zip-compress.core';
import { IZipExtractOptions } from './core/zip-extract.interface';
import { EnumZipOutputEncoding, IZipCompressOptions, IZipExportFile, TypeZipInputType } from './core/zip-compress.interface';
import { WorkerAssistantService, WorkerWrapper } from '../../worker-assistant';


export class ZipHandlerService {

	//** Core Zip Logic */
	private zipCompressCore	: ZipCompressCore = new ZipCompressCore();
	private zipExtractCore	: ZipExtractCore  = new ZipExtractCore();

	constructor(
		private workerAssistantService: WorkerAssistantService
	) {}


	/**------------------------------------------------------
	 * Checking Zip Formats
	 */
	isZipFormat(file: File): boolean {
		return this.zipExtractCore.isZipFormat(file);
	}

	isZipStandardSupported(file: File): boolean {
		return this.zipExtractCore.isZipStandardSupported(file);
	}


	/**------------------------------------------------------
	 * Extract Files form Zip
	 */
	extractFromZip(zipFile: File, options?: IZipExtractOptions): Promise<File[]> {
		return this.zipExtractCore.extractFromZip(zipFile, options);
	}

	async extractFromZipWorker(zipFile: File, options?: IZipExtractOptions): Promise<File[] | null> {

		//0 - create a new worker instance & extracting zip files
		const zipExtractWorker : WorkerWrapper = this.workerAssistantService.createSingleUseWorker(new Worker(new URL('libs/libraries/frontend/src/content-handlers/zip-handler/core/zip-extract.worker', import.meta.url), { type: 'module' }));
		const fileList	 	   : File[] | null = await zipExtractWorker.onProgress((percent: number) => options?.progress$?.next(percent)).process({ zipFile: zipFile, deepExtract: options?.deepExtract });

		//1 - return the result
		if (options?.progress$) RxJs.Subject.close(options?.progress$);
		return fileList;
	}


	/**------------------------------------------------------
	 * Compress Files to Zip
	 */
	compressToZipBase64<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options?: IZipCompressOptions): Promise<string[]> {
		return this.zipCompressCore.compressToZipBase64<T>(fileList, options!);
	}
	compressToZipUint8array<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options?: IZipCompressOptions): Promise<Uint8Array[]> {
		return this.zipCompressCore.compressToZipUint8array<T>(fileList, options!);
	}
	compressToZipBlob<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options?: IZipCompressOptions): Promise<Blob[]> {
		return this.zipCompressCore.compressToZipBlob<T>(fileList, options!);
	}


	/**------------------------------------------------------
	 * Compress Files to Zip with Worker
	 */
	compressToZipBase64Worker<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options: IZipCompressOptions | null): Promise<string[]> {
		return this.compressToZipWorker<T>(fileList, EnumZipOutputEncoding.Base64, options) as Promise<string[]>;
	}
	compressToZipUint8arrayWorker<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options: IZipCompressOptions | null): Promise<Uint8Array[]> {
		return this.compressToZipWorker<T>(fileList, EnumZipOutputEncoding.Uint8Array, options) as Promise<Uint8Array[]>;
	}
	compressToZipBlobWorker<T extends TypeZipInputType>(fileList: IZipExportFile<T>[], options: IZipCompressOptions | null): Promise<Blob[]> {
		return this.compressToZipWorker<T>(fileList, EnumZipOutputEncoding.Blob, options) as Promise<Blob[]>;
	}


	/**------------------------------------------------------
	 * Get all Files (Zip files will be extracted)
	 */
	async getAllFiles(originalFiles: FileList): Promise<File[]> {

		//0 - get file array
		const fileList: File[] = [];
		for (const file of Object.values(originalFiles)) {

			//a. is the current file a zip file (add simple file to list)?
			if (!this.isZipFormat(file)) {
				fileList.push(file);
				continue;
			}

			//b. extract all files from zip file
			const zippedFiles: File[] | null = await this.extractFromZipWorker(file);
			fileList.push(...zippedFiles!);
		}

		//1 - return file list
		return fileList;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private async compressToZipWorker<T extends TypeZipInputType>(
		fileList: IZipExportFile<T>[], outputEncoding: EnumZipOutputEncoding, options: IZipCompressOptions | null): Promise<(string | Uint8Array | Blob)[] | null> {

		//0 - create a new worker instance & compressing zip files
		const zipCompressWorker : WorkerWrapper = this.workerAssistantService.createSingleUseWorker(new Worker(new URL('libs/libraries/frontend/src/content-handlers/zip-handler/core/zip-compress.worker', import.meta.url), { type: 'module' }));
		const zipFileData	 	: Array<(string | Uint8Array | Blob)> | null = await zipCompressWorker.onProgress((percent: number) => options?.progress$?.next(percent)).process({ fileList, chunkSize: options?.chunkSize, outputEncoding });

		//1 - return the result
		if (options?.progress$) RxJs.Subject.close(options?.progress$);
		return zipFileData;
	}
}
