import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ErrorModalAssistant } from '../../../modals/error-modal/error-modal.assistant';
import { AuthSecurityService } from '../../../../services/shared/auth/auth-security/auth-security.service';
import { ENVIRONMENT } from '../../../../../environments';


@Injectable()
export class TimeGuard {

	//** Configurations */
	private readonly SERVER_URL: string = ENVIRONMENT.BACKEND.SERVER_URL;

	constructor(
		private errorModalAssistant 	: ErrorModalAssistant,
		private authSecurityService		: AuthSecurityService,
		private backend					: HttpBackend,
		private http					: HttpClient = null
	) {
		this.http = new HttpClient(this.backend);
	}

	canLoad(): Observable<boolean> | Promise<boolean> | boolean {

		// get timestamp from server
		return this.getTimeStamp().pipe(map((res: ITimestamp) => {
			this.authSecurityService.createTimeOffset(res.timestamp);
			return true;
		}), catchError(() => {
			this.errorModalAssistant.showServerError();
			return of(false);
		}));
	}

	getTimeStamp(): Observable<ITimestamp> {
		return this.http.get<ITimestamp>(`${this.SERVER_URL}timestamp`, {
			headers: new HttpHeaders()
		});
	}
}


//** Interfaces --------------------------------- */
interface ITimestamp {
	timestamp	: number;
}
