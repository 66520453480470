import type { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { EnumNgFaStyle } from '../../../../styling-components';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from '../../../../animations';
import { EnumNgPopoverSize, EnumNgPopoverBehavior, INgPopoverOptions, INgPopoverLink, INgPopoverButton } from './content-popover.interface';


@Component({
	selector		: 'ng-content-popover',
	templateUrl		: './content-popover.component.html',
	styleUrls		: ['./content-popover.component.scss'],
	changeDetection	: ChangeDetectionStrategy.OnPush,
	providers		: [
		NgbActiveModal
	],
	animations		: [
		fadeInOnEnterAnimation(),
		fadeOutOnLeaveAnimation()
	]
})
export class NgContentPopoverComponent implements OnInit, OnChanges, AfterViewInit {

	@ViewChild('popover') 			popover 		!: NgbPopover;
	@ViewChild('popoverContent')	popoverContent	!: ElementRef;

	@Input() title 		!: string;
	@Input() message 	!: TemplateRef<any> | string;
	@Input() options 	!: Partial<INgPopoverOptions>;
	@Input() delay 		 : number = 0;
	@Input() disabled 	!: boolean; // Optional Configuration

	@Output() readonly actionEvent: EventEmitter<string> = new EventEmitter();

	//** Popover Configuration */
	popoverConfig!		: INgPopoverConfig;
	isMessageTemplate	: boolean = false;
	hasPopoverContent	: boolean = false;
	templateMessage!	: TemplateRef<any>;

	//** Configuration */
	private readonly MAX_BUTTONS : number 		= 2;
	private readonly SIZE_CONFIG : INgSizeConfig 	= {
		small : {
			name		: EnumNgPopoverSize.Small,
			maxTitle 	: 25,
			maxMessage	: 250
		},
		medium : {
			name		: EnumNgPopoverSize.Medium,
			maxTitle 	: 50,
			maxMessage	: 750
		},
		large : {
			name		: EnumNgPopoverSize.Large,
			maxTitle 	: 70,
			maxMessage	: Number.MAX_SAFE_INTEGER
		}
	};

	constructor(
		private cdr: ChangeDetectorRef
	) {}


	/**------------------------------------------------------
	 * Lifecycle Hooks
	 */
	ngOnInit(): void {
		this.popoverConfig = this.getConfig(this.options);
		this.setMessage();
	}

	//** On change of Title or Message */
	ngOnChanges(): void {

		//0 - check popover config
		if (Util.Basic.isUndefined(this.popoverConfig)) return;

		//1 - check the title and message
		if (Util.String.isEmpty(this.title)) throw new Error(`NgInfoPopoverComponent => checkConfiguration => FATAL ERROR: title is required`);
		if (Util.String.isEmpty(this.message) && !(this.message instanceof TemplateRef)) throw new Error(`NgInfoPopoverComponent => checkConfiguration => FATAL ERROR: message is required`);

		//2 - set the size based on text length
		if (this.popoverConfig.autoSize && !(this.message instanceof TemplateRef)) {
			this.popoverConfig.size = this.SIZE_CONFIG.small.name;
			const message: string = Util.RegExp.removeHtml(this.message);
			if (message.length > this.SIZE_CONFIG.small.maxMessage)  this.popoverConfig.size = this.SIZE_CONFIG.medium.name;
			if (message.length > this.SIZE_CONFIG.medium.maxMessage) this.popoverConfig.size = this.SIZE_CONFIG.large.name;
			if (this.title.length > this.SIZE_CONFIG[this.popoverConfig.size].maxTitle) throw new Error(`NgInfoPopoverComponent => checkConfiguration => FATAL ERROR: max title size of "${this.SIZE_CONFIG[this.popoverConfig.size].maxTitle}" for "${this.popoverConfig.size}" exceeded (title length was ${this.title.length} / title used: "${this.title}")`);
			return;
		}

		//3 - set template message
		this.setMessage();
	}

	ngAfterViewInit(): void {

		//0 - check if component has child content
		this.hasPopoverContent = this.popoverContent.nativeElement.children.length > 0;

		//1 - update dom
		this.cdr.detectChanges();
	}


	/**------------------------------------------------------
	 * Handle the button/link click
	 */
	action(code: string): void {
		if (!Util.String.isEmpty(code)) this.actionEvent.emit(code);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private getConfig(options: Partial<INgPopoverOptions>): INgPopoverConfig {

		//0 - set initial values
		const config: INgPopoverConfig = {
			icon 		: {
				style 	: EnumNgFaStyle.Regular,
				name 	: 'circle-info'
			},
			behavior	: EnumNgPopoverBehavior.Default,
			buttons 	: [],
			size 		: EnumNgPopoverSize.Medium,
			autoSize	: true,
			triggers	: 'click',
			placement 	: 'auto',
			containerPosition : 'body' // previously it was '-'
		};

		//1 - set/check the icon
		if (!Util.Basic.isUndefined(options?.icon)) {
			if (Util.String.isEmpty(options.icon?.name)) throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: provided icon of "${options.icon?.name}" is empty`);
			config.icon = options.icon!;
		}

		//2 - set/check the buttons
		const buttons: Array<(INgPopoverLink | INgPopoverButton)[]> = options?.buttons!;
		if (!Util.Basic.isUndefined(buttons)) {
			if (!Util.Array.isArray(buttons)) 		throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: button must be in array`);
			if (buttons.length > this.MAX_BUTTONS) 	throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: maximum button length should be ${this.MAX_BUTTONS}`);
			for (const buttonGroup of buttons) {
				for (const button of buttonGroup) {
					if (Util.String.isEmpty(button.label))	throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: button label is required`);
					if (Util.String.isEmpty((button as INgPopoverButton).actionCode) && Util.String.isEmpty((button as INgPopoverLink).link)) throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: on of the following have to be provided: "action code" OR "link"`);
				}
			}
			config.buttons = buttons;
		}

		//3 - set/check the sizing
		const popoverSize: EnumNgPopoverSize = options?.size!;
		if (!Util.Basic.isUndefined(popoverSize)) {
			if (!Util.Enum.includes(EnumNgPopoverSize, popoverSize)) throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: ${popoverSize} is invalid (valid sizes are: ${Util.Enum.values(EnumNgPopoverSize)})`);

			config.autoSize = false;					// do not change size automatically if user defined it
			config.size 	= popoverSize;
		}

		//4 - set/check the behavior
		if (!Util.Basic.isUndefined(options?.behavior)) {
			switch (options.behavior) {
				case EnumNgPopoverBehavior.Default:
					config.triggers = 'click';
					break;
				case EnumNgPopoverBehavior.Tooltip:
					config.triggers = 'mouseenter:mouseleave';
					break;
				default:
					throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: provided behavior of "${options.behavior}" is not supported`);
			}
		}

		//5 - set/check the placement
		const placement: PlacementArray = options?.placement!;
		if (!Util.Basic.isUndefined(placement)) {
			if (Util.String.isEmpty(placement)) throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: provided placement of "${placement}" is empty`);
			config.placement = placement;
		}

		//6 - set/check the container
		const containerPosition: string = options?.containerPosition!;
		if (!Util.Basic.isUndefined(containerPosition)) {
			if (Util.String.isEmpty(containerPosition)) throw new Error(`NgInfoPopoverComponent => getConfig => FATAL ERROR: provided placement of "${containerPosition}" is empty`);
			config.containerPosition = containerPosition;
		}

		return config;
	}

	//** Set message */
	private setMessage() {

		//0 - check if message is string
		if (!(this.message instanceof TemplateRef)) return;

		//1 - set status if template ref
		this.isMessageTemplate = true;
		this.templateMessage = this.message as TemplateRef<any>;
	}
}


//** Interfaces --------------------------------- */
interface INgPopoverConfig extends INgPopoverOptions {
	autoSize : boolean;
	triggers : string;
}

interface INgSizeConfig {
	small 	: INgSize;
	medium 	: INgSize;
	large 	: INgSize;
}

interface INgSize {
	name		: EnumNgPopoverSize;
	maxTitle 	: number;
	maxMessage	: number;
}
