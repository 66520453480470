import { EnumAddition, IEnumAdditionInfo } from './enum-addition.interface';


/**------------------------------------------------------
 * Enum Extra Option Infos
 */
export const ENUM_ADDITION_INFOS: Record<EnumAddition, IEnumAdditionInfo> = {
	[EnumAddition.All]: {
		code	: EnumAddition.All,
		name	: 'All'
	},
	[EnumAddition.None]: {
		code	: EnumAddition.None,
		name	: 'None'
	}
};
