/**------------------------------------------------------
 * Ignored words in italy language
 */
export const STOP_WORDS_IT: string[] = [
	// cspell:disable
	'a',
	'adesso',
	'ai',
	'al',
	'alla',
	'allo',
	'allora',
	'altre',
	'altri',
	'altro',
	'anche',
	'ancora',
	'avere',
	'aveva',
	'avevano',
	'ben',
	'buono',
	'che',
	'chi',
	'cinque',
	'comprare',
	'con',
	'consecutivi',
	'consecutivo',
	'cosa',
	'cui',
	'da',
	'del',
	'della',
	'dello',
	'dentro',
	'deve',
	'devo',
	'di',
	'doppio',
	'due',
	'e',
	'ecco',
	'fare',
	'fine',
	'fino',
	'fra',
	'gente',
	'giu',
	'ha',
	'hai',
	'hanno',
	'ho',
	'il',
	'indietro',
	'invece',
	'io',
	'la',
	'lavoro',
	'le',
	'lei',
	'lo',
	'loro',
	'lui',
	'lungo',
	'ma',
	'me',
	'meglio',
	'molta',
	'molti',
	'molto',
	'nei',
	'nella',
	'no',
	'noi',
	'nome',
	'nostro',
	'nove',
	'nuovi',
	'nuovo',
	'o',
	'oltre',
	'ora',
	'otto',
	'peggio',
	'pero',
	'persone',
	'piu',
	'poco',
	'primo',
	'promesso',
	'qua',
	'quarto',
	'quasi',
	'quattro',
	'quello',
	'questo',
	'qui',
	'quindi',
	'quinto',
	'rispetto',
	'sara',
	'secondo',
	'sei',
	'sembra',
	'sembrava',
	'senza',
	'sette',
	'sia',
	'siamo',
	'siete',
	'solo',
	'sono',
	'sopra',
	'soprattutto',
	'sotto',
	'stati',
	'stato',
	'stesso',
	'su',
	'subito',
	'sul',
	'sulla',
	'tanto',
	'te',
	'tempo',
	'terzo',
	'tra',
	'tre',
	'triplo',
	'ultimo',
	'un',
	'una',
	'uno',
	'va',
	'vai',
	'voi',
	'volte',
	'vostro',
	'a',
	'abbastanza',
	'accidenti',
	'ad',
	'affinche',
	'agli',
	'ahime',
	'ahimÃ',
	'alcuna',
	'alcuni',
	'alcuno',
	'all',
	'alle',
	'altrimenti',
	'altrui',
	'anni',
	'anno',
	'ansa',
	'assai',
	'attesa',
	'avanti',
	'avendo',
	'avente',
	'aver',
	'avete',
	'avuta',
	'avute',
	'avuti',
	'avuto',
	'basta',
	'bene',
	'benissimo',
	'berlusconi',
	'brava',
	'bravo',
	'c',
	'casa',
	'caso',
	'cento',
	'certa',
	'certe',
	'certi',
	'certo',
	'chicchessia',
	'chiunque',
	'ci',
	'ciascuna',
	'ciascuno',
	'cima',
	'cio',
	'ciÃ',
	'cioe',
	'cioÃ',
	'circa',
	'citta',
	'cittÃ',
	'codesta',
	'codesti',
	'codesto',
	'cogli',
	'coi',
	'col',
	'colei',
	'coll',
	'coloro',
	'colui',
	'come',
	'concernente',
	'consiglio',
	'contro',
	'cortesia',
	'cos',
	'cosi',
	'cosÃ',
	'd',
	'dagli',
	'dai',
	'dal',
	'dall',
	'dalla',
	'dalle',
	'dallo',
	'davanti',
	'degli',
	'dei',
	'dell',
	'delle',
	'detto',
	'dice',
	'dietro',
	'dire',
	'dirimpetto',
	'dopo',
	'dove',
	'dovra',
	'dovrÃ',
	'dunque',
	'durante',
	'Ã',
	'ed',
	'egli',
	'ella',
	'eppure',
	'era',
	'erano',
	'esse',
	'essendo',
	'esser',
	'essere',
	'essi',
	'ex',
	'fa',
	'fatto',
	'favore',
	'fin',
	'finalmente',
	'finche',
	'forse',
	'fuori',
	'gia',
	'giÃ',
	'giacche',
	'giorni',
	'giorno',
	'gli',
	'gliela',
	'gliele',
	'glieli',
	'glielo',
	'gliene',
	'grande',
	'grazie',
	'gruppo',
	'i',
	'ieri',
	'improvviso',
	'in',
	'infatti',
	'insieme',
	'intanto',
	'intorno',
	'l',
	'lÃ',
	'li',
	'lontano',
	'macche',
	'magari',
	'mai',
	'male',
	'malgrado',
	'malissimo',
	'medesimo',
	'mediante',
	'meno',
	'mentre',
	'mesi',
	'mezzo',
	'mi',
	'mia',
	'mie',
	'miei',
	'mila',
	'miliardi',
	'milioni',
	'ministro',
	'mio',
	'moltissimo',
	'ne',
	'negli',
	'nel',
	'nell',
	'nelle',
	'nello',
	'nemmeno',
	'neppure',
	'nessuna',
	'nessuno',
	'niente',
	'non',
	'nondimeno',
	'nostra',
	'nostre',
	'nostri',
	'nulla',
	'od',
	'oggi',
	'ogni',
	'ognuna',
	'ognuno',
	'oppure',
	'ore',
	'osi',
	'ossia',
	'paese',
	'parecchi',
	'parecchie',
	'parecchio',
	'parte',
	'partendo',
	'peccato',
	'per',
	'perche',
	'perchÃ',
	'percio',
	'perciÃ',
	'perfino',
	'perÃ',
	'piedi',
	'pieno',
	'piglia',
	'piÃ',
	'po',
	'pochissimo',
	'poi',
	'poiche',
	'press',
	'prima',
	'proprio',
	'puo',
	'puÃ',
	'pure',
	'purtroppo',
	'qualche',
	'qualcuna',
	'qualcuno',
	'quale',
	'quali',
	'qualunque',
	'quando',
	'quanta',
	'quante',
	'quanti',
	'quanto',
	'quantunque',
	'quel',
	'quella',
	'quelli',
	'quest',
	'questa',
	'queste',
	'questi',
	'riecco',
	'salvo',
	'sarÃ',
	'sarebbe',
	'scopo',
	'scorso',
	'se',
	'seguente',
	'sempre',
	'si',
	'solito',
	'sta',
	'staranno',
	'stata',
	'state',
	'sua',
	'successivo',
	'sue',
	'sugli',
	'sui',
	'sull',
	'sulle',
	'sullo',
	'suo',
	'suoi',
	'tale',
	'talvolta',
	'ti',
	'tranne',
	'troppo',
	'tu',
	'tua',
	'tue',
	'tuo',
	'tuoi',
	'tutta',
	'tuttavia',
	'tutte',
	'tutti',
	'tutto',
	'uguali',
	'uomo',
	'vale',
	'varia',
	'varie',
	'vario',
	'verso',
	'vi',
	'via',
	'vicino',
	'visto',
	'vita',
	'volta',
	'vostra',
	'vostre',
	'vostri'
	// cspell:enable
];
