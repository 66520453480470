import { EmbeddedViewRef } from '@angular/core';


/**------------------------------------------------------
 * Embedded View Wrapper
 */
export class NgEmbeddedViewWrapper<T> {

	constructor(
		private embeddedViewRef: EmbeddedViewRef<T>
	) {}


	getEmbeddedViewRef(): EmbeddedViewRef<T> {
		return this.embeddedViewRef;
	}

	onDestroy(callback: Function): NgEmbeddedViewWrapper<T> {
		this.embeddedViewRef.onDestroy(() => callback());
		return this;
	}

	destroy(): NgEmbeddedViewWrapper<T> {
		this.embeddedViewRef.destroy();
		return this;
	}
}
