/**------------------------------------------------------
 * Deep Assign Objects
 */
export class UtilDeepAssign {

	//** Configurations / Constants */
	readonly DEFAULT_STRICT_MODE: boolean = true;

	constructor(
		private utilBasic: IUtilBasic
	) {}


	/**------------------------------------------------------
	 * Deep Assign Object/Array
	 */
	deepAssign<T>(target: T, source: T, strictMode: boolean = this.DEFAULT_STRICT_MODE): T {
		this.checkDeepAssignValues(target, source);
		return this.deepAssignAlgorithm(target, source, {
			strictMode  : strictMode,
			partial		: false
		});
	}
	deepAssignPartial<T>(target: T, source: Partial<T>, strictMode: boolean = this.DEFAULT_STRICT_MODE): T {
		this.checkDeepAssignValues(target, source as T);
		return this.deepAssignAlgorithm(target, source as T, {
			strictMode  : strictMode,
			partial		: true
		});
	}


	/**------------------------------------------------------
	 * Deep Assign Algorithm
	 */
	private checkDeepAssignValues<T>(target: T, source: T) {
		if (typeof target !== 'object') throw new Error(`DeepAssign => checkDeepAssignValues => FATAL ERROR: the provided target is not a valid object (typeof target: "${typeof target}" / value: "${target}")`);
		if (typeof source !== 'object') throw new Error(`DeepAssign => checkDeepAssignValues => FATAL ERROR: the provided source is not a valid object (typeof source: "${typeof source}" / value: "${source}")`);
	}
	private deepAssignAlgorithm<T>(target: T, source: T, options: IUtilDeepAssignOptions): T {

		//0 - check if types are compatible
		if (!this.utilBasic.isTypeEqual(target, source) && options.strictMode) throw new Error(`DeepAssign => deepAssign => FATAL ERROR: type of target (type: "${typeof target}" / isObject: ${this.utilBasic.isObject(target)} / isArray: ${this.utilBasic.isArray(target)}) and source (type: "${typeof source}" / isObject: ${this.utilBasic.isObject(source)} / isArray: ${this.utilBasic.isArray(source)}) are not matching`);

		//1 - assign all values
		const allKeys: string[] = Array.from(new Set([...Object.keys(target as any), ...Object.keys(source as any)])).reverse();		// revers required because of splice in arrays
		for (const key of allKeys) {
			const targetVal: unknown = (target as any)[key];
			const sourceVal: unknown = (source as any)[key];

			//a. check if value in target should be removed
			if (this.utilBasic.isDefined(targetVal) && this.utilBasic.isUndefined(sourceVal)) {
				if (options.partial && this.utilBasic.isObject(target)) continue;			// don't remove values from objects in "target" in partial mode (only from arrays)
				if (this.utilBasic.isObject(target)) delete (target as any)[key];			// eslint-disable-line @typescript-eslint/no-dynamic-delete
				if (this.utilBasic.isArray(target))  (target as any).splice(key, 1);
				continue;
			}

			//b. check if both have a value for the key
			if ((this.utilBasic.isObject(targetVal) !== this.utilBasic.isObject(sourceVal)) || (this.utilBasic.isArray(targetVal) !== this.utilBasic.isArray(sourceVal))) {
				if (this.utilBasic.isDefined(targetVal) && options.strictMode) throw new Error(`DeepAssign => deepAssign => FATAL ERROR: type of values for key of "${key}" not matching for target and source, target[${key}] (type: "${typeof targetVal}" / isObject: ${this.utilBasic.isObject(targetVal)} / isArray: ${this.utilBasic.isArray(targetVal)}) and source[${key}] (type: "${typeof sourceVal}" / isObject: ${this.utilBasic.isObject(sourceVal)} / isArray: ${this.utilBasic.isArray(sourceVal)})`);
				(target as any)[key] = this.utilBasic.deepCopy(sourceVal);
				continue;
			}

			//c. assign the value (if object call recursively)
			(target as any)[key] = this.getAssignedValue(targetVal, sourceVal, options);
		}
		return target;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getAssignedValue<T>(targetVal: T, sourceVal: T, options: IUtilDeepAssignOptions): T {
		switch (true) {

			//0 - copy the date object
			case targetVal && sourceVal && sourceVal instanceof Date:
				return new Date(sourceVal as unknown as Date) as unknown as T;

			//1 - copy a regex object
			case targetVal && sourceVal && sourceVal instanceof RegExp:
				return new RegExp(sourceVal as unknown as RegExp) as unknown as T;

			//2 - assign sub values recursively
			case targetVal && sourceVal && typeof targetVal === 'object' && typeof sourceVal === 'object':
				return this.deepAssignAlgorithm(targetVal, sourceVal, options);

			//3 - simple value (like number, string, ...)
			default:
				return sourceVal;
		}
	}
}


//** Interfaces --------------------------------- */
interface IUtilDeepAssignOptions {
	strictMode  : boolean;
	partial		: boolean;
}

interface IUtilBasic {
	isTypeEqual<T1, T2>(a: T1, b: T2): boolean;
	isPlainObject<T>(value: T): boolean;
	isObject<T>(value: T): boolean;
	isDefined<T>(value: T): boolean;
	isUndefined<T>(value: T): boolean;
	isArray<T>(value: T): boolean;
	deepCopy<T>(object: T): T;
}
