import { EnumSpreadshirtProduct, EnumSpreadshirtSorting } from '../../../../../../constants/research';
import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AbstractSearchLinks } from '../../../../data/search-links.abstract';
import { SPREADSHIRT_CONFIG_IT } from './spreadshirt-it.data';
import { ISpreadshirtSearchOptions, SPREADSHIRT_DEFAULT_OPTIONS } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Spreadshirt IT Search Links
 */
export class SpreadshirtItSearch extends AbstractSearchLinks<ISpreadshirtSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumSpreadshirtSorting>[] {
		return SPREADSHIRT_CONFIG_IT.sortings as ISearchParameterConfig<EnumSpreadshirtSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumSpreadshirtProduct>[] {
		return SPREADSHIRT_CONFIG_IT.products as ISearchParameterConfig<EnumSpreadshirtProduct>[];
	}

	constructor() {
		super(SPREADSHIRT_CONFIG_IT, SPREADSHIRT_DEFAULT_OPTIONS);
	}
}
