import { EnumTmOffice, EnumTmNiceClass, EnumTmStatus, EnumTmType } from '@domains/trademark/shared';


/**------------------------------------------------------
 * Trademark Config for Flying
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const FLYING_TRADEMARK_CONFIG = {
	TM_OFFICE		: [
		EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.US, EnumTmOffice.GB,
		EnumTmOffice.DE, EnumTmOffice.FR, EnumTmOffice.ES, EnumTmOffice.IT,
		EnumTmOffice.JP
	],
	TM_NICE_CLASS	: [
		EnumTmNiceClass.Class6,  EnumTmNiceClass.Class9,  EnumTmNiceClass.Class10,
		EnumTmNiceClass.Class16, EnumTmNiceClass.Class18, EnumTmNiceClass.Class20,
		EnumTmNiceClass.Class21, EnumTmNiceClass.Class24, EnumTmNiceClass.Class25,
		EnumTmNiceClass.Class26, EnumTmNiceClass.Class42
	],
	TM_STATUS		: [EnumTmStatus.Registered, EnumTmStatus.Filed, EnumTmStatus.Expired, EnumTmStatus.Ended],
	TM_TYPES		: [EnumTmType.Word]
};
