import { Util } from '@libs/utilities/util';


export class ValidatorName {

	//** Configurations */
	private readonly VALIDATE_NAME_REGEX		 : RegExp = /^[\p{L}\p{M}\p{S}'$\s-]+$/u;  // Update the regex to match Unicode characters, including those in non-Latin scripts
	private readonly MULTIPLE_SPACES_REGEX		 : RegExp = /\s{2,}/;
	private readonly LEADING_TRAILING_SPACE_REGEX: RegExp = /^\s|\s$/;


	/**------------------------------------------------------
	 * Full Name Validation
	 */
	isValidFullName(fullName: string): boolean {
		return this.validateFullName(fullName).length === 0;
	}

	validateFullName(fullName: string, nameInfo: string = 'Full name'): string[] {

		//0 - check if the name is defined
		const validationErrors: string[] = this.validateName(fullName, nameInfo);

		//1 - check if the name has at least two parts
		const nameParts: string[] = fullName.split(' ');
		if (nameParts.length < 2) validationErrors.push(`${nameInfo} should have at least two parts: first name and last name.`);

		//2 - check if the name has more than two parts
		return validationErrors;
	}


	/**------------------------------------------------------
	 * Name Validation
	 */
	isValidName(name: string): boolean {
		return this.validateName(name).length === 0;
	}

	validateName(name: string, nameInfo: string = 'Name'): string[] {

		//0 - check if the name is defined
		const validationErrors: string[] = [];
		if (Util.String.isEmpty(name)) return [`${nameInfo} is required.`];

		//1 - check if multiple spaces are used
		if (this.MULTIPLE_SPACES_REGEX.test(name)) validationErrors.push(`${nameInfo} cannot contain multiple spaces.`);

		//2 - check if the name has leading or trailing spaces
		if (this.LEADING_TRAILING_SPACE_REGEX.test(name)) validationErrors.push(`${nameInfo} cannot have leading or trailing spaces.`);

		//3 - check if the name is valid
		if (!this.VALIDATE_NAME_REGEX.test(name)) validationErrors.push(`${nameInfo} can only contain letters and spaces.`);

		//4 - return the validation errors
		return validationErrors;
	}
}
