import { EnumLanguage } from '@libs/constants';

import { INotesDesignInfo, INotesLicense, INotesResponsibilities } from '../pod-design/pod-listing.interface';
import { EnumKdpCategory, EnumKdpBookProduct, EnumKdpMarketplace, EnumKdpInteriorType, EnumKdpCoverFinish, EnumKdpPublish } from '../../app-constants/platforms/kindle-direct-publishing/kdp-amazon.constants';


/**------------------------------------------------------
 * Listing Structure
 */
export interface IKdpListing {
	flyId			: string;				// id of the design
	tags			: string[];				// max 3 tags
	kdp				: IKdpGlobal	   | null;
	notes 	 		: IKdpListingNotes | null;
}


/**------------------------------------------------------
 * KDP Listing Data
 */
export interface IKdpGlobal {
	text 			: IKdpText;
	books			: IKdpBookProduct[];
	options 		: IKdpOptions;
}

export interface IKdpText {
	language    	: EnumLanguage;
	title       	: string;
	subtitle    	: string;
	description 	: string;
	keywords    	: string[];
	author      	: IKdpAuthor;
}

export interface IKdpAuthor {
	prefix      	: string;
	fistName    	: string;
	middleName  	: string;
	lastName    	: string;
	suffix      	: string;
}

export interface IKdpBookProduct {
	bookCode		: EnumKdpBookProduct;
	marketplaces    : IKdpMarketplace[];
	categories      : EnumKdpCategory[];
	cover       : {
		finish      : EnumKdpCoverFinish;
		backColor   : string;
		textColor   : string;
	};
	interior    : {
		interiorType  : EnumKdpInteriorType;
		bleed         : boolean;
	};
}

export interface IKdpMarketplace {
	main            : boolean;
	market          : EnumKdpMarketplace;
	price           : number;
	extendedSails   : boolean;
}

export interface IKdpOptions {
	adultContent    : boolean;
	publish         : EnumKdpPublish;
}


/**------------------------------------------------------
 * Notes
 */
export interface IKdpListingNotes {
	note        	: string;
	license    		: INotesLicense;
	responsibilities: INotesResponsibilities;
	designInfos 	: INotesDesignInfo;
}
