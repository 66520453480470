import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Show the past time as time ago
 * ----------------
 * Format
 * > now, ... 5 min ago, ... 10 min ago
 * > ... 1 hour ago, ... 2 hours ago
 * > ... 1 day ago, ... 2 days ago
 * > ... 1 year ago, ... 2 years ago
 */
@Pipe({
	name : 'ngTimeAgo'
})
export class NgTimeAgoPipe implements PipeTransform {

	transform(value: string): string {

		//0 - get the current dates
		const pastDate	: Date = new Date(value);
		const nowDate	: Date = new Date();

		//1 - check if provided time is valid
		if (!Util.Date.isValidDate(pastDate))  throw new Error(`NgTimeAgoPipe => transform => FATAL ERROR: provided time is not valid`);
		if (nowDate < pastDate) 			   throw new Error(`NgTimeAgoPipe => transform => FATAL ERROR: provided time is in the future (it has to be in the past)`);

		//2 - define the times
		const seconds  : number	= Math.round(Math.abs((nowDate.getTime() - pastDate.getTime()) / 1000));
		const minutes  : number	= Math.round(Math.abs(seconds / 60));
		const hours    : number	= Math.round(Math.abs(minutes / 60));
		const days	   : number	= Math.round(Math.abs(hours / 24));
		const months   : number	= Math.round(Math.abs(days / 30.416));
		const years    : number	= Math.round(Math.abs(days / 365));

		//3 - show the matching text for the time
		if (seconds <= 45) 		return 'now';
		else if (seconds <= 90) return '1 min ago';
		else if (minutes <= 45) return `${minutes} min ago`;
		else if (minutes <= 90) return '1 hour ago';
		else if (hours <= 22) 	return `${hours} hours ago`;
		else if (hours <= 36) 	return '1 day ago';
		else if (days <= 25) 	return `${days} days ago`;
		else if (days <= 45) 	return '1 month ago';
		else if (days <= 345) 	return `${months} months ago`;
		else if (days <= 545) 	return '1 year ago';
		return `${years} years ago`;		// (days > 545)
	}
}
