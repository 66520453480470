import { Util } from '@libs/utilities/util';

import { ILoadDistributorRetryOptions, ILoadDistributorParams, ILoadDistributorExecutable, TypeExecuteFn, IRetryExecute } from './load-distributor.interface';


/**------------------------------------------------------
 * Load Distributor
 * ----------------
 * > Can be used to define multiple ways of execution and
 * > distributing the load among the options or instances
 */
export class LoadDistributor<T extends object> {

	//** Configurations */
	static readonly DEFAULT_RETRY: ILoadDistributorRetryOptions = {
		attempts		: 1,
		delayPeriod		: 1000,
		excludeOnFail	: false
	};

	//** Helper to Create Instance */
	static create<T extends object>(params: ILoadDistributorParams<T>): LoadDistributor<T> {
		const retryOptions: ILoadDistributorRetryOptions = Util.Function.assignOptions(this.DEFAULT_RETRY, params.retry);
		return new LoadDistributor<T>(params.options, retryOptions);
	}

	private constructor(
		private options		: ILoadDistributorExecutable<T>[],
		private retryOptions: ILoadDistributorRetryOptions
	) {}


	execute<R>(executeFn: TypeExecuteFn<T, R>): Promise<R> {
		return this.executeWithRetry(executeFn, {
			attempts	: this.retryOptions.attempts,
			excluded	: []
		});
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private async executeWithRetry<R>(executeFn: TypeExecuteFn<T, R>, retryExecute: IRetryExecute): Promise<R> {

		let optionIdentifier: string = '';
		try {
			//0 - get the right option first
			const option: ILoadDistributorExecutable<T> = this.getOption(retryExecute.excluded);
			optionIdentifier = option.identifier;

			//1 - try to execute
			return await executeFn(option.object);

		} catch (error) {

			// throw error if no retry left
			if (retryExecute.attempts <= 0) throw new Error(`LoadDistribution => executeWithRetry => FATAL ERROR: executing failed, no retries left (error: ${error})`);

			// retry to execute
			// > Lower the attempts, and exclude the one which was executed if required
			retryExecute.attempts--;
			if (this.retryOptions.excludeOnFail) retryExecute.excluded.push(optionIdentifier);
			const result: R = await this.executeWithRetry(executeFn, retryExecute);
			return result;
		}
	}

	private getOption(excluded: string[]): ILoadDistributorExecutable<T> {

		//0 - check that the total probability is not too high
		const validOptions		: ILoadDistributorExecutable<T>[] = this.options.filter((elem: ILoadDistributorExecutable<T>) => !excluded.includes(elem.identifier));
		const sumOfProbability	: number 						  = validOptions.reduce((sum: number, option: ILoadDistributorExecutable<T>) => sum + option.probability, 0);

		//1 - select randomly based on the probability
		// > Multiply by 100 is required as 'randomNumber' does not work with numbers < 0
		const randomNumber		 : number = Util.Random.randomNumber(0, sumOfProbability * 100) / 100;
		let cumulativeProbability: number = 0;
		for (const validOption of validOptions) {

			//a. cumulate probability to find the right one
			cumulativeProbability += validOption.probability;

			//b. did we found a match
			if (randomNumber < cumulativeProbability) return validOption;
		}

		//2 - code that should never be reached
		throw new Error(`LoadDistribution => getOption => FATAL ERROR: No match found, this should not be possible`);
	}
}
