import { ISecureServicesConfig } from '../services/security-services.config';


/**------------------------------------------------------
 * Frontend Security Config
 */
export enum EnumSecureHttpInjection {
	Config		= 'security_http_config'
}

/**------------------------------------------------------
 * Socket Security Config
 */
export interface ISecureHttpConfig extends ISecureServicesConfig {
	SERVER_URL		: string;
	HTTP_SECURITY	: {
		TIME_TOKEN	: boolean;
		ENCRYPTION	: boolean;
	};
}
