import { FLYING_APP_CONSTANTS } from '@apps-v2/flying/shared';

import { BASE64_SHOCKING_MASCOT, BASE64_SUPER_MECHANIC_MASCOT } from './error-modal-mascot.data';


/**------------------------------------------------------
 * Environment Links
 */
export const DISCORD_URL: string = FLYING_APP_CONSTANTS.SOCIAL_LINKS.DISCORD;


/**------------------------------------------------------
 * Enum Definitions
 */

//** Error Message Types Enum */
export enum EnumErrorModalType {
	NoConnection 		= 'no_connection',
	ServerError  		= 'server_error',
	MaintenanceError  	= 'maintenance_error',
	InvalidTime			= 'invalid_time'
}

//** Button action enum */
export enum EnumErrorModalButtonAction {
	Reload				= 'reload',
	Close				= 'close',
	Dismiss 			= 'dismiss'
}


/**------------------------------------------------------
 * Error Configurations
 */
export const ERROR_MESSAGES: IErrorMessage[] = [
	{
		type		: EnumErrorModalType.NoConnection,
		title		: 'No Connection',
		description : 'Oh, something went wrong. The request did not get to the server and was not processed. It seems like there was no valid internet connection. Please double-check your internet connection to make sure things are working and then try again. If this continues to happen please contact the support here.',
		buttons		: [
			{
				buttonText 	: 'Reload',
				buttonAction: EnumErrorModalButtonAction.Reload
			},
			{
				buttonText 	: 'Dismiss',
				buttonAction: EnumErrorModalButtonAction.Dismiss
			}
		],
		alt			: 'Connection Error',
		img			: BASE64_SHOCKING_MASCOT
	},
	{
		type		: EnumErrorModalType.ServerError,
		title		: 'Server Error',
		description : `Oh my god, something went really really wrong. The request was denied or did not get processed properly at the server. Please double-check your internet connection and try to reload the website. If this continues to happen even you tried these things please contact the <a href='/support'>support here</a>.`,
		buttons		: [
			{
				buttonText 	: 'Reload',
				buttonAction: EnumErrorModalButtonAction.Reload
			},
			{
				buttonText 	: 'Dismiss',
				buttonAction: EnumErrorModalButtonAction.Dismiss
			}
		],
		alt			: 'Server Error',
		img			: BASE64_SHOCKING_MASCOT
	},
	{
		type		: EnumErrorModalType.MaintenanceError,
		title		: 'Maintenance',
		description : `Hello, we're bringing a new update for you right now. The request was not processed because the server is updating right now. Please be patient with the update and try again in about 20 min. If this continues to happen please contact our support team <a target='_blank' href='${DISCORD_URL}'>here</a>.`,
		buttons		: [
			{
				buttonText 	: 'Reload',
				buttonAction: EnumErrorModalButtonAction.Reload
			},
			{
				buttonText 	: 'Dismiss',
				buttonAction: EnumErrorModalButtonAction.Dismiss
			}
		],
		alt			: 'Maintenance Error',
		img			: BASE64_SUPER_MECHANIC_MASCOT
	},
	{
		type		: EnumErrorModalType.InvalidTime,
		title		: 'Your clock is behind',
		description : `Can't establish a secure connection because your computer's date and time (${new Date().toDateString()}) are incorrect.`,
		buttons		: [
			{
				buttonText 	: 'Reload',
				buttonAction: EnumErrorModalButtonAction.Reload
			}
		],
		alt 		: 'Invalid Time Error',
		img 		: BASE64_SHOCKING_MASCOT
	}
];


//** Interfaces --------------------------------- */
export interface IErrorMessage {
	type		: EnumErrorModalType;
	img			: string;
	title		: string;
	description	: string;
	alt			: string;
	buttons		: Array<{
		buttonText		: string;
		buttonClass	   ?: string;
		buttonAction	: EnumErrorModalButtonAction;
	}>;
}
