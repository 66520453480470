import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { AddonPaddleScript } from './paddle.script';
import { AddonPaddleService } from './paddle.service';
import { AddonPageAddonsRenderer } from '../../shared/page-addons-renderer.helper';
import { EnumAddonPageAddonInjection } from '../../shared/page-addons.interface';


@NgModule()
export class AddonPaddleModule {

	static register(config: IAddonPaddleConfig): ModuleWithProviders<AddonPaddleModule> {
		return {
			ngModule	: AddonPaddleModule,
			providers	: [
				{
					provide		: EnumAddonPageAddonInjection.PaddleVendorId,
					useValue	: config.VENDOR_ID as string
				},
				AddonPageAddonsRenderer,
				AddonPaddleScript,
				{
					provide		: APP_INITIALIZER,
					deps		: [AddonPaddleScript],
					multi		: true,
					useFactory	: (paddleScript: AddonPaddleScript) => {
						return () => paddleScript.create();
					}
				},
				{
					provide		: AddonPaddleService,
					deps		: [DOCUMENT],
					useFactory	: () => new AddonPaddleService(document)
				}
			]
		};
	}

	static dummy(): ModuleWithProviders<AddonPaddleModule> {
		return {
			ngModule	: AddonPaddleModule,
			providers	: [
				{
					provide		: AddonPaddleService,
					useClass	: class extends AddonPaddleService {
						override openCheckout(paddleParams: any) {}
						override setRef(ref: string) {}
						override checkout(authParams: any, product: string, coupon: string | null = null) {}
						override getCallback(): BehaviorSubject<any | null> {
							return new BehaviorSubject<null>(null);
						}
					}
				}
			]
		};
	}
}


//** Interfaces --------------------------------- */
export interface IAddonPaddleConfig {
	VENDOR_ID	: string;
}
