import { TypeReject, TypeResolve } from '@libs/constants';

import { UtilArray } from './util-array';
import { UtilFunction } from './util-function';


/**------------------------------------------------------
 * Promise Utilities
 * -----------------
 * > Info: Handling advanced promise functionalities
 */
export class UtilPromise {

	//** Configurations */
	private readonly DEFAULT_REQUEST_TIMEOUT: number = 10000;
	private readonly DEFAULT_RETRY_OPTIONS	: IUtilRetryOptions = {
		attempts		: 3,
		delayPeriod		: 5000,
		onFailCallback	: (error: any, attempt: number) => {}
	};

	constructor(
		private utilArray	: UtilArray,
		private utilFunction: UtilFunction
	) {}


	//** Retry a Promise 'n' times */
	async retry<T>(request: () => Promise<T>, options?: Partial<IUtilRetryOptions>): Promise<T> {

		//0 - unify options
		const retryOptions: IUtilRetryOptions = this.utilFunction.assignOptions(this.DEFAULT_RETRY_OPTIONS, options);

		//1 - retry with attempts
		for (let attempt: number = 0; attempt <= retryOptions.attempts; attempt++) {
			try {
				const result: T = await request();
				return result;
			} catch (error) {

				// check if no attempt left
				if (attempt >= retryOptions.attempts) throw error;

				// delay & retry
				options?.onFailCallback?.(error, attempt + 1);
				await new Promise((resolveDelay: TypeResolve) => setTimeout(resolveDelay, retryOptions.delayPeriod));		// delay
			}
		}

		//2 - should not be possible
		throw new Error(`UtilPromise => retry => FATAL ERROR: end of function should not be possible`);
	}

	suppressErrors<T>(request: () => Promise<T>, requestTimeout: number = this.DEFAULT_REQUEST_TIMEOUT): Promise<T | null> {
		return new Promise((resolve: TypeResolve<T | null>) => {

			//0 - add timeout to avoid long waits
			setTimeout(() => { resolve(null); }, requestTimeout);

			//1 - request the data
			request().then((data: T) => { resolve(data); })
				.catch((error: any) => { resolve(null); });
		});
	}

	wrapInTimeout<T>(request: () => Promise<T>, timeout: number): Promise<T> {
		return Promise.race([
			request(),
			new Promise((resolve: TypeResolve, reject: TypeReject) => {
				setTimeout(() => {
					reject(new Error(`Timeout (timeout of ${timeout} ms exceeded)`));
				}, timeout);
			})
		]) as Promise<T>;
	}

	async flatMapAsync<T, U>(array: T[], callback: (value: T) => U[] | Promise<U[]>): Promise<U[]> {

		//0 - is the array empty
		if (this.utilArray.isEmpty(array)) return [];

		//1 - map each element
		// Note: Maps each element of the input array using the async callback and then flattens the result into a new array.
		// > Essentially, it's a combination of `map` and `flat` that supports asynchronous operations.
		const results: U[][] = await Promise.all(array.map((value: T) => callback(value)));
		return results.flat();
	}
}


//** Interfaces --------------------------------- */
export interface IUtilRetryOptions {
	attempts		: number;
	delayPeriod		: number;
	onFailCallback	: (error: any, attempt: number) => void;
}
