import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_DEFAULT_OPTIONS } from '../amazon-search.data';
import { AbstractAmazonSearch } from '../helpers/amazon-search.abstract';
import { AMAZON_CONFIG_NL } from './amazon-nl.data';
import { EnumAmazonSorting, EnumAmazonProduct, EnumAmazonMarketplace } from '../../../../../../constants/research/app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Amazon NL Search Links
 */
export class AmazonNlSearch extends AbstractAmazonSearch {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumAmazonSorting>[] {
		return AMAZON_CONFIG_NL.sortings as ISearchParameterConfig<EnumAmazonSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumAmazonProduct>[] {
		return AMAZON_CONFIG_NL.products as ISearchParameterConfig<EnumAmazonProduct>[];
	}

	constructor() {
		super(EnumAmazonMarketplace.NL, AMAZON_CONFIG_NL, AMAZON_DEFAULT_OPTIONS);
	}
}
