/**------------------------------------------------------
 * UTF-8 Encoding / Decoding
 * -------------------------
 * > Convert string into UTF-8	: https://stackoverflow.com/questions/20174280/nodejs-convert-string-into-utf-8
 * > Utf-8 to String Format		: https://stackoverflow.com/questions/24809826/convert-utf-8-data-into-the-proper-string-format
 * > Origin of Library			: https://github.com/mathiasbynens/punycode.js
 * > Git Repository				: https://github.com/mathiasbynens/utf8.js/blob/master/utf8.js
 * > NPM Package				: https://www.npmjs.com/package/utf8
 * > Deprecated Alternative		: decodeURIComponent(escape(badstr))
 */
export class UtilUtf8Encoder {


	/**------------------------------------------------------
	 * UTF-8 Decode
	 */
	decode(byteString: string): string {

		//0 - get byte array
		const byteArray: number[] = this.ucs2decode(byteString);

		//1 - initialize code points and byte index
		const codePoints: number[] = [];
		let   byteIndex	: number   = 0;

		//2 - get bytes
		while (byteIndex < byteArray.length) {

			//a. decoded byte
			const decodedByte: IUtilDecodedByte = this.decodeSymbol(byteArray, byteIndex);

			//b. update array and active index
			codePoints.push(decodedByte.byte);
			byteIndex = decodedByte.index; 			// WORKAROUND: Index is updating from sub function
		}

		//3 - return decoded string
		return this.ucs2encode(codePoints);
	}

	decodeFromUint8Array(uint8Array: Uint8Array): string {

		//0 - encode the Uint8Array to byteString
		let byteString: string = '';
		for (const byte of uint8Array) {
			byteString += String.fromCharCode(byte);
		}

		//1 - encode string to UTF-8
		const decodedText: string = this.decode(byteString);
		return decodedText;
	}

	/**------------------------------------------------------
	 * UTF-8 Encode
	 */
	encode(text: string): string {

		//0 - get code points from text
		const codePoints: number[] = this.ucs2decode(text);

		//1 - prepared byte string
		let byteString: string = '';
		for (const codePoint of codePoints) byteString += this.encodeCodePoint(codePoint);

		//2 - update byte string
		return byteString;
	}

	encodeToUint8Array(text: string): Uint8Array {

		//0 - encode string to UTF-8
		const utf8Str: string = this.encode(text);

		//1 - convert to Uint8Array format
		const bytes: Uint8Array = new Uint8Array(utf8Str.length);
		for (let i: number = 0; i < utf8Str.length; i++) {
			bytes[i] = utf8Str.charCodeAt(i); // We assume each character is only 1 byte
		}
		return bytes;
	}


	/**------------------------------------------------------
	 * Decoding Helpers
	 */
	private decodeSymbol(byteArray: number[], byteIndex: number): IUtilDecodedByte {

		//0 - read first byte
		const byte1: number = byteArray[byteIndex] & 0xFF;
		byteIndex++;

		//1 - byte sequence (no continuation bytes)
		if ((byte1 & 0x80) === 0) return { byte: byte1, index: byteIndex };

		//2 - 2-byte sequence
		if ((byte1 & 0xE0) === 0xC0) {

			//a. get decoded bytes and update index
			const decodedByte2: IUtilDecodedByte = this.readContinuationByte(byteArray, byteIndex);
			byteIndex = decodedByte2.index;

			//b. update code point
			const codePoint: number = ((byte1 & 0x1F) << 6) | decodedByte2.byte;
			if (codePoint >= 0x80) return { byte: codePoint, index: byteIndex };

			//c. show error if invalid decodeSymbol
			throw new Error(`Utf8Encoder => decodeSymbol => FATAL ERROR: Invalid continuation byte`);
		}

		//3 - 3-byte sequence (may include unpaired surrogates)
		if ((byte1 & 0xF0) === 0xE0) {

			//a. get 2 decoded bytes and update index
			const decodedByte2: IUtilDecodedByte = this.readContinuationByte(byteArray, byteIndex);
			byteIndex = decodedByte2.index;

			//b. get 3 decoded bytes and update index
			const decodedByte3: IUtilDecodedByte = this.readContinuationByte(byteArray, byteIndex);
			byteIndex = decodedByte3.index;

			//c. update code point
			const codePoint: number = ((byte1 & 0x0F) << 12) | (decodedByte2.byte << 6) | decodedByte3.byte;
			if (codePoint >= 0x0800) {
				this.checkScalarValue(codePoint);
				return { byte: codePoint, index: byteIndex };
			}

			//d. if nothing matches then it is invalid
			throw new Error(`Utf8Encoder => decodeSymbol => FATAL ERROR: Invalid continuation byte`);
		}

		//4 - 4-byte sequence
		if ((byte1 & 0xF8) === 0xF0) {

			//a. get 2 decoded bytes and update index
			const decodedByte2: IUtilDecodedByte = this.readContinuationByte(byteArray, byteIndex);
			byteIndex = decodedByte2.index;

			//b. get 3 decoded bytes and update index
			const decodedByte3: IUtilDecodedByte = this.readContinuationByte(byteArray, byteIndex);
			byteIndex = decodedByte3.index;

			//c. get 4 decoded bytes and update index
			const decodedByte4: IUtilDecodedByte = this.readContinuationByte(byteArray, byteIndex);
			byteIndex = decodedByte4.index;

			//d. update code point
			const codePoint: number = ((byte1 & 0x07) << 0x12) | (decodedByte2.byte << 0x0C) | (decodedByte3.byte << 0x06) | decodedByte4.byte;
			if (codePoint >= 0x010000 && codePoint <= 0x10FFFF) return { byte: codePoint, index: byteIndex };
		}

		//5 - if nothing matches then it is invalid
		throw new Error(`Utf8Encoder => decodeSymbol => FATAL ERROR: Invalid UTF-8 detected`);
	}

	private readContinuationByte(byteArray: number[], byteIndex: number): IUtilDecodedByte {

		//0 - get continuation byte
		const continuationByte: number = byteArray[byteIndex] & 0xFF;
		byteIndex++;

		//1 - return byte data
		if ((continuationByte & 0xC0) === 0x80) return { byte: continuationByte & 0x3F, index: byteIndex };

		//2 - throw error of invalid continuation byte
		throw new Error(`Utf8Encoder => readContinuationByte => FATAL ERROR: Invalid continuation byte`);
	}

	private ucs2encode(array: number[]): string {

		//0 - initialize output string
		let output: string = '';
		for (const item of array) {

			//a. set value
			let value: number = item;
			if (value > 0xFFFF) { // 65535
				value  -= 0x10000; // 65536
				output += String.fromCharCode(value >>> 10 & 0x3FF | 0xD800);
				value   = 0xDC00 | value & 0x3FF;
			}

			//b. update output string
			output += String.fromCharCode(value);
		}

		//1 - return output string
		return output;
	}


	/**------------------------------------------------------
	 * Encoding Helpers
	 */
	private encodeCodePoint(codePoint: number): string {

		//0 - is it a 1-byte sequence
		if ((codePoint & 0xFFFFFF80) === 0) {
			return String.fromCharCode(codePoint);
		}

		//1 - get symbol in byte sequence
		let symbol: string = '';
		switch (true) {

			//a. check 2-byte sequence
			case (codePoint & 0xFFFFF800) === 0:
				symbol = String.fromCharCode(((codePoint >> 6) & 0x1F) | 0xC0);
				break;

			//b. check 3-byte sequence
			case (codePoint & 0xFFFF0000) === 0:
				this.checkScalarValue(codePoint);
				symbol = String.fromCharCode(((codePoint >> 12) & 0x0F) | 0xE0);
				symbol += this.createByte(codePoint, 6);
				break;

			//c. check 4-byte sequence
			case (codePoint & 0xFFE00000) === 0:
				symbol = String.fromCharCode(((codePoint >> 18) & 0x07) | 0xF0);
				symbol += this.createByte(codePoint, 12);
				symbol += this.createByte(codePoint, 6);
				break;

			//d. show error on invalid byte
			default:
				throw new Error(`Utf8Encoder => encodeCodePoint => FATAL ERROR: Invalid byte`);
		}

		//2 - update string
		symbol += String.fromCharCode((codePoint & 0x3F) | 0x80);
		return symbol;
	}

	//** Create byte */
	private createByte(codePoint: number, shift: number) {
		return String.fromCharCode(((codePoint >> shift) & 0x3F) | 0x80);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private ucs2decode(text: string): number[] {

		//0 - add output and iterate over text and decode
		const output: number[] = [];
		for (let i: number = 0; i < text.length;) {

			//a. get value from char code
			const value: number = text.charCodeAt(i++);

			//b. decode the value
			if (value >= 0xD800 && value <= 0xDBFF && i < text.length) {

				// high surrogate, and there is a next character
				const extra: number = text.charCodeAt(i++);
				if ((extra & 0xFC00) === 0xDC00) { // low surrogate
					output.push(((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000);
					continue;
				}

				// unmatched surrogate; only append this code unit, in case the next
				// code unit is the high surrogate of a surrogate pair
				output.push(value);
				i--;
				continue;
			}

			//c. add the value to the output array
			output.push(value);
		}

		//1 - return output
		return output;
	}

	//** check scalar value */
	private checkScalarValue(codePoint: number) {
		if (codePoint >= 0xD800 && codePoint <= 0xDFFF) throw new Error(`Lone surrogate U+${codePoint.toString(16).toUpperCase()} is not a scalar value`);
	}
}


//** Interfaces --------------------------------- */
interface IUtilDecodedByte {
	byte 	: number;
	index	: number;
}
