/**------------------------------------------------------
 * Merch By Amazon Tagging Options
 */
export enum EnumMbaOptions {
	GeneralClearMba					= 'mba_general_clear_mba',
	GeneralClearAllMba				= 'mba_general_clear_all_mba',
	GeneralCopyMbaFrom	 			= 'mba_general_copy_mba_from',
	GeneralApplyMbaTo	 			= 'mba_general_apply_mba_to',
	GeneralSmartTransferToGlobal	= 'mba_general_smart_transfer_to_global',
	GeneralSmartTransferToDisplate	= 'mba_general_smart_transfer_to_displate',

	ProductCopyProductsFrom	 		= 'mba_product_copy_products_from',
	ProductCopyCurrentProductFrom  	= 'mba_product_copy_current_product_from',
	ProductApplyProductsTo 			= 'mba_product_apply_products_to',
	// ProductApplyToProducts 		= 'mba_product_apply_to_products',
	ProductClearAllProducts 		= 'mba_product_clear_all_products',
	ProductClearProduct 			= 'mba_product_clear_product',
	ProductClearProductTo 			= 'mba_product_clear_product_to',
	ProductLoadProfile 				= 'mba_product_load_profile',
	ProductLightProduct 			= 'mba_product_light_product',
	ProductDarkProduct 				= 'mba_product_dark_product',
	ProductLightListing 			= 'mba_product_light_listing',
	ProductDarkListing 				= 'mba_product_dark_listing',
	ProductResolveProblems 			= 'mba_product_resolve_problems',
	CopyFromProductHistory			= 'mba_product_copy_from_product_history',
	ApplyProductHistoryTo			= 'mba_product_apply_product_history_to',
	EnableAllMarkets				= 'mba_product_enable_all_markets',

	FitTypeCopyFitTypeFrom	 		= 'mba_fit_type_copy_fit_type_from',
	FitTypeApplyFitTypeTo 			= 'mba_fit_type_apply_fit_type_to',
	FitTypeCopyToAllProducts 		= 'mba_fit_type_copy_to_all_products',
	FitTypeSelectAll 				= 'mba_fit_type_select_all',
	FitTypeOnlyAdults 				= 'mba_fit_type_only_adults',

	PrintCopyPrintFrom	 			= 'mba_print_copy_print_from',
	PrintApplyPrintTo 				= 'mba_print_apply_print_to',
	// PrintApplyToProducts 		= 'mba_print_apply_to_products',

	ColorCopyColorFrom	 			= 'mba_color_copy_color_from',
	ColorApplyColorTo	 			= 'mba_color_apply_color_to',
	// ColorApplyToProducts 		= 'mba_color_apply_to_products',
	ColorClear 						= 'mba_color_clear',
	ColorSelectAll					= 'mba_color_select_all',
	ColorLoadProfile 				= 'mba_color_load_profile',
	ColorSelectLight 				= 'mba_color_select_light',
	ColorSelectDark 				= 'mba_color_select_dark',
	ColorSelectLightAllClothing 	= 'mba_color_select_light_all_clothing',
	ColorSelectDarkAllClothing 		= 'mba_color_select_dark_all_clothing',
	ColorAllProductColorsTo 		= 'mba_color_all_product_colors_to',

	BackgroundCopyBackgroundFrom	= 'mba_background_copy_background_from',
	BackgroundApplyBackgroundTo		= 'mba_background_apply_background_to',
	BackgroundCopyToAllAccessories	= 'mba_background_copy_to_all_accessories',
	BackgroundClear 				= 'mba_background_clear',
	BackgroundLoadProfile 			= 'mba_background_load_profile',

	MarketCopyMarketFrom	 		= 'mba_market_copy_market_from',
	MarketApplyMarketTo 			= 'mba_market_apply_market_to',
	// MarketApplyToProducts 		= 'mba_market_apply_to_products',
	MarketClear 					= 'mba_market_clear',
	MarketLoadProfile 				= 'mba_market_load_profile',
	MarketFirstToAll 				= 'mba_market_first_to_all',
	MarketSelectAllMarkets 			= 'mba_market_select_all_markets',
	MarketGlobalPrice 				= 'mba_market_global_price',
	MarketGlobalRoyalty 			= 'mba_market_global_royalty',
	MarketSetMinPrice 				= 'mba_market_set_min_price',
	MarketSetMaxPrice 				= 'mba_market_set_max_price',
	MarketMinPriceAllProducts		= 'mba_market_min_price_all_products',
	MarketMaxPriceAllProducts		= 'mba_market_max_price_all_products',
	MarketSetTo94 					= 'mba_market_set_to_94',
	MarketSetTo95 					= 'mba_market_set_to_95',
	MarketSetTo97 					= 'mba_market_set_to_97',
	MarketSetTo99 					= 'mba_market_set_to_99',

	BrandCopyBrandFrom	 			= 'mba_brand_copy_brand_from',
	BrandApplyBrandTo	 			= 'mba_brand_apply_brand_to',
	BrandUppercase					= 'mba_brand_uppercase',
	BrandGenerate 					= 'mba_brand_generate',
	BrandExtractFromGlobal 			= 'mba_brand_extract_from_global',
	BrandFromFileNames 				= 'mba_brand_from_file_names',
	BrandClearCurrentLanguage		= 'mba_brand_clear_current_language',
	BrandClearMbaText	 			= 'mba_brand_clear_mba_text',
	BrandCopyMbaTextFrom	 		= 'mba_brand_copy_mba_text_from',
	BrandApplyMbaTextTo	 			= 'mba_brand_apply_mba_text_to',

	TitleCopyTitleFrom	 		 	= 'mba_title_copy_title_from',
	TitleApplyTitleTo	 			= 'mba_title_apply_title_to',
	TitleUppercase					= 'mba_title_uppercase',
	TitleGenerate 					= 'mba_title_generate',
	TitleImportFromGlobal 			= 'mba_title_import_from_global',
	TitleImportFromDisplate 		= 'mba_title_import_from_displate',
	TitleFromFileNames 				= 'mba_title_from_file_names',
	TitleClearCurrentLanguage		= 'mba_title_clear_current_language',
	TitleClearMbaText	 			= 'mba_title_clear_mba_text',
	TitleCopyMbaTextFrom	 		= 'mba_title_copy_mba_text_from',
	TitleApplyMbaTextTo	 			= 'mba_title_apply_mba_text_to',

	Bullet1CopyBullet1From	 		= 'mba_bullet1_copy_bullet1_form',
	Bullet1ApplyBullet1To 			= 'mba_bullet1_apply_bullet1_to',
	Bullet1LoadBullet1Suggestion 	= 'mba_bullet1_load_bullet1_suggestion',
	Bullet1LoadBullet2Suggestion 	= 'mba_bullet1_load_bullet2_suggestion',
	Bullet1Generate 				= 'mba_bullet1_generate',
	Bullet1FromBrandAndTitle 		= 'mba_bullet1_from_brand_title',

	Bullet2CopyBullet2From 		 	= 'mba_bullet2_copy_bullet2_from',
	Bullet2ApplyBullet2To 			= 'mba_bullet2_apply_bullet2_to',
	Bullet2LoadBullet1Suggestion 	= 'mba_bullet2_load_bullet1_suggestion',
	Bullet2LoadBullet2Suggestion 	= 'mba_bullet2_load_bullet2_suggestion',
	Bullet2Generate 				= 'mba_bullet2_generate',
	Bullet2FromBrandAndTitle 		= 'mba_bullet2_from_brand_title',

	DescCopyDescFrom	 		 	= 'mba_desc_copy_desc_from',
	DescApplyDescTo 				= 'mba_desc_apply_desc_to',
	DescLoadSuggestion 				= 'mba_desc_load_suggestion',
	DescLoadShortSuggestion 		= 'mba_desc_load_short_suggestion',
	DescGenerate 					= 'mba_desc_generate',
	DescFromMbaListing 				= 'mba_desc_from_mba_listing',
	DescFromMbaListingBulk 			= 'mba_desc_from_mba_listing_bulk',
	DescImportFromGlobal 			= 'mba_desc_import_from_global',
	DescImportFromDisplate 			= 'mba_desc_import_from_displate',

	AvailabilityCopyAvailabilityFrom = 'mba_availability_copy_availability_from',
	AvailabilityApplyAvailabilityTo	= 'mba_availability_apply_availability_to',

	PublishCopyPublishFrom 			= 'mba_publish_copy_publish_from',
	PublishApplyPublishTo			= 'mba_publish_apply_publish_to'
}
