import { Subscriber } from 'rxjs';


/**------------------------------------------------------
 * SSE Request Method
 */
export enum EnumSecureHttpRequestMethod {
	Get 		= 'GET',			// NOTE: need to be UPPERCASE for http requests, do not change!!!
	Post 		= 'POST',
	Put 		= 'PUT',
	Delete 		= 'DELETE',
	Patch 		= 'PATCH',
	Head 		= 'HEAD',
	Options 	= 'OPTIONS'
}


/**------------------------------------------------------
 * Interfaces
 */
export interface ISecureSseRequestOptions {
	method 	 	: EnumSecureHttpRequestMethod;
	headers	 	: Record<string, string>;
	body	   ?: string | object;
}

export interface ISecureProcessText<T> {
	done		: boolean;
	value		: Uint8Array | undefined;
	observer	: Subscriber<T | null>;
	reader		: ReadableStreamDefaultReader<Uint8Array>;
}
