/* eslint-disable no-console, @typescript-eslint/no-dynamic-delete */
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Mock Local Storage
 * ------------------
 * > Info: Containing all functionalities for mocking the
 * > read and write into the local storage.
 */
export class MockLocalStorage {

	//** Storage for Mockup Data */
	private mockLocalStorage: TypeLocalStorage = {};


	/**------------------------------------------------
	 * Save & Load from Local Storage
	 */
	setItem<T extends object | string | number | boolean | Date>(key: string, data: T): void {

		//0 - log the info
		console.log('-------------------------');
		console.log(`LocalStorage Save Item`);
		console.log(` => save (key: ${key}): `, data, `\n`);

		//1 - set the data
		this.mockLocalStorage[key] = data;
	}

	getItem<T extends object | string | number | boolean | Date>(key: string): T | undefined {

		//0 - try to load the value
		// eslint-disable-next-line no-undefined
		const value: T | undefined = Util.Object.isKeyValid(key) ? this.mockLocalStorage[key] as T : undefined;

		//1 - log the info
		console.log('-------------------------');
		console.log(`LocalStorage Load Item`);
		console.log(` => load (key: ${key}): `, value, `\n`);

		//2 - return the loaded value or undefined
		return value;
	}


	/**------------------------------------------------------
	 * Remove Element
	 */
	removeItem(key: string): void {

		//0 - log the info
		console.log('-------------------------');
		console.log(`LocalStorage Remove Item`);
		console.log(` => key: `, key, `\n`);

		//1 - if key exists remove it from local storage
		if (Util.Object.isKeyValid(key)) delete this.mockLocalStorage[key];
	}


	/**------------------------------------------------------
	 * Log Current State
	 */
	log(): TypeLocalStorage {

		//0 - log the info
		console.log('-------------------------');
		console.log(`LocalStorage Logging State`);
		console.dir(this.mockLocalStorage, { depth: null });
		console.log(`\n`);

		//1 - return the storage data
		return this.mockLocalStorage;
	}


	/**------------------------------------------------------
	 * Initialize & Reset
	 */
	initialize(data: TypeLocalStorage): void {
		console.log('-------------------------');
		console.log(`LocalStorage Initialize\n`);
		this.mockLocalStorage = data;
	}

	reset(): void {
		console.log('-------------------------');
		console.log(`LocalStorage Reset\n`);
		this.mockLocalStorage = {};
	}
}


//** Interfaces --------------------------------- */
export type TypeLocalStorage = Record<string, object | string | number | boolean | Date>;
