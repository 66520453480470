/**------------------------------------------------------
 * Logger for Testings
 * -------------------
 * > This dummy logger is essentially empty and does not do
 * > anything. It is used for unit testing as in unit tests
 * > we don't want to have logging, especially not logging
 * > into local files like by the file logger, so this dummy
 * > can be injected and used.
 */
export class DummyLogger {
	log  (...messages: unknown[]): void {}
	warn (...messages: unknown[]): void {}
	error(...messages: unknown[]): void {}
}

export class DummyFileLogger extends DummyLogger {
	setProgressStartTime(): void { }
	logProgressByIndex(index: number, max: number, ...messages: unknown[]): void { }
	logProgressByPercent(progressPercent: number, ...messages: unknown[]): void { }
}
