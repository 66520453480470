// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Symmetric Signatures
 */
export class CryptoSymmetricSignature {


	/**------------------------------------------------------
	 * HMAC-SHA256
	 * -----------
	 * > HMAC-SHA256 Generator 1: https://www.devglan.com/online-tools/hmac-sha256-online
	 * > HMAC-SHA256 Generator 2: https://codebeautify.org/hmac-generator
	 * -----------
	 * > HMAC-SHA256 (Hash-based Message Authentication Code with the SHA-256 hash function)
	 * > is primarily used for message authentication and integrity verification, rather
	 * > than being classified strictly as a hashing or signing algorithm.
	 */
	signHmacSha256(data: string, secret: string): string {

		//0 - secret should never be empty
		if (Util.String.isEmpty(secret)) throw new Error(`SymmetricSignature => signHMAC_SHA256 => FATAL ERROR: the secret can not be empty`);

		//1 - prepare the algorithm configuration
		const hmac: forge.hmac.HMAC = forge.hmac.create();
		hmac.start('sha256', secret);
		hmac.update(forge.util.encodeUtf8(data));

		//2 - create the signature
		const signature: string = hmac.digest().toHex();
		return signature;
	}

	verifyHmacSha256(data: string, signatureValue: string, secret: string): boolean {
		const checkSignatureValue: string = this.signHmacSha256(data, secret);
		return checkSignatureValue === signatureValue;
	}


	/**------------------------------------------------------
	 * HMAC-SHA512
	 * -----------
	 * > HMAC-SHA512 Generator 1: https://www.devglan.com/online-tools/hmac-sha512-online
	 * > HMAC-SHA512 Generator 2: https://codebeautify.org/hmac-generator
	 * -----------
	 * > HMAC-SHA512 (Hash-based Message Authentication Code with the SHA-512 hash function)
	 * > is primarily used for message authentication and integrity verification, rather
	 * > than being classified strictly as a hashing or signing algorithm.
	 */
	signHmacSah512(data: string, secret: string): string {

		//0 - secret should never be empty
		if (Util.String.isEmpty(secret)) throw new Error(`SymmetricSignature => signHMAC_SHA512 => FATAL ERROR: the secret can not be empty`);

		//1 - prepare the algorithm configuration
		const hmac: forge.hmac.HMAC = forge.hmac.create();
		hmac.start('sha512', secret);
		hmac.update(forge.util.encodeUtf8(data));

		//2 - create the signature
		const signature: string = hmac.digest().toHex();
		return signature;
	}

	verifyHmacSha512(data: string, signatureValue: string, secret: string): boolean {
		const checkSignatureValue: string = this.signHmacSah512(data, secret);
		return checkSignatureValue === signatureValue;
	}
}
