import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { NgModalWrapper } from './modal-assistant.wrapper';
import { EnumNgModalSize } from './modal-assistant.interface';


@Injectable({
	providedIn: 'root'
})
export class NgModalAssistantService {

	//** Configurations */
	private readonly DEFAULT_MODAL_CONFIG: NgbModalOptions = {
		centered	: true,
		keyboard	: true,
		backdrop	: 'static',
		size		: EnumNgModalSize.Large,
		injector	: null!,
		windowClass : 'modal-custom-sizing'
	};

	//** Tracking Modal References */
	private openModalList: NgbModalRef[] = [];

	constructor(
		private modalService : NgbModal
	) {}


	/**------------------------------------------------------
	 * Open Simplified modal
	 */
	openSmall	  		<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { size: EnumNgModalSize.Small }); }
	openMedium	  		<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { size: EnumNgModalSize.Medium }); }
	openLarge	  		<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { size: EnumNgModalSize.Large }); }
	openExtraLarge		<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { size: EnumNgModalSize.ExtraLarge }); }

	//** Locked Modals will Not close on Escape */
	openLockedSmall	  	<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { keyboard: false, size: EnumNgModalSize.Small }); }
	openLockedMedium	<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { keyboard: false, size: EnumNgModalSize.Medium }); }
	openLockedLarge	  	<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { keyboard: false, size: EnumNgModalSize.Large }); }
	openLockedExtraLarge<T>(componentDefinition: T): NgModalWrapper { return this.openModal<T>(componentDefinition, { keyboard: false, size: EnumNgModalSize.ExtraLarge }); }


	/**------------------------------------------------------
	 * Open Custom modal
	 * > Note: these will be used later, do not remove them!!!
	 */
	open<T>(componentDefinition: T, modalOptions: Partial<NgbModalOptions> = {}): NgModalWrapper {
		return this.openModal<T>(componentDefinition, modalOptions);
	}

	openSingle<T>(componentDefinition: T, modalOptions: Partial<NgbModalOptions> = {}): NgModalWrapper {

		//0 - don't open modal multiple times
		if (this.hasOpenModals()) throw new Error(`NgModalAssistantService => openSingle => FATAL ERROR: modal is already open`);

		//1 - open the modal
		return this.openModal<T>(componentDefinition, modalOptions);
	}


	/**------------------------------------------------------
	 * Close all open modals
	 */
	closeAll(): NgModalAssistantService {

		//0 - close all modals
		for (const modal of this.openModalList) {

			//a. does the modal exists / can be closed
			// > "_backdropCmptRef" is defined if the modal is open / active
			if (!Util.Basic.isDefined(modal?.['_backdropCmptRef'])) continue;	// eslint-disable-line @typescript-eslint/dot-notation

			//b. close the open modal
			modal.close();
		}

		//1 - reset the list
		this.openModalList = [];
		return this;
	}

	dismissAll(reason?: string): NgModalAssistantService {
		this.modalService.dismissAll(reason);
		return this;
	}


	/**------------------------------------------------------
	 * Helpers
	 */
	hasOpenModals(): boolean {
		return this.modalService.hasOpenModals();
	}


	/**------------------------------------------------------
	 * Create / Open the Modal
	 */
	private openModal<T>(componentDefinition: T, modalOptions: Partial<NgbModalOptions> = {}): NgModalWrapper {

		//0 - define the configuration for the modal
		const modalConfig: NgbModalOptions = {
			centered	: Util.Basic.fallbackValue(modalOptions.centered, this.DEFAULT_MODAL_CONFIG.centered),
			keyboard	: Util.Basic.fallbackValue(modalOptions.keyboard, this.DEFAULT_MODAL_CONFIG.keyboard),
			backdrop	: Util.Basic.fallbackValue(modalOptions.backdrop, this.DEFAULT_MODAL_CONFIG.backdrop),
			size		: Util.Basic.fallbackValue(modalOptions.size, 	  this.DEFAULT_MODAL_CONFIG.size),
			injector	: Util.Basic.fallbackValue(modalOptions.injector, this.DEFAULT_MODAL_CONFIG.injector),
			windowClass : this.DEFAULT_MODAL_CONFIG.windowClass
		};

		//1 - create & open the modal
		const modalRef: NgbModalRef = this.modalService.open(componentDefinition, modalConfig);

		//2 - add to list & return wrapper
		this.openModalList.push(modalRef);
		return new NgModalWrapper(modalRef);
	}
}
