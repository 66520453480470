import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { FlyingDesignSchema, IFlyingDesign } from '../../../interfaces';


/**------------------------------------------------------
 * Constants
 */
export const FLYING_DESIGN_MANAGEMENT_EDIT_CONFIG: IFlyingDesignManagementEditConfig = {
	MAX_EDIT_DESIGNS: 500
};

interface IFlyingDesignManagementEditConfig {
	MAX_EDIT_DESIGNS: number;
}


/**------------------------------------------------------
 * Request
 */
export interface IFlyingUpdateDesignsListingRequest {
	listings	: Pick<IFlyingDesign, 'flyId' | 'listing'>[];
}

export interface IFlyingUpdateDesignsUploadsRequest {
	uploads		: Pick<IFlyingDesign, 'flyId' | 'uploads'>[];
}

export interface IFlyingEditDesignsRequest {
	flyIds		: string[];
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingUpdateDesignsListingRequestSchema implements ValidatorSchema<IFlyingUpdateDesignsListingRequest> {
	listings 	= Validator.Array().inspectEach({
		flyId	: new FlyingDesignSchema().flyId,
		listing : new FlyingDesignSchema().listing
	}).isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_EDIT_CONFIG.MAX_EDIT_DESIGNS);
}

export class FlyingUpdateDesignsUploadsRequestSchema implements ValidatorSchema<IFlyingUpdateDesignsUploadsRequest> {
	uploads 	= Validator.Array().inspectEach({
		flyId	: new FlyingDesignSchema().flyId,
		uploads : new FlyingDesignSchema().uploads
	}).isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_EDIT_CONFIG.MAX_EDIT_DESIGNS);
}

export class FlyingEditDesignsRequestSchema implements ValidatorSchema<IFlyingEditDesignsRequest> {
	flyIds 		= Validator.Array().each(new FlyingDesignSchema().flyId)
		.isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_EDIT_CONFIG.MAX_EDIT_DESIGNS);
}
