import { ZipHandlerService } from './zip-handler.service';
import { WorkerAssistantService } from '../../worker-assistant/worker-assistant.service';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class ZipHandlerServiceFactory {

	static create(): ZipHandlerService {
		return new ZipHandlerService(
			new WorkerAssistantService()
		);
	}
}
