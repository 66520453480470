import { Util } from '@libs/utilities/util';

import { ExcelSheetHelper } from './excel-sheet.helper';
import { IImportExcelData, IImportSheetCellValue, IImportSheetData, IImportSheetRow } from '../helper/excel-import.interface';


/**------------------------------------------------------
 * Excel Data Wrapper
 */
export class ExcelSheetWrapper {

	constructor(
		protected excelData			: IImportExcelData,
		protected excelSheetHelper	: ExcelSheetHelper			// injected into the creator, and passed down to this instance
	) {
		Util.Basic.deepFreezeObject(this.excelData);
	}


	/**------------------------------------------------------
	 * Sheet Checks
	 */
	hasAnySheet(): boolean {
		return this.excelData.sheets.length > 0;
	}

	hasMultipleSheets(): boolean {
		return this.excelData.sheets.length > 1;
	}

	getSheetCount(): number {
		return this.excelData.sheets.length;
	}

	getRowCount(sheetIndex: number = 0): number {
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		return this.excelData.sheets[sheetIndex].rows.length;
	}


	/**------------------------------------------------------
	 * Duplicate Rows Checks
	 */
	hasSheetDuplicateRows(sheetIndex: number = 0): boolean {
		return this.getSheetDuplicateRows(sheetIndex).length > 0;
	}

	getSheetDuplicateRows(sheetIndex: number = 0): IImportSheetRow[] {

		//0 - check duplication value
		const duplicatedRows: IImportSheetRow[] = [];
		for (const [rowIndex, importSheetRow] of this.excelData.sheets[sheetIndex].rows.entries()) {

			//a. has duplicated
			const hasDuplicated: boolean = this.isSheetRowDuplicated(sheetIndex, rowIndex);

			//b. put duplicated row data
			if (hasDuplicated) duplicatedRows.push(importSheetRow);
		}

		//1 - return duplicated rows
		return duplicatedRows;
	}

	isSheetRowDuplicated(sheetIndex: number = 0, rowIndex: number): boolean {

		//0 - get row data
		const rows : IImportSheetRow[] = this.excelData.sheets[sheetIndex].rows;
		const row  : IImportSheetRow   = rows[rowIndex];

		//1 - get the status
		let isDuplicate: boolean = false;
		for (const sheetRow of rows) {

			//a. check skip if less than the active index
			if (sheetRow.rowIndex <= rowIndex) continue;

			//b. set duplication status
			if (this.excelSheetHelper.isDuplicatedRow(sheetRow, row)) {
				isDuplicate = true;
				break;
			}
		}

		//2 - return duplication status
		return isDuplicate;
	}


	/**------------------------------------------------------
	 * Empty Rows Checks
	 */
	hasSheetEmptyRows(sheetIndex: number = 0): boolean {
		return this.getSheetEmptyRows(sheetIndex).length > 0;
	}

	//** Get sheet empty rows  */
	getSheetEmptyRows(sheetIndex: number = 0): IImportSheetRow[] {

		//0 - get import sheet rows
		const importSheetRows: IImportSheetRow[] = this.excelData.sheets[sheetIndex].rows;
		const sheetEmptyRows : IImportSheetRow[] = [];

		//1 - check empty sheet row
		for (const sheetRow of importSheetRows) {

			//a. check has empty
			const hasEmpty: boolean = sheetRow.cellValues.every((elem: IImportSheetCellValue) => Util.String.isEmpty(elem.value));

			//b. if empty then update indexes
			if (hasEmpty) sheetEmptyRows.push(sheetRow);
		}

		//2 - get sheet empty row indexes
		return sheetEmptyRows;
	}

	isSheetRowEmpty(sheetIndex: number = 0, rowIndex: number = 0): boolean {

		//0 - validate sheet
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		this.excelSheetHelper.checkIndex(this.excelData.sheets[sheetIndex].rows, rowIndex);

		//1 - get sheet row and check if value is defined
		const sheetRow: IImportSheetRow = this.excelData.sheets[sheetIndex].rows[rowIndex];
		for (const cellValue of sheetRow.cellValues) {
			if (Util.String.isNotEmpty(cellValue.value)) return false;  // row is not empty
		}

		//1 - return row empty status
		return true;
	}


	/**------------------------------------------------------
	 * Sheet Data Access
	 */
	getAllSheets(): IImportSheetData[] {
		return this.excelData.sheets;
	}

	getSheet(sheetIndex: number = 0): IImportSheetData {
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		return this.excelData.sheets[sheetIndex];
	}


	/**------------------------------------------------------
	 * Row Data Access
	 */
	getSheetRows(sheetIndex: number = 0, filterEmpty: boolean = false): IImportSheetRow[] {

		//0 - validate sheet
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);

		//1 - get non filtered row
		if (!filterEmpty) return this.excelData.sheets[sheetIndex].rows;

		//2 - get filtered row
		return this.excelSheetHelper.filterEmptyRowsData(this.excelData.sheets[sheetIndex].rows);
	}

	getSheetRow(sheetIndex: number, rowIndex: number, filterEmpty: boolean): IImportSheetRow {
		return this.excelSheetHelper.getSheetRow(this.excelData.sheets, sheetIndex, rowIndex, filterEmpty);
	}


	/**------------------------------------------------------
	 * Data Manipulation
	 */
	cropSheetRows(sheetIndex: number, rowStartIndex: number, rowEndIndex: number): ExcelSheetWrapper {

		//0 - deep copy original data
		const excelDataCopy: IImportExcelData = Util.Basic.deepCopy(this.excelData);

		//1 - check sheet index and get sheet rows
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		const importSheetRow: IImportSheetRow[] = excelDataCopy.sheets[sheetIndex].rows;

		//2 - validate row index
		if (rowEndIndex >= importSheetRow.length) rowEndIndex = importSheetRow.length - 1; 		// last row
		this.excelSheetHelper.checkIndex(importSheetRow, rowStartIndex);
		this.excelSheetHelper.checkIndex(importSheetRow, rowEndIndex);
		if (rowStartIndex > rowEndIndex) throw new Error(`ExcelSheetWrapper => cropSheetRows => FATAL ERROR: rowStartIndex must be less than rowEndIndex`);

		//3 - update rows
		excelDataCopy.sheets[sheetIndex].rows = importSheetRow.slice(rowStartIndex, rowEndIndex + 1);

		//4 - return a new immutable object
		return new ExcelSheetWrapper(excelDataCopy, this.excelSheetHelper);
	}

	removeSheetRow(sheetIndex: number, rowIndex: number): ExcelSheetWrapper {

		//0 - deep copy original data
		const excelDataCopy: IImportExcelData = Util.Basic.deepCopy(this.excelData);

		//1 - check sheet index
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);

		//2 - get import sheet rows and check index
		const importSheetRow: IImportSheetRow[] = excelDataCopy.sheets[sheetIndex].rows;
		this.excelSheetHelper.checkIndex(importSheetRow, rowIndex);

		//3 - remove the row
		importSheetRow.splice(rowIndex, 1);

		//4 - return a new immutable object
		return new ExcelSheetWrapper(excelDataCopy, this.excelSheetHelper);
	}

	changeSheetValue(value: string, indexes: ISheetValueChangeOptions): ExcelSheetWrapper {

		//0 - deep copy original data
		const excelDataCopy: IImportExcelData = Util.Basic.deepCopy(this.excelData);

		//1 - check sheet index and get sheet row
		this.excelSheetHelper.checkIndex(this.excelData.sheets, indexes.sheetIndex);
		const importSheetRow: IImportSheetRow[] = excelDataCopy.sheets[indexes.sheetIndex].rows;

		//2 - get import sheet rows and check index
		this.excelSheetHelper.checkIndex(importSheetRow, indexes.rowIndex);
		const row: IImportSheetCellValue[] = importSheetRow[indexes.rowIndex].cellValues;

		//3 - get row by row index and check
		this.excelSheetHelper.checkIndex(row, indexes.columnIndex);
		row[indexes.columnIndex].value = value;

		//4 - return a new immutable object
		return new ExcelSheetWrapper(excelDataCopy, this.excelSheetHelper);
	}
}


//** Interfaces --------------------------------- */
export interface ISheetValueChangeOptions {
	sheetIndex	: number;
	rowIndex	: number;
	columnIndex	: number;
}
