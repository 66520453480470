import { EnumPodMarketplace, EnumSpreadshirtProduct, EnumSpreadshirtSorting } from '../../../../../constants/research';
import { ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export const SPREADSHIRT_SORTING_LIST: ISearchParameterConfig<EnumSpreadshirtSorting>[] = [
	{
		code		: EnumSpreadshirtSorting.Relevance,
		name		: 'Relevance',
		parameters	: { sort: '0' }
	},
	{
		code		: EnumSpreadshirtSorting.Newest,
		name		: 'Newest',
		parameters	: { sort: '1' }
	}
];


/**------------------------------------------------------
 * Spreadshirt Search Options
 */
export const SPREADSHIRT_DEFAULT_OPTIONS: ISpreadshirtSearchOptions = {
	page			: 1,
	sorting			: EnumSpreadshirtSorting.Relevance,
	product			: EnumSpreadshirtProduct.All,
	marketplace 	: [EnumPodMarketplace.COM]
};

export interface ISpreadshirtSearchOptions {
	page			: number;
	sorting			: EnumSpreadshirtSorting;
	product			: EnumSpreadshirtProduct;
	marketplace 	: EnumPodMarketplace[];
}
