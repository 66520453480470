<!-- Header ------------------------------------------------>
<div
	class="ignore-dark-mode"
	*ngIf="modalConfig"
>
	<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
	<div
		class="modal-header"
		style.border-color="var({{ modalConfig.style.color }})"
	>
		<h5 class="modal-title text-muted">
			<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
			<ng-fa-icon
				class="d-inline-flex"
				[icon]="modalConfig.style.icon.name"
				[iconStyle]="modalConfig.style.icon.style"
				style.color="var({{ modalConfig.style.color }})"
			></ng-fa-icon>
			&nbsp;&nbsp; {{ modalContent?.title }}
		</h5>
	</div>
</div>

<!-- Body --------------------------------------------------->
<div class="modal-body m-3">
	<div class="mb-0 text-justify text-muted conf-modal-body">
		<div
			class="mx-1"
			[innerHtml]="modalContent?.message"
		></div>
	</div>
</div>

<!-- Footer ------------------------------------------------->
<div class="modal-footer">
	<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
	<button
		type="button"
		class="btn text-white"
		*ngFor="let button of modalConfig?.buttons"
		style.background="var({{ button.color }})"
		(click)="emitButtonAction(button?.actionCode!)"
	>
		{{ button.buttonText }}
	</button>
</div>
