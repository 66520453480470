/**------------------------------------------------------
 * Time Definitions
 */
export enum EnumTime {
	Nanosecond 		= 'time_ns',
	Microsecond 	= 'time_mu',
	Millisecond 	= 'time_ms',
	Second 			= 'time_s',
	Minute 			= 'time_min',
	Hour 			= 'time_h',
	Day 			= 'time_d',
	Week 			= 'time_week',
	Month 			= 'time_month',
	Year 			= 'time_year'
}
