import { Util } from '@libs/utilities/util';
import { EnumColorName } from '@libs/constants';
import { ColorCodeName } from '@libs/libraries/shared';

import { IImageColorInfo, IImageColorList } from './color-extraction.interface';


/**------------------------------------------------------
 * Color Extraction Algorithm
 */
export class ColorExtractionCore {

	//** Configurations */
	private readonly COLOR_EXTRACT_MINIMUM_PERCENTAGE: number = 2;

	constructor(
		private colorNamingCore: ColorCodeName
	) {}


	/**------------------------------------------------------
	 * Analyze the Image Colors
	 */
	analyzeImageColors(pixelData: ImageData, colorList: string[]): IImageColorInfo {

		//0 - verify arguments
		for (const colorName of colorList) {
			if (!Util.Enum.isValid(EnumColorName, colorName)) throw new Error(`ColorExtractionCore => getColorObjects => FATAL ERROR: colorName of "${colorName}" is not configured in the COLOR_LIST definitions and in the EnumColorName`);
		}

		//1 - prepare variables
		const height		: number 			= pixelData.height;
		const width			: number 			= pixelData.width;
		const pixels		: Uint8ClampedArray = pixelData.data;

		let totalCount		: number 			= 0;
		const colorMapping	: TypeColorMapping 	= {};
		const colorSum		: IRgbData			= { r: 0, g: 0, b: 0 };

		let borderCount		: number 			= 0;
		const borderMapping : TypeColorMapping 	= {};
		const borderSum		: IRgbData			= { r: 0, g: 0, b: 0 };

		//2 - loop over pixels and prepare colors mapping
		for (let y: number = 0; y < height; y += 1) {
			for (let x: number = 0; x < width; x += 1) {

				//a. get pixel index
				const pixelNdx: number = y * width + x;

				//b. check and ignore transparent pixel
				const a: number = pixels[4 * pixelNdx + 3];
				if (a === 0) continue;

				//c. get the rgb color values
				const r : number = pixels[4 * pixelNdx];
				const g : number = pixels[4 * pixelNdx + 1];
				const b : number = pixels[4 * pixelNdx + 2];

				//d. get the hex value and color name
				const hexValue : string 	   = Util.Color.rgbToHex(r, g, b);
				const colorCode: EnumColorName = this.colorNamingCore.getColorName(colorList, hexValue).code;

				//e. track the image colors (get the color name of the pixel, and count up the color occurrence, ...)
				colorSum.r += r;
				colorSum.g += g;
				colorSum.b += b;									// add to the sum values
				colorMapping[colorCode]  = colorMapping[colorCode] ? colorMapping[colorCode] += 1 : 1;
				totalCount++;

				//f. is pixel a border pixel? (get the color name of the pixel, and count up the color occurrence, ...)
				if (this.isBorderColor(pixels, x, y, width, height)) {
					borderSum.r += r;
					borderSum.g += g;
					borderSum.b += b;			// add to the sum values
					borderMapping[colorCode] = borderMapping[colorCode] ? borderMapping[colorCode] += 1 : 1;
					borderCount++;
				}
			}
		}

		//3 - filter out the colors, which don't occur enough, and sort the colors by occurrence
		const imageColors : IImageColorList[] = this.getPurifiedColorList(colorMapping, totalCount);
		const borderColors: IImageColorList[] = this.getPurifiedColorList(borderMapping, borderCount);

		//4 - return the color info result
		const colorInfo: IImageColorInfo = {
			colorList		: imageColors,
			average			: this.getAverage(colorSum, totalCount),
			dominant		: this.getDominantColor(imageColors),
			border			: {
				colorList	: borderColors,
				average		: (borderCount > 0) ? this.getAverage(borderSum, borderCount) : null,
				dominant	: (borderCount > 0) ? this.getDominantColor(borderColors)	  : null
			}
		};
		return colorInfo;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private isBorderColor(pixels: Uint8ClampedArray, x: number, y: number, width: number, height: number): boolean {

		//0 - if any neighbor pixel is transparent, it is a border color
		if (x > 0 		   && (pixels[4 * (y	   * width + (x + 1)) + 3] === 0)) return true;
		if (x < width - 1  && (pixels[4 * (y	   * width + (x - 1)) + 3] === 0)) return true;
		if (y > 0 		   && (pixels[4 * ((y + 1) * width + x) + 3] === 0)) return true;
		if (y < height - 1 && (pixels[4 * ((y - 1) * width + x) + 3] === 0)) return true;

		//1 - no border pixel
		return false;
	}

	private getPurifiedColorList(colorMapping: TypeColorMapping, totalCount: number): IImageColorList[] {

		//0 - convert the color map to the percentage format
		let colorList: IImageColorList[] = Object.keys(colorMapping).map((colorName: string) => ({
			name		: colorName,
			percentage	: colorMapping[colorName] * 100 / totalCount
		}));

		//1 - filter out low occurrences and sort by percentage
		colorList = colorList.filter((imageColor: IImageColorList) => imageColor.percentage >= this.COLOR_EXTRACT_MINIMUM_PERCENTAGE);
		colorList = colorList.sort((previousColor: IImageColorList, currentColor: IImageColorList) => (previousColor.percentage > currentColor.percentage ? -1 : 1));

		return colorList;
	}

	private getDominantColor(colorList: IImageColorList[]): string {
		return colorList.reduce((previousValue: IImageColorList, currentValue: IImageColorList) => (previousValue.percentage > currentValue.percentage ? previousValue : currentValue)).name;
	}

	private getAverage(rgbData: IRgbData, totalCount: number): string {
		return Util.Color.rgbToHex(Math.floor(rgbData.r / totalCount), Math.floor(rgbData.g / totalCount), Math.floor(rgbData.b / totalCount));
	}
}


//** Types -------------------------------------- */
type TypeColorMapping = Record<string, number>;

//** Interfaces --------------------------------- */
interface IRgbData {
	r			: number;
	g			: number;
	b			: number;
}
