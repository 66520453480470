import type { NgbToastOptions } from '@ng-bootstrap/ng-bootstrap/toast/toast-config';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgMessageToastType {
	ToastDefault	= 'toast-default',
	ToastSuccess	= 'toast-success',
	ToastWarning	= 'toast-warning',
	ToastError		= 'toast-error'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface INgMessageToastConfig extends NgbToastOptions {
	autohide	: boolean;
	delay		: number;
	animation	: boolean;
}

export interface INgMessageToastType {
	className	: string;
	color		: string;
}


/**------------------------------------------------------
 * Default Toast Configurations
 */
export const NG_MESSAGE_TOAST_TYPE_INFO: Record<EnumNgMessageToastType, INgMessageToastType> = {
	[EnumNgMessageToastType.ToastDefault]: {
		className	: 'message-toast-default',
		color		: '--toast-default'
	},
	[EnumNgMessageToastType.ToastSuccess]: {
		className	: 'message-toast-success',
		color		: '--toast-success'
	},
	[EnumNgMessageToastType.ToastWarning]: {
		className	: 'message-toast-warning',
		color		: '--toast-warning'
	},
	[EnumNgMessageToastType.ToastError]: {
		className	: 'message-toast-error',
		color		: '--toast-error'
	}
};
