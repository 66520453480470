/**------------------------------------------------------
 * Trademark Type
 */
export enum EnumTmType {
	Word 				= 'word',
	Shape3D 			= 'shape_3d',
	Colour 				= 'colour',
	Combined 			= 'combined',
	Figurative 			= 'figurative',
	GeneralMark 		= 'general_mark',
	Hologram 			= 'hologram',
	Kennfaden 			= 'kennfaden',
	Motion 				= 'motion',
	Multimedia 			= 'multimedia',
	Number 				= 'number',
	Olfactory 			= 'olfactory',
	Other 				= 'other',
	Packing 			= 'packing',
	Pattern 			= 'pattern',
	Position 			= 'position',
	Sound 				= 'sound',
	CombinationOfColours = 'combination_of_colours',
	StylizedCharacters 	= 'stylized characters',
	Undefined 			= 'undefined'
}


/**------------------------------------------------------
 * Definitions & Configurations
 */
export const TM_TYPE_INFOS: Record<EnumTmType, ITmTypeInfo> = {
	[EnumTmType.Word]: {
		code			: EnumTmType.Word,
		name			: 'Word',
		description		: `The Trademark type 'word' refers to a type of trademark that consists solely of a word or combination of words, letters, or numerals, without any special design elements.`,
		tmViewValue		: 'Word'
	},
	[EnumTmType.Shape3D]: {
		code			: EnumTmType.Shape3D,
		name			: '3D shape',
		description		: `The Trademark type '3D shape' refers to a type of three-dimensional trademark that represents the shape or configuration of a product.`,
		tmViewValue		: '3D shape'
	},
	[EnumTmType.Colour]: {
		code			: EnumTmType.Colour,
		name			: 'Colour',
		description		: `The Trademark type 'Colour' refers to a type of trademark that consists solely of a specific color or combination of colors without any specific design or wording.`,
		tmViewValue		: 'Colour'
	},
	[EnumTmType.CombinationOfColours]: {
		code			: EnumTmType.CombinationOfColours,
		name			: 'Combination of colours',
		description		: `The Trademark type 'Combination of colours' refers to a type of trademark that consists of a combination of two or more colors without any specific design or wording.`,
		tmViewValue		: 'Combination of colours'
	},
	[EnumTmType.Combined]: {
		code			: EnumTmType.Combined,
		name			: 'Combined',
		description		: `The Trademark type 'Combined' refers to a type of trademark that combines both word and design elements, creating a distinctive visual representation.`,
		tmViewValue		: 'Combined'
	},
	[EnumTmType.Figurative]: {
		code			: EnumTmType.Figurative,
		name			: 'Figurative',
		description		: `The Trademark type 'Figurative' refers to a type of trademark that consists of a specific design, logo, or graphical representation without any specific wording.`,
		tmViewValue		: 'Figurative'
	},
	[EnumTmType.GeneralMark]: {
		code			: EnumTmType.GeneralMark,
		name			: 'General mark',
		description		: `The Trademark type 'General mark' refers to a type of trademark that encompasses a broad range of marks, including word marks, design marks, and other variations.`,
		tmViewValue		: 'General mark'
	},
	[EnumTmType.Hologram]: {
		code			: EnumTmType.Hologram,
		name			: 'Hologram',
		description		: `The Trademark type 'Hologram' refers to a type of trademark that incorporates holographic or three-dimensional elements, creating a unique visual representation.`,
		tmViewValue		: 'Hologram'
	},
	[EnumTmType.Kennfaden]: {
		code			: EnumTmType.Kennfaden,
		name			: 'Kennfaden',
		description		: `The Trademark type 'Kennfaden' refers to a specific type of trademark associated with a German certification mark used for textile products.`,
		tmViewValue		: 'Kennfaden'
	},
	[EnumTmType.Motion]: {
		code			: EnumTmType.Motion,
		name			: 'Motion',
		description		: `The Trademark type 'Motion' refers to a type of trademark that includes moving elements or animations, typically used in multimedia or digital applications.`,
		tmViewValue		: 'Motion'
	},
	[EnumTmType.Multimedia]: {
		code			: EnumTmType.Multimedia,
		name			: 'Multimedia',
		description		: `The Trademark type 'Multimedia' refers to a type of trademark that incorporates multiple forms of media, such as images, audio, video, or interactive elements.`,
		tmViewValue		: 'Multimedia'
	},
	[EnumTmType.Number]: {
		code			: EnumTmType.Number,
		name			: 'Number',
		description		: `The Trademark type 'Number' refers to a type of trademark that consists solely of a numerical value or combination of numbers without any specific design or wording.`,
		tmViewValue		: 'Number'
	},
	[EnumTmType.Olfactory]: {
		code			: EnumTmType.Olfactory,
		name			: 'Olfactory',
		description		: `The Trademark type 'Olfactory' refers to a type of trademark associated with scents or smells, often used to protect unique or distinctive fragrances.`,
		tmViewValue		: 'Olfactory'
	},
	[EnumTmType.Other]: {
		code			: EnumTmType.Other,
		name			: 'Other',
		description		: `The Trademark type 'Other' refers to a type of trademark that does not fit into any specific predefined categories, encompassing unique or specialized trademarks.`,
		tmViewValue		: 'Other'
	},
	[EnumTmType.Packing]: {
		code			: EnumTmType.Packing,
		name			: 'Packing',
		description		: `The Trademark type 'Packing' refers to a type of trademark associated with the packaging or container of a product, often used to distinguish products based on their packaging.`,
		tmViewValue		: 'Packing'
	},
	[EnumTmType.Pattern]: {
		code			: EnumTmType.Pattern,
		name			: 'Pattern',
		description		: `The Trademark type 'Pattern' refers to a type of trademark that consists of a specific pattern, design, or decorative elements without any specific wording.`,
		tmViewValue		: 'Pattern'
	},
	[EnumTmType.Position]: {
		code			: EnumTmType.Position,
		name			: 'Position',
		description		: `The Trademark type 'Position' refers to a type of trademark that is defined by its specific placement or positioning on a product, typically used to indicate the origin or source of the product.`,
		tmViewValue		: 'Position'
	},
	[EnumTmType.Sound]: {
		code			: EnumTmType.Sound,
		name			: 'Sound',
		description		: `The Trademark type 'Sound' refers to a type of trademark associated with distinctive sounds or audio elements, often used to create audio branding or recognition.`,
		tmViewValue		: 'Sound'
	},
	[EnumTmType.StylizedCharacters]: {
		code			: EnumTmType.StylizedCharacters,
		name			: 'Stylized characters',
		description		: `The Trademark type 'Stylized characters' refers to a type of trademark that represents stylized or unique characters, often used in branding or design applications.`,
		tmViewValue		: 'Stylized characters'
	},
	[EnumTmType.Undefined]: {
		code			: EnumTmType.Undefined,
		name			: 'Undefined',
		description		: `The Trademark type 'Undefined' refers to a type of trademark that has an undefined or unknown classification, often requiring further evaluation or clarification.`,
		tmViewValue		: 'Undefined'
	}
};


//** Interfaces --------------------------------- */
export interface ITmTypeInfo {
	code			: EnumTmType;
	name			: string;
	description		: string;
	tmViewValue		: string;
}
