/**------------------------------------------------------
 * Speed Definitions
 */
export enum EnumSpeed {
	MetrePerSecond 		= 'speed_m/s',
	KilometerPerHour	= 'speed_km/h',
	MilePerHour 		= 'speed_mph',
	Knot 				= 'speed_knot',
	FootPerSecond 		= 'speed_ft/s'
}
