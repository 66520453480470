/**------------------------------------------------------
 * Whitelist and Blacklist API interfaces
 */
export interface IGetAllWhitelistResponse {
	whitelist		: object[];
	returnedResults	: number;
}

export interface IGetAllBlacklistResponse {
	blacklist		: object[];
	returnedResults	: number;
}

export interface IBlackWhiteListData {
	word	: string;
	userId	: string;
	note	: string;
	language: string;
}


/**------------------------------------------------------
 * Constants
 */
export const MAX_WORDS_TO_PROCESS: number = 100;
