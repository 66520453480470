import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchLinkUrlConfig } from '../../data/search-links.interface';


/**------------------------------------------------------
 * Wikipedia Search Url
 */
export class WikipediaSearch implements ISearchLinks {

	//** Configurations */
	private readonly WIKIPEDIA_CONFIG: ISearchLinkUrlConfig = {
		home		: 'https://en.wikipedia.org',
		login		: 'https://en.wikipedia.org/w/index.php?title=Special:UserLogin',
		search		: 'https://en.wikipedia.org/wiki/[SEARCH]'
	};


	//** Home & Login */
	home() : string { return this.WIKIPEDIA_CONFIG.home; }
	login(): string { return this.WIKIPEDIA_CONFIG.login; }

	//** Search */
	search(searchQuery: string): string {

		//0 - if no search string existing then return main or home page
		if (Util.String.isEmpty(searchQuery)) return 'https://en.wikipedia.org/wiki/Main_Page';

		//1 - else return the url with search query
		return this.WIKIPEDIA_CONFIG.search.replace('[SEARCH]', searchQuery);
	}
}
