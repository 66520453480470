import { EnumControlKey, EnumKeyboardKey } from './data/keyboard.data';


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IKeyEventAction {
	code		 : string;
	keyboardKey	 : EnumKeyboardKey;
	controlKeys  : EnumControlKey[];
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypeKeyPressed 			= KeyboardEvent | PointerEvent | MouseEvent;
export type TypeListenerCallbackFn 	= (keyEvent: KeyboardEvent, keyListener: IKeyEventAction) => void;
