import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumEtsySorting {
	Relevancy		= 'relevancy',			// default value
	LowestPrice		= 'lowest_price',
	HighestPrice	= 'highest_price',
	TopReviews		= 'top_reviews',
	MostRecent		= 'most_recent'
}

export const ETSY_SORTING_LIST: ISearchParameterConfig<EnumEtsySorting>[] = [
	{
		code 		: EnumEtsySorting.Relevancy,
		name 		: 'Relevancy',
		parameters 	: {}
	},
	{
		code 		: EnumEtsySorting.LowestPrice,
		name 		: 'Lowest Price',
		parameters 	: {
			explicit: '1',
			order 	: 'price_asc'
		}
	},
	{
		code 		: EnumEtsySorting.HighestPrice,
		name 		: 'Highest Price',
		parameters 	: {
			explicit: '1',
			order 	: 'price_desc'
		}
	},
	{
		code 		: EnumEtsySorting.TopReviews,
		name 		: 'Top Reviews',
		parameters 	: {
			explicit: '1',
			order 	: 'highest_reviews'
		}
	},
	{
		code 		: EnumEtsySorting.MostRecent,
		name 		: 'Most Recent',
		parameters 	: {
			explicit: '1',
			order 	: 'date_desc'
		}
	}
];


/**------------------------------------------------------
 * Product Configurations
 * ----------------------
 * > example url: https://www.etsy.com/c/clothing/womens-clothing/skirts?q=dog
 */
export enum EnumEtsyProduct {
	All 			= 'all_products',
	MansShirt 		= 'mans_shirt',
	MansHoodies 	= 'mans_hoodies_and_sweatshirts',
	WomansTops 		= 'womans_tops',
	WomansHoodies 	= 'womans_hoodies_and_sweatshirts',
	KidsBoysTops 	= 'kids_boys_shirt',						// using the boy' clothing products for kids
	KidsGirlsTops 	= 'kids_girls_dresses'
}

export const ETSY_PRODUCT_LIST: ISearchParameterConfig<EnumEtsyProduct>[] = [
	{
		code		: EnumEtsyProduct.All,
		name		: 'All Products',
		placeholders: {
			'[PRODUCT]'	: 'search'
		}
	},
	{
		code		: EnumEtsyProduct.MansShirt,
		name		: `Men's T-Shirt & Tees`,
		placeholders: {
			'[PRODUCT]'	: 'c/clothing/mens-clothing/shirts-and-tees'
		}
	},
	{
		code		: EnumEtsyProduct.MansHoodies,
		name		: `Men's Hoodies & Sweatshirts`,
		placeholders: {
			'[PRODUCT]'	: 'c/clothing/mens-clothing/hoodies-and-sweatshirts'
		}
	},
	{
		code		: EnumEtsyProduct.WomansTops,
		name		: `Women's Tops & Tees`,
		placeholders: {
			'[PRODUCT]'	: 'c/clothing/womens-clothing/tops-and-tees'
		}
	},
	{
		code		: EnumEtsyProduct.WomansHoodies,
		name		: `Women's Hoodies & Sweatshirts`,
		placeholders: {
			'[PRODUCT]'	: 'c/clothing/womens-clothing/hoodies-and-sweatshirts'
		}
	},
	{
		code		: EnumEtsyProduct.KidsBoysTops,
		name		: `Boys' Tops & Tees`,
		placeholders: {
			'[PRODUCT]'	: 'c/clothing/boys-clothing/tops-and-tees'
		}
	},
	{
		code		: EnumEtsyProduct.KidsGirlsTops,
		name		: `Girls' Tops & Tees`,
		placeholders: {
			'[PRODUCT]'	: 'c/clothing/girls-clothing/tops-and-tees'
		}
	}
];


/**------------------------------------------------------
 * Etsy Configuration
 */
export const ETSY_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.etsy.com/',
		login		: 'https://www.etsy.com/signin',
		search		: 'https://www.etsy.com/[PRODUCT]?q=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 200,
	sortings		: ETSY_SORTING_LIST,
	products		: ETSY_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Etsy Search Options
 */
export const ETSY_DEFAULT_OPTIONS: IEtsySearchOptions = {
	page			: 1,
	sorting			: EnumEtsySorting.Relevancy,
	product			: EnumEtsyProduct.All
};

export interface IEtsySearchOptions {
	page			: number;
	sorting			: EnumEtsySorting;
	product			: EnumEtsyProduct;
}
