/**------------------------------------------------------
 * Tool Definitions
 */
export enum EnumWebAppTool {
	Research 	= 'research',
	Upload		= 'upload'
}


/**------------------------------------------------------
 * Tool Instance Interface
 */
export interface IWebAppTool {
	name			: 'Flying Research' | 'Flying Upload';
	tool			: EnumWebAppTool;
	logo			: string;
	logoWide   		: string;
	toggleLogo 		: string;
	theme	  	   ?: {
		light   	: string;
		dark		: string;
	};
}
