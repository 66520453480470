import { Directive, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Util } from '@libs/utilities/util';

import { EnumNgTooltipSizes } from './tooltip.interface';


/**------------------------------------------------------
 * Tooltip Directive
 * -----------------
 * > Creating a text tooltip for an given element
 */
@Directive({
	selector: '[ngTooltip]',
	exportAs: 'ngTooltip'
})
export class NgTooltipDirective extends NgbTooltip implements OnInit, OnChanges, OnDestroy {

	//** Configurations */
	private readonly SUPPORTED_SIZES: any[] = ['small', 'medium', 'large'];

	@Input() onlyTruncate	 : any;							// show the tooltip only if truncating is applied
	@Input() size 			!: EnumNgTooltipSizes;			// set the width of the tooltip (default is medium)
	@Input() ngTooltip 		!: string | TemplateRef<any>;
	@Input() delay 			!: number;
	@Input() duration 		!: number;
	@Input() declare tooltipClass: string;

	constructor(
		private elementRef: ElementRef,
		private ngZone	  : NgZone
	) {
		super();
		this.triggers 	= 'hover';
		this.placement 	= 'auto';
	}

	@HostListener('mouseover')
	onHover(): void {
		this.disableTooltip = !this.hasTruncated() && !Util.Basic.isUndefined(this.onlyTruncate);
	}

	override ngOnInit(): void {

		//0 - check for errors
		if (this.size && !this.SUPPORTED_SIZES.includes(this.size)) throw new Error(`NgTooltipDirective => ngOnInit => FATAL ERROR: size is not supported (supported sizes: ${this.SUPPORTED_SIZES})`);

		//1 - set class according to size
		this.tooltipClass = !Util.Basic.isUndefined(this.size)
			? `ngbTooltip-${this.size} ${this.tooltipClass}`
			: `ngbTooltip-medium ${this.tooltipClass}`;

		//2 - handle delay and duration
		if (this.delay) 	this.handleDelay();
		if (this.duration)  this.handleDuration();

		//4 - call NgbTooltip OnInit
		super.ngOnInit();
	}

	override ngOnChanges({ ngTooltip, tooltipClass }: SimpleChanges): void {

		//0 - call NgbTooltip OnChanges
		if (tooltipClass) super.ngOnChanges({ tooltipClass });

		//1 - set default values
		if (ngTooltip) {
			this.ngbTooltip = ngTooltip.currentValue;
		}
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private hasTruncated() {
		const tooltipEle: HTMLElement = this.elementRef.nativeElement;
		return (tooltipEle?.scrollWidth > tooltipEle?.clientWidth) || (tooltipEle?.scrollHeight > tooltipEle?.clientHeight);
	}


	private handleDelay() {

		// convert delay to number and update openDelay
		const delay: number = Util.Number.toNumber(this.delay);
		this.openDelay = delay;
	}

	private handleDuration() {

		//0 - convert duration to number
		const duration: number = Util.Number.toNumber(this.duration);

		//1 - set delay and duration to the tooltip
		this.shown.subscribe(() => {
			this.ngZone.runOutsideAngular(() => {
				setTimeout(() => {
					this.close();
				}, duration);
			});
		});
	}
}
