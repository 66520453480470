import { EnumCurrency } from '../currency/currency.interface';
import { EnumCountry, ICountryInfo } from './country.interface';
import { EnumLanguage } from '../language/language.interface';
import { COUNTRY_FLAG_ICONS } from '../../assets';


/**------------------------------------------------------
 * Country Infos
 * -------------
 * > Source for the country flags: https://github.com/hampusborgos/country-flags/tree/main/svg
 */
export const COUNTRY_INFOS: Record<EnumCountry, ICountryInfo> = {
	// cspell:disable

	// Africa
	[EnumCountry.DZ]: {
		code			: EnumCountry.DZ,
		name			: 'Algeria',
		nameShort		: 'Algeria',
		nameLong		: 'People\'s Democratic Republic of Algeria',
		nameNative 		: 'الجزائر',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DZ],
		dialCode		: '+213',
		capital			: 'Algiers',
		domainTld		: '.dz',
		currencyCode	: EnumCurrency.DZD
	},
	[EnumCountry.AO]: {
		code			: EnumCountry.AO,
		name			: 'Angola',
		nameShort		: 'Angola',
		nameLong		: 'Republic of Angola',
		nameNative 		: 'Angola',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AO],
		dialCode		: '+244',
		capital			: 'Luanda',
		domainTld		: '.ao',
		currencyCode	: EnumCurrency.AOA
	},
	[EnumCountry.BJ]: {
		code			: EnumCountry.BJ,
		name			: 'Benin',
		nameShort		: 'Benin',
		nameLong		: 'Republic of Benin',
		nameNative 		: 'Bénin',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BJ],
		dialCode		: '+229',
		capital			: 'Porto-Novo',
		domainTld		: '.bj',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.BW]: {
		code			: EnumCountry.BW,
		name			: 'Botswana',
		nameShort		: 'Botswana',
		nameLong		: 'Republic of Botswana',
		nameNative 		: 'Botswana',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BW],
		dialCode		: '+267',
		capital			: 'Gaborone',
		domainTld		: '.bw',
		currencyCode	: EnumCurrency.BWP
	},
	[EnumCountry.BF]: {
		code			: EnumCountry.BF,
		name			: 'Burkina Faso',
		nameShort		: 'Burkina Faso',
		nameLong		: 'Burkina Faso',
		nameNative 		: 'Burkina Faso',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BF],
		dialCode		: '+226',
		capital			: 'Ouagadougou',
		domainTld		: '.bf',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.BI]: {
		code			: EnumCountry.BI,
		name			: 'Burundi',
		nameShort		: 'Burundi',
		nameLong		: 'Republic of Burundi',
		nameNative 		: 'Burundi',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BI],
		dialCode		: '+257',
		capital			: 'Gitega',
		domainTld		: '.bi',
		currencyCode	: EnumCurrency.BIF
	},
	[EnumCountry.CV]: {
		code			: EnumCountry.CV,
		name			: 'Cape Verde',
		nameShort		: 'Cape Verde',
		nameLong		: 'Republic of Cabo Verde',
		nameNative 		: 'Cabo Verde',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CV],
		dialCode		: '+238',
		capital			: 'Praia',
		domainTld		: '.cv',
		currencyCode	: EnumCurrency.CVE
	},
	[EnumCountry.CM]: {
		code			: EnumCountry.CM,
		name			: 'Cameroon',
		nameShort		: 'Cameroon',
		nameLong		: 'Republic of Cameroon',
		nameNative 		: 'Cameroun',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CM],
		dialCode		: '+237',
		capital			: 'Yaoundé',
		domainTld		: '.cm',
		currencyCode	: EnumCurrency.XAF
	},
	[EnumCountry.CF]: {
		code			: EnumCountry.CF,
		name			: 'Central African Republic',
		nameShort		: 'Central African Republic',
		nameLong		: 'Central African Republic',
		nameNative 		: 'République centrafricaine',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CF],
		dialCode		: '+236',
		capital			: 'Bangui',
		domainTld		: '.cf',
		currencyCode	: EnumCurrency.XAF
	},
	[EnumCountry.TD]: {
		code			: EnumCountry.TD,
		name			: 'Chad',
		nameShort		: 'Chad',
		nameLong		: 'Republic of Chad',
		nameNative 		: 'Tchad',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TD],
		dialCode		: '+235',
		capital			: 'N\'Djamena',
		domainTld		: '.td',
		currencyCode	: EnumCurrency.XAF
	},
	[EnumCountry.KM]: {
		code			: EnumCountry.KM,
		name			: 'Comoros',
		nameShort		: 'Comoros',
		nameLong		: 'Union of the Comoros',
		nameNative 		: 'Comores',
		languageCode	: [EnumLanguage.AR, EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KM],
		dialCode		: '+269',
		capital			: 'Moroni',
		domainTld		: '.km',
		currencyCode	: EnumCurrency.KMF
	},
	[EnumCountry.CG]: {
		code			: EnumCountry.CG,
		name			: 'Congo',
		nameShort		: 'Congo',
		nameLong		: 'Republic of the Congo',
		nameNative 		: 'République du Congo',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CG],
		dialCode		: '+242',
		capital			: 'Brazzaville',
		domainTld		: '.cg',
		currencyCode	: EnumCurrency.XAF
	},
	[EnumCountry.CD]: {
		code			: EnumCountry.CD,
		name			: 'DR Congo',
		nameShort		: 'DR Congo',
		nameLong		: 'Democratic Republic of the Congo',
		nameNative 		: 'République démocratique du Congo',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CD],
		dialCode		: '+243',
		capital			: 'Kinshasa',
		domainTld		: '.cd',
		currencyCode	: EnumCurrency.CDF
	},
	[EnumCountry.DJ]: {
		code			: EnumCountry.DJ,
		name			: 'Djibouti',
		nameShort		: 'Djibouti',
		nameLong		: 'Republic of Djibouti',
		nameNative 		: 'جيبوتي',
		languageCode	: [EnumLanguage.AR, EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DJ],
		dialCode		: '+253',
		capital			: 'Djibouti',
		domainTld		: '.dj',
		currencyCode	: EnumCurrency.DJF
	},
	[EnumCountry.EG]: {
		code			: EnumCountry.EG,
		name			: 'Egypt',
		nameShort		: 'Egypt',
		nameLong		: 'Arab Republic of Egypt',
		nameNative 		: 'مصر',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.EG],
		dialCode		: '+20',
		capital			: 'Cairo',
		domainTld		: '.eg',
		currencyCode	: EnumCurrency.EGP
	},
	[EnumCountry.GQ]: {
		code			: EnumCountry.GQ,
		name			: 'Equatorial Guinea',
		nameShort		: 'Equatorial Guinea',
		nameLong		: 'Republic of Equatorial Guinea',
		nameNative 		: 'Guinea Ecuatorial',
		languageCode	: [EnumLanguage.ES, EnumLanguage.FR, EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GQ],
		dialCode		: '+240',
		capital			: 'Malabo',
		domainTld		: '.gq',
		currencyCode	: EnumCurrency.XAF
	},
	[EnumCountry.ER]: {
		code			: EnumCountry.ER,
		name			: 'Eritrea',
		nameShort		: 'Eritrea',
		nameLong		: 'State of Eritrea',
		nameNative 		: 'ኤርትራ',
		languageCode	: [EnumLanguage.AR, EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ER],
		dialCode		: '+291',
		capital			: 'Asmara',
		domainTld		: '.er',
		currencyCode	: EnumCurrency.ERN
	},
	[EnumCountry.SZ]: {
		code			: EnumCountry.SZ,
		name			: 'Eswatini',
		nameShort		: 'Eswatini',
		nameLong		: 'Kingdom of Eswatini',
		nameNative		: 'Eswatini',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SZ],
		dialCode		: '+268',
		capital			: 'Lobamba',
		domainTld		: '.sz',
		currencyCode	: EnumCurrency.SZL
	},
	[EnumCountry.ET]: {
		code			: EnumCountry.ET,
		name			: 'Ethiopia',
		nameShort		: 'Ethiopia',
		nameLong		: 'Federal Democratic Republic of Ethiopia',
		nameNative 		: 'ኢትዮጵያ',
		languageCode	: [EnumLanguage.AM],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ET],
		dialCode		: '+251',
		capital			: 'Addis Ababa',
		domainTld		: '.et',
		currencyCode	: EnumCurrency.ETB
	},
	[EnumCountry.GA]: {
		code			: EnumCountry.GA,
		name			: 'Gabon',
		nameShort		: 'Gabon',
		nameLong		: 'Gabonese Republic',
		nameNative 		: 'Gabon',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GA],
		dialCode		: '+241',
		capital			: 'Libreville',
		domainTld		: '.ga',
		currencyCode	: EnumCurrency.XAF
	},
	[EnumCountry.GM]: {
		code			: EnumCountry.GM,
		name			: 'Gambia',
		nameShort		: 'Gambia',
		nameLong		: 'Republic of the Gambia',
		nameNative 		: 'Gambia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GM],
		dialCode		: '+220',
		capital			: 'Banjul',
		domainTld		: '.gm',
		currencyCode	: EnumCurrency.GMD
	},
	[EnumCountry.GH]: {
		code			: EnumCountry.GH,
		name			: 'Ghana',
		nameShort		: 'Ghana',
		nameLong		: 'Republic of Ghana',
		nameNative 		: 'Ghana',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GH],
		dialCode		: '+233',
		capital			: 'Accra',
		domainTld		: '.gh',
		currencyCode	: EnumCurrency.GHS
	},
	[EnumCountry.GN]: {
		code			: EnumCountry.GN,
		name			: 'Guinea',
		nameShort		: 'Guinea',
		nameLong		: 'Republic of Guinea',
		nameNative 		: 'Guinée',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GN],
		dialCode		: '+224',
		capital			: 'Conakry',
		domainTld		: '.gn',
		currencyCode	: EnumCurrency.GNF
	},
	[EnumCountry.GW]: {
		code			: EnumCountry.GW,
		name			: 'Guinea-Bissau',
		nameShort		: 'Guinea-Bissau',
		nameLong		: 'Republic of Guinea-Bissau',
		nameNative 		: 'Guiné-Bissau',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GW],
		dialCode		: '+245',
		capital			: 'Bissau',
		domainTld		: '.gw',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.CI]: {
		code			: EnumCountry.CI,
		name			: 'Ivory Coast',
		nameShort		: 'Ivory Coast',
		nameLong		: 'Republic of Côte d\'Ivoire',
		nameNative 		: 'Côte d\'Ivoire',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CI],
		dialCode		: '+225',
		capital			: 'Yamoussoukro',
		domainTld		: '.ci',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.KE]: {
		code			: EnumCountry.KE,
		name			: 'Kenya',
		nameShort		: 'Kenya',
		nameLong		: 'Republic of Kenya',
		nameNative 		: 'Kenya',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KE],
		dialCode		: '+254',
		capital			: 'Nairobi',
		domainTld		: '.ke',
		currencyCode	: EnumCurrency.KES
	},
	[EnumCountry.LS]: {
		code			: EnumCountry.LS,
		name			: 'Lesotho',
		nameShort		: 'Lesotho',
		nameLong		: 'Kingdom of Lesotho',
		nameNative 		: 'Lesotho',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LS],
		dialCode		: '+266',
		capital			: 'Maseru',
		domainTld		: '.ls',
		currencyCode	: EnumCurrency.LSL
	},
	[EnumCountry.LR]: {
		code			: EnumCountry.LR,
		name			: 'Liberia',
		nameShort		: 'Liberia',
		nameLong		: 'Republic of Liberia',
		nameNative 		: 'Liberia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LR],
		dialCode		: '+231',
		capital			: 'Monrovia',
		domainTld		: '.lr',
		currencyCode	: EnumCurrency.LRD
	},
	[EnumCountry.LY]: {
		code			: EnumCountry.LY,
		name			: 'Libya',
		nameShort		: 'Libya',
		nameLong		: 'State of Libya',
		nameNative 		: 'ليبيا',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LY],
		dialCode		: '+218',
		capital			: 'Tripoli',
		domainTld		: '.ly',
		currencyCode	: EnumCurrency.LYD
	},
	[EnumCountry.MG]: {
		code			: EnumCountry.MG,
		name			: 'Madagascar',
		nameShort		: 'Madagascar',
		nameLong		: 'Republic of Madagascar',
		nameNative 		: 'Madagasikara',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MG],
		dialCode		: '+261',
		capital			: 'Antananarivo',
		domainTld		: '.mg',
		currencyCode	: EnumCurrency.MGA
	},
	[EnumCountry.MW]: {
		code			: EnumCountry.MW,
		name			: 'Malawi',
		nameShort		: 'Malawi',
		nameLong		: 'Republic of Malawi',
		nameNative 		: 'Malawi',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MW],
		dialCode		: '+265',
		capital			: 'Lilongwe',
		domainTld		: '.mw',
		currencyCode	: EnumCurrency.MWK
	},
	[EnumCountry.ML]: {
		code			: EnumCountry.ML,
		name			: 'Mali',
		nameShort		: 'Mali',
		nameLong		: 'Republic of Mali',
		nameNative 		: 'Mali',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ML],
		dialCode		: '+223',
		capital			: 'Bamako',
		domainTld		: '.ml',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.MR]: {
		code			: EnumCountry.MR,
		name			: 'Mauritania',
		nameShort		: 'Mauritania',
		nameLong		: 'Islamic Republic of Mauritania',
		nameNative 		: 'موريتانيا',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MR],
		dialCode		: '+222',
		capital			: 'Nouakchott',
		domainTld		: '.mr',
		currencyCode	: EnumCurrency.MRU
	},
	[EnumCountry.MU]: {
		code			: EnumCountry.MU,
		name			: 'Mauritius',
		nameShort		: 'Mauritius',
		nameLong		: 'Republic of Mauritius',
		nameNative 		: 'Maurice',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MU],
		dialCode		: '+230',
		capital			: 'Port Louis',
		domainTld		: '.mu',
		currencyCode	: EnumCurrency.MUR
	},
	[EnumCountry.MA]: {
		code			: EnumCountry.MA,
		name			: 'Morocco',
		nameShort		: 'Morocco',
		nameLong		: 'Kingdom of Morocco',
		nameNative 		: 'المغرب',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MA],
		dialCode		: '+212',
		capital			: 'Rabat',
		domainTld		: '.ma',
		currencyCode	: EnumCurrency.MAD
	},
	[EnumCountry.MZ]: {
		code			: EnumCountry.MZ,
		name			: 'Mozambique',
		nameShort		: 'Mozambique',
		nameLong		: 'Republic of Mozambique',
		nameNative 		: 'Moçambique',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MZ],
		dialCode		: '+258',
		capital			: 'Maputo',
		domainTld		: '.mz',
		currencyCode	: EnumCurrency.MZN
	},
	[EnumCountry.NA]: {
		code			: EnumCountry.NA,
		name			: 'Namibia',
		nameShort		: 'Namibia',
		nameLong		: 'Republic of Namibia',
		nameNative 		: 'Namibia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NA],
		dialCode		: '+264',
		capital			: 'Windhoek',
		domainTld		: '.na',
		currencyCode	: EnumCurrency.NAD
	},
	[EnumCountry.NE]: {
		code			: EnumCountry.NE,
		name			: 'Niger',
		nameShort		: 'Niger',
		nameLong		: 'Republic of the Niger',
		nameNative 		: 'Niger',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NE],
		dialCode		: '+227',
		capital			: 'Niamey',
		domainTld		: '.ne',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.NG]: {
		code			: EnumCountry.NG,
		name			: 'Nigeria',
		nameShort		: 'Nigeria',
		nameLong		: 'Federal Republic of Nigeria',
		nameNative 		: 'Nigeria',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NG],
		dialCode		: '+234',
		capital			: 'Abuja',
		domainTld		: '.ng',
		currencyCode	: EnumCurrency.NGN
	},
	[EnumCountry.RW]: {
		code			: EnumCountry.RW,
		name			: 'Rwanda',
		nameShort		: 'Rwanda',
		nameLong		: 'Republic of Rwanda',
		nameNative 		: 'Rwanda',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.RW],
		dialCode		: '+250',
		capital			: 'Kigali',
		domainTld		: '.rw',
		currencyCode	: EnumCurrency.RWF
	},
	[EnumCountry.ST]: {
		code			: EnumCountry.ST,
		name			: 'São Tomé and Príncipe',
		nameShort		: 'São Tomé and Príncipe',
		nameLong		: 'Democratic Republic of São Tomé and Príncipe',
		nameNative 		: 'São Tomé e Príncipe',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ST],
		dialCode		: '+239',
		capital			: 'São Tomé',
		domainTld		: '.st',
		currencyCode	: EnumCurrency.STN
	},
	[EnumCountry.SN]: {
		code			: EnumCountry.SN,
		name			: 'Senegal',
		nameShort		: 'Senegal',
		nameLong		: 'Republic of Senegal',
		nameNative 		: 'Sénégal',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SN],
		dialCode		: '+221',
		capital			: 'Dakar',
		domainTld		: '.sn',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.SC]: {
		code			: EnumCountry.SC,
		name			: 'Seychelles',
		nameShort		: 'Seychelles',
		nameLong		: 'Republic of Seychelles',
		nameNative 		: 'Seychelles',
		languageCode	: [EnumLanguage.FR, EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SC],
		dialCode		: '+248',
		capital			: 'Victoria',
		domainTld		: '.sc',
		currencyCode	: EnumCurrency.SCR
	},
	[EnumCountry.SL]: {
		code			: EnumCountry.SL,
		name			: 'Sierra Leone',
		nameShort		: 'Sierra Leone',
		nameLong		: 'Republic of Sierra Leone',
		nameNative 		: 'Sierra Leone',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SL],
		dialCode		: '+232',
		capital			: 'Freetown',
		domainTld		: '.sl',
		currencyCode	: EnumCurrency.SLL
	},
	[EnumCountry.SO]: {
		code			: EnumCountry.SO,
		name			: 'Somalia',
		nameShort		: 'Somalia',
		nameLong		: 'Federal Republic of Somalia',
		nameNative 		: 'الصومال',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SO],
		dialCode		: '+252',
		capital			: 'Mogadishu',
		domainTld		: '.so',
		currencyCode	: EnumCurrency.SOS
	},
	[EnumCountry.ZA]: {
		code			: EnumCountry.ZA,
		name			: 'South Africa',
		nameShort		: 'South Africa',
		nameLong		: 'Republic of South Africa',
		nameNative 		: 'South Africa',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ZA],
		dialCode		: '+27',
		capital			: 'Pretoria',
		domainTld		: '.za',
		currencyCode	: EnumCurrency.ZAR
	},
	[EnumCountry.SS]: {
		code			: EnumCountry.SS,
		name			: 'South Sudan',
		nameShort		: 'South Sudan',
		nameLong		: 'Republic of South Sudan',
		nameNative 		: 'South Sudan',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SS],
		dialCode		: '+211',
		capital			: 'Juba',
		domainTld		: '.ss',
		currencyCode	: EnumCurrency.SSP
	},
	[EnumCountry.SD]: {
		code			: EnumCountry.SD,
		name			: 'Sudan',
		nameShort		: 'Sudan',
		nameLong		: 'Republic of the Sudan',
		nameNative 		: 'السودان',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SD],
		dialCode		: '+249',
		capital			: 'Khartoum',
		domainTld		: '.sd',
		currencyCode	: EnumCurrency.SDG
	},
	[EnumCountry.TZ]: {
		code			: EnumCountry.TZ,
		name			: 'Tanzania',
		nameShort		: 'Tanzania',
		nameLong		: 'United Republic of Tanzania',
		nameNative 		: 'Tanzania',
		languageCode	: [EnumLanguage.SW],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TZ],
		dialCode		: '+255',
		capital			: 'Dodoma',
		domainTld		: '.tz',
		currencyCode	: EnumCurrency.TZS
	},
	[EnumCountry.TG]: {
		code			: EnumCountry.TG,
		name			: 'Togo',
		nameShort		: 'Togo',
		nameLong		: 'Togolese Republic',
		nameNative 		: 'Togo',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TG],
		dialCode		: '+228',
		capital			: 'Lomé',
		domainTld		: '.tg',
		currencyCode	: EnumCurrency.XOF
	},
	[EnumCountry.TN]: {
		code			: EnumCountry.TN,
		name			: 'Tunisia',
		nameShort		: 'Tunisia',
		nameLong		: 'Republic of Tunisia',
		nameNative 		: 'تونس',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TN],
		dialCode		: '+216',
		capital			: 'Tunis',
		domainTld		: '.tn',
		currencyCode	: EnumCurrency.TND
	},
	[EnumCountry.UG]: {
		code			: EnumCountry.UG,
		name			: 'Uganda',
		nameShort		: 'Uganda',
		nameLong		: 'Republic of Uganda',
		nameNative 		: 'Uganda',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UG],
		dialCode		: '+256',
		capital			: 'Kampala',
		domainTld		: '.ug',
		currencyCode	: EnumCurrency.UGX
	},
	[EnumCountry.ZM]: {
		code			: EnumCountry.ZM,
		name			: 'Zambia',
		nameShort		: 'Zambia',
		nameLong		: 'Republic of Zambia',
		nameNative 		: 'Zambia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ZM],
		dialCode		: '+260',
		capital			: 'Lusaka',
		domainTld		: '.zm',
		currencyCode	: EnumCurrency.ZMW
	},
	[EnumCountry.ZW]: {
		code			: EnumCountry.ZW,
		name			: 'Zimbabwe',
		nameShort		: 'Zimbabwe',
		nameLong		: 'Republic of Zimbabwe',
		nameNative 		: 'Zimbabwe',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ZW],
		dialCode		: '+263',
		capital			: 'Harare',
		domainTld		: '.zw',
		currencyCode	: EnumCurrency.USD
	},

	// Antarctica
	[EnumCountry.AQ]: {
		code			: EnumCountry.AQ,
		name			: 'Antarctica',
		nameShort		: 'Antarctica',
		nameLong		: 'Antarctica',
		nameNative 		: 'Antarctica',
		languageCode	: [],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AQ],
		dialCode		: '+672',
		capital			: 'Antarctica',
		domainTld		: '.aq',
		currencyCode	: EnumCurrency.SRD
	},

	// Asia
	[EnumCountry.AF]: {
		code			: EnumCountry.AF,
		name			: 'Afghanistan',
		nameShort		: 'Afghanistan',
		nameLong		: 'Islamic Republic of Afghanistan',
		nameNative 		: 'افغانستان',
		languageCode	: [EnumLanguage.PS],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AF],
		dialCode		: '+93',
		capital			: 'Kabul',
		domainTld		: '.af',
		currencyCode	: EnumCurrency.AFN
	},
	[EnumCountry.AM]: {
		code			: EnumCountry.AM,
		name			: 'Armenia',
		nameShort		: 'Armenia',
		nameLong		: 'Republic of Armenia',
		nameNative 		: 'Հայաստան',
		languageCode	: [EnumLanguage.HY],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AM],
		dialCode		: '+374',
		capital			: 'Yerevan',
		domainTld		: '.am',
		currencyCode	: EnumCurrency.AMD
	},
	[EnumCountry.AZ]: {
		code			: EnumCountry.AZ,
		name			: 'Azerbaijan',
		nameShort		: 'Azerbaijan',
		nameLong		: 'Republic of Azerbaijan',
		nameNative 		: 'Azərbaycan',
		languageCode	: [EnumLanguage.AZ],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AZ],
		dialCode		: '+994',
		capital			: 'Baku',
		domainTld		: '.az',
		currencyCode	: EnumCurrency.AZN
	},
	[EnumCountry.BH]: {
		code			: EnumCountry.BH,
		name			: 'Bahrain',
		nameShort		: 'Bahrain',
		nameLong		: 'Kingdom of Bahrain',
		nameNative 		: 'البحرين',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BH],
		dialCode		: '+973',
		capital			: 'Manama',
		domainTld		: '.bh',
		currencyCode	: EnumCurrency.BHD
	},
	[EnumCountry.BD]: {
		code			: EnumCountry.BD,
		name			: 'Bangladesh',
		nameShort		: 'Bangladesh',
		nameLong		: 'People\'s Republic of Bangladesh',
		nameNative 		: 'বাংলাদেশ',
		languageCode	: [EnumLanguage.BN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BD],
		dialCode		: '+880',
		capital			: 'Dhaka',
		domainTld		: '.bd',
		currencyCode	: EnumCurrency.BDT
	},
	[EnumCountry.BT]: {
		code			: EnumCountry.BT,
		name			: 'Bhutan',
		nameShort		: 'Bhutan',
		nameLong		: 'Kingdom of Bhutan',
		nameNative 		: 'འབྲུག་ཡུལ',
		languageCode	: [EnumLanguage.DZ],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BT],
		dialCode		: '+975',
		capital			: 'Thimphu',
		domainTld		: '.bt',
		currencyCode	: EnumCurrency.BTN
	},
	[EnumCountry.BN]: {
		code			: EnumCountry.BN,
		name			: 'Brunei',
		nameShort		: 'Brunei',
		nameLong		: 'Nation of Brunei, the Abode of Peace',
		nameNative 		: 'Negara Brunei Darussalam',
		languageCode	: [EnumLanguage.MS],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BN],
		dialCode		: '+673',
		capital			: 'Bandar Seri Begawan',
		domainTld		: '.bn',
		currencyCode	: EnumCurrency.BND
	},
	[EnumCountry.KH]: {
		code			: EnumCountry.KH,
		name			: 'Cambodia',
		nameShort		: 'Cambodia',
		nameLong		: 'Kingdom of Cambodia',
		nameNative 		: 'កម្ពុជា',
		languageCode	: [EnumLanguage.KM],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KH],
		dialCode		: '+855',
		capital			: 'Phnom Penh',
		domainTld		: '.kh',
		currencyCode	: EnumCurrency.KHR
	},
	[EnumCountry.CN]: {
		code			: EnumCountry.CN,
		name			: 'China',
		nameShort		: 'China',
		nameLong		: 'People\'s Republic of China',
		nameNative 		: '中国',
		languageCode	: [EnumLanguage.ZH],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CN],
		dialCode		: '+86',
		capital			: 'Beijing',
		domainTld		: '.cn',
		currencyCode	: EnumCurrency.CNY
	},
	[EnumCountry.CY]: {
		code			: EnumCountry.CY,
		name			: 'Cyprus',
		nameShort		: 'Cyprus',
		nameLong		: 'Republic of Cyprus',
		nameNative 		: 'Κύπρος',
		languageCode	: [EnumLanguage.EL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CY],
		dialCode		: '+357',
		capital			: 'Nicosia',
		domainTld		: '.cy',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.GE]: {
		code			: EnumCountry.GE,
		name			: 'Georgia',
		nameShort		: 'Georgia',
		nameLong		: 'Georgia',
		nameNative 		: 'საქართველო',
		languageCode	: [EnumLanguage.KA],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GE],
		dialCode		: '+995',
		capital			: 'Tbilisi',
		domainTld		: '.ge',
		currencyCode	: EnumCurrency.GEL
	},
	[EnumCountry.IN]: {
		code			: EnumCountry.IN,
		name			: 'India',
		nameShort		: 'India',
		nameLong		: 'Republic of India',
		nameNative 		: 'भारत',
		languageCode	: [EnumLanguage.HI, EnumLanguage.PA, EnumLanguage.TA, EnumLanguage.TE, EnumLanguage.KN, EnumLanguage.MR, EnumLanguage.GU],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IN],
		dialCode		: '+91',
		capital			: 'New Delhi',
		domainTld		: '.in',
		currencyCode	: EnumCurrency.INR
	},
	[EnumCountry.ID]: {
		code			: EnumCountry.ID,
		name			: 'Indonesia',
		nameShort		: 'Indonesia',
		nameLong		: 'Republic of Indonesia',
		nameNative 		: 'Indonesia',
		languageCode	: [EnumLanguage.ID],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ID],
		dialCode		: '+62',
		capital			: 'Jakarta',
		domainTld		: '.id',
		currencyCode	: EnumCurrency.IDR
	},
	[EnumCountry.IR]: {
		code			: EnumCountry.IR,
		name			: 'Iran',
		nameShort		: 'Iran',
		nameLong		: 'Islamic Republic of Iran',
		nameNative 		: 'ایران',
		languageCode	: [EnumLanguage.FA],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IR],
		dialCode		: '+98',
		capital			: 'Tehran',
		domainTld		: '.ir',
		currencyCode	: EnumCurrency.IRR
	},
	[EnumCountry.IQ]: {
		code			: EnumCountry.IQ,
		name			: 'Iraq',
		nameShort		: 'Iraq',
		nameLong		: 'Republic of Iraq',
		nameNative 		: 'العراق',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IQ],
		dialCode		: '+964',
		capital			: 'Baghdad',
		domainTld		: '.iq',
		currencyCode	: EnumCurrency.IQD
	},
	[EnumCountry.IL]: {
		code			: EnumCountry.IL,
		name			: 'Israel',
		nameShort		: 'Israel',
		nameLong		: 'Israel',
		nameNative 		: 'ישראל',
		languageCode	: [EnumLanguage.HE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IL],
		dialCode		: '+972',
		capital			: 'Jerusalem',
		domainTld		: '.il',
		currencyCode	: EnumCurrency.ILS
	},
	[EnumCountry.JP]: {
		code			: EnumCountry.JP,
		name			: 'Japan',
		nameShort		: 'Japan',
		nameLong		: 'Japan',
		nameNative 		: '日本',
		languageCode	: [EnumLanguage.JA],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.JP],
		dialCode		: '+81',
		capital			: 'Tokyo',
		domainTld		: '.jp',
		currencyCode	: EnumCurrency.JPY
	},
	[EnumCountry.JO]: {
		code			: EnumCountry.JO,
		name			: 'Jordan',
		nameShort		: 'Jordan',
		nameLong		: 'Hashemite Kingdom of Jordan',
		nameNative 		: 'الأردن',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.JO],
		dialCode		: '+962',
		capital			: 'Amman',
		domainTld		: '.jo',
		currencyCode	: EnumCurrency.JOD
	},
	[EnumCountry.KZ]: {
		code			: EnumCountry.KZ,
		name			: 'Kazakhstan',
		nameShort		: 'Kazakhstan',
		nameLong		: 'Republic of Kazakhstan',
		nameNative 		: 'Қазақстан',
		languageCode	: [EnumLanguage.KK, EnumLanguage.RU],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KZ],
		dialCode		: '+7',
		capital			: 'Nur-Sultan',
		domainTld		: '.kz',
		currencyCode	: EnumCurrency.KZT
	},
	[EnumCountry.KW]: {
		code			: EnumCountry.KW,
		name			: 'Kuwait',
		nameShort		: 'Kuwait',
		nameLong		: 'State of Kuwait',
		nameNative 		: 'الكويت',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KW],
		dialCode		: '+965',
		capital			: 'Kuwait City',
		domainTld		: '.kw',
		currencyCode	: EnumCurrency.KWD
	},
	[EnumCountry.KG]: {
		code			: EnumCountry.KG,
		name			: 'Kyrgyzstan',
		nameShort		: 'Kyrgyzstan',
		nameLong		: 'Kyrgyz Republic',
		nameNative 		: 'Кыргызстан',
		languageCode	: [EnumLanguage.KY, EnumLanguage.RU],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KG],
		dialCode		: '+996',
		capital			: 'Bishkek',
		domainTld		: '.kg',
		currencyCode	: EnumCurrency.KGS
	},
	[EnumCountry.LA]: {
		code			: EnumCountry.LA,
		name			: 'Laos',
		nameShort		: 'Laos',
		nameLong		: 'Lao People\'s Democratic Republic',
		nameNative 		: 'ສປປລາວ',
		languageCode	: [EnumLanguage.LO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LA],
		dialCode		: '+856',
		capital			: 'Vientiane',
		domainTld		: '.la',
		currencyCode	: EnumCurrency.LAK
	},
	[EnumCountry.LB]: {
		code			: EnumCountry.LB,
		name			: 'Lebanon',
		nameShort		: 'Lebanon',
		nameLong		: 'Lebanese Republic',
		nameNative 		: 'لبنان',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LB],
		dialCode		: '+961',
		capital			: 'Beirut',
		domainTld		: '.lb',
		currencyCode	: EnumCurrency.LBP
	},
	[EnumCountry.MY]: {
		code			: EnumCountry.MY,
		name			: 'Malaysia',
		nameShort		: 'Malaysia',
		nameLong		: 'Malaysia',
		nameNative 		: 'Malaysia',
		languageCode	: [EnumLanguage.MS],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MY],
		dialCode		: '+60',
		capital			: 'Kuala Lumpur',
		domainTld		: '.my',
		currencyCode	: EnumCurrency.MYR
	},
	[EnumCountry.MV]: {
		code			: EnumCountry.MV,
		name			: 'Maldives',
		nameShort		: 'Maldives',
		nameLong		: 'Republic of Maldives',
		nameNative 		: 'ދިވެހިރާއްޖެ',
		languageCode	: [EnumLanguage.DV],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MV],
		dialCode		: '+960',
		capital			: 'Malé',
		domainTld		: '.mv',
		currencyCode	: EnumCurrency.MVR
	},
	[EnumCountry.MN]: {
		code			: EnumCountry.MN,
		name			: 'Mongolia',
		nameShort		: 'Mongolia',
		nameLong		: 'Mongolia',
		nameNative 		: 'Монгол улс',
		languageCode	: [EnumLanguage.MN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MN],
		dialCode		: '+976',
		capital			: 'Ulaanbaatar',
		domainTld		: '.mn',
		currencyCode	: EnumCurrency.MNT
	},
	[EnumCountry.MM]: {
		code			: EnumCountry.MM,
		name			: 'Myanmar',
		nameShort		: 'Myanmar',
		nameLong		: 'Republic of the Union of Myanmar',
		nameNative 		: 'မြန်မာ',
		languageCode	: [EnumLanguage.MY],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MM],
		dialCode		: '+95',
		capital			: 'Naypyidaw',
		domainTld		: '.mm',
		currencyCode	: EnumCurrency.MMK
	},
	[EnumCountry.NP]: {
		code			: EnumCountry.NP,
		name			: 'Nepal',
		nameShort		: 'Nepal',
		nameLong		: 'Federal Democratic Republic of Nepal',
		nameNative 		: 'नेपाल',
		languageCode	: [EnumLanguage.NE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NP],
		dialCode		: '+977',
		capital			: 'Kathmandu',
		domainTld		: '.np',
		currencyCode	: EnumCurrency.NPR
	},
	[EnumCountry.KP]: {
		code			: EnumCountry.KP,
		name			: 'North Korea',
		nameShort		: 'North Korea',
		nameLong		: 'Democratic People\'s Republic of Korea',
		nameNative 		: '북한',
		languageCode	: [EnumLanguage.KO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KP],
		dialCode		: '+850',
		capital			: 'Pyongyang',
		domainTld		: '.kp',
		currencyCode	: EnumCurrency.KPW
	},
	[EnumCountry.OM]: {
		code			: EnumCountry.OM,
		name			: 'Oman',
		nameShort		: 'Oman',
		nameLong		: 'Sultanate of Oman',
		nameNative 		: 'عُمان',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.OM],
		dialCode		: '+968',
		capital			: 'Muscat',
		domainTld		: '.om',
		currencyCode	: EnumCurrency.OMR
	},
	[EnumCountry.PK]: {
		code			: EnumCountry.PK,
		name			: 'Pakistan',
		nameShort		: 'Pakistan',
		nameLong		: 'Islamic Republic of Pakistan',
		nameNative 		: 'پاکستان',
		languageCode	: [EnumLanguage.UR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PK],
		dialCode		: '+92',
		capital			: 'Islamabad',
		domainTld		: '.pk',
		currencyCode	: EnumCurrency.PKR
	},
	[EnumCountry.PS]: {
		code			: EnumCountry.PS,
		name			: 'Palestine',
		nameShort		: 'Palestine',
		nameLong		: 'State of Palestine',
		nameNative 		: 'فلسطين',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PS],
		dialCode		: '+970',
		capital			: 'Ramallah',
		domainTld		: '.ps',
		currencyCode	: EnumCurrency.ILS
	},
	[EnumCountry.PH]: {
		code			: EnumCountry.PH,
		name			: 'Philippines',
		nameShort		: 'Philippines',
		nameLong		: 'Republic of the Philippines',
		nameNative 		: 'Pilipinas',
		languageCode	: [EnumLanguage.TL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PH],
		dialCode		: '+63',
		capital			: 'Manila',
		domainTld		: '.ph',
		currencyCode	: EnumCurrency.PHP
	},
	[EnumCountry.QA]: {
		code			: EnumCountry.QA,
		name			: 'Qatar',
		nameShort		: 'Qatar',
		nameLong		: 'State of Qatar',
		nameNative 		: 'قطر',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.QA],
		dialCode		: '+974',
		capital			: 'Doha',
		domainTld		: '.qa',
		currencyCode	: EnumCurrency.QAR
	},
	[EnumCountry.SA]: {
		code			: EnumCountry.SA,
		name			: 'Saudi Arabia',
		nameShort		: 'Saudi Arabia',
		nameLong		: 'Kingdom of Saudi Arabia',
		nameNative 		: 'العربية السعودية',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SA],
		dialCode		: '+966',
		capital			: 'Riyadh',
		domainTld		: '.sa',
		currencyCode	: EnumCurrency.SAR
	},
	[EnumCountry.SG]: {
		code			: EnumCountry.SG,
		name			: 'Singapore',
		nameShort		: 'Singapore',
		nameLong		: 'Republic of Singapore',
		nameNative 		: 'Singapore',
		languageCode	: [EnumLanguage.ZH, EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SG],
		dialCode		: '+65',
		capital			: 'Singapore',
		domainTld		: '.sg',
		currencyCode	: EnumCurrency.SGD
	},
	[EnumCountry.KR]: {
		code			: EnumCountry.KR,
		name			: 'South Korea',
		nameShort		: 'South Korea',
		nameLong		: 'Republic of Korea',
		nameNative 		: '대한민국',
		languageCode	: [EnumLanguage.KO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KR],
		dialCode		: '+82',
		capital			: 'Seoul',
		domainTld		: '.kr',
		currencyCode	: EnumCurrency.KRW
	},
	[EnumCountry.LK]: {
		code			: EnumCountry.LK,
		name			: 'Sri Lanka',
		nameShort		: 'Sri Lanka',
		nameLong		: 'Democratic Socialist Republic of Sri Lanka',
		nameNative 		: 'ශ්‍රී ලංකා',
		languageCode	: [EnumLanguage.SI, EnumLanguage.TA],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LK],
		dialCode		: '+94',
		capital			: 'Colombo',
		domainTld		: '.lk',
		currencyCode	: EnumCurrency.LKR
	},
	[EnumCountry.SY]: {
		code			: EnumCountry.SY,
		name			: 'Syria',
		nameShort		: 'Syria',
		nameLong		: 'Syrian Arab Republic',
		nameNative 		: 'سوريا',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SY],
		dialCode		: '+963',
		capital			: 'Damascus',
		domainTld		: '.sy',
		currencyCode	: EnumCurrency.SYP
	},
	[EnumCountry.TW]: {
		code			: EnumCountry.TW,
		name			: 'Taiwan',
		nameShort		: 'Taiwan',
		nameLong		: 'Republic of China',
		nameNative 		: '台灣',
		languageCode	: [EnumLanguage.ZH],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TW],
		dialCode		: '+886',
		capital			: 'Taipei',
		domainTld		: '.tw',
		currencyCode	: EnumCurrency.TWD
	},
	[EnumCountry.TJ]: {
		code			: EnumCountry.TJ,
		name			: 'Tajikistan',
		nameShort		: 'Tajikistan',
		nameLong		: 'Republic of Tajikistan',
		nameNative 		: 'Тоҷикистон',
		languageCode	: [EnumLanguage.TG],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TJ],
		dialCode		: '+992',
		capital			: 'Dushanbe',
		domainTld		: '.tj',
		currencyCode	: EnumCurrency.TJS
	},
	[EnumCountry.TH]: {
		code			: EnumCountry.TH,
		name			: 'Thailand',
		nameShort		: 'Thailand',
		nameLong		: 'Kingdom of Thailand',
		nameNative 		: 'ประเทศไทย',
		languageCode	: [EnumLanguage.TH],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TH],
		dialCode		: '+66',
		capital			: 'Bangkok',
		domainTld		: '.th',
		currencyCode	: EnumCurrency.THB
	},
	[EnumCountry.TL]: {
		code			: EnumCountry.TL,
		name			: 'Timor-Leste',
		nameShort		: 'Timor-Leste',
		nameLong		: 'Democratic Republic of Timor-Leste',
		nameNative 		: 'Timor-Leste',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TL],
		dialCode		: '+670',
		capital			: 'Dili',
		domainTld		: '.tl',
		currencyCode	: EnumCurrency.USD
	},
	[EnumCountry.TR]: {
		code			: EnumCountry.TR,
		name			: 'Turkey',
		nameShort		: 'Turkey',
		nameLong		: 'Republic of Turkey',
		nameNative 		: 'Türkiye',
		languageCode	: [EnumLanguage.TR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TR],
		dialCode		: '+90',
		capital			: 'Ankara',
		domainTld		: '.tr',
		currencyCode	: EnumCurrency.TRY
	},
	[EnumCountry.TM]: {
		code			: EnumCountry.TM,
		name			: 'Turkmenistan',
		nameShort		: 'Turkmenistan',
		nameLong		: 'Turkmenistan',
		nameNative 		: 'Türkmenistan',
		languageCode	: [EnumLanguage.TK],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TM],
		dialCode		: '+993',
		capital			: 'Ashgabat',
		domainTld		: '.tm',
		currencyCode	: EnumCurrency.TMT
	},
	[EnumCountry.AE]: {
		code			: EnumCountry.AE,
		name			: 'United Arab Emirates',
		nameShort		: 'United Arab Emirates',
		nameLong		: 'United Arab Emirates',
		nameNative 		: 'الإمارات العربية المتحدة',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AE],
		dialCode		: '+971',
		capital			: 'Abu Dhabi',
		domainTld		: '.ae',
		currencyCode	: EnumCurrency.AED
	},
	[EnumCountry.UZ]: {
		code			: EnumCountry.UZ,
		name			: 'Uzbekistan',
		nameShort		: 'Uzbekistan',
		nameLong		: 'Republic of Uzbekistan',
		nameNative 		: 'Oʻzbekiston',
		languageCode	: [EnumLanguage.UZ],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UZ],
		dialCode		: '+998',
		capital			: 'Tashkent',
		domainTld		: '.uz',
		currencyCode	: EnumCurrency.UZS
	},
	[EnumCountry.VN]: {
		code			: EnumCountry.VN,
		name			: 'Vietnam',
		nameShort		: 'Vietnam',
		nameLong		: 'Social Republic of Vietnam',
		nameNative 		: 'Việt Nam',
		languageCode	: [EnumLanguage.VI],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.VN],
		dialCode		: '+84',
		capital			: 'Hanoi',
		domainTld		: '.vn',
		currencyCode	: EnumCurrency.VND
	},
	[EnumCountry.YE]: {
		code			: EnumCountry.YE,
		name			: 'Yemen',
		nameShort		: 'Yemen',
		nameLong		: 'Republic of Yemen',
		nameNative 		: 'اليَمَن',
		languageCode	: [EnumLanguage.AR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.YE],
		dialCode		: '+967',
		capital			: 'Sana\'a',
		domainTld		: '.ye',
		currencyCode	: EnumCurrency.YER
	},

	// Europe
	[EnumCountry.AL]: {
		code			: EnumCountry.AL,
		name			: 'Albania',
		nameShort		: 'Albania',
		nameLong		: 'Republic of Albania',
		nameNative 		: 'Shqipëria',
		languageCode	: [EnumLanguage.SQ],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AL],
		dialCode		: '+355',
		capital			: 'Tirana',
		domainTld		: '.al',
		currencyCode	: EnumCurrency.ALL
	},
	[EnumCountry.AD]: {
		code			: EnumCountry.AD,
		name			: 'Andorra',
		nameShort		: 'Andorra',
		nameLong		: 'Principality of Andorra',
		nameNative 		: 'Andorra',
		languageCode	: [EnumLanguage.CA],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AD],
		dialCode		: '+376',
		capital			: 'Andorra la Vella',
		domainTld		: '.ad',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.AT]: {
		code			: EnumCountry.AT,
		name			: 'Austria',
		nameShort		: 'Austria',
		nameLong		: 'Republic of Austria',
		nameNative 		: 'Österreich',
		languageCode	: [EnumLanguage.DE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AT],
		dialCode		: '+43',
		capital			: 'Vienna',
		domainTld		: '.at',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.BY]: {
		code			: EnumCountry.BY,
		name			: 'Belarus',
		nameShort		: 'Belarus',
		nameLong		: 'Republic of Belarus',
		nameNative 		: 'Беларусь',
		languageCode	: [EnumLanguage.BE, EnumLanguage.RU],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BY],
		dialCode		: '+375',
		capital			: 'Minsk',
		domainTld		: '.by',
		currencyCode	: EnumCurrency.BYN
	},
	[EnumCountry.BE]: {
		code			: EnumCountry.BE,
		name			: 'Belgium',
		nameShort		: 'Belgium',
		nameLong		: 'Kingdom of Belgium',
		nameNative 		: 'België',
		languageCode	: [EnumLanguage.NL, EnumLanguage.FR, EnumLanguage.DE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BE],
		dialCode		: '+32',
		capital			: 'Brussels',
		domainTld		: '.be',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.BA]: {
		code			: EnumCountry.BA,
		name			: 'Bosnia and Herzegovina',
		nameShort		: 'Bosnia and Herzegovina',
		nameLong		: 'Bosnia and Herzegovina',
		nameNative 		: 'Bosna i Hercegovina',
		languageCode	: [EnumLanguage.BS, EnumLanguage.HR, EnumLanguage.SR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BA],
		dialCode		: '+387',
		capital			: 'Sarajevo',
		domainTld		: '.ba',
		currencyCode	: EnumCurrency.BAM
	},
	[EnumCountry.BG]: {
		code			: EnumCountry.BG,
		name			: 'Bulgaria',
		nameShort		: 'Bulgaria',
		nameLong		: 'Republic of Bulgaria',
		nameNative 		: 'България',
		languageCode	: [EnumLanguage.BG],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BG],
		dialCode		: '+359',
		capital			: 'Sofia',
		domainTld		: '.bg',
		currencyCode	: EnumCurrency.BGN
	},
	[EnumCountry.HR]: {
		code			: EnumCountry.HR,
		name			: 'Croatia',
		nameShort		: 'Croatia',
		nameLong		: 'Republic of Croatia',
		nameNative 		: 'Hrvatska',
		languageCode	: [EnumLanguage.HR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HR],
		dialCode		: '+385',
		capital			: 'Zagreb',
		domainTld		: '.hr',
		currencyCode	: EnumCurrency.HRK
	},
	[EnumCountry.CZ]: {
		code			: EnumCountry.CZ,
		name			: 'Czechia',
		nameShort		: 'Czechia',
		nameLong		: 'Czech Republic',
		nameNative 		: 'Česko',
		languageCode	: [EnumLanguage.CS],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CZ],
		dialCode		: '+420',
		capital			: 'Prague',
		domainTld		: '.cz',
		currencyCode	: EnumCurrency.CZK
	},
	[EnumCountry.DK]: {
		code			: EnumCountry.DK,
		name			: 'Denmark',
		nameShort		: 'Denmark',
		nameLong		: 'Kingdom of Denmark',
		nameNative 		: 'Danmark',
		languageCode	: [EnumLanguage.DA],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DK],
		dialCode		: '+45',
		capital			: 'Copenhagen',
		domainTld		: '.dk',
		currencyCode	: EnumCurrency.DKK
	},
	[EnumCountry.EE]: {
		code			: EnumCountry.EE,
		name			: 'Estonia',
		nameShort		: 'Estonia',
		nameLong		: 'Republic of Estonia',
		nameNative 		: 'Eesti',
		languageCode	: [EnumLanguage.ET],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.EE],
		dialCode		: '+372',
		capital			: 'Tallinn',
		domainTld		: '.ee',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.FI]: {
		code			: EnumCountry.FI,
		name			: 'Finland',
		nameShort		: 'Finland',
		nameLong		: 'Republic of Finland',
		nameNative 		: 'Suomi',
		languageCode	: [EnumLanguage.FI],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FI],
		dialCode		: '+358',
		capital			: 'Helsinki',
		domainTld		: '.fi',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.FR]: {
		code			: EnumCountry.FR,
		name			: 'France',
		nameShort		: 'France',
		nameLong		: 'French Republic',
		nameNative 		: 'France',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FR],
		dialCode		: '+33',
		capital			: 'Paris',
		domainTld		: '.fr',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.DE]: {
		code			: EnumCountry.DE,
		name			: 'Germany',
		nameShort		: 'Germany',
		nameLong		: 'Federal Republic of Germany',
		nameNative 		: 'Deutschland',
		languageCode	: [EnumLanguage.DE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DE],
		dialCode		: '+49',
		capital			: 'Berlin',
		domainTld		: '.de',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.GR]: {
		code			: EnumCountry.GR,
		name			: 'Greece',
		nameShort		: 'Greece',
		nameLong		: 'Hellenic Republic',
		nameNative 		: 'Ελλάδα',
		languageCode	: [EnumLanguage.EL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GR],
		dialCode		: '+30',
		capital			: 'Athens',
		domainTld		: '.gr',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.HU]: {
		code			: EnumCountry.HU,
		name			: 'Hungary',
		nameShort		: 'Hungary',
		nameLong		: 'Hungary',
		nameNative 		: 'Magyarország',
		languageCode	: [EnumLanguage.HU],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HU],
		dialCode		: '+36',
		capital			: 'Budapest',
		domainTld		: '.hu',
		currencyCode	: EnumCurrency.HUF
	},
	[EnumCountry.IS]: {
		code			: EnumCountry.IS,
		name			: 'Iceland',
		nameShort		: 'Iceland',
		nameLong		: 'Republic of Iceland',
		nameNative 		: 'Ísland',
		languageCode	: [EnumLanguage.IS],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IS],
		dialCode		: '+354',
		capital			: 'Reykjavik',
		domainTld		: '.is',
		currencyCode	: EnumCurrency.ISK
	},
	[EnumCountry.IE]: {
		code			: EnumCountry.IE,
		name			: 'Ireland',
		nameShort		: 'Ireland',
		nameLong		: 'Ireland',
		nameNative 		: 'Éire',
		languageCode	: [EnumLanguage.GA, EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IE],
		dialCode		: '+353',
		capital			: 'Dublin',
		domainTld		: '.ie',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.IT]: {
		code			: EnumCountry.IT,
		name			: 'Italy',
		nameShort		: 'Italy',
		nameLong		: 'Italian Republic',
		nameNative 		: 'Italia',
		languageCode	: [EnumLanguage.IT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IT],
		dialCode		: '+39',
		capital			: 'Rome',
		domainTld		: '.it',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.XK]: {
		code			: EnumCountry.XK,
		name			: 'Kosovo',
		nameShort		: 'Kosovo',
		nameLong		: 'Republic of Kosovo',
		nameNative 		: 'Kosova',
		languageCode	: [EnumLanguage.SQ],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.XK],
		dialCode		: '+383',
		capital			: 'Pristina',
		domainTld		: '.xk',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.LV]: {
		code			: EnumCountry.LV,
		name			: 'Latvia',
		nameShort		: 'Latvia',
		nameLong		: 'Republic of Latvia',
		nameNative 		: 'Latvija',
		languageCode	: [EnumLanguage.LV],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LV],
		dialCode		: '+371',
		capital			: 'Riga',
		domainTld		: '.lv',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.LI]: {
		code			: EnumCountry.LI,
		name			: 'Liechtenstein',
		nameShort		: 'Liechtenstein',
		nameLong		: 'Principality of Liechtenstein',
		nameNative 		: 'Liechtenstein',
		languageCode	: [EnumLanguage.DE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LI],
		dialCode		: '+423',
		capital			: 'Vaduz',
		domainTld		: '.li',
		currencyCode	: EnumCurrency.CHF
	},
	[EnumCountry.LT]: {
		code			: EnumCountry.LT,
		name			: 'Lithuania',
		nameShort		: 'Lithuania',
		nameLong		: 'Republic of Lithuania',
		nameNative 		: 'Lietuva',
		languageCode	: [EnumLanguage.LT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LT],
		dialCode		: '+370',
		capital			: 'Vilnius',
		domainTld		: '.lt',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.LU]: {
		code			: EnumCountry.LU,
		name			: 'Luxembourg',
		nameShort		: 'Luxembourg',
		nameLong		: 'Grand Duchy of Luxembourg',
		nameNative 		: 'Luxembourg',
		languageCode	: [EnumLanguage.LB, EnumLanguage.FR, EnumLanguage.DE],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LU],
		dialCode		: '+352',
		capital			: 'Luxembourg',
		domainTld		: '.lu',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.MT]: {
		code			: EnumCountry.MT,
		name			: 'Malta',
		nameShort		: 'Malta',
		nameLong		: 'Republic of Malta',
		nameNative 		: 'Malta',
		languageCode	: [EnumLanguage.MT, EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MT],
		dialCode		: '+356',
		capital			: 'Valletta',
		domainTld		: '.mt',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.MD]: {
		code			: EnumCountry.MD,
		name			: 'Moldova',
		nameShort		: 'Moldova',
		nameLong		: 'Republic of Moldova',
		nameNative 		: 'Moldova',
		languageCode	: [EnumLanguage.RO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MD],
		dialCode		: '+373',
		capital			: 'Chisinau',
		domainTld		: '.md',
		currencyCode	: EnumCurrency.MDL
	},
	[EnumCountry.MC]: {
		code			: EnumCountry.MC,
		name			: 'Monaco',
		nameShort		: 'Monaco',
		nameLong		: 'Principality of Monaco',
		nameNative 		: 'Monaco',
		languageCode	: [EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MC],
		dialCode		: '+377',
		capital			: 'Monaco',
		domainTld		: '.mc',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.ME]: {
		code			: EnumCountry.ME,
		name			: 'Montenegro',
		nameShort		: 'Montenegro',
		nameLong		: 'Montenegro',
		nameNative 		: 'Црна Гора',
		languageCode	: [EnumLanguage.SR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ME],
		dialCode		: '+382',
		capital			: 'Podgorica',
		domainTld		: '.me',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.NL]: {
		code			: EnumCountry.NL,
		name			: 'Netherlands',
		nameShort		: 'Netherlands',
		nameLong		: 'Kingdom of the Netherlands',
		nameNative 		: 'Nederland',
		languageCode	: [EnumLanguage.NL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NL],
		dialCode		: '+31',
		capital			: 'Amsterdam',
		domainTld		: '.nl',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.MK]: {
		code			: EnumCountry.MK,
		name			: 'North Macedonia',
		nameShort		: 'North Macedonia',
		nameLong		: 'Republic of North Macedonia',
		nameNative 		: 'Северна Македонија',
		languageCode	: [EnumLanguage.MK],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MK],
		dialCode		: '+389',
		capital			: 'Skopje',
		domainTld		: '.mk',
		currencyCode	: EnumCurrency.MKD
	},
	[EnumCountry.NO]: {
		code			: EnumCountry.NO,
		name			: 'Norway',
		nameShort		: 'Norway',
		nameLong		: 'Kingdom of Norway',
		nameNative 		: 'Norge',
		languageCode	: [EnumLanguage.NO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NO],
		dialCode		: '+47',
		capital			: 'Oslo',
		domainTld		: '.no',
		currencyCode	: EnumCurrency.NOK
	},
	[EnumCountry.PL]: {
		code			: EnumCountry.PL,
		name			: 'Poland',
		nameShort		: 'Poland',
		nameLong		: 'Republic of Poland',
		nameNative 		: 'Polska',
		languageCode	: [EnumLanguage.PL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PL],
		dialCode		: '+48',
		capital			: 'Warsaw',
		domainTld		: '.pl',
		currencyCode	: EnumCurrency.PLN
	},
	[EnumCountry.PT]: {
		code			: EnumCountry.PT,
		name			: 'Portugal',
		nameShort		: 'Portugal',
		nameLong		: 'Portuguese Republic',
		nameNative 		: 'Portugal',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PT],
		dialCode		: '+351',
		capital			: 'Lisbon',
		domainTld		: '.pt',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.RO]: {
		code			: EnumCountry.RO,
		name			: 'Romania',
		nameShort		: 'Romania',
		nameLong		: 'Romania',
		nameNative 		: 'România',
		languageCode	: [EnumLanguage.RO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.RO],
		dialCode		: '+40',
		capital			: 'Bucharest',
		domainTld		: '.ro',
		currencyCode	: EnumCurrency.RON
	},
	[EnumCountry.RU]: {
		code			: EnumCountry.RU,
		name			: 'Russia',
		nameShort		: 'Russia',
		nameLong		: 'Russian Federation',
		nameNative 		: 'Россия',
		languageCode	: [EnumLanguage.RU],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.RU],
		dialCode		: '+7',
		capital			: 'Moscow',
		domainTld		: '.ru',
		currencyCode	: EnumCurrency.RUB
	},
	[EnumCountry.SM]: {
		code			: EnumCountry.SM,
		name			: 'San Marino',
		nameShort		: 'San Marino',
		nameLong		: 'Republic of San Marino',
		nameNative 		: 'San Marino',
		languageCode	: [EnumLanguage.IT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SM],
		dialCode		: '+378',
		capital			: 'San Marino',
		domainTld		: '.sm',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.RS]: {
		code			: EnumCountry.RS,
		name			: 'Serbia',
		nameShort		: 'Serbia',
		nameLong		: 'Republic of Serbia',
		nameNative 		: 'Србија',
		languageCode	: [EnumLanguage.SR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.RS],
		dialCode		: '+381',
		capital			: 'Belgrade',
		domainTld		: '.rs',
		currencyCode	: EnumCurrency.RSD
	},
	[EnumCountry.SK]: {
		code			: EnumCountry.SK,
		name			: 'Slovakia',
		nameShort		: 'Slovakia',
		nameLong		: 'Slovak Republic',
		nameNative 		: 'Slovensko',
		languageCode	: [EnumLanguage.SK],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SK],
		dialCode		: '+421',
		capital			: 'Bratislava',
		domainTld		: '.sk',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.SI]: {
		code			: EnumCountry.SI,
		name			: 'Slovenia',
		nameShort		: 'Slovenia',
		nameLong		: 'Republic of Slovenia',
		nameNative 		: 'Slovenija',
		languageCode	: [EnumLanguage.SL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SI],
		dialCode		: '+386',
		capital			: 'Ljubljana',
		domainTld		: '.si',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.ES]: {
		code			: EnumCountry.ES,
		name			: 'Spain',
		nameShort		: 'Spain',
		nameLong		: 'Kingdom of Spain',
		nameNative 		: 'España',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ES],
		dialCode		: '+34',
		capital			: 'Madrid',
		domainTld		: '.es',
		currencyCode	: EnumCurrency.EUR
	},
	[EnumCountry.SE]: {
		code			: EnumCountry.SE,
		name			: 'Sweden',
		nameShort		: 'Sweden',
		nameLong		: 'Kingdom of Sweden',
		nameNative 		: 'Sverige',
		languageCode	: [EnumLanguage.SV],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SE],
		dialCode		: '+46',
		capital			: 'Stockholm',
		domainTld		: '.se',
		currencyCode	: EnumCurrency.SEK
	},
	[EnumCountry.CH]: {
		code			: EnumCountry.CH,
		name			: 'Switzerland',
		nameShort		: 'Switzerland',
		nameLong		: 'Swiss Confederation',
		nameNative 		: 'Schweiz',
		languageCode	: [EnumLanguage.DE, EnumLanguage.FR, EnumLanguage.IT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CH],
		dialCode		: '+41',
		capital			: 'Bern',
		domainTld		: '.ch',
		currencyCode	: EnumCurrency.CHF
	},
	[EnumCountry.UA]: {
		code			: EnumCountry.UA,
		name			: 'Ukraine',
		nameShort		: 'Ukraine',
		nameLong		: 'Ukraine',
		nameNative 		: 'Україна',
		languageCode	: [EnumLanguage.UK],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UA],
		dialCode		: '+380',
		capital			: 'Kiev',
		domainTld		: '.ua',
		currencyCode	: EnumCurrency.UAH
	},
	[EnumCountry.UK]: {
		code			: EnumCountry.UK,
		name			: 'United Kingdom',
		nameShort		: 'United Kingdom',
		nameLong		: 'United Kingdom of Great Britain and Northern Ireland',
		nameNative 		: 'United Kingdom',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UK],
		dialCode		: '+44',
		capital			: 'London',
		domainTld		: '.uk',
		currencyCode	: EnumCurrency.GBP
	},
	[EnumCountry.VA]: {
		code			: EnumCountry.VA,
		name			: 'Vatican City',
		nameShort		: 'Vatican City',
		nameLong		: 'Vatican City State',
		nameNative 		: 'Vaticano',
		languageCode	: [EnumLanguage.LA, EnumLanguage.IT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.VA],
		dialCode		: '+379',
		capital			: 'Vatican City',
		domainTld		: '.va',
		currencyCode	: EnumCurrency.EUR
	},

	// North America
	[EnumCountry.AG]: {
		code			: EnumCountry.AG,
		name			: 'Antigua and Barbuda',
		nameShort		: 'Antigua and Barbuda',
		nameLong		: 'Antigua and Barbuda',
		nameNative 		: 'Antigua and Barbuda',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AG],
		dialCode		: '+1',
		capital			: 'St. John\'s',
		domainTld		: '.ag',
		currencyCode	: EnumCurrency.XCD
	},
	[EnumCountry.BS]: {
		code			: EnumCountry.BS,
		name			: 'Bahamas',
		nameShort		: 'Bahamas',
		nameLong		: 'Commonwealth of The Bahamas',
		nameNative 		: 'Bahamas',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BS],
		dialCode		: '+1',
		capital			: 'Nassau',
		domainTld		: '.bs',
		currencyCode	: EnumCurrency.BSD
	},
	[EnumCountry.BB]: {
		code			: EnumCountry.BB,
		name			: 'Barbados',
		nameShort		: 'Barbados',
		nameLong		: 'Barbados',
		nameNative 		: 'Barbados',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BB],
		dialCode		: '+1',
		capital			: 'Bridgetown',
		domainTld		: '.bb',
		currencyCode	: EnumCurrency.BBD
	},
	[EnumCountry.BZ]: {
		code			: EnumCountry.BZ,
		name			: 'Belize',
		nameShort		: 'Belize',
		nameLong		: 'Belize',
		nameNative 		: 'Belize',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BZ],
		dialCode		: '+501',
		capital			: 'Belmopan',
		domainTld		: '.bz',
		currencyCode	: EnumCurrency.BZD
	},
	[EnumCountry.CA]: {
		code			: EnumCountry.CA,
		name			: 'Canada',
		nameShort		: 'Canada',
		nameLong		: 'Canada',
		nameNative 		: 'Canada',
		languageCode	: [EnumLanguage.EN, EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CA],
		dialCode		: '+1',
		capital			: 'Ottawa',
		domainTld		: '.ca',
		currencyCode	: EnumCurrency.CAD
	},
	[EnumCountry.CR]: {
		code			: EnumCountry.CR,
		name			: 'Costa Rica',
		nameShort		: 'Costa Rica',
		nameLong		: 'Republic of Costa Rica',
		nameNative 		: 'Costa Rica',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CR],
		dialCode		: '+506',
		capital			: 'San José',
		domainTld		: '.cr',
		currencyCode	: EnumCurrency.CRC
	},
	[EnumCountry.CU]: {
		code			: EnumCountry.CU,
		name			: 'Cuba',
		nameShort		: 'Cuba',
		nameLong		: 'Republic of Cuba',
		nameNative 		: 'Cuba',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CU],
		dialCode		: '+53',
		capital			: 'Havana',
		domainTld		: '.cu',
		currencyCode	: EnumCurrency.CUP
	},
	[EnumCountry.DM]: {
		code			: EnumCountry.DM,
		name			: 'Dominica',
		nameShort		: 'Dominica',
		nameLong		: 'Commonwealth of Dominica',
		nameNative 		: 'Dominica',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DM],
		dialCode		: '+1',
		capital			: 'Roseau',
		domainTld		: '.dm',
		currencyCode	: EnumCurrency.XCD
	},
	[EnumCountry.DO]: {
		code			: EnumCountry.DO,
		name			: 'Dominican Republic',
		nameShort		: 'Dominican Republic',
		nameLong		: 'Dominican Republic',
		nameNative 		: 'República Dominicana',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DO],
		dialCode		: '+1',
		capital			: 'Santo Domingo',
		domainTld		: '.do',
		currencyCode	: EnumCurrency.DOP
	},
	[EnumCountry.SV]: {
		code			: EnumCountry.SV,
		name			: 'El Salvador',
		nameShort		: 'El Salvador',
		nameLong		: 'Republic of El Salvador',
		nameNative 		: 'El Salvador',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SV],
		dialCode		: '+503',
		capital			: 'San Salvador',
		domainTld		: '.sv',
		currencyCode	: EnumCurrency.USD
	},
	[EnumCountry.GD]: {
		code			: EnumCountry.GD,
		name			: 'Grenada',
		nameShort		: 'Grenada',
		nameLong		: 'Grenada',
		nameNative 		: 'Grenada',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GD],
		dialCode		: '+1',
		capital			: 'St. George\'s',
		domainTld		: '.gd',
		currencyCode	: EnumCurrency.XCD
	},
	[EnumCountry.GT]: {
		code			: EnumCountry.GT,
		name			: 'Guatemala',
		nameShort		: 'Guatemala',
		nameLong		: 'Republic of Guatemala',
		nameNative 		: 'Guatemala',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GT],
		dialCode		: '+502',
		capital			: 'Guatemala City',
		domainTld		: '.gt',
		currencyCode	: EnumCurrency.GTQ
	},
	[EnumCountry.HT]: {
		code			: EnumCountry.HT,
		name			: 'Haiti',
		nameShort		: 'Haiti',
		nameLong		: 'Republic of Haiti',
		nameNative 		: 'Haïti',
		languageCode	: [EnumLanguage.FR, EnumLanguage.HT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HT],
		dialCode		: '+509',
		capital			: 'Port-au-Prince',
		domainTld		: '.ht',
		currencyCode	: EnumCurrency.HTG
	},
	[EnumCountry.HN]: {
		code			: EnumCountry.HN,
		name			: 'Honduras',
		nameShort		: 'Honduras',
		nameLong		: 'Republic of Honduras',
		nameNative 		: 'Honduras',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HN],
		dialCode		: '+504',
		capital			: 'Tegucigalpa',
		domainTld		: '.hn',
		currencyCode	: EnumCurrency.HNL
	},
	[EnumCountry.JM]: {
		code			: EnumCountry.JM,
		name			: 'Jamaica',
		nameShort		: 'Jamaica',
		nameLong		: 'Jamaica',
		nameNative 		: 'Jamaica',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.JM],
		dialCode		: '+1',
		capital			: 'Kingston',
		domainTld		: '.jm',
		currencyCode	: EnumCurrency.JMD
	},
	[EnumCountry.MX]: {
		code			: EnumCountry.MX,
		name			: 'Mexico',
		nameShort		: 'Mexico',
		nameLong		: 'United Mexican States',
		nameNative 		: 'México',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MX],
		dialCode		: '+52',
		capital			: 'Mexico City',
		domainTld		: '.mx',
		currencyCode	: EnumCurrency.MXN
	},
	[EnumCountry.NI]: {
		code			: EnumCountry.NI,
		name			: 'Nicaragua',
		nameShort		: 'Nicaragua',
		nameLong		: 'Republic of Nicaragua',
		nameNative 		: 'Nicaragua',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NI],
		dialCode		: '+505',
		capital			: 'Managua',
		domainTld		: '.ni',
		currencyCode	: EnumCurrency.NIO
	},
	[EnumCountry.PA]: {
		code			: EnumCountry.PA,
		name			: 'Panama',
		nameShort		: 'Panama',
		nameLong		: 'Republic of Panama',
		nameNative 		: 'Panamá',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PA],
		dialCode		: '+507',
		capital			: 'Panama City',
		domainTld		: '.pa',
		currencyCode	: EnumCurrency.PAB
	},
	[EnumCountry.KN]: {
		code			: EnumCountry.KN,
		name			: 'Saint Kitts and Nevis',
		nameShort		: 'Saint Kitts and Nevis',
		nameLong		: 'Federation of Saint Christopher and Nevis',
		nameNative 		: 'Saint Kitts and Nevis',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KN],
		dialCode		: '+1',
		capital			: 'Basseterre',
		domainTld		: '.kn',
		currencyCode	: EnumCurrency.XCD
	},
	[EnumCountry.LC]: {
		code			: EnumCountry.LC,
		name			: 'Saint Lucia',
		nameShort		: 'Saint Lucia',
		nameLong		: 'Saint Lucia',
		nameNative 		: 'Saint Lucia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LC],
		dialCode		: '+1',
		capital			: 'Castries',
		domainTld		: '.lc',
		currencyCode	: EnumCurrency.XCD
	},
	[EnumCountry.VC]: {
		code			: EnumCountry.VC,
		name			: 'Saint Vincent and the Grenadines',
		nameShort		: 'Saint Vincent and the Grenadines',
		nameLong		: 'Saint Vincent and the Grenadines',
		nameNative 		: 'Saint Vincent and the Grenadines',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.VC],
		dialCode		: '+1',
		capital			: 'Kingstown',
		domainTld		: '.vc',
		currencyCode	: EnumCurrency.XCD
	},
	[EnumCountry.TT]: {
		code			: EnumCountry.TT,
		name			: 'Trinidad and Tobago',
		nameShort		: 'Trinidad and Tobago',
		nameLong		: 'Republic of Trinidad and Tobago',
		nameNative 		: 'Trinidad and Tobago',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TT],
		dialCode		: '+1',
		capital			: 'Port of Spain',
		domainTld		: '.tt',
		currencyCode	: EnumCurrency.TTD
	},
	[EnumCountry.US]: {
		code			: EnumCountry.US,
		name			: 'United States',
		nameShort		: 'United States',
		nameLong		: 'United States of America',
		nameNative 		: 'United States',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.US],
		dialCode		: '+1',
		capital			: 'Washington, D.C.',
		domainTld		: '.us',
		currencyCode	: EnumCurrency.USD
	},

	// Oceania
	[EnumCountry.AU]: {
		code			: EnumCountry.AU,
		name			: 'Australia',
		nameShort		: 'Australia',
		nameLong		: 'Commonwealth of Australia',
		nameNative 		: 'Australia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AU],
		dialCode		: '+61',
		capital			: 'Canberra',
		domainTld		: '.au',
		currencyCode	: EnumCurrency.AUD
	},
	[EnumCountry.FJ]: {
		code			: EnumCountry.FJ,
		name			: 'Fiji',
		nameShort		: 'Fiji',
		nameLong		: 'Republic of Fiji',
		nameNative 		: 'Fiji',
		languageCode	: [EnumLanguage.EN, EnumLanguage.FJ],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FJ],
		dialCode		: '+679',
		capital			: 'Suva',
		domainTld		: '.fj',
		currencyCode	: EnumCurrency.FJD
	},
	[EnumCountry.KI]: {
		code			: EnumCountry.KI,
		name			: 'Kiribati',
		nameShort		: 'Kiribati',
		nameLong		: 'Republic of Kiribati',
		nameNative 		: 'Kiribati',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KI],
		dialCode		: '+686',
		capital			: 'Tarawa',
		domainTld		: '.ki',
		currencyCode	: EnumCurrency.AUD
	},
	[EnumCountry.MH]: {
		code			: EnumCountry.MH,
		name			: 'Marshall Islands',
		nameShort		: 'Marshall Islands',
		nameLong		: 'Republic of the Marshall Islands',
		nameNative 		: 'Marshall Islands',
		languageCode	: [EnumLanguage.EN, EnumLanguage.MH],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MH],
		dialCode		: '+692',
		capital			: 'Majuro',
		domainTld		: '.mh',
		currencyCode	: EnumCurrency.USD
	},
	[EnumCountry.FM]: {
		code			: EnumCountry.FM,
		name			: 'Micronesia',
		nameShort		: 'Micronesia',
		nameLong		: 'Federated States of Micronesia',
		nameNative 		: 'Micronesia',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FM],
		dialCode		: '+691',
		capital			: 'Palikir',
		domainTld		: '.fm',
		currencyCode	: EnumCurrency.USD
	},
	[EnumCountry.NR]: {
		code			: EnumCountry.NR,
		name			: 'Nauru',
		nameShort		: 'Nauru',
		nameLong		: 'Republic of Nauru',
		nameNative 		: 'Nauru',
		languageCode	: [EnumLanguage.EN, EnumLanguage.NR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NR],
		dialCode		: '+674',
		capital			: 'Yaren',
		domainTld		: '.nr',
		currencyCode	: EnumCurrency.AUD
	},
	[EnumCountry.NZ]: {
		code			: EnumCountry.NZ,
		name			: 'New Zealand',
		nameShort		: 'New Zealand',
		nameLong		: 'New Zealand',
		nameNative 		: 'New Zealand',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NZ],
		dialCode		: '+64',
		capital			: 'Wellington',
		domainTld		: '.nz',
		currencyCode	: EnumCurrency.NZD
	},
	[EnumCountry.PW]: {
		code			: EnumCountry.PW,
		name			: 'Palau',
		nameShort		: 'Palau',
		nameLong		: 'Republic of Palau',
		nameNative 		: 'Palau',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PW],
		dialCode		: '+680',
		capital			: 'Ngerulmud',
		domainTld		: '.pw',
		currencyCode	: EnumCurrency.USD
	},
	[EnumCountry.PG]: {
		code			: EnumCountry.PG,
		name			: 'Papua New Guinea',
		nameShort		: 'Papua New Guinea',
		nameLong		: 'Independent State of Papua New Guinea',
		nameNative 		: 'Papua New Guinea',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PG],
		dialCode		: '+675',
		capital			: 'Port Moresby',
		domainTld		: '.pg',
		currencyCode	: EnumCurrency.PGK
	},
	[EnumCountry.WS]: {
		code			: EnumCountry.WS,
		name			: 'Samoa',
		nameShort		: 'Samoa',
		nameLong		: 'Independent State of Samoa',
		nameNative 		: 'Samoa',
		languageCode	: [EnumLanguage.EN, EnumLanguage.SM],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.WS],
		dialCode		: '+685',
		capital			: 'Apia',
		domainTld		: '.ws',
		currencyCode	: EnumCurrency.WST
	},
	[EnumCountry.SB]: {
		code			: EnumCountry.SB,
		name			: 'Solomon Islands',
		nameShort		: 'Solomon Islands',
		nameLong		: 'Solomon Islands',
		nameNative 		: 'Solomon Islands',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SB],
		dialCode		: '+677',
		capital			: 'Honiara',
		domainTld		: '.sb',
		currencyCode	: EnumCurrency.SBD
	},
	[EnumCountry.TO]: {
		code			: EnumCountry.TO,
		name			: 'Tonga',
		nameShort		: 'Tonga',
		nameLong		: 'Kingdom of Tonga',
		nameNative 		: 'Tonga',
		languageCode	: [EnumLanguage.EN, EnumLanguage.TO],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TO],
		dialCode		: '+676',
		capital			: 'Nukuʻalofa',
		domainTld		: '.to',
		currencyCode	: EnumCurrency.TOP
	},
	[EnumCountry.TV]: {
		code			: EnumCountry.TV,
		name			: 'Tuvalu',
		nameShort		: 'Tuvalu',
		nameLong		: 'Tuvalu',
		nameNative 		: 'Tuvalu',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TV],
		dialCode		: '+688',
		capital			: 'Funafuti',
		domainTld		: '.tv',
		currencyCode	: EnumCurrency.AUD
	},
	[EnumCountry.VU]: {
		code			: EnumCountry.VU,
		name			: 'Vanuatu',
		nameShort		: 'Vanuatu',
		nameLong		: 'Republic of Vanuatu',
		nameNative 		: 'Vanuatu',
		languageCode	: [EnumLanguage.EN, EnumLanguage.BI, EnumLanguage.FR],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.VU],
		dialCode		: '+678',
		capital			: 'Port Vila',
		domainTld		: '.vu',
		currencyCode	: EnumCurrency.VUV
	},

	// South America
	[EnumCountry.AR]: {
		code			: EnumCountry.AR,
		name			: 'Argentina',
		nameShort		: 'Argentina',
		nameLong		: 'Argentine Republic',
		nameNative 		: 'Argentina',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AR],
		dialCode		: '+54',
		capital			: 'Buenos Aires',
		domainTld		: '.ar',
		currencyCode	: EnumCurrency.ARS
	},
	[EnumCountry.BO]: {
		code			: EnumCountry.BO,
		name			: 'Bolivia',
		nameShort		: 'Bolivia',
		nameLong		: 'Plurinational State of Bolivia',
		nameNative 		: 'Bolivia',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BO],
		dialCode		: '+591',
		capital			: 'Sucre',
		domainTld		: '.bo',
		currencyCode	: EnumCurrency.BOB
	},
	[EnumCountry.BR]: {
		code			: EnumCountry.BR,
		name			: 'Brazil',
		nameShort		: 'Brazil',
		nameLong		: 'Federative Republic of Brazil',
		nameNative 		: 'Brasil',
		languageCode	: [EnumLanguage.PT],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BR],
		dialCode		: '+55',
		capital			: 'Brasília',
		domainTld		: '.br',
		currencyCode	: EnumCurrency.BRL
	},
	[EnumCountry.CL]: {
		code			: EnumCountry.CL,
		name			: 'Chile',
		nameShort		: 'Chile',
		nameLong		: 'Republic of Chile',
		nameNative 		: 'Chile',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CL],
		dialCode		: '+56',
		capital			: 'Santiago',
		domainTld		: '.cl',
		currencyCode	: EnumCurrency.CLP
	},
	[EnumCountry.CO]: {
		code			: EnumCountry.CO,
		name			: 'Colombia',
		nameShort		: 'Colombia',
		nameLong		: 'Republic of Colombia',
		nameNative 		: 'Colombia',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CO],
		dialCode		: '+57',
		capital			: 'Bogotá',
		domainTld		: '.co',
		currencyCode	: EnumCurrency.COP
	},
	[EnumCountry.EC]: {
		code			: EnumCountry.EC,
		name			: 'Ecuador',
		nameShort		: 'Ecuador',
		nameLong		: 'Republic of Ecuador',
		nameNative 		: 'Ecuador',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.EC],
		dialCode		: '+593',
		capital			: 'Quito',
		domainTld		: '.ec',
		currencyCode	: EnumCurrency.USD
	},
	[EnumCountry.GY]: {
		code			: EnumCountry.GY,
		name			: 'Guyana',
		nameShort		: 'Guyana',
		nameLong		: 'Co-operative Republic of Guyana',
		nameNative 		: 'Guyana',
		languageCode	: [EnumLanguage.EN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GY],
		dialCode		: '+592',
		capital			: 'Georgetown',
		domainTld		: '.gy',
		currencyCode	: EnumCurrency.GYD
	},
	[EnumCountry.PY]: {
		code			: EnumCountry.PY,
		name			: 'Paraguay',
		nameShort		: 'Paraguay',
		nameLong		: 'Republic of Paraguay',
		nameNative 		: 'Paraguay',
		languageCode	: [EnumLanguage.ES, EnumLanguage.GN],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PY],
		dialCode		: '+595',
		capital			: 'Asunción',
		domainTld		: '.py',
		currencyCode	: EnumCurrency.PYG
	},
	[EnumCountry.PE]: {
		code			: EnumCountry.PE,
		name			: 'Peru',
		nameShort		: 'Peru',
		nameLong		: 'Republic of Peru',
		nameNative 		: 'Perú',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PE],
		dialCode		: '+51',
		capital			: 'Lima',
		domainTld		: '.pe',
		currencyCode	: EnumCurrency.PEN
	},
	[EnumCountry.SR]: {
		code			: EnumCountry.SR,
		name			: 'Suriname',
		nameShort		: 'Suriname',
		nameLong		: 'Republic of Suriname',
		nameNative 		: 'Suriname',
		languageCode	: [EnumLanguage.NL],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SR],
		dialCode		: '+597',
		capital			: 'Paramaribo',
		domainTld		: '.sr',
		currencyCode	: EnumCurrency.SRD
	},
	[EnumCountry.UY]: {
		code			: EnumCountry.UY,
		name			: 'Uruguay',
		nameShort		: 'Uruguay',
		nameLong		: 'Eastern Republic of Uruguay',
		nameNative 		: 'Uruguay',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UY],
		dialCode		: '+598',
		capital			: 'Montevideo',
		domainTld		: '.uy',
		currencyCode	: EnumCurrency.UYU
	},
	[EnumCountry.VE]: {
		code			: EnumCountry.VE,
		name			: 'Venezuela',
		nameShort		: 'Venezuela',
		nameLong		: 'Bolivarian Republic of Venezuela',
		nameNative 		: 'Venezuela',
		languageCode	: [EnumLanguage.ES],
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.VE],
		dialCode		: '+58',
		capital			: 'Caracas',
		domainTld		: '.ve',
		currencyCode	: EnumCurrency.VEF
	}
	// cspell:enable
};
