import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Category Configurations
 */
export enum EnumVexelsProduct {
	All				= 'all',				// default value
	Vectors			= 'vectors',
	Psd				= 'psd',
	Png				= 'png',
	Icons			= 'icons',
	Logos			= 'logos',
	MerchVectors	= 'merch_vectors',
	PsdTemplates	= 'psd_template',
	PngForMerch		= 'png_for_merch'
}

export const VEXELS_PRODUCT_LIST: ISearchParameterConfig<EnumVexelsProduct>[] = [
	{
		code 		: EnumVexelsProduct.All,
		name 		: 'All',
		placeholders: { '[TYPE]': 'graphics' }
	},
	{
		code 		: EnumVexelsProduct.Vectors,
		name 		: 'Vectors',
		placeholders: { '[TYPE]': 'free-vectors' }
	},
	{
		code 		: EnumVexelsProduct.Psd,
		name 		: 'Psd',
		placeholders: { '[TYPE]': 'psd' }
	},
	{
		code 		: EnumVexelsProduct.Png,
		name 		: 'Png',
		placeholders: { '[TYPE]': 'svg-png' }
	},
	{
		code 		: EnumVexelsProduct.Icons,
		name 		: 'Icons',
		placeholders: { '[TYPE]': 'icon' }
	},
	{
		code 		: EnumVexelsProduct.Logos,
		name 		: 'Logos',
		placeholders: { '[TYPE]': 'logos' }
	},
	{
		code 		: EnumVexelsProduct.MerchVectors,
		name 		: 'Merch Vectors',
		placeholders: { '[TYPE]': 'merch/vectors' }
	},
	{
		code 		: EnumVexelsProduct.PsdTemplates,
		name 		: 'Psd Templates',
		placeholders: { '[TYPE]': 'merch/psd' }
	},
	{
		code 		: EnumVexelsProduct.PngForMerch,
		name 		: 'Png For Merch',
		placeholders: { '[TYPE]': 'merch/png' }
	}
];


/**------------------------------------------------------
 * Vexels Configuration
 */
export const VEXELS_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.vexels.com',
		login		: 'https://www.vexels.com/login',
		search		: 'https://www.vexels.com/[TYPE]/[SEARCH]/p/[PAGE]/'
	},
	pageLimit		: 100,
	sortings		: [],
	products		: VEXELS_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Vexels Search Options
 */
export const VEXELS_DEFAULT_OPTIONS: IVexelsSearchOptions = {
	page			: 1,
	product			: EnumVexelsProduct.All
};

export interface IVexelsSearchOptions {
	page			: number;
	product			: EnumVexelsProduct;
}
