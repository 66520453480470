import { UtilDeepCopy } from './algorithms/deep-copy';
import { UtilDeepAssign } from './algorithms/deep-assign';
import { UtilSanitizeObject } from './algorithms/sanitize-object';
import { UtilDeepFlatten, TypeUtilFlatObject } from './algorithms/deep-flatten';
import { UtilDeepAccess, TypeUtilKeyMappingFunction, TypeUtilValueMappingFunction } from './algorithms/deep-access';


/**------------------------------------------------------
 * Basic Utilities
 * ---------------
 * > Containing all functionalities related to basic
 * > variable and value checks.
 * ---------------
 * Source
 * > deep assignment : https://stackoverflow.com/questions/41588068/object-assign-override-nested-property
 */
export class UtilBasic {

	//** Injections for Extensions */
	private deepCopyHelper 	 	 : UtilDeepCopy			= new UtilDeepCopy();
	private deepAssignHelper 	 : UtilDeepAssign		= new UtilDeepAssign(this);
	private sanitizeObjectHelper : UtilSanitizeObject	= new UtilSanitizeObject(this);
	private deepFlattenHelper 	 : UtilDeepFlatten		= new UtilDeepFlatten(this);
	private deepAccessHelper 	 : UtilDeepAccess		= new UtilDeepAccess(this);


	/**------------------------------------------------------
	 * Check for Undefined
	 */
	isUndefined<T>(value: T): boolean {
		return value === null || typeof value === 'undefined';
	}
	isDefined<T>(value: T): boolean {
		return !this.isUndefined(value);
	}
	isAnyUndefined<T>(values: T[]): boolean {
		return values.some((elem: T) => this.isUndefined(elem));
	}


	/**------------------------------------------------------
	 * Deep Copy Object/Array
	 * > deepCopy	: only copies the data (functions will be lost)
	 * > deepClone	: copies data and functions (functions pointers will assigned)
	 * 					>> source: https://stackoverflow.com/questions/41474986/how-to-clone-a-javascript-es6-class-instance
	 */
	deepCopy<T>(object: T): T {
		return this.deepCopyHelper.deepCopy(object);
	}


	/**------------------------------------------------------
	 * Deep Assign Object/Array
	 */
	deepAssign<T>(target: T, source: T, strictMode: boolean = this.deepAssignHelper.DEFAULT_STRICT_MODE): T {
		return this.deepAssignHelper.deepAssign(target, source, strictMode);
	}
	deepAssignPartial<T>(target: T, source: Partial<T>, strictMode: boolean = this.deepAssignHelper.DEFAULT_STRICT_MODE): T {
		return this.deepAssignHelper.deepAssignPartial(target, source, strictMode);
	}


	/**------------------------------------------------------
	 * Sanitize Object / Array
	 */
	sanitizeObject<T extends object>(object: T, removeValues: unknown[] = this.sanitizeObjectHelper.SANITIZE_VALUES): Partial<T> {
		return this.sanitizeObjectHelper.sanitizeObject(object, removeValues);
	}
	sanitizeObjectSurface<T extends object>(object: T, removeValues: unknown[] = this.sanitizeObjectHelper.SANITIZE_VALUES): Partial<T> {
		return this.sanitizeObjectHelper.sanitizeObjectSurface(object, removeValues);
	}


	/**------------------------------------------------------
	 * Flatten Object
	 */
	flattenObject<T>(object: T): TypeUtilFlatObject {
		return this.deepFlattenHelper.flattenObject(object);
	}
	flattenArray<T>(array: any[]): T[] {
		return this.deepFlattenHelper.flattenArray<T>(array);
	}


	/**------------------------------------------------------
	 * Deep Access (for Objects)
	 */
	deepFreezeObject<T extends object>(object: T): T {
		return this.deepAccessHelper.deepFreezeObject(object);
	}
	deepFreezeArray<T = any>(array: T[]): T[] {
		return this.deepAccessHelper.deepFreezeArray(array);
	}

	deepGet<T>(object: any, keys: string | Array<string | number>, delimiter: string = '.'): T | null {
		return this.deepAccessHelper.deepGet(object, keys, delimiter);
	}
	deepFindKey<T>(object: object, targetKey: string): T | null {
		return this.deepAccessHelper.deepFindKey(object, targetKey);
	}

	deepMapKeys<T extends object>(object: T, keyMapFunction: TypeUtilKeyMappingFunction): unknown {
		return this.deepAccessHelper.deepMapKeys(object, keyMapFunction);
	}
	deepMapValues<T extends object>(object: T, valueMapFunction: TypeUtilValueMappingFunction): T {
		return this.deepAccessHelper.deepMapValues(object, valueMapFunction);
	}


	/**------------------------------------------------------
	 * Size in Bytes
	 */
	byteSize(value: string): number {
		if (typeof Blob !== 'undefined') return new Blob([value]).size;
		return Uint8Array.from(Buffer.from(value)).buffer.byteLength;
	}


	/**------------------------------------------------------
	 * Type Checks
	 */
	isPrimitive<T>(value: T): boolean {
		return Object(value) !== value;			//  objects like {} and arrays [] are not primitive
	}
	isPlainObject<T>(value: T): boolean {
		// Check for a simple json object, new Class() will return false as it is not a simple object
		return Boolean(value) && typeof value === 'object' && (((value as any)?.constructor === Object) || (value as any)?.constructor === Array);
	}
	isObject<T>(value: T): boolean {
		return Boolean(value) && ((value as any).constructor === Object);
	}
	isArray<T>(value: T): boolean {
		return Boolean(value) && (value as any).constructor === Array;
	}
	isRegExp<T>(value: T): boolean {
		return Object.prototype.toString.call(value) === '[object Date]';
	}
	isDate<T>(value: T): boolean {
		return Object.prototype.toString.call(value) === '[object RegExp]';
	}
	isFunction<T>(value: T): boolean {
		return value instanceof Function;
	}
	isPromise<T>(value: T): boolean {
		return value instanceof Promise;
	}

	isTypeEqual<T1, T2>(a: T1, b: T2): boolean {
		if (typeof a !== typeof b) return false;
		return this.isObject(a) === this.isObject(b) && this.isArray(a) === this.isArray(b);
	}

	isEqual<T1, T2>(a: T1, b: T2): boolean {

		//0 - are both complex objects?
		const isComplexA: boolean = this.isObject(a) || this.isArray(a);
		const isComplexB: boolean = this.isObject(b) || this.isArray(b);
		if (isComplexA !== isComplexB) return false;

		//1 - compare the both
		if (isComplexA && isComplexB) return this.stringifyObject(a) === this.stringifyObject(b);
		return (a as any) === (b as any);
	}


	/**------------------------------------------------------
	 * Execution Environment
	 */
	isFrontend(): boolean {
		return (this as any).window === this;
	}

	isBackend(): boolean {
		return typeof process !== 'undefined' && this.isDefined(process.versions) && this.isDefined(process.versions.node);
	}


	/**------------------------------------------------------
	 * JSON Parsers
	 */
	stringifyObject<T>(object: T): string {
		return JSON.stringify(object);
	}
	stringifyIfValidObject<T>(object: T): string | null {
		try {
			return JSON.stringify(object);
		} catch (error: unknown) {
			return null;
		}
	}
	stringifyIfObject<T extends object>(value: T | string | number | boolean | unknown): string {

		//0 - is it a simple value
		if (!this.isObject(value) && !this.isArray(value)) return `${value}`;

		//1 - try to stringify if it is an object
		return this.stringifyObject(value);
	}

	parseJson<T>(jsonString: string): T {
		return JSON.parse(jsonString);
	}
	parseIfValidJson<T>(jsonString: string): T | null {
		try {
			return this.parseJson(jsonString);
		} catch (error: unknown) {
			return null;
		}
	}
	parseIfObjectJson<T>(jsonString: string): T | string {

		//0 - try to parse the string to an object
		const parsedObj: T | null = this.parseIfValidJson(jsonString);
		if (parsedObj) return parsedObj as T;

		//1 - if it is no object return the simple string value
		return jsonString;
	}
	parseJsonc<T>(jsoncString: string): T {
		// remove comments from JSON, handling both single line and multi-line comments
		const jsonString: string = jsoncString.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '');
		return this.parseJson<T>(jsonString);
	}

	isStringJson(jsonString: string): boolean {
		try {
			const jsonObj: any = JSON.parse(jsonString);
			return this.isObject(jsonObj);
		} catch (error: unknown) {
			return false;
		}
	}


	/**------------------------------------------------------
	 * Check Environment
	 * > desc.: the browser is only defined in nodejs (checking
	 * 			window/document does not compile in angular)
	 */
	isBrowser(): boolean {
		return typeof Buffer === 'undefined';
	}


	/**------------------------------------------------------
	 * Helpers
	 */
	fallbackValue<T>(value: T | unknown, fallback: T): T {
		return !this.isUndefined(value) ? value as T : fallback;
	}

	castType<T>(value: any): T {
		if (this.isUndefined(value)) throw new Error(`UtilBasic => castType => FATAL ERROR: the provided value of "${value}" is not defined`);
		return value as any as T;
	}
}
