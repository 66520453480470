import { BehaviorSubject, Observable } from 'rxjs';

import { IScaleLayoutHandler } from './scale-layout.interface';


export class ScaleLayoutService {

	//** Configurations */
	private readonly MIN_SCALE: number = 0.75;
	private readonly MAX_SCALE: number = 1.25;

	//** Helper Variables */
	readonly onZoomingChange$  	: BehaviorSubject<number>  = new BehaviorSubject<number>(1);
	readonly onZoomLayout$  	: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	scaleValue$ 	  			: BehaviorSubject<number>  = new BehaviorSubject<number>(1);


	constructor(
		private scaleLayoutHandler: IScaleLayoutHandler
	) {
		// load the saved / default configuration
		const scale: number = this.scaleLayoutHandler.getScaling();
		this.scaleValue$.next(Number(scale) || 1);
		this.setScale(this.scaleValue$.value);
	}


	/**------------------------------------------------------
	 * Get Scaling
	 */
	getScale()	 : number { return this.scaleValue$.value; }
	getMinScale(): number { return this.MIN_SCALE; }
	getMaxScale(): number { return this.MAX_SCALE; }

	onZoomChange$(): Observable<number> {
		return this.onZoomingChange$.asObservable();
	}


	/**------------------------------------------------------
	 * Change Scaling
	 */
	setScale(value: number): void {

		//0 - check if the value is valid
		if (value < this.MIN_SCALE || value > this.MAX_SCALE) throw new Error(`ScaleLayoutService => setScale => FATAL ERROR: value of "${value}" is outside of the valid range (min: ${this.MIN_SCALE} / max: ${this.MAX_SCALE})`);

		//1 - set the new zoom value
		this.scaleValue$.next(value);
		this.scaleLayoutHandler.setScaling(value);

		//2 - update the zoom level
		(document.body.style as any).zoom = value.toString();
		this.onZoomingChange$.next(value);
	}

	resetScale(): void {
		this.setScale(1.0);
	}

	/**------------------------------------------------------
	 * UI Helpers
	 */
	purifyCoordinate(value: number): number {
		return value / this.scaleValue$.value;
	}


	/**------------------------------------------------------
	 * Open Zoom Menu
	 */
	onZoomLayoutChange(): void {
		this.onZoomLayout$.next(true);
	}
	openZoomLayout$(): Observable<boolean> {
		return this.onZoomLayout$.asObservable();
	}
}
