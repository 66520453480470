import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { EnumTmNiceClass, EnumTmOffice, EnumTmStatus, EnumTmType } from '../../constants';


/**------------------------------------------------------
 * Trademark Request Interface
 */
export interface ITmFilterOptions {
	office			 	: EnumTmOffice[];
	niceClass		 	: EnumTmNiceClass[];
	status			 	: EnumTmStatus[];
	type			 	: EnumTmType[];
	applicationFrom	   ?: Date;
	applicationTo	   ?: Date;
	registrationFrom   ?: Date;
	registrationTo	   ?: Date;
}


/**------------------------------------------------------
 * Trademark Request Schemas
 */
export class TmFilterOptionsSchema implements ValidatorSchema<ITmFilterOptions> {
	office				= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmOffice));
	niceClass			= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmNiceClass));
	status				= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmStatus));
	type				= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmType));
	applicationFrom		= Validator.Date().optional().isInPast().isAfter('1970-01-01');
	applicationTo		= Validator.Date().optional().isInPast();
	registrationFrom 	= Validator.Date().optional().isInPast().isAfter('1970-01-01');
	registrationTo		= Validator.Date().optional().isInPast();
}
