// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';
import { TIME_DURATION } from '@libs/constants';
import { Util } from '@libs/utilities/util';


/**-----------------------------------------
 * Time-based One-Time Password
 * ----------------------------
 * > Implements TOTP, a cryptographic algorithm that
 * > produces a dynamic password at fixed intervals,
 * > using a shared secret key and the current time
 * > as factors for one-time authentication purposes.
 * ----------------------------
 * > Base32 Encoder/Decoder	: https://emn178.github.io/online-tools/base32_encode.html
 * > Random/Secret Generator: https://passwordsgenerator.net/
 * > TOTP Token Generator	: https://totp.danhersam.com/
 */
export class CryptoTimeBasedOtp {

	createTotpHmacSha1(secret: string, timestampTimeMs: number = Date.now()): string {

		//0 - check the secret
		if (Util.String.isEmpty(secret)) throw new Error(`TimeBasedOtp => createTOTP_HMAC_SHA1 => FATAL ERROR: secret is empty`);

		//1 - convert Uint8Array to a raw binary string
		const secretArray: Uint8Array = Util.String.fromBase32ToUint8Array(secret);
		let rawSecret: string = '';
		for (const secretElem of secretArray) {
			rawSecret += String.fromCharCode(secretElem);
		}

		//2 - create time counter interval
		// > Note: the TOTP value is based on the current time to ensure that it
		// > changes at a fixed interval (changes every 30 seconds).
		const timeCounter : number 	   = Math.floor(timestampTimeMs / TIME_DURATION.PERIOD_30_SEC);
		const timeBytes   : Uint8Array = Uint8Array.from([0, 0, 0, 0, (timeCounter >>> 24) & 0xff, (timeCounter >>> 16) & 0xff, (timeCounter >>> 8) & 0xff, timeCounter & 0xff]);
		const binaryString: string 	   = Array.from(timeBytes, (byte: number) => String.fromCharCode(byte)).join('');

		//3 - create HMAC-SHA1 hash with the secret key and time counter
		const hmac: forge.hmac.HMAC = forge.hmac.create();
		hmac.start('sha1', rawSecret);
		hmac.update(binaryString);

		//4 - get HMAC result and truncate to a 6-digit TOTP
		const hmacResult: string = hmac.digest().getBytes();
		const offset    : number = hmacResult.charCodeAt(hmacResult.length - 1) & 0x0f;
		const binary	: number = ((hmacResult.charCodeAt(offset) & 0x7f) << 24)
			| ((hmacResult.charCodeAt(offset + 1) & 0xff) << 16)
			| ((hmacResult.charCodeAt(offset + 2) & 0xff) << 8)
			| (hmacResult.charCodeAt(offset + 3)  & 0xff);

		//5 - convert the OTP as a 6-digit string
		const otp: number = binary % 1000000;
		return otp.toString().padStart(6, '0');
	}
}
