import { TypeResolve } from '@libs/constants';

import { IResizeOptions } from './resize-image.interface';


/**------------------------------------------------------
 * Image Resize Helper
 */
export class ResizeImageHelper {

	//** Helper Variables */
	private reader: FileReader = new FileReader();


	/**------------------------------------------------------
	 * Resize Image
	 */
	resizeImageFile(imageFile: File, options: IResizeOptions): Promise<Blob | null> {
		return new Promise(async (resolve: TypeResolve<Blob | null>) => {

			//0 - initialize image element
			const img	 : HTMLImageElement = new Image();
			const blobUrl: string 			= await this.getImageUrl(imageFile);

			//1 - check on image load
			img.onload = async () => {

				//a. resize the image
				const resizedImage: Blob | null = await this.resizeImageHtml(img, options);

				//b. return the result
				URL.revokeObjectURL(blobUrl);
				resolve(resizedImage);
			};

			//2 - return null if error
			img.onerror = () => {
				resolve(null);
			};
			img.src = blobUrl;
		});
	}

	async resizeImageHtml(imageHtml: HTMLImageElement, options: IResizeOptions): Promise<Blob | null> {

		//0 - calculate width and height maintaining aspect ratio
		const ratio  : number = imageHtml.width / imageHtml.height;
		let newWidth : number = options.width;
		let newHeight: number = options.height;
		if (imageHtml.width > imageHtml.height) newHeight = options.width / ratio;
			else newWidth = ratio * options.height;

		//1 - create canvas and setting to default width and height
		const canvas: HTMLCanvasElement = document.createElement('canvas');
		canvas.width  = options.width;
		canvas.height = options.height;

		//2 - draw the image on canvas
		const dx: number = ((options.width  - newWidth)  / 2) + options.padding;
		const dy: number = ((options.height - newHeight) / 2) + options.padding;
		const dw: number = newWidth  - (options.padding * 2);
		const dh: number = newHeight - (options.padding * 2);
		canvas.getContext('2d')!.drawImage(imageHtml, dx, dy, dw, dh);

		//3 - get the blob image from the canvas
		const imgPreview: Blob | null = await this.getBlobFromCanvas(canvas);
		return imgPreview;
	}


	/**------------------------------------------------------
	 * Crop Image
	 */
	// ... make the cropping logic of the research reusable


	/**------------------------------------------------------
	 * Crop Whitespace
	 */
	// ... create based on the old cropping logic


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getImageUrl(imageFile: File): Promise<string> {
		return new Promise((resolve: TypeResolve<string>) => {
			this.reader.onload = () => {
				resolve(this.reader.result as string);
			};
			this.reader.readAsDataURL(imageFile);
		});
	}

	private getBlobFromCanvas(canvas: HTMLCanvasElement): Promise<Blob | null> {
		return new Promise((resolve: TypeResolve<Blob | null>) => {
			canvas.toBlob((blob: Blob | null) => { resolve(blob); });
		});
	}
}
