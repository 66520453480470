/* cspell:ignore bigrams, bigram */
import { UtilBasic } from '../util-basic';
import { UtilString } from '../util-string';


/**------------------------------------------------------
 * Text Similarity Compare
 * -----------------------
 * > npm string-similarity			: https://www.npmjs.com/package/string-similarity
 * > git string-similarity			: https://github.com/aceakash/string-similarity/blob/master/src/index.js
 * > stack overflow compare strings	: https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
 */
export class UtilTextSimilarity {

	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString
	) {}


	/**------------------------------------------------------
	 * Helper Function
	 */
	sortBySimilarity<T>(search: string, textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {

		//0 - check and get text function
		const textFn: TypeUtilGetTextFn<T> = this.utilBasic.fallbackValue(getTextFn, (value: T) => this.utilString.toString(value));

		//1 - sort the texts based on the relevance to the search
		const sortedTextObjects: T[] = textObjects.sort((a: T, b: T) => this.similarityCompare(search, textFn!(b)) - this.similarityCompare(search, textFn!(a)));

		//2 - return the result
		return sortedTextObjects;
	}


	/**------------------------------------------------------
	 * Compare two Texts by Similarity
	 */
	similarityCompare(text1: string, text2: string): number {

		//0 - remove spaces
		text1 = text1.replace(/\s+/g, '');
		text2 = text2.replace(/\s+/g, '');

		//1 - check for identical or empty
		if (text1 === text2) return 1;

		//2 - if either is a 0-letter or 1-letter string
		if (text1.length < 2 || text2.length < 2) return 0;

		//3 - bigrams algorithm on text 1
		const text1Bigrams: Map<string, number> = new Map();
		for (let i: number = 0; i < text1.length - 1; i++) {

			//a. calculate the bigrams
			const bigram: string = text1.substring(i, i + 2);
			const count : number = text1Bigrams.has(bigram)
				? text1Bigrams.get(bigram)! + 1
				: 1;

			//b. add the calculated value to the  map
			text1Bigrams.set(bigram, count);
		}

		//4 - bigrams algorithm on text 2, comparing it to text 1
		let intersectionSize: number = 0;
		for (let i: number = 0; i < text2.length - 1; i++) {

			//a. calculate the bigrams
			const bigram: string = text2.substring(i, i + 2);
			const count : number = text1Bigrams.has(bigram)
				? text1Bigrams.get(bigram)!
				: 0;

			//b. do both strings have the char, if yes increase the intersection size
			if (count > 0) {
				text1Bigrams.set(bigram, count - 1);
				intersectionSize++;
			}
		}

		//5 - calculate the similarity
		const similarity: number = (2.0 * intersectionSize) / (text1.length + text2.length - 2);
		return similarity;
	}
}


//** Types -------------------------------------- */
type TypeUtilGetTextFn<T> = (object: T) => string;
