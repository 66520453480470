import { EnumPodMarketplace } from './platform.constants';


/**------------------------------------------------------
 * Product & Sorting Configurations
 */
export enum EnumRedbubbleProduct {
	All 		= 'all_products',
	Shirt 		= 'shirt',
	Premium 	= 'premium',
	Hoodie 		= 'hoodie',
	VNeck 		= 'v_neck',
	TankTop 	= 'tank_top',
	LongSleeve 	= 'long_sleeve',
	Sweatshirt 	= 'sweatshirt'
}

export enum EnumRedbubbleSorting {
	Relevant 	= 'relevant',
	Trending	= 'trending',
	Recent 		= 'recent',
	TopSelling 	= 'top_selling'
}


/**------------------------------------------------------
 * Mapping
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const MAPPING_REDBUBBLE = {
	[EnumPodMarketplace.All]			: 'All Marketplaces',
	[EnumPodMarketplace.COM]			: 'COM - United States',
	[EnumPodMarketplace.CO_UK]			: 'CO.UK - United Kingdom',
	[EnumPodMarketplace.DE]				: 'DE - Germany',
	[EnumPodMarketplace.FR]				: 'FR - France',
	[EnumPodMarketplace.IT]				: 'IT - Italy',
	[EnumPodMarketplace.ES]				: 'ES - Spain',

	[EnumRedbubbleProduct.All]			: 'All Products',
	[EnumRedbubbleProduct.Shirt]		: 'T-Shirt',
	[EnumRedbubbleProduct.Premium]		: 'Premium T-Shirt',
	[EnumRedbubbleProduct.Hoodie]		: 'Hoodie',
	[EnumRedbubbleProduct.VNeck]		: 'V-Neck',
	[EnumRedbubbleProduct.TankTop]		: 'Tank Top',
	[EnumRedbubbleProduct.LongSleeve]	: 'Long Sleeve',
	[EnumRedbubbleProduct.Sweatshirt]	: 'Sweatshirt',

	[EnumRedbubbleSorting.Relevant]		: 'Most Relevant',
	[EnumRedbubbleSorting.Trending]		: 'Trending',
	[EnumRedbubbleSorting.Recent]		: 'Newest',
	[EnumRedbubbleSorting.TopSelling]	: 'Top Selling'
};
