import { Util } from '@libs/utilities/util';
import { ReplaySubject } from 'rxjs';
import { Socket } from 'socket.io-client';

import { EnumSocketDisconnect, EnumSocketError, EnumSocketEvent, EnumSocketReconnect, IEmitEventData, IEmitEventLog, ISocketError } from '../websocket.interface';


/**------------------------------------------------------
 * Websocket Wrapper
 * > used to work with WebSockets
 */
export class WebsocketWrapper {

	//** Configurations */
	private readonly DEFAULT_EMIT_TIMEOUT: number = 10000;

	//** Helper Variables */
	private readonly eventHistory: IEmitEventLog[] = [];

	//** Helper Subjects */
	private readonly onConnect$	 		: ReplaySubject<void> 					= new ReplaySubject<void>();
	private readonly onDisconnect$	 	: ReplaySubject<EnumSocketDisconnect> 	= new ReplaySubject<EnumSocketDisconnect>();
	private readonly onConnectionChange$: ReplaySubject<void> 					= new ReplaySubject<void>();
	private readonly onError$	 		: ReplaySubject<ISocketError> 			= new ReplaySubject<ISocketError>();
	private readonly onDestroy$	 		: ReplaySubject<void> 					= new ReplaySubject<void>();
	private readonly onEmit$	 		: ReplaySubject<IEmitEventData<any>> 	= new ReplaySubject<IEmitEventData<any>>();

	constructor(
		private socket: Socket
	) {

		//0 - register connect & disconnect
		this.socket.on(EnumSocketEvent.Connect, () => {
			this.onConnect$.next();
			this.onConnectionChange$.next();		// monitor the connection status
		});
		this.socket.on(EnumSocketEvent.Disconnect, (reason: EnumSocketDisconnect) => {
			this.onDisconnect$.next(reason);
			this.onConnectionChange$.next();		// monitor the connection status
		});

		//1 - register the error handling
		this.socket.on(EnumSocketEvent.Error, (error: unknown) => {
			this.onError$.next({ reason: EnumSocketError.Error, error });
		});
		this.socket.on(EnumSocketEvent.ConnectError, (error: unknown) => {
			this.onError$.next({ reason: EnumSocketError.ConnectError, error });
		});
	}


	/**------------------------------------------------------
	 * Socket Event Hooks
	 */
	onConnect(onConnectCallback: () => void): WebsocketWrapper {
		this.onConnect$.subscribe(() => {
			onConnectCallback();
		});
		return this;
	}

	onDisconnect(onDisconnectCallback: (reason: EnumSocketDisconnect) => void): WebsocketWrapper {
		this.onDisconnect$.subscribe((reason: EnumSocketDisconnect) => {
			onDisconnectCallback(reason);
		});
		return this;
	}

	onDestroy(onDestroyCallback: () => void): WebsocketWrapper {
		this.onDestroy$.subscribe(() => {
			onDestroyCallback();
		});
		return this;
	}

	onError(onErrorCallback: (error: unknown) => void): WebsocketWrapper {
		this.onError$.subscribe((error: unknown) => {
			onErrorCallback(error);
		});
		return this;
	}

	onPing(onPingCallback: () => void): WebsocketWrapper {
		this.socket.on(EnumSocketEvent.Ping, () => {
			onPingCallback();
		});
		return this;
	}

	onReconnect(onReconnectCallback: (reason: EnumSocketReconnect) => void): WebsocketWrapper {
		this.socket.on(EnumSocketReconnect.Reconnect, 		 () => { onReconnectCallback(EnumSocketReconnect.Reconnect); });
		this.socket.on(EnumSocketReconnect.ReconnectAttempt, () => { onReconnectCallback(EnumSocketReconnect.ReconnectAttempt); });
		this.socket.on(EnumSocketReconnect.ReconnectFailed,  () => { onReconnectCallback(EnumSocketReconnect.ReconnectFailed); });
		this.socket.on(EnumSocketReconnect.ReconnectError, 	 () => { onReconnectCallback(EnumSocketReconnect.ReconnectError); });
		return this;
	}

	onEmit<T>(onEmitCallback: (event: string, data: T) => void): WebsocketWrapper {
		this.onEmit$.subscribe((eventData: IEmitEventData<T>) => {
			onEmitCallback(eventData.event, eventData.data);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Connect, Disconnect & Destroy
	 */
	connect(connectCallback: () => void = () => {}): WebsocketWrapper {

		//0 - connect the socket
		this.socket.connect();

		//1 - if required emit a callback on the first connect finished
		this.socket.once(EnumSocketEvent.Connect, () => {
			connectCallback();
		});
		return this;
	}

	disconnect(): WebsocketWrapper {
		this.socket.disconnect();
		return this;
	}

	destroy(): WebsocketWrapper {

		//0 - disconnect and destroy the socket connection
		this.socket.disconnect();
		this.socket.off();

		//1 - emit the destroy event
		this.onDestroy$.next();

		//2 - unsubscribe all event listeners
		this.completeAllObservables();

		//3 - clear the event history
		this.clearEventHistory();
		return this;
	}


	/**------------------------------------------------------
	 * Emit Events / Messages
	 */
	emitEvent<T>(event: string, data: T, timeout: number = this.DEFAULT_EMIT_TIMEOUT): WebsocketWrapper {

		//0 - is the socket already connected?
		if (this.isConnected()) {
			this.emitEventAction(event, data);
			return this;
		}

		//1 - wait till the socket is connected, before emitting the event
		let eventEmitted: boolean = false;
		this.socket.once(EnumSocketEvent.Connect, () => {
			this.emitEventAction(event, data);
			eventEmitted = true;
		});

		//2 - did the socket connect? was the value send?
		setTimeout(() => {

			//a. was the event emitted successfully?
			if (eventEmitted) return;

			//b. log an error info
			const errorMessage: string = `emitting the event of "${event}" failed, because the socket failed to connect`;
			console.error(`WebsocketWrapper => emitEvent => FATAL ERROR: ${errorMessage} (note: please make sure to call the connect before emitting event)`);

			//c. emit an error
			this.onError$.next({ reason: EnumSocketError.EventEmitTimeout, error: errorMessage });
		}, timeout);

		return this;
	}

	private emitEventAction<T>(event: string, data: T) {

		//0 - emit the event to the server
		this.socket.emit(event, data);

		//1 - submit the event to the onEmit subscribable
		this.onEmit$.next({
			event 	: event,
			data 	: data
		});

		//2 - track the history of events / messages
		this.eventHistory.push({
			event 	: event,
			data 	: data,
			date	: new Date()
		});
	}


	/**------------------------------------------------------
	 * Register Event Listeners / Event Handlers
	 */
	onEvent<T>(event: string, onEventCallback: (data: T) => void): WebsocketWrapper {
		this.socket.on(event, (data: T) => {
			onEventCallback(data);
		});
		return this;
	}

	onEventOnce<T>(event: string, onEventCallback: (data: T) => void): WebsocketWrapper {
		this.socket.once(event, (data: T) => {
			onEventCallback(data);
		});
		return this;
	}

	onAnyEvent<T>(onAnyEventCallback: (event: string, data: T) => void): WebsocketWrapper {
		this.socket.onAny((event: string, data: T) => {
			onAnyEventCallback(event, data);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Unregister Event Listeners / Event Handlers
	 */
	offEvent(event: string): WebsocketWrapper {
		this.socket.off(event);						// remove all listeners for that event
		return this;
	}

	offAnyEvent(): WebsocketWrapper {
		this.socket.offAny();						// all catch-all listeners are removed
		return this;
	}

	offAllEvents(): WebsocketWrapper {
		this.socket.off();							// remove all listeners for all events
		return this;
	}


	/**------------------------------------------------------
	 * Status Check Functions
	 */
	isConnected()	: boolean { return this.socket.connected; }
	isDisconnected(): boolean { return !this.isConnected(); }

	onConnectionChange(onConnectionChangeCallback: (connectionStatus: boolean) => void): WebsocketWrapper {
		this.onConnectionChange$.subscribe(() => {
			onConnectionChangeCallback(this.socket.connected);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Other Helpers
	 */
	timeout(timeoutTimeMs: number): WebsocketWrapper {
		if (Util.Number.isNegative(timeoutTimeMs)) throw new Error(`WebsocketWrapper => timeout => FATAL ERROR: the provided timeout time is negative, the timeout time is required to be a positive number (value: "${timeoutTimeMs}")`);
		this.socket.timeout(timeoutTimeMs);
		return this;
	}


	/**------------------------------------------------------
	 * Event History
	 */
	clearEventHistory(): void {
		Util.Array.clear(this.eventHistory);
	}

	getEventHistory(): IEmitEventLog[] {
		return this.eventHistory;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private completeAllObservables() {
		this.onDisconnect$.complete();
		this.onConnectionChange$.complete();
		this.onError$.complete();
		this.onDestroy$.complete();
	}
}
