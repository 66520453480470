import { AMAZON_CONFIG_COM } from './amazon-com.data';
import { AMAZON_DEFAULT_OPTIONS } from '../amazon-search.data';
import { AbstractAmazonSearch } from '../helpers/amazon-search.abstract';
import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { EnumAmazonSorting, EnumAmazonProduct, EnumAmazonMarketplace } from '../../../../../../constants/research/app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Amazon US Search Links
 */
export class AmazonComSearch extends AbstractAmazonSearch {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumAmazonSorting>[] {
		return AMAZON_CONFIG_COM.sortings as ISearchParameterConfig<EnumAmazonSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumAmazonProduct>[] {
		return AMAZON_CONFIG_COM.products as ISearchParameterConfig<EnumAmazonProduct>[];
	}

	constructor() {
		super(EnumAmazonMarketplace.COM, AMAZON_CONFIG_COM, AMAZON_DEFAULT_OPTIONS);
	}
}
