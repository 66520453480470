// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';

import { ICryptoPbkdf2HashOptions, ICryptoPbkdf2HashObject } from './password-hash.interface';


/**-----------------------------------------
 * Hashing (PBKDF2, HMAC-SHA1)
 * ---------------------------
 * > PBKDF2 generator: https://www.dcode.fr/pbkdf2-hash
 * ---------------------------
 * > The node-forge library only supports using HMAC-SHA1 as
 * > the underlying pseudorandom function (PRF) within PBKDF2.
 * ---------------------------
 * > PBKDF2 (Password-Based Key Derivation Function 2) is used for
 * > password hashing because it applies a computationally expensive
 * > process to derive a secure hash, making it more resistant to
 * > brute-force and dictionary attacks. Each password should have
 * > its own salt to add uniqueness, preventing attackers from
 * > efficiently precomputing hashes or using rainbow tables to
 * > reverse-engineer passwords.
 */
export class CryptoPasswordHash {

	//** Configurations */
	readonly PBKDF2_ALGORITHM_NAME: string = 'PBKDF2/HMAC-SHA512';


	hashPbkdf2Sha512(password: string, options: ICryptoPbkdf2HashOptions): ICryptoPbkdf2HashObject {

		//0 - create a new random salt for each password
		// > Note: salt is converted to hex, to make the values more readable,
		// > and to improve the encoding for DB and others
		const randomSalt: string = forge.random.getBytesSync(options.saltLength);
		const saltAsHex : string = forge.util.bytesToHex(randomSalt);

		//1 - crate the hash for the password
		const md		: forge.md.MessageDigest = forge.md.sha512.create();
		const derivedKey: string = forge.pkcs5.pbkdf2(password, saltAsHex, options.iterations, options.keyLength, md);
		const pbkdf2Hash: string = forge.util.bytesToHex(derivedKey);

		//2 - combine everything into the hash data
		const hashData: ICryptoPbkdf2HashObject = {
			algorithm	: this.PBKDF2_ALGORITHM_NAME,
			hash		: pbkdf2Hash,
			salt		: saltAsHex,
			iterations	: options.iterations,
			keyLength	: options.keyLength
		};
		return hashData;
	}

	checkHashPbkdf2Sha512(password: string, hashData: ICryptoPbkdf2HashObject): boolean {

		//0 - verify if "hashObject" is from pbkdf2 algorithm
		if (hashData.algorithm !== this.PBKDF2_ALGORITHM_NAME) throw new Error(`Hash => checkHashPBKDF2 => ERROR: the hash was crated with the wrong algorithm (require "${this.PBKDF2_ALGORITHM_NAME}" and got "${hashData.algorithm}" )`);

		//1 - verify if the hash value is matching the data
		const md		: forge.md.MessageDigest = forge.md.sha512.create();
		const derivedKey: string = forge.pkcs5.pbkdf2(password, hashData.salt, hashData.iterations, hashData.keyLength, md);
		const pbkdf2Hash: string = forge.util.bytesToHex(derivedKey);
		return pbkdf2Hash === hashData.hash;
	}
}
