/**------------------------------------------------------
 * Tostadora Constants
 */
export enum EnumTostadoraCountryLanguage {
	UnitedStatesEN	= 'united_states_en_us',
	UnitedKingdomEN	= 'united_states_en_uk',
	FranceFR		= 'france_fr',
	Spanish			= 'spanish_es',
	ItalyIT			= 'italy_it'
}

export enum EnumTostadoraMarketplace {
	US				= 'us',
	UK				= 'uk',
	FR				= 'fr',
	ES				= 'es',
	IT				= 'it'
}

export enum EnumTostadoraProduct {
	ManShirt		= 'men_t_shirt'
	// ...
}

export enum EnumTostadoraColor {
	ManShirt		= 'men_t_shirt'
	// ...
}


/**------------------------------------------------------
 * Tostadora Template
 */
export interface ITostadoraTemplateOptions {
	general 		: {
		countryLanguage : EnumTostadoraCountryLanguage;
		store			: string;
		storeSection	: string;
		marketplaces	: EnumTostadoraMarketplace[];
		royalty			: number;
	};
	products			: {
		productName	: EnumTostadoraProduct;
		color		: {
			colorName	: EnumTostadoraColor;
			colorCode	: string;
		};
	};
}
