import { enableProdMode } from '@angular/core';
import { EnumEnvironment } from '@libs/constants';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ENVIRONMENT } from './environments';


//0 - Are we in Production?
if (ENVIRONMENT.ENVIRONMENT === EnumEnvironment.Production) {
	enableProdMode();
}

//1 - bootstrap the application
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err: any) => { console.error(err); });
