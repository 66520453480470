/**------------------------------------------------------
 * Blocked Email Domains
 * ---------------------
 * > Mails that come from 10 min mails and other
 * > bad sources are added here and blocked for
 * > creating user accounts and other actions.
 */
export const BLOCKED_EMAIL_DOMAINS: string[] = [
	// cspell:disable
	'0wnd.net',
	'10-minuten',
	'10minutemail.co.uk',
	'10minutemail.com',
	'10minutemailbox.com',
	'2wslhost.com',
	'localtank.com',
	'816qs.com',
	'alvinneo.com',
	'brefmail.com',
	'bukanimers.com',
	'candassociates.com',
	'coffeetunner.com',
	'colevillecapital.com',
	'cosmorph.com',
	'cuedigy.com',
	'cuendita.com',
	'cuoly.com',
	'discard.email',
	'discardmail.com',
	'disposableinbox.com',
	'dispostable.com',
	'emailaing.com',
	'emailfake.com',
	'emailondeck.com',
	'emailtemporal.com',
	'emltmp.com',
	'fakeinbox.com',
	'fakeinbox.info',
	'firemailbox.club',
	'gasssmail.com',
	'getairmail.com',
	'getcashstash.com',
	'getnada.com',
	'grr.la',
	'guerrillamail.biz',
	'guerrillamail.com',
	'guerrillamail.de',
	'guerrillamail.info',
	'guerrillamail.net',
	'guerrillamail.org',
	'guerrillamailblock.com',
	'hmamail.com',
	'hmnmw.com',
	'inboxalias.com',
	'incognitomail.net',
	'incognitomail.org',
	'jeoce.com',
	'jetable.net',
	'jetable.org',
	'kaocashima.com',
	'kimsangun.com',
	'knowledgemd.com',
	'linshiyouxiang.net',
	'lite14.us',
	'mail-temporaire.fr',
	'mailcatch.com',
	'maildrop.cc',
	'maildrop.io',
	'mailforspam.com',
	'mailinator.co.uk',
	'mailinator.com',
	'mailinator.net',
	'mailinator2.com',
	'mailmetrash.com',
	'mailnesia.com',
	'mailnesia.org',
	'mailsac.com',
	'mintemail.com',
	'moakt.com',
	'mohmal.club',
	'mt2014.com',
	'my10minutemail.com',
	'mytemp.email',
	'nproxi.com',
	'nuer75.cf',
	'pokemail.net',
	'primaryale.com',
	'scpulse.com',
	'seventol.online',
	'sharklasers.com',
	'sharklasers.us',
	'shotshe.com',
	'spam4.me',
	'spambob.com',
	'spamgourmet.com',
	'temp-mail.io',
	'temp-mail.org',
	'tempinbox.com',
	'tempmail.it',
	'tempmail.space',
	'tempmailaddress.com',
	'tempmailgen.com',
	'tempmailo.com',
	'tempomail.fr',
	'temporaryemail.net',
	'throwawaymail.com',
	'trashmail.at',
	'trashmail.com',
	'trashmail.de',
	'trashmail.me',
	'trashmail.net',
	'trashmail.org',
	'twzhhq.online',
	'typery.com',
	'vmani.com',
	'wegwerfmail.de',
	'weinzed.org',
	'wpdork.com',
	'yevme.com',
	'yopmail.com',
	'yopmail.fr',
	'you-spam.com',
	'zhorachu.com'
	// cspell:enable
];
