import { animate, animation, AnimationReferenceMetadata, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

import { INgAnimationOptions } from '../shared/state-animation.interface';
import { useAnimationIncludingChildren } from '../shared/state-animation.helper';


/**------------------------------------------------------
 * Animation Definition
 */
function fadeOut(): AnimationReferenceMetadata {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({
					opacity : 1,
					easing 	: 'ease',
					offset 	: 0
				}),
				style({
					opacity : 0,
					easing 	: 'ease',
					offset 	: 1
				})
			])
		)
	]);
}


/**------------------------------------------------------
 * Configuration
 */
const NG_DEFAULT_DURATION: number = 1000;


/**------------------------------------------------------
 * FadeOut Animation On State
 */
export function fadeOutAnimation(options?: INgAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeOut', [
		transition(
			'0 => 1',
			[
				...useAnimationIncludingChildren(fadeOut(), options)
			],
			{
				params: {
					delay 	: options?.delay 	|| 0,
					duration: options?.duration || NG_DEFAULT_DURATION
				}
			}
		)
	]);
}


/**------------------------------------------------------
 * FadeOut Animation On Leave
 */
export function fadeOutOnLeaveAnimation(options?: INgAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeOutOnLeave', [
		transition(
			':leave',
			[
				...useAnimationIncludingChildren(fadeOut(), options)
			],
			{
				params: {
					delay 	: options?.delay 	|| 0,
					duration: options?.duration || NG_DEFAULT_DURATION
				}
			}
		)
	]);
}
