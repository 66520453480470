import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumSociety6Sorting {
	Relevance		= 'relevance',			// default value
	Featured		= 'featured',
	BestSelling		= 'best_selling',
	NewTrending		= 'new_and_trending'
}

export const SOCIETY6_SORTING_LIST: ISearchParameterConfig<EnumSociety6Sorting>[] = [
	{
		code		: EnumSociety6Sorting.Relevance,
		name		: 'Relevance',
		parameters	: { sort: 'best_match' }
	},
	{
		code		: EnumSociety6Sorting.Featured,
		name		: 'Featured',
		parameters	: { sort: 'featured' }
	},
	{
		code		: EnumSociety6Sorting.BestSelling,
		name		: 'Best Selling',
		parameters	: { sort: 'best_selling' }
	},
	{
		code		: EnumSociety6Sorting.NewTrending,
		name		: 'Trending',
		parameters	: { sort: 'trending' }
	}
];


/**------------------------------------------------------
 * Product Configurations
 */
export enum EnumSociety6Product {
	All				= 'all',				// default value
	ArtPrints		= 'art_prints',
	ThrowPillows	= 'throw_pillows',
	iPhoneCases		= 'iPhone_and_ipod_cases',
	TravelMugs		= 'travel_mugs',
	FaceMasks		= 'face_masks',
	LongSleeve		= 'long_sleeve_shirts',
	VNeckShirts		= 'v_neck_shirts',
	Shirts			= 't_shirts',
	Hoodies			= 'hoodies',
	TankTops		= 'tank_tops',
	Stickers		= 'stickers',
	Notebooks		= 'notebooks',
	Mugs			= 'mugs',
	Posters			= 'posters',
	MetalPrints		= 'metal_prints'
}

export const SOCIETY6_PRODUCT_LIST: ISearchParameterConfig<EnumSociety6Product>[] = [
	{
		code		: EnumSociety6Product.All,
		name		: 'All Products',
		parameters	: {}
	},
	{
		code		: EnumSociety6Product.ArtPrints,
		name		: 'Art Prints',
		parameters	: {
			filter	: 'product_prints,department_Wall_Art'
		}
	},
	{
		code		: EnumSociety6Product.ThrowPillows,
		name		: 'Throw Pillows',
		parameters	: {
			filter	: 'product_pillows,department_Home_Decor'
		}
	},
	{
		code		: EnumSociety6Product.iPhoneCases,
		name		: 'iPhone & iPod Cases',
		parameters	: {
			filter	: 'product_cases,department_Tech'
		}
	},
	{
		code		: EnumSociety6Product.TravelMugs,
		name		: 'Travel Mugs',
		parameters	: {
			filter	: 'product_travel-mugs,department_Tabletop'
		}
	},
	{
		code		: EnumSociety6Product.FaceMasks,
		name		: 'Face Masks',
		parameters	: {
			filter	: 'product_masks,department_Apparel_AND_Bags'
		}
	},
	{
		code		: EnumSociety6Product.LongSleeve,
		name		: 'Long Sleeve',
		parameters	: {
			filter	: 'product_long-sleeve-tshirts,department_Apparel_AND_Bags'
		}
	},
	{
		code		: EnumSociety6Product.VNeckShirts,
		name		: 'VNeck Shirts',
		parameters	: {
			filter	: 'product_vneck-tshirts,department_Apparel_AND_Bags'
		}
	},
	{
		code		: EnumSociety6Product.Shirts,
		name		: 'Shirts',
		parameters	: {
			filter	: 'product_tshirts,keyword_wear,department_Apparel_AND_Bags'
		}
	},
	{
		code		: EnumSociety6Product.Hoodies,
		name		: 'Hoodies',
		parameters	: {
			filter	: 'product_hoodies,keyword_wear,department_Apparel_AND_Bags'
		}
	},
	{
		code		: EnumSociety6Product.TankTops,
		name		: 'Tank Tops',
		parameters	: {
			filter	: 'product_tank-tops,keyword_wear,department_Apparel_AND_Bags'
		}
	},
	{
		code		: EnumSociety6Product.Stickers,
		name		: 'Stickers',
		parameters	: {
			filter	: 'product_stickers,department_Office'
		}
	},
	{
		code		: EnumSociety6Product.Notebooks,
		name		: 'Notebooks',
		parameters	: {
			filter	: 'product_notebooks,department_Office'
		}
	},
	{
		code		: EnumSociety6Product.Mugs,
		name		: 'Mugs',
		parameters	: {
			filter	: 'product_mugs,department_Tabletop'
		}
	},
	{
		code		: EnumSociety6Product.Posters,
		name		: 'Posters',
		parameters	: {
			filter	: 'product_posters,department_Wall_Art'
		}
	},
	{
		code		: EnumSociety6Product.MetalPrints,
		name		: 'Metal Prints',
		parameters	: {
			filter	: 'product_metal-prints,department_Wall_Art'
		}
	}
];


/**------------------------------------------------------
 * Society6 Configuration
 */
export const SOCIETY6_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://society6.com/',
		login		: 'https://society6.com/login',
		search		: 'https://society6.com/search?q=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 100,
	sortings		: SOCIETY6_SORTING_LIST,
	products		: SOCIETY6_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Society6 Search Options
 */
export const SOCIETY6_DEFAULT_OPTIONS: ISociety6SearchOptions = {
	page			: 1,
	sorting			: EnumSociety6Sorting.Relevance,
	product			: EnumSociety6Product.All
};

export interface ISociety6SearchOptions {
	page			: number;
	sorting			: EnumSociety6Sorting;
	product			: EnumSociety6Product;
}
