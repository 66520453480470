import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumZazzleProduct, EnumZazzleSorting, IZazzleSearchOptions, ZAZZLE_CONFIG, ZAZZLE_DEFAULT_OPTIONS } from './zazzle-search.data';


/**------------------------------------------------------
 * Zazzle Search Url
 */
export class ZazzleSearch extends AbstractSearchLinks<IZazzleSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumZazzleSorting>[] {
		return ZAZZLE_CONFIG.sortings as ISearchParameterConfig<EnumZazzleSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumZazzleProduct>[] {
		return ZAZZLE_CONFIG.products as ISearchParameterConfig<EnumZazzleProduct>[];
	}

	constructor() {
		super(ZAZZLE_CONFIG, ZAZZLE_DEFAULT_OPTIONS);
	}
}
