import { EnumTime } from './time.interface';
import { EnumSystem, EnumType, IUnitRecord } from '../shared/unit-system.interface';


/**------------------------------------------------------
 * Time Infos
 */
export const TIME_INFOS: Record<EnumTime, IUnitRecord> = {

	// -------------------------------- Time (International)
	[EnumTime.Nanosecond]: {
		code		: EnumTime.Nanosecond,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Nanosecond',
		namePlural	: 'Nanoseconds',
		symbol		: 'ns',
		toBase		: 1 / 1000000000
	},
	[EnumTime.Microsecond]: {
		code		: EnumTime.Microsecond,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Microsecond',
		namePlural	: 'Microseconds',
		symbol		: 'mu',
		toBase		: 1 / 1000000
	},
	[EnumTime.Millisecond]: {
		code		: EnumTime.Millisecond,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Millisecond',
		namePlural	: 'Milliseconds',
		symbol		: 'ms',
		toBase		: 1 / 1000
	},
	[EnumTime.Second]: {
		code		: EnumTime.Second,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Second',
		namePlural	: 'Seconds',
		symbol		: 's',
		toBase		: 1
	},
	[EnumTime.Minute]: {
		code		: EnumTime.Minute,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Minute',
		namePlural	: 'Minutes',
		symbol		: 'min',
		toBase		: 60
	},
	[EnumTime.Hour]: {
		code		: EnumTime.Hour,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Hour',
		namePlural	: 'Hours',
		symbol		: 'h',
		toBase		: 60 * 60
	},
	[EnumTime.Day]: {
		code		: EnumTime.Day,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Day',
		namePlural	: 'Days',
		symbol		: 'd',
		toBase		: 60 * 60 * 24
	},
	[EnumTime.Week]: {
		code		: EnumTime.Week,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Week',
		namePlural	: 'Weeks',
		symbol		: 'week',
		toBase		: 60 * 60 * 24 * 7
	},
	[EnumTime.Month]: {
		code		: EnumTime.Month,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Month',
		namePlural	: 'Months',
		symbol		: 'month',
		toBase		: (60 * 60 * 24 * 365.25) / 12
	},
	[EnumTime.Year]: {
		code		: EnumTime.Year,
		type		: EnumType.Time,
		system		: EnumSystem.International,
		name		: 'Year',
		namePlural	: 'Years',
		symbol		: 'year',
		toBase		: 60 * 60 * 24 * 365.25
	}
};
