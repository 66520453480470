import { EnumKdpCoverMineType } from '../../app-constants/platforms/kindle-direct-publishing/kdp-amazon.constants';
import { EnumPodImageColor, EnumPodImageColorType } from '../../app-constants/platforms/print-on-demand.constants';


/**------------------------------------------------------
 * Cover Data
 */
export interface IKdpCoverImage {
	flyId			: string;				// id of the design
	mimeType		: EnumKdpCoverMineType;
	fileName		: string;
	imgUrl          : ICoverImageUrl;
	resolution      : ICoverImageResolution;
	colors          : ICoverImageColors;
	pHash			: string;
	createdAt	    : Date;
	updatedAt	    : Date;
}


/**------------------------------------------------------
 * Divided Parts of the Interface
 */
export interface ICoverImageUrl {
	icon	    : string;
	preview	    : string;
	original    : string;
}

export interface ICoverImageResolution {
	width       : number;
	height      : number;
}

export interface ICoverImageColors {
	dominant    	: EnumPodImageColor;
	avg         	: string;
	colorType   	: EnumPodImageColorType;
	colorList		: Array<{
		name		: EnumPodImageColor;
		percentage	: number;
	}>;
}
