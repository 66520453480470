/**------------------------------------------------------
 * Page state API constants
 */
export enum EnumPageNames {
	Home 							= 'home',

	// edit page
	EditPodOptions 					= `edit_pod_page_options`,
	EditPodState   					= `edit_pod_page_state`,
	EditKdpOptions 					= `edit_kdp_page_options`,
	EditKdpState   					= `edit_kdp_page_state`,

	// design management
	DesignManagementToolbarState 	= `design_management_toolbar_state`

	// ... add more here
}
