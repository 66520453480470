/**------------------------------------------------------
 * Color Definitions
 */
export enum EnumDefaultColor {
	Yellow 		= '#ffc400',
	Blue 		= '#3b7ddd',
	Green 		= '#28a745',
	Orange 		= '#fd7e14',
	Red 		= '#dc3545',
	Gray 		= '#919191',
	LightGray 	= '#ced4da',
	White 		= '#ffffff'
}

export enum EnumActionColor {
	Info 		= EnumDefaultColor.Yellow,
	Success 	= EnumDefaultColor.Green,
	Warning 	= EnumDefaultColor.Orange,
	Error 		= EnumDefaultColor.Red
}

// Define the colors for the toasts
export enum EnumToastColor {
	ToastDefault	= '#4d4d4d',
	ToastSuccess	= '#28a745',
	ToastError		= '#dc3545e0',
	ToastWarning	= '#fd7d14dc',
	ToastAction		= '#4d4d4d' 		// eslint-disable-line @typescript-eslint/no-duplicate-enum-values
}
