import { EnumSpreadshirtProduct, EnumSpreadshirtSorting } from '../../../../../../constants/research';
import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AbstractSearchLinks } from '../../../../data/search-links.abstract';
import { SPREADSHIRT_CONFIG_ES } from './spreadshirt-es.data';
import { ISpreadshirtSearchOptions, SPREADSHIRT_DEFAULT_OPTIONS } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Spreadshirt ES Search Links
 */
export class SpreadshirtEsSearch extends AbstractSearchLinks<ISpreadshirtSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumSpreadshirtSorting>[] {
		return SPREADSHIRT_CONFIG_ES.sortings as ISearchParameterConfig<EnumSpreadshirtSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumSpreadshirtProduct>[] {
		return SPREADSHIRT_CONFIG_ES.products as ISearchParameterConfig<EnumSpreadshirtProduct>[];
	}

	constructor() {
		super(SPREADSHIRT_CONFIG_ES, SPREADSHIRT_DEFAULT_OPTIONS);
	}
}
