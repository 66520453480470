import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Sorting Definitions
 */
export enum EnumKeywordExtractSorting {
	None 			 = 'none',
	OccurrenceAsc 	 = 'occurrence_asc',
	OccurrenceDesc 	 = 'occurrence_desc',
	WordLengthAsc 	 = 'word_length_asc',
	WordLengthDesc 	 = 'word_length_desc',
	Random 			 = 'random',
	NonDeterministic = 'non_deterministic'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IKeywordExtractOptions {
	language			: EnumLanguage;
	maxResults			: number;
	caseInsensitive		: boolean;
	removeDigits 		: boolean;
	removeDuplicates 	: boolean;
	minLength 			: number;
	minOccurrenceCount	: number;
	chainedWords 		: boolean;
	maxNgrams 			: number;
	excludeWords		: string[];
	sorting				: EnumKeywordExtractSorting;
}

export interface IKeywordWithCount {
	keyword 		: string;
	count 			: number;
}

export interface IKeywordWithScore {
	keyword 		: string;
	count 			: number;
	score 			: number;
}

export interface ITextWeights {
	text			: string;
	weight			: number;
}
