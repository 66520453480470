/**------------------------------------------------------
 * Page Enum Definitions
 */
export enum EnumSidebarPage {

	/**------------------------------------------------------
	 * General / Shared
	 */
	Menu 	 					= 'sidebar_menu',
	Dashboard 					= 'sidebar_dashboard',

	// --------------------------------- general pages
	Settings 	 				= 'sidebar_settings',
	Home 	 					= 'sidebar_home',
	Tutorials 					= 'sidebar_tutorials',
	Support 	 				= 'sidebar_support',


	/**------------------------------------------------------
	 * Research
	 */
	ResearchMenu  				= 'sidebar_research_menu',
	ResearchMenuResearch  		= 'sidebar_research_menu_research',
	ResearchMenuAiResearch  	= 'sidebar_research_menu_ai_research',
	ResearchMenuEvents 	 		= 'sidebar_research_menu_events',
	ResearchMenuTrademark 		= 'sidebar_research_menu_trademark',
	ResearchMenuKeywords 		= 'sidebar_research_menu_keywords',
	ResearchMenuAdvertisement 	= 'sidebar_research_menu_advertisement',
	// ResearchMenuStatistics 	= 'sidebar_research_menu_statistics',
	// ResearchMenuDesignerTeam = 'sidebar_research_menu_designer_team',

	// --------------------------------- research settings
	ResearchSettingsEventNotifications = 'sidebar_settings_research_event_notifications',

	// --------------------------------- research pages
	ResearchBestSeller 			= 'sidebar_research_best_sellers',
	ResearchArchive 			= 'sidebar_research_archive',
	ResearchSellerTrends		= 'sidebar_research_seller_trends',
	// ResearchSellerHistory	= 'sidebar_research_seller_history',
	ResearchDeleted 			= 'sidebar_research_deleted',
	ResearchRtr 				= 'sidebar_research_rtr',
	// ResearchSubcategoryRanks = 'sidebar_research_subcategory_ranks',
	// ResearchGetInspiredMba 	= 'sidebar_research_get_inspired_mba',
	ResearchGetInspiredPod 		= 'sidebar_research_get_inspired_pod',
	ResearchBrands 				= 'sidebar_research_brands',
	ResearchCategories 			= 'sidebar_research_categories',
	ResearchNicheAnalysis 		= 'sidebar_research_niche_analysis',
	ResearchNicheList 			= 'sidebar_research_niche_list',
	ResearchHobbyList 			= 'sidebar_research_hobby_list',
	ResearchFavorites 			= 'sidebar_research_favorites',

	// EventsEventSellers 		= 'sidebar_events_event_sellers',
	EventsSocialMedia 			= 'sidebar_events_social_media',
	EventsCalendar 				= 'sidebar_events_calendar',
	// EventsUpcoming 			= 'sidebar_events_upcoming',

	TrademarkSearch 			= 'sidebar_trademark_search',
	TrademarkRegistration 		= 'sidebar_trademark_registration',
	TrademarkWatchlist 			= 'sidebar_trademark_watchlist',

	KeywordSearch 				= 'sidebar_keyword_search',
	KeywordCompetition			= 'sidebar_keyword_competition',
	KeywordHunter				= 'sidebar_keyword_hunter',
	ProductExplorer				= 'sidebar_keyword_product_explorer',
	KeywordSuggestions			= 'sidebar_keyword_suggestions',
	KeywordTagGenerator			= 'sidebar_keyword_tag_generator',
	KeywordLists				= 'sidebar_keyword_lists',
	KeywordWorkbench			= 'sidebar_keyword_workbench',
	KeywordWorkbenchModal		= 'sidebar_keyword_workbench_modal',

	KeywordBoardPinnedOptions	= 'keyword_board_pinned_options',

	RankPositioning				= 'sidebar_rank_positioning',
	SponsoredLookup				= 'sidebar_sponsored_lookup',
	AdvertisingSuggestions		= 'sidebar_advertising_suggestions',
	AdvertisingLists			= 'sidebar_advertising_lists',

	NicheFinder					= 'sidebar_ai_research_niche_finder',
	QuotesAndPuns				= 'sidebar_ai_research_quotes_and_puns',
	PromptSearch				= 'sidebar_ai_research_prompt_search',

	// ManagementTeamDesigner	= 'sidebar_management_team_designer',
	// ManagementTasks			= 'sidebar_management_tasks',
	// ManagementInvoices		= 'sidebar_management_invoices',
	// ManagementDesignRequests = 'sidebar_management_design_requests',
	// ManagementDesignWork		= 'sidebar_management_design_work',
	// ManagementFindDesigners	= 'sidebar_management_find_designers',

	// StatisticsUploads		= 'sidebar_statistics_uploads',
	// StatisticsEarnings		= 'sidebar_statistics_earnings',


	/**------------------------------------------------------
	 * Upload
	 */
	UploadMenuDesignsUploads	= 'sidebar_upload_menu_designs_uploads',
	UploadMenuPod		  		= 'sidebar_upload_menu_pod',
	UploadMenuKdp		  		= 'sidebar_upload_menu_kdp',
	UploadMenuUpload  			= 'sidebar_upload_menu_upload',
	// UploadMenuAdvertisePromote 	= 'sidebar_upload_menu_advertise_promote',
	// UploadMenuDownloadsExtras  	= 'sidebar_upload_menu_download_extras',

	// --------------------------------- upload settings
	// ...

	// --------------------------------- upload pages
	UploadPodStatistics 		= 'sidebar_pod_statistics',
	UploadPodDesignManagement	= 'sidebar_pod_design_management',
	UploadPodEdit 				= 'sidebar_pod_edit',
	// UploadPodMbaManager 		= 'sidebar_pod_mba_manager',
	// UploadPodProfileManager 	= 'sidebar_pod_profile_manager',
	// UploadPodSuggestionManager = 'sidebar_pod_suggestion_manager',

	UploadKdpEditUpload 		= 'sidebar_kdp_edit_upload',
	// UploadKdpSalesStatistics	= 'sidebar_kdp_sales_statistics',
	// UploadKdpBooksDesigns 	= 'sidebar_kdp_books_designs',
	// UploadKdpManuscripts 	= 'sidebar_kdp_manuscripts',
	// UploadKdpEdit 			= 'sidebar_kdp_edit',
	// UploadKdpProfileManager 	= 'sidebar_kdp_profile_manager',
	// UploadKdpSuggestionManager = 'sidebar_kdp_suggestion_manager',
	// UploadKdpBookGenerator 	= 'sidebar_kdp_book_generator',

	UploadSchedule 				= 'sidebar_upload_schedule',
	UploadAccountsTemplates		= 'sidebar_upload_accounts_templates',
	UploadSetupPOD 				= 'sidebar_upload_setup_pod',
	UploadSetupKDP 				= 'sidebar_upload_setup_kdp',
	UploadAiTemplates 			= 'sidebar_upload_ai_templates',
	// UploadTemplateGroups 	= 'sidebar_upload_template_groups',

	UploadPodTmReport 			= 'sidebar_upload_pod_tm_report',
	UploadPodTmWhitelist		= 'sidebar_upload_pod_tm_whitelist',
	UploadPodTmBlacklist		= 'sidebar_upload_pod_tm_blacklist'

	// UploadAdvertiseAmazonDsp 		= 'sidebar_upload_advertise_amazon_dsp',
	// UploadAdvertiseSocialUploader 	= 'sidebar_upload_advertise_social_uploader',

	// UploadDownloadsResourceBundles 	= 'sidebar_upload_downloads_resource_bundles',
	// UploadDownloadsDesignsVectors 	= 'sidebar_upload_downloads_designs_vectors'
}
