import { COUNTRY_FLAG_ICONS } from '../../assets';
import { EnumCountry } from '../country/country.interface';
import { EnumLanguage, ILanguageInfo } from './language.interface';


/**------------------------------------------------------
 * Language Infos
 */
export const LANGUAGE_INFOS: Record<EnumLanguage, ILanguageInfo> = {
	// cspell:disable
	[EnumLanguage.AF]: {
		code			: EnumLanguage.AF,
		name			: 'Afrikaans',
		nameNative 		: 'Afrikaans',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AF]
	},
	[EnumLanguage.AM]: {
		code			: EnumLanguage.AM,
		name			: 'Amharic',
		nameNative 		: 'Amharic',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AM]
	},
	[EnumLanguage.AR]: {
		code			: EnumLanguage.AR,
		name			: 'Arabic',
		nameNative 		: 'العربية',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AR]
	},
	[EnumLanguage.AZ]: {
		code			: EnumLanguage.AZ,
		name			: 'Azerbaijani',
		nameNative 		: 'Azerbaijani',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AZ]
	},
	[EnumLanguage.BE]: {
		code			: EnumLanguage.BE,
		name			: 'Belarusian',
		nameNative 		: 'Belarusian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BE]
	},
	[EnumLanguage.BG]: {
		code			: EnumLanguage.BG,
		name			: 'Bulgarian',
		nameNative 		: 'Bulgarian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BG]
	},
	[EnumLanguage.BI]: {
		code			: EnumLanguage.BI,
		name			: 'Bislama',
		nameNative 		: 'Bislama',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BI]
	},
	[EnumLanguage.BN]: {
		code			: EnumLanguage.BN,
		name			: 'Bengali',
		nameNative 		: 'Bengali',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BD]
	},
	[EnumLanguage.BR]: {
		code			: EnumLanguage.BR,
		name			: 'Breton',
		nameNative 		: 'Breton',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BR]
	},
	[EnumLanguage.BS]: {
		code			: EnumLanguage.BS,
		name			: 'Bosnian',
		nameNative 		: 'Bosnian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.BS]
	},
	[EnumLanguage.CA]: {
		code			: EnumLanguage.CA,
		name			: 'Catalan',
		nameNative 		: 'Catalan',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CA]
	},
	[EnumLanguage.CS]: {
		code			: EnumLanguage.CS,
		name			: 'Czech',
		nameNative 		: 'Czech',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CZ]
	},
	[EnumLanguage.CY]: {
		code			: EnumLanguage.CY,
		name			: 'Welsh',
		nameNative 		: 'Welsh',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CY]
	},
	[EnumLanguage.DA]: {
		code			: EnumLanguage.DA,
		name			: 'Danish',
		nameNative 		: 'Danish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DK]
	},
	[EnumLanguage.DE]: {
		code			: EnumLanguage.DE,
		name			: 'German',
		nameNative 		: 'Deutsch',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DE]
	},
	[EnumLanguage.DV]: {
		code			: EnumLanguage.DV,
		name			: 'Divehi',
		nameNative 		: 'Divehi',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MV]
	},
	[EnumLanguage.DZ]: {
		code			: EnumLanguage.DZ,
		name			: 'Dzongkha',
		nameNative 		: 'Dzongkha',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.DZ]
	},
	[EnumLanguage.EL]: {
		code			: EnumLanguage.EL,
		name			: 'Greek',
		nameNative 		: 'Greek',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GR]
	},
	[EnumLanguage.EN]: {
		code			: EnumLanguage.EN,
		name			: 'English',
		nameNative 		: 'English',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UK]
	},
	[EnumLanguage.EO]: {
		code			: EnumLanguage.EO,
		name			: 'Esperanto',
		nameNative 		: 'Esperanto',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UK]
	},
	[EnumLanguage.ES]: {
		code			: EnumLanguage.ES,
		name			: 'Spanish',
		nameNative 		: 'Española',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ES]
	},
	[EnumLanguage.ET]: {
		code			: EnumLanguage.ET,
		name			: 'Estonian',
		nameNative 		: 'Estonian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ET]
	},
	[EnumLanguage.EU]: {
		code			: EnumLanguage.EU,
		name			: 'Basque',
		nameNative 		: 'Basque',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ES]
	},
	[EnumLanguage.FA]: {
		code			: EnumLanguage.FA,
		name			: 'Persian',
		nameNative 		: 'Persian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IR]
	},
	[EnumLanguage.FI]: {
		code			: EnumLanguage.FI,
		name			: 'Finnish',
		nameNative 		: 'Finnish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FI]
	},
	[EnumLanguage.FJ]: {
		code			: EnumLanguage.FJ,
		name			: 'Fijian',
		nameNative 		: 'Fijian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FJ]
	},
	[EnumLanguage.FR]: {
		code			: EnumLanguage.FR,
		name			: 'French',
		nameNative 		: 'French',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.FR]
	},
	[EnumLanguage.GA]: {
		code			: EnumLanguage.GA,
		name			: 'Irish',
		nameNative 		: 'Irish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GA]
	},
	[EnumLanguage.GD]: {
		code			: EnumLanguage.GD,
		name			: 'Scots Gaelic',
		nameNative 		: 'Scots Gaelic',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GD]
	},
	[EnumLanguage.GL]: {
		code			: EnumLanguage.GL,
		name			: 'Galician',
		nameNative 		: 'Galician',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ES]
	},
	[EnumLanguage.GN]: {
		code			: EnumLanguage.GN,
		name			: 'Guarani',
		nameNative 		: 'Guarani',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GN]
	},
	[EnumLanguage.GU]: {
		code			: EnumLanguage.GU,
		name			: 'Gujarati',
		nameNative 		: 'Gujarati',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IN]
	},
	[EnumLanguage.HE]: {
		code			: EnumLanguage.HE,
		name			: 'Hebrew',
		nameNative 		: 'Hebrew',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PS]
	},
	[EnumLanguage.HI]: {
		code			: EnumLanguage.HI,
		name			: 'Hindi',
		nameNative 		: 'Hindi',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IN]
	},
	[EnumLanguage.HR]: {
		code			: EnumLanguage.HR,
		name			: 'Croatian',
		nameNative 		: 'Croatian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HR]
	},
	[EnumLanguage.HT]: {
		code			: EnumLanguage.HT,
		name			: 'Haitian Creole',
		nameNative 		: 'Haitian Creole',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HT]
	},
	[EnumLanguage.HU]: {
		code			: EnumLanguage.HU,
		name			: 'Hungarian',
		nameNative 		: 'Hungarian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.HU]
	},
	[EnumLanguage.HY]: {
		code			: EnumLanguage.HY,
		name			: 'Armenian',
		nameNative 		: 'Armenian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AM]
	},
	[EnumLanguage.ID]: {
		code			: EnumLanguage.ID,
		name			: 'Indonesian',
		nameNative 		: 'Indonesian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ID]
	},
	[EnumLanguage.IS]: {
		code			: EnumLanguage.IS,
		name			: 'Icelandic',
		nameNative 		: 'Icelandic',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IS]
	},
	[EnumLanguage.IT]: {
		code			: EnumLanguage.IT,
		name			: 'Italian',
		nameNative 		: 'Italian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IT]
	},
	[EnumLanguage.JA]: {
		code			: EnumLanguage.JA,
		name			: 'Japanese',
		nameNative 		: 'Japanese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.JP]
	},
	[EnumLanguage.KA]: {
		code			: EnumLanguage.KA,
		name			: 'Georgian',
		nameNative 		: 'Georgian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.GE]
	},
	[EnumLanguage.KK]: {
		code			: EnumLanguage.KK,
		name			: 'Kazakh',
		nameNative 		: 'Kazakh',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KZ]
	},
	[EnumLanguage.KM]: {
		code			: EnumLanguage.KM,
		name			: 'Khmer',
		nameNative 		: 'Khmer',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KM]
	},
	[EnumLanguage.KN]: {
		code			: EnumLanguage.KN,
		name			: 'Kannada',
		nameNative 		: 'Kannada',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KN]
	},
	[EnumLanguage.KO]: {
		code			: EnumLanguage.KO,
		name			: 'Korean',
		nameNative 		: 'Korean',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KR]
	},
	[EnumLanguage.KU]: {
		code			: EnumLanguage.KU,
		name			: 'Kurdish',
		nameNative 		: 'Kurdish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IQ]
	},
	[EnumLanguage.KY]: {
		code			: EnumLanguage.KY,
		name			: 'Kyrgyz',
		nameNative 		: 'Kyrgyz',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KG]
	},
	[EnumLanguage.LA]: {
		code			: EnumLanguage.LA,
		name			: 'Latin',
		nameNative 		: 'Latin',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LA]
	},
	[EnumLanguage.LB]: {
		code			: EnumLanguage.LB,
		name			: 'Luxembourgish',
		nameNative 		: 'Luxembourgish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LB]
	},
	[EnumLanguage.LO]: {
		code			: EnumLanguage.LO,
		name			: 'Lao',
		nameNative 		: 'Lao',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LA]
	},
	[EnumLanguage.LT]: {
		code			: EnumLanguage.LT,
		name			: 'Lithuanian',
		nameNative 		: 'Lithuanian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LT]
	},
	[EnumLanguage.LV]: {
		code			: EnumLanguage.LV,
		name			: 'Latvian',
		nameNative 		: 'Latvian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.LV]
	},
	[EnumLanguage.MG]: {
		code			: EnumLanguage.MG,
		name			: 'Malagasy',
		nameNative 		: 'Malagasy',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MG]
	},
	[EnumLanguage.MH]: {
		code			: EnumLanguage.MH,
		name			: 'Marshallese',
		nameNative 		: 'Marshallese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MH]
	},
	[EnumLanguage.MI]: {
		code			: EnumLanguage.MI,
		name			: 'Maori',
		nameNative 		: 'Maori',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NZ]
	},
	[EnumLanguage.MK]: {
		code			: EnumLanguage.MK,
		name			: 'FYRO Macedonian',
		nameNative 		: 'FYRO Macedonian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MK]
	},
	[EnumLanguage.ML]: {
		code			: EnumLanguage.ML,
		name			: 'Malayalam',
		nameNative 		: 'Malayalam',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ML]
	},
	[EnumLanguage.MN]: {
		code			: EnumLanguage.MN,
		name			: 'Mongolian',
		nameNative 		: 'Mongolian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MN]
	},
	[EnumLanguage.MR]: {
		code			: EnumLanguage.MR,
		name			: 'Marathi',
		nameNative 		: 'Marathi',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MR]
	},
	[EnumLanguage.MS]: {
		code			: EnumLanguage.MS,
		name			: 'Malay',
		nameNative 		: 'Malay',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MY]
	},
	[EnumLanguage.MT]: {
		code			: EnumLanguage.MT,
		name			: 'Maltese',
		nameNative 		: 'Maltese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MT]
	},
	[EnumLanguage.MY]: {
		code			: EnumLanguage.MY,
		name			: 'Burmese',
		nameNative 		: 'Burmese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MY]
	},
	[EnumLanguage.NE]: {
		code			: EnumLanguage.NE,
		name			: 'Nepali',
		nameNative 		: 'Nepali',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NE]
	},
	[EnumLanguage.NL]: {
		code			: EnumLanguage.NL,
		name			: 'Dutch',
		nameNative 		: 'Dutch',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NL]
	},
	[EnumLanguage.NO]: {
		code			: EnumLanguage.NO,
		name			: 'Norwegian',
		nameNative 		: 'Norwegian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NO]
	},
	[EnumLanguage.NR]: {
		code			: EnumLanguage.NR,
		name			: 'Nauruan',
		nameNative 		: 'Nauruan',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NR]
	},
	[EnumLanguage.NY]: {
		code			: EnumLanguage.NY,
		name			: 'Nyanja (Chichewa)',
		nameNative 		: 'Nyanja (Chichewa)',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.MW]
	},
	[EnumLanguage.PA]: {
		code			: EnumLanguage.PA,
		name			: 'Punjabi',
		nameNative 		: 'Punjabi',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PA]
	},
	[EnumLanguage.PL]: {
		code			: EnumLanguage.PL,
		name			: 'Polish',
		nameNative 		: 'Polish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PL]
	},
	[EnumLanguage.PS]: {
		code			: EnumLanguage.PS,
		name			: 'Pashto',
		nameNative 		: 'Pashto',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PS]
	},
	[EnumLanguage.PT]: {
		code			: EnumLanguage.PT,
		name			: 'Portuguese',
		nameNative 		: 'Portuguese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PT]
	},
	[EnumLanguage.RO]: {
		code			: EnumLanguage.RO,
		name			: 'Romanian',
		nameNative 		: 'Romanian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.RO]
	},
	[EnumLanguage.RU]: {
		code			: EnumLanguage.RU,
		name			: 'Russian',
		nameNative 		: 'Russian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.RU]
	},
	[EnumLanguage.SD]: {
		code			: EnumLanguage.SD,
		name			: 'Sindhi',
		nameNative 		: 'Sindhi',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SD]
	},
	[EnumLanguage.SI]: {
		code			: EnumLanguage.SI,
		name			: 'Sinhala',
		nameNative 		: 'Sinhala',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SI]
	},
	[EnumLanguage.SK]: {
		code			: EnumLanguage.SK,
		name			: 'Slovak',
		nameNative 		: 'Slovak',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SK]
	},
	[EnumLanguage.SL]: {
		code			: EnumLanguage.SL,
		name			: 'Slovenian',
		nameNative 		: 'Slovenian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SL]
	},
	[EnumLanguage.SM]: {
		code			: EnumLanguage.SM,
		name			: 'Samoan',
		nameNative 		: 'Samoan',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SM]
	},
	[EnumLanguage.SN]: {
		code			: EnumLanguage.SN,
		name			: 'Shona',
		nameNative 		: 'Shona',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SN]
	},
	[EnumLanguage.SO]: {
		code			: EnumLanguage.SO,
		name			: 'Somali',
		nameNative 		: 'Somali',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SO]
	},
	[EnumLanguage.SQ]: {
		code			: EnumLanguage.SQ,
		name			: 'Albanian',
		nameNative 		: 'Albanian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.AL]
	},
	[EnumLanguage.SR]: {
		code			: EnumLanguage.SR,
		name			: 'Serbian',
		nameNative 		: 'Serbian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SR]
	},
	[EnumLanguage.SV]: {
		code			: EnumLanguage.SV,
		name			: 'Swedish',
		nameNative 		: 'Swedish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.SV]
	},
	[EnumLanguage.SW]: {
		code			: EnumLanguage.SW,
		name			: 'Swahili',
		nameNative 		: 'Swahili',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.KE]
	},
	[EnumLanguage.TA]: {
		code			: EnumLanguage.TA,
		name			: 'Tamil',
		nameNative 		: 'Tamil',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IN]
	},
	[EnumLanguage.TE]: {
		code			: EnumLanguage.TE,
		name			: 'Telugu',
		nameNative 		: 'Telugu',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IN]
	},
	[EnumLanguage.TG]: {
		code			: EnumLanguage.TG,
		name			: 'Tajik',
		nameNative 		: 'Tajik',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TG]
	},
	[EnumLanguage.TH]: {
		code			: EnumLanguage.TH,
		name			: 'Thai',
		nameNative 		: 'Thai',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TH]
	},
	[EnumLanguage.TI]: {
		code			: EnumLanguage.TI,
		name			: 'Tigrinya',
		nameNative 		: 'Tigrinya',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ER]
	},
	[EnumLanguage.TK]: {
		code			: EnumLanguage.TK,
		name			: 'Turkmen',
		nameNative 		: 'Turkmen',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TM]
	},
	[EnumLanguage.TL]: {
		code			: EnumLanguage.TL,
		name			: 'Tagalog (Filipino)',
		nameNative 		: 'Tagalog (Filipino)',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TL]
	},
	[EnumLanguage.TO]: {
		code			: EnumLanguage.TO,
		name			: 'Tongan',
		nameNative		: 'Tongan',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TO]
	},
	[EnumLanguage.TR]: {
		code			: EnumLanguage.TR,
		name			: 'Turkish',
		nameNative 		: 'Turkish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TR]
	},
	[EnumLanguage.TT]: {
		code			: EnumLanguage.TT,
		name			: 'Tatar',
		nameNative 		: 'Tatar',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TT]
	},
	[EnumLanguage.TW]: {
		code			: EnumLanguage.TW,
		name			: 'Twi',
		nameNative 		: 'Twi',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.TW]
	},
	[EnumLanguage.UK]: {
		code			: EnumLanguage.UK,
		name			: 'Ukrainian',
		nameNative 		: 'Ukrainian',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UK]
	},
	[EnumLanguage.UR]: {
		code			: EnumLanguage.UR,
		name			: 'Urdu',
		nameNative 		: 'Urdu',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.PK]
	},
	[EnumLanguage.UZ]: {
		code			: EnumLanguage.UZ,
		name			: 'Uzbek',
		nameNative 		: 'Uzbek',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.UZ]
	},
	[EnumLanguage.VI]: {
		code			: EnumLanguage.VI,
		name			: 'Vietnamese',
		nameNative 		: 'Vietnamese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.VN]
	},
	[EnumLanguage.XH]: {
		code			: EnumLanguage.XH,
		name			: 'Xhosa',
		nameNative 		: 'Xhosa',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ZA]
	},
	[EnumLanguage.YI]: {
		code			: EnumLanguage.YI,
		name			: 'Yiddish',
		nameNative 		: 'Yiddish',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.IL]
	},
	[EnumLanguage.YO]: {
		code			: EnumLanguage.YO,
		name			: 'Yoruba',
		nameNative 		: 'Yoruba',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.NG]
	},
	[EnumLanguage.ZH]: {
		code			: EnumLanguage.ZH,
		name			: 'Chinese',
		nameNative 		: 'Chinese',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.CN]
	},
	[EnumLanguage.ZU]: {
		code			: EnumLanguage.ZU,
		name			: 'Zulu',
		nameNative 		: 'Zulu',
		flagIcon 		: COUNTRY_FLAG_ICONS[EnumCountry.ZA]
	}
	// cspell:enable
};
