import { UtilUtf8Encoder } from './utf8-encoder';


/**------------------------------------------------------
 * Base32 Encoding / Decoding
 * --------------------------
 * > Base 32 is a numerical system that uses a set of 32 different
 * > characters to represent data. It typically includes the
 * > 26 uppercase letters (A-Z) and the numbers 2 through 7.
 * > It's commonly used in encoding binary data in QR Codes,
 * > certain barcodes, and in identifiers for email attachments.
 * --------------------------
 * > online encoder: https://emn178.github.io/online-tools/base32_encode.html
 */
export class UtilBase32Encoder {

	//** Configurations */
	private readonly BASE32_ALPHABET: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';			// list all the characters in the base32 alphabet

	constructor(
		private utf8Encoder: UtilUtf8Encoder
	) {}


	/**------------------------------------------------------
	 * Encode to Base32
	 */
	toBase32(stringValue: string): string {

		//0 - correct usage of TextEncoder to convert string to Uint8Array
		const buffer: Uint8Array = this.utf8Encoder.encodeToUint8Array(stringValue);

		//1 - convert the buffer to a bit string
		let bits: string = '';
		for (const byte of buffer) {
			bits += byte.toString(2).padStart(8, '0');
		}

		//2 - convert the bit string to a base32 string
		let base32String: string = '';
		for (let i: number = 0; i < bits.length; i += 5) {
			const chunk: string = bits.slice(i, i + 5);
			base32String += this.BASE32_ALPHABET[parseInt(chunk.padEnd(5, '0'), 2)];
		}

		//3 - pad the base32 string to make its length a multiple of 8
		while (base32String.length % 8 !== 0) base32String += '=';
		return base32String;
	}


	/**------------------------------------------------------
	 * Decode from Base32
	 */
	fromBase32(base32Value: string): string {
		const uint8Array : Uint8Array = this.fromBase32ToUint8Array(base32Value);
		const decodedData: string 	  = this.utf8Encoder.decodeFromUint8Array(uint8Array);
		return decodedData;
	}

	fromBase32ToUint8Array(base32Value: string): Uint8Array {

		//0 - convert the base32 string to a bit string
		let bitString: string = '';
		for (const base32Char of base32Value) {

			//a. get the index of the character in the alphabet
			// > skip the padding characters
			const char		: string = base32Char.toUpperCase();
			const charIndex	: number = this.BASE32_ALPHABET.indexOf(char);
			if (charIndex === -1) continue;

			//b. decode char
			const charBinary: string = charIndex.toString(2).padStart(5, '0');
			bitString += charBinary;
		}

		//1 - convert the bit string to a Uint8Array
		const output: number[] = [];
		for (let i: number = 0; i + 8 <= bitString.length; i += 8) {
			const byte: string = bitString.substring(i, i + 8);
			output.push(parseInt(byte, 2));
		}

		//2 - return the Uint8Array
		const uint8Array: Uint8Array = new Uint8Array(output);
		return uint8Array;
	}
}
