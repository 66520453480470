import { TypeReject, TypeResolve } from '@libs/constants';


/**------------------------------------------------------
 * Time Utilities
 * --------------
 * > Info: Containing all functionalities for waiting and
 * > delaying the applications
 */
export class UtilRuntime {

	/**------------------------------------------------------
	 * Delay Execution
	 */
	delay(timeMs: number): Promise<void> {
		return new Promise((resolve: TypeResolve) => {
			setTimeout(resolve, timeMs);
		});
	}
	delaySec(timeSec: number): Promise<void> {
		return this.delay(timeSec * 1000);
	}
	delayMin(timeMin: number): Promise<void> {
		return this.delay(timeMin * 1000 * 60);
	}


	/**------------------------------------------------------
	 * Random Delay
	 */
	delayRandom(timeMs: number): Promise<void> {
		const offset	: number = timeMs * 0.2;
		const randTimeMs: number = this.randomNumber(timeMs - offset, timeMs + offset);
		return new Promise((resolve: TypeResolve) => {
			setTimeout(resolve, randTimeMs);
		});
	}


	/**------------------------------------------------------
	 * Wait with Timeout
	 */
	async waitUntil(conditionFn: () => boolean, interval: number = 500): Promise<void> {
		while (!conditionFn()) {
			await this.delay(interval);
		}
	}

	async waitUntilWithTimeout(params: IUtilWaitUntilParams): Promise<void> {
		const startTime: number = Date.now();
		while (!params.conditionFn()) {

			//a. did we run into the timeout
			const alreadyWaitedTime: number = Date.now() - startTime;
			if (alreadyWaitedTime >= params.timeout) {
				params.onTimeout();
				return;
			}

			//b. continue waiting
			await this.delay(params.interval);
		}
	}

	throwErrorDelayed(timeMs: number, error: Error): Promise<null> {
		return new Promise((resolve: TypeResolve<null>, reject: TypeReject) => {
			setTimeout(() => {
				if (error) reject(error);
					else resolve(null);
			}, timeMs);
		});
	}


	/**------------------------------------------------------
	 * Debounce action
	 * > desc.	: Creates a debounced function that delays invoking the provided function until
	 * 			  at least ms milliseconds have elapsed since the last time it was invoked.
	 * > source : https://decipher.dev/30-seconds-of-typescript/docs/debounce
	 */
	debounce(fn: Function, timeMs: number = 300): TypeUtilDebounceFunction {
		let timeoutId: ReturnType<typeof setTimeout>;
		return function (this: any, ...args: any[]) {
			clearTimeout(timeoutId);
			// eslint-disable-next-line no-invalid-this
			timeoutId = setTimeout(() => fn.apply(this, args), timeMs);
		};
	}


	/**------------------------------------------------------
	 * Defer (Let the browser update before calling a function)
	 * > desc.	: Defers invoking a function until the current call stack has cleared.
	 * > source : https://decipher.dev/30-seconds-of-typescript/docs/defer
	 */
	defer(fn: Function, ...args: any): void {
		// eslint-disable-next-line no-restricted-syntax
		setTimeout(fn, 1, ...args);
	}


	/**------------------------------------------------------
	 * Performance Testing
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/hz/
	 * > example : Util.Runtime.checkPerformance(sumReduce) -> { hz: 13611, timeMs: 0.00007346999999135732 }
	 */
	checkPerformance(fn: Function, iterations: number = 100): IUtilFunctionPerformance {

		//0 - run the performance test
		const before: number = performance.now();
		for (let i: number = 0; i < iterations; i++) fn();
		const resultInHz: number = (1000 * iterations) / (performance.now() - before);

		//1 - return the test result
		const result: IUtilFunctionPerformance = {
			hz   	: Math.round(resultInHz),
			time_ms : 1 / resultInHz
		};
		return result;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private randomNumber(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min) + min);			// duplication of the random util functionality
	}
}


//** Types -------------------------------------- */
export type TypeUtilDebounceFunction = (fn: Function, debounceTime: number) => void;


//** Interfaces --------------------------------- */
export interface IUtilFunctionPerformance {
	hz   	: number;		// how many times the function executes in a second
	time_ms : number;		// the duration in ms how long the function execution takes
}

export interface IUtilWaitUntilParams {
	conditionFn	: () => boolean;
	onTimeout	: () => void;
	interval	: 500;			// given in ms
	timeout		: 60000;		// given in ms
}
