import { UtilBasic } from './util-basic';
import { UtilString } from './util-string';


/**------------------------------------------------------
 * Enum Utilities
 * --------------
 * > Info: Containing all enum related functionalities.
 * > Link: https://www.cloudhadoop.com/typescript-enum-iterate/
 * --------------
 * Type from enum
 * > type TypeReturnValue<EnumReturnValues> = keyof typeof EnumReturnValues
 * > type KeyType<Enum> = keyof Enum
 */
export class UtilEnum {

	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString
	) {}


	/**------------------------------------------------------
	 * Basic Enum Functions
	 */
	keys(enumDef: object): string[] {
		this.checkEnumDef(enumDef);
		return Object.keys(enumDef);
	}
	values<T extends string>(enumDef: object): T[] {
		this.checkEnumDef(enumDef);
		return Object.values(enumDef) as T[];
	}
	value<T extends string>(enumDef: object, key: string): T {
		if (!this.includes(enumDef, key)) throw new Error(`UtilEnum => value => FATAL ERROR: the provided enumDef does not contain the key of "${key}"`);
		return (enumDef as any)[key] as T;
	}

	isValid<T extends object>(enumDef: T, key: string): boolean {
		if (this.utilString.isEmpty(key) || !this.includes(enumDef, key)) return false;
		return true;
	}
	areValid<T extends object>(enumDef: T, keys: string[]): boolean {
		for (const key of keys) {
			if (!this.isValid(enumDef, key)) return false;
		}
		return true;
	}

	areValidAndNotEmpty<T extends object>(enumDef: T, keys: string[]): boolean {
		if (keys.length === 0) return false;
		return this.areValid(enumDef, keys);
	}


	/**------------------------------------------------------
	 * Advanced Enum Functions
	 */
	getKeysInclude<T extends object>(enumDef: T, include: string[]): string[] {
		this.checkEnumDef(enumDef);
		if (this.utilString.hasEmptyValues(include)) throw new Error(`UtilEnum => keysInclude => FATAL ERROR: some of the provided include values are empty`);

		//0 - get the keys and values of the enum
		const keys 	: string[] = this.keys(enumDef);
		const values: string[] = this.values(enumDef);

		//1 - get the keys which are matching the include values
		const resultKeys: string[] = [];
		for (let i: number = 0; i < keys.length; ++i) {
			if (include.includes(keys[i]) || include.includes(values[i])) resultKeys.push(keys[i]);
		}
		return resultKeys;
	}
	getKeysExclude<T extends object>(enumDef: T, exclude: string[]): string[] {
		this.checkEnumDef(enumDef);
		if (this.utilString.hasEmptyValues(exclude)) throw new Error(`UtilEnum => keysExclude => FATAL ERROR: some of the provided exclude values are empty`);

		//0 - get the keys and values of the enum
		const keys 	: string[] = this.keys(enumDef);
		const values: string[] = this.values(enumDef);

		//1 - get the keys which are matching the exclude values
		const resultKeys: string[] = [];
		for (let i: number = 0; i < keys.length; ++i) {
			if (!exclude.includes(keys[i]) && !exclude.includes(values[i])) resultKeys.push(keys[i]);
		}
		return resultKeys;
	}

	getValuesInclude<T extends string>(enumDef: object, include: string[]): T[] {
		this.checkEnumDef(enumDef);
		if (this.utilString.hasEmptyValues(include)) throw new Error(`UtilEnum => valuesInclude => FATAL ERROR: some of the provided include values are empty`);
		return this.values<T>(enumDef).filter((elem: T) => include.includes(elem)) as T[];
	}
	getValuesExclude<T extends string>(enumDef: object, exclude: string[]): T[] {
		this.checkEnumDef(enumDef);
		if (this.utilString.hasEmptyValues(exclude)) throw new Error(`UtilEnum => valuesExclude => FATAL ERROR: some of the provided exclude values are empty`);
		return this.values<T>(enumDef).filter((elem: T) => !exclude.includes(elem)) as T[];
	}


	/**------------------------------------------------------
	 * Checks Functions
	 */
	includes<T extends object>(enumDef: T, value: string): boolean {

		//0 - check the function call
		this.checkEnumDef(enumDef);
		if (this.utilString.isEmpty(value)) throw new Error(`UtilEnum => includes => FATAL ERROR: the provided value string is empty`);

		//1 - check if the value is valid for the enum or not
		return this.values(enumDef).includes(value);
	}
	areIncluded<T extends object>(enumDef: T, values: string[]): boolean {
		if (values.length === 0) return false;
		for (const value of values) {
			if (!this.values(enumDef).includes(value)) return false;
		}
		return true;
	}


	/**------------------------------------------------------
	 * Helper Checks
	 */
	private checkEnumDef<T>(enumDef: T) {
		if (this.utilBasic.isUndefined(enumDef)) throw new Error(`UtilEnum => checkEnumDef => FATAL ERROR: the provided enum definition is undefined`);
	}
}
