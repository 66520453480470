import { Util } from '@libs/utilities/util';


export class ValidatorDomainName {

	//** Configurations */
	private VALIDATE_DOMAIN_CHARS_REGEX	: RegExp = /^[a-zA-Z0-9.-]+$/;
	private VALIDATE_TLD_REGEX			: RegExp = /^[a-zA-Z]{2,}$/;
	private VALIDATE_LAST_CHAR_REGEX	: RegExp = /[a-zA-Z0-9]$/;
	private VALIDATE_DOMAIN_NAME_REGEX	: RegExp = /^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/;
	private CONSECUTIVE_PERIODS_REGEX	: RegExp = /\.\./;


	isValid(domain: string): boolean {
		return Util.Array.isEmpty(this.validate(domain));
	}

	validate(domain: string): string[] {

		//0 - check if the domain is defined
		if (Util.String.isEmpty(domain)) return [`Domain address is required.`];

		//1 - check if the domain is valid
		const validationErrors: string[] = this.validateDomain(domain);

		//2 - return no errors
		return validationErrors;
	}

	private validateDomain(domain: string): string[] {

        //0 - check for valid characters in domain name
        if (!this.VALIDATE_DOMAIN_CHARS_REGEX.test(domain)) return ['Invalid characters in domain name.'];

        //1 - split domain into parts for subdomain checks
        const domainParts: string[] = domain.split('.');
		if (domainParts.length < 2) return ['Domain must have both a subdomain and a top-level domain (TLD).'];

		//2 - check for valid first and last characters in domain parts
		if (!this.VALIDATE_DOMAIN_NAME_REGEX.test(domainParts[0])) return ['Invalid first or last character in domain name.'];
        if (!this.VALIDATE_LAST_CHAR_REGEX.test(domainParts[domainParts.length - 1])) return ['The last character of the domain must be an ASCII letter (a-z) or number (0-9).'];

        //3 - check for consecutive periods in domain name parts
        if (this.CONSECUTIVE_PERIODS_REGEX.test(domain)) return ['Domain name cannot contain consecutive periods (..).'];

        //4 - check for valid top-level domain (TLD) format
        const topLevelDomain: string = domainParts[domainParts.length - 1];
        if (!this.VALIDATE_TLD_REGEX.test(topLevelDomain)) return ['Invalid top-level domain (TLD) format.'];

        //5 - valid domain
        return [];
	}
}
