import { EnumDevice, EnumOperatingSystem, EnumSystemTheme } from '@libs/constants';
import { Util } from '@libs/utilities/util';
import { debounceTime, fromEvent } from 'rxjs';

import { IResolution, IScreenInfo } from './device-detection.interface';


/**------------------------------------------------------
 * Device Detection
 */
export class DeviceDetectionService {

	//** Configurations */
	private readonly EVENT_DEBOUNCE_TIME: number = 50;


	/**------------------------------------------------------
	 * Observables
	 */
	onOrientationChange(callback: (orientation: ScreenOrientation) => void): void {
		fromEvent(window, 'orientationchange').pipe(debounceTime(this.EVENT_DEBOUNCE_TIME)).subscribe((event: Event) => {
			callback((event.target as Window).screen.orientation);
		});
	}

	onResize(callback: (resolution: IResolution) => void): void {
		fromEvent(window, 'resize').pipe(debounceTime(this.EVENT_DEBOUNCE_TIME)).subscribe(() => {
			const resolution: IResolution = {
				width	: window.innerWidth,
				height	: window.innerHeight
			};
			callback(resolution);
		});
	}


	/**------------------------------------------------------
	 * Device & OS Infos
	 */
	getScreenInfo(): IScreenInfo {
		const screenInfo: IScreenInfo = {
			width		: screen.width,
			height		: screen.height,
			availWidth	: screen.availWidth,
			availHeight	: screen.availHeight,
			colorDepth	: screen.colorDepth,
			pixelDepth	: screen.pixelDepth
		};
		return screenInfo;
	}

	getResolution(): IResolution {
		const resolution: IResolution = {
			width	: window.innerWidth,
			height	: window.innerHeight
		};
		return resolution;
	}

	getDevice(userAgentParam: string = navigator.userAgent): EnumDevice {
		return Util.UserAgent.determineDevice(userAgentParam);
	}

	getOperatingSystem(userAgentParam: string = navigator.userAgent): EnumOperatingSystem {
		return Util.UserAgent.determineOperatingSystem(userAgentParam);
	}


	/**------------------------------------------------------
	 * Zoom Level
	 */

	//** Browser zoom level */
	getDeviceZoom(): number {
		const pixelRatio: number = (window.outerWidth - 10) / window.innerWidth;
		const zoomLevel : number = Util.Number.inRange(pixelRatio, { min: 0.95, max: 1.05 }) ? 1 : pixelRatio;		// take the rounding variance into consideration
		return zoomLevel;
	}

	//** CSS zoom (the custom one) */
	getVirtualZoom(): number {

		//0 - try to the the defined zoom level from the css
		const browserZoom: string = (document.body.style as any).zoom;
		if (Util.String.isEmpty(browserZoom)) return 1;

		//1 - return the zoom level
		return Util.Number.toNumber(browserZoom);
	}


	/**------------------------------------------------------
	 * Dark Reader Helper
	 */

	//** Get OS theme preference */
	isSystemLightTheme(): boolean { return window?.matchMedia?.('(prefers-color-scheme: light)')?.matches; }
	isSystemDarkTheme() : boolean { return window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches; }

	//** getOsActiveTheme */
	getSystemTheme(): EnumSystemTheme {
		if (this.isSystemLightTheme()) return EnumSystemTheme.Light;
		if (this.isSystemDarkTheme())  return EnumSystemTheme.Dark;
		return EnumSystemTheme.Unknown;
	}

	//** Detect on system theme change */
	onSystemThemeChange(callback: (theme: EnumSystemTheme) => void): void {
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event: MediaQueryListEvent) => {
			if (event.matches) callback(EnumSystemTheme.Dark);
				else callback(EnumSystemTheme.Light);
		});
	}
}
