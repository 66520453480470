import { EnumAmazonMarketplace } from '../../../app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Product Rank Data
 */
export interface IAmazonProductRank {
	asin 			: string;					// 'B09H881W6X'
	img				: string;					// https://m.media-amazon.com/images/I/61FH1jph4gS._AC_UL320_.jpg
	marketplace		: EnumAmazonMarketplace;	// EnumAmazonMarketplace.COM
	brand			: string;					// 'Brand: Drinking Beer Penguin Alcohol Bachelor Party'
	title			: string;					// 'Beer Guin Beer Penguin Saufen Party Beer Drinker T-Shirt'
	price			: number;					// 16.99
	reviews			: {
		count		: number;					// 42
		stars		: number;					// 3.2
	};
	organicRanks	: IAmazonOrganicRank[];
	sponsored		: IKeywordSponsoredRank[];
}

export interface IAmazonOrganicRank {
	keyword			: string;					// example: 'drinking'
	rank			: number;					// the rank counted from the first organic rank with 1
	page			: number;
	createdAt		: Date;						// '2022-05-01'
}

export interface IKeywordSponsoredRank {
	keyword			: string;					// example: 'drinking'
	createdAt		: Date;						// '2022-05-01'
}
