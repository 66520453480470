import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';

import { AddonTapfiliateScript } from './tapfiliate.script';
import { AddonTapfiliateService } from './tapfiliate.service';
import { AddonPageAddonsRenderer } from '../../shared/page-addons-renderer.helper';
import { EnumAddonPageAddonInjection } from '../../shared/page-addons.interface';


@NgModule()
export class AddonTapfiliateModule {

	static register(config: IAddonTapfiliateConfig): ModuleWithProviders<AddonTapfiliateModule> {
		return {
			ngModule	: AddonTapfiliateModule,
			providers	: [
				{
					provide		: EnumAddonPageAddonInjection.TapfiliateAccountId,
					useValue	: config.ACCOUNT_ID as string
				},
				AddonPageAddonsRenderer,
				AddonTapfiliateScript,
				AddonTapfiliateService,
				{
					provide		: APP_INITIALIZER,
					deps		: [AddonTapfiliateScript],
					multi		: true,
					useFactory	: (tapfiliateScript: AddonTapfiliateScript) => {
						return () => tapfiliateScript.create();
					}
				}
			]
		};
	}

	static dummy(): ModuleWithProviders<AddonTapfiliateModule> {
		return {
			ngModule	: AddonTapfiliateModule,
			providers	: [
				AddonTapfiliateScript,
				{
					provide		: EnumAddonPageAddonInjection.TapfiliateAccountId,
					useValue	: null
				},
				{
					provide		: AddonTapfiliateService,
					useClass	: class extends AddonTapfiliateService {
						override detect(ref: string) {}
						override trackConversion(id: string) {}
					}
				}
			]
		};
	}
}


//** Interfaces --------------------------------- */
export interface IAddonTapfiliateConfig {
	ACCOUNT_ID: string;
}
