/**------------------------------------------------------
 * Excluding the Official Brands
 */
export const AMAZON_OFFICIAL_BRANDS: string[] = [
	// cspell:disable
	'Disney',
	'Star Wars',
	'Marvel',
	'DC Comics',
	'Star Track',
	'Warner Bros.',
	'Harry Potter',
	'Wizarding World',
	'WWE',
	'Sanrio',
	'NASA',
	'Naruto Shippuden',
	'Coca-Cola',
	'Despicable Me',
	'20th Century Fox TV',
	'Visit the Star Wars Store',
	'Besuchen Sie den AC/DC-Store',
	'Marque : Déign by Maiden Interprète Iron',
	'Jurassic Park',
	'Visit the Aerosmith Store',
	'Visit the Disney Store',
	'SUPER MARIO',
	'Visit the Nintendo Store',
	'Dr. Seuss',
	'Visit the MTV Store',
	'Sonic The Hedgehog',
	'RICK AND MORTY',
	'Ghostbusters',
	'SpongeBob SquarePants',
	'Brand: SpongeBob SquarePants',
	'Cartoon Network',
	'Visit the Coca-Cola Store',
	'Teenage Mutant Ninja Turtles',
	'Visit the Peanuts Store',
	'Queen',
	'JoJo Siwa',
	'Brand: Dinosaurs',
	'Iron Maiden Official',
	'Legend of Zelda',
	'Visit the Despicable Me Store',
	'Visit the Marvel Store',
	'Brand: Marvel',
	'DreamWorks How to Train Your Dragon',
	'Animal Crossing',
	'Visit the ManCity Store',
	'DC Comic',
	'THE PINK PANTHER',
	'Power Rangers',
	'The Simpsons',
	'Peanuts',
	'Euroleague',
	'Visiter la boutique Marvel',
	'Brand: Disney',
	'Besuchen Sie den WWE-Store',
	'Jurassic World',
	'Visit the Jeep Store',
	'Hello Kitty',
	'The Beatles',
	'Pokemon',
	'Nickelodeon',
	'Naruto',
	'Visit the Slipknot Store',
	'The Office',
	'Visit the Justin Bieber Store',
	'TOM and JERRY',
	'Guns N’ Roses',
	'Brand: Peanuts',
	'Visiter la boutique Star Wars',
	'Cobra Kai',
	'Brand: Transformers',
	'Pac Man',
	'Pixar',
	'Besuchen Sie den Star Trek-Store',
	'super.natural',
	'Big Bang Theory',
	'Rick and Morty Fan Art',
	'Bad Religion',
	'Visita la Store de WWE',
	'LOONEY TUNES',
	'David Bowie',
	'Stranger Things',
	'Visit the Bratz Store',
	'Visit the The Expanse Store',
	'Outlander',
	'Boyz N The Hood',
	'Grumpy Cat',
	'Cuphead',
	'DreamWorks She-Ra And The Princess of Power',
	'BTS',
	'Visit the ODDBODS Store',
	'Visit the Rolling Stones Store',
	'Tupac',
	'Black Sabbath',
	'Visit the Power Rangers Store',
	'Def Leppard',
	'Kuromi',
	'Billie Eilish',
	'Pusheen the cat',
	'Visit the John Lennon Store',
	'BuzzFeed Unsolved',
	'MODARTIS - Lustige Frösche T-Shirts & Geschenke',
	'Aspen Comics',
	'Visit the Sesame Street Store',
	'KATY PERRY',
	'KISS',
	'STAR TREK',
	'Julie And The Phantoms',
	'Godzilla',
	'Visita lo Store di Barbie',
	'Angry Birds',
	'PAW PATROL',
	'Fast and Furious',
	'Spice Girls Official',
	'South Par',
	'Shrek',
	'Unser Sandmännchen',
	'Jurassic World 2',
	'Godzilla vs Kong',
	'Visit the ZZ Top Store',
	'Visit the AC/DC Store',
	'Voltron Defender Of The Universe',
	'Crazy Frog',
	'South Park',
	'Visit the Dolly Parton Store',
	'Besuchen Sie den Feuerwehrmann Sam-Store',
	'Mötley Crüe Official',
	'Voltron Legendary Defender',
	'Visit the The Beatles Store',
	'My Melody',
	'BLACKPINK',
	`Gabby's Dollhouse`,
	'rugrats',
	'Avatar: The Last Airbender',
	'Visit the Bob Marley Store',
	'Falling in Reverse',
	'Misfits',
	'Visit the Rocky Store',
	'Visit the KISS Store',
	'Besuchen Sie den Sanrio-Store',
	'Jurassic Franchise',
	'Tom & Jerry',
	'Visit the ESPN Store',
	'Mortal Kombat',
	'Barbie',
	'Attack on Titan',
	'Bibi & Tina - Die Serie',
	'Breaking Bad',
	'Visit the Nickelodeon Store',
	'Hello Sanrio',
	'Visit the Godzilla Store',
	'Mattel',
	'Visita lo Store di AS Roma',
	'Besuchen Sie den FC Bayern München-Store',
	'Miller Brewing Company',
	'CNCO',
	'NBC',
	'Motorhead Official',
	'Visit the Queen Store',
	'Guns N Roses',
	'Slayer Official',
	'Scorpions Official',
	'Visit the Black Sabbath Store',
	'Freddie Mercury',
	'Slipknot',
	'Backstreet Boys Official',
	'Yungblud',
	'The Walking Dead',
	'Trailer Park Boys',
	'Bob Marley',
	'Dropkick Murphys',
	'Cannibal Corpse',
	'ArenaNet, Guild Wars 2',
	'Mr Bean',
	'JoelG',
	'ODDBODS',
	'Kvelertak',
	'Visit the Angry Birds Store',
	'Elton John',
	'Visit the Lady Gaga Store',
	'Visit the BEYBLADE Store',
	'Sia',
	'Visit the STAR TREK Store',
	'Ozzy Osbourne Official',
	'Shaun das schaf',
	'Visit the Magic The Gathering Store',
	'Crayon Shin-chan',
	'DreamWorks',
	'Visita lo Store di David Bowie',
	'Pink Floyd',
	'WERNER',
	'Tee Grizzley',
	'Community',
	'Minecraft',
	'Visita lo Store di KISS',
	'Bureau of Magic',
	'Besuchen Sie den dc comics-Store',
	'PINK',
	'ZELDA',
	'Back To The Future',
	'Visit the Dungeons & Dragons Store',
	'Visit the LOONEY TUNES Store',
	'G. I. Joe',
	'The Big Lebowski',
	'Gas Monkey Garage',
	'Visiter la boutique Disney',
	'Besuchen Sie den Jurassic World-Store',
	'Besuchen Sie den Marvel-Store',
	'JAWS',
	'Visit the Transformers Store',
	`Visit the Gabby's Dollhouse Store`,
	`Visit the Bob's Burgers Store`,
	'Visit the Ghostbusters Store',
	'Star Wars: The Mandalorian',
	'Visit the Kuromi Store',
	'Visit the TUPAC SHAKUR Store',
	'Visit the Naruto Store',
	'Visit the Jurassic Park Store',
	'Visit the Teenage Mutant Ninja Turtles Store',
	'Visit the FanPrint Store',
	'LOL Surprise',
	'ThunderCats',
	'Visit the Smokey Bear Store',
	'Visit the Unknown Store',
	'BreakingT',
	'Visit the Jurassic World Store',
	'Visit the Death Row Records Store',
	`Visit the Blue's Clues Store`,
	'Visit the The Office Store',
	'Transformers',
	'Visit the Superbad Store',
	'UEFA EURO 2020',
	'Visita la Store de Star Wars',
	'Visita la Store de Marvel',
	'Visita la Store de The Simpsons',
	'Visita la Store de Bob Dylan',
	'Visita la Store de Disney',
	'Visita lo Store di Hasbro',
	'Visita lo Store di Disney',
	'Visita lo Store di WWE',
	'Visit the SUPER MARIO Store',
	'Twin Peaks',
	'Descendents',
	'Jaws',
	'Fortnite',
	'Dungeons & Dragons',
	'Big Lebowski',
	'Madagascar',
	'Kung Fu Panda',
	'ELVIS PRESLEY',
	'FRIENDS',
	'Besuchen Sie den Coca-Cola-Store',
	'Besuchen Sie den Hot Wheels-Store',
	'Besuchen Sie den Mia and Me-Store',
	'Emoji R Us',
	'SPACE JAM',
	'Unbekannt',
	'Hunt Showdown',
	'Visit the Guns N’ Roses Store',
	'Visit the Pink Floyd Store',
	'Visit the Teletubbies Store',
	'Visit the RICK AND MORTY Store',
	'Visit the Cobra Kai Store',
	'Visit the Amazon Essentials Store',
	`Visit the Ferris Bueller's Day Off Store`,
	'Visit the Dodge Store',
	'Visit the Five Finger Death Punch Official Store',
	'Visit the SAN Store',
	'Visit the Blippi Store',
	'Visit the David Bowie Store',
	'Visit the My Melody Store',
	'Visit the Miraculous Store',
	'Visit the Freddie Mercury Store',
	'Visit the Mötley Crüe Official Store',
	'Visit the Poison Band Store',
	'Visit the Green Day Store',
	'Visit the Foo Fighters Store',
	'Visit the Care Bears Store',
	'Visit the Dr. Seuss Store',
	'Visit the Pantera Store',
	'Visit the Motörhead Official Store',
	`Visit the JoJo's Bizarre Adventure Store`,
	'Visit the Minecraft Store',
	'Public Enemy',
	'DreamWorks - Boss Baby',
	'Bud Light',
	'Unknown',
	'The Golden Girls',
	'Budweiser',
	'BUSCH',
	'Strokes',
	'Waylon Jennings',
	'Santa Cruz, CA',
	'Paramount Network',
	'Carmela Soprano Tee',
	'Disney Pixar',
	'Britney Spears',
	'Space Jam: A New Legacy',
	'Rolling Stones',
	'Scarface',
	'Stick War',
	'Mademark',
	'Visit the PlayStation Store',
	'Amazon.com',
	'Besuchen Sie den Iron Maiden Official-Store',
	'BATMAN',
	'Minions',
	'Visita lo Store di Hot Wheels',
	'Visita lo Store di Star Wars',
	'World of Tanks',
	'Générique',
	'Scooby-Doo',
	'Visit the Hello Kitty Store',
	'Suicide Squad',
	'Talladega Nights',
	'The Goonies',
	`Visit the Gold's Gym Store`,
	'The Sandlot',
	'Outer Banks',
	'Visit the Foreigner Store',
	'Visit the Boyz N The Hood Store',
	'Visit the Peppa Pig Store',
	'Visit the Fender Store',
	'Visit the Taco Bell Store',
	'Visit the Attack on Titan Store',
	'Visit the Jurassic World: Fallen Kingdom Store',
	'Hey Arnold',
	'Visit the Uzumaki Store',
	'Visit the Death Note Store',
	'Visit the Mr Potato Head Store',
	'Mean Girls',
	'Visit the LOL Surprise Store',
	`Blue's Clues`,
	'Visit the Top Gun Store',
	'Visit the Paw Patrol Store',
	'Dune',
	'Ford',
	'Visit the Modelo Store',
	'Beetlejuice',
	'Boy Scouts of America',
	`Bob's Burgers`,
	'My Little Pony',
	'Visit the Beavis and Butt-Head Store',
	'Visit the Parks and Recreation Store',
	'Visit the Atari Store',
	'The Godfather',
	'Visit the Bleach Store',
	'Visit the Corona Store',
	'MTV Music Television',
	'Visit the NPR Store',
	'Visit the Dinosaurs Store',
	'Slam diego padres Shirt',
	'H.E.R.',
	'Woody Woodpecker',
	'Fast & Furious',
	'Nightmare On Elm Street',
	'E T',
	'Big Trouble in Little China',
	'DONKEY KONG',
	'Street Fighter',
	'Baywatch',
	'Visita la Store de David Bowie',
	'Besuchen Sie den Jurassic Park-Store',
	'Pac-Man',
	'Game of Thrones',
	'Visit the Iron Maiden Official Store',
	'Terminator',
	'Rise Against',
	'SpongeBob',
	'MTV',
	'Five Finger Death Punch Official',
	'NASA T-Shirt',
	'Justin Bieber',
	'Garfield',
	'Fender',
	'The Offspring',
	'The Clash Official',
	'ROCKY',
	'Top Gun',
	'Magic: The Gathering',
	'Rancid',
	'Besuchen Sie den Bob Dylan-Store',
	'Papa Roach',
	'Besuchen Sie den Angry Birds-Store',
	'DOCTOR WHO',
	'Lil Nas X',
	'E.T.',
	'Corona',
	'Sea of Thieves',
	'Showtime',
	'2Pac',
	`Child's Play`,
	'The Breakfast Club',
	'Jurassic World: Camp Cretaceous',
	'NERF',
	'Bathory',
	'Official Socials Art Distortion',
	'Halloween',
	'Visit the Halloween Store',
	'The Rocky Horror Picture Show',
	'Death Row Records',
	'Boyz In The Hood',
	'IT Movie',
	'Visit the Mademark Store',
	'THE WHO',
	'Visit the NBC Store',
	'Visit the Roblox Store',
	'Visit the Gorillaz Store',
	'Visit the JAWS Store',
	'Lost Boys',
	'Visit the WWE Store',
	'Visit the Billie Eilish Store',
	'Beavis and Butt-Head',
	'Visit the Candyland Store',
	'Blues Clues',
	'Sesame Street',
	'Aaliyah',
	'Attack on Titan Season 4',
	`Gold's Gym`,
	'The Smurfs',
	'Squid Game',
	'Amazon Games - New World',
	'Halloween Costume Kills Evil Dies',
	'Tonight Horror',
	'Superman',
	'MARADONA',
	'Besuchen Sie den Warner Bros.-Store',
	'Visita lo Store di AC/DC',
	'Visit the Pokemon Store',
	'Ted Lasso',
	'Visit the THE WHO Store',
	'Visit the The Golden Girls Store',
	'EMINEM',
	'Visit the Britney Spears Store',
	'Visit the Doja Cat Store',
	'Visit the Jeff Dunham Store',
	'Visit the Hooters Store',
	'Gremlins',
	'Home Alone',
	'American Horror Story',
	'Visit the Oreo Store',
	'the flintstones',
	'Pikmin',
	'Visit the Blues Clues Store',
	'Elf',
	'Castlevania',
	'Visit the Harry Potter Store',
	'The Batman',
	'Bon Jovi',
	'Visit the Artist Unknown Store',
	'Besuche den Jurassic World-Store',
	'Besuche den Marvel-Store',
	'Sonic il Riccio',
	'The Rolling Stones',
	'DC TV',
	'TV DC',
	'DC Movies',
	'Konami Retro',
	'Superbad',
	'Cercami a Parigi',
	'Dottor chi',
	'Paddington Bear',
	'Visita lo Store di Paw Patrol',
	'Dark Souls',
	'Artist Unknown',
	'Mario Kart',
	`Assassin's Creed`,
	'CAPCOM Official Merchandise',
	'Korn',
	'Sing',
	'Jeff Dunham',
	'Machine Gun Kelly',
	'Hollywood Undead',
	'Lord of the Rings',
	'Seinfeld',
	'Visit the JoJo Siwa Store',
	'Visit the Pinkfong Store',
	'Visit the Schoolhouse Rock Store',
	'Visit the BATTLEBOTS Store',
	'Visit the Joan Jett Store',
	'Visit the ASPCA Store',
	'Visit the Megadeth Official Store',
	'Visit the Garfield Store',
	'Visit the Betty Boop Store',
	'Visit the Attack on Titan Season 4 Store',
	'Visit the Sex Pistols Store',
	'Visit the Daria Store',
	'Visit the Horizon Zero Dawn Store',
	'Visit the Odd Squad Store',
	'Visit the Elvis Presley Store',
	'Visit the Judas Priest Official Store',
	'Visit the Ghost Official Store',
	'Visit the Spice Girls Store',
	'Visit the Slayer Official Store',
	'Visit the Elton John Store',
	'Visit the RUN--DMC Store',
	'The Metal Tour of the Year Official',
	'Modelo',
	'Shania Twain',
	'Invader Zim',
	'Greta Van Fleet',
	`Blue's Clues & You`,
	'The Cuphead Show',
	'Coors',
	'Watain',
	'JACKASS',
	'Rob Zombie Official',
	'Mastodon Official',
	'Sex Education',
	'Anthrax Official',
	'The Thread Shop',
	'DJ Maaho Official',
	'Alice Cooper Official',
	'Woody',
	'Beavis and Butthead',
	'Care Bears Official Merchandise',
	'Besuche den AS Roma-Store',
	'Besuche den Scott-Store',
	'Besuche den Peppa Pig-Store',
	'Besuche den Cypress Hill-Store',
	'Besuche den AC/DC-Store',
	'Besuche den ODDBODS-Store',
	'Besuche den Star Trek-Store',
	'Besuche den My little Pony-Store',
	'Besuche den Jurassic Park-Store',
	`Guns N' Roses`,
	`Bob l'éponge`,
	'Roblox',
	'Iconic Tops',
	'Converge',
	'Visita la Store de Minecraft',
	'They Live',
	'WEEZER',
	'Forrest Gump',
	'Battlestar Galactica',
	'Land Before Time',
	'Bring Me The Horizon',
	'Alkaline Trio',
	'Stone Temple Pilots Official',
	'G.I. Joe',
	'Ghost Official',
	'CLUELESS',
	'Breakfast Club',
	'Animal House',
	'Bullet For My Valentine Official',
	'Monopoly',
	'Alanis Morissette',
	'X Ambassadors',
	'jxdn',
	'Little Mix Official',
	'Architects',
	'The Thundermans',
	'Parkway Drive',
	`rocko's modern life`,
	'Judas Priest Official',
	'Beavis & Butt-Head',
	'The Nightmare Before Christmas',
	'Universal Monsters',
	'Visita la Store de Miraculous',
	'Visita la Store de My Little Pony',
	'Visita la Store de Peppa Pig',
	'Yes',
	'The Loud House',
	'RUN--DMC',
	'Iggy Pop',
	'Ouija',
	'Behemoth',
	'Lamb of God Official',
	'Ben & Holly',
	'Visit the Star Wars: The Mandalorian Store',
	'Visita lo Store di MINECRAFT',
	'Visita lo Store di Bob Dylan',
	'Duke Nukem',
	'Besuche den Jurassic World 2-Store',
	'Manchester City Football Club',
	'Visita lo Store di Pixar',
	'Visiter la boutique Peppa Pig',
	'TMNT',
	'Visiter la boutique My Little Pony',
	'NASA - Official',
	'Visit the Backstreet Boys Official Store',
	'Visit the Machine Gun Kelly Store',
	'Visit the Greta Van Fleet Store',
	'Axel Rudi Pell Official',
	'Besuche den Coca-Cola-Store',
	'Shelby Cobra',
	'Besuche den WWE-Store',
	'Paramount',
	'Megadeth Official',
	'Besuche den Wacken Open Air-Store',
	'ELVIS',
	'Besuche den Star Wars-Store',
	'Besuche den CBGB-Store',
	'Thin Lizzy Official',
	'Besuche den My Little Pony: A New Generation-Store',
	'The Boys',
	'Stretch Armstrong',
	'Tremors',
	'Visita lo Store di Peppa Pig',
	'Kipo And The Age Of Wonderbeasts',
	'SBAM',
	'COBRA',
	'Scott Pilgrim Vs. The World',
	'Back To The Outback',
	'Halloween 2',
	'Official NASA Merchandise',
	'TWISTER',
	'K9 OFFICER K-9 TEAM CANINE POLICE UNITÉ CADEAUX',
	'Ziggy Marley',
	'The Pretty Reckless',
	'Lenny Kravitz Official',
	'Blaze & The Monster Machines',
	'Official American Gods Merchandise',
	'Visiter la boutique Bob Dylan',
	'Blaupunkt',
	'Visit the The Boys Store',
	'Tribe Called Quest',
	'Visit the Rob Zombie Official Store',
	'Visit the Ozzy Osbourne Official Store',
	'Visit the Weezer Store',
	'Visit the Mary J Blige Store',
	'Visit the Mary J. Blige Store',
	'Coheed & Cambria',
	'Terraria',
	'Shinedown',
	'Visit the Care Bears Official Merchandise Store',
	'Visit the Community Store',
	'Visit the Bud Light Store',
	'Officially Licensed Sue Bird - Washington State',
	'5 Seconds Of Summer Official',
	'Operation Ivy',
	'hololive production',
	'Visit the NERF Store',
	'Miller Lite',
	`Luigi's Mansion 3`,
	'Visit the The Black Crowes Store',
	'Visit the Stone Temple Pilots Official Store',
	'Rainbow High',
	'Visit the Fall Out Boy Store',
	'A Perfect Circle Official',
	'Miller',
	'I Prevail',
	'Circle Jerks',
	'Mayhem',
	'The Terminal List',
	'Visit the Death Wish Coffee Co. Store',
	'The Bad Guys',
	'Mr Potato Head',
	'Tim Dillon [OFFICIAL STORE]',
	'Unbekannt',
	'Besuche den Disney-Store',
	'Besuche den Star Wars-Store',
	'Besuche den Bob Dylan-Store',
	'Besuche den Transformers-Store',
	'Visiter la boutique Jurassic Park',
	'Visiter la boutique Jurassic Franchise',
	'Visiter la boutique Dungeons & Dragons',
	'Visiter la boutique Pixar',
	'Visiter la boutique The Simpsons',
	'Visit the The Boys Store',
	'Visit the Rob Zombie Official Store',
	'Visit the Mr. Men Little Miss Store',
	'Visit the Andrew Lloyd Webber Store',
	'Visit the ELVIS Store',
	'Visit the Weezer Store',
	'Visit the BreakingT Store',
	'Visit the Ozzy Osbourne Official Store',
	'Visit the Imagine Dragons Store',
	'Visit the Mary J Blige Store',
	'Visit the Panic At The Disco Store',
	'Visit the My Little Pony Store',
	'Visit the Community Store',
	'Visit the UFC Store',
	'Visit the NERF Store',
	'Visit the Bud Light Store',
	'The Pretty Reckless',
	'Dazed and Confused',
	'Blaze and the Monster Machines',
	'Back To The Outback',
	'The Killers',
	'The Boys',
	'Loteria',
	'Stone Sour Official',
	'Coheed & Cambria',
	'SBAM',
	'Beverly Hills 90210',
	'Emily the Strange',
	'Billy Idol',
	'I Prevail',
	'Manga',
	'Architects UK',
	'Operation Ivy',
	'A Perfect Circle Official',
	'Shinedown',
	'Thin Lizzy Official',
	'Kipo And The Age Of Wonderbeasts',
	'House of the Dragon',
	'Mr Potato Head',
	'Transformers',
	'hololive production',
	'Miller',
	'Visita lo Store di Transformers',
	'Visita lo Store di Peppa Pig',
	'Visit the Elite Authentics Store',
	'LUMOMIX',
	'Visita lo Store di Marvel',
	'Besuche den MINIONS-Store',
	'Visita lo Store di Marvel',
	'Visita lo Store di Jurassic Park',
	'Besuche den Cobra Kai-Store',
	'Besuche den Backstreet Boys Official-Store',
	'Visita lo Store di The Simpsons',
	'Visit the DC Comics Store',
	'Besuche den Naruto Shippuden-Store',
	'Visit the SpongeBob SquarePants Store',
	'Visiter la boutique Hasbro',
	'Visiter la boutique Barbie',
	'Tribe',
	'Avatar',
	'Visita lo Store di Jurassic Franchise',
	`THE HANDMAID'S TALE`,
	'Visit the Boy Scouts of America Store',
	'Visita lo Store di Marvel',
	'Visit the Lizzo Store',
	'Visita lo Store di Jurassic World: Camp Cretaceous',
	'Panic At The Disco',
	'Hasbro',
	'NEW LINE CINEMA',
	'Visita lo Store di The Simpsons',
	'Official Soccer National Tees',
	'Visit the Stranger Things Store',
	'Visit the SpongeBob SquarePants Store',
	'Flashdance',
	'Visit the BLACKPINK Store',
	'Marilyn Manson Official',
	'Besuche den Naruto Shippuden-Store',
	'Texas Chainsaw Massacre Official',
	'Visit the Nightmare On Elm Street Store',
	'Wednesday',
	'Elite Authentics',
	'Y Yellowstone',
	'The Warriors',
	'Visiter la boutique Jurassic World 2',
	'The Legend of Korra',
	'Visit the Elite Authentics Store',
	'DreamWorks Puss In Boots',
	'Blade Runner',
	'Officially Licensed Nasa Shirts and Gifts',
	'Officially Licensed NASA Merchandise',
	'Jurassic World',
	'Curious George',
	'Rudolph the Red Nosed Reindeer',
	'Visiter la boutique Transformers',
	'LAPD',
	'Visita la Store de Jurassic Franchise',
	'Fear Street',
	'Transformers',
	'Visit the Scooby-Doo Store',
	'Besuche den My little Pony-Store',
	'Besuche den Death Row Records-Store',
	'Besuche den Peppa Pig-Store',
	'Mossy Oak',
	'Visit the LOL Surprise Store',
	'Foreigner',
	'Robocop',
	'Angry Beavers',
	'Visita lo Store di Fender',
	'Voltron Legendary Defenders',
	'Besuche den NERF-Store',
	'Grease',
	'Animal Crossing',
	'Visit the Ford Store',
	'Jurassic Park',
	'Visit the The Bad Guys Store',
	'Cowboy Bebop',
	'FOOTLOOSE',
	'Visit the AVATAR Store',
	'Visit the Uzumaki Store',
	'Pinky and the Brain',
	'Visit the Ghost of Tsushima Store',
	'Zoolander',
	'The Goonies',
	'Sally Face',
	'House of the Dragon',
	'Candyland',
	'Ren and Stimpy',
	'E T',
	'Mean Girls',
	'Mortal Kombat',
	'Splatoon',
	`Visit the Reese's Store`,
	'Dune',
	'The Big Bang Theory',
	'Jay and Silent Bob',
	'Visit the Maruchan Store',
	'Manic Merch LLC',
	'Visit the The Princess Bride Store',
	'Back To The Future',
	'Krampus',
	'Hey Arnold!',
	'Avatar: The Last Airbender',
	'Black Adam',
	'Minions',
	'Captain Planet',
	'Silence Of The Lambs',
	'Besuche den Scott Edward-Store',
	'Over the Garden Wall',
	'Disney',
	'Wednesday',
	'Elite Authentics',
	'Y Yellowstone',
	'The Warriors',
	'Visiter la boutique Jurassic World 2',
	'The Legend of Korra',
	'Visit the Elite Authentics Store',
	'DreamWorks Puss In Boots',
	'Blade Runner',
	'Officially Licensed Nasa Shirts and Gifts',
	'Officially Licensed NASA Merchandise',
	'Jurassic World',
	'Curious George',
	'Rudolph the Red Nosed Reindeer',
	'Visiter la boutique Transformers',
	'LAPD',
	'Visita la Store de Jurassic Franchise',
	'Fear Street',
	'Transformers',
	'Visit the Scooby-Doo Store',
	'Besuche den My little Pony-Store',
	'Besuche den Death Row Records-Store',
	'Besuche den Peppa Pig-Store',
	'Mossy Oak',
	'Visit the LOL Surprise Store',
	'Foreigner',
	'Robocop',
	'Angry Beavers',
	'Visita lo Store di Fender',
	'Voltron Legendary Defenders',
	'Besuche den NERF-Store',
	'Grease',
	'Animal Crossing',
	'Visit the Ford Store',
	'Jurassic Park',
	'Visit the The Bad Guys Store',
	'Cowboy Bebop',
	'FOOTLOOSE',
	'Visit the AVATAR Store',
	'Visit the Uzumaki Store',
	'Pinky and the Brain',
	'Visit the Ghost of Tsushima Store',
	'Zoolander',
	'The Goonies',
	'Sally Face',
	'House of the Dragon',
	'Candyland',
	'Ren and Stimpy',
	'E T',
	'Mean Girls',
	'Mortal Kombat',
	'Splatoon',
	`Visit the Reese's Store`,
	'Dune',
	'The Big Bang Theory',
	'Jay and Silent Bob',
	'Visit the Maruchan Store',
	'Manic Merch LLC',
	'Visit the The Princess Bride Store',
	'Back To The Future',
	'Krampus',
	'Hey Arnold!',
	'Avatar: The Last Airbender',
	'Black Adam',
	'Minions',
	'Captain Planet',
	'Silence Of The Lambs',
	'Besuche den Scott Edward-Store',
	'Over the Garden Wall',
	'Disney',
	'AC/DC',
	'Wednesday Series',
	'Visit the Wednesday 13 Store',
	'Visit the L.O.L. Surprise! Store',
	'Magic the Gathering',
	'NASA Officially Licensed Nasa Shirts and Gifts',
	'Officially Licensed NASA tee and gifts',
	'Jurassic World',
	'Shrek',
	'Ozuna',
	'Visiter la boutique dc comics',
	'Transformers',
	`Netflix's The Watcher`,
	'Emily in Paris',
	'Pound Puppies',
	'Visit the Wednesday Store',
	'Visit the Aaliyah Store',
	'Visit the Brooks Store',
	'Visit the The Last of Us Store',
	'Visit the PINK Store',
	'Visit the ACCGUYS Store',
	'Visit the Offspring Store',
	'Visit the Jack Harlow Store',
	'Visit the Creed Store',
	'Strawberry Shortcake',
	'Overwatch',
	'Indiana Jones',
	'Ariana Grande',
	'Daisy Jones & the Six',
	'Misfits Official',
	'FIT2SERVE Gear - Positivity Through ',
	'JEFF DUNHAM: Seriously!? TourLaughter',
	'ACCGUYS',
	'Besuche den Dungeons & Dragons-',
	'Store',
	'Besuche den Miraculous-Store',
	'Besuche den The Clash Official-Store',
	'Besuche den Ghostbusters-Store',
	'Besuche den hummel-Store',
	'Olde English 800',
	'Pixar',
	'The Crow Official',
	'Status Quo Official',
	'Lucha Brothers',
	'Official James Bond 007',
	'Visiter la boutique Nerf',
	'Bridgerton',
	'Visit the Barbie Store',
	'Besuche den Barbie-Store',
	'Jeep',
	'Visit the Ted Lasso Store',
	'Surfvivor Media Inc.',
	'Visit the GÜLIFE Store',
	'Bratz',
	'Visit the Legend of Zelda Store',
	'Visit the ZERONIC Store',
	'Visit the Blockbuster Store',
	'Visit the The Simpsons Store',
	'The Flash',
	'Visit the Kirby Store',
	'Visit the CoComelon Store',
	'Purple Rain',
	`Ferris Bueller's Day Off`,
	'Cheech & Chong',
	'Besuche den David Bowie-Store',
	'Besuche den DC Comics-Store',
	'BON SCOTT',
	'Visita la tienda de Marvel',
	'Visita la tienda de Disney',
	'Visita la tienda de AC/DC',
	'Arex & Vastatore',
	'Pink (Cut Merch)',
	'TUPAC SHAKUR',
	'The Summer I Turned Pretty',
	'Visita la tienda de Star Wars',
	'Visit the Znines Store',
	'Godzilla x Kong',
	'Visita lo Store di Fortnite',
	'Visit the Dunes Store',
	'The Legend of Zelda',
	'Visita la tienda de Dungeons & Dragons',
	'Htlaew',
	'Parks and Recreation',
	'Back To The Future',
	'Charm Foot',
	'Visit the ThunderCats Store',
	'Mr. Potato Head',
	'Visit the Y Yellowstone Store',
	'The Super Mario Bros. Movie',
	'Visit the E.T. Store',
	'Warcraft',
	`Visit the Child's Play Store`,
	'Visit the PJ Masks Store',
	'The Lord of the Rings',
	'Visit the The Breakfast Club Store',
	'Besuche den Dungeons & Dragons-Store',
	'Furby',
	'Visit the Candy Land Store',
	'Visit the The Big Lebowski Store',
	'Visit the Naruto Shippuden Store',
	'Ministry',
	'Visit the Cheech & Chong Store',
	'Twilight Zone',
	'The Hobbit',
	'Rochester Institute of Technology -- Steve State',
	'Visit the God of War Store',
	'PBR Professional Bull Riders',
	'Visit the Arthur Store',
	'Sixteen Candles',
	'Visita lo Store di Magic The Gathering',
	'Jimmy Neutron',
	'Visit the Dora the Explorer Store',
	'Dora the Explorer',
	'Visit the Clerks Store',
	`National Lampoon's Christmas Vacation`,
	'Visit the USPS Store',
	'Spin Master',
	'Blue Beetle',
	'Visit the Campus Lab Store',
	'Visit the Mossy Oak Store',
	`Where's Waldo`,
	'Visit the Znines Store',
	'Frosty the Snowman',
	'Besuche den Dune-Store',
	'Visita la tienda de Magic The Gathering',
	'Paul Frank',
	'The Matrix',
	'Visit the super.natural Store',
	'Candy Land',
	'Visit the Universal Monsters Store',
	'University of Southern California',
	'Caddyshack',
	'Visit the The Righteous Gemstones Store',
	`Visit the Blue's Clues & You Store`,
	'A Nightmare On Elm Street',
	'Twizzlers',
	`Visit the HERSHEY'S Store`,
	'Visit the Outlander Store',
	'Visit the Universal Monsters Store',
	'Ghostbuster',
	`Visit the Gabby's Dollhouse Store`,
	'Visit the XHoob Store',
	'Visit the LAPD Store',
	'The Wizard Of Oz',
	'Reservation Dogs',
	'Visit the Woody Woodpecker Store',
	'Visit the Bloodborne Store',
	'Jolly Rancher',
	'Icon Sports',
	'Corona Extra',
	'Hunter x Hunter',
	'Call of Duty',
	'The Year Without A Santa Claus',
	'Friday The 13th',
	'JEFF DUNHAM: Seriously!? Tour',
	'A Christmas Story',
	'The Rolling Stones',
	'Visit the Avril Lavigne Store',
	'Visit the Carego Store',
	'Visit the FORTNITE Store',
	'Anheuser-Busch',
	`Visit the Guns N' Roses Store`,
	'Selena Quintanilla',
	'Visit the Scarface Store',
	'Visit the Dune London Store',
	'Peppa Pig',
	'Kirby',
	'Visit the Hot Wheels Store',
	'Scoban',
	'Visit the Scoban Store',
	'Beyeah',
	'New York Knicks',
	'Visit the Scott Pilgrim Vs. The World Store',
	'Aqua Teen Hunger Force',
	'Honda',
	'Visit the Attack on Titan Season 3 Store',
	'Visit the Heywood Store',
	'Visit the G.I. Joe Store',
	'LyricVerse',
	'Visit the True Blood Store',
	'MUHAMMAD ALI',
	'The Conjuring',
	'Visit the DreamWorks Puss In Boots Store',
	'Visit the Ripple Junction Store',
	'Violent Femmes',
	'Back To The Future',
	'Visit the The Three Stooges Store',
	'Visit the Hasbro Store',
	'CATDOG',
	'Ford Mustang',
	'Miami Vice',
	'Visit the Cinnamon Toast Crunch Store',
	'Bruce Springsteen',
	'Crazy Ex-Girlfriend',
	'LEGALLY BLONDE',
	'Visit the Care Bears Store',
	'Visit the Icee Store',
	'Official FFA Merchandise Ohio',
	'Visit the Jay & Silent Bob Store',
	'LyricLyfe',
	'Betty Boop',
	'AZZUTORK',
	'Supernatural',
	'Fantastic Beasts',
	'G2 Entertainment',
	'Visit the Coming to America Store',
	'Polar Express',
	'Johns Hopkins University',
	'Operation',
	'Nimona',
	'Visit the Booba Store',
	'Clue',
	'Year Without a Santa Claus',
	'Visit the Grease Store',
	'Visit the Campus Lab Store',
	'Gossip Girl',
	'Danny Phantom',
	'Visit the Jay and Silent Bob Store',
	'Adventure Time',
	'Visit the Silicon Valley Store',
	'Gilmore Girls',
	'Adventure Time',
	'Visit the Jurassic World: Fallen Kingdom Store',
	'Visit the Ripple Junction Store',
	'Visit the Fast & Furious Store',
	'Visit the Chucky Store',
	'Official High School Fan Gear',
	'The Powerpuff Girls',
	'DC League of Super-Pets',
	'Visit the Animal House Store',
	'Bioworld',
	'Arthur',
	'Visit the Campus Lab Store',
	'Rainbow Brite',
	'Visit the Twin Peaks Store',
	'Opal Sky Studio - SCP Foundation',
	'Visiter la boutique Fortnite',
	'TEENAGE MUTANT NINJA',
	'Visita la tienda de Transformers',
	'Stephen Wright Photography',
	'Mad Max',
	'Visita la tienda de Minecraft',
	'Liakai',
	'Visita lo Store di Animal House',
	'space jam',
	'PIXAR',
	'Super Mario',
	'Visit the DreamWorks The Wild Robot Store',
	'Visit the Jelly Roll Store',
	'I Love NY',
	'Visit the The Rolling Stones Store',
	'Visit the Sonic The Hedgehog Store',
	'Visit the PIXAR Store',
	'USC',
	'unifun',
	'Visit the Twisters Store',
	'Pink',
	'Visit the Morgan Wallen Store',
	'Visit the Indiana Fever Store',
	'Visit the STAR WARS Store',
	'The Gilmore Girls',
	'Social Distortion',
	'Visit the POST MALONE Store',
	'Visit the Lainey Wilson Store',
	'FORTNITE',
	'Visit the Icon Sports Store',
	'Visit the Rugrats Store',
	'Visit the Mr. Potato Head Store',
	'NSYNC',
	'20th Century Fox',
	'Visit the Caseeto Store',
	'Aliens',
	'Falling In Reverse',
	'Visit the UNO Store',
	'United States Navy',
	'Visit the Slayer Store',
	'Visit the ATEEZ Store',
	'Visit the KIDZ BOP Store',
	'Visit the A Tribe Called Quest Store',
	'DAILYLIVE',
	'Benson Boone',
	'The Sopranos',
	'Visit the wicked Store',
	'Talking Heads',
	'MTV MUSIC TELEVISION',
	'Donkey Kong',
	'A NIGHTMARE ON ELM STREET',
	'Visit the Avatar: The Last Airbender Store',
	'Visit the 311 Store',
	'Ghostbusters: Afterlife',
	'Visit the American Girl Store',
	'Visit the Twisters Store',
	'Looney Tunes',
	'Visit the The Who Store',
	'Chuck E. Cheese',
	'Explore Academy Official Gear',
	'Visit the The Godfather Store',
	'Visit the HARDY Store',
	'AMAZIGREEK',
	'Volkswagen',
	'Visit the Star Trek Store',
	'Sleeping With Sirens',
	'Beastie Boys',
	'Visit the South Park Store',
	'Billy F Gibbons',
	'Star Trek',
	'Buffy the Vampire Slayer',
	'Visit the Mean Girls Store',
	'Visit the Ice Nine Kills Official Store',
	'The Lord Of The Rings',
	'Sleeping With Sirens',
	'Patriot Issue',
	'Leegosun',
	'Visit the The Flintstones Store',
	'Visit the BrewerShirts Store',
	'Counting Crows',
	'My Chemical Romance',
	'AMAZIGREEK',
	'Jane’s Addiction Official',
	'Dallas Mavericks',
	'ONE Championship',
	'Gucci',
	'AP7 OFFICIAL',
	'Visit the A Tribe Called Quest Store',
	'Visit the FALL OUT BOY Store',
	'Visit the American Girl Store',
	'Modeo',
	'Visit the Howard University Store',
	'Monty Python',
	'ULTRAMAN',
	'University of California-Santa Cruz',
	'The Hu Official',
	'YES',
	'GoNoodle',
	'Visit the Trivium Store',
	'SHORESY',
	'Talking Heads',
	'OFFICIAL - Bleach Blonde Bad Built Butch Body',
	'Zelda',
	'Naughty By Nature Official',
	'A Boogie Wit da Hoodie',
	'Visit the CREED Store',
	'Minnesota Timberwolves',
	'Ice Nine Kills Official',
	'Wheeler WAlker Jr. Official',
	'Simon Fowler Photography',
	'Visit the Jennifer Lopez Store',
	'Monsterverse',
	'OFFICIAL - DONALD TRUMP ERAS',
	'Visit the Lil Nas X Store',
	'iDKHOW Official',
	'Legendary',
	'Visit the DreamWorks Kung Fu Panda Store',
	'Visit the Ariana Grande Store',
	'Goo Goo Dolls',
	'Visit the Desert Cactus Store',
	'Lone Star',
	'Visit the Bioworld Store',
	'Doctor Who',
	'Visit the Astro Bot Rescue Mission Store',
	'Dukes',
	'Ghost',
	'Visit the Diary of a Wimpy Kid Store',
	'Visit the License Champs Store',
	'BLholife',
	'Visit the FOXCUP Store',
	'Campus Lab',
	'The Flintstones',
	'Visit the The Smurfs Store',
	'Boo Berry',
	'Los Angeles Sparks',
	'Dallas',
	'Charms Blow Pop',
	'House Of The Dragon',
	'Shazam!',
	'Visit the Unicorn Academy Store',
	'STAR WARS',
	'Visit the Heinz Kraft Store',
	'Stroh\'s',
	'Visit the Cartoon Network Store',
	'Schoolhouse Rock!',
	'Curb Your Enthusiasm',
	'Heinz Kraft',
	'Pet Sematary',
	'Suicide Silence',
	'Popeye and Olive Oyl Official Merchandise',
	'Visit the FAST AND FURIOUS Store',
	'VIolent Femmes',
	'World of Warcraft',
	'Visit the Barbie The Movie Store',
	'Three’s Company Officially Licensed Product',
	'Visit the Polly Pocket Store',
	'Richard Simmons',
	'Barney',
	'Visit the Venley Store',
	'Visit the THOMAS & FRIENDS Store',
	'Las Vegas Aces',
	'Visit the Iron Maiden Store',
	'Dogman and Cat For Essential Kids Tees',
	'FOXCUP',
	'Visit the SANRIO Store',
	'COTTON SOUL',
	'Bandai Namco Entertainment Inc.',
	'Visiter la boutique AC/DC',
	'Teen Titans',
	'SOEPCNEI',
	'Fallout',
	'Die Wilden Kerle Logo',
	'Visita lo Store di Guns N\' Roses',
	'MM93 Racing',
	'Besuche den Sesame Street-Store',
	'Iron Maiden',
	'dc comics',
	'SPONGEBOB SQUAREPANTS',
	'Godzilla Minus One',
	'Deadpool',
	'MINECRAFT',
	'Oasis',
	'Visita la tienda de Harry Potter',
	'Twilight',
	'Visita la tienda de Stranger Things'
	// cspell:enable
];
