/**------------------------------------------------------
 * Digital Definitions
 */
export enum EnumDigital {
	Bit 		= 'bit_b',
	Kilobit 	= 'bit_kb',
	Megabit 	= 'bit_mb',
	Gigabit 	= 'bit_gb',
	Terabit 	= 'bit_tb',
	Byte 		= 'bytes_b',
	Kilobyte 	= 'bytes_kb',
	Megabyte 	= 'bytes_mb',
	Gigabyte 	= 'bytes_gb',
	Terabyte 	= 'bytes_tb'
}
