/**------------------------------------------------------
 * Interface Definitions
 */
export interface IPatienceDiff {
	lines			  : Array<{
		line	: string;
		aIndex	: number;
		bIndex	: number;
	}>;
	lineCountDeleted  : number;
	lineCountInserted : number;
	lineCountMoved	  : number;
	aMove			  : string[];
	aMoveIndex		  : number[];
	bMove			  : string[];
	bMoveIndex		  : number[];
}

export interface IPatienceDiffMoved {
	lines			  : Array<{
		line	: string;
		aIndex	: number;
		bIndex	: number;
		moved   : boolean;
	}>;
	lineCountDeleted  : number;
	lineCountInserted : number;
	lineCountMoved	  : number;
	aMove			  : string[];
	aMoveIndex		  : number[];
	bMove			  : string[];
	bMoveIndex		  : number[];
}
