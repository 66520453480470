import { EnumNgToastPlacement, INgMessageToastInput } from './message-toast.interface';
import { EnumNgMessageToastType } from './message-toast/message-toast.interface';
import { EnumNgFaStyle } from '../../../styling-components';


/**------------------------------------------------------
 * Base Configuration
 */
const NG_BASE_TOAST_CONFIG: Readonly<INgMessageToastInput> = {
	title		: '',
	message		: '',
	type		: EnumNgMessageToastType.ToastDefault,
	progress	: 0,
	icon		: {
		style	: EnumNgFaStyle.Solid,
		name	: 'circle-check'
	},
	placement	: EnumNgToastPlacement.TopRight,
	actions		: []
};


/**------------------------------------------------------
 * Configurations
 */
export const NG_DEFAULT_TOAST_CONFIG: Readonly<INgMessageToastInput> = {
	...NG_BASE_TOAST_CONFIG,
	type	: EnumNgMessageToastType.ToastDefault,
	icon	: {
		style	: EnumNgFaStyle.Solid,
		name	: 'circle-info'
	}
};

export const NG_SUCCESS_TOAST_CONFIG: Readonly<INgMessageToastInput> = {
	...NG_BASE_TOAST_CONFIG,
	type	: EnumNgMessageToastType.ToastSuccess,
	icon	: {
		style	: EnumNgFaStyle.Solid,
		name	: 'circle-check'
	}
};

export const NG_WARNING_TOAST_CONFIG: Readonly<INgMessageToastInput> = {
	...NG_BASE_TOAST_CONFIG,
	type	: EnumNgMessageToastType.ToastWarning,
	icon	: {
		style	: EnumNgFaStyle.Solid,
		name	: 'triangle-exclamation'
	}
};

export const NG_ERROR_TOAST_CONFIG: Readonly<INgMessageToastInput> = {
	...NG_BASE_TOAST_CONFIG,
	type	: EnumNgMessageToastType.ToastError,
	icon	: {
		style	: EnumNgFaStyle.Solid,
		name	: 'circle-xmark'
	}
};
