/* eslint-disable @typescript-eslint/typedef */


/**------------------------------------------------------
 * Time Constants
 */
export const TIME_BASE = {
	MILLISECOND		: 1, 					// 1
	SECOND			: 1000, 				// 1000 * 1
	MINUTE			: 60000, 				// 1000 * 60
	HOUR			: 3600000, 				// 1000 * 60 * 60
	DAY				: 86400000, 			// 1000 * 60 * 60 * 24
	WEEK			: 604800000, 			// 1000 * 60 * 60 * 24 * 7
	MONTH			: 2592000000, 			// 1000 * 60 * 60 * 24 * 30
	YEAR			: 31536000000			// 1000 * 60 * 60 * 24 * 365
};

export const TIME_DURATION = {

	// seconds
	PERIOD_1_SEC	: TIME_BASE.SECOND,
	PERIOD_2_SEC	: TIME_BASE.SECOND * 2,
	PERIOD_5_SEC	: TIME_BASE.SECOND * 5,
	PERIOD_10_SEC	: TIME_BASE.SECOND * 10,
	PERIOD_15_SEC	: TIME_BASE.SECOND * 15,
	PERIOD_30_SEC	: TIME_BASE.SECOND * 30,

	// minutes
	PERIOD_1_MIN	: TIME_BASE.MINUTE,
	PERIOD_2_MIN	: TIME_BASE.MINUTE * 2,
	PERIOD_5_MIN	: TIME_BASE.MINUTE * 5,
	PERIOD_10_MIN	: TIME_BASE.MINUTE * 10,
	PERIOD_15_MIN	: TIME_BASE.MINUTE * 15,
	PERIOD_30_MIN	: TIME_BASE.MINUTE * 30,

	// hours
	PERIOD_1_HOUR	: TIME_BASE.HOUR,

	// days
	PERIOD_1_DAY	: TIME_BASE.DAY,
	PERIOD_2_DAY	: TIME_BASE.DAY * 2,
	PERIOD_3_DAY	: TIME_BASE.DAY * 3,
	PERIOD_5_DAY	: TIME_BASE.DAY * 5,

	// weeks
	PERIOD_1_WEEK	: TIME_BASE.DAY * 7,
	PERIOD_2_WEEK	: TIME_BASE.DAY * 7 * 2,
	PERIOD_3_WEEK	: TIME_BASE.DAY * 7 * 3,
	PERIOD_4_WEEK	: TIME_BASE.DAY * 7 * 4,

	// month
	PERIOD_1_MONTH	: TIME_BASE.MONTH,
	PERIOD_3_MONTH	: TIME_BASE.MONTH * 3,
	PERIOD_6_MONTH	: TIME_BASE.MONTH * 6,

	// years
	PERIOD_1_YEAR	: TIME_BASE.YEAR,
	PERIOD_2_YEAR	: TIME_BASE.YEAR * 2,
	PERIOD_5_YEAR	: TIME_BASE.YEAR * 5,
	PERIOD_10_YEAR	: TIME_BASE.YEAR * 10,
	PERIOD_25_YEAR	: TIME_BASE.YEAR * 25,
	PERIOD_50_YEAR	: TIME_BASE.YEAR * 50,
	PERIOD_100_YEAR	: TIME_BASE.YEAR * 100,
	PERIOD_1000_YEAR: TIME_BASE.YEAR * 1000,

	MAX_TIME		: 8640000000000000 		// largest possible time value for a Date object
};


/**------------------------------------------------------
 * Time Factors
 */
export const TIME_FACTORS = {
	MILLISECONDS_IN_A_SECOND 	: 1000,
	HOURS_IN_A_DAY 				: 24,
	MINUTES_IN_AN_HOUR 			: 60,
	SECONDS_IN_A_MINUTE 		: 60
};
