import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';
import { EnumKeywordDataset, EnumKwTableOptions } from '../../research/app-constants';


/**------------------------------------------------------
 * Limitations of the Keywords
 */
export const KEYWORDS_LIMITATIONS		 : IKeywordLimitations[] = [

	// ---------------------- free version
	{
		modules			: [EnumSubscriptionModule.FreeVersion],
		excludeKeywords : {
			kwSearch 	 	: {
				searchCooldown		: true,
				wordFrequency		: false,
				advancedOptions		: false,
				interestOverTime	: false,
				kwBoard				: false,
				badges				: false,
				excludedTableOptions: [
					EnumKwTableOptions.AddToKWBoard, EnumKwTableOptions.AddToAdvertisingList, EnumKwTableOptions.OpenInKWWorkbench,
					EnumKwTableOptions.CopyCompetitionAsin, EnumKwTableOptions.CopySponsoredAsin, EnumKwTableOptions.CopyBrands,
					EnumKwTableOptions.ExportSelectedAsExcel, EnumKwTableOptions.ExportAllColumnsAsExcel
				],
				tableLimitations	: {
					rankPosition	: false,
					filter			: false,
					sort			: false,
					disableColumnsAfter	: 1,
					disableKeywordsAfter: 5,
					disableColumns	: []
				}
			},
			tagGenerator	: {
				listView 	: {
					openInWorkbench	: false,
					exportAsExcel	: false
				},
				kwBoard		: false,
				textView	: false
			}
		}
	},

	// ---------------------- keyword basic module
	{
		modules			: [EnumSubscriptionModule.KeywordBasic],
		excludeKeywords : {
			kwSearch 	 	: {
				searchCooldown		: false,
				wordFrequency		: true,
				advancedOptions		: true,
				interestOverTime	: true,
				badges				: true,
				kwBoard				: true,
				excludedTableOptions: [EnumKwTableOptions.AddToAdvertisingList],
				tableLimitations	: {
					rankPosition	: false,
					filter			: true,
					sort			: true,
					disableColumnsAfter	: -1,
					disableKeywordsAfter: -1,
					disableColumns	: []
				}
			},
			tagGenerator	: {
				listView 	: {
					openInWorkbench	: true,
					exportAsExcel	: true
				},
				kwBoard		: true,
				textView	: true
			}
		}
	},

	// ---------------------- keyword pro module
	{
		modules			: [EnumSubscriptionModule.KeywordPro],
		excludeKeywords : {
			kwSearch 	 	: {
				searchCooldown		: false,
				wordFrequency		: true,
				advancedOptions		: true,
				interestOverTime	: true,
				badges				: true,
				kwBoard				: true,
				excludedTableOptions: [],
				tableLimitations	: {
					rankPosition	: true,
					filter			: true,
					sort			: true,
					disableColumnsAfter	: -1,
					disableKeywordsAfter: -1,
					disableColumns	: []
				}
			},
			tagGenerator	: {
				listView 	: {
					openInWorkbench	: true,
					exportAsExcel	: true
				},
				kwBoard		: true,
				textView	: true
			}
		}
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IKeywordLimitations {
	modules					: EnumSubscriptionModule[];
	excludeKeywords 		: IKeywordPagesLimitations;
}

export interface IKeywordPagesLimitations {
	kwSearch 	 	: {
		searchCooldown		: boolean;
		wordFrequency		: boolean;
		advancedOptions		: boolean;
		interestOverTime	: boolean;
		kwBoard				: boolean;
		badges				: boolean;
		excludedTableOptions: EnumKwTableOptions[];
		tableLimitations	: {
			rankPosition	: boolean;
			filter			: boolean;
			sort			: boolean;
			disableColumnsAfter	: number;
			disableKeywordsAfter: number;
			disableColumns	: EnumKeywordDataset[];
		};
	};
	tagGenerator	: {
		listView 	: {
			openInWorkbench	: boolean;
			exportAsExcel	: boolean;
		};
		kwBoard		: boolean;
		textView	: boolean;
	};
}
