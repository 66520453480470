import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';
import { EnumPlatform } from '../../upload/app-constants/platforms/print-on-demand.constants';


/**------------------------------------------------------
 * Free Upload Limits
 */
export const FREE_UPLOAD_LIMIT_MONTH: number = 150;


/**------------------------------------------------------
 * Feature Names
 */
export enum EnumUploadFeatures {
	ScheduleTime 		= 'upload_schedule_time',
	ParallelUpload 		= 'upload_parallel'
}


/**------------------------------------------------------
 * Upload Limitations (Webapp & Magic Uploader)
 */
export const UPLOAD_LIMITATIONS: IUploadLimitations[] = [

	// -------------------------------------- Free Upload
	{
		module			: EnumSubscriptionModule.FreeVersion,
		unlimitedUploads: [],
		enabledFeatures	: []
	},

	// -------------------------------------- Upload POD
	{
		module			: EnumSubscriptionModule.UploadPodSpreadshirt,
		unlimitedUploads: [EnumPlatform.SpreadshirtEU, EnumPlatform.SpreadshirtUS],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodRedbubble,
		unlimitedUploads: [EnumPlatform.Redbubble],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodMba,
		unlimitedUploads: [EnumPlatform.MBA],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadKdp,
		unlimitedUploads: [EnumPlatform.KDP],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodDisplate,
		unlimitedUploads: [EnumPlatform.Displate],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodFineArtAmerica,
		unlimitedUploads: [EnumPlatform.FineArtAmerica],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodPrintful,
		unlimitedUploads: [EnumPlatform.Printful],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodPrintify,
		unlimitedUploads: [EnumPlatform.Printify],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodSociety6,
		unlimitedUploads: [EnumPlatform.Society6],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodTeepublic,
		unlimitedUploads: [EnumPlatform.Teepublic],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodTeespring,
		unlimitedUploads: [EnumPlatform.Teespring],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodTostadora,
		unlimitedUploads: [EnumPlatform.Tostadora],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodZazzle,
		unlimitedUploads: [EnumPlatform.Zazzle],
		enabledFeatures	: []
	},
	{
		module			: EnumSubscriptionModule.UploadPodAdvanced,
		unlimitedUploads: [EnumPlatform.MBA, EnumPlatform.Redbubble, EnumPlatform.SpreadshirtEU, EnumPlatform.SpreadshirtUS],
		enabledFeatures	: [EnumUploadFeatures.ParallelUpload]
	},
	{
		module			: EnumSubscriptionModule.UploadPodAdvancedShop,
		unlimitedUploads: [EnumPlatform.MBA, EnumPlatform.Printful, EnumPlatform.Printify],
		enabledFeatures	: [EnumUploadFeatures.ParallelUpload]
	},
	{
		module			: EnumSubscriptionModule.UploadPodPro,
		unlimitedUploads: [
			EnumPlatform.Redbubble, EnumPlatform.SpreadshirtUS, EnumPlatform.SpreadshirtEU,  EnumPlatform.Teepublic, EnumPlatform.Teespring,
			EnumPlatform.Tostadora, EnumPlatform.Zazzle, 	    EnumPlatform.FineArtAmerica, EnumPlatform.Printful,  EnumPlatform.Printify,
			EnumPlatform.Shirtee,   EnumPlatform.Society6, 		EnumPlatform.MBA,  			 EnumPlatform.Displate
		],
		enabledFeatures	: [EnumUploadFeatures.ParallelUpload, EnumUploadFeatures.ScheduleTime]
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IUploadLimitations {
	module				: EnumSubscriptionModule;
	unlimitedUploads	: EnumPlatform[];				// platforms where the user can upload the designs
	enabledFeatures		: EnumUploadFeatures[];
}
