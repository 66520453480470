import { IKeyCode } from '@libs/libraries/frontend';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgConformAction {
	Continue		= 'action_continue',
	Close			= 'action_close'
}


/**---------------------------------------------
 * Modal Content
 */
export interface INgConformModalContent {
	title		: string;
	message		: string;
}

export interface INgConformModalButton {
	buttonText	: string;
	actionCode	: EnumNgConformAction;
	color 	 	: string;
	actionKey	: IKeyCode[];
}
