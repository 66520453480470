/* cspell:ignore fxios, crios */
import { EnumDigital, EnumBrowser, BROWSER_INFO } from '@libs/constants';
import { Util } from '@libs/utilities/util';

import { INetworkSpeed, ILocationInfo } from './browser-detection.interface';


/**------------------------------------------------------
 * Browser Detection
 */
export class BrowserDetectionService {

	//** Configurations */
	private readonly TRIDENT_CHECK_REGEX: RegExp = /trident/;
	private readonly FIREFOX_CHECK_REGEX: RegExp = /firefox|fxios/;
	private readonly OPERA_CHECK_REGEX	: RegExp = /opr/;
	private readonly EDGE_CHECK_REGEX	: RegExp = /edg/;
	private readonly SAFARI_CHECK_REGEX	: RegExp = /(mac|ipad|iphone)(?=.*safari)(?!.*(edg|opr|fxios))/;
	private readonly CHROME_CHECK_REGEX	: RegExp = /(chrome|crios)(?!.*(edg|opr|firefox))/;

	//** Enum Getters */
	readonly EnumBrowser: typeof EnumBrowser = EnumBrowser;


	/**------------------------------------------------------
	 * Browser Info
	 */
	getBrowser(userAgentParam: string = navigator.userAgent): EnumBrowser {

		//0 - extract the user agent
		const userAgent: string = userAgentParam.toLowerCase();

		//1 - detect the browser
		switch (true) {
			case Util.Basic.isDefined((navigator as any)?.brave?.isBrave):
				return EnumBrowser.Brave;

			case this.CHROME_CHECK_REGEX.test(userAgent):
				return EnumBrowser.GoogleChrome;

			case this.SAFARI_CHECK_REGEX.test(userAgent):
				return EnumBrowser.Safari;

			case this.EDGE_CHECK_REGEX.test(userAgent):
				return EnumBrowser.MicrosoftEdge;

			case this.OPERA_CHECK_REGEX.test(userAgent):
				return EnumBrowser.Opera;

			case this.FIREFOX_CHECK_REGEX.test(userAgent):
				return EnumBrowser.MozillaFirefox;

			case this.TRIDENT_CHECK_REGEX.test(userAgent):
				return EnumBrowser.InternetExplorer;

			default:
				return EnumBrowser.Other;
		}
	}

	getBrowserName(): string {
		return BROWSER_INFO[this.getBrowser()].name;
	}


	/**------------------------------------------------------
	 * Url Info
	 */
	currentUrl(): string {
		return window.location.href;
	}

	//** Info about the current url */
	getLocationInfo(): ILocationInfo {
		const locationInfo: ILocationInfo = {
			host 		: window.location.host,
			hostname	: window.location.hostname,
			href		: window.location.href,
			origin		: window.location.origin,
			pathname	: window.location.pathname,
			port		: window.location.port,
			protocol	: window.location.protocol
		};
		return locationInfo;
	}


	/**------------------------------------------------------
	 * Network Connectivity Status, Speed
	 */
	isOnline(): boolean {
		return navigator.onLine;
	}

	isOffline(): boolean {
		return !this.isOnline();
	}

	getNetworkSpeed(): INetworkSpeed | null {

		//0 - is the measurement supported by the browser?
		const unsupportedBrowsers: EnumBrowser[] = [EnumBrowser.MozillaFirefox, EnumBrowser.InternetExplorer, EnumBrowser.Safari];
		if (unsupportedBrowsers.includes(this.getBrowser())) return null;

		//1 - get the network speed
		const networkSpeed: number = (navigator as any)?.connection?.downlink;
		if (Util.Basic.isUndefined(networkSpeed)) throw new Error(`BrowserDetectionService => networkSpeed => FATAL ERROR: network measure speed is unsupported (browser should supported it based on web standard)`);

		//2 - convert the digital measurement
		const speedMbps: number = networkSpeed;
		const speedKbps: number = Util.Convert.convertDigital(speedMbps, EnumDigital.Megabit, EnumDigital.Kilobit);
		const speedBps : number = Util.Convert.convertDigital(speedMbps, EnumDigital.Megabit, EnumDigital.Bit);

		//3 - return the result
		const result: INetworkSpeed = {
			speedMbps	: speedMbps,
			speedKbps	: speedKbps,
			speedBps	: speedBps
		};
		return result;
	}
}
