/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumSocketDisconnect {
	ServerDisconnect	= 'io server disconnect',	// names are defined by socket.io, do not change them!
	ClientDisconnect	= 'io client disconnect',
	PingTimeout			= 'ping timeout',
	ConnectionLost		= 'transport close',
	ConnectionError		= 'transport error'
}

export enum EnumSocketError {
	Error 			 	= 'error',
	ConnectError	 	= 'connect_error',
	EventEmitTimeout	= 'event_emit_timeout'
}

export enum EnumSocketReconnect {
	Reconnect 			= 'reconnect',
	ReconnectAttempt 	= 'reconnect_attempt',
	ReconnectFailed  	= 'reconnect_failed',
	ReconnectError 	 	= 'reconnect_error'
}

export enum EnumSocketEvent {
	Connect 		 	= 'connect',					// events names are defined by socket.io, do not change them!
	Disconnect 		 	= 'disconnect',
	Error 			 	= 'error',
	ConnectError	 	= 'connect_error',
	Ping 			 	= 'ping'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IEmitEventData<T> {
	event 	: string;
	data  	: T;
}

export interface IEmitEventLog {
	event 	: string;
	data  	: unknown | object | string | number | boolean;
	date	: Date;
}

export interface ISocketError {
	reason 	: EnumSocketError;
	error	: unknown;
}
