import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


/**------------------------------------------------------
 * Prepare Root Params
 * -------------------
 * > Prepare the parameters in the root path
 * > Needs to be used/called in the "app.component.ts"
 */
@Injectable()
export class AffiliateRootParamService {

	constructor(
		private router	: Router
	) {}

	//* set ref, coupon or forward from the root url params */
	handleParams(): void {

		//0 - get the parameters
		const params  : URLSearchParams = new URLSearchParams(window.location.search);
		const ref 	  : string = params.get('ref');
		const checkout: string = params.get('checkout');
		const coupon  : string = params.get('coupon');
		const forward : string = params.get('forward');

		//1 - redirect to the forward param url
		if (forward) {
			const fullUrl	: string = window.location.href;
			const forwardUrl: string = fullUrl.split('forward=')[1];
			this.router.navigate(['/'], { queryParams: { ref: ref, forward: forwardUrl } });
		}

		//2 - set param of ref and coupon and redirect to the store
		if (ref || coupon || checkout) {
			this.router.navigate(['/', 'store'], { queryParams: { ref: ref, coupon: coupon, checkout: checkout } });
		}
	}
}
