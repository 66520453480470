import { EnumFileCategory, EnumFileType, IFileTypeInfo } from './file-type.interface';


/**------------------------------------------------------
 * File Configurations
 */
export const FILE_TYPE_INFO: Record<EnumFileType, IFileTypeInfo> = {
	[EnumFileType.JPEG] : {
		extension 	: 'jpeg',
		fileEnding	: '.jpeg',
		mimeType 	: 'image/jpeg',
		altMimeTypes: [],
		category 	: EnumFileCategory.Image
	},
	[EnumFileType.JPG] : {
		extension 	: 'jpg',
		fileEnding	: '.jpg',
		mimeType 	: 'image/jpg',
		altMimeTypes: [],
		category 	: EnumFileCategory.Image
	},
	[EnumFileType.PNG] : {
		extension 	: 'png',
		fileEnding	: '.png',
		mimeType 	: 'image/png',
		altMimeTypes: [],
		category 	: EnumFileCategory.Image
	},
	[EnumFileType.GIF] : {
		extension 	: 'gif',
		fileEnding	: '.gif',
		mimeType 	: 'image/gif',
		altMimeTypes: [],
		category 	: EnumFileCategory.Image
	},
	[EnumFileType.SVG] : {
		extension 	: 'svg',
		fileEnding	: '.svg',
		mimeType 	: 'image/png',
		altMimeTypes: [],
		category 	: EnumFileCategory.Image
	},
	[EnumFileType.XML] : {
		extension 	: 'xml',
		fileEnding	: '.xml',
		mimeType 	: 'text/xml',
		altMimeTypes: [],
		category 	: EnumFileCategory.Data
	},
	[EnumFileType.FLY] : {
		extension 	: 'fly',
		fileEnding	: '.fly',
		mimeType 	: 'text',
		altMimeTypes: [],
		category 	: EnumFileCategory.Data
	},
	[EnumFileType.ZIP] : {
		extension 	: 'zip',
		fileEnding	: '.zip',
		mimeType 	: 'application/zip',
		altMimeTypes: ['application/x-zip-compressed', 'application/octet-stream', 'application/x-compress', 'application/x-compressed', 'multipart/x-zip'],
		category 	: EnumFileCategory.Archive
	},
	[EnumFileType.XLSX] : {
		extension 	: 'xlsx',
		fileEnding	: '.xlsx',
		mimeType 	: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // cspell:disable-line
		altMimeTypes: [],
		category 	: EnumFileCategory.Spreadsheet
	},
	[EnumFileType.XLS] : {
		extension 	: 'xls',
		fileEnding	: '.xls',
		mimeType 	: 'application/vnd.ms-excel',
		altMimeTypes: [],
		category 	: EnumFileCategory.Spreadsheet
	},
	[EnumFileType.JSON] : {
		extension 	: 'json',
		fileEnding	: '.json',
		mimeType 	: 'application/json',
		altMimeTypes: [],
		category 	: EnumFileCategory.Text
	},
	[EnumFileType.ODS] : {
		extension 	: 'ods',
		fileEnding	: '.ods',
		mimeType 	: 'application/vnd.oasis.opendocument.spreadsheet',
		altMimeTypes: [],
		category 	: EnumFileCategory.Spreadsheet
	},
	[EnumFileType.CSV] : {
		extension 	: 'csv',
		fileEnding	: '.csv',
		mimeType 	: 'text/csv',
		altMimeTypes: [],
		category 	: EnumFileCategory.Spreadsheet
	},
	[EnumFileType.TXT] : {
		extension 	: 'txt',
		fileEnding	: '.txt',
		mimeType 	: 'text/txt',
		altMimeTypes: [],
		category 	: EnumFileCategory.Text
	},
	[EnumFileType.INI] : {
		extension 	: 'ini',
		fileEnding	: '.ini',
		mimeType 	: 'text/ini',
		altMimeTypes: [],
		category 	: EnumFileCategory.Text
	}
};
