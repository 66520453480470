import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumShutterStockSorting {
	Popular			= 'popular',		// default value
	FreshContent	= 'fresh_content',
	MostRelevant	= 'most_relevant',
	Random			= 'random'
}

export const SHUTTER_STOCK_SORTING_LIST: ISearchParameterConfig<EnumShutterStockSorting>[] = [
	{
		code 		: EnumShutterStockSorting.Popular,
		name 		: 'Popular',
		parameters 	: {}
	},
	{
		code 		: EnumShutterStockSorting.FreshContent,
		name 		: 'Fresh Content',
		parameters 	: { sort: 'newest' }
	},
	{
		code 		: EnumShutterStockSorting.MostRelevant,
		name 		: 'Most Relevant',
		parameters 	: { sort: 'relevant' }
	},
	{
		code 		: EnumShutterStockSorting.Random,
		name 		: 'Random',
		parameters 	: { sort: 'random' }
	}
];


/**------------------------------------------------------
 * ShutterStock Configuration
 */
export const SHUTTER_STOCK_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.shutterstock.com',
		login		: 'https://accounts.shutterstock.com/login',
		search		: 'https://www.shutterstock.com/search/[SEARCH]/?page=[PAGE]'
	},
	pageLimit		: 1000,
	sortings		: SHUTTER_STOCK_SORTING_LIST,
	products		: [],
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * ShutterStock Search Options
 */
export const SHUTTER_STOCK_DEFAULT_OPTIONS: IShutterStockSearchOptions = {
	page			: 1,
	sorting			: EnumShutterStockSorting.Popular
};

export interface IShutterStockSearchOptions {
	page			: number;
	sorting			: EnumShutterStockSorting;
}
