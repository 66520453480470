import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumAppInfoTypes } from '@apps/flying/shared';

import { AppInfoRequest } from '../../../../requests/shared/app-info/app-info.request';
import { IMaintenanceDetail, IMaintenanceFlags } from './maintenance.interface';


//** Enums -------------------------------------- */
export enum EnumMaintenance {
	LiveResearch	= 'liveResearch',
	LiveKeywords	= 'liveKeywords',
	Translate		= 'translate'
}


/**------------------------------------------------------
 * Maintenance Flags Management
 */
export class MaintenanceStateHelper {

	constructor(
		private appInfoRequest: AppInfoRequest
	) {}


	getMaintenanceFlag(name: EnumMaintenance): Observable<IMaintenanceDetail> {
		return this.getMaintenanceFlags().pipe(map((flagObj: IMaintenanceFlags) => {

			//0 - try to get the maintenance object from the JSON
			const maintenanceObj: IMaintenanceDetail = flagObj.research?.[name] || flagObj.upload?.[name];
			if (!maintenanceObj) throw new Error(`MaintenanceStateHelper => getMaintenanceFlag => FATAL ERROR: maintenance configuration not found for name of "${name}" (please make sure it is set in the "maintenance-flags.json" file on the server)`);

			//1 - return the configuration
			return maintenanceObj;
		}));
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private getMaintenanceFlags(): Observable<IMaintenanceFlags> {
		return this.appInfoRequest.getAppInfo(EnumAppInfoTypes.MaintenanceInfo) as Observable<IMaintenanceFlags>;
	}
}
