import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../app-constants';


/**------------------------------------------------------
 * Advertising List Data
 */
export interface IAdvertisingList {
	id			: string;
	name		: string;
	notes		: string;
	marketplace	: EnumAmazonMarketplace;
	dates		: IAdvertisingListDates;
	productLists: IAdvertisingProductList[];		// can be empty!
}

export interface IAdvertisingListDates {
	from		: Date | null;						// can be null
	to			: Date | null;
}

export interface IAdvertisingProductList {
	product		: EnumAmazonProduct;
	keyword		: {
		positive	: string[];
		negative	: string[];
	};
	asin		: {
		positive	: string[];
		negative	: string[];
	};
}
