/**------------------------------------------------------
 * Base Security Interface
 */
interface ISecureBaseSecurityOptions {
	timeToken			: boolean;				// time token (protecting from reply attacks)
	encryption			: {						// encrypting the request and response
		request			: boolean;
		response		: boolean;
	};
	// botDetector		: boolean;				// check for automation like selenium or puppeteer
	// connectionType	: boolean;				// should it connect: SameServer, RandomServer, ...
	// rateLimits		: { ... }
}


/**------------------------------------------------------
 * Security Interfaces
 */
export interface ISecureHttpSecurityOptions extends ISecureBaseSecurityOptions {
	// .. add custom to sockets
}

export interface ISecureSocketSecurityOptions extends ISecureBaseSecurityOptions {
	// .. add custom to sockets
}


/**------------------------------------------------------
 * Socket Security Interfaces
 */
export interface ISecureSocketData<T> {
	clientData		: T;
	socketSecurity	: ISecureSocketSecurityOptions;
	formateResponse	: (data: T, statusCode: number, socketSecurity: ISecureSocketSecurityOptions) => string | T;
}
