import { HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnumSubscriptionModule } from '@apps/flying/shared';
import { IFlyingAccessRight } from '@apps-v2/flying/shared';

import { AccessTokenService } from './access-token/access-token.service';
import { ContentDataService } from './content-data/content-data.service';
import { TimeTokenService } from './time-token/time-token.service';


@Injectable({
	providedIn: 'root'
})
export class AuthSecurityService {

	private redirectUrl: string = null;

	constructor(
		private accessTokenService	: AccessTokenService,
		private timeTokenService	: TimeTokenService,
		private contentDataService	: ContentDataService
	) {}


	/**------------------------------------------------------
	 * Access Token
	 */
	getRedirect()					: string			{ return this.redirectUrl; }
	setRedirect(url: string)		: void				{ this.redirectUrl = url; }

	isAuthenticated()				: boolean 			{ return this.accessTokenService.isAuthenticated(); }
	reAuthenticate()				: Promise<boolean>	{ return this.accessTokenService.reAuthenticate(); }

	hasModule(module: EnumSubscriptionModule)				: boolean	{ return this.accessTokenService.hasModule(module); }
	authenticate(accessToken: string, refreshToken: string)	: void		{ this.accessTokenService.authenticate(accessToken, refreshToken); }

	getToken()						: string			{ return this.accessTokenService.getToken(); }
	getBearerToken()				: string			{ return this.accessTokenService.getBearerToken(); }
	setAccessToken(token: string)	: void				{ this.accessTokenService.setAccessToken(token); }
	getAccessRights()				: IFlyingAccessRight[] { return this.accessTokenService.getAccessRights(); }

	getUserEmail()					: string			{ return this.accessTokenService.getUserEmail(); }
	getId()							: string			{ return this.accessTokenService.getId(); }

	isPrivateState()				: boolean			{ return this.accessTokenService.isPrivateState(); }
	signoutClient()					: void				{ this.accessTokenService.signoutClient(); }
	fullSignout()					: void				{ this.accessTokenService.fullSignout(); }


	/**------------------------------------------------------
	 * Time Token
	 */
	appendTimeTokenHeader(headers: HttpHeaders)	: HttpHeaders 	{ return this.timeTokenService.appendTimeTokenHeader(headers); }
	createTimeOffset(timestamp: number)			: number		{ return this.timeTokenService.createTimeOffset(timestamp); }
	getTimeToken()								: string		{ return this.timeTokenService.getTimeToken(); }


	/**------------------------------------------------------
	 * Content Data Encryption
	 */
	getContentEncryption(req: HttpRequest<any>, newHeaders: HttpHeaders): HttpRequest<any> 	{ return this.contentDataService.getContentEncryption(req, newHeaders); }
	getContentDecryption(res: HttpResponse<any>)						: HttpResponse<any> { return this.contentDataService.getContentDecryption(res); }
	getErrorDecryption(error: HttpErrorResponse)						: HttpErrorResponse { return this.contentDataService.getErrorDecryption(error); }
}
