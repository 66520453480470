import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Show exact time, well formatted
 * ----------------
 * Format
 * > 1 sec, ... 40 sec, ... 55 sec,
 * > ... 1 min 30 sec, ... 1 hour 2 min 30 sec,
 * > ... 1 day 1 hour 1 min 30 sec,
 * > ... 2 month 1 day 1 hour 1 min 30 sec,
 * > ... 4 years 2 month 1 day 1 hour 1 min 30 sec,
 */
@Pipe({
	name : 'ngExactRemainingTime'
})
export class NgExactRemainingTimePipe implements PipeTransform {

	transform(value: string): string {

		//0 - check valid date time
		if (!Util.Date.isValidDateString(value))  throw new Error(`NgExactRemainingTimePipe => transform => FATAL ERROR: provided time is not valid`);
		if (new Date(value) < new Date())		  throw new Error(`NgExactRemainingTimePipe => transform => FATAL ERROR: provided time is in the past (it has to be in the future)`);

		//1 - check the time difference
		const dateTime: number = new Date(value).getTime();
		const now	  : number = new Date().getTime();
		const millisecondDiff: number = dateTime - now;
		if (millisecondDiff <= 0) return '0 sec';

		//2 - get date object in years, months, days, hours, minutes and seconds format
		const dateObject: INgDateObject = this.convertFromMs(millisecondDiff);
		let timeDiff: string = '';
		if (dateObject.years) 	timeDiff += dateObject.years  + this.formatTimeString(dateObject.years,  'year');
		if (dateObject.months) 	timeDiff += dateObject.months + this.formatTimeString(dateObject.months, 'month');
		if (dateObject.days) 	timeDiff += dateObject.days	  + this.formatTimeString(dateObject.days,   'day');
		if (dateObject.hours) 	timeDiff += dateObject.hours  + this.formatTimeString(dateObject.hours,  'hour');
		if (dateObject.minutes) timeDiff += ` ${dateObject.minutes} min `;
		if (dateObject.seconds) timeDiff += ` ${dateObject.seconds} sec `;
		return timeDiff.trim();
	}

	//** Convert from milliseconds to date object */
	convertFromMs(milliseconds: number): INgDateObject {

		//0 - calculate the times
		let seconds: number = Math.floor(milliseconds / 1000);
		let minutes: number = Math.floor(seconds / 60);
		let hours  : number = Math.floor(minutes / 60);
		let days   : number = Math.floor(hours / 24);
		let months : number = Math.floor(days / 30);
		const years: number = Math.floor(days / 365);

		//1 - convert absolute times in remaining
		seconds %= 60;
		minutes %= 60;
		hours   %= 24;
		days	%= 30;
		months  %= 12;

		//2 - return the times in object
		return { years, months, days, hours, minutes, seconds };
	}

	//** Get formatted string */
	formatTimeString(value: number, name: string): string {
		if (value > 1) name += 's';
		return ` ${name} `; // add one space before and after the name
	}
}


//** Interfaces --------------------------------- */
interface INgDateObject {
	years	: number;
	months	: number;
	days	: number;
	hours	: number;
	minutes	: number;
	seconds	: number;
}
