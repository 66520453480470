import { Component, HostListener, Input } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Util } from '@libs/utilities/util';
import { IKeyCode, KeyboardMouseService } from '@libs/libraries/frontend';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { INgConformModalConfig } from './data/conform-modal-config.interface';
import { INgConformModalButton, INgConformModalContent, EnumNgConformAction } from './conform-modal.interface';


@Component({
	selector	: 'ng-conform-modal',
	templateUrl	: './conform-modal.component.html',
	styleUrls	: ['./conform-modal.component.scss']
})
export class NgConformModalComponent {

	//** Confirmation Modal Object */
	@Input() modalConfig	: INgConformModalConfig | null  = null;
	@Input() modalContent	: INgConformModalContent | null = null;
	@Input() modalRef 	   !: NgbModalRef;

	//** Handle the Events */
	private readonly actionEvent$: Subject<EnumNgConformAction | string> = new Subject<EnumNgConformAction | string>();

	constructor(
		private ngbActiveModal		: NgbActiveModal,
		private keyboardMouseService: KeyboardMouseService
	) {}


	/**------------------------------------------------------
	 * Subscribe
	 */
	subscribe(callbackFunction: Function): NgConformModalComponent {
		this.actionEvent$.pipe(take(1)).subscribe((action: EnumNgConformAction | string) => {
			callbackFunction(action);
		});
		return this;
	}

	onAgree(callbackFunction: Function): NgConformModalComponent {
		this.actionEvent$.pipe(take(1)).subscribe((action: EnumNgConformAction | string) => {
			if (action as EnumNgConformAction === EnumNgConformAction.Continue) callbackFunction();
		});
		return this;
	}

	onClose(callbackFunction: Function): NgConformModalComponent {
		this.actionEvent$.pipe(take(1)).subscribe((action: EnumNgConformAction | string) => {
			if (action as EnumNgConformAction === EnumNgConformAction.Close) callbackFunction();
		});
		return this;
	}


	/**------------------------------------------------------
	 * Getter & Setter Helpers
	 */
	setTitle(title: string): NgConformModalComponent {
		if (this.modalContent) this.modalContent.title = title;
		return this;
	}

	setMessage(message: string): NgConformModalComponent {
		if (this.modalContent) this.modalContent.message = message;
		return this;
	}


	/**------------------------------------------------------
	 * Close Options
	 */
	closeAfter(seconds: number): NgConformModalComponent {

		//0 - check the provided value
		if (Util.Number.isNegative(seconds)) throw new Error(`ConformModalComponent => closeAfter => FATAL ERROR: second of "${seconds}" not valid`);

		//1 - start a timeout to close the modal
		setTimeout(() => {
			this.ngbActiveModal.close();
		}, seconds);
		return this;
	}

	close(): NgConformModalComponent {

		//0 - emit the close event
		this.actionEvent$.next(EnumNgConformAction.Close);

		//1 - close the modal
		this.ngbActiveModal.close();
		return this;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */

	//** Emit the actions */
	emitButtonAction(actionCode: string): void {
		this.actionEvent$.next(actionCode);
		this.ngbActiveModal.close();
	}

	//** HostListener to close modal on escape click */
	@HostListener('keydown', ['$event'])
	onKeyEscapeHandler(event: KeyboardEvent): void {
		if (this.keyboardMouseService.isKeyEscape(event) || this.keyboardMouseService.isKeyEnter(event)) {
			const actionKey: string = event.code;
			this.triggerButtonEvent(actionKey);
		}
	}

	//** Function will trigger once ESCAPE and ENTER key will click */
	triggerButtonEvent(actionKey: string): void {

		//0 - check for modal config
		if (!this.modalConfig) throw new Error(`ConformModalComponent => triggerButtonEvent => modal config not provided`);

		//1 - get the triggered event
		const triggeredButton: INgConformModalButton = this.modalConfig.buttons.find((button: INgConformModalButton) => button.actionKey.find((action: IKeyCode) => action.code === actionKey))!;
		if (!triggeredButton) throw new Error(`ConformModalComponent => triggerButtonEvent => ${actionKey} not found`);

		//2 - emit the action code
		this.emitButtonAction(triggeredButton.actionCode);
	}
}
