/**------------------------------------------------------
 * Design Management Toolbar Options
 */
export enum EnumToolbarOptions {

	// main options
	EditDesigns				= 'toolbar_edit_designs',
	SelectAll				= 'toolbar_select_all',
	UploadHistory			= 'toolbar_upload_history',
	UploadBatch				= 'toolbar_upload_batch',
	ScheduleUpload 			= 'toolbar_schedule_upload',

	// more options
	TmListingCheck			= 'toolbar_tm_listing_check',
	TmBlacklistCheck		= 'toolbar_tm_blacklist_check',
	UnselectAll				= 'toolbar_unselect_all',
	Export					= 'toolbar_export',
	Duplicate				= 'toolbar_duplicate',
	ClearListing			= 'toolbar_clear_listing',
	DownloadImages			= 'toolbar_download_images',
	ExportListingsFly		= 'toolbar_export_listings_fly',
	ExportListingsExcel		= 'toolbar_export_listings_excel',
	ExportAllData			= 'toolbar_export_All_data',
	OpenTmWatch				= 'toolbar_open_tm_watch',
	CopyToKDP				= 'toolbar_copy_to_kdp',
	AddTags					= 'toolbar_add_tags',
	SetTags					= 'toolbar_set_tags',
	ReplaceTags				= 'toolbar_replace_tags',
	ClearTags				= 'toolbar_clear_tags',
	InvertSelection			= 'toolbar_invert_selection',
	SelectListing			= 'toolbar_select_listing',
	SelectEmpty				= 'toolbar_select_empty',
	CopyFlyIds				= 'toolbar_copy_fly_ids',
	Delete					= 'toolbar_delete',
	GroupSimilar			= 'toolbar_group_similar',
	RemoveListing 			= 'toolbar_remove_listing',
	RemoveEmpty 			= 'toolbar_remove_empty',
	DivideListingEmpty		= 'toolbar_divide_listing_empty',
	DivideTaggedEmpty		= 'toolbar_divide_tagged_empty',
	DivideLightDark			= 'toolbar_divide_light_dark',
	GroupByColor			= 'toolbar_group_by_color'
}
