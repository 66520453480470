/**------------------------------------------------------
 * Security Http Module
 * --------------------
 * > Provides the "Http Service" to make requests to the backend.
 * > It has all used security features of the backend embedded.
 */
export * from './secure-http/secure-http.module';
export * from './secure-http/secure-http.config';
export * from './secure-http/http/secure-http';


/**------------------------------------------------------
 * Secure Socket Module
 * --------------------
 * > Provides the "Socket Service" to make requests to the backend.
 * > It has all used security features of the backend embedded.
 */
export * from './secure-socket/secure-socket.module';
export * from './secure-socket/secure-socket.config';
export * from './secure-socket/socket/secure-socket';
