import { AbstractStorage } from '@libs/libraries/shared';

import { CookiesStorageObject } from './cookies-storage-object';


export class CookiesStorageHandler implements AbstractStorage {

	constructor(
		private cookiesStorageObject: CookiesStorageObject
	) {}

	getItem(key: string): string | null {
		return this.cookiesStorageObject.getCookie(key);
	}

	setItem(key: string, value: string): void {
		this.cookiesStorageObject.setCookie(key, value);
	}

	removeItem(key: string): void {

		// expire the cookie with old date
		this.cookiesStorageObject.setExpiry(key, '', new Date(new Date().setDate(new Date().getDate() - 1)));
	}
}
