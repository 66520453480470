/**------------------------------------------------------
 * Get Inspired MBA constants
 */
export enum EnumInspiredMbaSorting {
	Bsr 		= 'bsr',
	Review		= 'review',
	Price		= 'price',
	Newest		= 'newest',
	Oldest		= 'oldest',
	Random		= 'random',
	Relevant	= 'relevant'
}

export enum EnumInspiredMbaType {
	Search		= 'search',
	NewDesigns 	= 'new-designs',
	NewTrends	= 'new-trends',
	BsrWinners 	= 'bsr-winners',
	Random		= 'random'
}
