<!-- Header ---------------------------------------------- -->
<div
	class="ignore-dark-mode"
	*ngIf="modalConfig"
>
	<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
	<div
		class="modal-header position-relative"
		style.border-color="var({{ modalConfig.style.color }})"
	>
		<h5
			class="modal-title text-muted"
			*ngIf="modalContent?.options?.size === EnumNgTemplateSize.Small"
		>
			<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
			<ng-fa-icon
				[icon]="modalConfig.style.icon.name"
				[iconStyle]="modalConfig.style.icon.style"
				style.color="var({{ modalConfig.style.color }})"
				class="me-1 d-inline-flex"
			></ng-fa-icon>
			{{ modalContent?.text?.title }}
		</h5>
		<h4
			class="modal-title text-muted"
			*ngIf="modalContent?.options?.size !== EnumNgTemplateSize.Small"
		>
			<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
			<ng-fa-icon
				[icon]="modalConfig.style.icon.name"
				[iconStyle]="modalConfig.style.icon.style"
				style.color="var({{ modalConfig.style.color }})"
				class="me-1 d-inline-flex"
			></ng-fa-icon>
			{{ modalContent?.text?.title }}
		</h4>
	</div>
</div>

<!-- Body ------------------------------------------------- -->
<div class="modal-body m-3 scroll-bar text-justify">
	<div
		class="text-justify"
		*ngIf="modalContent?.text?.templateRef"
		[ngTemplateOutlet]="modalContent?.text?.templateRef!"
	></div>
	<div
		class="mx-1"
		*ngIf="modalContent?.text?.htmlString"
		[innerHTML]="modalContent?.text?.htmlString"
	></div>
</div>

<!-- Footer ----------------------------------------------- -->
<div class="modal-footer ignore-dark-mode">
	<!-- Option buttons and mandatory buttons  -->
	<div
		class="buttons-block"
		[ngClass]="modalContent?.buttons?.length! <= 2 ? 'justify-content-end' : 'justify-content-between'"
	>
		<!-- Option buttons -->
		<div
			class="buttons-options-block"
			*ngIf="modalContent?.buttons?.length! > 2"
		>
			<!-- 3rd button as option button if total buttons are 3 only -->
			<ng-container *ngIf="modalContent?.buttons?.length! <= 3">
				<button
					type="button"
					class="btn btn-option ignore-dark-mode"
					(click)="emitButtonAction(modalContent!.buttons[modalContent!.buttons!.length - 1]!.actionCode)"
				>
					{{ modalContent!.buttons[modalContent!.buttons!.length - 1]!.buttonText }}
				</button>
			</ng-container>

			<!-- Dropdown with option buttons if buttons are more than 3 -->
			<ng-container *ngIf="modalContent?.buttons?.length! > 3">
				<!-- Dropdown outermost button -->
				<div
					ngbDropdown
					class="d-inline-block m-0"
					container="body"
				>
					<button
						class="d-flex justify-content-center align-items-center form-control text-truncate"
						id="option-buttons-dropdown"
						ngbDropdownToggle
					>
						<div
							class="text-truncate outermost-dropdown-button"
							container="body"
							onlyTruncate
							ngTooltip="{{ 'options' | uppercase }}"
						>
							Options
						</div>
						<span class="dropdown-arrow">
							<ng-fa-icon
								class="d-inline-flex"
								icon="chevron-down"
							></ng-fa-icon>
						</span>
					</button>

					<!-- Dropdown menu with option buttons -->
					<div
						ngbDropdownMenu
						aria-labelledby="option-buttons-dropdown"
					>
						<ng-container *ngFor="let button of modalContent?.buttons; let i = index">
							<button
								*ngIf="i > 1"
								ngbDropdownItem
								(click)="emitButtonAction(button.actionCode)"
							>
								{{ button.buttonText }}
							</button>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>

		<!-- Mandatory action buttons -->
		<div class="buttons-mandatory-block">
			<ng-container *ngFor="let button of modalContent?.buttons; let i = index">
				<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
				<button
					*ngIf="i < 2"
					type="button"
					ngbAutoFocus
					class="btn text-white btn-hover"
					[class.me-2]="i === 0"
					[style.background-color]="getBackgroundColor(i)"
					(click)="emitButtonAction(button.actionCode)"
				>
					{{ button.buttonText }}
				</button>
			</ng-container>
		</div>
	</div>
</div>
