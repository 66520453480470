import { EnumTmTerritory } from '@domains/trademark/shared';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_WATCHLIST_LIMITS: IFlyingWatchlistConfig = {
	WORD_LENGTH		: 120,
	NOTES_LENGTH	: 400,
	MAX_LIST_SIZE	: 10000
};

interface IFlyingWatchlistConfig {
	WORD_LENGTH		: number;
	NOTES_LENGTH	: number;
	MAX_LIST_SIZE	: number;
}


/**------------------------------------------------------
 * TM Watchlist Data Structures
 */
export interface IFlyingTmWatchlist {
	entries		: IFlyingTmWatchlistEntry[];	// is required and all elements have to be a valid 'ITmWatchlistEntry', the array can also be an empty array
}

export interface IFlyingTmWatchlistEntry {
	word		: string;						// should be a valid string and not empty
	note		: string;						// can be empty but has to be a type string
	territory	: EnumTmTerritory;				// has to be valid enum of "EnumTmTerritory"
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingTmWatchlistSchema implements ValidatorSchema<IFlyingTmWatchlist> {
	entries		= Validator.Array().isMaxSize(FLYING_WATCHLIST_LIMITS.MAX_LIST_SIZE)
					.inspectEach<IFlyingTmWatchlistEntry>(FlyingTmWatchlistEntrySchema);
}

export class FlyingTmWatchlistEntrySchema implements ValidatorSchema<IFlyingTmWatchlistEntry> {
	word		= Validator.String().isNotEmpty().isMaxLength(FLYING_WATCHLIST_LIMITS.WORD_LENGTH);
	note		= Validator.String().isMaxLength(FLYING_WATCHLIST_LIMITS.NOTES_LENGTH);
	territory	= Validator.Enum(EnumTmTerritory).isWhitelisted([
		EnumTmTerritory.WO,
		EnumTmTerritory.US, EnumTmTerritory.GB,
		EnumTmTerritory.DE, EnumTmTerritory.FR,
		EnumTmTerritory.ES, EnumTmTerritory.IT
	]);
}
