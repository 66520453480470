/* cspell:ignore pacra, miem, dnpi, turkpatent, innorpi, usbm, sipo, rospatent, ipors, inpipt, ompic, lrpv, osim, ipophl, indecopi, oapi, iponz, nipo, myipo, iprd, soip, agepi, mcipo, ippd, cgdptm, ilpo, kipo, isipo, ipoi, hipo, dziv, nipcg, ukipo, itda, dkpto, ipocz, ocpi, inapi, drcip, rnpcr, cnipa, cipo, boip, bruipo, aripo, gdip */
import { EnumCountry } from '@libs/constants';

import { EnumTmTerritory } from './tm-territories.data';


/**------------------------------------------------------
 * Trademark Offices
 */
export enum EnumTmOffice {
	WO 		= 'wo',
	EM 		= 'em',

	AL 		= 'al',
	AP 		= 'ap',
	AR 		= 'ar',
	AT 		= 'at',
	AU 		= 'au',
	BA 		= 'ba',
	BG 		= 'bg',
	BN 		= 'bn',
	BR 		= 'br',
	BX 		= 'bx',
	CA 		= 'ca',
	CH 		= 'ch',
	CL 		= 'cl',
	CN 		= 'cn',
	CO 		= 'co',
	CR 		= 'cr',
	CU 		= 'cu',
	CY 		= 'cy',
	CZ 		= 'cz',
	DE 		= 'de',
	DK 		= 'dk',
	EE 		= 'ee',
	EG 		= 'eg',
	ES 		= 'es',
	FI 		= 'fi',
	FR 		= 'fr',
	GB 		= 'gb',
	GE 		= 'ge',
	GR 		= 'gr',
	HR 		= 'hr',
	HU 		= 'hu',
	IE 		= 'ie',
	IL 		= 'il',
	IN 		= 'in',
	IS 		= 'is',
	IT 		= 'it',
	JO 		= 'jo',
	JP 		= 'jp',
	KH 		= 'kh',
	KR 		= 'kr',
	LA 		= 'la',
	LI 		= 'li',
	LT 		= 'lt',
	LV 		= 'lv',
	MA 		= 'ma',
	MC 		= 'mc',
	MD 		= 'md',
	ME 		= 'me',
	MK 		= 'mk',
	MT 		= 'mt',
	MX 		= 'mx',
	MY 		= 'my',
	NO 		= 'no',
	NZ 		= 'nz',
	OA 		= 'oa',
	PE 		= 'pe',
	PH 		= 'ph',
	PL 		= 'pl',
	PT 		= 'pt',
	RO 		= 'ro',
	RS 		= 'rs',
	RU 		= 'ru',
	SE 		= 'se',
	SI 		= 'si',
	SK 		= 'sk',
	SM 		= 'sm',
	TH 		= 'th',
	TN 		= 'tn',
	TR 		= 'tr',
	US 		= 'us',
	UY 		= 'uy',
	ZM 		= 'zm'
}


/**------------------------------------------------------
 * Definitions & Configurations
 */
export const TM_OFFICE_INFOS: Record<EnumTmOffice, ITmOfficeInfo> = {
	[EnumTmOffice.AL]: {
		code			: EnumTmOffice.AL,
		abbreviation	: 'GDIP',
		name			: 'General Directorate of Intellectual Property (Albania)',
		country			: EnumCountry.AL,
		countryName		: 'AL GDIP',
		territories		: [EnumTmTerritory.AL, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://dppi.gov.al/',
		tmViewValue		: 'AL'
	},
	[EnumTmOffice.AP]: {
		code			: EnumTmOffice.AP,
		abbreviation	: 'ARIPO',
		name			: 'African Regional Intellectual Property Organization',
		country			: null,
		countryName		: 'AP ARIPO',
		territories		: [
			EnumTmTerritory.AP, EnumTmTerritory.BW, EnumTmTerritory.GM, EnumTmTerritory.GH, EnumTmTerritory.KE,
			EnumTmTerritory.LS, EnumTmTerritory.LR, EnumTmTerritory.MW, EnumTmTerritory.MZ, EnumTmTerritory.NA,
			EnumTmTerritory.RW, EnumTmTerritory.ST, EnumTmTerritory.SL, EnumTmTerritory.SO, EnumTmTerritory.SD,
			EnumTmTerritory.SZ, EnumTmTerritory.TZ, EnumTmTerritory.UG, EnumTmTerritory.ZM, EnumTmTerritory.ZW,
			EnumTmTerritory.AFR
		],
		href			: 'http://www.aripo.org',
		tmViewValue		: 'AP'
	},
	[EnumTmOffice.AR]: {
		code			: EnumTmOffice.AR,
		abbreviation	: 'INPI',
		name			: 'National Institute of Industrial Property (Argentina, France)',
		country			: EnumCountry.AR,
		countryName		: 'AR INPI',
		territories		: [EnumTmTerritory.AR, EnumTmTerritory.AMC],
		href			: 'http://www.inpi.gob.ar',
		tmViewValue		: 'AR'
	},
	[EnumTmOffice.AT]: {
		code			: EnumTmOffice.AT,
		abbreviation	: 'OPA',
		name			: 'Intellectual Property Office (Australia)',
		country			: EnumCountry.AT,
		countryName		: 'AT OPA',
		territories		: [EnumTmTerritory.AT, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.patentamt.at/',
		tmViewValue		: 'AT'
	},
	[EnumTmOffice.AU]: {
		code			: EnumTmOffice.AU,
		abbreviation	: 'IPA',
		name			: 'Intellectual Property Agency (Azerbaijan)',
		country			: EnumCountry.AU,
		countryName		: 'AU IPA',
		territories		: [EnumTmTerritory.AU, EnumTmTerritory.AUS],
		href			: 'https://www.ipaustralia.gov.au/',
		tmViewValue		: 'AU'
	},
	[EnumTmOffice.BA]: {
		code			: EnumTmOffice.BA,
		abbreviation	: 'IIP-BIH',
		name			: 'Institute for Intellectual Property of Bosnia and Herzegovina',
		country			: EnumCountry.BA,
		countryName		: 'BA IIP-BIH',
		territories		: [EnumTmTerritory.BA, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.ipr.gov.ba/en/',
		tmViewValue		: 'BA'
	},
	[EnumTmOffice.BG]: {
		code			: EnumTmOffice.BG,
		abbreviation	: 'BPO',
		name			: 'Bulgarian Patent Office',
		country			: EnumCountry.BG,
		countryName		: 'BG BPO',
		territories		: [EnumTmTerritory.BG, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.bpo.bg/',
		tmViewValue		: 'BG'
	},
	[EnumTmOffice.BN]: {
		code			: EnumTmOffice.BN,
		abbreviation	: 'BRUIPO',
		name			: 'Benelux Office for Intellectual Property',
		country			: EnumCountry.BN,
		countryName		: 'BN BRUIPO',
		territories		: [EnumTmTerritory.BN, EnumTmTerritory.ASI],
		href			: 'http://www.bruipo.gov.bn/',
		tmViewValue		: 'BN'
	},
	[EnumTmOffice.BR]: {
		code			: EnumTmOffice.BR,
		abbreviation	: 'INPI',
		name			: 'National Institute of Industrial Property (Brazil)',
		country			: EnumCountry.BR,
		countryName		: 'BR INPI',
		territories		: [EnumTmTerritory.BR, EnumTmTerritory.AMC],
		href			: 'http://www.inpi.gov.br',
		tmViewValue		: 'BR'
	},
	[EnumTmOffice.BX]: {
		code			: EnumTmOffice.BX,
		abbreviation	: 'BOIP',
		name			: 'Benelux Office for Intellectual Property',
		country			: null,
		countryName		: 'BX BOIP',
		territories		: [
			EnumTmTerritory.BE, EnumTmTerritory.BX, EnumTmTerritory.LU, EnumTmTerritory.NL, EnumTmTerritory.EU, EnumTmTerritory.EUR
		],
		href			: 'https://www.boip.int/en',
		tmViewValue		: 'BX'
	},
	[EnumTmOffice.CA]: {
		code			: EnumTmOffice.CA,
		abbreviation	: 'CIPO',
		name			: 'Canadian Intellectual Property Office',
		country			: EnumCountry.CA,
		countryName		: 'CA CIPO',
		territories		: [EnumTmTerritory.CA, EnumTmTerritory.AMC],
		href			: 'http://www.cipo.ic.gc.ca/eic/site/cipointernet-internetopic.nsf/eng/home',
		tmViewValue		: 'CA'
	},
	[EnumTmOffice.CH]: {
		code			: EnumTmOffice.CH,
		abbreviation	: 'IGE-IPI',
		name			: 'Swiss Federal Institute of Intellectual Property',
		country			: EnumCountry.CH,
		countryName		: 'CH IGE-IPI',
		territories		: [EnumTmTerritory.CH, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'https://www.ige.ch/en.html',
		tmViewValue		: 'CH'
	},
	[EnumTmOffice.CL]: {
		code			: EnumTmOffice.CL,
		abbreviation	: 'INAPI',
		name			: 'National Institute of Industrial Property (Chile)',
		country			: EnumCountry.CL,
		countryName		: 'CL INAPI',
		territories		: [EnumTmTerritory.CL, EnumTmTerritory.AMC],
		href			: 'http://www.inapi.cl',
		tmViewValue		: 'CL'
	},
	[EnumTmOffice.CN]: {
		code			: EnumTmOffice.CN,
		abbreviation	: 'CNIPA',
		name			: 'China National Intellectual Property Administration',
		country			: EnumCountry.CN,
		countryName		: 'CN CNIPA',
		territories		: [EnumTmTerritory.CN, EnumTmTerritory.ASI],
		href			: 'https://english.cnipa.gov.cn/',
		tmViewValue		: 'CN'
	},
	[EnumTmOffice.CO]: {
		code			: EnumTmOffice.CO,
		abbreviation	: 'SIC',
		name			: 'Intellectual Property Office (Czech Republic)',
		country			: EnumCountry.CO,
		countryName		: 'CO SIC',
		territories		: [EnumTmTerritory.CO, EnumTmTerritory.AMC],
		href			: 'http://www.sic.gov.co',
		tmViewValue		: 'CO'
	},
	[EnumTmOffice.CR]: {
		code			: EnumTmOffice.CR,
		abbreviation	: 'RNPCR',
		name			: 'National Center for Patents and Registration (Democratic Republic of Congo)',
		country			: EnumCountry.CR,
		countryName		: 'CR RNPCR',
		territories		: [EnumTmTerritory.CR, EnumTmTerritory.AMC],
		href			: 'http://www.rnpdigital.com/',
		tmViewValue		: 'CR'
	},
	[EnumTmOffice.CU]: {
		code			: EnumTmOffice.CU,
		abbreviation	: 'OCPI',
		name			: 'Office of Intellectual Property of Cuba',
		country			: EnumCountry.CU,
		countryName		: 'CU OCPI',
		territories		: [EnumTmTerritory.CU, EnumTmTerritory.AMC],
		href			: 'http://www.ocpi.cu',
		tmViewValue		: 'CU'
	},
	[EnumTmOffice.CY]: {
		code			: EnumTmOffice.CY,
		abbreviation	: 'DRCIP',
		name			: 'Industrial Property Office (Dominican Republic)',
		country			: EnumCountry.CY,
		countryName		: 'CY DRCIP',
		territories		: [EnumTmTerritory.CY, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'https://www.intellectualproperty.gov.cy/en/',
		tmViewValue		: 'CY'
	},
	[EnumTmOffice.CZ]: {
		code			: EnumTmOffice.CZ,
		abbreviation	: 'IPOCZ',
		name			: 'Industrial Property Office (Czech Republic)',
		country			: EnumCountry.CZ,
		countryName		: 'CZ IPOCZ',
		territories		: [EnumTmTerritory.CZ, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.upv.cz',
		tmViewValue		: 'CZ'
	},
	[EnumTmOffice.DE]: {
		code			: EnumTmOffice.DE,
		abbreviation	: 'DPMA',
		name			: 'German Patent and Trade Mark Office (Deutsches Patent- und Markenamt)',
		country			: EnumCountry.DE,
		countryName		: 'DE DPMA',
		territories		: [EnumTmTerritory.DE, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.dpma.de',
		tmViewValue		: 'DE'
	},
	[EnumTmOffice.DK]: {
		code			: EnumTmOffice.DK,
		abbreviation	: 'DKPTO',
		name			: 'Danish Patent and Trademark Office',
		country			: EnumCountry.DK,
		countryName		: 'DK DKPTO',
		territories		: [EnumTmTerritory.DK, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.dkpto.dk',
		tmViewValue		: 'DK'
	},
	[EnumTmOffice.EE]: {
		code			: EnumTmOffice.EE,
		abbreviation	: 'EPA',
		name			: 'European Patent Office',
		country			: EnumCountry.EE,
		countryName		: 'EE EPA',
		territories		: [EnumTmTerritory.EE, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.epa.ee',
		tmViewValue		: 'EE'
	},
	[EnumTmOffice.EG]: {
		code			: EnumTmOffice.EG,
		abbreviation	: 'ITDA',
		name			: 'Indian Trade Development Agency',
		country			: EnumCountry.EG,
		countryName		: 'EG ITDA',
		territories		: [EnumTmTerritory.EG, EnumTmTerritory.AFR],
		href			: 'http://www.itda.gov.eg',
		tmViewValue		: 'EG'
	},
	[EnumTmOffice.ES]: {
		code			: EnumTmOffice.ES,
		abbreviation	: 'OEPM',
		name			: 'Spanish Patent and Trademark Office',
		country			: EnumCountry.ES,
		countryName		: 'ES OEPM',
		territories		: [EnumTmTerritory.ES, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.oepm.es ',
		tmViewValue		: 'ES'
	},
	[EnumTmOffice.FI]: {
		code			: EnumTmOffice.FI,
		abbreviation	: 'PRH',
		name			: 'Finnish Patent and Registration Office',
		country			: EnumCountry.FI,
		countryName		: 'FI PRH',
		territories		: [EnumTmTerritory.FI, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.prh.fi/en.html ',
		tmViewValue		: 'FI'
	},
	[EnumTmOffice.FR]: {
		code			: EnumTmOffice.FR,
		abbreviation	: 'INPI',
		name			: 'National Institute of Industrial Property (France)',
		country			: EnumCountry.FR,
		countryName		: 'FR INPI',
		territories		: [EnumTmTerritory.FR, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.inpi.fr',
		tmViewValue		: 'FR'
	},
	[EnumTmOffice.GB]: {
		code			: EnumTmOffice.GB,
		abbreviation	: 'UKIPO',
		name			: 'Intellectual Property Office of the United Kingdom',
		country			: EnumCountry.UK,
		countryName		: 'GB UKIPO',
		territories		: [EnumTmTerritory.GB, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.ipo.gov.uk/',
		tmViewValue		: 'GB'
	},
	[EnumTmOffice.GE]: {
		code			: EnumTmOffice.GE,
		abbreviation	: 'NIPCG',
		name			: 'Intellectual Property Office of Montenegro',
		country			: EnumCountry.GE,
		countryName		: 'GE NIPCG',
		territories		: [EnumTmTerritory.GE, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.sakpatenti.gov.ge/en/',
		tmViewValue		: 'GE'
	},
	[EnumTmOffice.GR]: {
		code			: EnumTmOffice.GR,
		abbreviation	: 'OBI',
		name			: 'Intellectual Property Office of Bosnia and Herzegovina',
		country			: EnumCountry.GR,
		countryName		: 'GR OBI',
		territories		: [EnumTmTerritory.GR, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.obi.gr/',
		tmViewValue		: 'GR'
	},
	[EnumTmOffice.HR]: {
		code			: EnumTmOffice.HR,
		abbreviation	: 'DZIV',
		name			: 'State Agency on Intellectual Property (Republic of Moldova)',
		country			: EnumCountry.HR,
		countryName		: 'HR DZIV',
		territories		: [EnumTmTerritory.HR, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.dziv.hr/',
		tmViewValue		: 'HR'
	},
	[EnumTmOffice.HU]: {
		code			: EnumTmOffice.HU,
		abbreviation	: 'HIPO',
		name			: 'Hungarian Intellectual Property Office',
		country			: EnumCountry.HU,
		countryName		: 'HU HIPO',
		territories		: [EnumTmTerritory.HU, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.sztnh.gov.hu',
		tmViewValue		: 'HU'
	},
	[EnumTmOffice.IE]: {
		code			: EnumTmOffice.IE,
		abbreviation	: 'IPOI',
		name			: 'Intellectual Property Office of Iceland',
		country			: EnumCountry.IE,
		countryName		: 'IE IPOI',
		territories		: [EnumTmTerritory.IE, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'https://www.ipoi.gov.ie',
		tmViewValue		: 'IE'
	},
	[EnumTmOffice.IL]: {
		code			: EnumTmOffice.IL,
		abbreviation	: 'ILPO',
		name			: 'Israel Patent Office',
		country			: EnumCountry.IL,
		countryName		: 'IL ILPO',
		territories		: [EnumTmTerritory.IL, EnumTmTerritory.ASI],
		href			: 'http://www.trademarks.justice.gov.il',
		tmViewValue		: 'IL'
	},
	[EnumTmOffice.IN]: {
		code			: EnumTmOffice.IN,
		abbreviation	: 'CGDPTM',
		name			: 'Intellectual Property Office of Cape Verde',
		country			: EnumCountry.IN,
		countryName		: 'IN CGDPTM',
		territories		: [EnumTmTerritory.IN, EnumTmTerritory.ASI],
		href			: 'https://ipindia.gov.in',
		tmViewValue		: 'IN'
	},
	[EnumTmOffice.IS]: {
		code			: EnumTmOffice.IS,
		abbreviation	: 'ISIPO',
		name			: 'Intellectual Property Office of Iceland',
		country			: EnumCountry.IS,
		countryName		: 'IS ISIPO',
		territories		: [EnumTmTerritory.IS, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'https://www.hugverk.is/en',
		tmViewValue		: 'IS'
	},
	[EnumTmOffice.IT]: {
		code			: EnumTmOffice.IT,
		abbreviation	: 'UIBM',
		name			: 'Italian Patent and Trademark Office',
		country			: EnumCountry.IT,
		countryName		: 'IT UIBM',
		territories		: [EnumTmTerritory.IT, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'https://uibm.mise.gov.it/index.php/it/',
		tmViewValue		: 'IT'
	},
	[EnumTmOffice.JO]: {
		code			: EnumTmOffice.JO,
		abbreviation	: 'IPPD',
		name			: 'Intellectual Property Office of the Philippines',
		country			: EnumCountry.JO,
		countryName		: 'JO IPPD',
		territories		: [EnumTmTerritory.JO, EnumTmTerritory.ASI],
		href			: 'http://www.mit.gov.jo',
		tmViewValue		: 'JO'
	},
	[EnumTmOffice.JP]: {
		code			: EnumTmOffice.JP,
		abbreviation	: 'JPO',
		name			: 'Japan Patent Office',
		country			: EnumCountry.JP,
		countryName		: 'JP JPO',
		territories		: [EnumTmTerritory.JP, EnumTmTerritory.ASI],
		href			: 'http://www.jpo.go.jp',
		tmViewValue		: 'JP'
	},
	[EnumTmOffice.KH]: {
		code			: EnumTmOffice.KH,
		abbreviation	: 'DIP',
		name			: 'Department of Intellectual Property (Thailand)',
		country			: EnumCountry.KH,
		countryName		: 'KH DIP',
		territories		: [EnumTmTerritory.KH, EnumTmTerritory.ASI],
		href			: 'http://www.cambodiaip.gov.kh',
		tmViewValue		: 'KH'
	},
	[EnumTmOffice.KR]: {
		code			: EnumTmOffice.KR,
		abbreviation	: 'KIPO',
		name			: 'Korean Intellectual Property Office',
		country			: EnumCountry.KR,
		countryName		: 'KR KIPO',
		territories		: [EnumTmTerritory.KR, EnumTmTerritory.ASI],
		href			: 'https://www.kipo.go.kr/en/MainApp',
		tmViewValue		: 'KR'
	},
	[EnumTmOffice.LA]: {
		code			: EnumTmOffice.LA,
		abbreviation	: 'DIP',
		name			: 'Department Of Interllectual Property',
		country			: EnumCountry.LA,
		countryName		: 'LA DIP',
		territories		: [EnumTmTerritory.LA, EnumTmTerritory.ASI],
		href			: 'https://dip.gov.la/?lang=en',
		tmViewValue		: 'LA'
	},
	[EnumTmOffice.LI]: {
		code			: EnumTmOffice.LI,
		abbreviation	: 'AVW-LLV',
		name			: 'Office for Harmonization in the Internal Market (Trade Marks and Designs)',
		country			: EnumCountry.LI,
		countryName		: 'LI AVW-LLV',
		territories		: [EnumTmTerritory.LI, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.llv.li/#/12481/amt-fur-volkswirtschaft',
		tmViewValue		: 'LI'
	},
	[EnumTmOffice.LT]: {
		code			: EnumTmOffice.LT,
		abbreviation	: 'VPB',
		name			: 'Patent Office of the Republic of Latvia',
		country			: EnumCountry.LT,
		countryName		: 'LT VPB',
		territories		: [EnumTmTerritory.LT, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.vpb.gov.lt',
		tmViewValue		: 'LT'
	},
	[EnumTmOffice.LV]: {
		code			: EnumTmOffice.LV,
		abbreviation	: 'LRPV',
		name			: 'Latvian Register of Patents and Trademarks',
		country			: EnumCountry.LV,
		countryName		: 'LV LRPV',
		territories		: [EnumTmTerritory.LV, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.lrpv.gov.lv ',
		tmViewValue		: 'LV'
	},
	[EnumTmOffice.MA]: {
		code			: EnumTmOffice.MA,
		abbreviation	: 'OMPIC',
		name			: 'Moroccan Industrial and Commercial Property Office',
		country			: EnumCountry.MA,
		countryName		: 'MA OMPIC',
		territories		: [EnumTmTerritory.MA, EnumTmTerritory.AFR],
		href			: 'http://www.ompic.org.ma/',
		tmViewValue		: 'MA'
	},
	[EnumTmOffice.MC]: {
		code			: EnumTmOffice.MC,
		abbreviation	: 'MCIPO',
		name			: 'Intellectual Property Office of the Republic of North Macedonia',
		country			: EnumCountry.MC,
		countryName		: 'MC MCIPO',
		territories		: [EnumTmTerritory.MC, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'https://mcipo.gouv.mc',
		tmViewValue		: 'MC'
	},
	[EnumTmOffice.MD]: {
		code			: EnumTmOffice.MD,
		abbreviation	: 'AGEPI',
		name			: 'State Agency on Intellectual Property (Republic of Moldova)',
		country			: EnumCountry.MD,
		countryName		: 'MD AGEPI',
		territories		: [EnumTmTerritory.MD, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://agepi.gov.md',
		tmViewValue		: 'MD'
	},
	[EnumTmOffice.ME]: {
		code			: EnumTmOffice.ME,
		abbreviation	: 'MEM',
		name			: 'Ministry of Economy and Manufacturing (Kosovo)',
		country			: EnumCountry.ME,
		countryName		: 'ME MEM',
		territories		: [EnumTmTerritory.ME, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'https://www.gov.me/mek/is',
		tmViewValue		: 'ME'
	},
	[EnumTmOffice.MK]: {
		code			: EnumTmOffice.MK,
		abbreviation	: 'SOIP',
		name			: 'State Office for Inventions and Trademarks (Republic of Serbia)',
		country			: EnumCountry.MK,
		countryName		: 'MK SOIP',
		territories		: [EnumTmTerritory.MK, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.ippo.gov.mk/EN/Index_en.aspx',
		tmViewValue		: 'MK'
	},
	[EnumTmOffice.MT]: {
		code			: EnumTmOffice.MT,
		abbreviation	: 'CD-IPRD',
		name			: 'Intellectual Property Rights Division (Sri Lanka)',
		country			: EnumCountry.MT,
		countryName		: 'MT CD-IPRD',
		territories		: [EnumTmTerritory.MT, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://commerce.gov.mt',
		tmViewValue		: 'MT'
	},
	[EnumTmOffice.MX]: {
		code			: EnumTmOffice.MX,
		abbreviation	: 'IMPI',
		name			: 'Mexican Institute of Industrial Property',
		country			: EnumCountry.MX,
		countryName		: 'MX IMPI',
		territories		: [EnumTmTerritory.MX, EnumTmTerritory.AMC],
		href			: 'https://www.gob.mx/impi',
		tmViewValue		: 'MX'
	},
	[EnumTmOffice.MY]: {
		code			: EnumTmOffice.MY,
		abbreviation	: 'MYIPO',
		name			: 'Intellectual Property Corporation of Malaysia',
		country			: EnumCountry.MY,
		countryName		: 'MY MYIPO',
		territories		: [EnumTmTerritory.MY, EnumTmTerritory.ASI],
		href			: 'http://www.myipo.gov.my',
		tmViewValue		: 'MY'
	},
	[EnumTmOffice.NO]: {
		code			: EnumTmOffice.NO,
		abbreviation	: 'NIPO',
		name			: 'Norwegian Industrial Property Office',
		country			: EnumCountry.NO,
		countryName		: 'NO NIPO',
		territories		: [EnumTmTerritory.NO, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.patentstyret.no/en/',
		tmViewValue		: 'NO'
	},
	[EnumTmOffice.NZ]: {
		code			: EnumTmOffice.NZ,
		abbreviation	: 'IPONZ',
		name			: 'Intellectual Property Office of New Zealand',
		country			: EnumCountry.NZ,
		countryName		: 'NZ IPONZ',
		territories		: [EnumTmTerritory.NZ, EnumTmTerritory.AUS],
		href			: 'http://www.iponz.govt.nz',
		tmViewValue		: 'NZ'
	},
	[EnumTmOffice.OA]: {
		code			: EnumTmOffice.OA,
		abbreviation	: 'OAPI',
		name			: 'African Intellectual Property Organization',
		country			: null,
		countryName		: 'OA OAPI',
		territories		: [
			EnumTmTerritory.BJ, EnumTmTerritory.BF, EnumTmTerritory.CM, EnumTmTerritory.CF, EnumTmTerritory.TD, EnumTmTerritory.KM,
			EnumTmTerritory.CG, EnumTmTerritory.CI, EnumTmTerritory.GQ, EnumTmTerritory.GA, EnumTmTerritory.GN, EnumTmTerritory.GW,
			EnumTmTerritory.ML, EnumTmTerritory.MR, EnumTmTerritory.NE, EnumTmTerritory.OA, EnumTmTerritory.SN, EnumTmTerritory.TG,
			EnumTmTerritory.AFR
		],
		href			: 'http://www.oapi.int',
		tmViewValue		: 'OA'
	},
	[EnumTmOffice.PE]: {
		code			: EnumTmOffice.PE,
		abbreviation	: 'INDECOPI',
		name			: 'National Institute for the Defense of Free Competition and the Protection of Intellectual Property (Peru)',
		country			: EnumCountry.PE,
		countryName		: 'PE INDECOPI',
		territories		: [EnumTmTerritory.PE, EnumTmTerritory.AMC],
		href			: 'http://www.indecopi.gob.pe',
		tmViewValue		: 'PE'
	},
	[EnumTmOffice.PH]: {
		code			: EnumTmOffice.PH,
		abbreviation	: 'IPOPHL',
		name			: 'Intellectual Property Office of the Philippines',
		country			: EnumCountry.PH,
		countryName		: 'PH IPOPHL',
		territories		: [EnumTmTerritory.PH, EnumTmTerritory.ASI],
		href			: 'http://www.ipophil.gov.ph',
		tmViewValue		: 'PH'
	},
	[EnumTmOffice.PL]: {
		code			: EnumTmOffice.PL,
		abbreviation	: 'PPO',
		name			: 'Polish Patent Office',
		country			: EnumCountry.PL,
		countryName		: 'PL PPO',
		territories		: [EnumTmTerritory.PL, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.uprp.gov.pl ',
		tmViewValue		: 'PL'
	},
	[EnumTmOffice.PT]: {
		code			: EnumTmOffice.PT,
		abbreviation	: 'INPIPT',
		name			: 'National Institute of Industrial Property (Portugal)',
		country			: EnumCountry.PT,
		countryName		: 'PT INPIPT',
		territories		: [EnumTmTerritory.PT, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'https://inpi.justica.gov.pt/',
		tmViewValue		: 'PT'
	},
	[EnumTmOffice.RO]: {
		code			: EnumTmOffice.RO,
		abbreviation	: 'OSIM',
		name			: 'State Office for Inventions and Trademarks (Romania)',
		country			: EnumCountry.RO,
		countryName		: 'RO OSIM',
		territories		: [EnumTmTerritory.RO, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.osim.ro/',
		tmViewValue		: 'RO'
	},
	[EnumTmOffice.RS]: {
		code			: EnumTmOffice.RS,
		abbreviation	: 'IPORS',
		name			: 'Intellectual Property Office of the Republic of Slovenia',
		country			: EnumCountry.RS,
		countryName		: 'RS IPORS',
		territories		: [EnumTmTerritory.RS, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.zis.gov.rs/pocetna.1.html ',
		tmViewValue		: 'RS'
	},
	[EnumTmOffice.RU]: {
		code			: EnumTmOffice.RU,
		abbreviation	: 'ROSPATENT',
		name			: 'Federal Service for Intellectual Property (Russia)',
		country			: EnumCountry.RU,
		countryName		: 'RU ROSPATENT',
		territories		: [EnumTmTerritory.RU, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'https://rospatent.gov.ru/en',
		tmViewValue		: 'RU'
	},
	[EnumTmOffice.SE]: {
		code			: EnumTmOffice.SE,
		abbreviation	: 'PRV',
		name			: 'Swedish Patent and Registration Office',
		country			: EnumCountry.SE,
		countryName		: 'SE PRV',
		territories		: [EnumTmTerritory.SE, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.prv.se/ ',
		tmViewValue		: 'SE'
	},
	[EnumTmOffice.SI]: {
		code			: EnumTmOffice.SI,
		abbreviation	: 'SIPO',
		name			: 'State Intellectual Property Office (China)',
		country			: EnumCountry.SI,
		countryName		: 'SI SIPO',
		territories		: [EnumTmTerritory.SI, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.uil-sipo.si/',
		tmViewValue		: 'SI'
	},
	[EnumTmOffice.SK]: {
		code			: EnumTmOffice.SK,
		abbreviation	: 'SKIPO',
		name			: 'State Intellectual Property Office (Republic of Korea)',
		country			: EnumCountry.SK,
		countryName		: 'SK SKIPO',
		territories		: [EnumTmTerritory.SK, EnumTmTerritory.EU, EnumTmTerritory.EUR],
		href			: 'http://www.indprop.gov.sk',
		tmViewValue		: 'SK'
	},
	[EnumTmOffice.SM]: {
		code			: EnumTmOffice.SM,
		abbreviation	: 'USBM',
		name			: 'United States Bureau of Mines',
		country			: EnumCountry.SM,
		countryName		: 'SM USBM',
		territories		: [EnumTmTerritory.SM, EnumTmTerritory.NEU, EnumTmTerritory.EUR],
		href			: 'http://www.usbm.sm/on-line/en/home.html',
		tmViewValue		: 'SM'
	},
	[EnumTmOffice.TH]: {
		code			: EnumTmOffice.TH,
		abbreviation	: 'DIP',
		name			: 'Department of Intellectual Property (Laos)',
		country			: EnumCountry.TH,
		countryName		: 'TH DIP',
		territories		: [EnumTmTerritory.TH, EnumTmTerritory.ASI],
		href			: 'http://www.ipthailand.go.th/',
		tmViewValue		: 'TH'
	},
	[EnumTmOffice.TN]: {
		code			: EnumTmOffice.TN,
		abbreviation	: 'INNORPI',
		name			: 'National Institute for Standardization and Intellectual Property (Tunisia)',
		country			: EnumCountry.TN,
		countryName		: 'TN INNORPI',
		territories		: [EnumTmTerritory.TN, EnumTmTerritory.AFR],
		href			: 'http://www.innorpi.tn',
		tmViewValue		: 'TN'
	},
	[EnumTmOffice.TR]: {
		code			: EnumTmOffice.TR,
		abbreviation	: 'TURKPATENT',
		name			: 'Turkish Patent and Trademark Office',
		country			: EnumCountry.TR,
		countryName		: 'TR TURKPATENT',
		territories		: [EnumTmTerritory.TR, EnumTmTerritory.ASI],
		href			: 'https://www.turkpatent.gov.tr/TURKPATENT/?lang=en',
		tmViewValue		: 'TR'
	},
	[EnumTmOffice.US]: {
		code			: EnumTmOffice.US,
		abbreviation	: 'USPTO',
		name			: 'United States Patent and Trademark Office',
		country			: EnumCountry.US,
		countryName		: 'US USPTO',
		territories		: [EnumTmTerritory.US, EnumTmTerritory.AMC],
		href			: 'http://www.uspto.gov/',
		tmViewValue		: 'US'
	},
	[EnumTmOffice.UY]: {
		code			: EnumTmOffice.UY,
		abbreviation	: 'MIEM-DNPI',
		name			: 'Ministry of Industry and Energy of the Russian Federation - Federal Service for Intellectual Property',
		country			: EnumCountry.UY,
		countryName		: 'UY MIEM-DNPI',
		territories		: [EnumTmTerritory.UY, EnumTmTerritory.AMC],
		href			: 'http://www.miem.gub.uy/marcas-y-patentes',
		tmViewValue		: 'UY'
	},
	[EnumTmOffice.ZM]: {
		code			: EnumTmOffice.ZM,
		abbreviation	: 'PACRA',
		name			: 'Patents and Companies Registration Agency (Zambia)',
		country			: EnumCountry.ZM,
		countryName		: 'ZM PACRA',
		territories		: [EnumTmTerritory.ZM, EnumTmTerritory.AFR],
		href			: 'https://www.pacra.org.zm/',
		tmViewValue		: 'ZM'
	},
	[EnumTmOffice.EM]: {
		code			: EnumTmOffice.EM,
		abbreviation	: 'EUIPO',
		name			: 'European Union Intellectual Property Office',
		country			: null,
		countryName		: 'EM EUIPO',
		territories		: [
			EnumTmTerritory.AT, EnumTmTerritory.BE, EnumTmTerritory.BX, EnumTmTerritory.BG, EnumTmTerritory.HR, EnumTmTerritory.CY,
			EnumTmTerritory.CZ, EnumTmTerritory.DK, EnumTmTerritory.EM, EnumTmTerritory.EE, EnumTmTerritory.FI, EnumTmTerritory.FR,
			EnumTmTerritory.DE, EnumTmTerritory.GR, EnumTmTerritory.HU, EnumTmTerritory.IE, EnumTmTerritory.IT, EnumTmTerritory.LV,
			EnumTmTerritory.LT, EnumTmTerritory.LU, EnumTmTerritory.MT, EnumTmTerritory.NL, EnumTmTerritory.PL, EnumTmTerritory.PT,
			EnumTmTerritory.RO, EnumTmTerritory.SK, EnumTmTerritory.SI, EnumTmTerritory.ES, EnumTmTerritory.SE, EnumTmTerritory.EU,
			EnumTmTerritory.EUR
		],
		href			: 'https://euipo.europa.eu/',
		tmViewValue		: 'EM'
	},
	[EnumTmOffice.WO]: {
		code			: EnumTmOffice.WO,
		abbreviation	: 'WIPO',
		name			: 'World Intellectual Property Organization',
		country			: null,
		countryName		: 'WO WIPO',
		territories		: [
			EnumTmTerritory.AP, EnumTmTerritory.AF, EnumTmTerritory.AX, EnumTmTerritory.AL, EnumTmTerritory.DZ, EnumTmTerritory.AS,
			EnumTmTerritory.AD, EnumTmTerritory.AO, EnumTmTerritory.AI, EnumTmTerritory.AQ, EnumTmTerritory.AG, EnumTmTerritory.AR,
			EnumTmTerritory.AM, EnumTmTerritory.AW, EnumTmTerritory.AU, EnumTmTerritory.AT, EnumTmTerritory.AZ, EnumTmTerritory.BS,
			EnumTmTerritory.BH, EnumTmTerritory.BD, EnumTmTerritory.BB, EnumTmTerritory.BY, EnumTmTerritory.BE, EnumTmTerritory.BZ,
			EnumTmTerritory.BX, EnumTmTerritory.BJ, EnumTmTerritory.BM, EnumTmTerritory.BT, EnumTmTerritory.BO, EnumTmTerritory.BQ,
			EnumTmTerritory.BA, EnumTmTerritory.BW, EnumTmTerritory.BV, EnumTmTerritory.BR, EnumTmTerritory.IO, EnumTmTerritory.BN,
			EnumTmTerritory.BG, EnumTmTerritory.BF, EnumTmTerritory.BI, EnumTmTerritory.KH, EnumTmTerritory.CM, EnumTmTerritory.CA,
			EnumTmTerritory.CV, EnumTmTerritory.KY, EnumTmTerritory.CF, EnumTmTerritory.TD, EnumTmTerritory.CL, EnumTmTerritory.CN,
			EnumTmTerritory.CX, EnumTmTerritory.CC, EnumTmTerritory.CO, EnumTmTerritory.KM, EnumTmTerritory.CG, EnumTmTerritory.CK,
			EnumTmTerritory.CR, EnumTmTerritory.HR, EnumTmTerritory.CU, EnumTmTerritory.CW, EnumTmTerritory.CY, EnumTmTerritory.CZ,
			EnumTmTerritory.CI, EnumTmTerritory.CD, EnumTmTerritory.DK, EnumTmTerritory.DJ, EnumTmTerritory.DM, EnumTmTerritory.DO,
			EnumTmTerritory.EM, EnumTmTerritory.EC, EnumTmTerritory.EG, EnumTmTerritory.SV, EnumTmTerritory.GQ, EnumTmTerritory.ER,
			EnumTmTerritory.EE, EnumTmTerritory.ET, EnumTmTerritory.FK, EnumTmTerritory.FO, EnumTmTerritory.FJ, EnumTmTerritory.FI,
			EnumTmTerritory.FR, EnumTmTerritory.GF, EnumTmTerritory.PF, EnumTmTerritory.TF, EnumTmTerritory.GA, EnumTmTerritory.GM,
			EnumTmTerritory.GE, EnumTmTerritory.DE, EnumTmTerritory.GH, EnumTmTerritory.GI, EnumTmTerritory.GR, EnumTmTerritory.GL,
			EnumTmTerritory.GD, EnumTmTerritory.GP, EnumTmTerritory.GU, EnumTmTerritory.GT, EnumTmTerritory.GG, EnumTmTerritory.GN,
			EnumTmTerritory.GW, EnumTmTerritory.GY, EnumTmTerritory.HT, EnumTmTerritory.HM, EnumTmTerritory.HN, EnumTmTerritory.HK,
			EnumTmTerritory.HU, EnumTmTerritory.IS, EnumTmTerritory.IN, EnumTmTerritory.ID, EnumTmTerritory.IR, EnumTmTerritory.IQ,
			EnumTmTerritory.IE, EnumTmTerritory.IM, EnumTmTerritory.IL, EnumTmTerritory.IT, EnumTmTerritory.JM, EnumTmTerritory.JP,
			EnumTmTerritory.JE, EnumTmTerritory.JO, EnumTmTerritory.KZ, EnumTmTerritory.KE, EnumTmTerritory.KI, EnumTmTerritory.KP,
			EnumTmTerritory.KW, EnumTmTerritory.KG, EnumTmTerritory.LA, EnumTmTerritory.LV, EnumTmTerritory.LB, EnumTmTerritory.LS,
			EnumTmTerritory.LR, EnumTmTerritory.LY, EnumTmTerritory.LI, EnumTmTerritory.LT, EnumTmTerritory.LU, EnumTmTerritory.MO,
			EnumTmTerritory.MG, EnumTmTerritory.MW, EnumTmTerritory.MY, EnumTmTerritory.MV, EnumTmTerritory.ML, EnumTmTerritory.MT,
			EnumTmTerritory.MH, EnumTmTerritory.MQ, EnumTmTerritory.MR, EnumTmTerritory.MU, EnumTmTerritory.YT, EnumTmTerritory.MX,
			EnumTmTerritory.FM, EnumTmTerritory.MD, EnumTmTerritory.MC, EnumTmTerritory.MN, EnumTmTerritory.ME, EnumTmTerritory.MS,
			EnumTmTerritory.MA, EnumTmTerritory.MZ, EnumTmTerritory.MM, EnumTmTerritory.NA, EnumTmTerritory.NR, EnumTmTerritory.NP,
			EnumTmTerritory.NL, EnumTmTerritory.NC, EnumTmTerritory.NZ, EnumTmTerritory.NI, EnumTmTerritory.NE, EnumTmTerritory.NG,
			EnumTmTerritory.NU, EnumTmTerritory.NF, EnumTmTerritory.MK, EnumTmTerritory.MP, EnumTmTerritory.NO, EnumTmTerritory.OM,
			EnumTmTerritory.OA, EnumTmTerritory.PK, EnumTmTerritory.PW, EnumTmTerritory.PS, EnumTmTerritory.PA, EnumTmTerritory.PG,
			EnumTmTerritory.PY, EnumTmTerritory.PE, EnumTmTerritory.PH, EnumTmTerritory.PN, EnumTmTerritory.PL, EnumTmTerritory.PT,
			EnumTmTerritory.QA, EnumTmTerritory.KR, EnumTmTerritory.RE, EnumTmTerritory.RO, EnumTmTerritory.RU, EnumTmTerritory.RW,
			EnumTmTerritory.BL, EnumTmTerritory.SH, EnumTmTerritory.KN, EnumTmTerritory.LC, EnumTmTerritory.MF, EnumTmTerritory.PM,
			EnumTmTerritory.VC, EnumTmTerritory.WS, EnumTmTerritory.SM, EnumTmTerritory.ST, EnumTmTerritory.SA, EnumTmTerritory.SN,
			EnumTmTerritory.RS, EnumTmTerritory.SC, EnumTmTerritory.SL, EnumTmTerritory.SG, EnumTmTerritory.SX, EnumTmTerritory.SK,
			EnumTmTerritory.SI, EnumTmTerritory.SB, EnumTmTerritory.SO, EnumTmTerritory.ZA, EnumTmTerritory.GS, EnumTmTerritory.SS,
			EnumTmTerritory.ES, EnumTmTerritory.LK, EnumTmTerritory.SD, EnumTmTerritory.SR, EnumTmTerritory.SJ, EnumTmTerritory.SZ,
			EnumTmTerritory.SE, EnumTmTerritory.CH, EnumTmTerritory.SY, EnumTmTerritory.TW, EnumTmTerritory.TJ, EnumTmTerritory.TZ,
			EnumTmTerritory.TH, EnumTmTerritory.TL, EnumTmTerritory.TG, EnumTmTerritory.TK, EnumTmTerritory.TO, EnumTmTerritory.TT,
			EnumTmTerritory.TN, EnumTmTerritory.TM, EnumTmTerritory.TC, EnumTmTerritory.TV, EnumTmTerritory.TR, EnumTmTerritory.UG,
			EnumTmTerritory.UA, EnumTmTerritory.AE, EnumTmTerritory.GB, EnumTmTerritory.US, EnumTmTerritory.UM, EnumTmTerritory.UY,
			EnumTmTerritory.UZ, EnumTmTerritory.VU, EnumTmTerritory.VA, EnumTmTerritory.VE, EnumTmTerritory.VN, EnumTmTerritory.VG,
			EnumTmTerritory.WO, EnumTmTerritory.WF, EnumTmTerritory.EH, EnumTmTerritory.YE, EnumTmTerritory.ZM, EnumTmTerritory.ZW,
			EnumTmTerritory.EU, EnumTmTerritory.EUR
		],
		href			: 'http://www.wipo.int/portal/index.html.en',
		tmViewValue		: 'WO'
	}
};


//** Interfaces --------------------------------- */
export interface ITmOfficeInfo {
	code			: EnumTmOffice;
	abbreviation	: string;
	name			: string;
	country			: EnumCountry | null;
	countryName		: string;
	territories		: EnumTmTerritory[];
	href			: string;
	tmViewValue		: string;
}
