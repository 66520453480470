import { IKdpCoverImage } from './kdp-cover-image.interface';
import { IKdpListing } from './kdp-listing.interface';
import { IKdpManuscript } from './kdp-manuscript.interface';
import { IKdpUploads } from './kdp-uploads.interface';


/**------------------------------------------------------
 * KDP Book Structure
 */
export interface IKdpBook {
	image			: IKdpCoverImage;
	manuscript		: IKdpManuscript;
	listing 	 	: IKdpListing;
	uploads 	 	: IKdpUploads;
}
