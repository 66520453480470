import { KeywordExtractor } from './keyword-extractor';
import { StopWordFilter } from '../stop-word-filter/stop-word-filter';
import { TextSegmenterFactory } from '../text-segmenter';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class KeywordExtractorFactory {

	static create(): KeywordExtractor {
		return new KeywordExtractor(
			new StopWordFilter(),
			TextSegmenterFactory.create()
		);
	}
}
