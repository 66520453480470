/**------------------------------------------------------
 * Response
 */
export interface IFlyingDesignsFingerprintsResponse {
	results			: IFlyingDesignImageHash[];
	total			: number;
}

export interface IFlyingDesignImageHash {
	flyId			: string;
	pHash			: string;
}

export interface IFlyingDesignsInfoResponse {
	totalDesigns	: number;				// total designs a user has in the DB
	totalStorage	: number;				// total storage a user has based on his subscription
	usedStorage		: number;				// total storage a user has filled with his designs currently
	availableStorage: number;				// available storage = total - used
}
