import { Util } from '@libs/utilities/util';

import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';


export class ValidatorValueFunction extends AbstractValidatorValue<ValidatorValueFunction> {

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'function'; }
	protected isValueTypeValid(value: any): boolean { return Util.Basic.isFunction(value); }
}
