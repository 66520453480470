import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgAppInjectorService } from './app-injector/app-injector.service';


@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		NgAppInjectorService
	]
})
export class NgAngularHelpersModule { }
