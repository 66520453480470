/* cspell:ignore blankrows, defval */
import { read, Sheet2JSONOpts, utils as xlsxUtils, WorkBook, WorkSheet } from 'xlsx';
import { Util } from '@libs/utilities/util';

import { ExcelReaderHelper, IImportedSheetInfo } from '../excel-reader.helper';
import { IImportExcelData, IImportSheetData } from '../excel-import.interface';


export class ExcelSheetReaderHelper {

	//** Configurations */
	private readonly WORKSHEET_JSON_OPTIONS: Sheet2JSONOpts = {
		header		: 'A',
		raw			: true,
		blankrows	: true,
		defval		: null,
		rawNumbers	: true
	};

	constructor(
		private excelReaderHelper: ExcelReaderHelper
	) {}


	/**------------------------------------------------------
	 * Import Excel File
	 */
	async importExcelFile(file: File): Promise<IImportExcelData | null> {

		//0 - try to read the sheet data
		if (!this.excelReaderHelper.isAcceptedExcelType(file)) return null;

		//1 - try to read the sheet data
		const fileBuffer: ArrayBuffer | null = await this.excelReaderHelper.readFile(file);
		if (!fileBuffer) return null;

		//2 - read the spreadsheet data with library
		const importSheetData: IImportSheetData[] | null = this.bufferToExcelData(fileBuffer);
		if (!importSheetData) return null;

		//3 - create the excel sheet data
		const excelData: IImportExcelData = {
			sheets: importSheetData,
			metaInfo: {
				file: this.excelReaderHelper.getFileInfo(file)
			}
		};

		//4 - return the excel formatted data
		return excelData;
	}


	/**------------------------------------------------------
	 * Import Excel Sheet (Get sheet info from buffer)
	 */
	private bufferToExcelData(fileBuffer: ArrayBuffer): IImportSheetData[] | null {

		try {
			//0 - initialize sheet and importSheetsData
			const importSheetsData: IImportSheetData[] 	= [];
			const workBook		  : WorkBook 			= read(fileBuffer);

			//1 - grab sheet
			const sheetNames: string[] = workBook.SheetNames;
			for (const sheetName of sheetNames) {

				//a. get worksheet and json object
				const workSheet: WorkSheet = workBook.Sheets[sheetName];

				//b. get sheet json info and get imported sheet info
				const workSheetData		: object[] 			 = xlsxUtils.sheet_to_json(workSheet, this.WORKSHEET_JSON_OPTIONS);
				const importedSheetInfo	: IImportedSheetInfo = this.excelReaderHelper.importedSheetInfo(workSheetData);

				//c. get imported sheet data object
				const importSheetData: IImportSheetData = {
					rows	: importedSheetInfo.rows,
					metaInfo	: {
						sheet	: {
							sheetName	 : sheetName,
							columnCount  : importedSheetInfo.columnCount,
							rowCount	 : importedSheetInfo.rows.length
						}
					}
				};

				//d. update sheets data
				importSheetsData.push(importSheetData);
			}

			//2 - return the result
			return importSheetsData;

		} catch (error: unknown) {
			console.error(`ExcelSheetReaderHelper => readBuffer => ERROR: ${Util.Basic.stringifyObject(error)}`);
			return null;
		}
	}
}
