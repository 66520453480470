import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { FlyingDesignImageSchema, FlyingDesignListingSchema, FlyingDesignSchema, FlyingDesignUploadSchema, EnumFlyingDesignImageTypes, IFlyingDesignImage, IFlyingDesignListing, IFlyingDesignUpload } from '../../../interfaces';


/**------------------------------------------------------
 * Constants
 */
export const FLYING_DESIGN_MANAGEMENT_IMPORT_CONFIG: IFlyingDesignManagementImportConfig = {
	MAX_DESIGNS_IMPORT		: 500,
	MAX_DUPLICATE_DESIGNS	: 500
};

interface IFlyingDesignManagementImportConfig {
	MAX_DESIGNS_IMPORT		: number;
	MAX_DUPLICATE_DESIGNS	: number;
}


/**------------------------------------------------------
 * Request
 */
export interface IFlyingImportDesignsRequest {
	designs		: IFlyingImportDesignsData[];
}

export interface IFlyingImportDesignsData {
	identifier	: string;							// used in the frontend to know which singed url belongs to what image
	image		: IFlyingDesignImage;
	listing		: IFlyingDesignListing;
	uploads		: IFlyingDesignUpload[];
}

export interface IFlyingActivateDesignsRequest {
	flyIds		: string[];
}

export interface IFlyingDuplicateDesignsRequest {
	flyIds		: string[];
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingImportDesignResponse {
	signedUrls	: IFlyingDesignImageSignedUrls[];
}

export interface IFlyingDesignImageSignedUrls {
	identifier	: string;
	[EnumFlyingDesignImageTypes.Original] : string;		// signed urls
	[EnumFlyingDesignImageTypes.Preview] 	: string;
	[EnumFlyingDesignImageTypes.Icon] 	: string;
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingImportDesignsRequestSchema implements ValidatorSchema<IFlyingImportDesignsRequest> {
	designs = Validator.Array().inspectEach<IFlyingImportDesignsData>({
		identifier	: Validator.String().isNotEmpty(),
		image 		: Validator.Object().inspect<IFlyingDesignImage>(FlyingDesignImageSchema),
		listing 	: Validator.Object().inspect<IFlyingDesignListing>(FlyingDesignListingSchema),
		uploads 	: Validator.Array().inspectEach<IFlyingDesignUpload>(FlyingDesignUploadSchema)
	}).isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_IMPORT_CONFIG.MAX_DESIGNS_IMPORT);
}

export class FlyingActivateDesignsRequestSchema implements ValidatorSchema<IFlyingActivateDesignsRequest> {
	flyIds 	= Validator.Array().each(new FlyingDesignSchema().flyId).isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_IMPORT_CONFIG.MAX_DESIGNS_IMPORT);
}

export class FlyingDuplicateDesignsRequestSchema implements ValidatorSchema<IFlyingDuplicateDesignsRequest> {
	flyIds 	= Validator.Array().each(new FlyingDesignSchema().flyId).isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_IMPORT_CONFIG.MAX_DUPLICATE_DESIGNS);
}
