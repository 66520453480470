import { Socket, io } from 'socket.io-client';
import { Util } from '@libs/utilities/util';
import { ISecureSocketSecurityOptions } from '@domains/security/shared';

import { SecureWebSocketWrapper } from './websocket.wrapper';
import { SecureWebSocketApiWrapper } from './websocket-api.wrapper';
import { ISecureSocketConfig } from '../../secure-socket.config';
import { AbstractSecureRequest } from '../../../shared/request.abstract';
import { SecureTimeTokenService } from '../../../services/time-token.service';
import { SecureEncryptionService } from '../../../services/encryption.service';


export class SecureWebSocketService extends AbstractSecureRequest<SecureWebSocketService> {

	constructor(
		protected config					: ISecureSocketConfig,
		protected override endpoint			: string,
		protected override timeTokenService	: SecureTimeTokenService,
		protected override encryptionService: SecureEncryptionService
	) {
		super(config.SOCKET_URL, endpoint, timeTokenService, encryptionService);
	}

	setSecurity(options: Partial<ISecureSocketSecurityOptions>): SecureWebSocketService {

		//0 - check options
		if (Util.Basic.isUndefined(options)) throw new Error(`WebSocketService => setSecure => FATAL ERROR: security options are undefined (value: ${options})`);

		//1 - set security options, by assigning the custom one to the default ones
		this.securityOptions = Util.Function.assignOptions(this.config.DEFAULT_SOCKET_SECURITY, options);

		//2 - return this
		return this;
	}

	asSocket(): SecureWebSocketWrapper {

		//0 - prepare headers with security options and create socket instance
		const endpointUrl	: string = this.prepareEndpointUrl();
		const socketInstance: Socket = io(endpointUrl, {
			extraHeaders: this.prepareHeaders()
		});

		//1 - create the socket wrapper
		return new SecureWebSocketWrapper(socketInstance, this.encryptionService, this.securityOptions);
	}

	asApi(): SecureWebSocketApiWrapper {
		const socketWrapper: SecureWebSocketWrapper = this.asSocket();
		return new SecureWebSocketApiWrapper(socketWrapper);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	protected getSecureDefault(): ISecureSocketSecurityOptions {
		return this.config.DEFAULT_SOCKET_SECURITY;
	}
}
