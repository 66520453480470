import { EnumBrowser, IBrowserInfo } from './browser.interface';


/**------------------------------------------------------
 * Browser Names
 */
export const BROWSER_INFO: Record<EnumBrowser, IBrowserInfo>  = {
	[EnumBrowser.MicrosoftEdge]: {
		code	: EnumBrowser.MicrosoftEdge,
		name	: 'Microsoft Edge',
		icon	: 'edge'
	},
	[EnumBrowser.Opera]: {
		code	: EnumBrowser.Opera,
		name	: 'Opera',
		icon	: 'opera'
	},
	[EnumBrowser.GoogleChrome]: {
		code	: EnumBrowser.GoogleChrome,
		name	: 'Google Chrome',
		icon	: 'chrome'
	},
	[EnumBrowser.InternetExplorer]: {
		code	: EnumBrowser.InternetExplorer,
		name	: 'Internet Explorer',
		icon	: 'internet-explorer'
	},
	[EnumBrowser.MozillaFirefox] : {
		code	: EnumBrowser.MozillaFirefox,
		name	: 'Mozilla Firefox',
		icon	: 'firefox'
	},
	[EnumBrowser.Safari]: {
		code	: EnumBrowser.Safari,
		name	: 'Safari',
		icon	: 'safari'
	},
	[EnumBrowser.Brave]: {
		code	: EnumBrowser.Brave,
		name	: 'Brave',
		icon	: 'brave'
	},
	[EnumBrowser.Other]: {
		code	: EnumBrowser.Other,
		name	: 'Other',
		icon	: 'internet-explorer'
	}
};
