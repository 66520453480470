/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumSecureSocketDisconnect {
	ServerDisconnect	= 'io server disconnect',	// names are defined by socket.io, do not change them!
	ClientDisconnect	= 'io client disconnect',
	PingTimeout			= 'ping timeout',
	ConnectionLost		= 'transport close',
	ConnectionError		= 'transport error',
	ParseError			= 'parse error'
}

export enum EnumSecureSocketError {
	Error 			 	= 'error',
	ConnectError	 	= 'connect_error',
	EventEmitTimeout	= 'event_emit_timeout'
}

export enum EnumSecureSocketReconnect {
	Reconnect 			= 'reconnect',
	ReconnectAttempt 	= 'reconnect_attempt',
	ReconnectFailed  	= 'reconnect_failed',
	ReconnectError 	 	= 'reconnect_error'
}

export enum EnumSecureSocketEvent {
	Connect 		 	= 'connect',					// events names are defined by socket.io, do not change them!
	Disconnect 		 	= 'disconnect',
	Error 			 	= 'error',
	ConnectError	 	= 'connect_error',
	Ping 			 	= 'ping'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface ISecureEmitEventData<T> {
	event 	: string;
	data  	: T;
}

export interface ISecureEmitEventLog {
	event 	: string;
	data  	: unknown | object | string | number | boolean;
	date	: Date;
}

export interface ISecureSocketError {
	reason 	: EnumSecureSocketError;
	error	: unknown;
}
