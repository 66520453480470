import { EnumAddition, EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_BLACKLIST_LIMITS: IFlyingBlacklistConfig = {
	WORD_LENGTH		: 120,
	NOTES_LENGTH	: 400,
	MAX_LIST_SIZE	: 10000
};

interface IFlyingBlacklistConfig {
	WORD_LENGTH		: number;
	NOTES_LENGTH	: number;
	MAX_LIST_SIZE	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingBlacklistLanguage {
	All 	= EnumAddition.All,
	EN 		= EnumLanguage.EN,
	DE 		= EnumLanguage.DE,
	FR 		= EnumLanguage.FR,
	ES 		= EnumLanguage.ES,
	IT 		= EnumLanguage.IT,
	JA 		= EnumLanguage.JA
}


/**------------------------------------------------------
 * TM Blacklist Data Structures
 */
export interface IFlyingTmBlacklist {
	entries		: IFlyingTmBlacklistEntry[];		// is required and all elements have to be a valid 'ITmBlacklistEntry', the array can also be an empty array
}

export interface IFlyingTmBlacklistEntry {
	word		: string;							// should be a valid string and not empty
	note		: string;							// can be empty but has to be a type string
	language	: EnumFlyingBlacklistLanguage;		// has to be valid enum of "EnumBlacklistLanguage"
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingTmBlacklistSchema implements ValidatorSchema<IFlyingTmBlacklist> {
	entries		= Validator.Array().isMaxSize(FLYING_BLACKLIST_LIMITS.MAX_LIST_SIZE)
					.inspectEach<IFlyingTmBlacklistEntry>(FlyingTmBlacklistEntrySchema);
}

export class FlyingTmBlacklistEntrySchema implements ValidatorSchema<IFlyingTmBlacklistEntry> {
	word		= Validator.String().isNotEmpty().isMaxLength(FLYING_BLACKLIST_LIMITS.WORD_LENGTH);
	note		= Validator.String().isMaxLength(FLYING_BLACKLIST_LIMITS.NOTES_LENGTH);
	language	= Validator.Enum(EnumFlyingBlacklistLanguage);
}
