/**------------------------------------------------------
 * Interface Definitions
 */
export interface IResolution {
	width		: number;
	height		: number;
}

export interface IScreenInfo {
	width		: number;
	height		: number;
	availWidth	: number;
	availHeight	: number;
	colorDepth	: number;
	pixelDepth	: number;
}
