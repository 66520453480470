import { UtilArray } from './util-array';


/**------------------------------------------------------
 * Interface Utilities
 * -------------------
 * > Info: Containing all functionalities for working with
 * > interfaces and types.
 */
export class UtilInterface {

	constructor(
		private utilArray: UtilArray
	) {}


	/**------------------------------------------------------
	 * Helpers
	 */
	keys<T extends object>(interfaceObject: T): string[] {
		return Object.keys(interfaceObject);
	}


	/**------------------------------------------------------
	 * Interface/Object Validations
	 */
	checkInterface<T>(object: T | any, interfaceObject: T): boolean {
		for (const key in interfaceObject) {

			//0 - check the property
			if (!Object.hasOwn(object, key)) return false;

			//1 - check the property types
			if (typeof object[key] !== typeof interfaceObject[key]) {
				return false;
			}

			//2 - if value is an object check recursively
			if (typeof object[key] === 'object' && !this.checkInterface(object[key], interfaceObject[key])) {
				return false;
			}
		}

		return true;
	}

	checkByKeys<T extends object>(object: T | any, keys: string[]): boolean {

		//0 - check the function call
		if (this.utilArray.isEmpty(keys)) throw new Error(`UtilInterface => checkKeys => FATAL ERROR: provided keys are empty`);

		//1 - check the values against the keys
		for (const key of keys) {
			if (!Object.hasOwn(object, key)) return false;
		}

		return true;
	}
}
