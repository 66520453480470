import { EnumSpreadshirtProduct, EnumSpreadshirtSorting } from '../../../../../../constants/research';
import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AbstractSearchLinks } from '../../../../data/search-links.abstract';
import { SPREADSHIRT_CONFIG_UK } from './spreadshirt-uk.data';
import { ISpreadshirtSearchOptions, SPREADSHIRT_DEFAULT_OPTIONS } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Spreadshirt Uk Search Links
 */
export class SpreadshirtUkSearch extends AbstractSearchLinks<ISpreadshirtSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumSpreadshirtSorting>[] {
		return SPREADSHIRT_CONFIG_UK.sortings as ISearchParameterConfig<EnumSpreadshirtSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumSpreadshirtProduct>[] {
		return SPREADSHIRT_CONFIG_UK.products as ISearchParameterConfig<EnumSpreadshirtProduct>[];
	}

	constructor() {
		super(SPREADSHIRT_CONFIG_UK, SPREADSHIRT_DEFAULT_OPTIONS);
	}
}
