/**------------------------------------------------------
 * Trademark Nice Classes Type
 */
export enum EnumTmNiceType {
	Goods		= 'goods',			// Represents goods or products in trademark classification
	Services 	= 'services'		// Represents services in trademark classification
}


/**------------------------------------------------------
 * Definitions & Configurations
 */
export const TM_NICE_TYPE_INFOS: Record<EnumTmNiceType, ITmNiceTypeInfo> = {
	[EnumTmNiceType.Goods]: {
		code			: EnumTmNiceType.Goods,
		name			: 'Goods',
		categorization	: 'Represents goods or products in trademark classification',
		info			: 'Nice class 1 till 34',
		tmViewValue		: 'Goods'
	},
	[EnumTmNiceType.Services]: {
		code			: EnumTmNiceType.Services,
		name			: 'Services',
		categorization	: 'Represents services in trademark classification',
		info			: 'Nice class 35 till 45',
		tmViewValue		: 'Services'
	}
};


//** Interfaces --------------------------------- */
export interface ITmNiceTypeInfo {
	code			: EnumTmNiceType;
	name			: string;
	categorization	: string;
	info			: string;
	tmViewValue		: string;
}
