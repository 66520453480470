/**------------------------------------------------------
 * Password Utilities
 * ------------------
 * > Info: Containing all functionalities to generate a
 * > password.
 */
export class UtilPassword {

	//** Configurations */
	private readonly DEFAULT_PASSWORD_TYPE	 : EnumUtilPasswordType[] = [EnumUtilPasswordType.CharLowercase, EnumUtilPasswordType.CharUppercase, EnumUtilPasswordType.Number];
	private readonly WEEK_CHARACTER_SEQUENCES: string[] 		  = [
		'012', '123', '456', '789', '890', '098', '987', '654', '321', '210', '1122', 'abc', 'def', 'ghi', 'jkl', 'mno', 'pqr', 'sto', 'vwx', 'xyz',
		'123123', 'qwerty', 'qwer', 'abc123', 'asdf', 'ghjk', 'uiop', 'yxcv', 'vbnm', 'password', 'vault', 'key', 'my', 'you', 'fuck', 'love', 'you', 'qqww' // cspell:disable-line
	];


	/**------------------------------------------------------
	 * Export Enums
	 */
	get PasswordType(): typeof EnumUtilPasswordType { return EnumUtilPasswordType; }


	/**------------------------------------------------------
	 * Create Random Password
	 */
	generate(length: number, types: EnumUtilPasswordType[] = this.DEFAULT_PASSWORD_TYPE): string {

		//0 - check if call is valid
		if (length <= 0) throw new Error(`UtilPassword => generate => FATAL ERROR: provided length parameter "${length}" is is not positive`);
		for (const type of types) {
			if (!Object.values(EnumUtilPasswordType).includes(type)) throw new Error(`UtilPassword => generate => FATAL ERROR: provided type of "${type}" is not supported (supported types: ${Object.values(EnumUtilPasswordType)})`);
		}

		//1 - define the available chars for the password (based on the given PasswordType)
		let randomChars: string = '';
		if (types.includes('A-Z' as EnumUtilPasswordType))	randomChars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		if (types.includes('a-z' as EnumUtilPasswordType))	randomChars += 'abcdefghijklmnopqrstuvwxyz';
		if (types.includes('0-9' as EnumUtilPasswordType))	randomChars += '0123456789';

		//2 - generate the random password
		let result: string = '';
		for (let i: number = 0; i < length; i++) {
			result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
		}
		return result;
	}
	generateMixed(passwordLength: number = 8): string {
		const lowChar: string = 'abcdefghijklmnopqrstuvwxyz';
		const upChar : string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		let   result : string = '';

		for (let i: number = 0; i < passwordLength / 2; i++) {
			result += upChar.charAt(Math.floor(Math.random() * upChar.length));
			result += lowChar.charAt(Math.floor(Math.random() * lowChar.length));
		}

		return result;
	}


	/**------------------------------------------------------
	 * Checking a password
	 */
	isStrongPassword(password: string): boolean {
		const strongRegex: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})');
		return strongRegex.test(password);
	}

	hasWeekCharacterSequence(password: string): boolean {
		password = password.toLowerCase();
		for (const weekSequence of this.WEEK_CHARACTER_SEQUENCES) {
			if (password.includes(weekSequence)) return true;
		}
		return false;
	}

	getWeekCharacterSequences(password: string): string[] {
		const weekSequences: string[] = [];
		password = password.toLowerCase();
		for (const weekSequence of this.WEEK_CHARACTER_SEQUENCES) {
			if (password.includes(weekSequence)) weekSequences.push(weekSequence);
		}
		return weekSequences;
	}
}


//** Enums -------------------------------------- */
enum EnumUtilPasswordType {
	Number			= 'password_type_0_9',
	CharLowercase	= 'password_type_a_z',
	CharUppercase	= 'password_type_A_Z'
}
