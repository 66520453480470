import { Inject, Injectable } from '@angular/core';

import { SecureSseRequest } from './sse-request/sse-request';
import { SecureGetRequest } from './requests/get-request';
import { SecurePostRequest } from './requests/post-request';
import { SecureDeleteRequest } from './requests/delete-request';
import { SecurePutRequest } from './requests/put-request';
import { SecurePatchRequest } from './requests/patch-request';
import { SecureHeadRequest } from './requests/head-request';
import { SecureOptionsRequest } from './requests/options-request';
import { AbstractSecureHttpClient } from './secure-http.interface';
import { SecureEncryptionService } from '../../services/encryption.service';
import { SecureTimeTokenService } from '../../services/time-token.service';
import { EnumSecureHttpInjection, ISecureHttpConfig } from '../secure-http.config';


@Injectable()
export class SecureHttp {

	constructor(
		@Inject(EnumSecureHttpInjection.Config)
		private config				: ISecureHttpConfig,
		private httpClient			: AbstractSecureHttpClient,
		private timeTokenService	: SecureTimeTokenService,
		private encryptionService	: SecureEncryptionService,
		private sseRequest			: SecureSseRequest
	) {}


	head(endpoint: string): SecureHeadRequest {
		return new SecureHeadRequest(this.httpClient, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}

	options(endpoint: string): SecureOptionsRequest {
		return new SecureOptionsRequest(this.httpClient, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}

	get(endpoint: string): SecureGetRequest {
		return new SecureGetRequest(this.httpClient, this.sseRequest, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}

	post(endpoint: string): SecurePostRequest {
		return new SecurePostRequest(this.httpClient, this.sseRequest, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}

	put(endpoint: string): SecurePutRequest {
		return new SecurePutRequest(this.httpClient, this.sseRequest, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}

	patch(endpoint: string): SecurePatchRequest {
		return new SecurePatchRequest(this.httpClient, this.sseRequest, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}

	delete(endpoint: string): SecureDeleteRequest {
		return new SecureDeleteRequest(this.httpClient, this.sseRequest, this.config, endpoint, this.timeTokenService, this.encryptionService);
	}
}
