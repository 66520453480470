// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';


/**------------------------------------------------------
 * Symmetric Encryption Algorithms
 * -------------------------------
 * > doc 				: https://cryptojs.gitbook.io/docs/
 * > install 			: https://stackoverflow.com/questions/41038425/how-to-use-cryptojs-with-angular-2-and-typescript-in-webpack-build-environment
 * > online encryption 	: https://www.devglan.com/online-tools/aes-encryption-decryption
 */
export class CryptoSymmetricEncryption {

	//** Configurations */
	private readonly SPECIAL_CHAR_TEST_REGEX: RegExp = /^[A-Za-z0-9]+$/;
	private readonly KEY_LENGTH_AES256		: number = 32;
	private readonly KEY_LENGTH_AES128		: number = 16;


	/**-----------------------------------------
	 * Encryption with ASE-256 standard
	 * > Using an 256 bit key (32 char key)
	 * > source: https://github.com/digitalbazaar/forge/issues/134
	 */
	encryptAes256(data: string, encryptionKey: string): string {

		//0 - check "encryptionKey" has special chars & verify if length is correct
		if (encryptionKey.length !== this.KEY_LENGTH_AES256) throw new Error(`SymmetricEncryption => encryptAES256 => ERROR: wrong encryption key length`);

		//1 - encrypt the data
		const cipher: forge.cipher.BlockCipher = forge.cipher.createCipher('AES-CBC', encryptionKey);
		cipher.start({ iv: this.createEmptyIv() });
		cipher.update(forge.util.createBuffer(data, 'utf8'));
		if (!cipher.finish()) throw new Error('SymmetricEncryption => encryptAES256 => ERROR: decryption process failed (probably wrong encryption key)');

		//2 - return the result as base64
		const encryptedBase64: string = forge.util.encode64(cipher.output.getBytes(), 0);
		if (encryptedBase64.length === 0) throw new Error(`SymmetricEncryption => decryptAES256 => ERROR: decryption failed (probably wrong encryption key)`);
		return encryptedBase64;
	}

	decryptAes256(data: string, encryptionKey: string): string {

		//0 - check "encryptionKey" has special chars & verify if length is correct
		if (encryptionKey.length !== this.KEY_LENGTH_AES256) throw new Error(`SymmetricEncryption => decryptAES256 => ERROR: wrong encryption key length`);

		//1 - decrypt the data
		const decipher: forge.cipher.BlockCipher = forge.cipher.createDecipher('AES-CBC', encryptionKey);
		decipher.start({ iv: this.createEmptyIv() });
		decipher.update(forge.util.createBuffer(forge.util.decode64(data)));
		if (!decipher.finish()) throw new Error('SymmetricEncryption => encryptAES256 => ERROR: decryption process failed (probably wrong encryption key)');

		//2 - return the result as string
		const decryptedString: string = forge.util.decodeUtf8(decipher.output.getBytes());
		if (decryptedString.length === 0) throw new Error(`SymmetricEncryption => decryptAES256 => ERROR: decryption failed (probably wrong encryption key)`);
		return decryptedString;
	}


	/**-----------------------------------------
	 * Encryption with AES-128 standard
	 * > Using an 128 bit key (16 char key)
	 */
	encryptAes128(data: string, encryptionKey: string): string {

		//0 - check "encryptionKey" has special chars & verify if length is correct
		if (encryptionKey.length !== this.KEY_LENGTH_AES128) throw new Error(`SymmetricEncryption => encryptAES128 => ERROR: wrong encryption key length`);

		//1 - encrypt the data
		const cipher: forge.cipher.BlockCipher = forge.cipher.createCipher('AES-CBC', encryptionKey);
		cipher.start({ iv: this.createEmptyIv() });
		cipher.update(forge.util.createBuffer(data, 'utf8'));
		if (!cipher.finish()) throw new Error('SymmetricEncryption => encryptAES128 => ERROR: decryption process failed (probably wrong encryption key)');

		//2 - return the result as base64
		const encryptedBase64: string = forge.util.encode64(cipher.output.getBytes(), 0);
		if (encryptedBase64.length === 0) throw new Error(`SymmetricEncryption => decryptAES128 => ERROR: decryption failed (probably wrong encryption key)`);
		return encryptedBase64;
	}

	decryptAes128(data: string, encryptionKey: string): string {

		//0 - check "encryptionKey" has special chars & verify if length is correct
		if (encryptionKey.length !== this.KEY_LENGTH_AES128) throw new Error(`SymmetricEncryption => decryptAES128 => ERROR: wrong encryption key length`);

		//1 - decrypt the data
		const decipher: forge.cipher.BlockCipher = forge.cipher.createDecipher('AES-CBC', encryptionKey);
		decipher.start({ iv: this.createEmptyIv() });
		decipher.update(forge.util.createBuffer(forge.util.decode64(data)));
		if (!decipher.finish()) throw new Error('SymmetricEncryption => encryptAES128 => ERROR: decryption process failed (probably wrong encryption key)');

		//2 - return the result as string
		const decryptedString: string = forge.util.decodeUtf8(decipher.output.getBytes());
		if (decryptedString.length === 0) throw new Error(`SymmetricEncryption => decryptAES128 => ERROR: decryption failed (probably wrong encryption key)`);
		return decryptedString;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	hasEncryptionKeySpecialChars(encryptionKey: string): boolean {
		const hasSpecialChard: boolean = !this.SPECIAL_CHAR_TEST_REGEX.test(encryptionKey);
		return hasSpecialChard;
	}

	private createEmptyIv(): forge.util.ByteStringBuffer {

		//0 - create iv
		const ivBuffer: forge.util.ByteStringBuffer = forge.util.createBuffer();
		const length  : number = 16;

		//1 - pad with zeros
		const zerosToAdd: number = length - ivBuffer.length();
		for (let i: number = 0; i < zerosToAdd; i++) ivBuffer.putByte(0);

		//2 - return the empty iv buffer
		return ivBuffer;
	}
}
