import { EnumSubscriptionModule } from './module.constants';


/**------------------------------------------------------
 * Package Names/Id's
 */
export enum EnumSubscriptionPackage {

	// -------------------------------------- Research
	Research 					= 'package_research',
	AiResearch					= 'package_ai_research',
	Trademark 					= 'package_trademark',
	KeywordBasic 				= 'package_keyword_basic',
	KeywordPro 					= 'package_keyword_pro',
	ResearchBundle2023			= 'package_research_bundle_2023',

	// -------------------------------------- POD
	DesignManagement25GB		= 'package_design_management_25gb',
	DesignManagement100GB		= 'package_design_management_100gb',
	DesignManagement500GB		= 'package_design_management_500gb',

	UploadPodMba				= 'package_upload_pod_mba',
	UploadKdp					= 'package_upload_kdp',
	UploadPodSpreadshirt		= 'package_upload_pod_spreadshirt',
	UploadPodRedbubble			= 'package_upload_pod_redbubble',
	UploadPodDisplate			= 'package_upload_pod_displate',
	UploadPodFineArtAmerica		= 'package_upload_pod_fine_art_america',
	UploadPodPrintful			= 'package_upload_pod_printful',
	UploadPodPrintify			= 'package_upload_pod_printify',
	UploadPodShirtee			= 'package_upload_pod_shirtee',
	UploadPodSociety6			= 'package_upload_pod_society6',
	UploadPodTeepublic			= 'package_upload_pod_teepublic',
	UploadPodTeespring			= 'package_upload_pod_teespring',
	UploadPodTostadora			= 'package_upload_pod_tostadora',
	UploadPodZazzle				= 'package_upload_pod_zazzle',
	UploadPodAdvanced			= 'package_upload_pod_advanced',
	UploadPodAdvancedShop 		= 'package_upload_pod_advanced_shop',
	UploadPodPro				= 'package_upload_pod_pro',

	// -------------------------------------- Bundles
	ProfessionalBundle2021 		= 'package_professional_bundle_2021',
	ProfessionalPlusBundle2021 	= 'package_professional_plus_bundle_2021',
	ProfessionalMaxBundle2021 	= 'package_professional_max_bundle_2021',
	UltimateBundle				= 'package_ultimate_bundle',

	// -------------------------------------- Legacy Bundles / Packages
	BlackFriday2020				= 'package_black_friday_2020',
	ResearchBundle2021 			= 'package_research_bundle',
	UploadBundle2021 			= 'package_upload_bundle_2021',
	UploadPlusBundle2021 		= 'package_upload_plus_bundle_2021',
	UploadMaxBundle2021 		= 'package_upload_max_bundle_2021'
}


/**------------------------------------------------------
 * Subscription Packages
 */
export const SUBSCRIPTION_PACKAGES: ISubscriptionPackage[] = [

	// -------------------------------------- Research
	{
		code			: EnumSubscriptionPackage.Research,
		modules			: [EnumSubscriptionModule.Research],
		packageName		: 'Research',
		description		: 'The full research from Best Seller, Seller Trends, to Evergreens for your Print-On-Demand business.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '644620',
				annual 		: '644621'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.Trademark,
		modules			: [EnumSubscriptionModule.Trademark],
		packageName		: 'Trademark',
		description		: 'Trademark are important for the safety of you and your account. Search for Trademarks, see New Registrations, and add phrases to your Watchlist, or get Mail Notifications for more security.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '644622',
				annual 		: '644624'
			}
		]
	},


	// -------------------------------------- Research Bundles
	{
		code			: EnumSubscriptionPackage.ResearchBundle2021,
		modules			: [EnumSubscriptionModule.Research, EnumSubscriptionModule.Trademark],
		packageName		: 'Research & Trademark Bundle',
		description		: 'Most popular research bundle to professionalize your workflow and to get inspired for creating your new best selling design.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '644626',
				annual 		: '644627'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.ResearchBundle2023,
		modules			: [EnumSubscriptionModule.Research, EnumSubscriptionModule.AiResearch, EnumSubscriptionModule.Trademark],
		packageName		: 'AI Research Bundle',
		description		: 'Comprehensive suite of tools for research workflow curated to help you research, create and protect your listings and designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '860729',
				annual 		: '860730'
			}
		]
	},


	// -------------------------------------- AI Research
	{
		code			: EnumSubscriptionPackage.AiResearch,
		modules			: [EnumSubscriptionModule.AiResearch],
		packageName		: 'AI Research',
		description		: 'Smart research leveraging artificial intelligence for finding profitable niches and creative ideas bolstering new opportunities to grow your business.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '860727',
				annual 		: '860728'
			}
		]
	},


	// -------------------------------------- Keywords
	{
		code			: EnumSubscriptionPackage.KeywordBasic,
		modules			: [EnumSubscriptionModule.KeywordBasic],
		packageName		: 'Keyword Basic',
		description		: 'The simplified keyword package offers a subset of the features of the pro. Search for the most popular keywords, long-tail keywords and phrases.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '803130',
				annual 		: '803131'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.KeywordPro,
		modules			: [EnumSubscriptionModule.KeywordPro],
		packageName		: 'Keyword Pro',
		description		: 'All keywords in one place. Search for the most popular keywords, long-tail keywords and phrases. Research and add the best keywords to your listings to create the best listings.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '803132',
				annual 		: '803133'
			}
		]
	},


	// -------------------------------------- Design Management
	{
		code			: EnumSubscriptionPackage.DesignManagement25GB,
		modules			: [EnumSubscriptionModule.DesignManagement25GB],
		packageName		: 'Design Management 25GB',
		description		: 'The design management for all your images and listings. Add and manage all your designs and listings in one place. Manage up to 25GB of designs at once.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740413',
				annual 		: '740417'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.DesignManagement100GB,
		modules			: [EnumSubscriptionModule.DesignManagement100GB],
		packageName		: 'Design Management 100GB',
		description		: 'The design management for all your images and listings. Add and manage all your designs and listings in one place. Manage up to 100GB of designs at once.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740414',
				annual 		: '740418'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.DesignManagement500GB,
		modules			: [EnumSubscriptionModule.DesignManagement500GB],
		packageName		: 'Design Management 500GB',
		description		: 'The design management for all your images and listings. Add and manage all your designs and listings in one place. Manage up to 500GB of designs at once.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740416',
				annual 		: '740419'
			}
		]
	},


	// -------------------------------------- Upload POD
	{
		code			: EnumSubscriptionPackage.UploadPodSpreadshirt,
		modules			: [EnumSubscriptionModule.UploadPodSpreadshirt],
		packageName		: 'Upload Single - Spreadshirt',
		description		: 'Upload all your POD designs automatically and unlimited to Spreadshirt EU and Spreadshirt US.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741763',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodRedbubble,
		modules			: [EnumSubscriptionModule.UploadPodRedbubble],
		packageName		: 'Upload Single - Redbubble',
		description		: 'Upload all your POD designs automatically and unlimited to Redbubble.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741762',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodMba,
		modules			: [EnumSubscriptionModule.UploadPodMba],
		packageName		: 'Upload Single - Merch By Amazon',
		description		: 'Upload all your POD designs automatically and unlimited to Merch By Amazon.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741761',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodDisplate,
		modules			: [EnumSubscriptionModule.UploadPodDisplate],
		packageName		: 'Upload Single - Displate',
		description		: 'Upload all your POD designs automatically and unlimited to Displate.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741769',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodFineArtAmerica,
		modules			: [EnumSubscriptionModule.UploadPodFineArtAmerica],
		packageName		: 'Upload Single - Fine Art America',
		description		: 'Upload all your POD designs automatically and unlimited to Fine Art America.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741773',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodPrintful,
		modules			: [EnumSubscriptionModule.UploadPodPrintful],
		packageName		: 'Upload Single - Printful',
		description		: 'Upload all your POD designs automatically and unlimited to Printful.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741770',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodShirtee,
		modules			: [EnumSubscriptionModule.UploadPodShirtee],
		packageName		: 'Upload Single - Shirtee',
		description		: 'Upload all your POD designs automatically and unlimited to Shirtee.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741772',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodPrintify,
		modules			: [EnumSubscriptionModule.UploadPodPrintify],
		packageName		: 'Upload Single - Printify',
		description		: 'Upload all your POD designs automatically and unlimited to Printify.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741771',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodSociety6,
		modules			: [EnumSubscriptionModule.UploadPodSociety6],
		packageName		: 'Upload Single - Society6',
		description		: 'Upload all your POD designs automatically and unlimited to Society6.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741765',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodTeepublic,
		modules			: [EnumSubscriptionModule.UploadPodTeepublic],
		packageName		: 'Upload Single - Teepublic',
		description		: 'Upload all your POD designs automatically and unlimited to Teepublic.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741764',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodTeespring,
		modules			: [EnumSubscriptionModule.UploadPodTeespring],
		packageName		: 'Upload Single - Teespring',
		description		: 'Upload all your POD designs automatically and unlimited to Teespring.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741766',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodTostadora,
		modules			: [EnumSubscriptionModule.UploadPodTostadora],
		packageName		: 'Upload Single - Tostadora',
		description		: 'Upload all your POD designs automatically and unlimited to Tostadora.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741774',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodZazzle,
		modules			: [EnumSubscriptionModule.UploadPodZazzle],
		packageName		: 'Upload Single - Zazzle',
		description		: 'Upload all your POD designs automatically and unlimited to Zazzle.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '741768',
				annual 		: null
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodAdvanced,
		modules			: [EnumSubscriptionModule.UploadPodAdvanced],
		packageName		: 'Upload Advanced - MBA, Spreadshirt, and Redbubble',
		description		: 'Upload all your designs automatically and unlimited to Amazon, Redbubble, and Spreadshirt.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740420',
				annual 		: '740422'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodAdvancedShop,
		modules			: [EnumSubscriptionModule.UploadPodAdvancedShop],
		packageName		: 'Upload Advanced - MBA, Printful, and Printify',
		description		: 'Upload all your designs automatically and unlimited to Merch By Amazon, Printful, and Printify.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '860732',
				annual 		: '860733'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPodPro,
		modules			: [EnumSubscriptionModule.UploadPodPro],
		packageName		: 'Upload Pro',
		description		: 'Upload all your designs automatically and unlimited to all available Print On Demand Platforms.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740421',
				annual 		: '740423'
			}
		]
	},


	// -------------------------------------- Book Management
	/* {
		code			: EnumSubscriptionPackage.BookManagement25GB,
		modules			: [EnumSubscriptionModule.BookManagement25GB],
		packageName		: 'Book Management 25GB',
		description		: 'The book management for all your kdp books and manuscripts. Add and manage all your books and listings for kdp in one place. Manage up to 25GB of designs at once.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '... (paddle plan id)',
				annual 		: '... (paddle plan id)',
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.BookManagement100GB,
		modules			: [EnumSubscriptionModule.BookManagement100GB],
		packageName		: 'Book Management 100GB',
		description		: 'The book management for all your kdp books and manuscripts. Add and manage all your books and listings for kdp in one place. Manage up to 100GB of designs at once.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '... (paddle plan id)',
				annual 		: '... (paddle plan id)',
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.BookManagement500GB,
		modules			: [EnumSubscriptionModule.BookManagement500GB],
		packageName		: 'Book Management 500GB',
		description		: 'The book management for all your kdp books and manuscripts. Add and manage all your books and listings for kdp in one place. Manage up to 500GB of designs at once.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '... (paddle plan id)',
				annual 		: '... (paddle plan id)',
			}
		]
	}, */


	// -------------------------------------- Upload KDP
	{
		code			: EnumSubscriptionPackage.UploadKdp,
		modules			: [EnumSubscriptionModule.UploadKdp],
		packageName		: 'Upload KDP',
		description		: 'Upload all your books automatically to Kindle Direct Publishing.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740424',
				annual 		: '740425'
			}
		]
	},


	// -------------------------------------- Upload Bundles
	{
		code			: EnumSubscriptionPackage.UploadBundle2021,
		modules			: [EnumSubscriptionModule.UploadKdp, EnumSubscriptionModule.UploadPodPro, EnumSubscriptionModule.DesignManagement25GB],
		packageName		: 'Upload Bundle',
		description		: 'The all-in-one upload bundle for uploading to KDP and all POD platforms. Including the brand new design management with 25GB for managing all your designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740426',
				annual 		: '740427'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadPlusBundle2021,
		modules			: [EnumSubscriptionModule.UploadKdp, EnumSubscriptionModule.UploadPodPro, EnumSubscriptionModule.DesignManagement100GB],
		packageName		: 'Upload Plus Bundle',
		description		: 'The all-in-one upload bundle for uploading to KDP and all POD platforms. Including the brand new design management with 100GB for managing all your designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '743708',
				annual 		: '743709'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UploadMaxBundle2021,
		modules			: [EnumSubscriptionModule.UploadKdp, EnumSubscriptionModule.UploadPodPro, EnumSubscriptionModule.DesignManagement500GB],
		packageName		: 'Upload Max Bundle',
		description		: 'The all-in-one upload bundle for uploading to KDP and all POD platforms. Including the brand new design management with 500GB for managing all your designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '743710',
				annual 		: '743711'
			}
		]
	},


	// -------------------------------------- Mega/Professional Bundles
	{
		code			: EnumSubscriptionPackage.ProfessionalBundle2021,
		modules			: [
			EnumSubscriptionModule.Research, 	EnumSubscriptionModule.Trademark,
			EnumSubscriptionModule.UploadKdp, 	EnumSubscriptionModule.UploadPodPro, 	EnumSubscriptionModule.DesignManagement25GB
		],
		packageName		: 'Professional Bundle',
		description		: 'The all-in-one bundle for professionals with Research, Trademarks, and the Upload Tool. Including the Upload to KDP, all POD platforms, and the new design management with 25GB for managing all your designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740428',
				annual 		: '740429'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.ProfessionalPlusBundle2021,
		modules			: [
			EnumSubscriptionModule.Research, 	EnumSubscriptionModule.Trademark,
			EnumSubscriptionModule.UploadKdp, 	EnumSubscriptionModule.UploadPodPro, 	EnumSubscriptionModule.DesignManagement100GB
		],
		packageName		: 'Professional Plus Bundle',
		description		: 'The all-in-one bundle for professionals with Research, Trademarks, and the Upload Tool. Including the Upload to KDP, all POD platforms, and the new design management with 100GB for managing all your designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '740430',
				annual 		: '740431'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.ProfessionalMaxBundle2021,
		modules			: [
			EnumSubscriptionModule.Research, 	EnumSubscriptionModule.Trademark,
			EnumSubscriptionModule.UploadKdp, 	EnumSubscriptionModule.UploadPodPro, 	EnumSubscriptionModule.DesignManagement500GB
		],
		packageName		: 'Professional Max Bundle',
		description		: 'The all-in-one bundle for professionals with Research, Trademarks, and the Upload Tool. Including the Upload to KDP, all POD platforms, and the new design management with 500GB for managing all your designs.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '743705',
				annual 		: '743707'
			}
		]
	},
	{
		code			: EnumSubscriptionPackage.UltimateBundle,
		modules			: [
			EnumSubscriptionModule.Research, 	EnumSubscriptionModule.Trademark, 		EnumSubscriptionModule.KeywordPro, 				EnumSubscriptionModule.AiResearch,
			EnumSubscriptionModule.UploadKdp, 	EnumSubscriptionModule.UploadPodPro, 	EnumSubscriptionModule.DesignManagement500GB
		],
		packageName		: 'Ultimate Bundle',
		description		: 'Embraced by creative pros this bundle delivers complete solution for researching niches, creating listings and designs, checking trademarks, and uploading your designs to multiple platforms with ease.',
		paddlePlans		: [
			{
				isActive	: true,
				month	 	: '860724',
				annual 		: '860726'
			}
		]
	},


	// -------------------------------------- Legacy Bundles / Packages
	{
		code			: EnumSubscriptionPackage.BlackFriday2020,
		modules			: [EnumSubscriptionModule.Research, EnumSubscriptionModule.Trademark],
		packageName		: 'BLACK FRIDAY 2020',
		description		: 'The BLACK FRIDAY 2020 special deal from the year of 2020',
		// note			: black friday deal of 2020, customer of the old upload tool received the new research 1 year for free (this plain is not linked to paddle and was created through adding the subscription key of the old shop system of edd)
		paddlePlans		: [
			{
				isActive	: false,
				month	 	: 'black_friday_2020',
				annual 		: 'black_friday_2020'
			}
		]
	}
];


/**------------------------------------------------------
 * Interfaces Definition
 */
export interface ISubscriptionPackage {
	code			: EnumSubscriptionPackage;
	modules			: EnumSubscriptionModule[];
	packageName		: string;
	description		: string;
	paddlePlans		: ISubscriptionPaddlePlan[];
}

export interface ISubscriptionPaddlePlan {
	isActive		: boolean;			// is active in store (only one of the array can be active)
	month	 		: string | null;
	annual 			: string | null;
}
