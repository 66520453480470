/**------------------------------------------------------
 * Interface Definitions
 */
export interface IWindowBounds {
	x 		: number;
	y 		: number;
	width 	: number;
	height 	: number;
}

export interface IPoint {
	x 		: number;
	y 		: number;
}

export interface IQueryParameters {
	queryParams: Record<string, string | number | boolean>;
}
