import { Subject } from 'rxjs';


/**------------------------------------------------------
 * RxJs Subject
 * ------------
 * ...
 */
export class RxJsSubject {

	//** Unsubscribe & Close Subject */
	close<T>(subject$: Subject<T>): Subject<T> {

		//0 - check if the subject was defined
		if (!subject$) throw new Error(`RxJsSubject => close => FATAL ERROR: subject$ instance in undefined`);

		//1 - close the subject
		subject$.complete();
		subject$.unsubscribe();
		return subject$;
	}
}
