import { EnumKdpPaperType, EnumKdpTrimSize, EnumKdpTrimSizeStandard } from './kdp-amazon.constants';


/**------------------------------------------------------
 * Cover Size Calculation
 * ----------------------
 * > Cover Size			: https://kdp.amazon.com/en_US/help/topic/G200645690#:~:text=Ideal%20dimensions%20for%20cover%20files,be%202500%20pixels%20in%20height.
 * > Paper definitions	: https://kdp.amazon.com/en_US/help/topic/G201834180
 * ----------------------
 * > Cover Width  = Bleed + Back Cover Width + Spine Width + Front Cover Width + Bleed
 * > Cover Height = Bleed + Trim Height + Bleed
 */


/**------------------------------------------------------
 * Cover Specification for KDP
 */
export const KDP_BOOK_COVER_SPECIFICATION: IAmazonKdpCoverSpecification = {
	bleed		: {
		inch	: 0.125,
		mm		: 3.175
	},
	fromEdge 	: {
		min_inch	: 0.25,
		min_mm		: 6.4
	},
	papers 		: [
		{
			paperType	: EnumKdpPaperType.BlackInkCream,
			name		: 'Black ink and cream paper',
			description : '... todo',
			definition  : 'Black ink and 55# (90 GSM) cream paper',
			spineMeasure : {
				inch	: 0.0025,
				mm		: 0.0635
			}
		},
		{
			paperType	: EnumKdpPaperType.BlackInkWhite,
			name		: 'Black ink and white paper',
			description : '... todo',
			definition  : 'Black ink and 55# (90 GSM) white paper',
			spineMeasure : {
				inch	: 0.002252,
				mm		: 0.0572
			}
		},
		{
			paperType	: EnumKdpPaperType.ColorInkWhite,
			name		: 'Color ink and white paper',
			description : '... todo',
			definition  : 'Color ink and 60# (100 GSM) white paper',
			spineMeasure : {
				inch	: 0.002347,
				mm		: 0.0596
			}
		}
	]
};


/**------------------------------------------------------
 * Interior Specification for KDP
 */
export const KDP_BOOK_INTERIOR_SPECIFICATION: IAmazonKdpInteriorSpecifications[] = [
	{
		code		: EnumKdpTrimSize.JuniorLegal,
		name		: `Junior Legal - 5' x 8' (12.7 x 20.32 cm)`,
		amazonName  : `5' x 8' (12.7 x 20.32 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 5,
				height_inch	: 8,
				width_mm 	: 127,
				height_mm	: 203.2
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 5.13,
				height_inch	: 8.25,
				width_mm 	: 130.302,
				height_mm	: 209.55
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.DinB6,
		name		: `DIN B6 - 5.06' x 7.81' (12.85 x 19.84 cm)`,
		amazonName  : `5.06' x 7.81' (12.85 x 19.84 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 5.06,
				height_inch	: 7.81,
				width_mm 	: 128.5,
				height_mm	: 198.4
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 5.19,
				height_inch	: 8.06,
				width_mm 	: 131.826,
				height_mm	: 204.724
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size5x8,
		name		: `5.25' x 8' (13.34 x 20.32 cm)`,
		amazonName  : `5.25' x 8' (13.34 x 20.32 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 5.25,
				height_inch	: 8,
				width_mm 	: 133.4,
				height_mm	: 203.2
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 5.38,
				height_inch	: 8.25,
				width_mm 	: 136.652,
				height_mm	: 209.55
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.DinA5,
		name		: `DIN A5 - 5.5' x 8.5' (13.97 x 21.59 cm)`,
		amazonName  : `5.5' x 8.5' (13.97 x 21.59 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 5.5,
				height_inch	: 8.5,
				width_mm 	: 139.7,
				height_mm	: 215.9
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 5.63,
				height_inch	: 8.75,
				width_mm 	: 143.002,
				height_mm	: 222.25
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size6x9,
		name		: `6' x 9' (15.24 x 22.86 cm)`,
		amazonName  : `6' x 9' (15.24 x 22.86 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 6,
				height_inch	: 9,
				width_mm 	: 152.4,
				height_mm	: 228.6
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 6.13,
				height_inch	: 9.25,
				width_mm 	: 155.702,
				height_mm	: 234.95
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size6x92,
		name		: `6.14' x 9.21' (15.6 x 23.39 cm)`,
		amazonName  : `6.14' x 9.21' (15.6 x 23.39 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 6.14,
				height_inch	: 9.21,
				width_mm 	: 156,
				height_mm	: 233.9
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 6.26,
				height_inch	: 9.46,
				width_mm 	: 159.004,
				height_mm	: 240.284
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size6x96,
		name		: `6.69' x 9.61' (16.99 x 24.41 cm)`,
		amazonName  : `6.69' x 9.61' (16.99 x 24.41 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 6.69,
				height_inch	: 9.61,
				width_mm 	: 169.9,
				height_mm	: 244.1
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 6.81,
				height_inch	: 9.86,
				width_mm 	: 172.974,
				height_mm	: 250.444
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.DinB5,
		name		: `DIN B5 - 7' x 10' x (17.78 x 25.4 cm)`,
		amazonName  : `7' x 10' x (17.78 x 25.4 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 7,
				height_inch	: 10,
				width_mm 	: 177.8,
				height_mm	: 254
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 7.13,
				height_inch	: 10.25,
				width_mm 	: 181.102,
				height_mm	: 260.35
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size7x96,
		name		: `7.44' x 9.69' (18.9 x 24.61 cm)`,
		amazonName  : `7.44' x 9.69' (18.9 x 24.61 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 7.44,
				height_inch	: 9.69,
				width_mm 	: 189,
				height_mm	: 246.1
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 7.56,
				height_inch	: 9.94,
				width_mm 	: 192.024,
				height_mm	: 252.476
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size7x92,
		name		: `7.5' x 9.25' (19.05 x 23.5 cm)`,
		amazonName  : `7.5' x 9.25' (19.05 x 23.5 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 7.5,
				height_inch	: 9.25,
				width_mm 	: 190.5,
				height_mm	: 235
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 7.63,
				height_inch	: 9.5,
				width_mm 	: 193.802,
				height_mm	: 241.3
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size8x10,
		name		: `8' x 10' (20.32 x 25.4 cm)`,
		amazonName  : `8' x 10' (20.32 x 25.4 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 8,
				height_inch	: 10,
				width_mm 	: 203.2,
				height_mm	: 254
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 8.13,
				height_inch	: 10.25,
				width_mm 	: 206.502,
				height_mm	: 260.35
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 828
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 776
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 828
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size8x6,
		name		: `8.25' x 6' (20.96 x 15.24 cm)`,
		amazonName  : `8.25' x 6' (20.96 x 15.24 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 8.25,
				height_inch	: 6,
				width_mm 	: 209.6,
				height_mm	: 152.4
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 8.38,
				height_inch	: 6.25,
				width_mm 	: 212.852,
				height_mm	: 158.75
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 800
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 750
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 800
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size8x82,
		name		: `8.25' x 8.25' (20.96 x 20.96 cm)`,
		amazonName  : `8.25' x 8.25' (20.96 x 20.96 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 8.25,
				height_inch	: 8.25,
				width_mm 	: 209.6,
				height_mm	: 209.6
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 8.38,
				height_inch	: 8.5,
				width_mm 	: 212.852,
				height_mm	: 215.9
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 800
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 750
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 800
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Size8x8,
		name		: `8.5' x 8.5' (21.59 x 21.59 cm)`,
		amazonName  : `8.5' x 8.5' (21.59 x 21.59 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 8.5,
				height_inch	: 8.5,
				width_mm 	: 215.9,
				height_mm	: 215.9
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 8.63,
				height_inch	: 8.75,
				width_mm 	: 219.202,
				height_mm	: 222.25
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 590
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 550
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 590
			}
		]
	},
	{
		code		: EnumKdpTrimSize.Letter,
		name		: `Letter - 8.5' x 11' (21.59 x 27.94 cm)`,
		amazonName  : `8.5' x 11' (21.59 x 27.94 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 8.5,
				height_inch	: 11,
				width_mm 	: 215.9,
				height_mm	: 279.4
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 8.63,
				height_inch	: 11.25,
				width_mm 	: 219.202,
				height_mm	: 285.75
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 590
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 550
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 590
			}
		]
	},
	{
		code		: EnumKdpTrimSize.DinA4,
		name		: `DIN A4 - 8.27' x 11.69' (21 x 29.7 cm)`,
		amazonName  : `8.27' x 11.69' (21 x 29.7 cm)`,
		description : `... todo`,
		sizes		: [
			{
				standard	: EnumKdpTrimSizeStandard.OfficialKDP,
				width_inch 	: 8.27,
				height_inch	: 11.69,
				width_mm 	: 210,
				height_mm	: 297
			},
			{
				standard	: EnumKdpTrimSizeStandard.Tangent,
				width_inch 	: 8.4,
				height_inch	: 11.94,
				width_mm 	: 213.36,
				height_mm	: 303.276
			}
		],
		pageCount	: [
			{
				paperType	: EnumKdpPaperType.BlackInkWhite,
				min			: 24,
				max			: 780
			},
			{
				paperType	: EnumKdpPaperType.BlackInkCream,
				min			: 24,
				max			: 730
			},
			{
				paperType	: EnumKdpPaperType.ColorInkWhite,
				min			: 24,
				max			: 9999
			}
		]
	}
];


/**------------------------------------------------------
 * Interfaces
 */
interface IAmazonKdpCoverSpecification {
	bleed		: {
		inch	: number;
		mm		: number;
	};
	fromEdge 	: {						// Any content not intending to be trimmed off should be ... from the outside cover edge
		min_inch	: number;
		min_mm		: number;
	};
	papers : Array<{
		paperType		: string;
		name			: string;
		definition  	: string;
		description 	: string;
		spineMeasure 	: {
			inch		: number;
			mm			: number;
		};
	}>;
}

interface IAmazonKdpInteriorSpecifications {
	code		: EnumKdpTrimSize;
	name		: string;
	amazonName  : string;
	description : string;
	sizes		: Array<{
		standard	: EnumKdpTrimSizeStandard;	// standard of the supported platform sizings
		width_inch 	: number;
		height_inch	: number;
		width_mm 	: number;
		height_mm	: number;
	}>;
	pageCount	: Array<{
		paperType	: EnumKdpPaperType;
		min			: number;
		max			: number;
	}>;
}
