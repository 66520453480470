import { UtilBasic } from './util-basic';


/**------------------------------------------------------
 * Function Utilities
 * ------------------
 * > Info: Handling advanced function related operations
 */
export class UtilFunction {

	constructor(
		private utilBasic: UtilBasic
	) {}


	//** Clean and assigns default options */
	assignOptions<T>(defaultOptions: T, customOptions?: Partial<T>): T {

		//0 - did we get any custom options?
		const defaultCopy: T = this.utilBasic.deepCopy(defaultOptions);
		if (this.utilBasic.isUndefined(customOptions)) return defaultCopy;

		//1 - overwrite the default options with the custom options
		return this.utilBasic.deepAssignPartial(defaultCopy, customOptions!);
	}

	cache<TFn extends Function>(fn: TFn): TFn {

		//0 - create the cache to hold the calculated values
		const cache: Record<string, any> = {};

		//1 - define the caching behavior
		return ((...args: any[]) => {

			//a. was the value already calculated?
			const argsHash: string = this.hashObject(args);
			if (cache[argsHash]) return cache[argsHash];

			//b. calculate the value
			cache[argsHash] = fn(...args);
			return cache[argsHash];
		}) as any as TFn;
	}

	//** Get Trace of Object & Method */
	// > https://stackoverflow.com/questions/19114150/get-the-class-function-name-within-itself
	getCallTrace(stackIndex: number = 1): string[] {

		//0 - get and purify the stack trace
		const error: any 	  = new Error();
		const stack: string[] = error.stack
			.replace(/^[^(]+?[\n$]/gm, '') 								// remove first useless line
			.replace(/^\s+at\s+/gm, '') 								// remove 'at'
			.replace(/^Object.<anonymous>\s*\(/gm, '{anonymous}()@') 	// replace Object.<anon> with {anon}
			.replace(/\s\(.*:[0-9]+:[0-9]+\)/gm, '') 					// remove filename, col and row
			.split('\n');

		//1 - return the trace of the position
		if (stackIndex >= stack.length) throw new Error(`UtilFunction => getCallTrace => FATAL ERROR: the stackIndex of "${stackIndex}" is larger then the stack size of "${stack.length}"`);
		return stack[stackIndex].split('.'); 							// Previous method, the one calling this one
	}

	setIntervalImmediately(fn: Function, interval: number): number {
		fn();
		return setInterval(fn, interval);								// returns the intervalID
	}

	executeAndIgnoreErrors<T>(fn: () => T): T | null {
		try {
			return fn();												// suppressing all errors, will return null instead of throwing the error
		} catch (error) {
			return null;
		}
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private hashObject(values: any[]): string {
		return this.utilBasic.stringifyIfObject(values).split('').reduce(
			(hashCode: number, currentVal: string) => (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
			0
		).toString();
	}
}


//** Interfaces --------------------------------- */
export interface IUtilRetryOptions {
	attempts	: number;
	delayPeriod	: number;
}
