import { NgModule } from '@angular/core';

import { AuthGuardService } from './auth-guard/auth-guard.service';
import { ModuleGuard } from './module-guard/module-guard';
import { TimeGuard } from './time-guard/time-guard';
import { AffiliateGuard } from './affiliate-links/affiliate.guard';
import { AffiliateRootParamService } from './affiliate-links/affiliate-root-param.service';


@NgModule({
	providers: [
		AffiliateGuard,
		AffiliateRootParamService,
		AuthGuardService,
		ModuleGuard,
		TimeGuard
	]
})
export class RouteGuardsModule {}
