import { JapaneseSegmenter } from './helper/japanese-segmenter';
import { TextSegmenter } from './text-segmenter';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class TextSegmenterFactory {

	static create(): TextSegmenter {
		return new TextSegmenter(
			new JapaneseSegmenter()
		);
	}
}
