import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchLinkUrlConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Pinterest Search Url
 */
export class PinterestSearch implements ISearchLinks {

	//** Configurations */
	private readonly PINTEREST_CONFIG: ISearchLinkUrlConfig = {
		home		: 'https://www.pinterest.com',
		login		: 'https://www.pinterest.com/login/',
		search		: 'https://www.pinterest.com/search/pins/?q=[SEARCH]'
	};


	//** Home & Login */
	home() : string { return this.PINTEREST_CONFIG.home; }
	login(): string { return this.PINTEREST_CONFIG.login; }

	//** Search */
	search(searchQuery: string): string {

		//0 - if no search string existing then return main or home page
		if (Util.String.isEmpty(searchQuery)) return this.home();

		//1 - else return the url with search query
		return this.PINTEREST_CONFIG.search.replace('[SEARCH]', searchQuery);
	}
}
