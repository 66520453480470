import { Util } from '@libs/utilities/util';
import { ObjectStorage } from '@libs/libraries/shared';

import { EnumSidebarPage } from '../../../../components/layout/sidebar/sidebar-pages.interface';
import { IPageStates, TypePages, TypePagesItemKey, TypePagesItem } from './page.interface';


/**------------------------------------------------------
 * Page Storage
 */
export class PageStorageHelper {

	constructor(
		private storageAccessHelper: ObjectStorage<IPageStates>
	) {}


	/**------------------------------------------------------
	 * Save & Load Page State
	 */
	savePageState(pageName: EnumSidebarPage, state: TypePages): void {
		this.storageAccessHelper.setStorageObject<EnumSidebarPage>(pageName, state);
	}
	assignPageState(pageName: EnumSidebarPage, stateRef: TypePages): boolean {

		//0 - try to load the value from the local storage
		const state: Record<string, any> = this.storageAccessHelper.getStorageObject<EnumSidebarPage>(pageName);
		if (Util.Basic.isUndefined(state)) return false;

		//1 - set the loaded values as the new state
		Util.Basic.deepAssignPartial(stateRef, state, false);
		return true;
	}
	loadPageState(pageName: EnumSidebarPage): TypePages {

		//0 - try to load the value from the local storage
		const state: TypePages = this.storageAccessHelper.getStorageObject<EnumSidebarPage>(pageName);
		if (Util.Basic.isUndefined(state)) return null;

		//1 - return the state
		return state;
	}


	/**------------------------------------------------------
	 * Save & Load Page State Item
	 */
	savePageStateItem(pageName: EnumSidebarPage, itemKey: TypePagesItemKey, state: TypePagesItem): void {
		this.storageAccessHelper.setStorageItem<EnumSidebarPage, TypePagesItemKey>(pageName, itemKey, state);
	}

	assignPageStateItem(pageName: EnumSidebarPage, itemKey: TypePagesItemKey, stateRef: TypePagesItem): boolean {

		//0 - try to load the value from the local storage
		const stateItem: any = this.storageAccessHelper.getStorageItem<EnumSidebarPage, TypePagesItemKey>(pageName, itemKey);
		if (Util.Basic.isUndefined(stateItem)) return false;

		//1 - set the loaded values as the new state
		Util.Basic.deepAssignPartial(stateRef, stateItem, false);
		return true;
	}
	loadPageStateItem(pageName: EnumSidebarPage, itemKey: TypePagesItemKey): TypePagesItem | null {

		//0 - try to load the value from the local storage
		const stateItem: any = this.storageAccessHelper.getStorageItem<EnumSidebarPage, TypePagesItemKey>(pageName, itemKey);
		if (Util.Basic.isUndefined(stateItem)) return null;

		//1 - return the state
		return stateItem;
	}
}
