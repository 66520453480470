<div class="modal-header border-bottom-0">
	<div class="w-100 d-flex justify-content-center">
		<img
			[src]="errorDetails?.img"
			[alt]="errorDetails?.alt"
			class="w-50 align-items-center"
		/>
	</div>
</div>

<div class="horizontal-line-sep-with-text">
	<span>{{ errorDetails?.title }}</span>
</div>

<div class="modal-body d-flex flex-column justify-content-center align-items-center">
	<small class="text-justify px-4">
		<div
			[innerHtml]="htmlDesc"
			ngRouteTransformer
		></div>
	</small>

	<div class="my-3 d-flex justify-content-center align-items-center">
		<button
			*ngFor="let button of errorDetails?.buttons"
			[ngClass]="button?.buttonClass"
			class="btn btn-primary mx-3"
			(click)="onButtonAction(button.buttonAction)"
		>
			{{ button.buttonText }}
		</button>
	</div>
</div>
