import { Observable, Subscriber } from 'rxjs';

import { SecureWebSocketWrapper } from './websocket.wrapper';


/**------------------------------------------------------
 * Socket as API
 * -------------
 * > This class wraps around a socket connection to pretend
 * > that is an api, so the socket with the messaging can
 * > be used like a normal api.
 */
export class SecureWebSocketApiWrapper {

	constructor(
		private websocketWrapper: SecureWebSocketWrapper
	) {}


	post<TMessage extends object, TResponse extends object>(event: string, data: TMessage): Observable<TResponse> {

		return new Observable<TResponse>((observer: Subscriber<TResponse>) => {

			//0 - handle socket and observer for all events
			this.websocketWrapper.onConnect(() => this.websocketWrapper.emitEvent(event, data));
			this.websocketWrapper.onDisconnect(() => { observer.complete(); });

			//1 - subscript to success and error responses
			this.websocketWrapper.onEventOnce(event, (responseData: TResponse) => { observer.next(responseData); });
			this.websocketWrapper.onError((error: any) => { observer.error(error); });

			//2 - connect and start socket communication
			this.websocketWrapper.connect();

			//3 - return observable for subscription
			return () => {
				this.websocketWrapper.disconnect();
			};
		});
	}

	stream<TMessage extends object, TResponse extends object>(event: string, data: TMessage): Observable<TResponse> {

		return new Observable<TResponse>((observer: Subscriber<TResponse>) => {

			//0 - handle socket and observer for all events
			this.websocketWrapper.onConnect(() => this.websocketWrapper.emitEvent(event, data));
			this.websocketWrapper.onDisconnect(() => { observer.complete(); });

			//1 - subscript to success and error responses
			this.websocketWrapper.onEvent(event, (responseData: TResponse) => { observer.next(responseData); });
			this.websocketWrapper.onError((error: any) => { observer.error(error); });

			//2 - connect and start socket communication
			this.websocketWrapper.connect();

			//3 - return observable for subscription
			return () => {
				this.websocketWrapper.disconnect();
			};
		});
	}
}
