import { AbstractSearchLinks } from './search-links.abstract';
import { ISearchLinkOptions } from './search-links.interface';


/**------------------------------------------------------
 * Search Link Helper
 */
export class SearchLinksHelper {

	//** Get search handler for given marketplace */
	getMarketplace<TMarketplace extends string, TSearchLinkOptions extends ISearchLinkOptions>(
		marketplace: TMarketplace, searchHandlers: AbstractSearchLinks<TSearchLinkOptions>[]): AbstractSearchLinks<TSearchLinkOptions> {

		//0 - try to find the handler for a given marketplace
		for (const searchHandler of searchHandlers) {

			//a. does the marketplace match?
			if (searchHandler.getMarketplace() !== marketplace) continue;

			//b. if we found the matching market return the platform
			return searchHandler;
		}

		//1 - if nothing was found throw an error
		throw new Error(`SearchLinkHelper => getMarketplace => FATAL ERROR: no search configuration was found for the marketplace of "${marketplace}" (Please check the marketplace parameter and the configuration)`);
	}
}
