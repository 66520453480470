import { JdtHandlerService } from './jdt-handler.service';
import { JdtDecodingHelper } from './data/jdt-decoding.helper';
import { JdtEncodingHelper } from './data/jdt-encoding.helper';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class JdtHandlerServiceFactory {

	static create(): JdtHandlerService {
		const jdtEncodingHandler: JdtEncodingHelper = new JdtEncodingHelper();
		return new JdtHandlerService(
			jdtEncodingHandler,
			new JdtDecodingHelper(jdtEncodingHandler)
		);
	}
}
