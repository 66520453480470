import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Keyword List Data
 */
export interface IKeywordList {
	id				: string;
	name			: string;
	notes			: string;
	language		: EnumLanguage;
	categoryLists	: IKeywordsCategoryList[];		// can be empty!
}

export interface IKeywordsCategoryList {
	category		: string;
	keywords 		: string[];
}
