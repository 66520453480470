import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AbstractSecureHttpRequest } from './http-request.abstract';
import { SecureSseRequest } from '../sse-request/sse-request';
import { ISecureHttpConfig } from '../../secure-http.config';
import { SecureTimeTokenService } from '../../../services/time-token.service';
import { SecureEncryptionService } from '../../../services/encryption.service';
import { ISecureSseRequestOptions, EnumSecureHttpRequestMethod } from '../sse-request/sse-request.interface';


export class SecureGetRequest extends AbstractSecureHttpRequest {

	constructor(
		private   http						: HttpClient,
		protected sseRequest				: SecureSseRequest,
		protected override config			: ISecureHttpConfig,
		protected override endpoint			: string,
		protected override timeTokenService	: SecureTimeTokenService,
		protected override encryptionService: SecureEncryptionService
	) {
		super(config, endpoint, timeTokenService, encryptionService);
	}

	send<T>(): Observable<T> {

		//0 - get endpoint
		const endpoint: string = this.prepareEndpointUrl();

		//1 - send request
		const getRequest$: Observable<T | string> = this.http.get<T>(endpoint, {
			headers		: this.prepareHeaders(),
			responseType: this.prepareResponseType() as any
		});

		//2 - decrypt response
		return this.decryptResponse(getRequest$);
	}

	streamSse<T>(): Observable<T> {

		//0 - get endpoint
		const endpoint: string = this.prepareEndpointUrl();

		//1 - send requestOptions
		const options: ISecureSseRequestOptions = {
			method		: EnumSecureHttpRequestMethod.Get,
			headers		: this.prepareHeaders()
		};

		//2 - return stream with decrypted response
		const streamGetRequest$: Observable<T | string> = this.sseRequest.getServerSentEvent<T>(endpoint, options);
		return this.decryptResponse(streamGetRequest$);
	}
}
