import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumVexelsProduct, IVexelsSearchOptions, VEXELS_CONFIG, VEXELS_DEFAULT_OPTIONS } from './vexels-search.data';


/**------------------------------------------------------
 * Vexels Search Url
 */
export class VexelsSearch extends AbstractSearchLinks<IVexelsSearchOptions> {

	//** Helper Getters */
	get productList(): ISearchParameterConfig<EnumVexelsProduct>[] {
		return VEXELS_CONFIG.products as ISearchParameterConfig<EnumVexelsProduct>[];
	}

	constructor() {
		super(VEXELS_CONFIG, VEXELS_DEFAULT_OPTIONS);
	}
}
