import { EnumAmazonMarketplace } from '@apps/flying/shared';
import { EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { EnumFlyingNicheSize } from '../../interfaces';


/**------------------------------------------------------
 * Constants
 */
export const FLYING_AI_NICHE_FINDER_CONFIG: IFlyingAiNicheFinderConfig = {
	MAX_SEARCH_LENGTH	: 50,
	MAX_RESULTS		 	: 25
};

interface IFlyingAiNicheFinderConfig {
	MAX_SEARCH_LENGTH	: number;
	MAX_RESULTS		 	: number;
}


/**------------------------------------------------------
 * Requests
 */
export interface IFlyingNicheFinderRequest {
	search			: string;
	size			: EnumFlyingNicheSize;
	market			: EnumAmazonMarketplace;
	language		: EnumLanguage;
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingNicheFinderResponse {
	suggestions		: string[];
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingNicheFinderRequestSchema implements ValidatorSchema<IFlyingNicheFinderRequest> {
	search			= Validator.String().isMaxLength(FLYING_AI_NICHE_FINDER_CONFIG.MAX_SEARCH_LENGTH)	// search text can be empty
						.hasNoHtmlScripts().toLowerCase().trim().purifyWhitespaces();
	size			= Validator.Enum(EnumFlyingNicheSize);
	market			= Validator.Enum(EnumAmazonMarketplace);
	language		= Validator.Enum(EnumLanguage);
}

export class FlyingNicheFinderResponseSchema implements ValidatorSchema<IFlyingNicheFinderResponse> {
	suggestions		= Validator.Array().each(Validator.String().isNotEmpty());
}
