/**------------------------------------------------------
 * Redbubble Template
 */
export interface IRedbubbleTemplateOptions {
	designLink 		: string;
	options 		: {
		allLanguages	: boolean;
		forcePublic		: boolean;					// Always publish in public
	};
}
