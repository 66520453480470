import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { NgProductTourInfoComponent } from './product-tour-info/product-tour-info.component';
import { NgProductTourService } from './product-tour.service';
import { NgDialogsModule } from '../../dialogs';


@NgModule({
	declarations: [
		NgProductTourInfoComponent
	],
	imports: [
		CommonModule,
		NgbModule,
		NgbPopoverModule,
		NgDialogsModule
	],
	providers: [
		NgProductTourService
	],
	exports: [
		NgProductTourInfoComponent
	]
})
export class NgProductTourModule { }
