import { Util } from '@libs/utilities/util';
import { ReplaySubject } from 'rxjs';
import { Socket } from 'socket.io-client';
import { ISecureSocketSecurityOptions, SECURE_WHITELIST_SOCKET_ENCRYPTION } from '@domains/security/shared';

import { SecureEncryptionService } from '../../../services/encryption.service';
import { EnumSecureSocketDisconnect, EnumSecureSocketError, EnumSecureSocketEvent, EnumSecureSocketReconnect, ISecureEmitEventData, ISecureEmitEventLog, ISecureSocketError } from '../secure-socket.interface';


/**------------------------------------------------------
 * Websocket Wrapper
 * -----------------
 * > Creates a fancy wrapper with method chaining around
 * > the socket connection, to allow a more convenient
 * > and advanced usage.
 */
export class SecureWebSocketWrapper {

	//** Configurations */
	private readonly DEFAULT_EMIT_TIMEOUT: number = 10000;

	//** Helper Variables */
	private readonly eventHistory: ISecureEmitEventLog[] = [];

	//** Helper Subjects */
	private readonly onConnect$	 		: ReplaySubject<void> 					= new ReplaySubject<void>();
	private readonly onDisconnect$	 	: ReplaySubject<EnumSecureSocketDisconnect>	= new ReplaySubject<EnumSecureSocketDisconnect>();
	private readonly onConnectionChange$: ReplaySubject<void> 					= new ReplaySubject<void>();
	private readonly onError$	 		: ReplaySubject<ISecureSocketError> 			= new ReplaySubject<ISecureSocketError>();
	private readonly onDestroy$	 		: ReplaySubject<void> 					= new ReplaySubject<void>();
	private readonly onEmit$	 		: ReplaySubject<ISecureEmitEventData<any>>  	= new ReplaySubject<ISecureEmitEventData<any>>();

	constructor(
		private socket			 : Socket,
		private encryptionService: SecureEncryptionService,
		private securityOptions	 : ISecureSocketSecurityOptions
	) {
		//0 - register connect & disconnect
		this.socket.on(EnumSecureSocketEvent.Connect, () => {
			this.onConnect$.next();
			this.onConnectionChange$.next();		// monitor the connection status
		});
		this.socket.on(EnumSecureSocketEvent.Disconnect, (reason: Socket.DisconnectReason) => {
			this.onDisconnect$.next(reason as EnumSecureSocketDisconnect);
			this.onConnectionChange$.next();		// monitor the connection status
		});

		//1 - register the error handling
		this.socket.on(EnumSecureSocketEvent.Error, (error: unknown) => {
			this.onError$.next({ reason: EnumSecureSocketError.Error, error });
		});
		this.socket.on(EnumSecureSocketEvent.ConnectError, (error: unknown) => {
			this.onError$.next({ reason: EnumSecureSocketError.ConnectError, error });
		});
	}


	/**------------------------------------------------------
	 * Socket Event Hooks
	 */
	onConnect(onConnectCallback: () => void): SecureWebSocketWrapper {
		this.onConnect$.subscribe(() => {
			onConnectCallback();
		});
		return this;
	}

	onDisconnect(onDisconnectCallback: (reason: EnumSecureSocketDisconnect) => void): SecureWebSocketWrapper {
		this.onDisconnect$.subscribe((reason: EnumSecureSocketDisconnect) => {
			onDisconnectCallback(reason);
		});
		return this;
	}

	onDestroy(onDestroyCallback: () => void): SecureWebSocketWrapper {
		this.onDestroy$.subscribe(() => {
			onDestroyCallback();
		});
		return this;
	}

	onError(onErrorCallback: (error: unknown) => void): SecureWebSocketWrapper {
		this.onError$.subscribe((error: unknown) => {
			onErrorCallback(error);
		});
		return this;
	}

	onPing(onPingCallback: () => void): SecureWebSocketWrapper {
		this.socket.on(EnumSecureSocketEvent.Ping, () => {
			onPingCallback();
		});
		return this;
	}

	onReconnect(onReconnectCallback: (reason: EnumSecureSocketReconnect) => void): SecureWebSocketWrapper {
		this.socket.on(EnumSecureSocketReconnect.Reconnect, 		 () => { onReconnectCallback(EnumSecureSocketReconnect.Reconnect); });
		this.socket.on(EnumSecureSocketReconnect.ReconnectAttempt, () => { onReconnectCallback(EnumSecureSocketReconnect.ReconnectAttempt); });
		this.socket.on(EnumSecureSocketReconnect.ReconnectFailed,  () => { onReconnectCallback(EnumSecureSocketReconnect.ReconnectFailed); });
		this.socket.on(EnumSecureSocketReconnect.ReconnectError,   () => { onReconnectCallback(EnumSecureSocketReconnect.ReconnectError); });
		return this;
	}

	onEmit<T>(onEmitCallback: (event: string, data: T) => void): SecureWebSocketWrapper {
		this.onEmit$.subscribe((eventData: ISecureEmitEventData<T>) => {
			onEmitCallback(eventData.event, eventData.data);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Connect, Disconnect & Destroy
	 */
	connect(connectCallback: () => void = () => {}): SecureWebSocketWrapper {

		//0 - connect the socket
		this.socket.connect();

		//1 - if required emit a callback on the first connect finished
		this.socket.once(EnumSecureSocketEvent.Connect, () => {
			connectCallback();
		});
		return this;
	}

	disconnect(): SecureWebSocketWrapper {
		this.socket.disconnect();
		return this;
	}

	destroy(): SecureWebSocketWrapper {

		//0 - disconnect and destroy the socket connection
		this.socket.disconnect();
		this.socket.off();

		//1 - emit the destroy event
		this.onDestroy$.next();

		//2 - unsubscribe all event listeners
		this.completeAllObservables();

		//3 - clear the event history
		this.clearEventHistory();
		return this;
	}


	/**------------------------------------------------------
	 * Emit Events / Messages
	 */
	emitEvent<T>(event: string, data: T, timeout: number = this.DEFAULT_EMIT_TIMEOUT): SecureWebSocketWrapper {

		//0 - is the socket already connected?
		const encryptedData: T | string = this.encryptMessage(event, data);
		if (this.isConnected()) {
			this.emitEventAction(event, encryptedData);
			return this;
		}

		//1 - wait till the socket is connected, before emitting the event
		let eventEmitted: boolean = false;
		this.socket.once(EnumSecureSocketEvent.Connect, () => {
			this.emitEventAction(event, encryptedData);
			eventEmitted = true;
		});

		//2 - did the socket connect? was the value send?
		setTimeout(() => {

			//a. was the event emitted successfully?
			if (eventEmitted) return;

			//b. log an error info
			const errorMessage: string = `emitting the event of "${event}" failed, because the socket failed to connect`;
			console.error(`SecureWebSocketWrapper => emitEvent => FATAL ERROR: ${errorMessage} (note: please make sure to call the connect before emitting event)`);

			//c. emit an error
			this.onError$.next({ reason: EnumSecureSocketError.EventEmitTimeout, error: errorMessage });
		}, timeout);

		return this;
	}

	private emitEventAction<T>(event: string, data: T | string) {

		//0 - emit the event to the server
		this.socket.emit(event, data);

		//1 - submit the event to the onEmit subscribable
		this.onEmit$.next({
			event 	: event,
			data  	: data
		});

		//2 - track the history of events / messages
		this.eventHistory.push({
			event 	: event,
			data  	: data,
			date	: new Date()
		});
	}


	/**------------------------------------------------------
	 * Register Event Listeners / Event Handlers
	 */
	onEvent<T>(event: string, onEventCallback: (data: T) => void): SecureWebSocketWrapper {
		this.socket.on(event, (data: T) => {

			//0 - emit the event to the onEmit subscribable
			const decryptedData: T = this.decryptMessage(event, data);

			//1 - submit the event to the onEmit subscribable
			onEventCallback(decryptedData);
		});
		return this;
	}

	onEventOnce<T>(event: string, onEventCallback: (data: T) => void): SecureWebSocketWrapper {
		this.socket.once(event, (data: T) => {
			const decryptedData: T = this.decryptMessage(event, data);
			onEventCallback(decryptedData);
		});
		return this;
	}

	onAnyEvent<T>(onAnyEventCallback: (event: string, data: T) => void): SecureWebSocketWrapper {
		this.socket.onAny((event: string, data: T) => {
			onAnyEventCallback(event, data);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Unregister Event Listeners / Event Handlers
	 */
	offEvent(event: string): SecureWebSocketWrapper {
		this.socket.off(event);						// remove all listeners for that event
		return this;
	}

	offAnyEvent(): SecureWebSocketWrapper {
		this.socket.offAny();						// all catch-all listeners are removed
		return this;
	}

	offAllEvents(): SecureWebSocketWrapper {
		this.socket.off();							// remove all listeners for all events
		return this;
	}


	/**------------------------------------------------------
	 * Status Check Functions
	 */
	isConnected()	: boolean { return this.socket.connected; }
	isDisconnected(): boolean { return !this.isConnected(); }

	onConnectionChange(onConnectionChangeCallback: (connectionStatus: boolean) => void): SecureWebSocketWrapper {
		this.onConnectionChange$.subscribe(() => {
			onConnectionChangeCallback(this.socket.connected);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Other Helpers
	 */
	timeout(timeoutTimeMs: number): SecureWebSocketWrapper {
		if (Util.Number.isNegative(timeoutTimeMs)) throw new Error(`SecureWebSocketWrapper => timeout => FATAL ERROR: the provided timeout time is negative, the timeout time is required to be a positive number (value: "${timeoutTimeMs}")`);
		this.socket.timeout(timeoutTimeMs);
		return this;
	}


	/**------------------------------------------------------
	 * Event History
	 */
	clearEventHistory(): void {
		Util.Array.clear(this.eventHistory);
	}

	getEventHistory(): ISecureEmitEventLog[] {
		return this.eventHistory;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private completeAllObservables() {
		this.onDisconnect$.complete();
		this.onConnectionChange$.complete();
		this.onError$.complete();
		this.onDestroy$.complete();
	}

	private encryptMessage<T>(event: string, data: T) {

		//0 - should the message be encrypted?
		const isEncryptionIgnored: boolean = SECURE_WHITELIST_SOCKET_ENCRYPTION.includes(event);
		if (isEncryptionIgnored) return data;

		//1 - encrypt the response
		return this.encryptionService.encryptData(this.securityOptions, data);
	}

	private decryptMessage<T>(event: string, data: T) {

		//0 - should the message be decrypted?
		const isEncryptionIgnored: boolean = SECURE_WHITELIST_SOCKET_ENCRYPTION.includes(event);
		if (isEncryptionIgnored) return data;

		//1 - decrypted the response
		return this.encryptionService.decryptData(this.securityOptions, data);
	}
}
