/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumAddonPageAddonInjection {
	FacebookSocialConfig 	= 'facebook_social_config',
	DarkModeConfig 			= 'dark_mode_config',
	GoogleRecaptchaKey 		= 'google_recaptcha_key',
	PaddleVendorId 			= 'paddle_vendor_id',
	TapfiliateAccountId 	= 'tapfiliate_account_id',
	GoogleLoginConfig		= 'google_login_config',
	GoogleTagManagerId		= 'google_tag_manager_id'
}


/**------------------------------------------------------
 * Script Service Definition
 */
export interface IAddonAddOnScriptScript {
	create: () => IAddonAddOnScriptScript;
}
