import { COUNTRY_INFOS, EnumCountry, EnumLanguage, EnumLanguageLocale } from '@libs/constants';

import { EnumAmazonMarketplace } from './amazon.constants';
import { EnumPodMarketplace } from './platform.constants';


/**------------------------------------------------------
 * Country Infos
 */
export const MARKETPLACE_INFO: IMarketplaceInfo[] = [
	{
		code			: EnumCountry.US,
		abbreviation	: 'US',
		name			: 'United States',
		link			: 'Amazon.com',
		label			: 'US - United States',
		flagIcon 		: COUNTRY_INFOS[EnumCountry.US].flagIcon.ratio1x1,
		marketplace 	: EnumAmazonMarketplace.COM,
		marketplacePod 	: EnumPodMarketplace.COM,
		language		: EnumLanguage.EN,
		languageName 	: 'English',
		country			: EnumCountry.US,
		locationLanguage: EnumLanguageLocale.enUS,
		currency		: {
			code		: 'usd',
			symbol		: '$'
		}
	},
	{
		code			: EnumCountry.UK,
		abbreviation	: 'GB',
		name			: 'Great Britain',
		link			: 'Amazon.co.uk',
		label			: 'UK - United Kingdom',
		flagIcon 		: COUNTRY_INFOS[EnumCountry.UK].flagIcon.ratio1x1,
		marketplace 	: EnumAmazonMarketplace.CO_UK,
		marketplacePod 	: EnumPodMarketplace.CO_UK,
		language		: EnumLanguage.EN,
		languageName 	: 'English',
		country			: EnumCountry.UK,
		locationLanguage: EnumLanguageLocale.enGB,
		currency		: {
			code		: 'gbp',
			symbol		: '£'
		}
	},
	{
		code			: EnumCountry.DE,
		abbreviation	: 'DE',
		name			: 'Germany',
		link			: 'Amazon.de',
		label			: 'DE - Germany',
		flagIcon 		: COUNTRY_INFOS[EnumCountry.DE].flagIcon.ratio1x1,
		marketplace 	: EnumAmazonMarketplace.DE,
		marketplacePod 	: EnumPodMarketplace.DE,
		language		: EnumLanguage.DE,
		languageName 	: 'German',
		country			: EnumCountry.DE,
		locationLanguage: EnumLanguageLocale.deDE,
		currency		: {
			code		: 'eur',
			symbol		: '€'
		}
	},
	{
		code			: EnumCountry.FR,
		abbreviation	: 'FR',
		name			: 'France',
		link			: 'Amazon.fr',
		label			: 'FR - France',
		flagIcon 		: COUNTRY_INFOS[EnumCountry.FR].flagIcon.ratio1x1,
		marketplace 	: EnumAmazonMarketplace.FR,
		marketplacePod 	: EnumPodMarketplace.FR,
		language		: EnumLanguage.FR,
		languageName 	: 'French',
		country			: EnumCountry.FR,
		locationLanguage: EnumLanguageLocale.frFR,
		currency		: {
			code		: 'eur',
			symbol		: '€'
		}
	},
	{
		code			: EnumCountry.ES,
		abbreviation	: 'ES',
		name			: 'Spain',
		link			: 'Amazon.es',
		label			: 'ES - Spain',
		flagIcon 		: COUNTRY_INFOS[EnumCountry.ES].flagIcon.ratio1x1,
		marketplace 	: EnumAmazonMarketplace.ES,
		marketplacePod 	: EnumPodMarketplace.ES,
		language		: EnumLanguage.ES,
		languageName 	: 'Spanish',
		country			: EnumCountry.ES,
		locationLanguage: EnumLanguageLocale.esES,
		currency		: {
			code		: 'eur',
			symbol		: '€'
		}
	},
	{
		code			: EnumCountry.IT,
		abbreviation	: 'IT',
		name			: 'Italy',
		link			: 'Amazon.it',
		label			: 'IT - Italy',
		flagIcon 		: COUNTRY_INFOS[EnumCountry.IT].flagIcon.ratio1x1,
		marketplace 	: EnumAmazonMarketplace.IT,
		marketplacePod 	: EnumPodMarketplace.IT,
		language		: EnumLanguage.IT,
		languageName 	: 'Italian',
		country			: EnumCountry.IT,
		locationLanguage: EnumLanguageLocale.itIT,
		currency		: {
			code		: 'eur',
			symbol		: '€'
		}
	}
];


//** Interfaces --------------------------------- */
export interface IMarketplaceInfo {
	code			: EnumCountry;
	abbreviation	: string;
	name			: string;
	label			: string;
	link			: string;
	flagIcon 		: string;
	marketplace 	: EnumAmazonMarketplace;
	marketplacePod 	: EnumPodMarketplace;
	language	 	: EnumLanguage;
	languageName 	: string;
	country			: EnumCountry;
	locationLanguage: EnumLanguageLocale;
	currency		: {
		code		: string;
		symbol		: string;
	};
}
