import { UtilArray } from './util-array';
import { UtilObject } from './util-object';
import { UtilString } from './util-string';


/**------------------------------------------------------
 * HTTP Utilities
 * --------------
 * > Info: Containing all functionalities related url
 * > and request encodings.
 * --------------
 * > git querystring: https://github.com/Gozala/querystring#readme
 */
export class UtilHttp {

	//** Configurations */
	private readonly TEST_IPV4_ADDRESS_REGEX		: RegExp = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
	private readonly TEST_LOCAL_IPV4_ADDRESS_REGEX	: RegExp = /^(127\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}|10\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}|192\.168\.[0-9]{1,3}\.[0-9]{1,3}|172\.(1[6-9]|2[0-9]|3[0-1])\.[0-9]{1,3}\.[0-9]{1,3})$/;
	private readonly TEST_IPV6_ADDRESS_REGEX		: RegExp = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9])|[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9])|[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9])|[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9])|[0-9]))$/;
	private readonly TEST_LOCAL_IPV6_ADDRESS_REGEX	: RegExp = /^::1$/;

	private readonly HTTP_STATUS_MIN_SUCCESS		: number = 200;
	private readonly HTTP_STATUS_MAX_SUCCESS		: number = 299;


	constructor(
		private utilString	: UtilString,
		private utilObject	: UtilObject,
		private utilArray	: UtilArray
	) {}


	/**------------------------------------------------------
	 * Handle Body Parameters
	 */
	stringifyBody(bodyObj: object, separator?: string): string {

		//0 - set the default values
		if (!this.utilObject.isObject(bodyObj)) throw new Error(`UtilHttp => stringifyBody => FATAL ERROR: bodyObj is not an object`);
		separator = !this.utilString.isEmpty(separator) ? separator : '&';

		//1 - stringify the body elements
		const stringifiedBodyParts: string[] = [];
		for (const [key, value] of Object.entries(bodyObj)) {

			//a. convert element to string
			const elemName 	: string = `${encodeURIComponent(this.utilString.stringifyPrimitive(key))}=`;
			const elemString: string = this.utilArray.isArray(value)
				? [...value].map((elem: any) => elemName + encodeURIComponent(this.utilString.stringifyPrimitive(elem))).join(separator)
				: elemName + encodeURIComponent(this.utilString.stringifyPrimitive(value));

			//b. add the element to the result
			stringifiedBodyParts.push(elemString);
		}

		//2 - return the result
		return stringifiedBodyParts.join(separator);
	}


	/**------------------------------------------------------
	 * Handler URL's
	 */
	isUrlValid(url: string): boolean {
		try {
			const urlObject: URL = new URL(url);
			return urlObject.protocol === 'http:' || urlObject.protocol === 'https:';
		} catch (error: unknown) {
			return false;
		}
	}
	isAbsoluteUrl(url: string): boolean {
		return /^[a-z][a-z0-9+.-]*:/.test(url);
	}

	//** Extract hostname from url */
	// > code source : https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
	// > original	 : http://www.youtube.com/watch?v=ClkQA2Lb_iE
	// > hostname	 : www.youtube.com
	extractHostname(url: string): string {

		//0 - find & remove protocol (http, ftp, etc.) and get hostname
		let hostname: string = url.includes('//')
			? url.split('/')[2]
			: url.split('/')[0];

		//1 - find & remove port number
		hostname = hostname.split(':')[0];

		//2 - find & remove "?"
		hostname = hostname.split('?')[0];
		return hostname;
	}

	//** Extract domain from url */
	// > code source : https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
	// > original	 : http://www.youtube.com/watch?v=ClkQA2Lb_iE
	// > hostname	 : youtube.com
	extractRootDomain(url: string): string {

		//0 - prepare the data
		let   domain  : string   = this.extractHostname(url);
		const splitArr: string[] = domain.split('.');
		const arrLen  : number   = splitArr.length;

		//1 - extracting the root domain here, if there is a subdomain
		if (arrLen > 2) {
			domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`;
			// check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
			if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
				domain = `${splitArr[arrLen - 3]}.${domain}`;		// this is using a ccTLD
			}
		}
		return domain;
	}

	//** Extract domain from url */
	// > original	 : http://www.youtube.com/watch?v=ClkQA2Lb_iE
	// > hostname	 : http://www.youtube.com/watch
	extractDomain(url: string): string {
		this.checkUrl(url);

		//0 - remove protocol (http, ftp, etc.) and get hostname
		let linkUrl: string = url.includes('//')
			? url.split('//')[1]
			: url.split('//')[0];

		//1 - remove the parameters
		if (linkUrl.includes('?')) linkUrl = linkUrl.slice(0, linkUrl.indexOf('?'));
		return linkUrl;
	}

	normalizeHostUrl(host: string, port: string): string {
		if (this.utilString.isEmpty(host) || this.utilString.isEmpty(port)) throw new Error(`UtilHttp => normalizeHostUrl => FATAL ERROR: provided host or host is empty`);
		return `${host}:${port}`;
	}
	encodeUrl(url: string): string {
		this.checkUrl(url);
		return encodeURIComponent(url);
	}
	decodeUrl(url: string): string {
		this.checkUrl(url);
		return decodeURIComponent(url);
	}


	/**------------------------------------------------------
	 * Join Url
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/URLJoin/
	 * > example : urlJoin(['http://www.google.com', 'a', '/b/cd', '?foo=123', '?bar=foo'])  =>  'http://www.google.com/a/b/cd?foo=123&bar=foo'
	 */
	urlJoin(urlParts: string[]): string {
		return urlParts.join('/')
			.replace(/[/]+/g, '/')
			.replace(/^(.+):\//, '$1://')
			.replace(/^file:/, 'file:/')
			.replace(/\/(\?|&|#[^!])/g, '$1')
			.replace(/\?/g, '&')
			.replace('&', '?');
	}
	urlJoinWithParams<T extends object>(urlParts: string[], queryParameters: T): string {
		return this.urlJoin(urlParts) + this.objectToQueryString(queryParameters);
	}


	/**------------------------------------------------------
	 * Convert ParameterObject to QueryString
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/objectToQueryString/
	 * > example : { page: '1', size: '2kg', key: undefined } => '?page=1&size=2kg'
	 */
	objectToQueryString<T extends object>(queryParameters: T): string {

		//0 - was anything defined
		if (this.utilObject.isEmpty(queryParameters)) return '';

		//1 - construct the query string form the object parameters
		return Object.entries(queryParameters).reduce(
			(queryString: string, [key, value]: [string, any], index: number) => {

				//a. query starting symbol
				const symbol: string = queryString.length === 0 ? '?' : '&';

				//b. all element to query
				queryString += value ? `${symbol}${key}=${value}` : '';
				return queryString;
			},
			''
		);
	}


	/**------------------------------------------------------
	 * URL parameter extraction
	 * ------------------------
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/getURLParameters/
	 * > example : http://url.com/page?name=Adam&surname=Smith&surname=Sm&surname=Tm
	 * 				-> { name: "Adam", surname: ["Smith", "Sm", "Tm"] }
	 */
	getUrlParameters(url: string): TypeUtilUrlParams {
		this.checkUrl(url);
		return (url.match(/([^?=&]+)(=([^&]*))/g) || [] as string[]).reduce<TypeUtilUrlParams>((acc: TypeUtilUrlParams, urlValue: string) => {
			const [key, value] = urlValue.split('=');
			if (acc[key]) {
				acc[key] = ((typeof acc[key] === 'string'
					? [acc[key]]
					: acc[key]) as string[]).concat(value);
			} else {
				acc[key] = value;
			}
			return acc;
		}, {});
	}


	/**------------------------------------------------------
	 * Parse Cookie
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/parseCookie/
	 * > example : "foo=bar; equation=E%3Dmc%5E2"   => { foo: 'bar', equation: 'E=mc^2' }
	 */
	parseCookie(cookieString: string): TypeUtilCookieObject {

		try {
			//0 - try to parse the cookie
			const parsedCookies: TypeUtilCookieObject = cookieString.split(';')
				.map((cookieValue: string) => cookieValue.split('='))
				.reduce((acc: object, cookieValues: string[]) => {
					(acc as any)[decodeURIComponent(cookieValues[0].trim())] = decodeURIComponent(cookieValues[1].trim());
					return acc;
				}, {}) as TypeUtilCookieObject;

			//1 - returned the parsed cookie
			return parsedCookies;
		} catch (error: unknown) {
			throw new Error(`UtilHttp => parseCookie => FATAL ERROR: the cookieString of "${cookieString}" seams to be invalid (example of a cookie string: "foo=bar; equation=E%3Dmc%5E2")`);
		}
	}


	/**------------------------------------------------------
	 * IP
	 */
	isValidIpv4(ipAddress: string): boolean {
		return this.TEST_IPV4_ADDRESS_REGEX.test(ipAddress);
	}

	isValidLocalIpv4(ipAddress: string): boolean {
		return this.TEST_LOCAL_IPV4_ADDRESS_REGEX.test(ipAddress);
	}

	isValidIpv6(ipAddress: string): boolean {
		return this.TEST_IPV6_ADDRESS_REGEX.test(ipAddress);
	}

	isValidLocalIpv6(ipAddress: string): boolean {
		return this.TEST_LOCAL_IPV6_ADDRESS_REGEX.test(ipAddress);
	}


	/**------------------------------------------------------
	 * Others
	 */
	isHttpStatusSuccess(statusCode: number): boolean {
		// http status codes from 200 till 299 indicate a success
		return statusCode >= this.HTTP_STATUS_MIN_SUCCESS && statusCode <= this.HTTP_STATUS_MAX_SUCCESS;
	}

	purifyRoute(route: string): string {

		//0 - route prefixed with "https://"
		if (route.includes('://')) {
			const [scheme, rest]: string[] = route.split('://');
			return `${scheme}://${rest.replace(/\/\//g, '/')}`;
		}

		//1 - cleanup a relative route
		// > Note: cleanup the '/' in the route, let each route start with '/'
		route = route.replace(/\/\//g, '/');
		if (!route.startsWith('/')) route = `/${route}`;
		return route;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private checkUrl(url: string) {
		if (this.utilString.isEmpty(url)) throw new Error(`UtilHttp => getBaseURL => FATAL ERROR: provided url of "${url}" is empty or not a string`);
	}
}


//** Types -------------------------------------- */
export type TypeUtilUrlParams    = Record<string, string | string[]>;
export type TypeUtilCookieObject = Record<string, string>;
