import { Util } from '@libs/utilities/util';

import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';
import { EnumValidatorOperationType } from '../shared/validator-value.interface';


export class ValidatorValueBoolean extends AbstractValidatorValue<ValidatorValueBoolean> {

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'boolean'; }
	protected isValueTypeValid(value: any): boolean { return Util.Boolean.isBoolean(value) || value === 'true' || value === 'false'; }

	constructor() {

		//0 - initialize parent
		super();

		//1 - if we have a boolean as string, convert it to boolean
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string | number | boolean) => (Util.Boolean.isBoolean(value)
				? value
				: Util.Boolean.toBoolean(value))
		});
	}


	/**------------------------------------------------------
	 * Validators
	 */
	isTrue(): ValidatorValueBoolean {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Boolean.isTrue(value),
				error		: `Value must be true`
			})
		});
		return this;
	}

	isFalse(): ValidatorValueBoolean {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Boolean.isFalse(value),
				error		: `Value must be false`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Transformers
	 */
	toTrue(): ValidatorValueBoolean {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: number) => true
		});
		return this;
	}

	toFalse(): ValidatorValueBoolean {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: number) => false
		});
		return this;
	}
}
