import { EnumSubscriptionModule } from '@apps/flying/shared';


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingAccessTokenData {
	accessRights    : IFlyingAccessRight[];
	refreshTokenId  : string;
}

export interface IFlyingAccessRight {
	expires 		: Date;
	module  		: EnumSubscriptionModule;
}
