import { EnumDisplateCategory, IDisplateText, IGlobalText, IMbaProduct, IMbaText, IPodListing, IPodListingDisplate, IPodListingGlobal, IPodListingMba, IAmazonMbaProduct, MBA_PRODUCT_SPECIFICATION, EnumMbaColor, MBA_COLOR_SPECIFICATION, IAmazonMbaColor, EnumMbaProduct } from '@apps/flying/shared';
import { Util } from '@libs/utilities/util';

import { FLYING_DISPLATE_LISTING_LIMITATIONS, FLYING_GLOBAL_LISTING_LIMITATIONS, FLYING_MBA_LISTING_LIMITATIONS, FLYING_DESIGN_TAG_LIMITATIONS } from '../../interfaces';


/**------------------------------------------------------
 * Listing Limitations
 * -------------------
 * > The platforms like Merch By Amazon, Spreadshirt, ... define
 * > limitations for texts like title length, description length,
 * > and much more. This service is to validate the listing against
 * > the limitations and to resolve any problems.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class ListingLimitationsServiceOld {

	//** Configurations */
	private readonly DEFAULT_GLOBAL_LIMITATIONS: IGlobalLimitationsOld = {
		titleLength	: FLYING_GLOBAL_LISTING_LIMITATIONS.TITLE_LENGTH,
		descLength	: FLYING_GLOBAL_LISTING_LIMITATIONS.DESCRIPTION_LENGTH
	};


	/**------------------------------------------------------
	 * Pod Listing Text
	 */
	applyPodLimitations(listing: IPodListing, globalLimits: IGlobalLimitationsOld = this.DEFAULT_GLOBAL_LIMITATIONS): void {

		//0 - shorten the listing tags
		this.applyTagsLimitations(listing);

		//1 - cut all over length texts
		this.applyGlobalLimitations(listing?.global, globalLimits);
		this.applyMbaLimitations(listing?.mba);
		this.applyDisplateLimitations(listing?.displate);
	}

	private applyTagsLimitations(listing: IPodListing) {

		//0 - do we have any tags?
		const listingTags: string[] = listing?.tags;
		if (Util.Array.isEmpty(listingTags)) return;

		//1 - apply the limitation on the tags
		listing.tags = this.cutArrayOverLength(listing.tags, FLYING_DESIGN_TAG_LIMITATIONS.MAX_TAGS)
			.filter((elem: string) => elem.length <= FLYING_DESIGN_TAG_LIMITATIONS.TAG_LENGTH);
	}


	/**------------------------------------------------------
	 * Global Listing Text
	 */
	applyGlobalLimitations(globalListing: IPodListingGlobal | null, globalLimits: IGlobalLimitationsOld): void {

		//0 - does the global listing has any text?
		const globalTexts: IGlobalText[] = globalListing?.texts || [];
		if (Util.Array.isEmpty(globalTexts)) return;

		//1 - check for over length in the listing texts
		for (const globalText of globalTexts) {
			globalText.title 		= this.cutTextOverLengthByWord(globalText.title, 		globalLimits.titleLength);
			globalText.description 	= this.cutTextOverLengthByWord(globalText.description, 	globalLimits.descLength);
			globalText.keywords 	= this.cutArrayOverLength(globalText.keywords, 	 		FLYING_GLOBAL_LISTING_LIMITATIONS.MAX_KEYWORDS)
				.filter((elem: string) => elem.length <= FLYING_GLOBAL_LISTING_LIMITATIONS.KEYWORD_LENGTH);
		}
	}


	/**------------------------------------------------------
	 * Mba Listing Text
	 */
	applyMbaLimitations(mbaListing: IPodListingMba | null): void {

		//0 - does the mba listing has any text?
		const mbaTexts	 : IMbaText[] 	 = mbaListing?.texts || [];
		const mbaProducts: IMbaProduct[] = mbaListing?.products || [];
		if (Util.Array.isEmpty(mbaTexts) || Util.Array.isEmpty(mbaProducts)) return;

		//1 - check for over length in the listing texts
		for (const mbaText of mbaTexts) {
			mbaText.brand 		= this.cutTextOverLengthByWord(mbaText.brand, 	  	FLYING_MBA_LISTING_LIMITATIONS.BRAND_LENGTH);
			mbaText.title 		= this.cutTextOverLengthByWord(mbaText.title, 	  	FLYING_MBA_LISTING_LIMITATIONS.TITLE_LENGTH);
			mbaText.bullet1 	= this.cutTextOverLengthByWord(mbaText.bullet1, 	FLYING_MBA_LISTING_LIMITATIONS.BULLET1_LENGTH);
			mbaText.bullet2 	= this.cutTextOverLengthByWord(mbaText.bullet2, 	FLYING_MBA_LISTING_LIMITATIONS.BULLET2_LENGTH);
			mbaText.description = this.cutTextOverLengthByWord(mbaText.description, FLYING_MBA_LISTING_LIMITATIONS.DESCRIPTION_LENGTH);
		}

		//2 - apply additional limitations
		for (const product of mbaProducts) {

			//a. fix background legacy data
			this.fixMbaBackgroundColor(product);

			//b. limit the max colors
			product.colors = Util.Array.shorten(product.colors, FLYING_MBA_LISTING_LIMITATIONS.MAX_COLORS);
		}
	}

	private fixMbaBackgroundColor(product: IMbaProduct) {

		//a. does the product support background color?
		const mbaProductConfig: IAmazonMbaProduct | undefined = MBA_PRODUCT_SPECIFICATION.find((elem: IAmazonMbaProduct) => elem.code === product.productCode);
		if (!mbaProductConfig) throw new Error(`ListingLimitationsHelper => fixMbaBackgroundColor => FATAL ERROR: no mba product specification found for the productCode of "${product.productCode}" (valid are: ${Util.Enum.values(EnumMbaProduct)})`);
		if (!mbaProductConfig.colors.background) return;

		//b. product supported background color then is the color left empty?
		if (Util.String.isEmpty(product.background) || Util.Basic.isUndefined(product.background)) {
			product.background = EnumMbaColor.NoColor;
			return;
		}

		//c. is background color valid
		if (Util.Color.isHexColor(product.background!) || product.background === EnumMbaColor.NoColor) return;

		//d. legacy issue, color name was set as background (fix the issue by mapping the color name)
		const colorConfig: IAmazonMbaColor | undefined = MBA_COLOR_SPECIFICATION.find((elem: IAmazonMbaColor) => elem.code === product.background);
		if (!colorConfig) throw new Error(`ListingLimitationsHelper => fixMbaBackgroundColor => FATAL ERROR: no mba color specification found for color name of "${product.background}"`);
		product.background = colorConfig.colorCode;
	}


	/**------------------------------------------------------
	 * Filter Displate Listing Text
	 */
	applyDisplateLimitations(displateListing: IPodListingDisplate | null): void {

		//0 - does the displate listing has any text?
		const displateText: IDisplateText | undefined = displateListing?.text;
		if (!displateListing || !displateText) return;

		//1 - check for over length in the listing texts
		displateText.title 		 = this.cutTextOverLengthByWord(displateText.title, FLYING_DISPLATE_LISTING_LIMITATIONS.TITLE_LENGTH);
		displateText.description = this.cutTextOverLengthByWord(displateText.description, FLYING_DISPLATE_LISTING_LIMITATIONS.DESCRIPTION_LENGTH);
		displateText.keywords 	 = this.cutArrayOverLength(displateText.keywords, FLYING_DISPLATE_LISTING_LIMITATIONS.MAX_KEYWORDS);

		//2 - apply additional limitations
		displateListing.options.collection = this.cutTextOverLengthByLength(displateListing.options.collection, FLYING_DISPLATE_LISTING_LIMITATIONS.COLLECTION_LENGTH);
		displateListing.options.categories = this.cutArrayOverLength(displateListing.options.categories, FLYING_DISPLATE_LISTING_LIMITATIONS.MAX_CATEGORIES) as EnumDisplateCategory[];
	}


	/**------------------------------------------------------
	 * Filter KDP Listing text
	 */
	applyKdpLimitations(): string {
		throw new Error(`ListingTextHelper => getKdpListingText => FATAL ERROR: ... todo, not implemented yet`);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private cutTextOverLengthByLength(text: string, maxLength: number): string {
		const purifiedText: string = Util.String.removeSpecialCharacters(text);
		return Util.Text.shortenByLength(purifiedText, maxLength);
	}

	private cutTextOverLengthByWord(text: string, maxLength: number): string {
		const purifiedText: string = Util.String.removeSpecialCharacters(text);
		return Util.Text.shortenByWord(purifiedText, maxLength);
	}

	private cutArrayOverLength(tagsOrKeywords: string[], maxLength: number): string[] {
		const purifiedTagsOrKeywords: string[] = tagsOrKeywords.map((elem: string) => Util.String.removeSpecialCharacters(elem));
		const uniqueTagsOrKeywords  : string[] = Util.Array.unique(purifiedTagsOrKeywords);
		return Util.Array.shorten(uniqueTagsOrKeywords, maxLength);
	}
}


//** Interfaces --------------------------------- */
// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGlobalLimitationsOld {
	titleLength	: number;
	descLength	: number;
}
