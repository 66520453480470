import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';


/**------------------------------------------------------
 * Html Element Renderer
 */
@Injectable()
export class AddonPageAddonsRenderer {

	constructor(
		@Inject(DOCUMENT)
		private document: Document
	) {}

	/**------------------------------------------------------
	 * Div Element
	 */
	addDivElementToHtml(addOnName: string, params: IAddonRenderParams): HTMLDivElement {

		//0 - create div element
		const div: HTMLDivElement = this.document.createElement('div');

		//1 - set innerHTML
		if (params.innerHTML) div.innerHTML = params.innerHTML;

		//2 - set attributes
		if (params.attributes) {
			for (const [attribute, value] of Object.entries(params.attributes)) {
				div.setAttribute(attribute, value);
			}
		}

		//3 - append to head
		div.setAttribute('add-on-id', addOnName);					// set an identifier to have access later
		return this.document.body.appendChild(div);
	}


	/**------------------------------------------------------
	 * Script & NoScript Element
	 */
	addScriptToHead(addOnName: string, params: IAddonRenderParams): HTMLScriptElement {

		//0 - create script element
		const script: HTMLScriptElement = this.document.createElement('script');
		script.type = 'text/javascript';

		//1 - set innerHTML
		if (params.innerHTML) script.innerHTML = params.innerHTML;

		//2 - set attributes
		if (params.attributes) {
			for (const [attribute, value] of Object.entries(params.attributes)) {
				script.setAttribute(attribute, value);
			}
		}

		//3 - append to head
		script.setAttribute('add-on-id', addOnName);					// set an identifier to have access later
		return this.document.head.appendChild(script);
	}

	addNoScriptToHead(addOnName: string, params: IAddonRenderParams): HTMLElement {

		//0 - create script element
		const noscript: HTMLElement	= this.document.createElement('noscript');
		noscript.innerHTML = params.innerHTML || '';

		//1 - append to head
		noscript.setAttribute('add-on-id', addOnName);					// set an identifier to have access later
		return this.document.head.appendChild(noscript);
	}

	addNoScriptToBody(addOnName: string, params: IAddonRenderParams): HTMLElement {

		//0 - create script element
		const noscript: HTMLElement	= this.document.createElement('noscript');
		noscript.innerHTML = params.innerHTML || '';

		//1 - append to head
		noscript.setAttribute('add-on-id', addOnName);					// set an identifier to have access later
		return this.document.body.appendChild(noscript);
	}


	/**------------------------------------------------------
	 * Style Element
	 */
	addStyleToHtml(addOnName: string, params: IAddonRenderParams): HTMLStyleElement {

		//0 - create script element
		const style: HTMLStyleElement = this.document.createElement('style');

		//1 - set innerHTML
		if (params.innerHTML) style.innerHTML = params.innerHTML;

		//2 - set attributes
		if (params.attributes) {
			for (const [attribute, value] of Object.entries(params.attributes)) {
				style.setAttribute(attribute, value);
			}
		}

		//3 - append to head
		style.setAttribute('add-on-id', addOnName);					// set an identifier to have access later
		return this.document.head.appendChild(style);
	}


	/**------------------------------------------------------
	 * Remove Element
	 */
	remove(addOnName: string): void {
		const addOnElements: NodeListOf<Element> = this.document.querySelectorAll(`[add-on-id="${addOnName}}"]`);
		for (const element of addOnElements) element.remove();
	}
}


//** Interfaces --------------------------------- */
interface IAddonRenderParams {
	innerHTML	?: string;
	attributes	?: object;
}
