import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingGetFreeUploads {
	userEmail	: string;
}

export interface IFlyingUpdateFreeUploads {
	userEmail	: string;
	uploadType	: EnumFlyingUploadType;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingUploadType {
	POD 	= 'pod',
	KDP 	= 'kdp',
	STOCK 	= 'stock'
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingGetFreeUploadsSchema implements ValidatorSchema<IFlyingGetFreeUploads> {
	userEmail = Validator.String().isNotEmpty().isEmail();
}

export class FlyingUpdateFreeUploadsSchema implements ValidatorSchema<IFlyingUpdateFreeUploads> {
	userEmail	= Validator.String().isNotEmpty().isEmail();
	uploadType	= Validator.Enum(EnumFlyingUploadType).isNotEmpty();
}
