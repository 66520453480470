//** Research */
export * from './research-settings/research-settings.constants';
export * from './research-settings/research-settings.interface';
export * from './research/amazon-mba-design.interface';
export * from './research/amazon-kdp-book.interface';
export * from './research/pod-design.interface';

//** Keywords */
export * from './keywords/keyword.interface';
export * from './keywords/amazon/amazon-keyword.interface';
export * from './keywords/amazon/amazon-product-rank.interface';
export * from './keywords/google/google-keyword.interface';
export * from './keywords/spreadshirt-tags/spreadshirt-tags.interface';

//** Trademarks */
export * from './trademarks/trademarks.interface';

//** Advertisement */
export * from './advertising-list/advertising-list.interface';

//** Keyword List */
export * from './kw-list/kw-list.interface';
