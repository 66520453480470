import { IAmazonProductRank } from '../../../data-structures';


/**------------------------------------------------------
 * Amazon Clothing Product Ranks Request API-Interfaces
 */
export interface IGetAmazonClothingProductRanksRequest {
	asinList: string[];
}


/**------------------------------------------------------
 * Amazon Clothing Product Ranks Response API-Interfaces
 */
export interface IGetAmazonClothingProductRanksResponse {
	productRanks: IAmazonProductRank[];
}
