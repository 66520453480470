import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { Util } from '@libs/utilities/util';

import { TypeAddonWindowTapfiliate } from './tapfiliate.interface';
import { AddonPageAddonsRenderer } from '../../shared/page-addons-renderer.helper';
import { EnumAddonPageAddonInjection, IAddonAddOnScriptScript } from '../../shared/page-addons.interface';


@Injectable()
export class AddonTapfiliateScript implements IAddonAddOnScriptScript {

	//** Window object to access the script functions */
	private readonly window: TypeAddonWindowTapfiliate;

	constructor(
		@Inject(EnumAddonPageAddonInjection.TapfiliateAccountId)
		private accountId	: string,
		@Inject(DOCUMENT)
		private document	: Document,
		private renderHelper: AddonPageAddonsRenderer
	) {
		// set the window object
		this.window = this.document.defaultView as TypeAddonWindowTapfiliate;
		this.window.tapfiliateOnLoad$ = new Subject<void>();
	}

	onLoadScript(): Observable<void> {
		return this.window.tapfiliateOnLoad$.asObservable();
	}

	create(): AddonTapfiliateScript {

		//0 - check if the tapfiliateAccountId was configured
		if (Util.String.isEmpty(this.accountId)) throw new Error(`AddonTapfiliateScript => create => FATAL ERROR: Tapfiliate Account ID was not provided in the module (value is: "${this.accountId}")`);

		//1 - create the src
		const script1Attributes: { src: string } = {
			src 	: 'https://script.tapfiliate.com/tapfiliate.js'
		};

		//2 - create the scripts
		const script2InnerHtml: string = `
			(function (t, a, p) {
				t.TapfiliateObject = a; t[a] = t[a] || function () {
					(t[a].q = t[a].q || []).push(arguments)
				}
			})(window, 'tap');

			tap('create', '${this.accountId}', { integration: "javascript" }, window.tapfiliateOnLoad$.next());
			tap('detect');

			function tapDetect(detectOptions, successCallback) {
				tap('detect', detectOptions, successCallback);
			}

			function tapConversion(customerId) {
				tap('trial', customerId);
			}`;

		//3 - add the src & script to the html
		this.renderHelper.addScriptToHead('tapfiliate', { attributes: script1Attributes });
		this.renderHelper.addScriptToHead('tapfiliate', { innerHTML: script2InnerHtml });
		return this;
	}
}
