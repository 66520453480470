import { KEY_CODES } from '@libs/libraries/frontend';

import { EnumNgConformAction } from '../conform-modal.interface';
import { EnumNgFaStyle } from '../../../../styling-components';
import { EnumNgConfirmModalTypes, INgConfirmModalConfigs } from './conform-modal-config.interface';


/**------------------------------------------------------
 * Conform Modal Configurations
 */
export const NG_PRE_DEFINED_CONFORM_MODALS: INgConfirmModalConfigs = {
	[EnumNgConfirmModalTypes.DefaultConfirm] : {
		style 		: {
			color	: '--blue',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'comment'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Continue',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--blue',
				actionKey	 : [KEY_CODES.escape, KEY_CODES.enter]
			}
		]
	},
	[EnumNgConfirmModalTypes.DefaultOption] : {
		style 		: {
			color	: '--blue',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'comment'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Yes',
				actionCode	 : EnumNgConformAction.Continue,
				color 	 	 : '--blue',
				actionKey	 : [KEY_CODES.enter]
			},
			{
				buttonText	 : 'Close',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		]
	},
	[EnumNgConfirmModalTypes.SuccessConfirm] : {
		style 		: {
			color	: '--green',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'circle-check'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Understand',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--green',
				actionKey	 : [KEY_CODES.escape, KEY_CODES.enter]
			}
		]
	},
	[EnumNgConfirmModalTypes.SuccessOption] : {
		style 		: {
			color	: '--green',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'circle-check'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Yes',
				actionCode	 : EnumNgConformAction.Continue,
				color 	 	 : '--green',
				actionKey	 : [KEY_CODES.enter]
			},
			{
				buttonText	 : 'Continue',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		]
	},
	[EnumNgConfirmModalTypes.InfoConfirm] : {
		style 		: {
			color	: '--yellow',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'lightbulb'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Understand',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--yellow',
				actionKey	 : [KEY_CODES.escape, KEY_CODES.enter]
			}
		]
	},
	[EnumNgConfirmModalTypes.InfoOption] : {
		style 		: {
			color	: '--yellow',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'lightbulb'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Yes',
				actionCode	 : EnumNgConformAction.Continue,
				color 	 	 : '--yellow',
				actionKey	 : [KEY_CODES.enter]
			},
			{
				buttonText	 : 'Cancel',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		]
	},
	[EnumNgConfirmModalTypes.WarningConfirm] : {
		style 		: {
			color 	: '--orange',
			icon 	: {
				style: EnumNgFaStyle.Solid,
				name : 'triangle-exclamation'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Close',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--orange',
				actionKey	 : [KEY_CODES.escape, KEY_CODES.enter]
			}
		]
	},
	[EnumNgConfirmModalTypes.WarningOption] : {
		style 		: {
			color	: '--orange',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'triangle-exclamation'
			}
		},
		buttons 	: [
			{
				buttonText	 : 'Yes',
				actionCode	 : EnumNgConformAction.Continue,
				color 	 	 : '--orange',
				actionKey	 : [KEY_CODES.enter]
			},
			{
				buttonText	 : 'Cancel',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		]
	},
	[EnumNgConfirmModalTypes.ErrorConfirm] : {
		style 		: {
			color	: '--red',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'exclamation'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Close',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--red',
				actionKey	 : [KEY_CODES.escape, KEY_CODES.enter]
			}
		]
	},
	[EnumNgConfirmModalTypes.ErrorOption] : {
		style 		: {
			color	: '--red',
			icon	: {
				style: EnumNgFaStyle.Solid,
				name : 'exclamation'
			}
		},
		buttons		: [
			{
				buttonText	 : 'Yes',
				actionCode	 : EnumNgConformAction.Continue,
				color 	 	 : '--red',
				actionKey	 : [KEY_CODES.enter]
			},
			{
				buttonText	 : 'Cancel',
				actionCode	 : EnumNgConformAction.Close,
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		]
	}
};
