import { EnumLanguage } from '@libs/constants';
import { Util } from '@libs/utilities/util';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';

/**------------------------------------------------------
 * Configurations
 */
export const FLYING_MBA_LISTING_LIMITATIONS: IFlyingMbaListingConfig = {
	BRAND_LENGTH 			: 50,
	TITLE_LENGTH 			: 60,
	BULLET1_LENGTH 			: 256,
	BULLET2_LENGTH 			: 256,
	DESCRIPTION_LENGTH 		: 2000,
	MAX_COLORS 				: Number.MAX_SAFE_INTEGER,
	BACKGROUND_LENGTH		: 100,
	TRANSLATE_LENGTH		: 100,
	AMAZON_EDIT_ID_LENGTH	: 100
};

interface IFlyingMbaListingConfig {
	BRAND_LENGTH 			: number;
	TITLE_LENGTH 			: number;
	BULLET1_LENGTH 			: number;
	BULLET2_LENGTH 			: number;
	DESCRIPTION_LENGTH 		: number;
	MAX_COLORS 				: number;
	BACKGROUND_LENGTH		: number;
	TRANSLATE_LENGTH		: number;
	AMAZON_EDIT_ID_LENGTH	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingMbaMarketplace {
	COM 			= 'com',
	CO_UK 			= 'co_uk',
	DE 				= 'de',
	FR 				= 'fr',
	IT 				= 'it',
	ES 				= 'es',
	JP 				= 'jp'
}

export enum EnumFlyingMbaProduct {
	StandardShirt 	= 'standard_shirt',
	PremiumShirt 	= 'premium_shirt',
	VNeckShirt 		= 'vneck_shirt',
	TankTop 		= 'tank_top',
	LongSleeveShirt = 'long_sleeve_shirt',
	Raglan 			= 'raglan',
	Sweatshirt 		= 'sweatshirt',
	PulloverHoodie 	= 'pullover_hoodie',
	ZipHoodie 		= 'zip_hoodie',
	PopSockets 		= 'popsockets',
	IPhoneCases 	= 'iphone_cases',
	SamsungCases 	= 'samsung_cases',
	ToteBag 		= 'tote_bag',
	ThrowPillows 	= 'throw_pillows'
}

export enum EnumFlyingMbaPrint {
	Front  			= 'front',
	Back 			= 'back'
}

export enum EnumFlyingMbaFitType {
	Men  			= 'men',
	Women 			= 'women',
	Youth			= 'youth'
}

export enum EnumFlyingMbaAvailability {
	Public 			= 'public',
	Private			= 'private'
}

export enum EnumFlyingMbaPublish {
	Live  			= 'live',
	Draft			= 'draft'
}

export enum EnumFlyingMbaColor {
	NoColor				= 'no_color',
	Asphalt				= 'asphalt',
	BabyBlue			= 'baby_blue',
	Black				= 'black',
	Brown				= 'brown',
	Cranberry			= 'cranberry',
	DarkHeather			= 'dark_heather',
	ForestGreen			= 'forest_green',
	Grass				= 'grass',
	HeatherBlue			= 'heather_blue',
	HeatherGrey			= 'heather_grey',
	KellyGreen			= 'kelly_green',
	Lemon				= 'lemon',
	Navy				= 'navy',
	Olive				= 'olive',
	Orange				= 'orange',
	Pink				= 'pink',
	NeonPink			= 'neon_pink',
	Purple				= 'purple',
	Red					= 'red',
	Sapphire			= 'sapphire',
	Royal				= 'royal',
	Silver				= 'silver',
	Slate				= 'slate',
	White				= 'white',
	BlackAthleticHeather = 'black_athletic_heather',
	BlackWhite			= 'black_white',
	DarkHeatherWhite 	= 'dark_heather_white',
	NavyAthleticHeather	= 'navy_athletic_heather',
	NavyWhite			= 'navy_white',
	RedWhite			= 'red_white',
	RoyalBlueWhite		= 'royal_blue_white'
}


/**------------------------------------------------------
 * MBA Data Interfaces
 */
export interface IFlyingMbaListing {
	texts  	 		: IFlyingMbaText[];
	products 		: IFlyingMbaProduct[];
	options  		: IFlyingMbaOptions;
}

export interface IFlyingMbaText {
	language   		: EnumLanguage;
	brand      		: string;
	title      		: string;
	bullet1    		: string;
	bullet2    		: string;
	description		: string;
}

export interface IFlyingMbaProduct {
	productCode  	: EnumFlyingMbaProduct;
	marketplaces 	: IFlyingMbaMarketplace[];
	fitTypes		: EnumFlyingMbaFitType[];
	print   	 	: EnumFlyingMbaPrint;
	colors       	: EnumFlyingMbaColor[];
	background     	: string | null;      			// used for phone cases, popsockets, ...
}

export interface IFlyingMbaMarketplace {
	market 	 		: EnumFlyingMbaMarketplace;
	price  	 		: number;
}

export interface IFlyingMbaOptions {
	availability 	: EnumFlyingMbaAvailability;
	publish			: EnumFlyingMbaPublish;
	autoTranslate	: EnumLanguage | '';
	editId			: string;
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingMbaListingSchema implements ValidatorSchema<IFlyingMbaListing> {
	texts	= Validator.Array().each(Validator.Object().inspect<IFlyingMbaText>({
		language		: Validator.Enum(EnumLanguage).isNotEmpty(),
		brand			: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.BRAND_LENGTH),
		title			: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.TITLE_LENGTH),
		bullet1			: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.BULLET1_LENGTH),
		bullet2			: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.BULLET2_LENGTH),
		description		: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.DESCRIPTION_LENGTH)
	})).isMaxSize(Util.Enum.values(EnumLanguage).length);
	products = Validator.Array().each(Validator.Object().inspect<IFlyingMbaProduct>({
		productCode		: Validator.Enum(EnumFlyingMbaProduct).isNotEmpty(),
		print			: Validator.Enum(EnumFlyingMbaPrint).isNotEmpty(),
		background		: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.BACKGROUND_LENGTH).isNotEmpty().optional(),
		fitTypes		: Validator.Array().each(Validator.Enum(EnumFlyingMbaFitType).isNotEmpty()).isMaxSize(Util.Enum.values(EnumFlyingMbaFitType).length),
		colors			: Validator.Array().each(Validator.Enum(EnumFlyingMbaColor).isNotEmpty()).isMaxSize(FLYING_MBA_LISTING_LIMITATIONS.MAX_COLORS),
		marketplaces	: Validator.Array().each(Validator.Object().inspect<IFlyingMbaMarketplace>({
			market		: Validator.Enum(EnumFlyingMbaMarketplace).isNotEmpty(),
			price		: Validator.Number().isGreaterThan(0)
		})).isMaxSize(Util.Enum.values(EnumFlyingMbaMarketplace).length)
	})).isMaxSize(Util.Enum.values(EnumFlyingMbaProduct).length);
	options	= {
		availability	: Validator.Enum(EnumFlyingMbaAvailability).isNotEmpty(),
		publish			: Validator.Enum(EnumFlyingMbaPublish).isNotEmpty(),
		autoTranslate	: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.TRANSLATE_LENGTH),
		editId			: Validator.String().isMaxLength(FLYING_MBA_LISTING_LIMITATIONS.AMAZON_EDIT_ID_LENGTH)
	};
}
