import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Time Token - for API Security
 */
export class SecureTimeToken {

	//** Configurations */
	private readonly CHECK_TIME_TOKEN_FORMAT: RegExp = /^[0-9a-z]+\.[0-9a-z]{4}\.[0-9a-z]+$/;		// check for: 1631427362848.01ba.1f9c08b945609f3a


	/**------------------------------------------------------
	 * Time Token (blocking DDOS & Replay attacks)
	 */
	createTimeToken(salt: string, utcTime: Date = Util.Date.getUtcTime()): string {

		//0 - validate the function call
		if (Util.String.isEmpty(salt)) throw new Error(`UtilTimeToken => createTimeToken => FATAL ERROR: salt can not be empty (token would not have a secure signature)`);

		//1 - create the time token
		const timeStamp: string = `${utcTime.getTime()}`;
		const saltHash : string = Util.Crypto.quickHash(salt).substring(0, 4);
		const signature: string = Util.Crypto.quickHash(timeStamp + salt);
		const timeToken: string = `${timeStamp}.${saltHash}.${signature}`;

		return timeToken;
	}

	verifyTimeToken(timeToken: string, salt: string, timeWindowSec: number, utcTime: Date = Util.Date.getUtcTime()): boolean {

		//0 - validate the function call
		if (Util.String.isEmpty(timeToken)) throw new Error(`UtilTimeToken => verifyTimeToken => FATAL ERROR: timeToken can not be empty (with no token, no verification can be done)`);
		if (Util.String.isEmpty(salt)) 		throw new Error(`UtilTimeToken => verifyTimeToken => FATAL ERROR: salt can not be empty (token would not have a secure signature)`);
		if (!this.CHECK_TIME_TOKEN_FORMAT.test(timeToken)) throw new Error(`UtilTimeToken => verifyTimeToken => FATAL ERROR: timeToken of "${timeToken}" is in the wrong format (example: "1631427362848.01ba.1f9c08b945609f3a")`);

		//1 - extract the data
		const timeStamp: string = timeToken.split('.')[0];
		const saltHash : string = timeToken.split('.')[1];
		const signature: string = timeToken.split('.')[2];

		//2 - verify the used salts (throw error if salt in differ)
		const verifySaltHash: string = Util.Crypto.quickHash(salt).substring(0, 4);
		if (saltHash !== verifySaltHash) throw new Error(`UtilTimeToken => verifyTimeToken => FATAL ERROR: used salts are different (token salt hash: ${saltHash} / verify salt hash: ${verifySaltHash})`);

		//3 - verify the signature of the token
		const verifySignature: string = Util.Crypto.quickHash(timeStamp + salt);
		if (verifySignature !== signature) return false;

		//4 - verify if the time window is still valid
		const offset: number = Math.abs(Number(timeStamp) - utcTime.getTime());
		if (offset > (timeWindowSec * 1000)) return false;

		return true;
	}
}
