import { EnumCountry, ICountryFlagIcons } from '../international/country/country.interface';


/**------------------------------------------------------
 * Country Flags
 * -------------
 *  > Source for the country flags: https://github.com/hampusborgos/country-flags/tree/main/svg
 */
export const COUNTRY_FLAG_ICONS: Record<EnumCountry, ICountryFlagIcons> = {
	// Africa
	[EnumCountry.DZ]: {
		ratio1x1: 'assets/country-flags/1x1/dz.svg',
		ratio4x3: 'assets/country-flags/4x3/dz.svg'
	},
	[EnumCountry.AO]: {
		ratio1x1: 'assets/country-flags/1x1/ao.svg',
		ratio4x3: 'assets/country-flags/4x3/ao.svg'
	},
	[EnumCountry.BJ]: {
		ratio1x1: 'assets/country-flags/1x1/bj.svg',
		ratio4x3: 'assets/country-flags/4x3/bj.svg'
	},
	[EnumCountry.BW]: {
		ratio1x1: 'assets/country-flags/1x1/bw.svg',
		ratio4x3: 'assets/country-flags/4x3/bw.svg'
	},
	[EnumCountry.BF]: {
		ratio1x1: 'assets/country-flags/1x1/bf.svg',
		ratio4x3: 'assets/country-flags/4x3/bf.svg'
	},
	[EnumCountry.BI]: {
		ratio1x1: 'assets/country-flags/1x1/bi.svg',
		ratio4x3: 'assets/country-flags/4x3/bi.svg'
	},
	[EnumCountry.CV]: {
		ratio1x1: 'assets/country-flags/1x1/cv.svg',
		ratio4x3: 'assets/country-flags/4x3/cv.svg'
	},
	[EnumCountry.CM]: {
		ratio1x1: 'assets/country-flags/1x1/cm.svg',
		ratio4x3: 'assets/country-flags/4x3/cm.svg'
	},
	[EnumCountry.CF]: {
		ratio1x1: 'assets/country-flags/1x1/cf.svg',
		ratio4x3: 'assets/country-flags/4x3/cf.svg'
	},
	[EnumCountry.TD]: {
		ratio1x1: 'assets/country-flags/1x1/td.svg',
		ratio4x3: 'assets/country-flags/4x3/td.svg'
	},
	[EnumCountry.KM]: {
		ratio1x1: 'assets/country-flags/1x1/km.svg',
		ratio4x3: 'assets/country-flags/4x3/km.svg'
	},
	[EnumCountry.CG]: {
		ratio1x1: 'assets/country-flags/1x1/cg.svg',
		ratio4x3: 'assets/country-flags/4x3/cg.svg'
	},
	[EnumCountry.CD]: {
		ratio1x1: 'assets/country-flags/1x1/cd.svg',
		ratio4x3: 'assets/country-flags/4x3/cd.svg'
	},
	[EnumCountry.DJ]: {
		ratio1x1: 'assets/country-flags/1x1/dj.svg',
		ratio4x3: 'assets/country-flags/4x3/dj.svg'
	},
	[EnumCountry.EG]: {
		ratio1x1: 'assets/country-flags/1x1/eg.svg',
		ratio4x3: 'assets/country-flags/4x3/eg.svg'
	},
	[EnumCountry.GQ]: {
		ratio1x1: 'assets/country-flags/1x1/gq.svg',
		ratio4x3: 'assets/country-flags/4x3/gq.svg'
	},
	[EnumCountry.ER]: {
		ratio1x1: 'assets/country-flags/1x1/er.svg',
		ratio4x3: 'assets/country-flags/4x3/er.svg'
	},
	[EnumCountry.SZ]: {
		ratio1x1: 'assets/country-flags/1x1/sz.svg',
		ratio4x3: 'assets/country-flags/4x3/sz.svg'
	},
	[EnumCountry.ET]: {
		ratio1x1: 'assets/country-flags/1x1/et.svg',
		ratio4x3: 'assets/country-flags/4x3/et.svg'
	},
	[EnumCountry.GA]: {
		ratio1x1: 'assets/country-flags/1x1/ga.svg',
		ratio4x3: 'assets/country-flags/4x3/ga.svg'
	},
	[EnumCountry.GM]: {
		ratio1x1: 'assets/country-flags/1x1/gm.svg',
		ratio4x3: 'assets/country-flags/4x3/gm.svg'
	},
	[EnumCountry.GH]: {
		ratio1x1: 'assets/country-flags/1x1/gh.svg',
		ratio4x3: 'assets/country-flags/4x3/gh.svg'
	},
	[EnumCountry.GN]: {
		ratio1x1: 'assets/country-flags/1x1/gn.svg',
		ratio4x3: 'assets/country-flags/4x3/gn.svg'
	},
	[EnumCountry.GW]: {
		ratio1x1: 'assets/country-flags/1x1/gw.svg',
		ratio4x3: 'assets/country-flags/4x3/gw.svg'
	},
	[EnumCountry.CI]: {
		ratio1x1: 'assets/country-flags/1x1/ci.svg',
		ratio4x3: 'assets/country-flags/4x3/ci.svg'
	},
	[EnumCountry.KE]: {
		ratio1x1: 'assets/country-flags/1x1/ke.svg',
		ratio4x3: 'assets/country-flags/4x3/ke.svg'
	},
	[EnumCountry.LS]: {
		ratio1x1: 'assets/country-flags/1x1/ls.svg',
		ratio4x3: 'assets/country-flags/4x3/ls.svg'
	},
	[EnumCountry.LR]: {
		ratio1x1: 'assets/country-flags/1x1/lr.svg',
		ratio4x3: 'assets/country-flags/4x3/lr.svg'
	},
	[EnumCountry.LY]: {
		ratio1x1: 'assets/country-flags/1x1/ly.svg',
		ratio4x3: 'assets/country-flags/4x3/ly.svg'
	},
	[EnumCountry.MG]: {
		ratio1x1: 'assets/country-flags/1x1/mg.svg',
		ratio4x3: 'assets/country-flags/4x3/mg.svg'
	},
	[EnumCountry.MW]: {
		ratio1x1: 'assets/country-flags/1x1/mw.svg',
		ratio4x3: 'assets/country-flags/4x3/mw.svg'
	},
	[EnumCountry.ML]: {
		ratio1x1: 'assets/country-flags/1x1/ml.svg',
		ratio4x3: 'assets/country-flags/4x3/ml.svg'
	},
	[EnumCountry.MR]: {
		ratio1x1: 'assets/country-flags/1x1/mr.svg',
		ratio4x3: 'assets/country-flags/4x3/mr.svg'
	},
	[EnumCountry.MU]: {
		ratio1x1: 'assets/country-flags/1x1/mu.svg',
		ratio4x3: 'assets/country-flags/4x3/mu.svg'
	},
	[EnumCountry.MA]: {
		ratio1x1: 'assets/country-flags/1x1/ma.svg',
		ratio4x3: 'assets/country-flags/4x3/ma.svg'
	},
	[EnumCountry.MZ]: {
		ratio1x1: 'assets/country-flags/1x1/mz.svg',
		ratio4x3: 'assets/country-flags/4x3/mz.svg'
	},
	[EnumCountry.NA]: {
		ratio1x1: 'assets/country-flags/1x1/na.svg',
		ratio4x3: 'assets/country-flags/4x3/na.svg'
	},
	[EnumCountry.NE]: {
		ratio1x1: 'assets/country-flags/1x1/ne.svg',
		ratio4x3: 'assets/country-flags/4x3/ne.svg'
	},
	[EnumCountry.NG]: {
		ratio1x1: 'assets/country-flags/1x1/ng.svg',
		ratio4x3: 'assets/country-flags/4x3/ng.svg'
	},
	[EnumCountry.RW]: {
		ratio1x1: 'assets/country-flags/1x1/rw.svg',
		ratio4x3: 'assets/country-flags/4x3/rw.svg'
	},
	[EnumCountry.ST]: {
		ratio1x1: 'assets/country-flags/1x1/st.svg',
		ratio4x3: 'assets/country-flags/4x3/st.svg'
	},
	[EnumCountry.SN]: {
		ratio1x1: 'assets/country-flags/1x1/sn.svg',
		ratio4x3: 'assets/country-flags/4x3/sn.svg'
	},
	[EnumCountry.SC]: {
		ratio1x1: 'assets/country-flags/1x1/sc.svg',
		ratio4x3: 'assets/country-flags/4x3/sc.svg'
	},
	[EnumCountry.SL]: {
		ratio1x1: 'assets/country-flags/1x1/sl.svg',
		ratio4x3: 'assets/country-flags/4x3/sl.svg'
	},
	[EnumCountry.SO]: {
		ratio1x1: 'assets/country-flags/1x1/so.svg',
		ratio4x3: 'assets/country-flags/4x3/so.svg'
	},
	[EnumCountry.ZA]: {
		ratio1x1: 'assets/country-flags/1x1/za.svg',
		ratio4x3: 'assets/country-flags/4x3/za.svg'
	},
	[EnumCountry.SS]: {
		ratio1x1: 'assets/country-flags/1x1/ss.svg',
		ratio4x3: 'assets/country-flags/4x3/ss.svg'
	},
	[EnumCountry.SD]: {
		ratio1x1: 'assets/country-flags/1x1/sd.svg',
		ratio4x3: 'assets/country-flags/4x3/sd.svg'
	},
	[EnumCountry.TZ]: {
		ratio1x1: 'assets/country-flags/1x1/tz.svg',
		ratio4x3: 'assets/country-flags/4x3/tz.svg'
	},
	[EnumCountry.TG]: {
		ratio1x1: 'assets/country-flags/1x1/tg.svg',
		ratio4x3: 'assets/country-flags/4x3/tg.svg'
	},
	[EnumCountry.TN]: {
		ratio1x1: 'assets/country-flags/1x1/tn.svg',
		ratio4x3: 'assets/country-flags/4x3/tn.svg'
	},
	[EnumCountry.UG]: {
		ratio1x1: 'assets/country-flags/1x1/ug.svg',
		ratio4x3: 'assets/country-flags/4x3/ug.svg'
	},
	[EnumCountry.ZM]: {
		ratio1x1: 'assets/country-flags/1x1/zm.svg',
		ratio4x3: 'assets/country-flags/4x3/zm.svg'
	},
	[EnumCountry.ZW]: {
		ratio1x1: 'assets/country-flags/1x1/zw.svg',
		ratio4x3: 'assets/country-flags/4x3/zw.svg'
	},

	// Antarctica
	[EnumCountry.AQ]: {
		ratio1x1: 'assets/country-flags/1x1/aq.svg',
		ratio4x3: 'assets/country-flags/4x3/aq.svg'
	},

	// Asia
	[EnumCountry.AF]: {
		ratio1x1: 'assets/country-flags/1x1/af.svg',
		ratio4x3: 'assets/country-flags/4x3/af.svg'
	},
	[EnumCountry.AM]: {
		ratio1x1: 'assets/country-flags/1x1/am.svg',
		ratio4x3: 'assets/country-flags/4x3/am.svg'
	},
	[EnumCountry.AZ]: {
		ratio1x1: 'assets/country-flags/1x1/az.svg',
		ratio4x3: 'assets/country-flags/4x3/az.svg'
	},
	[EnumCountry.BH]: {
		ratio1x1: 'assets/country-flags/1x1/bh.svg',
		ratio4x3: 'assets/country-flags/4x3/bh.svg'
	},
	[EnumCountry.BD]: {
		ratio1x1: 'assets/country-flags/1x1/bd.svg',
		ratio4x3: 'assets/country-flags/4x3/bd.svg'
	},
	[EnumCountry.BT]: {
		ratio1x1: 'assets/country-flags/1x1/bt.svg',
		ratio4x3: 'assets/country-flags/4x3/bt.svg'
	},
	[EnumCountry.BN]: {
		ratio1x1: 'assets/country-flags/1x1/bn.svg',
		ratio4x3: 'assets/country-flags/4x3/bn.svg'
	},
	[EnumCountry.KH]: {
		ratio1x1: 'assets/country-flags/1x1/kh.svg',
		ratio4x3: 'assets/country-flags/4x3/kh.svg'
	},
	[EnumCountry.CN]: {
		ratio1x1: 'assets/country-flags/1x1/cn.svg',
		ratio4x3: 'assets/country-flags/4x3/cn.svg'
	},
	[EnumCountry.CY]: {
		ratio1x1: 'assets/country-flags/1x1/cy.svg',
		ratio4x3: 'assets/country-flags/4x3/cy.svg'
	},
	[EnumCountry.GE]: {
		ratio1x1: 'assets/country-flags/1x1/ge.svg',
		ratio4x3: 'assets/country-flags/4x3/ge.svg'
	},
	[EnumCountry.IN]: {
		ratio1x1: 'assets/country-flags/1x1/in.svg',
		ratio4x3: 'assets/country-flags/4x3/in.svg'
	},
	[EnumCountry.ID]: {
		ratio1x1: 'assets/country-flags/1x1/id.svg',
		ratio4x3: 'assets/country-flags/4x3/id.svg'
	},
	[EnumCountry.IR]: {
		ratio1x1: 'assets/country-flags/1x1/ir.svg',
		ratio4x3: 'assets/country-flags/4x3/ir.svg'
	},
	[EnumCountry.IQ]: {
		ratio1x1: 'assets/country-flags/1x1/iq.svg',
		ratio4x3: 'assets/country-flags/4x3/iq.svg'
	},
	[EnumCountry.IL]: {
		ratio1x1: 'assets/country-flags/1x1/il.svg',
		ratio4x3: 'assets/country-flags/4x3/il.svg'
	},
	[EnumCountry.JP]: {
		ratio1x1: 'assets/country-flags/1x1/jp.svg',
		ratio4x3: 'assets/country-flags/4x3/jp.svg'
	},
	[EnumCountry.JO]: {
		ratio1x1: 'assets/country-flags/1x1/jo.svg',
		ratio4x3: 'assets/country-flags/4x3/jo.svg'
	},
	[EnumCountry.KZ]: {
		ratio1x1: 'assets/country-flags/1x1/kz.svg',
		ratio4x3: 'assets/country-flags/4x3/kz.svg'
	},
	[EnumCountry.KW]: {
		ratio1x1: 'assets/country-flags/1x1/kw.svg',
		ratio4x3: 'assets/country-flags/4x3/kw.svg'
	},
	[EnumCountry.KG]: {
		ratio1x1: 'assets/country-flags/1x1/kg.svg',
		ratio4x3: 'assets/country-flags/4x3/kg.svg'
	},
	[EnumCountry.LA]: {
		ratio1x1: 'assets/country-flags/1x1/la.svg',
		ratio4x3: 'assets/country-flags/4x3/la.svg'
	},
	[EnumCountry.LB]: {
		ratio1x1: 'assets/country-flags/1x1/lb.svg',
		ratio4x3: 'assets/country-flags/4x3/lb.svg'
	},
	[EnumCountry.MY]: {
		ratio1x1: 'assets/country-flags/1x1/my.svg',
		ratio4x3: 'assets/country-flags/4x3/my.svg'
	},
	[EnumCountry.MV]: {
		ratio1x1: 'assets/country-flags/1x1/mv.svg',
		ratio4x3: 'assets/country-flags/4x3/mv.svg'
	},
	[EnumCountry.MN]: {
		ratio1x1: 'assets/country-flags/1x1/mn.svg',
		ratio4x3: 'assets/country-flags/4x3/mn.svg'
	},
	[EnumCountry.MM]: {
		ratio1x1: 'assets/country-flags/1x1/mm.svg',
		ratio4x3: 'assets/country-flags/4x3/mm.svg'
	},
	[EnumCountry.NP]: {
		ratio1x1: 'assets/country-flags/1x1/np.svg',
		ratio4x3: 'assets/country-flags/4x3/np.svg'
	},
	[EnumCountry.KP]: {
		ratio1x1: 'assets/country-flags/1x1/kp.svg',
		ratio4x3: 'assets/country-flags/4x3/kp.svg'
	},
	[EnumCountry.OM]: {
		ratio1x1: 'assets/country-flags/1x1/om.svg',
		ratio4x3: 'assets/country-flags/4x3/om.svg'
	},
	[EnumCountry.PK]: {
		ratio1x1: 'assets/country-flags/1x1/pk.svg',
		ratio4x3: 'assets/country-flags/4x3/pk.svg'
	},
	[EnumCountry.PS]: {
		ratio1x1: 'assets/country-flags/1x1/ps.svg',
		ratio4x3: 'assets/country-flags/4x3/ps.svg'
	},
	[EnumCountry.PH]: {
		ratio1x1: 'assets/country-flags/1x1/ph.svg',
		ratio4x3: 'assets/country-flags/4x3/ph.svg'
	},
	[EnumCountry.QA]: {
		ratio1x1: 'assets/country-flags/1x1/qa.svg',
		ratio4x3: 'assets/country-flags/4x3/qa.svg'
	},
	[EnumCountry.SA]: {
		ratio1x1: 'assets/country-flags/1x1/sa.svg',
		ratio4x3: 'assets/country-flags/4x3/sa.svg'
	},
	[EnumCountry.SG]: {
		ratio1x1: 'assets/country-flags/1x1/sg.svg',
		ratio4x3: 'assets/country-flags/4x3/sg.svg'
	},
	[EnumCountry.KR]: {
		ratio1x1: 'assets/country-flags/1x1/kr.svg',
		ratio4x3: 'assets/country-flags/4x3/kr.svg'
	},
	[EnumCountry.LK]: {
		ratio1x1: 'assets/country-flags/1x1/lk.svg',
		ratio4x3: 'assets/country-flags/4x3/lk.svg'
	},
	[EnumCountry.SY]: {
		ratio1x1: 'assets/country-flags/1x1/sy.svg',
		ratio4x3: 'assets/country-flags/4x3/sy.svg'
	},
	[EnumCountry.TW]: {
		ratio1x1: 'assets/country-flags/1x1/tw.svg',
		ratio4x3: 'assets/country-flags/4x3/tw.svg'
	},
	[EnumCountry.TJ]: {
		ratio1x1: 'assets/country-flags/1x1/tj.svg',
		ratio4x3: 'assets/country-flags/4x3/tj.svg'
	},
	[EnumCountry.TH]: {
		ratio1x1: 'assets/country-flags/1x1/th.svg',
		ratio4x3: 'assets/country-flags/4x3/th.svg'
	},
	[EnumCountry.TL]: {
		ratio1x1: 'assets/country-flags/1x1/tl.svg',
		ratio4x3: 'assets/country-flags/4x3/tl.svg'
	},
	[EnumCountry.TR]: {
		ratio1x1: 'assets/country-flags/1x1/tr.svg',
		ratio4x3: 'assets/country-flags/4x3/tr.svg'
	},
	[EnumCountry.TM]: {
		ratio1x1: 'assets/country-flags/1x1/tm.svg',
		ratio4x3: 'assets/country-flags/4x3/tm.svg'
	},
	[EnumCountry.AE]: {
		ratio1x1: 'assets/country-flags/1x1/ae.svg',
		ratio4x3: 'assets/country-flags/4x3/ae.svg'
	},
	[EnumCountry.UZ]: {
		ratio1x1: 'assets/country-flags/1x1/uz.svg',
		ratio4x3: 'assets/country-flags/4x3/uz.svg'
	},
	[EnumCountry.VN]: {
		ratio1x1: 'assets/country-flags/1x1/vn.svg',
		ratio4x3: 'assets/country-flags/4x3/vn.svg'
	},
	[EnumCountry.YE]: {
		ratio1x1: 'assets/country-flags/1x1/ye.svg',
		ratio4x3: 'assets/country-flags/4x3/ye.svg'
	},

	// Europe
	[EnumCountry.AL]: {
		ratio1x1: 'assets/country-flags/1x1/al.svg',
		ratio4x3: 'assets/country-flags/4x3/al.svg'
	},
	[EnumCountry.AD]: {
		ratio1x1: 'assets/country-flags/1x1/ad.svg',
		ratio4x3: 'assets/country-flags/4x3/ad.svg'
	},
	[EnumCountry.AT]: {
		ratio1x1: 'assets/country-flags/1x1/at.svg',
		ratio4x3: 'assets/country-flags/4x3/at.svg'
	},
	[EnumCountry.BY]: {
		ratio1x1: 'assets/country-flags/1x1/by.svg',
		ratio4x3: 'assets/country-flags/4x3/by.svg'
	},
	[EnumCountry.BE]: {
		ratio1x1: 'assets/country-flags/1x1/be.svg',
		ratio4x3: 'assets/country-flags/4x3/be.svg'
	},
	[EnumCountry.BA]: {
		ratio1x1: 'assets/country-flags/1x1/ba.svg',
		ratio4x3: 'assets/country-flags/4x3/ba.svg'
	},
	[EnumCountry.BG]: {
		ratio1x1: 'assets/country-flags/1x1/bg.svg',
		ratio4x3: 'assets/country-flags/4x3/bg.svg'
	},
	[EnumCountry.HR]: {
		ratio1x1: 'assets/country-flags/1x1/hr.svg',
		ratio4x3: 'assets/country-flags/4x3/hr.svg'
	},
	[EnumCountry.CZ]: {
		ratio1x1: 'assets/country-flags/1x1/cz.svg',
		ratio4x3: 'assets/country-flags/4x3/cz.svg'
	},
	[EnumCountry.DK]: {
		ratio1x1: 'assets/country-flags/1x1/dk.svg',
		ratio4x3: 'assets/country-flags/4x3/dk.svg'
	},
	[EnumCountry.EE]: {
		ratio1x1: 'assets/country-flags/1x1/ee.svg',
		ratio4x3: 'assets/country-flags/4x3/ee.svg'
	},
	[EnumCountry.FI]: {
		ratio1x1: 'assets/country-flags/1x1/fi.svg',
		ratio4x3: 'assets/country-flags/4x3/fi.svg'
	},
	[EnumCountry.FR]: {
		ratio1x1: 'assets/country-flags/1x1/fr.svg',
		ratio4x3: 'assets/country-flags/4x3/fr.svg'
	},
	[EnumCountry.DE]: {
		ratio1x1: 'assets/country-flags/1x1/de.svg',
		ratio4x3: 'assets/country-flags/4x3/de.svg'
	},
	[EnumCountry.GR]: {
		ratio1x1: 'assets/country-flags/1x1/gr.svg',
		ratio4x3: 'assets/country-flags/4x3/gr.svg'
	},
	[EnumCountry.HU]: {
		ratio1x1: 'assets/country-flags/1x1/hu.svg',
		ratio4x3: 'assets/country-flags/4x3/hu.svg'
	},
	[EnumCountry.IS]: {
		ratio1x1: 'assets/country-flags/1x1/is.svg',
		ratio4x3: 'assets/country-flags/4x3/is.svg'
	},
	[EnumCountry.IE]: {
		ratio1x1: 'assets/country-flags/1x1/ie.svg',
		ratio4x3: 'assets/country-flags/4x3/ie.svg'
	},
	[EnumCountry.IT]: {
		ratio1x1: 'assets/country-flags/1x1/it.svg',
		ratio4x3: 'assets/country-flags/4x3/it.svg'
	},
	[EnumCountry.XK]: {
		ratio1x1: 'assets/country-flags/1x1/xk.svg',
		ratio4x3: 'assets/country-flags/4x3/xk.svg'
	},
	[EnumCountry.LV]: {
		ratio1x1: 'assets/country-flags/1x1/lv.svg',
		ratio4x3: 'assets/country-flags/4x3/lv.svg'
	},
	[EnumCountry.LI]: {
		ratio1x1: 'assets/country-flags/1x1/li.svg',
		ratio4x3: 'assets/country-flags/4x3/li.svg'
	},
	[EnumCountry.LT]: {
		ratio1x1: 'assets/country-flags/1x1/lt.svg',
		ratio4x3: 'assets/country-flags/4x3/lt.svg'
	},
	[EnumCountry.LU]: {
		ratio1x1: 'assets/country-flags/1x1/lu.svg',
		ratio4x3: 'assets/country-flags/4x3/lu.svg'
	},
	[EnumCountry.MT]: {
		ratio1x1: 'assets/country-flags/1x1/mt.svg',
		ratio4x3: 'assets/country-flags/4x3/mt.svg'
	},
	[EnumCountry.MD]: {
		ratio1x1: 'assets/country-flags/1x1/md.svg',
		ratio4x3: 'assets/country-flags/4x3/md.svg'
	},
	[EnumCountry.MC]: {
		ratio1x1: 'assets/country-flags/1x1/mc.svg',
		ratio4x3: 'assets/country-flags/4x3/mc.svg'
	},
	[EnumCountry.ME]: {
		ratio1x1: 'assets/country-flags/1x1/me.svg',
		ratio4x3: 'assets/country-flags/4x3/me.svg'
	},
	[EnumCountry.NL]: {
		ratio1x1: 'assets/country-flags/1x1/nl.svg',
		ratio4x3: 'assets/country-flags/4x3/nl.svg'
	},
	[EnumCountry.MK]: {
		ratio1x1: 'assets/country-flags/1x1/mk.svg',
		ratio4x3: 'assets/country-flags/4x3/mk.svg'
	},
	[EnumCountry.NO]: {
		ratio1x1: 'assets/country-flags/1x1/no.svg',
		ratio4x3: 'assets/country-flags/4x3/no.svg'
	},
	[EnumCountry.PL]: {
		ratio1x1: 'assets/country-flags/1x1/pl.svg',
		ratio4x3: 'assets/country-flags/4x3/pl.svg'
	},
	[EnumCountry.PT]: {
		ratio1x1: 'assets/country-flags/1x1/pt.svg',
		ratio4x3: 'assets/country-flags/4x3/pt.svg'
	},
	[EnumCountry.RO]: {
		ratio1x1: 'assets/country-flags/1x1/ro.svg',
		ratio4x3: 'assets/country-flags/4x3/ro.svg'
	},
	[EnumCountry.RU]: {
		ratio1x1: 'assets/country-flags/1x1/ru.svg',
		ratio4x3: 'assets/country-flags/4x3/ru.svg'
	},
	[EnumCountry.SM]: {
		ratio1x1: 'assets/country-flags/1x1/sm.svg',
		ratio4x3: 'assets/country-flags/4x3/sm.svg'
	},
	[EnumCountry.RS]: {
		ratio1x1: 'assets/country-flags/1x1/rs.svg',
		ratio4x3: 'assets/country-flags/4x3/rs.svg'
	},
	[EnumCountry.SK]: {
		ratio1x1: 'assets/country-flags/1x1/sk.svg',
		ratio4x3: 'assets/country-flags/4x3/sk.svg'
	},
	[EnumCountry.SI]: {
		ratio1x1: 'assets/country-flags/1x1/si.svg',
		ratio4x3: 'assets/country-flags/4x3/si.svg'
	},
	[EnumCountry.ES]: {
		ratio1x1: 'assets/country-flags/1x1/es.svg',
		ratio4x3: 'assets/country-flags/4x3/es.svg'
	},
	[EnumCountry.SE]: {
		ratio1x1: 'assets/country-flags/1x1/se.svg',
		ratio4x3: 'assets/country-flags/4x3/se.svg'
	},
	[EnumCountry.CH]: {
		ratio1x1: 'assets/country-flags/1x1/ch.svg',
		ratio4x3: 'assets/country-flags/4x3/ch.svg'
	},
	[EnumCountry.UA]: {
		ratio1x1: 'assets/country-flags/1x1/ua.svg',
		ratio4x3: 'assets/country-flags/4x3/ua.svg'
	},
	[EnumCountry.UK]: {
		ratio1x1: 'assets/country-flags/1x1/gb.svg',
		ratio4x3: 'assets/country-flags/4x3/gb.svg'
	},
	[EnumCountry.VA]: {
		ratio1x1: 'assets/country-flags/1x1/va.svg',
		ratio4x3: 'assets/country-flags/4x3/va.svg'
	},

	// North America
	[EnumCountry.AG]: {
		ratio1x1: 'assets/country-flags/1x1/ag.svg',
		ratio4x3: 'assets/country-flags/4x3/ag.svg'
	},
	[EnumCountry.BS]: {
		ratio1x1: 'assets/country-flags/1x1/bs.svg',
		ratio4x3: 'assets/country-flags/4x3/bs.svg'
	},
	[EnumCountry.BB]: {
		ratio1x1: 'assets/country-flags/1x1/bb.svg',
		ratio4x3: 'assets/country-flags/4x3/bb.svg'
	},
	[EnumCountry.BZ]: {
		ratio1x1: 'assets/country-flags/1x1/bz.svg',
		ratio4x3: 'assets/country-flags/4x3/bz.svg'
	},
	[EnumCountry.CA]: {
		ratio1x1: 'assets/country-flags/1x1/ca.svg',
		ratio4x3: 'assets/country-flags/4x3/ca.svg'
	},
	[EnumCountry.CR]: {
		ratio1x1: 'assets/country-flags/1x1/cr.svg',
		ratio4x3: 'assets/country-flags/4x3/cr.svg'
	},
	[EnumCountry.CU]: {
		ratio1x1: 'assets/country-flags/1x1/cu.svg',
		ratio4x3: 'assets/country-flags/4x3/cu.svg'
	},
	[EnumCountry.DM]: {
		ratio1x1: 'assets/country-flags/1x1/dm.svg',
		ratio4x3: 'assets/country-flags/4x3/dm.svg'
	},
	[EnumCountry.DO]: {
		ratio1x1: 'assets/country-flags/1x1/do.svg',
		ratio4x3: 'assets/country-flags/4x3/do.svg'
	},
	[EnumCountry.SV]: {
		ratio1x1: 'assets/country-flags/1x1/sv.svg',
		ratio4x3: 'assets/country-flags/4x3/sv.svg'
	},
	[EnumCountry.GD]: {
		ratio1x1: 'assets/country-flags/1x1/gd.svg',
		ratio4x3: 'assets/country-flags/4x3/gd.svg'
	},
	[EnumCountry.GT]: {
		ratio1x1: 'assets/country-flags/1x1/gt.svg',
		ratio4x3: 'assets/country-flags/4x3/gt.svg'
	},
	[EnumCountry.HT]: {
		ratio1x1: 'assets/country-flags/1x1/ht.svg',
		ratio4x3: 'assets/country-flags/4x3/ht.svg'
	},
	[EnumCountry.HN]: {
		ratio1x1: 'assets/country-flags/1x1/hn.svg',
		ratio4x3: 'assets/country-flags/4x3/hn.svg'
	},
	[EnumCountry.JM]: {
		ratio1x1: 'assets/country-flags/1x1/jm.svg',
		ratio4x3: 'assets/country-flags/4x3/jm.svg'
	},
	[EnumCountry.MX]: {
		ratio1x1: 'assets/country-flags/1x1/mx.svg',
		ratio4x3: 'assets/country-flags/4x3/mx.svg'
	},
	[EnumCountry.NI]: {
		ratio1x1: 'assets/country-flags/1x1/ni.svg',
		ratio4x3: 'assets/country-flags/4x3/ni.svg'
	},
	[EnumCountry.PA]: {
		ratio1x1: 'assets/country-flags/1x1/pa.svg',
		ratio4x3: 'assets/country-flags/4x3/pa.svg'
	},
	[EnumCountry.KN]: {
		ratio1x1: 'assets/country-flags/1x1/kn.svg',
		ratio4x3: 'assets/country-flags/4x3/kn.svg'
	},
	[EnumCountry.LC]: {
		ratio1x1: 'assets/country-flags/1x1/lc.svg',
		ratio4x3: 'assets/country-flags/4x3/lc.svg'
	},
	[EnumCountry.VC]: {
		ratio1x1: 'assets/country-flags/1x1/vc.svg',
		ratio4x3: 'assets/country-flags/4x3/vc.svg'
	},
	[EnumCountry.TT]: {
		ratio1x1: 'assets/country-flags/1x1/tt.svg',
		ratio4x3: 'assets/country-flags/4x3/tt.svg'
	},
	[EnumCountry.US]: {
		ratio1x1: 'assets/country-flags/1x1/us.svg',
		ratio4x3: 'assets/country-flags/4x3/us.svg'
	},

	// Oceania
	[EnumCountry.AU]: {
		ratio1x1: 'assets/country-flags/1x1/au.svg',
		ratio4x3: 'assets/country-flags/4x3/au.svg'
	},
	[EnumCountry.FJ]: {
		ratio1x1: 'assets/country-flags/1x1/fj.svg',
		ratio4x3: 'assets/country-flags/4x3/fj.svg'
	},
	[EnumCountry.KI]: {
		ratio1x1: 'assets/country-flags/1x1/ki.svg',
		ratio4x3: 'assets/country-flags/4x3/ki.svg'
	},
	[EnumCountry.MH]: {
		ratio1x1: 'assets/country-flags/1x1/mh.svg',
		ratio4x3: 'assets/country-flags/4x3/mh.svg'
	},
	[EnumCountry.FM]: {
		ratio1x1: 'assets/country-flags/1x1/fm.svg',
		ratio4x3: 'assets/country-flags/4x3/fm.svg'
	},
	[EnumCountry.NR]: {
		ratio1x1: 'assets/country-flags/1x1/nr.svg',
		ratio4x3: 'assets/country-flags/4x3/nr.svg'
	},
	[EnumCountry.NZ]: {
		ratio1x1: 'assets/country-flags/1x1/nz.svg',
		ratio4x3: 'assets/country-flags/4x3/nz.svg'
	},
	[EnumCountry.PW]: {
		ratio1x1: 'assets/country-flags/1x1/pw.svg',
		ratio4x3: 'assets/country-flags/4x3/pw.svg'
	},
	[EnumCountry.PG]: {
		ratio1x1: 'assets/country-flags/1x1/pg.svg',
		ratio4x3: 'assets/country-flags/4x3/pg.svg'
	},
	[EnumCountry.WS]: {
		ratio1x1: 'assets/country-flags/1x1/ws.svg',
		ratio4x3: 'assets/country-flags/4x3/ws.svg'
	},
	[EnumCountry.SB]: {
		ratio1x1: 'assets/country-flags/1x1/sb.svg',
		ratio4x3: 'assets/country-flags/4x3/sb.svg'
	},
	[EnumCountry.TO]: {
		ratio1x1: 'assets/country-flags/1x1/to.svg',
		ratio4x3: 'assets/country-flags/4x3/to.svg'
	},
	[EnumCountry.TV]: {
		ratio1x1: 'assets/country-flags/1x1/tv.svg',
		ratio4x3: 'assets/country-flags/4x3/tv.svg'
	},
	[EnumCountry.VU]: {
		ratio1x1: 'assets/country-flags/1x1/vu.svg',
		ratio4x3: 'assets/country-flags/4x3/vu.svg'
	},

	// South America
	[EnumCountry.AR]: {
		ratio1x1: 'assets/country-flags/1x1/ar.svg',
		ratio4x3: 'assets/country-flags/4x3/ar.svg'
	},
	[EnumCountry.BO]: {
		ratio1x1: 'assets/country-flags/1x1/bo.svg',
		ratio4x3: 'assets/country-flags/4x3/bo.svg'
	},
	[EnumCountry.BR]: {
		ratio1x1: 'assets/country-flags/1x1/br.svg',
		ratio4x3: 'assets/country-flags/4x3/br.svg'
	},
	[EnumCountry.CL]: {
		ratio1x1: 'assets/country-flags/1x1/cl.svg',
		ratio4x3: 'assets/country-flags/4x3/cl.svg'
	},
	[EnumCountry.CO]: {
		ratio1x1: 'assets/country-flags/1x1/co.svg',
		ratio4x3: 'assets/country-flags/4x3/co.svg'
	},
	[EnumCountry.EC]: {
		ratio1x1: 'assets/country-flags/1x1/ec.svg',
		ratio4x3: 'assets/country-flags/4x3/ec.svg'
	},
	[EnumCountry.GY]: {
		ratio1x1: 'assets/country-flags/1x1/gy.svg',
		ratio4x3: 'assets/country-flags/4x3/gy.svg'
	},
	[EnumCountry.PY]: {
		ratio1x1: 'assets/country-flags/1x1/py.svg',
		ratio4x3: 'assets/country-flags/4x3/py.svg'
	},
	[EnumCountry.PE]: {
		ratio1x1: 'assets/country-flags/1x1/pe.svg',
		ratio4x3: 'assets/country-flags/4x3/pe.svg'
	},
	[EnumCountry.SR]: {
		ratio1x1: 'assets/country-flags/1x1/sr.svg',
		ratio4x3: 'assets/country-flags/4x3/sr.svg'
	},
	[EnumCountry.UY]: {
		ratio1x1: 'assets/country-flags/1x1/uy.svg',
		ratio4x3: 'assets/country-flags/4x3/uy.svg'
	},
	[EnumCountry.VE]: {
		ratio1x1: 'assets/country-flags/1x1/ve.svg',
		ratio4x3: 'assets/country-flags/4x3/ve.svg'
	}
	// cspell:enable
};
