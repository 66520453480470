import { TypeCompareFn, defaultCompareFn, TypeGetValueFn, defaultGetValueFn } from '@libs/constants';

import { UtilBasic } from './util-basic';


/**------------------------------------------------------
 * Array Utilities
 * ---------------
 * > Info: Containing all functionalities related to arrays
 * > the functions are created as generics, so they can be
 * > used regardless of the type.
 */
export class UtilArray {

	constructor(
		private utilBasic: UtilBasic
	) {}


	/**------------------------------------------------------
	 * Check Array Type
	 */
	isArray(array: any): boolean {
		return Boolean(array) && array.constructor === Array;
	}
	isNotArray(array: any): boolean {
		return !this.isArray(array);
	}


	/**------------------------------------------------------
	 * Check for Empty
	 */
	isEmpty<T>(array: T[]): boolean {
		return this.utilBasic.isUndefined(array) || !this.utilBasic.isArray(array) || array.length === 0;
	}
	isNotEmpty<T>(array: T[]): boolean {
		return !this.isEmpty(array);
	}


	/**------------------------------------------------------
	 * Check for Specific Count
	 */
	hasOneElement<T>(array: T[]): boolean {
		return this.hasNumberOfElements(array, 1);
	}
	hasTwoElements<T>(array: T[]): boolean {
		return this.hasNumberOfElements(array, 2);
	}
	hasNumberOfElements<T>(array: T[], n: number): boolean {
		if (n < 0) throw new Error(`UtilArray => hasNElements => FATAL ERROR: the provided value for n can not be negative (n: "${n}")`);
		return this.isNotEmpty(array) && this.hasNoUndefinedValues(array) && array.length === n;
	}
	hasDuplicates<T>(array: T[]): boolean {
		this.checkArray(array);
		return array.length !== this.unique(array).length;
	}

	hasOnlyUnique<T>(array: T[], getValueFn: TypeGetValueFn<T> = defaultGetValueFn): boolean {
		const uniques: Set<string | number | boolean | T> = new Set(array.map((elem: T) => getValueFn(elem)));
		return [...uniques].length === array.length;
	}


	/**------------------------------------------------------
	 * Check for Undefined Values
	 */
	hasUndefinedValues<T>(array: T[], getValueFn: TypeGetValueFn<T> = defaultGetValueFn): boolean {
		this.checkArray(array);
		for (const elem of array) {
			if (this.utilBasic.isUndefined(getValueFn(elem))) return true;
		}
		return false;
	}
	hasNoUndefinedValues<T>(array: T[], getValueFn: TypeGetValueFn<T> = defaultGetValueFn): boolean {
		return !this.hasUndefinedValues(array, getValueFn);
	}


	/**------------------------------------------------------
	 * Compare Arrays
	 */
	isEqual<T>(array1: T[], array2: T[]): boolean {
		this.checkArrays([array1, array2]);
		return JSON.stringify(array1) === JSON.stringify(array2);
	}
	isNotEqual<T>(array1: T[], array2: T[]): boolean {
		return !this.isEqual(array1, array2);
	}
	areElementsEqual<T>(array1: T[], array2: T[]): boolean {

		//0 - check if both arrays have the same length
		if (array1.length !== array2.length) return false;

		//1 - convert the objects to strings, to be able to compare them
		const array1AsString: string[] = array1.map((elem: T) => JSON.stringify(elem)).sort();
		const array2AsString: string[] = array2.map((elem: T) => JSON.stringify(elem)).sort();

		//2 - compare element for element in the array
		for (let i: number = 0; i < array1AsString.length; i++) {
			if (array1AsString[i].length !== array2AsString[i].length) return false;
		}
		return true;
	}


	/**------------------------------------------------------
	 * Check for Common Elements
	 */
	includesCount<T>(array1: T[], array2: T[]): number {
		this.checkArrays([array1, array2]);
		return array1.filter((elem: T) => array2.includes(elem)).length;
	}
	includesAny<T>(array1: T[], array2: T[]): boolean {
		this.checkArrays([array1, array2]);
		return this.includesCount(array1, array2) > 0;
	}
	includesNone<T>(array1: T[], array2: T[]): boolean {
		return !this.includesAny(array1, array2);
	}
	includesAll<T>(array1: T[], array2: T[]): boolean {
		this.checkArrays([array1, array2]);
		return array1.length === array2.length && array1.length === this.includesCount(array1, array2);
	}

	contains<T>(array: T[], value: T): boolean {
		this.checkArray(array);
		return array.includes(value);
	}
	containValues(array: Array<string | number>, values: Array<string | number>): boolean {
		this.checkArray(array);
		const count: number = this.includesCount(values, array);
		return count === values.length;
	}


	/**------------------------------------------------------
	 * Helpers
	 */
	isLast<T>(array: T[], elem: T): boolean {
		return this.getLast(array) === elem;
	}


	/**------------------------------------------------------
	 * Get Element
	 */
	isValidIndex<T>(array: T[], index: number): boolean {
		if (!this.isArray(array)) throw new Error(`UtilArray => isIndexValid => FATAL ERROR: parameter of array is not a valid array`);
		if (index < 0 || index >= array.length) return false;
		return true;
	}
	isInvalidIndex<T>(array: T[], index: number): boolean {
		return this.isValidIndex(array, index);
	}

	getByIndex<T>(array: T[], index: number): T {
		this.checkArrayForEmpty(array);
		return array[this.getValidIndex(index, array.length)];
	}
	getRandom<T>(array: T[]): T {
		this.checkArrayForEmpty(array);
		const randomIndex: number = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	}
	getRandomList<T>(array: T[], count: number): T[] {

		//0 - check the function call
		this.checkArrayForEmpty(array);
		if (count <= 0) 		  throw new Error(`UtilArray => getRandomList => FATAL ERROR: provided count of "${count}" was <= 0 (count defines the number of random elements which should be selected form the array)`);
		if (count > array.length) throw new Error(`UtilArray => getRandomList => FATAL ERROR: not enough elements in the array count <= array.length (count: ${count} / array.length: ${array.length})`);

		//1 - get the random elements
		const randomList: T[] = [];
		const arrayCopy : T[] = this.utilBasic.deepCopy(array);
		for (let i: number = 0; i < count; ++i) {

			//a. generate a random index
			const randomIndex: number = Math.floor(Math.random() * arrayCopy.length);

			//b. add the element
			randomList.push(arrayCopy[randomIndex]);
			arrayCopy.splice(randomIndex, 1);			// remove it from the copied array, so it can not be added again
		}
		return randomList;
	}
	getEveryNth<T>(array: T[], nth: number): T[] {

		//0 - check the function call
		this.checkArrayForEmpty(array);
		if (array.length <= nth) throw new Error(`UtilArray => getEveryNth => FATAL ERROR: the array is smaller then the nth parameter (array.length: "${array.length}" / nth: "${nth}")`);

		//1 - geth the nth element in the array
		return array.filter((elem: T, i: number) => i % nth === nth - 1);
	}

	getArrayValues<T>(array: T[], startIndex: number, endIndex: number): T[] {
		return array.slice(startIndex, endIndex + 1);
	}

	getFirst<T>(array: T[]): T {
		this.checkArrayForEmpty(array);
		return array[0];
	}
	getLast<T>(array: T[]): T {
		this.checkArrayForEmpty(array);
		return array[array.length - 1];
	}
	findLast<T>(array: T[], findFunction: (value: T) => boolean): T | null {
		this.checkArrayForEmpty(array);
		for (let i: number = array.length - 1; i >= 0; --i) {
			if (findFunction(array[i])) return array[i];
		}
		return null;
	}
	findLastIndex<T>(array: T[], findFunction: (value: T) => boolean): number | -1 {
		this.checkArrayForEmpty(array);
		return (array.map((value: T, i: number) => [i, value])
			.filter(([i, value]: any) => findFunction(value as T))
			.pop() || [-1])[0] as number;
	}

	//** Index of all occurrences (example: for 1 in array[1, 2, 3, 1, 2, 3] => [0, 3]) */
	indexOfAll<T>(array: T[], value: T): number[] {
		return array.reduce((acc: number[], elem: T, i: number) => (elem === value ? [...acc, i] : acc), []);
	}


	/**------------------------------------------------------
	 * Array Access Helpers
	 */
	removeByIndex<T>(array: T[], index: number): T[] {
		this.checkArray(array);
		if (index < 0 && index >= array.length) throw new Error(`UtilArray => removeByIndex => FATAL ERROR: provided index of "${index}" is not valid for removing (array length: ${array.length})`);
		return array.splice(index, 1);
	}
	removeByValue<T>(array: T[], value: T): T[] {
		this.checkArray(array);
		while (true) { // eslint-disable-line no-constant-condition
			const index: number = array.indexOf(value);
			if (index === -1) return array;			// return if no more instances are found
			array.splice(index, 1);
		}
	}
	removeByValues<T>(array: T[], values: T[]): T[] {
		this.checkArray(array);
		for (const value of values) {
			array = this.removeByValue(array, value);
		}
		return array;
	}
	removeUndefined<T>(array: T[]): T[] {
		this.checkArray(array);
		for (let i: number = array.length - 1; i >= 0; --i) {
			if (this.utilBasic.isUndefined(array[i])) array.splice(i, 1);
		}
		return array;
	}
	removeUnique<T>(array: T[]): T[] {
		this.checkArray(array);
		return array.filter((elem: T) => array.indexOf(elem) !== array.lastIndexOf(elem));
	}
	removeNotUnique<T>(array: T[]): T[] {
		this.checkArray(array);
		return array.filter((elem: T) => array.indexOf(elem) === array.lastIndexOf(elem));
	}

	remove<T>(array: T[], condition: (value: T) => boolean): T[] {
		this.checkArrayForEmpty(array);
		for (let i: number = array.length - 1; i >= 0; --i) {
			if (condition(array[i])) array.splice(i, 1);
		}
		return array;
	}
	clear<T>(array: T[]): void {
		this.checkArray(array);
		array.splice(0, array.length);
	}
	asArray<T>(value: T | T[]): T[] {
		return (this.isArray(value) ? value : [value]) as T[];
	}

	update<T>(array: T[], element: T, findFunction: (value: T) => boolean): T[] {
		this.checkArrayForEmpty(array);
		const index: number = this.findIndex(array, findFunction);
		if (index === -1) throw new Error(`UtilArray => replace => FATAL ERROR: element of "${this.utilBasic.stringifyObject(array)}" could not be found in the array`);
		array[index] = element;
		return array;
	}
	findIndex<T>(array: T[], findFunction: (value: T) => boolean): number | -1 {
		this.checkArrayForEmpty(array);
		for (let i: number = 0; i < array.length; i++) {
			if (findFunction(array[i])) return i;
		}
		return -1;
	}


	/**------------------------------------------------------
	 * Inserts
	 */
	insertAt<T>(array: T[], index: number, ...items: T[]): T[] {
		this.checkArray(array);
		if (index < 0 && index >= array.length) throw new Error(`UtilArray => insertAt => FATAL ERROR: provided index of "${index}" is not valid for inserting (array.length: ${array.length}})`);
		array.splice(index, 0, ...items);
		return array;
	}
	insertBefore<T>(array: T[], index: number, ...items: T[]): T[] {
		return this.insertAt(array, index, ...items);
	}
	insertAfter<T>(array: T[], index: number, ...items: T[]): T[] {
		return this.insertAt(array, index + 1, ...items);
	}


	/**------------------------------------------------------
	 * Array Manipulation
	 */
	setAll<T>(array: T[], value: T): T[] {
		this.checkArray(array);
		for (let i: number = 0; i < array.length; ++i) {
			array[i] = value;
		}
		return array;
	}
	create<T>(length: number, value: T): T[] {
		if (length <= 0) throw new Error(`UtilArray => create => FATAL ERROR: provided length of "${length}" is invalid for creating a new array`);
		if (this.utilBasic.isUndefined(value)) throw new Error(`UtilArray => create => FATAL ERROR: provided value is undefined`);
		return Array(length).fill(value);
	}
	add<T>(array: T[], value: T, index?: number): T[] {

		//0 - append the element at the end
		if (this.utilBasic.isUndefined(index) || index === array.length) {
			array.push(value);
			return array;
		}

		//1 - place new element inside the array
		this.checkIndex(array, index!);
		array.splice(index!, 0, value);		// DO NOT return this statement!
		return array;
	}
	shorten<T>(array: T[], maxCount: number): T[] {
		this.checkArray(array);
		if (maxCount < 0) throw new Error(`UtilArray => create => FATAL ERROR: provided maxCount is negative`);
		array.splice(maxCount);
		return array;
	}

	divideByCondition<T>(array: T[], conditionFn: (elem: T) => boolean): [T[], T[]] {
		this.checkArray(array);

		//0 - divide the array into two, based on the condition
		const arrayOfValid	: T[] = [];
		const arrayOfInvalid: T[] = [];
		for (const elem of array) {
			if (conditionFn(elem)) arrayOfValid.push(elem);
				else arrayOfInvalid.push(elem);
		}

		//1 - return the result
		return [arrayOfValid, arrayOfInvalid];
	}
	countByCondition<T>(array: T[], conditionFn: (elem: T) => boolean): [number, number] {
		this.checkArray(array);

		//0 - count the matched and not matched by the condition
		let matchedCount	: number = 0;
		let notMatchedCount	: number = 0;
		for (const elem of array) {
			if (conditionFn(elem)) matchedCount++;
				else notMatchedCount++;
		}

		//1 - return the result
		return [matchedCount, notMatchedCount];
	}


	/**------------------------------------------------------
	 * Get Elements
	 */
	common<T>(array1: T[], array2: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): T[] {
		this.checkArrays([array1, array2]);
		return array1.filter((elemArr1: T) => array2.filter((elemArr2: T) => compareFn(elemArr1, elemArr2)).length > 0);
	}
	uncommon<T>(array1: T[], array2: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): T[] {
		this.checkArrays([array1, array2]);
		const uniqueFromArr1: T[] = array1.filter((elemArr1: T) => !array2.some((elemArr2: T) => compareFn(elemArr1, elemArr2)));
		const uniqueFromArr2: T[] = array2.filter((elemArr2: T) => !array1.some((elemArr1: T) => compareFn(elemArr2, elemArr1)));
		return [...uniqueFromArr1, ...uniqueFromArr2];
	}
	unique<T>(array: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): T[] {
		this.checkArray(array);
		if (this.utilBasic.isUndefined(compareFn)) Array.from(new Set(array));
		return array.filter((value: T, index: number, self: T[]) => {
			const matchingIndex: number = self.findIndex((elem: T) => compareFn(elem, value));
			return matchingIndex === index;
		});
	}
	notUndefined<T>(array: (T | null | undefined)[], getValueFn: TypeGetValueFn<T> = defaultGetValueFn): T[] {
		this.checkArray(array);
		return array.filter((elem: T | null | undefined) => !this.utilBasic.isUndefined(getValueFn(elem as T))) as T[];
	}
	duplicates<T>(array: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): T[] {
		const duplicateEntries: T[] = array.filter((item: T, index: number) => array.findIndex((elem: T) => compareFn(elem, item)) !== index);
		return duplicateEntries;
	}


	/**------------------------------------------------------
	 * Get Index
	 */
	getValidIndex(index: number, size: number): number {
		if (index < 0) throw new Error(`UtilArray => getValidIndex => FATAL ERROR: provided index is negative`);
		return (index < size - 1) ? index : size - 1;
	}


	/**------------------------------------------------------
	 * Shuffle Elements
	 */
	shuffle<T>(array: T[], shuffleRounds: number = 10): T[] {

		//0 - check the function call
		if (!this.isArray(array) || array.length === 0) throw new Error(`UtilArray => shuffle => FATAL ERROR: provided array is not an array or is empty (provided array: ${array})`);

		//1 - shuffle all tags/keywords
		for (let k: number = 0; k < shuffleRounds; k++) {
			for (let i: number = array.length - 1; i > 0; i--) {
				const randIndex: number = Math.floor(Math.random() * (i + 1));
				[array[i], array[randIndex]] = [array[randIndex], array[i]];
			}
		}
		return array;
	}


	/**------------------------------------------------------
	 * Chunk Array
	 *  > source : https://decipher.dev/30-seconds-of-typescript/docs/chunkIntoN
	 */
	chunk<T>(array: T[], size: number): Array<T[]> {

		//0 - check the parameters
		this.checkArray(array);
		if (size < 1) throw new Error(`UtilArray => chunk => FATAL ERROR: value of size can not be smaller as 1 (value: "${size}")`);

		//1 - chunk the array into parts with defined size
		return Array.from({ length: Math.ceil(array.length / size) }, (v: any, i: number) => array.slice(i * size, i * size + size));
	}
	chunkIntoN<T>(array: T[], n: number): Array<T[]> {

		//0 - check the parameters
		this.checkArray(array);
		if (n < 1) throw new Error(`UtilArray => chunkIntoN => FATAL ERROR: value of count can not be smaller as 1 (value: "${n}")`);

		//1 - chunk the array into count/n parts
		const size: number = Math.ceil(array.length / n);
		return Array.from({ length: n }, (v: unknown, i: number) => array.slice(i * size, i * size + size));
	}

	chunkAt<T>(array: T[], splitIndex: number): [T[], T[]] {
		if (splitIndex < 0) throw new Error(`UtilArray => chunkAt => FATAL ERROR: the splitIndex can not be smaller then 0 (splitIndex: "${splitIndex}")`);
		const splitArray: T[][] = [0, splitIndex].map((n: number, i: number, m: number[]) => array.slice(n, m[i + 1]));
		return [splitArray[0], splitArray[1]];
	}


	/**------------------------------------------------------
	 * Helper Validation Checks
	 */
	private checkArray<T>(array: T[]) {
		this.checkArrays([array]);
	}
	private checkArrays<T>(arrays: Array<T[]>) {
		for (const array of arrays) {
			if (!this.isArray(array)) throw new Error(`UtilArray => checkArrays => FATAL ERROR: provided array is not an actual Array`);
		}
	}
	private checkIndex<T>(array: T[], index: number) {
		if (index < 0 || index >= array.length) throw new Error(`UtilArray => checkIndex => FATAL ERROR: provided index of "${index}" is not valid for the array with a length of "${array.length}"`);
	}
	private checkArrayForEmpty<T>(array: T[]) {
		if (!this.isArray(array)) throw new Error(`UtilArray => checkArrayForEmpty => FATAL ERROR: provided array is not an actual Array`);
		if (array.length === 0)   throw new Error(`UtilArray => checkArrayForEmpty => FATAL ERROR: provided array is empty, at least one element is required`);
	}
}
