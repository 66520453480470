import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_RESEARCH_SETTING_LIMITS: IFlyingResearchSettingsConfig = {
	MAX_MAILS	: 3
};

interface IFlyingResearchSettingsConfig {
	MAX_MAILS	: number;
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingResearchSettings {
	tmWatchlist : IFlyingTmWatchlistSetting;
}

export interface IFlyingTmWatchlistSetting {
	isActive	: boolean;
	mails		: string[];
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingResearchSettingsSchema implements ValidatorSchema<IFlyingResearchSettings> {
	tmWatchlist	= {
		isActive	: Validator.Boolean(),
		mails		: Validator.Array().isMaxSize(FLYING_RESEARCH_SETTING_LIMITS.MAX_MAILS)
						.each(Validator.String().isNotEmpty().isEmail())
	};
}
