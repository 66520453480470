import { utils as xlsxUtils, WorkBook, WorkSheet, write } from 'xlsx';
import { Util } from '@libs/utilities/util';

import { IExportSheetTable, IExportSheetOptions, IExportSheetRow, EnumOutputType } from './excel-export.interface';


export class ExcelExportService {

	//** Configurations */
	private readonly DEFAULT_EXPORT_OPTIONS: IExportSheetOptions = {
		outputType		: EnumOutputType.Base64,
		columnWidth		: 20
	};


	/**------------------------------------------------------
	 * Export Sheet with Builder
	 */
	// ... todo => crete a sheet builder that allows the manipulation of the data and the export at the end
	/*
		//0 - create the sheet
		const excelFile: ExcelSheetBuilder = this.excelExportService.createSheet();

		//1 - add the data
		excelFile.addSheet().addHeading(headings)
		for (const rowData of rows) {
			excelFile.addRow(rowData)
		}

		excelFile.renameSheet(0, 'My Sheet'). ....

		//2 - create the excel sheet data
		excelFile.exportSheet();
	*/


	/**------------------------------------------------------
	 * Export Table in Excel Sheet
	 */
	exportTableSheet<T>(sheetsInfo: IExportSheetTable[], options: Partial<IExportSheetOptions> = {}): T {

		//0 - define the options
		const defaultOptions: IExportSheetOptions = Util.Basic.deepCopy(this.DEFAULT_EXPORT_OPTIONS);
		options = Util.Basic.deepAssignPartial(defaultOptions, options);

		//1 - create workbook and worksheet
		const workBook : WorkBook  = xlsxUtils.book_new();

		//2 - iterate sheet
		for (const sheet of sheetsInfo) {

			//a. create worksheet
			const workSheet: WorkSheet = xlsxUtils.json_to_sheet([]);

			//b. adding the column width
			const formattedSheet: IFormattedSheet = this.getFormattedSheet(sheet.rows);
			const workSheetCols : Array<{ wch: number | undefined }> = Object.keys(formattedSheet.headings).map(() => ({ wch: options.columnWidth }));
			workSheet['!cols']  = workSheetCols;

			//c. add data into the sheet
			xlsxUtils.sheet_add_aoa(workSheet, [formattedSheet.headings]);
			xlsxUtils.sheet_add_json(workSheet, formattedSheet.sheetRows, { origin: 'A2', skipHeader: true });
			xlsxUtils.book_append_sheet(workBook, workSheet, sheet.sheetName);
		}

		//3 - export the sheet (start the download)
		return write(workBook, { type: options.outputType });
	}


	/**------------------------------------------------------
	 * Heading Extraction
	 */
	private getHeadings(rows: IExportSheetRow[]): string[] {

		//0 - define the headings array
		let headings: string[] = [];

		//1 - extract the headings
		for (const row of rows) {
			for (const cell of row) {

				//a. skip empty values
				if (Util.Object.isEmpty(cell)) continue;

				//b. get headings
				if (Util.String.hasIgnoreCase(headings, cell.heading)) continue;
				headings.push(cell.heading);
			}
		}

		//2 - order the extracted headings
		headings = headings.sort((a: string, b: string) => this.compareHeadings(a, b, rows)).reverse();

		//3 - return the headings
		return headings;
	}

	private compareHeadings(headingA: string, headingB: string, rows: IExportSheetRow[]): number {

		//0 - try to find both headings in array by comparing indexes
		for (const row of rows) {

			let positionA: number = -1;
			let positionB: number = -1;
			for (const [index, cell] of row.entries()) {

				//a. skip empty values
				if (Util.Object.isEmpty(cell)) continue;

				//b. check if any of the values is found
				if (Util.String.equalsIgnoreCase(cell.heading, headingA)) positionA = index;
				if (Util.String.equalsIgnoreCase(cell.heading, headingB)) positionB = index;

				//c. stop if we found both
				if (positionA !== -1 && positionB !== -1) return (positionA > positionB) ? -1 : 1;
			}
		}

		//1 - no row contains both, we don't know the order
		return 0;
	}


	/**------------------------------------------------------
	 * Format Row Data
	 */

	//** Get sheet headings */
	private getFormattedSheet(rows: IExportSheetRow[]): IFormattedSheet {

		//0 - check if rows is an array
		if (Util.Array.isNotArray(rows)) throw new Error(`ExcelExportService => getFormattedSheet => FATAL ERROR: rows must be an array`);

		//1 - get headings
		const headings: string[] = this.getHeadings(rows);

		//2 - get formatted sheet
		const sheetRows: TypeSheetRow[] = [];
		for (const row of rows) {

			//a. check row is valid array
			if (Util.Array.isNotArray(row)) throw new Error(`ExcelExportService => getFormattedSheet => FATAL ERROR: row must be an array`);

			//b. update sheet rows
			const sheetRow: TypeSheetRow = this.getFormattedSheetRow(row);
			sheetRows.push(sheetRow);
		}

		//3 - create formatted sheet object
		const formattedSheet: IFormattedSheet = {
			headings	: headings,
			sheetRows	: sheetRows
		};

		//4 - return the formatted sheet
		return formattedSheet;
	}

	private getFormattedSheetRow(row: IExportSheetRow): TypeSheetRow {

		//0 - create the sheet row object
		const sheetRow: TypeSheetRow = {};

		//1 - prepare formatted sheet
		for (const cell of row) {

			//a. check if cell and cell heading is empty
			if (Util.Object.isEmpty(cell) || Util.String.isEmpty(cell.heading)) continue;

			//b. prepare object
			sheetRow[cell.heading] = this.formatToString(cell.value);
		}

		//2 - return the extracted data
		return sheetRow;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private formatToString(value: string | number | boolean | string[]): string {

		//0 - try to convert the value to a string
		if (Util.String.isString(value)) return value as string;
		if (Util.Number.isNumber(value) || Util.Boolean.isBoolean(value)) return Util.String.toString(value);

		//1 - try to convert an array of string
		if (Util.String.areStrings(value as string[])) return (value as string[]).join(', ');

		//2 - if value can not be converted, throw an error
		throw new Error(`ExcelExportService => formatToString => FATAL ERROR: the value is neither a string, boolean, number, nor a array (provided value: "${value}")`);
	}
}


//** Types -------------------------------------- */
type TypeSheetRow = Record<string, string>;


//** Interfaces --------------------------------- */
interface IFormattedSheet {
	headings	: string[];
	sheetRows	: TypeSheetRow[];
}
