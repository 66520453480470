import { EnumPlatform, EnumWebsiteOption } from '../../app-constants/platforms/print-on-demand.constants';


/**------------------------------------------------------
 * Design Upload / History of the Uploads
 */
export interface IKdpUploads {
	flyId			: string;				// id of the design
	uploads			: IKdpUpload[];
}

export interface IKdpUpload {
	platform    	: EnumPlatform;
	websiteOption   : EnumWebsiteOption;
	accountMail 	: string;
	uploadedAt  	: Date;
	data        	: IUploadDataKdp;
}


/**------------------------------------------------------
 * Upload Specific Data
 */
export interface IUploadDataKdp {
	// ...
}
