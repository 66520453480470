/**------------------------------------------------------
 * Kw Table Options
 */
export enum EnumKwTableOptions {
	OpenInKWWorkbench			= 'open_in_kw_workbench',
	AddToKWBoard				= 'add_to_kw_board',
	AddToAdvertisingList		= 'add_to_advertising_list',

	CopyKeywords				= 'copy_keywords',
	CopyCompetitionAsin			= 'copy_competition_asin',
	CopySponsoredAsin			= 'copy_sponsored_asin',
	CopyBrands					= 'copy_brands',

	ExportSelectedAsExcel		= 'export_selected_columns_as_excel',
	ExportAllColumnsAsExcel		= 'export_all_columns_as_excel'
}


/**------------------------------------------------------
 * Table Columns
 */
export enum EnumKeywordDataset {

	// Keyword / Text
	Keyword 				= 'keyword',
	KeywordWordCount 		= 'keyword_word_count',
	KeywordPhraseLength 	= 'keyword_phrase_length',

	// Amazon Search Volume
	AmazonSearchVolume 		= 'amazon_search_volume',
	AmazonCpc 				= 'amazon_cpc',
	AmazonAdsCmp 			= 'amazon_ads_cmp',

	// Google Search Volume
	GoogleSearchVolume 		= 'google_search_volume',
	GoogleCpc 				= 'google_cpc',
	GoogleAdsCmp 			= 'google_ads_cmp',

	// Trends
	Trend30Days 			= 'trend_30_days',
	Trend3Month 			= 'trend_3_month',
	Trend12Month 			= 'trend_12_month',

	// Products & Rankings
	CompetingProducts		= 'competing_products',
	ProductDistribution		= 'product_distribution',
	TopAsinRanks			= 'top_asin_ranks',
	ProductPricing			= 'product_pricing',
	ProductReviews			= 'product_reviews',
	ReviewsPage1			= 'reviews_page_1',
	Brands					= 'brands',
	Categories				= 'categories',
	Sponsored				= 'sponsored',

	// Niche & Revenue
	RevenuePage1			= 'revenue_page_1',
	SalesPage1				= 'sales_page_1',
	AvgSalesPage1			= 'avg_sales_page_1',

	// Relevant Keyword
	AmazonSuggestions		= 'amazon_suggestions',
	GoogleWebSearchSuggestions = 'google_web_search_suggestions',
	GoogleShoppingSuggestions = 'google_shopping_suggestions',

	// Positioning
	SalesFactorPage1		= 'sales_factor-page1',
	AdsRequirement			= 'ads_requirement',
	RankClaimPage1			= 'rank_claim_page1',
	RankClaimTop3			= 'rank_claim_top3',
	ExpectedSalesPage1		= 'expected_sales_page1',
	ExpectedSalesTop3		= 'expected_sales_top3',

	// Scores
	CompetitiveScore		= 'competitive_score',
	OrganicRankPotential	= 'organic_rank_potential',
	AdPotential				= 'ad_potential',
	ClothingRelevance		= 'clothing_relevance',
	BookRelevance			= 'book_relevance',

	// Actions
	Delete 					= 'delete'
}
