import { Injectable, Injector, Type } from '@angular/core';


/**------------------------------------------------------
 * App Injector
 * ------------
 * > Getting instance of service without constructor injection
 * > Example: const deleteInstance = AppInjector.get(DeleteController)
 * ------------
 * > Note: Allows for retrieving singletons using "AppInjector.get(MyService)" (whereas
 * > "ReflectiveInjector.resolveAndCreate(MyService)" would create a new instance of the service).
 */
@Injectable()
export class NgAppInjectorService {

	//** Angular Injector Instance */
	private injector: Injector = null!;

	//** Function Name */
	create<T>(token: Type<T>): T {

		//0 - check if the instance was already initialized
		if (!this.injector) throw new Error(`NgAppInjectorService => create => FATAL ERROR: injector instance is not defined yet, it has to be defined at the root level of the project (look at the README.md for more details)`);

		//1 - create the class instance with the injector
		return this.injector.get(token);
	}

	//** Set the Injector instance only one time */
	setAppInjector(injectorInstance: Injector): void {

		//0 - check if the injector was already defined
		if (this.injector) throw new Error(`AppInjectorService => setAppInjector => FATAL ERROR: AppInjector was already set (only call this function to set the AppInjector once)`);

		//1 - set the app injector
		this.injector = injectorInstance;
	}
}
