import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumShutterStockSorting, IShutterStockSearchOptions, SHUTTER_STOCK_CONFIG, SHUTTER_STOCK_DEFAULT_OPTIONS } from './shutterstock-search.data';


/**------------------------------------------------------
 * ShutterStock Search Url
 */
export class ShutterStockSearch extends AbstractSearchLinks<IShutterStockSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumShutterStockSorting>[] {
		return SHUTTER_STOCK_CONFIG.sortings as ISearchParameterConfig<EnumShutterStockSorting>[];
	}

	constructor() {
		super(SHUTTER_STOCK_CONFIG, SHUTTER_STOCK_DEFAULT_OPTIONS);
	}
}
