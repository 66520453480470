/**------------------------------------------------------
 * Continent
 * ---------
 * > Holds comprehensive configurations related to global
 * > continents, facilitating organized reference and data
 * > representation for geographical regions.
 */
export * from './continent/continent.interface';
export * from './continent/continent.data';


/**------------------------------------------------------
 * Country
 * -------
 * > Encompasses configurations for all worldwide countries,
 * > providing structured information for accurate representation
 * > and categorization of nations.
 */
export * from './country/country.interface';
export * from './country/country.data';


/**------------------------------------------------------
 * Currency
 * --------
 * > Stores data about currencies used across different
 * > countries, ensuring consistent and accessible information
 * > about global monetary units.
 */
export * from './currency/currency.interface';
export * from './currency/currency.data';


/**------------------------------------------------------
 * Language
 * --------
 * > Contains language-related configurations.
 */
export * from './language/language.interface';
export * from './language/language.data';
export * from './language/language-locale.interface';
