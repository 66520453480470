import { Util } from '@libs/utilities/util';

import { ExcelSheetHelper } from './excel-sheet.helper';
import { ExcelSheetWrapper, ISheetValueChangeOptions } from './excel-sheet.wrapper';
import { IImportExcelData, IImportSheetCellValue, IImportSheetRow, IImportSheetTable, IImportSheetTableHeading } from '../helper/excel-import.interface';


/**------------------------------------------------------
 * Excel Table Wrapper
 */
export class ExcelTableSheetWrapper extends ExcelSheetWrapper {

	//** Configurations */
	private readonly TABLE_HEADING_DEFAULT_OPTIONS: ITableHeadingsOptions = {
		ignoreHeadings	: [],
		filterEmpty		: false
	};

	private readonly SHEET_ROWS_DEFAULT_OPTIONS: ITableSheetRowsOption = {
		filterEmpty 	: false,
		startRow		: 0,
		endRow	  		: Number.MAX_SAFE_INTEGER
	};

	constructor(
		protected override excelData		: IImportExcelData,
		protected override excelSheetHelper	: ExcelSheetHelper			// injected into the creator, and passed down to this instance
	) {
		super(excelData, excelSheetHelper);
	}


	/**------------------------------------------------------
	 * Table Sheet Checks
	 */
	isTableSheet(): boolean {

		//0 - check if sheet is empty
		if (Util.Array.isEmpty(this.excelData.sheets)) return false;

		//1 - get sheet rows and check empty
		const sheetRows: IImportSheetRow[] = this.excelData.sheets[0].rows;
		if (Util.Array.isEmpty(sheetRows) || sheetRows.length < 2) return false;

		//2 - is any heading a number?
		for (const cellValue of sheetRows[0].cellValues) {
			if (Util.Number.isNumber(cellValue.value)) return false;
		}

		//3 - are there any values without a heading information
		const dataRows: IImportSheetRow[] = sheetRows.slice(1);
		for (const row of dataRows) {
			for (const cellValue of row.cellValues) {
				if (!Util.String.isEmpty(cellValue.value) && Util.String.isEmpty(cellValue.metaInfo.heading)) return false;
			}
		}

		//4 - return the valid status
		return true;
	}

	getTableRowCount(sheetIndex: number = 0): number {
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		return this.getTableSheetRows().length;
	}


	/**------------------------------------------------------
	 * Table Sheet Headings
	 */
	getTableHeadings(options: Partial<ITableHeadingsOptions> = this.TABLE_HEADING_DEFAULT_OPTIONS): IImportSheetTableHeading[] {

		//0 - get all headings and defined heading
		const allOptions : ITableHeadingsOptions = Util.Function.assignOptions(this.TABLE_HEADING_DEFAULT_OPTIONS, options) as ITableHeadingsOptions;
		let tableHeadings: IImportSheetTableHeading[] = this.excelSheetHelper.getAllSheetHeadings(this.excelData.sheets);

		//1 - do we need to filter out empty ones?
		if (allOptions.filterEmpty) {
			tableHeadings = tableHeadings.filter((importSheetTableHeading: IImportSheetTableHeading) => Util.String.isNotEmpty(importSheetTableHeading.heading));
		}

		//2 - exclude the ignore headings
		tableHeadings = tableHeadings.filter((importSheetTableHeading: IImportSheetTableHeading) => {

			//a. check was heading ignored
			for (const ignoreHeading of allOptions.ignoreHeadings) {
				if (Util.String.equalsIgnoreCase(importSheetTableHeading.heading, ignoreHeading)) return false;
			}

			//b. heading is not ignored
			return true;
		});

		//3 - return the remaining headings
		return tableHeadings;
	}


	/**------------------------------------------------------
	 * Duplicate & Unique Headings
	 */
	hasTableDuplicateHeading(): boolean {

		//0 - get all headings and remove undefined value
		const allDefinedHeadings: IImportSheetTableHeading[] = this.excelSheetHelper.getDefinedHeadings(this.excelData.sheets);

		//1 - get unique headers
		const getUniqueHeadings	: IImportSheetTableHeading[] = allDefinedHeadings.filter((heading: IImportSheetTableHeading, index: number) => this.excelSheetHelper.isUniqueHeading(heading, index, allDefinedHeadings));
		return allDefinedHeadings.length !== getUniqueHeadings.length;
	}

	getTableDuplicateHeading(ignoreHeadings: string[] = []): IImportSheetTableHeading[] {

		//0 - get all heading
		const allTableHeadings : IImportSheetTableHeading[] = this.getTableHeadings({ ignoreHeadings, filterEmpty: true });

		//1 - get duplicate heading
		const duplicateHeadings: IImportSheetTableHeading[] = [];
		for (const [index, headingObject] of allTableHeadings.entries()) {

			//a. check if heading is unique and if it was already added
			const isHeadingUnique: boolean = this.excelSheetHelper.isUniqueHeading(headingObject, index, allTableHeadings);
			const isAlreadyAdded : boolean = duplicateHeadings.some((entry: IImportSheetTableHeading) => entry.heading === headingObject.heading);
			if (isHeadingUnique || isAlreadyAdded) continue;

			//b. if it is a duplicate heading, and not added yet, add it
			duplicateHeadings.push(headingObject);
		}

		//2 - return the duplicate headings
		return duplicateHeadings;
	}

	getTableUniqueHeading(ignoreHeadings: string[] = []): IImportSheetTableHeading[] {

		//0 - get all table headings
		const tableHeadings: IImportSheetTableHeading[] = this.getTableHeadings({ ignoreHeadings, filterEmpty: true });

		//1 - get only the unique headings
		return tableHeadings.filter((heading: IImportSheetTableHeading, index: number) => this.excelSheetHelper.isUniqueHeading(heading, index, tableHeadings));
	}


	/**------------------------------------------------------
	 * Table Sheet Access
	 */
	getTableSheet(sheetIndex: number = 0): IImportSheetTable {

		//0 - validate sheet index
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);

		//1 - create import sheet object
		const importSheetTable: IImportSheetTable = {
			headings	: this.excelSheetHelper.getDefinedHeadings(this.excelData.sheets, sheetIndex),
			rows		: this.getTableSheetRows(sheetIndex),
			metaInfo	: {
				sheet   : this.excelData.sheets[sheetIndex].metaInfo.sheet,
				file	: this.excelData.metaInfo.file
			}
		};

		//2 - return sheet rows
		return importSheetTable;
	}


	/**------------------------------------------------------
	 * Table Rows Access
	 */
	getTableSheetRows(sheetIndex: number = 0, filterEmpty: boolean = false): IImportSheetRow[] {

		//0 - validate sheet index
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);

		//1 - get table sheet rows
		const sheetRows: IImportSheetRow[] = this.getSheetRows(sheetIndex, filterEmpty);
		return sheetRows.slice(1);
	}

	getTableSheetRow(sheetIndex: number, rowIndex: number, filterEmpty: boolean = false): IImportSheetRow {

		//0 - validate sheet
		rowIndex += 1;			// the row 0 is for heading
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		this.excelSheetHelper.checkIndex(this.excelData.sheets[sheetIndex].rows, rowIndex);

		//1 - get table sheet row
		return this.excelSheetHelper.getSheetRow(this.excelData.sheets, sheetIndex, rowIndex, filterEmpty);
	}

	getTableSheetRowsRange(sheetIndex: number = 0, options: ITableSheetRowsOption = this.SHEET_ROWS_DEFAULT_OPTIONS): IImportSheetRow[] {

		//0 - validate sheet index
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);

		//1 - validate sheet input
		options = Util.Function.assignOptions(this.TABLE_HEADING_DEFAULT_OPTIONS, options) as ITableSheetRowsOption & ITableHeadingsOptions;
		if (!Util.Number.areNumbers([options.startRow, options.endRow])) throw new Error(`ExcelTableSheetWrapper => getTableSheetRowsRange => FATAL ERROR: start row and end row must be an number`);

		//2 - check row index is valid
		if (options.startRow > options.endRow) throw new Error(`ExcelTableSheetWrapper => getTableSheetRowsRange => FATAL ERROR: start row must be less than of end row`);

		//3 - get table sheet rows
		const sheetRows: IImportSheetRow[] = this.getSheetRows(sheetIndex, options.filterEmpty);

		//4 - get row between index
		const startRow	: number = options.startRow + 1; 									// +1 for start from 2nd row because 1st row is heading
		const endRow	: number = options.endRow + 1; 										// +1 for end upto endRow + 1 because 1st row is heading

		//5 - validate sheet
		this.excelSheetHelper.checkIndex(sheetRows, startRow);
		return sheetRows.slice(startRow, endRow);
	}


	/**------------------------------------------------------
	 * Data Manipulation
	 */
	cropTableSheetRows(sheetIndex: number, rowStartIndex: number, rowEndIndex: number): ExcelTableSheetWrapper {

		//0 - deep copy original data
		const excelDataCopy: IImportExcelData = Util.Basic.deepCopy(this.excelData);

		//2 - check sheet index and get sheet rows
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);
		const importSheetRow: IImportSheetRow[] = excelDataCopy.sheets[sheetIndex].rows;

		//3 - validate row index
		if (rowEndIndex >= importSheetRow.length) rowEndIndex = importSheetRow.length - 1; 	// last row
		this.excelSheetHelper.checkIndex(importSheetRow, rowStartIndex);
		this.excelSheetHelper.checkIndex(importSheetRow, rowEndIndex);
		if (rowStartIndex > rowEndIndex) throw new Error(`ExcelSheetWrapper => cropTableSheetRows => FATAL ERROR: rowStartIndex must be less than rowEndIndex`);

		//3 - update rows
		excelDataCopy.sheets[sheetIndex].rows = [
			...importSheetRow.slice(0, 1),
			...importSheetRow.slice(rowStartIndex, rowEndIndex + 1)
		];

		//4 - return a new immutable object
		return new ExcelTableSheetWrapper(excelDataCopy, this.excelSheetHelper);
	}

	removeTableSheetRow(sheetIndex: number, rowIndex: number): ExcelTableSheetWrapper {

		//0 - deep copy original data
		const excelDataCopy: IImportExcelData = Util.Basic.deepCopy(this.excelData);

		//1 - check sheet index
		this.excelSheetHelper.checkIndex(this.excelData.sheets, sheetIndex);

		//2 - get import sheet rows and check index
		const importSheetRow: IImportSheetRow[] = excelDataCopy.sheets[sheetIndex].rows;
		this.excelSheetHelper.checkIndex(importSheetRow, rowIndex + 1);

		//3 - remove the row
		importSheetRow.splice(rowIndex + 1, 1);

		//4 - return a new immutable object
		return new ExcelTableSheetWrapper(excelDataCopy, this.excelSheetHelper);
	}

	changeTableSheetValue(value: string, indexes: ISheetValueChangeOptions): ExcelTableSheetWrapper {

		//0 - deep copy original data
		const excelDataCopy: IImportExcelData = Util.Basic.deepCopy(this.excelData);

		//1 - check sheet index and get sheet row
		this.excelSheetHelper.checkIndex(this.excelData.sheets, indexes.sheetIndex);
		const importSheetRow: IImportSheetRow[] = excelDataCopy.sheets[indexes.sheetIndex].rows;

		//2 - get import sheet rows and check index
		this.excelSheetHelper.checkIndex(importSheetRow, indexes.rowIndex);
		const row: IImportSheetCellValue[] = importSheetRow[indexes.rowIndex].cellValues;

		//3 - get row by row index and check
		this.excelSheetHelper.checkIndex(row, indexes.columnIndex);
		row[indexes.columnIndex].value = value;

		//4 - return a new immutable object
		return new ExcelTableSheetWrapper(excelDataCopy, this.excelSheetHelper);
	}
}


//** Interfaces --------------------------------- */
export interface ITableHeadingsOptions {
	ignoreHeadings	: string[];
	filterEmpty		: boolean;
}

export interface ITableSheetRowsOption {
	filterEmpty	: boolean;
	startRow	: number;
	endRow		: number;
}
