<!-- Popover button -->
<span
	popoverClass="info-popover"
	[popoverTitle]="popoverTitle"
	[ngbPopover]="popoverBody"
	autoClose="outside"
	[placement]="popoverConfig.placement"
	[disablePopover]="disabled"
	[animation]="true"
	[triggers]="popoverConfig.triggers"
	[container]="popoverConfig.containerPosition"
	[openDelay]="delay"
	#popover="ngbPopover"
	[@fadeInOnEnter]
	[@fadeOutOnLeave]
	(click)="$event.stopPropagation()"
>
	<span #popoverContent>
		<ng-content></ng-content>
	</span>
	<ng-container
		*ngIf="!hasPopoverContent"
		[ngTemplateOutlet]="infoIcon"
	></ng-container>
</span>

<!-------- Templates ---------->

<!-- Info icon template -->
<ng-template #infoIcon>
	<ng-fa-icon
		role="button"
		class="p-1"
		icon="circle-info"
		[fixedWidth]="false"
	></ng-fa-icon>
</ng-template>

<!-- Popover title template -->
<ng-template #popoverTitle>
	<div
		class="info-popover-main-block d-flex justify-content-between"
		[class]="'info-popover-' + popoverConfig.size"
		[@fadeInOnEnter]
		[@fadeOutOnLeave]
	>
		<h6 class="m-0 p-0">{{ title }}</h6>
		<span
			*ngIf="popoverConfig"
			class="info-icon"
		>
			<ng-fa-icon
				class="d-inline-flex"
				[iconStyle]="popoverConfig.icon.style"
				[icon]="popoverConfig.icon.name"
			></ng-fa-icon>
		</span>
	</div>
</ng-template>

<!-- Check if not template -->
<ng-template #noTemplate>
	<div
		class="info-message text-justify scroll-bar pe-2"
		[innerHtml]="message"
	></div>
</ng-template>

<!-- Popover body -->
<ng-template #popoverBody>
	<div
		*ngIf="popoverConfig"
		class="info-popover-main-block"
		[class]="'info-popover-' + popoverConfig.size"
		[@fadeInOnEnter]
		[@fadeOutOnLeave]
	>
		<!-- Check if message body is template -->
		<span *ngIf="isMessageTemplate; else noTemplate">
			<ng-container [ngTemplateOutlet]="templateMessage"></ng-container>
		</span>

		<!-- Check if actions -->
		<div *ngIf="popoverConfig.buttons.length !== 0">
			<hr class="mb-1" />
			<div class="d-flex m-0 info-link">
				<ng-container *ngFor="let buttonGroup of popoverConfig.buttons; let iLast = last">
					<ng-container *ngFor="let button of buttonGroup; let jLast = last">
						<button
							class="btn btn-sm btn-link bg-transparent border-0"
							(click)="action(button.actionCode!)"
							[class.me-2]="!iLast && jLast"
						>
							{{ button?.label }}
						</button>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
