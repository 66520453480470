import { EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { EnumFlyingQuoteType } from '../../interfaces/ai-research/quotes.interface';


/**------------------------------------------------------
 * Constants
 */
export const FLYING_AI_QUOTES_CONFIG: IFlyingAiQuotesConfig = {
	MAX_SEARCH_LENGTH	: 50,
	MAX_RESULTS		 	: 25
};

interface IFlyingAiQuotesConfig {
	MAX_SEARCH_LENGTH	: number;
	MAX_RESULTS		 	: number;
}


/**------------------------------------------------------
 * Requests
 */
export interface IFlyingQuotesAndPunsRequest {
	search			: string;
	type			: EnumFlyingQuoteType;
	language		: EnumLanguage;
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingQuotesAndPunsResponse {
	suggestions		: string[];
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingQuotesAndPunsRequestSchema implements ValidatorSchema<IFlyingQuotesAndPunsRequest> {
	search			= Validator.String().isMaxLength(FLYING_AI_QUOTES_CONFIG.MAX_SEARCH_LENGTH)		// search text can be empty
						.hasNoHtmlScripts().toLowerCase().trim().purifyWhitespaces();
	type			= Validator.Enum(EnumFlyingQuoteType);
	language		= Validator.Enum(EnumLanguage);
}

export class FlyingQuotesAndPunsResponseSchema implements ValidatorSchema<IFlyingQuotesAndPunsResponse> {
	suggestions		= Validator.Array().each(Validator.String().isNotEmpty());
}
