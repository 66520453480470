import { EnumPodPlatform } from './pod-platforms/platform.constants';


/**------------------------------------------------------
 * Trademark Offices & Territories
 */
export enum EnumTmOffice {
	WO 		= 'WO',
	EM 		= 'EM',

	US 		= 'US',
	GB 		= 'GB',
	DE 		= 'DE',
	FR 		= 'FR',
	ES 		= 'ES',
	IT 		= 'IT'
}

export enum EnumTmTerritory {
	All 	= 'all_territories',
	US		= 'us',
	DE		= 'de',
	GB		= 'gb',
	FR		= 'fr',
	IT		= 'it',
	ES		= 'es'
}


/**------------------------------------------------------
 * Trademark Nice Classes
 */
export enum EnumTmNiceClass {
	Class2		= '2',
	Class9		= '9',
	Class10		= '10',
	Class16		= '16',
	Class18		= '18',
	Class21		= '21',
	Class24		= '24',
	Class25		= '25',
	Class26		= '26',
	Class42		= '42'

	// ... adding soon
	// Class14	= '14',
	// Class27	= '27',
	// Class28	= '28',
}
export enum EnumTmProductClass {
	All					= 'all',

	MetalWallArt		= 'metal_wallart',
	WallArt				= 'wallart',
	Clothing        	= 'clothing',
	Accessories 		= 'accessories',
	MobileAccessories	= 'mobile_accessories',
	Books               = 'books'

	/* MetalWallArt		= '02',
	FaceMasks			= '10',
	ClothBag			= '18',
	CupsAndMugs			= '21',
	Cushions			= '24',
	ShowerCurtains		= '24',
	Buttons				= '26',
	GraphicArtsDesign 	= '42',

	// ... adding soon
	/*Clock				= '14',
	BathMats			= '27',
	Puzzles				= '28', */
}


/**------------------------------------------------------
 * Trademark Status, Type& Date Types
 */
export enum EnumTmStatus {
	Filed 			= 'filed',
	Registered 		= 'registered',
	Ended 			= 'ended',
	Expired 		= 'expired'
}

export enum EnumTmDateType {
	ApplicationDate 	= 'application_date',
	RegistrationDate 	= 'registration_date'
}

export enum EnumTmType {
	Word 	= 'Word'
}


/**------------------------------------------------------
 * Mappings
 */
export const MAPPING_COUNTRY_OFFICE: Record<EnumTmTerritory, EnumTmOffice[]> = {
	[EnumTmTerritory.All]			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.US, EnumTmOffice.GB, EnumTmOffice.DE, EnumTmOffice.FR, EnumTmOffice.ES, EnumTmOffice.IT],
	[EnumTmTerritory.US] 			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.US],
	[EnumTmTerritory.GB] 			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.GB],
	[EnumTmTerritory.DE] 			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.DE],
	[EnumTmTerritory.FR] 			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.FR],
	[EnumTmTerritory.ES] 			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.ES],
	[EnumTmTerritory.IT] 			: [EnumTmOffice.WO, EnumTmOffice.EM, EnumTmOffice.IT]
};

// eslint-disable-next-line @typescript-eslint/typedef
export const MAPPING_TRADEMARK = {
	[EnumTmOffice.US]				: 'United States (USTPO)',
	[EnumTmOffice.GB]				: 'United Kingdom (IPO)',
	[EnumTmOffice.DE]				: 'Germany (DPMA)',
	[EnumTmOffice.FR]				: 'France (INPI)',
	[EnumTmOffice.IT]				: 'Italy (UIBM)',
	[EnumTmOffice.ES]				: 'Spain (OEPM)',
	[EnumTmOffice.WO]				: 'Worldwide (WIPO)',
	[EnumTmOffice.EM]				: 'Europe (EUIPO)',

	[EnumTmTerritory.All]			: 'All',
	[EnumTmTerritory.US]			: 'US - United States',
	[EnumTmTerritory.GB]			: 'GB - United Kingdom',
	[EnumTmTerritory.DE]			: 'DE - Germany',
	[EnumTmTerritory.FR]			: 'FR - France',
	[EnumTmTerritory.IT]			: 'IT - Italy',
	[EnumTmTerritory.ES]			: 'ES - Spain',

	[EnumTmNiceClass.Class2]		: 'Paintings, Printings, and Metal Wall Art',
	[EnumTmNiceClass.Class9]		: 'Mobile Accessories',
	[EnumTmNiceClass.Class10]		: 'Face Masks',
	// [EnumTmNiceClass.Class14]		: 'Clocks',
	[EnumTmNiceClass.Class16]		: 'Books',
	[EnumTmNiceClass.Class18]		: 'Cloth Bag',
	[EnumTmNiceClass.Class21]		: 'Cups and Mugs',
	[EnumTmNiceClass.Class24]		: 'Shower Curtains',
	[EnumTmNiceClass.Class25]		: 'Clothing',
	[EnumTmNiceClass.Class26]		: 'Buttons',
	// [EnumTmNiceClass.Class27]		: 'Bath Mats',
	// [EnumTmNiceClass.Class28]		: 'Puzzles',
	[EnumTmNiceClass.Class42]		: 'Graphic Arts Designs',

	[EnumTmStatus.Filed]			: 'Filed',
	[EnumTmStatus.Registered]		: 'Registered',
	[EnumTmStatus.Ended]			: 'Ended',
	[EnumTmStatus.Expired]			: 'Expired'
};

// eslint-disable-next-line @typescript-eslint/typedef
export const MAPPING_NICE_CLASSES = {
	[EnumTmProductClass.All]    		: [EnumTmNiceClass.Class2, EnumTmNiceClass.Class9, EnumTmNiceClass.Class10, EnumTmNiceClass.Class16, EnumTmNiceClass.Class18,
		EnumTmNiceClass.Class21, EnumTmNiceClass.Class24, EnumTmNiceClass.Class25, EnumTmNiceClass.Class26, EnumTmNiceClass.Class42],

	[EnumPodPlatform.MBA]    			: [EnumTmNiceClass.Class9, EnumTmNiceClass.Class25, EnumTmNiceClass.Class42],
	[EnumPodPlatform.Displate]  		: [EnumTmNiceClass.Class2],
	[EnumPodPlatform.KDP]      			: [EnumTmNiceClass.Class16],

	[EnumTmProductClass.MetalWallArt]	: [EnumTmNiceClass.Class2],
	[EnumTmProductClass.WallArt]     	: [EnumTmNiceClass.Class16],
	[EnumTmProductClass.Clothing]    	: [EnumTmNiceClass.Class10, EnumTmNiceClass.Class25, EnumTmNiceClass.Class42],
	[EnumTmProductClass.Accessories] 	: [EnumTmNiceClass.Class9, EnumTmNiceClass.Class18, EnumTmNiceClass.Class21, EnumTmNiceClass.Class24, EnumTmNiceClass.Class26],
	[EnumTmProductClass.MobileAccessories]: [EnumTmNiceClass.Class9],
	[EnumTmProductClass.Books]          : [EnumTmNiceClass.Class16]
};
