import { Util } from '@libs/utilities/util';

import { IImportSheetCellValue, IImportSheetData, IImportSheetRow, IImportSheetTableHeading } from '../helper/excel-import.interface';


export class ExcelSheetHelper {


	/**------------------------------------------------------
	 * Heading Helpers
	 */
	getAllSheetHeadings(sheets: IImportSheetData[], sheetIndex: number = 0): IImportSheetTableHeading[] {

		//0 - get first sheet row
		const sheetRow: IImportSheetRow = this.getSheetRow(sheets, sheetIndex, 0);

		//1 - get sheet headings
		const headings: IImportSheetTableHeading[] = [];
		for (const cellValue of sheetRow.cellValues) {

			//a. check if heading is defined
			if (!Util.Basic.isDefined(cellValue.metaInfo.heading)) continue;

			//b. convert header to a string (just in case it is a number)
			const importSheetTableHeading: IImportSheetTableHeading = {
				heading	 	: Util.String.toString(cellValue.value),
				metaInfo	: {
					columnName   : cellValue.metaInfo.columnName,	   						// Alphabetic Column Naming: A, B, C
					columnIndex  : cellValue.metaInfo.columnIndex,							// numeric index, starting at 0
					rowName	  	 : Util.String.toString(cellValue.metaInfo.rowIndex + 1), 	// starting at 1 like in excel (row index + 1)
					rowIndex	 : cellValue.metaInfo.rowIndex		  						// numeric index, starting at 0
				}
			};

			//c. add the heading to the list
			headings.push(importSheetTableHeading);
		}

		//2 - return the heading
		return headings;
	}

	getDefinedHeadings(sheets: IImportSheetData[], sheetIndex: number = 0): IImportSheetTableHeading[] {

		//0 - get all sheet headings
		const allHeadings		: IImportSheetTableHeading[] = this.getAllSheetHeadings(sheets, sheetIndex);
		if (Util.Array.isNotArray(allHeadings)) return [];

		//1 - get defined headers
		const allDefinedHeadings: IImportSheetTableHeading[] = Util.Array.removeUndefined(allHeadings);
		const filterNonEmpty	: IImportSheetTableHeading[] = allDefinedHeadings.filter((allDefinedHeading: IImportSheetTableHeading) => Util.String.isNotEmpty(allDefinedHeading.heading));
		return filterNonEmpty;
	}

	isUniqueHeading(heading: IImportSheetTableHeading, index: number, allHeadings: IImportSheetTableHeading[]): boolean {

		//0 - check all defined headings entries
		for (const [headerIndex, importSheetTableHeading] of allHeadings.entries()) {

			//a. skip if same index
			if (headerIndex === index) continue;

			//b. match the value
			if (importSheetTableHeading.heading === heading.heading) return false;
		}

		//1 - get unique heading status
		return true;
	}


	/**------------------------------------------------------
	 * Row Data Helpers
	 */
	getSheetRow(sheets: IImportSheetData[], sheetIndex: number, rowIndex: number, filterEmpty: boolean = false): IImportSheetRow {

		//0 - validate sheet index
		this.checkIndex(sheets, sheetIndex);
		this.checkIndex(sheets[sheetIndex].rows, rowIndex);

		//1 - filter out empty values
		let importSheetRow: IImportSheetRow = sheets[sheetIndex].rows[rowIndex];
		if (filterEmpty) importSheetRow = this.filterEmptyRowData(importSheetRow);

		//2 - return the result
		return importSheetRow;
	}

	//** Check is row duplicated  */
	isDuplicatedRow(sheetRow: IImportSheetRow, activeRow: IImportSheetRow): boolean {

		//0 - set duplicate status
		let isDuplicate: boolean = false;

		//1 - check cell is duplicated
		for (const [cellIndex, cellValue] of sheetRow.cellValues.entries()) {

			//a. skip if cell undefined
			if (Util.String.isEmpty(cellValue.value) || Util.String.isEmpty(activeRow.cellValues[cellIndex].value)) continue;

			//b. check if cell value is equal
			if (Util.String.equalsIgnoreCase(cellValue.value, activeRow.cellValues[cellIndex].value)) {
				isDuplicate = true;
				break;
			}
		}

		//2 - return duplication status
		return isDuplicate;
	}


	/**------------------------------------------------------
	 * Row Data Filter Helpers
	 */
	filterEmptyRowsData(rows: IImportSheetRow[]): IImportSheetRow[] {

		//0 - deep copy the data, so we don't change the original data
		const rowsCopy: IImportSheetRow[] = Util.Basic.deepCopy(rows);

		//1 - filter out empty element
		const filteredRows: IImportSheetRow[] = rowsCopy.filter((row: IImportSheetRow) => {

			//a. is the row empty (then remove row)?
			const cellValues: IImportSheetCellValue[] = row.cellValues.filter((cellValue: IImportSheetCellValue) => Util.String.isNotEmpty(cellValue.value));
			if (Util.Array.isEmpty(cellValues)) return false;

			//b. set only the not empty values
			row.cellValues = cellValues;
			return true;
		});

		//2 - return the filtered rows
		return filteredRows;
	}

	filterEmptyRowData(row: IImportSheetRow): IImportSheetRow {
		return this.filterEmptyRowsData([row])[0];
	}


	/**------------------------------------------------------
	 * Validation Helpers
	 */

	//** Check valid index */
	checkIndex<T>(array: T[], index: number): void {
		if (!Util.Array.isValidIndex(array, index)) throw new Error(`ExcelImportWrapper => checkIndex => FATAL ERROR: index of "${index}" is invalid for array length of "${array.length}"`);
	}
}
