/* eslint-disable @typescript-eslint/typedef */
import { EnumLanguage } from '../../international';


/**------------------------------------------------------
 * Language Specific Charset
 */
export const LANGUAGE_CHARACTERS = {
	// cspell:disable
	ALPHABET: {
		[EnumLanguage.EN]: `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`,
		[EnumLanguage.DE]: `ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜabcdefghijklmnopqrstuvwxyzäöüß`,
		[EnumLanguage.FR]: `ABCDEFGHIJKLMNOPQRSTUVWXYZÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒabcdefghijklmnopqrstuvwxyzùûüÿàâæçéèêëïîôœ`,
		[EnumLanguage.ES]: `ABCDEFGHIJKLMNOPQRSTUVWXYZÁÉÍÑÓÚÜabcdefghijklmnopqrstuvwxyzáéíñóúü`,
		[EnumLanguage.IT]: `ABCDEFGHIJKLMNOPQRSTUVWXYZÀÈÉÌÒÓÙabcdefghijklmnopqrstuvwxyzàèéìòóù`
	},
	SPECIAL_CHARS: {
		[EnumLanguage.DE]: `ÄÖÜäöüß`,
		[EnumLanguage.FR]: `ÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒùûüÿàâæçéèêëïîôœ`,
		[EnumLanguage.ES]: `ÁÉÍÑÓÚÜáéíñóúü`,
		[EnumLanguage.IT]: `ÀÈÉÌÒÓÙàèéìòóù`
	},
	NUMBER_CHARS: `0123456789`
	// cspell:enable
};
