import { EnumDesktopPermission } from './device-notification.interface';
import { NotificationWrapper } from './helpers/notification.wrapper';


/**------------------------------------------------------
 * Device Notification
 */
export class DeviceNotificationService {


	/**------------------------------------------------------
	 * Check if Notification are Supported
	 */
	isNotificationSupported(): boolean {
		return 'Notification' in window;
	}


	/**------------------------------------------------------
	 * Check for Permission
	 */
	isPermissionGranted(): boolean {
		return Notification.permission === EnumDesktopPermission.Granted;
	}
	isPermissionDenied(): boolean {
		return Notification.permission === EnumDesktopPermission.Denied;
	}


	/**------------------------------------------------------
	 * Post Desktop Notification to the User
	 */
	postNotification(title: string, options?: NotificationOptions): NotificationWrapper {
		return new NotificationWrapper(title, options);
	}
}
