import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Teepublic Template
 */
export interface ITeepublicTemplateOptions {
	designLink 		: string;
	options			: {
		mature		: boolean;
		language	: EnumLanguage;
	};
}
