/**------------------------------------------------------
 * Handling the Storage of Cookies
 */
export class CookiesStorageObject {

	//** Configurations */
	private readonly DEFAULT_DATA: ICookiesStorageData = {
		id		: '',
		value	: ''
	};

	constructor(
		private expiry	: Date,
		private document: Document
	) {}

	//** Set value of cookie */
	setCookie(id: string, value: string): void {
		this.setCookieObject(id, value);
	}

	//** Set expiry of cookie */
	setExpiry(id: string, value: string, date: Date): void {
		this.expiry = date;
		this.setCookieObject(id, value);
	}

	//** Get cookie */
	getCookie(id: string): string {
		const cookiesObject: ICookiesStorageData = this.getCookiesObject(id);
		return cookiesObject.value;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private setCookieObject(id: string, value: string) {
		this.document.cookie = `${id}=${value};expires=${this.expiry.toUTCString()}`;
	}

	private getCookiesObject(id: string): ICookiesStorageData {

		//0 - get cookies string, if no cookie found with same id, return default data
		const cookiesString	: string[] = this.document.cookie.split('; ');
		const currentCookie	: string | undefined = cookiesString.find((cookieString: string) => cookieString.startsWith(id));
		if (!currentCookie) return this.DEFAULT_DATA;

		//1 - get cookie key and value
		const [cookieKey, cookieValue]: [string, string] = currentCookie.split('=') as [string, string];
		const cookieObject: ICookiesStorageData = {
			id		: cookieKey,
			value	: cookieValue
		};

		//2 - return cookie object
		return cookieObject as ICookiesStorageData;
	}
}


//** Interfaces --------------------------------- */
interface ICookiesStorageData extends Record<string, string> {
	id		: string;
	value	: string;
}
