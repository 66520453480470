import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';

import { AddonGoogleReCaptchaScript } from './google-re-captcha.script';
import { AddonGoogleReCaptchaService } from './google-re-captcha.service';
import { AddonPageAddonsRenderer } from '../../shared/page-addons-renderer.helper';
import { EnumAddonPageAddonInjection } from '../../shared/page-addons.interface';


@NgModule()
export class AddonGoogleRecaptchaModule {

	static register(config: IAddonGoogleReCaptchaConfig): ModuleWithProviders<AddonGoogleRecaptchaModule> {

		return {
			ngModule	: AddonGoogleRecaptchaModule,
			providers	: [
				{
					provide		: EnumAddonPageAddonInjection.GoogleRecaptchaKey,
					useValue	: config.RECAPTCHA_KEY as string
				},
				AddonPageAddonsRenderer,
				AddonGoogleReCaptchaScript,
				AddonGoogleReCaptchaService,
				{
					provide		: APP_INITIALIZER,
					deps		: [AddonGoogleReCaptchaScript],
					multi		: true,
					useFactory	: (googleRecaptchaScript: AddonGoogleReCaptchaScript) => {
						return () => googleRecaptchaScript.create();
					}
				}
			]
		};
	}
}


//** Interfaces --------------------------------- */
export interface IAddonGoogleReCaptchaConfig {
	RECAPTCHA_KEY: string;
}
