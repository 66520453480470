import { Inject, Injectable } from '@angular/core';

import { SecureTimeTokenService } from '../../services/time-token.service';
import { SecureEncryptionService } from '../../services/encryption.service';
import { SecureWebSocketService } from './connection/websocket.service';
import { EnumSecureSocketInjection, ISecureSocketConfig } from '../secure-socket.config';


@Injectable()
export class SecureSocket {

	constructor(
		@Inject(EnumSecureSocketInjection.Config)
		protected config			: ISecureSocketConfig,
		protected timeTokenService	: SecureTimeTokenService,
		protected encryptionService	: SecureEncryptionService
	) {}


	open(endpoint: string): SecureWebSocketService {
		return new SecureWebSocketService(this.config, endpoint, this.timeTokenService, this.encryptionService);
	}
}
