import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { FlyingDesignImageSchema, IFlyingDesignImage } from './design-image.interface';
import { FlyingDesignUploadSchema, IFlyingDesignUpload } from './design-upload.interface';
import { IFlyingMbaListing, FlyingMbaListingSchema } from './design-listing-mba.interface';
import { IFlyingGlobalListing, FlyingGlobalListingSchema } from './design-listing-global.interface';
import { IFlyingDisplateListing, FlyingDisplateListingSchema } from './design-listing-displate.interface';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_DESIGN_TAG_LIMITATIONS: IFlyingDesignTagConfig = {
	TAG_LENGTH	: 100,
	MAX_TAGS	: 3		// max 3 tags
};

interface IFlyingDesignTagConfig {
	TAG_LENGTH	: number;
	MAX_TAGS	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingDesignStatus {
	ImportPending   = 'import_pending',
	Active          = 'active'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingDesign {
	flyId		: string;
	image		: IFlyingDesignImage;
	listing		: IFlyingDesignListing;
	uploads		: IFlyingDesignUpload[];
}

export interface IFlyingDesignStatus {
	status		: EnumFlyingDesignStatus;
}

export interface IFlyingDesignListing {
	tags		: string[];
	global		: IFlyingGlobalListing   | null;
	mba			: IFlyingMbaListing	     | null;
	displate	: IFlyingDisplateListing | null;
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingDesignSchema implements ValidatorSchema<IFlyingDesign> {
	flyId 		= Validator.String().isNotEmpty();
	status  	= Validator.Enum(EnumFlyingDesignStatus).isNotEmpty();
	image 		= Validator.Object().inspect<IFlyingDesignImage>(FlyingDesignImageSchema);
	listing 	= Validator.Object().inspect<IFlyingDesignListing>(FlyingDesignListingSchema);
	uploads 	= Validator.Array().inspectEach<IFlyingDesignUpload>(FlyingDesignUploadSchema);
}

export class FlyingDesignListingSchema implements ValidatorSchema<IFlyingDesignListing> {
	tags 		= Validator.Array().each(Validator.String().isNotEmpty().isMaxLength(FLYING_DESIGN_TAG_LIMITATIONS.TAG_LENGTH)).isMaxSize(FLYING_DESIGN_TAG_LIMITATIONS.MAX_TAGS);
	global 		= Validator.Object().optional().inspect<IFlyingGlobalListing>(FlyingGlobalListingSchema);
	mba 		= Validator.Object().optional().inspect<IFlyingMbaListing>(FlyingMbaListingSchema);
	displate 	= Validator.Object().optional().inspect<IFlyingDisplateListing>(FlyingDisplateListingSchema);
}
