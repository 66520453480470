/**------------------------------------------------------
 * Ignored http routes from security
 */
export const SECURITY_EXCEPTION_ROUTES: ISecurityExceptionRouts = {
	IGNORE_FULL_SECURITY		: [
		'/payment/paddle',
		'/timestamp',
		'/auth/verify-mail',
		'/keyword/extract',
		'/keyword/tag-generator',
		'/keyword/normal',
		'/keyword/long-tail',
		'/keyword/synonyms',
		'/user/modules',
		'/trademark/listing-check',
		'/trademark/single-check',
		'/data-analysis/free-uploads',
		'/data-analysis/free-uploads/increment',
		'/design-management/pod-uploads',
		'/admin'
	],
	IGNORE_TIME_TOKEN			: [],
	IGNORE_REQUEST_DECRYPTION	: [
		'/trademark/complaint/send'
	],
	IGNORE_RESPONSE_ENCRYPTION	: [
		'/proxy/download-s3',
		'/proxy/download-files',
		'/listing-stream'
	]
};


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface ISecurityExceptionRouts {
	IGNORE_FULL_SECURITY		: string[];
	IGNORE_TIME_TOKEN			: string[];
	IGNORE_REQUEST_DECRYPTION	: string[];
	IGNORE_RESPONSE_ENCRYPTION	: string[];
}
