// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';

import { CryptoAsymmetricEncryption } from '../asymmetric-encryption/asymmetric-encryption';


/**------------------------------------------------------
 * Asymmetric Signatures
 */
export class CryptoAsymmetricSignature extends CryptoAsymmetricEncryption {


	/**------------------------------------------------------
	 * PKCS (Public-Key Cryptography Standards)
	 * --------------------------------------
	 * > Online RSA Signature: https://8gwifi.org/rsasignverifyfunctions.jsp
	 * --------------------------------------
	 * > PKCS is a set of standards that defines various cryptographic operations, including signature schemes.
	 * > PKCS #1 is the specific standard that outlines the RSA encryption and signature schemes. PKCS #1 v1.5 is
	 * > an older and widely supported signature scheme that has been used for many years. It involves a deterministic
	 * > process to generate the signature (same message and key will produce an identical signature value each time).
	 * > Sign data with a private key and output DigestInfo DER-encoded bytes
	 */
	signRsaPkcsSha256(data: string, privateKeyPem: string): string {

		//0 - prepare the algorithm configuration
		const privateKey: forge.pki.rsa.PrivateKey = this.privateKeyFromPem(privateKeyPem);
		const md		: forge.md.MessageDigest   = forge.md.sha256.create().update(data, 'utf8');		// using SHA256

		//1 - create the signature
		const signature 	 : string = privateKey.sign(md);			// defaults to RSASSA PKCS#1 v1.5
		const signatureBase64: string = forge.util.encode64(signature);
		return signatureBase64;
	}

	verifyRsaPkcsSha256(data: string, signatureBase64: string, publicKeyPem: string): boolean {

		//0 - prepare the signature, public key
		const publicKey: forge.pki.rsa.PublicKey = this.publicKeyFromPem(publicKeyPem);
		const signature: string 				 = forge.util.decode64(signatureBase64);

		//1 - prepare the algorithm configuration
		const md: forge.md.MessageDigest  = forge.md.sha256.create().update(data, 'utf8');

		//2 - verify RSASSA-PKCS signature
		try {
			const isSignatureValid: boolean = publicKey.verify(md.digest().bytes(), signature);
			return isSignatureValid;
		} catch (error: unknown) {
			return false;
		}
	}


	/**------------------------------------------------------
	 * PKCS (Public-Key Cryptography Standards)
	 * --------------------------------------
	 * > Online RSA Signature: https://8gwifi.org/rsasignverifyfunctions.jsp
	 */
	signRsaPkcsSha512(data: string, privateKeyPem: string): string {

		//0 - prepare the algorithm configuration
		const privateKey: forge.pki.rsa.PrivateKey = this.privateKeyFromPem(privateKeyPem);
		const md		: forge.md.MessageDigest   = forge.md.sha512.create().update(data, 'utf8');		// using SHA512

		//1 - create the signature
		const signature 	 : string = privateKey.sign(md);			// defaults to RSASSA PKCS#1 v1.5
		const signatureBase64: string = forge.util.encode64(signature);
		return signatureBase64;
	}

	verifyRsaPkcsSha512(data: string, signatureBase64: string, publicKeyPem: string): boolean {

		//0 - prepare the signature, public key
		const publicKey: forge.pki.rsa.PublicKey = this.publicKeyFromPem(publicKeyPem);
		const signature: string 				 = forge.util.decode64(signatureBase64);

		//1 - prepare the algorithm configuration
		const md: forge.md.MessageDigest  = forge.md.sha512.create().update(data, 'utf8');

		//2 - verify RSASSA-PKCS signature
		try {
			const isSignatureValid: boolean = publicKey.verify(md.digest().bytes(), signature);
			return isSignatureValid;
		} catch (error: unknown) {
			return false;
		}
	}


	/**------------------------------------------------------
	 * PSS (Probabilistic Signature Scheme)
	 * ------------------------------------
	 * > PASS is a newer and more secure signature scheme introduced in PKCS #1 v2.1. PSS is based on a
	 * > randomized process, which adds a level of probabilistic security to the signature generation.
	 * > It uses a hash function and a mask generation function to incorporate random data into the signature.
	 * > PSS is randomized and will produce a different signature value each time (unless you use a zero-length salt).
	 */
	signRsaPssSha512(data: string, privateKeyPem: string): string {

		//0 - prepare the private key &
		const privateKey: forge.pki.rsa.PrivateKey = this.privateKeyFromPem(privateKeyPem);

		//1 - prepare the algorithm configuration
		const md : forge.md.MessageDigest = forge.md.sha512.create().update(data, 'utf8');
		const pss: forge.pss.PSS 		  = forge.pss.create({
			md			: forge.md.sha512.create(),
			mgf			: forge.mgf.mgf1.create(forge.md.sha512.create()),				// RSASSA-PSS where PSS uses a SHA-1 hash, a SHA-1 based
			saltLength	: 20															// Masking function MGF1, and a 20 byte salt
		});

		//2 - create the signature
		const signature		 : string = privateKey.sign(md, pss);
		const signatureBase64: string = forge.util.encode64(signature);
		return signatureBase64;
	}

	verifyRsaPssSha512(data: string, signatureBase64: string, publicKeyPem: string): boolean {

		//0 - prepare the signature & public key
		const publicKey: forge.pki.rsa.PublicKey = this.publicKeyFromPem(publicKeyPem);
		const signature: string 				 = forge.util.decode64(signatureBase64);

		//1 - prepare the algorithm configurations
		const md : forge.md.MessageDigest = forge.md.sha512.create().update(data, 'utf8');
		const pss: forge.pss.PSS 		  = forge.pss.create({
			md			: forge.md.sha512.create(),
			mgf			: forge.mgf.mgf1.create(forge.md.sha512.create()),
			saltLength	: 20
		});

		//2 - verify RSASSA-PSS signature
		try {
			const isSignatureValid: boolean = publicKey.verify(md.digest().getBytes(), signature, pss);
			return isSignatureValid;
		} catch (error: unknown) {
			return false;
		}
	}
}
