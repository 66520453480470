import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';
import { EnumNgFaStyle } from '@libs/frameworks/angular';

import { EnumSidebarPage } from '../../sidebar-pages.interface';


/**------------------------------------------------------
 * Research
 */
export const RESEARCH_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.ResearchBestSeller,
		label		: 'Best Sellers',
		routePath	: '/research/research/best-sellers',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'ranking-star'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchSellerTrends,
		label		: 'Seller Trends',
		routePath	: '/research/research/seller-trends',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'coins'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchArchive,
		label		: 'Archive',
		routePath	: '/research/research/archive',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		},
		info 		: 'New'
	},
	/* {
		id 			: EnumSidebarPage.ResearchSellerHistory,
		label		: 'Seller History',
		routePath	: '/research/research/seller-history',
		disable		: true,
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'rectangle-history'
		}
	}, */
	{
		id 			: EnumSidebarPage.ResearchDeleted,
		label		: 'Deleted',
		routePath	: '/research/research/deleted-designs',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'trash'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchRtr,
		label		: 'Real Time Research',
		routePath	: '/research/research/real-time-research',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'clock'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchNicheAnalysis,
		label		: 'Niche Analysis',
		routePath	: '/research/research/niche-analysis',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'magnifying-glass-chart'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchGetInspiredPod,
		label		: 'Get Inspired POD v2',
		routePath	: '/research/research/get-inspired-pod',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'lightbulb-on'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchBrands,
		label		: 'Official Brands',
		routePath	: '/research/research/brands',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'bolt'
		}
	},
	/* {
		id 			: EnumSidebarPage.ResearchNicheList,
		label		: 'Niche Lists',
		routePath	: '/research/research/niche-lists',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'list'
		}
	}, */
	{
		id 			: EnumSidebarPage.ResearchHobbyList,
		label		: 'Hobby List',
		routePath	: '/research/research/hobby-list',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'dumbbell'
		}
	},
	{
		id 			: EnumSidebarPage.ResearchFavorites,
		label		: 'Design Favorites',
		routePath	: '/research/research/favorites',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'heart'
		}
	}
];


/**------------------------------------------------------
 * Event Pages (Research)
 */
export const RESEARCH_EVENT_PAGE_LIST: ISparkSidebarSubMenu[] = [
	/* {
		id 			: EnumSidebarPage.EventsEventSellers,
		label		: 'Event Sellers',
		routePath	: '/research/events/event-sellers',
		disable		: true,
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	}, */
	{
		id 			: EnumSidebarPage.EventsSocialMedia,
		label		: 'Social Media Holidays',
		routePath	: '/research/events/social-media-holidays',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'lights-holiday'
		}
	},
	{
		id 			: EnumSidebarPage.EventsCalendar,
		label		: 'Calendar',
		routePath	: '/research/events/event-calendar',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'calendar-star'
		}
	}
];


/**------------------------------------------------------
 * Trademark
 */
export const TRADEMARK_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.TrademarkSearch,
		label		: 'TM Search',
		routePath	: '/research/trademark/tm-search',
		icon 		: {
			style 	: EnumNgFaStyle.Brands,
			name 	: 'searchengin'
		}
	},
	{
		id 			: EnumSidebarPage.TrademarkRegistration,
		label		: 'TM Registrations',
		routePath	: '/research/trademark/tm-registrations',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'pen'
		}
	},
	{
		id 			: EnumSidebarPage.TrademarkWatchlist,
		label		: 'TM Watchlist',
		routePath	: '/research/trademark/tm-watchlist',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'grid-2'
		}
	}
];


/**------------------------------------------------------
 * Keyword
 */
export const KEYWORD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.KeywordSearch,
		label		: 'KW Search',
		routePath	: '/research/keyword/kw-search',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'magnifying-glass'
		}
	},
	{
		id 			: EnumSidebarPage.KeywordCompetition,
		label		: 'KW Competition',
		routePath	: '/research/keyword/kw-competition',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'chart-simple'
		}
	},
	{
		id 			: EnumSidebarPage.KeywordHunter,
		label		: 'KW Hunter',
		routePath	: '/research/keyword/kw-hunter',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'mountain-city'
		}
	},
	{
		id 			: EnumSidebarPage.ProductExplorer,
		label		: 'Product Explorer',
		routePath	: '/research/keyword/product-explorer',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'bomb'
		}
	},
	{
		id 			: EnumSidebarPage.KeywordSuggestions,
		label		: 'KW Suggestions',
		routePath	: '/research/keyword/kw-suggestions',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'timeline-arrow'
		}
	},
	{
		id 			: EnumSidebarPage.KeywordWorkbench,
		label		: 'KW Workbench',
		routePath	: '/research/keyword/kw-workbench',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'chart-area'
		}
	},
	{
		id 			: EnumSidebarPage.KeywordTagGenerator,
		label		: 'Tag Generator',
		routePath	: '/research/keyword/tag-generator',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'briefcase'
		}
	},
	{
		id 			: EnumSidebarPage.KeywordLists,
		label		: 'KW Lists',
		routePath	: '/research/keyword/kw-lists',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'clipboard-list-check'
		}
	}
];


/**------------------------------------------------------
 * Advertisement Keywords
 */
export const ADVERTISEMENT_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.RankPositioning,
		label		: 'Rank Positioning',
		routePath	: '/research/advertisement/positioning',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'bow-arrow'
		}
	},
	{
		id 			: EnumSidebarPage.AdvertisingSuggestions,
		label		: 'Ad Suggestions',
		routePath	: '/research/advertisement/ad-suggestions',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'rectangle-ad'
		}
	},
	{
		id 			: EnumSidebarPage.AdvertisingLists,
		label		: 'Ad Lists',
		routePath	: '/research/advertisement/ad-lists',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'list-ol'
		}
	}
];


/**------------------------------------------------------
 * AI Research
 */
export const AI_RESEARCH_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.NicheFinder,
		label		: 'Aurora',
		routePath	: '/research/ai/niche-finder',
		info		: 'AI Powered',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'waves-sine'
		}
	},
	{
		id 			: EnumSidebarPage.PromptSearch,
		label		: 'Reveal',
		routePath	: '/research/ai/prompt-search',
		info		: 'AI Powered',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-open-full'
		}
	},
	{
		id 			: EnumSidebarPage.QuotesAndPuns,
		label		: 'Mumble',
		routePath	: '/research/ai/quotes-and-puns',
		info		: 'AI Powered',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'quote-left'
		}
	}
];


/**------------------------------------------------------
 * Trending Tags
 */
/* const TRENDING_TAG_PAGE_LIST: ISidebarPage[] = [
	{
		id 			: EnumSidebarPage.TrendingTagRedbubble,
		label		: 'Redbubble Tags',
		routePath	: '/research/trending-tags/redbubble',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.TrendingTagEtsy,
		label		: 'Etsy Tags',
		routePath	: '/research/trending-tags/etsy',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
]; */


/**------------------------------------------------------
 * Designer / Team Management
 */
/* const MANAGEMENT_PAGE_LIST: ISidebarPage[] = [
	{
		id 			: EnumSidebarPage.ManagementTeamDesigner,
		label		: 'Team/Designer',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.ManagementTasks,
		label		: 'Tasks',
		routePath	: '' },,
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.ManagementInvoices,
		label		: 'Invoices',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.ManagementDesignRequests,
		label		: 'Design Requests',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.ManagementDesignWork,
		label		: 'Design Work',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.ManagementFindDesigners,
		label		: 'Find Designers',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
]; */


/**------------------------------------------------------
 * Sales & Statistics
 */
/* const STATISTICS_PAGE_LIST: ISidebarPage[] = [
	{
		id 			: EnumSidebarPage.StatisticsUploads,
		label		: 'Uploads',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	},
	{
		id 			: EnumSidebarPage.StatisticsEarnings,
		label		: 'Earnings',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'box-archive'
		}
	}
]; */
