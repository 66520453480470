import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_ES: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'es_ES'
		}
	},
	{
		// https://www.amazon.es/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&hidden-keywords=%22+Color+solido%3A+100%25+Algodon%3B+Gris%3A+90%25+Algodon%2C+10%25+Poliester%3B+otros+colores%3A+50%25+Algodon%2C+50%25+Poliester%22
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES',
			'hidden-keywords': '%22+Color+solido%3A+100%25+Algodon%3B+Gris%3A+90%25+Algodon%2C+10%25+Poliester%3B+otros+colores%3A+50%25+Algodon%2C+50%25+Poliester%22' // cspell:disable-line
		}
	},
	/* {
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameter 	: {
			language	: 'es_ES',
		},
	}, */
	{
		// https://www.amazon.es/s?k=dog&hidden-keywords=%22+Colores+solidos%3A+100%25+Algodon%3B+Gris+Jaspeado%3A+90%25+Algodon%2C+10%25+Poliester%3B+Otros+Jaspeados%3A+50%25+Algodon%2C+50%25+Poliester%22+-Raglan
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES',
			'hidden-keywords': '%22+Colores+solidos%3A+100%25+Algodon%3B+Gris+Jaspeado%3A+90%25+Algodon%2C+10%25+Poliester%3B+Otros+Jaspeados%3A+50%25+Algodon%2C+50%25+Poliester%22+-Raglan' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.es/s?k=dog&hidden-keywords=%22+Colores+solidos%3A+100%25+Algodon%3B+Gris+Jaspeado%3A+90%25+Algodon%2C+10%25+Poliester%3B+Otros+Jaspeados+y+neones%3A+50%25+Algodon%2C+50%25+Poliester%22
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES',
			'hidden-keywords': '%22+Colores+solidos%3A+100%25+Algodon%3B+Gris+Jaspeado%3A+90%25+Algodon%2C+10%25+Poliester%3B+Otros+Jaspeados+y+neones%3A+50%25+Algodon%2C+50%25+Poliester%22' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.es/s?k=dog&hidden-keywords=%22+Ligero%2C+Encaje+clasico%2C+Manga+de+doble+puntada+y+bastilla+baja+%22+%2B%22Manga+Larga+%22+-Raglan
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES',
			'hidden-keywords': '%22+Ligero%2C+Encaje+clasico%2C+Manga+de+doble+puntada+y+bastilla+baja+%22+%2B%22Manga+Larga+%22+-Raglan' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.es/s?k=dog+raglan
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES'
		},
		queryOptions: {
			postfix		: '+raglan',
			prefix		: ''
		}
	},
	{
		// https://www.amazon.es/s?k=dog&hidden-keywords=%22+Colores+solidos%3A+80%25+Algodon%2C+20%25+Poliester%3B+Gris+Jaspeado%3A+78%25+Algodon%2C+22%25+Poli%3B+Jaspeado+Oscuro%3A+50%25+Algodon%2C+50%25+Poliester%22+%2BSudadera+-Capucha
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES',
			'hidden-keywords': '%22+Colores+solidos%3A+80%25+Algodon%2C+20%25+Poliester%3B+Gris+Jaspeado%3A+78%25+Algodon%2C+22%25+Poli%3B+Jaspeado+Oscuro%3A+50%25+Algodon%2C+50%25+Poliester%22+%2BSudadera+-Capucha' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.es/s?k=dog&hidden-keywords=%22+Colores+solidos%3A+80%25+Algodon%2C+20%25+Poliester%3B+Gris+Jaspeado%3A+78%25+Algodon%2C+22%25+Poli%3B+Jaspeado+Oscuro%3A+50%25+Algodon%2C+50%25+Poliester%22+%2B%22Sudadera+con+Capucha%22
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			i			: 'fashion',
			language	: 'es_ES',
			'hidden-keywords': '%22+Colores+solidos%3A+80%25+Algodon%2C+20%25+Poliester%3B+Gris+Jaspeado%3A+78%25+Algodon%2C+22%25+Poli%3B+Jaspeado+Oscuro%3A+50%25+Algodon%2C+50%25+Poliester%22+%2B%22Sudadera+con+Capucha%22' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.es/s?k=dog+PopSockets+Agarre+y+Soporte+para+Tel%C3%A9fonos+y+Tabletas
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			language	: 'es_ES'
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+PopSockets+Agarre+y+Soporte+para+Tel%C3%A9fonos+y+Tabletas' // cspell:disable-line
		}
	}
	/* {
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameter 	: {
			language	: 'es_ES',
		},
	},
	{
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameter 	: {
			language	: 'es_ES',
		},
	},
	{
		code		: EnumAmazonProduct.ThrowPillows,
		name		: 'Throw Pillows',
		parameter 	: {
			language	: 'es_ES',
		},
	},
	{
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameter 	: {
			language	: 'es_ES',
		},
	}, */
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_ES: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.ES,
	websiteUrl		: {
		home		: 'https://www.amazon.es/',
		login		: 'https://www.amazon.es/ap/signin?openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=esflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',
		search		: 'https://www.amazon.es/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_ES,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
