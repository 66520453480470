import type { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

import { EnumNgFaStyle } from '../../../../styling-components';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgPopoverSize {
	Small 		= 'small',
	Medium 		= 'medium',
	Large 		= 'large'
}
export enum EnumNgPopoverBehavior {
	Default 	= 'default',
	Tooltip 	= 'tooltip'
}


/**------------------------------------------------------
 * Popover Interfaces
 */
export interface INgPopoverOptions {
	icon 				: {
		style 			: EnumNgFaStyle;
		name 			: string;
	};
	buttons 			: Array<(INgPopoverLink | INgPopoverButton)[]>;
	size 				: EnumNgPopoverSize;
	behavior 			: EnumNgPopoverBehavior;
	placement 			: PlacementArray;
	containerPosition 	: string;
}

export interface INgPopoverLink {
	label		 	 : string;
	actionCode 		?: string;
	link		 	 : string;
	targetBlank		 : boolean;
}

export interface INgPopoverButton {
	label 			: string;
	actionCode	 	: string;
}
