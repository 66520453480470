import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumTeepublicSorting {
	Popular			= 'popular',
	Newest			= 'newest'
}

export const TEEPUBLIC_SORTING_LIST: ISearchParameterConfig<EnumTeepublicSorting>[] = [
	{
		code        : EnumTeepublicSorting.Popular,
		name        : 'Popular',
		parameters  : { sort: 'popular' }
	},
	{
		code		: EnumTeepublicSorting.Newest,
		name		: 'Newest',
		parameters	: { sort: 'newest' }
	}
];


/**------------------------------------------------------
 * Product Configurations
 */
export enum EnumTeepublicProduct {
	All 			= 'all',

	// ADULT APPAREL
	Shirts 			= 't_shirts',
	TankTops 		= 'tank_tops',
	Hoodies 		= 'hoodies',
	Crewneck 		= 'crewneck_sweatshirts',
	LongSleeve 		= 'long_sleeve_shirts',
	BaseballShirts 	= 'baseball_shirts',

	// KIDS APPAREL
	KidsShirts 		= 'kids_shirts',
	KidsHoodie 		= 'kids_hoodie',
	KidsLongSleeve 	= 'kids_long_sleeve_shirt',
	Onesie 			= 'onesie',

	// MASKS
	Masks 			= 'masks',

	// HOME GOODS
	WallArt 		= 'wall_art',
	Notebooks 		= 'notebooks',
	Mugs 			= 'mugs',
	Pillows 		= 'pillows',
	Totes 			= 'totes',
	Tapestries 		= 'tapestries',
	Pins 			= 'pins',

	// CASES & STICKERS
	PhoneCases 		= 'phone cases',
	LaptopCases 	= 'laptop cases',
	Stickers 		= 'stickers',
	Magnets 		= 'magnets',
	Merchandise 	= 'merchandise'
}

export const TEEPUBLIC_PRODUCT_LIST: ISearchParameterConfig<EnumTeepublicProduct>[] = [
	{
		code				: EnumTeepublicProduct.All,
		name				: 'All Products',
		placeholders: {
			'[PRODUCT]' 	: 't-shirts'
		}
	},
	{
		code				: EnumTeepublicProduct.Shirts,
		name				: 'Shirts',
		parameters	: {
			canvas_subclass : 'classic-t-shirt',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 't-shirts'
		}
	},
	{
		code				: EnumTeepublicProduct.TankTops,
		name				: 'Tank Tops',
		parameters	: {
			canvas_subclass : 'classic-tank',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'tank-top'
		}
	},
	{
		code				: EnumTeepublicProduct.Hoodies,
		name				: 'Hoodies',
		parameters	: {
			canvas_subclass : 'classic-hoodie',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'hoodie'
		}
	},
	{
		code				: EnumTeepublicProduct.Crewneck,
		name				: 'Crewneck',
		parameters	: {
			canvas_subclass : 'crewneck-sweatshirt',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'crewneck-sweatshirt'
		}
	},
	{
		code				: EnumTeepublicProduct.LongSleeve,
		name				: 'Long Sleeve',
		parameters	: {
			canvas_subclass : 'long-sleeve-t-shirt',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'long-sleeve-t-shirt'
		}
	},
	{
		code				: EnumTeepublicProduct.BaseballShirts,
		name				: 'Baseball Tee',
		parameters	: {
			canvas_subclass : 'baseball-tee',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'baseball-t-shirt'
		}
	},
	{
		code				: EnumTeepublicProduct.KidsShirts,
		name				: 'Kids Shirts',
		parameters	: {
			canvas_subclass : 'toddler-ages-1-3',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'kids-t-shirt'
		}
	},
	{
		code				: EnumTeepublicProduct.KidsHoodie,
		name				: 'Kids Hoodie',
		parameters	: {
			canvas_subclass : 'youth-hoodie',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'kids-hoodies'
		}
	},
	{
		code				: EnumTeepublicProduct.KidsLongSleeve,
		name				: 'Kids Long Sleeve',
		parameters	: {
			canvas_subclass : 'youth-long-sleeve-t-shirt',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'kids-long-sleeve-t-shirts'
		}
	},
	{
		code				: EnumTeepublicProduct.Onesie,
		name				: 'Onesie',
		parameters	: {
			canvas_subclass : 'onesie',
			gender 			: 'unisex'
		},
		placeholders: {
			'[PRODUCT]' 	: 'onesies'
		}
	},
	{
		code				: EnumTeepublicProduct.Masks,
		name				: 'Masks',
		parameters	: {
			canvas_subclass : 'face-mask-basic'
		},
		placeholders: {
			'[PRODUCT]' 	: 'masks'
		}
	},
	{
		code				: EnumTeepublicProduct.WallArt,
		name				: 'Wall Art',
		parameters	: {
			canvas_subclass : 'art-print'
		},
		placeholders: {
			'[PRODUCT]' 	: 'posters-and-art'
		}
	},
	{
		code				: EnumTeepublicProduct.Notebooks,
		name				: 'Notebooks',
		parameters	: {
			canvas_subclass : 'hardcover-notebook'
		},
		placeholders: {
			'[PRODUCT]' 	: 'notebook'
		}
	},
	{
		code				: EnumTeepublicProduct.Mugs,
		name				: 'Mugs',
		parameters	: {
			canvas_subclass : 'coffee-mug'
		},
		placeholders: {
			'[PRODUCT]' 	: 'mug'
		}
	},
	{
		code				: EnumTeepublicProduct.Pillows,
		name				: 'Pillows',
		parameters	: {
			canvas_subclass : 'throw-pillow'
		},
		placeholders: {
			'[PRODUCT]' 	: 'throw-pillows'
		}
	},
	{
		code				: EnumTeepublicProduct.Totes,
		name				: 'Totes',
		parameters	: {
			canvas_subclass : 'tote-bag'
		},
		placeholders: {
			'[PRODUCT]' 	: 'totes'
		}
	},
	{
		code				: EnumTeepublicProduct.Tapestries,
		name				: 'Tapestry',
		parameters	: {
			canvas_subclass : 'tapestry'
		},
		placeholders: {
			'[PRODUCT]' 	: 'tapestries'
		}
	},
	{
		code				: EnumTeepublicProduct.Pins,
		name				: 'Pins',
		parameters	: {
			canvas_subclass : 'pin'
		},
		placeholders: {
			'[PRODUCT]' 	: 'pins'
		}
	},
	{
		code				: EnumTeepublicProduct.PhoneCases,
		name				: 'Phone Cases',
		parameters	: {
			canvas_subclass : 'iphone13'
		},
		placeholders: {
			'[PRODUCT]' 	: 'phone-case'
		}
	},
	{
		code				: EnumTeepublicProduct.LaptopCases,
		name				: 'Laptop Cases',
		parameters	: {
			canvas_subclass : 'laptop-case'
		},
		placeholders: {
			'[PRODUCT]' 	: 'laptop-case'
		}
	},
	{
		code				: EnumTeepublicProduct.Stickers,
		name				: 'Stickers',
		parameters	: {
			canvas_subclass : 'sticker'
		},
		placeholders: {
			'[PRODUCT]' 	: 'sticker'
		}
	},
	{
		code				: EnumTeepublicProduct.Magnets,
		name				: 'Magnets',
		parameters	: {
			canvas_subclass : 'magnet'
		},
		placeholders: {
			'[PRODUCT]' 	: 'magnets'
		}
	},
	{
		code				: EnumTeepublicProduct.Merchandise,
		name				: 'Merchandise',
		placeholders: {
			'[PRODUCT]' 	: 'merchandise'
		}
	}
];


/**------------------------------------------------------
 * Teepublic Configuration
 */
export const TEEPUBLIC_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.teepublic.com/',
		login		: 'https://www.teepublic.com/',
		search		: 'https://www.teepublic.com/[PRODUCT]?page=[PAGE]&query=[SEARCH]'
	},
	pageLimit		: 50,
	sortings		: TEEPUBLIC_SORTING_LIST,
	products		: TEEPUBLIC_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Teepublic Search Options
 */
export const TEEPUBLIC_DEFAULT_OPTIONS: ITeepublicSearchOptions = {
	page			: 1,
	sorting			: EnumTeepublicSorting.Popular,
	product			: EnumTeepublicProduct.All
};

export interface ITeepublicSearchOptions {
	page			: number;
	sorting			: EnumTeepublicSorting;
	product			: EnumTeepublicProduct;
}
