import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumPixabaySorting {
	Popular			= 'popular',		// default value
	EditorsChoice	= 'editors_choice',
	Latest			= 'latest',
	Upcoming		= 'upcoming'
}

export const PIXABAY_SORTING_LIST: ISearchParameterConfig<EnumPixabaySorting>[] = [
	{
		code 		: EnumPixabaySorting.Popular,
		name 		: 'Popular',
		parameters 	: {}
	},
	{
		code 		: EnumPixabaySorting.EditorsChoice,
		name 		: `Editor's Choice`,
		parameters 	: { order: 'ec' }
	},
	{
		code 		: EnumPixabaySorting.Upcoming,
		name        : 'Upcoming',
		parameters  : { order: 'trending' }
	},
	{
		code 		: EnumPixabaySorting.Latest,
		name 		: 'Latest',
		parameters 	: { order: 'latest' }
	}
];


/**------------------------------------------------------
 * Pixabay Configuration
 */
export const PIXABAY_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://pixabay.com',
		login		: 'https://pixabay.com/',
		search		: 'https://pixabay.com/images/search/[SEARCH]/?pagi=[PAGE]'
	},
	pageLimit		: 1000,
	sortings		: PIXABAY_SORTING_LIST,
	products		: [],
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Pixabay Search Options
 */
export const PIXABAY_DEFAULT_OPTIONS: IPixabaySearchOptions = {
	page			: 1,
	sorting			: EnumPixabaySorting.Popular
};

export interface IPixabaySearchOptions {
	page			: number;
	sorting			: EnumPixabaySorting;
}
