import { WorkerWrapper } from '../../../worker-assistant/helper/worker.wrapper';
import { WorkerAssistantService } from '../../../worker-assistant/worker-assistant.service';
import { ColorExtractionCore } from './core/color-extraction.core';
import { IImageColorInfo } from './core/color-extraction.interface';


/**------------------------------------------------------
 * Color Extraction Service
 */
export class ColorExtractionHelper {

	constructor(
		private workerAssistantService	: WorkerAssistantService,
		private colorExtractionCore		: ColorExtractionCore
	) {}


	/**------------------------------------------------------
	 * Extract Color Information as Worker
	 */
	async analyzeImageColorsWorker(imageData: ImageData, colorList: string[]): Promise<IImageColorInfo | null> {

		//0 - create a new worker instance & process the image colors
		const colorExtractionWorker : WorkerWrapper = this.createColorExtractionWorker();
		const imageColorResult	 	: IImageColorInfo | null = await colorExtractionWorker.process({
			imageData	: imageData,
			colorList	: colorList
		});

		//1 - terminate the worker & return the result
		colorExtractionWorker.terminate();
		return imageColorResult;
	}


	/**------------------------------------------------------
	 * Extract Color Information
	 */
	analyzeImageColors(imageData: ImageData, colorList: string[]): IImageColorInfo {
		return this.colorExtractionCore.analyzeImageColors(imageData, colorList);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	createColorExtractionWorker(): WorkerWrapper {

		// the worker has to be destroyed after usage
		return this.workerAssistantService.createWorker(new Worker(new URL('libs/libraries/frontend/src/content-handlers/img-handler/color-extraction/core/color-extraction.worker', import.meta.url), { type: 'module' }));
	}
}
