import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';

import { EnumNgToastPlacement } from '../message-toast.interface';
import { fadeInRightOnEnterAnimation, fadeOutRightOnLeaveAnimation } from '../../../../animations';


@Component({
	selector	: 'ng-message-container',
	templateUrl	: './message-container.component.html',
	styleUrls	: ['./message-container.component.scss'],
	animations	: [
		fadeInRightOnEnterAnimation(),
		fadeOutRightOnLeaveAnimation()
	]
})
export class NgMessageContainerComponent {

	@ViewChild('toastMessage', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;

	//** Decorators */
	@Input() placement: EnumNgToastPlacement = null!;
}
