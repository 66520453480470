import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumSociety6Product, EnumSociety6Sorting, ISociety6SearchOptions, SOCIETY6_CONFIG, SOCIETY6_DEFAULT_OPTIONS } from './society6-search.data';


/**------------------------------------------------------
 * Society6 Search Url
 */
export class Society6Search extends AbstractSearchLinks<ISociety6SearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumSociety6Sorting>[] {
		return SOCIETY6_CONFIG.sortings as ISearchParameterConfig<EnumSociety6Sorting>[];
	}

	get productList(): ISearchParameterConfig<EnumSociety6Product>[] {
		return SOCIETY6_CONFIG.products as ISearchParameterConfig<EnumSociety6Product>[];
	}

	constructor() {
		super(SOCIETY6_CONFIG, SOCIETY6_DEFAULT_OPTIONS);
	}


	//** Overwriting the Search */
	override search(searchQuery: string, options?: Partial<ISociety6SearchOptions>): string {
		return super.search(searchQuery, options).replace(/%20/g, '+');
	}
}
