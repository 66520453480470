import { IKeywordList } from '../../../data-structures';


/**------------------------------------------------------
 * Keyword List Request API-Interfaces
 */
export interface ICreateKeywordListRequest {
	keywordList		: IKeywordList[];
}

export interface IDeleteKeywordListRequest {
	keywordListIds	: string[];
}


/**------------------------------------------------------
 * Keyword List Response API-Interfaces
 */
export interface IGetKeywordListsResponse {
	response	: IKeywordList[];
}

export interface ICreateKeywordListResponse {
	updated		: number;
	created		: number;
}
