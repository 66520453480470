/**------------------------------------------------------
 * Digital
 * -------
 * > Digital storage units measure the amount of data that
 * > can be stored electronically. These units include
 * > gigabytes (GB), terabytes (TB), and beyond. Each unit
 * > represents a specific amount of data capacity.
 */
export * from './digital/digital.interface';
export * from './digital/digital.data';


/**------------------------------------------------------
 * Length
 * ------
 * > Length units quantify the extent of physical distance
 * > between two points. Common units include meters (m),
 * > centimeters (cm), and kilometers (km). These units help
 * > describe the size and dimensions of objects and distances.
 */
export * from './length/length.interface';
export * from './length/length.data';


/**------------------------------------------------------
 * Mass
 * ----
 * > Mass units quantify the amount of matter in an object.
 * > They are commonly used for measuring the weight of substances.
 * > Units like grams (g) and kilograms (kg) are used for smaller
 * > objects, while metric tons (tonnes) are used for larger quantities.
 */
export * from './mass/mass.interface';
export * from './mass/mass.data';


/**------------------------------------------------------
 * Speed
 * -----
 * > Speed units measure how fast an object moves over a given
 * > distance. Kilometers per hour (km/h), meters per second (m/s),
 * > and miles per hour (mph) are common speed units.
 * > These units help describe the rate of movement.
 */
export * from './speed/speed.interface';
export * from './speed/speed.data';


/**------------------------------------------------------
 * Temperature
 * -----------
 * > Temperature units measure the degree of hotness or coldness
 * > of an object. Celsius (°C) and Fahrenheit (°F) are widely
 * > used units. Kelvin (K) is an absolute temperature scale
 * > used in scientific contexts.
 */
export * from './temperature/temperature.interface';
export * from './temperature/temperature.data';


/**------------------------------------------------------
 * Time
 * ----
 * > Time units measure the duration between events or the passage
 * > of time. Common units include seconds (s), minutes (min),
 * > hours (h), and days (d). Larger units like weeks, months,
 * > and years are also used for longer time spans.
 */
export * from './time/time.interface';
export * from './time/time.data';
export * from './time/time-milliseconds.data';


/**------------------------------------------------------
 * Shared
 */
export * from './shared/unit-system.interface';
