/**------------------------------------------------------
 * JWT Interface
 */
export interface IJwtHeader {
	alg			: string;
	typ			: string;
}

export interface IJwtPayload {
	issuer		: string;			// issuer, example 'flying'
	issuedAt	: number;			// issued At (timestamp in seconds)
	expiresAt	: number;			// expiration Time (timestamp in seconds)
}


/**------------------------------------------------------
 * Configuration
 */
export enum EnumSignatureAlgorithm {
	HS256		= 'HS256',			// Note: keep the names uppercase, as they are used as string values in the token
	RS256		= 'RS256'
}

export interface IJwtSignatureOptions {
	algorithm	: EnumSignatureAlgorithm;
	secret		: string;
}
