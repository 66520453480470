import { EnumColorName, EnumCountry, EnumLanguage } from '@libs/constants';

import { EnumMbaMarketplace } from './mba-amazon.constants';


/**------------------------------------------------------
 * Marketplace Configurations
 */
export const MBA_MARKETPLACE_SPECIFICATION: IAmazonMbaMarket[] = [
	{
		code      	: EnumMbaMarketplace.COM,
		name		: 'Amazon.com',
		chartColor	: EnumColorName.Darkblue,
		productor	: 'US',
		rootUrl		: 'https://www.amazon.com',
		brandUrl    : 'https://www.amazon.com/s?rh=n%3A7141123011%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.com/dp/[ASIN]',
		zipCode		: '90001',
		keywords	: [
			'com', 'us', 'usa', 'united states', 'en-us', 'en_us', 'en us', 'usa',
			'amazon.com', 'amazon.us', 'amazon.usa', 'amazon us', 'amazon united states'
		],
		language	: EnumLanguage.EN,
		country		: EnumCountry.US,
		currency	: {
			code	: 'usd',
			symbol	: '$'
		}
	},
	{
		code      	: EnumMbaMarketplace.CO_UK,
		name		: 'Amazon.co.uk',
		chartColor	: EnumColorName.Gray,
		productor	: 'GB',
		rootUrl		: 'https://www.amazon.co.uk',
		brandUrl	: 'https://www.amazon.co.uk/s?rh=n%3A83450031%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.co.uk/dp/[ASIN]',
		zipCode		: 'SW1W 0NY',
		keywords	: [
			'co_uk', 'co_gb', 'gb', 'uk', 'england', 'en-uk', 'en_uk', 'co uk', 'co gb',
			'co-uk', 'co-gb', 'united kingdom', 'great britain', 'amazon.uk', 'amazon.co.uk',
			'amazon.gb', 'amazon uk', 'amazon gb', 'amazon great britten', 'amazon united kingdom'
		],
		language	: EnumLanguage.EN,
		country		: EnumCountry.UK,
		currency	: {
			code	: 'gbp',
			symbol	: '£'
		}
	},
	{
		code      	: EnumMbaMarketplace.DE,
		name		: 'Amazon.de',
		chartColor	: EnumColorName.Darkgray,
		productor	: 'DE',
		rootUrl		: 'https://www.amazon.de',
		brandUrl	: 'https://www.amazon.de/s?rh=n%3A77028031%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.de/dp/[ASIN]',
		zipCode		: '94447',
		keywords	: [
			'de', 'ger', 'german', 'deutsch', 'germany', 'ch', 'at', 'austria', 'de-de', 'de-at',
			'de-ch', 'de_de', 'de_at', 'de_ch', 'mba de', 'mba germany', 'de mba', 'germany mba',
			'amazon.de', 'amazon de', 'amazon germany'
		],
		language	: EnumLanguage.DE,
		country		: EnumCountry.DE,
		currency	: {
			code	: 'eur',
			symbol	: '€'
		}
	},
	{
		code      	: EnumMbaMarketplace.FR,
		name		: 'Amazon.fr',
		chartColor	: EnumColorName.Lightblue,
		productor	: 'FR',
		rootUrl		: 'https://www.amazon.fr',
		brandUrl	: 'https://www.amazon.fr/s?rh=n%3A340855031%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.fr/dp/[ASIN]',
		zipCode		: '75000',
		keywords	: [
			'fr', 'france', 'french', 'fr-fr', 'fr_fr', 'mba fr', 'mba france', 'fr mba',
			'france mba', 'amazon.fr', 'amazon fr', 'amazon france'
		],
		language	: EnumLanguage.FR,
		country		: EnumCountry.FR,
		currency	: {
			code	: 'eur',
			symbol	: '€'
		}
	},
	{
		code      	: EnumMbaMarketplace.IT,
		name		: 'Amazon.it',
		chartColor	: EnumColorName.Green,
		productor	: 'IT',
		rootUrl		: 'https://www.amazon.it',
		brandUrl	: 'https://www.amazon.it/s?rh=n%3A2844433031%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.it/dp/[ASIN]',
		zipCode		: '20089',
		keywords	: [
			'it', 'italy', 'italian', 'it_it', 'it-it', 'mba it', 'mba italy', 'it mba', 'italy mba',
			'amazon.it', 'amazon it', 'amazon italy'
		],
		language	: EnumLanguage.IT,
		country		: EnumCountry.IT,
		currency	: {
			code	: 'eur',
			symbol	: '€'
		}
	},
	{
		code      	: EnumMbaMarketplace.ES,
		name		: 'Amazon.es',
		chartColor	: EnumColorName.Yellow,
		productor	: 'ES',
		rootUrl		: 'https://www.amazon.es',
		brandUrl	: 'https://www.amazon.es/s?rh=n%3A2846220031%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.es/dp/[ASIN]',
		zipCode		: '50004',
		keywords	: [
			'es', 'spain', 'spanish', 'es_es', 'es-es', 'mba es', 'mba spain', 'es mba',
			'spain mba', 'amazon.es', 'amazon es', 'amazon spain'
		],
		language	: EnumLanguage.ES,
		country		: EnumCountry.ES,
		currency	: {
			code	: 'eur',
			symbol	: '€'
		}
	},
	{
		code      	: EnumMbaMarketplace.JP,
		name		: 'Amazon.co.jp',
		chartColor	: EnumColorName.Whitesmoke,
		productor	: 'JP',
		rootUrl		: 'https://www.amazon.co.jp',
		brandUrl	: 'https://www.amazon.co.jp/s?rh=n%3A352484011%2Cp_4%3A[BRAND]',
		asinUrl		: 'https://www.amazon.co.jp/dp/[ASIN]',
		zipCode		: '102-0084',
		keywords	: [
			'ja', 'jp', 'co_jp', 'co_ja', 'ja-jp', 'ja_jp', 'japan', 'japanese', 'co jp', 'co ja', 'mba ja',
			'mba jp', 'mba japan', 'mba japanese', 'ja mba', 'jp mba', 'japan mba', 'japanese mba',
			'amazon japan', 'amazon.co.jp', 'amazon.ja', 'amazon ja', 'amazon.jp', 'amazon jp'
		],
		language	: EnumLanguage.JA,
		country		: EnumCountry.JP,
		currency	: {
			code	: 'yen',
			symbol	: '¥'
		}
	}
];


//** Interfaces --------------------------------- */
export interface IAmazonMbaMarket {
	code      		: EnumMbaMarketplace;
	name      		: string;
	chartColor		: EnumColorName;
	productor		: string;
	rootUrl			: string;
	brandUrl		: string;
	asinUrl			: string;
	zipCode			: string;
	keywords		: string[];					// used for the excel import, to map the user input to the marketplace
	language		: EnumLanguage;
	country			: EnumCountry;
	currency		: {
		code		: string;
		symbol		: string;
	};
}
