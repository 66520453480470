import { EmbeddedViewRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

import { INgTemplateOptions } from './template-assistant.interface';
import { NgEmbeddedViewWrapper } from './template-assistant.wrapper';


@Injectable({
	providedIn: 'root'
})
export class NgTemplateAssistantService {

	//** Create Template Instance */
	create<T>(viewContainerRef: ViewContainerRef, templateRef: TemplateRef<T>, options?: Partial<INgTemplateOptions<T>>): NgEmbeddedViewWrapper<T> {
		const embeddedViewRef: EmbeddedViewRef<T> = viewContainerRef.createEmbeddedView(templateRef, options?.context, options?.index);
		return new NgEmbeddedViewWrapper<T>(embeddedViewRef);
	}
}
