/**------------------------------------------------------
 * Enums
 */
export enum EnumApplication {

	// created apps
	Admin			= 'admin',
	Flying			= 'flying',
	FlyingMerch		= 'flying_merch',

	// only landing pages exist for these
	StockUploader	= 'stock-uploader',
	SeoMagnet		= 'seo-magnet',
	EtsElite		= 'etselite',
	KeyBayX			= 'keybayx',
	AmazeIQ			= 'amaze-iq',
	BookPioneer		= 'book-pioneer',
	AuthenFlow		= 'authenflow',
	TMFinder		= 'tm-finder',
	GDPRProtect		= 'gdpr-protect',
	DSGVOProtect	= 'dsgvo-protect',
	GDPRConnect		= 'gdpr-connect',
	DSGVOConnect	= 'dsgvo-connect'
}
