import { Sheet2HTMLOpts, WorkBook, WorkSheet, utils as xlsxUtils, read } from 'xlsx';
import { Util } from '@libs/utilities/util';

import { IImportFileInfo, IImportSheetInfo } from '../excel-import.interface';
import { ExcelReaderHelper, IImportedSheetInfo } from '../excel-reader.helper';


export class ExcelHtmlReaderHelper {

	//** Configurations */
	private readonly DEFAULT_OPTIONS: IImportExcelHtmlOptions = {
		id		 : 'excel-html-content',
		header	 : '',
		footer	 : '',
		editable : false
	};

	constructor(
		private excelReaderHelper: ExcelReaderHelper
	) {}


	/**------------------------------------------------------
	 * Import Excel File as Html
	 */
	async importExcelFileAsHtml(file: File, options: Partial<IImportExcelHtmlOptions>): Promise<IImportExcelHtmlData | null> {

		//0 - check is valid excel file
		const htmlOptions: IImportExcelHtmlOptions = Util.Function.assignOptions(this.DEFAULT_OPTIONS, options);
		if (!this.excelReaderHelper.isAcceptedExcelType(file)) return null;

		//1 - try to read the sheet data
		const fileBuffer: ArrayBuffer | null = await this.excelReaderHelper.readFile(file);
		if (!fileBuffer) return null;

		//2 - read the spreadsheet data with library
		const importSheetHtmlData: IImportSheetHtmlData[] | null = this.bufferToExcelHtmlData(fileBuffer, htmlOptions);
		if (!importSheetHtmlData) return null;

		//3 - create the excel sheet data
		const excelHtmlData: IImportExcelHtmlData = {
			sheets: importSheetHtmlData,
			metaInfo: {
				file: this.excelReaderHelper.getFileInfo(file)
			}
		};

		//4 - return the excel formatted data
		return excelHtmlData;
	}


	/**------------------------------------------------------
	 * Import Excel Sheet (Get sheet info from buffer)
	 */
	private bufferToExcelHtmlData(fileBuffer: ArrayBuffer, options: IImportExcelHtmlOptions): IImportSheetHtmlData[] | null {

		try {
			//0 - initialize sheet and importSheetsData
			const importSheetsData: IImportSheetHtmlData[] 	= [];
			const workBook		  : WorkBook 				= read(fileBuffer);

			//1 - grab sheet
			const sheetNames: string[] = workBook.SheetNames;
			for (const sheetName of sheetNames) {

				//a. get worksheet and json object
				const workSheet: WorkSheet = workBook.Sheets[sheetName];

				//b. get sheet json info and get imported sheet as html
				const workSheetData		: object[] 			 = xlsxUtils.sheet_to_json(workSheet);
				const htmlData			: string 			 = xlsxUtils.sheet_to_html(workSheet, options);
				const importedSheetInfo	: IImportedSheetInfo = this.excelReaderHelper.importedSheetInfo(workSheetData);

				//c. get imported sheet data object
				const importSheetData: IImportSheetHtmlData = {
					htmlData	: htmlData,
					metaInfo	: {
						sheet	: {
							sheetName	 : sheetName,
							columnCount  : importedSheetInfo.columnCount,
							rowCount	 : importedSheetInfo.rows.length
						}
					}
				};

				//d. update sheets data
				importSheetsData.push(importSheetData);
			}

			//2 - return the result
			return importSheetsData;

		} catch (error: unknown) {
			console.error(`ExcelHtmlReaderHelper => readBuffer => ERROR: ${Util.Basic.stringifyObject(error)}`);
			return null;
		}
	}
}


//** Interfaces --------------------------------- */
export interface IImportExcelHtmlData {
	sheets		: IImportSheetHtmlData[];
	metaInfo	: {
		file	: IImportFileInfo;
	};
}

export interface IImportSheetHtmlData {
	htmlData	: string;
	metaInfo	: {
		sheet	: IImportSheetInfo;
	};
}

export interface IImportExcelHtmlOptions extends Sheet2HTMLOpts {
	id		 : string;
	header	 : string;
	footer	 : string;
	editable : boolean;
}
