import { EnumFileType } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { FlyingDesignSchema, EnumFlyingDesignImageColor, EnumFlyingDesignImageColorType, EnumFlyingDesignUploadPlatform, EnumFlyingDisplateCategory, EnumFlyingGlobalColorOption, EnumFlyingGlobalTypeOption, EnumFlyingMbaFitType, EnumFlyingMbaMarketplace, EnumFlyingMbaProduct, IFlyingDesign } from '../../../interfaces';


/**------------------------------------------------------
 * Constants
 */
export const FLYING_DESIGN_MANAGEMENT_ENDPOINTS_CONFIG: IFlyingDesignManagementEndpointsConfig = {
	DESIGN_SEARCH_LIMIT		: 500,
	MAX_DESIGN_SEARCH_TAGS	: 50
};

interface IFlyingDesignManagementEndpointsConfig {
	DESIGN_SEARCH_LIMIT		: number;
	MAX_DESIGN_SEARCH_TAGS	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingDesignSearchSorting {
	Newest		= 'newest',
	Oldest		= 'oldest'
}

export enum EnumFlyingListingTypes {
	Global		= 'global',
	Mba			= 'mba',
	Displate	= 'displate'
}


/**------------------------------------------------------
 * Request
 */
export interface IFlyingDesignSearchRequest {
	searchTerm		?: string;
	tags			?: string[];
	sortBy			?: EnumFlyingDesignSearchSorting;
	page			?: number;
	limit			?: number;
	generalSpecific ?: IFlyingDesignSearchGeneral;
	platformSpecific?: IFlyingDesignSearchPlatform;
	colorSpecific	?: IFlyingDesignSearchColor;
	imageSpecific	?: IFlyingDesignSearchImage;
}

interface IFlyingDesignSearchGeneral {
	listingsExist	?: IFlyingGeneralListingExist;		// which listing exist, global, mba, displate
	uploadedOn		?: IFlyingGeneralUploadedOn;			// platforms in which design got uploaded
	excludeTags		?: string[];
	fromImportedDate?: Date;
	toImportedDate	?: Date;
}

interface IFlyingGeneralListingExist {
	isExist			: boolean;
	listings		: EnumFlyingListingTypes[];
}

interface IFlyingGeneralUploadedOn {
	isUploaded		: boolean;
	platforms		: EnumFlyingDesignUploadPlatform[];
}

interface IFlyingDesignSearchPlatform {
	globalFitTypes	?: EnumFlyingGlobalTypeOption[];
	globalColors	?: EnumFlyingGlobalColorOption[];
	mbaMarkets		?: EnumFlyingMbaMarketplace[];
	mbaProduct		?: EnumFlyingMbaProduct;
	mbaFitTypes		?: EnumFlyingMbaFitType[];
	displateCategories ?: EnumFlyingDisplateCategory[];
	displateCollections?: string[];
}

interface IFlyingDesignSearchColor {
	types			?: EnumFlyingDesignImageColorType[];
	dominant		?: EnumFlyingDesignImageColor;
	contains		?: EnumFlyingDesignImageColor[];
}

interface IFlyingDesignSearchImage {
	mimeTypes		?: EnumFileType[];
	minHeight		?: number;
	maxHeight		?: number;
	minWidth		?: number;
	maxWidth		?: number;
	minFileSize		?: number;
	maxFileSize		?: number;
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingDesignSearchResponse {
	results			: IFlyingDesign[];
	totalCount		: number;
}


/**------------------------------------------------------
 * Fetch by FlyIDs Request
 */
export interface IFlyingDesignListRequest {
	flyIds			: string[];
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingDesignSearchRequestSchema implements ValidatorSchema<IFlyingDesignSearchRequest> {
	searchTerm 	= Validator.String().isNotEmpty().trim().toLowerCase().optional();
	sortBy 		= Validator.Enum(EnumFlyingDesignSearchSorting).isNotEmpty().default(EnumFlyingDesignSearchSorting.Newest).optional();
	page 		= Validator.Number().isGreaterThan(0).default(1).optional();
	limit 		= Validator.Number().isGreaterThan(0).default(FLYING_DESIGN_MANAGEMENT_ENDPOINTS_CONFIG.DESIGN_SEARCH_LIMIT).optional();
	tags 		= Validator.Array().each(Validator.String().isNotEmpty()).isMaxSize(FLYING_DESIGN_MANAGEMENT_ENDPOINTS_CONFIG.MAX_DESIGN_SEARCH_TAGS).optional();
	generalSpecific = Validator.Object().optional().inspect<IFlyingDesignSearchGeneral>({
		listingsExist	: Validator.Object().inspect<IFlyingGeneralListingExist>({
			isExist		: Validator.Boolean(),
			listings	: Validator.Array().each(Validator.Enum(EnumFlyingListingTypes).isNotEmpty())
		}),
		uploadedOn		: Validator.Object().optional().inspect<IFlyingGeneralUploadedOn>({
			isUploaded	: Validator.Boolean(),
			platforms	: Validator.Array().each(Validator.Enum(EnumFlyingDesignUploadPlatform).isNotEmpty())
		}),
		excludeTags		: Validator.Array().each(Validator.String().isNotEmpty()).optional(),
		fromImportedDate: Validator.Date().optional(),
		toImportedDate	: Validator.Date().optional()
	});
	platformSpecific = Validator.Object().optional().inspect<IFlyingDesignSearchPlatform>({
		mbaProduct		: Validator.Enum(EnumFlyingMbaProduct).isNotEmpty().optional(),
		mbaMarkets		: Validator.Array().each(Validator.Enum(EnumFlyingMbaMarketplace).isNotEmpty()).optional(),
		mbaFitTypes		: Validator.Array().each(Validator.Enum(EnumFlyingMbaFitType).isNotEmpty()).optional(),
		globalFitTypes	: Validator.Array().each(Validator.Enum(EnumFlyingGlobalTypeOption).isNotEmpty()).optional(),
		globalColors	: Validator.Array().each(Validator.Enum(EnumFlyingGlobalColorOption).isNotEmpty()).optional(),
		displateCategories : Validator.Array().each(Validator.Enum(EnumFlyingDisplateCategory).isNotEmpty()).optional(),
		displateCollections: Validator.Array().each(Validator.String().isNotEmpty()).optional()
	});
	colorSpecific = Validator.Object().optional().inspect<IFlyingDesignSearchColor>({
		dominant	: Validator.Enum(EnumFlyingDesignImageColor).isNotEmpty().optional(),
		types		: Validator.Array().each(Validator.Enum(EnumFlyingDesignImageColorType).isNotEmpty()).optional(),
		contains	: Validator.Array().each(Validator.Enum(EnumFlyingDesignImageColor).isNotEmpty()).optional()
	});
	imageSpecific = Validator.Object().optional().inspect<IFlyingDesignSearchImage>({
		mimeTypes	: Validator.Array().each(Validator.Enum(EnumFileType).isNotEmpty()).optional(),
		minHeight	: Validator.Number().isGreaterThan(0).optional(),
		maxHeight	: Validator.Number().isGreaterThan(0).optional(),
		minWidth	: Validator.Number().isGreaterThan(0).optional(),
		maxWidth	: Validator.Number().isGreaterThan(0).optional(),
		minFileSize	: Validator.Number().isGreaterThan(0).optional(),
		maxFileSize	: Validator.Number().isGreaterThan(0).optional()
	});
}

export class FlyingDesignListRequestSchema implements ValidatorSchema<IFlyingDesignListRequest> {
	flyIds = Validator.Array().each(new FlyingDesignSchema().flyId)
		.isMinSize(1).isMaxSize(FLYING_DESIGN_MANAGEMENT_ENDPOINTS_CONFIG.DESIGN_SEARCH_LIMIT);
}
