import { ComponentRef, Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';

import { NgTemplateAssistantService } from '../template-assistant/template-assistant.service';
import { NgEmbeddedViewWrapper } from '../template-assistant/template-assistant.wrapper';
import { INgComponentOptions } from './component-assistant.interface';
import { NgComponentWrapper } from './component-assistant.wrapper';


@Injectable({
	providedIn: 'root'
})
export class NgComponentAssistantService {

	constructor(
		private templateAssistantService: NgTemplateAssistantService
	) {}

	//** Create Component Instance */
	create<T extends object>(viewContainerRef: ViewContainerRef, component: Type<T>, options?: Partial<INgComponentOptions>): NgComponentWrapper<T> {
		const componentRef: ComponentRef<T> = viewContainerRef.createComponent(component, options);
		return new NgComponentWrapper<T>(componentRef);
	}

	//** Open component as content of main component */
	createContentComponent<TComponent extends object, TContentComponent extends object>(viewContainerRef: ViewContainerRef, component: Type<TComponent>, contentComponent: Type<TContentComponent>): NgComponentWrapper<TComponent> {

		//0 - create main component and content component as its content
		const contentComponentWrapper: NgComponentWrapper<TContentComponent> = this.create(viewContainerRef, contentComponent);
		const componentWrapper		 : NgComponentWrapper<TComponent> 	   = this.create(viewContainerRef, component, { projectableNodes: [[contentComponentWrapper.getComponentRef().location.nativeElement]] });

		//1 - return component wrapper
		return componentWrapper;
	}

	//** Open template as content of main component */
	createContentTemplate<TComponent extends object, TContentTemplate extends object>(viewContainerRef: ViewContainerRef, component: Type<TComponent>, contentTemplate: TemplateRef<TContentTemplate>): NgComponentWrapper<TComponent> {

		//0 - create main component and content template as its content
		const contentTemplateWrapper: NgEmbeddedViewWrapper<TContentTemplate> = this.templateAssistantService.create(viewContainerRef, contentTemplate);
		const componentWrapper		: NgComponentWrapper<TComponent> 			= this.create(viewContainerRef, component, { projectableNodes: [contentTemplateWrapper.getEmbeddedViewRef().rootNodes] });

		//1 - return component wrapper
		return componentWrapper;
	}
}
