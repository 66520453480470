import { EnumConsoleSetting, EnumConsoleTextColor } from '@libs/constants';

import { UtilNumber } from './util-number';


/**------------------------------------------------------
 * Logging Utilities
 * -----------------
 * > Info: Containing all functionalities for improving
 * > and making good logging simpler. Most of the methods
 * > support method chaining
 */
export class UtilLog {

	constructor(
		private utilNumber	: UtilNumber
	) {}


	/**------------------------------------------------------
	 * Runtime Logging
	 */
	debugger(): UtilLog {
		// eslint-disable-next-line no-debugger
		debugger;
		return this;
	}
	setBreakpoint(): UtilLog {
		return this.debugger();
	}
	printStackTrace(): UtilLog {
		// eslint-disable-next-line no-console
		console.trace();
		return this;
	}
	printErrorTrace(): UtilLog {
		const error: any = new Error();
		// eslint-disable-next-line no-console
		console.log(error.stack);
		return this;
	}
	debug(message: string): UtilLog {
		// eslint-disable-next-line no-console
		console.debug(message);
		return this;
	}


	/**------------------------------------------------------
	 * Advanced Logging
	 */
	logObject<T>(object: T): UtilLog {
		// eslint-disable-next-line no-console
		console.dir(object, { depth: null });
		return this;
	}
	logAsJson<T>(object: T): UtilLog {
		// eslint-disable-next-line no-console
		console.log(JSON.stringify(object));
		return this;
	}
	logAsFormattedJson<T>(object: T): UtilLog {
		// eslint-disable-next-line no-console
		console.log(JSON.stringify(object, null, 2));
		return this;
	}
	logAsTable<T>(array: T[]): UtilLog {
		// eslint-disable-next-line no-console
		console.table(array);
		return this;
	}


	/**------------------------------------------------------
	 * Time Logging
	 */
	startTimer(): UtilLog {
		// eslint-disable-next-line no-console
		console.time();
		return this;
	}
	logTimer(): UtilLog {
		// eslint-disable-next-line no-console
		console.timeLog();
		return this;
	}


	/**------------------------------------------------------
	 * Clear Logs
	 */
	clear(): UtilLog {
		// eslint-disable-next-line no-console
		console.clear();
		return this;
	}


	/**------------------------------------------------------
	 * Colorized Logging
	 * -----------------
	 * > Emoji List: https://www.w3schools.com/charsets/ref_emoji.asp
	 */
	logInBlack(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Black, text));
		return this;
	}

	logInRed(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Red, text));
		return this;
	}

	logInGreen(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Green, text));
		return this;
	}

	logInYellow(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Yellow, text));
		return this;
	}

	logInBlue(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Blue, text));
		return this;
	}

	logInMagenta(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Magenta, text));
		return this;
	}

	logInCyan(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.Cyan, text));
		return this;
	}

	logInWhite(text: string, logger: IUtilLogger = console): UtilLog {
		logger.log(this.colorize(EnumConsoleTextColor.White, text));
		return this;
	}


	/**------------------------------------------------------
	 * Logging Formatters
	 */
	spacer(x: number): string {

		//0 - check if number is negative
		if (this.utilNumber.isNegative(x)) return '';

		//1 - check if number is positive then return empty string
		return [...new Array(x)].map(() => ' ').join('');
	}

	colorize(color: EnumConsoleTextColor, text: string): string {
		const coloredText: string = `${color}${text}${EnumConsoleSetting.Reset}`;
		return coloredText;
	}
}


//** Interfaces --------------------------------- */
interface IUtilLogger {
	log  (...messages: unknown[]): void;
	warn (...messages: unknown[]): void;
	error(...messages: unknown[]): void;
}
