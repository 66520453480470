import { EnumLength } from './length.interface';
import { EnumSystem, EnumType, IUnitRecord } from '../shared/unit-system.interface';


/**------------------------------------------------------
 * Length Infos
 */
export const LENGTH_INFOS: Record<EnumLength, IUnitRecord> = {

	// -------------------------------- Length (Metric)
	[EnumLength.Nanometer]: {
		code		: EnumLength.Nanometer,
		type		: EnumType.Length,
		system		: EnumSystem.Metric,
		name		: 'Nanometer',
		namePlural	: 'Nanometers',
		symbol		: 'nm',
		toBase		: 1e-9
	},
	[EnumLength.Micrometer]: {
		code		: EnumLength.Micrometer,
		type		: EnumType.Length,
		system		: EnumSystem.Metric,
		name		: 'Micrometer',
		namePlural	: 'Micrometers',
		symbol		: 'μm',
		toBase		: 1e-6
	},
	[EnumLength.Millimeter]: {
		code		: EnumLength.Millimeter,
		type		: EnumType.Length,
		system		: EnumSystem.Metric,
		name		: 'Millimeter',
		namePlural	: 'Millimeters',
		symbol		: 'mm',
		toBase		: 1e-3
	},
	[EnumLength.Centimeter]: {
		code		: EnumLength.Centimeter,
		type		: EnumType.Length,
		system		: EnumSystem.Metric,
		name		: 'Centimeter',
		namePlural	: 'Centimeters',
		symbol		: 'cm',
		toBase		: 1e-2
	},
	[EnumLength.Meter]: {
		code		: EnumLength.Meter,
		type		: EnumType.Length,
		system		: EnumSystem.Metric,
		name		: 'Meter',
		namePlural	: 'Meters',
		symbol		: 'm',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumLength.Foot,
			toType		: EnumType.Length,
			toSystem	: EnumSystem.Imperial,
			convert		: (value: number) => 3.28084 * value
		}
	},
	[EnumLength.Kilometer]: {
		code		: EnumLength.Kilometer,
		type		: EnumType.Length,
		system		: EnumSystem.Metric,
		name		: 'Kilometer',
		namePlural	: 'Kilometers',
		symbol		: 'km',
		toBase		: 1e3
	},

	// -------------------------------- Length (Imperial)
	[EnumLength.Inch]: {
		code		: EnumLength.Inch,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'Inch',
		namePlural	: 'Inches',
		symbol		: 'in',
		toBase		: 1 / 12
	},
	[EnumLength.Yard]: {
		code		: EnumLength.Yard,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'Yard',
		namePlural	: 'Yards',
		symbol		: 'yd',
		toBase		: 3
	},
	[EnumLength.SurveyFootUS]: {
		code		: EnumLength.SurveyFootUS,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'US Survey Foot',
		namePlural	: 'US Survey Feet',
		symbol		: 'ft-us',
		toBase		: 1.000002
	},
	[EnumLength.Foot]: {
		code		: EnumLength.Foot,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'Foot',
		namePlural	: 'Feet',
		symbol		: 'ft',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumLength.Meter,
			toType		: EnumType.Length,
			toSystem	: EnumSystem.Metric,
			convert		: (value: number) => 1 / 3.28084 * value
		}
	},
	[EnumLength.Fathom]: {
		code		: EnumLength.Fathom,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'Fathom',
		namePlural	: 'Fathoms',
		symbol		: 'fathom',
		toBase		: 6
	},
	[EnumLength.Mile]: {
		code		: EnumLength.Mile,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'Mile',
		namePlural	: 'Miles',
		symbol		: 'mi',
		toBase		: 5280
	},
	[EnumLength.NauticalMile]: {
		code		: EnumLength.NauticalMile,
		type		: EnumType.Length,
		system		: EnumSystem.Imperial,
		name		: 'Nautical Mile',
		namePlural	: 'Nautical Miles',
		symbol		: 'nMi',
		toBase		: 6076.12
	}
};
