import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthSecurityService } from '../../../../services/shared/auth/auth-security/auth-security.service';


@Injectable()
export class AuthGuardService  {

	constructor(
		private router				: Router,
		private authSecurityService	: AuthSecurityService
	) {}


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (!this.authSecurityService.isAuthenticated()) {
			this.authSecurityService.setRedirect(state.url);
			try {
				return this.authSecurityService.reAuthenticate();
			} catch (error: unknown) {
				const message: string = this.authSecurityService.isPrivateState() ? 'Your session is expired, Please login again.' : null;
				this.router.navigate(['user', 'login'], { state: { message } });
				return false;
			}
		} else {
			return true;
		}
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.canActivate(childRoute, state);
	}
}
