/**------------------------------------------------------
 * Logging Level Definitions
 */
export enum EnumLogLevel {
	Info		= 'info',
	Warning		= 'warning',
	Error		= 'error'
}


/**------------------------------------------------------
 * Constants
 */
export const ALL_LOG_LEVELS: EnumLogLevel[] = [
	EnumLogLevel.Info,
	EnumLogLevel.Warning,
	EnumLogLevel.Error
];


/**------------------------------------------------------
 * Logging History
 */
export interface ILogHistory {
	[EnumLogLevel.Info]		: ILogHistoryEntry[];
	[EnumLogLevel.Warning]	: ILogHistoryEntry[];
	[EnumLogLevel.Error]	: ILogHistoryEntry[];
}

export interface ILogHistoryEntry {
	time	: Date;
	log		: string;
}


/**------------------------------------------------------
 * Logger
 */
export abstract class AbstractLogger {
	abstract log  (...messages: unknown[]): void;
	abstract warn (...messages: unknown[]): void;
	abstract error(...messages: unknown[]): void;
}


/**------------------------------------------------------
 * Logging Statistics
 */
export interface ILogReport {
	totalLogs				: number;
	[EnumLogLevel.Info]		: ILogReportEvaluation;
	[EnumLogLevel.Warning]	: ILogReportEvaluation;
	[EnumLogLevel.Error]	: ILogReportEvaluation;
}

export interface ILogReportEvaluation {
	count		: number;
	percent		: number;
	history 	: ILogHistoryEntry[];
}
