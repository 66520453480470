import { EnumDigital } from './digital.interface';
import { EnumSystem, EnumType, IUnitRecord } from '../shared/unit-system.interface';


/**------------------------------------------------------
 * Digital Infos
 */
export const DIGITAL_INFOS: Record<EnumDigital, IUnitRecord> = {

	// -------------------------------- Digital (Bits)
	[EnumDigital.Bit]: {
		code		: EnumDigital.Bit,
		type		: EnumType.Digital,
		system		: EnumSystem.Bits,
		name		: 'Bit',
		namePlural	: 'Bits',
		symbol		: 'b',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumDigital.Byte,
			toType		: EnumType.Digital,
			toSystem	: EnumSystem.Bytes,
			convert		: (value: number) => 1 / 8 * value
		}
	},
	[EnumDigital.Kilobit]: {
		code		: EnumDigital.Kilobit,
		type		: EnumType.Digital,
		system		: EnumSystem.Bits,
		name		: 'Kilobit',
		namePlural	: 'Kilobits',
		symbol		: 'Kb',
		toBase		: 1024
	},
	[EnumDigital.Megabit]: {
		code		: EnumDigital.Megabit,
		type		: EnumType.Digital,
		system		: EnumSystem.Bits,
		name		: 'Megabit',
		namePlural	: 'Megabits',
		symbol		: 'Mb',
		toBase		: 1048576
	},
	[EnumDigital.Gigabit]: {
		code		: EnumDigital.Gigabit,
		type		: EnumType.Digital,
		system		: EnumSystem.Bits,
		name		: 'Gigabit',
		namePlural	: 'Gigabits',
		symbol		: 'Gb',
		toBase		: 1073741824
	},
	[EnumDigital.Terabit]: {
		code		: EnumDigital.Terabit,
		type		: EnumType.Digital,
		system		: EnumSystem.Bits,
		name		: 'Terabit',
		namePlural	: 'Terabits',
		symbol		: 'Tb',
		toBase		: 1099511627776
	},

	// -------------------------------- Digital (Bytes)
	[EnumDigital.Byte]: {
		code		: EnumDigital.Byte,
		type		: EnumType.Digital,
		system		: EnumSystem.Bytes,
		name		: 'Byte',
		namePlural	: 'Bytes',
		symbol		: 'B',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumDigital.Bit,
			toType		: EnumType.Digital,
			toSystem	: EnumSystem.Bits,
			convert		: (value: number) => 8 * value
		}
	},
	[EnumDigital.Kilobyte]: {
		code		: EnumDigital.Kilobyte,
		type		: EnumType.Digital,
		system		: EnumSystem.Bytes,
		name		: 'Kilobyte',
		namePlural	: 'Kilobytes',
		symbol		: 'KB',
		toBase		: 1024
	},
	[EnumDigital.Megabyte]: {
		code		: EnumDigital.Megabyte,
		type		: EnumType.Digital,
		system		: EnumSystem.Bytes,
		name		: 'Megabyte',
		namePlural	: 'Megabytes',
		symbol		: 'MB',
		toBase		: 1048576
	},
	[EnumDigital.Gigabyte]: {
		code		: EnumDigital.Gigabyte,
		type		: EnumType.Digital,
		system		: EnumSystem.Bytes,
		name		: 'Gigabyte',
		namePlural	: 'Gigabytes',
		symbol		: 'GB',
		toBase		: 1073741824
	},
	[EnumDigital.Terabyte]: {
		code		: EnumDigital.Terabyte,
		type		: EnumType.Digital,
		system		: EnumSystem.Bytes,
		name		: 'Terabyte',
		namePlural	: 'Terabytes',
		symbol		: 'TB',
		toBase		: 1099511627776
	}
};
