import { EnumSecureHeaders, ISecureHttpSecurityOptions, ISecureSocketSecurityOptions } from '@domains/security/shared';
import { Util } from '@libs/utilities/util';
import { Validator, ValidatorSchemaRef } from '@libs/utilities/validator';

import { SecureTimeTokenService } from '../services/time-token.service';
import { SecureEncryptionService } from '../services/encryption.service';


/**------------------------------------------------------
 * Request Helper
 * --------------
 * > This class is used as a base class for the secure
 * > socket and http builder patterns. It provides the
 * > functionality of setting the header, params, and
 * > query params to a request.
 */
export abstract class AbstractSecureRequest<RequestClass> {

	//** Helper Variables */
	protected headers		 : Record<string, string | number | boolean> = {};
	protected params		 : Record<string, string | number | boolean> = {};
	protected queryParams	 : Record<string, string | number | boolean> = {};
	protected securityOptions: ISecureHttpSecurityOptions | ISecureSocketSecurityOptions = null!;
	protected captchaToken	 : string = null!;
	protected endpointVersion: string = '';

	constructor(
		protected serverUrl			: string,
		protected endpoint			: string,
		protected timeTokenService	: SecureTimeTokenService,
		protected encryptionService	: SecureEncryptionService
	) {
		if (Util.String.isEmpty(endpoint)) throw new Error(`RequestAbstract => constructor => FATAL ERROR: endpoint is required (endpoint: ${endpoint})`);
	}


	//** Methods to Overwrite */
	abstract setSecurity(options: Partial<ISecureHttpSecurityOptions | ISecureSocketSecurityOptions>): RequestClass;
	protected abstract getSecureDefault(): ISecureHttpSecurityOptions | ISecureSocketSecurityOptions;


	/**------------------------------------------------------
	 * Setters
	 */
	setServerUrl(url: string): RequestClass {

		//0 - check if the url is valid
		if (Util.String.isEmpty(url)) throw new Error(`RequestAbstract => setServerUrl => FATAL ERROR: url is empty (url: ${url})`);

		//1 - set the url
		this.serverUrl = url;
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}

	version(version: string): RequestClass {

		//0 - check if the version is not empty
		if (Util.String.isEmpty(version)) throw new Error(`RequestAbstract => version => FATAL ERROR: version is empty (version: ${version})`);

		//1 - set the endpoint version
		this.endpointVersion = version;
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}

	setHeaders(headers: Record<string, string | number | boolean>): RequestClass {

		//0 - check if headers are empty
		if (Util.Object.isEmpty(headers)) throw new Error(`RequestAbstract => setHeaders => FATAL ERROR: headers are empty (headers: ${headers})`);

		//1 - set headers
		Util.Function.assignOptions(this.headers, headers);
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}

	setParams<T extends Record<string, string | number | boolean>>(params: T, paramsSchema: ValidatorSchemaRef<T>): RequestClass {

		//0 - validate the parameters
		this.validateBySchemaAndThrow('params', params, paramsSchema);

		//1 - set params
		Util.Function.assignOptions(this.params, params);
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}

	setQueryParams<T extends Record<string, string | number | boolean>>(queryParams: T, querySchema: ValidatorSchemaRef<T>): RequestClass {

		//0 - validate the query params
		this.validateBySchemaAndThrow('query', queryParams, querySchema);

		//1 - set queryParams
		Util.Function.assignOptions(this.queryParams, queryParams);
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}


	/**------------------------------------------------------
	 * Security Features
	 */
	setAuthToken(token: string): RequestClass {
		// TODO (Chandra and Martin) => we need to support the token here
		this.headers[EnumSecureHeaders.Authorization] = token;
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}

	setReCaptchaToken(token: string): RequestClass {
		if (Util.String.isEmpty(token)) throw new Error(`RequestAbstract => setCaptchaToken => FATAL ERROR: token is empty (value: ${token})`);
		this.captchaToken = token;
		return this as AbstractSecureRequest<RequestClass> as RequestClass;
	}


	/**------------------------------------------------------
	 * Prepare Endpoint Url
	 */
	protected prepareEndpointUrl(): string {
		const endpointUrl: string = this.serverUrl + this.prepareEndpointWithParams() + this.prepareQuery();
		return Util.Http.purifyRoute(endpointUrl);
	}

	private prepareEndpointWithParams(): string {

		//0 - define the route
		const endpointRoute: string = Util.String.isEmpty(this.endpointVersion)
			? this.endpoint
			: `/${this.endpointVersion}/${this.endpoint}`;
		if (Util.String.isEmpty(this.params?.toString())) return endpointRoute;				// return endpoint if params are empty

		//1 - replace params in endpoint
		let urlWithSetParams: string = endpointRoute;
		for (const paramsKey in this.params) {

			//a. check if paramsKey is present in endpoint
			if (!endpointRoute.includes(`:${paramsKey}`)) throw new Error(`RequestAbstract => prepareEndpointWithParams => FATAL ERROR: params of ":${paramsKey}" is not present in endpoint (endpoint: ${this.endpoint})`);

			//b. replace paramsKey with params value
			urlWithSetParams = urlWithSetParams.replace(`:${paramsKey}`, this.params[paramsKey] as string);
		}

		//2 - return url with set params
		return urlWithSetParams;
	}

	private prepareQuery(): string {
		if (Util.Object.isEmpty(this.queryParams)) return '';
		return `?${this.queryParams.toString()}`;
	}


	/**------------------------------------------------------
	 * Prepare Headers
	 */
	protected prepareHeaders(): Record<string, string> {

		//0 - check if headers are empty
		const headers: Record<string, string | number | boolean> = Util.Basic.deepCopy(this.headers);
		for (const headerKey in headers) {
			if (Util.String.isEmpty(headers[headerKey])) throw new Error(`RequestAbstract => getSseHeaders => FATAL ERROR: header of "${headerKey}" is empty (value: ${this.headers[headerKey]})`);
		}

		//1 - set the security features to the headers
		const securedHeaders: Record<string, string | number | boolean> = this.getSecuredHeaders(headers);
		return securedHeaders as Record<string, string>;
	}

	private getSecuredHeaders(headers: Record<string, string | number | boolean>): Record<string, string | number | boolean> {

		//0 - return headers if security options are empty
		const securityOptions: ISecureHttpSecurityOptions = this.securityOptions || this.getSecureDefault();
		if (Util.Object.isEmpty(securityOptions)) return headers;

		//1 - set secure fingerprint header
		headers[EnumSecureHeaders.SecureFingerPrint] = Util.Crypto.quickHashObject(securityOptions);

		//2 - set captcha token header
		if (securityOptions.timeToken) headers[EnumSecureHeaders.TimeToken] = this.timeTokenService.getTimeToken();

		//3 - set captcha token header
		if (this.captchaToken) headers[EnumSecureHeaders.GoogleRecaptcha] = this.captchaToken;

		//4 - return headers
		return headers;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	protected validateBySchemaAndThrow<T extends object>(paramType: string, data: T, schema: ValidatorSchemaRef<T>): void {

		//0 - is the data valid?
		if (Validator.Schema.isValid(data, schema)) return;

		//1 - log information for debugging
		console.warn(`Invalid ${paramType} data: `, data);
		console.warn('Validation Errors: ', Validator.Schema.validate(data, schema));

		//2 - throw the error so the backend does not get called
		throw new Error(`RequestAbstract => validateBySchemaAndThrow => FATAL ERROR: data of paramType "${paramType}" is empty/undefined (data: ${data})`);
	}
}
