export * from './platform-templates/fine-art-america-template.interface';
export * from './platform-templates/printful-template.interface';
export * from './platform-templates/printify-template.interface';
export * from './platform-templates/redbubble-template.interface';
export * from './platform-templates/shirtee-template.interface';
export * from './platform-templates/society6-template.interface';
export * from './platform-templates/spreadshirt-template.interface';
export * from './platform-templates/teepublic-template.interface';
export * from './platform-templates/teespring-template.interface';
export * from './platform-templates/tostadora-template.interface';
export * from './platform-templates/zazzle-template.interface';
export * from './platform-account.interface';
export * from './platform-template.interface';
