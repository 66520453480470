import { EnumTmStatus } from '../../app-constants/trademark.constants';


/**------------------------------------------------------
 * TM Interface Definitions
 */
export interface ITrademarkData {
	id                  	: string;
	tmName              	: string;
	tmOffice            	: string;
	tmType              	: string;
	tmStatus            	: EnumTmStatus;
	applicationNumber   	: string;
	registrationNumber  	: string;
	applicationDate     	: Date;
	registrationDate    	: Date;
	tmNiceClass         	: string[];
	oppositionPeriodStart	: Date | null;
	oppositionPeriodEnd		: Date | null;
	applicantName          ?: string | null;
	tmSearch			   ?: string;
}
