import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';


/**------------------------------------------------------
 * Feature Definitions
 */
export enum EnumTmSearchFeatures {
	AdvanceOptions 		= 'tm_search_advanced_options',
	FilterOptions		= 'tm_search_filter_options',
	AddToWatchlist		= 'tm_search_add_to_watchlist'
}


/**------------------------------------------------------
 * Limitations of the Research
 */
export const TRADEMARK_LIMITATIONS: ITrademarkLimitations[] = [

	// ---------------------- free version
	{
		modules			: [EnumSubscriptionModule.FreeVersion],
		excludeTrademark: {
			tmSearch 	 	: [EnumTmSearchFeatures.AdvanceOptions, EnumTmSearchFeatures.FilterOptions, EnumTmSearchFeatures.AddToWatchlist]
		}
	},

	// ---------------------- research module
	{
		modules			: [EnumSubscriptionModule.Trademark],
		excludeTrademark: {
			tmSearch 	 	: []
		}
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface ITrademarkLimitations {
	modules			: EnumSubscriptionModule[];
	excludeTrademark: {
		tmSearch 	 	: EnumTmSearchFeatures[];
	};
}
