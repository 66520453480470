/* eslint-disable no-undefined */
import { ValidatorValueProcessor } from './value-processor';
import { IValidatorError } from '../validator-error.interface';
import { TypeValidatorValidateFn, IValidatorValidateOperation, IValidatorTransformOperation, EnumValidatorOperationType, IValidatorProcessResult, TypeValidatorTransformFn, IValidatorAngularAbstractControl, TypeValidatorAngularErrors, TypeValidatorAngularFn } from '../validator-value.interface';


/**------------------------------------------------------
 * Base Validation Class
 */
export abstract class AbstractValidatorValue<TValueClass> {

	//** Processors / Executors */
	protected valueProcessor: ValidatorValueProcessor = new ValidatorValueProcessor();
	protected operations	: Array<IValidatorValidateOperation | IValidatorTransformOperation> = [];
	getOperations(): Array<IValidatorValidateOperation | IValidatorTransformOperation> {
		return this.operations;
	}


	constructor() {

		// validate if value is a valid string
		// > Note: if not a string stop further execution as they can have errors
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: any) => ({
				isValid		: this.isValueTypeValid(value),
				stopVal  	: !this.isValueTypeValid(value),
				error		: `Value is not of type '${this.requiredTypeName()}'`
			})
		});
	}

	//** Methods to Implement in Child */
	protected abstract requiredTypeName(): string;
	protected abstract isValueTypeValid(value: any): boolean;


	optional(): TValueClass {

		//0 - check if the value is optional
		// > Note: Add to the start of the validation. If the value is not defined,
		// > and the "optional()" operator is executed all further operations will
		// > the skipped. If the value is optional and not defined, then skip validation.
		this.operations.unshift({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: any) => ({
				isValid		: true,
				stopVal  	: value === undefined || value === null,			// if optional and the value is not defined, skip all further operations
				error		: ''
			})
		});

		//1 - support for method chaining
		return this as AbstractValidatorValue<TValueClass> as TValueClass;
	}

	default(defaultValue: any): TValueClass {

		//0 - apply the default value if needed
		// > Note: Add to the start of the validation. The default
		// > value applies only if the value was not defined
		this.operations.unshift({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: any) => {
				const isValueUndefined: boolean = value === undefined || value === null;
				return isValueUndefined ? defaultValue : value;
			}
		});

		//1 - support for method chaining
		return this as AbstractValidatorValue<TValueClass> as TValueClass;
	}


	/**------------------------------------------------------
	 * Custom Validation & Transformation
	 */
	verify<T>(verifyFn: TypeValidatorValidateFn<T>): TValueClass {

		//0 - add custom validate
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: any) => verifyFn(value)
		});

		//1 - support for method chaining
		return this as AbstractValidatorValue<TValueClass> as TValueClass;
	}

	map<T>(mapFn: TypeValidatorTransformFn<T>): TValueClass {

		//0 - add custom transformation
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: T) => mapFn(value) as T
		});

		//1 - support for method chaining
		return this as AbstractValidatorValue<TValueClass> as TValueClass;
	}


	/**------------------------------------------------------
	 * Exposing
	 */
	ngForm(): TypeValidatorAngularFn {
		return (control: IValidatorAngularAbstractControl): TypeValidatorAngularErrors | null => {

			//0 - if we did not have any validation issue the validation passed
			const validationError: IValidatorError | null = this.valueProcessor.processValueByOperations(this.getOperations(), control.value).validationError;
			if (!validationError) return null;

			//1 - create angular form validation error
			const validationErrors: TypeValidatorAngularErrors = { [validationError.property]: validationError.errors };
			return validationErrors;
		};
	}


	/**------------------------------------------------------
	 * Process Value
	 */
	isValid(value: any): boolean {
		const result: IValidatorProcessResult = this.valueProcessor.processValueByOperations(this.getOperations(), value);
		return result.validationError === null;
	}

	validate(value: any): IValidatorError | null {
		const result: IValidatorProcessResult = this.valueProcessor.processValueByOperations(this.getOperations(), value);
		return result.validationError;
	}

	process(value: any): any {

		//0 - process and check if there were any validation issues?
		const result: IValidatorProcessResult = this.valueProcessor.processValueByOperations(this.getOperations(), value);
		if (result.validationError) throw new Error(`AbstractValidator => process => FATAL ERROR: deny transformation as value of "${value}" is invalid and not matching the conditions (make sure to validate the data first)`);

		//1 - return the transformed value
		return result.transformedValue;
	}
}
