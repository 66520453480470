import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumZazzleSorting {
	Popular			= 'popular',			// default value
	Newest			= 'newest',
	PriceLowToHigh	= 'price_low_to_high',
	PriceHighToLow	= 'price_high_to_low'
}

export const ZAZZLE_SORTING_LIST: ISearchParameterConfig<EnumZazzleSorting>[] = [
	{
		code 		: EnumZazzleSorting.Popular,
		name 		: 'Popular',
		parameters 	: {}
	},
	{
		code 		: EnumZazzleSorting.Newest,
		name       	: 'Newest',
		parameters 	: {
			st		: 'date_created'
		}
	},
	{
		code 		: EnumZazzleSorting.PriceLowToHigh,
		name       	: 'Price Low To High',
		parameters 	: {
			st 		: 'price',
			sd 		: 'asc'
		}
	},
	{
		code 		: EnumZazzleSorting.PriceHighToLow,
		name 		: 'Price High To Low',
		parameters 	: {
			st 		: 'price'
		}
	}
];


/**------------------------------------------------------
 * Product Configurations
 */
export enum EnumZazzleProduct {
	All				= 'all',				// default value
	CoffeeMugs		= 'coffee_mugs',
	ClothingAndShoes = 'clothing_and_shoes',
	MenShirts		= 'men_shirts',
	WomenShirts		= 'women_shirts',
	MenHoodies		= 'men_hoodies',
	KidsClothing	= 'kids_clothing_and_shoes',
	Accessories		= 'accessories',
	ToteBags		= 'tote_bags'
}

export const ZAZZLE_PRODUCT_LIST: ISearchParameterConfig<EnumZazzleProduct>[] = [
	{
		code	   : EnumZazzleProduct.All,
		name       : 'All',
		parameters : {}
	},
	{
		code 		: EnumZazzleProduct.CoffeeMugs,
		name 		: 'Coffee Mugs',
		parameters	: {
			dp 		: '252650912503659973'
		}
	},
	{
		code 		: EnumZazzleProduct.ClothingAndShoes,
		name 		: 'Clothing And Shoes',
		parameters	: {
			dp 		: '252448753675037147'
		}
	},
	{
		code 		: EnumZazzleProduct.MenShirts,
		name 		: 'Men Shirts',
		parameters	: {
			dp 		: '252477843985536935'
		}
	},
	{
		code 		: EnumZazzleProduct.WomenShirts,
		name 		: 'Women Shirts',
		parameters	: {
			dp 		: '252028589404414806'
		}
	},
	{
		code 		: EnumZazzleProduct.MenHoodies,
		name 		: 'Men Hoodies',
		parameters	: {
			dp 		: '252373232887418566'
		}
	},
	{
		code 		: EnumZazzleProduct.KidsClothing,
		name 		: 'Kids Clothing',
		parameters	: {
			dp 		: '252325881220551784'
		}
	},
	{
		code 		: EnumZazzleProduct.Accessories,
		name 		: 'Accessories',
		parameters	: {
			dp 		: '252215319309773652'
		}
	},
	{
		code 		: EnumZazzleProduct.ToteBags,
		name 		: 'Tote Bags',
		parameters	: {
			dp 		: '252193615037612430'
		}
	}
];


/**------------------------------------------------------
 * Zazzle Configuration
 */
export const ZAZZLE_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.zazzle.com/',
		login		: 'https://www.zazzle.com/',
		search		: 'https://www.zazzle.com/s/[SEARCH]?pg=[PAGE]'
	},
	pageLimit		: 20,
	sortings		: ZAZZLE_SORTING_LIST,
	products		: ZAZZLE_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : false
	}
};


/**------------------------------------------------------
 * Zazzle Search Options
 */
export const ZAZZLE_DEFAULT_OPTIONS: IZazzleSearchOptions = {
	page			: 1,
	sorting			: EnumZazzleSorting.Popular,
	product			: EnumZazzleProduct.All
};

export interface IZazzleSearchOptions {
	page			: number;
	sorting			: EnumZazzleSorting;
	product			: EnumZazzleProduct;
}
