/**------------------------------------------------------
 * Length Definitions
 */
export enum EnumLength {
	Nanometer 		= 'length_nm',
	Micrometer 		= 'length_μm',
	Millimeter 		= 'length_mm',
	Centimeter	 	= 'length_cm',
	Meter	 		= 'length_m',
	Kilometer	 	= 'length_km',
	Inch	 		= 'length_in',
	Yard	 		= 'length_yd',
	SurveyFootUS	= 'length_ft_us',
	Foot	 		= 'length_ft',
	Fathom	 		= 'length_fathom',
	Mile	 		= 'length_mi',
	NauticalMile	= 'length_nMi'
}
