import { UtilBasic } from './util-basic';
import { UtilString } from './util-string';
import { UtilArray } from './util-array';


/**------------------------------------------------------
 * Object Utilities
 * ----------------
 * > Info: Containing all functionalities related to objects
 * > like checking for empty objects, ...
 */
export class UtilObject {

	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString,
		private utilArray	: UtilArray
	) {}


	/**------------------------------------------------------
	 * Check Object Type
	 */
	isObject(object: any): boolean {
		return Boolean(object) && (object.constructor === Object);
	}
	isNotObject(object: any): boolean {
		return !this.isObject(object);
	}


	/**------------------------------------------------------
	 * Check for Empty
	 */
	isEmpty(object: object | undefined | null): boolean {
		if (this.utilBasic.isUndefined(object) || Object.entries(object!).length === 0) return true;
		return false;
	}
	isNotEmpty(object: object): boolean {
		return !this.isEmpty(object);
	}


	/**------------------------------------------------------
	 * Check for Undefined Values
	 */
	hasUndefinedValues(object: object, except: string[] = []): boolean {
		for (const [key, value] of Object.entries(object)) {
			if (except.includes(key)) continue;
			if (this.utilBasic.isUndefined(key) || this.utilBasic.isUndefined(value)) return true;
		}
		return false;
	}
	hasNoUndefinedValues(object: object): boolean {
		return !this.hasUndefinedValues(object);
	}

	hasDefinedValues(object: object): boolean {
		for (const [key, value] of Object.entries(object)) {
			if (this.utilBasic.isDefined(key) && this.utilBasic.isDefined(value)) return true;
		}
		return false;
	}
	hasNoDefinedValues(object: object): boolean {
		return !this.hasDefinedValues(object);
	}


	/**------------------------------------------------------
	 * Check Object Keys
	 */
	isKeyValid<T>(value: T): boolean {
		return this.utilBasic.isDefined(value);
	}
	isKeyNotValid<T>(value: T): boolean {
		return !this.isKeyValid(value);
	}
	isObjectKeyValid(object: object, key: string): boolean {
		return this.utilBasic.isDefined((object as any)[key]);
	}
	isObjectKeyNotValid(object: object, key: string): boolean {
		return !this.isObjectKeyValid(object, key);
	}


	/**------------------------------------------------------
	 * Compare Objects
	 */
	isEqual<T extends object>(obj1: T, obj2: T): boolean {
		return JSON.stringify(obj1) === JSON.stringify(obj2);
	}
	isNotEqual<T extends object>(obj1: T, obj2: T): boolean {
		return !this.isEqual(obj1, obj2);
	}

	compareKeys<T1 extends object, T2 extends object>(a: T1, b: T2): boolean {
		const aKeys: string[] = Object.keys(a).sort();
		const bKeys: string[] = Object.keys(b).sort();
		return this.utilBasic.stringifyObject(aKeys) === this.utilBasic.stringifyObject(bKeys);
	}


	/**------------------------------------------------------
	 * Object Helpers
	 */
	getObjValue<T>(name: string, value: T): Record<string, T> | object {
		if (!this.utilBasic.isUndefined(value)) return { [name]: value };
		return {};
	}

	getStringValues(object: object): string[] {

		//0 - check for wrong function call
		if (!this.isObject(object) && !this.utilBasic.isArray(object)) throw new Error(`UtilObject => getStringValues => FATAL ERROR: provided argument is not of type object or array`);

		//1 - convert object to an array of its values
		const values: unknown[] = !Array.isArray(object)
			? Object.keys(object).map((key: string) => (object as any)[key])
			: object;

		//2 - get all the values
		const stringValue: string[] = [];
		for (const value of values) {
			if (typeof value === 'object') stringValue.push(...this.getStringValues(value!));
			if (typeof value === 'string') stringValue.push(value);
		}
		return stringValue;
	}

	//** Get merged string values from provided fields */
	getStringValuesFromFields(object: object, fields: string[]): string[] {

		//0 - check for wrong function call
		this.checkObject(object);
		if (!this.utilBasic.isArray(fields) || fields?.length === 0) throw new Error(`UtilObject => getStringValuesFromFields => FATAL ERROR: provided argument fields is not a valid array`);

		//1 - loop over the fields
		const stringValues: string[] = [];
		for (const field of fields) {

			//a. check if object value is a valid string
			if (this.utilBasic.isUndefined((object as any)[field]))  throw new Error(`UtilObject => getStringValuesFromFields => FATAL ERROR: provided field value of object[${field}] is undefined`);
			if (this.utilString.isNotString((object as any)[field])) throw new Error(`UtilObject => getStringValuesFromFields => FATAL ERROR: provided field "${field}" value is not a string`);

			//b. merge the string
			stringValues.push((object as any)[field] as string);
		}

		//2 - return trimmed merged string in lowercase
		return stringValues;
	}


	/**------------------------------------------------------
	 * Extract Values
	 */
	asValueArray<T>(object: object): T[] {
		this.checkObject(object);
		const array: T[] = [];
		for (const key in object) {
			array.push((object as any)[key] as T);
		}
		return array;
	}

	asTupleArray<T>(object: object): Array<[string, T]> {
		this.checkObject(object);
		return Object.entries(object);
	}


	/**------------------------------------------------------
	 * Invert the Object Keys
	 * > source : https://decipher.dev/30-seconds-of-typescript/docs/invertKeyValues
	 * > example: { a: 1, b: 2, c: 1 } => { 1: [ 'a', 'c' ], 2: [ 'b' ] }
	 */
	invertKeyValues<T extends object>(object: T, keyNamingFunction?: TypeUtilKeyNamingFunction): object {
		return Object.keys(object).reduce((acc: object, key: string) => {

			//0 - get the key
			const value: string = keyNamingFunction
				? keyNamingFunction((object as any)[key])
				: (object as any)[key];

			//1 - add value to object
			(acc as any)[value] = (acc as any)[value] || [];
			(acc as any)[value].push(key);
			return acc;
		}, {});
	}


	/**------------------------------------------------------
	 * Assigns default properties
	 * > desc. 	: Assigns default values for all properties which are undefined
	 * > source : https://decipher.dev/30-seconds-of-typescript/docs/defaults
	 */
	defaults<T extends object>(object: T, ...properties: any[]): T {

		//0 - check the parameters
		this.checkObject(object);
		if (properties.length === 0) throw new Error(`UtilObject => defaults => FATAL ERROR: properties array is empty (value: ${properties})`);

		//1 - assign the parameters if the value is not set yet
		return Object.assign({}, object, ...properties, object);
	}


	/**------------------------------------------------------
	 * Shuffle Object Elements
	 */
	shuffle<T extends object>(object: T): T {

		//0 - check the function call
		if (!this.isObject(object)) throw new Error(`UtilObject => shuffle => FATAL ERROR: provided value is not an object`);

		//1 - shuffle all values
		const shuffledKeys		: string[] = this.utilArray.shuffle(Object.keys(object));
		const shuffledHeaders	: any = {};

		//2 - assign shuffled keys
		for (const key of shuffledKeys) {
			shuffledHeaders[key] = (object as any)[key];
		}
		return object;
	}


	/**------------------------------------------------------
	 * Others
	 */
	objectKeysToLowercase<TInitial extends object>(object: TInitial): object {
		const preparedObj: object = {};
		for (const key of Object.keys(object)) {
			(preparedObj as any)[key.toLowerCase().trim()] = (object as any)[key];
		}
		return preparedObj;
	}

	stringifyObjectValues(object: any): Record<string, string> {

		//0 - convert non-object values to a string
		if (typeof object !== 'object' || object === null) {
			object = String(object);
			return object;
		}

		//1 - recursive calls to convert everything
		for (const key in object) {
			if (Object.prototype.hasOwnProperty.call(object, key)) {
				object[key] = this.stringifyObjectValues(object[key]); 		// recursively stringify nested objects
				object[key] = String(object[key]); 							// convert the value to a string
			}
		}
		return object;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private checkObject(object: object) {
		if (!this.isObject(object)) throw new Error(`UtilObject => checkObject => FATAL ERROR: provided argument is not of type object`);
	}
}


//** Types -------------------------------------- */
type TypeUtilKeyNamingFunction = (value: string | number | boolean | object) => string;
