import { delay } from 'rxjs/operators';
import { Observable, Subscriber } from 'rxjs';
/* eslint-disable no-console */


/**------------------------------------------------------
 * Mock HTTP & API's
 * -----------------
 * > Info: Containing all functionalities for mocking api
 * > calls in the frontend.
 */
export class MockHttpRequest {

	//** Configurations */
	private readonly RESPONSE_TIME: number = 500;


	/**------------------------------------------------------
	 * GET Request
	 */
	get<T extends object>(url: string, getResponse: T, responseTime: number = this.RESPONSE_TIME): Observable<T> {

		//0 - create get mocking request
		const observable: Observable<T> = new Observable((subscriber: Subscriber<T>) => {

			//a. log the mocking data
			console.log('-------------------------');
			console.log(`Mock GET, url: ${url}`);
			console.log(` => response: `, getResponse, `\n`);

			//b. trigger the request
			subscriber.next(getResponse);

			//c. complete the subscriber
			subscriber.complete();
		});

		//1 - return the observable
		return observable.pipe(delay(responseTime));
	}


	/**------------------------------------------------------
	 * POST Request
	 */
	post<B extends object, T extends object>(url: string, postBody: B, postResponse: T = ({} as T), responseTime: number = this.RESPONSE_TIME): Observable<T> {

		//0 - create get mocking request
		const observable: Observable<T> = new Observable((subscriber: Subscriber<T>) => {

			//a. log the mocking data
			console.log('-------------------------');
			console.log(`Mock POST, url: ${url}`);
			console.log(` => postBody: `, postBody);
			console.log(` => response: `, postResponse, `\n`);

			//b. trigger the request
			subscriber.next(postResponse);

			//c. complete the subscriber
			subscriber.complete();
		});

		//1 - return the observable
		return observable.pipe(delay(responseTime));
	}


	/**------------------------------------------------------
	 * PUT Request
	 */
	put<B extends object, T extends object>(url: string, putBody: B, putResponse: T = ({} as T), responseTime: number = this.RESPONSE_TIME): Observable<T> {

		//0 - create get mocking request
		const observable: Observable<T> = new Observable((subscriber: Subscriber<T>) => {

			//a. log the mocking data
			console.log('-------------------------');
			console.log(`Mock PUT, url: ${url}`);
			console.log(` => putBody: `, putBody);
			console.log(` => response: `, putResponse, `\n`);

			//b. trigger the request
			subscriber.next(putResponse);

			//c. complete the subscriber
			subscriber.complete();
		});

		//1 - return the observable
		return observable.pipe(delay(responseTime));
	}


	/**------------------------------------------------------
	 * PATCH Request
	 */
	patch<B extends object, T extends object>(url: string, patchBody: B, patchResponse: T = ({} as T), responseTime: number = this.RESPONSE_TIME): Observable<T> {

		//0 - create get mocking request
		const observable: Observable<T> = new Observable((subscriber: Subscriber<T>) => {

			//a. log the mocking data
			console.log('-------------------------');
			console.log(`Mock PATCH, url: ${url}`);
			console.log(` => patchBody: `, patchBody);
			console.log(` => response: `, patchResponse, `\n`);

			//b. trigger the request
			subscriber.next(patchResponse);

			//c. complete the subscriber
			subscriber.complete();
		});

		//1 - return the observable
		return observable.pipe(delay(responseTime));
	}


	/**------------------------------------------------------
	 * DELETE Request
	 */
	delete<T extends object>(url: string, deleteResponse: T = ({} as T), responseTime: number = this.RESPONSE_TIME): Observable<T> {

		//0 - create get mocking request
		const observable: Observable<T> = new Observable((subscriber: Subscriber<T>) => {

			//a. log the mocking data
			console.log('-------------------------');
			console.log(`Mock DELETE, url: ${url}`);
			console.log(` => response: `, deleteResponse, `\n`);

			//b. trigger the request
			subscriber.next(deleteResponse);

			//c. complete the subscriber
			subscriber.complete();
		});

		//1 - return the observable
		return observable.pipe(delay(responseTime));
	}
}
