import { CURRENCY_INFOS, DIGITAL_INFOS, EnumCurrency, EnumDigital, EnumLength, EnumMass, EnumSpeed, EnumTemperature, EnumTime, ICurrencyInfo, IUnitRecord, LENGTH_INFOS, MASS_INFOS, SPEED_INFOS, TEMPERATURE_INFOS, TIME_INFOS } from '@libs/constants';

import { UtilEnum } from './util-enum';
import { UtilBasic } from './util-basic';
import { UtilNumber } from './util-number';


/**------------------------------------------------------
 * Unit Converter
 * --------------
 * > This util is used to convert between different units
 */
export class UtilConvert {

	constructor(
		private utilBasic	: UtilBasic,
		private utilNumber	: UtilNumber,
		private utilEnum	: UtilEnum
	) {}


	/**------------------------------------------------------
	 * Currency
	 */
	convertCurrency(value: number, from: EnumCurrency, to: EnumCurrency): number {

		//0 - currencies should not be the same
		if (from === to) throw new Error(`UtilCurrency => convert => FATAL ERROR: currencyFrom and currencyTo can not be the same (value: ${from})`);

		//1 - get the currency objects (check for currencies included)
		const configFrom: ICurrencyInfo = CURRENCY_INFOS[from];
		const configTo  : ICurrencyInfo = CURRENCY_INFOS[to];

		//2 - convert the value to USD
		const valUsd   : number = value  / configFrom.rateToUSD;
		const valTarget: number = valUsd * configTo.rateToUSD;
		return valTarget;
	}


	/**------------------------------------------------------
	 * Units
	 */
	convertDigital(value: number, from: EnumDigital, to: EnumDigital): number {
		if (!this.utilEnum.areValid(EnumDigital, [from, to])) throw new Error(`UtilConvert => convert => FATAL ERROR: provided digital from "${from}", or digital to "${to}" is invalid (valid values are: ${this.utilEnum.values(EnumDigital)})`);
		return this.convertUnits({
			unitDataset	: DIGITAL_INFOS,
			value		: value,
			from		: from,
			to			: to
		});
	}

	convertLength(value: number, from: EnumLength, to: EnumLength): number {
		if (!this.utilEnum.areValid(EnumLength, [from, to])) throw new Error(`UtilConvert => convert => FATAL ERROR: provided length from "${from}", or length to "${to}" is invalid (valid values are: ${this.utilEnum.values(EnumLength)})`);
		return this.convertUnits({
			unitDataset	: LENGTH_INFOS,
			value		: value,
			from		: from,
			to			: to
		});
	}

	convertMass(value: number, from: EnumMass, to: EnumMass): number {
		if (!this.utilEnum.areValid(EnumMass, [from, to])) throw new Error(`UtilConvert => convert => FATAL ERROR: provided mass from "${from}", or mass to "${to}" is invalid (valid values are: ${this.utilEnum.values(EnumMass)})`);
		return this.convertUnits({
			unitDataset	: MASS_INFOS,
			value		: value,
			from		: from,
			to			: to
		});
	}

	convertSpeed(value: number, from: EnumSpeed, to: EnumSpeed): number {
		if (!this.utilEnum.areValid(EnumSpeed, [from, to])) throw new Error(`UtilConvert => convert => FATAL ERROR: provided speed from "${from}", or speed to "${to}" is invalid (valid values are: ${this.utilEnum.values(EnumSpeed)})`);
		return this.convertUnits({
			unitDataset	: SPEED_INFOS,
			value		: value,
			from		: from,
			to			: to
		});
	}

	convertTemperature(value: number, from: EnumTemperature, to: EnumTemperature): number {
		if (!this.utilEnum.areValid(EnumTemperature, [from, to])) throw new Error(`UtilConvert => convert => FATAL ERROR: provided temperature from "${from}", or temperature to "${to}" is invalid (valid values are: ${this.utilEnum.values(EnumTemperature)})`);
		return this.convertUnits({
			unitDataset	: TEMPERATURE_INFOS,
			value		: value,
			from		: from,
			to			: to
		});
	}

	convertTime(value: number, from: EnumTime, to: EnumTime): number {
		if (!this.utilEnum.areValid(EnumTime, [from, to])) throw new Error(`UtilConvert => convert => FATAL ERROR: provided time from "${from}", or time to "${to}" is invalid (valid values are: ${this.utilEnum.values(EnumTime)})`);
		return this.convertUnits({
			unitDataset	: TIME_INFOS,
			value		: value,
			from		: from,
			to			: to
		});
	}


	/**------------------------------------------------------
	 * Convert Units
	 */
	protected convertUnits<TEnum extends string>(params: IUtilConvertUnitsPrams<TEnum>): number {

		//0 - check the function call
		if (!this.utilNumber.isNumber(params.value)) throw new Error(`UtilConvert => convertUnits => FATAL ERROR: provided value of "${params.value}" is not a valid number`);

		//1 - check if both units have the same type
		const unitFrom: IUnitRecord = params.unitDataset[params.from];
		const unitTo  : IUnitRecord = params.unitDataset[params.to];
		if (unitFrom.type !== unitTo.type) throw new Error(`UtilConvert => convertUnits => FATAL ERROR: the type of "from" (type: ${unitFrom.type}) is not compatible with the type of "to" (type: ${unitTo.type}). Only if both are matching the values can be converted!`);

		//2 - if the metric systems match, convert the values
		if (unitFrom.system === unitTo.system) return (params.value * unitFrom.toBase) / unitTo.toBase;

		//3 - if the metric systems DON'T match
		// > Example: "metric <=> imperial", convert them through the metric base
		const unitSysBaseFrom: IUnitRecord = this.getSysBaseConfig(params.unitDataset, unitFrom.type, unitFrom.system);			// get the base to convert between two different metric systems
		const valueBaseFrom  : number 	   = (params.value * unitFrom.toBase) / unitSysBaseFrom.toBase;
		const valueBaseTo	 : number 	   = unitSysBaseFrom.toSystem?.convert(valueBaseFrom)!;
		const converted		 : number 	   = this.convertUnits({
			unitDataset	: params.unitDataset,
			value		: valueBaseTo,
			from		: unitSysBaseFrom.toSystem!.toCode as TEnum,
			to			: unitTo.code as TEnum
		});

		//4 - return the converted value
		return converted;
	}

	private getSysBaseConfig<TEnum extends string>(unitDataset: Record<TEnum, IUnitRecord>, type: string, system: string): IUnitRecord {

		//0 - check if the element exists
		const unitConfigs  : IUnitRecord[] = Object.values<IUnitRecord>(unitDataset);
		const unitSysConfig: IUnitRecord   = unitConfigs.find((elem: IUnitRecord) => elem.type === type && elem.system === system && this.utilBasic.isDefined((elem as any).toSystem))!;
		if (!unitSysConfig) throw new Error(`UtilConvert => getUnitConfig => FATAL ERROR: no unit configured for the system conversion (type: ${type} / system: ${system})`);

		//1 - return the defined unit config
		return unitSysConfig;
	}
}


//** Interfaces --------------------------------- */
interface IUtilConvertUnitsPrams<TEnum extends string> {
	unitDataset	: Record<TEnum, IUnitRecord>;
	value		: number;
	from		: TEnum;
	to			: TEnum;
}
