/**------------------------------------------------------
 * Random Utilities
 * ----------------
 * > Info: Containing all functionalities regarding any
 * > creation of random numbers or strings
 */
export class UtilRandom {

	//** Configurations */
	readonly ONLY_TEXT_CHARS  		: string = `abcdefghijklmnopqrstuvwxyz`;
	readonly ONLY_NUMBER_CHARS		: string = `0123456789`;
	readonly HEX_CHARS		  		: string = '0123456789abcdef';
	readonly PASSWORD_SPECIAL_CHARS	: string = `!@#$%^&*()_+-=[]{}|;':",.<>/~`;			// special chars used in passwords


	/**------------------------------------------------------
	 * Generate Random Number
	 */
	randomNumber(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min) + min);
	}

	//** Random number between 0 and 1 */
	randomNormalized(): number {
		return Math.floor(Math.random() * (100 + 1)) / 100;
	}


	/**------------------------------------------------------
	 * Generate Modified Random Values
	 */
	randomIndex(length: number): number {
		return Math.floor(Math.random() * length);
	}
	randomBoolean(): boolean {
		return Math.random() >= 0.5;
	}
	randomDeviation(number: number, deviationPercent: number): number {
		if (deviationPercent <= 0 || deviationPercent >= 100) throw new Error(`UtilRandom => randomDeviation => FATAL ERROR: provided deviationPercent "${deviationPercent}" is out of range (valid range 0 < val < 100)`);
		const deviation: number = Math.floor(number * deviationPercent);
		return this.randomNumber(number - deviation, number + deviation);
	}


	/**------------------------------------------------------
	 * Generate Random String
	 */
	randomAlphabeticString(length: number): string {
		return this.randomString(length, this.ONLY_TEXT_CHARS);
	}
	randomNumericString(length: number): string {
		return this.randomString(length, this.ONLY_NUMBER_CHARS);
	}
	randomAlphanumericString(length: number): string {
		return this.randomString(length, this.ONLY_TEXT_CHARS + this.ONLY_NUMBER_CHARS);
	}
	randomHex(length: number): string {
		return this.randomString(length, this.HEX_CHARS);
	}
	randomSpecialCharString(length: number): string {
		return this.randomString(length, this.PASSWORD_SPECIAL_CHARS);
	}
	randomKey(length: number): string {
		return Array(length).fill(0).map(() => Math.random().toString(36).charAt(2)).join('');			// all chars will be lowercase
	}


	/**------------------------------------------------------
	 * Random Manipulations
	 */
	shuffleString(inputString: string): string {

		//0 - shuffle the array elements
		const array: string[] = inputString.split('');
		for (let i: number = array.length - 1; i > 0; i--) {
			const j: number = Math.floor(Math.random() * (i + 1));		// pick a random index
			[array[i], array[j]] = [array[j], array[i]];		// swap elements array[i] and array[j]
		}

		//1 - convert the array back into a string
		return array.join('');
	}

	randomlyFlipChars(text: string, count: number): string {

		//0 - flip characters
		for (let i: number = 0; i < count; ++i) {
			text = this.randomlyFlipOneChar(text);
		}

		//1 - return changed text
		return text;
	}

	randomlyFlipOneChar(text: string): string {

		//0 - return original string if empty or null
		if (!text || text.length === 0) return text;

		//1 - randomly select an index, and get the character at the selected index
		const index 	: number = Math.floor(Math.random() * text.length);
		const charToFlip: string = text.charAt(index);

		//2 - generate a random character different from the selected character
		let flippedChar: string = null!;
		do {
			flippedChar = String.fromCharCode(Math.floor(Math.random() * 256));
		} while (flippedChar === charToFlip);

		//3 - replace the character at the selected index with the flipped character
		const flippedString: string = text.substring(0, index) + flippedChar + text.substring(index + 1);
		return flippedString;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private randomString(length: number, charList: string): string {		// lowercase and uppercase mixed by default

		//0 - check the function call
		if (length <= 0) throw new Error(`UtilRandom => randomString => FATAL ERROR: provided length is not valid (valid: length > 0)`);

		//1 - generate random string
		let result: string = '';
		for (let i: number = 0; i < length; i++) {
			result += charList.charAt(this.randomIndex(charList.length));
		}
		return result;
	}
}
