import { UtilString } from './util-string';


/**------------------------------------------------------
 * Helper Utilities
 * ----------------
 * > Info: Containing kind of functionalities gathered at
 * > one place, which can not be placed in any specific
 * > utility file.
 * ----------------
 * > Chunk String Equally	: https://stackoverflow.com/questions/6259515/how-can-i-split-a-string-into-segments-of-n-characters
 * > Convert Hex to Binary	: https://stackoverflow.com/questions/45053624/convert-hex-to-binary-in-javascript/45053836
 */
export class UtilHelper {

	constructor(
		private utilString: UtilString
	) {}


	/**------------------------------------------------------
	 * Buffer helpers
	 */
	base64ImageToBuffer(image: string): Buffer {
		if (this.utilString.isEmpty(image)) throw new Error(`UtilHelper => base64ImageToBuffer => FATAL ERROR: provided image string is empty`);
		return Buffer.from(image.replace(/^data:image\/\w+;base64,/, ''), 'base64');
	}


	/**------------------------------------------------------
	 * Email Validations
	 */
	getEmailDomain(email: string): IUtilEmail {

		//0 - check the mail address
		if (this.utilString.isEmpty(email)) throw new Error(`UtilHelper => base64ImageToBuffer => FATAL ERROR: provided email string is empty`);
		if (!email.includes('@'))	 		throw new Error(`UtilHelper => base64ImageToBuffer => FATAL ERROR: provided email is not valid (it has to have at least the '@' symbol)`);

		//1 - return the mail object
		const mailObj: IUtilEmail = {
			name	: email.split('@')[0],
			domain	: email.split('@')[1]
		};
		return mailObj;
	}
	isEmailAddress(text: string): boolean {
		const emailRegex: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g; // eslint-disable-line no-control-regex
		return emailRegex.test(text);
	}


	/**------------------------------------------------------
	 * File Path Helper
	 */
	getFileNameFromPath(filePath: string): string {

		//0 - check for empty string
		if (this.utilString.isEmpty(filePath)) throw new Error(`UtilHelper => getFileNameFromPath => FATAL ERROR: provided filePath string is empty`);

		//1 - get the file name
		const fileName: string = filePath.replace(/^.*(\\|\/|:)/, '');
		if (this.utilString.isEmpty(fileName)) throw new Error(`UtilHelper => getFileNameFromPath => FATAL ERROR: provided filePath has not fileName (filePath: "${filePath}")`);

		//2 - return the file name
		return fileName;
	}
}


//** Interfaces --------------------------------- */
interface IUtilEmail {
	name	: string;
	domain	: string;
}
