import { EnumPlatform, EnumWebsiteOption } from '../../app-constants/platforms/print-on-demand.constants';


/**------------------------------------------------------
 * Design Upload / History of the Uploads
 */
export interface IPodUploads {
	flyId			: string;
	uploads			: IPodUpload[];
}

export interface IPodUpload {
	platform    	: EnumPlatform;
	websiteOption   : EnumWebsiteOption;
	accountMail 	: string;
	uploadedAt  	: Date;
	data        	: any;
}


/**------------------------------------------------------
 * Upload Specific Data
 */
export type TypeUploadData = IDataGlobal | IDataMba | IDataDisplate;

export interface IDataGlobal {
	// ...
}

export interface IDataMba {
	// ...
}

export interface IDataDisplate {
	// ...
}
