import { Util } from '@libs/utilities/util';

import { AbstractLoggerService } from '../shared/logger.abstract';
import { ALL_LOG_LEVELS, EnumLogLevel } from '../shared/logger.interface';


/**------------------------------------------------------
 * Logger for Production & Development
 */
export class DebugLoggerService extends AbstractLoggerService {

	//** Helper Variables */
	private startTime  : number = new Date().getTime();
	private lastLogTime: number = new Date().getTime();


	constructor(
		protected override readonly logLevels: EnumLogLevel[] = ALL_LOG_LEVELS
	) {
		super(logLevels);
	}

	/**------------------------------------------------------
	 * Helper Functions
	 */
	protected printLogMessage(logLevel: EnumLogLevel, ...messages: unknown[]): string {

		//0 - format and add time stamp to the message
		const timeSinceStart	: string   = Util.Date.formatDurationShort(new Date().getTime() - this.startTime);
		const timeSinceLastLog  : string   = Util.Date.formatDurationShort(new Date().getTime() - this.lastLogTime);
		const timestamp			: string   = `[${Util.Date.format('YYYY-MM-DD HH:mm:ss')} / sinceStart: ${timeSinceStart} / sinceLastLog: ${timeSinceLastLog}]`;
		const timestampFormatted: string[] = [`%c${timestamp}\n`, 'color: #5e5e5e;'];

		//1 - log the message
		switch (logLevel) {

			case EnumLogLevel.Info:
				// eslint-disable-next-line no-console
				console.log(...timestampFormatted, ...messages);
				break;

			case EnumLogLevel.Warning:
				console.warn(...timestampFormatted, ...messages);
				break;

			case EnumLogLevel.Error:
				console.error(...timestampFormatted, ...messages);
				break;

			default:
				throw new Error(`DebugLoggerService => printLogMessage => FATAL ERROR: the logging level of "${logLevel}" was not defined`);
		}

		//2 - update last log time
		this.lastLogTime = new Date().getTime();
		return this.messagesToText(`${timestamp}, `, ...messages);
	}
}
