/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumDevice {
	Mobile  	= 'device_mobile',
	Tablet		= 'device_tablet',
	Desktop		= 'device_desktop'
}

export enum EnumOperatingSystem {
	MacOS  		= 'os_mac_os',
	Windows		= 'os_windows',
	Linux		= 'os_linux',
	IOS			= 'os_ios',
	IPadOS		= 'os_ipad',
	Android		= 'os_android',
	Other 		= 'os_other'
}

export enum EnumSystemTheme {
	Light 		= 'prefers_light_scheme',
	Dark 		= 'prefers_dark_scheme',
	Unknown		= 'unknown_scheme'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IDeviceInfo {
	code		: EnumDevice;
	name		: string;
	icon 		: string;			// icons are of style "brands"
}

export interface IOperatingSystemInfo {
	code		: EnumOperatingSystem;
	name		: string;
	icon		: string;			// icons are of style "brands"
}

export interface ISystemThemeInfo {
	code		: EnumSystemTheme;
	name		: string;
}
