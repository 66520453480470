import { EnumCountry } from '@libs/constants';


/**------------------------------------------------------
 * API Request & Response
 */
export interface IGoogleTrendsInterestOverTimeRequest {
	keyword		: string;
	country		: EnumCountry;
	startTime	: Date;
	endTime		: Date;
}

export interface IGoogleTrendsInterestOverTimeResponse {
	keyword		: string;
	interest	: IGoogleTrendsInterestValue[];
}

export interface IGoogleTrendsInterestValue {
	value		: number;
	date	 	: Date;
}
