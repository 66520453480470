import type { ManagerOptions } from 'socket.io-client/build/esm/manager';
import type { SocketOptions } from 'socket.io-client/build/esm/socket';
import { Util } from '@libs/utilities/util';
import { io, Socket } from 'socket.io-client';

import { IAuthService } from '../request-handlers.interface';
import { WebsocketApiWrapper } from './helper/websocket-api.wrapper';
import { WebsocketWrapper } from './helper/websocket.wrapper';


export class WebsocketService {

	constructor(
		private authService: IAuthService
	) { }


	/**------------------------------------------------------
	 * Open / Create a Socket Connection
	 */
	open(url: string, options?: Partial<ManagerOptions & SocketOptions>): WebsocketWrapper {

		//0 - create the new socket
		if (!Util.Http.isUrlValid(url)) throw new Error(`WebsocketService => open => FATAL ERROR: invalid url was provided to create the socket (url: "${url}")`);
		const newSocket: Socket = io(url, {
			auth		: {
				token	: this.authService.getToken()
			},
			autoConnect	: false,
			...options
		});

		//1 - create the socket wrapper
		const socketConnection: WebsocketWrapper = new WebsocketWrapper(newSocket);
		return socketConnection;
	}

	asApi<T>(url: string): WebsocketApiWrapper<T> {
		const socketConnection		: WebsocketWrapper 		 = this.open(url);
		const socketApiConnection	: WebsocketApiWrapper<T> = new WebsocketApiWrapper(socketConnection);
		return socketApiConnection;
	}
}
