/**------------------------------------------------------
 * Global Tagging Options
 */
export enum EnumGlobalOptions {
	GeneralClearCurrentLanguage		= 'global_general_clear_current_language',
	GeneralClearGlobal				= 'global_general_clear_global',
	GeneralClearAllGlobal			= 'global_general_clear_all_global',
	GeneralCopyGlobalFrom 			= 'global_general_copy_global_from',
	GeneralApplyGlobalTo 			= 'global_general_apply_global_to',

	TitleCopyTitleFrom 				= 'global_title_copy_title_from',
	TitleApplyTitleTo 				= 'global_title_apply_title_to',
	TitleUppercase					= 'global_title_uppercase',
	TitleAsListing  				= 'global_title_as_listing',
	TitleImportFromMba 				= 'global_title_import_from_mba',
	TitleImportFromDisplate			= 'global_title_import_from_displate',
	TitleFromFileNames 				= 'global_title_from_file_names',

	DescCopyDescFrom  				= 'global_desc_copy_desc_from',
	DescApplyDescTo		 			= 'global_desc_apply_desc_to',
	DescFromTitle 					= 'global_desc_from_title',
	DescImportFromMba 				= 'global_desc_import_from_mba',
	DescImportFromDisplate 			= 'global_desc_import_from_displate',

	KeywordsCopyKeywordFrom 		= 'global_keywords_copy_keyword_from',
	KeywordsApplyKeywordTo			= 'global_keywords_apply_keyword_to',
	KeywordsExtractFromListing 		= 'global_keywords_extract_from_listing',
	KeywordsAddKeywordsTo			= 'global_keywords_add_keywords_to',
	KeywordsClear 					= 'global_keywords_clear',
	KeywordsCopyToClipboard 		= 'global_keywords_copy_to_clipboard',
	KeywordsTagGenerator 			= 'global_keywords_tag_generator',
	KeywordsShuffle 				= 'global_keywords_shuffle',
	KeywordsShuffleAll 				= 'global_keywords_shuffle_all',
	KeywordsImportFromDisplate 		= 'global_keywords_import_from_displate',

	TypesCopyTypesFrom 				= 'global_types_copy_types_from',
	TypesApplyTypesTo				= 'global_type_apply_types_to',
	TypesSelectAll 					= 'global_types_select_all',
	TypesOnlyAdults 				= 'global_types_only_adults',

	ColorCopyColorFrom 				= 'global_color_copy_color_from',
	ColorApplyColorTo				= 'global_color_apply_color_to'
}
