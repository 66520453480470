import { EnumMbaProductType } from '../../../upload/app-constants';
import { EnumAmazonProduct } from './amazon.constants';


/**------------------------------------------------------
 * Amazon Product Specifications
 */
export const AMAZON_PRODUCT_SPECIFICATION: IAmazonProduct[] = [

	// Shirt --------------------
	{
		code			: EnumAmazonProduct.Shirt,
		name			: 'Standard Shirt',
		nameShort		: 'Standard Shirt',
		productType		: EnumMbaProductType.Clothing
	},

	// Premium Shirt ------------
	{
		code			: EnumAmazonProduct.PremiumShirt,
		name			: 'Premium Shirt',
		nameShort		: 'Premium Shirt',
		productType		: EnumMbaProductType.Clothing
	},

	// VNeck --------------------
	{
		code			: EnumAmazonProduct.VNeck,
		name			: 'V-Neck',
		nameShort		: 'V-Neck',
		productType		: EnumMbaProductType.Clothing
	},

	// Tank Top -----------------
	{
		code			: EnumAmazonProduct.TankTop,
		name			: 'Tank Top',
		nameShort		: 'Tank Top',
		productType		: EnumMbaProductType.Clothing
	},

	// Long Sleeve --------------
	{
		code			: EnumAmazonProduct.LongSleeve,
		name			: 'Long Sleeve',
		nameShort		: 'Long Sleeve',
		productType		: EnumMbaProductType.Clothing
	},

	// Raglan -------------------
	{
		code			: EnumAmazonProduct.Raglan,
		name			: 'Raglan',
		nameShort		: 'Raglan',
		productType		: EnumMbaProductType.Clothing
	},

	// Sweatshirt ---------------
	{
		code			: EnumAmazonProduct.Sweatshirt,
		name			: 'Sweatshirt',
		nameShort		: 'Sweatshirt',
		productType		: EnumMbaProductType.Clothing
	},

	// Hoodie -------------------
	{
		code			: EnumAmazonProduct.Hoodie,
		name			: 'Hoodie',
		nameShort		: 'Hoodie',
		productType		: EnumMbaProductType.Clothing
	},

	// Pop Socket ---------------
	{
		code			: EnumAmazonProduct.PopSocket,
		name			: 'PopSockets Grip',
		nameShort		: 'PopSockets Grip',
		productType		: EnumMbaProductType.Accessory
	},

	// Phone Case ---------------
	{
		code			: EnumAmazonProduct.PhoneCase,
		name			: 'Phone Cases',
		nameShort		: 'Phone Cases',
		productType		: EnumMbaProductType.Accessory
	},

	// Tote Bag -----------------
	{
		code			: EnumAmazonProduct.ToteBag,
		name			: 'Tote Bag',
		nameShort		: 'Tote Bag',
		productType		: EnumMbaProductType.Accessory
	},

	// Throw Pillow -------------
	{
		code			: EnumAmazonProduct.ThrowPillow,
		name			: 'Throw Pillows',
		nameShort		: 'Throw Pillows',
		productType		: EnumMbaProductType.Accessory
	}
];


//** Interfaces --------------------------------- */
export interface IAmazonProduct {
	code			: EnumAmazonProduct;
	name			: string;
	nameShort		: string;
	productType		: EnumMbaProductType;
}
