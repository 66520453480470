/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingInfluencer {
	RyanHogue 			= 'ryan_hogue',
	DanielGaiswinkler 	= 'daniel gaiswinkler',
	Patrick 			= 'patrick',
	Nico 				= 'nico',
	LeonChaudhari 		= 'leon chaudhari'
}


/**------------------------------------------------------
 * Constants
 */
export const FLYING_INFLUENCER_TESTIMONIALS: Record<EnumFlyingInfluencer, IFlyingTestimonial> = {
	[EnumFlyingInfluencer.RyanHogue]: {
		name		: 'Ryan Hogue',
		subName 	: 'Passive Income Previous',
		text		: `I use Flying Research on a regular basis to find new, best-selling print on demand products for my Amazon Merch business. It's become my go-to tool when preparing my 'Top 5 Niches of the Week' YouTube video series!`,
		channelName : 'Ryan Hogue Passive Income',
		channelLink : 'https://www.youtube.com/user/RyanHcs',
		videos	  	: [
			{
				name: 'Research',
				link: 'https://www.youtube.com/watch?v=UKlWzW683UM'
			}
		]
	},
	[EnumFlyingInfluencer.DanielGaiswinkler]: {
		name		: 'Daniel Gaiswinkler',
		subName 	: 'Teemoney.de',
		text		: `Die Jungs von Flying sind einfach Spitze. Seit den ersten Stunden bin ich großer Fan und konnte schon viele Ideen zu der wirklich genialen Software beisteuern. Genau so ein Tool habe ich mir immer gewünscht und hier ist es Realität geworden. Research, Upload, Trademark Check in einem - genau so ist es perfekt.`,
		channelName : 'Daniel Gaiswinkler / Teemoney.de',
		channelLink : 'https://www.youtube.com/channel/UCedXlfiPdgJYRWn6nZOnI2g',
		videos	  	: [
			{
				name: 'Article Research',
				link: 'https://www.danielgaiswinkler.com/online-tools/flying-research-testbericht/'
			},
			{
				name: 'Research',
				link: 'https://www.youtube.com/watch?v=dY03YSC02VU&t=54s'
			},
			{
				name: 'Upload',
				link: 'https://www.youtube.com/watch?v=qUNaJn5qKOY&t=17s'
			}
		]
	},
	[EnumFlyingInfluencer.Patrick]: {
		name		: 'Patrick',
		subName 	: 'POD - Business Mit Patty Previous',
		text		: `Spart euch den stundenlangen 'manuellen' Research. Mit Flying Research bekommt ihr eine schnelle und gute Übersicht über bekannte POD-Marktplätze' Die Nutzung von Flying Upload hat meinen Design-Einstellungsprozess wirklich vereinfacht. Ich genieße es, mit einfachen Tastenkombinationen viel Zeit zu sparen und meine Prozesse weiter zu optimieren.`,
		channelName : 'POD - Business Mit Patty',
		channelLink : 'https://www.youtube.com/channel/UCvYo7EKJloIARYCs8DjiToA',
		videos	  	: [
			{
				name: 'Upload',
				link: 'https://www.youtube.com/watch?v=RCPrx2POKPA'
			},
			{
				name: 'Research',
				link: 'https://www.youtube.com/watch?v=YKU_Fsgly0I'
			}
		]
	},
	[EnumFlyingInfluencer.Nico]: {
		name		: 'Nico',
		subName 	: 'Master Your Merch Previous',
		text		: `Ich nutze Flying Upload, weil es super einfach ist damit zu arbeiten und es mir wirklich viel Zeit spart. Der Research macht richtig Spaß zu verwenden, um nach neuen Inspirationen zu suchen. Den Spreadshirt und Redbubble Research finde ich hier vor allem gut, um neue Ideen zu sammeln. Mit den beiden Tools kann ich richtig effektiv mit meinen Designern zusammenarbeiten.`,
		channelName : 'Master Your Merch',
		channelLink : 'https://www.youtube.com/c/MasterYourMerch/videos',
		videos	  	: [
			{
				name: 'Upload',
				link: 'https://www.youtube.com/watch?v=uMtS1bCzijk'
			},
			{
				name: 'Research',
				link: 'https://www.youtube.com/watch?v=nSzf_mGbVfY'
			}
		]
	},
	[EnumFlyingInfluencer.LeonChaudhari]: {
		name		: 'Leon Chaudhari',
		subName 	: 'Leon Chaudhari',
		text		: `Ich benutze Flying Research seit Anfang an, um nach neuen Ideen für mein POD-Business zu suchen und um Nischen zu analysieren. Super interessant sind hier auch die Trends der Best Seller Designs. Mir ist auch die Sicherheit meiner Accounts wichtig, wofür ich die Trademark Suche und die Watchlist nutze. Mit den Benachrichtigungen bekomme ich dann mit, wenn was angemeldet oder eingetragen wurde.`,
		channelName : 'Leon Chaudhari',
		channelLink : 'https://www.youtube.com/channel/UCw9tq5Kbtx48_8Gs1YzjAQg',
		videos	  	: [
			{
				name: 'Upload',
				link: 'https://www.youtube.com/watch?v=4_tkOv6bmJ4'
			}, {
				name: 'Research',
				link: 'https://www.youtube.com/watch?v=6mMo-gkWZIE'
			}
		]
	}
};


/**------------------------------------------------------
 * Interfaces
 */
export interface IFlyingTestimonial {
	name		: string;
	subName 	: string;
	text		: string;
	channelName : string;
	channelLink : string;
	videos	  	: Array<{
		name	: string;
		link	: string;
	}>;
}
