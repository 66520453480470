import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnumSubscriptionModule } from '@apps/flying/shared';
import { JwtHandlerService } from '@libs/libraries/shared';
import { IUserAccessTokenPayload } from '@domains/user/shared';
import { IFlyingAccessRight, IFlyingAccessTokenData } from '@apps-v2/flying/shared';
import { TypeReject, TypeResolve } from '@libs/constants';

import { ThemeService } from '../../../helpers/theme/theme.service';
import { StatesService } from '../../../states/states.service';
import { AuthRequest } from '../../../../../requests/shared/auth/auth.request';
import { IRefreshTokenParams, ITokenResponse } from '../../../../../requests/shared/auth/auth.interface';
import { IAuthStorageService } from './access-token.interface';


@Injectable({
	providedIn: 'root'
})
export class AccessTokenService {

	constructor(
		@Inject('IAuthStorageService')
		private authStorageService	: IAuthStorageService,
		private authRequest			: AuthRequest,
		private statesService		: StatesService,
		private router				: Router,
		private themeService		: ThemeService,
		private jwtHelper			: JwtHandlerService
	) {}


	reAuthenticate(): Promise<boolean> {
		return new Promise((resolve: TypeResolve<boolean>, reject: TypeReject) => {

			//0 - get the refresh token
			const refreshToken: string = this.authStorageService.getRefreshToken();
			const params	  : IRefreshTokenParams = { refreshToken };

			//1 - trigger the re authentication process
			this.authRequest.getRefreshTokenApi(params).subscribe((resData: ITokenResponse) => {
				const accessToken: string = resData.token;
				this.authenticate(accessToken, refreshToken);
				resolve(true);
			}, () => {
				reject(false);
			});
		});
	}

	signoutClient(): void {
		this.authStorageService.onSignOut();
		this.themeService.setResearchTheme();
	}

	getBearerToken(): string {

		//0 - get the auth token
		const authToken: string = this.authStorageService.getAccessToken();

		//1 - create the JWT/Bearer token
		return `Bearer ${authToken}`;
	}

	getToken(): string {
		return this.authStorageService.getAccessToken();
	}

	isAuthenticated(): boolean {

		//0 - get the token
		const token: string = this.authStorageService.getAccessToken();
		if (!token) return false;

		//1 - did the token expire?
		const isTokenExpired: boolean = this.jwtHelper.isExpired(token);
		if (isTokenExpired) return false;

		//2 - set access rights
		const accessRights: IFlyingAccessRight[] = this.jwtHelper.extractPayload<IUserAccessTokenPayload<IFlyingAccessTokenData>>(token).data.accessRights;
		this.statesService.access.setAccessRights(accessRights);

		//3 - return that the user is authenticated
		return true;
	}

	authenticate(accessToken: string, refreshToken: string): void {

		//0 - save the refresh token
		this.authStorageService.setRefreshToken(refreshToken);

		//1 - set the access token
		this.setAccessToken(accessToken);
	}

	fullSignout(): void {
		this.authRequest.logout().subscribe(() => {

			//0 - signout the user & reset the user data
			this.signoutClient();
			this.statesService.user.resetUser();

			//1 - navigate back to the login screen
			this.router.navigate(['user', 'login'], { state: { message: `You're logged out successfully.` } }).then(() => {
				window.location.reload();
			});
		});
	}


	getId(): string {
		return this.isAuthenticated()
			? this.jwtHelper.extractPayload<IUserAccessTokenPayload<IFlyingAccessTokenData>>(this.authStorageService.getAccessToken()).userId
			: null;
	}

	getUserEmail(): string {
		return this.isAuthenticated()
			? this.jwtHelper.extractPayload<IUserAccessTokenPayload<IFlyingAccessTokenData>>(this.authStorageService.getAccessToken()).profile.email
			: null;
	}

	getAccessRights(): IFlyingAccessRight[] {
		return this.isAuthenticated()
			? this.jwtHelper.extractPayload<IUserAccessTokenPayload<IFlyingAccessTokenData>>(this.authStorageService.getAccessToken()).data.accessRights
			: [];
	}

	hasModule(module: EnumSubscriptionModule): boolean {

		//0 - get all the access rights a the user has
		const accessRights: IFlyingAccessRight[] = this.getAccessRights();

		//1 - can we find the right for the subscription module?
		const foundRights : IFlyingAccessRight | null  = accessRights.find((accessRight: IFlyingAccessRight) => accessRight.module === module && new Date(accessRight.expires) >= new Date());
		if (!foundRights) return false;

		//2 - if the right was found the user has access
		return true;
	}

	setAccessToken(token: string): void {

		//0 - save the access token
		this.authStorageService.setAccessToken(token);

		//1 - get the access rights
		const accessRights: IFlyingAccessRight[] = this.isAuthenticated()
			? this.jwtHelper.extractPayload<IUserAccessTokenPayload<IFlyingAccessTokenData>>(token).data.accessRights
			: [];

		//2 - set the access rights
		this.statesService.access.setAccessRights(accessRights);
	}


	/**------------------------------------------------------
	 * Private / Public State Check
	 */
	isPrivateState(): boolean {
		return !this.isPublicState();
	}

	isPublicState(): boolean {
		return !this.authStorageService.getAccessToken() && !this.authStorageService.getRefreshToken();
	}
}
