import { EnumFileType, FILE_TYPE_INFO, IFileTypeInfo, TypeReject, TypeResolve } from '@libs/constants';
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Convert Image Service
 */
export class ConvertImageHelper {

	//** Configurations */
	private readonly ACCEPTABLE_IMAGE_CONSTANT: EnumFileType[] 	= [EnumFileType.JPG, EnumFileType.JPEG, EnumFileType.PNG, EnumFileType.GIF];
	private readonly IMAGE_TYPE_DEFINITIONS	  : IFileTypeInfo[] = this.getImageTypeDefinitions();


	/**------------------------------------------------------
	 * Convert: Base64 <=> Blob
	 * ------------------------
	 * > Convert 10mb 	: 0s
	 * > Convert 100mb	: 1s
	 */
	convertBase64ToJpegBlob(base64: string): Promise<Blob> {
		return this.convertBase64ToBlob(base64, 'image/jpeg');
	}

	convertBase64ToPngBlob(base64: string): Promise<Blob> {
		return this.convertBase64ToBlob(base64, 'image/png');
	}


	/**------------------------------------------------------
	 * Convert: Blob <=> ImageData
	 * ---------------------------
	 * > Convert 7mb : 0s
	 */
	async convertBlobToImageData(blob: Blob): Promise<ImageData> {

		//0 - get png blob data
		this.isValidImage(blob);
		const imageBlob: Blob = await this.convertBlobToPng(blob);

		//1 - get image data
		const imageData: Promise<ImageData> = this.convertImageDataFromBlobData(imageBlob);
		return imageData;
	}

	convertImageDataToBlob(imageData: ImageData): Promise<Blob> {

		//0 - create canvas element
		const canvas: HTMLCanvasElement = document.createElement('canvas');
		canvas.width  = imageData.width;
		canvas.height = imageData.height;

		//1 - create context and put image data
		const renderingContext: CanvasRenderingContext2D | null = canvas.getContext('2d');
		if (!renderingContext) throw new Error(`ConvertImageHelper => convertImageDataToBlob => FATAL ERROR: failed to create context`);
		renderingContext.putImageData(imageData, imageData.width, imageData.height);

		//2 - convert into blob
		canvas.remove();
		return this.canvasElementToBlob(canvas);
	}


	/**------------------------------------------------------
	 * Convert: ImageElement <=> ImageData
	 * ---------------------------
	 * > Convert 7mb : 0s
	 */
	async convertImageElementToImageData(imageElement: HTMLImageElement): Promise<ImageData> {
		const blob: Blob = await this.convertImageElementToBlob(imageElement);
		return this.convertBlobToImageData(blob);
	}

	async convertImageDataToImageElement(imageData: ImageData): Promise<HTMLImageElement> {

		//0 - create canvas element
		const canvas : HTMLCanvasElement = document.createElement('canvas');
		const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
		canvas.width  = imageData.width;
		canvas.height = imageData.height;
		context!.putImageData(imageData, 0, 0); 	// synchronous

		//2 - convert into blob
		const blob		 : Blob   = await this.canvasElementToBlob(canvas);
		const imageSource: string = URL.createObjectURL(blob);

		//1 - get image element
		canvas.remove();
		return this.createImageElement(imageSource);
	}


	/**------------------------------------------------------
	 * Convert: ImageElement <=> Blob
	 * ---------------------------
	 * > Convert 7mb : 0s
	 */
	convertImageElementToBlob(imageElement: HTMLImageElement): Promise<Blob> {

		//0 - create canvas and set 2d context
		const canvas : HTMLCanvasElement = document.createElement('canvas');
		const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
		if (!context) throw new Error(`ConvertImageHelper => convertImageElementToBlob => FATAL ERROR: failed to create context`);

		//1 - check context and draw image
		const { width, height } = imageElement;
		canvas.width  = width;
		canvas.height = height;
		context.drawImage(imageElement, 0, 0, width, height);

		//2 - get blob from canvas
		canvas.remove();
		return this.canvasElementToBlob(canvas);
	}

	convertBlobToImageElement(blob: Blob): Promise<HTMLImageElement> {
		const imageSource: string = URL.createObjectURL(blob);
		return this.createImageElement(imageSource);
	}


	/**------------------------------------------------------
	 * Convert: ImageElement <=> Base64
	 */
	convertImageElementToBase64(imageElement: HTMLImageElement, background?: string): Promise<string> {
		return new Promise((resolve: TypeResolve<string>, reject: TypeReject) => {

			//0 - on load return image element
			imageElement.onload = () => {

				//a. create canvas and set 2d context
				const canvas : HTMLCanvasElement = document.createElement('canvas');
				const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
				if (!context) throw new Error(`ConvertImageHelper => convertImageElementToBase64 => FATAL ERROR: failed to create context`);

				//b. check context and draw image
				const { width, height } = imageElement;
				canvas.width  = width;
				canvas.height = height;

				//c. set background color
				if (background && Util.Color.isHexColor(background)) context.fillStyle = background;

				//d. draw the image
				context.fillRect(0, 0, width, height);
				context.drawImage(imageElement, 0, 0, width, height);

				//e. get base64 from canvas
				const base64: string = canvas.toDataURL('image/png');
				canvas.remove();
				resolve(base64);
			};

			//1 - error handle
			imageElement.onabort = reject;
			imageElement.onerror = reject;
		});
	}


	/**------------------------------------------------------
	 * Convert: ImageUrl <=> Base64
	 */
	convertImageUrlToBase64(imageUrl: string, background?: string): Promise<string> {
		return new Promise(async (resolve: TypeResolve<string>, reject: TypeReject) => {

			//0 - get image element
			const imageElement: HTMLImageElement = document.createElement('img');
			imageElement.crossOrigin = 'anonymous';
			imageElement.src 		 = imageUrl;

			//1 - on load return image element
			const base64: string = await this.convertImageElementToBase64(imageElement, background);
			resolve(base64);

			//2 - error handle
			imageElement.onabort = reject;
			imageElement.onerror = reject;
		});
	}


	/**------------------------------------------------------
	 * Convert: Blob <=> Base64
	 */
	convertBlobToBase64(blob: Blob, background?: string): Promise<string> {
		return new Promise((resolve: TypeResolve<string>, reject: TypeReject) => {

			//0 - create file reader
			const reader: FileReader = new FileReader();

			//1 - on load return base64
			reader.onloadend = () => {
				const base64String: string = reader.result as string;
				resolve(base64String);
			};

			//2 - error handle
			reader.onerror = (error: ProgressEvent<FileReader>) => {
				reject(error);
			};

			//3 - create image element
			const canvas : HTMLCanvasElement = document.createElement('canvas');
			const context: CanvasRenderingContext2D | null = canvas.getContext('2d');

			//4 - on load return image element
			const imgElement: HTMLImageElement = new Image();
			imgElement.onload = () => {

				//a. set canvas size and draw image
				canvas.width  = imgElement.width;
				canvas.height = imgElement.height;

				//b. set background color
				if (context) {
					if (background) context.fillStyle = background;
					context.fillRect(0, 0, canvas.width, canvas.height);
					context.drawImage(imgElement, 0, 0);
				}

				//c. get base64 from canvas
				canvas.toBlob((canvasBlob: Blob | null) => {
					if (canvasBlob) reader.readAsDataURL(canvasBlob);
						else reject('Error converting canvas to Blob.');
				});
			};

			//5 - set image source
			imgElement.src = URL.createObjectURL(blob);
		});
	}


	/**------------------------------------------------------
	 * Convert to PNG
	 * convert 7mb : 0s
	 */
	async convertBlobToPng(blob: Blob): Promise<Blob> {

		//0 - get image source
		this.isValidImage(blob);
		const imageSource: string = URL.createObjectURL(blob);

		//1 - get image element
		const imageElement: HTMLImageElement = await this.createImageElement(imageSource);

		//2 - get png blob
		const imageBlob: Promise<Blob> = this.convertImageElementToBlob(imageElement);
		URL.revokeObjectURL(imageSource);
		return imageBlob;
	}


	/**------------------------------------------------------
	 * Convert Helpers (Convert to PNG)
	 */
	private createImageElement(source: string): Promise<HTMLImageElement> {
		return new Promise((resolve: TypeResolve<HTMLImageElement>, reject: TypeReject) => {

			//0 - get image element
			const imageElement: HTMLImageElement = document.createElement('img');
			imageElement.crossOrigin = 'anonymous';
			imageElement.src 		 = source;

			//1 - on load return image element
			imageElement.onload = (event: Event) => {
				resolve(imageElement);
			};

			//2 - error handle
			imageElement.onabort = reject;
			imageElement.onerror = reject;
		});
	}

	private async convertImageDataFromBlobData(blob: Blob): Promise<ImageData> {

		//0 - get image element
		const imageSource : string 			 = URL.createObjectURL(blob);
		const imageElement: HTMLImageElement = await this.createImageElement(imageSource);
		URL.revokeObjectURL(imageSource);

		//1 - draw image in context
		const canvas : HTMLCanvasElement = document.createElement('canvas');
		canvas.width = imageElement.width;
		canvas.height = imageElement.height;
		const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
		context!.drawImage(imageElement, 0, 0);

		//2 - get unit 8 array from array buffer
		const buffer: ArrayBufferLike   = context!.getImageData(0, 0, context!.canvas.width, context!.canvas.height).data.buffer;
		const array	: Uint8ClampedArray = new Uint8ClampedArray(buffer);
		const image	: ImageData 		= new ImageData(array, context!.canvas.width, context!.canvas.height);
		canvas.remove();
		return image;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getImageTypeDefinitions(): IFileTypeInfo[] {

		//0 - image type definitions
		const imageTypeDefinitions: IFileTypeInfo[] = [];
		for (const key in FILE_TYPE_INFO) {

			//a. skip non image types
			if (!this.ACCEPTABLE_IMAGE_CONSTANT.includes(key as EnumFileType)) continue;

			//b. add image types to the definitions
			imageTypeDefinitions.push(FILE_TYPE_INFO[key as EnumFileType]);
		}

		//1 - return all image type definitions
		return imageTypeDefinitions;
	}

	//** Check valid image */
	private isValidImage(blob: Blob): boolean {

		//0 - is the blob a valid image
		for (const imageDefinition of this.IMAGE_TYPE_DEFINITIONS) {
			if (imageDefinition.mimeType === blob.type) return true;
		}

		//1 - if it is not a valid image, throw an error
		throw new Error(`ConvertImageHelper => isValidImage => FATAL ERROR: the blob is invalid image type (blob type: "${blob.type}")`);
	}

	private canvasElementToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
		return new Promise((resolve: TypeResolve<Blob>, reject: TypeReject) => {
			canvas.toBlob((blob: Blob | null) => {

				//0 - was the conversion successful?
				if (!blob) {
					reject('Cannot get blob from image element');
					return;
				}

				//1 - if successful, resolve with the result
				resolve(blob);

			}, 'image/png', 1);
		});
	}

	private async convertBase64ToBlob(base64: string, type: string): Promise<Blob> {

		//0 - get base64 data
		const base64Data: string = base64;
		if (base64.includes(';base64,')) base64Data.substr(base64Data.indexOf(',') + 1);

		//1 - get blob from base64
		const base64Response: Response = await fetch(`data:${type};base64,${base64Data}`);
		const blob			: Blob 	   = await base64Response.blob();
		return blob;
	}
}
