import { WikipediaSearch } from './searches/lookup/wikipedia-search.helper';
import { GoogleImagesSearch } from './searches/lookup/google-images-search.helper';
import { GoogleSearch } from './searches/lookup/google-search.helper';
import { GoogleTrendsSearch } from './searches/lookup/google-trends-search/google-trends-search.helper';
import { InstagramSearch } from './searches/social-media/instagram/instagram-search.helper';
import { PinterestSearch } from './searches/social-media/pinterest/pinterest-search.helper';
import { TwitterSearch } from './searches/social-media/twitter/twitter-search.helper';
import { Social9GagSearch } from './searches/social-media/9gag/social-9gag-search.helper';
import { FreepikSearch } from './searches/stock-images/freepik/freepik-search.helper';
import { PexelsSearch } from './searches/stock-images/pexels/pexels-search.helper';
import { PixabaySearch } from './searches/stock-images/pixabay/pixabay-search.helper';
import { VexelsSearch } from './searches/stock-images/vexels/vexels-search.helper';
import { ShutterStockSearch } from './searches/stock-images/shutterstock/shutterstock-search.helper';
import { SpreadshirtSearch } from './searches/print-on-demand/spreadshirt/spreadshirt-search.helper';
import { Society6Search } from './searches/print-on-demand/society6/society6-search.helper';
import { ZazzleSearch } from './searches/print-on-demand/zazzle/zazzle-search.helper';
import { DisplateSearch } from './searches/print-on-demand/displate/displate-search.helper';
import { RedbubbleSearch } from './searches/print-on-demand/redbubble/redbubble-search.helper';
import { TeepublicSearch } from './searches/print-on-demand/teepublic/teepublic-search.helper';
import { AmazonSearch } from './searches/stores/amazon/amazon-search.helper';
import { EtsySearch } from './searches/stores/etsy/etsy-search.helper';


/**------------------------------------------------------
 * Website Search URL
 */
export class SearchLinks {

	constructor(

		// Lookup
		public readonly google			: GoogleSearch,
		public readonly googleImages	: GoogleImagesSearch,
		public readonly googleTrends	: GoogleTrendsSearch,
		public readonly wikipedia		: WikipediaSearch,

		// Print On Demand Platforms
		public readonly displate		: DisplateSearch,
		public readonly redbubble		: RedbubbleSearch,
		public readonly society6		: Society6Search,
		public readonly teepublic		: TeepublicSearch,
		public readonly zazzle			: ZazzleSearch,
		public readonly spreadshirt		: SpreadshirtSearch,

		// Social Media
		public readonly instagram		: InstagramSearch,
		public readonly pinterest		: PinterestSearch,
		public readonly twitter			: TwitterSearch,
		public readonly social9Gag		: Social9GagSearch,

		// Stock Images
		public readonly freepik			: FreepikSearch,
		public readonly pexels			: PexelsSearch,
		public readonly pixabay			: PixabaySearch,
		public readonly shutterstock	: ShutterStockSearch,
		public readonly vexels			: VexelsSearch,

		// Stores
		public readonly etsy			: EtsySearch,
		public readonly amazon			: AmazonSearch
	) {}
}
