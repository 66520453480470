import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Style Definitions
 * -----------------
 * > Style Cheatsheet: https://fontawesome.com/v6/docs/web/style/style-cheatsheet#sizing-icons
 */
export enum EnumNgFaStyle {
	Solid			= 'solid',
	Regular			= 'regular',
	Light			= 'light',
	Thin			= 'thin	',
	Duotone			= 'duotone',
	Brands			= 'brands'
}

export enum EnumNgFaSize {
	Size1em			= '1x',					// Changes an icon's font-size to 1em
	Size2em			= '2x',					// Changes an icon's font-size to 2em
	Size3em			= '3x',					// Changes an icon's font-size to 3em
	Size4em			= '4x',					// Changes an icon's font-size to 4em
	Size5em			= '5x',					// Changes an icon's font-size to 5em
	Size6em			= '6x',					// Changes an icon's font-size to 6em
	Size7em			= '7x',					// Changes an icon's font-size to 7em
	Size8em			= '8x',					// Changes an icon's font-size to 8em
	Size9em			= '9x',					// Changes an icon's font-size to 9em
	Size10em		= '10x',				// Changes an icon's font-size to 10em

	Size2XS			= '2xs',				// Changes an icon's font-size to 0.625em (~10px) and also vertically aligns icon
	SizeXS			= 'xs',					// Changes an icon's font-size to .75em (~12px) and also vertically aligns icon
	SizeSM			= 'sm',					// Changes an icon's font-size to 0.875em (~14px) and also vertically aligns icon
	SizeMD			= 'md',					// Changes an icon's font-size to 1em (~16px) and also vertically aligns icon
	SizeLG			= 'lg',					// Changes an icon's font-size to 1.25em (~120px) and also vertically aligns icon
	SizeXL			= 'xl',					// Changes an icon's font-size to 1.5em (~24px) and also vertically aligns icon
	Size2XL			= '2xl'					// Changes an icon's font-size to 2em (~32px) and also vertically aligns icon
}

export enum EnumNgFaRotating {
	Rotate0			= 'rotate-0',			// Rotates an icon 0°
	Rotate90		= 'rotate-90',			// Rotates an icon 90°
	Rotate180		= 'rotate-180',			// Rotates an icon 180°
	Rotate270		= 'rotate-270',			// Rotates an icon 270°
	MirrorHorizontal = 'flip-horizontal',	// Mirrors an icon vertically
	MirrorVertical	= 'flip-vertical',		// Mirrors an icon both vertically and horizontally
	MirrorBoth		= 'flip-both'			// Rotates an icon by a specific degree - setting an accompanying valid (opens new window)inline value for --fa-rotate-angle is required
}

export enum EnumNgFaAnimation {
	None			= 'none',				// Makes an icon spin 360° clock-wise
	SpinClockwise	= 'spin',				// Makes an icon spin 360° clock-wise
	SpinWithSteps	= 'spin-pulse',			// Makes an icon spin 360° clock-wise in 8 incremental steps
	SpinReverse		= 'spin-reverse',		// When used in conjunction with fa-spin or fa-spin-pulse, makes an icon spin counter-clockwise
	Beat			= 'beat',				// Makes an icon scale up and down
	Fade			= 'fade',				// Makes an icon visually fade in and out using opacity
	Flip			= 'flip	'				// Makes an icon rotate about the Y axis
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface INgFaIcon {
	style 	: EnumNgFaStyle;
	name 	: string;
}


/**------------------------------------------------------
 * Schemas
 */
export class NgFaIconSchema implements ValidatorSchema<INgFaIcon> {
	style 	= Validator.Enum(EnumNgFaStyle);
	name 	= Validator.String().isNotEmpty();
}
