import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Base Class for Extension Pattern
 */
export abstract class AbstractExtensionPattern<T> {

	//** Helper Variables */
	protected mainInstance: T = null!;

	//** Getter Helper */
	get main(): T {

		//0 - was the extended instance initialized?
		if (Util.Basic.isUndefined(this.mainInstance)) throw new Error(`AbstractExtensionBase => main => FATAL ERROR: mainInstance has not been initialized (call the initialized method right in the constructor)`);

		//1 - return the instance
		return this.mainInstance;
	}


	/**------------------------------------------------------
	 * Initialize the Base Class Helper
	 */
	initialize(instance: T): void {
		if (Util.Basic.isUndefined(instance)) throw new Error(`SliderValueUpdateExtension => initialize => FATAL ERROR: instance parameter can not be undefined or null`);
		this.mainInstance = instance;
	}
}
