import { BehaviorSubject, Observable } from 'rxjs';

import { ThemeService } from '../../helpers/theme/theme.service';
import { LOGO_LINKS } from '../../../../../assets';
import { IWebAppTool, EnumWebAppTool } from '../../../../shared/constants/app.constant';


/**------------------------------------------------------
 * App State Management
 */
export class AppStateHelper {

	//** Configurations */
	private readonly WEBAPP_TOOL_CONFIG: IWebAppToolConfig = {
		[EnumWebAppTool.Research] : {
			name		: 'Flying Research',
			tool		: EnumWebAppTool.Research,
			logo		: LOGO_LINKS.RESEARCH_LOGO,
			logoWide	: LOGO_LINKS.RESEARCH_LOGO_WIDE,
			toggleLogo	: LOGO_LINKS.UPLOAD_LOGO
		},
		[EnumWebAppTool.Upload] : {
			name		: 'Flying Upload',
			tool		: EnumWebAppTool.Upload,
			logo		: LOGO_LINKS.UPLOAD_LOGO,
			logoWide	: LOGO_LINKS.UPLOAD_LOGO_WIDE,
			toggleLogo	: LOGO_LINKS.RESEARCH_LOGO
		}
	};
	private readonly DEFAULT_TOOL_CONFIG: IWebAppTool = this.WEBAPP_TOOL_CONFIG[EnumWebAppTool.Research];
	private selectedApp$				: BehaviorSubject<IWebAppTool> = new BehaviorSubject<IWebAppTool>(this.DEFAULT_TOOL_CONFIG);


	constructor(
		private themeService: ThemeService
	) {
		this.setTheme(this.DEFAULT_TOOL_CONFIG.tool);
	}


	setSelectedApp(appId: EnumWebAppTool): void {

		//0 - find selected app info
		const selectedApp: IWebAppTool = this.WEBAPP_TOOL_CONFIG[appId];

		//1 - check if selected app found and update selected app
		if (!selectedApp) throw new Error(`AppStateHelper => setSelectedApp => FATAL ERROR: App info for provided app id was not found.`);
		this.setTheme(selectedApp.tool);
		this.selectedApp$.next(selectedApp);
	}

	getSelectedApp(): IWebAppTool {
		return this.selectedApp$.value;
	}

	getSelectedAppObservable(): Observable<IWebAppTool> {
		return this.selectedApp$.asObservable();
	}

	getAppConfig(tool: EnumWebAppTool): IWebAppTool {
		return this.WEBAPP_TOOL_CONFIG[tool];
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private setTheme(selectedApp: EnumWebAppTool) {
		if (selectedApp === EnumWebAppTool.Research) this.themeService.setResearchTheme();
			else this.themeService.setUploadTheme();
	}
}


//** Interfaces --------------------------------- */
interface IWebAppToolConfig {
	[EnumWebAppTool.Research] : IWebAppTool;
	[EnumWebAppTool.Upload]   : IWebAppTool;
}
