import { Util } from '@libs/utilities/util';

import { EnumMouseButton, MOUSE_CODES } from './data/mouse.data';
import { EnumKeyboardKey, EnumControlKey, KEY_CODES } from './data/keyboard.data';
import { IKeyEventAction, TypeKeyPressed, TypeListenerCallbackFn } from './keyboard-mouse.interface';


export class KeyboardMouseService {

	/**------------------------------------------------------
	 * Mouse Event Checks
	 */
	isMouseLeft			(mouseEvent: MouseEvent): boolean { return mouseEvent.button === MOUSE_CODES[EnumMouseButton.Left]; }
	isMouseWheel		(mouseEvent: MouseEvent): boolean { return mouseEvent.button === MOUSE_CODES[EnumMouseButton.Middle]; }
	isMouseRight		(mouseEvent: MouseEvent): boolean { return mouseEvent.button === MOUSE_CODES[EnumMouseButton.Right]; }

	onMouseLeft			(mouseEvent: MouseEvent, callback: Function, ...params: any[]): void { if (this.isMouseLeft(mouseEvent))  callback(...params); }
	onMouseRight		(mouseEvent: MouseEvent, callback: Function, ...params: any[]): void { if (this.isMouseRight(mouseEvent)) callback(...params); }
	onMouseWheel		(mouseEvent: MouseEvent, callback: Function, ...params: any[]): void { if (this.isMouseWheel(mouseEvent)) callback(...params); }


	/**------------------------------------------------------
	 * Keyboard Events
	 */
	isKeyPressed		(keyEvent: KeyboardEvent, keyboardKey: EnumKeyboardKey): boolean { return this.isDefinedKeyPresses(keyEvent, keyboardKey); }
	isKeyBackspace		(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Backspace); }
	isKeyEscape			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Escape); }
	isKeyShift			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ShiftLeft)   || this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ShiftRight); }
	isKeyControl		(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ControlLeft) || this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ControlRight); }
	isKeyTab			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Tab); }
	isKeyEnter			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Enter); }
	isKeySpace			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Space); }
	isKeyAlt			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.AltRight); }
	isKeyDelete			(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Delete); }
	isKeyArrowLeft		(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowLeft); }
	isKeyArrowRight		(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowRight); }
	isKeyArrowUp		(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowUp); }
	isKeyArrowDown		(keyEvent: KeyboardEvent): boolean { return this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowDown); }

	onKeyPressed		(keyEvent: KeyboardEvent, keyboardKey: EnumKeyboardKey, callback: Function, ...params: any[]): void { if (this.isKeyPressed(keyEvent, keyboardKey)) callback(...params); }
	onKeyBackspace		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyBackspace(keyEvent))  	callback(...params); }
	onKeyEscape			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyEscape(keyEvent)) 	 	callback(...params); }
	onKeyShift			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyShift(keyEvent)) 	 		callback(...params); }
	onKeyControl		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyControl(keyEvent)) 	 	callback(...params); }
	onKeyTab			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyTab(keyEvent)) 		 	callback(...params); }
	onKeyEnter			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyEnter(keyEvent)) 	 		callback(...params); }
	onKeySpace			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeySpace(keyEvent)) 	 		callback(...params); }
	onKeyAlt			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyAlt(keyEvent)) 		 	callback(...params); }
	onKeyDelete			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyDelete(keyEvent)) 	 	callback(...params); }
	onKeyArrowLeft		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyArrowLeft(keyEvent))  	callback(...params); }
	onKeyArrowRight		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyArrowRight(keyEvent)) 	callback(...params); }
	onKeyArrowUp		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyArrowUp(keyEvent)) 	 	callback(...params); }
	onKeyArrowDown		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isKeyArrowDown(keyEvent))  	callback(...params); }


	/**------------------------------------------------------
	 * Keyboard Shortcuts (Ctrl+C, ...)
	 */
	isShortcutCopy		(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.KeyC); }
	isShortcutCut		(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.KeyX); }
	isShortcutPaste		(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.KeyV); }
	isShortcutSelectAll	(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.KeyA); }

	onShortcutCopy		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutCopy(keyEvent)) 	 	callback(...params); }
	onShortcutCut		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutCut(keyEvent)) 		callback(...params); }
	onShortcutPaste		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutPaste(keyEvent)) 	callback(...params); }
	onShortcutSelectAll	(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutSelectAll(keyEvent)) callback(...params); }


	/**------------------------------------------------------
	 * Keyboard Shortcuts (others)
	 */
	isShortcutControlEnter		(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent)  && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Enter); }
	isShortcutControlBackspace	(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent)  && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Backspace); }
	isShortcutControlDelete		(keyEvent: KeyboardEvent): boolean { return this.isControlKeyPressed(keyEvent)  && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Delete); }
	isShortcutShiftDelete		(keyEvent: KeyboardEvent): boolean { return this.isShiftKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.Delete); }
	isShortcutShiftUp			(keyEvent: KeyboardEvent): boolean { return this.isShiftKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowUp); }
	isShortcutShiftDown			(keyEvent: KeyboardEvent): boolean { return this.isShiftKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowDown); }
	isShortcutShiftLeft			(keyEvent: KeyboardEvent): boolean { return this.isShiftKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowLeft); }
	isShortcutShiftRight		(keyEvent: KeyboardEvent): boolean { return this.isShiftKeyPressed(keyEvent) && this.isDefinedKeyPresses(keyEvent, EnumKeyboardKey.ArrowRight); }

	onShortcutControlEnter		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutControlEnter(keyEvent)) 		callback(...params); }
	onShortcutControlBackspace	(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutControlBackspace(keyEvent)) 	callback(...params); }
	onShortcutControlDelete		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutControlDelete(keyEvent)) 	callback(...params); }
	onShortcutShiftDelete		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutShiftDelete(keyEvent)) 	 	callback(...params); }
	onShortcutShiftUp			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutShiftUp(keyEvent)) 			callback(...params); }
	onShortcutShiftDown			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutShiftDown(keyEvent)) 		callback(...params); }
	onShortcutShiftLeft			(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutShiftLeft(keyEvent)) 		callback(...params); }
	onShortcutShiftRight		(keyEvent: KeyboardEvent, callback: Function, ...params: any[]): void { if (this.isShortcutShiftRight(keyEvent)) 		callback(...params); }


	/**------------------------------------------------------
	 * Control Down & Keyboard Event
	 */
	isControlWithKeyPressed		(keyEvent: KeyboardEvent, key: EnumKeyboardKey): boolean { return this.isControlKeyPressed(keyEvent) && this.isKeyPressed(keyEvent, key); }
	isAltWithKeyPressed			(keyEvent: KeyboardEvent, key: EnumKeyboardKey): boolean { return this.isAltKeyPressed(keyEvent)  && this.isKeyPressed(keyEvent, key); }

	onControlWithKeyPressed		(keyEvent: KeyboardEvent, keyboardKey: EnumKeyboardKey, callback: Function, ...params: any[]): void { if (this.isControlKeyPressed(keyEvent) && this.isKeyPressed(keyEvent, keyboardKey)) callback(...params); }
	onAltWithKeyPressed			(keyEvent: KeyboardEvent, keyboardKey: EnumKeyboardKey, callback: Function, ...params: any[]): void { if (this.isAltKeyPressed(keyEvent)  && this.isKeyPressed(keyEvent, keyboardKey)) callback(...params); }


	/**------------------------------------------------------
	 * Performance Helpers
	 */
	isAnyControlKeyPressed		(keyEvent: KeyboardEvent | PointerEvent | MouseEvent): boolean {
		return this.isControlKeyPressed(keyEvent) || this.isAltKeyPressed(keyEvent) || this.isShiftKeyPressed(keyEvent);
	}


	/**------------------------------------------------------
	 * Control Down & Mouse Event
	 */
	isKeyControlAndMouseLeftClick	(mouseEvent: PointerEvent): boolean { return this.isMouseLeft(mouseEvent) && this.isControlKeyPressed(mouseEvent); }
	isKeyAltAndMouseLeftClick		(mouseEvent: PointerEvent): boolean { return this.isMouseLeft(mouseEvent) && this.isAltKeyPressed(mouseEvent); }
	isShiftAndMouseLeftClick		(mouseEvent: PointerEvent): boolean { return this.isMouseLeft(mouseEvent) && this.isShiftKeyPressed(mouseEvent); }

	onKeyControlAndMouseLeftClick	(mouseEvent: PointerEvent, callback: Function, ...params: any[]): void { if (this.isKeyControlAndMouseLeftClick(mouseEvent))  callback(...params); }
	onKeyAltAndMouseLeftClick		(mouseEvent: PointerEvent, callback: Function, ...params: any[]): void { if (this.isKeyAltAndMouseLeftClick(mouseEvent)) 	  	callback(...params); }
	onShiftAndMouseLeftClick		(mouseEvent: PointerEvent, callback: Function, ...params: any[]): void { if (this.isShiftAndMouseLeftClick(mouseEvent)) 	  	callback(...params); }


	/**------------------------------------------------------
	 * Custom Combined Shortcut Check
	 */
	isKeyCombinationPressed			(keyEvent: KeyboardEvent, keyboardKey: EnumKeyboardKey, controlKeys: EnumControlKey[]): boolean {
		return this.areControlKeysPressed(keyEvent, controlKeys) && this.isKeyPressed(keyEvent, keyboardKey);
	}


	/**------------------------------------------------------
	 * Keyboard Event Listeners
	 */
	keyEventListener(keyEvent: KeyboardEvent, keyListener: IKeyEventAction, callback: TypeListenerCallbackFn): void {

		//0 - validate key listener
		if (Util.Basic.isUndefined(keyListener.controlKeys)) keyListener.controlKeys = [];
		this.validateKeyListener(keyListener);

		//1 - check if keys are matching
		if (!this.isKeyCombinationPressed(keyEvent, keyListener.keyboardKey, keyListener.controlKeys)) return;

		//3 - trigger the callback function
		callback(keyEvent, keyListener);
	}

	keyEventListeners(keyEvent: KeyboardEvent, listeners: IKeyEventAction[], callback: TypeListenerCallbackFn): void {
		for (const keyListener of listeners) {
			this.keyEventListener(keyEvent, keyListener, callback);
		}
	}


	/**------------------------------------------------------
	 * Advanced Input Field Guard
	 */
	isKeyInputValid(keyEvent: KeyboardEvent, allowedFormat: string[]): boolean {

		//0 - check if key is backspace or space
		if (keyEvent.code === KEY_CODES[EnumKeyboardKey.Backspace].code || keyEvent.code === KEY_CODES[EnumKeyboardKey.Space].code) return true;

		//1 - uppercase, lowercase, and number character validation
		const key: string = keyEvent.key;
		if (/[A-Z]/.test(key) && allowedFormat.includes(Util.Password.PasswordType.CharUppercase)) return true;
		if (/[a-z]/.test(key) && allowedFormat.includes(Util.Password.PasswordType.CharLowercase)) return true;
		if (/[0-9]/.test(key) && allowedFormat.includes(Util.Password.PasswordType.Number)) 	   return true;

		return false;
	}


	/**------------------------------------------------------
	 * Check Windows & Mac Controlling Keys
	 * ------------------------------------
	 * Windows:
	 * > Alt, Ctrl, Shift
	 *
	 * MacOS / Linux:
	 * > Option   >> mapped to >>	 Alt
	 * > Ctrl	  >> mapped to >>	 Ctrl
	 * > Command  >> mapped to >>	 Ctrl
	 * > Shift 	  >> mapped to >>	 Shift
	 */
	private isControlKeyPressed(keyEvent: TypeKeyPressed): boolean {
		return keyEvent.ctrlKey || keyEvent.metaKey;
	}
	private isAltKeyPressed(keyEvent: TypeKeyPressed): boolean {
		return keyEvent.altKey;
	}
	private isShiftKeyPressed(keyEvent: TypeKeyPressed): boolean {
		return keyEvent.shiftKey;
	}

	private areControlKeysPressed(keyEvent: KeyboardEvent, controlKeys: EnumControlKey[]): boolean {

		//0 - if control, alt, or shift were required, were they it pressed?
		if (controlKeys.includes(EnumControlKey.Control) && !this.isControlKeyPressed(keyEvent)) return false;
		if (controlKeys.includes(EnumControlKey.Alt) 	 && !this.isAltKeyPressed(keyEvent)) 	 return false;
		if (controlKeys.includes(EnumControlKey.Shift) 	 && !this.isShiftKeyPressed(keyEvent)) 	 return false;

		//1 - all required control keys were pressed
		return true;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private validateKeyListener(keyListener: IKeyEventAction) {

		//0 - general validation
		if (Util.Basic.isUndefined(keyListener)) throw new Error(`KeyboardMouseService => validateKeyListener => FATAL ERROR: keyListener is required`);
		if (!Util.Enum.isValid(EnumKeyboardKey, keyListener.keyboardKey)) throw new Error(`KeyboardMouseService => validateKeyListener => FATAL ERROR: keyboardKey with value of "${keyListener.keyboardKey}" is invalid (valid are: "${Util.Enum.values(EnumKeyboardKey)}")`);

		//1 - check control keys
		const controlKeys: EnumControlKey[] = Util.Array.asArray(keyListener.controlKeys);
		if (!Util.Enum.areValid(EnumControlKey, controlKeys)) throw new Error(`KeyboardMouseService => validateKeyListener => FATAL ERROR: controlKeys with values of "${keyListener.controlKeys}" are invalid (valid are: "${Util.Enum.values(EnumControlKey)}")`);
		if (controlKeys.length > 2) throw new Error(`KeyboardMouseService => validateKeyListener => FATAL ERROR: controlKeys should not be greater that 2 (provided controlKeys "${controlKeys}")`);
	}

	private isDefinedKeyPresses(keyEvent: KeyboardEvent, keyboardKey: EnumKeyboardKey) {

		//0 - is it a valid key event
		if (Util.Basic.isUndefined(keyEvent?.code) || Util.Basic.isUndefined(keyEvent?.key)) return false;

		//1 - special case of numpad
		if (keyEvent.code.includes('Numpad') && keyEvent.key === KEY_CODES[keyboardKey].keyString) return true;

		//2 - check the default case (easyString to be independent of country specific)
		// > Note: check with "key" or "keyCode" even it is deprecated, as "code" is screwed up in international keyboards,
		// > there "Y" and "Z" for example as swapped.
		// > When multiple keyboard buttons are pressed at onces, then "Dead" is given back
		return (keyEvent.key === KEY_CODES[keyboardKey].keyString) || (keyEvent.key === 'Dead' && !this.isAltKeyPressed(keyEvent));
	}
}
