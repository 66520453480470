import { enable as enableDarkMode, disable as disableDarkMode, DynamicThemeFix, setFetchMethod } from 'darkreader';
import { DeviceDetectionService } from '@libs/libraries/frontend';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Util } from '@libs/utilities/util';
import { EnumSystemTheme } from '@libs/constants';

import { EnumAddonDarkMode, IAddonDarkModeConfig } from './dark-mode.interface';
import { AddonDarkModeStorageHandler } from './dark-mode-storage.handler';
import { EnumAddonPageAddonInjection } from '../../shared/page-addons.interface';


@Injectable()
export class AddonDarkModeService {

	//** Helper Variables */
	private darkMode$: BehaviorSubject<EnumAddonDarkMode> = new BehaviorSubject<EnumAddonDarkMode>(EnumAddonDarkMode.Light);
	isDarkMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


	constructor(
		@Inject(EnumAddonPageAddonInjection.DarkModeConfig)
		private config					: IAddonDarkModeConfig,
		private darkModeStorageHandler	: AddonDarkModeStorageHandler,
		private deviceDetectionService	: DeviceDetectionService
	) {
		//0 - get the saved mode value
		const savedMode: EnumAddonDarkMode | null = this.darkModeStorageHandler.getDarkMode();
		if (!savedMode) return;

		//1 - set it as initial value
		this.darkMode$ = new BehaviorSubject<EnumAddonDarkMode>(savedMode);
		this.darkMode$.subscribe(() => {
			this.isDarkMode$.next(this.isDarkMode());
		});

		//2 - set the fetch
		setFetchMethod(window.fetch);
	}


	/**------------------------------------------------------
	 * Getter / State Helpers
	 */
	getActiveMode(): EnumAddonDarkMode {
		return this.darkMode$.getValue();
	}
	isLightMode(): boolean {
		return this.getActiveMode() === EnumAddonDarkMode.Light;
	}
	isDarkMode(): boolean {
		return !this.isLightMode();
	}


	/**------------------------------------------------------
	 * Subscribers
	 */
	onModeChange(callback: (mode: EnumAddonDarkMode) => void): AddonDarkModeService {
		this.darkMode$.subscribe((mode: EnumAddonDarkMode) => {
			callback(mode);
		});
		return this;
	}


	/**------------------------------------------------------
	 * Set Dark Mode
	 */
	setMode(mode: EnumAddonDarkMode): void {

		//0 - dark mode control for the library
		this.setModeInDarkReader(mode);

		//1 - set in active theme in the local storage
		this.darkModeStorageHandler.setDarkMode(mode);
		this.darkMode$.next(mode);
	}


	/**------------------------------------------------------
	 * System theme helper
	 */
	applySystemTheme(): void {

		//0 - check if system theme enabled
		if (!this.isAutoSystemThemeEnabled()) return;

		//1 - set the system theme
		if (this.deviceDetectionService.getSystemTheme() === EnumSystemTheme.Dark) this.setMode(EnumAddonDarkMode.Dark);
		else this.setMode(EnumAddonDarkMode.Light);
	}

	resetAndDisableSystemTheme(): void {
		this.setMode(EnumAddonDarkMode.Light);
		this.disableAutoSystemTheme();
	}

	setCustomMode(mode: EnumAddonDarkMode): void {
		this.disableAutoSystemTheme();
		this.setMode(mode);
	}

	isAutoSystemThemeEnabled(): boolean {
		return this.darkModeStorageHandler.getAutoSystemTheme();
	}

	isSystemThemeSet(): boolean {

		//0 - fetch the current active mode and theme
		const systemTheme: EnumSystemTheme = this.deviceDetectionService.getSystemTheme();
		const activeMode : EnumAddonDarkMode    = this.getActiveMode();

		//1 - check if the system theme is covered by the current mode
		const isSystemThemeSet: boolean = (systemTheme === EnumSystemTheme.Unknown)
			|| (systemTheme === EnumSystemTheme.Light && activeMode === EnumAddonDarkMode.Light)
			|| (systemTheme === EnumSystemTheme.Dark  && activeMode === EnumAddonDarkMode.Dark)
			|| (systemTheme === EnumSystemTheme.Dark  && activeMode === EnumAddonDarkMode.DimmedDark);

		return isSystemThemeSet;
	}

	private disableAutoSystemTheme() {
		this.darkModeStorageHandler.setAutoSystemTheme(false);
	}


	/**------------------------------------------------------
	 * Dark Mode Control for the Library
	 */
	private setModeInDarkReader(mode: EnumAddonDarkMode) {

		//0 - create the dynamic fixes
		const dynamicFixes: DynamicThemeFix = {
			css						: this.config.CSS,
			ignoreInlineStyle		: this.config.IGNORE_INLINE_STYLE,
			ignoreImageAnalysis		: this.config.IGNORE_IMAGES,
			disableStyleSheetsProxy	: false,
			invert					: this.config.INVERT
		};

		//1 - set the dark mode
		switch (mode) {
			case EnumAddonDarkMode.Light:
				disableDarkMode();
				break;

			case EnumAddonDarkMode.Dark:
				enableDarkMode({
					brightness	: 100,
					contrast	: 100,
					sepia		: 10
				}, dynamicFixes);
				break;

			case EnumAddonDarkMode.DimmedDark:
				enableDarkMode({
					brightness	: 95,
					contrast	: 85
				}, dynamicFixes);
				break;

			default:
				throw new Error(`DarkModeService => setModeInDarkReader => FATAL ERROR: the mode of "${mode}" is not supported (supported values: "${Util.Enum.values(EnumAddonDarkMode)}")`);
		}

		//2 - clear the console (to hide the errors for dark modes)
		if (mode !== EnumAddonDarkMode.Light) {
			setTimeout(() => Util.Log.clear(), 2000);
		}
	}
}
