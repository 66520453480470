import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Util } from '@libs/utilities/util';

import { AddonPageAddonsRenderer } from '../../shared/page-addons-renderer.helper';
import { TypeAddonWindowPaddle, TypeAddonSetupCallback } from './paddle.interface';
import { EnumAddonPageAddonInjection, IAddonAddOnScriptScript } from '../../shared/page-addons.interface';


@Injectable()
export class AddonPaddleScript implements IAddonAddOnScriptScript {

	//** Window object to access the script functions */
	private readonly window: TypeAddonWindowPaddle;

	constructor(
		@Inject(EnumAddonPageAddonInjection.PaddleVendorId)
		private vendorId	: string,
		@Inject(DOCUMENT)
		private document	: Document,
		private renderHelper: AddonPageAddonsRenderer
	) {
		this.window = this.document.defaultView as TypeAddonWindowPaddle;
	}

	create(): AddonPaddleScript {

		//0 - check if the FACEBOOK_PIXEL_ID was configured
		if (Util.String.isEmpty(this.vendorId)) throw new Error(`AddonPaddleScript => create => FATAL ERROR: Paddle Vendor ID was not provided in the module (value is: "${this.vendorId}")`);

		//1 - setup the paddle script
		const paddleSetupCallback: TypeAddonSetupCallback = () => {
			this.window.Paddle.Setup({ vendor: Util.Number.toNumber(this.vendorId) });
		};

		//2 - add paddle script
		const script1Attributes: IAddonScript1Attributes = {
			src		: 'https://cdn.paddle.com/paddle/paddle.js',
			async	: false
		};
		this.renderHelper.addScriptToHead('paddle', { attributes: script1Attributes })
			.onload = paddleSetupCallback;

		return this;
	}
}


//** Interfaces --------------------------------- */
interface IAddonScript1Attributes {
	src		: string;
	async	: boolean;
}
