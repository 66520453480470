/**------------------------------------------------------
 * Seller Trends constants
 */
export enum EnumTrendTimePeriod {
	OneWeek 		= '1W',
	OneMonth 		= '1M',
	ThreeMonth 		= '3M'
}

export enum EnumTrendTimeField {
	OneWeek 		= 'trend_1w',
	OneMonth 		= 'trend_1m',
	ThreeMonth 		= 'trend_3m'
}

export enum EnumTrendType {
	All				= 'all_trends',
	Winner 			= 'winners',
	Loser 			= 'losers',
	Defender 		= 'defenders',
	Newcomer 		= 'newcomers'
}

export enum EnumTrendSorting {
	TrendWinnerPer	= 'trend',
	TrendWinnerBsr	= 'trend_rank',
	TrendLoserPer	= 'trend_desc',
	TrendLoserBsr	= 'trend_rank_desc',
	Bsr				= 'bsr',
	Newest			= 'newest',
	Oldest			= 'oldest',
	Random			= 'random'
}
