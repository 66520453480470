import { EnumRedbubbleProduct, EnumRedbubbleSorting } from '../../../../../constants/research';
import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { IRedbubbleSearchOptions, REDBUBBLE_CONFIG, REDBUBBLE_DEFAULT_OPTIONS } from './redbubble-search.data';


/**------------------------------------------------------
 * Redbubble Search Links
 */
export class RedbubbleSearch extends AbstractSearchLinks<IRedbubbleSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumRedbubbleSorting>[] {
		return REDBUBBLE_CONFIG.sortings as ISearchParameterConfig<EnumRedbubbleSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumRedbubbleProduct>[] {
		return REDBUBBLE_CONFIG.products as ISearchParameterConfig<EnumRedbubbleProduct>[];
	}

	constructor() {
		super(REDBUBBLE_CONFIG, REDBUBBLE_DEFAULT_OPTIONS);
	}
}
