import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumDisplateSorting {
	Bestselling		= 'bestselling',		// default value
	MostRelevant	= 'most_relevant',
	Newest			= 'newest'
}

export const DISPLATE_SORTING_LIST: ISearchParameterConfig<EnumDisplateSorting>[] = [
	{
		code		: EnumDisplateSorting.Bestselling,
		name		: 'Bestselling',
		parameters	: { sort: 'bestselling' }
	},
	{
		code		: EnumDisplateSorting.MostRelevant,
		name		: 'Most Relevant',
		parameters	: { sort: 'relevance' }
	},
	{
		code		: EnumDisplateSorting.Newest,
		name		: 'Newest',
		parameters	: { sort: 'newest' }
	}
];


/**------------------------------------------------------
 * Displate Configuration
 */
export const DISPLATE_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://displate.com',
		login		: 'https://displate.com/auth/signin',
		search		: 'https://displate.com/sr-artworks/[SEARCH]/[PAGE]'
	},
	pageLimit		: 157,
	sortings		: DISPLATE_SORTING_LIST,
	products		: [],
	category	    : [],
	options 		: {
		emptySearch : false
	}
};


/**------------------------------------------------------
 * Displate Search Options
 */
export const DISPLATE_DEFAULT_OPTIONS: IDisplateSearchOptions = {
	page			: 1,
	sorting			: EnumDisplateSorting.Bestselling
};

export interface IDisplateSearchOptions {
	page			: number;
	sorting			: EnumDisplateSorting;
}
