import { read, utils as xlsxUtils, WorkBook, WorkSheet } from 'xlsx';
import { Util } from '@libs/utilities/util';

import { ExcelReaderHelper, IImportedSheetInfo } from '../excel-reader.helper';
import { IImportFileInfo, IImportSheetInfo } from '../excel-import.interface';


export class ExcelFormulaeReaderHelper {

	constructor(
		private excelReaderHelper: ExcelReaderHelper
	) {}

	/**------------------------------------------------------
	 * Import Excel File as Formulae
	 */
	async importExcelFileAsFormulae(file: File): Promise<IImportExcelFormulaeData | null> {

		//0 - check is valid excel file
		if (!this.excelReaderHelper.isAcceptedExcelType(file)) return null;

		//1 - try to read the sheet data
		const fileBuffer: ArrayBuffer | null = await this.excelReaderHelper.readFile(file);
		if (!fileBuffer) return null;

		//2 - read the spreadsheet data with library
		const importSheetFormulaeData: IImportSheetFormulaeData[] | null = this.bufferToExcelFormulaeData(fileBuffer);
		if (!importSheetFormulaeData) return null;

		//3 - create the excel sheet data
		const excelFormulaeData: IImportExcelFormulaeData = {
			sheets: importSheetFormulaeData,
			metaInfo: {
				file: this.excelReaderHelper.getFileInfo(file)
			}
		};

		//4 - return the excel formatted data
		return excelFormulaeData;
	}


	/**------------------------------------------------------
	 * Import Excel Sheet (Get sheet info from buffer)
	 */
	private bufferToExcelFormulaeData(fileBuffer: ArrayBuffer): IImportSheetFormulaeData[] | null {

		try {
			//0 - initialize sheet and importSheetsData
			const importSheetsData: IImportSheetFormulaeData[] 	= [];
			const workBook		  : WorkBook 					= read(fileBuffer);

			//1 - grab sheet
			const sheetNames: string[] = workBook.SheetNames;
			for (const sheetName of sheetNames) {

				//a. get worksheet and json object
				const workSheet: WorkSheet = workBook.Sheets[sheetName];

				//b. get sheet json info and get imported sheet as Formulae
				const workSheetData		: object[] 			 = xlsxUtils.sheet_to_json(workSheet);
				const formulaeData		: string[] 			 = xlsxUtils.sheet_to_formulae(workSheet);
				const importedSheetInfo	: IImportedSheetInfo = this.excelReaderHelper.importedSheetInfo(workSheetData);

				//c. get imported sheet data object
				const importSheetData: IImportSheetFormulaeData = {
					formulaeData: formulaeData,
					metaInfo	: {
						sheet	: {
							sheetName	 : sheetName,
							columnCount  : importedSheetInfo.columnCount,
							rowCount	 : importedSheetInfo.rows.length
						}
					}
				};

				//d. update sheets data
				importSheetsData.push(importSheetData);
			}

			//2 - return the result
			return importSheetsData;

		} catch (error: unknown) {
			console.error(`ExcelFormulaeReaderHelper => bufferToExcelFormulaeData => ERROR: ${Util.Basic.stringifyObject(error)}`);
			return null;
		}
	}
}


//** Interfaces --------------------------------- */
export interface IImportExcelFormulaeData {
	sheets		: IImportSheetFormulaeData[];
	metaInfo	: {
		file	: IImportFileInfo;
	};
}

export interface IImportSheetFormulaeData {
	formulaeData: string[];
	metaInfo	: {
		sheet	: IImportSheetInfo;
	};
}
