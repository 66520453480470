import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { REGEX_VALIDATORS } from '@libs/constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


/**------------------------------------------------------
 * Transform Href
 * --------------
 * Transform href into RouterLink
 *
 * Theoretical Example:
 * If we need to pass link in innerHtml in this case we
 * cannot router url in inner html. This directive will
 * convert href to RouterLink so, that project will not
 * reload on click of dropdown menu then then directive
 * is useful.
 *
 * Example:
 * --------
 * 	<div [innerHtml]="htmlDesc" ngRouteTransformer></div>
 */
@Directive({
	selector: '[ngRouteTransformer]'
})
export class NgRouteTransformerDirective {

	constructor(
		private router			: Router,
		private ngbActiveModal	: NgbActiveModal
	) {}

	@HostListener('click', ['$event'])
	public onClick(event: Event): void {
		const targetUrl: string = (event.target as HTMLElement).getAttribute('href')!;

		//0 - check for wrong values
		const excludes: string[] = ['', 'javascript:void(0)', 'void(0)', 'unsafe:javascript:void(0)', 'unsafe:void(0)'];
		if (excludes.includes(targetUrl)) return;

		//1 - check if target url is another website?
		const target	: HTMLAnchorElement = event.target as HTMLAnchorElement;
		const isUrlValid: boolean = REGEX_VALIDATORS.MATCH_URL.regex.test(targetUrl);
		if (target.tagName !== 'A' || target?.target === '_blank' || isUrlValid) return;

		//2 - rout on our website
		this.ngbActiveModal.close();
		this.router.navigateByUrl(targetUrl);
		event.preventDefault();
	}
}
