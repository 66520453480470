import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';
import { EnumNgFaStyle } from '@libs/frameworks/angular';

import { EnumSidebarPage } from '../../sidebar-pages.interface';


/**------------------------------------------------------
 * Print On Demand
 */
export const POD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadPodStatistics,
		label		: 'Statistics',
		routePath	: '/upload/pod/statistics',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'square-poll-vertical'
		}
	},
	{
		id 			: EnumSidebarPage.UploadPodDesignManagement,
		label		: 'Designs',
		routePath	: '/upload/pod/design-management',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'diamond-half-stroke'
		}
	},
	{
		id 			: EnumSidebarPage.UploadPodEdit,
		label		: 'Edit',
		info		: 'AI Powered',
		routePath	: '/upload/pod/edit',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'compass-drafting'
		}
	},
	{
		id 			: EnumSidebarPage.UploadAiTemplates,
		label		: 'AI Templates',
		info		: 'AI Powered',
		routePath	: '/upload/pod/ai-templates',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'object-ungroup'
		}
	}
	/* {
		id 			: EnumSidebarPage.UploadPodMbaManager,
		label		: 'MBA Manager',
		routePath	: '',
			icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadPodProfileManager,
		label		: 'Profile Manager',
		routePath	: '',
			icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadPodSuggestionManager,
		label		: 'Suggestion Manager',
		routePath	: '',
			icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		label		: '_Test',
		routePath	: '/upload/pod/test',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	}, */
];


/**------------------------------------------------------
 * Kindle Direct Publishing
 */
export const KDP_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadKdpEditUpload,
		label		: 'Edit & Upload',
		routePath	: '/upload/kdp/edit-and-upload',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'swatchbook'
		}
	}
	/* {
		id 			: EnumSidebarPage.UploadKdpSalesStatistics,
		label		: 'Sales & Statistics',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadKdpBooksDesigns,
		label		: 'Books & Designs',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadKdpManuscripts,
		label		: 'Manuscripts',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadKdpEdit,
		label		: 'Edit',
		routePath	: '/upload/kdp/edit',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadKdpProfileManager,
		label		: 'Profile Manager',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadKdpSuggestionManager,
		label		: 'Suggestion Manager',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadKdpBookGenerator,
		label		: 'Book Generator',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	}, */
];


/**------------------------------------------------------
 * Upload Tool / Magic Uploader
 */
export const UPLOAD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadSchedule,
		label		: 'Upload Schedule',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadAccountsTemplates,
		label		: 'Accounts & Templates',
		routePath	: '/upload/upload/accounts-templates',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadSetupPOD,
		label		: 'Setup POD Uploader',
		routePath	: '/upload/upload/setup-pod-uploader',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'square-sliders-vertical'
		}
	},
	{
		id 			: EnumSidebarPage.UploadSetupKDP,
		label		: 'Setup KDP Uploader',
		routePath	: '/upload/upload/setup-kdp-uploader',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'square-sliders'
		}
	}
	/*
	{
		id 			: EnumSidebarPage.UploadTemplateGroups,
		label		: 'Template Groups',
		routePath	: '/upload/upload/template-groups',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	} */
];


/**------------------------------------------------------
 * Trademarks Upload
 */
export const TM_UPLOAD_POD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		label		: 'TM Report',
		id 			: EnumSidebarPage.UploadPodTmReport,
		routePath	: '/upload/pod/tm-report',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'notes'
		}
	},
	{
		id 			: EnumSidebarPage.UploadPodTmWhitelist,
		label		: 'TM Whitelist',
		routePath	: '/upload/pod/tm-whitelist',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'lock-keyhole-open'
		}
	},
	{
		id 			: EnumSidebarPage.UploadPodTmBlacklist,
		label		: 'TM Blacklist',
		routePath	: '/upload/pod/tm-blacklist',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'ban'
		}
	}
];


/**------------------------------------------------------
 * Advertise & Promote
 */
/* const ADVERTISE_UPLOAD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadAdvertiseAmazonDsp,
		label		: 'Amazon DSP',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadAdvertiseSocialUploader,
		label		: 'Social Uploader',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
]; */


/**------------------------------------------------------
 * Downloads & Image Bundles
 */
/* const DOWNLOADS_UPLOAD_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.UploadDownloadsResourceBundles,
		label		: 'Resource Bundles',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.UploadDownloadsDesignsVectors,
		label		: 'Designs / Vectors',
		routePath	: '',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
]; */
