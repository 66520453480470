import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_DE: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'de_DE'
		}
	},
	{
		// https://www.amazon.de/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&i=clothing&bbn=77028031&rh=p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF&dc&hidden-keywords=%22Unifarben%3A+100%25+Baumwolle%3B+Grau+meliert%22+Alle+anderen+melierten+Farben&__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing',
			bbn			: '77028031',
			rh			: 'p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF', // cspell:disable-line
			'hidden-keywords': '%22Unifarben%3A+100%25+Baumwolle%3B+Grau+meliert%22+Alle+anderen+melierten+Farben'
		}
	},
	/* {
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameter 	: {
			language	: 'de_DE',
		},
	}, */
	{
		// https://www.amazon.de/s?k=dog&i=clothing&bbn=77028031&rh=p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF&dc&hidden-keywords=leichtes%2C+klassisch+geschnittenes+T-Shirt%2C+doppelt+gen%C3%A4hte+%C3%84rmel+und+Saumabschluss+%22v-ausschnitt%22&__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing',
			bbn			: '77028031',
			rh			: 'p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF', // cspell:disable-line
			'hidden-keywords': 'leichtes%2C+klassisch+geschnittenes+T-Shirt%2C+doppelt+gen%C3%A4hte+%C3%84rmel+und+Saumabschluss+%22v-ausschnitt%22' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.de/s?k=dog&i=clothing&bbn=77028031&rh=p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF&dc&hidden-keywords=leichtes%2C+klassisch+geschnittenes+Tank+Top%2C+doppelt+gen%C3%A4hte+%C3%84rmel+und+Saumabschluss&__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&tag=merchresearch-21
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing',
			bbn			: '77028031',
			rh			: 'p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF', // cspell:disable-line
			'hidden-keywords': 'leichtes%2C+klassisch+geschnittenes+Tank+Top%2C+doppelt+gen%C3%A4hte+%C3%84rmel+und+Saumabschluss' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.de/s?k=dog+Langarmshirt&i=clothing&bbn=77028031&rh=p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF&dc&hidden-keywords=%22Unifarben%3A+100%25+Baumwolle%3B+Grau+meliert%3A+90%25+Baumwolle%2C+10%25+Polyester&tag=merchresearch-21
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing',
			bbn			: '77028031',
			rh			: 'p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF', // cspell:disable-line
			'hidden-keywords': '%22Unifarben%3A+100%25+Baumwolle%3B+Grau+meliert%3A+90%25+Baumwolle%2C+10%25+Polyester'
		},
		queryOptions: {
			postfix		: '+Langarmshirt',
			prefix		: ''
		}
	},
	{
		// https://www.amazon.de/s?k=dog+raglon&i=clothing
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing'
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+raglon' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.de/s?k=dog&i=clothing&bbn=77028031&rh=p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF&dc&hidden-keywords=%22Unifarben%3A+80%25+Baumwolle%2C+20%25+Polyester%3B+Hellgrau+meliert%3A+78%25+Baumwolle%2C+22%25+Polyester%22+%25Sweatshirt%22+Klassisch+geschnitten&tag=merchresearch-21
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing',
			bbn			: '77028031',
			rh			: 'p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF', // cspell:disable-line
			'hidden-keywords': '%22Unifarben%3A+80%25+Baumwolle%2C+20%25+Polyester%3B+Hellgrau+meliert%3A+78%25+Baumwolle%2C+22%25+Polyester%22+%25Sweatshirt%22+Klassisch+geschnitten'
		}
	},
	{
		// https://www.amazon.de/s?k=dog&i=clothing&bbn=77028031&rh=p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF&dc&hidden-keywords=%22Unifarben%3A+80%25+Baumwolle%2C+20%25+Polyester%3B+Hellgrau+meliert%3A+78%25+Baumwolle%22+%22hoodie%22&language=de_DE
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			language	: 'de_DE',
			i			: 'clothing',
			bbn			: '77028031',
			rh			: 'p_76%3A419122031%2Cp_6%3AA3JWKAKR8XB7XF', // cspell:disable-line
			'hidden-keywords': '%22Unifarben%3A+80%25+Baumwolle%2C+20%25+Polyester%3B+Hellgrau+meliert%3A+78%25+Baumwolle%22+%22hoodie%22'
		}
	},
	{
		// https://www.amazon.de/s?k=dog+PopSockets+Ausziehbarer+Sockel+und+Griff&tag=merchresearch-21
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			language	: 'de_DE'
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+PopSockets+Ausziehbarer+Sockel+und+Griff'
		}
	},
	/* {
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameter 	: {
			language	: 'de_DE',
		},
	},
	{
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameter 	: {
			language	: 'de_DE',
		},
	},
	{
		code		: EnumAmazonProduct.ThrowPillows,
		name		: 'Throw Pillows',
		parameter 	: {
			language	: 'de_DE',
		},
	}, */
	{
		// https://www.amazon.de/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&i=stripbooks&hidden-keywords=independently+published&__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&qid=1601804685&ref=sr_pg_2
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameters 	: {
			language	: 'de_DE',
			i			: 'stripbooks',
			qid			: '1601804685',
			ref			: 'sr_pg_2',
			'hidden-keywords': 'independently+published'
		}
	}
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_DE: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.DE,
	websiteUrl		: {
		home		: 'https://www.amazon.de/',
		login		: 'https://www.amazon.de/ap/signin?openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=deflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',
		search		: 'https://www.amazon.de/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_DE,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
