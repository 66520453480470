/* eslint-disable import/no-cycle */
import { Util } from '@libs/utilities/util';

import { ValidatorValueString } from './value-string';
import { EnumValidatorOperationType } from '../shared/validator-value.interface';


export class ValidatorValueEnum extends ValidatorValueString {

	constructor(
		private enumDef: object | object[]
	) {
		//0 - initialize the parent
		super();

		//1 - is the enum valid
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string) => ({
				isValid		: Util.Array.asArray(this.enumDef)
								.some((elem: object) => Util.Enum.isValid(elem, value)),
				error		: 'The value is not matching the enum definition'
			})
		});
	}
}
