import { EnumSubscriptionModule } from '@apps/flying/shared';

import { IPagesListModuleAccess } from '../pages-list.interface';
import { EnumSidebarPage } from '../../sidebar-pages.interface';
import { FLYING_VIDEOS, EnumFlyingTutorialVideo } from '../../../../../shared/constants/youtube-tutorials/flying-youtube-videos.data';


/**------------------------------------------------------
 * Print On Demand
 */
export const UPLOAD_PAGE_LIST_MODULE_DATA: IPagesListModuleAccess[] = [

	// POD
	{
		id 		: EnumSidebarPage.UploadPodStatistics,
		access	: [
			EnumSubscriptionModule.DesignManagement25GB,
			EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,
			EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop,
			EnumSubscriptionModule.UploadPodPro
		],
		title	: 'Statistics (Design Management or Upload Module)',
		message	: 'There are just too many designs, how can you keep the overview of all tags and import? This "Statistics" is the only tool which gives you the perfect overview of your used tags, upload history, and designs infos.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.PodUploadStatistics].videos
	},
	{
		id 		: EnumSidebarPage.UploadAiTemplates,
		access	: [
			EnumSubscriptionModule.UploadPodPro
		],
		title	: 'AI Templates (Only Upload Pro Module)',
		message	: `Empower your listings with AI! Skip manual writing or VA costs. Use AI Templates for specialized, efficient listing generation. Today's future is AI-enabled.`,
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.PodUploadAiTemplates].videos
	},

	// Upload Trademarks
	{
		id 		: EnumSidebarPage.UploadPodTmReport,
		access	: [
			EnumSubscriptionModule.DesignManagement25GB,
			EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,
			EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop,
			EnumSubscriptionModule.UploadPodPro
		],
		title	: 'TM Report (Design Management or Upload Module)',
		message	: 'There are just too many trademarks in the word, how can you make sure you are secure with thousands of designs? This "TM Report" is the only tool which checks all the new trademark registrations against all your listings in your design management. Now you can come here once a week and be sure to never miss a trademark again.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.DesignManagementTmReport].videos
	},
	{
		id 		: EnumSidebarPage.UploadPodTmWhitelist,
		access	: [
			EnumSubscriptionModule.DesignManagement25GB,
			EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,
			EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop,
			EnumSubscriptionModule.UploadPodPro
		],
		title	: 'TM Whitelist (Design Management or Upload Module)',
		message	: 'There are just too many trademarks registered, and we all get overwhelmed when we do our first trademark checks. Add words to your whitelist which should be ignored in the TM check. By doing so over time you will get the perfect TM check which only shows you relevant results and nothing else.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.DesignManagementListingBlacklistCheck].videos
	},
	{
		id 		: EnumSidebarPage.UploadPodTmBlacklist,
		access	: [
			EnumSubscriptionModule.DesignManagement25GB,
			EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,
			EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop,
			EnumSubscriptionModule.UploadPodPro
		],
		title	: 'TM Blacklist (Design Management or Upload Module)',
		message	: 'Some trademarks are really dangerous, and you might get sued. Other words will be rejected by Amazon. The question is, how can we keep track of all of this effectively? You guessed it with the Blacklist, you will be warned if any of these words is found in your listing, and you get access to the full community blacklist.',
		video	: FLYING_VIDEOS[EnumFlyingTutorialVideo.DesignManagementListingBlacklistCheck].videos
	}
];
