import { EnumTemperature } from './temperature.interface';
import { EnumSystem, EnumType, IUnitRecord } from '../shared/unit-system.interface';


/**------------------------------------------------------
 * Temperature Infos
 */
export const TEMPERATURE_INFOS: Record<EnumTemperature, IUnitRecord> = {

	// -------------------------------- Temperature (Metric & Imperial)
	[EnumTemperature.Celsius]: {
		code		: EnumTemperature.Celsius,
		type		: EnumType.Temperature,
		system		: EnumSystem.Metric,
		name		: 'Celsius',
		namePlural	: 'Celsius',
		symbol		: 'C',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumTemperature.Fahrenheit,
			toType		: EnumType.Temperature,
			toSystem	: EnumSystem.Imperial,
			convert		: (celsius: number): number => celsius / (5 / 9) + 32
		}
	},
	[EnumTemperature.Fahrenheit]: {
		code		: EnumTemperature.Fahrenheit,
		type		: EnumType.Temperature,
		system		: EnumSystem.Imperial,
		name		: 'Fahrenheit',
		namePlural	: 'Fahrenheit',
		symbol		: 'F',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumTemperature.Celsius,
			toType		: EnumType.Temperature,
			toSystem	: EnumSystem.Metric,
			convert		: (fahrenheit: number): number => (fahrenheit - 32) * (5 / 9)
		}
	}
};
