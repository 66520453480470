import { EnumLanguage } from '@libs/constants';
import { Util } from '@libs/utilities/util';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';

/**------------------------------------------------------
 * Configurations
 */
export const FLYING_GLOBAL_LISTING_LIMITATIONS: IFlyingGlobalListingConfig = {
	TITLE_LENGTH		: 120,
	DESCRIPTION_LENGTH 	: 2000,
	KEYWORD_LENGTH		: 50,
	MAX_KEYWORDS		: 50
};

interface IFlyingGlobalListingConfig {
	TITLE_LENGTH		: number;
	DESCRIPTION_LENGTH 	: number;
	KEYWORD_LENGTH		: number;
	MAX_KEYWORDS		: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingGlobalTypeOption {
	Men			= 'men',
	Women		= 'women',
	Youth		= 'youth'
}

export enum EnumFlyingGlobalColorOption {
	Black		= 'black',
	White		= 'white',
	Colorful	= 'colorful'
}


/**------------------------------------------------------
 * Global Data Interfaces
 */
export interface IFlyingGlobalListing {
	texts  		: IFlyingGlobalText[];
	options 	: IFlyingGlobalOptions;
}

export interface IFlyingGlobalText {
	language    : EnumLanguage;
	title       : string;
	description : string;
	keywords    : string[];
}

export interface IFlyingGlobalOptions {
	color   	: EnumFlyingGlobalColorOption;		// will be used to work with the old uploader
	types   	: EnumFlyingGlobalTypeOption[];
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingGlobalListingSchema implements ValidatorSchema<IFlyingGlobalListing> {
	texts	= Validator.Array().each(Validator.Object().inspect<IFlyingGlobalText>({
		language	: Validator.Enum(EnumLanguage).isNotEmpty(),
		title		: Validator.String().isMaxLength(FLYING_GLOBAL_LISTING_LIMITATIONS.TITLE_LENGTH),
		description	: Validator.String().isMaxLength(FLYING_GLOBAL_LISTING_LIMITATIONS.DESCRIPTION_LENGTH),
		keywords	: Validator.Array().each(Validator.String().isNotEmpty().isMaxLength(FLYING_GLOBAL_LISTING_LIMITATIONS.KEYWORD_LENGTH)).isMaxSize(FLYING_GLOBAL_LISTING_LIMITATIONS.MAX_KEYWORDS)
	})).isMaxSize(Util.Enum.values(EnumLanguage).length);
	options	= {
		color		: Validator.Enum(EnumFlyingGlobalColorOption).isNotEmpty(),
		types		: Validator.Array().each(Validator.Enum(EnumFlyingGlobalTypeOption).isNotEmpty()).isMaxSize(Util.Enum.values(EnumFlyingGlobalTypeOption).length)
	};
}
