import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserDetectionService } from '@libs/libraries/frontend';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgMessageToastAssistant } from '@libs/frameworks/angular';
import { SECURITY_EXCEPTION_ROUTES } from '@apps/flying/shared';

import { AuthSecurityService } from '../auth-security/auth-security.service';
import { ErrorModalAssistant } from '../../../../shared/modals/error-modal/error-modal.assistant';
import { ENVIRONMENT } from '../../../../../environments';


@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

	//** Configurations */
	private readonly IGNORE_FULL_SECURITY : string[] = SECURITY_EXCEPTION_ROUTES.IGNORE_FULL_SECURITY;
	private readonly IGNORE_ERROR_API_LIST: string[] = ['design-management/update-design-storage', 'design-management/import-design-data', 'trademark/v2/listing-check'];

	//** Error Code Definitions */
	private readonly ERROR_NOTIFICATION	: number[] = ENVIRONMENT.ERROR_CATCH.NOTIFICATION;
	private readonly ERROR_SERVER_ERROR	: number[] = ENVIRONMENT.ERROR_CATCH.SERVER_ERROR;
	private readonly ERROR_MAINTENANCE	: number[] = ENVIRONMENT.ERROR_CATCH.MAINTENANCE;
	private readonly ERROR_TIME_TOKEN	: number[] = ENVIRONMENT.ERROR_CATCH.TIME_TOKEN;

	constructor(
		private router					: Router,
		private messageToastAssistant	: NgMessageToastAssistant,
		private authSecurityService		: AuthSecurityService,
		private errorModalAssistant 	: ErrorModalAssistant,
		private browserDetectionService	: BrowserDetectionService
	) {}

	//** Interceptor */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		//0 - modify headers and body
		req = this.getAuthRequest(req);

		//1 - handle response body and error
		return next.handle(req).pipe(
			map((event: HttpEvent<any>) => {

				//a. is the content encrypted?
				if (event instanceof HttpResponse) return this.authSecurityService.getContentDecryption(event);

				//b. return the original content (if not encrypted)
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				const decryptedError: HttpErrorResponse = this.authSecurityService.getErrorDecryption(error);
				return this.errorHandler(decryptedError) as Observable<HttpEvent<any>>;
			})
		);
	}

	//** Authenticate the user */
	private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {

		//0 - get bearer token
		const bearerToken: string = this.authSecurityService.getBearerToken();

		//1 - sets auth headers
		let headers: HttpHeaders = req.headers.append('Authorization', bearerToken);

		//2 - ignore security
		const reqUrl	 	 : string = new URL(req.url).pathname;
		const matchIgnoreList: RegExp = new RegExp(`^(${this.IGNORE_FULL_SECURITY.join('|')})`);
		if (matchIgnoreList.test(reqUrl)) return req;

		//3 - set time token
		headers = this.authSecurityService.appendTimeTokenHeader(headers);

		//4 - encrypt content
		return this.authSecurityService.getContentEncryption(req, headers);
	}


	/**------------------------------------------------------
	 * Error Handling
	 */
	private errorHandler(error: HttpErrorResponse): Observable<HttpErrorResponse> | Observable<never> {

		//0 - ignore error modals
		if (this.IGNORE_ERROR_API_LIST.find((api: string) => error?.url?.includes(api))) return throwError(error);

		//1 - check if user has no internet connection
		if (this.browserDetectionService.isOffline()) {
			this.errorModalAssistant.showNoConnection();
			return of(error);
		}

		//2 - check if the user was logged out
		if (error.status === 401) {
			this.authSecurityService.signoutClient();
			this.router.navigate(['user', 'login'], {
				state: { message: 'Your session is expired, Please login again.' }
			});
			return of(error);
		}

		//3 - check if tost message should be shown
		if (this.ERROR_NOTIFICATION.includes(error.status)) {
			this.messageToastAssistant.showError('Oops, Error!', error.error.message);
			return of(error);
		}

		//4 - check for forbidden (time token error)
		if (this.ERROR_TIME_TOKEN.includes(error.status)) {
			this.errorModalAssistant.showInvalidTime();
			return of(error);
		}

		//5 - check for server errors
		if (this.ERROR_SERVER_ERROR.includes(error.status)) {
			this.errorModalAssistant.showServerError();
			return of(error);
		}

		//6 - check for server maintenance
		if (this.ERROR_MAINTENANCE.includes(error.status)) {
			this.errorModalAssistant.showMaintenanceError();
			return of(error);
		}

		//7 - if error did not match, forward it
		return throwError(error);
	}
}
