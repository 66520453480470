import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { ETSY_CONFIG, ETSY_DEFAULT_OPTIONS, EnumEtsySorting, IEtsySearchOptions } from './etsy-search.data';


/**------------------------------------------------------
 * Etsy Search Url
 */
export class EtsySearch extends AbstractSearchLinks<IEtsySearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumEtsySorting>[] {
		return ETSY_CONFIG.sortings as ISearchParameterConfig<EnumEtsySorting>[];
	}

	constructor() {
		super(ETSY_CONFIG, ETSY_DEFAULT_OPTIONS);
	}
}
