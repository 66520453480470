/**------------------------------------------------------
 * Ignored words in japan language
 * source for stopwords JAPAN => https://github.com/fergiemcdowall/stopword/blob/master/lib/stopwords_ja.js
 */
export const STOP_WORDS_JA: string[] = [
	// cspell:disable
	'の',
	'に',
	'は',
	'を',
	'た',
	'が',
	'で',
	'て',
	'と',
	'し',
	'れ',
	'さ',
	'ある',
	'いる',
	'も',
	'する',
	'から',
	'な',
	'こと',
	'として',
	'い',
	'や',
	'れる',
	'など',
	'なっ',
	'ない',
	'この',
	'ため',
	'その',
	'あっ',
	'よう',
	'また',
	'もの',
	'という',
	'あり',
	'まで',
	'られ',
	'なる',
	'へ',
	'か',
	'だ',
	'これ',
	'によって',
	'により',
	'おり',
	'より',
	'による',
	'ず',
	'なり',
	'られる',
	'において',
	'ば',
	'なかっ',
	'なく',
	'しかし',
	'について',
	'せ',
	'だっ',
	'その後',
	'できる',
	'それ',
	'う',
	'ので',
	'なお',
	'のみ',
	'でき',
	'き',
	'つ',
	'における',
	'および',
	'いう',
	'さらに',
	'でも',
	'ら',
	'たり',
	'その他',
	'に関する',
	'たち',
	'ます',
	'ん',
	'なら',
	'に対して',
	'特に',
	'せる',
	'及び',
	'これら',
	'とき',
	'では',
	'にて',
	'ほか',
	'ながら',
	'うち',
	'そして',
	'とともに',
	'ただし',
	'かつて',
	'それぞれ',
	'または',
	'お',
	'ほど',
	'ものの',
	'に対する',
	'ほとんど',
	'と共に',
	'といった',
	'です',
	'とも',
	'ところ',
	'ここ'
	// cspell:enable
];
