import { JdtEncodingHelper } from './data/jdt-encoding.helper';
import { JdtDecodingHelper } from './data/jdt-decoding.helper';
import { IJdtEncodingOptions, IJdtDecodingOptions, IJdtData } from './data/jdt-data-token.interface';


/**------------------------------------------------------
 * JSON-Export/Import Handler
 */
export class JdtHandlerService {

	constructor(
		private jdtEncodingHandler: JdtEncodingHelper,
		private jdtDecodingHandler: JdtDecodingHelper
	) {}


	encode<TInfo, TData>(data: TData, exportOptions: IJdtEncodingOptions): string {
		return this.jdtEncodingHandler.encodeDataToJdt<TInfo, TData>(data, exportOptions);
	}

	decode<TInfo, TData>(rawData: string, importOptions: IJdtDecodingOptions): IJdtData<TInfo, TData> {
		return this.jdtDecodingHandler.decodeDataFromJdt<TInfo, TData>(rawData, importOptions);
	}
}
