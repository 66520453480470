import { Inject, Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { Observable, from } from 'rxjs';

import { EnumSecureServiceInjection, ISecureServicesConfig } from './security-services.config';


//** Browser Helper Variable */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const grecaptcha: ISecureGoogleReCaptcha;


@Injectable()
export class SecureReCaptchaService {

	constructor(
		@Inject(EnumSecureServiceInjection.Config)
		private config: ISecureServicesConfig
	) {}


	getReCaptchaResponse(): Observable<unknown> {

		//0 - check if the RECAPTCHA_KEY was configured
		if (Util.String.isEmpty(this.config.GOOGLE_RECAPTCHA_KEY)) throw new Error(`ReCaptchaService => getReCaptchaResponse => FATAL ERROR: GOOGLE_RECAPTCHA_KEY was not defined in the environment variables (value is: "${this.config.GOOGLE_RECAPTCHA_KEY}")`);
		if (Util.Basic.isUndefined(grecaptcha)) throw new Error(`ReCaptchaService => getReCaptchaResponse => FATAL ERROR: google recaptcha is undefined, are you sure it was added to the dom / page`);

		//1 - get the response
		return from(grecaptcha.execute(this.config.GOOGLE_RECAPTCHA_KEY, { action: 'submit' }));
	}
}


//** Interfaces --------------------------------- */
interface ISecureGoogleReCaptcha {
	execute: (recaptchaKey: string, options: { action: string }) => Observable<unknown>;
}
