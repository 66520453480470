import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumTwitterSorting {
	Top				= 'top',
	Latest			= 'latest',
	People			= 'people',
	Photos			= 'photos',
	Videos			= 'videos'
}

export const TWITTER_SORTING_LIST: ISearchParameterConfig<EnumTwitterSorting>[] = [
	{
		code 		: EnumTwitterSorting.Top,
		name 		: 'Top',
		parameters 	: {
			src 	: 'typed_query'
		}
	},
	{
		code 		: EnumTwitterSorting.Latest,
		name 		: 'Latest',
		parameters 	: {
			src 	: 'typed_query',
			f 		: 'live'
		}
	},
	{
		code 		: EnumTwitterSorting.People,
		name 		: 'People',
		parameters 	: {
			src 	: 'typed_query',
			f 		: 'user'
		}
	},
	{
		code 		: EnumTwitterSorting.Photos,
		name 		: 'Photos',
		parameters 	: {
			src 	: 'typed_query',
			f 		: 'image'
		}
	},
	{
		code 		: EnumTwitterSorting.Videos,
		name 		: 'Videos',
		parameters 	: {
			src 	: 'typed_query',
			f 		: 'video'
		}
	}
];


/**------------------------------------------------------
 * Twitter Configuration
 */
export const TWITTER_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://twitter.com',
		login		: 'https://twitter.com/i/flow/login',
		search		: 'https://twitter.com/search?q=[SEARCH]'
	},
	pageLimit		: -1,
	sortings		: TWITTER_SORTING_LIST,
	products		: [],
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Twitter Search Options
 */
export const TWITTER_DEFAULT_OPTIONS: ITwitterSearchOptions = {
	sorting			: EnumTwitterSorting.Top
};

export interface ITwitterSearchOptions {
	sorting			: EnumTwitterSorting;
}
