import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';


/**------------------------------------------------------
 * Limitations of the Research
 */
export const AI_RESEARCH_LIMITATIONS: IAiResearchLimitations[] = [

	// ---------------------- free version
	{
		modules	: [EnumSubscriptionModule.FreeVersion],
		exclude : {
			workbench		: false,
			nicheFinder		: false,
			quotesAndPuns	: false,
			aiSearch		: false
		}
	},

	// ---------------------- ai research module
	{
		modules	: [EnumSubscriptionModule.AiResearch],
		exclude : {
			workbench		: true,
			nicheFinder		: true,
			quotesAndPuns	: true,
			aiSearch		: true
		}
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IAiResearchLimitations {
	modules	: EnumSubscriptionModule[];
	exclude : IAiResearchPageLimitations;
}

export interface IAiResearchPageLimitations {
	workbench		: boolean;
	nicheFinder		: boolean;
	quotesAndPuns	: boolean;
	aiSearch		: boolean;
}
