import { Subject } from 'rxjs';

import { WebsocketWrapper } from './websocket.wrapper';


export class WebsocketApiWrapper<T> {

	constructor(
		private websocketWrapper: WebsocketWrapper
	) {}


	post(event: string, params: Record<string, string | boolean> = null): Subject<T> {

		//0 - observable for communicating data
		const dataObserver: Subject<T> = new Subject<T>();

		//1 - handle socket and observer for all events
		this.websocketWrapper.onConnect(() => this.websocketWrapper.emitEvent(event, params || ''));
		this.websocketWrapper.onDisconnect(() => { dataObserver.complete(); });
		this.websocketWrapper.onEventOnce(event, (data: T) => { this.handleData(dataObserver, data); });

		//2 - connect and start socket communication
		this.websocketWrapper.connect();

		//3 - return observable for subscription
		return dataObserver;
	}

	stream(event: string, params: Record<string, string | boolean> = null): Subject<T> {

		//0 - observable for communicating data
		const dataObserver: Subject<T> = new Subject<T>();

		//1 - handle socket and observer for all events
		this.websocketWrapper.onConnect(() => this.websocketWrapper.emitEvent(event, params || ''));
		this.websocketWrapper.onDisconnect(() => { dataObserver.complete(); });
		this.websocketWrapper.onEvent(event, (data: T) => { this.handleData(dataObserver, data); });

		//2 - connect and start socket communication
		this.websocketWrapper.connect();

		//3 - return observable for subscription
		return dataObserver;
	}


	//** Handle socket data */
	private handleData(dataObserver: Subject<T>, data: T | IWebsocketError) {

		//0 - invoke error if socket error
		if ((data as IWebsocketError)?.error) {
			dataObserver.error((data as IWebsocketError).message);
			return;
		}

		//1 - if success emit the data
		dataObserver.next(data as T);
	}
}


//** Interfaces --------------------------------- */
interface IWebsocketError {
	status	: boolean;
	code 	: number;
	message : string;
	error 	: object;
}
