import { IGoogleTrendsSearchOptions } from './searches/lookup/google-trends-search/google-trends-search.data';
import { IDisplateSearchOptions } from './searches/print-on-demand/displate/displate-search.data';
import { IRedbubbleSearchOptions } from './searches/print-on-demand/redbubble/redbubble-search.data';
import { ISociety6SearchOptions } from './searches/print-on-demand/society6/society6-search.data';
import { ISpreadshirtSearchOptions } from './searches/print-on-demand/spreadshirt/spreadshirt-search.data';
import { ITeepublicSearchOptions } from './searches/print-on-demand/teepublic/teepublic-search.data';
import { IZazzleSearchOptions } from './searches/print-on-demand/zazzle/zazzle-search.data';
import { IFreepikSearchOptions } from './searches/stock-images/freepik/freepik-search.data';
import { IPixabaySearchOptions } from './searches/stock-images/pixabay/pixabay-search.data';
import { IShutterStockSearchOptions } from './searches/stock-images/shutterstock/shutterstock-search.data';
import { IVexelsSearchOptions } from './searches/stock-images/vexels/vexels-search.data';
import { IAmazonSearchOptions } from './searches/stores/amazon/amazon-search.data';
import { IEtsySearchOptions } from './searches/stores/etsy/etsy-search.data';
import { ITwitterSearchOptions } from './searches/social-media/twitter/twitter-search.data';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumSearchLinkPlatform {
	Amazon			= 'amazon',
	AmazonMBA		= 'amazon_mba',
	AmazonKDP		= 'amazon_kdp',
	Displate 		= 'displate',
	Redbubble 		= 'redbubble',
	Society6 		= 'society6',
	Teepublic 		= 'teepublic',
	Zazzle 			= 'zazzle',
	Spreadshirt 	= 'spreadshirt',
	GoogleSearch	= 'google_search',
	GoogleImages 	= 'google_images',
	GoogleTrends 	= 'google_trends',
	Wikipedia 		= 'wikipedia',
	Instagram 		= 'instagram',
	Pinterest 		= 'pinterest',
	Twitter 		= 'twitter',
	Social9Gag 		= 'social9_gag',
	Freepik 		= 'freepik',
	Pexels 			= 'pexels',
	Pixabay 		= 'pixabay',
	ShutterStock 	= 'shutter_stock',
	Vexels 			= 'vexels',
	Etsy 			= 'etsy'
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypeSearchOptions =
	IGoogleTrendsSearchOptions 	| IDisplateSearchOptions 	| IRedbubbleSearchOptions
	| ISociety6SearchOptions 	| ITeepublicSearchOptions 	| IZazzleSearchOptions
	| IFreepikSearchOptions 	| IPixabaySearchOptions 	| IShutterStockSearchOptions
	| IAmazonSearchOptions 		| IEtsySearchOptions		| ISpreadshirtSearchOptions
	| IVexelsSearchOptions 		| ITwitterSearchOptions;
