/**------------------------------------------------------
 * Proxy Forwarding constants
 */
export enum EnumApiKey {
	MsApiKey 	= 'api_key_spell_checking_microsoft' // TODO(harsh) => only for test
}

export enum EnumHttpMethod {
	GET 		= 'GET',
	POST 		= 'POST',
	PATCH 		= 'PATCH',
	DELETE 		= 'DELETE'
}


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IRequestData {
	method   	: EnumHttpMethod;
	url      	: string;
	headers    ?: object;
	body       ?: object;
}
