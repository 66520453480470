import { Util } from '@libs/utilities/util';

import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';
import { EnumValidatorOperationType } from '../shared/validator-value.interface';


export class ValidatorValueNumber extends AbstractValidatorValue<ValidatorValueNumber> {

	//** Configurations */
	private readonly MAX_PORT_NUMBER: number = 65535;

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'number'; }
	protected isValueTypeValid(value: any): boolean { return Util.Number.isNumber(value); }

	constructor() {

		//0 - initialize parent
		super();

		//1 - if we have a string convert it to number
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: string | number) => Util.Number.toNumber(value)
		});
	}


	/**------------------------------------------------------
	 * Number Validators
	 */
	isPositive(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Number.isPositive(value),
				error		: `Value must be positive`
			})
		});
		return this;
	}

	isNegative(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Number.isNegative(value),
				error		: `Value must be positive`
			})
		});
		return this;
	}

	isInteger(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Number.isInteger(value),
				error		: `Value must be an integer`
			})
		});
		return this;
	}

	isFloat(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Number.isFloat(value),
				error		: `Value must be a float`
			})
		});
		return this;
	}

	isEven(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value % 2 === 0,
				error		: `Value must be even`
			})
		});
		return this;
	}

	isOdd(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value % 2 !== 0,
				error		: `Value must be odd`
			})
		});
		return this;
	}

	inRange(min: number, max: number): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: Util.Number.inRange(value, { min, max }),
				error		: `Value must be in range between ${min} and ${max}`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Port
	 */
	isPort(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value > 0 && value <= this.MAX_PORT_NUMBER,
				error		: `Value must be in range between 0 and ${this.MAX_PORT_NUMBER} to be a valid port number`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Greater / Less
	 */
	isGreaterThan(min: number): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value > min,
				error		: `Value must be greater than ${min}`
			})
		});
		return this;
	}

	isGreaterThanOrEqual(min: number): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value >= min,
				error		: `Value must be greater than or equal ${min}`
			})
		});
		return this;
	}

	isLessThan(max: number): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value < max,
				error		: `Value must be less than ${max}`
			})
		});
		return this;
	}

	isLessThanOrEqual(max: number): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: number) => ({
				isValid		: value <= max,
				error		: `Value must be less than or equal ${max}`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Number Transformers
	 */
	toPositive(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: number) => Util.Number.toPositive(value)
		});
		return this;
	}

	toNegative(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: number) => Util.Number.toNegative(value)
		});
		return this;
	}

	toInteger(): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: number) => Util.Number.toInteger(value)
		});
		return this;
	}

	toRounded(decimals: number): ValidatorValueNumber {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (value: number) => Util.Number.roundDouble(value, decimals)
		});
		return this;
	}
}
