/* eslint-disable no-alert */
import { Util } from '@libs/utilities/util';
import { TypeResolve } from '@libs/constants';

import { IWindowBounds, IPoint, IQueryParameters } from './web-helper.interface';


/**------------------------------------------------------
 * Web Util Helper
 * ---------------
 * > Window Object With Examples : https://www.bitdegree.org/learn/javascript-window
 */
export class WebHelperService {

	//** Configurations */
	private WINDOW_DEFAULT_WIDTH : number = 1000;
	private WINDOW_DEFAULT_HEIGHT: number = 750;


	/**------------------------------------------------------
	 * Browser Window Operations
	 */

	//** Opens a link in a new window */
	openCurrentTab(url: string): void {
		if (!Util.Http.isUrlValid(url)) throw new Error(`WebHelperService => openCurrentTab => FATAL ERROR: the url of "${url}" is invalid`);
		window.open(url, '_self'); // eslint-disable-line no-restricted-syntax
	}

	openNewTab(url: string): void {
		if (!Util.Http.isUrlValid(url)) throw new Error(`WebHelperService => openNewTab => FATAL ERROR: the url of "${url}" is invalid`);
		window.open(url); // eslint-disable-line no-restricted-syntax
	}

	openNewWindow(url: string, bounds: IWindowBounds | null = null): void {

		//0 - define the bounds
		const windowBounds: IWindowBounds = {
			x 		: Util.Basic.fallbackValue(bounds?.x, window.screen.width  - (this.WINDOW_DEFAULT_WIDTH / 2)),
			y 		: Util.Basic.fallbackValue(bounds?.y, window.screen.height - (this.WINDOW_DEFAULT_HEIGHT / 2)),
			width 	: Util.Basic.fallbackValue(bounds?.width,  this.WINDOW_DEFAULT_WIDTH),
			height 	: Util.Basic.fallbackValue(bounds?.height, this.WINDOW_DEFAULT_HEIGHT)
		};

		//1 - open the url in the a new window
		if (!Util.Http.isUrlValid(url)) throw new Error(`WebHelperService => open => FATAL ERROR: the url of "${url}" is invalid`);
		const windowObject: Window = window.open(url, '', `width=${windowBounds.width}, height=${windowBounds.height}`)!; // eslint-disable-line no-restricted-syntax
		windowObject.moveTo(windowBounds.width, windowBounds.height);
	}

	//** Close the current window */
	close(): void {
		window.close();
	}


	/**------------------------------------------------------
	 * Navigation
	 */
	navigateUrl(url: string): void {
		this.openCurrentTab(this.getNavigateUrl(url));
	}
	navigateUrlNewTab(url: string): void {
		this.openNewTab(this.getNavigateUrl(url));
	}

	navigate(path: string[], parameters?: IQueryParameters): void {
		this.openCurrentTab(this.getNavigatePathUrl(path, parameters));
	}
	navigateNewTab(path: string[], parameters?: IQueryParameters): void {
		this.openNewTab(this.getNavigatePathUrl(path, parameters));
	}


	/**------------------------------------------------------
	 * Scrolling in Current Tab
	 */
	getScrollPosition(): IPoint {
		return { x : window.pageXOffset, y : window.pageYOffset };
	}

	//** Scroll by the specified amount of pixels */
	scrollBy(vertical: number, horizontal: number = 0): void {
		window.scrollBy({
			top		: vertical,
			left	: horizontal,
			behavior: 'smooth'
		});
	}

	//** Scroll to the x/y coordinates */
	scrollTo(x: number, y: number = 0): void {
		window.scrollTo({
			top		: x,
			left	: y,
			behavior: 'smooth'
		});
	}

	scrollToElement(element: HTMLElement): void {
		element.scrollIntoView({ behavior: 'smooth' });
	}

	scrollTop(): void {
		this.scrollTo(0, 0);
	}
	scrollBottom(): void {
		const height: number = document.documentElement.scrollHeight || document.documentElement.clientHeight;
		this.scrollTo(0, height);
	}


	/**------------------------------------------------------
	 * Confirmation Popups
	 */

	//** Confirmation popup with yes/no option */
	confirm(message: string): boolean {
		if (Util.String.isEmpty(message)) throw new Error(`WebHelperService => confirm => FATAL ERROR: message string of "${message}" is empty`);
		return window.confirm(message);
	}

	//** Alert popup with an info message */
	alert(message: string): void {
		if (Util.String.isEmpty(message)) throw new Error(`WebHelperService => alert => FATAL ERROR: message string of "${message}" is empty`);
		window.alert(message);
	}

	//** Prompt popup with an input field for text, ... */
	prompt(message: string, defaultValue: string = ''): string | null {
		if (Util.String.isEmpty(message)) 		 throw new Error(`WebHelperService => prompt => FATAL ERROR: message string of "${message}" is empty`);
		if (!Util.String.isString(defaultValue)) throw new Error(`WebHelperService => prompt => FATAL ERROR: defaultValue of "${defaultValue}" is not a valid string`);
		return window.prompt(message, defaultValue);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	removeFocusFromWindow(): void { window.blur(); }
	printPageContent()	   : void { window.print(); }

	//** Browser history navigation */
	goForward()	: void { window.history.forward(); }
	goBack()	: void { window.history.back(); }


	/**------------------------------------------------------
	 * Access Cookies
	 */
	getCookie(cookieName: string): string | null {

		//0 - check the parameters
		if (Util.String.isEmpty(cookieName)) throw new Error(`WebHelperService => getCookie => FATAL ERROR: value of cookieName is empty (value: "${cookieName}")`);

		//1 - fetch the cookie value (return null if no value exists)
		const match: RegExpMatchArray | null = document.cookie.match(`${cookieName}=([^;]*)`);
		return match ? match[1] : null;
	}

	setCookie(cookieName: string, value: string, expirationInDays: number, domainName: string = ''): void {

		//0 - check the provided values
		if (Util.String.isEmpty(cookieName)) throw new Error(`WebHelperService => setCookie => FATAL ERROR: value of cookieName is empty (value: "${cookieName}")`);
		if (Util.String.isEmpty(value)) 	 throw new Error(`WebHelperService => setCookie => FATAL ERROR: value of "parameter value" is empty (value: "${value}")`);
		if (expirationInDays <= 0) 			 throw new Error(`WebHelperService => setCookie => FATAL ERROR: value of expirationInDays is to small (value: "${expirationInDays}")`);

		//1 - define the domainName and expiration date
		if (!Util.String.isEmpty(domainName)) domainName = `domain=${domainName};`;
		const expirationDate: Date = new Date();
		expirationDate.setDate(new Date().getDate() + expirationInDays);

		//2 - set the cookie value
		document.cookie = `${cookieName}=${escape(value)}; expires=${expirationDate.toUTCString()}; ${domainName}path=/`;
	}


	/**------------------------------------------------------
	 * Copy To Clipboard
	 * > source : https://decipher.dev/30-seconds-of-typescript/docs/copyToClipboard
	 */
	copyToClipboard(value: string): void {

		//0 - create invisible html element
		const invisibleElement: HTMLTextAreaElement = document.createElement('textarea');
		invisibleElement.value = value;
		invisibleElement.setAttribute('readonly', '');
		invisibleElement.style.position = 'absolute';
		invisibleElement.style.left 	= '-9999px';

		//1 - create the html element and select it
		document.body.appendChild(invisibleElement);
		const selected: Range | null = (document.getSelection()!.rangeCount > 0)
			? document.getSelection()!.getRangeAt(0)
			: null;
		invisibleElement.select();

		//2 - copy the value to the clipboard
		document.execCommand('copy');
		document.body.removeChild(invisibleElement);

		//3 - select the previous element again
		if (selected) {
			document.getSelection()!.removeAllRanges();
			document.getSelection()!.addRange(selected);
		}
	}


	/**------------------------------------------------------
	 * Element Accessors
	 * > source (elementContains) : https://decipher.dev/30-seconds-of-typescript/docs/elementContains
	 * > source (elementVisible)  : https://decipher.dev/30-seconds-of-typescript/docs/elementIsVisibleInViewport
	 */
	elementContains(parent: HTMLElement | Element, child: HTMLElement | Element): boolean {

		//0 - check if both elements are defined
		if (!parent) throw new Error(`WebHelperService => elementContains => FATAL ERROR: provided parent element is undefined`);
		if (!child)  throw new Error(`WebHelperService => elementContains => FATAL ERROR: provided child element is undefined`);

		//1 - check if one element contains the other
		return (parent !== child) && parent.contains(child);
	}

	elementVisible(element: HTMLElement | Element, partiallyVisible: boolean = false): boolean {

		//0 - is the element defined (and rendered yet)
		if (!element) throw new Error(`WebHelperService => elementVisible => FATAL ERROR: provided element is undefined`);
		if (!element?.getBoundingClientRect()) return false;

		//1 - define helper variables
		const { top, left, bottom, right } = element.getBoundingClientRect();
		const { innerHeight, innerWidth }  = window;

		//2 - check if element is visible
		if (partiallyVisible) {
			return ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight))
				&& ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));
		}
		return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
	}

	isBottomVisible(): boolean {
		const height: number = document.documentElement.scrollHeight || document.documentElement.clientHeight;
		return document.documentElement.clientHeight + window.scrollY >= height;
	}


	/**------------------------------------------------------
	 * JS Click
	 */
	clickElementByClassName(elementClass: string, elementIndex: number = 0): void {

		//0 - get the element
		const elementList: HTMLCollectionOf<Element> = document.getElementsByClassName(elementClass) as HTMLCollectionOf<Element>;
		if (!elementList || elementList.length === 0) throw new Error(`WebHelperService => clickElementByClassName => FATAL ERROR: no element with the class of "${elementClass}" could be found`);
		if (elementList.length <= elementIndex)		  throw new Error(`WebHelperService => clickElementByClassName => FATAL ERROR: ${elementList.length} elements with class of "${elementClass}" exist, but the index of ${elementIndex} is to high to access the list of element`);

		//1 - click event
		const element: HTMLElement = elementList[elementIndex] as HTMLElement;
		element.click();
	}

	clickElementById(elementId: string): void {

		//0 - get the element
		const element: HTMLElement = document.getElementById(elementId)!;
		if (!element) throw new Error(`WebHelperService => clickElementById => FATAL ERROR: no element with the id of "${elementId}" could be found`);

		//1 - click event
		element.click();
	}


	/**------------------------------------------------------
	 * Other Functions / Helpers
	 */

	//** Download file */
	downloadFile(content: Blob, fileName: string): void {

		//0 - create anchor element
		const link: HTMLAnchorElement = document.createElement('a');
		link.href	  = window.URL.createObjectURL(content);
		link.download = fileName;

		//1 - download and remove the reference
		link.click();
		link.remove();
	}

	//** Download file */
	localFileDownload(filePath: string, fileName: string): void {

		//0 - create anchor element
		const link: HTMLAnchorElement = document.createElement('a');
		link.setAttribute('type', 'hidden');

		//1 - set url and download filename and append to the body
		link.href 		= filePath;
		link.download 	= fileName;
		document.body.appendChild(link);

		//2 - download and remove the reference
		link.click();
		document.body.removeChild(link);
	}

	downloadFileFromUrl(fileUrl: string, fileName: string, target: '_blank' | '_self'): void {

		//0 - create anchor element
		const link: HTMLAnchorElement = document.createElement('a');
		link.href 		= fileUrl; 			// Url of the file where it is existing
		link.target 	= target; 			// This makes it open in a new window or tab
		link.download 	= fileName; 		// This attribute should prompt a download dialog box

		//1 - download and remove the reference
		document.body.appendChild(link); 	// This line is important, append the link to DOM
		link.click();
		link.remove();
	}

	//** Extract Content from HTML */
	extractTextFromHtmlContent(html: string): string {

		//0 - construct a html helper element
		const span: HTMLSpanElement = document.createElement('span');
		span.innerHTML = html;

		//1 - get the text content without html tags
		const textContent: string = span.textContent || span.innerText;
		return textContent;
	}


	/**------------------------------------------------------
	 * JS find Element using SetInterval
	 */

	//** Check for target element until it is found in the DOM */
	waitForElement(targetElementId: string, detectionSpeed: number = 500): Promise<HTMLElement> {
		return new Promise((resolve: TypeResolve<HTMLElement>) => {

			// check the element in the dom for every 2000ms
			const checkElementInterval: NodeJS.Timeout = setInterval(() => {

				//a. get the element by id (if element not found in the DOM then wait for next check)
				const htmlElement: HTMLElement | null = document.getElementById(targetElementId);
				if (!htmlElement) return;

				//b. if element exists, resolve promise and clear interval
				clearInterval(checkElementInterval);
				resolve(htmlElement);

			}, detectionSpeed);
		});
	}


	/**------------------------------------------------------
	 * Url Path Helper
	 */
	getNavigateUrl(urlPath: string): string {

		//0 - check if url is valid
		if (urlPath.startsWith('http://') || urlPath.startsWith('https://')) return urlPath;

		//1 - return url
		return `https://${urlPath}`;
	}

	getNavigatePathUrl(path: string[], parameters?: IQueryParameters): string {

		//0 - construct the basic url
		if (!path.includes(window.location.host)) {
			const protocol: string = (window.location.protocol === 'http:') ? 'http' : 'https';
			path = [`${protocol}://${window.location.host}/#`, ...path];
		}
		const basePath: string = Util.Http.urlJoin(path);

		//1 - add the query parameters, and check url
		const urlQuery: string = parameters?.queryParams
			? Util.Http.objectToQueryString(parameters?.queryParams)
			: '';

		//2 - open url in current tab
		return basePath + urlQuery;
	}
}
