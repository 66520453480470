import { BrowserDetectionService, DeviceDetectionService, KeyboardMouseService, WebHelperService, WorkerAssistantService, ExcelHandlerServiceFactory, ExcelHandlerService, ScaleLayoutService, ScaleLayoutServiceFactory, ImageHandlerService, ImageHandlerServiceFactory, ZipHandlerService, ZipHandlerServiceFactory } from '@libs/libraries/frontend';
import { KeywordExtractor, StopWordFilter, ColorCodeName, DebugLoggerService, JdtHandlerService, JdtHandlerServiceFactory, KeywordExtractorFactory, JwtHandlerService } from '@libs/libraries/shared';
import { Injectable, Injector, ModuleWithProviders, Provider, Type, ViewContainerRef } from '@angular/core';
import { NgAppInjectorService, NgAngularHelpersModule, NgProductTourService } from '@libs/frameworks/angular';
import { SearchLinks, SearchLinksFactory } from '@apps/flying/shared';
import { ListingLimitationsServiceOld, FlyingKeywordLimitation } from '@apps-v2/flying/shared';

import { AuthSecurityService } from './services/shared/auth/auth-security/auth-security.service';
import { LocalStorageService } from './services/shared/local-storage/local-storage.service';
import { WebsocketService } from './_legacy/request-handlers/websocket/websocket.service';
import { ENVIRONMENT } from '../environments';
import { AppStorageHelper } from './services/shared/local-storage/sub-storages/app.helper';


/**------------------------------------------------------
 * Auth Security Service
 * ---------------------
 * 1. Why do we have that?
 * > The "AccessTokenService" service is storing the access
 * > token in the local storage. The storage access is project
 * > specific and should not be hardcoded. For this reason an
 * > interface of "IAuthStorageService" defines the required
 * > functions of the class instance which is used internally
 * > to access the local storage to store the access token,
 * > refresh token and other things.
 * ---------------------
 * 2. Where is it used?
 * > The providers are injected into the "app.module.ts",
 * > so that the "IAuthStorageService" in the "AccessTokenService"
 * > can be injected project specifically by Angular.
 */
export const AUTH_SECURITY_PROVIDERS: Provider[] = [
	{
		provide		: 'IAuthStorageService',
		deps		: [LocalStorageService],
		useFactory	: (localStorageService: LocalStorageService): AppStorageHelper => {
			return localStorageService.app;
		}
	}
];


/**------------------------------------------------------
 * Angular Framework Modules
 */
export const ANGULAR_FRAMEWORK_MODULES: Array<Type<object> | ModuleWithProviders<object> | object[]> = [
	NgAngularHelpersModule
];


/**------------------------------------------------------
 * Security Http / Socket
 */
export const SECURITY_MODULES: Array<Type<object> | ModuleWithProviders<object> | object[]> = [
	// ... add here
];


/**------------------------------------------------------
 * Shared Services
 */
export const SHARED_SERVICE_PROVIDERS: Provider[] = [
	{
		provide		: DebugLoggerService,
		useFactory	: (): DebugLoggerService => {
			return new DebugLoggerService(ENVIRONMENT.DEBUG_LOG_LEVEL);
		}
	},
	{
		provide		: StopWordFilter,
		useClass	: StopWordFilter
	},
	{
		provide		: KeywordExtractor,
		useFactory	: (): KeywordExtractorFactory => {
			return KeywordExtractorFactory.create();
		}
	},
	{
		provide		: ColorCodeName,
		useClass	: ColorCodeName
	},
	{
		provide		: DeviceDetectionService,
		useClass	: DeviceDetectionService
	},
	{
		provide		: BrowserDetectionService,
		useClass	: BrowserDetectionService
	},
	{
		provide		: KeyboardMouseService,
		useClass	: KeyboardMouseService
	},
	{
		provide		: JwtHandlerService,
		useClass	: JwtHandlerService
	},
	{
		provide		: WebsocketService,
		deps		: [AuthSecurityService],
		useFactory	: (authSecurityService: AuthSecurityService): WebsocketService => {
			return new WebsocketService(authSecurityService);
		}
	},
	{
		provide		: WebHelperService,
		useClass	: WebHelperService
	},
	{
		provide		: WorkerAssistantService,
		useClass	: WorkerAssistantService
	},
	{
		provide		: FlyingKeywordLimitation,
		useClass	: FlyingKeywordLimitation
	},
	{
		provide		: ListingLimitationsServiceOld,
		useClass	: ListingLimitationsServiceOld
	},
	{
		provide		: ExcelHandlerService,
		useFactory	: (): ExcelHandlerServiceFactory => {
			return ExcelHandlerServiceFactory.create();
		}
	},
	{
		provide		: ScaleLayoutService,
		deps		: [LocalStorageService],
		useFactory	: (localStorageService: LocalStorageService): ScaleLayoutServiceFactory => {
			return ScaleLayoutServiceFactory.create(localStorageService.app);
		}
	},
	{
		provide		: JdtHandlerService,
		useFactory	: (): JdtHandlerServiceFactory => {
			return JdtHandlerServiceFactory.create();
		}
	},
	{
		provide		: ImageHandlerService,
		useFactory	: (): ImageHandlerServiceFactory => {
			return ImageHandlerServiceFactory.create();
		}
	},
	{
		provide		: ZipHandlerService,
		useFactory	: (): ZipHandlerServiceFactory => {
			return ZipHandlerServiceFactory.create();
		}
	},
	{
		provide		: SearchLinks,
		useFactory	: (): SearchLinksFactory => {
			return SearchLinksFactory.create();
		}
	}
];


@Injectable({
	providedIn: 'root'
})
export class ProviderHelperService {

	constructor(
		private appInjectorService: NgAppInjectorService,
		private productTourService: NgProductTourService
	) { }


	/**------------------------------------------------------
	 * App Injector Service
	 * --------------------
	 * 1. Why do we have that?
	 * > The "NgAppInjectorService" allows us to create instances
	 * > of classes or services which have dependencies. Such a
	 * > class / service can not just be created by the "new"
	 * > operator, because it has multiple dependencies which
	 * > need to be injected. The injection is handled by the
	 * > core logic of Angular and can be used though the
	 * > "NgAppInjectorService".
	 * --------------------
	 * 2. Where is it used?
	 * > The "Injector" instance needs to be injected into the
	 * > "AppModule" of the Angular project. The injector
	 * > instance is created by the angular dependency injection
	 * > in the "AppModule" and is then passed inside the
	 * > "NgAppInjectorService" to initialize the internal pointer
	 * > to the injector instance, which is then later used to
	 * > access the angular dependency injection mechanism.
	 */
	initializeAppInjector(injector: Injector): void {
		this.appInjectorService.setAppInjector(injector);
	}


	/**------------------------------------------------------
	 * Product Tour Service
	 * --------------------
	 * 1. Why do we have that?
	 * > The "NgProductTourService" allows us to create product
	 * > tours. Such a tour consists of overlaying elements
	 * > which are highlighting defined functionalities. In
	 * > order to render these info elements on the html page
	 * > the product tour requires to have the "viewContainerRef".
	 * --------------------
	 * 2. Where is it used?
	 * > The "viewContainerRef" needs to be injected into the
	 * > "AppComponent". Then within the "ngAfterViewInit()"
	 * > live-cycle-hook of Angular the "viewContainerRef" is
	 * > set within the "NgProductTourService".
	 */
	initializeProductTour(viewContainerRef: ViewContainerRef): void {
		this.productTourService.tooltipViewRef = viewContainerRef;
	}
}
