/**------------------------------------------------------
 * Dark Mode Enums
 */
export enum EnumAddonDarkMode {
	Light 		= 'light',
	Dark  		= 'dark',
	DimmedDark 	= 'dimmed_dark'
}


/**------------------------------------------------------
 * Configurations
 */
export interface IAddonDarkModeConfig {
	CSS					: string;
	IGNORE_INLINE_STYLE	: string[];
	IGNORE_IMAGES		: string[];
	INVERT				: string[];
}


/**------------------------------------------------------
 * Constants
 */
export const ADDON_INITIAL_DARK_MODE_STORAGE: IAddonDarkModeStorage = {
	darkMode		: EnumAddonDarkMode.Light,
	autoSystemTheme	: true
};

export interface IAddonDarkModeStorage {
	darkMode		: EnumAddonDarkMode | null;
	autoSystemTheme	: boolean;
}
