import { KEY_CODES } from '@libs/libraries/frontend';

import { INgTemplateModalConfig } from './template-modal.interface';
import { EnumNgFaStyle } from '../../../styling-components';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgTemplateModalType {
	DefaultTemplate 	= 'default_template',
	InfoTemplate		= 'info_template',
	SuccessTemplate		= 'success_template',
	WarningTemplate		= 'warning_template',
	ErrorTemplate		= 'error_template'
}

export enum EnumNgTemplateAction {
	Continue			= 'action_continue',
	Close				= 'action_close'
}


/**------------------------------------------------------
 * Template Modal Configurations
 */
export const NG_TEMPLATE_MODAL_STYLES: INgTemplateModalConfigs = {
	[EnumNgTemplateModalType.DefaultTemplate] : {
		style 		: {
			color 	: '--blue',
			icon 	: {
				style: EnumNgFaStyle.Solid,
				name : 'comment'
			}
		},
		buttons 	: {
			primary : {
				color 	 	 : '--blue',
				actionKey	 : [KEY_CODES.enter]
			},
			secondary : {
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		}
	},
	[EnumNgTemplateModalType.SuccessTemplate] : {
		style 		: {
			color 	: '--green',
			icon 	: {
				style: EnumNgFaStyle.Solid,
				name : 'circle-check'
			}
		},
		buttons 	: {
			primary : {
				color 	 	 : '--green',
				actionKey	 : [KEY_CODES.enter]
			},
			secondary : {
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		}
	},
	[EnumNgTemplateModalType.InfoTemplate] : {
		style 		: {
			color 	: '--yellow',
			icon 	: {
				style: EnumNgFaStyle.Solid,
				name : 'lightbulb'
			}
		},
		buttons		: {
			primary : {
				color 	 	 : '--yellow',
				actionKey	 : [KEY_CODES.enter]
			},
			secondary : {
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		}
	},
	[EnumNgTemplateModalType.WarningTemplate] : {
		style 		: {
			color 	: '--orange',
			icon 	: {
				style: EnumNgFaStyle.Solid,
				name : 'triangle-exclamation'
			}
		},
		buttons		: {
			primary : {
				color 	 	 : '--orange',
				actionKey	 : [KEY_CODES.enter]
			},
			secondary : {
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		}
	},
	[EnumNgTemplateModalType.ErrorTemplate] : {
		style 		: {
			color 	: '--red',
			icon 	: {
				style: EnumNgFaStyle.Solid,
				name : 'circle-exclamation'
			}
		},
		buttons		: {
			primary : {
				color 	 	 : '--red',
				actionKey	 : [KEY_CODES.enter]
			},
			secondary : {
				color 	 	 : '--gray',
				actionKey	 : [KEY_CODES.escape]
			}
		}
	}
};


//** Interfaces --------------------------------- */
export interface INgTemplateModalConfigs {
	[EnumNgTemplateModalType.DefaultTemplate]	: INgTemplateModalConfig;
	[EnumNgTemplateModalType.SuccessTemplate]	: INgTemplateModalConfig;
	[EnumNgTemplateModalType.InfoTemplate]		: INgTemplateModalConfig;
	[EnumNgTemplateModalType.WarningTemplate]	: INgTemplateModalConfig;
	[EnumNgTemplateModalType.ErrorTemplate]		: INgTemplateModalConfig;
}
