import { SPECIAL_CHAR_ENCODING_MAP } from '@libs/constants';

import { UtilString } from './util-string';
import { UtilBasic } from './util-basic';
import { UtilArray } from './util-array';
import { UtilFunction } from './util-function';
import { UtilRegex } from './util-regex';
import { UtilTextToBlocks } from './algorithms/text-to-blocks';
import { UtilTextSimilarity } from './algorithms/text-similarity';
import { UtilTextSearchRelevance } from './algorithms/text-search-relevance';
import { IUtilTextReplaceParams, UtilTextReplacer } from './algorithms/text-replacer';
import { IUtilTextMatchOptions, IUtilTextSearchOptions, UtilTextSearchFilter } from './algorithms/text-search-filter';


/**------------------------------------------------------
 * Text Utilities
 * --------------
 * > Info: Containing all functionalities related text
 * > manipulations. These functionalities are one
 * > abstraction layer above strings and consider listings
 * > and more.
 */
export class UtilText {

	//** Configurations */
	private readonly DEFAULT_INCLUDES_OPTION: IUtilIncludesWordOptions = {
		caseSensitive	: false,
		exactWordMatch	: false
	};

	//** Injections for Algorithm Extensions */
	private textToBlocks		: UtilTextToBlocks 			= new UtilTextToBlocks();
	private textSimilarity		: UtilTextSimilarity 		= null!;
	private textSearchRelevance	: UtilTextSearchRelevance	= null!;
	private textSearchFilter	: UtilTextSearchFilter		= null!;
	private textReplacer		: UtilTextReplacer			= null!;


	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString,
		private utilArray	: UtilArray,
		private utilFunction: UtilFunction,
		private utilRegex	: UtilRegex
	) {

		// setup the dependencies
		this.textSimilarity 	 = new UtilTextSimilarity(this.utilBasic, this.utilString);
		this.textSearchRelevance = new UtilTextSearchRelevance(this.utilBasic, this.utilString);
		this.textSearchFilter 	 = new UtilTextSearchFilter(this.utilBasic, this.utilString, this.utilFunction, this);
		this.textReplacer		 = new UtilTextReplacer(this.utilRegex, this.utilString);
	}


	/**------------------------------------------------------
	 * Count Occurrence
	 */
	countOccurrence(text: string, search: string): number {
		if (this.utilString.isEmpty(text))   throw new Error(`UtilText => countOccurrence => FATAL ERROR: text is empty`);
		if (this.utilString.isEmpty(search)) throw new Error(`UtilText => countOccurrence => FATAL ERROR: search is empty`);
		return (text.match(new RegExp(search, 'gi')) || []).length;
	}


	/**------------------------------------------------------
	 * String Cleanups
	 */
	cleanText(text: string): string {
		if (this.utilString.isEmpty(text)) return '';
		return this.utilString.purifyWhitespaces(text).trim();
	}

	cleanTexts(texts: string[]): string[] {
		if (!this.utilArray.isArray(texts)) return [];
		return texts.map((text: string) => this.cleanText(text))			// cleanup text
			.filter((elem: string) => !this.utilString.isEmpty(elem));		// filter out empty
	}


	/**------------------------------------------------------
	 * Shorten Text
	 */
	shortenByLength(text: string, maxLength: number): string {
		this.checkText(text);
		if (maxLength < 0) throw new Error(`UtilText => shortenByLength => FATAL ERROR: provided maxLength is negative`);
		return text.substring(0, maxLength);
	}
	shortenByWord(text: string, maxLength: number, splitChar: string = ' '): string {

		//0 - check the function call
		this.checkText(text);
		if (maxLength < 0) throw new Error(`UtilText => shortenByLength => FATAL ERROR: provided maxLength is negative`);

		//1 - shorten the text by word
		let shortText: string = '';
		for (const word of text.split(splitChar)) {
			if ((shortText + word.trim()).length > maxLength) break;
			shortText += `${word.trim()} `; // add space at the end
		}
		return shortText.trim();
	}
	shortenByWordCount(text: string, maxWords: number, splitChar: string = ' '): string {

		//0 - check the function call
		this.checkText(text);
		if (maxWords < 0) throw new Error(`UtilText => shortenByLength => FATAL ERROR: provided maxWords is negative`);

		//1 - shorten the text by word
		let   shortText: string   = '';
		const words	   : string[] = text.split(splitChar);
		for (let i: number = 0; i < words.length && i < maxWords; i++) {
			if (this.utilString.isEmpty(words[i])) continue;
			shortText += `${words[i].trim()} `; // add space at the end
		}
		return shortText.trim();
	}


	/**------------------------------------------------------
	 * Create Statistics of the Text
	 */
	metrics(text: string): IUtilTextInfo {

		//0 - check the function call
		this.checkText(text);
		text = text.trim();

		//1 - count the words in the text
		const wordCounts: Record<string, number> = {};
		for (let word of text.split(' ')) {
			word = word.trim();
			if (word.length === 0) continue;
			wordCounts[word] = wordCounts[word] ? ++wordCounts[word] : 1;
		}

		//2 - count the chars in the text
		const charCounts: Record<string, number> = {};
		for (let char of text.split('')) {
			char = char.trim();
			if (char.length === 0) continue;
			charCounts[char] = charCounts[char] ? ++charCounts[char] : 1;
		}

		//3 - calculate all other metrics
		const result: IUtilTextInfo = {
			characterCount	: text.length,
			wordCount		: this.wordCount(text),
			lineCount		: this.lineCount(text),
			wordCounts		: wordCounts,
			charCounts		: charCounts
		};

		return result;
	}
	charCount(text: string, char: string): number {
		this.checkText(text);
		if (char.length !== 1) throw new Error(`UtilText => checkText => FATAL ERROR: provided char is empty or not a single character (char: ${char})`);
		return (text.match(new RegExp(char, 'g')) || []).length;
	}
	wordOccurrence(text: string, word: string): number {
		this.checkText(text);
		this.checkText(word);
		return (text.match(new RegExp(word, 'g')) || []).length;
	}
	wordCount(text: string): number {
		this.checkText(text);
		return text.split(' ').filter((elem: string) => elem.trim().length !== 0).length;
	}
	lineCount(text: string): number {
		this.checkText(text);
		return text.split('\n').filter((elem: string) => elem.trim().length !== 0).length;
	}


	/**------------------------------------------------------
	 * Chunk Text into Blocks
	 */
	chunkToBlocks(text: string, blockSize: number): string[] {
		return this.textToBlocks.chunkTextToBlocks(text, blockSize);
	}
	wordSplit(text: string): string[] {
		this.checkText(text);
		return text.split(/(?:,| |;)+/);
	}


	/**------------------------------------------------------
	 * Text Formatting
	 */
	fileNameToTitleCase(fullFileName: string): string {

		//0 - get the file name without mime type
		let fileName: string = this.utilString.splitByLast(fullFileName, '.').first;

		//1 - remove date from string
		fileName = this.utilString.removeDateFromString(fileName);

		//2 - remove leading numbers form string
		fileName = this.utilString.removeLeadingNumbers(fileName);

		//3 - replace special dividing chars with space (example: 'dog.cat-bird_run' => dog cat bird run)
		fileName = this.utilString.removeTextDividers(fileName);

		//4 - convert the text into the title case format
		fileName = this.utilString.titleCase(fileName);
		return fileName;
	}


	/**------------------------------------------------------
	 * Smart Text Search
	 * > Check if all words of search are contained in text
	 */
	isTextMatch(search: string, text: string, options: Partial<IUtilTextMatchOptions> = {}): boolean {
		return this.textSearchFilter.isTextMatch(search, text, options);
	}

	filterDatasetBySearch<T>(search: string, textObjects: T[], getTextFn: TypeUtilGetTextFn<T>, options: Partial<IUtilTextSearchOptions> = {}): T[] {
		return this.textSearchFilter.filterTextsBySearch(search, textObjects, getTextFn, options);
	}
	filterTextsBySearch(search: string, texts: string[], options: Partial<IUtilTextSearchOptions> = {}): string[] {
		return this.textSearchFilter.filterTextsBySearch(search, texts, null, options);
	}


	/**------------------------------------------------------
	 * Remove Special Language Chars
	 * -----------------------------
	 * > cspell:disable
	 * > german  => ÄÖÜäöüß
	 * > french  => ÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒùûüÿàâæçéèêëïîôœ
	 * > spanish => ÁÉÍÑÓÚÜáéíñóúü
	 * > italian => ÀÈÉÌÒÓÙàèéìòóù
	 * > cspell:enable
	 */
	stripSpecialChars(text: string): string {
		const match = (char: string): string => (SPECIAL_CHAR_ENCODING_MAP as any)[char] || char;
		return text.replace(/[^\u0000-\u007E]/g, match); // eslint-disable-line no-control-regex
	}

	replaceCharList(text: string, charList: Array<string[]>): string {
		for (const charTuple of charList) {
			text = text.replace(charTuple[0], charTuple[1]);
		}
		return text;
	}


	/**------------------------------------------------------
	 * Includes Word Checks
	 */
	includesText(text: string, searchText: string, caseSensitive: boolean = false): boolean {
		this.utilString.checkStrings([text, searchText]);
		return new RegExp(searchText, caseSensitive ? '' : 'i').test(text);
	}
	includesExactWord(text: string, searchWord: string, caseSensitive: boolean = false): boolean {
		this.utilString.checkStrings([text, searchWord]);
		return new RegExp(`\\b${searchWord}\\b`, caseSensitive ? '' : 'i').test(text);
	}

	includesAnyWord(text: string, searchWords: string[], options?: Partial<IUtilIncludesWordOptions>): boolean {

		//0 - check the string and purify the options
		this.utilString.checkStrings([text, ...searchWords]);
		const includesOptions: IUtilIncludesWordOptions = this.utilFunction.assignOptions(this.DEFAULT_INCLUDES_OPTION, options);

		//1 - try to find any of the words in the text (full word search)
		for (const word of searchWords) {
			const includesWord: boolean = includesOptions.exactWordMatch
				? this.includesExactWord(text, word, includesOptions.caseSensitive)
				: this.includesText(text, word, includesOptions.caseSensitive);
			if (includesWord) return true;
		}
		return false;
	}
	includesAllWord(text: string, searchWords: string[], options?: Partial<IUtilIncludesWordOptions>): boolean {

		//0 - check the string and purify the options
		this.utilString.checkStrings([text, ...searchWords]);
		const includesOptions: IUtilIncludesWordOptions = this.utilFunction.assignOptions(this.DEFAULT_INCLUDES_OPTION, options);

		//1 - check if all words are included in the text
		for (const word of searchWords) {
			const includesWord: boolean = includesOptions.exactWordMatch
				? this.includesExactWord(text, word, includesOptions.caseSensitive)
				: this.includesText(text, word, includesOptions.caseSensitive);
			if (!includesWord) return false;
		}
		return true;
	}

	startWith(text: string, search: string, caseSensitive: boolean = false): boolean {
		this.utilString.checkStrings([text, search]);
		return new RegExp(`^${search}`, caseSensitive ? '' : 'i').test(text);
	}
	endWith(text: string, search: string, caseSensitive: boolean = false): boolean {
		this.utilString.checkStrings([text, search]);
		return new RegExp(`${search}$`, caseSensitive ? '' : 'i').test(text);
	}


	/**------------------------------------------------------
	 * Similarity Compare
	 */
	similarityCompare(text1: string, text2: string): number {
		return this.textSimilarity.similarityCompare(text1, text2);
	}
	sortBySimilarity<T>(search: string, textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {
		return this.textSimilarity.sortBySimilarity(search, textObjects, getTextFn);
	}


	/**------------------------------------------------------
	 * Relevance Compare
	 */
	relevanceScore(search: string, text: string): number {
		return this.textSearchRelevance.compare(search, text);
	}
	sortByRelevance<T>(search: string, textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {
		return this.textSearchRelevance.sortByRelevance(search, textObjects, getTextFn);
	}


	/**------------------------------------------------------
	 * Alphabetic Text Sorting
	 */
	sortAlphabeticAsc<T>(textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {

		//0 - check and get text function
		const textFn: Function = this.utilBasic.fallbackValue(getTextFn, (value: T) => this.utilString.toString(value));

		//1 - sort the text objects
		const sortedObjects: T[] = textObjects.sort((a: T, b: T) => textFn(a).localeCompare(textFn(b), [], { sensitivity: 'base' }));
		return sortedObjects;
	}
	sortAlphabeticDesc<T>(textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {
		return this.sortAlphabeticAsc(textObjects, getTextFn).reverse();
	}


	/**------------------------------------------------------
	 * Text Length Sorting
	 */
	sortByTextLengthAsc<T>(textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {

		//0 - check and get text function
		const textFn: Function = this.utilBasic.fallbackValue(getTextFn, (value: T) => this.utilString.toString(value));

		//1 - sort the text objects
		const sortedObjects: T[] = textObjects.sort((a: T, b: T) => textFn(a).length - textFn(b).length);
		return sortedObjects;
	}
	sortByTextLengthDesc<T>(textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {
		return this.sortByTextLengthAsc(textObjects, getTextFn).reverse();
	}


	/**------------------------------------------------------
	 * Text Replace
	 */
	replaceBySmartWord(params: IUtilTextReplaceParams): string {
		return this.textReplacer.smartReplace(params);
	}
	replaceByStartWith(params: IUtilTextReplaceParams): string {
		return this.textReplacer.replaceStartsWith(params);
	}
	replaceByEndWith(params: IUtilTextReplaceParams): string {
		return this.textReplacer.replaceEndsWith(params);
	}
	replaceByCaseInsensitive(params: IUtilTextReplaceParams): string {
		return this.textReplacer.replaceCaseInsensitive(params);
	}
	replaceByCaseSensitive(params: IUtilTextReplaceParams): string {
		return this.textReplacer.replaceCaseSensitive(params);
	}


	/**------------------------------------------------------
	 * Unify Text
	 */
	standardizeTextLayout(input: string, maxLineLength: number = 80): string {

		//0 - remove extra empty lines
		input = input.replace(/\n{2,}/g, '\n\n');

		//1 - give lines a max length
		const lines : string[] = input.split('\n'); // split text into lines
		let   result: string = '';
		for (let line of lines) {

			//a. wrap lines longer than maxLineLength
			while (line.length > maxLineLength) {

				// define the split index
				let wrapIndex: number = line.lastIndexOf(' ', maxLineLength);
				if (wrapIndex === -1) wrapIndex = maxLineLength; // in case there are no spaces

				// wrap the line
				result += `${line.substring(0, wrapIndex)}\n`;
				line    = line.substring(wrapIndex).trim();
			}

			//b. add reformatted line to result
			result += `${line}\n`;
		}

		//2 - trim the result
		return result.trim();
	}


	/**------------------------------------------------------
	 * Helper Validation Checks
	 */
	private checkTexts(texts: string[]) {
		for (const text of texts) this.checkText(text);
	}
	private checkText(text: string) {
		if (!this.utilString.isString(text)) throw new Error(`UtilText => checkText => FATAL ERROR: provided text is a valid string`);
	}
}


//** Interfaces --------------------------------- */
export interface IUtilTextInfo {
	characterCount	: number;
	wordCount		: number;
	lineCount		: number;
	wordCounts		: Record<string, number>;
	charCounts		: Record<string, number>;
}

export interface IUtilIncludesWordOptions {
	caseSensitive	: boolean;
	exactWordMatch	: boolean;
}


//** Types -------------------------------------- */
type TypeUtilGetTextFn<T> = (object: T) => string;
