/**------------------------------------------------------
 * File Types / Categories
 */
export enum EnumFileType {
	JPEG 		= 'jpeg',
	JPG 		= 'jpg',
	PNG 		= 'png',
	GIF 		= 'gif',
	SVG 		= 'svg',
	XML 		= 'xml',
	FLY 		= 'fly',
	ZIP 		= 'zip',
	XLSX 		= 'xlsx',
	XLS 		= 'xls',
	JSON 		= 'json',
	ODS 		= 'ods',
	CSV 		= 'csv',
	TXT 		= 'txt',
	INI 		= 'ini'
}

export enum EnumFileCategory {
	Image 		= 'image',
	Spreadsheet = 'spreadsheet',
	Text 		= 'text',
	Data 		= 'data',
	Archive 	= 'archive'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFileTypeInfo {
	extension 	: string;
	fileEnding	: string;
	mimeType 	: string;
	altMimeTypes: string[];
	category 	: EnumFileCategory;
}
