import { AddonDarkModeModule, AddonGoogleRecaptchaModule, AddonPaddleModule, AddonTapfiliateModule } from '@domains/frontend/page-addons';
import { EnumLogLevel } from '@libs/libraries/shared';
import { EnumEnvironment } from '@libs/constants';

import { EnumSidebarPage } from '../app/components/layout/sidebar/sidebar-pages.interface';
import { IEnvironment } from './environment.interface';
import { STYLES_ON_DARK_MODE, IGNORE_INLINE_STYLES, IGNORE_IMAGES } from '../app/shared/constants/dark-mode.constant';


/**------------------------------------------------------
 * Development Environment
 */
export const ENVIRONMENT: IEnvironment = {
	ENVIRONMENT			: EnumEnvironment.Development,

	DEBUG_LOG_LEVEL		: [EnumLogLevel.Info, EnumLogLevel.Warning, EnumLogLevel.Error],

	PROXY_API_URL		: {
		S3_DOWNLOAD		: 'https://live.beta.flyingresearch.net/proxy/download-s3',
		DOWNLOAD		: 'https://live.beta.flyingresearch.net/proxy/download-files',
		REQUEST			: 'https://live.beta.flyingresearch.net/proxy/forward-request'
	},

	HTTP_SECURITY 		: {
		CTT				: true,						// Content Time Token
		CTT_SAULT		: 'R93Yvjg3TpCAQ5Rh',		// Secret Sault used in the Time Token
		CDE				: false,					// Contend Data Encryption
		CDE_KEY			: 'DEyQ2Cuydvq47dQx'		// Encryption Key (value is hashed, encryption key is "342f1cbe0a524e1b")
	},

	VERSION             : '1.1.1',
	BACKEND				: {
		SERVER_URL      : 'https://api.beta.flyingresearch.net/',
		SERVER_LIVE_URL : 'https://live.beta.flyingresearch.net/'
	},

	SECURE_BACKEND		: {
		SERVER_URL		: 'https://beta-new-backend.flyingresearch.net/',
		HTTP_SECURITY	: {
			TIME_TOKEN		: false,
			ENCRYPTION		: false
		},
		TIME_TOKEN			: {
			TIME_WINDOW		: 60,
			SALT    		: 'R93Yvjg3TpCAQ5Rh'
		},
		ENCRYPTION_SECRET	: '',
		GOOGLE_RECAPTCHA_KEY: ''
	},

	WAIT_TIME_OTP       : 5,
	ERROR_CATCH 		: {
		NOTIFICATION 	: [400, 401],				// toster will be shown
		SERVER_ERROR	: [500],					// error modal will show
		MAINTENANCE		: [0, 502],					// maintenance modal will show
		TIME_TOKEN		: [405]					// error code for wrong time token
	},
	FORMATTING : {
		LOCAL_FORMAT 	: 'en-US'
	},

	// ----------------------------------------- External Scripts
	PAGE_ADDONS_MODULES: [
		AddonGoogleRecaptchaModule.register({
			RECAPTCHA_KEY	: '6LctUIEaAAAAAL9G9RfVoJl8eSdrPqzfrjQI2VP9'
		}),
		AddonTapfiliateModule.dummy(),
		AddonPaddleModule.dummy(),
		AddonDarkModeModule.register({
			CSS					: STYLES_ON_DARK_MODE,
			IGNORE_INLINE_STYLE	: IGNORE_INLINE_STYLES,
			IGNORE_IMAGES		: IGNORE_IMAGES,
			INVERT				: []
		})
	],

	// ----------------------------------------- Flying Research
	GOOGLE_CALENDAR_API_KEY: 'AIzaSyBshtMKpygaMiXSFUUOQZp2NXgIpwROP1w',

	// ----------------------------------------- Flying Upload
	POD					: {
		IMAGE_BUCKET_URI 	: 'https://pod-design-images-test.S3.eu-central-1.amazonaws.com/',
		EXCEL_STANDARD_URL	: 'https://flying-upload-desktop.s3.eu-central-1.amazonaws.com/resources/excel-standard.zip'
	},

	// ----------------------------------------- Feature Flags
	FEATURE_FLAGS : {
		PRODUCT_TOUR 				: false,
		DEFAULT_SYSTEM_THEME 		: false,

		// temporary flags for not released features
		// > add flags to show/hide features ("true" to show, and "false" to hide)
		SIDEBAR_HIDDEN_PAGES  		: [
			EnumSidebarPage.UploadSchedule,
			EnumSidebarPage.UploadAccountsTemplates
		],
		ALL_UPLOAD_SETTINGS			: false,
		ALL_EDIT_FEATURES			: false,
		EDIT_UPLOAD_PLATFORM_STATUS : {
			GLOBAL	: true,
			MBA 	: true
		},
		WHATS_NEW					: false
	}
};
