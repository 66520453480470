import { Util } from '@libs/utilities/util';
import { TypeResolve } from '@libs/constants';

import { IImportFileInfo, IImportSheetRow, IImportSheetCellValue, EXCEL_ACCEPTED_FILE_TYPES } from './excel-import.interface';


export class ExcelReaderHelper {


	/**------------------------------------------------------
	 * Import Spreadsheet File
	 */
	isAcceptedExcelType(file: File): boolean {
		const extension: string = Util.String.splitByLast(file.name, '.').second.toLowerCase();
		return (EXCEL_ACCEPTED_FILE_TYPES as string[]).includes(extension);
	}


	/**------------------------------------------------------
	 * Read Excel File Helpers
	 */
	readFile(file: File): Promise<ArrayBuffer | null> {
		return new Promise((resolve: TypeResolve<ArrayBuffer | null>) => {

			//0 - create reader instance
			const reader: FileReader = new FileReader();
			reader.onload = ((event: ProgressEvent) => {

				//a. get buffer of file and check if undefined
				const fileBuffer: ArrayBuffer = (event.target as FileReader).result as ArrayBuffer;
				if (Util.Basic.isUndefined(fileBuffer)) {
					resolve(null);
					return;
				}

				//b. resolve with the file buffer
				resolve(fileBuffer);
			});

			//1 - show on error and read file as buffer
			reader.onerror = (error: unknown) => {
				console.error(`ExcelReaderHelper => readExcelFile => ERROR: error at reading file of '${file.name}' (info: '${Util.Basic.stringifyObject(error)}')`);
				resolve(null);
			};

			//2 - initiate the read of the file
			reader.readAsArrayBuffer(file);
		});
	}


	/**------------------------------------------------------
	 * Get file info
	 */
	getFileInfo(file: File): IImportFileInfo {

		//0 - get file extension
		let extension: string | null = null;
		if (file.name.includes('.')) extension = Util.String.splitByLast(file.name, '.').second.toLowerCase();

		//1 - create file object
		const importFileInfo: IImportFileInfo = {
			name		: file.name,
			createDate  : new Date(file.lastModified),
			size		: file.size,
			extension   : extension
		};

		//2 - return file info
		return importFileInfo;
	}


	/**------------------------------------------------------
	 * Get Sheet Info
	 */
	importedSheetInfo(sheetData: Array<object>): IImportedSheetInfo {

		//0 - extract the headers
		let headers: IFormattedHeader[] = [];
		for (const row of sheetData) {

			//a. skip all leading empty rows
			const isRowEmpty: boolean = Util.Basic.isUndefined(row) || Util.Object.hasNoDefinedValues(row);
			if (isRowEmpty) continue;

			//b. get headers from the first not empty row
			headers = this.formattedSheetHeader(row);
			break;
		}

		//1 - extract the row
		const importSheetRows: IImportSheetRow[]  = [];
		for (const [index, row] of sheetData.entries()) {

			//a. create row object
			const importSheetRow: IImportSheetRow = {
				cellValues	: this.getCellValue(row, headers, index),
				rowIndex	: index,
				rowName		: Util.String.toString(index + 1)
			};

			//b. update sheet rows
			importSheetRows.push(importSheetRow);
		}

		//2 - return sheet rows
		const importedSheetInfo: IImportedSheetInfo = {
			columnCount	: headers.length,
			rows		: importSheetRows
		};
		return importedSheetInfo;
	}

	//** Get cell value */
	private getCellValue(row: object, headers: IFormattedHeader[], rowIndex: number): IImportSheetCellValue[] {

		//0 - get import sheet cell value
		const importSheetCellValues: IImportSheetCellValue[] = [];
		for (const key in row) {

			//a. check current heading
			const heading: IFormattedHeader | undefined = headers.find((item: IFormattedHeader) => item.columnName === key);
			if (Util.Basic.isUndefined(heading)) throw new Error(`ExcelReaderHelper => getCellValue => FATAL ERROR: heading is undefined`);

			//b. create cell object
			const importSheetCellValue: IImportSheetCellValue = {
				value	 : this.decodeToUtf8((row as any)[key]),
				metaInfo : {
					heading		: !Util.String.isEmpty(heading!.cellValue) ? heading!.cellValue : '',
					columnName	: heading!.columnName,
					columnIndex	: this.convertColumnToIndex(heading!.columnName),
					rowIndex	: rowIndex,
					rowName		: Util.String.toString(rowIndex + 1)
				}
			};

			//c. update cell values
			importSheetCellValues.push(importSheetCellValue);
		}

		//1 - return cell value rows
		return importSheetCellValues;
	}

	//** Get formatted headers */
	private formattedSheetHeader(row: object): IFormattedHeader[] {

		//0 - get formatted headers
		const formattedHeaders: IFormattedHeader[] = [];
		for (const key in row) {
			const formattedHeader: IFormattedHeader = {
				cellValue	: (row as any)[key],
				columnName	: key
			};
			formattedHeaders.push(formattedHeader);
		}

		//1 - return headers
		return formattedHeaders;
	}

	private convertColumnToIndex(column: string): number {

		//0 - check column empty validation and convert to uppercase
		if (Util.String.isEmpty(column)) throw new Error(`ExcelReaderHelper => convertColumnToIndex => FATAL ERROR: column name is not a valid string`);
		column = column.toUpperCase();

		//1 - get column index
		let   columnIndex : number = 0;
		const columnLength: number = column.length;
		for (let position: number = 0; position < columnLength; position++) {
			columnIndex += (column.charCodeAt(position) - 64) * Math.pow(26, columnLength - position - 1);
		}

		//2 - return column index (-1 to start with zero)
		return columnIndex - 1;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private decodeToUtf8(value: string): string {

		//0 - try to decode to utf-8
		value = Util.String.toString(value);
		try {
			value = Util.String.decodeUtf8(value);
		} catch (error) {
			//** Ignore Error: if we cant convert it is may already be a utf-8 encoded string */
		}

		//1 - return the decode value
		return value;
	}
}


//** Interfaces --------------------------------- */
export interface IFormattedHeader {
	cellValue	: string;
	columnName	: string;
}

export interface IImportedSheetInfo {
	columnCount	: number;
	rows		: IImportSheetRow[];
}
