/**------------------------------------------------------
 * Interface Definitions
 */
export interface ILoadDistributorParams<T extends object> {
	options			: ILoadDistributorExecutable<T>[];
	retry			: Partial<ILoadDistributorRetryOptions>;
}

export interface ILoadDistributorExecutable<T extends object> {
	identifier		: string;
	object			: T;
	probability		: number;			// a number expressing in how many cases it should be used (value between 0 and 1)
}

export interface ILoadDistributorRetryOptions {
	attempts		: number;
	delayPeriod		: number;
	excludeOnFail	: boolean;
}

export interface IRetryExecute {
	attempts		: number;
	excluded		: string[];
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypeExecuteFn<TObject extends object, R> = (object: TObject) => Promise<R>;
