import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecureTimeToken } from '@domains/security/shared';
import { Util } from '@libs/utilities/util';

import { ENVIRONMENT } from '../../../../../../environments';


@Injectable({
	providedIn: 'root'
})
export class TimeTokenService {

	//** Env Configurations */
	private readonly CTT 	 	: boolean = ENVIRONMENT.HTTP_SECURITY.CTT;
	private readonly CTT_SAULT  : string  = ENVIRONMENT.HTTP_SECURITY.CTT_SAULT;

	//** Helper Variables */
	private timeOffset 			: number  = null;

	constructor(
		private timeToken: SecureTimeToken = new SecureTimeToken()
	) {

		// check the environment variables
		if (Util.Basic.isUndefined(this.CTT))  	 throw new Error(`TimeTokenService => constructor => FATAL ERROR: the environment variable of HTTP_SECURITY.CTT is empty`);
		if (Util.String.isEmpty(this.CTT_SAULT)) throw new Error(`TimeTokenService => constructor => FATAL ERROR: the environment variable of HTTP_SECURITY.CTT_SAULT is empty`);
	}


	/**------------------------------------------------------
	 * Time Token
	 */
	appendTimeTokenHeader(headers: HttpHeaders): HttpHeaders {

		//0 - return headers if ctt is disabled
		if (!this.CTT) return headers;

		//1 - append x-ctt if ctt is enabled
		return headers.append('x-ctt', this.getTimeToken());
	}

	createTimeOffset(timestamp: number): number {

		//0 - error on invalid timestamp
		if (!Util.Number.isNumber(timestamp)) throw new Error(`TimeTokenService => createTimeOffset => Invalid request, timestamp of "${timestamp}" is not a valid number`);

		//1 - calculate offset and set it to local storage
		this.timeOffset = timestamp - Date.now();
		return this.timeOffset;
	}

	getTimeToken(): string {

		//0 - check if timeOffset was created
		if (Util.Basic.isUndefined(this.timeOffset)) throw new Error(`TimeTokenService => Module => FATAL ERROR: timeOffset was not initialized yet (value is "${this.timeOffset}"), please call the "createTimeOffset()" method for initialization`);

		//1 - get offset time
		const offsetTimestamp: string = new Date(Date.now() + this.timeOffset).toUTCString();
		const offsetDate	 : Date   = new Date(offsetTimestamp);

		//2 - create time token
		const timeToken: string = this.timeToken.createTimeToken(this.CTT_SAULT, offsetDate);
		return timeToken;
	}
}
