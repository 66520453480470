import { ApplicationRef, ComponentRef, Inject, Injectable, Injector, TemplateRef, ViewContainerRef, createComponent } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Util } from '@libs/utilities/util';

import { NgMessageToastComponent } from './message-toast/message-toast.component';
import { NgMessageContainerComponent } from './toast-container/message-container.component';
import { EnumNgToastPlacement, INgMessageToastInput } from './message-toast.interface';
import { NG_DEFAULT_TOAST_CONFIG, NG_SUCCESS_TOAST_CONFIG, NG_WARNING_TOAST_CONFIG, NG_ERROR_TOAST_CONFIG } from './message-toast.data';


@Injectable()
export class NgMessageToastAssistant {

	//** Helper Variables */
	// > Note: the variables need to be "static", as it needs to be guaranteed that they only exists
	// > once for each application. The management of the toasts should be global and independent
	// > of how many times them module was imported.
	private static loadedContainers: Map<EnumNgToastPlacement, ComponentRef<NgMessageContainerComponent>> = new Map<EnumNgToastPlacement, ComponentRef<NgMessageContainerComponent>>();
	private static loadedComponents: Map<string, ComponentRef<NgMessageToastComponent>> = new Map<string, ComponentRef<NgMessageToastComponent>>();

	constructor(
		@Inject(DOCUMENT)
		private document: Document,
		private appRef	: ApplicationRef,
		private injector: Injector
	) {}


	/**------------------------------------------------------
	 * Show Toasters
	 */
	showDefault(title: string, message: TemplateRef<HTMLElement> | string, placement?: EnumNgToastPlacement): NgMessageToastComponent {
		const messageToastInput: INgMessageToastInput = Util.Function.assignOptions(NG_DEFAULT_TOAST_CONFIG, { title, message });
		if (placement && Util.Enum.isValid(EnumNgToastPlacement, placement)) messageToastInput.placement = placement;
		return this.createToast(messageToastInput);
	}

	showSuccess(title: string, message: TemplateRef<HTMLElement> | string, placement?: EnumNgToastPlacement): NgMessageToastComponent {
		const messageToastInput: INgMessageToastInput = Util.Function.assignOptions(NG_SUCCESS_TOAST_CONFIG, { title, message });
		if (placement && Util.Enum.isValid(EnumNgToastPlacement, placement)) messageToastInput.placement = placement;
		return this.createToast(messageToastInput);
	}

	showWarning(title: string, message: TemplateRef<HTMLElement> | string, placement?: EnumNgToastPlacement): NgMessageToastComponent {
		const messageToastInput: INgMessageToastInput = Util.Function.assignOptions(NG_WARNING_TOAST_CONFIG, { title, message });
		if (placement && Util.Enum.isValid(EnumNgToastPlacement, placement)) messageToastInput.placement = placement;
		return this.createToast(messageToastInput);
	}

	showError(title: string, message: TemplateRef<HTMLElement> | string, placement?: EnumNgToastPlacement): NgMessageToastComponent {
		const messageToastInput: INgMessageToastInput = Util.Function.assignOptions(NG_ERROR_TOAST_CONFIG, { title, message });
		if (placement && Util.Enum.isValid(EnumNgToastPlacement, placement)) messageToastInput.placement = placement;
		return this.createToast(messageToastInput);
	}

	showAction(title: string, message: TemplateRef<HTMLElement> | string): NgMessageToastComponent {
		return this.showDefault(title, message, EnumNgToastPlacement.BottomRight);
	}


	/**------------------------------------------------------
	 * Close Helpers
	 */
	closeAll(): NgMessageToastAssistant {
		for (const componentRef of NgMessageToastAssistant.loadedComponents.values()) componentRef.destroy();
		NgMessageToastAssistant.loadedComponents.clear();
		return this;
	}

	close(toastId: string): void {

		//0 - select the right toast instance
		const componentRef: ComponentRef<NgMessageToastComponent> = NgMessageToastAssistant.loadedComponents.get(toastId)!;
		if (Util.Basic.isUndefined(componentRef)) return;

		//1 - destroy and close the toast
		componentRef.destroy();
		NgMessageToastAssistant.loadedComponents.delete(toastId);
	}


	/**------------------------------------------------------
	 * Show Functions
	 */
	private createToast(messageToastInput: INgMessageToastInput): NgMessageToastComponent {

		//0 - get the container reference?
		const loadedContainerRef: ComponentRef<NgMessageContainerComponent> = NgMessageToastAssistant.loadedContainers.get(messageToastInput.placement)!;
		if (Util.Basic.isUndefined(loadedContainerRef)) throw new Error(`NgMessageToastAssistant => getToastContainerRef => FATAL ERROR: The container for the placement "${messageToastInput.placement}" is not loaded! (was the module loaded?)`);

		//1 - create a component reference from the component
		const viewContainerRef: ViewContainerRef = loadedContainerRef.instance.viewContainerRef;
		const componentRef	  : ComponentRef<NgMessageToastComponent> = viewContainerRef.createComponent(NgMessageToastComponent, { index: 0 });

		//2 - set message toast config
		componentRef.instance.placement 	= messageToastInput.placement;
		componentRef.instance.messageToast 	= messageToastInput;

		//3 - subscribe to emitted events
		const toastId: string = Util.Random.randomAlphabeticString(16);
		componentRef.instance.onClose$.subscribe(() => { this.close(toastId); });
		componentRef.instance.onCloseAll$.subscribe(() => this.closeAll());

		//4 - add the toast to the the component instance
		NgMessageToastAssistant.loadedComponents.set(toastId, componentRef);
		return componentRef.instance;
	}


	/**------------------------------------------------------
	 * Get and Load Toast Containers
	 */
	loadToastContainers(): void {
		for (const placement of Object.values(EnumNgToastPlacement)) {

			//0 - check if the container is already loaded
			const loadedContainerRef: ComponentRef<NgMessageContainerComponent> = NgMessageToastAssistant.loadedContainers.get(placement)!;
			if (loadedContainerRef && Util.Basic.isDefined(loadedContainerRef)) continue;

			//1 - create the component using the component factory
			// > Note: four of these containers are created, one for each corner. These are then holding the message toasts.
			const componentRef: ComponentRef<NgMessageContainerComponent> = createComponent(NgMessageContainerComponent, {
				elementInjector		: this.injector,
				environmentInjector	: this.appRef.injector
			});
			this.appRef.attachView(componentRef.hostView);

			//2 - get DOM element from component
			const domElem: HTMLElement = (componentRef.hostView as any).rootNodes[0] as HTMLElement;

			//3 - append DOM element to the body
			this.document.body.appendChild(domElem);
			componentRef.onDestroy(() => this.document.body.removeChild(domElem));

			//4 - add to loaded containers
			NgMessageToastAssistant.loadedContainers.set(placement, componentRef);
			componentRef.setInput('placement', placement);
		}
	}
}
