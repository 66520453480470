/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgProductTourTheme {
	Light 			= 'light',
	Dark  			= 'dark',
	DimmedDark 		= 'dimmed_dark'
}


/**------------------------------------------------------
 * Product Tour Configuration Interfaces
 */
export interface INgProductTour {
	tourSteps	 			: INgProductTourStep[];
	showTourProgressBullets : boolean;
	showCloseButton 		: boolean;
	hooks			   	   ?: Partial<INgProductTourHook>;
}

export interface INgProductTourStep {
	id				: string;
	pageUrl			: string;
	hint			: {
		title   	: string;
		message 	: string;					// can be html text (for example for bullet point)
		image  	   ?: string;
		actions	   ?: INgProductTourAction[];
	};
	hooks		   ?: Partial<INgProductTourStepHook>;
}

export interface INgProductTourAction {
	buttonText		: string;
	execute			: () => void;
}

interface INgProductTourStepHook {
	onStepLoad 	   	: () => Promise<void> | void;
	beforeStep 	   	: () => Promise<void> | void;
	afterStep  	   	: () => Promise<void> | void;
	onClose		   	: () => Promise<void> | void;
}

interface INgProductTourHook {
	onStart  	   	: () => void;
	onClose		   	: () => void; 				// return step number for resuming the product tour
	onComplete 	   	: () => void;
}


/**------------------------------------------------------
 * Helper Interface
 */
export interface INgTourStepIndexed extends INgProductTourStep {
	index 	: number;
}
