import { EnumFileType } from '@libs/constants';

import { EnumPodImageColor, EnumPodImageColorType } from '../../app-constants/platforms/print-on-demand.constants';


/**------------------------------------------------------
 * Image Data
 */
export interface IPodImage {
	flyId			: string;
	mimeType		: EnumFileType;
	fileName		: string;
	imgUrl          : IImageUrl;
	resolution      : IImageResolution;
	colors          : IImageColors;
	pHash			: string;
	createdAt	    : Date;
	updatedAt	    : Date;
}


/**------------------------------------------------------
 * Divided Parts of the Interface
 */
export interface IImageUrl {
	icon	    	: string;
	preview	    	: string;
	original    	: string;
}

export interface IImageResolution {
	width       	: number;
	height      	: number;
}

export interface IImageColors {
	dominant    	: EnumPodImageColor;
	avg         	: string;
	colorType   	: EnumPodImageColorType;
	colorList		: IListColor[];
}

export interface IListColor {
	name			: EnumPodImageColor;
	percentage		: number;
}
