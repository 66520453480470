import { Util } from '@libs/utilities/util';

import { AbstractStorage } from './object-storage.interface';


/**------------------------------------------------------
 * Helper for Saving & Loading
 * ---------------------------
 * Info: the values are stored in the local storage as
 * objects, the object is converted into a base64 string.
 */
export class ObjectStorage<IState extends object> {


	static create<IState extends object>(storageName: string, initialObject: IState, storage: AbstractStorage): ObjectStorage<IState> {
		return new ObjectStorage(storageName, initialObject, storage);
	}

	private constructor(
		private storageName		: string,
		private initialObject	: IState,
		private storage			: AbstractStorage
	) { }


	/**------------------------------------------------------
	 * Load / Save a Object
	 */
	getStorageObject<Key extends keyof IState>(subObjectKey: Key): IState[Key] {

		//0 - get and open the appState object
		const storedObject: IState = this.getStorage();
		if (Util.Basic.isUndefined(storedObject[subObjectKey])) throw new Error(`ObjectStorageHelper => getStorageObject => FATAL ERROR: local storage does not support the TObject for subObjectKey: "${String(subObjectKey)}" (access failed for: storedObject[${String(subObjectKey)}])`);

		//1 - return the requested sub object
		const subObject: IState[Key] = storedObject[subObjectKey];
		return subObject;
	}

	setStorageObject<Key extends keyof IState>(subObjectKey: Key, itemValue: IState[Key]): void {

		//0 - prepare the new storage state (check if element is supported)
		const newState: IState = Util.Basic.deepAssignPartial(Util.Basic.deepCopy(this.initialObject), this.getStorage());
		if (Util.Basic.isUndefined(newState[subObjectKey])) throw new Error(`ObjectStorageHelper => setStorageItem => FATAL ERROR: local storage does not support the value for subObjectKey: "${String(subObjectKey)}" (access failed for: newState[${String(subObjectKey)}])`);
		newState[subObjectKey] = itemValue;

		//1 - save the values
		const newStateBase64: string = Util.String.toBase64(Util.Basic.stringifyObject(newState));
		this.storage.setItem(this.storageName, newStateBase64);
	}


	/**------------------------------------------------------
	 * Load / Save a Item of an Object
	 */
	getStorageItem<Key1 extends keyof IState, Key2 extends keyof IState[Key1]>(subObjectKey: Key1, itemKey: Key2): IState[Key1][Key2] | null {

		//0 - get and open the appState object
		const storedObject: IState = this.getStorage();
		if (Util.Basic.isUndefined(storedObject?.[subObjectKey]?.[itemKey])) return null;

		//1 - return the requested item
		const item: IState[Key1][Key2] = storedObject[subObjectKey][itemKey];
		return item;
	}

	setStorageItem<Key1 extends keyof IState, Key2 extends keyof IState[Key1]>(subObjectKey: Key1, itemKey: Key2, itemValue: IState[Key1][Key2]): void {

		//0 - prepare the new storage state (check if element is supported)
		const newState: IState = Util.Basic.deepAssignPartial(Util.Basic.deepCopy(this.initialObject), this.getStorage());
		if (Util.Basic.isUndefined(newState[subObjectKey][itemKey])) throw new Error(`ObjectStorageHelper => setStorageItem => FATAL ERROR: local storage does not support the value for subObjectKey: "${String(subObjectKey)}" (access failed for: newState[${String(subObjectKey)}])`);
		newState[subObjectKey][itemKey] = itemValue;

		//1 - save the values
		const newStateBase64: string = Util.String.toBase64(Util.Basic.stringifyObject(newState));
		this.storage.setItem(this.storageName, newStateBase64);
	}


	/**------------------------------------------------------
	 * Load values in Local Storage
	 */
	private getStorage(): IState {

		//0 - try to load the saved object (if it does not exist return initial one)
		const stateString: string | null = this.storage.getItem(this.storageName);
		if (!stateString) return this.initialObject;

		//1 - convert the string to an object
		const stateObject: IState = Util.Basic.parseJson<IState>(Util.String.fromBase64(stateString));

		//2 - did anything in the state changed? (are all keys in the state still the same)
		if (!Util.Object.compareKeys(stateObject, this.initialObject)) return this.initialObject;
		return stateObject;
	}
}
