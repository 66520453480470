import { UtilBasic } from './util-basic';


/**------------------------------------------------------
 * Cryptographic Utilities
 * -----------------------
 * > Info: Containing all quick implementations of the
 * > basic functionalities like hashing, encryption, ...
 */
export class UtilCrypto {

	constructor(
		private utilBasic	: UtilBasic
	) {}


	/**------------------------------------------------------
	 * Simple Hashing function
	 * > hashLength - is provided as number of bytes (2 is 16, ...)
	 * > source (quickHash)	   : https://stackoverflow.com/questions/6122571/quick-non-secure-hash-function-for-javascript
	 * > source (hashAsNumber) : https://decipher.dev/30-seconds-of-typescript/docs/sdbm/
  	 */
	quickHash(object: string, hashLength: number = 2): string {
		const objectString: string = this.utilBasic.stringifyObject(object);
		const hashValue   : any[]  = [];
		for (let i: number = 0; i < hashLength; i++) {
			hashValue.push(i * 268803292);
		}
		for (let i: number = 0; i < objectString.length; i++) {
			for (let c: number = 0; c < hashValue.length; c++) {
				hashValue[c] = (hashValue[c] << 13) - (hashValue[c] >> 19);
				hashValue[c] += objectString.charCodeAt(i) << (hashValue[c] % 24);
				hashValue[c] = hashValue[c] & hashValue[c];
			}
		}
		for (let i: number = 0; i < hashValue.length; i++) {
			hashValue[i] = this.toHex(hashValue[i] as number);
		}
		return hashValue.join('');
	}

	quickHashObject(object: object, hashLength: number = 2): string {
		const objString: string = this.utilBasic.stringifyObject(object).replace(/[^a-zA-Z0-9]/g, '').replace(/\s+/g, '').trim();
		return this.quickHash(objString, hashLength);
	}
	quickHashObjects(objects: object[], hashLength: number = 2): string {
		let objString: string = '';
		for (const object of objects) {
			objString += this.utilBasic.stringifyObject(object).replace(/[^a-zA-Z0-9]/g, '').replace(/\s+/g, '').trim();
		}
		return this.quickHash(objString, hashLength);
	}

	hashAsNumber(value: string): number {

		//0 - hash the value
		const numberHash: number = value.split('').reduce(
			(hashCode: number, currentVal: string) => (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode), 0
		);

		//1 - convert to positive
		return Math.abs(numberHash);
	}


	/**------------------------------------------------------
	 * Simple Encryption (Not Secure)
	 */
	quickCipher(text: string, salt: string = ''): string {
		const textToChars 	 : Function = (textParam: string) => textParam.split('').map((c: string) => c.charCodeAt(0));
		const byteHex 		 : Function = (n: any)	  => `0${Number(n).toString(16)}`.substr(-2);
		const applySaltToChar: Function = (code: any) => textToChars(salt).reduce((a: number, b: number) => a ^ b, code);

		return text.split('')
			.map(textToChars as any)
			.map(applySaltToChar as any)
			.map(byteHex as any)
			.join('');
	}

	quickDecipher(cipherText: string, salt: string = ''): string {
		const textToChars 	 : Function = (text: string) => text.split('').map((c: string) => c.charCodeAt(0));
		const applySaltToChar: Function = (code: any) => textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
		return cipherText.match(/.{1,2}/g)!
			.map((hex: string) => parseInt(hex, 16))
			.map(applySaltToChar as any)
			.map((charCode: unknown) => String.fromCharCode(charCode as any))
			.join('');
	}


	/**------------------------------------------------------
	 * Manipulations
	 */
	obfuscateBy20Percent(value: number): number {
		return this.obfuscateByPercent(value, 5  /* 20% divination */);
	}
	obfuscateBy10Percent(value: number): number {
		return this.obfuscateByPercent(value, 10 /* 10% divination */);
	}
	obfuscateBy5Percent(value: number): number {
		return this.obfuscateByPercent(value, 20 /* 5% divination */);
	}

	private obfuscateByPercent(value: number, divinationDivider: number): number {

		//0 - don't manipulate -1
		if (value === -1) return value;

		//1 - get hash factor
		const deterministicHashFactor: number = this.getDeterministicHashFactor(value);
		if (deterministicHashFactor < 0 || deterministicHashFactor > 1) throw new Error(`UtilCrypto => manipulateBy10Percent => FATAL ERROR: factor of "${deterministicHashFactor}" is invalid, it has to be "0 < factor < 1"`);

		//2 - manipulate the data
		const manipulation: number = (value / divinationDivider) * deterministicHashFactor;
		return Number.isInteger(value)
			? Math.round(value + manipulation)
			: value + manipulation;
	}

	private getDeterministicHashFactor<T extends number | string | boolean>(seed: T): number {
		return Math.round(this.hashAsNumber(this.quickHash(`${seed}`))) % 100 / 100;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private toHex(value: number): string {
		let ret: string = ((value < 0 ? 0x8 : 0) + ((value >> 28) & 0x7)).toString(16) + (value & 0xfffffff).toString(16);
		while (ret.length < 8) ret = `0${ret}`;
		return ret;
	}
}
