import { BehaviorSubject } from 'rxjs';

import { IUserInfo } from '../../../../requests/shared/user/user.request';


/**------------------------------------------------------
 * User Info
 */
export class UserStateHelper {

	//** User Info */
	private readonly userInfo$: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>({
		firstname	: '',
		lastname	: '',
		email		: '',
		avatar		: 6,
		roles		: []
	});


	/**------------------------------------------------------
	 * User Access Helper
	 */
	getUserInfo(): BehaviorSubject<IUserInfo> {
		return this.userInfo$;
	}

	setUserInfo(userInfo: IUserInfo): void {
		this.userInfo$.next(userInfo);
	}

	isDevUser(): boolean {
		return this.userInfo$.value.roles.includes('dev');
	}

	resetUser(): void {
		this.setUserInfo({
			firstname	: '',
			lastname	: '',
			email		: '',
			avatar		: 6,
			roles		: []
		});
	}
}
