import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';


/**------------------------------------------------------
 * TM Listing Check Options
 */
export enum EnumTmCheckOptions {

	//** TM Search Options */
	// ...

	//** TM Check Options */
	OpenTmSearch 	= 'tm_option_open_tm_search',
	OpenWatchlist 	= 'tm_option_open_watchlist',
	OpenWhitelist 	= 'tm_option_open_whitelist',
	OpenBlacklist 	= 'tm_option_open_blacklist',
	ResetFilters 	= 'tm_option_reset_filters',
	AddWatchlist 	= 'tm_option_add_watchlist',
	ReplaceText 	= 'tm_option_replace_text',
	AddBlacklist	= 'tm_option_add_blacklist'
}


/**------------------------------------------------------
 * Tm Limitations for Print On Demand
 */
export const TRADEMARKS_POD_CHECK_LIMITATIONS: ITmCheckLimitations[] = [

	// ---------------------- free version
	{
		modules			: [EnumSubscriptionModule.FreeVersion],
		excludeTrademark: {
			tmCheck 	: [
				EnumTmCheckOptions.OpenWatchlist, EnumTmCheckOptions.AddWatchlist,
				EnumTmCheckOptions.OpenWhitelist,
				EnumTmCheckOptions.OpenBlacklist, EnumTmCheckOptions.AddBlacklist
			]
		}
	},

	// ---------------------- research module
	{
		modules			: [EnumSubscriptionModule.Trademark],
		excludeTrademark: {
			tmCheck 	: [
				EnumTmCheckOptions.OpenWhitelist,
				EnumTmCheckOptions.OpenBlacklist, EnumTmCheckOptions.AddBlacklist
			]
		}
	},

	// ---------------------- upload module
	{
		modules			: [
			EnumSubscriptionModule.DesignManagement25GB, 	EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,	EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop, 	EnumSubscriptionModule.UploadPodPro
		],
		excludeTrademark: {
			tmCheck 	: []
		}
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface ITmCheckLimitations {
	modules			: EnumSubscriptionModule[];
	excludeTrademark: {
		tmCheck 	: EnumTmCheckOptions[];
	};
}
