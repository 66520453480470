import { ISecureSocketSecurityOptions } from '@domains/security/shared';

import { ISecureServicesConfig } from '../services/security-services.config';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumSecureSocketInjection {
	Config		= 'security_socket_config'
}


/**------------------------------------------------------
 * Socket Security Config
 */
export interface ISecureSocketConfig extends ISecureServicesConfig {
	SOCKET_URL				: string;
	DEFAULT_SOCKET_SECURITY	: ISecureSocketSecurityOptions;
}
