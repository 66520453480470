/**------------------------------------------------------
 * Platform Definitions
 */
export enum EnumPlatform {
	Displate		= 'displate',
	FineArtAmerica	= 'fine_art_america',
	MBA				= 'mba',
	KDP 			= 'kdp',
	Printful		= 'printful',
	Printify		= 'printify',
	Redbubble		= 'redbubble',
	Shirtee			= 'shirtee',
	Society6		= 'society6',
	SpreadshirtUS	= 'spreadshirt_us',
	SpreadshirtEU	= 'spreadshirt_eu',
	Teepublic		= 'teepublic',
	Teespring		= 'teespring',
	Tostadora		= 'tostadora',
	Zazzle			= 'zazzle'
}

export enum EnumPlatformCategory {
	Global		= 'global',
	Displate	= 'displate',
	MBA			= 'mba',
	KDP			= 'kdp'
}

export enum EnumPlatformUploadType {
	Template	= 'template',
	NoTemplate	= 'no_account'
}

export enum EnumWebsiteOption {
	us_EN		= 'us-EN',
	us_MULTI	= 'us-MULTI',
	de_EN		= 'de-EN',
	de_DE		= 'de-DE',
	at_DE		= 'at-DE',
	ch_DE		= 'ch-DE',
	de_FR		= 'de-FR',
	de_ES		= 'de-ES',
	de_IT		= 'de-IT',
	ca_EN		= 'ca-EN',
	au_EN		= 'au-EN',
	gb_EN		= 'gb-EN',
	fr_FR		= 'fr-FR',
	es_ES		= 'es-ES',
	it_IT		= 'it-IT',
	be_NL		= 'be-NL',
	dk_DA		= 'dk-DA',
	ie_EN		= 'ie-EN',
	nl_NL		= 'nl-NL',
	nb_NO		= 'nb-NO',
	pl_PL		= 'pl-PL',
	fi_FI		= 'fi-FI',
	se_SV		= 'se-SV'
}


/**------------------------------------------------------
 * Image
 */
export enum EnumPodImageColorType {
	Light 		= 'light',
	Dark 		= 'dark'
}

export enum EnumPodImageColor {
	Aqua 		= 'aqua',
	Black 		= 'black',
	Blue 		= 'blue',
	Gray 		= 'gray',
	Green 		= 'green',
	Lime 		= 'lime',
	Maroon 		= 'maroon',
	Olive 		= 'olive',
	Orange 		= 'orange',
	Pink 		= 'pink',
	Purple 		= 'purple',
	Red 		= 'red',
	Silver 		= 'silver',
	Teal 		= 'teal',
	White 		= 'white',
	Yellow 		= 'yellow'
}


/**------------------------------------------------------
 * Global Listing Options
 */
export enum EnumGlobalTypeOption {
	Men			= 'men',
	Women		= 'women',
	Youth		= 'youth'
}

export enum EnumGlobalColorOption {
	Black		= 'black',
	White		= 'white',
	Colorful	= 'colorful'
}


/**------------------------------------------------------
 * Displate Listing Options
 */

export enum EnumDisplateArtworkType {
	Illustration	= 'illustration',
	Photography		= 'photography'
}

export enum EnumDisplateCategory {
	Abstract		= 'abstract',
	Animals			= 'animals',
	AnimeAndManga	= 'anime & manga',
	Blueprints		= 'blueprints',
	Books			= 'books',
	Cars			= 'cars',
	Cartoons		= 'cartoons',
	Celebrities		= 'celebrities',
	Cityscapes		= 'cityscapes',
	Comics			= 'comics',
	ContemporaryArt	= 'contemporary art',
	Cute			= 'cute',
	Fantasy			= 'fantasy',
	Fashion			= 'fashion',
	Floral			= 'floral',
	FoodAndKitchen	= 'food & kitchen',
	Funny			= 'funny',
	Gaming			= 'gaming',
	Inspirational	= 'inspirational',
	JapaneseAndAsian = 'japanese & asian',
	KidsRoom		= `kid's room`,
	Landscapes		= 'landscapes',
	Mancave			= 'mancave',
	Maps			= 'maps',
	Military		= 'military',
	Minimalistic	= 'minimalistic',
	Movies			= 'movies',
	Music			= 'music',
	Nature			= 'nature',
	Paintings		= 'paintings',
	Planes			= 'planes',
	PopArt			= 'pop art',
	Retro			= 'retro',
	Space			= 'space',
	Sport			= 'sport',
	TextArt			= 'text art',
	Travel			= 'travel',
	TvShows			= 'tv shows',
	UnitedStates	= 'united states',
	VintagePoster	= 'vintage posters'
}
