/* cspell:ignore capslock, numlock, intlro, intlro, intlbackslash, intlbackslash, intlyen, intlyen */

/**------------------------------------------------------
 * Control Keyboard Enums
 */
export enum EnumControlKey {
	Alt 	= 'alt_key',
	Control = 'ctrl_key',
	Shift 	= 'shift_key'
}

export const CONTROL_KEY_CODES: Record<EnumControlKey, string> = {
	[EnumControlKey.Alt] 	 : 'altKey',
	[EnumControlKey.Control] : 'ctrlKey',
	[EnumControlKey.Shift] 	 : 'shiftKey'
};

/**------------------------------------------------------
 * Keyboard Enums
 */
export enum EnumKeyboardKey {
	Backspace 	  = 'backspace',
	Tab 		  = 'tab',
	Enter	 	  = 'enter',
	Escape		  = 'escape',
	Space	 	  = 'space',
	PageUp		  = 'page_up',
	PageDown  	  = 'page_down',
	End	   	  	  = 'end',
	Home	  	  = 'home',
	ArrowLeft 	  = 'arrow_left',
	ArrowUp   	  = 'arrow_up',
	ArrowRight	  = 'arrow_right',
	ArrowDown 	  = 'arrow_down',
	Insert		  = 'insert',
	Delete		  = 'delete',

	ShiftLeft	  = 'shift_left',
	ShiftRight	  = 'shift_right',
	ControlLeft   = 'control_left',
	ControlRight  = 'control_right',
	AltLeft	      = 'alt_left',
	AltRight	  = 'alt_right',
	CapsLock	  = 'caps_lock',
	OsLeft		  = 'os_left',
	OsRight	      = 'os_right',

	NumLock	     = 'num_lock',
	Semicolon	 = 'semicolon',
	Equal		 = 'equal',
	Comma		 = 'comma',
	Minus		 = 'minus',
	Period		 = 'period',
	Slash		 = 'slash',
	Backquote	 = 'backquote',
	IntlRo		 = 'intl_ro',
	BracketLeft  = 'bracket_left',
	Backslash	 = 'backslash',
	BracketRight = 'bracket_right',
	Quote		 = 'quote',
	IntlBackslash = 'intl_backslash',
	IntlYen	     = 'intl_yen',

	Digit0 		= 'digit0',
	Digit1 		= 'digit1',
	Digit2 		= 'digit2',
	Digit3 		= 'digit3',
	Digit4 		= 'digit4',
	Digit5 		= 'digit5',
	Digit6 		= 'digit6',
	Digit7 		= 'digit7',
	Digit8 		= 'digit8',
	Digit9 		= 'digit9',

	Numpad0		= 'numpad0',
	Numpad1		= 'numpad1',
	Numpad2		= 'numpad2',
	Numpad3		= 'numpad3',
	Numpad4		= 'numpad4',
	Numpad5		= 'numpad5',
	Numpad6		= 'numpad6',
	Numpad7		= 'numpad7',
	Numpad8		= 'numpad8',
	Numpad9		= 'numpad9',

	KeyA		= 'key_a',
	KeyB		= 'key_b',
	KeyC		= 'key_c',
	KeyD		= 'key_d',
	KeyE		= 'key_e',
	KeyF		= 'key_f',
	KeyG		= 'key_g',
	KeyH		= 'key_h',
	KeyI		= 'key_i',
	KeyJ		= 'key_j',
	KeyK		= 'key_k',
	KeyL		= 'key_l',
	KeyM		= 'key_m',
	KeyN		= 'key_n',
	KeyO		= 'key_o',
	KeyP		= 'key_p',
	KeyQ		= 'key_q',
	KeyR		= 'key_r',
	KeyS		= 'key_s',
	KeyT		= 'key_t',
	KeyU		= 'key_u',
	KeyV		= 'key_v',
	KeyW		= 'key_w',
	KeyX		= 'key_x',
	KeyY		= 'key_y',
	KeyZ		= 'key_z',

	Multiply   	= 'multiply',
	Add			= 'add',
	Decimal		= 'decimal',
	Subtract   	= 'subtract',
	Divide	 	= 'divide',

	F1 			= 'f1',
	F2 			= 'f2',
	F3 			= 'f3',
	F4 			= 'f4',
	F5 			= 'f5',
	F6 			= 'f6',
	F7 			= 'f7',
	F8 			= 'f8',
	F9 			= 'f9',
	F10			= 'f10',
	F11			= 'f11',
	F12			= 'f12',
	F13			= 'f13',
	F14			= 'f14',
	F15			= 'f15',
	F16			= 'f16',
	F17			= 'f17',
	F18			= 'f18',
	F19			= 'f19',
	F20			= 'f20',
	F21			= 'f21',
	F22			= 'f22',
	F23			= 'f23',
	F24			= 'f24'
}


/**------------------------------------------------------
 * Keyboard Key Definitions
 * ------------------------
 * Sources
 * > KeyCodes : https://github.com/eclipse-theia/theia/blob/master/packages/core/src/browser/keyboard/keys.ts
 * > Info	  : Please use the "event.code" as the other approaches are deprecated
 */
export const KEY_CODES: Record<EnumKeyboardKey, IKeyCode> = {

	// Action keys
	[EnumKeyboardKey.Backspace] 	: { code: 'Backspace', 			keyCode: 8, 	keyString: 'Backspace',		easyString: 'backspace' },
	[EnumKeyboardKey.Tab] 			: { code: 'Tab', 				keyCode: 9, 	keyString: 'Tab',			easyString: 'tab' },
	[EnumKeyboardKey.Enter] 		: { code: 'Enter', 				keyCode: 13, 	keyString: 'Enter',			easyString: 'enter' },
	[EnumKeyboardKey.Escape] 		: { code: 'Escape',				keyCode: 27, 	keyString: 'Escape',		easyString: 'escape' },
	[EnumKeyboardKey.Space] 		: { code: 'Space', 				keyCode: 32, 	keyString: ' ',				easyString: 'space' },
	[EnumKeyboardKey.PageUp] 		: { code: 'PageUp', 			keyCode: 33, 	keyString: 'PageUp',		easyString: 'pageup' },
	[EnumKeyboardKey.PageDown] 		: { code: 'PageDown', 			keyCode: 34, 	keyString: 'PageDown',		easyString: 'pagedown' },
	[EnumKeyboardKey.End] 			: { code: 'End', 				keyCode: 35, 	keyString: 'End',			easyString: 'end' },
	[EnumKeyboardKey.Home] 			: { code: 'Home', 				keyCode: 36, 	keyString: 'Home',			easyString: 'home' },
	[EnumKeyboardKey.ArrowLeft] 	: { code: 'ArrowLeft', 			keyCode: 37, 	keyString: 'ArrowLeft',		easyString: 'left' },
	[EnumKeyboardKey.ArrowUp] 		: { code: 'ArrowUp', 			keyCode: 38, 	keyString: 'ArrowUp',		easyString: 'up' },
	[EnumKeyboardKey.ArrowRight] 	: { code: 'ArrowRight', 		keyCode: 39, 	keyString: 'ArrowRight',	easyString: 'right' },
	[EnumKeyboardKey.ArrowDown] 	: { code: 'ArrowDown', 			keyCode: 40, 	keyString: 'ArrowDown',		easyString: 'down' },
	[EnumKeyboardKey.Insert] 		: { code: 'Insert', 			keyCode: 45, 	keyString: 'Help',			easyString: 'insert' },
	[EnumKeyboardKey.Delete] 		: { code: 'Delete', 			keyCode: 46, 	keyString: 'Delete',		easyString: 'delete' },

	[EnumKeyboardKey.ShiftLeft] 	: { code: 'ShiftLeft', 			keyCode: 16, 	keyString: 'Shift',			easyString: 'shift' },
	[EnumKeyboardKey.ShiftRight] 	: { code: 'ShiftRight', 		keyCode: 16, 	keyString: 'Shift',			easyString: 'shift' },
	[EnumKeyboardKey.ControlLeft] 	: { code: 'ControlLeft', 		keyCode: 17, 	keyString: 'Control',		easyString: 'ctrl' },
	[EnumKeyboardKey.ControlRight]  : { code: 'ControlRight', 		keyCode: 17, 	keyString: 'Control',		easyString: 'ctrl' },
	[EnumKeyboardKey.AltLeft] 		: { code: 'AltLeft', 			keyCode: 18, 	keyString: 'Alt',			easyString: 'alt' },
	[EnumKeyboardKey.AltRight] 		: { code: 'AltRight', 			keyCode: 18, 	keyString: 'Alt',			easyString: 'alt' },
	[EnumKeyboardKey.CapsLock] 		: { code: 'CapsLock', 			keyCode: 20, 	keyString: 'CapsLock',		easyString: 'capslock' },
	[EnumKeyboardKey.OsLeft] 		: { code: 'OSLeft', 			keyCode: 91, 	keyString: 'OSLeft',		easyString: 'super' },
	[EnumKeyboardKey.OsRight] 		: { code: 'OSRight', 			keyCode: 92, 	keyString: 'OSRight',		easyString: 'super' },

	[EnumKeyboardKey.NumLock] 		: { code: 'NumLock', 			keyCode: 144, 	keyString: 'Clear',			easyString: 'numlock' },
	[EnumKeyboardKey.Semicolon] 	: { code: 'Semicolon', 			keyCode: 186, 	keyString: ';',				easyString: ';' },
	[EnumKeyboardKey.Equal] 		: { code: 'Equal', 				keyCode: 187, 	keyString: '=',				easyString: '=' },
	[EnumKeyboardKey.Comma] 		: { code: 'Comma', 				keyCode: 188, 	keyString: ',',				easyString: ',' },
	[EnumKeyboardKey.Minus] 		: { code: 'Minus', 				keyCode: 189, 	keyString: '-',				easyString: '-' },
	[EnumKeyboardKey.Period] 		: { code: 'Period', 			keyCode: 190, 	keyString: '.',				easyString: '.' },
	[EnumKeyboardKey.Slash] 		: { code: 'Slash', 				keyCode: 191, 	keyString: '/',				easyString: '/' },
	[EnumKeyboardKey.Backquote] 	: { code: 'Backquote', 			keyCode: 192, 	keyString: '`',				easyString: '`' },
	[EnumKeyboardKey.IntlRo] 		: { code: 'IntlRo', 			keyCode: 193, 	keyString: 'intlro',		easyString: 'intlro' },
	[EnumKeyboardKey.BracketLeft] 	: { code: 'BracketLeft', 		keyCode: 219, 	keyString: '[',				easyString: '[' },
	[EnumKeyboardKey.Backslash] 	: { code: 'Backslash', 			keyCode: 220, 	keyString: '\\',			easyString: '\\' },
	[EnumKeyboardKey.BracketRight]  : { code: 'BracketRight', 		keyCode: 221, 	keyString: ']',				easyString: ']' },
	[EnumKeyboardKey.Quote] 		: { code: 'Quote', 				keyCode: 222, 	keyString: '\'',			easyString: '\'' },
	[EnumKeyboardKey.IntlBackslash] : { code: 'IntlBackslash', 		keyCode: 229, 	keyString: 'intlbackslash',	easyString: 'intlbackslash' },
	[EnumKeyboardKey.IntlYen] 		: { code: 'IntlYen', 			keyCode: 255, 	keyString: 'intlyen',		easyString: 'intlyen' },

	// Number keys
	[EnumKeyboardKey.Digit0]		: { code: 'Digit0', 			keyCode: 48, 	keyString: '0',			easyString: '0' },
	[EnumKeyboardKey.Digit1]		: { code: 'Digit1', 			keyCode: 49, 	keyString: '1',			easyString: '1' },
	[EnumKeyboardKey.Digit2]		: { code: 'Digit2', 			keyCode: 50, 	keyString: '2',			easyString: '2' },
	[EnumKeyboardKey.Digit3]		: { code: 'Digit3', 			keyCode: 51, 	keyString: '3',			easyString: '3' },
	[EnumKeyboardKey.Digit4]		: { code: 'Digit4', 			keyCode: 52, 	keyString: '4',			easyString: '4' },
	[EnumKeyboardKey.Digit5]		: { code: 'Digit5', 			keyCode: 53, 	keyString: '5',			easyString: '5' },
	[EnumKeyboardKey.Digit6]		: { code: 'Digit6', 			keyCode: 54, 	keyString: '6',			easyString: '6' },
	[EnumKeyboardKey.Digit7]		: { code: 'Digit7', 			keyCode: 55, 	keyString: '7',			easyString: '7' },
	[EnumKeyboardKey.Digit8]		: { code: 'Digit8', 			keyCode: 56, 	keyString: '8',			easyString: '8' },
	[EnumKeyboardKey.Digit9]		: { code: 'Digit9', 			keyCode: 57, 	keyString: '9',			easyString: '9' },

	[EnumKeyboardKey.Numpad0]   	: { code: 'Numpad0', 			keyCode: 96,	keyString: '0',			easyString: '0' },
	[EnumKeyboardKey.Numpad1]   	: { code: 'Numpad1', 			keyCode: 97,	keyString: '1',			easyString: '1' },
	[EnumKeyboardKey.Numpad2]   	: { code: 'Numpad2', 			keyCode: 98,	keyString: '2',			easyString: '2' },
	[EnumKeyboardKey.Numpad3]   	: { code: 'Numpad3', 			keyCode: 99,	keyString: '3',			easyString: '3' },
	[EnumKeyboardKey.Numpad4]   	: { code: 'Numpad4', 			keyCode: 100,	keyString: '4',			easyString: '4' },
	[EnumKeyboardKey.Numpad5]   	: { code: 'Numpad5', 			keyCode: 101,	keyString: '5',			easyString: '5' },
	[EnumKeyboardKey.Numpad6]   	: { code: 'Numpad6', 			keyCode: 102,	keyString: '6',			easyString: '6' },
	[EnumKeyboardKey.Numpad7]   	: { code: 'Numpad7', 			keyCode: 103,	keyString: '7',			easyString: '7' },
	[EnumKeyboardKey.Numpad8]   	: { code: 'Numpad8', 			keyCode: 104,	keyString: '8',			easyString: '8' },
	[EnumKeyboardKey.Numpad9]   	: { code: 'Numpad9', 			keyCode: 105,	keyString: '9',			easyString: '9' },

	// Alphabet (upper-/lowercase have same keycode)
	[EnumKeyboardKey.KeyA] 			: { code: 'KeyA', 				keyCode: 65, 	keyString: 'a',			easyString: 'a' },
	[EnumKeyboardKey.KeyB] 			: { code: 'KeyB', 				keyCode: 66, 	keyString: 'b',			easyString: 'b' },
	[EnumKeyboardKey.KeyC] 			: { code: 'KeyC', 				keyCode: 67, 	keyString: 'c',			easyString: 'c' },
	[EnumKeyboardKey.KeyD] 			: { code: 'KeyD', 				keyCode: 68, 	keyString: 'd',			easyString: 'd' },
	[EnumKeyboardKey.KeyE] 			: { code: 'KeyE', 				keyCode: 69, 	keyString: 'e',			easyString: 'e' },
	[EnumKeyboardKey.KeyF] 			: { code: 'KeyF', 				keyCode: 70, 	keyString: 'f',			easyString: 'f' },
	[EnumKeyboardKey.KeyG] 			: { code: 'KeyG', 				keyCode: 71, 	keyString: 'g',			easyString: 'g' },
	[EnumKeyboardKey.KeyH] 			: { code: 'KeyH', 				keyCode: 72, 	keyString: 'h',			easyString: 'h' },
	[EnumKeyboardKey.KeyI] 			: { code: 'KeyI', 				keyCode: 73, 	keyString: 'i',			easyString: 'i' },
	[EnumKeyboardKey.KeyJ] 			: { code: 'KeyJ', 				keyCode: 74, 	keyString: 'j',			easyString: 'j' },
	[EnumKeyboardKey.KeyK] 			: { code: 'KeyK', 				keyCode: 75, 	keyString: 'k',			easyString: 'k' },
	[EnumKeyboardKey.KeyL] 			: { code: 'KeyL', 				keyCode: 76, 	keyString: 'l',			easyString: 'l' },
	[EnumKeyboardKey.KeyM] 			: { code: 'KeyM', 				keyCode: 77, 	keyString: 'm',			easyString: 'm' },
	[EnumKeyboardKey.KeyN] 			: { code: 'KeyN', 				keyCode: 78, 	keyString: 'n',			easyString: 'n' },
	[EnumKeyboardKey.KeyO] 			: { code: 'KeyO', 				keyCode: 79, 	keyString: 'o',			easyString: 'o' },
	[EnumKeyboardKey.KeyP] 			: { code: 'KeyP', 				keyCode: 80, 	keyString: 'p',			easyString: 'p' },
	[EnumKeyboardKey.KeyQ] 			: { code: 'KeyQ', 				keyCode: 81, 	keyString: 'q',			easyString: 'q' },
	[EnumKeyboardKey.KeyR] 			: { code: 'KeyR', 				keyCode: 82, 	keyString: 'r',			easyString: 'r' },
	[EnumKeyboardKey.KeyS] 			: { code: 'KeyS', 				keyCode: 83, 	keyString: 's',			easyString: 's' },
	[EnumKeyboardKey.KeyT] 			: { code: 'KeyT', 				keyCode: 84, 	keyString: 't',			easyString: 't' },
	[EnumKeyboardKey.KeyU] 			: { code: 'KeyU', 				keyCode: 85, 	keyString: 'u',			easyString: 'u' },
	[EnumKeyboardKey.KeyV] 			: { code: 'KeyV', 				keyCode: 86, 	keyString: 'v',			easyString: 'v' },
	[EnumKeyboardKey.KeyW] 			: { code: 'KeyW', 				keyCode: 87, 	keyString: 'w',			easyString: 'w' },
	[EnumKeyboardKey.KeyX] 			: { code: 'KeyX', 				keyCode: 88, 	keyString: 'x',			easyString: 'x' },
	[EnumKeyboardKey.KeyY] 			: { code: 'KeyY', 				keyCode: 89, 	keyString: 'y',			easyString: 'y' },
	[EnumKeyboardKey.KeyZ] 			: { code: 'KeyZ', 				keyCode: 90,	keyString: 'z',			easyString: 'z' },

	// Mathematical functions
	[EnumKeyboardKey.Multiply]  	: { code: 'NumpadMultiply', 	keyCode: 106, 	keyString: '*',			easyString: '*' },
	[EnumKeyboardKey.Add] 			: { code: 'NumpadAdd', 			keyCode: 107, 	keyString: '+',			easyString: '+' },
	[EnumKeyboardKey.Decimal]   	: { code: 'NumpadDecimal', 		keyCode: 108, 	keyString: '.',			easyString: '.' },
	[EnumKeyboardKey.Subtract]  	: { code: 'NumpadSubtract', 	keyCode: 109, 	keyString: '-',			easyString: '-' },
	[EnumKeyboardKey.Divide] 		: { code: 'NumpadDivide', 		keyCode: 111, 	keyString: '/',			easyString: '/' },

	// Function keys
	[EnumKeyboardKey.F1] 			: { code: 'F1', 				keyCode: 112, 	keyString: 'f1', 		easyString: 'f1' },
	[EnumKeyboardKey.F2] 			: { code: 'F2', 				keyCode: 113, 	keyString: 'f2', 		easyString: 'f2' },
	[EnumKeyboardKey.F3] 			: { code: 'F3', 				keyCode: 114, 	keyString: 'f3', 		easyString: 'f3' },
	[EnumKeyboardKey.F4] 			: { code: 'F4', 				keyCode: 115, 	keyString: 'f4', 		easyString: 'f4' },
	[EnumKeyboardKey.F5] 			: { code: 'F5', 				keyCode: 116, 	keyString: 'f5', 		easyString: 'f5' },
	[EnumKeyboardKey.F6] 			: { code: 'F6', 				keyCode: 117, 	keyString: 'f6', 		easyString: 'f6' },
	[EnumKeyboardKey.F7] 			: { code: 'F7', 				keyCode: 118, 	keyString: 'f7', 		easyString: 'f7' },
	[EnumKeyboardKey.F8] 			: { code: 'F8', 				keyCode: 119, 	keyString: 'f8', 		easyString: 'f8' },
	[EnumKeyboardKey.F9] 			: { code: 'F9', 				keyCode: 120, 	keyString: 'f9', 		easyString: 'f9' },
	[EnumKeyboardKey.F10] 			: { code: 'F10', 				keyCode: 121, 	keyString: 'f10', 		easyString: 'f10' },
	[EnumKeyboardKey.F11] 			: { code: 'F11', 				keyCode: 122, 	keyString: 'f11', 		easyString: 'f11' },
	[EnumKeyboardKey.F12] 			: { code: 'F12', 				keyCode: 123, 	keyString: 'f12', 		easyString: 'f12' },
	[EnumKeyboardKey.F13] 			: { code: 'F13', 				keyCode: 124, 	keyString: 'f13', 		easyString: 'f13' },
	[EnumKeyboardKey.F14] 			: { code: 'F14', 				keyCode: 125, 	keyString: 'f14', 		easyString: 'f14' },
	[EnumKeyboardKey.F15] 			: { code: 'F15', 				keyCode: 126, 	keyString: 'f15', 		easyString: 'f15' },
	[EnumKeyboardKey.F16] 			: { code: 'F16', 				keyCode: 127, 	keyString: 'f16', 		easyString: 'f16' },
	[EnumKeyboardKey.F17] 			: { code: 'F17', 				keyCode: 128, 	keyString: 'f17', 		easyString: 'f17' },
	[EnumKeyboardKey.F18] 			: { code: 'F18', 				keyCode: 129, 	keyString: 'f18', 		easyString: 'f18' },
	[EnumKeyboardKey.F19] 			: { code: 'F19', 				keyCode: 130, 	keyString: 'f19', 		easyString: 'f19' },
	[EnumKeyboardKey.F20] 			: { code: 'F20', 				keyCode: 131, 	keyString: 'f20', 		easyString: 'f20' },
	[EnumKeyboardKey.F21] 			: { code: 'F21', 				keyCode: 132, 	keyString: 'f21', 		easyString: 'f21' },
	[EnumKeyboardKey.F22] 			: { code: 'F22', 				keyCode: 133, 	keyString: 'f22', 		easyString: 'f22' },
	[EnumKeyboardKey.F23] 			: { code: 'F23', 				keyCode: 134, 	keyString: 'f23', 		easyString: 'f23' },
	[EnumKeyboardKey.F24] 			: { code: 'F24', 				keyCode: 135, 	keyString: 'f24', 		easyString: 'f24' }
};


//** Interfaces --------------------------------- */
export interface IKeyCode {
	code		: string;
	keyCode		: number;
	keyString	: string;
	easyString	: string;
}
