import { Observable, map } from 'rxjs';
import { Util } from '@libs/utilities/util';
import { ValidatorSchemaRef } from '@libs/utilities/validator';
import { ISecureHttpSecurityOptions } from '@domains/security/shared';

import { ISecureHttpConfig } from '../../secure-http.config';
import { SecureTimeTokenService } from '../../../services/time-token.service';
import { SecureEncryptionService } from '../../../services/encryption.service';
import { AbstractSecureRequest } from '../../../shared/request.abstract';


export abstract class AbstractSecureHttpRequest extends AbstractSecureRequest<AbstractSecureHttpRequest> {

	//** Helper Variables */
	protected requestBody	 : object | string 	= null!;
	protected responseType	 : string			= 'json';

	constructor(
		protected config					: ISecureHttpConfig,
		protected override endpoint			: string,
		protected override timeTokenService	: SecureTimeTokenService,
		protected override encryptionService: SecureEncryptionService
	) {
		super(config.SERVER_URL, endpoint, timeTokenService, encryptionService);
	}


	/**------------------------------------------------------
	 * Send Request
	 */
	abstract 	  send<T extends object | string>(): Observable<T>;
	abstract streamSse<T extends object | string>(): Observable<T>;


	/**------------------------------------------------------
	 * Setters
	 */
	setBody<T extends object>(body: T, bodySchema: ValidatorSchemaRef<T>): AbstractSecureHttpRequest {

		//0 - validate the body
		this.validateBySchemaAndThrow('body', body, bodySchema);

		//1 - set the body
		this.requestBody = body;
		return this;
	}

	setResponseType(responseType: string): AbstractSecureHttpRequest {
		if (Util.String.isEmpty(responseType)) throw new Error(`HttpRequestAbstract => setResponseTye => FATAL ERROR: responseType is empty (value: ${responseType})`);
		this.responseType = responseType;
		return this;
	}

	setSecurity(options: Partial<ISecureHttpSecurityOptions>): AbstractSecureHttpRequest {

		//0 - check options
		if (Util.Basic.isUndefined(options)) throw new Error(`HttpRequestAbstract => secure => FATAL ERROR: security options are undefined (value: ${options})`);

		//1 - set security options, by assigning the custom one to the default ones
		this.securityOptions = Util.Function.assignOptions(this.getSecureDefault(), options);

		//2 - return this
		return this;
	}


	/**------------------------------------------------------
	 * Prepare Response Type and Body
	 */
	protected prepareResponseType(): string {

		//0 - response type is text if encryption is enabled
		if (this.securityOptions?.encryption.response) return 'text';

		//1 - return response type
		return this.responseType;
	}

	protected prepareRequestBody(): string | object {
		if (Util.Object.isEmpty(this.securityOptions)) return this.requestBody;
		return this.encryptionService.encryptData<object | string>(this.securityOptions, this.requestBody);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	protected decryptResponse<T>(request$: Observable<T | string>): Observable<T> {
		return request$.pipe(map((response: T | string) => {

			//0 - is any encryption required?
			if (Util.Object.isEmpty(this.securityOptions)) return response as T;

			//1 - handle the decryption
			return this.encryptionService.decryptData<T>(this.securityOptions, response);
		}));
	}

	protected getSecureDefault(): ISecureHttpSecurityOptions {

		//0 - define the default security
		const defaultSecurity: ISecureHttpSecurityOptions = {
			timeToken			: this.config.HTTP_SECURITY.TIME_TOKEN,
			encryption			: {
				request			: this.config.HTTP_SECURITY.ENCRYPTION,
				response		: this.config.HTTP_SECURITY.ENCRYPTION
			}
		};

		//1 - return the default security
		return defaultSecurity;
	}
}
