import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchLinkUrlConfig } from '../../data/search-links.interface';


/**------------------------------------------------------
 * Google Images Search Url
 */
export class GoogleImagesSearch implements ISearchLinks {

	//** Configurations */
	private readonly GOOGLE_IMAGES_CONFIG: ISearchLinkUrlConfig = {
		home		: 'https://images.google.com',
		login		: 'https://accounts.google.com/signin/v2',
		search		: 'https://www.google.com//search?q=[SEARCH]&tbm=isch'
	};


	//** Home & Login */
	home() : string { return this.GOOGLE_IMAGES_CONFIG.home; }
	login(): string { return this.GOOGLE_IMAGES_CONFIG.login; }

	//** Search */
	search(searchQuery: string): string {

		//0 - if no search string existing then return main or home page
		if (Util.String.isEmpty(searchQuery)) return this.home();

		//1 - else return the url with search query
		return this.GOOGLE_IMAGES_CONFIG.search.replace('[SEARCH]', searchQuery);
	}
}
