import { UtilArray } from './utilities/util-array';
import { UtilArrayGroup } from './utilities/util-array-group';
import { UtilBasic } from './utilities/util-basic';
import { UtilBoolean } from './utilities/util-boolean';
import { UtilColor } from './utilities/util-color';
import { UtilCrypto } from './utilities/util-crypto';
import { UtilDate } from './utilities/util-date';
import { UtilEnum } from './utilities/util-enum';
import { UtilError } from './utilities/util-error';
import { UtilHelper } from './utilities/util-helper';
import { UtilHttp } from './utilities/util-http';
import { UtilInterface } from './utilities/util-interface';
import { UtilKeywords } from './utilities/util-keywords';
import { UtilLog } from './utilities/util-log';
import { UtilMapper } from './utilities/util-mapping';
import { UtilPromise } from './utilities/util-promise';
import { UtilFunction } from './utilities/util-function';
import { UtilNumber } from './utilities/util-number';
import { UtilObject } from './utilities/util-object';
import { UtilPassword } from './utilities/util-password';
import { UtilRuntime } from './utilities/util-runtime';
import { UtilRandom } from './utilities/util-random';
import { UtilRegex } from './utilities/util-regex';
import { UtilRetry } from './utilities/util-retry';
import { UtilString } from './utilities/util-string';
import { UtilText } from './utilities/util-text';
import { UtilUuid } from './utilities/util-uuid';
import { UtilPipeline } from './utilities/util-pipeline';
import { UtilUserAgent } from './utilities/util-user-agent';
import { UtilConvert } from './utilities/util-convert';
import { UtilLocale } from './utilities/util-locale';


/**------------------------------------------------------
 * Utilities
 * ---------
 * > Dependencies: none dependencies
 */
export class Util {

	/**------------------------------------------------------
	 * Simple Utilities
	 */
	static readonly Basic		: UtilBasic 	= new UtilBasic();
	static readonly String		: UtilString 	= new UtilString(Util.Basic);
	static readonly Number		: UtilNumber 	= new UtilNumber(Util.Basic, Util.String);
	static readonly Boolean		: UtilBoolean 	= new UtilBoolean(Util.Basic);
	static readonly Helper		: UtilHelper 	= new UtilHelper(Util.String);


	/**------------------------------------------------------
	 * Complex Utilities
	 */
	static readonly Array		: UtilArray 	= new UtilArray(Util.Basic);
	static readonly Object		: UtilObject 	= new UtilObject(Util.Basic, Util.String, Util.Array);
	static readonly Date		: UtilDate 		= new UtilDate(Util.String);
	static readonly Enum		: UtilEnum 		= new UtilEnum(Util.Basic, Util.String);
	static readonly Interface	: UtilInterface = new UtilInterface(Util.Array);
	static readonly Function	: UtilFunction 	= new UtilFunction(Util.Basic);
	static readonly Promise		: UtilPromise 	= new UtilPromise(Util.Array, Util.Function);
	static readonly RegExp		: UtilRegex		= new UtilRegex(Util.String);
	static readonly UserAgent	: UtilUserAgent = new UtilUserAgent();


	/**------------------------------------------------------
	 * Functional Utilities
	 */
	static readonly ArrayGroup	: UtilArrayGroup = new UtilArrayGroup();
	static readonly Random		: UtilRandom 	= new UtilRandom();
	static readonly Text		: UtilText 		= new UtilText(Util.Basic, Util.String, Util.Array, Util.Function, Util.RegExp);
	static readonly Http		: UtilHttp 		= new UtilHttp(Util.String, Util.Object, Util.Array);
	static readonly Keywords	: UtilKeywords 	= new UtilKeywords(Util.String, Util.Array, Util.Text);
	static readonly Color		: UtilColor 	= new UtilColor(Util.Basic, Util.String, Util.Number);
	static readonly Password 	: UtilPassword	= new UtilPassword();
	static readonly Mapper 		: UtilMapper	= new UtilMapper();
	static readonly Pipeline 	: UtilPipeline	= new UtilPipeline();
	static readonly Convert		: UtilConvert 	= new UtilConvert(Util.Basic, Util.Number, Util.Enum);


	/**------------------------------------------------------
	 * Cryptography
	 */
	static readonly UUID		: UtilUuid 		= new UtilUuid(Util.String);
	static readonly Crypto 		: UtilCrypto	= new UtilCrypto(Util.Basic);


	/**------------------------------------------------------
	 * Internationalization (i18n)
	 */
	static readonly Locale		: UtilLocale 	= new UtilLocale(Util.Enum);


	/**------------------------------------------------------
	 * Runtime
	 */
	static readonly Runtime		: UtilRuntime 	= new UtilRuntime();
	static readonly Retry		: UtilRetry 	= new UtilRetry(Util.Runtime);
	static readonly Log			: UtilLog 		= new UtilLog(Util.Number);
	static readonly Error		: UtilError 	= new UtilError(Util.Array);
}
