/**------------------------------------------------------
 * Fine Art America Constants
 */
export enum EnumFineArtAmericaCategory {
	DigitalArt		= 'Digital Art',
	CeramicArt		= 'Ceramic Art',
	Drawing			= 'Drawing',
	GlassArt		= 'Glass Art',
	Jewelry			= 'Jewelry',
	MixedMedia		= 'Mixed Media',
	Painting		= 'Painting',
	Pastel			= 'Pastel',
	Photograph		= 'Photograph',
	Pyrography		= 'Pyrography',
	Relief 			= 'Relief',
	Sculpture		= 'Sculpture',
	TapestryTextile = 'Tapestry - Textile'
}

export enum EnumFineArtAmericaProduct {
	Prints			= 'Prints',
	GreetingCards	= 'Greeting Cards',
	ThrowPillows	= 'Throw Pillows',
	DuvetCovers		= 'Duvet Covers',
	ShowerCurtains	= 'Shower Curtains',
	ToteBags		= 'Tote Bags',
	PhoneCases		= 'Phone Cases',
	TShirts			= 'T-Shirts',
	RoundBeachTowels = 'Round Beach Towels',
	Towels			= 'Towels',
	YogaMats		= 'Yoga Mats',
	SpiralNotebooks	= 'Spiral Notebooks',
	FleeceBlankets	= 'Fleece Blankets',
	BatteriesChargers = 'Portable Batteries Chargers',
	CarryAllPouches	= 'Carry-all Pouches',
	WeekenderToteBags = 'Weekender Tote Bags',
	CoffeeMugs		= 'Coffee Mugs',
	Tapestries		= 'Tapestries',
	FaceMasks		= 'Face Masks',
	Puzzles			= 'Puzzles',
	Stickers		= 'Stickers'
}

export enum EnumFineArtAmericaOrientation {
	Horizontal		= 'horizontal',
	Vertical		= 'vertical'
}

export enum EnumFineArtAmericaColors {
	Red				= 'red'
	// ...
}


/**------------------------------------------------------
 * Fine Art America Template
 */
export interface IFineArtAmericaTemplateOptions {
	general 		: {
		artistName	: string;
		medium		: string;
		category	: EnumFineArtAmericaCategory;
		display		: {
			watermark		: boolean;
			fullResolution	: boolean;
			workSafe		: boolean;
		};
		background	: string;						// hex color code
	};
	products		: Array<{
		productName		: EnumFineArtAmericaProduct;
		orientation		: EnumFineArtAmericaOrientation;
		backgroundColor	: EnumFineArtAmericaColors;
	}>;
}
