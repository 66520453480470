/**------------------------------------------------------
 * Displate Tagging Options
 */
export enum EnumDisplateOptions {
	GeneralClearDisplate			= 'displate_general_clear_displate',
	GeneralClearAllDisplate			= 'displate_general_clear_all_displate',
	GeneralCopyDisplateFrom 		= 'displate_general_copy_displate_from',
	GeneralApplyDisplateTo 			= 'displate_general_apply_displate_to',

	TitleCopyTitleFrom 				= 'displate_title_copy_title_from',
	TitleApplyTitleTo	 			= 'displate_title_apply_title_to',
	TitleUppercase					= 'displate_title_uppercase',
	TitleAsListing 					= 'displate_title_as_listing',
	TitleLoadSuggestion 			= 'displate_title_load_suggestion',
	TitleGenerate					= 'displate_title_generate',
	TitleImportFromGlobal 			= 'displate_title_import_from_global',
	TitleImportFromMba 				= 'displate_title_import_from_mba',
	TitleFromFileNames 				= 'displate_title_from_file_names',

	DescCopyDescFrom 				= 'displate_desc_copy_desc_from',
	DescApplyDescTo		 			= 'displate_desc_apply_desc_to',
	DescFromTitle 					= 'displate_desc_from_title',
	DescLoadShortSuggestion 		= 'displate_desc_load_short_suggestion',
	DescGenerate 					= 'displate_desc_generate',
	DescImportFromGlobal 			= 'displate_desc_import_from_global',
	DescImportFromMba 				= 'displate_desc_import_from_mba',

	KeywordsCopyKeywordsFrom 		= 'displate_keywords_copy_keywords_from',
	KeywordsApplyKeywordsTo 		= 'displate_keywords_apply_keywords_to',
	KeywordsAddKeywordsTo 			= 'displate_keywords_add_keywords_to',
	KeywordsExtractFromListings		= 'displate_keywords_extract_from_listing',
	KeywordsLoadSuggestions 		= 'displate_keywords_load_suggestion',
	KeywordsTagGenerator 			= 'displate_keywords_tag_generator',
	KeywordsClear 					= 'displate_keywords_clear',
	KeywordsCopyToClipboard			= 'displate_keywords_copy_to_clipboard',
	KeywordsShuffle 				= 'displate_keywords_shuffle',
	KeywordsShuffleAll 				= 'displate_keywords_shuffle_all',
	KeywordsImportFromGlobal 		= 'displate_keywords_import_from_global',

	CollectionCopyCollectionFrom	= 'displate_collection_copy_collection_from',
	CollectionApplyCollectionTo		= 'displate_collection_apply_collection_to',
	CollectionFirstKeyword 			= 'displate_collection_first_keyword',
	CollectionCopyHistory 			= 'displate_collection_copy_history',
	CollectionExportHistory 		= 'displate_collection_export_history',
	CollectionClearHistory 			= 'displate_collection_clear_history',

	CategoriesCopyCategoriesFrom	= 'displate_categories_copy_categories_from',
	CategoriesApplyCategoriesTo		= 'displate_categories_apply_categories_to',
	CategoriesClear 				= 'displate_categories_clear',
	CategoriesCopyToClipboard		= 'displate_categories_copy_to_clipboard',
	CategoriesByKeywords  			= 'displate_categories_by_keywords',

	BackgroundCopyBackgroundFrom 	= 'displate_background_copy_background_from',
	BackgroundApplyBackgroundTo		= 'displate_background_apply_background_to',
	BackgroundApplyToLight 			= 'displate_background_apply_to_light',
	BackgroundApplyToDark 			= 'displate_background_apply_to_dark',

	ArtworkCopyArtworkFrom 			= 'displate_artwork_copy_artwork_from',
	ArtworkApplyArtworkTo 			= 'displate_artwork_apply_artwork_to'
}
