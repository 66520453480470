import { EnumPodMarketplace } from './platform.constants';


/**------------------------------------------------------
 * Product & Sorting Configurations
 */
export enum EnumSpreadshirtProduct {
	All 		= 'all_products',
	Shirt 		= 'shirt',
	Hoodie 		= 'hoodie',
	VNeck 		= 'v_neck',
	TankTop 	= 'tank_top',
	LongSleeve 	= 'long_sleeve',
	Sweatshirt 	= 'sweatshirt'
}

export enum EnumSpreadshirtSorting {
	Relevance 	= 'relevant',
	Newest 		= 'newest'
}


/**------------------------------------------------------
 * Mapping
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const MAPPING_SPREADSHIRT = {
	[EnumPodMarketplace.All]			: 'All Marketplaces',
	[EnumPodMarketplace.COM]			: 'COM - United States',
	[EnumPodMarketplace.CO_UK]			: 'CO.UK - United Kingdom',
	[EnumPodMarketplace.DE]				: 'DE - Germany',
	[EnumPodMarketplace.FR]				: 'FR - France',
	[EnumPodMarketplace.IT]				: 'IT - Italy',
	[EnumPodMarketplace.ES]				: 'ES - Spain',

	[EnumSpreadshirtProduct.All]		: 'All Products',
	[EnumSpreadshirtProduct.Shirt]		: 'T-Shirt',
	[EnumSpreadshirtProduct.Hoodie]		: 'Hoodie',
	[EnumSpreadshirtProduct.VNeck]		: 'V-Neck',
	[EnumSpreadshirtProduct.TankTop]	: 'Tank Top',
	[EnumSpreadshirtProduct.LongSleeve]	: 'Long Sleeve',
	[EnumSpreadshirtProduct.Sweatshirt]	: 'Sweatshirt',

	[EnumSpreadshirtSorting.Newest]		: 'Newest',
	[EnumSpreadshirtSorting.Relevance]	: 'Relevance'
};
