import { IPodImage } from './pod-image.interface';
import { IPodListing } from './pod-listing.interface';
import { IPodUploads } from './pod-uploads.interface';


/**------------------------------------------------------
 * POD Design Structure
 */
export interface IPodDesign {
	image			: IPodImage;
	listing			: IPodListing;
	uploads			: IPodUploads;
}
