import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';
import { EnumDisplateOptions } from '../../upload/app-constants/edit-options/displate-options.constants';
import { EnumGlobalOptions } from '../../upload/app-constants/edit-options/global-options.constants';
import { EnumMbaOptions } from '../../upload/app-constants/edit-options/mba-options.constants';
import { EnumNavbarOptions } from '../../upload/app-constants/edit-options/navbar-options.constants';


/**------------------------------------------------------
 * Feature Names
 */
export enum EnumEditFeatures {
	TranslateAll 		= 'edit_feature_translate_all_listings',
	KeywordFinder 		= 'edit_feature_keyword_finder',
	ScriptedReplace 	= 'navbar_scripted_replace'
	// Whitelist 		= 'edit_feature_whitelist',
	// Profiles 		= 'edit_feature_profiles',
	// TrademarkFinder 	= 'edit_feature_trademark_finder',
	// Blacklist 		= 'edit_feature_blacklist',
	// SpeechToText 	= 'edit_feature_speech_to_text',
}


/**------------------------------------------------------
 * Limitations of Edit Pod
 */
export const EDIT_LIMITATIONS: IEditLimitations[] = [

	// ---------------------- free version / beginner
	{
		modules		: [
			EnumSubscriptionModule.FreeVersion,
			EnumSubscriptionModule.UploadPodSpreadshirt,	EnumSubscriptionModule.UploadPodRedbubble,
			EnumSubscriptionModule.UploadPodMba,			EnumSubscriptionModule.UploadPodDisplate,
			EnumSubscriptionModule.UploadPodFineArtAmerica,	EnumSubscriptionModule.UploadPodPrintful,
			EnumSubscriptionModule.UploadPodPrintify,		EnumSubscriptionModule.UploadPodShirtee,
			EnumSubscriptionModule.UploadPodSociety6,		EnumSubscriptionModule.UploadPodTeepublic,
			EnumSubscriptionModule.UploadPodTeespring,		EnumSubscriptionModule.UploadPodTostadora,
			EnumSubscriptionModule.UploadPodZazzle
		],
		excludedFeatures : [EnumEditFeatures.TranslateAll, 	EnumEditFeatures.KeywordFinder, 				EnumEditFeatures.ScriptedReplace],
		excludedOptions  : {
			navbar 	 : [
				EnumNavbarOptions.ClearAllListings,		  	EnumNavbarOptions.ApplyListingTo,				EnumNavbarOptions.BulkCopyListing,
				EnumNavbarOptions.CopyFromListingHistory,	EnumNavbarOptions.ApplyListingHistoryTo,		EnumNavbarOptions.TmSearch,
				EnumNavbarOptions.TmBulkCheck,				EnumNavbarOptions.TmBlacklistCheck,				EnumNavbarOptions.ApplyTagsTo,
				EnumNavbarOptions.KwSearch,					EnumNavbarOptions.AiListingByKeywords,			EnumNavbarOptions.AiListingByTemplate,
				EnumNavbarOptions.AiListingByVision
			],
			global 	 : [
				EnumGlobalOptions.GeneralClearGlobal, 	  	EnumGlobalOptions.GeneralClearAllGlobal,		EnumGlobalOptions.GeneralCopyGlobalFrom,
				EnumGlobalOptions.GeneralApplyGlobalTo,		EnumGlobalOptions.TitleFromFileNames,			EnumGlobalOptions.TitleApplyTitleTo,
				EnumGlobalOptions.DescApplyDescTo,			EnumGlobalOptions.KeywordsAddKeywordsTo,		EnumGlobalOptions.KeywordsApplyKeywordTo,
				EnumGlobalOptions.ColorApplyColorTo,		EnumGlobalOptions.TypesApplyTypesTo,			EnumGlobalOptions.KeywordsShuffleAll
			],
			mba 	 : [
				EnumMbaOptions.GeneralClearMba, 	 	  	EnumMbaOptions.GeneralClearAllMba,				EnumMbaOptions.GeneralCopyMbaFrom,
				EnumMbaOptions.GeneralApplyMbaTo, 			EnumMbaOptions.ProductApplyProductsTo,			EnumMbaOptions.TitleFromFileNames,
				EnumMbaOptions.BrandFromFileNames,			EnumMbaOptions.GeneralSmartTransferToGlobal,	EnumMbaOptions.GeneralSmartTransferToDisplate,
				EnumMbaOptions.BrandCopyMbaTextFrom,		EnumMbaOptions.BrandApplyMbaTextTo,				EnumMbaOptions.TitleCopyMbaTextFrom,
				EnumMbaOptions.TitleApplyMbaTextTo,			EnumMbaOptions.CopyFromProductHistory,			EnumMbaOptions.ApplyProductHistoryTo,
				EnumMbaOptions.PrintApplyPrintTo,			EnumMbaOptions.ColorApplyColorTo,				EnumMbaOptions.MarketApplyMarketTo,
				EnumMbaOptions.BrandApplyBrandTo,			EnumMbaOptions.TitleApplyTitleTo,				EnumMbaOptions.Bullet1ApplyBullet1To,
				EnumMbaOptions.Bullet2ApplyBullet2To,		EnumMbaOptions.DescApplyDescTo,					EnumMbaOptions.DescFromMbaListingBulk,
				EnumMbaOptions.PublishApplyPublishTo,		EnumMbaOptions.AvailabilityApplyAvailabilityTo, EnumMbaOptions.ColorAllProductColorsTo,
				EnumMbaOptions.ProductClearProductTo,		EnumMbaOptions.FitTypeApplyFitTypeTo,			EnumMbaOptions.EnableAllMarkets,
				EnumMbaOptions.FitTypeCopyToAllProducts,	EnumMbaOptions.MarketMinPriceAllProducts,		EnumMbaOptions.MarketMaxPriceAllProducts,
				EnumMbaOptions.ColorSelectDarkAllClothing,	EnumMbaOptions.ColorSelectLightAllClothing,		EnumMbaOptions.BackgroundCopyToAllAccessories
			],
			displate : [
				EnumDisplateOptions.GeneralClearDisplate, 	EnumDisplateOptions.GeneralClearAllDisplate,	EnumDisplateOptions.GeneralCopyDisplateFrom,
				EnumDisplateOptions.GeneralApplyDisplateTo,	EnumDisplateOptions.TitleFromFileNames,			EnumDisplateOptions.CollectionApplyCollectionTo,
				EnumDisplateOptions.TitleApplyTitleTo,		EnumDisplateOptions.DescApplyDescTo,			EnumDisplateOptions.CategoriesApplyCategoriesTo,
				EnumDisplateOptions.KeywordsAddKeywordsTo,	EnumDisplateOptions.KeywordsApplyKeywordsTo,	EnumDisplateOptions.KeywordsShuffleAll,
				EnumDisplateOptions.ArtworkApplyArtworkTo,	EnumDisplateOptions.BackgroundApplyBackgroundTo
			]
		}
	},

	// ---------------------- design management / advanced
	{
		modules		: [
			EnumSubscriptionModule.DesignManagement25GB, 	EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,	EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop
		],
		excludedFeatures : [EnumEditFeatures.KeywordFinder],
		excludedOptions  : {
			navbar 	 : [EnumNavbarOptions.KwSearch, EnumNavbarOptions.AiListingByKeywords, EnumNavbarOptions.AiListingByTemplate, EnumNavbarOptions.AiListingByVision],
			global 	 : [],
			mba 	 : [],
			displate : []
		}
	},

	// ---------------------- pro
	{
		modules		: [EnumSubscriptionModule.UploadPodPro],
		excludedFeatures : [],
		excludedOptions  : {
			navbar 	 : [],
			global 	 : [],
			mba 	 : [],
			displate : []
		}
	}
];


/**------------------------------------------------------
 * Interfaces
 */
export interface IEditLimitations {
	modules			: EnumSubscriptionModule[];
	excludedFeatures: EnumEditFeatures[];
	excludedOptions : {
		navbar 	 	: EnumNavbarOptions[];
		global 	 	: EnumGlobalOptions[];
		mba 	 	: EnumMbaOptions[];
		displate 	: EnumDisplateOptions[];
	};
}


/**------------------------------------------------------
 * Feature Plannings
 * -----------
 * > // ------------------------- free
 * > upload manager
 * > suggestion
 * > trademark check
 * > spell-checking
 * >
 * > // ------------------------- beginner
 * >
 * > // ------------------------- advanced / design management
 * > taggingOptions NavBar => bulkCopy, apply to listings
 * > taggingOptions View   => view options (copy, apply to all)
 * >                       => mba (product to all)
 * > translate all
 * > whitelist
 * > profiles
 * >
 * > // ------------------------- pro
 * > trademark finder
 * > keywords finder (with search volume)
 * > blacklist
 * > speech to text
 */
