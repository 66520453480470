import { EnumLanguage } from '@libs/constants';

import { EnumDisplateArtworkType, EnumDisplateCategory, EnumGlobalColorOption, EnumGlobalTypeOption } from '../../app-constants/platforms/print-on-demand.constants';
import { EnumMbaProduct, EnumMbaMarketplace, EnumMbaFitType, EnumMbaPrint, EnumMbaColor, EnumMbaAvailability, EnumMbaPublish } from '../../app-constants/platforms/merch-by-amazon/mba-amazon.constants';


/**------------------------------------------------------
 * Listing Structure
 */
export interface IPodListing {
	flyId			: string;
	tags			: string[];						// max 3 tags
	global 	 		: IPodListingGlobal	  | null;
	mba  	 		: IPodListingMba	  | null;
	displate 		: IPodListingDisplate | null;
	notes 	 		: IPodListingNotes	  | null;
}


/**------------------------------------------------------
 * Global Data
 */
export interface IPodListingGlobal {
	texts  			: IGlobalText[];
	options 		: IGlobalOptions;
}

export interface IGlobalText {
	language    	: EnumLanguage;
	title       	: string;
	description 	: string;
	keywords    	: string[];
}

export interface IGlobalOptions {
	templateMapping : {
		color   	: EnumGlobalColorOption;		// will be used to work with the old uploader
		types   	: EnumGlobalTypeOption[];
	};
	// templates : IGlobalTemplate[];
}

export interface IGlobalTemplate {
	accountId     	: string;
	templateId    	: string;
}


/**------------------------------------------------------
 * Merch By Amazon Data
 */
export interface IPodListingMba {
	texts  	 		: IMbaText[];
	products 		: IMbaProduct[];
	options  		: IMbaOptions;
}

export interface IMbaText {
	language   		: EnumLanguage;
	brand      		: string;
	title      		: string;
	bullet1    		: string;
	bullet2    		: string;
	description		: string;
}

export interface IMbaProduct {
	productCode  	: EnumMbaProduct;
	marketplaces 	: IMbaMarketplace[];
	fitTypes		: EnumMbaFitType[];
	print   	 	: EnumMbaPrint;
	colors       	: EnumMbaColor[];
	background     ?: string;      			// used for phone cases, popsockets, ...
}

export interface IMbaMarketplace {
	market 	 		: EnumMbaMarketplace;
	price  	 		: number;
}

export interface IMbaOptions {
	// accountId    : string;				// will be used later
	availability 	: EnumMbaAvailability;
	publish			: EnumMbaPublish;
	autoTranslate	: EnumLanguage | '';
}


/**------------------------------------------------------
 * Displate
 */
export interface IPodListingDisplate {
	text  	 		: IDisplateText;
	options  		: IDisplateOptions;
}

export interface IDisplateText {
	title           : string;
	description     : string;
	keywords     	: string[];
}

export interface IDisplateOptions {
	collection      : string;
	categories      : EnumDisplateCategory[];
	background      : string;
	artworkType     : EnumDisplateArtworkType;
}


/**------------------------------------------------------
 * Notes
 */
export interface IPodListingNotes {
	note        	: string;
	license    		: INotesLicense;
	responsibilities: INotesResponsibilities;
	designInfos 	: INotesDesignInfo;
}

export interface INotesLicense {
	text    		: string;
	files   		: string[];  			// license documents can be added (saved in s3)
}

export interface INotesResponsibilities {
	researchedBy	: string;
	designedBy		: string;
	taggedBy		: string;
}

export interface INotesDesignInfo {
	designText		: string;
	language        : EnumLanguage;
	color           : string;
	adultContent	: boolean;
	createdAt		: Date;
	uploadedAt		: Date;
}
