import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { ITmTrademarkEntry } from '../interfaces';
import { ITmFilterOptions, TmFilterOptionsSchema } from './shared/filter-options.interface';


/**------------------------------------------------------
 * Configurations
 */
export const TM_BULK_SEARCH_LIMITS: ITmBulkSearchConfig = {
	SEARCH_TEXT_LENGTH	: 500,
	MAX_SEARCH_TEXTS	: 10000
};

interface ITmBulkSearchConfig {
	SEARCH_TEXT_LENGTH	: number;
	MAX_SEARCH_TEXTS	: number;
}


/**------------------------------------------------------
 * Search Request
 */
export interface ITmBulkSearchRequest extends ITmFilterOptions {
	searchTexts		: string[];	// can not be empty
}


/**------------------------------------------------------
 * Search Response
 */
export interface ITmBulkSearchResponse {
	entries			: ITmTrademarkEntry[];
	totalCount		: number;
}


/**------------------------------------------------------
 * Schemas
 */
export class TmBulkSearchRequestSchema extends TmFilterOptionsSchema implements ValidatorSchema<ITmBulkSearchRequest> {
	searchTexts	= Validator.Array().isNotEmpty().isMaxSize(TM_BULK_SEARCH_LIMITS.MAX_SEARCH_TEXTS).toUnique()
					.each(Validator.String().isNotEmpty().isMaxLength(TM_BULK_SEARCH_LIMITS.SEARCH_TEXT_LENGTH).hasNoHtmlScripts().toLowerCase().trim());
}
