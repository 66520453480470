import { Util } from '@libs/utilities/util';

import { VALIDATOR_KEYBOARD_LAYOUTS } from './password-validator.data';


export class ValidatorPassword {

	//** Configurations */
	private readonly MIN_PASSWORD_LENGTH : number = 8;
	private readonly MAX_KEYBOARD_PATTERN: number = 4;
	private readonly MIN_SEQUENCE_PATTERN: number = 4;


	isValid(password: string): boolean {
		return Util.Array.isEmpty(this.validate(password));
	}

	validate(password: string): string[] {

		//0 - check if the password is defined
		if (Util.String.isEmpty(password)) return [`Password can not be empty.`];

		//1 - password length
		const validationErrors: string[] = [];
		if (password.length < this.MIN_PASSWORD_LENGTH) validationErrors.push(`Password must be at least ${this.MIN_PASSWORD_LENGTH} characters long.`);

		//2 - contain whitespaces, upper-case, lower-case, numbers, and special chars
		if (/\s/.test(password)) 	 validationErrors.push('Password must not contain whitespaces.');
		if (!/[A-Z]/.test(password)) validationErrors.push('Password must include at least one uppercase letter.');
		if (!/[a-z]/.test(password)) validationErrors.push('Password must include at least one lowercase letter.');
		if (!/[0-9]/.test(password)) validationErrors.push('Password must include at least one number.');
		if (!/[^0-9a-zA-Z]/.test(password)) validationErrors.push('Password must include at least one special character.');

		//3 - detect week patterns
		validationErrors.push(

			//a. alphanumeric sequence patterns
			...this.detectAlphanumericSequences(password),

			//b. keyboard patterns
			...this.detectKeyboardPatterns(password)
		);

		//4 - return the validation errors
		return validationErrors;
	}


	/**------------------------------------------------------
	 * Detect Alphanumeric Sequences
	 * > Weakness of Numeric and Alphabetic Sequences: Numeric
	 * > and alphabetic sequences, such as "1234" or "abcd", are
	 * > considered weak in passwords because they can be easily
	 * > guessed or cracked by attackers. By detecting these
	 * > sequences, we can enforce stronger password requirements
	 * > and improve overall security.
	 */
	private detectAlphanumericSequences(password: string): string[] {

		//0 - create the sequential numeric and alphabetic patterns
		const sequentialPatterns: string[] = [];
		for (let i: number = 0; i <= 9; i++) {
			for (let j: number = i; j <= i + 9 - this.MIN_SEQUENCE_PATTERN; j++) {
				sequentialPatterns.push(...this.generateNumericSequences(j));
			}

			for (let j: number = i; j <= i + 26 - this.MIN_SEQUENCE_PATTERN; j++) {
				sequentialPatterns.push(...this.generateAlphabeticSequences(j));
			}
		}

		//1 - try to find the patterns in the password
		for (const pattern of sequentialPatterns) {
			if (password.includes(pattern)) return [`Password must not contain simple numeric or alphabetic sequences like "${pattern}".`];
		}

		//1 - no sequence pattern was found
		return [];
	}

	private generateNumericSequences(start: number): string[] {
		const ascendingOrder : string[] = Array.from({ length: this.MIN_SEQUENCE_PATTERN }, (acc: string[], index: number) => (start + index).toString());
		const descendingOrder: string[] = Array.from({ length: this.MIN_SEQUENCE_PATTERN }, (acc: string[], index: number) => (start - index).toString());
		return [ascendingOrder.join(''), descendingOrder.join('')];
	}

	private generateAlphabeticSequences(start: number): string[] {
		const lowerCaseAscendingOrder : string[] = Array.from({ length: this.MIN_SEQUENCE_PATTERN }, (acc: string[], index: number) => String.fromCharCode(start + index + 97));
		const lowerCaseDescendingOrder: string[] = Array.from({ length: this.MIN_SEQUENCE_PATTERN }, (acc: string[], index: number) => String.fromCharCode(start + -index + 97));
		const upperCaseAscendingOrder : string[] = Array.from({ length: this.MIN_SEQUENCE_PATTERN }, (acc: string[], index: number) => String.fromCharCode(start + index + 65));
		const upperCaseDescendingOrder: string[] = Array.from({ length: this.MIN_SEQUENCE_PATTERN }, (acc: string[], index: number) => String.fromCharCode(start + -index + 65));
		return [lowerCaseAscendingOrder.join(''), lowerCaseDescendingOrder.join(''), upperCaseAscendingOrder.join(''), upperCaseDescendingOrder.join('')];
	}


	/**------------------------------------------------------
	 * Detect Keyboard Patterns
	 * > Checking for keyboard patterns is important to ensure strong password
	 * > security. Keyboard patterns, such as 'qwerty' or 'asdf', are easy to
	 * > guess and are often targeted by attackers. Our approach generates all
	 * > possible sequential patterns for multiple keyboard layouts and checks
	 * > if any are present in the entered password.
	 */
	private detectKeyboardPatterns(password: string): string[] {

		//0 - check for keyboard patterns for all keyboards
		for (const [layout, keyboardLines] of Object.entries(VALIDATOR_KEYBOARD_LAYOUTS)) {

			//a. generate the keyboard patterns for the specific keyboard
			const patterns: string[] = this.generateKeyboardPatterns(keyboardLines, this.MAX_KEYBOARD_PATTERN);

			//b. check if the password contains one
			for (const pattern of patterns) {
				if (password.includes(pattern)) return [`Password contains a common keyboard pattern (${layout} layout): ${pattern}.`];
			}
		}

		//1 - no keyboard pattern was found
		return [];
	}

	private generateKeyboardPatterns(layout: string[], patternLength: number): string[] {

		//0 - patterns in normal direction
		const patterns: string[] = [];
		for (let i: number = 0; i <= layout.length - patternLength; i++) {
			patterns.push(layout.slice(i, i + patternLength).join(''));
		}

		//1 - check in opposite direction
		const oppositeLayout: string[] = layout.reverse();
		for (let i: number = 0; i <= oppositeLayout.length - patternLength; i++) {
			patterns.push(oppositeLayout.slice(i, i + patternLength).join(''));
		}
		return patterns;
	}
}
