import { Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { Observable, Subscriber } from 'rxjs';

import { ISecureProcessText, ISecureSseRequestOptions } from './sse-request.interface';


@Injectable()
export class SecureSseRequest {

	getServerSentEvent<T>(url: string, options: ISecureSseRequestOptions): Observable<T> {
		return new Observable<T>((observer: Subscriber<T>) => {

			//0 - initialize abort controller
			const abortController: AbortController = new AbortController();

			//1 - send request
			// > Note: The "Accept" and "Content-Type" are required if a body is send,
			// > otherwise the body will not be send to the backend. Furthermore the
			// > body needs to be stringified.
			// > info link: https://stackoverflow.com/questions/29775797/fetch-post-json-data
			fetch(url, {
				method	: options.method,
				headers	: {
					'Accept'		: 'application/json',
					'Content-Type'	: 'application/json',
					...options.headers
				},
				...options.body
					? { body: Util.Basic.stringifyIfObject(options.body) }
					: {},
				signal	: abortController.signal
			}).then((response: Response) => {

				//a. check if response is ok
				const reader: ReadableStreamDefaultReader<Uint8Array> | undefined = response?.body?.getReader();

				//b. process text
				reader?.read()
					.then((readableStream: ReadableStreamReadResult<Uint8Array>) => this.processText({
						done	: readableStream.done,
						value	: readableStream.value,
						observer,
						reader
					}))
					.catch(() => {});

			}).catch((error: any) => {
				abortController.abort();
				observer.error(error);
			});

			//2 - return unsubscribe function
			return () => {
				abortController.abort();
				observer.error('Aborted');
			};
		});
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private async processText<T>(params: ISecureProcessText<T>): Promise<void> {

		//0 - check if processing is done
		if (params.done) return;

		//1 - process text
		const text	: string   = new TextDecoder('utf-8').decode(params.value);
		const events: string[] = text.split('\n\n');

		//2 - process events
		for (const event of events) {

			//a. is it defined?
			if (!event) continue;

			//b. is it data / success
			if (event.startsWith('event: message')) {
				const data: T | null = Util.Basic.parseIfValidJson(event.split('data: ')[1]);
				params.observer.next(data);
			}

			//c. is it an error
			if (event.startsWith('event: error')) {
				const error: T | null = Util.Basic.parseIfValidJson(event.split('data: ')[1]);
				params.observer.error(error);
			}
		}

		//3 - return next iteration
		const response: ReadableStreamReadResult<Uint8Array> = await params.reader.read();
		await this.processText({ ...params, ...response });
	}
}
