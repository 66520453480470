/**------------------------------------------------------
 * Color Code & Name Definitions
 * -----------------------------
 * > CSS Colors W3C 	: https://www.w3.org/wiki/CSS/Properties/color/keywords
 * > CSS Color Codes 	: https://www.rapidtables.com/web/css/css-color.html#red
 */
export enum EnumColorName {

	// Basic Colors
	Black 				= 'black',
	Silver 				= 'silver',
	Gray 				= 'gray',
	White 				= 'white',
	Maroon 				= 'maroon',
	Red 				= 'red',
	Purple 				= 'purple',
	Fuchsia 			= 'fuchsia',
	Green 				= 'green',
	Lime 				= 'lime',
	Olive 				= 'olive',
	Yellow 				= 'yellow',
	Navy 				= 'navy',
	Blue 				= 'blue',
	Teal 				= 'teal',
	Aqua 				= 'aqua',

	// Extended colors
	Aliceblue 			= 'aliceblue',
	Antiquewhite 		= 'antiquewhite',
	Aquamarine 			= 'aquamarine',
	Azure 				= 'azure',
	Beige 				= 'beige',
	Bisque 				= 'bisque',
	Blanchedalmond 		= 'blanchedalmond',
	Blueviolet 			= 'blueviolet',
	Brown 				= 'brown',
	Burlywood 			= 'burlywood',
	Cadetblue 			= 'cadetblue',
	Chartreuse 			= 'chartreuse',
	Chocolate 			= 'chocolate',
	Coral 				= 'coral',
	Cornflowerblue 		= 'cornflowerblue',
	Cornsilk 			= 'cornsilk',
	Crimson 			= 'crimson',
	Cyan 				= 'cyan',
	Darkblue 			= 'darkblue',
	Darkcyan 			= 'darkcyan',
	Darkgoldenrod 		= 'darkgoldenrod',
	Darkgray 			= 'darkgray',
	Darkgreen 			= 'darkgreen',
	Darkgrey 			= 'darkgrey',
	Darkkhaki 			= 'darkkhaki',
	Darkmagenta 		= 'darkmagenta',
	Darkolivegreen 		= 'darkolivegreen',
	Darkorange 			= 'darkorange',
	Darkorchid 			= 'darkorchid',
	Darkred 			= 'darkred',
	Darksalmon 			= 'darksalmon',
	Darkseagreen 		= 'darkseagreen',
	Darkslateblue 		= 'darkslateblue',
	Darkslategray 		= 'darkslategray',
	Darkslategrey 		= 'darkslategrey',
	Darkturquoise 		= 'darkturquoise',
	Darkviolet 			= 'darkviolet',
	Deeppink 			= 'deeppink',
	Deepskyblue 		= 'deepskyblue',
	Dimgray 			= 'dimgray',
	Dimgrey 			= 'dimgrey',
	Dodgerblue 			= 'dodgerblue',
	Firebrick 			= 'firebrick',
	Floralwhite 		= 'floralwhite',
	Forestgreen 		= 'forestgreen',
	Gainsboro 			= 'gainsboro',
	Ghostwhite 			= 'ghostwhite',
	Gold 				= 'gold',
	Goldenrod 			= 'goldenrod',
	Greenyellow 		= 'greenyellow',
	Grey 				= 'grey',
	Honeydew 			= 'honeydew',
	Hotpink 			= 'hotpink',
	Indianred 			= 'indianred',
	Indigo 				= 'indigo',
	Ivory 				= 'ivory',
	Khaki 				= 'khaki',
	Lavender 			= 'lavender',
	Lavenderblush 		= 'lavenderblush',
	Lawngreen 			= 'lawngreen',
	Lemonchiffon 		= 'lemonchiffon',
	Lightblue 			= 'lightblue',
	Lightcoral 			= 'lightcoral',
	Lightcyan 			= 'lightcyan',
	Lightgoldenrodyellow = 'lightgoldenrodyellow',
	Lightgray 			= 'lightgray',
	Lightgreen 			= 'lightgreen',
	Lightgrey 			= 'lightgrey',
	Lightpink 			= 'lightpink',
	Lightsalmon 		= 'lightsalmon',
	Lightseagreen 		= 'lightseagreen',
	Lightskyblue 		= 'lightskyblue',
	Lightslategray 		= 'lightslategray',
	Lightslategrey 		= 'lightslategrey',
	Lightsteelblue 		= 'lightsteelblue',
	Lightyellow 		= 'lightyellow',
	Limegreen 			= 'limegreen',
	Linen 				= 'linen',
	Magenta 			= 'magenta',
	Mediumaquamarine 	= 'mediumaquamarine',
	Mediumblue 			= 'mediumblue',
	Mediumorchid 		= 'mediumorchid',
	Mediumpurple 		= 'mediumpurple',
	Mediumseagreen 		= 'mediumseagreen',
	Mediumslateblue 	= 'mediumslateblue',
	Mediumspringgreen 	= 'mediumspringgreen',
	Mediumturquoise 	= 'mediumturquoise',
	Mediumvioletred 	= 'mediumvioletred',
	Midnightblue 		= 'midnightblue',
	Mintcream 			= 'mintcream',
	Mistyrose 			= 'mistyrose',
	Moccasin 			= 'moccasin',
	Navajowhite 		= 'navajowhite',
	Oldlace 			= 'oldlace',
	Olivedrab 			= 'olivedrab',
	Orange 				= 'orange',
	Orangered 			= 'orangered',
	Orchid 				= 'orchid',
	Palegoldenrod 		= 'palegoldenrod',
	Palegreen 			= 'palegreen',
	Paleturquoise 		= 'paleturquoise',
	Palevioletred 		= 'palevioletred',
	Papayawhip 			= 'papayawhip',
	Peachpuff 			= 'peachpuff',
	Peru 				= 'peru',
	Pink 				= 'pink',
	Plum 				= 'plum',
	Powderblue 			= 'powderblue',
	Rosybrown 			= 'rosybrown',
	Royalblue 			= 'royalblue',
	Saddlebrown 		= 'saddlebrown',
	Salmon 				= 'salmon',
	Sandybrown 			= 'sandybrown',
	Seagreen 			= 'seagreen',
	Seashell 			= 'seashell',
	Sienna 				= 'sienna',
	Skyblue 			= 'skyblue',
	Slateblue 			= 'slateblue',
	Slategray 			= 'slategray',
	Snow 				= 'snow',
	Springgreen 		= 'springgreen',
	Steelblue 			= 'steelblue',
	Tan 				= 'tan',
	Thistle 			= 'thistle',
	Tomato 				= 'tomato',
	Turquoise 			= 'turquoise',
	Violet 				= 'violet',
	Wheat 				= 'wheat',
	Whitesmoke 			= 'whitesmoke',
	Yellowgreen 		= 'yellowgreen'
}


/**------------------------------------------------------
 * Color Definitions
 */
export interface IColorDefinition {
	code 		: EnumColorName;
	name		: string;
	hexValue	: string;
}
