import { Util } from '@libs/utilities/util';


export class TmDataHelper {

	getOptimizedName(tmName: string): string {

		//0 - check if the tmName is valid
		if (Util.String.isEmpty(tmName)) throw new Error(`TrademarkHelper => getOptimizedName => FATAL ERROR: passed tmName is empty (value: ${tmName})`);

		//1 - handle special cases
		tmName = Util.String.convertFullWidthToAscii(tmName);
		tmName = Util.String.removeNonAlphanumeric(tmName);

		//2 - purify the tm search
		const optimizedTmName: string = tmName
			.replace(Util.RegExp.REGEX_WHITESPACE, ' ')
			.trim().toLowerCase();

		//3 - return the purified value
		return optimizedTmName;
	}
}
