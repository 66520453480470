import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { NG_OUTDATED_V5_ICON_NAMES } from './fa-icon.data';
import { EnumNgFaStyle, EnumNgFaSize, EnumNgFaRotating, EnumNgFaAnimation } from './fa-icon.interface';


@Component({
	selector		: 'ng-fa-icon',
	templateUrl		: './fa-icon.component.html',
	styleUrls		: ['./fa-icon.component.scss'],
	changeDetection : ChangeDetectionStrategy.OnPush
})
export class NgFaIconComponent implements OnChanges {

	@Input() icon		 : string			= '';					// info-circle
	@Input() iconStyle	 : EnumNgFaStyle 	= EnumNgFaStyle.Solid;	// fa-solid
	@Input() size		?: EnumNgFaSize;
	@Input() fixedWidth	 : boolean			= true;					// fa-fw, Changes an icon's font-size to .75em

	@Input() bordered	 : boolean			= false;				// fa-border, Creates a border with border-radius and padding applied around an icons

	@Input() rotate		?: EnumNgFaRotating;
	@Input() animation	 : EnumNgFaAnimation = EnumNgFaAnimation.None;

	@Input() disabled	?: boolean;									// show as disabled, and don't emit events
	@Input() style		 : string			= '';					// put the style value through to the i element

	//** Helper Variable */
	iconClass: string = '';


	/**------------------------------------------------------
	 * Validate Input parameters
	 */
	ngOnChanges(): void {

		//0 - check mandatory values
		if (Util.String.isEmpty(this.icon))	throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the icon can not be an empty string`);
		if (this.icon.startsWith('fa-'))	throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the icon should be added without the "fa-" prefix`);
		if (this.icon.includes(' '))		throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the icon should only have the icon, if there is a space it means more then just the icon was defined`);
		if (!Util.Enum.isValid(EnumNgFaStyle, this.iconStyle)) throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the style of "${this.iconStyle}" is invalid`);

		//1 - check against the definitions if defined
		if (this.size	   && !Util.Enum.isValid(EnumNgFaSize, 	    this.size)) 	 throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the size of "${this.size}" is invalid`);
		if (this.rotate	   && !Util.Enum.isValid(EnumNgFaRotating,  this.rotate)) 	 throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the rotate of "${this.rotate}" is invalid`);
		if (this.animation && !Util.Enum.isValid(EnumNgFaAnimation, this.animation)) throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the animation of "${this.animation}" is invalid`);

		//2 - is the icon outdated
		const outdatedV5Elem: [string, string] = NG_OUTDATED_V5_ICON_NAMES.find((elem: [string, string]) => elem[0] === this.icon)!;
		if (outdatedV5Elem) throw new Error(`FaIconComponent => ngOnChanges => FATAL ERROR: the icon name of "${outdatedV5Elem[0]}" is from V5 and outdated, please use the V6 name of "${outdatedV5Elem[1]}"`);

		//3 - define the icon class
		this.iconClass = `fa-${this.icon} fa-${this.iconStyle} fa-${this.size} ${this.rotate ? `fa-${this.rotate}` : ''} ${this.generateAnimationString()}`.trim();
	}


	/**------------------------------------------------------
	 * Generate fa animation string
	 */
	private generateAnimationString(): string {
		switch (this.animation) {
			case EnumNgFaAnimation.None:
				return '';

			case EnumNgFaAnimation.SpinReverse:
				return `fa-${EnumNgFaAnimation.SpinClockwise} fa-${EnumNgFaAnimation.SpinReverse}`;

			default:
				return `fa-${this.animation}`;
		}
	}
}
