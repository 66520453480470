/**------------------------------------------------------
 * Flying Constants
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const FLYING_APP_CONSTANTS = {
	APP_NAME		: 'Flying',
	LEGAL			: {
		COMPANY_NAME	: 'Bavaria2TM UG',
		ADDRESS			: ['Rickering 7', '94577 Winzer', 'Germany'],
		LINKS			: {
			TERMS_OF_USE  		: 'https://flyingresearch.net/legal/terms-of-use',
			PRIVACY_POLICY 		: 'https://flyingresearch.net/legal/privacy-policy',
			CANCELLATION_POLICY	: 'https://flyingresearch.net/legal/cancellation-policy',
			IMPRINT				: 'https://flyingresearch.net/legal/imprint',
			AFFILIATE_TERMS		: 'https://flyingresearch.net/legal/affiliate-terms'
		}
	},
	WHATS_NEW		: {
		RESEARCH		: 'https://closed-pilot-dec.notion.site/What-s-New-4f461d9beafa4441a9418de78dce5468',
		UPLOAD			: 'https://closed-pilot-dec.notion.site/What-s-New-eb8e8f6f334a44dfbee535c65f9c7b89'
	},
	HOMEPAGE_LINKS	: {
		ABOUT_US			: 'https://flyingresearch.net/about-us',
		SUPPORT		  		: 'https://flyingresearch.net/support',
		AFFILIATE_PROGRAM	: 'https://flyingresearch.net/affiliate-program',
		AFFILIATE_OLD 		: 'https://flyingupload.com/partner-program-account/',
		AFFILIATE_LOGIN		: 'https://flyingresearch.tapfiliate.com/'
	},
	APP_LINKS		: {
		LANDING_HOME	: 'https://flyingresearch.net',
		APP_HOME 		: 'https://app.flyingresearch.net',
		SIGNUP 			: 'https://app.flyingresearch.net/#/user/sign-up',
		UPLOAD_PRICING 	: 'https://app.flyingresearch.net/#/store/upload',
		RESEARCH_PRICING: 'https://app.flyingresearch.net/#/store/research'
	},
	SOCIAL_LINKS	: {
		FACEBOOK		: 'https://www.facebook.com/flyingUpload',
		INSTAGRAM 		: 'https://www.instagram.com/flyingresearch/',
		YOUTUBE			: 'https://www.youtube.com/channel/UCqZYGr4PWLor0IeQ6YwE81A',
		DISCORD			: 'https://discord.com/invite/MGRY8WS',
		SUPPORT 		: {
			MAIL		: 'info@flyingupload.com',
			REPORT		: 'https://app.flyingresearch.net/#/support/report',
			MAILTO		: 'mailto:info@flyingupload.com?subject=%22Question or Problem:%20...%22&amp;body=%22%22'
		}
	},
	RESOURCES		: {
		LOGO_URL 		: 'https://dim.mcusercontent.com/cs/529b11ff945f80430d7c34cc7/images/43c1b6cf-8b7b-0b00-2d6c-9110abe56aee.png?w=263&amp;dpr=2',
		POD_REPORT_PDF	: 'https://flying-upload-desktop.s3.eu-central-1.amazonaws.com/resources/example-pod-report.pdf'
	},
	GOOGLE_TRANSLATE_EXTENSION : {
		TUTORIAL_VIDEO		: 'https://www.youtube.com/watch?v=d1ZeB0QTbXQ',
		TUTORIAL_HELP		: 'https://atlarge.icann.org/resources/enable-google-translate',
		WEB_STORE_CHROME	: 'https://chrome.google.com/webstore/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb?hl=en',
		WEB_STORE_FIREFOX	: 'https://addons.mozilla.org/en-US/firefox/addon/to-google-translate/'
	},
	UPLOADER	 	 	: {
		DOWNLOAD_INSTALLER 			: {
			MAC_OS 					: 'https://flying-upload-desktop.s3.eu-central-1.amazonaws.com/launcher/FlyingUploadLauncher-mac.zip',
			WINDOWS 				: 'https://flying-upload-desktop.s3.eu-central-1.amazonaws.com/launcher/FlyingUploadLauncher-windows.zip'
		},
		LAUNCHER 					: {
			WINDOWS 				: 'https://flying-upload-desktop.s3.eu-central-1.amazonaws.com/launcher/FlyingUploadLauncher-windows-raw.zip'
		},
		MAC_OS_INSTALLATION_GUIDE 	: 'https://flying-upload-desktop.s3.eu-central-1.amazonaws.com/resources/installation-guid-macos.pdf',
		SHORTCUTS_GUIDE				: 'https://flyingupload.com/information/',
		CONFIGURATION_GUIDE 		: 'https://closed-pilot-dec.notion.site/Upload-f5591a0d90a14f6a8e2b4764c74c0039'
	}
};
