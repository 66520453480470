import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingDesignUploadPlatform {
	Displate		= 'displate',
	FineArtAmerica	= 'fine_art_america',
	MBA				= 'mba',
	KDP 			= 'kdp',
	Printful		= 'printful',
	Printify		= 'printify',
	Redbubble		= 'redbubble',
	Shirtee			= 'shirtee',
	Society6		= 'society6',
	SpreadshirtUS	= 'spreadshirt_us',
	SpreadshirtEU	= 'spreadshirt_eu',
	Teepublic		= 'teepublic',
	Teespring		= 'teespring',
	Tostadora		= 'tostadora',
	Zazzle			= 'zazzle'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingDesignUpload {
	platform    	: EnumFlyingDesignUploadPlatform;
	accountMail 	: string;
	uploadedAt  	: Date;
	data        	: any;
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingDesignUploadSchema implements ValidatorSchema<IFlyingDesignUpload>  {
	platform 		= Validator.Enum(EnumFlyingDesignUploadPlatform).isNotEmpty();
	accountMail 	= Validator.String();
	uploadedAt 		= Validator.Date();
	data 			= Validator.Object().optional();
}
