import { BehaviorSubject } from 'rxjs';
import { Util } from '@libs/utilities/util';
import { EnumSubscriptionModule } from '@apps/flying/shared';
import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';
import { IFlyingAccessRight } from '@apps-v2/flying/shared';

import { ALL_RESEARCH_PAGES_LIST } from '../../../../components/layout/sidebar/sidebar-menu/research-sidebar.data';
import { ALL_UPLOAD_PAGES_LIST } from '../../../../components/layout/sidebar/sidebar-menu/upload-sidebar.data';
import { IPagesListModuleAccess } from '../../../../components/layout/sidebar/page-lists/pages-list.interface';
import { UPLOAD_PAGE_LIST_MODULE_DATA } from '../../../../components/layout/sidebar/page-lists/upload-pages/upload-module.data';
import { RESEARCH_PAGE_LIST_MODULE_DATA } from '../../../../components/layout/sidebar/page-lists/research-pages/research-module.data';


/**------------------------------------------------------
 * Access Right Management
 */
export class AccessStateHelper {

	//** Configurations */
	private readonly ALL_PAGE_LIST 	: ISparkSidebarSubMenu[] = [...ALL_RESEARCH_PAGES_LIST, ...ALL_UPLOAD_PAGES_LIST];
	private readonly ALL_PAGE_LIST_MODULE_DATA: IPagesListModuleAccess[] = [...RESEARCH_PAGE_LIST_MODULE_DATA, ...UPLOAD_PAGE_LIST_MODULE_DATA];

	//** Helper Variables */
	private readonly userRights$: BehaviorSubject<EnumSubscriptionModule[]> = new BehaviorSubject<EnumSubscriptionModule[]>([]);


	/**------------------------------------------------------
	 * Access Rights Functions
	 */
	setAccessRights(rights: IFlyingAccessRight[]): void {
		const newModules: EnumSubscriptionModule[] = rights.filter((right: IFlyingAccessRight) => new Date(right.expires) > new Date()).map((right: IFlyingAccessRight) => right.module);
		this.userRights$.next([...newModules]);
	}

	getAccessRights(): BehaviorSubject<EnumSubscriptionModule[]> {
		return this.userRights$;
	}

	hasAccess(path: string): boolean {

		//0 - do we have any restrictions for the page?
		const page 		: ISparkSidebarSubMenu 	 = this.ALL_PAGE_LIST.find((elem: ISparkSidebarSubMenu) => path === elem.routePath);
		const pageModule: IPagesListModuleAccess = this.ALL_PAGE_LIST_MODULE_DATA.find((data: IPagesListModuleAccess) => data.id === page?.id);
		if (Util.Basic.isUndefined(pageModule)) return true;

		//1 - does the user have the module required to load the page
		const usersModules: EnumSubscriptionModule[] = this.userRights$.getValue();
		for (const module of usersModules) {
			if (pageModule.access.includes(module)) return true;
		}

		//2 - user has no right to access the page
		return false;
	}
}
