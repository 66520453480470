import { ISearchLinkUrlConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Trend Duration Configurations
 */
export enum EnumGoogleTrendsTrendDuration {
	PastDay 		= 'past_day',
	PastWeek 		= 'past_week',
	PastMonth 		= 'past_month',
	PastYear 		= 'past_year',				// default value
	Past5Years 		= 'past_5_year',
	All 			= 'all'					// since 2004 - now
}

export const GOOGLE_TRENDS_TREND_DURATION_LIST: ISearchParameterConfig<EnumGoogleTrendsTrendDuration>[] = [
	{
		code		: EnumGoogleTrendsTrendDuration.PastDay,
		name		: 'Past Day',
		parameters	: { date: 'now%201-d' }
	},
	{
		code		: EnumGoogleTrendsTrendDuration.PastWeek,
		name		: 'Past Week',
		parameters	: { date: 'now%207-d' }
	},
	{
		code		: EnumGoogleTrendsTrendDuration.PastMonth,
		name		: 'Past Month (30 Days)',
		parameters	: { date: 'date=today%201-m' }
	},
	{
		code		: EnumGoogleTrendsTrendDuration.PastYear,
		name		: 'Past Year (12 Month)',
		parameters	: { date: '' }
	},
	{
		code		: EnumGoogleTrendsTrendDuration.Past5Years,
		name		: 'Past 5 Years',
		parameters	: { date: 'today%205-y' }
	},
	{
		code		: EnumGoogleTrendsTrendDuration.All,
		name		: 'All (2004 - Present)',
		parameters	: { date: 'all' }
	}
];


/**------------------------------------------------------
 * Geo Location Configurations
 */
export enum EnumGoogleTrendsGeoLocation {
	World 			= '',		// world

	UnitedStates 	= 'us',
	UnitedKingdom 	= 'gb',
	Germany 		= 'de',
	Austria 		= 'at',
	France 			= 'fr',
	Spain 			= 'es',
	Italy 			= 'it',

	Japan 			= 'jp',
	Canada 			= 'ca',
	Australia 		= 'au'
}

export const GOOGLE_TRENDS_GEO_LOCATION_LIST: ISearchParameterConfig<EnumGoogleTrendsGeoLocation>[] = [
	{
		code		: EnumGoogleTrendsGeoLocation.World,
		name		: 'Worldwide',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.World.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.UnitedKingdom,
		name		: 'United States',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.UnitedStates.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.UnitedKingdom,
		name		: 'United Kingdom',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.UnitedKingdom.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.Germany,
		name		: 'Germany',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.Germany.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.France,
		name		: 'France',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.France.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.Spain,
		name		: 'Spain',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.Spain.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.Italy,
		name		: 'Italy',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.Italy.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.Japan,
		name		: 'Japan',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.Japan.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.Canada,
		name		: 'Canada',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.Canada.toUpperCase() }
	},
	{
		code		: EnumGoogleTrendsGeoLocation.Australia,
		name		: 'Australia',
		parameters	: { geo: EnumGoogleTrendsGeoLocation.Australia.toUpperCase() }
	}
];


/**------------------------------------------------------
 * URL Config
 */
export const GOOGLE_TRENDS_CONFIG: ISearchLinkUrlConfig = {
	home		: 'https://www.google.com/',
	login		: 'https://accounts.google.com/signin/v2',
	search		: 'https://trends.google.com/explore'
};


/**------------------------------------------------------
 * Google Trends Search Options
 */
export const GOOGLE_TRENDS_DEFAULT_OPTIONS: IGoogleTrendsSearchOptions = {
	duration		: EnumGoogleTrendsTrendDuration.PastYear,
	geoLocation		: EnumGoogleTrendsGeoLocation.World
};

export interface IGoogleTrendsSearchOptions {
	duration		: EnumGoogleTrendsTrendDuration;
	geoLocation		: EnumGoogleTrendsGeoLocation;
}
