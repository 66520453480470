import { EnumLanguage } from '@libs/constants';
import { Util } from '@libs/utilities/util';

import { FLYING_APP_CONSTANTS } from './flying-app-constants.data';
import { FLYING_AI_RESEARCH_RATE_LIMIT } from '../../endpoints';


/**------------------------------------------------------
 * Legal Configuration
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const FLYING_LEGAL_PAGES_CONFIG = {
	language: EnumLanguage.EN,
	company: {
		address     : [FLYING_APP_CONSTANTS.LEGAL.COMPANY_NAME, ...FLYING_APP_CONSTANTS.LEGAL.ADDRESS],
		phone       : 'Available upon request',
		mail        : FLYING_APP_CONSTANTS.SOCIAL_LINKS.SUPPORT.MAIL,
		directors   : ['Thomas Lösl'],
		court       : 'District court Deggendorf',          // Register Court, where the business was registered
		courtNumber : 'HRB 5115',                           // Register Number of the Court
		vat         : 'DE328647516',                        // Value Added Tax Identification Number (VAT ID)
		legal : {
			localCourt  : 'Deggendorf, Bavaria, Germany',   // used for arbitration for the court location
			lawCountry  : 'Germany'                         // protected by the law of a country
		}
	},
	payment     : {
		providers   : {
			paddle      : true,
			stripe      : true,
			paypal      : true
		},
		processing  : {
			recurringPayments: true,                         // do we have "subscriptions" payments
			inAppPurchases   : false
		},
		affiliation   : {
			tapfiliate  : true
		}
	},
	engagement  : {
		community   : {
			facebook: true,
			discord : true
		},
		documentation: true,
		customerSupport: true
	},
	addons: {
		newsletter  : {
			mailChimp   : true
		},
		plugins     : {
			youTube     : true,
			reCaptcha   : true,
			facebookChat: true
		},
		socialLogin : {
			facebook    : true,
			google      : true,
			apple       : false
		},
		analytics   : {
			googleAnalytics: true,
			facebookPixel: true
		}
	},
	termsOfUse      : {
		products: {
			userAccount     : true,
			freeVersionSAAS : true,
			subscriptionSAAS: true
		},
		productTerms    : [
			{
				heading  : 'Flying Research',
				texts    : [
					'**Research**',
					'Our services provide data for research purposes only, including details like best seller rank or other performance indicators. We make no guarantees that these values can be replicated or that they will result in any specific outcomes when used in your designs. Users are solely responsible for how they use this data.',
					'We do not assume any responsibility for copyright or trademark violations arising from the use of our research tool. Each user must comply with the terms of use for the print-on-demand platforms they utilize, and we do not take any liability for violations of these platforms.',
					'**AI Research**',
					'By using the AI Research feature of our website, you acknowledge and agree that the feature utilizes APIs provided by OpenAI to generate content including, but not limited to, niches, searches, quotes, and other research materials. We disclaim all liability and responsibility for content generated through the OpenAI APIs. This includes, but is not limited to, any errors, incorrect wording, trademark infringements, hate speech, or any other issue that may arise from the generated content. It is your obligation to verify all generated content for accuracy, appropriateness, and compliance with relevant laws and regulations.',
					`In addition, access to our AI Research feature is subject to rate limitations. Specifically, users are limited to ${FLYING_AI_RESEARCH_RATE_LIMIT.rateLimit} searches for all AI Research pages per ${Util.Date.formatDuration(FLYING_AI_RESEARCH_RATE_LIMIT.timeWindow)}. This limitation is implemented as a measure to prevent misuse of the feature, including spamming, and to manage the costs associated with using OpenAI's services. By subscribing to or using the AI Research module, you agree to abide by these limitations as part of the terms of your subscription and use.`,
					'**Trademark Check**',
					'Our services offer a trademark check feature. However, the accuracy of these checks cannot be guaranteed as data comes from third-party providers. Users are solely responsible for confirming the status of trademarks and ensuring their designs do not infringe on registered trademarks.',
					'Legal Notice: The trademark check feature is not a substitute for legal advice. We are not responsible for the use and verification of trademarks. Users are independently responsible for the accuracy of trademarks and compliance with brand protection, trademark laws, and the regulations of the respective websites they use.',
					'**Keyword Research**',
					'Our services include a keyword tool that provides search volume data and other information. The accuracy of this data cannot be guaranteed due to its sourcing from third-party providers. Users must verify this keyword data themselves and ensure their usage doesn\'t infringe on any registered trademarks or violate terms of use on any platforms where the keywords will be used.',
					'The keywords may come from various sources and might include hate speech, negative words, potential trademark infringements, or other harmful content. Users are responsible for reviewing and using keywords appropriately. We do not take any responsibility for keywords that come from third-party providers.',
					'Our keywords are often used for advertisement campaigns on various platforms. Users must comply with all guidelines of these platforms, and we are not responsible for any violations arising from the use of incorrect keywords or any other factors. To comply with these guidelines, users must familiarize themselves with the platform\'s advertising policies.'
				]
			},
			{
				heading  : 'Flying Upload',
				texts    : [
					'**Upload Automation**',
					'Our service offers automation for uploading to various Print on Demand platforms provided as a third-party service. Consequently, changes in upload processes made by these platforms may require adjustments to our automation service.',
					'We cannot guarantee the time-frame or ability to incorporate these changes. Changes made by third-party platforms are out of our control and no warranties are provided for any potential discrepancies. We do not assume responsibility for technical difficulties encountered on the platforms themselves.',
					'It is important to note that usage of our automation service may in some instances lead to account restrictions or bans by the platform. We accept no liability for account restrictions or bans that result from violations of a platform\'s terms of service through the use of our automation tool. The user assumes all risks associated with the usage of our automation tool.',
					'**Trademark Check**',
					'We offer a trademark check feature. However, the accuracy of these checks cannot be guaranteed as the data originates from third-party providers. Users bear sole responsibility for double checking the trademarks and ensuring that their designs do not infringe any registered trademarks. Consequently, we are not liable for any trademark violations.',
					'Legal Notice: The trademark check feature does not provide legal advice and we are not responsible for the usage and verification of trademarks. Each user is independently responsible for the correctness of trademarks, ensuring design quality, brand protection, compliance with trademark laws, and the regulations of the respective websites they use.',
					'**Design Management**',
					'In the event of data loss due to user error, we assume no liability. Furthermore, we are not responsible if a user\'s account access details are accidentally leaked or shared by the user himself and a third party gains unauthorized access to the user\'s account, causing harm such as data deletion or content manipulation.',
					'**Trademark Report**',
					'The trademark report checks the current week\'s trademarks against all user designs. This is intended to provide indications of potential design infringement. However, the accuracy of these checks cannot be guaranteed as the data originates from third-party providers.',
					'Legal Notice: The trademark report feature does not provide legal advice and we are not responsible for the use and verification of trademarks. Each user is independently responsible for the correctness of trademarks.',
					'**Translate**',
					'We do not own a translation functionality, we only provide a google translate integration. We help you translate your input by automating the process of inserting the input into google. All credit goes to google for making this service available. We are not affiliated with google and google is not affiliated with us.',
					'**DeepL Translation**',
					`By enabling DeepL translation integration within our application, you agree to provide your own DeepL API license key, and acknowledge that you are solely responsible for all associated fees charged by DeepL based on the volume of translated characters used through our service. We do not have any influence over DeepL's pricing policies and accept no responsibility for the charges incurred by your use of DeepL’s services. Furthermore, you agree that we are not liable for the accuracy or quality of translations produced by the DeepL API, and it is your obligation to review all translated content for errors, trademark infringements, potential hate speech, or any other content that may be deemed harmful or illegal. Our role is limited to providing integration for DeepL translation services, and we disclaim all liability associated with the translations produced by DeepL, its services, and any content generated through the use of its API within our application.`,
					'**AI Listing Generator**',
					'By using our AI Listing Generator, you agree to supply your own OpenAI API key and acknowledge that you are solely responsible for all fees charged by OpenAI based on the number of tokens used in generating listings. We have no influence over OpenAI\'s pricing model and accept no responsibility for these charges.',
					'You also agree that we are not liable for any content generated through the OpenAI API. You are obligated to review and verify each generated listing for errors, trademark infringements, hate speech, or any other harmful or illegal content. We disclaim all liability related to OpenAI, its services, and any content generated by its API.'
				]
			}
		]
	},
	cancellationPolicy 	: {
		selfCancellation: {
			heading : 'Self-Cancellation User Account',
			texts    : [
				'To initiate the cancellation process directly, please follow the steps below:',
				'On flyingresearch.net: Log in to your account, navigate to the top right corner and click on the “gear” icon, then select “Settings”. Now, proceed to “Subscriptions”. Here, you will find a “Cancel” button for each active subscription.',
				'On flyingupload.com (old system): Log in to your account, navigate to the top right corner and click on the “person” icon. Then select “Subscriptions”. Here, you will find a “Cancel” option for each active subscription.'
			]
		},
		refundPolicies 	: [
			{
				heading : 'Refund of Subscriptions',
				texts    : [
					'In compliance with German law, we offer a 14-day money-back guarantee exclusively for initial purchases of our software and service. This guarantee allows you to explore and utilize all our tools without financial risk within 14 days from the date of your initial purchase. If you determine that our services are not meeting your expectations within this period, you can request a cancellation of your initial subscription and a full refund by sending us a brief email. Please note that this money-back guarantee does not apply to subscription renewals.',
					'Please note, however, that after the 14-day period, the subscription fee becomes non-refundable. Given the availability of our free software version, we encourage users to thoroughly test our services prior to committing to a purchase. As such, refund requests post the 14-day period and post-purchase might not be granted.',
					'In order to protect the integrity of our services and our money-back guarantee, we reserve the right to assess usage patterns within the 14-day period. Should we find evidence of extensive use that suggests an intention to exploit the refund policy, we may withhold the money-back guarantee. This measure is designed to prevent misuse while maintaining fair access and service quality for all users.',
					'Additionally, another example of misuse includes excessively utilizing our customer service resources within the 14-day period and before. This includes, but is not limited to, repeatedly contacting customer service leading to a significant expenditure of time and resources, such as through time-consuming email correspondence or the resolution of issues that require substantial effort and attention. If it is determined that a customer has disproportionately engaged with our customer service team, to the extent that it incurs relevant expenses, we reserve the right to consider this as a factor in declining a refund request. This policy aims to ensure equitable use of our customer support and to prevent exploitation of our services.',
					'We advise you to review and understand these terms thoroughly before committing to a subscription to avoid any misunderstandings.'
				]
			}
		]
	},
	affiliateTerms		: {}
};
