/**------------------------------------------------------
 * Deep Access
 * -----------
 * > source (deep freeze)   : https://decipher.dev/30-seconds-of-typescript/docs/deepFreeze
 * > source (deep get)		: https://decipher.dev/30-seconds-of-typescript/docs/deepGet
 * > source (deep map)		: https://decipher.dev/30-seconds-of-typescript/docs/deepMapKeys
 * > source (deep defaults) : https://decipher.dev/30-seconds-of-typescript/docs/defaults
 * > source (deep dig) 		: https://decipher.dev/30-seconds-of-typescript/docs/dig
 */
export class UtilDeepAccess {

	constructor(
		private utilBasic: IUtilBasic
	) {}


	/**------------------------------------------------------
	 * Deep Freeze
	 */
	deepFreezeObject<T extends object>(object: T): T {
		if (!this.utilBasic.isObject(object) || !object) throw new Error(`DeepAccess => deepFreezeObject => FATAL ERROR: provided object of "${object}" is invalid (make sure it is defined and not an array)`);
		return this.deepFreezeAlgorithm(object) as T;
	}
	deepFreezeArray<T = any>(array: T[]): T[] {
		if (!this.utilBasic.isArray(array) || array.length === 0) throw new Error(`DeepAccess => deepFreezeObject => FATAL ERROR: provided array of "${array}" is invalid (make sure it is an array and has at least one element)`);
		return this.deepFreezeAlgorithm(array) as T[];
	}

	//** Deep freezes an object */
	private deepFreezeAlgorithm(object: any): any {

		//0 - freeze all nested elements recursively
		for (const prop of Object.keys(object)) {
			if (typeof object[prop] === 'object' && !Object.isFrozen(object[prop])) {
				this.deepFreezeAlgorithm(object[prop]);
			}
		}

		//1 - freeze the current object
		return Object.freeze(object);
	}


	/**------------------------------------------------------
	 * Deep Get
	 * > example 1 : 	deepGet(data, ["foo", "foz", index]); // 3
	 * > example 2 : 	deepGet(data, ["foo", "bar", "baz", 8, "foz"]);
	 */
	deepGet<T>(object: any, keys: string | Array<string | number>, delimiter: string = '.'): T | null {

		//0 - check the provided arguments
		if (delimiter.trim().length === 0) 							 throw new Error(`DeepAccess => deepGet => FATAL ERROR: provided delimiter of "${delimiter}" is an empty string`);
		if ((typeof keys === 'string')  && keys.trim().length === 0) throw new Error(`DeepAccess => deepGet => FATAL ERROR: if keys is provided as string it can not be empty (value: ${keys})`);
		if (!(typeof keys === 'string') && keys.length === 0) 		 throw new Error(`DeepAccess => deepGet => FATAL ERROR: if keys is provided as array it can not be empty (value: ${keys})`);
		if (!object) 												 throw new Error(`DeepAccess => deepGet => FATAL ERROR: provided object of "${object}" is undefined/null`);
		if (!this.utilBasic.isObject(object) && !this.utilBasic.isArray(object)) throw new Error(`DeepAccess => deepGet => FATAL ERROR: provided object of "${object}" is not a valid object`);

		//1 - try to get the value based on key path
		return this.deepGetAlgorithm(object, keys, delimiter);
	}

	private deepGetAlgorithm<T>(object: any, keys: string | Array<string | number>, delimiter: string): T | null {

		//0 - get the keys
		if (typeof keys === 'string') keys = keys.split(delimiter);

		//1 - get the value of the object
		const value: T | null = keys.reduce((xs: any, x: string | number) => (xs?.[x] ? xs[x] : null), object) as T | null;
		if (!value) return null;

		//2 - return the found value
		return value;
	}


	/**------------------------------------------------------
	 * Dig / Find Key in Object, and return value
	 */
	deepFindKey<T>(object: object, targetKey: string): T | null {

		//0 - check the parameters
		if (!this.utilBasic.isObject(object) || !object) throw new Error(`DeepAccess => deepFindKey => FATAL ERROR: provided object of "${object}" is invalid (make sure it is defined and not an array)`);
		if (targetKey.trim().length === 0) 				 throw new Error(`DeepAccess => deepFindKey => FATAL ERROR: the provided targetKey is an empty string (value: "${targetKey}")`);

		//1 - try to find the key in the object
		return this.deepFindKeyAlgorithm(object, targetKey);
	}

	private deepFindKeyAlgorithm<T>(object: object, targetKey: string): T | null {

		//0 - is key directly in object
		if (targetKey in object) return (object as any)[targetKey];

		//1 - recursively try to find the key
		return Object.values(object).reduce((acc: any, value: any) => {

			//a. if undefined stop here
			// eslint-disable-next-line no-undefined
			if (acc !== undefined && acc !== null) return acc;

			//b. if object, dig deeper
			if (typeof value === 'object') return this.deepFindKeyAlgorithm(value, targetKey);
			return null;
		}, null);
	}


	/**------------------------------------------------------
	 * Deep Key Mapping
	 */
	deepMapKeys<T extends object>(object: T, keyMapFunction: TypeUtilKeyMappingFunction): unknown {

		//0 - check the values
		if (!this.utilBasic.isObject(object) || !object) throw new Error(`DeepAccess => deepMapKeys => FATAL ERROR: provided object of "${object}" is invalid (make sure it is defined and not an array)`);
		if (!this.utilBasic.isFunction(keyMapFunction))  throw new Error(`DeepAccess => deepMapKeys => FATAL ERROR: provided keyMapFunction is not a valid function (type: "${typeof keyMapFunction}")`);

		//1 - deep map the keys of the object
		return this.deepMapKeysAlgorithm(object, keyMapFunction);
	}

	private deepMapKeysAlgorithm(object: unknown | unknown[], keyMapFunction: TypeUtilKeyMappingFunction): unknown | unknown[] {

		//0 - is it an array, then call the mapping for all elements
		if (Array.isArray(object)) {
			return object.map((value: any) => this.deepMapKeysAlgorithm(value, keyMapFunction));
		}

		//1 - if simple value just return it
		if (typeof object !== 'object') return object;

		//2 - for object call the logic recursively
		return Object.keys(object!).reduce((acc: any, current: string) => {

			//a. map the key name
			const key  : string = keyMapFunction(current);
			const value: any    = (object as any)[current];

			//b. set the new value
			(acc as any)[key] = (value !== null && typeof value === 'object')
				? this.deepMapKeysAlgorithm(value, keyMapFunction)
				: value;
			return acc;
		}, {});
	}


	/**------------------------------------------------------
	 * Deep Value Mapping
	 */
	deepMapValues<T extends object>(object: T, valueMapFunction: TypeUtilValueMappingFunction): T {

		//0 - check the values
		if (!this.utilBasic.isObject(object) || !object)  throw new Error(`DeepAccess => deepMapKeys => FATAL ERROR: provided object of "${object}" is invalid (make sure it is defined and not an array)`);
		if (!this.utilBasic.isFunction(valueMapFunction)) throw new Error(`DeepAccess => deepMapKeys => FATAL ERROR: provided valueMapFunction is not a valid function (type: "${typeof valueMapFunction}")`);

		//1 - deep map the keys of the object
		return this.deepMapValuesAlgorithm(object, valueMapFunction) as T;
	}

	private deepMapValuesAlgorithm(object: unknown | unknown[], valueMapFunction: TypeUtilValueMappingFunction): unknown | unknown[] {

		//0 - is it an array, then call the mapping for all elements
		if (Array.isArray(object)) {
			return object.map((value: any) => this.deepMapValuesAlgorithm(value, valueMapFunction));
		}

		//1 - if simple value map it
		if (typeof object !== 'object') return valueMapFunction(object as string | number | boolean);

		//2 - for object call the logic recursively
		return Object.keys(object!).reduce((acc: any, current: string) => {

			//a. get the current value
			const value: any = (object as any)[current];

			//b. map the simple values
			(acc as any)[current] = (value !== null && typeof value === 'object')
				? this.deepMapValuesAlgorithm(value, valueMapFunction)
				: valueMapFunction(value as string | number | boolean);			// if simple value map it
			return acc;
		}, {});
	}
}


//** Types -------------------------------------- */
export type TypeUtilKeyMappingFunction   = (key: string) => string;
export type TypeUtilValueMappingFunction = (key: string | number | boolean) => unknown;


//** Interfaces --------------------------------- */
interface IUtilBasic {
	isObject<T>(value: T): boolean;
	isArray<T>(value: T): boolean;
	isFunction<T>(value: T): boolean;
}
