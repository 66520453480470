import { Inject, Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { Crypto } from '@libs/utilities/crypto';
import { ISecureSocketSecurityOptions } from '@domains/security/shared';

import { EnumSecureServiceInjection, ISecureServicesConfig } from './security-services.config';


@Injectable()
export class SecureEncryptionService {

	constructor(
		@Inject(EnumSecureServiceInjection.Config)
		private config: ISecureServicesConfig
	) {}


	//** Secured Body */
	encryptData<T>(securityOptions: ISecureSocketSecurityOptions, data: T): T | string {

		//0 - check if encryption is enabled
		if (!securityOptions.encryption.request) return data;

		//1 - encrypt the request body
		if (Util.Basic.isUndefined(data)) throw new Error(`EncryptionService => encryptedBody => FATAL ERROR: the passed data is undefined (value: ${data})`);
		const encryptedBody: string = Crypto.SymmetricEncryption.encryptAes256(Util.Basic.stringifyObject(data), this.config.ENCRYPTION_SECRET);

		//2 - return the encrypted body
		return encryptedBody;
	}


	//** Decrypted Response */
	decryptData<T>(securityOptions: ISecureSocketSecurityOptions, data: T | string): T {

		//0 - check if encryption is enabled
		if (!securityOptions.encryption.response) return data as T;

		//1 - decrypt the request body
		if (!Util.String.isString(data)) throw new Error(`EncryptionService => decryptedBody => FATAL ERROR: the passed data is not a string (value: ${data})`);
		const decryptedBody: string = Crypto.SymmetricEncryption.decryptAes256(data as string, this.config.ENCRYPTION_SECRET);

		//2 - return the decrypted body
		return JSON.parse(decryptedBody);
	}
}
