import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Queue Scheduler
 * ---------------
 * > Encapsulates a job queue and processing logic to
 * > execute async jobs in parallel with a maximum of
 * > 'maxConcurrent' jobs running at any given time.
 */
export class QueueScheduler {

	//** Configurations */
	private readonly CHECK_INTERVAL: number = 1000;

	//** Helper Variables */
	private jobs	   : TypeJobExecuteFn[] = [];
	private runningJobs: number 			= 0;

	//** Helper to Create Instance */
	static create(options: { maxConcurrent: number; onError: (error: any) => void }): QueueScheduler {
		if (!Util.Number.isNumber(options.maxConcurrent)) throw new Error(`QueueScheduler => create => FATAL ERROR: please pass a valid number for maxConcurrent (value: "${options.maxConcurrent}")`);
		return new QueueScheduler(options.maxConcurrent, options.onError);
	}

	private constructor(
		private maxConcurrent: number,
		private onError			 : (error: any) => void
	) {
		this.processQueue();
	}

	addJob(job: TypeJobExecuteFn): void {
		this.jobs.push(job);
		this.processQueue();
	}

	async waitForCompletion(): Promise<void> {
		await Util.Runtime.waitUntil(() => this.isComplete(), this.CHECK_INTERVAL); // wait for 1 second before checking again
	}

	isComplete(): boolean {
		return this.jobs.length === 0 && this.runningJobs === 0;
	}


	/**------------------------------------------------------
	 * Scheduling / Execution
	 */
	private async processQueue(): Promise<void> {

		// schedule the next job if a space is free, as long as we have any job to run
		while (this.runningJobs < this.maxConcurrent && this.jobs.length > 0) {

			//a. get the next job
			const jobExecuteFn: TypeJobExecuteFn | undefined = this.jobs.shift();
			if (!jobExecuteFn) continue;

			//b. execute the job
			this.runningJobs++;
			await this.runJob(jobExecuteFn);

			//c. process next job
			this.processQueue();
			this.runningJobs--;
		}
	}

	private async runJob(jobExecuteFn: TypeJobExecuteFn): Promise<void> {
		try {
			await jobExecuteFn();
		} catch (error) {
			this.onError(error);
		}
	}
}


//** Types -------------------------------------- */
export type TypeJobExecuteFn = () => Promise<void>;
