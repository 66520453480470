/**------------------------------------------------------
 * Stop Words
 * ----------
 * > Stop words are common words that are often filtered out or
 * > ignored during natural language processing tasks, such as
 * > text analysis and search engine indexing.
 * > These words are typically of low informational value as they
 * > appear frequently in language but often don't carry significant
 * > meaning by themselves. Examples of stop words include articles
 * > (e.g., "the," "a," "an"), prepositions (e.g., "in," "on," "at"),
 * > and other frequently used words like "is," "and," "of."
 * > Removing stop words can help reduce the computational load and
 * > improve the efficiency of language processing tasks, and focus
 * > on the more important words that convey the core meaning of the text.
 */
export * from './stop-words/stop-words-de';
export * from './stop-words/stop-words-en';
export * from './stop-words/stop-words-es';
export * from './stop-words/stop-words-fr';
export * from './stop-words/stop-words-it';
export * from './stop-words/stop-words-ja';


/**------------------------------------------------------
 * Characters
 * ----------
 * > These are language specific characters or special chars used within
 * > regex expressions or other places. All of them are characters of
 * > different languages or encodings.
 */
export * from './characters/language-characters.data';
export * from './characters/prefix-postfix-list.data';
export * from './characters/special-char-encodings.data';
export * from './characters/unicode-categories.data';


/**------------------------------------------------------
 * Regular Expressions
 * -------------------
 * > Structured patterns used to validate and identify specific text forms,
 * > like names, emails, URLs, and more. These expressions aid in precise
 * > text processing for applications by efficiently recognizing and handling
 * > desired patterns within input data.
 */
export * from './regex/regex-validation.data';
