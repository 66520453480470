import { animate, animation, AnimationReferenceMetadata, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

import { INgAnimationOptions } from '../shared/state-animation.interface';
import { useAnimationIncludingChildren } from '../shared/state-animation.helper';


/**------------------------------------------------------
 * Animation Definition
 */
function fadeOutRight(): AnimationReferenceMetadata {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({
					opacity 	: 1,
					transform 	: 'translate3d(0, 0, 0)',
					easing 		: 'ease',
					offset 		: 0
				}),
				style({
					opacity 	: 0,
					transform 	: 'translate3d({{translate}}, 0, 0)',
					easing 		: 'ease',
					offset 		: 1
				})
			])
		)
	]);
}


/**------------------------------------------------------
 * Configuration
 */
const NG_DEFAULT_DURATION: number = 1000;


/**------------------------------------------------------
 * FadeOutRight Animation On State
 */
export function fadeOutRightAnimation(options?: INgFadeOutRightAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeOutRight', [
		transition(
			'0 => 1',
			[
				...useAnimationIncludingChildren(fadeOutRight(), options)
			],
			{
				params: {
					delay 	 : options?.delay 	  || 0,
					duration : options?.duration  || NG_DEFAULT_DURATION,
					translate: options?.translate || '100%'
				}
			}
		)
	]);
}


/**------------------------------------------------------
 * FadeOutRight Animation On Leave
 */
export function fadeOutRightOnLeaveAnimation(options?: INgFadeOutRightAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeOutRightOnLeave', [
		transition(
			':leave',
			[
				...useAnimationIncludingChildren(fadeOutRight(), options)
			],
			{
				params: {
					delay 	 : options?.delay 	  || 0,
					duration : options?.duration  || NG_DEFAULT_DURATION,
					translate: options?.translate || '100%'
				}
			}
		)
	]);
}


//** Interfaces --------------------------------- */
export interface INgFadeOutRightAnimationOptions extends INgAnimationOptions {

	/**------------------------------------------------------
	 * Translate, possible units: px, %, em, rem, vw, vh
	 *
	 * Default: 2000px
	 */
	translate?: string;
}
