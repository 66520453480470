import { WorkerWrapper } from './helper/worker.wrapper';


/**------------------------------------------------------
 * Worker Base Class
 * -----------------
 * Info: all services which are offering their functionality
 * > as a worker need to take the "AbstractWorkerService"
 * > as a base class. By doing so the "workerInstances" are
 * > managed automatically and terminated at the end of the
 * > lifetime of the service. Furthermore "createWorker(...)"
 * > spans the worker as a "WorkerWrapper" instance.
 */
export class WorkerAssistantService {

	//** Worker that has to be terminated after usage */
	createWorker(workerInstance: Worker): WorkerWrapper {

		//0 - create the worker as the wrapper
		const newWorkerWrapper: WorkerWrapper = new WorkerWrapper(workerInstance);

		//2 - return the newly created worker
		return newWorkerWrapper;
	}


	//** Worker for only a single use (automatically terminated) */
	createSingleUseWorker(workerInstance: Worker): WorkerWrapper {

		//0 - create the worker as the wrapper
		const newWorkerWrapper: WorkerWrapper = new WorkerWrapper(workerInstance);

		//1 - terminate on finish
		newWorkerWrapper.terminateOnFinish();

		//2 - return the newly created worker
		return newWorkerWrapper;
	}
}
