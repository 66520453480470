import { HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '@libs/utilities/util';

import { ENVIRONMENT } from '../../environments';


/**------------------------------------------------------
 * Http Base Class
 * ---------------
 * Info: all services containing http calls to the api's
 * (all classes inside the service_api folder) are using
 * this abstract class as a base class.
 * The helper functions for setting the http parameter
 * can then be used as utilities. Furthermore this class
 * contains the webhooks, where for example the encryption
 * for the data can be added.
 */
export abstract class AbstractHttp {

	//** Configurations */
	protected readonly SERVER_URL	  : string = ENVIRONMENT.BACKEND.SERVER_URL;
	protected readonly SERVER_LIVE_URL: string = ENVIRONMENT.BACKEND.SERVER_LIVE_URL;

	constructor(
		protected http: HttpClient
	) {
		// check the server links in the env variables
		if (Util.String.isEmpty(this.SERVER_URL)) 		throw new Error(`AbstractHttpService => constructor => FATAL ERROR: the SERVER_URL was not defined in the environment variables (this.SERVER_URL: "${this.SERVER_URL}")`);
		if (Util.String.isEmpty(this.SERVER_LIVE_URL))  throw new Error(`AbstractHttpService => constructor => FATAL ERROR: the SERVER_LIVE_URL was not defined in the environment variables (this.SERVER_LIVE_URL: "${this.SERVER_LIVE_URL}")`);
	}

	/**------------------------------------------------------
	 * Hooks
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected requestHook(body: any): any {
		// ... add data encryption and more
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected responseHook(res: any): any {
		// ... add data decryption and more
	}

	/**------------------------------------------------------
	 * Shared Utilities / Helper
	 */
	protected setHttpParams(params: object): HttpParams {
		let httpParams: HttpParams = new HttpParams();
		for (const paramKey of Object.keys(params)) {
			if (params[paramKey] || params[paramKey] === 0) httpParams = httpParams.set(paramKey, params[paramKey]);
		}
		return httpParams;
	}
}
