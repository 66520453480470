/**------------------------------------------------------
 * Marketplace Definitions
 */
export enum EnumMbaMarketplace {
	COM 		= 'com',
	CO_UK 		= 'co_uk',
	DE 			= 'de',
	FR 			= 'fr',
	IT 			= 'it',
	ES 			= 'es',
	JP 			= 'jp'
}


/**------------------------------------------------------
 * Product Definitions
 */
export enum EnumMbaProduct {
	StandardShirt 	= 'standard_shirt',
	PremiumShirt 	= 'premium_shirt',
	VNeckShirt 		= 'vneck_shirt',
	TankTop 		= 'tank_top',
	LongSleeveShirt = 'long_sleeve_shirt',
	Raglan 			= 'raglan',
	Sweatshirt 		= 'sweatshirt',
	PulloverHoodie 	= 'pullover_hoodie',
	ZipHoodie 		= 'zip_hoodie',
	PopSockets 		= 'popsockets',
	IPhoneCases 	= 'iphone_cases',
	SamsungCases 	= 'samsung_cases',
	ToteBag 		= 'tote_bag',
	ThrowPillows 	= 'throw_pillows',
	Tumbler 		= 'tumbler'
}


/**------------------------------------------------------
 * Listing Definitions
 */
export enum EnumMbaProductType {
	Clothing  	= 'clothing',
	Accessory 	= 'accessory'
}

export enum EnumMbaScalingType {
	Default		= 'default',
	Special		= 'special'
}

export enum EnumMbaPrint {
	Front  		= 'front',
	Back 		= 'back'
}

export enum EnumMbaFitType {
	Men  		= 'men',
	Women 		= 'women',
	Youth		= 'youth'
}

export enum EnumMbaAvailability {
	Public 		= 'public',
	Private		= 'private'
}

export enum EnumMbaPublish {
	Live  		= 'live',
	Draft		= 'draft'
}


/**------------------------------------------------------
 * Color Definitions
 */
export enum EnumMbaColor {
	NoColor				= 'no_color',
	Asphalt				= 'asphalt',
	BabyBlue			= 'baby_blue',
	Black				= 'black',
	Brown				= 'brown',
	Cranberry			= 'cranberry',
	DarkHeather			= 'dark_heather',
	ForestGreen			= 'forest_green',
	Grass				= 'grass',
	HeatherBlue			= 'heather_blue',
	HeatherGrey			= 'heather_grey',
	KellyGreen			= 'kelly_green',
	Lemon				= 'lemon',
	Navy				= 'navy',
	Olive				= 'olive',
	Orange				= 'orange',
	Pink				= 'pink',
	NeonPink			= 'neon_pink',
	Purple				= 'purple',
	Red					= 'red',
	Sapphire			= 'sapphire',
	Royal				= 'royal',
	Silver				= 'silver',
	Slate				= 'slate',
	White				= 'white',
	BlackAthleticHeather = 'black_athletic_heather',
	BlackWhite			= 'black_white',
	DarkHeatherWhite 	= 'dark_heather_white',
	NavyAthleticHeather	= 'navy_athletic_heather',
	NavyWhite			= 'navy_white',
	RedWhite			= 'red_white',
	RoyalBlueWhite		= 'royal_blue_white',
	DarkGreen 			= 'dark_green',
	Burgundy 			= 'burgundy',
	GoldenYellow 		= 'golden_yellow',
	PurpleHeather 		= 'purple_heather',
	RedHeather 			= 'red_heather',
	OliveHeather 		= 'olive_heather',
	PinkHeather 		= 'pink_heather',
	SageGreen 			= 'sage_green',
	BrightPink 			= 'bright_pink',
	DustyBlue 			= 'dusty_blue',
	BrushedSteel 		= 'brushed_steel'
}
