/**--------------------------------
 * Text Validation
 * ---------------
 * > Escaping regex in strings : https://javascript.info/regexp-escaping
 * ---------------
 * Escaping
 * a) Info:
 * > All characters which interfere with the quotations, like `...`, "..." and so on need to
 * > be escaped, otherwise the string will break. Another part which needs to be escaped are
 * > the '\' in a string. For example a '\.' in a regex expression needs to be escaped as '\\.'.
 * > If this is not done the string will remove the '\' and the regex will only have the '.'
 * b) Example
 * > regex 	: /`myTest`-[a-z]\./g
 * > escaped : "`myTest`-[a-z]\\."			// escaping the string within "..." quotations
 * > escaped : `\`myTest\`-[a-z]\\.`		// escaping the string within `...` quotations
 */
export const REGEX_VALIDATORS: IRegexValidators = {
	// cspell:disable

	// only text (NO space)
	MATCH_NAME_EN	: {
		string 	: `[a-zA-Z]+`,
		regex 	: /[a-zA-Z]+/g
	},
	MATCH_NAME_EU	: {
		string 	: `[a-zA-ZÄÖÜSSÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒÁÉÍÑÓÚÜÀÈÉÌÒÓÙäöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù]+`,
		regex 	: /[a-zA-ZÄÖÜSSÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒÁÉÍÑÓÚÜÀÈÉÌÒÓÙäöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù]+/g
	},

	// text, with spaces
	MATCH_TEXT_EN	: {
		string 	: `[a-zA-Z ]+`,
		regex 	: /[a-zA-Z ]+/g
	},
	MATCH_TEXT_EU	: {
		string 	: `[A-Za-zÄÖÜSSÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒÁÉÍÑÓÚÜÀÈÉÌÒÓÙäöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù ]+`,
		regex 	: /[A-Za-zÄÖÜSSÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒÁÉÍÑÓÚÜÀÈÉÌÒÓÙäöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù ]+/g
	},
	MATCH_TEXT_ALL	: {
		string 	: `^((?![<>~{}|@]).)*$`,
		regex 	: /^((?![<>~{}|@]).)*$/g
	},

	// only numbers (NO space)
	MATCH_NUMBER	: {
		string 	: `[0-9]+`,
		regex 	: /[0-9]+/g
	},

	// special
	MATCH_PHONE_NUMBER	: {
		string 	: `[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]+`,
		regex 	: /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]+/g
	},
	MATCH_EMAIL			: {
		string 	: `(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])`,
		regex 	: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g // eslint-disable-line no-control-regex
	},
	MATCH_STRONG_PASSWORD: {
		string 	: `^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})`,
		regex 	: /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/g
	},

	// text, with spaces
	MATCH_TITLE_EN	: {
		string 	: `[0-9a-zA-Z ]+`,
		regex 	: /[0-9a-zA-Z ]+/g
	},
	MATCH_TITLE_EU	: {
		string 	: `[0-9A-Za-zÄÖÜSSÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒÁÉÍÑÓÚÜÀÈÉÌÒÓÙäöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù ]+`,
		regex 	: /[0-9A-Za-zÄÖÜSSÙÛÜŸÀÂÆÇÉÈÊËÏÎÔŒÁÉÍÑÓÚÜÀÈÉÌÒÓÙäöüßùûüÿàâæçéèêëïîôœáéíñóúüàèéìòóù ]+/g
	},

	// check url
	MATCH_URL : {
		string 	: `((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\\w]+@)[A-Za-z0-9.-]+)((?:\\/[\\+~%\\/.\\w-_]*)?\\??(?:[-\\+=&;%@.\\w_]*)#?(?:[\\w]*))?)`,
		regex 	: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/g
	},

	// other expressions
	MATCH_HTML_CODE : {
		string 	: `<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)<\\/\\1>`,
		regex 	: /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/g
	}
	// cspell:enable
};


//** Interfaces --------------------------------- */
interface IRegexValidators {
	MATCH_NAME_EN		: IRegexValidator;
	MATCH_NAME_EU		: IRegexValidator;
	MATCH_TEXT_EN		: IRegexValidator;
	MATCH_TEXT_EU		: IRegexValidator;
	MATCH_TEXT_ALL		: IRegexValidator;
	MATCH_NUMBER		: IRegexValidator;
	MATCH_PHONE_NUMBER	: IRegexValidator;
	MATCH_EMAIL			: IRegexValidator;
	MATCH_STRONG_PASSWORD: IRegexValidator;
	MATCH_TITLE_EN		: IRegexValidator;
	MATCH_TITLE_EU		: IRegexValidator;
	MATCH_URL 			: IRegexValidator;
	MATCH_HTML_CODE 	: IRegexValidator;
}

interface IRegexValidator {
	string 	: string;
	regex 	: RegExp;
}
