import { IPodDesign } from '../../data-structures/pod-design/pod-design.interface';


/**------------------------------------------------------
 * Design Management constants
 */
export enum EnumUploadDesignConfigs {
	MaxFiles 		= 500,
	MaxUploadSize 	= 50 * 1000 * 1000 			// bytes = MB * KB * 1000
}

export enum EnumDesignListSorting {
	Newest 			= 'newest',
	Oldest 			= 'oldest'
}

export enum EnumImageTypes {
	Icon 	 		= 'icon',
	Preview  		= 'preview',
	Original 		= 'original'
}

export enum EnumDesignListSearchType {
	Default	 		= 'default',
	Specific 		= 'specific'
}


/**------------------------------------------------------
 * Design Management interfaces
 */
export interface IGetDesignsListResponse {
	result			: IPodDesign[];
	returnedResults	: number;
}
