import { EnumPlatform } from '../../app-constants/platforms/print-on-demand.constants';
import { EnumUploadException } from './upload-exceptions.interface';


/**------------------------------------------------------
 * Upload Task
 */
export enum EnumUploadTaskStatus {
	Scheduled 		= 'scheduled',
	Success 		= 'success',
	Error 			= 'error'
}


/**------------------------------------------------------
 * Upload Task
 */
export interface IUploadTaskSchedule {
	designFlyId		: string;						// flyId of the design
	schedule	: {
		platform    : EnumPlatform;				// the account name could change to another platform
		accountName	: string;
		templateName: string;
		uploadAfter	: Date;
	};
	status		: {
		type		: EnumUploadTaskStatus;
		exception	: EnumUploadException;
		timestamp	: Date;
	};
}
