/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumSecureServiceInjection {
	Config		= 'security_service_config'
}


/**------------------------------------------------------
 * Socket Security Config
 */
export interface ISecureServicesConfig {
	TIME_TOKEN			: {
		TIME_WINDOW		: number;
		SALT			: string;
	};
	ENCRYPTION_SECRET	: string;
	GOOGLE_RECAPTCHA_KEY: string;
}
