/**------------------------------------------------------
 * Mass Definitions
 */
export enum EnumMass {
	Microgram 		= 'mass_mcg',
	Milligram 		= 'mass_mg',
	Gram 			= 'mass_g',
	Kilogram 		= 'mass_kg',
	MetricTonne 	= 'mass_metric_t',
	Ounce 			= 'mass_oz',
	Pound 			= 'mass_lb',
	ImperialTon 	= 'mass_imperial_t'
}
