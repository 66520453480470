import { EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Constants
 */
const FLYING_EDIT_TRANSLATE_CONFIG: IFlyingEditTranslateConfig = {
	MAX_TRANSLATION				: 5,
	MAX_TRANSLATE_STRING_LENGTH	: 4000
};

interface IFlyingEditTranslateConfig {
	MAX_TRANSLATION				: number;
	MAX_TRANSLATE_STRING_LENGTH : number;
}


/**------------------------------------------------------
 * Request
 */
export interface IFlyingEditTranslateRequest {
	entries		: IFlyingEditTranslateListing[];
}

export interface IFlyingEditTranslateListing {
	info		: object;              	 	// for the frontend to identify the things from the array, can be an empty object, parameter is optional
	from		: EnumLanguage;
	to			: EnumLanguage;
	texts		: Record<string, string>;	// key has to be a string, string can not be empty, max string length is 4000
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingEditTranslateResponse {
	results		: IFlyingEditTranslatedListing[];
}

export interface IFlyingEditTranslatedListing extends IFlyingEditTranslateListing {
	info		: object;
	from		: EnumLanguage;
	to			: EnumLanguage;
	texts		: Record<string, string>;
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingEditTranslateRequestSchema implements ValidatorSchema<IFlyingEditTranslateRequest> {
	entries = Validator.Array().isMinSize(1).isMaxSize(FLYING_EDIT_TRANSLATE_CONFIG.MAX_TRANSLATION)
		.inspectEach<IFlyingEditTranslateListing>({
			info 	: Validator.Object().optional(),
			from 	: Validator.Enum(EnumLanguage),
			to 		: Validator.Enum(EnumLanguage),
			texts	: Validator.Object().isNotEmpty().hasOnlyStringValues()
						.eachValue((text: string) => text.length <= FLYING_EDIT_TRANSLATE_CONFIG.MAX_TRANSLATE_STRING_LENGTH)
		});
}
