import { NgModalAssistantService, NgModalWrapper } from '@libs/frameworks/angular';
import { Injectable } from '@angular/core';

import { ErrorModalComponent } from './error-modal.component';
import { EnumErrorModalType } from './error-modal.data';


@Injectable({
	providedIn: 'root'
})
export class ErrorModalAssistant {

	constructor(
		private modalAssistantService 	: NgModalAssistantService
	) {}


	/**------------------------------------------------------
	 * Open Error Modals
	 */
	showNoConnection()	  : NgModalWrapper { return this.showErrorModal(EnumErrorModalType.NoConnection); }
	showServerError()	  : NgModalWrapper { return this.showErrorModal(EnumErrorModalType.ServerError); }
	showMaintenanceError(): NgModalWrapper { return this.showErrorModal(EnumErrorModalType.MaintenanceError); }
	showInvalidTime()	  : NgModalWrapper { return this.showErrorModal(EnumErrorModalType.InvalidTime); }


	/**------------------------------------------------------
	 * Open Error Modal
	 */
	private showErrorModal(errorType: EnumErrorModalType): NgModalWrapper {

		//0 - close all open modals
		this.modalAssistantService.closeAll();

		//1 - open the new modal
		return this.modalAssistantService.openLockedMedium(ErrorModalComponent).set({
			type : errorType
		});
	}
}
