import { Util } from '@libs/utilities/util';
import { Crypto } from '@libs/utilities/crypto';

import { JdtEncodingHelper } from './jdt-encoding.helper';
import { IJdtData, IJdtHeader, IJdtPayload, IJdtDecodingOptions, VERIFICATION_HASH_LENGTH } from './jdt-data-token.interface';


export class JdtDecodingHelper {

	//** Configurations */
	private readonly EXPORT_CHUNK_LENGTH: number = 3;

	constructor(
		private jdtEncodingHandler: JdtEncodingHelper
	) {}


	/**------------------------------------------------------
	 * Decode JDT (JSON Data Token)
	 */
	decodeDataFromJdt<TInfo, TData>(rawData: string, importOptions: IJdtDecodingOptions): IJdtData<TInfo, TData> {

		//0 - check the provided import options
		if (!Util.String.isString(importOptions.salt)) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: provided salt of "${importOptions.salt}" is not a string (the salt is optional and used to protect the exported data against manipulations)`);
		if (importOptions.encryption.active  && Util.String.isEmpty(importOptions.encryption.key))  throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: encryption key of "${importOptions.encryption.key}" is empty (when the encryption is set to active for the export and encryption key needs to be provided)`);
		if (!importOptions.encryption.active && !Util.String.isEmpty(importOptions.encryption.key)) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: if the encryption is deactivated, the encryption key has to be empty`);

		//1 - import the raw data
		const rawDataChunks: string[] = rawData.split('.');
		if (rawDataChunks.length !== this.EXPORT_CHUNK_LENGTH) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: the imported raw data of ["${rawData}"] does have a chunk length of "${rawDataChunks.length}" but the exported format has a chunk length of "${this.EXPORT_CHUNK_LENGTH}"`);
		if (rawDataChunks.some((elem: string) => !Util.RegExp.isBase64Valid(elem))) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: base64 encoding of the JDT is not valid (invalid base64 encoded string)`);

		//2 - verify if the data was manipulated (check the signature)
		const headerBase64	  	   : string = rawDataChunks[0];
		const payloadBase64	  	   : string = rawDataChunks[1];
		const signatureBase64 	   : string = rawDataChunks[2];
		const verifySignatureBase64: string = this.jdtEncodingHandler.createBase64Signature(headerBase64, payloadBase64, importOptions.salt);
		if (signatureBase64 !== verifySignatureBase64) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: the signature check failed, this can happen if the exported file was manipulated (required signature: ${verifySignatureBase64} / signature of file: ${signatureBase64})`);

		//3 - extract the encoded header & payload
		const header	: IJdtHeader<TInfo> = this.fromBase64<IJdtHeader<TInfo>>(headerBase64);
		const payload	: IJdtPayload 		= this.fromBase64<IJdtPayload>(payloadBase64);

		//4 - verify if the used encryption key is correct
		if (header.verify.encrypted) {
			const verifyKeyHash: string = Util.String.truncate(Crypto.Hash.hashSha256(importOptions.encryption.key), VERIFICATION_HASH_LENGTH);
			if (header.verify.keyHash !== verifyKeyHash) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: the encryption key used in the exported file and the provided one in the importOptions are not the same (saltKey of options: ${verifyKeyHash} / saltKey of file: ${header.verify.keyHash})`);
		}

		//5 - verify if the used salt is correct
		const verifySaltHash: string = Util.String.truncate(Crypto.Hash.hashSha256(importOptions.salt), VERIFICATION_HASH_LENGTH);
		if (header.verify.saltHash !== verifySaltHash) throw new Error(`JDTDecodingHandler => decodeDataFromJdt => FATAL ERROR: the salt used in the exported file and the provided one in the importOptions are not the same (saltHash of options: ${verifySaltHash} / saltHash of file: ${header.verify.saltHash})`);

		//6 - import the data
		const dataObj: TData = header.verify.encrypted
			? this.getEncryptedData(payload.data, importOptions.encryption.key)
			: Util.Basic.parseIfValidJson<TData>(payload.data)!;

		const importedData: IJdtData<TInfo, TData> = {
			header	: {
				type	: header.type,
				info 	: header.info as TInfo,
				date 	: header.date
			},
			data	: dataObj
		};

		//7 - return the imported data
		return importedData;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private fromBase64<T>(base64String: string): T {
		const jsonObjString: string = Util.String.fromBase64(base64String);
		return Util.Basic.parseJson(jsonObjString);
	}

	private getEncryptedData<T>(dataString: string, encryptionKey: string): T {
		const decryptedData: string = Crypto.SymmetricEncryption.decryptAes256(dataString, encryptionKey);
		const object	   : T		= Util.Basic.parseIfValidJson<T>(decryptedData)!;
		return object;
	}
}
