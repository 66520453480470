import { UtilRuntime } from './util-runtime';


/**------------------------------------------------------
 * Basic Utilities
 * ---------------
 * > Info: Containing the basic functionality to retry an
 * > action n times before throwing the error
 * ---------------
 * Source (inspired by):
 * > npm retry : https://www.npmjs.com/package/retry
 * > git retry : https://github.com/tim-kos/node-retry
 */
export class UtilRetry {

	constructor(
		private utilRuntime	: UtilRuntime
	) {}


	async try<T>(actionFunction: () => Promise<T> | T, options: IUtilRetryOptions): Promise<T | null> {
		for (let i: number = 0; i < options.retry; ++i) {
			try {
				const result: T = await actionFunction();
				if (!result) continue;
				return result;
			} catch (error: unknown) { /* empty */ }
			await this.utilRuntime.delay(options.slowdown);
		}
		return null;
	}


	async promise<T>(promise: Promise<T>, options: IUtilRetryOptions): Promise<T | null> {
		try {
			const result: T = await promise;
			return result;
		} catch (error: unknown) {
			options.retry--;
			if (options.retry <= 0) return null;
			await this.utilRuntime.delay(options.slowdown);
			const promiseExecuted: T | null = await this.promise(promise, options);
			return promiseExecuted;
		}
	}
}


//** Interfaces --------------------------------- */
interface IUtilRetryOptions {
	retry 		: number;				// The maximum amount of times to retry the operation.
	slowdown	: number;				// The number of milliseconds before starting the next retry
}
