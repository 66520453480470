import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AbstractSecureHttpRequest } from './http-request.abstract';
import { ISecureHttpConfig } from '../../secure-http.config';
import { SecureTimeTokenService } from '../../../services/time-token.service';
import { SecureEncryptionService } from '../../../services/encryption.service';


export class SecureOptionsRequest extends AbstractSecureHttpRequest {

	constructor(
		private   http						: HttpClient,
		protected override config			: ISecureHttpConfig,
		protected override endpoint			: string,
		protected override timeTokenService	: SecureTimeTokenService,
		protected override encryptionService: SecureEncryptionService
	) {
		super(config, endpoint, timeTokenService, encryptionService);
	}

	send<T>(): Observable<T> {

		//0 - options endpoint
		const endpoint: string = this.prepareEndpointUrl();

		//1 - send request
		const optionsRequest$: Observable<T | string> = this.http.options<T>(endpoint, {
			headers		: this.prepareHeaders(),
			responseType: this.prepareResponseType() as any
		});

		//2 - decrypt response
		return this.decryptResponse(optionsRequest$);
	}

	streamSse<T>(): Observable<T> {
		throw new Error(`OptionsRequest => streamSSE => FATAL ERROR: the "options" request does not support SSE`);
	}
}
