import { UtilArray } from './util-array';


/**------------------------------------------------------
 * Exception & Error Handling
 * --------------------------
 * > Info: This utility unifies the handling of errors
 * > and exceptions.
 * --------------------------
 * Example
 *
 * try {
 * 	  // logic which is failing (this error will be catched by the catch block)
 * 	  Util.Exception.throw(EnumError.InvalidOperation);
 *
 * } catch (e: unknown) {
 * 	  Util.Exception.catchOnly(e, [EnumError.InvalidOperation, EnumError.InvalidData]);
 *
 * 	  // react to custom errors
 * 	  if (Util.Exception.isEqual(e, EnumError.InvalidOperation)) {
 * 	  	// ...
 * 	  }
 * }
 */
export class UtilError {

	constructor(
		private utilArray: UtilArray
	) {}


	//** Throw a custom error */
	throw<T extends string>(exceptionType: T): void {
		throw new Error(exceptionType);
	}

	//** Catch only specific errors, if not matching rethrow the error */
	catchOnly<T extends string>(error: any, exceptionType: T | T[]): void {

		//0 - will this exception be handled otherwise?
		const exceptionTypeArray: T[] = this.utilArray.asArray(exceptionType);
		if (exceptionTypeArray.some((elem: T) => error?.toString().includes(elem))) return;

		//1 - forward the error by throwing it again
		throw new Error(error?.toString());
	}

	//** Check if error is matching a specific value */
	isEqual<T extends string>(error: any, exceptionType: T): boolean {
		return error?.toString().includes(exceptionType);
	}

	//** Wrap execution in try-catch */
	wrapInTryCatch<TResult>(execute: () => TResult): TResult | null {
		try {
			return execute();
		} catch (error) {
			return null;
		}
	}
}
