import { Util } from '@libs/utilities/util';

import { ICryptoImagePixelData, ICryptoImageBound, ICryptoPixelVector, ICryptoHashResult } from './perceptual-hash.interface';


/**------------------------------------------------------
 * Perceptual Hash Algorithms
 * --------------------------
 * > Perceptual hashing is a technique to generate compact
 * > image fingerprints representing visual content. These
 * > hashes are designed for efficient comparison and can
 * > withstand minor image modifications. The Hamming Distance
 * > is a metric used to measure the similarity between two
 * > hashes, where a lower distance indicates higher similarity.
 * --------------------------
 * > DCT Hashing Library Code : https://github.com/btd/sharp-phash/blob/master/index.js
 */
export class CryptoPerceptualHash {

	//** Configurations */
	private readonly IMAGE_SAMPLE_SIZE: number = 8;


	/**------------------------------------------------------
	 * Hamming Distance
	 */
	hammingDistance(a: string, b: string): number {

		//0 - check the function call
		if (Util.String.isEmpty(a)) throw new Error(`PerceptualHash => hammingDistance => FATAL ERROR: provided value of "a" is empty`);
		if (Util.String.isEmpty(b)) throw new Error(`PerceptualHash => hammingDistance => FATAL ERROR: provided value of "b" is empty`);
		if (a.length !== b.length)	throw new Error(`PerceptualHash => hammingDistance => FATAL ERROR: provided value of "a" and "b" have different length (a.length: "${a.length}" / b.length: "${b.length}")`);

		//1 - calculate the hamming distance
		const aChars: string[] = a.split('');
		const bChars: string[] = b.split('');

		let distance: number = 0;
		for (let i: number = 0; i < a.length && b.length > 0; ++i) {
			if (aChars[i] !== bChars[i]) distance++;
		}
		return distance;
	}


	/**------------------------------------------------------
	 * Hashing Image (Perceptual Hashing)
	 */
	pHash(imagePixelData: ICryptoImagePixelData): ICryptoHashResult {

		//0 - calculate trimmed height and width (bound of the image pixels)
		const bound		: ICryptoImageBound = this.getImageBounds(imagePixelData);
		const trimHeight: number = bound.bottom - bound.top  + 1;
		const trimWidth : number = bound.right  - bound.left + 1;

		//1 - calculate block width and height
		const blockWidth : number = Math.floor(trimWidth  / this.IMAGE_SAMPLE_SIZE);
		const blockHeight: number = Math.floor(trimHeight / this.IMAGE_SAMPLE_SIZE);

		//2 - loop over blocks to calculate Mean
		const regionMeans: ICryptoPixelVector[] = [];
		for (let blockRow: number = 0; blockRow < this.IMAGE_SAMPLE_SIZE; blockRow++) {
			for (let blockCol: number = 0; blockCol < this.IMAGE_SAMPLE_SIZE; blockCol++) {

				//a. calculate x and y
				const x: number = bound.left + blockWidth  * blockRow;
				const y: number = bound.top  + blockHeight * blockCol;

				//b. get images region pixels average / mean
				const imageRegionMeans: ICryptoPixelVector = this.getRegionMeans(imagePixelData, x, y, blockWidth, blockHeight);
				regionMeans.push(imageRegionMeans);
			}
		}

		//3 - calculate median
		const meanBaseline: ICryptoPixelVector = this.getPixelsMean(regionMeans);

		//4 - calculate the pHash (as hex string)
		let   pHashHex  : string = '';
		const blockCount: number = this.IMAGE_SAMPLE_SIZE * this.IMAGE_SAMPLE_SIZE;
		for (let i: number = 0; i < blockCount; i++) {
			let char: number = 0;
			if (regionMeans[i].r >= meanBaseline.r) char |= 0x8;
			if (regionMeans[i].g >= meanBaseline.r) char |= 0x4;
			if (regionMeans[i].b >= meanBaseline.g) char |= 0x2;
			if (regionMeans[i].a >= meanBaseline.a) char |= 0x1;
			pHashHex += char.toString(16);
		}

		//5 - define the final pHash value
		const pHash: ICryptoHashResult = {
			pHashHex	: pHashHex,
			pHashBinary	: this.hexToBinary(pHashHex)
		};
		return pHash;
	}


	/**------------------------------------------------------
	 * Calculate Image Boundaries
	 */
	private getImageBounds(imagePixelData: ICryptoImagePixelData): ICryptoImageBound {

		//0 - prepare bound empty object
		const width		: number 			= imagePixelData.width;
		const height	: number 			= imagePixelData.height;
		const pixelData	: Uint8ClampedArray = imagePixelData.data;
		const bound		: ICryptoImageBound 		= {
			left	: 0,
			top		: 0,
			right	: width,
			bottom	: height
		};

		//1 - find the first pixel on the top
		let foundTopPixel: boolean = false;
		for (let y: number = 0; y < height && !foundTopPixel; y++) {
			bound.top = y;
			for (let x: number = 0; x < width; x++) {
				const index: number = (y * width + x) * 4;
				if (pixelData[index + 3] > 0) {
					foundTopPixel = true;
					break;
				}
			}
		}

		//2 - find the first pixel on the right
		let foundRightPixel: boolean = false;
		for (let x: number = width - 1; x >= 0 && !foundRightPixel; x--) {
			for (let y: number = height - 1; y >= 0; y--) {
				const index: number = (y * width + x) * 4;
				if (pixelData[index + 3] > 0) {
					foundRightPixel = true;
					break;
				}
			}
			bound.right = x;
		}

		//3 - find the first pixel on the bottom
		let foundBottomPixel: boolean = false;
		for (let y: number = height - 1; y >= 0 && !foundBottomPixel; y--) {
			for (let x: number = width - 1; x >= 0; x--) {
				const index: number = (y * width + x) * 4;
				if (pixelData[index + 3] > 0) {
					foundBottomPixel = true;
					break;
				}
			}
			bound.bottom = y;
		}

		//4 - find the first pixel on the left
		let foundLeftPixel: boolean = false;
		for (let x: number = 0; x < width && !foundLeftPixel; x++) {
			bound.left = x;
			for (let y: number = 0; y < height; y++) {
				const index: number = (y * width + x) * 4;
				if (pixelData[index + 3] > 0) {
					foundLeftPixel = true;
					break;
				}
			}
		}

		return bound;
	}


	/**------------------------------------------------------
	 * Get Region Pixels
	 */
	private getRegionMeans(imagePixelData: ICryptoImagePixelData, x: number, y: number, width: number, height: number): ICryptoPixelVector {

		//0 - calculate the sum of the pixel values
		const regionMean: ICryptoPixelVector = { r: 0, g: 0, b: 0, a: 0 };
		let   pixelCount: number 		 = 0;
		for (let j: number = y; j < y + height; j += 1) {
			for (let i: number = x; i < x + width; i += 1) {

				//a. skip transparent pixels
				const pixelNdx: number = j * imagePixelData.width + i;
				if (imagePixelData.data[4 * pixelNdx + 3] === 0) continue;

				//b. add pixel value to the sum
				regionMean.r += 256 - imagePixelData.data[4 * pixelNdx];
				regionMean.g += 256 - imagePixelData.data[4 * pixelNdx + 1];
				regionMean.b += 256 - imagePixelData.data[4 * pixelNdx + 2];
				pixelCount += 1;
			}
		}

		//1 - calculate the average color values
		if (pixelCount > 0) {
			regionMean.r = Math.round(regionMean.r / pixelCount);
			regionMean.g = Math.round(regionMean.g / pixelCount);
			regionMean.b = Math.round(regionMean.b / pixelCount);
		}

		//2 - calculate the average transparency values
		const pixelsInBlock: number = height * width;
		regionMean.a = (100 / pixelsInBlock) * (pixelsInBlock - pixelCount);

		return regionMean;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private getPixelsMean(pixels: ICryptoPixelVector[]): ICryptoPixelVector {

		//0 - calculate the sum
		const pixelSum: ICryptoPixelVector = { r: 0, g: 0, b: 0, a: 0 };
		for (const pixel of pixels) {
			pixelSum.r += pixel.r;
			pixelSum.g += pixel.g;
			pixelSum.b += pixel.b;
			pixelSum.a += pixel.a;
		}

		//1 - calculate the average / mean
		pixelSum.r = pixelSum.r / pixels.length;
		pixelSum.g = pixelSum.g / pixels.length;
		pixelSum.b = pixelSum.b / pixels.length;
		pixelSum.a = pixelSum.a / pixels.length;

		return pixelSum;
	}

	private hexToBinary(pHashHex: string): string {
		return pHashHex.split('').map((i: string) => parseInt(i, 16).toString(2).padStart(4, '0')).join('');
	}
}
