/* cspell:ignore yxxx */
import { UtilString } from './util-string';


/**------------------------------------------------------
 * UUID Utilities
 * --------------
 * > Info: Containing all functionalities to create the
 * > different identifiers of the UUID standard.
 * --------------
 * > UUID Versions Explained : https://www.uuidtools.com/uuid-versions-explained
 */
export class UtilUuid {

	//** RegEx Checks for UUID */
	private readonly CHECK_UUID_NORMAL  : RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	private readonly CHECK_UUID_NO_DASH : RegExp = /^[0-9a-f]{8}[0-9a-f]{4}[1-5][0-9a-f]{3}[89ab][0-9a-f]{3}[0-9a-f]{12}$/i;

	constructor(
		private utilString: UtilString
	) {}


	/**------------------------------------------------------
	 * Namespace UUID
	 * --------------
	 * > Version-3 (hash with MD5) and Version-5 UUID (SHA-1)
	 * > Namespace and name are concatenated and hashed.
	 *   There is no random component.
	 */
	v3NamespaceUuid(namespace: string, name: string, hashMd5: (value: string) => string): string {
		if (this.utilString.isEmpty(namespace) || this.utilString.isEmpty(name)) throw new Error(`UtilUUID => v3NamespaceUUID => FATAL ERROR: the provided namespace or name is empty (namespace: "${namespace}" / name: "${name}")`);
		const value: string = hashMd5(namespace + name);
		const uuid : string = `${value.slice(0, 8)}-${value.slice(8, 12)}-3${value.slice(13, 16)}-${this.formatEncoding(value.slice(16, 17))}${value.slice(17, 20)}-${value.slice(20, 33)}`;
		return uuid;
	}


	/**------------------------------------------------------
	 * Random UUID
	 * -----------
	 * > Version-4 UUIDs are randomly generated.
	 * > Over 5.3 x 10^36 unique v4 UUID
	 */
	v4RandomUuid(): string {
		const template: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
		return this.createRandomUuid(template);
	}


	/**------------------------------------------------------
	 * Timestamp First UUID
	 * --------------------
	 * > Encodes the timestamp as the last chars to make the
	 *   values ordered (useful for indexed data)
	 */
	v4TimestampFirstUuid(): string {
		const timestamp 		: string = `${new Date().getTime()}`;
		const relevantTimestamp	: string = timestamp.slice(timestamp.length - 12, timestamp.length);
		const randomUuidPart	: string = this.createRandomUuid('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx');
		const uuid				: string = `${relevantTimestamp.slice(0, 8)}-${relevantTimestamp.slice(8, 12)}-${randomUuidPart}`;
		return uuid;
	}

	nilUuid(): string {
		return '00000000-0000-0000-0000-000000000000';
	}


	/**------------------------------------------------------
	 * Check UUID
	 */
	isValidUuid(uuid: string): boolean {
		if (this.utilString.isEmpty(uuid)) throw new Error(`UtilUUID => isValidUUID => FATAL ERROR: the provided uuid of "${uuid}" is empty`);
		return this.CHECK_UUID_NORMAL.test(uuid);
	}
	isValidDashFreeUuid(uuid: string): boolean {
		if (this.utilString.isEmpty(uuid)) throw new Error(`UtilUUID => isValidDashFreeUUID => FATAL ERROR: the provided uuid of "${uuid}" is empty`);
		return this.CHECK_UUID_NO_DASH.test(uuid);
	}
	getDashFreeUuid(uuid: string): string {
		if (this.utilString.isEmpty(uuid)) throw new Error(`UtilUUID => getDashFreeUUID => FATAL ERROR: the provided uuid of "${uuid}" is empty`);
		return uuid.replace(/-/g, '');
	}


	/**------------------------------------------------------
	 * Generate UUID
	 * > http://stackoverflow.com/a/8809472/188246
	 */
	private createRandomUuid(template: string): string {

		//0 - get the current time
		let dateTime: number = new Date().getTime();

		//1 - create the random uuid
		const uuid: string = template.replace(/[xy]/g, (c: string) => {
			const random: number = (dateTime + Math.random() * 16) % 16 | 0;
			dateTime = Math.floor(dateTime / 16);
			return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
		});

		//2 - return the generated id
		return uuid;
	}

	private formatEncoding(char: string): string {
		// value will always start with a 8, 9, A , or B
		return ((char.charCodeAt(0) & 0x3) | 0x8).toString(16);
	}
}
