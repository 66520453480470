/**------------------------------------------------------
 * Teespring Constants
 */
export enum EnumTeespringVisibility {
	Public			= 'public',
	Private			= 'private'
}


/**------------------------------------------------------
 * Teespring Template
 */
export interface ITeespringTemplateOptions {
	designLink 		: string;
	options			: {
		shop		: string;
		visibility	: EnumTeespringVisibility;
	};
}
