/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgDomElement {
	Overlay  		= 'product_tour_overlay',
	Highlighter 	= 'product_tour_highlighter',
	Floating 		= 'product_tour_floating',
	DummyDomElement = 'dummy_dom_element'
}


/**------------------------------------------------------
 * Enum Definitions
 */
export interface INgElementProps {
	idSelector 		: string;
	classSelector 	: string;
	cssStyles  		: string;
}

export interface INgElementBoundValues {
	elementHeight 	: string;
	elementWidth  	: string;
	elementTop 	 	: string;
	elementRight  	: string;
	elementBottom 	: string;
	elementLeft  	: string;
}
