/**------------------------------------------------------
 * Zazzle Constants
 */
export enum EnumZazzleTemplateType {
	Product			= 'product',
	QuickCreate		= 'quick_create'
}

export enum EnumZazzleAudience {
	G_AnyAge		= 'g_any_age',					// Any Age
	PG13_Teenager	= 'pg_13_teenager',				// Teenager
	R_AdultsOnly	= 'r_adults_only'				// Adults Only
}

export enum EnumZazzleVisibility {
	Public			= 'public',
	Private			= 'private'
}


/**------------------------------------------------------
 * Zazzle Template
 */
export interface IZazzleTemplateOptions {
	type 			: EnumZazzleTemplateType;
	template		: IZazzleProductTemplate | IZazzleQuickCreateTemplate;
}

export interface IZazzleProductTemplate {					// Uploading a single Product
	productTemplate : string;
	options			: {
		audience	: EnumZazzleAudience;
		royalty		: number;
		shop		: string;
		visibility	: EnumZazzleVisibility;
		customize	: boolean;
	};
}

export interface IZazzleQuickCreateTemplate {				// Uploading multiple products at once
	quickCreate : string;
	options			: {
		royalty		: number;
		shop		: string;
	};
}
