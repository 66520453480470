import { Observable, Subscriber, lastValueFrom } from 'rxjs';


/**------------------------------------------------------
 * RxJs Basic
 * ----------
 * ...
 */
export class RxJsBasic {

	//** Convert promise to observable */
	createObservable<T>(requestPromise: Promise<T>): Observable<T> {
		return new Observable<T>((subscriber: Subscriber<T>) => {
			requestPromise.then((res: T) => {
				subscriber.next(res);
				subscriber.complete();
			}).catch((error: any) => {
				subscriber.error(error);
			});
		});
	}

	toPromise<T>(request: Observable<T>): Promise<T> {
		return lastValueFrom<T>(request);
	}
}
