/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumHttpStatus {

	//** Informational responses */
	Continue 			= 100,
	SwitchingProtocols 	= 101,
	Processing 			= 102,
	EarlyHints 			= 103,

	//** Success responses */
	Ok 					= 200,
	Created 			= 201,
	Accepted 			= 202,
	NonAuthoritative 	= 203,
	NoContent 			= 204,
	ResetContent 		= 205,
	PartialContent 		= 206,
	MultiStatus			= 207,
	AlreadyReported		= 208,

	//** Redirection */
	MultipleChoices		= 300,
	MovedPermanently 	= 301,
	Found 				= 302,
	SeeOther 			= 303,
	NotModified 		= 304,
	RedirectUseProxy	= 305,
	TemporaryRedirect 	= 307,
	PermanentRedirect 	= 308,

	//** Client errors */
	BadRequest 			= 400,
	Unauthorized 		= 401,
	Forbidden 			= 403,
	NotFound 			= 404,
	MethodNotAllowed 	= 405,
	NotAcceptable 		= 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout 		= 408,
	Conflict 			= 409,
	Gone 				= 410,
	LengthRequired 		= 411,
	PreconditionFailed 	= 412,
	PayloadTooLarge 	= 413,
	UriTooLong 			= 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed 	= 417,
	IAmATeapot 			= 418,
	Misdirected 		= 421,
	UnprocessableEntity = 422,
	ClientErrorLocked 	= 423,
	FailedDependency 	= 424,
	ClientErrorUpgradeRequired 	= 426,
	PreconditionRequired 		= 428,
	TooManyRequests 	= 429,
	HeaderFieldsTooLarge = 431,
	LoginTimeOut 		= 440,
	ErrorRetryWith 		= 449,
	UnavailableForLegalReasons = 451,

	//** Server errors */
	InternalServerError = 500,
	NotImplemented 		= 501,
	BadGateway 			= 502,
	ServiceUnavailable 	= 503,
	GatewayTimeout 		= 504,
	HttpVersionNotSupported = 505,
	VariantAlsoNegotiates 	= 506,
	InsufficientStorage = 507,
	LoopDetected 		= 508,
	BandwidthLimitExceeded = 509,
	NotExtended 		= 510,
	NetworkAuthRequired = 511
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IHttpStatusInfo {
	code		: EnumHttpStatus;
	message 	: string;
	description	: string;
}
