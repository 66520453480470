import { EnumFileType } from '@libs/constants';


/**------------------------------------------------------
 * Configurations
 */
export const EXCEL_ACCEPTED_FILE_TYPES: EnumFileType[] = [EnumFileType.XLSX, EnumFileType.XLS, EnumFileType.CSV, EnumFileType.ODS];


/**------------------------------------------------------
 * Normal Sheet
 */
export interface IImportExcelData {
	sheets		: IImportSheetData[];
	metaInfo	: {
		file	: IImportFileInfo;
	};
}

export interface IImportSheetData {
	rows		: IImportSheetRow[];
	metaInfo	: {
		sheet	: IImportSheetInfo;
	};
}


/**------------------------------------------------------
 * Table Sheet
 */
export interface IImportSheetTable {
	headings	: IImportSheetTableHeading[];
	rows		: IImportSheetRow[];
	metaInfo	: {
		sheet	: IImportSheetInfo;
		file	: IImportFileInfo;
	};
}

export interface IImportSheetTableHeading {
	heading		: string;
	metaInfo	: {
		columnName	: string;					// Alphabetic Column Naming: A, B, C
		columnIndex : number;					// numeric index, starting at 0
		rowName 	: string;					// starting at 1 like in excel (row index + 1)
		rowIndex 	: number;					// numeric index, starting at 0
	};
}


/**------------------------------------------------------
 * Sheet Data
 */
export interface IImportSheetRow {
	cellValues	: IImportSheetCellValue[];
	rowName 	: string;
	rowIndex 	: number;
}

export interface IImportSheetCellValue {
	value		: string;
	metaInfo	: {
		heading		: string;
		columnName	: string;					// Alphabetic Column Naming: A, B, C
		columnIndex : number;
		rowName 	: string;
		rowIndex 	: number;
	};
}


/**------------------------------------------------------
 * Meta Info
 */
export interface IImportFileInfo {
	name		: string;
	createDate	: Date;
	size		: number;
	extension	: string | null;
}

export interface IImportSheetInfo {
	sheetName	: string;
	columnCount	: number;
	rowCount	: number;
}


/**------------------------------------------------------
 * Import Options
 */
export interface IExcelImportOptions {
	removeEmptyLeading	: boolean;
	removeEmptyTrailing	: boolean;
	removeEmptyAllEmpty	: boolean;
}
