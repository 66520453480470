import { EnumAmazonMarketplace, EnumAmazonProduct } from './amazon.constants';


/**------------------------------------------------------
 * Sales Estimations
 * -----------------
 * How many sales come for one review in average.
 * This was calculated by our actual MBA sales of
 * our main sales account.
 * -----------------------
 * Sales/Review Relation
 * • com > 11,3 sales per review
 * • uk  > 20,9 sales per review
 * • de  > 19,94 sales per review
 * • fr  > 21 sales per review
 * • es  > 24,35 sales per review
 * • it  > 24,8 sales per review
 */
export const AMAZON_SALES_ESTIMATIONS: IAmazonSalesEstimations[] = [
	{
		marketplace 	: EnumAmazonMarketplace.COM,
		totalSales		: (reviews: number): number => {
			// com > 11,3 sales per review
			return reviews * 11.3;
		},
		salesPerMonthMba: (reviews: number): number => {
			// com (mba)
			// > y = 0														| x <= 0
			// > y = -0.0000058895x^2 + 0.04525x + 5.842868					| x > 0 AND x < 3842
			// > y = 0.01057109671848x + 52.144516407599					| x >= 3842					// old y = 92.75867
			switch (true) {
				case reviews <= 0:	 return 0;
				case reviews < 3842: return -0.0000058895 * Math.pow(reviews, 2) + 0.04525 * reviews + 5.842868;
				default:			 return 0.01057109671848 * reviews + 52.144516407599;
			}
		},
		productSpecific : {
			[EnumAmazonProduct.Shirt]: (bsr: number): number => {
				return 894982.4 * Math.pow(bsr, -0.8718843);
			},
			[EnumAmazonProduct.Hoodie]: (bsr: number): number => {
				return 894982.4 * Math.pow(bsr, -0.8718843);
			},
			[EnumAmazonProduct.PopSocket]: (bsr: number): number => {
				return 826495 * Math.pow(bsr, -0.885235);
			},
			[EnumAmazonProduct.PhoneCase]: (bsr: number): number => {
				return 826495 * Math.pow(bsr, -0.885235);
			},
			[EnumAmazonProduct.KDP]: (bsr: number): number => {
				return 312025.5 * Math.pow(bsr, -0.827626);
			}
		}
	},
	{
		marketplace 	: EnumAmazonMarketplace.CO_UK,
		totalSales		: (reviews: number): number => {
			return reviews * 20.9;				// uk > 20,9 sales per review
		},
		salesPerMonthMba: (reviews: number): number => {
			// uk (mba)
			// > y = 0														| x <= 0
			// > y = 0.000000169x^3 + -0.0003293x^2 + 0.194088x + 2.8183776	| x > 0 AND x < 452
			// > y = 0.01117700729927x + 33.82299270073						| x >= 452				// old y = 38.875
			switch (true) {
				case reviews <= 0:	return 0;
				case reviews < 452:	return 0.000000169 * Math.pow(reviews, 3) + -0.0003293 * Math.pow(reviews, 2) + 0.194088 * reviews + 2.8183776;
				default:			return 0.01117700729927 * reviews + 33.82299270073;
			}
		},
		productSpecific : {
			[EnumAmazonProduct.Shirt]: (bsr: number): number => {
				return 8355.059 * Math.pow(bsr, -0.559088);
			},
			[EnumAmazonProduct.Hoodie]: (bsr: number): number => {
				return 8355.059 * Math.pow(bsr, -0.559088);
			},
			[EnumAmazonProduct.PopSocket]: (bsr: number): number => {
				return 313260 * Math.pow(bsr, -1.005964);
			},
			[EnumAmazonProduct.PhoneCase]: (bsr: number): number => {
				return 313260 * Math.pow(bsr, -1.005964);
			},
			[EnumAmazonProduct.KDP]: (bsr: number): number => {
				return 20355 * Math.pow(bsr, -0.754107);
			}
		}
	},
	{
		marketplace 	: EnumAmazonMarketplace.DE,
		totalSales		: (reviews: number): number => {
			return reviews * 19.94;				// de > 19,94 sales per review
		},
		salesPerMonthMba: (reviews: number): number => {
			// de (mba)
			// > y = 0													| x <= 0
			// > y = -0.0000588x^2 + 0.08864x + 6.260338				| x > 0 AND x < 754
			// > y = 0.011520325203252x + 30.979674796748				| x >= 754					// old y = 39.666
			switch (true) {
				case reviews <= 0:	return 0;
				case reviews < 754:	return -0.0000588 * Math.pow(reviews, 2) + 0.08864 * reviews + 6.260338;
				default:			return 0.011520325203252 * reviews + 30.979674796748;
			}
		},
		productSpecific : {
			[EnumAmazonProduct.Shirt]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387);
			},
			[EnumAmazonProduct.Hoodie]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387);
			},
			[EnumAmazonProduct.PopSocket]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879);
			},
			[EnumAmazonProduct.PhoneCase]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879);
			},
			[EnumAmazonProduct.KDP]: (bsr: number): number => {
				return 191893.4 * Math.pow(bsr, -0.7699062);
			}
		}
	},
	{
		marketplace 	: EnumAmazonMarketplace.FR,
		totalSales		: (reviews: number): number => {
			return reviews * 21;				// fr > 21 sales per review
		},
		salesPerMonthMba: (reviews: number): number => {
			// fr (mba)
			// > y = 0															| x <= 0
			// > y = 0.000008028467x^3 + -0.0041713x^2 + 0.6675969x + 3.5224975	| x > 0 AND x < 125
			// > y = 0.024551111111111x + 34.407111111111						| x >= 125					// old y = 37.476
			switch (true) {
				case reviews <= 0:	return 0;
				case reviews < 125:	return 0.000008028467 * Math.pow(reviews, 3) + -0.0041713 * Math.pow(reviews, 2) + 0.6675969 * reviews + 3.5224975;
				default:			return 0.024551111111111 * reviews + 34.407111111111;
			}
		},
		productSpecific : {
			[EnumAmazonProduct.Shirt]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387) * 0.75;
			},
			[EnumAmazonProduct.Hoodie]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387) * 0.75;
			},
			[EnumAmazonProduct.PopSocket]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879) * 0.75;
			},
			[EnumAmazonProduct.PhoneCase]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879) * 0.75;
			},
			[EnumAmazonProduct.KDP]: (bsr: number): number => {
				return 191893.4 * Math.pow(bsr, -0.7699062) * 0.75;
			}
		}
	},
	{
		marketplace 	: EnumAmazonMarketplace.ES,
		totalSales		: (reviews: number): number => {
			return reviews * 24.35;				// es > 24,35 sales per review
		},
		salesPerMonthMba: (reviews: number): number => {
			// es (mba)
			// > y = 0											| x <= 0
			// > y = -0.010048x^2 + 1.08980968x + 2.5981869		| x > 0 AND x < 54
			// > y = 0.11575625x + 25.8970625					| x >= 54					// old y = 32.1479
			switch (true) {
				case reviews <= 0:	return 0;
				case reviews < 54:	return -0.010048 * Math.pow(reviews, 2) + 1.08980968 * reviews + 2.5981869;
				default:			return 0.11575625 * reviews + 25.8970625;
			}
		},
		productSpecific : {
			[EnumAmazonProduct.Shirt]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387) * 0.55;
			},
			[EnumAmazonProduct.Hoodie]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387) * 0.55;
			},
			[EnumAmazonProduct.PopSocket]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879) * 0.55;
			},
			[EnumAmazonProduct.PhoneCase]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879) * 0.55;
			},
			[EnumAmazonProduct.KDP]: (bsr: number): number => {
				return 191893.4 * Math.pow(bsr, -0.7699062) * 0.55;
			}
		}
	},
	{
		marketplace 	: EnumAmazonMarketplace.IT,
		totalSales		: (reviews: number): number => {
			return reviews * 24.8;				// it > 24,8 sales per review
		},
		salesPerMonthMba: (reviews: number): number => {
			// italy (mba)
			// > y = 0											| x <= 0
			// > y = -0.0017132x^2 + 0.441887x + 2.15516		| x > 0 AND x < 129
			// > y = 0.089450704225352x + 19.10985915493		| x >= 129					// old y = 30.649
			switch (true) {
				case reviews <= 0:	return 0;
				case reviews < 129:	return -0.0017132 * Math.pow(reviews, 2) + 0.441887 * reviews + 2.15516;
				default:			return 0.089450704225352 * reviews + 19.10985915493;
			}
		},
		productSpecific : {
			[EnumAmazonProduct.Shirt]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387) * 0.45;
			},
			[EnumAmazonProduct.Hoodie]: (bsr: number): number => {
				return 10978.98 * Math.pow(bsr, -0.5987387) * 0.45;
			},
			[EnumAmazonProduct.PopSocket]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879) * 0.45;
			},
			[EnumAmazonProduct.PhoneCase]: (bsr: number): number => {
				return 184026.4 * Math.pow(bsr, -0.8313879) * 0.45;
			},
			[EnumAmazonProduct.KDP]: (bsr: number): number => {
				return 191893.4 * Math.pow(bsr, -0.7699062) * 0.45;
			}
		}
	}
];


//** Interfaces --------------------------------- */
export interface IAmazonSalesEstimations {
	marketplace 	: EnumAmazonMarketplace;
	totalSales		: (reviews: number) => number;
	salesPerMonthMba: (reviews: number) => number;
	productSpecific : {
		[EnumAmazonProduct.Shirt]	 : (bsr: number) => number;
		[EnumAmazonProduct.Hoodie]	 : (bsr: number) => number;
		[EnumAmazonProduct.PopSocket]: (bsr: number) => number;
		[EnumAmazonProduct.PhoneCase]: (bsr: number) => number;
		[EnumAmazonProduct.KDP]		 : (bsr: number) => number;
	};
}
