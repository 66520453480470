import { IScaleLayoutHandler } from './scale-layout.interface';
import { ScaleLayoutService } from './scale-layout.service';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class ScaleLayoutServiceFactory {

	static create(scaleLayoutHandler: IScaleLayoutHandler): ScaleLayoutService {
		return new ScaleLayoutService(
			scaleLayoutHandler
		);
	}
}
