import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_COM: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'en_US'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&rh=p_6%3AATVPDKIKX0DER&s=featured&hidden-keywords=Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+-Longsleeve+-Raglan+-Vneck+-Tanktop
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			language		 : 'en_US',
			i				 : 'fashion-novelty',
			rh				 : 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': 'Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+-Longsleeve+-Raglan+-Vneck+-Tanktop'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&bbn=12035955011&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=This+premium+t-shirt+is+made+of+lightweight+fine+jersey+fabric+Mens+fit+runs+small+size+up+for+a+looser+fit
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			bbn			: '12035955011',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': 'This+premium+t-shirt+is+made+of+lightweight+fine+jersey+fabric+Mens+fit+runs+small+size+up+for+a+looser+fit'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=v-neck%20Lightweight%2C%20Classic%20fit%2C%20Double-needle%20sleeve%20and%20bottom%20hem
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': 'v-neck%20Lightweight%2C%20Classic%20fit%2C%20Double-needle%20sleeve%20and%20bottom%20hem'
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+vneck'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=fit+tank+top%22Tank+Top%22
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': 'fit+tank+top%22Tank+Top%22'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&bbn=12035955011&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=%22Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem%22+++%22long+sleeve%22
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			bbn			: '12035955011',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': '%22Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem%22+++%22long+sleeve%22'
		}
	},
	{
		// https://www.amazon.com/s?k=dog+raglan&i=fashion-novelty&bbn=12035955011&rh=p_6%3AATVPDKIKX0DER
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			bbn			: '12035955011',
			rh			: 'p_6%3AATVPDKIKX0DER' // cspell:disable-line
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+raglan'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&bbn=12035955011&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=%228.5+oz%2C+Classic+fit%2C+Twill-taped+neck%22+%22sweatshirt%22+-hoodie
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			bbn			: '12035955011',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': '%228.5+oz%2C+Classic+fit%2C+Twill-taped+neck%22+%22sweatshirt%22+-hoodie'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=fashion-novelty&bbn=12035955011&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=%228.5+oz%2C+Classic+fit%2C+Twill-taped+neck%22+%22hoodie%22
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			language	: 'en_US',
			i			: 'fashion-novelty',
			bbn			: '12035955011',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': '%228.5+oz%2C+Classic+fit%2C+Twill-taped+neck%22+%22hoodie%22'
		}
	},
	{
		// https://www.amazon.com/s?k=dog+popsocket&i=mobile&rh=p_6%3AATVPDKIKX0DER&sprefix=%2Cmobile%2C797
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			language	: 'en_US',
			i			: 'mobile',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			sprefix		: '%2Cmobile%2C797' // cspell:disable-line
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+popsocket'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&i=mobile&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=Two-part+protective+case+made+from+a+premium+scratch-resistant+polycarbonate+shell+and+shock+absorbent+TPU+liner+protects+against+drops&sprefix=%2Cmobile%2C797
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameters 	: {
			language	: 'en_US',
			i			: 'mobile',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			sprefix		: '%2Cmobile%2C797', // cspell:disable-line
			'hidden-keywords': 'Two-part+protective+case+made+from+a+premium+scratch-resistant+polycarbonate+shell+and+shock+absorbent+TPU+liner+protects+against+drops'
		}
	},
	{
		// https://www.amazon.com/s?k=&rh=p_6%3AATVPDKIKX0DER&hidden-keywords=16%E2%80%9D%20x%2016%E2%80%9D%20bag%20with%20two%2014%E2%80%9D%20long%20and%201%E2%80%9D%20wide%20black%20cotton%20webbing%20strap%20handles.
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameters 	: {
			language	: 'en_US',
			rh			: 'p_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': '16%E2%80%9D%20x%2016%E2%80%9D%20bag%20with%20two%2014%E2%80%9D%20long%20and%201%E2%80%9D%20wide%20black%20cotton%20webbing%20strap%20handles'
		}
	},
	{
		// https://www.amazon.com/s?k=dog&rh=n%3A3732321%2Cp_6%3AATVPDKIKX0DER&hidden-keywords=100%25+spun-polyester+fabric+Double-sided+print+Spot+clean%2Fdry+clean+only+Filled+with+100%25+polyester+and+sewn+closed
		code		: EnumAmazonProduct.ThrowPillow,
		name		: 'Throw Pillows',
		parameters 	: {
			language	: 'en_US',
			rh			: 'n%3A3732321%2Cp_6%3AATVPDKIKX0DER', // cspell:disable-line
			'hidden-keywords': '100%25+spun-polyester+fabric+Double-sided+print+Spot+clean%2Fdry+clean+only+Filled+with+100%25+polyester+and+sewn+closed' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.de/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&i=stripbooks&hidden-keywords=independently+published&__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&qid=1601804685&ref=sr_pg_2
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameters 	: {
			language	: 'en_US',
			i			: 'stripbooks-intl-ship',
			qid			: '1601804586',
			'hidden-keywords': 'independently+published'
		}
	}
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_COM: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.amazon.com/',
		login		: 'https://www.amazon.com/ap/signin?openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=usflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',
		search		: 'https://www.amazon.com/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_COM,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
