/**------------------------------------------------------
 * Cover
 */
export enum EnumKdpCoverMineType {
	PNG 			= 'image/png',
	JPG 			= 'image/jpg',
	JPEG 			= 'image/jpeg',
	PDF 			= 'file/pdf'
}


/**------------------------------------------------------
 * Manuscript
 */
export enum EnumKdpTrimSize {
	JuniorLegal		= 'junior_legal_5_x_8',
	DinB6 			= 'din_b6_5.06_x_7.81',
	Size5x8			= '5.25_x_8',
	DinA5 			= 'din_a5_5.5_x_8.5',
	Size6x9			= '6_x_9',
	Size6x92		= '6.14_x_9.21',
	Size6x96		= '6.69_x_9.61',
	DinB5			= 'din_b5_7_x_10',
	Size7x96		= '7.44_x_9.69',
	Size7x92		= '7.5_x_9.25',
	Size8x10		= '8_x_10',
	Size8x6			= '8.25_x_6',
	Size8x82		= '8.25_x_8.25',
	Size8x8			= '8.5_x_8.5',
	Letter  		= 'letter_8.5_x_11',
	DinA4 			= 'din_a4_8.27_x_11.69'
}

export enum EnumKdpPaperType {
	BlackInkWhite 	= 'black_ink_white',
	BlackInkCream 	= 'black_ink_cream',
	ColorInkWhite 	= 'color_ink_white'
}

export enum EnumKdpTrimSizeStandard {
	OfficialKDP 	= 'official_kdp',
	Tangent 		= 'tangent'
}


/**------------------------------------------------------
 * Other Options
 */
export enum EnumKdpBookProduct {
	eBook 			= 'e_book',
	Paperback 		= 'paperback',
	Hardcover 		= 'hardcover'
}

export enum EnumKdpInteriorType {
	BlackAndWhiteCremePaper = 'black_and_white_creme_paper',
	BlackAndWhiteWhitePaper = 'black_and_white_white_paper',
	ColorWhitePaper 		= 'color_white_paper',
	PremiumColorWhitePaper 	= 'premium_color_white_paper'
}

export enum EnumKdpCoverFinish {
	Matte 			= 'matte',
	Glossy 			= 'glossy'
}

export enum EnumKdpPublish {
	Live  			= 'live',
	Draft			= 'draft'
}


/**------------------------------------------------------
 * Listing
 */
export enum EnumKdpCategory {
	// ...
}

export enum EnumKdpMarketplace {
	COM 			= 'com',
	CO_UK 			= 'co_uk',
	DE 				= 'de',
	FR 				= 'fr',
	IT 				= 'it',
	ES 				= 'es',
	JP 				= 'jp',
	CA 				= 'ca',
	AU 				= 'au'
}
