import { Util } from '@libs/utilities/util';

import { IValidatorError } from '../validator-error.interface';
import { IValidatorProcessResult, IValidatorValidationResult, EnumValidatorOperationType, IValidatorValidateOperation, IValidatorTransformOperation } from '../validator-value.interface';


export class ValidatorValueProcessor {

	/**------------------------------------------------------
	 * Process Value by Pipeline
	 */
	processValueByOperations(operations: Array<IValidatorValidateOperation | IValidatorTransformOperation>, value: any, property: string = 'value'): IValidatorProcessResult {

		//0 - deep copy the value, don't change the original
		let copyOfValue: any = (typeof value === 'object')
			? Util.Basic.deepCopy(value) 							// deep copy if object, array, ...
			: Util.String.isString(value) ? `${value}` : value;		// browsers don't deep copy string, we need to do that manually

		//1 - perform validations
		const validationResults: IValidatorValidationResult[] = [];
		outerLoop: for (const operation of operations) {
			switch (operation.type) {

				//a. validate the value
				case EnumValidatorOperationType.Validate:
					const result: IValidatorValidationResult = operation.validateFn(copyOfValue);
					if (!result.isValid) validationResults.push(result);
					if (result?.stopVal) break outerLoop;
					break;

				//b. transform / update the value
				case EnumValidatorOperationType.Transform:
					copyOfValue = operation.transformFn(copyOfValue);
					break;

				//c. undefined operation
				default:
					throw new Error(`ValidationProcessor => processValueByPipeline => FATAL ERROR: operation of $${(operation as any).type} is not defined`);
			}
		}

		//2 - processed result from the pipeline
		const result: IValidatorProcessResult = {
			transformedValue: copyOfValue,
			validationError	: this.convertValidationResultToError(value, property, validationResults)
		};

		//3 - return the result
		return result;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private convertValidationResultToError(value: any, property: string, validationResults: IValidatorValidationResult[]): IValidatorError | null {

		//0 - were there any validation errors?
		const isValidationValidForAll: boolean = validationResults.every((elem: IValidatorValidationResult) => elem.isValid);
		if (validationResults.length === 0 || isValidationValidForAll) return null;

		//1 - unify the validation results
		const validationError: IValidatorError = {
			property	: property,
			value		: value,
			errors		: validationResults.flatMap((elem: IValidatorValidationResult) => elem.error),
			children    : validationResults.flatMap((elem: IValidatorValidationResult) => elem?.children || [])
		};

		//2 - return the validation error
		return validationError;
	}
}
