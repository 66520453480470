import { Util } from '@libs/utilities/util';

import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';
import { EnumValidatorOperationType } from '../shared/validator-value.interface';


export class ValidatorValueDate extends AbstractValidatorValue<ValidatorValueDate> {

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'date'; }
	protected isValueTypeValid(value: any): boolean { return Util.Date.isValidDate(value) || Util.Date.isValidDateString(value); }


	/**------------------------------------------------------
	 * Date Instance
	 */
	isDateClass(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: value instanceof Date,
				error		: `The value must be a date object`
			})
		});
		return this;
	}

	isDateString(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: Util.String.isString(value),
				error		: `The value must be a date string`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Day
	 */
	isToday(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: Util.Date.isToday(new Date(value)),
				error		: `The value must be today`
			})
		});
		return this;
	}

	isYesterday(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: Util.Date.isYesterday(new Date(value)),
				error		: `The value must be yesterday's date`
			})
		});
		return this;
	}

	isTomorrow(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: Util.Date.isTomorrow(new Date(value)),
				error		: `The value must be tomorrow's date`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * After, Before, and Between
	 */
	isAfter(date: Date | string): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: new Date(value) > new Date(date),
				error		: `The value must be after ${new Date(date).toLocaleDateString()} date`
			})
		});
		return this;
	}

	isBefore(date: Date | string): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: new Date(value) < new Date(date),
				error		: `The value must be before ${new Date(date).toLocaleDateString()} date`
			})
		});
		return this;
	}

	isBetween(minDate: Date | string, maxDate: Date | string): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: new Date(value) > new Date(minDate) && new Date(value) < new Date(maxDate),
				error		: `The value must be between ${minDate} and ${maxDate}`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Future / Past
	 */
	isInFuture(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: Util.Date.inFuture(new Date(value)),
				error		: `The value must be in the future`
			})
		});
		return this;
	}

	isInPast(): ValidatorValueDate {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (value: string | Date) => ({
				isValid		: Util.Date.inPast(new Date(value)),
				error		: `The value must be in the past`
			})
		});
		return this;
	}
}
