import { CryptoSymmetricEncryption } from './utilities/symmetric-encryption/symmetric-encryption';
import { CryptoAsymmetricEncryption } from './utilities/asymmetric-encryption/asymmetric-encryption';
import { CryptoAsymmetricSignature } from './utilities/asymmetric-signature/asymmetric-signature';
import { CryptoHash } from './utilities/hash/hash';
import { CryptoPerceptualHash } from './utilities/perceptual-hash/perceptual-hash';
import { CryptoPasswordHash } from './utilities/password-hash/password-hash';
import { CryptoSymmetricSignature } from './utilities/symmetric-signature/symmetric-signature';
import { CryptoTimeBasedOtp } from './utilities/time-based-otp/time-based-otp';


/**------------------------------------------------------
 * Crypto Utilities
 * ----------------
 * > Dependencies: node-forge
 */
export class Crypto {


	/**------------------------------------------------------
	 * Encryption
	 */
	static readonly SymmetricEncryption	: CryptoSymmetricEncryption 	= new CryptoSymmetricEncryption();
	static readonly AsymmetricEncryption: CryptoAsymmetricEncryption 	= new CryptoAsymmetricEncryption();


	/**------------------------------------------------------
	 * Hashing
	 */
	static readonly Hash				: CryptoHash 					= new CryptoHash();
	static readonly PasswordHash		: CryptoPasswordHash 			= new CryptoPasswordHash();
	static readonly PerceptualHash		: CryptoPerceptualHash 		= new CryptoPerceptualHash();
	static readonly TimeBasedOtp		: CryptoTimeBasedOtp 			= new CryptoTimeBasedOtp();


	/**------------------------------------------------------
	 * Signatures
	 */
	static readonly SymmetricSignature	: CryptoSymmetricSignature 	= new CryptoSymmetricSignature();
	static readonly AsymmetricSignature	: CryptoAsymmetricSignature 	= new CryptoAsymmetricSignature();
}
