import JSZip from 'jszip';
import { Util } from '@libs/utilities/util';
import { FILE_TYPE_INFO, TypeResolve } from '@libs/constants';

import { IZipExtractOptions, IZipExtractState } from './zip-extract.interface';


/**------------------------------------------------------
 * Zip Extract Logic
 */
export class ZipExtractCore {

	//** Configurations */
	private readonly IGNORE_FILES  		  		: string[] = ['._.DS_Store', '.DS_Store'];
	private readonly IGNORE_FILES_PREFIX  		: string[] = ['._'];
	private readonly IGNORE_FILES_POSTFIX 		: string[] = [];
	private readonly SUPPORTED_ZIP_FILE_TYPES 	: string[] = ['.zip'];
	private readonly ALL_ZIP_FORMAT_EXTENSIONS	: string[] = ['.zip', '.tar', '.gz', '.7z', '.zipx', '.z', '.cab', '.rar', '.iso', '.wim'];

	private readonly DEFAULT_ZIP_EXTRACT_OPTIONS: IZipExtractState = {
		deepExtract	 	: true,
		progress$	 	: null!,
		progressCount	: 0,
		totalFileCount	: 0
	};


	/**------------------------------------------------------
	 * Checking Zip Formats
	 */
	isZipFormat(file: File | null): boolean {

		//0 - is the file a zip file
		for (const extension of this.ALL_ZIP_FORMAT_EXTENSIONS) {
			if (file!.name.toLowerCase().endsWith(extension)) return true;
		}

		//1 - return that it is not a zip file
		return false;
	}

	//** Check if zip standard is supported */
	isZipStandardSupported(file: File | null): boolean {

		//0 - is the zip format supported?
		for (const extension of this.SUPPORTED_ZIP_FILE_TYPES) {
			if (file!.name.toLowerCase().endsWith(extension)) return true;
		}

		//1 - if not supported return false
		return false;
	}


	/**------------------------------------------------------
	 * Extract Files form Zip
	 */
	async extractFromZip(zipFile: File, options?: IZipExtractOptions): Promise<File[]> {

		//0 - extract files from zip
		const optionState	: IZipExtractState 	= Util.Function.assignOptions(this.DEFAULT_ZIP_EXTRACT_OPTIONS, options);
		const extractedFiles: File[] 			= await this.extractFilesFromZip(zipFile, optionState);

		//1 - update the progress, that the import finished (value 100%)
		optionState.progress$?.next(100);

		//2 - return the extracted files
		return extractedFiles;
	}


	/**------------------------------------------------------
	 * Extract Files / Objects from Zip
	 */
	private extractFilesFromZip(zipFile: File | null, options: IZipExtractState): Promise<File[]> {
		return new Promise((resolve: TypeResolve<File[]>) => {

			//0 - collect all extracted files in this array
			if (!this.isZipStandardSupported(zipFile)) {
				resolve([]);
				return;
			}

			//1 - extract all the files form the zip
			const zip: JSZip = new JSZip();
			if (!zipFile) throw new Error();
			zip.loadAsync(zipFile).then(async (contents: JSZip) => {

				//a. extract the files
				const extractedFiles: File[] = await this.extractZipContent(zip, contents, options);

				//b. resolve with the extracted files
				resolve(extractedFiles);

			}).catch((error: unknown) => {
				console.error(`ZipExtractCore => extractFilesFromZip => FATAL ERROR: error at loading the zip content (error: ${error})`);
				resolve([]);			// note: promise does not resolve with null
			});
		});
	}

	private async extractZipContent(zip: JSZip, contents: JSZip, options: IZipExtractState): Promise<File[]> {

		//0 - add to the total file count
		options.totalFileCount += Object.entries(zip.files).length;

		//1 - extract zip objects
		const extractedFiles: File[] = [];
		for (const zipFileObject of Object.values(contents.files)) {

			//a. update counter
			options.progressCount++;

			//b. get file from zip object
			const file: File | null = await this.fileFromZipObject(zipFileObject, zip);
			if (Util.Basic.isUndefined(file)) continue;

			//c. check if extracted element is a zip file
			if (options.deepExtract && this.isZipFormat(file)) {
				const extractedZipFiles: File[] = await this.extractFilesFromZip(file, options);
				extractedFiles.push(...extractedZipFiles);
				continue;
			}

			//d. push all extracted files (not for a zip, checked before)
			if (!file) continue;
			extractedFiles.push(file);

			//e. update progress
			options.progress$?.next(Util.Number.percent(options.progressCount, options.totalFileCount));
		}

		//2 - update progress
		options.progress$?.next(Util.Number.percent(options.progressCount, options.totalFileCount));

		//3 - return the extracted files
		return extractedFiles;
	}

	private async fileFromZipObject(zipFile: JSZip.JSZipObject, zip: JSZip): Promise<File | null> {

		//0 - ignore directory names
		if (zipFile.dir) return null;

		//1 - get file name
		const filePath : string = zipFile.name;
		const fileName : string = filePath.includes('/') ? Util.String.splitByLast(filePath, '/').second : filePath;

		//2 - should file be ignored (check file name include with '._', ...)
		if (this.isStartWithUnsupportedPrefix(fileName)) return null;
		if (this.isEndsWithUnsupportedPostfix(fileName)) return null;
		if (this.isFileToBeIgnored(fileName)) 			 return null;

		//3 - check accepted format
		const extension: string = Util.String.splitByLast(zipFile.name, '.').second.toLowerCase();

		//4 - get extracted file
		const fileBlob		: Blob   = await zip.file(filePath)!.async('blob');
		const type			: string = (FILE_TYPE_INFO as any)[extension].mimeType;					// get the file type of the current element
		const extractedFile	: File   = new File([fileBlob], fileName, { type: type });

		//5 - check if zip file
		return extractedFile;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */

	//** Check start with unsupported prefix */
	private isStartWithUnsupportedPrefix(value: string): boolean {
		const ignoreCaseValue: string = value.toLowerCase();
		for (const prefix of this.IGNORE_FILES_PREFIX) {
			if (ignoreCaseValue.startsWith(prefix.toLowerCase())) return true;
		}
		return false;
	}

	//** Check ends with with unsupported prefix  */
	private isEndsWithUnsupportedPostfix(value: string): boolean {
		const ignoreCaseValue: string = value.toLowerCase();
		for (const postfix of this.IGNORE_FILES_POSTFIX) {
			if (ignoreCaseValue.endsWith(postfix.toLowerCase())) return true;
		}
		return false;
	}

	//** Check file to be ignored */
	private isFileToBeIgnored(value: string): boolean {
		const ignoreValue: string = value.toLowerCase();
		for (const prefix of this.IGNORE_FILES) {
			if (ignoreValue.includes(prefix.toLowerCase())) return true;
		}
		return false;
	}
}
