import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchLinkUrlConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * 9Gag Search Url
 */
export class Social9GagSearch implements ISearchLinks {

	//** Configurations */
	private readonly GAG9_CONFIG: ISearchLinkUrlConfig = {
		home		: 'https://9gag.com',
		login		: 'https://9gag.com/login',
		search		: 'https://9gag.com/search/?query=[SEARCH]'
	};


	//** Home & Login */
	home() : string { return this.GAG9_CONFIG.home; }
	login(): string { return this.GAG9_CONFIG.login; }

	//** Search */
	search(searchQuery: string): string {

		//0 - if no search string existing then return main or home page
		if (Util.String.isEmpty(searchQuery)) return this.home();

		//1 - else return the url with search query
		return this.GAG9_CONFIG.search.replace('[SEARCH]', searchQuery);
	}
}
