import { Util } from '@libs/utilities/util';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TypeResolve } from '@libs/constants';

import { EnumMessageType, IWorkerMessage, TypeProgressCallback } from '../worker-assistant.interface';


/**------------------------------------------------------
 * Worker Wrapper (contains the worker instance)
 */
export class WorkerWrapper {

	//** Helper Functions */
	private subjectFinished$ : Subject<any>  = new Subject<any>();
	private subjectProgress$ : Subject<any>  = new Subject<any>();
	private subjectTerminate$: Subject<void> = new Subject<void>();

	constructor(
		private worker	: Worker
	) {
		this.registerActionListeners();
	}


	/**------------------------------------------------------
	 * Run worker (promise base approach)
	 */
	process<TParams, TResult>(params: TParams): Promise<TResult | null> {
		return new Promise((resolve: TypeResolve<TResult | null>) => {

			//a. start the process
			this.worker.postMessage(params);

			//b. subscript to result
			this.subjectFinished$.pipe(take(1)).subscribe((data: TResult | null) => {
				resolve(data);
			});
		});
	}


	/**------------------------------------------------------
	 * Run worker (subject based approach)
	 */
	start<TParams>(params: TParams): WorkerWrapper {
		this.worker.postMessage(params);
		return this;
	}

	restart<TParams>(params: TParams): WorkerWrapper {
		return this.terminate().start(params);
	}

	onFinish<TResult>(callbackFunction: Function): WorkerWrapper {
		this.subjectFinished$.subscribe((result: TResult | null) => {
			callbackFunction(result);
		});
		return this;
	}

	onProgress(progressCallback: TypeProgressCallback): WorkerWrapper {
		this.subjectProgress$.subscribe((progress: number) => {
			progressCallback(progress);
		});
		return this;
	}

	toPromise<TResult>(): Promise<TResult | null> {
		return new Promise((resolve: TypeResolve<TResult | null>) => {
			this.subjectFinished$.pipe(take(1)).subscribe((data: TResult | null) => {
				resolve(data);
			});
		});
	}


	/**------------------------------------------------------
	 * Terminate / Stop Running Worker
	 */
	terminate(): WorkerWrapper {
		this.worker.terminate();
		this.subjectTerminate$.next();
		return this;
	}

	terminateAfter(timeMs: number): WorkerWrapper {
		if (timeMs <= 0) throw new Error(`WorkerWrapper => terminateAfter => FATAL ERROR: timeMs of "${timeMs}" is 0 or less`);
		setTimeout(() => {
			this.terminate();
		}, timeMs);
		return this;
	}

	terminateOnFinish(): WorkerWrapper {
		this.onFinish(() => {
			this.terminate();
		});
		return this;
	}

	onTerminate(callbackFunction: Function): WorkerWrapper {
		this.subjectTerminate$.subscribe(() => {
			callbackFunction();
		});
		return this;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private registerActionListeners() {

		//0 - action / trigger on success
		this.worker.onmessage = ({ data } : { data: IWorkerMessage }) => {

			//a. check if the message from the worker is valid
			if (Util.Basic.isUndefined(data?.type)) throw new Error(`WorkerWrapper => registerActionListeners => FATAL ERROR: the type value in the response is undefined (the message should be of "IWorkerMessage")`);
			if (Util.Basic.isUndefined(data?.data)) throw new Error(`WorkerWrapper => registerActionListeners => FATAL ERROR: the data value in the response is undefined (the message should be of "IWorkerMessage")`);

			//b. process the return type
			switch (data.type) {
				case EnumMessageType.Progress:
					this.subjectProgress$.next(data.data);
					break;

				case EnumMessageType.Finish:
					this.subjectFinished$.next(data.data);
					break;

				default:
					throw new Error(`WorkerWrapper => registerActionListeners => FATAL ERROR: the message type of "${data.type}" was not defined (valid are: "${Util.Enum.values(EnumMessageType)}")`);
			}
		};

		//1 - action / trigger on error
		this.worker.onerror = (error: unknown) => {
			this.subjectFinished$.next(null);
		};
	}
}
