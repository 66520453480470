import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { EnumErrorModalType, IErrorMessage, ERROR_MESSAGES, EnumErrorModalButtonAction } from './error-modal.data';


@Component({
	selector	: 'app-error-modal',
	templateUrl	: './error-modal.component.html',
	styleUrls	: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

	@Input() type   : EnumErrorModalType = null;

	//** Helper Members */
	public htmlDesc	: SafeHtml		= null;
	errorDetails	: IErrorMessage = null;

	constructor(
		private activeModal	: NgbActiveModal,
		private sanitizer	: DomSanitizer
	) {}


	ngOnInit(): void {

		//0 - check the type
		const errorConfig: IErrorMessage = ERROR_MESSAGES.find((elem: IErrorMessage) => elem.type === this.type);
		if (!errorConfig) throw new Error(`ErrorModalComponent => ngOnInit => FATAL ERROR: invalid error type: ${this.type}, no error configuration exist for that type`);

		//1 - construct the error modal
		this.errorDetails = errorConfig;
		this.htmlDesc 	  = this.sanitizer.bypassSecurityTrustHtml(this.errorDetails.description);
	}

	//** Handle button action */
	onButtonAction(action: EnumErrorModalButtonAction): void {

		switch (action) {

			//0 - reload page
			case EnumErrorModalButtonAction.Reload:
				location.reload();
				this.activeModal.close();
				break;

			//1 - close modal
			case EnumErrorModalButtonAction.Close:
				this.activeModal.close();
				break;

			//2 - dismiss modal
			case EnumErrorModalButtonAction.Dismiss:
				this.activeModal.dismiss();
				break;

			default:
				throw new Error(`ErrorModalComponent => onButtonAction => FATAL ERROR: Invalid button action: ${action}`);
		}
	}
}
