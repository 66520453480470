/**------------------------------------------------------
 * Security Headers
 */
export enum EnumSecureHeaders {
	TimeToken			= 'x-ctt',					// content time token
	RequestEncryption	= 'x-cde',					// content data encryption
	SecureFingerPrint	= 'x-sfp',					// content secure form protection
	GoogleRecaptcha		= 'x-grecaptcha',			// content google recaptcha
	Authorization		= 'authorization'			// access token / auth token
}
