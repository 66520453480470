import { UtilBasic } from './util-basic';
import { UtilString } from './util-string';
import { UtilNumber } from './util-number';


/**------------------------------------------------------
 * Color Utilities
 * ---------------
 * > Info: Containing all functionalities related to work
 * > with colors.
 * ---------------
 * Check Brightness
 * > question: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
 */
export class UtilColor {

	//** Configurations */
	private readonly LUMEN_THRESHOLD_LIGHT_COLOR: number = 40;
	private readonly LUMEN_THRESHOLD_DARK_COLOR : number = 150;
	private readonly REGEX_HEX_COLOR_CHECK		: RegExp = /^#([0-9A-F]{6}|[0-9A-F]{8})$/i;
	private readonly REGEX_TRANSPARENCY_CHECK	: RegExp = /^#[0-9A-F]{8}$/i;

	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString,
		private utilNumber	: UtilNumber
	) {}


	/**------------------------------------------------------
	 * Calculate the brightness
	 * a) Lumen is given in a range of 0 - 255
	 * b) Brightness given in a range of 0 - 1
	 */
	lumen(color: string): number {
		this.checkColor(color);

		//0 - extract the color parts
		color = color.substring(1);			// strip #
		const rgb: number = parseInt(color, 16);	// convert rrggbb to decimal
		const r  : number = (rgb >> 16) & 0xff;		// extract red
		const g  : number = (rgb >>  8) & 0xff;		// extract green
		const b  : number = (rgb >>  0) & 0xff;		// extract blue

		//1 - calculate the lumen
		const lumen: number = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
		return Math.round(lumen);
	}

	brightness(color: string): number {
		const value: number = 1.0 / 255.0 * this.lumen(color);
		return this.utilNumber.roundDouble(value);
	}


	/**------------------------------------------------------
	 * Check for Light or Dark
	 */
	isLight(color: string): boolean {
		this.checkColor(color);
		return this.lumen(color) < this.LUMEN_THRESHOLD_LIGHT_COLOR;
	}
	isDark(color: string): boolean {
		this.checkColor(color);
		return this.lumen(color) > this.LUMEN_THRESHOLD_DARK_COLOR;
	}


	/**------------------------------------------------------
	 * Checking & Helpers
	 */
	isHexColor(color: string): boolean {
		if (this.utilBasic.isUndefined(color) || !this.utilString.isString(color)) throw new Error(`UtilColor => isHexColor => FATAL ERROR: provided color value of "${color}" is not a valid string`);
		return this.REGEX_HEX_COLOR_CHECK.test(color);
	}

	isTransparent(color: string): boolean {
		this.checkColor(color);
		return this.REGEX_TRANSPARENCY_CHECK.test(color);
	}

	removeTransparency(color: string): string {
		this.checkColor(color);
		return this.isTransparent(color) ? color.substring(0, 7) : color;
	}


	/**------------------------------------------------------
	 * Convert RGB Colors
	 */
	rgbToHex(r: number, g: number, b: number): string {
		return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
	}

	hexToRgb(hexCode: string): number[] {
		const r: number = parseInt(`0x${hexCode.substring(1, 3)}`);
		const g: number = parseInt(`0x${hexCode.substring(3, 5)}`);
		const b: number = parseInt(`0x${hexCode.substring(5, 7)}`);
		return [r, g, b];
	}


	/**------------------------------------------------------
	 * RBG
	 */
	isRgbColor(rgbColorValue: string): boolean {

		//0 - check if the color is valid
		const rgbColorRegex: RegExp = /^(rgba?)\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(\d+(\.\d+)?))?\)$/;
		if (!rgbColorRegex.test(rgbColorValue)) return false;

		//1 - extract the color components
		const match: RegExpMatchArray | null = rgbColorValue.match(rgbColorRegex);
		if (!match) return false;

		//2 - check if the color components are valid
		const r: number = parseInt(match[2], 10);
		const g: number = parseInt(match[3], 10);
		const b: number = parseInt(match[4], 10);
		if (match[1] === 'rgb') {
			return this.isValidColorComponent(r) && this.isValidColorComponent(g) && this.isValidColorComponent(b);
		}
		if (match[1] === 'rgba') {
			const a: number = parseFloat(match[6]);
			return this.isValidColorComponent(r) && this.isValidColorComponent(g) && this.isValidColorComponent(b) && (a >= 0 && a <= 1);
		}

		//3 - is not a valid RGB color
		return false;
	}


	/**------------------------------------------------------
	 * Functionalities
	 */
	randomHexColor(): string {
		const randomHexString: string = (Math.random() * 0xfffff * 1000000).toString(16);
		const randomHexColor : string = randomHexString.slice(0, 6);
		return `#${randomHexColor}`;
	}


	/**------------------------------------------------------
	 * Lighten & Darken Color
	 * > source: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
	 */
	lighten(color: string, amount: number): string {
		return this.adjustBrightnessOfColor(color, amount);
	}

	darken(color: string, amount: number): string {
		return this.adjustBrightnessOfColor(color, -amount);
	}

	private adjustBrightnessOfColor(color: string, amount: number): string {
		return `#${color.replace(/^#/, '').replace(/../g, (replacedColor: string) => `0${Math.min(255, Math.max(0, parseInt(replacedColor, 16) + amount)).toString(16)}`.substr(-2))}`;
	}


	/**------------------------------------------------------
	 * Helper Validation Checks
	 */
	private checkColor(color: string) {
		if (!this.isHexColor(color)) throw new Error(`UtilColor => checkColor => FATAL ERROR: provided color of "${color}" is not a valid hex color`);
	}

	//** convert color component like red, blue, green to hex */
	private componentToHex(colorComponentRange: number) {
		const hex: string = colorComponentRange.toString(16);
		return hex.length === 1 ? `0${hex}` : hex;
	}

	private isValidColorComponent(value: number) {
		return value >= 0 && value <= 255;
	}
}
