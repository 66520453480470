import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Util } from '@libs/utilities/util';
import { BehaviorSubject } from 'rxjs';

import { IAddonSuccessData, IAddonPassthrough, TypeAddonWindowPaddle, IAddonPaddleCheckoutParams, IAddonPaddleAuthParams } from './paddle.interface';


@Injectable()
export class AddonPaddleService {

	//** Window object to access the script functions */
	private readonly window	 : TypeAddonWindowPaddle;
	private readonly callback: BehaviorSubject<IAddonSuccessData | null> = new BehaviorSubject<IAddonSuccessData | null>(null);

	//** Helper Variables */
	private ref: string | null = null;
	private id : string | null = null;

	constructor(
		@Inject(DOCUMENT)
		private document: Document
	) {
		this.window = this.document.defaultView as TypeAddonWindowPaddle;
	}

	openCheckout(paddleParams: IAddonPaddleCheckoutParams): void {
		this.window.Paddle.Checkout.open(paddleParams);
	}

	setRef(ref: string): void {
		this.ref = ref;
	}

	getCallback(): BehaviorSubject<IAddonSuccessData | null> {
		return this.callback;
	}

	checkout(authParams: IAddonPaddleAuthParams, product: string, coupon: string | null = null): void {
		this.id = Util.Random.randomKey(32);
		if (!authParams) throw new Error(`AddonPaddleHelper => checkout => FATAL ERROR : Auth Params are not defined.`);
		this.openCheckout(this.getPaddleObject(authParams, product, coupon));
	}

	successCallback = (data: IAddonSuccessData): void => {
		if (data.checkout.completed) this.callback.next(data);
	};

	closeCallback = (data: IAddonSuccessData): void => {
		if (data.checkout.completed) this.callback.next(data);
	};


	/**------------------------------------------------------
	 * Helper Function
	 */
	private getPaddleObject(authParams: IAddonPaddleAuthParams, product: string, coupon: string | null): IAddonPaddleCheckoutParams {
		const paddleObject: IAddonPaddleCheckoutParams = {
			product		   : product,
			passthrough	   : Util.Basic.stringifyObject(this.getPassthrough(authParams, coupon)),
			email		   : authParams.userEmail,
			coupon		   : coupon,
			successCallback: this.successCallback,
			closeCallback  : this.closeCallback
		};
		return paddleObject;
	}

	private getPassthrough(authParams: IAddonPaddleAuthParams, coupon: string | null): IAddonPassthrough {
		const passthroughData: IAddonPassthrough = {
			isLoggedIn : Boolean(authParams.isAuthenticated),
			identifier : this.id,
			userId	   : authParams.userId,
			ref_code   : this.ref,
			coupon	   : coupon
		};
		return passthroughData;
	}
}
