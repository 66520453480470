import { AddonDarkModeService, EnumAddonDarkMode } from '@domains/frontend/page-addons';
import { Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { RESEARCH_THEME, UPLOAD_THEME } from './data/theme-colors.data';
import { EnumDefaultColor, EnumActionColor, EnumToastColor } from './data/default-colors.data';


@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	constructor(
		private darkModeService: AddonDarkModeService
	) {}

	//** Flying Research (blue colors) */
	setResearchTheme(): void {
		document.documentElement.style.setProperty('--gradient-light',		RESEARCH_THEME['gradient-light']);
		const activeMode: EnumAddonDarkMode = this.darkModeService.getActiveMode();
		document.documentElement.style.setProperty('--gradient-dark',		RESEARCH_THEME['gradient-dark']);
		document.documentElement.style.setProperty('--content',				RESEARCH_THEME.content);
		document.documentElement.style.setProperty('--content-background',	RESEARCH_THEME['content-background']);
		this.darkModeService.setMode(activeMode);
	}

	//** Flying Upload (orange colors) */
	setUploadTheme(): void {
		document.documentElement.style.setProperty('--gradient-light',			UPLOAD_THEME['gradient-light']);
		const activeMode: EnumAddonDarkMode = this.darkModeService.getActiveMode();
		document.documentElement.style.setProperty('--gradient-dark',			UPLOAD_THEME['gradient-dark']);
		document.documentElement.style.setProperty('--content',					UPLOAD_THEME.content);
		document.documentElement.style.setProperty('--content-background',		UPLOAD_THEME['content-background']);
		document.documentElement.style.setProperty('--upload-gradient-top',		UPLOAD_THEME['digital-vault']['gradient-top']);
		document.documentElement.style.setProperty('--upload-gradient-bottom', 	UPLOAD_THEME['digital-vault']['gradient-bottom']);
		this.darkModeService.setMode(activeMode);
	}

	setColors(): void {

		//0 - set default colors
		this.setColorsOfDefinition(EnumDefaultColor);

		//1 - set action colors
		this.setColorsOfDefinition(EnumActionColor);

		//2 - set toast colors
		this.setColorsOfDefinition(EnumToastColor);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private setColorsOfDefinition<T extends object>(colorDef: T) {
		for (const cssColorName of Util.Enum.keys(colorDef)) {
			document.documentElement.style.setProperty(`--${Util.String.camelToHyphen(cssColorName)}`, colorDef[cssColorName]);
		}
	}
}
