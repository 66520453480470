import { ISparkSidebarConfig, ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';
import { EnumNgFaStyle } from '@libs/frameworks/angular';

import { EnumSidebarPage } from '../sidebar-pages.interface';
import { SHARED_PAGE_LIST } from '../page-lists/shared-pages/shared-pages.data';
import { EnumWebAppTool } from '../../../../shared/constants/app.constant';
import { POD_PAGE_LIST, UPLOAD_PAGE_LIST, KDP_PAGE_LIST, TM_UPLOAD_POD_PAGE_LIST } from '../page-lists/upload-pages/upload-pages.data';


/**------------------------------------------------------
 * Upload Sidebar Menu
 */
export const UPLOAD_SIDEBAR_LIST: ISparkSidebarConfig = {
	appInfo			: {
		id			: EnumWebAppTool.Upload,
		label		: 'Flying Upload',
		logoWide	: 'assets/upload/logos/upload-logo-wide.svg',
		logoSmall	: 'assets/upload/logos/upload-logo.svg'
	},
	pageLists	: [
		{
			label	: 'Menu',
			menus	: [
				{
					id		: EnumSidebarPage.Dashboard,
					label	: 'Dashboards',
					icon	: {
						style	: EnumNgFaStyle.Solid,
						name	: 'grid-horizontal'
					},
					isCollapsed	: false,
					subMenus	: SHARED_PAGE_LIST
				}
			]
		},
		{
			label	: 'Designs & Uploads',
			menus	: [
				{
					id		: EnumSidebarPage.UploadMenuPod,
					label	: 'Print On Demand',
					icon	: {
						style	: EnumNgFaStyle.Solid,
						name	: 'shirt'
					},
					isCollapsed	: false,
					subMenus	: [...POD_PAGE_LIST, ...TM_UPLOAD_POD_PAGE_LIST]
				},
				{
					id		: EnumSidebarPage.UploadMenuKdp,
					label	: 'Kindle Direct Publishing',
					icon	: {
						style	: EnumNgFaStyle.Solid,
						name	: 'book-open'
					},
					isCollapsed	: false,
					subMenus	: KDP_PAGE_LIST
				},
				{
					id		: EnumSidebarPage.UploadMenuUpload,
					label	: 'Upload',
					icon	: {
						style	: EnumNgFaStyle.Solid,
						name	: 'cloud-arrow-up'
					},
					isCollapsed	: false,
					subMenus	: UPLOAD_PAGE_LIST
				}
				// {
				// 	label	: 'Advertise & Promote',
				// 	icon	: {
				// 		style	: EnumNgFaStyle.Solid,
				// 		name	: 'cloud-arrow-up'
				// 	},
				// 	isCollapsed	: false,
				// 	subMenus	: ADVERTISE_UPLOAD_PAGE_LIST
				// },
				// {
				// 	label	: 'Downloads & Extras',
				// 	icon	: {
				// 		style	: EnumNgFaStyle.Solid,
				// 		name	: 'cloud-arrow-up'
				// 	},
				// 	isCollapsed	: false,
				// 	subMenus	: DOWNLOADS_UPLOAD_PAGE_LIST
				// }
			]
		}
	]
};


/**------------------------------------------------------
 * All Upload Pages
 */
export const ALL_UPLOAD_PAGES_LIST: ISparkSidebarSubMenu[] = [
	...POD_PAGE_LIST,
	...TM_UPLOAD_POD_PAGE_LIST,
	...UPLOAD_PAGE_LIST
];
