import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_DISPLATE_LISTING_LIMITATIONS: IFlyingDisplateListingConfig = {
	TITLE_LENGTH			: 26,
	DESCRIPTION_LENGTH 		: 140,
	COLLECTION_LENGTH		: 26,
	KEYWORD_LENGTH			: 50,
	MAX_KEYWORDS			: 20,
	MAX_CATEGORIES			: 1,
	BACKGROUND_LENGTH		: 100
};

interface IFlyingDisplateListingConfig {
	TITLE_LENGTH			: number;
	DESCRIPTION_LENGTH 		: number;
	COLLECTION_LENGTH		: number;
	KEYWORD_LENGTH			: number;
	MAX_KEYWORDS			: number;
	MAX_CATEGORIES			: number;
	BACKGROUND_LENGTH		: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingDisplateArtworkType {
	Illustration	= 'illustration',
	Photography		= 'photography'
}

export enum EnumFlyingDisplateCategory {
	Abstract		= 'abstract',
	Animals			= 'animals',
	AnimeAndManga	= 'anime & manga',
	Blueprints		= 'blueprints',
	Books			= 'books',
	Cars			= 'cars',
	Cartoons		= 'cartoons',
	Celebrities		= 'celebrities',
	Cityscapes		= 'cityscapes',
	Comics			= 'comics',
	ContemporaryArt	= 'contemporary art',
	Cute			= 'cute',
	Fantasy			= 'fantasy',
	Fashion			= 'fashion',
	Floral			= 'floral',
	FoodAndKitchen	= 'food & kitchen',
	Funny			= 'funny',
	Gaming			= 'gaming',
	Inspirational	= 'inspirational',
	JapaneseAndAsian = 'japanese & asian',
	KidsRoom		= `kid's room`,
	Landscapes		= 'landscapes',
	Mancave			= 'mancave',
	Maps			= 'maps',
	Military		= 'military',
	Minimalistic	= 'minimalistic',
	Movies			= 'movies',
	Music			= 'music',
	Nature			= 'nature',
	Paintings		= 'paintings',
	Planes			= 'planes',
	PopArt			= 'pop art',
	Retro			= 'retro',
	Space			= 'space',
	Sport			= 'sport',
	TextArt			= 'text art',
	Travel			= 'travel',
	TvShows			= 'tv shows',
	UnitedStates	= 'united states',
	VintagePoster	= 'vintage posters'
}


/**------------------------------------------------------
 * Displate Data Interfaces
 */
export interface IFlyingDisplateListing {
	text  	 		: IFlyingDisplateText;
	options  		: IFlyingDisplateOptions;
}

export interface IFlyingDisplateText {
	title           : string;
	description     : string;
	keywords     	: string[];
}

export interface IFlyingDisplateOptions {
	collectionName	: string;
	categories      : EnumFlyingDisplateCategory[];
	background      : string;
	artworkType     : EnumFlyingDisplateArtworkType;
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingDisplateListingSchema implements ValidatorSchema<IFlyingDisplateListing> {
	text	= {
		title			: Validator.String().isMaxLength(FLYING_DISPLATE_LISTING_LIMITATIONS.TITLE_LENGTH),
		description		: Validator.String().isMaxLength(FLYING_DISPLATE_LISTING_LIMITATIONS.DESCRIPTION_LENGTH),
		keywords		: Validator.Array().each(Validator.String().isNotEmpty().isMaxLength(FLYING_DISPLATE_LISTING_LIMITATIONS.KEYWORD_LENGTH)).isMaxSize(FLYING_DISPLATE_LISTING_LIMITATIONS.MAX_KEYWORDS)
	};
	options	= {
		collectionName	: Validator.String().isMaxLength(FLYING_DISPLATE_LISTING_LIMITATIONS.COLLECTION_LENGTH),
		background		: Validator.String().isMaxLength(FLYING_DISPLATE_LISTING_LIMITATIONS.BACKGROUND_LENGTH),
		categories		: Validator.Array().each(Validator.Enum(EnumFlyingDisplateCategory).isNotEmpty()).isMaxSize(FLYING_DISPLATE_LISTING_LIMITATIONS.MAX_CATEGORIES),
		artworkType		: Validator.Enum(EnumFlyingDisplateArtworkType).isNotEmpty()
	};
}
