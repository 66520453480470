import { Injectable } from '@angular/core';

import { AccessStateHelper } from './sub-states/access.helper';
import { AppStateHelper } from './sub-states/app.helper';
import { MaintenanceStateHelper } from './sub-states/maintenance.helper';
import { NewsStateHelper } from './sub-states/news.helper';
import { UserStateHelper } from './sub-states/user.helper';
import { ThemeService } from '../helpers/theme/theme.service';
import { AppInfoRequest } from '../../../requests/shared/app-info/app-info.request';


@Injectable({
	providedIn: 'root'
})
export class StatesService {

	constructor(
		private themeService	: ThemeService,
		private appInfoRequest	: AppInfoRequest
	) {}


	//** Access Right Management */
	readonly access			: AccessStateHelper		 = new AccessStateHelper();

	//** App State Management */
	readonly appState		: AppStateHelper		 = new AppStateHelper(this.themeService);

	//** Maintenance Flags Management */
	readonly maintenance	: MaintenanceStateHelper = new MaintenanceStateHelper(this.appInfoRequest);

	//** News & Info Management */
	readonly news			: NewsStateHelper		 = new NewsStateHelper(this.appInfoRequest);

	//** User Info */
	readonly user			: UserStateHelper		 = new UserStateHelper();
}
