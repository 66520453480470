import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_UK: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'en_GB'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog&i=fashion&rh=n%3A11961407031%2Cp_6%3AA3P5ROKL5A1OLE&s=featured&hidden-keywords=%22Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem%22+-tanktop+-raglan+-vneck+-longsleeve
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			language	: 'en_GB',
			i			: 'fashion',
			rh			: 'n%3A11961407031%2Cp_6%3AA3P5ROKL5A1OLE', // cspell:disable-line
			'hidden-keywords': '%22Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem%22+-tanktop+-raglan+-vneck+-longsleeve'
		}
	},
	/* {
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameter 	: {
			language	: 'en_GB',
		},
	}, */
	{
		// https://www.amazon.co.uk/s?hidden-keywords=Lightweight+Classic+fit+Double+needle+sleeve+and+bottom+hem+v-neck+Solid+colours+100+Cotton+Heather+Grey+90+Cotton+10+Polyester+All+Other+Heathers+50+Cotton+50+Polyester
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Lightweight+Classic+fit+Double+needle+sleeve+and+bottom+hem+v-neck+Solid+colours+100+Cotton+Heather+Grey+90+Cotton+10+Polyester+All+Other+Heathers+50+Cotton+50+Polyester'
		}
	},
	{
		// https://www.amazon.co.uk/s?hidden-keywords=Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+%22tank+top%22
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+%22tank+top%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?hidden-keywords=Solid+colors%3A+100%25+Cotton%3B+Heather+Grey%3A+90%25+Cotton%2C+10%25+Polyester%3B+All+Other+Heathers%3A+50%25+Cotton%2C+50%25+Polyester+%22long+sleeve%22
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Solid+colors%3A+100%25+Cotton%3B+Heather+Grey%3A+90%25+Cotton%2C+10%25+Polyester%3B+All+Other+Heathers%3A+50%25+Cotton%2C+50%25+Polyester+%22long+sleeve%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?raglon&i=clothing&hidden-keywords=Solid+colours+100+Cotton+Heather+Grey+90+Cotton+10+Polyester+All+Other+Heathers+50+Cotton+50+Polyester+Raglan
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		queryOptions	: {
			prefix	: '',
			postfix	: '+raglon' // cspell:disable-line
		},
		parameters 	: {
			language	: 'en_GB',
			i			: 'clothing',
			'hidden-keywords': 'Solid+colours+100+Cotton+Heather+Grey+90+Cotton+10+Polyester+All+Other+Heathers+50+Cotton+50+Polyester+Raglan'
		}
	},
	{
		// https://www.amazon.co.uk/s?hidden-keywords=Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22Sweatshirt%22
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22Sweatshirt%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?hidden-keywords=Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22hoodie%22
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22hoodie%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog+PopSockets+Grip+and+Stand+for+Phones+and+Tablets&rh=p_76%3A419158031&dc&qid=1573821601&rnid=419157031
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			language	: 'en_GB',
			rh			: 'p_76%3A419158031',
			dc			: '',
			qid			: '1573821601',
			rnid		: '419157031' // cspell:disable-line
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+PopSockets+Grip+and+Stand+for+Phones+and+Tablets'
		}
	}
	/* {
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameter 	: {
			language	: 'en_GB',
		},
	},
	{
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameter 	: {
			language	: 'en_GB',
		},
	},
	{
		code		: EnumAmazonProduct.ThrowPillows,
		name		: 'Throw Pillows',
		parameter 	: {
			language	: 'en_GB',
		},
	},
	{
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameter 	: {
			language	: 'en_GB',
		},
	}, */
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_UK: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.CO_UK,
	websiteUrl		: {
		home		: 'https://www.amazon.co.uk/',
		login		: 'https://www.amazon.co.uk/ap/signin?openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=gbflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',
		search		: 'https://www.amazon.co.uk/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_UK,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
