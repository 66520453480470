import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { NgContentPopoverComponent } from '../content-popover/content-popover.component';
import { INgPopoverOptions } from '../content-popover/content-popover.interface';


@Component({
	selector		: 'ng-info-popover',
	templateUrl		: './info-popover.component.html',
	styleUrls		: ['./info-popover.component.scss'],
	changeDetection	: ChangeDetectionStrategy.OnPush
})
export class NgInfoPopoverComponent implements OnInit {

	@ViewChild(NgContentPopoverComponent) contentPopoverComponent!: NgContentPopoverComponent;

	@Output() readonly actionEvent: EventEmitter<string> = new EventEmitter<string>();

	@Input() title	 !: string;
	@Input() message !: TemplateRef<any> | string;
	@Input() info    !: INgPopoverInfo;
	@Input() options !: Partial<INgPopoverOptions>;

	//** Optional Configuration */
	@Input() disabled!: boolean;

	ngOnInit(): void {

		//0 - are the inputs valid
		if (Util.Object.isNotEmpty(this.info) && (Util.String.isNotEmpty(this.title) || Util.String.isNotEmpty(this.message))) {
			throw new Error(`NgInfoPopoverComponent => ngOnInit => FATAL ERROR: you can't use both ('title'|'message') and 'info' inputs at the same time`);
		}

		//1 - set the settings
		if (Util.String.isNotEmpty(this.info?.title))   this.title = this.info.title;
		if (Util.String.isNotEmpty(this.info?.message)) this.message = this.info.message;
	}

	//** Emit action */
	emitAction(code: string): void {
		this.actionEvent.emit(code);
	}
}


//** Interfaces --------------------------------- */
export interface INgPopoverInfo {
	title	: string;
	message	: string;
}
