import { Inject, Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { AddonPageAddonsRenderer } from '../../shared/page-addons-renderer.helper';
import { EnumAddonPageAddonInjection, IAddonAddOnScriptScript } from '../../shared/page-addons.interface';


@Injectable()
export class AddonGoogleReCaptchaScript implements IAddonAddOnScriptScript {

	constructor(
		@Inject(EnumAddonPageAddonInjection.GoogleRecaptchaKey)
		private recaptchaKey	: string,
		private renderHelper	: AddonPageAddonsRenderer
	) {}

	create(): AddonGoogleReCaptchaScript {

		//0 - check if the google recaptcha key was configured
		if (Util.String.isEmpty(this.recaptchaKey)) throw new Error(`GoogleReCaptchaScript => create => FATAL ERROR: Google Recaptcha Key was not provided in the module (value is: "${this.recaptchaKey}")`);

		//1 - define the scripts
		const script1Attributes: IAddonScript1Attributes = {
			src		: `https://www.google.com/recaptcha/api.js?render=${this.recaptchaKey}`,
			async	: false
		};

		//2 - add the scripts
		this.renderHelper.addScriptToHead('google_recaptcha', { attributes: script1Attributes });
		return this;
	}
}


//** Interfaces --------------------------------- */
interface IAddonScript1Attributes {
	src		: string;
	async	: boolean;
}
