import { UtilString } from './util-string';
import { UtilBasic } from './util-basic';
import { UtilHexToBinary } from './algorithms/hex-to-binary';


/**------------------------------------------------------
 * Number Utilities
 * ----------------
 * > Info: Containing all functionalities related to numbers
 * > and calculations.
 */
export class UtilNumber {

	//** Injections for Algorithm Extensions */
	private readonly hexToBinaryAlg: UtilHexToBinary = new UtilHexToBinary();

	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString
	) {}


	/**------------------------------------------------------
	 * Check for Number
	 */
	isNumber<T>(value: T): boolean {
		const isEmptyString: boolean = this.utilString.isString(value) && this.utilString.isEmpty(value);
		return this.utilBasic.isDefined(value) && !isEmptyString && !isNaN(value as any);
	}
	isNotNumber<T>(value: T): boolean {
		return !this.isNumber(value);
	}
	areNumbers<T>(values: T[]): boolean {

		//0 - check the function call
		if (!this.isArray(values)) throw new Error(`UtilNumber => areNumbers => FATAL ERROR: provided parameter is not an array`);

		//1 - check if all elements in the array are of type number
		for (const value of values) {
			if (!this.isNumber(value)) return false;
		}
		return true;
	}


	/**------------------------------------------------------
	 * Convert Numbers
	 */
	toNumber<T extends string | number>(value: T, fallback?: number): number {

		//0 - try to convert the value
		const isInvalid: boolean = this.utilBasic.isUndefined(value) || (this.utilString.isString(value) && this.utilString.isEmpty(value));
		const converted: number | null = !isInvalid ? Number(value) : null;
		if (this.isNumber(converted)) return converted!;

		//1 - was any fallback value defined? if so use it
		if (this.isNumber(fallback)) return fallback!;

		//2 - number can not be converted and no fallback value was defined
		throw new Error(`UtilNumber => toNumber => FATAL ERROR: provided value of "${value}" can not be converted to a number (you can also use the "fallback" to prevent the exceptions)`);
	}
	toNumberArray<T extends string | number>(values: T[], fallback?: number): number[] {
		if (!this.isArray(values)) throw new Error(`UtilNumber => toNumberArray => FATAL ERROR: provided values of "${values}" is not an array`);
		return values.map((value: T) => this.toNumber(value, fallback));
	}

	toInteger<T extends string | number>(value: T, fallback?: number): number {
		return Math.trunc(this.toNumber(value, fallback));
	}
	toSafeInteger<T extends string | number>(value: T, fallback?: number): number {
		return this.toSaveNumber(this.toInteger(value, fallback), Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
	}

	toSaveNumber(value: number, min: number, max: number): number {
		this.checkNumbers([value, min, max]);
		if (value < min) return min;
		if (value > max) return max;
		return value;
	}

	percent(value: number, maxValue: number): number {
		this.checkNumbers([value, maxValue]);
		return (100 / maxValue) * value;
	}
	perThousand(value: number, maxValue: number): number {
		return this.percent(value, maxValue) / 10;
	}


	/**------------------------------------------------------
	 * Converting to Positive / Negative
	 */
	toPositive(value: number): number {
		this.checkNumbers([value]);
		return Math.abs(value);
	}
	toNegative(value: number): number {
		this.checkNumbers([value]);
		return -Math.abs(value);
	}


	/**------------------------------------------------------
	 * Formatting of Numbers
	 */

	//** Convert Number to decimal string (example: 12305030388.9087 => "12,305,030,388.909") */
	toDecimalMark(value: number, local: string = 'en-US'): string {
		return value.toLocaleString(local);
	}

	//** Converts a number to an array of digits (example: 123 => [1, 2, 3]) */
	digitize(value: number): number[] {
		this.checkNumberPositive(value);
		return [...`${value}`].map((elem: string) => parseInt(elem));
	}

	//** Adds an ordinal suffix to a number (example: "123" => "123rd" */
	toOrdinalSuffix(value: number): string {

		//0 - parse the number to full int
		const intNumber: number = this.toInteger(value);

		//1 - configurations for the suffix creation
		const ordinals : string[] = ['st', 'nd', 'rd', 'th'];
		const oPattern : number[] = [1, 2, 3, 4];
		const tPattern : number[] = [11, 12, 13, 14, 15, 16, 17, 18, 19];

		//2 - create the number string with suffix
		const digits : number[] = [intNumber % 10, intNumber % 100];
		return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
			? intNumber + ordinals[digits[0] - 1]
			: intNumber + ordinals[3];
	}


	/**------------------------------------------------------
	 * Convert Hex/Numbers
	 */
	toHex(numberValue: number): string {
		this.checkNumber(numberValue);
		return numberValue.toString(16);
	}
	fromHex(hexValue: string): number {
		if (this.utilString.isEmpty(hexValue)) throw new Error(`UtilNumber => fromHex => FATAL ERROR: provided hexValue of "${hexValue}" is empty`);
		return parseInt(hexValue, 16);
	}


	/**------------------------------------------------------
	 * Convert Binary/Numbers
	 */
	toBinary(numberValue: number): string {
		this.checkNumber(numberValue);
		return numberValue.toString(2);
	}
	fromBinary(binaryValue: string): number {
		if (this.utilString.isEmpty(binaryValue)) throw new Error(`UtilNumber => fromBinary => FATAL ERROR: provided binaryValue of "${binaryValue}" is empty`);
		return parseInt(binaryValue, 2);
	}


	/**------------------------------------------------------
	 * Number Checks
	 */
	isInteger(value: number): boolean {
		if (!this.isNumber(value)) return false;
		return Number.isInteger(value);
	}
	isFloat(value: number): boolean {
		if (!this.isNumber(value)) return false;
		return !this.isInteger(value);
	}
	isPrime(value: number): boolean {
		this.checkNumber(value);
		for (let i: number = 2; i < value; i++) {
			if (value % i === 0) return false;
		}
		return value > 1;
	}
	inRange(value: number, range: { min: number; max: number }): boolean {
		if (!range?.min) return range.max > value;
		if (!range?.max) return range.min < value;
		return range.min < value && value < range.max;
	}
	isNegative(value: number): boolean {
		if (!this.isNumber(value)) return false;
		return value < 0;
	}
	isPositive(value: number): boolean {
		if (!this.isNumber(value)) return false;
		return value > 0;
	}
	isPowerOfTwo(value: number): boolean {
		this.checkNumberPositive(value);
		return Boolean(value) && (value & (value - 1)) === 0;
	}
	isClosedTo(value: number, options: { expected: number; offset: number }): boolean {
		const lowerBound: number = options.expected - options.offset;
		const upperBound: number = options.expected + options.offset;
		return value >= lowerBound && value <= upperBound;
	}


	/**------------------------------------------------------
	 * Min & Max
	 */
	min(values: number[], fallbackValue: number = -1): number {
		this.checkNumbers(values);
		if (values.length === 0) return fallbackValue;
		return Math.min(...values);
	}
	max(values: number[], fallbackValue: number = -1): number {
		this.checkNumbers(values);
		if (values.length === 0) return fallbackValue;
		return Math.max(...values);
	}
	avg(values: number[], fallbackValue: number = -1): number {
		this.checkNumbers(values);
		if (values.length === 0) return fallbackValue;
		return values.reduce((a: number, b: number) => a + b, 0) / values.length;
	}
	sum(values: number[]): number {
		this.checkNumbers(values);
		return values.reduce((a: number, b: number) => a + b, 0);
	}


	/**------------------------------------------------------
	 * Decimal places counters
	 */
	preDecimalPlaces(value: number): number {
		this.checkNumber(value);
		return value.toFixed(0).toString().replace('-', '').length;
	}
	decimalPlaces(value: number): number {
		this.checkNumber(value);
		if (this.isInteger(value)) return 0;
		return value.toString().split('.')[1].length || 0;
	}


	/**------------------------------------------------------
	 * Rounding
	 */
	roundInteger(value: number): number {
		this.checkNumber(value);
		return Math.round(value);
	}
	roundDouble(value: number, decimals: number = 2): number {
		this.checkNumbers([value, decimals]);
		if (decimals < 0) throw new Error(`UtilNumber => round => FATAL ERROR: provided decimals of "${decimals}" are not allowed for rounding`);
		const decimalFactor: number = Math.pow(10, decimals);
		return Math.round(value * decimalFactor) / decimalFactor;
	}


	/**------------------------------------------------------
	 * Hex to Binary
	 */
	hexToBinary(hexString: string): string {
		return this.hexToBinaryAlg.hexToBinary(hexString);
	}

	binaryToHex(binaryString: string): string {
		return this.hexToBinaryAlg.binaryToHex(binaryString);
	}


	/**------------------------------------------------------
	 * Others
	 */

	//** Get Number from String */
	extractNumber<T>(stringValue: T, fallback?: number): number {

		//0 - extract the number from the string
		const numberString: string = this.utilString.toString(stringValue).replace(/[^\d.]*/g, '');
		if (this.utilString.isEmpty(numberString)) return this.toNumber(null as any, fallback);

		//1 - try to convert the number
		return this.toNumber(numberString, fallback);
	}


	/**------------------------------------------------------
	 * Generate Array of Number
	 */
	arrayOfAscNumbers(start: number, end: number): number[] {

		//0 - check the values
		if (start < 0)   throw new Error(`UtilNumber => arrayOfAscNumbers => FATAL ERROR: provided start of "${start}" is negative `);
		if (start > end) throw new Error(`UtilNumber => arrayOfAscNumbers => FATAL ERROR: provided start of "${start}" is larger then the end of "${end}" (ascending: 0, 1, 2, 3, ...)`);

		//1 - create the array of numbers
		const array: number[] = [];
		for (let i: number = start; i <= end; i++) array.push(i);
		return array;
	}
	arrayOfDescNumbers(start: number, end: number): number[] {

		//0 - check the values
		if (end < 0)	 throw new Error(`UtilNumber => arrayOfDescNumbers => FATAL ERROR: provided end of "${end}" is negative `);
		if (start < end) throw new Error(`UtilNumber => arrayOfDescNumbers => FATAL ERROR: provided end of "${end}" is larger then the start of "${start}" (descending: 10, 9, 8, ...)`);

		//1 - create the array of numbers
		const array: number[] = [];
		for (let i: number = start; i >= end; i--) array.push(i);
		return array;
	}


	/**------------------------------------------------------
	 * Helper Validation Checks
	 */
	checkNumber(value: number): void {
		this.checkNumbers([value]);
	}
	checkNumbers(values: number[]): void {
		for (const value of values) {
			if (!this.isNumber(value)) throw new Error(`UtilNumber => checkNumber => FATAL ERROR: provided value of "${value}" is not a number`);
		}
	}

	checkNumberPositive(value: number): void {
		this.checkNumbersPositive([value]);
	}
	checkNumbersPositive(values: number[]): void {
		for (const value of values) {
			if (!this.isNumber(value))  throw new Error(`UtilNumber => checkNumber => FATAL ERROR: provided value of "${value}" is not a number`);
			if (this.isNegative(value)) throw new Error(`UtilNumber => checkNumber => FATAL ERROR: provided value of "${value}" is negative`);
		}
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private isArray(array: any): boolean {
		return Boolean(array) && array.constructor === Array;		// duplication of the array util function
	}
}
