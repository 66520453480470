import { Util } from '@libs/utilities/util';

import { IImportExcelData, IImportSheetRow, IImportSheetCellValue, IExcelImportOptions } from './excel-import.interface';


export class ExcelDataHelper {

	//** Configurations */
	private readonly DEFAULT_IMPORT_OPTIONS: IExcelImportOptions = {
		removeEmptyLeading	: true,
		removeEmptyTrailing	: true,
		removeEmptyAllEmpty	: false
	};


	/**------------------------------------------------------
	 * Remove Empty Leading, Trailing Rows and All Empty Roes
	 */
	removeEmptyRows(excelData: IImportExcelData, options: Partial<IExcelImportOptions> | null): IImportExcelData {

		//0 - purify the options
		options = Util.Function.assignOptions(this.DEFAULT_IMPORT_OPTIONS, options!) as IExcelImportOptions;

		//1 - remove all empty lines (based on the set options)
		if (options.removeEmptyAllEmpty) excelData = this.removeAllEmptyRows(excelData);
		if (options.removeEmptyLeading)  excelData = this.removeEmptyLeadingRows(excelData);
		if (options.removeEmptyTrailing) excelData = this.removeEmptyTrailingRows(excelData);

		//2 - return the purified data
		return excelData;
	}

	private removeEmptyTrailingRows(excelData: IImportExcelData): IImportExcelData {

		//0 - filter out all leading empty rows
		for (const sheet of excelData.sheets) {

			//a. find the first valid index
			let leadingEmptyCount: number = 0;
			const sheetRows: IImportSheetRow[] = sheet.rows;

			//b. get sheet rows
			for (let i: number = sheetRows.length - 1; i >= 0; i--) {

				// is the row empty?
				const isRowEmpty: boolean = sheetRows[i].cellValues.every((value: IImportSheetCellValue) => Util.String.isEmpty(value.value));
				if (!isRowEmpty) break;

				// remove the empty value
				leadingEmptyCount++;
			}

			//c. remove the leading empty rows
			if (leadingEmptyCount > 0) sheet.rows.splice(-leadingEmptyCount);
		}

		//1 - return the filtered data
		return excelData;
	}

	private removeEmptyLeadingRows(excelData: IImportExcelData): IImportExcelData {

		//0 - filter out all leading empty rows
		for (const sheet of excelData.sheets) {

			//a. find the first valid index
			let leadingEmptyCount: number = 0;
			for (const row of sheet.rows) {

				// is the row empty?
				const isRowEmpty: boolean = row.cellValues.every((cellValue: IImportSheetCellValue) => Util.String.isEmpty(cellValue.value));
				if (!isRowEmpty) break;

				// remove the empty value
				leadingEmptyCount++;
			}

			//b. remove the leading empty rows
			sheet.rows.splice(0, leadingEmptyCount);
		}

		//1 - return the filtered data
		return excelData;
	}

	private removeAllEmptyRows(excelData: IImportExcelData): IImportExcelData {

		//0 - filter out all leading empty rows
		for (const sheet of excelData.sheets) {

			// find the first valid index
			const length: number = sheet.rows.length;
			for (let i: number = length - 1; i >= 0; --i) {

				//a. check if empty row
				const isRowEmpty: boolean = sheet.rows[i].cellValues.every((value: IImportSheetCellValue) => Util.String.isEmpty(value.value));

				//b. remove if empty
				if (isRowEmpty) sheet.rows.splice(i, 1);
			}
		}

		//1 - return the filtered data
		return excelData;
	}
}
