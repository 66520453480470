/**------------------------------------------------------
 * Spreadshirt Constants
 */
export enum EnumSpreadshirtSpreadshops {
	None		= 'none',
	All			= 'all',
	Specific	= 'specific'
}


/**------------------------------------------------------
 * Spreadshirt Template
 */
export interface ISpreadshirtTemplateOptions {
	productTemplate 	: string;
	marketplace			: {
		officialMarketplace	: boolean;
		spreadshops			: {
			type			: EnumSpreadshirtSpreadshops;
			specificShops	: string[];
		};
	};
	options 			: {
		autoTranslate	: boolean;
		optimizeImage	: boolean;				// Optimize Image Version by Spreadshirt
	};
}
