/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumBrowser {
	MicrosoftEdge 	= 'browser_microsoft_edge',
	Opera			= 'browser_opera',
	GoogleChrome	= 'browser_google_chrome',
	InternetExplorer = 'browser_internet_explorer',
	MozillaFirefox	= 'browser_mozilla_firefox',
	Safari			= 'browser_safari',
	Brave			= 'browser_brave',
	Other 			= 'browser_other'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IBrowserInfo {
	code	: EnumBrowser;
	name	: string;
	icon	: string;			// icons are of style "brands"
}
