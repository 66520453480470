import { Inject, Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { Observable, from } from 'rxjs';

import { EnumAddonPageAddonInjection } from '../../shared/page-addons.interface';


//** Browser Helper Variable */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const grecaptcha: IAddonGoogleRecaptcha;

@Injectable()
export class AddonGoogleReCaptchaService {

	constructor(
		@Inject(EnumAddonPageAddonInjection.GoogleRecaptchaKey)
		private recaptchaKey: string
	) {}

	getResponse(): Observable<unknown> {

		//0 - check if the RECAPTCHA_KEY was configured
		if (Util.String.isEmpty(this.recaptchaKey)) throw new Error(`AddonGoogleReCaptchaScript => getResponse => FATAL ERROR: "googleRecaptchaKey" was not defined (value is: "${this.recaptchaKey}")`);
		if (Util.Basic.isUndefined(grecaptcha)) 	throw new Error(`AddonGoogleReCaptchaScript => getResponse => FATAL ERROR: grecaptcha is undefined, are you sure it was added to the dom / page`);

		//1 - get the response
		return from(grecaptcha.execute(this.recaptchaKey, { action: 'submit' }));
	}
}


//** Interfaces --------------------------------- */
interface IAddonGoogleRecaptcha {
	execute: (recaptchaKey: string, options: { action: string }) => Observable<unknown>;
}
