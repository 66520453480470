import { EnumColorName, IColorDefinition } from './color-list.interface';


/**------------------------------------------------------
 * Color Definitions
 */
export const COLOR_LIST: Record<EnumColorName, IColorDefinition> = {
	[EnumColorName.Aliceblue]: {
		code 		: EnumColorName.Aliceblue,
		name		: 'Aliceblue',
		hexValue	: '#f0f8ff'
	},
	[EnumColorName.Antiquewhite]: {
		code 		: EnumColorName.Antiquewhite,
		name		: 'Antiquewhite',
		hexValue	: '#faebd7'
	},
	[EnumColorName.Aqua]: {
		code 		: EnumColorName.Aqua,
		name		: 'Aqua',
		hexValue	: '#00ffff'
	},
	[EnumColorName.Aquamarine]: {
		code 		: EnumColorName.Aquamarine,
		name		: 'Aquamarine',
		hexValue	: '#7fffd4'
	},
	[EnumColorName.Azure]: {
		code 		: EnumColorName.Azure,
		name		: 'Azure',
		hexValue	: '#f0ffff'
	},
	[EnumColorName.Beige]: {
		code 		: EnumColorName.Beige,
		name		: 'Beige',
		hexValue	: '#f5f5dc'
	},
	[EnumColorName.Bisque]: {
		code 		: EnumColorName.Bisque,
		name		: 'Bisque',
		hexValue	: '#ffe4c4'
	},
	[EnumColorName.Black]: {
		code 		: EnumColorName.Black,
		name		: 'Black',
		hexValue	: '#000000'
	},
	[EnumColorName.Blanchedalmond]: {
		code 		: EnumColorName.Blanchedalmond,
		name		: 'Blanchedalmond',
		hexValue	: '#ffebcd'
	},
	[EnumColorName.Blue]: {
		code 		: EnumColorName.Blue,
		name		: 'Blue',
		hexValue	: '#0000ff'
	},
	[EnumColorName.Blueviolet]: {
		code 		: EnumColorName.Blueviolet,
		name		: 'Blueviolet',
		hexValue	: '#8a2be2'
	},
	[EnumColorName.Brown]: {
		code 		: EnumColorName.Brown,
		name		: 'Brown',
		hexValue	: '#a52a2a'
	},
	[EnumColorName.Burlywood]: {
		code 		: EnumColorName.Burlywood,
		name		: 'Burlywood',
		hexValue	: '#deb887'
	},
	[EnumColorName.Cadetblue]: {
		code 		: EnumColorName.Cadetblue,
		name		: 'Cadetblue',
		hexValue	: '#5f9ea0'
	},
	[EnumColorName.Chartreuse]: {
		code 		: EnumColorName.Chartreuse,
		name		: 'Chartreuse',
		hexValue	: '#7fff00'
	},
	[EnumColorName.Chocolate]: {
		code 		: EnumColorName.Chocolate,
		name		: 'Chocolate',
		hexValue	: '#d2691e'
	},
	[EnumColorName.Coral]: {
		code 		: EnumColorName.Coral,
		name		: 'Coral',
		hexValue	: '#ff7f50'
	},
	[EnumColorName.Cornflowerblue]: {
		code 		: EnumColorName.Cornflowerblue,
		name		: 'Cornflowerblue',
		hexValue	: '#6495ed'
	},
	[EnumColorName.Cornsilk]: {
		code 		: EnumColorName.Cornsilk,
		name		: 'Cornsilk',
		hexValue	: '#fff8dc'
	},
	[EnumColorName.Crimson]: {
		code 		: EnumColorName.Crimson,
		name		: 'Crimson',
		hexValue	: '#dc143c'
	},
	[EnumColorName.Cyan]: {
		code 		: EnumColorName.Cyan,
		name		: 'Cyan',
		hexValue	: '#00ffff'
	},
	[EnumColorName.Darkblue]: {
		code 		: EnumColorName.Darkblue,
		name		: 'Darkblue',
		hexValue	: '#00008b'
	},
	[EnumColorName.Darkcyan]: {
		code 		: EnumColorName.Darkcyan,
		name		: 'Darkcyan',
		hexValue	: '#008b8b'
	},
	[EnumColorName.Darkgoldenrod]: {
		code 		: EnumColorName.Darkgoldenrod,
		name		: 'Darkgoldenrod',
		hexValue	: '#b8860b'
	},
	[EnumColorName.Darkgray]: {
		code 		: EnumColorName.Darkgray,
		name		: 'Darkgray',
		hexValue	: '#a9a9a9'
	},
	[EnumColorName.Darkgreen]: {
		code 		: EnumColorName.Darkgreen,
		name		: 'Darkgreen',
		hexValue	: '#006400'
	},
	[EnumColorName.Darkgrey]: {
		code 		: EnumColorName.Darkgrey,
		name		: 'Darkgrey',
		hexValue	: '#a9a9a9'
	},
	[EnumColorName.Darkkhaki]: {
		code 		: EnumColorName.Darkkhaki,
		name		: 'Darkkhaki',
		hexValue	: '#bdb76b'
	},
	[EnumColorName.Darkmagenta]: {
		code 		: EnumColorName.Darkmagenta,
		name		: 'Darkmagenta',
		hexValue	: '#8b008b'
	},
	[EnumColorName.Darkolivegreen]: {
		code 		: EnumColorName.Darkolivegreen,
		name		: 'Darkolivegreen',
		hexValue	: '#556b2f'
	},
	[EnumColorName.Darkorange]: {
		code 		: EnumColorName.Darkorange,
		name		: 'Darkorange',
		hexValue	: '#ff8c00'
	},
	[EnumColorName.Darkorchid]: {
		code 		: EnumColorName.Darkorchid,
		name		: 'Darkorchid',
		hexValue	: '#9932cc'
	},
	[EnumColorName.Darkred]: {
		code 		: EnumColorName.Darkred,
		name		: 'Darkred',
		hexValue	: '#8b0000'
	},
	[EnumColorName.Darksalmon]: {
		code 		: EnumColorName.Darksalmon,
		name		: 'Darksalmon',
		hexValue	: '#e9967a'
	},
	[EnumColorName.Darkseagreen]: {
		code 		: EnumColorName.Darkseagreen,
		name		: 'Darkseagreen',
		hexValue	: '#8fbc8f'
	},
	[EnumColorName.Darkslateblue]: {
		code 		: EnumColorName.Darkslateblue,
		name		: 'Darkslateblue',
		hexValue	: '#483d8b'
	},
	[EnumColorName.Darkslategray]: {
		code 		: EnumColorName.Darkslategray,
		name		: 'Darkslategray',
		hexValue	: '#2f4f4f'
	},
	[EnumColorName.Darkslategrey]: {
		code 		: EnumColorName.Darkslategrey,
		name		: 'Darkslategrey',
		hexValue	: '#2f4f4f'
	},
	[EnumColorName.Darkturquoise]: {
		code 		: EnumColorName.Darkturquoise,
		name		: 'Darkturquoise',
		hexValue	: '#00ced1'
	},
	[EnumColorName.Darkviolet]: {
		code 		: EnumColorName.Darkviolet,
		name		: 'Darkviolet',
		hexValue	: '#9400d3'
	},
	[EnumColorName.Deeppink]: {
		code 		: EnumColorName.Deeppink,
		name		: 'Deeppink',
		hexValue	: '#ff1493'
	},
	[EnumColorName.Deepskyblue]: {
		code 		: EnumColorName.Deepskyblue,
		name		: 'Deepskyblue',
		hexValue	: '#00bfff'
	},
	[EnumColorName.Dimgray]: {
		code 		: EnumColorName.Dimgray,
		name		: 'Dimgray',
		hexValue	: '#696969'
	},
	[EnumColorName.Dimgrey]: {
		code 		: EnumColorName.Dimgrey,
		name		: 'Dimgrey',
		hexValue	: '#696969'
	},
	[EnumColorName.Dodgerblue]: {
		code 		: EnumColorName.Dodgerblue,
		name		: 'Dodgerblue',
		hexValue	: '#1e90ff'
	},
	[EnumColorName.Firebrick]: {
		code 		: EnumColorName.Firebrick,
		name		: 'Firebrick',
		hexValue	: '#b22222'
	},
	[EnumColorName.Floralwhite]: {
		code 		: EnumColorName.Floralwhite,
		name		: 'Floralwhite',
		hexValue	: '#fffaf0'
	},
	[EnumColorName.Forestgreen]: {
		code 		: EnumColorName.Forestgreen,
		name		: 'Forestgreen',
		hexValue	: '#228b22'
	},
	[EnumColorName.Fuchsia]: {
		code 		: EnumColorName.Fuchsia,
		name		: 'Fuchsia',
		hexValue	: '#ff00ff'
	},
	[EnumColorName.Gainsboro]: {
		code 		: EnumColorName.Gainsboro,
		name		: 'Gainsboro',
		hexValue	: '#dcdcdc'
	},
	[EnumColorName.Ghostwhite]: {
		code 		: EnumColorName.Ghostwhite,
		name		: 'Ghostwhite',
		hexValue	: '#f8f8ff'
	},
	[EnumColorName.Gold]: {
		code 		: EnumColorName.Gold,
		name		: 'Gold',
		hexValue	: '#ffd700'
	},
	[EnumColorName.Goldenrod]: {
		code 		: EnumColorName.Goldenrod,
		name		: 'Goldenrod',
		hexValue	: '#daa520'
	},
	[EnumColorName.Gray]: {
		code 		: EnumColorName.Gray,
		name		: 'Gray',
		hexValue	: '#808080'
	},
	[EnumColorName.Green]: {
		code 		: EnumColorName.Green,
		name		: 'Green',
		hexValue	: '#008000'
	},
	[EnumColorName.Greenyellow]: {
		code 		: EnumColorName.Greenyellow,
		name		: 'Greenyellow',
		hexValue	: '#adff2f'
	},
	[EnumColorName.Grey]: {
		code 		: EnumColorName.Grey,
		name		: 'Grey',
		hexValue	: '#808080'
	},
	[EnumColorName.Honeydew]: {
		code 		: EnumColorName.Honeydew,
		name		: 'Honeydew',
		hexValue	: '#f0fff0'
	},
	[EnumColorName.Hotpink]: {
		code 		: EnumColorName.Hotpink,
		name		: 'Hotpink',
		hexValue	: '#ff69b4'
	},
	[EnumColorName.Indianred]: {
		code 		: EnumColorName.Indianred,
		name		: 'Indianred',
		hexValue	: '#cd5c5c'
	},
	[EnumColorName.Indigo]: {
		code 		: EnumColorName.Indigo,
		name		: 'Indigo',
		hexValue	: '#4b0082'
	},
	[EnumColorName.Ivory]: {
		code 		: EnumColorName.Ivory,
		name		: 'Ivory',
		hexValue	: '#fffff0'
	},
	[EnumColorName.Khaki]: {
		code 		: EnumColorName.Khaki,
		name		: 'Khaki',
		hexValue	: '#f0e68c'
	},
	[EnumColorName.Lavender]: {
		code 		: EnumColorName.Lavender,
		name		: 'Lavender',
		hexValue	: '#e6e6fa'
	},
	[EnumColorName.Lavenderblush]: {
		code 		: EnumColorName.Lavenderblush,
		name		: 'Lavenderblush',
		hexValue	: '#fff0f5'
	},
	[EnumColorName.Lawngreen]: {
		code 		: EnumColorName.Lawngreen,
		name		: 'Lawngreen',
		hexValue	: '#7cfc00'
	},
	[EnumColorName.Lemonchiffon]: {
		code 		: EnumColorName.Lemonchiffon,
		name		: 'Lemonchiffon',
		hexValue	: '#fffacd'
	},
	[EnumColorName.Lightblue]: {
		code 		: EnumColorName.Lightblue,
		name		: 'Lightblue',
		hexValue	: '#add8e6'
	},
	[EnumColorName.Lightcoral]: {
		code 		: EnumColorName.Lightcoral,
		name		: 'Lightcoral',
		hexValue	: '#f08080'
	},
	[EnumColorName.Lightcyan]: {
		code 		: EnumColorName.Lightcyan,
		name		: 'Lightcyan',
		hexValue	: '#e0ffff'
	},
	[EnumColorName.Lightgoldenrodyellow]: {
		code 		: EnumColorName.Lightgoldenrodyellow,
		name		: 'Lightgoldenrodyellow',
		hexValue	: '#fafad2'
	},
	[EnumColorName.Lightgray]: {
		code 		: EnumColorName.Lightgray,
		name		: 'Lightgray',
		hexValue	: '#d3d3d3'
	},
	[EnumColorName.Lightgreen]: {
		code 		: EnumColorName.Lightgreen,
		name		: 'Lightgreen',
		hexValue	: '#90ee90'
	},
	[EnumColorName.Lightgrey]: {
		code 		: EnumColorName.Lightgrey,
		name		: 'Lightgrey',
		hexValue	: '#d3d3d3'
	},
	[EnumColorName.Lightpink]: {
		code 		: EnumColorName.Lightpink,
		name		: 'Lightpink',
		hexValue	: '#ffb6c1'
	},
	[EnumColorName.Lightsalmon]: {
		code 		: EnumColorName.Lightsalmon,
		name		: 'Lightsalmon',
		hexValue	: '#ffa07a'
	},
	[EnumColorName.Lightseagreen]: {
		code 		: EnumColorName.Lightseagreen,
		name		: 'Lightseagreen',
		hexValue	: '#20b2aa'
	},
	[EnumColorName.Lightskyblue]: {
		code 		: EnumColorName.Lightskyblue,
		name		: 'Lightskyblue',
		hexValue	: '#87cefa'
	},
	[EnumColorName.Lightslategray]: {
		code 		: EnumColorName.Lightslategray,
		name		: 'Lightslategray',
		hexValue	: '#778899'
	},
	[EnumColorName.Lightslategrey]: {
		code 		: EnumColorName.Lightslategrey,
		name		: 'Lightslategrey',
		hexValue	: '#778899'
	},
	[EnumColorName.Lightsteelblue]: {
		code 		: EnumColorName.Lightsteelblue,
		name		: 'Lightsteelblue',
		hexValue	: '#b0c4de'
	},
	[EnumColorName.Lightyellow]: {
		code 		: EnumColorName.Lightyellow,
		name		: 'Lightyellow',
		hexValue	: '#ffffe0'
	},
	[EnumColorName.Lime]: {
		code 		: EnumColorName.Lime,
		name		: 'Lime',
		hexValue	: '#00ff00'
	},
	[EnumColorName.Limegreen]: {
		code 		: EnumColorName.Limegreen,
		name		: 'Limegreen',
		hexValue	: '#32cd32'
	},
	[EnumColorName.Linen]: {
		code 		: EnumColorName.Linen,
		name		: 'Linen',
		hexValue	: '#faf0e6'
	},
	[EnumColorName.Magenta]: {
		code 		: EnumColorName.Magenta,
		name		: 'Magenta',
		hexValue	: '#ff00ff'
	},
	[EnumColorName.Maroon]: {
		code 		: EnumColorName.Maroon,
		name		: 'Maroon',
		hexValue	: '#800000'
	},
	[EnumColorName.Mediumaquamarine]: {
		code 		: EnumColorName.Mediumaquamarine,
		name		: 'Mediumaquamarine',
		hexValue	: '#66cdaa'
	},
	[EnumColorName.Mediumblue]: {
		code 		: EnumColorName.Mediumblue,
		name		: 'Mediumblue',
		hexValue	: '#0000cd'
	},
	[EnumColorName.Mediumorchid]: {
		code 		: EnumColorName.Mediumorchid,
		name		: 'Mediumorchid',
		hexValue	: '#ba55d3'
	},
	[EnumColorName.Mediumpurple]: {
		code 		: EnumColorName.Mediumpurple,
		name		: 'Mediumpurple',
		hexValue	: '#9370db'
	},
	[EnumColorName.Mediumseagreen]: {
		code 		: EnumColorName.Mediumseagreen,
		name		: 'Mediumseagreen',
		hexValue	: '#3cb371'
	},
	[EnumColorName.Mediumslateblue]: {
		code 		: EnumColorName.Mediumslateblue,
		name		: 'Mediumslateblue',
		hexValue	: '#7b68ee'
	},
	[EnumColorName.Mediumspringgreen]: {
		code 		: EnumColorName.Mediumspringgreen,
		name		: 'Mediumspringgreen',
		hexValue	: '#00fa9a'
	},
	[EnumColorName.Mediumturquoise]: {
		code 		: EnumColorName.Mediumturquoise,
		name		: 'Mediumturquoise',
		hexValue	: '#48d1cc'
	},
	[EnumColorName.Mediumvioletred]: {
		code 		: EnumColorName.Mediumvioletred,
		name		: 'Mediumvioletred',
		hexValue	: '#c71585'
	},
	[EnumColorName.Midnightblue]: {
		code 		: EnumColorName.Midnightblue,
		name		: 'Midnightblue',
		hexValue	: '#191970'
	},
	[EnumColorName.Mintcream]: {
		code 		: EnumColorName.Mintcream,
		name		: 'Mintcream',
		hexValue	: '#f5fffa'
	},
	[EnumColorName.Mistyrose]: {
		code 		: EnumColorName.Mistyrose,
		name		: 'Mistyrose',
		hexValue	: '#ffe4e1'
	},
	[EnumColorName.Moccasin]: {
		code 		: EnumColorName.Moccasin,
		name		: 'Moccasin',
		hexValue	: '#ffe4b5'
	},
	[EnumColorName.Navajowhite]: {
		code 		: EnumColorName.Navajowhite,
		name		: 'Navajowhite',
		hexValue	: '#ffdead'
	},
	[EnumColorName.Navy]: {
		code 		: EnumColorName.Navy,
		name		: 'Navy',
		hexValue	: '#000080'
	},
	[EnumColorName.Oldlace]: {
		code 		: EnumColorName.Oldlace,
		name		: 'Oldlace',
		hexValue	: '#fdf5e6'
	},
	[EnumColorName.Olive]: {
		code 		: EnumColorName.Olive,
		name		: 'Olive',
		hexValue	: '#808000'
	},
	[EnumColorName.Olivedrab]: {
		code 		: EnumColorName.Olivedrab,
		name		: 'Olivedrab',
		hexValue	: '#6b8e23'
	},
	[EnumColorName.Orange]: {
		code 		: EnumColorName.Orange,
		name		: 'Orange',
		hexValue	: '#ffa500'
	},
	[EnumColorName.Orangered]: {
		code 		: EnumColorName.Orangered,
		name		: 'Orangered',
		hexValue	: '#ff4500'
	},
	[EnumColorName.Orchid]: {
		code 		: EnumColorName.Orchid,
		name		: 'Orchid',
		hexValue	: '#da70d6'
	},
	[EnumColorName.Palegoldenrod]: {
		code 		: EnumColorName.Palegoldenrod,
		name		: 'Palegoldenrod',
		hexValue	: '#eee8aa'
	},
	[EnumColorName.Palegreen]: {
		code 		: EnumColorName.Palegreen,
		name		: 'Palegreen',
		hexValue	: '#98fb98'
	},
	[EnumColorName.Paleturquoise]: {
		code 		: EnumColorName.Paleturquoise,
		name		: 'Paleturquoise',
		hexValue	: '#afeeee'
	},
	[EnumColorName.Palevioletred]: {
		code 		: EnumColorName.Palevioletred,
		name		: 'Palevioletred',
		hexValue	: '#db7093'
	},
	[EnumColorName.Papayawhip]: {
		code 		: EnumColorName.Papayawhip,
		name		: 'Papayawhip',
		hexValue	: '#ffefd5'
	},
	[EnumColorName.Peachpuff]: {
		code 		: EnumColorName.Peachpuff,
		name		: 'Peachpuff',
		hexValue	: '#ffdab9'
	},
	[EnumColorName.Peru]: {
		code 		: EnumColorName.Peru,
		name		: 'Peru',
		hexValue	: '#cd853f'
	},
	[EnumColorName.Pink]: {
		code 		: EnumColorName.Pink,
		name		: 'Pink',
		hexValue	: '#ffc0cb'
	},
	[EnumColorName.Plum]: {
		code 		: EnumColorName.Plum,
		name		: 'Plum',
		hexValue	: '#dda0dd'
	},
	[EnumColorName.Powderblue]: {
		code 		: EnumColorName.Powderblue,
		name		: 'Powderblue',
		hexValue	: '#b0e0e6'
	},
	[EnumColorName.Purple]: {
		code 		: EnumColorName.Purple,
		name		: 'Purple',
		hexValue	: '#800080'
	},
	[EnumColorName.Red]: {
		code 		: EnumColorName.Red,
		name		: 'Red',
		hexValue	: '#ff0000'
	},
	[EnumColorName.Rosybrown]: {
		code 		: EnumColorName.Rosybrown,
		name		: 'Rosybrown',
		hexValue	: '#bc8f8f'
	},
	[EnumColorName.Royalblue]: {
		code 		: EnumColorName.Royalblue,
		name		: 'Royalblue',
		hexValue	: '#4169e1'
	},
	[EnumColorName.Saddlebrown]: {
		code 		: EnumColorName.Saddlebrown,
		name		: 'Saddlebrown',
		hexValue	: '#8b4513'
	},
	[EnumColorName.Salmon]: {
		code 		: EnumColorName.Salmon,
		name		: 'Salmon',
		hexValue	: '#fa8072'
	},
	[EnumColorName.Sandybrown]: {
		code 		: EnumColorName.Sandybrown,
		name		: 'Sandybrown',
		hexValue	: '#f4a460'
	},
	[EnumColorName.Seagreen]: {
		code 		: EnumColorName.Seagreen,
		name		: 'Seagreen',
		hexValue	: '#2e8b57'
	},
	[EnumColorName.Seashell]: {
		code 		: EnumColorName.Seashell,
		name		: 'Seashell',
		hexValue	: '#fff5ee'
	},
	[EnumColorName.Sienna]: {
		code 		: EnumColorName.Sienna,
		name		: 'Sienna',
		hexValue	: '#a0522d'
	},
	[EnumColorName.Silver]: {
		code 		: EnumColorName.Silver,
		name		: 'Silver',
		hexValue	: '#c0c0c0'
	},
	[EnumColorName.Skyblue]: {
		code 		: EnumColorName.Skyblue,
		name		: 'Skyblue',
		hexValue	: '#87ceeb'
	},
	[EnumColorName.Slateblue]: {
		code 		: EnumColorName.Slateblue,
		name		: 'Slateblue',
		hexValue	: '#6a5acd'
	},
	[EnumColorName.Slategray]: {
		code 		: EnumColorName.Slategray,
		name		: 'Slategray',
		hexValue	: '#708090'
	},
	[EnumColorName.Snow]: {
		code 		: EnumColorName.Snow,
		name		: 'Snow',
		hexValue	: '#fffafa'
	},
	[EnumColorName.Springgreen]: {
		code 		: EnumColorName.Springgreen,
		name		: 'Springgreen',
		hexValue	: '#00ff7f'
	},
	[EnumColorName.Steelblue]: {
		code 		: EnumColorName.Steelblue,
		name		: 'Steelblue',
		hexValue	: '#4682b4'
	},
	[EnumColorName.Tan]: {
		code 		: EnumColorName.Tan,
		name		: 'Tan',
		hexValue	: '#d2b48c'
	},
	[EnumColorName.Teal]: {
		code 		: EnumColorName.Teal,
		name		: 'Teal',
		hexValue	: '#008080'
	},
	[EnumColorName.Thistle]: {
		code 		: EnumColorName.Thistle,
		name		: 'Thistle',
		hexValue	: '#d8bfd8'
	},
	[EnumColorName.Tomato]: {
		code 		: EnumColorName.Tomato,
		name		: 'Tomato',
		hexValue	: '#ff6347'
	},
	[EnumColorName.Turquoise]: {
		code 		: EnumColorName.Turquoise,
		name		: 'Turquoise',
		hexValue	: '#40e0d0'
	},
	[EnumColorName.Violet]: {
		code 		: EnumColorName.Violet,
		name		: 'Violet',
		hexValue	: '#ee82ee'
	},
	[EnumColorName.Wheat]: {
		code 		: EnumColorName.Wheat,
		name		: 'Wheat',
		hexValue	: '#f5deb3'
	},
	[EnumColorName.White]: {
		code 		: EnumColorName.White,
		name		: 'White',
		hexValue	: '#ffffff'
	},
	[EnumColorName.Whitesmoke]: {
		code 		: EnumColorName.Whitesmoke,
		name		: 'Whitesmoke',
		hexValue	: '#f5f5f5'
	},
	[EnumColorName.Yellow]: {
		code 		: EnumColorName.Yellow,
		name		: 'Yellow',
		hexValue	: '#ffff00'
	},
	[EnumColorName.Yellowgreen]: {
		code 		: EnumColorName.Yellowgreen,
		name		: 'Yellowgreen',
		hexValue	: '#9acd32'
	}
};
