import { SearchLinksHelper } from './data/search-links.helper';
import { SearchLinks } from './search-links';
import { WikipediaSearch } from './searches/lookup/wikipedia-search.helper';
import { GoogleImagesSearch } from './searches/lookup/google-images-search.helper';
import { GoogleSearch } from './searches/lookup/google-search.helper';
import { GoogleTrendsSearch } from './searches/lookup/google-trends-search/google-trends-search.helper';
import { InstagramSearch } from './searches/social-media/instagram/instagram-search.helper';
import { PinterestSearch } from './searches/social-media/pinterest/pinterest-search.helper';
import { TwitterSearch } from './searches/social-media/twitter/twitter-search.helper';
import { Social9GagSearch } from './searches/social-media/9gag/social-9gag-search.helper';
import { FreepikSearch } from './searches/stock-images/freepik/freepik-search.helper';
import { PexelsSearch } from './searches/stock-images/pexels/pexels-search.helper';
import { PixabaySearch } from './searches/stock-images/pixabay/pixabay-search.helper';
import { VexelsSearch } from './searches/stock-images/vexels/vexels-search.helper';
import { ShutterStockSearch } from './searches/stock-images/shutterstock/shutterstock-search.helper';
import { SpreadshirtSearch } from './searches/print-on-demand/spreadshirt/spreadshirt-search.helper';
import { SpreadshirtComSearch } from './searches/print-on-demand/spreadshirt/marketplaces/spreadshirt-com.helper';
import { SpreadshirtDeSearch } from './searches/print-on-demand/spreadshirt/marketplaces/spreadshirt-de.helper';
import { SpreadshirtEsSearch } from './searches/print-on-demand/spreadshirt/marketplaces/spreadshirt-es.helper';
import { SpreadshirtFrSearch } from './searches/print-on-demand/spreadshirt/marketplaces/spreadshirt-fr.helper';
import { SpreadshirtItSearch } from './searches/print-on-demand/spreadshirt/marketplaces/spreadshirt-it.helper';
import { SpreadshirtUkSearch } from './searches/print-on-demand/spreadshirt/marketplaces/spreadshirt-uk.helper';
import { Society6Search } from './searches/print-on-demand/society6/society6-search.helper';
import { ZazzleSearch } from './searches/print-on-demand/zazzle/zazzle-search.helper';
import { DisplateSearch } from './searches/print-on-demand/displate/displate-search.helper';
import { RedbubbleSearch } from './searches/print-on-demand/redbubble/redbubble-search.helper';
import { TeepublicSearch } from './searches/print-on-demand/teepublic/teepublic-search.helper';
import { AmazonComSearch } from './searches/stores/amazon/marketplaces/amazon-com.helper';
import { AmazonUkSearch } from './searches/stores/amazon/marketplaces/amazon-uk.helper';
import { AmazonDeSearch } from './searches/stores/amazon/marketplaces/amazon-de.helper';
import { AmazonFrSearch } from './searches/stores/amazon/marketplaces/amazon-fr.helper';
import { AmazonEsSearch } from './searches/stores/amazon/marketplaces/amazon-es.helper';
import { AmazonItSearch } from './searches/stores/amazon/marketplaces/amazon-it.helper';
import { AmazonJpSearch } from './searches/stores/amazon/marketplaces/amazon-jp.helper';
import { AmazonNlSearch } from './searches/stores/amazon/marketplaces/amazon-nl.helper';
import { AmazonPlSearch } from './searches/stores/amazon/marketplaces/amazon-pl.helper';
import { AmazonSeSearch } from './searches/stores/amazon/marketplaces/amazon-se.helper';
import { AmazonCaSearch } from './searches/stores/amazon/marketplaces/amazon-ca.helper';
import { AmazonAuSearch } from './searches/stores/amazon/marketplaces/amazon-au.helper';
import { AmazonMxSearch } from './searches/stores/amazon/marketplaces/amazon-mx.helper';
import { AmazonSearch } from './searches/stores/amazon/amazon-search.helper';
import { EtsySearch } from './searches/stores/etsy/etsy-search.helper';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class SearchLinksFactory {

	static create(): SearchLinks {

		//0 - create the dependency
		const searchLinksHelper: SearchLinksHelper = new SearchLinksHelper();

		return new SearchLinks(

			// Lookup
			new GoogleSearch(),
			new GoogleImagesSearch(),
			new GoogleTrendsSearch(),
			new WikipediaSearch(),

			// Print On Demand Platforms
			new DisplateSearch(),
			new RedbubbleSearch(),
			new Society6Search(),
			new TeepublicSearch(),
			new ZazzleSearch(),
			new SpreadshirtSearch(searchLinksHelper, [
				new SpreadshirtComSearch(),
				new SpreadshirtUkSearch(),
				new SpreadshirtDeSearch(),
				new SpreadshirtFrSearch(),
				new SpreadshirtEsSearch(),
				new SpreadshirtItSearch()
			]),

			// Social Media
			new InstagramSearch(),
			new PinterestSearch(),
			new TwitterSearch(),
			new Social9GagSearch(),

			// Stock Images
			new FreepikSearch(),
			new PexelsSearch(),
			new PixabaySearch(),
			new ShutterStockSearch(),
			new VexelsSearch(),

			// Stores
			new EtsySearch(),
			new AmazonSearch(searchLinksHelper, [
				new AmazonComSearch(),
				new AmazonUkSearch(),
				new AmazonDeSearch(),
				new AmazonFrSearch(),
				new AmazonEsSearch(),
				new AmazonItSearch(),
				new AmazonJpSearch(),
				new AmazonNlSearch(),
				new AmazonPlSearch(),
				new AmazonSeSearch(),
				new AmazonCaSearch(),
				new AmazonAuSearch(),
				new AmazonMxSearch()
			])
		);
	}
}
