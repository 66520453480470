import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchLinkUrlConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Pexels Search Url
 */
export class PexelsSearch implements ISearchLinks {

	//** Configurations */
	private readonly PEXELS_CONFIG: ISearchLinkUrlConfig = {
		home		: 'https://www.pexels.com',
		login		: 'https://www.pexels.com/login/',
		search		: 'https://www.pexels.com/search/[SEARCH]'
	};


	//** Home & Login */
	home() : string { return this.PEXELS_CONFIG.home; }
	login(): string { return this.PEXELS_CONFIG.login; }

	//** Search */
	search(searchQuery: string): string {

		//0 - if no search string existing then return main or home page
		if (Util.String.isEmpty(searchQuery)) return this.home();

		//1 - else return the url with search query
		return this.PEXELS_CONFIG.search.replace('[SEARCH]', searchQuery);
	}
}
