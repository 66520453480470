import { TypeResolve } from '@libs/constants';


/**------------------------------------------------------
 * Delayed Executor
 * ----------------
 * > If waiting is required till a certain condition
 * > is fulfilled, before executing code this "behavior"
 * > can be used.
 * > Wait with "await ...waitForRelease()" till the event
 * > happens that we are waiting for. To when the condition
 * > is fulfilled resolve the wait with the "...release()"
 * > function (after the waitForRelease() will stop waiting)
 */
export class DelayedExecutor {

	//** Helper Variables */
	private resolve: () => void 	= null!;
	private promise: Promise<void> 	= new Promise((resolve: TypeResolve) => {
		this.resolve = resolve;
	});

	static create(): DelayedExecutor {
		return new DelayedExecutor();
	}


	release(): void {
		this.resolve();
	}

	async waitForRelease(): Promise<void> {
		await this.promise;
	}
}
