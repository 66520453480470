import { EnumTmNiceClass, EnumTmOffice, EnumTmStatus, EnumTmType, ITmTrademarkEntryOptimized } from '@domains/trademark/shared';
import { EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_LIST_CHECK_LIMITS: IFlyingListCheckConfig = {
	INFO_LENGTH		: 120,
	TEXT_LENGTH		: 2000,
	MAX_LIST_SIZE	: 10000
};

interface IFlyingListCheckConfig {
	INFO_LENGTH		: number;
	TEXT_LENGTH		: number;
	MAX_LIST_SIZE	: number;
}


/**------------------------------------------------------
 * Listing Check Request
 */
export interface IFlyingTmListingCheckRequest {
	listings	: IFlyingTmListingCheckEntry[];
}

export interface IFlyingTmListingCheckEntry {
	info		: string;
	language	: EnumLanguage;
	text		: string;
	office		: EnumTmOffice[];
	niceClass	: EnumTmNiceClass[];
	status		: EnumTmStatus[];
	type		: EnumTmType[];
}


/**------------------------------------------------------
 * Listing Check Response
 */
export interface IFlyingTmListingCheckResponse {
	entries		: IFlyingTmListingCheckTrademarks[];
	totalCount	: number;
}

export interface IFlyingTmListingCheckTrademarks {
	info    	: string;
	trademarks	: ITmTrademarkEntryOptimized[];
	office		: EnumTmOffice[];
	niceClass	: EnumTmNiceClass[];
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingTmListingCheckRequestSchema implements ValidatorSchema<IFlyingTmListingCheckRequest> {
	listings	= Validator.Array().isNotEmpty().isMaxSize(FLYING_LIST_CHECK_LIMITS.MAX_LIST_SIZE)
					.inspectEach<IFlyingTmListingCheckEntry>(FlyingTmListingCheckEntrySchema);
}

class FlyingTmListingCheckEntrySchema implements ValidatorSchema<IFlyingTmListingCheckEntry> {
	info		= Validator.String().isNotEmpty().isMaxLength(FLYING_LIST_CHECK_LIMITS.INFO_LENGTH).hasNoHtmlScripts();
	language	= Validator.Enum(EnumLanguage);
	text		= Validator.String().isNotEmpty().isMaxLength(FLYING_LIST_CHECK_LIMITS.TEXT_LENGTH).hasNoHtmlScripts();
	office		= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmOffice));
	niceClass	= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmNiceClass));
	status		= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmStatus));
	type		= Validator.Array().isNotEmpty().each(Validator.Enum(EnumTmType));
}
