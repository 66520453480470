import { UtilBasic } from '../util-basic';
import { UtilFunction } from '../util-function';
import { UtilString } from '../util-string';


/**------------------------------------------------------
 * Text Search Filter
 * ------------------
 * > This algorithm represents a configurable text search
 * > which is used over the different projects to search
 * > for items, ...
 */
export class UtilTextSearchFilter {

	//** Configurations */
	private readonly DEFAULT_TEXT_SEARCH_OPTIONS: IUtilTextSearchOptions = {
		searchType		: EnumUtilSearchMatch.AllWord,
		caseSensitive	: false,
		sortByRelevance	: false
	};


	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString,
		private utilFunction: UtilFunction,
		private utilText	: IUtilText
	) {}


	/**------------------------------------------------------
	 * Smart Text Search
	 * > Check if all words of search are contained in text
	 */
	filterTextsBySearch<T>(search: string, textObjects: T[], getTextFn: TypeUtilGetTextFn<T> | null, options?: Partial<IUtilTextSearchOptions>): T[] {

		//0 - check and get text function
		const textFn	   : TypeUtilGetTextFn<T>   = this.utilBasic.fallbackValue(getTextFn, (value: T) => this.utilString.toString(value));
		const searchOptions: IUtilTextSearchOptions = this.utilFunction.assignOptions(this.DEFAULT_TEXT_SEARCH_OPTIONS, options);

		//1 - filter out the text which is not matching the search
		const sortedTextObjects: T[] = textObjects.filter((elem: T) => this.isTextMatch(search, textFn(elem), searchOptions));

		//2 - sort the text by relevance if required
		if (searchOptions.sortByRelevance) this.utilText.sortByRelevance(search, sortedTextObjects, textFn);

		//3 - return the remaining matching results
		return sortedTextObjects;
	}


	/**------------------------------------------------------
	 * Text Match
	 */
	isTextMatch(search: string, text: string, options?: Partial<IUtilTextMatchOptions>): boolean {

		//0 - define the options
		this.utilString.checkStrings([text, search]);
		const matchOptions: IUtilTextMatchOptions = this.utilFunction.assignOptions(this.DEFAULT_TEXT_SEARCH_OPTIONS, options);

		//1 - get the search words
		const searchWords: string[] = this.utilText.wordSplit(search)
			.filter((word: string) => !this.utilString.isEmpty(word))
			.map((word: string) => word.trim());

		//2 - is the text matching to the search text?
		const caseSensitive: boolean = matchOptions.caseSensitive;
		switch (matchOptions.searchType) {
			case EnumUtilSearchMatch.AnyWord:
				return this.utilText.includesAnyWord(text, searchWords, { caseSensitive, exactWordMatch: false });

			case EnumUtilSearchMatch.AllWord:
				return this.utilText.includesAllWord(text, searchWords, { caseSensitive, exactWordMatch: false });

			case EnumUtilSearchMatch.IncludesSearch:
				return this.utilText.includesText(text, search, caseSensitive);

			case EnumUtilSearchMatch.StartWith:
				return this.utilText.startWith(text, search, caseSensitive);

			case EnumUtilSearchMatch.EndWith:
				return this.utilText.endWith(text, search, caseSensitive);

			default:
				throw new Error(`TextSearch => isTextMatching => FATAL ERROR: the searchType of "${matchOptions.searchType}" was not defined`);
		}
	}
}


//** Enums -------------------------------------- */
export enum EnumUtilSearchMatch {
	AnyWord 		= 'any_word',
	AllWord 		= 'all_word',
	IncludesSearch	= 'includes_search',
	StartWith 		= 'start_with',
	EndWith 		= 'end_with'
}


//** Interfaces --------------------------------- */
export interface IUtilTextSearchOptions {
	searchType		: EnumUtilSearchMatch;
	caseSensitive	: boolean;
	sortByRelevance	: boolean;
}

export interface IUtilTextMatchOptions {
	searchType		: EnumUtilSearchMatch;
	caseSensitive	: boolean;
}

interface IUtilText {
	sortByRelevance	: <T>(search: string, textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>) => T[];
	wordSplit		: (text: string) => string[];
	includesAnyWord	: (text: string, searchWords: string[], options?: Partial<object>) => boolean;
	includesAllWord	: (text: string, searchWords: string[], options?: Partial<object>) => boolean;
	includesText	: (text: string, searchText: string, caseSensitive: boolean) => boolean;
	startWith		: (text: string, search: string, caseSensitive: boolean) => boolean;
	endWith			: (text: string, search: string, caseSensitive: boolean) => boolean;
}


//** Types -------------------------------------- */
type TypeUtilGetTextFn<T> = (object: T) => string;
