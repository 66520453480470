/* cspell:ignore aripo, oapi, åland, eustatius, eustatius, faroes, cunha, maarten, cabo, côte, ivoire, eswatini, leste, türkiye, bouvet, tokelau, futuna, barthélemy, caicos */


/**------------------------------------------------------
 * Territory Offices
 */
export enum EnumTmTerritory {

	WO		= 'wo',
	EUR		= 'eur',
	EU		= 'eu',
	AFR		= 'afr',
	NEU		= 'neu',
	AMC		= 'amc',
	AUS		= 'aus',
	ASI		= 'asi',

	AP		= 'ap',
	AF		= 'af',
	AX		= 'ax',
	AL		= 'al',
	DZ		= 'dz',
	AS		= 'as',

	AD		= 'ad',
	AO		= 'ao',
	AI		= 'ai',
	AQ		= 'aq',
	AG		= 'ag',
	AR		= 'ar',

	AM		= 'am',
	AW		= 'aw',
	AU		= 'au',
	AT		= 'at',
	AZ		= 'az',
	BS		= 'bs',

	BH		= 'bh',
	BD		= 'bd',
	BB		= 'bb',
	BY		= 'by',
	BE		= 'be',
	BZ		= 'bz',

	BX		= 'bx',
	BJ		= 'bj',
	BM		= 'bm',
	BT		= 'bt',
	BO		= 'bo',
	BQ		= 'bq',

	BA		= 'ba',
	BW		= 'bw',
	BV		= 'bv',
	BR		= 'br',
	IO		= 'io',
	BN		= 'bn',

	BG		= 'bg',
	BF		= 'bf',
	BI		= 'bi',
	KH		= 'kh',
	CM		= 'cm',
	CA		= 'ca',

	CV		= 'cv',
	KY		= 'ky',
	CF		= 'cf',
	TD		= 'td',
	CL		= 'cl',
	CN		= 'cn',

	CX		= 'cx',
	CC		= 'cc',
	CO		= 'co',
	KM		= 'km',
	CG		= 'cg',
	CK		= 'ck',

	CR		= 'cr',
	HR		= 'hr',
	CU		= 'cu',
	CW		= 'cw',
	CY		= 'cy',
	CZ		= 'cz',

	CI		= 'ci',
	CD		= 'cd',
	DK		= 'dk',
	DJ		= 'dj',
	DM		= 'dm',
	DO		= 'do',

	EM		= 'em',
	EC		= 'ec',
	EG		= 'eg',
	SV		= 'sv',
	GQ		= 'gq',
	ER		= 'er',

	EE		= 'ee',
	ET		= 'et',
	FK		= 'fk',
	FO		= 'fo',
	FJ		= 'fj',
	FI		= 'fi',

	FR		= 'fr',
	GF		= 'gf',
	PF		= 'pf',
	TF		= 'tf',
	GA		= 'ga',
	GM		= 'gm',

	GE		= 'ge',
	DE		= 'de',
	GH		= 'gh',
	GI		= 'gi',
	GR		= 'gr',
	GL		= 'gl',

	GD		= 'gd',
	GP		= 'gp',
	GU		= 'gu',
	GT		= 'gt',
	GG		= 'gg',
	GN		= 'gn',

	GW		= 'gw',
	GY		= 'gy',
	HT		= 'ht',
	HM		= 'hm',
	HN		= 'hn',
	HK		= 'hk',

	HU		= 'hu',
	IS		= 'is',
	IN		= 'in',
	ID		= 'id',
	IR		= 'ir',
	IQ		= 'iq',

	IE		= 'ie',
	IM		= 'im',
	IL		= 'il',
	IT		= 'it',
	JM		= 'jm',
	JP		= 'jp',

	JE		= 'je',
	JO		= 'jo',
	KZ		= 'kz',
	KE		= 'ke',
	KI		= 'ki',
	KP		= 'kp',

	KW		= 'kw',
	KG		= 'kg',
	LA		= 'la',
	LV		= 'lv',
	LB		= 'lb',
	LS		= 'ls',

	LR		= 'lr',
	LY		= 'ly',
	LI		= 'li',
	LT		= 'lt',
	LU		= 'lu',
	MO		= 'mo',

	MG		= 'mg',
	MW		= 'mw',
	MY		= 'my',
	MV		= 'mv',
	ML		= 'ml',
	MT		= 'mt',

	MH		= 'mh',
	MQ		= 'mq',
	MR		= 'mr',
	MU		= 'mu',
	YT		= 'yt',
	MX		= 'mx',

	FM		= 'fm',
	MD		= 'md',
	MC		= 'mc',
	MN		= 'mn',
	ME		= 'me',
	MS		= 'ms',

	MA		= 'ma',
	MZ		= 'mz',
	MM		= 'mm',
	NA		= 'na',
	NR		= 'nr',
	NP		= 'np',

	NL		= 'nl',
	NC		= 'nc',
	NZ		= 'nz',
	NI		= 'ni',
	NE		= 'ne',
	NG		= 'ng',

	NU		= 'nu',
	NF		= 'nf',
	MK		= 'mk',
	MP		= 'mp',
	NO		= 'no',
	OM		= 'om',

	OA		= 'oa',
	PK		= 'pk',
	PW		= 'pw',
	PS		= 'ps',
	PA		= 'pa',
	PG		= 'pg',

	PY		= 'py',
	PE		= 'pe',
	PH		= 'ph',
	PN		= 'pn',
	PL		= 'pl',
	PT		= 'pt',

	QA		= 'qa',
	KR		= 'kr',
	RE		= 're',
	RO		= 'ro',
	RU		= 'ru',
	RW		= 'rw',

	BL		= 'bl',
	SH		= 'sh',
	KN		= 'kn',
	LC		= 'lc',
	MF		= 'mf',
	PM		= 'pm',

	VC		= 'vc',
	WS		= 'ws',
	SM		= 'sm',
	ST		= 'st',
	SA		= 'sa',
	SN		= 'sn',

	RS		= 'rs',
	SC		= 'sc',
	SL		= 'sl',
	SG		= 'sg',
	SX		= 'sx',
	SK		= 'sk',

	SI		= 'si',
	SB		= 'sb',
	SO		= 'so',
	ZA		= 'za',
	GS		= 'gs',
	SS		= 'ss',

	ES		= 'es',
	LK		= 'lk',
	SD		= 'sd',
	SR		= 'sr',
	SJ		= 'sj',
	SZ		= 'sz',

	SE		= 'se',
	CH		= 'ch',
	SY		= 'sy',
	TW		= 'tw',
	TJ		= 'tj',
	TZ		= 'tz',

	TH		= 'th',
	TL		= 'tl',
	TG		= 'tg',
	TK		= 'tk',
	TO		= 'to',
	TT		= 'tt',

	TN		= 'tn',
	TM		= 'tm',
	TC		= 'tc',
	TV		= 'tv',
	TR		= 'tr',
	UG		= 'ug',

	UA		= 'ua',
	AE		= 'ae',
	GB		= 'gb',
	US		= 'us',
	UM		= 'um',
	UY		= 'uy',

	UZ		= 'uz',
	VU		= 'vu',
	VA		= 'va',
	VE		= 've',
	VN		= 'vn',
	VG		= 'vg',

	WF		= 'wf',
	EH		= 'eh',
	YE		= 'ye',
	ZM		= 'zm',
	ZW		= 'zw'
}


/**------------------------------------------------------
 * Definitions & Configurations
 */
export const TM_TERRITORY_INFOS: Record<EnumTmTerritory, ITmTerritoryInfo> = {
	[EnumTmTerritory.WO]: {
		code		: EnumTmTerritory.WO,
		abbreviation: 'WIPO',
		name		: 'World',
		children	: [
			EnumTmTerritory.AP, EnumTmTerritory.AF, EnumTmTerritory.AX, EnumTmTerritory.AL, EnumTmTerritory.DZ, EnumTmTerritory.AS,
			EnumTmTerritory.AD, EnumTmTerritory.AO, EnumTmTerritory.AI, EnumTmTerritory.AQ, EnumTmTerritory.AG, EnumTmTerritory.AR,
			EnumTmTerritory.AM, EnumTmTerritory.AW, EnumTmTerritory.AU, EnumTmTerritory.AT, EnumTmTerritory.AZ, EnumTmTerritory.BS,
			EnumTmTerritory.BH, EnumTmTerritory.BD, EnumTmTerritory.BB, EnumTmTerritory.BY, EnumTmTerritory.BE, EnumTmTerritory.BZ,
			EnumTmTerritory.BX, EnumTmTerritory.BJ, EnumTmTerritory.BM, EnumTmTerritory.BT, EnumTmTerritory.BO, EnumTmTerritory.BQ,
			EnumTmTerritory.BA, EnumTmTerritory.BW, EnumTmTerritory.BV, EnumTmTerritory.BR, EnumTmTerritory.IO, EnumTmTerritory.BN,
			EnumTmTerritory.BG, EnumTmTerritory.BF, EnumTmTerritory.BI, EnumTmTerritory.KH, EnumTmTerritory.CM, EnumTmTerritory.CA,
			EnumTmTerritory.CV, EnumTmTerritory.KY, EnumTmTerritory.CF, EnumTmTerritory.TD, EnumTmTerritory.CL, EnumTmTerritory.CN,
			EnumTmTerritory.CX, EnumTmTerritory.CC, EnumTmTerritory.CO, EnumTmTerritory.KM, EnumTmTerritory.CG, EnumTmTerritory.CK,
			EnumTmTerritory.CR, EnumTmTerritory.HR, EnumTmTerritory.CU, EnumTmTerritory.CW, EnumTmTerritory.CY, EnumTmTerritory.CZ,
			EnumTmTerritory.CI, EnumTmTerritory.CD, EnumTmTerritory.DK, EnumTmTerritory.DJ, EnumTmTerritory.DM, EnumTmTerritory.DO,
			EnumTmTerritory.EM, EnumTmTerritory.EC, EnumTmTerritory.EG, EnumTmTerritory.SV, EnumTmTerritory.GQ, EnumTmTerritory.ER,
			EnumTmTerritory.EE, EnumTmTerritory.ET, EnumTmTerritory.FK, EnumTmTerritory.FO, EnumTmTerritory.FJ, EnumTmTerritory.FI,
			EnumTmTerritory.FR, EnumTmTerritory.GF, EnumTmTerritory.PF, EnumTmTerritory.TF, EnumTmTerritory.GA, EnumTmTerritory.GM,
			EnumTmTerritory.GE, EnumTmTerritory.DE, EnumTmTerritory.GH, EnumTmTerritory.GI, EnumTmTerritory.GR, EnumTmTerritory.GL,
			EnumTmTerritory.GD, EnumTmTerritory.GP, EnumTmTerritory.GU, EnumTmTerritory.GT, EnumTmTerritory.GG, EnumTmTerritory.GN,
			EnumTmTerritory.GW, EnumTmTerritory.GY, EnumTmTerritory.HT, EnumTmTerritory.HM, EnumTmTerritory.HN, EnumTmTerritory.HK,
			EnumTmTerritory.HU, EnumTmTerritory.IS, EnumTmTerritory.IN, EnumTmTerritory.ID, EnumTmTerritory.IR, EnumTmTerritory.IQ,
			EnumTmTerritory.IE, EnumTmTerritory.IM, EnumTmTerritory.IL, EnumTmTerritory.IT, EnumTmTerritory.JM, EnumTmTerritory.JP,
			EnumTmTerritory.JE, EnumTmTerritory.JO, EnumTmTerritory.KZ, EnumTmTerritory.KE, EnumTmTerritory.KI, EnumTmTerritory.KP,
			EnumTmTerritory.KW, EnumTmTerritory.KG, EnumTmTerritory.LA, EnumTmTerritory.LV, EnumTmTerritory.LB, EnumTmTerritory.LS,
			EnumTmTerritory.LR, EnumTmTerritory.LY, EnumTmTerritory.LI, EnumTmTerritory.LT, EnumTmTerritory.LU, EnumTmTerritory.MO,
			EnumTmTerritory.MG, EnumTmTerritory.MW, EnumTmTerritory.MY, EnumTmTerritory.MV, EnumTmTerritory.ML, EnumTmTerritory.MT,
			EnumTmTerritory.MH, EnumTmTerritory.MQ, EnumTmTerritory.MR, EnumTmTerritory.MU, EnumTmTerritory.YT, EnumTmTerritory.MX,
			EnumTmTerritory.FM, EnumTmTerritory.MD, EnumTmTerritory.MC, EnumTmTerritory.MN, EnumTmTerritory.ME, EnumTmTerritory.MS,
			EnumTmTerritory.MA, EnumTmTerritory.MZ, EnumTmTerritory.MM, EnumTmTerritory.NA, EnumTmTerritory.NR, EnumTmTerritory.NP,
			EnumTmTerritory.NL, EnumTmTerritory.NC, EnumTmTerritory.NZ, EnumTmTerritory.NI, EnumTmTerritory.NE, EnumTmTerritory.NG,
			EnumTmTerritory.NU, EnumTmTerritory.NF, EnumTmTerritory.MK, EnumTmTerritory.MP, EnumTmTerritory.NO, EnumTmTerritory.OM,
			EnumTmTerritory.OA, EnumTmTerritory.PK, EnumTmTerritory.PW, EnumTmTerritory.PS, EnumTmTerritory.PA, EnumTmTerritory.PG,
			EnumTmTerritory.PY, EnumTmTerritory.PE, EnumTmTerritory.PH, EnumTmTerritory.PN, EnumTmTerritory.PL, EnumTmTerritory.PT,
			EnumTmTerritory.QA, EnumTmTerritory.KR, EnumTmTerritory.RE, EnumTmTerritory.RO, EnumTmTerritory.RU, EnumTmTerritory.RW,
			EnumTmTerritory.BL, EnumTmTerritory.SH, EnumTmTerritory.KN, EnumTmTerritory.LC, EnumTmTerritory.MF, EnumTmTerritory.PM,
			EnumTmTerritory.VC, EnumTmTerritory.WS, EnumTmTerritory.SM, EnumTmTerritory.ST, EnumTmTerritory.SA, EnumTmTerritory.SN,
			EnumTmTerritory.RS, EnumTmTerritory.SC, EnumTmTerritory.SL, EnumTmTerritory.SG, EnumTmTerritory.SX, EnumTmTerritory.SK,
			EnumTmTerritory.SI, EnumTmTerritory.SB, EnumTmTerritory.SO, EnumTmTerritory.ZA, EnumTmTerritory.GS, EnumTmTerritory.SS,
			EnumTmTerritory.ES, EnumTmTerritory.LK, EnumTmTerritory.SD, EnumTmTerritory.SR, EnumTmTerritory.SJ, EnumTmTerritory.SZ,
			EnumTmTerritory.SE, EnumTmTerritory.CH, EnumTmTerritory.SY, EnumTmTerritory.TW, EnumTmTerritory.TJ, EnumTmTerritory.TZ,
			EnumTmTerritory.TH, EnumTmTerritory.TL, EnumTmTerritory.TG, EnumTmTerritory.TK, EnumTmTerritory.TO, EnumTmTerritory.TT,
			EnumTmTerritory.TN, EnumTmTerritory.TM, EnumTmTerritory.TC, EnumTmTerritory.TV, EnumTmTerritory.TR, EnumTmTerritory.UG,
			EnumTmTerritory.UA, EnumTmTerritory.AE, EnumTmTerritory.GB, EnumTmTerritory.US, EnumTmTerritory.UM, EnumTmTerritory.UY,
			EnumTmTerritory.UZ, EnumTmTerritory.VU, EnumTmTerritory.VA, EnumTmTerritory.VE, EnumTmTerritory.VN, EnumTmTerritory.VG,
			EnumTmTerritory.WO, EnumTmTerritory.WF, EnumTmTerritory.EH, EnumTmTerritory.YE, EnumTmTerritory.ZM, EnumTmTerritory.ZW,
			EnumTmTerritory.EU
		],
		tmViewValue	: 'EUR'
	},
	[EnumTmTerritory.EUR]: {
		code		: EnumTmTerritory.EUR,
		abbreviation: 'EUR',
		name		: 'Europe',
		children	: [
			EnumTmTerritory.EU, EnumTmTerritory.AT, EnumTmTerritory.BE, EnumTmTerritory.BG, EnumTmTerritory.HR, EnumTmTerritory.CY,
			EnumTmTerritory.CZ, EnumTmTerritory.DK, EnumTmTerritory.EE, EnumTmTerritory.FI, EnumTmTerritory.FR, EnumTmTerritory.DE,
			EnumTmTerritory.GR, EnumTmTerritory.HU, EnumTmTerritory.IE, EnumTmTerritory.IT, EnumTmTerritory.LV, EnumTmTerritory.LT,
			EnumTmTerritory.LU, EnumTmTerritory.MT, EnumTmTerritory.NL, EnumTmTerritory.PL, EnumTmTerritory.PT, EnumTmTerritory.RO,
			EnumTmTerritory.SK, EnumTmTerritory.SI, EnumTmTerritory.ES, EnumTmTerritory.SE, EnumTmTerritory.NEU, EnumTmTerritory.AX,
			EnumTmTerritory.AL, EnumTmTerritory.AD, EnumTmTerritory.BY, EnumTmTerritory.BQ, EnumTmTerritory.BA, EnumTmTerritory.CW,
			EnumTmTerritory.FO, EnumTmTerritory.GE, EnumTmTerritory.GI, EnumTmTerritory.GG, EnumTmTerritory.IS, EnumTmTerritory.IM,
			EnumTmTerritory.JE, EnumTmTerritory.LI, EnumTmTerritory.MD, EnumTmTerritory.MC, EnumTmTerritory.ME, EnumTmTerritory.MK,
			EnumTmTerritory.NO, EnumTmTerritory.RU, EnumTmTerritory.SH, EnumTmTerritory.SM, EnumTmTerritory.RS, EnumTmTerritory.SX,
			EnumTmTerritory.SJ, EnumTmTerritory.CH, EnumTmTerritory.UA, EnumTmTerritory.GB, EnumTmTerritory.VA
		],
		tmViewValue	: 'EUR'
	},
	[EnumTmTerritory.EU]: {
		code		: EnumTmTerritory.EU,
		abbreviation: 'EU',
		name		: 'European Union',
		children	: [
			EnumTmTerritory.AT, EnumTmTerritory.BE, EnumTmTerritory.BG, EnumTmTerritory.HR, EnumTmTerritory.CY, EnumTmTerritory.CZ,
			EnumTmTerritory.DK, EnumTmTerritory.EE, EnumTmTerritory.FI, EnumTmTerritory.FR, EnumTmTerritory.DE, EnumTmTerritory.GR,
			EnumTmTerritory.HU, EnumTmTerritory.IE, EnumTmTerritory.IT, EnumTmTerritory.LV, EnumTmTerritory.LT, EnumTmTerritory.LU,
			EnumTmTerritory.MT, EnumTmTerritory.NL, EnumTmTerritory.PL, EnumTmTerritory.PT, EnumTmTerritory.RO, EnumTmTerritory.SK,
			EnumTmTerritory.SI, EnumTmTerritory.ES, EnumTmTerritory.SE
		],
		tmViewValue	: 'EU'
	},
	[EnumTmTerritory.AT]: {
		code		: EnumTmTerritory.AT,
		abbreviation: 'AT',
		name		: 'Austria',
		children	: [],
		tmViewValue	: 'AT'
	},
	[EnumTmTerritory.AP]: {
		code		: EnumTmTerritory.AP,
		abbreviation: 'AP',
		name		: 'ARIPO',
		children	: [],
		tmViewValue	: 'AP'
	},
	[EnumTmTerritory.BE]: {
		code		: EnumTmTerritory.BE,
		abbreviation: 'BE',
		name		: 'Belgium',
		children	: [],
		tmViewValue	: 'BE'
	},
	[EnumTmTerritory.BX]: {
		code		: EnumTmTerritory.BX,
		abbreviation: 'BX',
		name		: 'Benelux',
		children	: [],
		tmViewValue	: 'BX'
	},
	[EnumTmTerritory.BG]: {
		code		: EnumTmTerritory.BG,
		abbreviation: 'BG',
		name		: 'Bulgaria',
		children	: [],
		tmViewValue	: 'BG'
	},
	[EnumTmTerritory.HR]: {
		code		: EnumTmTerritory.HR,
		abbreviation: 'HR',
		name		: 'Croatia',
		children	: [],
		tmViewValue	: 'HR'
	},
	[EnumTmTerritory.CY]: {
		code		: EnumTmTerritory.CY,
		abbreviation: 'CY',
		name		: 'Cyprus',
		children	: [],
		tmViewValue	: 'CY'
	},
	[EnumTmTerritory.CZ]: {
		code		: EnumTmTerritory.CZ,
		abbreviation: 'CZ',
		name		: 'Czech Republic',
		children	: [],
		tmViewValue	: 'CZ'
	},
	[EnumTmTerritory.DK]: {
		code		: EnumTmTerritory.DK,
		abbreviation: 'DK',
		name		: 'Denmark',
		children	: [],
		tmViewValue	: 'DK'
	},
	[EnumTmTerritory.EE]: {
		code		: EnumTmTerritory.EE,
		abbreviation: 'EE',
		name		: 'Estonia',
		children	: [],
		tmViewValue	: 'EE'
	},
	[EnumTmTerritory.FI]: {
		code		: EnumTmTerritory.FI,
		abbreviation: 'FI',
		name		: 'Finland',
		children	: [],
		tmViewValue	: 'FI'
	},
	[EnumTmTerritory.FR]: {
		code		: EnumTmTerritory.FR,
		abbreviation: 'FR',
		name		: 'France',
		children	: [],
		tmViewValue	: 'FR'
	},
	[EnumTmTerritory.DE]: {
		code		: EnumTmTerritory.DE,
		abbreviation: 'DE',
		name		: 'Germany',
		children	: [],
		tmViewValue	: 'DE'
	},
	[EnumTmTerritory.GR]: {
		code		: EnumTmTerritory.GR,
		abbreviation: 'GR',
		name		: 'Greece',
		children	: [],
		tmViewValue	: 'GR'
	},
	[EnumTmTerritory.HU]: {
		code		: EnumTmTerritory.HU,
		abbreviation: 'HU',
		name		: 'Hungary',
		children	: [],
		tmViewValue	: 'HU'
	},
	[EnumTmTerritory.IE]: {
		code		: EnumTmTerritory.IE,
		abbreviation: 'IE',
		name		: 'Ireland',
		children	: [],
		tmViewValue	: 'IE'
	},
	[EnumTmTerritory.IT]: {
		code		: EnumTmTerritory.IT,
		abbreviation: 'IT',
		name		: 'Italy',
		children	: [],
		tmViewValue	: 'IT'
	},
	[EnumTmTerritory.LV]: {
		code		: EnumTmTerritory.LV,
		abbreviation: 'LV',
		name		: 'Latvia',
		children	: [],
		tmViewValue	: 'LV'
	},
	[EnumTmTerritory.LT]: {
		code		: EnumTmTerritory.LT,
		abbreviation: 'LT',
		name		: 'Lithuania',
		children	: [],
		tmViewValue	: 'LT'
	},
	[EnumTmTerritory.LU]: {
		code		: EnumTmTerritory.LU,
		abbreviation: 'LU',
		name		: 'Luxembourg',
		children	: [],
		tmViewValue	: 'LU'
	},
	[EnumTmTerritory.MT]: {
		code		: EnumTmTerritory.MT,
		abbreviation: 'MT',
		name		: 'Malta',
		children	: [],
		tmViewValue	: 'MT'
	},
	[EnumTmTerritory.NL]: {
		code		: EnumTmTerritory.NL,
		abbreviation: 'NL',
		name		: 'Netherlands',
		children	: [],
		tmViewValue	: 'NL'
	},
	[EnumTmTerritory.PL]: {
		code		: EnumTmTerritory.PL,
		abbreviation: 'PL',
		name		: 'Poland',
		children	: [],
		tmViewValue	: 'PL'
	},
	[EnumTmTerritory.PT]: {
		code		: EnumTmTerritory.PT,
		abbreviation: 'PT',
		name		: 'Portugal',
		children	: [],
		tmViewValue	: 'PT'
	},
	[EnumTmTerritory.RO]: {
		code		: EnumTmTerritory.RO,
		abbreviation: 'RO',
		name		: 'Romania',
		children	: [],
		tmViewValue	: 'RO'
	},
	[EnumTmTerritory.SK]: {
		code		: EnumTmTerritory.SK,
		abbreviation: 'SK',
		name		: 'Slovakia',
		children	: [],
		tmViewValue	: 'SK'
	},
	[EnumTmTerritory.SI]: {
		code		: EnumTmTerritory.SI,
		abbreviation: 'SI',
		name		: 'Slovenia',
		children	: [],
		tmViewValue	: 'SI'
	},
	[EnumTmTerritory.ES]: {
		code		: EnumTmTerritory.ES,
		abbreviation: 'ES',
		name		: 'Spain',
		children	: [],
		tmViewValue	: 'ES'
	},
	[EnumTmTerritory.SE]: {
		code		: EnumTmTerritory.SE,
		abbreviation: 'SE',
		name		: 'Sweden',
		children	: [],
		tmViewValue	: 'SE'
	},
	[EnumTmTerritory.NEU]: {
		code		: EnumTmTerritory.NEU,
		abbreviation: 'NEU',
		name		: 'Non-European Union',
		children	: [
			EnumTmTerritory.AX, EnumTmTerritory.AL, EnumTmTerritory.AD, EnumTmTerritory.BY, EnumTmTerritory.BQ, EnumTmTerritory.BA,
			EnumTmTerritory.CW, EnumTmTerritory.FO, EnumTmTerritory.GE, EnumTmTerritory.GI, EnumTmTerritory.GG, EnumTmTerritory.IS,
			EnumTmTerritory.IM, EnumTmTerritory.JE, EnumTmTerritory.LI, EnumTmTerritory.MD, EnumTmTerritory.MC, EnumTmTerritory.ME,
			EnumTmTerritory.MK, EnumTmTerritory.NO, EnumTmTerritory.RU, EnumTmTerritory.SH, EnumTmTerritory.SM, EnumTmTerritory.RS,
			EnumTmTerritory.SX, EnumTmTerritory.SJ, EnumTmTerritory.CH, EnumTmTerritory.UA, EnumTmTerritory.GB, EnumTmTerritory.VA
		],
		tmViewValue	: 'NEU'
	},
	[EnumTmTerritory.AX]: {
		code		: EnumTmTerritory.AX,
		abbreviation: 'AX',
		name		: 'Åland Islands',
		children	: [],
		tmViewValue	: 'AX'
	},
	[EnumTmTerritory.AL]: {
		code		: EnumTmTerritory.AL,
		abbreviation: 'AL',
		name		: 'Albania',
		children	: [],
		tmViewValue	: 'AL'
	},
	[EnumTmTerritory.AD]: {
		code		: EnumTmTerritory.AD,
		abbreviation: 'AD',
		name		: 'Andorra',
		children	: [],
		tmViewValue	: 'AD'
	},
	[EnumTmTerritory.BY]: {
		code		: EnumTmTerritory.BY,
		abbreviation: 'BY',
		name		: 'Belarus',
		children	: [],
		tmViewValue	: 'BY'
	},
	[EnumTmTerritory.BQ]: {
		code		: EnumTmTerritory.BQ,
		abbreviation: 'BQ',
		name		: 'Bonaire, Sint Eustatius and Saba',
		children	: [],
		tmViewValue	: 'BQ'
	},
	[EnumTmTerritory.BA]: {
		code		: EnumTmTerritory.BA,
		abbreviation: 'BA',
		name		: 'Bosnia and Herzegovina',
		children	: [],
		tmViewValue	: 'BA'
	},
	[EnumTmTerritory.CW]: {
		code		: EnumTmTerritory.CW,
		abbreviation: 'CW',
		name		: 'Curaçao',
		children	: [],
		tmViewValue	: 'CW'
	},
	[EnumTmTerritory.FO]: {
		code		: EnumTmTerritory.FO,
		abbreviation: 'FO',
		name		: 'Faroes',
		children	: [],
		tmViewValue	: 'FO'
	},
	[EnumTmTerritory.GE]: {
		code		: EnumTmTerritory.GE,
		abbreviation: 'GE',
		name		: 'Georgia',
		children	: [],
		tmViewValue	: 'GE'
	},
	[EnumTmTerritory.GI]: {
		code		: EnumTmTerritory.GI,
		abbreviation: 'GI',
		name		: 'Gibraltar',
		children	: [],
		tmViewValue	: 'GI'
	},
	[EnumTmTerritory.GG]: {
		code		: EnumTmTerritory.GG,
		abbreviation: 'GG',
		name		: 'Guernsey',
		children	: [],
		tmViewValue	: 'GG'
	},
	[EnumTmTerritory.IS]: {
		code		: EnumTmTerritory.IS,
		abbreviation: 'IS',
		name		: 'Iceland',
		children	: [],
		tmViewValue	: 'IS'
	},
	[EnumTmTerritory.IM]: {
		code		: EnumTmTerritory.IM,
		abbreviation: 'IM',
		name		: 'Isle of Man',
		children	: [],
		tmViewValue	: 'IM'
	},
	[EnumTmTerritory.JE]: {
		code		: EnumTmTerritory.JE,
		abbreviation: 'JE',
		name		: 'Jersey',
		children	: [],
		tmViewValue	: 'JE'
	},
	[EnumTmTerritory.LI]: {
		code		: EnumTmTerritory.LI,
		abbreviation: 'LI',
		name		: 'Liechtenstein',
		children	: [],
		tmViewValue	: 'LI'
	},
	[EnumTmTerritory.MD]: {
		code		: EnumTmTerritory.MD,
		abbreviation: 'MD',
		name		: 'Moldova',
		children	: [],
		tmViewValue	: 'MD'
	},
	[EnumTmTerritory.MC]: {
		code		: EnumTmTerritory.MC,
		abbreviation: 'MC',
		name		: 'Monaco',
		children	: [],
		tmViewValue	: 'MC'
	},
	[EnumTmTerritory.ME]: {
		code		: EnumTmTerritory.ME,
		abbreviation: 'ME',
		name		: 'Montenegro',
		children	: [],
		tmViewValue	: 'ME'
	},
	[EnumTmTerritory.MK]: {
		code		: EnumTmTerritory.MK,
		abbreviation: 'MK',
		name		: 'North Macedonia',
		children	: [],
		tmViewValue	: 'MK'
	},
	[EnumTmTerritory.NO]: {
		code		: EnumTmTerritory.NO,
		abbreviation: 'NO',
		name		: 'Norway',
		children	: [],
		tmViewValue	: 'NO'
	},
	[EnumTmTerritory.RU]: {
		code		: EnumTmTerritory.RU,
		abbreviation: 'RU',
		name		: 'Russian Federation',
		children	: [],
		tmViewValue	: 'RU'
	},
	[EnumTmTerritory.SH]: {
		code		: EnumTmTerritory.SH,
		abbreviation: 'SH',
		name		: 'Saint Helena, Ascension and Tristan da Cunha',
		children	: [],
		tmViewValue	: 'SH'
	},
	[EnumTmTerritory.SM]: {
		code		: EnumTmTerritory.SM,
		abbreviation: 'SM',
		name		: 'San Marino',
		children	: [],
		tmViewValue	: 'SM'
	},
	[EnumTmTerritory.RS]: {
		code		: EnumTmTerritory.RS,
		abbreviation: 'RS',
		name		: 'Serbia',
		children	: [],
		tmViewValue	: 'RS'
	},
	[EnumTmTerritory.SX]: {
		code		: EnumTmTerritory.SX,
		abbreviation: 'SX',
		name		: 'Sint Maarten',
		children	: [],
		tmViewValue	: 'SX'
	},
	[EnumTmTerritory.SJ]: {
		code		: EnumTmTerritory.SJ,
		abbreviation: 'SJ',
		name		: 'Svalbard and Jan Mayen',
		children	: [],
		tmViewValue	: 'SJ'
	},
	[EnumTmTerritory.CH]: {
		code		: EnumTmTerritory.CH,
		abbreviation: 'CH',
		name		: 'Switzerland',
		children	: [],
		tmViewValue	: 'CH'
	},
	[EnumTmTerritory.UA]: {
		code		: EnumTmTerritory.UA,
		abbreviation: 'UA',
		name		: 'Ukraine',
		children	: [],
		tmViewValue	: 'UA'
	},
	[EnumTmTerritory.GB]: {
		code		: EnumTmTerritory.GB,
		abbreviation: 'GB',
		name		: 'United Kingdom',
		children	: [],
		tmViewValue	: 'GB'
	},
	[EnumTmTerritory.VA]: {
		code		: EnumTmTerritory.VA,
		abbreviation: 'VA',
		name		: 'Vatican City State',
		children	: [],
		tmViewValue	: 'VA'
	},
	[EnumTmTerritory.AFR]: {
		code		: EnumTmTerritory.AFR,
		abbreviation: 'AFR',
		name		: 'Africa',
		children	: [
			EnumTmTerritory.DZ, EnumTmTerritory.AO, EnumTmTerritory.BJ, EnumTmTerritory.BW, EnumTmTerritory.BF, EnumTmTerritory.BI,
			EnumTmTerritory.CM, EnumTmTerritory.CV, EnumTmTerritory.CF, EnumTmTerritory.TD, EnumTmTerritory.KM, EnumTmTerritory.CG,
			EnumTmTerritory.CI, EnumTmTerritory.CD, EnumTmTerritory.DJ, EnumTmTerritory.EG, EnumTmTerritory.GQ, EnumTmTerritory.ER,
			EnumTmTerritory.ET, EnumTmTerritory.GA, EnumTmTerritory.GM, EnumTmTerritory.GH, EnumTmTerritory.GN, EnumTmTerritory.GW,
			EnumTmTerritory.KE, EnumTmTerritory.LS, EnumTmTerritory.LR, EnumTmTerritory.LY, EnumTmTerritory.MG, EnumTmTerritory.MW,
			EnumTmTerritory.ML, EnumTmTerritory.MR, EnumTmTerritory.MU, EnumTmTerritory.YT, EnumTmTerritory.MA, EnumTmTerritory.MZ,
			EnumTmTerritory.NA, EnumTmTerritory.NE, EnumTmTerritory.NG, EnumTmTerritory.OA, EnumTmTerritory.RE, EnumTmTerritory.RW,
			EnumTmTerritory.ST, EnumTmTerritory.SN, EnumTmTerritory.SC, EnumTmTerritory.SL, EnumTmTerritory.SO, EnumTmTerritory.ZA,
			EnumTmTerritory.SS, EnumTmTerritory.SD, EnumTmTerritory.SZ, EnumTmTerritory.TZ, EnumTmTerritory.TG, EnumTmTerritory.TO,
			EnumTmTerritory.TT, EnumTmTerritory.TN, EnumTmTerritory.UG, EnumTmTerritory.EH, EnumTmTerritory.ZM, EnumTmTerritory.ZW
		],
		tmViewValue	: 'AFR'
	},
	[EnumTmTerritory.DZ]: {
		code		: EnumTmTerritory.DZ,
		abbreviation: 'DZ',
		name		: 'Algeria',
		children	: [],
		tmViewValue	: 'DZ'
	},
	[EnumTmTerritory.AO]: {
		code		: EnumTmTerritory.AO,
		abbreviation: 'AO',
		name		: 'Angola',
		children	: [],
		tmViewValue	: 'AO'
	},
	[EnumTmTerritory.BJ]: {
		code		: EnumTmTerritory.BJ,
		abbreviation: 'BJ',
		name		: 'Benin',
		children	: [],
		tmViewValue	: 'BJ'
	},
	[EnumTmTerritory.BW]: {
		code		: EnumTmTerritory.BW,
		abbreviation: 'BW',
		name		: 'Botswana',
		children	: [],
		tmViewValue	: 'BW'
	},
	[EnumTmTerritory.BF]: {
		code		: EnumTmTerritory.BF,
		abbreviation: 'BF',
		name		: 'Burkina Faso',
		children	: [],
		tmViewValue	: 'BF'
	},
	[EnumTmTerritory.BI]: {
		code		: EnumTmTerritory.BI,
		abbreviation: 'BI',
		name		: 'Burundi',
		children	: [],
		tmViewValue	: 'BI'
	},
	[EnumTmTerritory.CM]: {
		code		: EnumTmTerritory.CM,
		abbreviation: 'CM',
		name		: 'Cameroon',
		children	: [],
		tmViewValue	: 'CM'
	},
	[EnumTmTerritory.CV]: {
		code		: EnumTmTerritory.CV,
		abbreviation: 'CV',
		name		: 'Cabo Verde',
		children	: [],
		tmViewValue	: 'CV'
	},
	[EnumTmTerritory.CF]: {
		code		: EnumTmTerritory.CF,
		abbreviation: 'CF',
		name		: 'Central African Republic',
		children	: [],
		tmViewValue	: 'CF'
	},
	[EnumTmTerritory.TD]: {
		code		: EnumTmTerritory.TD,
		abbreviation: 'TD',
		name		: 'Chad',
		children	: [],
		tmViewValue	: 'TD'
	},
	[EnumTmTerritory.KM]: {
		code		: EnumTmTerritory.KM,
		abbreviation: 'KM',
		name		: 'Comoros',
		children	: [],
		tmViewValue	: 'KM'
	},
	[EnumTmTerritory.CG]: {
		code		: EnumTmTerritory.CG,
		abbreviation: 'CG',
		name		: 'Congo',
		children	: [],
		tmViewValue	: 'CG'
	},
	[EnumTmTerritory.CI]: {
		code		: EnumTmTerritory.CI,
		abbreviation: 'CI',
		name		: 'Côte d’Ivoire',
		children	: [],
		tmViewValue	: 'CI'
	},
	[EnumTmTerritory.CD]: {
		code		: EnumTmTerritory.CD,
		abbreviation: 'CD',
		name		: 'Democratic Republic of Congo',
		children	: [],
		tmViewValue	: 'CD'
	},
	[EnumTmTerritory.DJ]: {
		code		: EnumTmTerritory.DJ,
		abbreviation: 'DJ',
		name		: 'Djibouti',
		children	: [],
		tmViewValue	: 'DJ'
	},
	[EnumTmTerritory.EG]: {
		code		: EnumTmTerritory.EG,
		abbreviation: 'EG',
		name		: 'Egypt',
		children	: [],
		tmViewValue	: 'EG'
	},
	[EnumTmTerritory.GQ]: {
		code		: EnumTmTerritory.GQ,
		abbreviation: 'GQ',
		name		: 'Equatorial Guinea',
		children	: [],
		tmViewValue	: 'GQ'
	},
	[EnumTmTerritory.ER]: {
		code		: EnumTmTerritory.ER,
		abbreviation: 'ER',
		name		: 'Eritrea',
		children	: [],
		tmViewValue	: 'ER'
	},
	[EnumTmTerritory.ET]: {
		code		: EnumTmTerritory.ET,
		abbreviation: 'ET',
		name		: 'Ethiopia',
		children	: [],
		tmViewValue	: 'ET'
	},
	[EnumTmTerritory.GA]: {
		code		: EnumTmTerritory.GA,
		abbreviation: 'GA',
		name		: 'Gabon',
		children	: [],
		tmViewValue	: 'GA'
	},
	[EnumTmTerritory.GM]: {
		code		: EnumTmTerritory.GM,
		abbreviation: 'GM',
		name		: 'Gambia',
		children	: [],
		tmViewValue	: 'GM'
	},
	[EnumTmTerritory.GH]: {
		code		: EnumTmTerritory.GH,
		abbreviation: 'GH',
		name		: 'Ghana',
		children	: [],
		tmViewValue	: 'GH'
	},
	[EnumTmTerritory.GN]: {
		code		: EnumTmTerritory.GN,
		abbreviation: 'GN',
		name		: 'Guinea',
		children	: [],
		tmViewValue	: 'GN'
	},
	[EnumTmTerritory.GW]: {
		code		: EnumTmTerritory.GW,
		abbreviation: 'GW',
		name		: 'Guinea-Bissau',
		children	: [],
		tmViewValue	: 'GW'
	},
	[EnumTmTerritory.KE]: {
		code		: EnumTmTerritory.KE,
		abbreviation: 'KE',
		name		: 'Kenya',
		children	: [],
		tmViewValue	: 'KE'
	},
	[EnumTmTerritory.LS]: {
		code		: EnumTmTerritory.LS,
		abbreviation: 'LS',
		name		: 'Lesotho',
		children	: [],
		tmViewValue	: 'LS'
	},
	[EnumTmTerritory.LR]: {
		code		: EnumTmTerritory.LR,
		abbreviation: 'LR',
		name		: 'Liberia',
		children	: [],
		tmViewValue	: 'LR'
	},
	[EnumTmTerritory.LY]: {
		code		: EnumTmTerritory.LY,
		abbreviation: 'LY',
		name		: 'Libya',
		children	: [],
		tmViewValue	: 'LY'
	},
	[EnumTmTerritory.MG]: {
		code		: EnumTmTerritory.MG,
		abbreviation: 'MG',
		name		: 'Madagascar',
		children	: [],
		tmViewValue	: 'MG'
	},
	[EnumTmTerritory.MW]: {
		code		: EnumTmTerritory.MW,
		abbreviation: 'MW',
		name		: 'Malawi',
		children	: [],
		tmViewValue	: 'MW'
	},
	[EnumTmTerritory.ML]: {
		code		: EnumTmTerritory.ML,
		abbreviation: 'ML',
		name		: 'Mali',
		children	: [],
		tmViewValue	: 'ML'
	},
	[EnumTmTerritory.MR]: {
		code		: EnumTmTerritory.MR,
		abbreviation: 'MR',
		name		: 'Mauritania',
		children	: [],
		tmViewValue	: 'MR'
	},
	[EnumTmTerritory.MU]: {
		code		: EnumTmTerritory.MU,
		abbreviation: 'MU',
		name		: 'Mauritius',
		children	: [],
		tmViewValue	: 'MU'
	},
	[EnumTmTerritory.YT]: {
		code		: EnumTmTerritory.YT,
		abbreviation: 'YT',
		name		: 'Mayotte',
		children	: [],
		tmViewValue	: 'YT'
	},
	[EnumTmTerritory.MA]: {
		code		: EnumTmTerritory.MA,
		abbreviation: 'MA',
		name		: 'Morocco',
		children	: [],
		tmViewValue	: 'MA'
	},
	[EnumTmTerritory.MZ]: {
		code		: EnumTmTerritory.MZ,
		abbreviation: 'MZ',
		name		: 'Mozambique',
		children	: [],
		tmViewValue	: 'MZ'
	},
	[EnumTmTerritory.NA]: {
		code		: EnumTmTerritory.NA,
		abbreviation: 'NA',
		name		: 'Namibia',
		children	: [],
		tmViewValue	: 'NA'
	},
	[EnumTmTerritory.NE]: {
		code		: EnumTmTerritory.NE,
		abbreviation: 'NE',
		name		: 'Niger',
		children	: [],
		tmViewValue	: 'NE'
	},
	[EnumTmTerritory.NG]: {
		code		: EnumTmTerritory.NG,
		abbreviation: 'NG',
		name		: 'Nigeria',
		children	: [],
		tmViewValue	: 'NG'
	},
	[EnumTmTerritory.OA]: {
		code		: EnumTmTerritory.OA,
		abbreviation: 'OA',
		name		: 'OAPI',
		children	: [],
		tmViewValue	: 'OA'
	},
	[EnumTmTerritory.RE]: {
		code		: EnumTmTerritory.RE,
		abbreviation: 'RE',
		name		: 'Réunion',
		children	: [],
		tmViewValue	: 'RE'
	},
	[EnumTmTerritory.RW]: {
		code		: EnumTmTerritory.RW,
		abbreviation: 'RW',
		name		: 'Rwanda',
		children	: [],
		tmViewValue	: 'RW'
	},
	[EnumTmTerritory.ST]: {
		code		: EnumTmTerritory.ST,
		abbreviation: 'ST',
		name		: 'Sao Tome and Principe',
		children	: [],
		tmViewValue	: 'ST'
	},
	[EnumTmTerritory.SN]: {
		code		: EnumTmTerritory.SN,
		abbreviation: 'SN',
		name		: 'Senegal',
		children	: [],
		tmViewValue	: 'SN'
	},
	[EnumTmTerritory.SC]: {
		code		: EnumTmTerritory.SC,
		abbreviation: 'SC',
		name		: 'Seychelles',
		children	: [],
		tmViewValue	: 'SC'
	},
	[EnumTmTerritory.SL]: {
		code		: EnumTmTerritory.SL,
		abbreviation: 'SL',
		name		: 'Sierra Leone',
		children	: [],
		tmViewValue	: 'SL'
	},
	[EnumTmTerritory.SO]: {
		code		: EnumTmTerritory.SO,
		abbreviation: 'SO',
		name		: 'Somalia',
		children	: [],
		tmViewValue	: 'SO'
	},
	[EnumTmTerritory.ZA]: {
		code		: EnumTmTerritory.ZA,
		abbreviation: 'ZA',
		name		: 'South Africa',
		children	: [],
		tmViewValue	: 'ZA'
	},
	[EnumTmTerritory.SS]: {
		code		: EnumTmTerritory.SS,
		abbreviation: 'SS',
		name		: 'South Sudan',
		children	: [],
		tmViewValue	: 'SS'
	},
	[EnumTmTerritory.SD]: {
		code		: EnumTmTerritory.SD,
		abbreviation: 'SD',
		name		: 'Sudan',
		children	: [],
		tmViewValue	: 'SD'
	},
	[EnumTmTerritory.SZ]: {
		code		: EnumTmTerritory.SZ,
		abbreviation: 'SZ',
		name		: 'Eswatini',
		children	: [],
		tmViewValue	: 'SZ'
	},
	[EnumTmTerritory.TZ]: {
		code		: EnumTmTerritory.TZ,
		abbreviation: 'TZ',
		name		: 'Tanzania',
		children	: [],
		tmViewValue	: 'TZ'
	},
	[EnumTmTerritory.TG]: {
		code		: EnumTmTerritory.TG,
		abbreviation: 'TG',
		name		: 'Togo',
		children	: [],
		tmViewValue	: 'TG'
	},
	[EnumTmTerritory.TO]: {
		code		: EnumTmTerritory.TO,
		abbreviation: 'TO',
		name		: 'Tonga',
		children	: [],
		tmViewValue	: 'TO'
	},
	[EnumTmTerritory.TT]: {
		code		: EnumTmTerritory.TT,
		abbreviation: 'TT',
		name		: 'Trinidad and Tobago',
		children	: [],
		tmViewValue	: 'TT'
	},
	[EnumTmTerritory.TN]: {
		code		: EnumTmTerritory.TN,
		abbreviation: 'TN',
		name		: 'Tunisia',
		children	: [],
		tmViewValue	: 'TN'
	},
	[EnumTmTerritory.UG]: {
		code		: EnumTmTerritory.UG,
		abbreviation: 'UG',
		name		: 'Uganda',
		children	: [],
		tmViewValue	: 'UG'
	},
	[EnumTmTerritory.EH]: {
		code		: EnumTmTerritory.EH,
		abbreviation: 'EH',
		name		: 'Western Sahara',
		children	: [],
		tmViewValue	: 'EH'
	},
	[EnumTmTerritory.ZM]: {
		code		: EnumTmTerritory.ZM,
		abbreviation: 'ZM',
		name		: 'Zambia',
		children	: [],
		tmViewValue	: 'ZM'
	},
	[EnumTmTerritory.ZW]: {
		code		: EnumTmTerritory.ZW,
		abbreviation: 'ZW',
		name		: 'Zimbabwe',
		children	: [],
		tmViewValue	: 'ZW'
	},
	[EnumTmTerritory.ASI]: {
		code		: EnumTmTerritory.ASI,
		abbreviation: 'ASI',
		name		: 'Asia',
		children	: [
			EnumTmTerritory.AF, EnumTmTerritory.AM, EnumTmTerritory.AZ, EnumTmTerritory.BH, EnumTmTerritory.BD, EnumTmTerritory.BT,
			EnumTmTerritory.IO, EnumTmTerritory.BN, EnumTmTerritory.KH, EnumTmTerritory.CN, EnumTmTerritory.CX, EnumTmTerritory.CC,
			EnumTmTerritory.HK, EnumTmTerritory.IN, EnumTmTerritory.ID, EnumTmTerritory.IR, EnumTmTerritory.IQ, EnumTmTerritory.IL,
			EnumTmTerritory.JP, EnumTmTerritory.JO, EnumTmTerritory.KZ, EnumTmTerritory.KP, EnumTmTerritory.KW, EnumTmTerritory.KG,
			EnumTmTerritory.LA, EnumTmTerritory.LB, EnumTmTerritory.MO, EnumTmTerritory.MY, EnumTmTerritory.MV, EnumTmTerritory.MN,
			EnumTmTerritory.MM, EnumTmTerritory.NP, EnumTmTerritory.OM, EnumTmTerritory.PK, EnumTmTerritory.PS, EnumTmTerritory.PH,
			EnumTmTerritory.QA, EnumTmTerritory.KR, EnumTmTerritory.SA, EnumTmTerritory.SG, EnumTmTerritory.LK, EnumTmTerritory.SY,
			EnumTmTerritory.TW, EnumTmTerritory.TJ, EnumTmTerritory.TH, EnumTmTerritory.TL, EnumTmTerritory.TM, EnumTmTerritory.TR,
			EnumTmTerritory.AE, EnumTmTerritory.UZ, EnumTmTerritory.VN, EnumTmTerritory.YE
		],
		tmViewValue	: 'ASI'
	},
	[EnumTmTerritory.AF]: {
		code		: EnumTmTerritory.AF,
		abbreviation: 'AF',
		name		: 'Afghanistan',
		children	: [],
		tmViewValue	: 'AF'
	},
	[EnumTmTerritory.AM]: {
		code		: EnumTmTerritory.AM,
		abbreviation: 'AM',
		name		: 'Armenia',
		children	: [],
		tmViewValue	: 'AM'
	},
	[EnumTmTerritory.AZ]: {
		code		: EnumTmTerritory.AZ,
		abbreviation: 'AZ',
		name		: 'Azerbaijan',
		children	: [],
		tmViewValue	: 'AZ'
	},
	[EnumTmTerritory.BH]: {
		code		: EnumTmTerritory.BH,
		abbreviation: 'BH',
		name		: 'Bahrain',
		children	: [],
		tmViewValue	: 'BH'
	},
	[EnumTmTerritory.BD]: {
		code		: EnumTmTerritory.BD,
		abbreviation: 'BD',
		name		: 'Bangladesh',
		children	: [],
		tmViewValue	: 'BD'
	},
	[EnumTmTerritory.BT]: {
		code		: EnumTmTerritory.BT,
		abbreviation: 'BT',
		name		: 'Bhutan',
		children	: [],
		tmViewValue	: 'BT'
	},
	[EnumTmTerritory.IO]: {
		code		: EnumTmTerritory.IO,
		abbreviation: 'IO',
		name		: 'British Indian Ocean Territory',
		children	: [],
		tmViewValue	: 'IO'
	},
	[EnumTmTerritory.BN]: {
		code		: EnumTmTerritory.BN,
		abbreviation: 'BN',
		name		: 'Brunei Darussalam',
		children	: [],
		tmViewValue	: 'BN'
	},
	[EnumTmTerritory.KH]: {
		code		: EnumTmTerritory.KH,
		abbreviation: 'KH',
		name		: 'Cambodia',
		children	: [],
		tmViewValue	: 'KH'
	},
	[EnumTmTerritory.CN]: {
		code		: EnumTmTerritory.CN,
		abbreviation: 'CN',
		name		: 'China',
		children	: [],
		tmViewValue	: 'CN'
	},
	[EnumTmTerritory.CX]: {
		code		: EnumTmTerritory.CX,
		abbreviation: 'CX',
		name		: 'Christmas Island',
		children	: [],
		tmViewValue	: 'CX'
	},
	[EnumTmTerritory.CC]: {
		code		: EnumTmTerritory.CC,
		abbreviation: 'CC',
		name		: 'Cocos (Keeling) Islands',
		children	: [],
		tmViewValue	: 'CC'
	},
	[EnumTmTerritory.HK]: {
		code		: EnumTmTerritory.HK,
		abbreviation: 'HK',
		name		: 'Hong Kong',
		children	: [],
		tmViewValue	: 'HK'
	},
	[EnumTmTerritory.IN]: {
		code		: EnumTmTerritory.IN,
		abbreviation: 'IN',
		name		: 'India',
		children	: [],
		tmViewValue	: 'IN'
	},
	[EnumTmTerritory.ID]: {
		code		: EnumTmTerritory.ID,
		abbreviation: 'ID',
		name		: 'Indonesia',
		children	: [],
		tmViewValue	: 'ID'
	},
	[EnumTmTerritory.IR]: {
		code		: EnumTmTerritory.IR,
		abbreviation: 'IR',
		name		: 'Iran',
		children	: [],
		tmViewValue	: 'IR'
	},
	[EnumTmTerritory.IQ]: {
		code		: EnumTmTerritory.IQ,
		abbreviation: 'IQ',
		name		: 'Iraq',
		children	: [],
		tmViewValue	: 'IQ'
	},
	[EnumTmTerritory.IL]: {
		code		: EnumTmTerritory.IL,
		abbreviation: 'IL',
		name		: 'Israel',
		children	: [],
		tmViewValue	: 'IL'
	},
	[EnumTmTerritory.JP]: {
		code		: EnumTmTerritory.JP,
		abbreviation: 'JP',
		name		: 'Japan',
		children	: [],
		tmViewValue	: 'JP'
	},
	[EnumTmTerritory.JO]: {
		code		: EnumTmTerritory.JO,
		abbreviation: 'JO',
		name		: 'Jordan',
		children	: [],
		tmViewValue	: 'JO'
	},
	[EnumTmTerritory.KZ]: {
		code		: EnumTmTerritory.KZ,
		abbreviation: 'KZ',
		name		: 'Kazakhstan',
		children	: [],
		tmViewValue	: 'KZ'
	},
	[EnumTmTerritory.KP]: {
		code		: EnumTmTerritory.KP,
		abbreviation: 'KP',
		name		: 'North Korea',
		children	: [],
		tmViewValue	: 'KP'
	},
	[EnumTmTerritory.KW]: {
		code		: EnumTmTerritory.KW,
		abbreviation: 'KW',
		name		: 'Kuwait',
		children	: [],
		tmViewValue	: 'KW'
	},
	[EnumTmTerritory.KG]: {
		code		: EnumTmTerritory.KG,
		abbreviation: 'KG',
		name		: 'Kyrgyzstan',
		children	: [],
		tmViewValue	: 'KG'
	},
	[EnumTmTerritory.LA]: {
		code		: EnumTmTerritory.LA,
		abbreviation: 'LA',
		name		: 'Laos',
		children	: [],
		tmViewValue	: 'LA'
	},
	[EnumTmTerritory.LB]: {
		code		: EnumTmTerritory.LB,
		abbreviation: 'LB',
		name		: 'Lebanon',
		children	: [],
		tmViewValue	: 'LB'
	},
	[EnumTmTerritory.MO]: {
		code		: EnumTmTerritory.MO,
		abbreviation: 'MO',
		name		: 'Macao',
		children	: [],
		tmViewValue	: 'MO'
	},
	[EnumTmTerritory.MY]: {
		code		: EnumTmTerritory.MY,
		abbreviation: 'MY',
		name		: 'Malaysia',
		children	: [],
		tmViewValue	: 'MY'
	},
	[EnumTmTerritory.MV]: {
		code		: EnumTmTerritory.MV,
		abbreviation: 'MV',
		name		: 'Maldives',
		children	: [],
		tmViewValue	: 'MV'
	},
	[EnumTmTerritory.MN]: {
		code		: EnumTmTerritory.MN,
		abbreviation: 'MN',
		name		: 'Mongolia',
		children	: [],
		tmViewValue	: 'MN'
	},
	[EnumTmTerritory.MM]: {
		code		: EnumTmTerritory.MM,
		abbreviation: 'MM',
		name		: 'Myanmar',
		children	: [],
		tmViewValue	: 'MM'
	},
	[EnumTmTerritory.NP]: {
		code		: EnumTmTerritory.NP,
		abbreviation: 'NP',
		name		: 'Nepal',
		children	: [],
		tmViewValue	: 'NP'
	},
	[EnumTmTerritory.OM]: {
		code		: EnumTmTerritory.OM,
		abbreviation: 'OM',
		name		: 'Oman',
		children	: [],
		tmViewValue	: 'OM'
	},
	[EnumTmTerritory.PK]: {
		code		: EnumTmTerritory.PK,
		abbreviation: 'PK',
		name		: 'Pakistan',
		children	: [],
		tmViewValue	: 'PK'
	},
	[EnumTmTerritory.PS]: {
		code		: EnumTmTerritory.PS,
		abbreviation: 'PS',
		name		: 'PS',
		children	: [],
		tmViewValue	: 'PS'
	},
	[EnumTmTerritory.PH]: {
		code		: EnumTmTerritory.PH,
		abbreviation: 'PH',
		name		: 'Philippines',
		children	: [],
		tmViewValue	: 'PH'
	},
	[EnumTmTerritory.QA]: {
		code		: EnumTmTerritory.QA,
		abbreviation: 'QA',
		name		: 'Qatar',
		children	: [],
		tmViewValue	: 'QA'
	},
	[EnumTmTerritory.KR]: {
		code		: EnumTmTerritory.KR,
		abbreviation: 'KR',
		name		: 'Republic of Korea',
		children	: [],
		tmViewValue	: 'KR'
	},
	[EnumTmTerritory.SA]: {
		code		: EnumTmTerritory.SA,
		abbreviation: 'SA',
		name		: 'Saudi Arabia',
		children	: [],
		tmViewValue	: 'SA'
	},
	[EnumTmTerritory.SG]: {
		code		: EnumTmTerritory.SG,
		abbreviation: 'SG',
		name		: 'Singapore',
		children	: [],
		tmViewValue	: 'SG'
	},
	[EnumTmTerritory.LK]: {
		code		: EnumTmTerritory.LK,
		abbreviation: 'LK',
		name		: 'Sri Lanka',
		children	: [],
		tmViewValue	: 'LK'
	},
	[EnumTmTerritory.SY]: {
		code		: EnumTmTerritory.SY,
		abbreviation: 'SY',
		name		: 'Syria',
		children	: [],
		tmViewValue	: 'SY'
	},
	[EnumTmTerritory.TW]: {
		code		: EnumTmTerritory.TW,
		abbreviation: 'TW',
		name		: 'Taiwan, Province of China',
		children	: [],
		tmViewValue	: 'TW'
	},
	[EnumTmTerritory.TJ]: {
		code		: EnumTmTerritory.TJ,
		abbreviation: 'TJ',
		name		: 'Tajikistan',
		children	: [],
		tmViewValue	: 'TJ'
	},
	[EnumTmTerritory.TH]: {
		code		: EnumTmTerritory.TH,
		abbreviation: 'TH',
		name		: 'Thailand',
		children	: [],
		tmViewValue	: 'TH'
	},
	[EnumTmTerritory.TL]: {
		code		: EnumTmTerritory.TL,
		abbreviation: 'TL',
		name		: 'Timor-Leste',
		children	: [],
		tmViewValue	: 'TL'
	},
	[EnumTmTerritory.TM]: {
		code		: EnumTmTerritory.TM,
		abbreviation: 'TM',
		name		: 'Turkmenistan',
		children	: [],
		tmViewValue	: 'TM'
	},
	[EnumTmTerritory.TR]: {
		code		: EnumTmTerritory.TR,
		abbreviation: 'TR',
		name		: 'Türkiye',
		children	: [],
		tmViewValue	: 'TR'
	},
	[EnumTmTerritory.AE]: {
		code		: EnumTmTerritory.AE,
		abbreviation: 'AE',
		name		: 'United Arab Emirates',
		children	: [],
		tmViewValue	: 'AE'
	},
	[EnumTmTerritory.UZ]: {
		code		: EnumTmTerritory.UZ,
		abbreviation: 'UZ',
		name		: 'Uzbekistan',
		children	: [],
		tmViewValue	: 'UZ'
	},
	[EnumTmTerritory.VN]: {
		code		: EnumTmTerritory.VN,
		abbreviation: 'VN',
		name		: 'Vietnam',
		children	: [],
		tmViewValue	: 'VN'
	},
	[EnumTmTerritory.YE]: {
		code		: EnumTmTerritory.YE,
		abbreviation: 'YE',
		name		: 'Yemen',
		children	: [],
		tmViewValue	: 'YE'
	},
	[EnumTmTerritory.AUS]: {
		code		: EnumTmTerritory.AUS,
		abbreviation: 'AUS',
		name		: 'Oceania',
		children	: [
			EnumTmTerritory.AS, EnumTmTerritory.AU, EnumTmTerritory.BV, EnumTmTerritory.CK, EnumTmTerritory.FJ, EnumTmTerritory.PF,
			EnumTmTerritory.GU, EnumTmTerritory.KI, EnumTmTerritory.MH, EnumTmTerritory.FM, EnumTmTerritory.NR, EnumTmTerritory.NC,
			EnumTmTerritory.NZ, EnumTmTerritory.NU, EnumTmTerritory.NF, EnumTmTerritory.PW, EnumTmTerritory.PG, EnumTmTerritory.PN,
			EnumTmTerritory.WS, EnumTmTerritory.SB, EnumTmTerritory.TK, EnumTmTerritory.TV, EnumTmTerritory.UM, EnumTmTerritory.VU,
			EnumTmTerritory.WF
		],
		tmViewValue	: 'AUS'
	},
	[EnumTmTerritory.AS]: {
		code		: EnumTmTerritory.AS,
		abbreviation: 'AS',
		name		: 'American Samoa',
		children	: [],
		tmViewValue	: 'AS'
	},
	[EnumTmTerritory.AU]: {
		code		: EnumTmTerritory.AU,
		abbreviation: 'AU',
		name		: 'Australia',
		children	: [],
		tmViewValue	: 'AU'
	},
	[EnumTmTerritory.BV]: {
		code		: EnumTmTerritory.BV,
		abbreviation: 'BV',
		name		: 'Bouvet Island',
		children	: [],
		tmViewValue	: 'BV'
	},
	[EnumTmTerritory.CK]: {
		code		: EnumTmTerritory.CK,
		abbreviation: 'CK',
		name		: 'Cook Islands',
		children	: [],
		tmViewValue	: 'CK'
	},
	[EnumTmTerritory.FJ]: {
		code		: EnumTmTerritory.FJ,
		abbreviation: 'FJ',
		name		: 'Fiji',
		children	: [],
		tmViewValue	: 'FJ'
	},
	[EnumTmTerritory.PF]: {
		code		: EnumTmTerritory.PF,
		abbreviation: 'PF',
		name		: 'French Polynesia',
		children	: [],
		tmViewValue	: 'PF'
	},
	[EnumTmTerritory.GU]: {
		code		: EnumTmTerritory.GU,
		abbreviation: 'GU',
		name		: 'Guam',
		children	: [],
		tmViewValue	: 'GU'
	},
	[EnumTmTerritory.KI]: {
		code		: EnumTmTerritory.KI,
		abbreviation: 'KI',
		name		: 'Kiribati',
		children	: [],
		tmViewValue	: 'KI'
	},
	[EnumTmTerritory.MH]: {
		code		: EnumTmTerritory.MH,
		abbreviation: 'MH',
		name		: 'Marshall Islands',
		children	: [],
		tmViewValue	: 'MH'
	},
	[EnumTmTerritory.FM]: {
		code		: EnumTmTerritory.FM,
		abbreviation: 'FM',
		name		: 'Micronesia',
		children	: [],
		tmViewValue	: 'FM'
	},
	[EnumTmTerritory.NR]: {
		code		: EnumTmTerritory.NR,
		abbreviation: 'NR',
		name		: 'Nauru',
		children	: [],
		tmViewValue	: 'NR'
	},
	[EnumTmTerritory.NC]: {
		code		: EnumTmTerritory.NC,
		abbreviation: 'NC',
		name		: 'New Caledonia',
		children	: [],
		tmViewValue	: 'NC'
	},
	[EnumTmTerritory.NZ]: {
		code		: EnumTmTerritory.NZ,
		abbreviation: 'NZ',
		name		: 'New Zealand',
		children	: [],
		tmViewValue	: 'NZ'
	},
	[EnumTmTerritory.NU]: {
		code		: EnumTmTerritory.NU,
		abbreviation: 'NU',
		name		: 'Niue',
		children	: [],
		tmViewValue	: 'NU'
	},
	[EnumTmTerritory.NF]: {
		code		: EnumTmTerritory.NF,
		abbreviation: 'NF',
		name		: 'Norfolk Island',
		children	: [],
		tmViewValue	: 'NF'
	},
	[EnumTmTerritory.PW]: {
		code		: EnumTmTerritory.PW,
		abbreviation: 'PW',
		name		: 'Palau',
		children	: [],
		tmViewValue	: 'PW'
	},
	[EnumTmTerritory.PG]: {
		code		: EnumTmTerritory.PG,
		abbreviation: 'PG',
		name		: 'Papua New Guinea',
		children	: [],
		tmViewValue	: 'PG'
	},
	[EnumTmTerritory.PN]: {
		code		: EnumTmTerritory.PN,
		abbreviation: 'PN',
		name		: 'Pitcairn Islands',
		children	: [],
		tmViewValue	: 'PN'
	},
	[EnumTmTerritory.WS]: {
		code		: EnumTmTerritory.WS,
		abbreviation: 'WS',
		name		: 'Samoa',
		children	: [],
		tmViewValue	: 'WS'
	},
	[EnumTmTerritory.SB]: {
		code		: EnumTmTerritory.SB,
		abbreviation: 'SB',
		name		: 'Solomon Islands',
		children	: [],
		tmViewValue	: 'SB'
	},
	[EnumTmTerritory.TK]: {
		code		: EnumTmTerritory.TK,
		abbreviation: 'TK',
		name		: 'Tokelau',
		children	: [],
		tmViewValue	: 'TK'
	},
	[EnumTmTerritory.TV]: {
		code		: EnumTmTerritory.TV,
		abbreviation: 'TV',
		name		: 'Tuvalu',
		children	: [],
		tmViewValue	: 'TV'
	},
	[EnumTmTerritory.UM]: {
		code		: EnumTmTerritory.UM,
		abbreviation: 'UM',
		name		: 'United States Minor Outlying Islands',
		children	: [],
		tmViewValue	: 'UM'
	},
	[EnumTmTerritory.VU]: {
		code		: EnumTmTerritory.VU,
		abbreviation: 'VU',
		name		: 'Vanuatu',
		children	: [],
		tmViewValue	: 'VU'
	},
	[EnumTmTerritory.WF]: {
		code		: EnumTmTerritory.WF,
		abbreviation: 'WF',
		name		: 'Wallis and Futuna',
		children	: [],
		tmViewValue	: 'WF'
	},
	[EnumTmTerritory.AMC]: {
		code		: EnumTmTerritory.AMC,
		abbreviation: 'AMC',
		name		: 'America',
		children	: [
			EnumTmTerritory.AI, EnumTmTerritory.AQ, EnumTmTerritory.AG, EnumTmTerritory.AR, EnumTmTerritory.AW, EnumTmTerritory.BS,
			EnumTmTerritory.BB, EnumTmTerritory.BZ, EnumTmTerritory.BM, EnumTmTerritory.BO, EnumTmTerritory.BR, EnumTmTerritory.CA,
			EnumTmTerritory.KY, EnumTmTerritory.CL, EnumTmTerritory.CO, EnumTmTerritory.CR, EnumTmTerritory.CU, EnumTmTerritory.DM,
			EnumTmTerritory.DO, EnumTmTerritory.EC, EnumTmTerritory.SV, EnumTmTerritory.FK, EnumTmTerritory.GF, EnumTmTerritory.TF,
			EnumTmTerritory.GL, EnumTmTerritory.GD, EnumTmTerritory.GP, EnumTmTerritory.GT, EnumTmTerritory.GY, EnumTmTerritory.HT,
			EnumTmTerritory.HM, EnumTmTerritory.HN, EnumTmTerritory.JM, EnumTmTerritory.MQ, EnumTmTerritory.MX, EnumTmTerritory.MS,
			EnumTmTerritory.NI, EnumTmTerritory.MP, EnumTmTerritory.PA, EnumTmTerritory.PY, EnumTmTerritory.PE, EnumTmTerritory.BL,
			EnumTmTerritory.KN, EnumTmTerritory.LC, EnumTmTerritory.MF, EnumTmTerritory.PM, EnumTmTerritory.VC, EnumTmTerritory.GS,
			EnumTmTerritory.SR, EnumTmTerritory.TC, EnumTmTerritory.US, EnumTmTerritory.UY, EnumTmTerritory.VE, EnumTmTerritory.VG
		],
		tmViewValue	: 'AMC'
	},
	[EnumTmTerritory.AI]: {
		code		: EnumTmTerritory.AI,
		abbreviation: 'AI',
		name		: 'Anguilla',
		children	: [],
		tmViewValue	: 'AI'
	},
	[EnumTmTerritory.AQ]: {
		code		: EnumTmTerritory.AQ,
		abbreviation: 'AQ',
		name		: 'Antarctica',
		children	: [],
		tmViewValue	: 'AQ'
	},
	[EnumTmTerritory.AG]: {
		code		: EnumTmTerritory.AG,
		abbreviation: 'AG',
		name		: 'Antigua and Barbuda',
		children	: [],
		tmViewValue	: 'AG'
	},
	[EnumTmTerritory.AR]: {
		code		: EnumTmTerritory.AR,
		abbreviation: 'AR',
		name		: 'Argentina',
		children	: [],
		tmViewValue	: 'AR'
	},
	[EnumTmTerritory.AW]: {
		code		: EnumTmTerritory.AW,
		abbreviation: 'AW',
		name		: 'Aruba',
		children	: [],
		tmViewValue	: 'AW'
	},
	[EnumTmTerritory.BS]: {
		code		: EnumTmTerritory.BS,
		abbreviation: 'BS',
		name		: 'Bahamas',
		children	: [],
		tmViewValue	: 'BS'
	},
	[EnumTmTerritory.BB]: {
		code		: EnumTmTerritory.BB,
		abbreviation: 'BB',
		name		: 'Barbados',
		children	: [],
		tmViewValue	: 'BB'
	},
	[EnumTmTerritory.BZ]: {
		code		: EnumTmTerritory.BZ,
		abbreviation: 'BZ',
		name		: 'Belize',
		children	: [],
		tmViewValue	: 'BZ'
	},
	[EnumTmTerritory.BM]: {
		code		: EnumTmTerritory.BM,
		abbreviation: 'BM',
		name		: 'Bermuda',
		children	: [],
		tmViewValue	: 'BM'
	},
	[EnumTmTerritory.BO]: {
		code		: EnumTmTerritory.BO,
		abbreviation: 'BO',
		name		: 'Bolivia',
		children	: [],
		tmViewValue	: 'BO'
	},
	[EnumTmTerritory.BR]: {
		code		: EnumTmTerritory.BR,
		abbreviation: 'BR',
		name		: 'Brazil',
		children	: [],
		tmViewValue	: 'BR'
	},
	[EnumTmTerritory.CA]: {
		code		: EnumTmTerritory.CA,
		abbreviation: 'CA',
		name		: 'Canada',
		children	: [],
		tmViewValue	: 'CA'
	},
	[EnumTmTerritory.KY]: {
		code		: EnumTmTerritory.KY,
		abbreviation: 'KY',
		name		: 'Cayman Islands',
		children	: [],
		tmViewValue	: 'KY'
	},
	[EnumTmTerritory.CL]: {
		code		: EnumTmTerritory.CL,
		abbreviation: 'CL',
		name		: 'Chile',
		children	: [],
		tmViewValue	: 'CL'
	},
	[EnumTmTerritory.CO]: {
		code		: EnumTmTerritory.CO,
		abbreviation: 'CO',
		name		: 'Colombia',
		children	: [],
		tmViewValue	: 'CO'
	},
	[EnumTmTerritory.CR]: {
		code		: EnumTmTerritory.CR,
		abbreviation: 'CR',
		name		: 'Costa Rica',
		children	: [],
		tmViewValue	: 'CR'
	},
	[EnumTmTerritory.CU]: {
		code		: EnumTmTerritory.CU,
		abbreviation: 'CU',
		name		: 'Cuba',
		children	: [],
		tmViewValue	: 'CU'
	},
	[EnumTmTerritory.DM]: {
		code		: EnumTmTerritory.DM,
		abbreviation: 'DM',
		name		: 'Dominica',
		children	: [],
		tmViewValue	: 'DM'
	},
	[EnumTmTerritory.DO]: {
		code		: EnumTmTerritory.DO,
		abbreviation: 'DO',
		name		: 'Dominican Republic',
		children	: [],
		tmViewValue	: 'DO'
	},
	[EnumTmTerritory.EM]: {
		code		: EnumTmTerritory.EM,
		abbreviation: 'EM',
		name		: 'EUIPO',
		children	: [],
		tmViewValue	: 'EM'
	},
	[EnumTmTerritory.EC]: {
		code		: EnumTmTerritory.EC,
		abbreviation: 'EC',
		name		: 'Ecuador',
		children	: [],
		tmViewValue	: 'EC'
	},
	[EnumTmTerritory.SV]: {
		code		: EnumTmTerritory.SV,
		abbreviation: 'SV',
		name		: 'El Salvador',
		children	: [],
		tmViewValue	: 'SV'
	},
	[EnumTmTerritory.FK]: {
		code		: EnumTmTerritory.FK,
		abbreviation: 'FK',
		name		: 'Falkland Islands',
		children	: [],
		tmViewValue	: 'FK'
	},
	[EnumTmTerritory.GF]: {
		code		: EnumTmTerritory.GF,
		abbreviation: 'GF',
		name		: 'French Guiana',
		children	: [],
		tmViewValue	: 'GF'
	},
	[EnumTmTerritory.TF]: {
		code		: EnumTmTerritory.TF,
		abbreviation: 'TF',
		name		: 'French Southern and Antarctic Lands',
		children	: [],
		tmViewValue	: 'TF'
	},
	[EnumTmTerritory.GL]: {
		code		: EnumTmTerritory.GL,
		abbreviation: 'GL',
		name		: 'Greenland',
		children	: [],
		tmViewValue	: 'GL'
	},
	[EnumTmTerritory.GD]: {
		code		: EnumTmTerritory.GD,
		abbreviation: 'GD',
		name		: 'Grenada',
		children	: [],
		tmViewValue	: 'GD'
	},
	[EnumTmTerritory.GP]: {
		code		: EnumTmTerritory.GP,
		abbreviation: 'GP',
		name		: 'Guadeloupe',
		children	: [],
		tmViewValue	: 'GP'
	},
	[EnumTmTerritory.GT]: {
		code		: EnumTmTerritory.GT,
		abbreviation: 'GT',
		name		: 'Guatemala',
		children	: [],
		tmViewValue	: 'GT'
	},
	[EnumTmTerritory.GY]: {
		code		: EnumTmTerritory.GY,
		abbreviation: 'GY',
		name		: 'Guyana',
		children	: [],
		tmViewValue	: 'GY'
	},
	[EnumTmTerritory.HT]: {
		code		: EnumTmTerritory.HT,
		abbreviation: 'HT',
		name		: 'Haiti',
		children	: [],
		tmViewValue	: 'HT'
	},
	[EnumTmTerritory.HM]: {
		code		: EnumTmTerritory.HM,
		abbreviation: 'HM',
		name		: 'Heard Island and McDonald Islands',
		children	: [],
		tmViewValue	: 'HM'
	},
	[EnumTmTerritory.HN]: {
		code		: EnumTmTerritory.HN,
		abbreviation: 'HN',
		name		: 'Honduras',
		children	: [],
		tmViewValue	: 'HN'
	},
	[EnumTmTerritory.JM]: {
		code		: EnumTmTerritory.JM,
		abbreviation: 'JM',
		name		: 'Jamaica',
		children	: [],
		tmViewValue	: 'JM'
	},
	[EnumTmTerritory.MQ]: {
		code		: EnumTmTerritory.MQ,
		abbreviation: 'MQ',
		name		: 'Martinique',
		children	: [],
		tmViewValue	: 'MQ'
	},
	[EnumTmTerritory.MX]: {
		code		: EnumTmTerritory.MX,
		abbreviation: 'MX',
		name		: 'Mexico',
		children	: [],
		tmViewValue	: 'MX'
	},
	[EnumTmTerritory.MS]: {
		code		: EnumTmTerritory.MS,
		abbreviation: 'MS',
		name		: 'Montserrat',
		children	: [],
		tmViewValue	: 'MS'
	},
	[EnumTmTerritory.NI]: {
		code		: EnumTmTerritory.NI,
		abbreviation: 'NI',
		name		: 'Nicaragua',
		children	: [],
		tmViewValue	: 'NI'
	},
	[EnumTmTerritory.MP]: {
		code		: EnumTmTerritory.MP,
		abbreviation: 'MP',
		name		: 'Northern Mariana Islands',
		children	: [],
		tmViewValue	: 'MP'
	},
	[EnumTmTerritory.PA]: {
		code		: EnumTmTerritory.PA,
		abbreviation: 'PA',
		name		: 'Panama',
		children	: [],
		tmViewValue	: 'PA'
	},
	[EnumTmTerritory.PY]: {
		code		: EnumTmTerritory.PY,
		abbreviation: 'PY',
		name		: 'Paraguay',
		children	: [],
		tmViewValue	: 'PY'
	},
	[EnumTmTerritory.PE]: {
		code		: EnumTmTerritory.PE,
		abbreviation: 'PE',
		name		: 'Peru',
		children	: [],
		tmViewValue	: 'PE'
	},
	[EnumTmTerritory.BL]: {
		code		: EnumTmTerritory.BL,
		abbreviation: 'BL',
		name		: 'Saint Barthélemy',
		children	: [],
		tmViewValue	: 'BL'
	},
	[EnumTmTerritory.KN]: {
		code		: EnumTmTerritory.KN,
		abbreviation: 'KN',
		name		: 'Saint Kitts and Nevis',
		children	: [],
		tmViewValue	: 'KN'
	},
	[EnumTmTerritory.LC]: {
		code		: EnumTmTerritory.LC,
		abbreviation: 'LC',
		name		: 'Saint Lucia',
		children	: [],
		tmViewValue	: 'LC'
	},
	[EnumTmTerritory.MF]: {
		code		: EnumTmTerritory.MF,
		abbreviation: 'MF',
		name		: 'Saint Martin',
		children	: [],
		tmViewValue	: 'MF'
	},
	[EnumTmTerritory.PM]: {
		code		: EnumTmTerritory.PM,
		abbreviation: 'PM',
		name		: 'Saint Pierre and Miquelon',
		children	: [],
		tmViewValue	: 'PM'
	},
	[EnumTmTerritory.VC]: {
		code		: EnumTmTerritory.VC,
		abbreviation: 'VC',
		name		: 'Saint Vincent and the Grenadines',
		children	: [],
		tmViewValue	: 'VC'
	},
	[EnumTmTerritory.GS]: {
		code		: EnumTmTerritory.GS,
		abbreviation: 'GS',
		name		: 'South Georgia and the South Sandwich Islands',
		children	: [],
		tmViewValue	: 'GS'
	},
	[EnumTmTerritory.SR]: {
		code		: EnumTmTerritory.SR,
		abbreviation: 'SR',
		name		: 'Suriname',
		children	: [],
		tmViewValue	: 'SR'
	},
	[EnumTmTerritory.TC]: {
		code		: EnumTmTerritory.TC,
		abbreviation: 'TC',
		name		: 'Turks and Caicos Islands',
		children	: [],
		tmViewValue	: 'TC'
	},
	[EnumTmTerritory.US]: {
		code		: EnumTmTerritory.US,
		abbreviation: 'US',
		name		: 'United States',
		children	: [],
		tmViewValue	: 'US'
	},
	[EnumTmTerritory.UY]: {
		code		: EnumTmTerritory.UY,
		abbreviation: 'UY',
		name		: 'Uruguay',
		children	: [],
		tmViewValue	: 'UY'
	},
	[EnumTmTerritory.VE]: {
		code		: EnumTmTerritory.VE,
		abbreviation: 'VE',
		name		: 'Venezuela',
		children	: [],
		tmViewValue	: 'VE'
	},
	[EnumTmTerritory.VG]: {
		code		: EnumTmTerritory.VG,
		abbreviation: 'VG',
		name		: 'British Virgin Islands',
		children	: [],
		tmViewValue	: 'VG'
	}
};


//** Interfaces --------------------------------- */
export interface ITmTerritoryInfo {
	code		: EnumTmTerritory;
	abbreviation: string;
	name		: string;
	children	: EnumTmTerritory[];
	tmViewValue	: string;
}
