import { EnumCountry } from '@libs/constants';

import { EnumAmazonMarketplace } from '../pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Google Calendar Constants
 */
export const GOOGLE_CALENDAR_CONFIG: IGoogleCalendarConfig[] = [
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		countryName	: 'United States',
		calendarId	: 'en.usa#holiday@group.v.calendar.google.com'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		countryName	: 'United Kingdom',
		calendarId	: 'uk.uk#holiday@group.v.calendar.google.com'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		countryName	: 'Germany',
		calendarId	: 'de.german#holiday@group.v.calendar.google.com'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		countryName	: 'France',
		calendarId	: 'fr.french#holiday@group.v.calendar.google.com'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		countryName	: 'Italy',
		calendarId	: 'it.italian#holiday@group.v.calendar.google.com'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		countryName	: 'Spain',
		calendarId	: 'es.spain#holiday@group.v.calendar.google.com'
	}
];


//** Interfaces --------------------------------- */
export interface IGoogleCalendarConfig {
	country		: EnumCountry;
	marketplace : EnumAmazonMarketplace;
	calendarId	: string;
	countryName	: string;
}
