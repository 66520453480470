import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_DEFAULT_OPTIONS } from '../amazon-search.data';
import { AbstractAmazonSearch } from '../helpers/amazon-search.abstract';
import { AMAZON_CONFIG_SE } from './amazon-se.data';
import { EnumAmazonSorting, EnumAmazonProduct, EnumAmazonMarketplace } from '../../../../../../constants/research/app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Amazon SE Search Links
 */
export class AmazonSeSearch extends AbstractAmazonSearch {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumAmazonSorting>[] {
		return AMAZON_CONFIG_SE.sortings as ISearchParameterConfig<EnumAmazonSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumAmazonProduct>[] {
		return AMAZON_CONFIG_SE.products as ISearchParameterConfig<EnumAmazonProduct>[];
	}

	constructor() {
		super(EnumAmazonMarketplace.SE, AMAZON_CONFIG_SE, AMAZON_DEFAULT_OPTIONS);
	}
}
