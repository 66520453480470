/**------------------------------------------------------
 * Type Definitions
 */
export type TypeCompareFn<T>  = (a: T, b: T) => boolean;
export type TypeGetValueFn<T> = (value: T) => T | string | number | boolean;


/**------------------------------------------------------
 * Helper Functions
 */
export function defaultCompareFn<T>(a: T, b: T): boolean {
	return a === b;
}
export function defaultGetValueFn<T>(value: T): T | string | number | boolean {
	return value;
}
