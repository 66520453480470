/* eslint-disable */
import { Util } from '@libs/utilities/util';
import { COLOR_LIST, EnumColorName, IColorDefinition } from '@libs/constants'


/**------------------------------------------------------
 * Find Color Name for Hex Color
 */
export class ColorCodeName {


	getColorName(colorNameList: string[], hexCode: string): IColorDefinition {

		//0 - checks for valid color
		if (!Util.Color.isHexColor(hexCode)) throw new Error(`ColorCodeName => getColorName => FATAL ERROR: hexCode of "${hexCode}" is not a valid hex code/color`);

		//1 - get the color name
		return this.getColorNameForHexCode(colorNameList, hexCode);
	}

	getColorNamePredefined(hexCode: string): IColorDefinition {

		//0 - checks for valid color
		if (!Util.Color.isHexColor(hexCode)) throw new Error(`ColorCodeName => getColorNamePredefined => FATAL ERROR: hexCode of "${hexCode}" is not a valid hex code/color`);

		//1 - get the color name
		return this.getColorNameForHexCode(Util.Enum.values(EnumColorName), hexCode);
	}


	/**------------------------------------------------------
	 * Gets the color name using hex code as input
	 */
	private getColorNameForHexCode(colorNameList: string[], hexCode: string): IColorDefinition {

		//0 - checks for valid color
		if (!Util.Color.isHexColor(hexCode)) throw new Error(`ColorNamingCore => getColorNameForHexCode => FATAL ERROR: hexCode of "${hexCode}" is not a valid hex code/color`);
		for (const colorName of colorNameList) {
			if (!Util.Enum.isValid(EnumColorName, colorName)) throw new Error(`ColorNamingCore => getColorNameForHexCode => FATAL ERROR: colorName of "${colorName}" is not configured in the COLOR_LIST definitions and in the EnumColorName`);
		}

		//1 - converts the color to uppercase and re-assigns the color to the hexCode
		hexCode = Util.Color.removeTransparency(hexCode).toUpperCase();
		if (hexCode.length % 3 == 0) hexCode = `#${hexCode}`;

		//2 - if color is having 4 characters then makes the color to length 7 characters
		if (hexCode.length == 4) {
			hexCode = `#${hexCode.substr(1, 1)}${hexCode.substr(1, 1)}${hexCode.substr(2, 1)}${hexCode.substr(2, 1)}${hexCode.substr(3, 1)}${hexCode.substr(3, 1)}`;
		}

		//3 - extracting the R G B H S L from the hex code and returns the fitting color name
		const [r, g, b] : number[]	= Util.Color.hexToRgb(hexCode);
		const [h, s, l] : number[]	= this.hsl(hexCode);
		let ndf1 		: number 	=  0;
		let ndf2 		: number 	=  0;
		let ndf  		: number 	=  0;
		let cl   		: number 	= -1;
		let df   		: number 	= -1;

		const colorConfigList: IColorConfig[] = this.hexColorConfig(colorNameList);
		for (let i = 0; i < colorConfigList.length; i++) {

			//a. is it a perfect match?
			if (hexCode === colorConfigList[i].definition.hexValue) {
				return colorConfigList[i].definition;
			}

			//b. save if it is this color definition is the nearest to the hexValue
			ndf1 = Math.pow(r - colorConfigList[i].red, 2) + Math.pow(g - colorConfigList[i].green, 2) + Math.pow(b - colorConfigList[i].blue, 2);
			ndf2 = Math.pow(h - colorConfigList[i].hue, 2) + Math.pow(s - colorConfigList[i].saturation, 2) + Math.pow(l - colorConfigList[i].lightness, 2);
			ndf = ndf1 + ndf2 * 2;
			if (df < 0 || df > ndf) {
				df = ndf;
				cl = i;
			}
		}

		//4 - check if the hex code is invalid and return the result
		if (cl < 0) throw new Error(`ColorNamingCore => getColorName => FATAL ERROR: hexCode of "${hexCode}" is invalid for the color extraction algorithm (no match could be found)`);
		return colorConfigList[cl].definition;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private hexColorConfig(colorNameList: string[]): IColorConfig[] {

		//0 - get the color list (only use the color definitions of the list)
		const colorList: IColorDefinition[] = Object.values(COLOR_LIST).filter((elem: IColorDefinition) => colorNameList.includes(elem.code));
		if (Util.Array.isEmpty(colorList)) throw new Error(`ColorNameByHexCode => hexColorConfig => FATAL ERROR: definitions for colorNameList of "${colorNameList}" is empty (colorList result of of "${colorList}", can not be empty!)`);

		//1 - extracts the R G B H S L values of the hex codes present in colorList
		const colorConfigList: IColorConfig[] = [];
		for (let i = 0; i < colorList.length; i++) {

			//a. color info values
			const color 	: string = colorList[i].hexValue;
			const [r, g, b] : number[] = Util.Color.hexToRgb(color);
			const [h, s, l] : number[] = this.hsl(color);

			//b. create the color info data, used in the algorithm
			colorConfigList.push({
				definition 	: colorList[i],
				red			: r,
				green		: g,
				blue		: b,
				hue			: h,
				saturation	: s,
				lightness	: l,
			});
		}

		//2 - return the color list configuration
		return colorConfigList;
	}

	//** Extracts the h s l values from the hex code */
	private hsl(hexCode: string): number[] {

		//0 - extract the parts of the color
		const r 	: number = parseInt(`0x${hexCode.substring(1, 3)}`)/255;
		const g 	: number = parseInt(`0x${hexCode.substring(3, 5)}`)/255;
		const b 	: number = parseInt(`0x${hexCode.substring(5, 7)}`)/255;

		const min 	: number = Math.min(r, Math.min(g, b));
		const max 	: number = Math.max(r, Math.max(g, b));
		const delta : number = max - min;
		const l 	: number = (min + max) / 2;

		//2 - calculate h/HUE, s/SATURATION, and l/LIGHTNESS
		let s: number = 0;
		if (l > 0 && l < 1) {
			s = delta / (l < 0.5 ? (2 * l) : (2 - 2 * l));
		}

		let h: number = 0;
		if (delta > 0) {
			if (max == r && max != g) h += (g - b) / delta;
			if (max == g && max != b) h += (2 + (b - r) / delta);
			if (max == b && max != r) h += (4 + (r - g) / delta);
			h /= 6;
		}

		return [(h * 255), (s * 255), (l * 255)];
	}
}


//** Interfaces --------------------------------- */
interface IColorConfig {
	definition 	: IColorDefinition;
	red			: number;
	green		: number;
	blue		: number;
	hue			: number;
	saturation	: number;
	lightness	: number;
}
