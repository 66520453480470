/**------------------------------------------------------
 * Flatten Object
 * --------------
 * > source : https://www.geeksforgeeks.org/flatten-javascript-objects-into-a-single-depth-object/
 */
export class UtilDeepFlatten {

	constructor(
		private utilBasic: IUtilBasic
	) {}


	//** Flatten any object */
	flattenObject<T>(object: T): TypeUtilFlatObject {

		//0 - check the provided object
		if (!this.utilBasic.isObject(object)) throw new Error(`FlattenObject => flattenObject => FATAL ERROR: the provided object is not a valid object, please make sure it is not an array (typeof object: "${typeof object}" / value: "${object}")`);
		if (!object) 						  throw new Error(`FlattenObject => flattenObject => FATAL ERROR: the provided object is null or undefined (value: "${object}")`);

		//1 - flatten the object
		return this.flattenObjectAlgorithm(object);
	}

	//** Flatten any array */
	flattenArray<T>(array: any[]): T[] {

		//0 - check the provided object
		if (!this.utilBasic.isArray(array)) throw new Error(`FlattenObject => flattenArray => FATAL ERROR: the provided array is not a valid array, please make sure it is not an array (typeof object: "${typeof array}" / value: "${array}")`);
		if (!array || array.length === 0)   throw new Error(`flattenArray => flattenObject => FATAL ERROR: the provided array is null/undefined/empty (value: "${array}")`);

		//1 - flatten the object
		return this.flattenArrayAlgorithm(array);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private flattenObjectAlgorithm<T>(object: T): TypeUtilFlatObject {

		//0 - the object which contains the final result
		const flattenedObject: TypeUtilFlatObject = {};

		//1 - loop through the object "ob"
		for (const key in object) {

			//a. is the current value an object?
			const isObjectForFlatten: boolean = this.utilBasic.isObject(object[key]) && !this.utilBasic.isArray(object[key]);
			if (!isObjectForFlatten) {
				flattenedObject[key] = object[key];
				continue;
			}

			//b. if object has to be flattened, do so
			const flattenedElements: TypeUtilFlatObject = this.flattenObjectAlgorithm(object[key]);
			for (const element in flattenedElements) {
				flattenedObject[`${key}.${element}`] = flattenedElements[element];
			}
		}

		//2 - return the flattened object
		return flattenedObject;
	}

	private flattenArrayAlgorithm(array: any[]): any[] {
		return [].concat(
			...array.map((value: any) => (Array.isArray(value) ? this.flattenArrayAlgorithm(value) : value))
		);
	}
}


//** Types -------------------------------------- */
export type TypeUtilFlatObject = Record<string, unknown | unknown[]>;


//** Interfaces --------------------------------- */
interface IUtilBasic {
	isObject<T>(value: T): boolean;
	isArray<T>(value: T): boolean;
}
