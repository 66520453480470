import { EnumAmazonMarketplace } from '../../../app-constants';
import { IAmazonCompositeKeywordData } from '../../../data-structures';


/**------------------------------------------------------
 * Amazon Clothing Bulk Keywords Request API-Interfaces
 */
export interface IGetAmazonClothingBulkKeywordsRequest {
	marketplace	: EnumAmazonMarketplace;
	keywords	: string[];
}


/**------------------------------------------------------
 * Amazon Clothing Bulk Keywords Response API-Interfaces
 */
export interface IGetAmazonClothingBulkKeywordsResponse {
	marketplace	: EnumAmazonMarketplace;
	keywords	: IAmazonCompositeKeywordData[];
}
