import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgComponentAssistantService } from './component-assistant/component-assistant.service';
import { NgModalAssistantService } from './modal-assistant/modal-assistant.service';
import { NgTemplateAssistantService } from './template-assistant/template-assistant.service';


@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		NgComponentAssistantService,
		NgModalAssistantService,
		NgTemplateAssistantService
	]
})
export class NgContentAssistantModule { }
