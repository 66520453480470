import { EnumMass } from './mass.interface';
import { EnumSystem, EnumType, IUnitRecord } from '../shared/unit-system.interface';


/**------------------------------------------------------
 * Mass Infos
 */
export const MASS_INFOS: Record<EnumMass, IUnitRecord> = {

	// -------------------------------- Mass (Metric)
	[EnumMass.Microgram]: {
		code		: EnumMass.Microgram,
		type		: EnumType.Mass,
		system		: EnumSystem.Metric,
		name		: 'Microgram',
		namePlural	: 'Micrograms',
		symbol		: 'mcg',
		toBase		: 1 / 1000000
	},
	[EnumMass.Milligram]: {
		code		: EnumMass.Milligram,
		type		: EnumType.Mass,
		system		: EnumSystem.Metric,
		name		: 'Milligram',
		namePlural	: 'Milligrams',
		symbol		: 'mg',
		toBase		: 1 / 1000
	},
	[EnumMass.Gram]: {
		code		: EnumMass.Gram,
		type		: EnumType.Mass,
		system		: EnumSystem.Metric,
		name		: 'Gram',
		namePlural	: 'Grams',
		symbol		: 'g',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumMass.Pound,
			toType		: EnumType.Mass,
			toSystem	: EnumSystem.Imperial,
			convert		: (value: number) => 1 / 453.592 * value
		}
	},
	[EnumMass.Kilogram]: {
		code		: EnumMass.Kilogram,
		type		: EnumType.Mass,
		system		: EnumSystem.Metric,
		name		: 'Kilogram',
		namePlural	: 'Kilograms',
		symbol		: 'kg',
		toBase		: 1000
	},
	[EnumMass.MetricTonne]: {
		code		: EnumMass.MetricTonne,
		type		: EnumType.Mass,
		system		: EnumSystem.Metric,
		name		: 'Metric Tonne',
		namePlural	: 'Metric Tonnes',
		symbol		: 't',
		toBase		: 1000000
	},

	// -------------------------------- Mass (Imperial)
	[EnumMass.Ounce]: {
		code		: EnumMass.Ounce,
		type		: EnumType.Mass,
		system		: EnumSystem.Imperial,
		name		: 'Ounce',
		namePlural	: 'Ounces',
		symbol		: 'oz',
		toBase		: 1 / 16
	},
	[EnumMass.Pound]: {
		code		: EnumMass.Pound,
		type		: EnumType.Mass,
		system		: EnumSystem.Imperial,
		name		: 'Pound',
		namePlural	: 'Pounds',
		symbol		: 'lb',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumMass.Gram,
			toType		: EnumType.Mass,
			toSystem	: EnumSystem.Metric,
			convert		: (value: number) => 453.592 * value
		}
	},
	[EnumMass.ImperialTon]: {
		code		: EnumMass.ImperialTon,
		type		: EnumType.Mass,
		system		: EnumSystem.Imperial,
		name		: 'Ton',
		namePlural	: 'Tons',
		symbol		: 't',
		toBase		: 2240
	}
};
