/**------------------------------------------------------
 * Sanitize Object Values
 */
export class UtilSanitizeObject {

	//** Configurations / Constants */
	readonly SANITIZE_VALUES: unknown[] = [null, undefined, [], {}, ''];			// eslint-disable-line no-undefined

	constructor(
		private utilBasic: IUtilBasic
	) {}


	/**------------------------------------------------------
	 * Sanitize Object / Array
	 */
	sanitizeObject<T extends object>(object: T, removeValues: unknown[] = this.SANITIZE_VALUES): Partial<T> {
		this.checkSanitizeObjectValues(object, removeValues);
		return this.sanitizeObjectAlgorithm(object, {
			removeValues	: removeValues,
			runRecursively	: true
		});
	}
	sanitizeObjectSurface<T extends object>(object: T, removeValues: unknown[] = this.SANITIZE_VALUES): Partial<T> {
		this.checkSanitizeObjectValues(object, removeValues);
		return this.sanitizeObjectAlgorithm(object, {
			removeValues	: removeValues,
			runRecursively	: false
		});
	}


	/**------------------------------------------------------
	 * Sanitize / Remove empty values
	 */
	private checkSanitizeObjectValues<T>(object: T, removeValues: any[]) {
		if (!object) throw new Error(`SanitizeObject => checkSanitizeObjectValues => FATAL ERROR: provided object of "${object}" null / undefined`);
		if (!this.utilBasic.isObject(object) && !this.utilBasic.isArray(object)) throw new Error(`SanitizeObject => checkSanitizeObjectValues => FATAL ERROR: provided object of "${object}" is not an object or array`);
		if (!removeValues || removeValues.length === 0) throw new Error(`SanitizeObject => checkSanitizeObjectValues => FATAL ERROR: provided removeValues of "${removeValues}" is empty of invalid`);
	}

	private sanitizeObjectAlgorithm<T extends object>(object: T, options: IUtilSanitizeAssignOptions): Partial<T> {

		//0 - check if provided value is an array or object
		const isObject: boolean = this.utilBasic.isObject(object);
		const isArray : boolean = this.utilBasic.isArray(object);
		if (!isObject && !isArray) throw new Error(`SanitizeObject => sanitizeObjectAlgorithm => FATAL ERROR: provided object of "${object}" is not an object or array`);

		//1 - if object, cleanup the elements in object
		if (isObject && !isArray) {
			for (const key of Object.keys(object as object)) {

				//a. if complex object, sanitize it first
				const entryValue: any = (object as any)[key];
				const isObjectOrArray: boolean = this.utilBasic.isObject(entryValue) || this.utilBasic.isArray(entryValue);
				if (options.runRecursively && isObjectOrArray) this.sanitizeObjectAlgorithm(entryValue, options);

				//b. sanitize the value if possible, remove empty values
				for (const removeValue of options.removeValues) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					if (this.utilBasic.isEqual(entryValue, removeValue)) delete (object as any)[key];
				}
			}
		}

		//2 - if array, cleanup elements within the array
		if (!isObject && isArray) {
			const array: any[] = object as any as any[];
			for (let i: number = array.length - 1; i >= 0; i--) {

				//a. if array, sanitize it first
				const isObjectOrArray: boolean = this.utilBasic.isObject(array[i]) || this.utilBasic.isArray(array[i]);
				if (options.runRecursively && isObjectOrArray) this.sanitizeObjectAlgorithm(array[i], options);

				//b. sanitize the values if possible, remove empty values
				for (const removeValue of options.removeValues) {
					if (this.utilBasic.isEqual(array[i], removeValue)) array.splice(i, 1);
				}
			}
		}

		return object;
	}
}


//** Interfaces --------------------------------- */
interface IUtilSanitizeAssignOptions {
	removeValues	: any[];
	runRecursively	: boolean;
}

interface IUtilBasic {
	isObject<T>(value: T): boolean;
	isArray<T>(value: T): boolean;
	isEqual<T1, T2>(a: T1, b: T2): boolean;
}
