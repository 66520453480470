import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_FR: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'fr_FR'
		}
	},
	{
		// https://www.amazon.fr/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&hidden-keywords=%22+Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester%22+%2BT-Shirt+-Col+-Raglan
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR',
			'hidden-keywords': '%22+Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester%22+%2BT-Shirt+-Col+-Raglan' // cspell:disable-line
		}
	},
	/* {
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameter 	: {
			language	: 'fr_FR',
		},
	}, */
	{
		// https://www.amazon.fr/s?k=dog&hidden-keywords=Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester+Shirt+-D%C3%A9bardeur+Col
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR',
			'hidden-keywords': 'Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester+Shirt+-D%C3%A9bardeur+Col' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.fr/s?k=dog&hidden-keywords=Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester+D%C3%A9bardeur
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR',
			'hidden-keywords': 'Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester+D%C3%A9bardeur' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.fr/s?k=dog&hidden-keywords=Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester+Manche+Longue+-Raglan
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR',
			'hidden-keywords': 'Couleurs+unies%3A+100%25+Coton%3B+Gris+chin%C3%A9%3A+90%25+Coton%2C+10%25+Polyester%3B+Autres+couleurs+chin%C3%A9es%3A+50%25+Coton%2C+50%25+Polyester+Manche+Longue+-Raglan' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.fr/s?k=dog+raglon
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR'
		},
		queryOptions: {
			postfix		: '+raglon', // cspell:disable-line
			prefix		: ''
		}
	},
	{
		// https://www.amazon.fr/s?k=dog&hidden-keywords=Couleur+unies%3A+80%25+Coton%2C+20%25+Polyester%3B+Gris+chin%C3%A9%3A+78%25+Coton%2C+22%25+Poly%3B+Couleur+sombre+chin%C3%A9e%3A+50%25+Coton%2C+50%25+Polyester+Sweatshirt+-Capuche
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR',
			'hidden-keywords': 'Couleur+unies%3A+80%25+Coton%2C+20%25+Polyester%3B+Gris+chin%C3%A9%3A+78%25+Coton%2C+22%25+Poly%3B+Couleur+sombre+chin%C3%A9e%3A+50%25+Coton%2C+50%25+Polyester+Sweatshirt+-Capuche' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.fr/s?k=dog&hidden-keywords=Couleurs+unies%3A+80%25+Coton%2C+20%25+Polyester%3B+Gris+chin%C3%A9%3A+78%25+Coton%2C+22%25+Poly%3B+Couleur+sombre+chin%C3%A9e%3A+50%25+Coton%2C+50%25+Polyester+Sweat+Capuche
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			i			: 'fashion',
			language	: 'fr_FR',
			'hidden-keywords': 'Couleurs+unies%3A+80%25+Coton%2C+20%25+Polyester%3B+Gris+chin%C3%A9%3A+78%25+Coton%2C+22%25+Poly%3B+Couleur+sombre+chin%C3%A9e%3A+50%25+Coton%2C+50%25+Polyester+Sweat+Capuche' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.fr/s?k=dog+PopSockets+Support+et+Grip+pour+Smartphones+et+Tablettes
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			i			: 'electronics',
			language	: 'fr_FR'
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+PopSockets+Support+et+Grip+pour+Smartphones+et+Tablettes'
		}
	}
	/* {
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameter 	: {
			language	: 'fr_FR',
		},
	},
	{
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameter 	: {
			language	: 'fr_FR',
		},
	},
	{
		code		: EnumAmazonProduct.ThrowPillows,
		name		: 'Throw Pillows',
		parameter 	: {
			language	: 'fr_FR',
		},
	},
	{
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameter 	: {
			language	: 'fr_FR',
		},
	}, */
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_FR: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.FR,
	websiteUrl		: {
		home		: 'https://www.amazon.fr/',
		login		: 'https://www.amazon.fr/ap/signin?openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=frflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',
		search		: 'https://www.amazon.fr/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_FR,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
