/**------------------------------------------------------
 * Metric System and Conversion Type Definitions
 */
export enum EnumSystem {
	Metric	 	  	= 'metric',
	Imperial 	  	= 'imperial',
	International 	= 'international',
	Bits 		  	= 'bits',
	Bytes 		  	= 'bytes'
}

export enum EnumType {
	Length	 		= 'length',
	Mass	 		= 'mass',
	Temperature 	= 'temperature',
	Time			= 'time',
	Speed			= 'speed',
	Digital			= 'digital'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IUnitRecord {
	code		: string;
	type		: string;
	system		: string;
	name		: string;
	namePlural	: string;
	symbol		: string;
	toBase		: number;
	toSystem   ?: IUnitSystemConvert;
}

export interface IUnitSystemConvert {
	toCode		: string;
	toType		: string;
	toSystem	: string;
	convert		: (arg0: number) => number;
}
