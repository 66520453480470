import { animate, animation, AnimationReferenceMetadata, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

import { INgAnimationOptions } from '../shared/state-animation.interface';
import { useAnimationIncludingChildren } from '../shared/state-animation.helper';


/**------------------------------------------------------
 * Animation Definition
 */
function fadeInRight(): AnimationReferenceMetadata {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({
					visibility 	: 'visible',
					opacity 	: 0,
					transform 	: 'translate3d({{translate}}, 0, 0)',
					easing 		: 'ease',
					offset 		: 0
				}),
				style({
					opacity 	: 1,
					transform 	: 'translate3d(0, 0, 0)',
					easing 		: 'ease',
					offset 		: 1
				})
			])
		)
	]);
}


/**------------------------------------------------------
 * Configuration
 */
const NG_DEFAULT_DURATION: number = 1000;


/**------------------------------------------------------
 * FadeInRight Animation On State
 */
export function fadeInRightAnimation(options?: INgFadeInRightAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeInRight', [
		transition(
			'0 => 1',
			[
				style({ visibility: 'hidden' }),
				...useAnimationIncludingChildren(fadeInRight(), options)
			],
			{
				params: {
					delay 	 : options?.delay 	  || 0,
					duration : options?.duration  || NG_DEFAULT_DURATION,
					translate: options?.translate || '100%'
				}
			}
		)
	]);
}


/**------------------------------------------------------
 * FadeInRight Animation On Enter
 */
export function fadeInRightOnEnterAnimation(options?: INgFadeInRightAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeInRightOnEnter', [
		transition(
			':enter',
			[
				style({ visibility: 'hidden' }),
				...useAnimationIncludingChildren(fadeInRight(), options)
			],
			{
				params: {
					delay 	 : options?.delay 	  || 0,
					duration : options?.duration  || NG_DEFAULT_DURATION,
					translate: options?.translate || '100%'
				}
			}
		)
	]);
}


//** Interfaces --------------------------------- */
export interface INgFadeInRightAnimationOptions extends INgAnimationOptions {

	/**------------------------------------------------------
	 * Translate, possible units: px, %, em, rem, vw, vh
	 *
	 * Default: 2000px
	 */
	translate?: string;
}
