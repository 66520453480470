import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AddonTapfiliateService } from '@domains/frontend/page-addons';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class AffiliateGuard {

	constructor(
		private tapfiliateService	: AddonTapfiliateService,
		private router				: Router
	) {}


	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		//0 - get the parameters
		const ref 	  : string = next.queryParamMap.get('ref');
		const coupon  : string = next.queryParamMap.get('coupon');
		const checkout: string = next.queryParamMap.get('checkout');
		const forward : string = next.queryParamMap.get('forward');

		//1 - set the affiliate code for tapfiliate
		if (ref) this.tapfiliateService.detect(ref);

		//2 - check if user should be forwarded
		if (forward) {
			const containsHttp: boolean = forward.includes('https') || forward.includes('http');
			window.location.href = containsHttp ? forward : `http://${forward}`;
			return false;
		}

		//3 - check if any store related params are set, if so forward to store
		if (!state.url.includes('/store') && (ref || coupon || checkout)) {
			this.router.navigate(['/', 'store'], { queryParams: { ref: ref, coupon: coupon, checkout: checkout } });
			return true;
		}

		//4 - path is valid and can be opened
		return true;
	}
}
