// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Asymmetric Encryption Algorithms
 * --------------------------------
 * > Online RSA Key Generator1: https://travistidwell.com/jsencrypt/demo/
 * > Online RSA Key Generator2: https://cryptotools.net/rsagen
 * > Online RSA Encryption	  : https://8gwifi.org/rsafunctions.jsp
 * > Online RSA Signature 	  : https://8gwifi.org/rsasignverifyfunctions.jsp
 * > node forge example		  : https://github.com/digitalbazaar/forge#rsa
 * > node forge npm			  : https://www.npmjs.com/package/node-forge
 * > regex check for keys	  : https://stackoverflow.com/questions/37230706/getting-public-key-format-in-javascript
 * > RSA encryption schemes   : https://www.cryptosys.net/pki/manpki/pki_rsaschemes.html
 */
export class CryptoAsymmetricEncryption {

	//** Configurations */
	private readonly CHECK_RSA_PUBLIC_KEY_PEM : RegExp = /^(?:\s*-----BEGIN RSA PUBLIC KEY-----\s*)([A-Za-z0-9+/=\s]+)(?:\s*-----END RSA PUBLIC KEY-----\s*)$/;
	private readonly CHECK_RSA_PRIVATE_KEY_PEM: RegExp = /^(?:\s*-----BEGIN RSA PRIVATE KEY-----\s*)([A-Za-z0-9+/=\s]+)(?:\s*-----END RSA PRIVATE KEY-----\s*)$/;


	/**------------------------------------------------------
	 * RSA Encryption (RSA OAEP, with SHA-256)
	 * ----------------------–----------------
	 * > Online RSA Encryption: https://8gwifi.org/rsafunctions.jsp
	 * > Compatible with Java's RSA/ECB/OAEPWithSHA-256AndMGF1Padding
	 * > Decrypt data with a private key using RSAES-OAEP/SHA-256/MGF1-SHA-1
	 * ----------------------–----------------
	 * > Note: this can not handle special chars, use base64 for special chars
	 */
	encryptRsa(data: string, publicKeyPem: string): string {

		//0 - encrypt the data (with RSA/ECB/OAEPWithSHA-256AndMGF1Padding)
		const publicKey: forge.pki.rsa.PublicKey = this.publicKeyFromPem(publicKeyPem);
		const encrypted: string = publicKey.encrypt(forge.util.encodeUtf8(data), 'RSA-OAEP', {
			md		: forge.md.sha256.create(),
			mgf1	: {
				md	: forge.md.sha1.create()
			}
		});

		//1 - convert to base 64
		const encryptedBase64: string = forge.util.encode64(encrypted);
		return encryptedBase64;
	}

	decryptRsa(encryptedDataBase64: string, privateKeyPem: string): string {

		//0 - decrypt the data (with RSA/ECB/OAEPWithSHA-256AndMGF1Padding)
		const privateKey   : forge.pki.rsa.PrivateKey = this.privateKeyFromPem(privateKeyPem);
		const encryptedData: string = forge.util.decode64(encryptedDataBase64);
		const decrypted	   : string = privateKey.decrypt(encryptedData, 'RSA-OAEP', {
			md		: forge.md.sha256.create(),
			mgf1	: {
				md	: forge.md.sha1.create()
			}
		});

		//1 - return the decrypted data
		return decrypted;
	}


	/**------------------------------------------------------
	 * Check RSA Pem Keys
	 */
	publicKeyPemBitLength(publicKeyPem: string): number {
		const publicKey: forge.pki.rsa.PublicKey = this.publicKeyFromPem(publicKeyPem);
		const keyLength: number = publicKey.n.bitLength();
		return keyLength;
	}

	privateKeyPemBitLength(privateKeyPem: string): number {
		const privateKey: forge.pki.rsa.PrivateKey = this.privateKeyFromPem(privateKeyPem);
		const keyLength: number = privateKey.n.bitLength();
		return keyLength;
	}

	isKeyPemPairValid(privateKeyPem: string, publicKeyPem: string): boolean {

		try {
			//0 - are the key sizes the same
			if (this.privateKeyPemBitLength(privateKeyPem) !== this.publicKeyPemBitLength(publicKeyPem)) return false;

			//1 - can we encrypt and decrypt with the key pair
			const plainText    : string = Util.Random.randomAlphanumericString(20);
			const encryptedText: string = this.encryptRsa(plainText, publicKeyPem);
			const decryptedText: string = this.decryptRsa(encryptedText, privateKeyPem);
			if (decryptedText !== plainText) return false;

			//2 - key pair belongs together
			return true;
		} catch (error) {
			return false;
		}
	}


	/**------------------------------------------------------
	 * Encryption Key Helpers
	 */
	protected publicKeyFromPem(publicKeyPem: string): forge.pki.rsa.PublicKey {

		//0 - check if the key is valid
		const cleanedKeyPem		: string  = publicKeyPem.replace(/[\n\t]/g, '');
		const isStartAndEndValid: boolean = cleanedKeyPem.trim().startsWith('-----BEGIN RSA PUBLIC KEY-----') && cleanedKeyPem.endsWith('-----END RSA PUBLIC KEY-----');
		if (!isStartAndEndValid) throw new Error(`AsymmetricEncryption => publicKeyFromPem => FATAL ERROR: invalid RSA public key PEM format, expected format: "-----BEGIN RSA PUBLIC KEY----- ... -----END RSA PUBLIC KEY-----"`);
		if (!this.CHECK_RSA_PUBLIC_KEY_PEM.test(cleanedKeyPem)) throw new Error(`AsymmetricEncryption => publicKeyFromPem => FATAL ERROR: invalid RSA public key PEM format.`);

		//1 - try to create the private public key
		const publicKey: forge.pki.rsa.PublicKey = forge.pki.publicKeyFromPem(cleanedKeyPem);
		if (!publicKey) throw new Error(`AsymmetricEncryption => publicKeyFromPem => FATAL ERROR: failed to generate the public key, make sure that the "publicKeyPem" is correct`);
		return publicKey;
	}

	protected privateKeyFromPem(privateKeyPem: string): forge.pki.rsa.PrivateKey {

		//0 - check if the key is valid
		const cleanedKeyPem		: string  = privateKeyPem.replace(/[\n\t]/g, '');
		const isStartAndEndValid: boolean = cleanedKeyPem.trim().startsWith('-----BEGIN RSA PRIVATE KEY-----') && cleanedKeyPem.endsWith('-----END RSA PRIVATE KEY-----');
		if (!isStartAndEndValid) throw new Error(`AsymmetricEncryption => privateKeyFromPem => FATAL ERROR: invalid RSA private key PEM format, expected format: "-----BEGIN RSA PRIVATE KEY----- ... -----END RSA PRIVATE KEY-----"`);
		if (!this.CHECK_RSA_PRIVATE_KEY_PEM.test(cleanedKeyPem)) throw new Error(`AsymmetricEncryption => privateKeyFromPem => FATAL ERROR: invalid RSA private key PEM format.`);

		//1 - try to create the public key
		const privateKey: forge.pki.rsa.PrivateKey = forge.pki.privateKeyFromPem(cleanedKeyPem);
		if (!privateKey) throw new Error(`AsymmetricEncryption => privateKeyFromPem => FATAL ERROR: failed to generate the private key, make sure that the "privateKeyPem" is correct`);
		return privateKey;
	}
}
