import { TemplateRef } from '@angular/core';
import { IKeyCode } from '@libs/libraries/frontend';

import { INgFaIcon } from '../../../styling-components';


/**------------------------------------------------------
 * Enums Definitions
 */
export enum EnumNgTemplateSize {
	Small		= 'sm',			// 'sm' size of ngb modal
	Medium		= 'md',			// 'md' size of ngb modal
	Large		= 'lg',			// 'lg' size of ngb modal
	ExtraLarge	= 'xl'			// 'xl' size of ngb modal
}


/**---------------------------------------------
 * Modal Style / Definition
 */
export interface INgTemplateModalConfig {
	style 		: {
		color	: string;
		icon	: INgFaIcon;
	};
	buttons		: {
		primary : {
			color 	 	 : string;
			actionKey	 : IKeyCode[];
		};
		secondary : {
			color 	 	 : string;
			actionKey	 : IKeyCode[];
		};
	};
}


/**---------------------------------------------
 * Modal Content
 */
export interface INgTemplateModalContent {
	text 		: INgTemplateModalText;
	options 	: INgTemplateModalOptions;
	buttons 	: INgTemplateModalButton[];				// all buttons over 2 will be added in the additional section of the modal
}

export interface INgTemplateModalText {
	title		 : string;
	templateRef	?: TemplateRef<any>;
	htmlString	?: string;
}

export interface INgTemplateModalOptions {
	size 		: EnumNgTemplateSize;
}

export interface INgTemplateModalButton {
	buttonText	 : string;
	actionCode	 : string;
	actionKey 	?: IKeyCode[];
}
