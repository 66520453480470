import { IValidatorError } from './validator-error.interface';


/**------------------------------------------------------
 * Operation Definitions
 * ---------------------
 * > Note: validation and transformation function have different results.
 * > A validation function returns "IValidationResult" in case of invalid
 * > data, in case of valid data "isValid" is set to "true".
 * > A transformation function returns the "update value" usually just
 * > changes the value.
 */
export enum EnumValidatorOperationType {
	Validate		= 'validate',
	Transform		= 'transform'
}

export interface IValidatorValidateOperation {
	type			: EnumValidatorOperationType.Validate;
	validateFn		: (value: any) => IValidatorValidationResult;
}

export interface IValidatorTransformOperation {
	type			: EnumValidatorOperationType.Transform;
	transformFn		: (value: any) => any;
}


/**------------------------------------------------------
 * Validation Result
 */
export interface IValidatorProcessResult {
	transformedValue: any;
	validationError	: IValidatorError | null;
}

export interface IValidatorValidationResult {
	isValid			: boolean;								// is the value valid
	stopVal 	   ?: boolean;
	error		  	: string;								// error messages of constraints that failed validation
	children       ?: IValidatorError[];
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypeValidatorTransformFn<T> = (value: T) => T;
export type TypeValidatorValidateFn<T>  = (value: T) => {
	isValid		: boolean;
	error		: string;
};


/**------------------------------------------------------
 * Angular Form Types
 * ------------------
 * > The validators can also be used in angular forms, but as this
 * > library is part of the utility, which is shared between frontend
 * > and backend, we must not use any angular imports or angular interfaces.
 * > For this reason we are imitating / redefining them.
 */
export interface IValidatorAngularAbstractControl {
	value	: any;
	setValue: (value: any) => void;
	// ... add other necessary fields/methods if needed
}

export type TypeValidatorAngularErrors = Record<string, any>;
export type TypeValidatorAngularFn 	   = (control: IValidatorAngularAbstractControl) => TypeValidatorAngularErrors | null;
