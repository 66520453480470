/**------------------------------------------------------
 * Template Renderer
 * -----------------
 * > This parser is responsible of parsing JavaScript / TS
 * > code into texts, it could be that html templates, or
 * > markdown files are enhanced with loops like for, if
 * > conditions, or variables.
 */
export class TemplateRenderer {

	//** Configurations */
	private readonly EJS_HTML_DELIMITERS		: RegExp = /<%(.+?)%>/gs;
	private readonly EJS_MARKDOWN_DELIMITERS	: RegExp = /<!--(.+?)-->/gs;

	private readonly HTML_DELIMITERS_REGEX		: RegExp = /<%|<%=|%>/;
	private readonly MARKDOWN_DELIMITERS_REGEX	: RegExp = /<!--|<!--=|-->/;


	parseHtml<T extends object>(params: ITemplateRendererParams<T>): string {
		return this.parse(params, this.EJS_HTML_DELIMITERS, this.HTML_DELIMITERS_REGEX);
	}

	parseMarkdown<T extends object>(params: ITemplateRendererParams<T>): string {
		return this.parse(params, this.EJS_MARKDOWN_DELIMITERS, this.MARKDOWN_DELIMITERS_REGEX);
	}


	/**------------------------------------------------------
	 * Template Parsing
	 */
	private parse<T extends object>(params: ITemplateRendererParams<T>, delimiters: RegExp, checkRemainingRegex: RegExp) {

		//0 - helper variables
		let cursor	: number = 0;
		let code	: string = 'with(context){\n';				// code string to be converted into a new function
		let lastText: string = '';

		//1 - Loop over the template string and extract EJS tags and plain text
		let match: RegExpExecArray | null = null;
		while ((match = delimiters.exec(params.template)) !== null) {

			//a. add plain text to the code string as a string literal
			lastText = params.template.slice(cursor, match.index);
			code += `_out+=\`${lastText}\`;\n`;

			//b. check if the EJS tag contains a JavaScript expression
			if (match[1].startsWith('=')) {
				code += `_out+=escapeValue(${match[1].slice(1)});\n`;	// add the JavaScript expression to the code string
			} else {
				code += `${match[1]};\n`;								// add the JavaScript code to the code string
			}

			//c. move the cursor to the end of the current EJS tag
			cursor = match.index + match[0].length;
		}

		//2 - add the remaining plain text to the code string
		lastText = params.template.slice(cursor);
		code += `_out+=\`${lastText}\`;\n`;
		code += '}\nreturn _out;';

		//3 - try to create a new js function from the code string
		let fn: Function = null!;
		try {
			// eslint-disable-next-line no-new-func, @typescript-eslint/no-implied-eval
			fn = new Function('context', 'escapeValue', '_out', code);
		} catch (error: any) {
			throw new Error(`TemplateRenderer => parse => FATAL ERROR: error at compiling template: ${error.message}`);
		}

		//4 - try to execute the compiled template
		try {
			//a. try to execute the compiled template function with the provided context
			const parsedHtml: string = fn(params.context, this.escapeValue, '');

			//b. are there any delimiters remaining
			const isDelimiterRemaining: boolean = checkRemainingRegex.test(parsedHtml);
			if (isDelimiterRemaining) throw new Error(`TemplateRenderer => parse => FATAL ERROR: delimiters are remaining in the parsed template. Please make sure to define template with the delimiters correctly. \n Current result, parsed: ${parsedHtml}`);

			//c. return the parsed result
			return parsedHtml;

		} catch (error: any) {
			throw new Error(`TemplateRenderer => parse => FATAL ERROR: error at executing template: ${error.message}`);
		}
	}

	private escapeValue = (value: string | number | boolean): string => {

		//0 - convert to string
		const text: string = `${value}`.trim();

		//1 - escape HTML special characters
		const escapedHtml: string = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
		return escapedHtml;
	};
}


//** Interfaces --------------------------------- */
interface ITemplateRendererParams<T extends object> {
	template	: string;
	context		: T;
}
