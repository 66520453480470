import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Util } from '@libs/utilities/util';

import { NgConformModalComponent } from './conform-modal.component';
import { NG_PRE_DEFINED_CONFORM_MODALS } from './data/conform-modal-config.data';
import { INgConformModalButton, INgConformModalContent } from './conform-modal.interface';
import { EnumNgConfirmModalTypes, INgConformModalConfig } from './data/conform-modal-config.interface';


@Injectable()
export class NgConformModalAssistant {

	//** Configurations */
	private readonly DEFAULT_MODAL_CONFIG: NgbModalOptions = {
		centered	: true,
		keyboard	: false,
		backdrop	: 'static'
	};

	constructor(
		private modalService: NgbModal
	) {}


	/**----------------------------------------------------------
	 * Open Confirm Modal
	 */
	showDefaultConfirm(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.DefaultConfirm); }
	showDefaultConfirmSimple(title: string, message: string): NgConformModalComponent { return this.showDefaultConfirm({ title: title, message: message }); }

	//** Info */
	showInfoConfirm(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.InfoConfirm); }
	showInfoConfirmSimple(title: string, message: string)	: NgConformModalComponent { return this.showInfoConfirm({ title: title, message: message }); }

	//** Success */
	showSuccessConfirm(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.SuccessConfirm); }
	showSuccessConfirmSimple(title: string, message: string): NgConformModalComponent { return this.showSuccessConfirm({ title: title, message: message }); }

	//** Warning */
	showWarningConfirm(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.WarningConfirm); }
	showWarningConfirmSimple(title: string, message: string): NgConformModalComponent { return this.showWarningConfirm({ title: title, message: message }); }

	//** Error */
	showErrorConfirm(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.ErrorConfirm); }
	showErrorConfirmSimple(title: string, message: string)	: NgConformModalComponent { return this.showErrorConfirm({ title: title, message: message }); }


	/**----------------------------------------------------------
	 * Open Option Modal
	 */
	showDefaultOption(content: INgConformModalContent) 		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.DefaultOption); }
	showDefaultOptionSimple(title: string, message: string) : NgConformModalComponent { return this.showDefaultOption({ title: title, message: message }); }

	//** Info */
	showInfoOption(content: INgConformModalContent)			: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.InfoOption); }
	showInfoOptionSimple(title: string, message: string)	: NgConformModalComponent { return this.showInfoOption({ title: title, message: message }); }

	//** Success */
	showSuccessOption(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.SuccessOption); }
	showSuccessOptionSimple(title: string, message: string)	: NgConformModalComponent { return this.showSuccessOption({ title: title, message: message }); }

	//** Warning */
	showWarningOption(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.WarningOption); }
	showWarningOptionSimple(title: string, message: string)	: NgConformModalComponent { return this.showWarningOption({ title: title, message: message }); }

	//** Error */
	showErrorOption(content: INgConformModalContent)		: NgConformModalComponent { return this.open(content, EnumNgConfirmModalTypes.ErrorOption); }
	showErrorOptionSimple(title: string, message: string)	: NgConformModalComponent { return this.showErrorOption({ title: title, message: message }); }


	/**----------------------------------------------------------
	 * Handel Modal
	 */
	private open(modalContent: INgConformModalContent, modalType: EnumNgConfirmModalTypes): NgConformModalComponent {

		//0 - get the configuration
		const modalConfig: INgConformModalConfig = NG_PRE_DEFINED_CONFORM_MODALS[modalType];
		if (!modalConfig) throw new Error(`NgConformModalAssistant => open => FATAL ERROR: no configuration found for modalType of "${modalType}"`);
		this.checkConfiguration(modalConfig, modalContent);

		//1 - open the modal
		const modalRef: NgbModalRef = this.modalService.open(NgConformModalComponent, this.DEFAULT_MODAL_CONFIG);
		modalRef.componentInstance.modalConfig 	= modalConfig;
		modalRef.componentInstance.modalContent = modalContent;
		modalRef.componentInstance.modalRef 	= modalRef;

		//3 - return the component instance
		return modalRef.componentInstance;
	}

	private checkConfiguration(modalConfig: INgConformModalConfig, modalContent: INgConformModalContent) {

		//0 - check if title or message is empty
		if (Util.String.isEmpty(modalContent.title)) 	throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a title`);
		if (Util.String.isEmpty(modalContent.message))  throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a message`);

		//1 - check color and icon
		if (!modalConfig.style.color || !modalConfig.style.icon) throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a style.color or style.icon`);

		//2 - check the buttons
		if (!modalConfig.buttons?.length) throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a button`);
		for (let i: number = 0; i < modalConfig.buttons.length; i++) {
			const button: INgConformModalButton = modalConfig.buttons[i];
			if (!button.actionCode) throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a button ${i + 1} actionCode`);
			if (!button.buttonText) throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a button ${i + 1} buttonText`);
			if (!button.color) 		throw new Error(`NgConformModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a button ${i + 1} color`);
		}
	}
}
