import { TypeCompareFn, defaultCompareFn } from '@libs/constants';


/**------------------------------------------------------
 * Array Grouping Utilities
 * ------------------------
 * > Info: Util for grouping array elements by a compare
 * > together. Furthermore functionalities are provided to
 * > work with these groupings.
 */
export class UtilArrayGroup {


	/**------------------------------------------------------
	 * Group and Unwind
	 */
	group<T>(array: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): Array<T[]> {

		//0 - group the values
		const groups: Array<T[]> = [];
		for (const item of array) {

			//a. does a group already exist? add it to existing group
			const group: T[] = groups.find((groupElem: T[]) => compareFn(groupElem[0], item))!;
			if (group) {
				group.push(item);
				continue;
			}

			//b. if no group exist, create a new one
			const newGroup: T[] = [item];
			groups.push(newGroup);
		}

		//1 - return the grouped values
		return groups;
	}

	unwindGroup<T>(groups: Array<T[]>): T[] {
		const array: T[] = [];
		for (const group of groups) array.push(...group);
		return array;
	}


	/**------------------------------------------------------
	 * Sorting Functions
	 */
	sortByOccurrenceAsc<T>(array: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): T[] {

		//0 - group same elements into groups
		const groups: Array<T[]> = this.group(array, compareFn);

		//1 - sort the groups
		this.sortGroupsAsc(groups);

		//2 - convert the groups back to an array
		const sortedArray: T[] = this.unwindGroup(groups);
		return sortedArray;
	}

	sortByOccurrenceDesc<T>(array: T[], compareFn: TypeCompareFn<T> = defaultCompareFn): T[] {
		return this.sortByOccurrenceAsc(array, compareFn).reverse();
	}

	sortGroupsAsc<T>(groups: Array<T[]>): Array<T[]> {
		groups.sort((a: T[], b: T[]) => a.length - b.length);	// (ASC  -> a.length - b.length)
		return groups;
	}

	sortGroupsDesc<T>(groups: Array<T[]>): Array<T[]> {
		return this.sortGroupsAsc(groups).reverse();			// (DESC -> b.length - a.length)
	}
}
