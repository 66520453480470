import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumAppInfoTypes } from '@apps/flying/shared';

import { AppInfoRequest, INews, IResearchInfo, IRoadMap, IVersion } from '../../../../requests/shared/app-info/app-info.request';


/**------------------------------------------------------
 * News & Info Management
 */
export class NewsStateHelper {

	constructor(
		private appInfoRequest: AppInfoRequest
	) {}


	/**------------------------------------------------------
	 * Info Helper
	 */
	getResearchInfo(): Observable<IResearchInfo> {
		return this.appInfoRequest.getAppInfo(EnumAppInfoTypes.HomeInfo) as Observable<IResearchInfo>;
	}

	getNews(): Observable<INews[]> {
		return this.getResearchInfo().pipe(map((response: IResearchInfo) => response.news));
	}

	getVersion(): Observable<IVersion[]> {
		return this.getResearchInfo().pipe(map((response: IResearchInfo) => response.version));
	}

	getRoadMap(): Observable<IRoadMap[]> {
		return this.getResearchInfo().pipe(map((response: IResearchInfo) => response.roadmap));
	}
}
