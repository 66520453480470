import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { ITmFilterOptions, TmFilterOptionsSchema } from './shared/filter-options.interface';


/**------------------------------------------------------
 * Configurations
 */
export const TM_APPLICANT_LIMITS: ITmApplicantConfig = {
	SEARCH_TEXT_LENGTH	: 500
};

interface ITmApplicantConfig {
	SEARCH_TEXT_LENGTH	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumTmApplicantType {
	Is				= 'is',						// should be exact match to the word
	BeginsWith		= 'begins',
	EndsWith		= 'ends',
	Contains		= 'contains'				// should contain the exact word
}


/**------------------------------------------------------
 * Applicant Request
 */
export interface ITmApplicantRequest extends ITmFilterOptions {
	searchType		: EnumTmApplicantType;		// has to be a valid search type
	searchText		: string;					// can not be empty
}


/**------------------------------------------------------
 * Applicant Response
 */
export interface ITmApplicantResponse {
	entries			: string[];
	totalCount		: number;
}


/**------------------------------------------------------
 * Schemas
 */
export class TmApplicantRequestSchema extends TmFilterOptionsSchema implements ValidatorSchema<ITmApplicantRequest> {
	searchType		= Validator.Enum(EnumTmApplicantType);
	searchText		= Validator.String().isNotEmpty().isMaxLength(TM_APPLICANT_LIMITS.SEARCH_TEXT_LENGTH).hasNoHtmlScripts();
}
