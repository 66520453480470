/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumMessageType {
	Progress 	= 'worker_progress',
	Finish 		= 'worker_finish'
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypeProgressCallback = (progress: number) => void;


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IWorkerMessage {
	type 		: EnumMessageType;
	data		: unknown;
}


/**------------------------------------------------------
 * Post Response Message
 */
export function postProgress(progress: number): void {
	postMessage({ type: EnumMessageType.Progress, data: progress } as IWorkerMessage);
}

export function postFinish<T>(data: T): void {
	postMessage({ type: EnumMessageType.Finish, data } as IWorkerMessage);
}
