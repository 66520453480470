import { IFineArtAmericaTemplateOptions } from './platform-templates/fine-art-america-template.interface';
import { IPrintfulTemplateOptions } from './platform-templates/printful-template.interface';
import { IPrintifyTemplateOptions } from './platform-templates/printify-template.interface';
import { IRedbubbleTemplateOptions } from './platform-templates/redbubble-template.interface';
import { IShirteeTemplateOptions } from './platform-templates/shirtee-template.interface';
import { ISociety6TemplateOptions } from './platform-templates/society6-template.interface';
import { ISpreadshirtTemplateOptions } from './platform-templates/spreadshirt-template.interface';
import { ITeepublicTemplateOptions } from './platform-templates/teepublic-template.interface';
import { ITeespringTemplateOptions } from './platform-templates/teespring-template.interface';
import { ITostadoraTemplateOptions } from './platform-templates/tostadora-template.interface';
import { IZazzleTemplateOptions } from './platform-templates/zazzle-template.interface';
import { EnumPlatform } from '../../app-constants/platforms/print-on-demand.constants';


/**------------------------------------------------------
 * Template Blueprint
 */
export interface IPlatformTemplate<T> {
	name        	: string;				// 'mySpreadshirt',
	description 	: string;				// 'My main spreadshirt account with ...',
	options 		: T;
}


/**------------------------------------------------------
 * Platform Templates
 * ------------------
 * > Mba and Displate do not have templates.
 */
export interface IPrintfulTemplate 			extends IPlatformTemplate<IPrintfulTemplateOptions> 		{ platform: EnumPlatform.Printful }
export interface IPrintifyTemplate 			extends IPlatformTemplate<IPrintifyTemplateOptions> 		{ platform: EnumPlatform.Printify }
export interface IRedbubbleTemplate 		extends IPlatformTemplate<IRedbubbleTemplateOptions> 		{ platform: EnumPlatform.Redbubble }
export interface IShirteeTemplate 			extends IPlatformTemplate<IShirteeTemplateOptions> 			{ platform: EnumPlatform.Shirtee }
export interface ISociety6Template 			extends IPlatformTemplate<ISociety6TemplateOptions> 		{ platform: EnumPlatform.Society6 }
export interface ITeepublicTemplate 		extends IPlatformTemplate<ITeepublicTemplateOptions> 		{ platform: EnumPlatform.Teepublic }
export interface ITeespringTemplate 		extends IPlatformTemplate<ITeespringTemplateOptions> 		{ platform: EnumPlatform.Teespring }
export interface ITostadoraTemplate 		extends IPlatformTemplate<ITostadoraTemplateOptions> 		{ platform: EnumPlatform.Tostadora }
export interface IZazzleTemplate 			extends IPlatformTemplate<IZazzleTemplateOptions> 			{ platform: EnumPlatform.Zazzle }
export interface IFineArtAmericaTemplate 	extends IPlatformTemplate<IFineArtAmericaTemplateOptions> 	{ platform: EnumPlatform.FineArtAmerica }
export interface ISpreadshirtTemplate 		extends IPlatformTemplate<ISpreadshirtTemplateOptions> 		{ platform: EnumPlatform.SpreadshirtEU | EnumPlatform.SpreadshirtUS }


/**------------------------------------------------------
 * Types
 */
export type TypePlatformTemplate = IPrintfulTemplate | IPrintifyTemplate | IRedbubbleTemplate | IShirteeTemplate | ISociety6Template
	| ITeepublicTemplate | ITeespringTemplate | ITostadoraTemplate | IZazzleTemplate | IFineArtAmericaTemplate | ISpreadshirtTemplate;
