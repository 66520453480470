/**------------------------------------------------------
 * Piping Utilities
 * ----------------
 * > Helper for piping like RxJs does
 */
export class UtilPipeline {

	//** Chains functions together, applying them sequentially to an input */
	pipe<T>(value: T, ...functions: TypeUtilPipelineFn<T>[]): T {
		return functions.reduce((acc: T, fn: TypeUtilPipelineFn<T>) => fn(acc), value);
	}

	//** Similar to pipe, but applies the functions in reverse order */
	compose<T>(value: T, ...functions: TypeUtilPipelineFn<T>[]): T {
		return functions.reduceRight((acc: T, fn: TypeUtilPipelineFn<T>) => fn(acc), value);
	}

	//** Applies a side effect without changing the value */
	tap<T>(value: T, fn: (value: T) => void): T {
		fn(value);				// Useful for logging or other non-transformative actions.
		return value;
	}

	//** Apply different functions based on a condition */
	branch<T>(value: T, functions: { predicate: (value: T) => boolean; ifTrue: TypeUtilPipelineFn<T>; ifFalse: TypeUtilPipelineFn<T> }): T {
		return functions.predicate(value)
			? functions.ifTrue(value)
			: functions.ifFalse(value);
	}

	//** Cache the results of a function to avoid redundant computations */
	memoize<T, R>(fn: (value: T) => R): (value: T) => R {

		//0 - create the cache
		const cache: Map<T, R> = new Map<T, R>();

		//1 - create the memorize wrapped function
		return (value: T) => {

			//a. is the value already computed?
			if (cache.has(value)) return cache.get(value)!;

			//b. compute and cache it
			const result: R = fn(value);
			cache.set(value, result);
			return result;
		};
	}
}


//** Types -------------------------------------- */
type TypeUtilPipelineFn<T> = (value: T) => T;
