/**------------------------------------------------------
 * Markdown Parser
 * ---------------
 * > Parses a markdown to html, using internally the library
 * > "marked". It presets certain configuration for executing
 * > the parsing to match our standard.
 */
export * from './markdown-parser/markdown-parser';


/**------------------------------------------------------
 * Template Renderer
 * -----------------
 * > A custom implementation inspired by "ejs html parsing".
 * > It takes in html or markdown files with special delimiters
 * > and context variables. The renderer can then parse it,
 * > or in other words replace the variables with the real values.
 */
export * from './template-renderer/template-renderer';
