import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_DEFAULT_OPTIONS } from '../amazon-search.data';
import { AbstractAmazonSearch } from '../helpers/amazon-search.abstract';
import { AMAZON_CONFIG_PL } from './amazon-pl.data';
import { EnumAmazonSorting, EnumAmazonProduct, EnumAmazonMarketplace } from '../../../../../../constants/research/app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Amazon PL Search Links
 */
export class AmazonPlSearch extends AbstractAmazonSearch {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumAmazonSorting>[] {
		return AMAZON_CONFIG_PL.sortings as ISearchParameterConfig<EnumAmazonSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumAmazonProduct>[] {
		return AMAZON_CONFIG_PL.products as ISearchParameterConfig<EnumAmazonProduct>[];
	}

	constructor() {
		super(EnumAmazonMarketplace.PL, AMAZON_CONFIG_PL, AMAZON_DEFAULT_OPTIONS);
	}
}
