/**------------------------------------------------------
 * Print On Demand Platforms
 */
export enum EnumPodPlatform {
	All 		= 'all_platforms',
	Amazon      = 'amazon',
	Spreadshirt = 'spreadshirt',
	Redbubble   = 'redbubble',
	Displate    = 'displate',
	MBA      	= 'mba',
	KDP      	= 'kdp'
}


/**------------------------------------------------------
 * Marketplaces for POD
 */
export enum EnumPodMarketplace {
	All 		= 'all_markets',
	COM 		= 'com',
	CO_UK 		= 'co_uk',
	DE 			= 'de',
	FR 			= 'fr',
	IT 			= 'it',
	ES 			= 'es'
}


/**------------------------------------------------------
 * Categories
 */
export enum EnumPodCategory {
	Relevance 	= 'relevance',
	Newest   	= 'newest'
}


/**------------------------------------------------------
 * Mapping
 */
// eslint-disable-next-line @typescript-eslint/typedef
export const POD_PLATFORMS_MAPPING = {
	[EnumPodPlatform.All]			: 'All Platforms',
	[EnumPodPlatform.Amazon]		: 'Amazon',
	[EnumPodPlatform.Spreadshirt]	: 'Spreadshirt',
	[EnumPodPlatform.Redbubble]		: 'Redbubble',
	[EnumPodPlatform.Displate]		: 'Displate',
	[EnumPodPlatform.MBA]			: 'Merch By Amazon',
	[EnumPodPlatform.KDP]			: 'Kindle Direct Publishing',

	[EnumPodMarketplace.All]		: 'All Marketplaces',
	[EnumPodMarketplace.COM]		: 'COM - United States',
	[EnumPodMarketplace.CO_UK]		: 'CO.UK - United Kingdom',
	[EnumPodMarketplace.DE]			: 'DE - Germany',
	[EnumPodMarketplace.FR]			: 'FR - France',
	[EnumPodMarketplace.IT]			: 'IT - Italy',
	[EnumPodMarketplace.ES]			: 'ES - Spain',

	[EnumPodCategory.Relevance]		: 'Relevance',
	[EnumPodCategory.Newest]		: 'Newest'
};
