import { Util } from '@libs/utilities/util';

import { TypeCsvRowData, TypeCsvArray, TypeStringMap } from './csv-handler.interface';


/**------------------------------------------------------
 * CSV Data Handler
 * ----------------
 * > source (arrayToCSV)  : https://decipher.dev/30-seconds-of-typescript/docs/arrayToCSV
 * > source (CSVToArray)  : https://decipher.dev/30-seconds-of-typescript/docs/CSVToArray
 * > source (JSONtoCSV)   : https://decipher.dev/30-seconds-of-typescript/docs/JSONtoCSV
 * > source (CSVToJSON)   : https://decipher.dev/30-seconds-of-typescript/docs/CSVToJSON
 * > source (downloadCSV) : https://decipher.dev/30-seconds-of-typescript/docs/downloadCSV
 */
export class CsvHandlerService {


	/**------------------------------------------------------
	 * Convert Array <=> CSV
	 */

	//** Converts a 2D array to a comma-separated values (CSV) string. */
	arrayToCsv(array: TypeCsvArray, delimiter: string = ';'): string {

		//0 - convert to csv data
		const csvData: string = array.map((value: (string | number)[]) => {

			//a. convert the value to string
			const convertedValue: string = value
				.map((x: string | number) => (typeof x === 'string' ? `"${x.replace(/"/g, '""')}"` : x))
				.join(delimiter);

			//b. use the converted value to combine the complete data
			return convertedValue;
		}).join('\n');

		//1 - return the csv data
		return csvData;
	}

	//** Converts a comma-separated values (CSV) string to a 2D array. */
	csvToArray(csvData: string, delimiter: string = ';', omitFirstRow: boolean = false): TypeCsvArray {
		return csvData.slice(omitFirstRow ? csvData.indexOf('\n') + 1 : 0)
			.split('\n')
			.map((v: string) => v.split(delimiter));
	}


	/**------------------------------------------------------
	 * Convert Json <=> CSV
	 */
	jsonToCsv(columns: string[], dataArray: TypeCsvRowData[], delimiter: string = ';'): string {

		//0 - create the column names
		const columnNames: string = columns.join(delimiter);

		//1 - create the data rows
		const dataRows: string[] = dataArray.map((object: TypeCsvRowData) => {
			return columns.reduce((acc: string, key: string) => `${acc}${!acc.length ? '' : delimiter}'${!object[key] ? '' : object[key]}'`, '');
		});

		//2 - combine the headers and the data
		const csvData: string = [
			columnNames,
			...dataRows
		].join('\n');

		//3 - return the csv data string
		return csvData;
	}

	csvToJson(csvData: string, delimiter: string = ';'): TypeCsvRowData[] {

		//0 - extract the column names
		const columnNames: string[] = csvData.slice(0, csvData.indexOf('\n')).split(delimiter);

		//1 - extract the row data
		const csvDataWithoutFirstRow: string = csvData.slice(csvData.indexOf('\n') + 1);
		const rowData: TypeCsvRowData[] = csvDataWithoutFirstRow.split('\n').map((value: string) => {

			//a. get the row values
			const values: string[] = value.split(delimiter);

			//b. combine values into json object format
			return columnNames.reduce<TypeStringMap>(
				(object: TypeStringMap, columnName: string, index: number) => {
					object[columnName] = values[index];
					return object;
				}, {}
			);
		});

		//2 - return the row data
		return rowData;
	}


	/**------------------------------------------------------
	 * Download CSV
	 */
	downloadCsv(csvContent: any, fileName: string = 'download.csv'): void {

		//0 - check the input parameters
		if (Util.String.isEmpty(csvContent)) throw new Error(`CsvHandlerService => downloadCSV => FATAL ERROR: provided csvContent is empty`);
		if (!fileName.includes('.csv')) 	 throw new Error(`CsvHandlerService => downloadCSV => FATAL ERROR: provided fileName does not end with ".csv" (fileName: "${fileName}")`);

		//1 - create a link as workaround for the download
		const link: HTMLAnchorElement = document.createElement('a');
		const blob: Blob = new Blob([csvContent], { type: 'text/csv' });
		link.setAttribute('href', window.URL.createObjectURL(blob));
		link.setAttribute('download', fileName);

		//2 - trigger the download, and remove element from dom
		document.body.appendChild(link); // Required for FF
		link.click();

		//3 - remove element from dom
		link.remove();
	}
}
