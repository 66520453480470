<ngb-toast
	*ngIf="messageToast"
	[autohide]="messageToastConfig.autohide"
	[delay]="messageToastConfig.delay"
	[animation]="messageToastConfig.animation"
	[class]="messageToastType.className"
	(hidden)="onClose$.next()"
	[@fadeInRightOnEnter]
>
	<!-- close toast -->
	<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
	<span
		class="close-toast px-0"
		[style]="'background: var(' + messageToastType.color + ');'"
		(click)="onClose$.next()"
	>
		<ng-fa-icon
			class="d-inline-flex"
			icon="xmark"
		></ng-fa-icon>
	</span>

	<!-- show timing info -->
	<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
	<div
		class="toast-top-info-container"
		[style.color]="'var(' + messageToastType.color + ')'"
	>
		<small
			class="toast-right-top-info"
			*ngIf="remainingTime"
		>
			<ng-message-time
				[option]="EnumTimeOptions.remainingTime"
				[time]="remainingTime"
				(exactTimeOver)="exactTimeOver($event)"
			></ng-message-time>
		</small>
		<small
			class="toast-right-top-info"
			*ngIf="timeAgo"
		>
			<ng-message-time
				[option]="EnumTimeOptions.pastTime"
				[time]="timeAgo"
			></ng-message-time>
		</small>
	</div>

	<!-- show toast info -->
	<div class="toast-info-container">
		<!-- toast icon -->
		<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
		<div
			class="m-auto text-center icon-container"
			[style]="'color: var(' + messageToastType.color + ')'"
		>
			<ng-fa-icon
				class="d-inline-flex toast-icon-text-size"
				[icon]="messageToast.icon.name"
				[iconStyle]="messageToast.icon!.style"
			></ng-fa-icon>
		</div>

		<!-- toast message -->
		<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
		<div
			class="message-container"
			[style.color]="'var(' + messageToastType.color + ')'"
		>
			<div class="d-flex">
				<strong
					class="mb-0 text-truncate"
					[ngTooltip]="messageToast.title"
					onlyTruncate
				>
					{{ messageToast.title }}
				</strong>
			</div>
			<div
				class="text-truncate"
				[ngTooltip]="messageToast.message"
				onlyTruncate
			>
				{{ messageToast.message }}
			</div>
		</div>

		<!-- toast action -->
		<div
			class="action-container text-center m-auto"
			*ngIf="messageToast.actions.length"
		>
			<!-- in case of only one button -->
			<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
			<button
				*ngIf="
					messageToast.actions &&
						messageToast.actions.length === 1 &&
						messageToast.actions[0].label.length < MAX_BUTTON_LABEL_LENGTH;
					else messageToastOptions
				"
				class="btn p-0 toast-action-button"
				[style.color]="'var(' + messageToastType.color + ')'"
				(click)="messageToast.actions[0].execute(); onAction$.next(messageToast.actions[0].label)"
			>
				{{ messageToast.actions[0].label }}
			</button>

			<!-- in case of more than one button -->
			<ng-template #messageToastOptions>
				<div
					class="d-inline-block"
					ngbDropdown
					container="body"
					placement="bottom-right"
					dropdownClass="message-toast-action"
				>
					<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
					<a
						class="btn toast-action-button p-0"
						id="dropdownOptions"
						[style.color]="'var(' + messageToastType.color + ')'"
						ngbDropdownToggle
					>
						Option
						<span
							class="ms-2"
							aria-hidden="true"
						>
							<ng-fa-icon
								class="d-inline-flex"
								icon="chevron-down"
							></ng-fa-icon>
						</span>
					</a>
					<div
						ngbDropdownMenu
						aria-labelledby="dropdownOptions"
					>
						<button
							ngbDropdownItem
							(click)="action.execute(); onAction$.next(action.label)"
							class="btn btn-default"
							*ngFor="let action of messageToast.actions"
						>
							{{ action.label }}
						</button>
					</div>
				</div>
			</ng-template>
		</div>
	</div>

	<!-- Progress Bar -->
	<div class="col-12 position-relative">
		<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
		<div
			class="toast-progress rounded"
			[style.background]="'var(' + messageToastType.color + ')'"
			[style.width]="(messageToast.progress > 100 ? 100 : messageToast.progress) + '%'"
		></div>
	</div>
</ngb-toast>
