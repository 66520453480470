/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumAppStateCategory {
	Styling				= 'app_styling',
	Auth				= 'app_auth',
	InfoMessages		= 'app_info_messages'
}

export enum EnumInfoMessages {
	TabletMode 			= 'tablet_mode',
	WhatsNewResearch 	= 'whats_new_research',
	WhatsNewUpload 		= 'whats_new_upload',
	AppTour 			= 'app_tour',
	UpcomingAdvertisement = 'upcoming_advertisement'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IAppStates extends Record<EnumAppStateCategory, Record<string, number | boolean | string | Date>> {
	[EnumAppStateCategory.Styling] : {
		layoutScale 	: number;
	};
	[EnumAppStateCategory.Auth] : {
		userHash 		: string;							// hash value of the user mail
		accessToken 	: string;
		refreshToken	: string;
	};
	[EnumAppStateCategory.InfoMessages] : {
		[EnumInfoMessages.TabletMode] 			 : boolean;
		[EnumInfoMessages.WhatsNewUpload] 		 : string;		// current version
		[EnumInfoMessages.WhatsNewResearch] 	 : string;		// current version
		[EnumInfoMessages.AppTour] 				 : boolean;
		[EnumInfoMessages.UpcomingAdvertisement] : boolean;
	};
}


/**------------------------------------------------------
 * Default Data
 */
export const INITIAL_APP_STATE_OBJECT: IAppStates = {
	[EnumAppStateCategory.Styling] : {
		layoutScale 	: 1					// 100% scaling is the default
	},
	[EnumAppStateCategory.Auth] : {
		userHash 		: '',					// hash value of the user mail
		accessToken 	: '',
		refreshToken	: ''
	},
	[EnumAppStateCategory.InfoMessages] : {
		[EnumInfoMessages.TabletMode] 			 : true,
		[EnumInfoMessages.WhatsNewResearch] 	 : '',
		[EnumInfoMessages.WhatsNewUpload]   	 : '',
		[EnumInfoMessages.AppTour] 				 : true,
		[EnumInfoMessages.UpcomingAdvertisement] : true
	}
};
