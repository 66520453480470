import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StatesService } from '../../../../services/shared/states/states.service';


@Injectable()
export class ModuleGuard {

	constructor(
		private stateService: StatesService,
		private router		: Router
	) {}


	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const hasAccess: boolean = this.stateService.access.hasAccess(state.url);
		if (!hasAccess) this.router.navigate(['/']);
		return hasAccess;
	}
}
