import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { EnumTmNiceClass, EnumTmOffice, EnumTmStatus, EnumTmType } from '../constants';


/**------------------------------------------------------
 * Trademark Entry Interface
 */
export interface ITmTrademarkEntry {
	trademarkId				: string;
	name					: string;						// the registered tm name
	optimizedName			: string;						// optimized for better query (old tmSearch)
	office					: EnumTmOffice;
	niceClasses				: EnumTmNiceClass[];
	type			  		: EnumTmType;
	status					: EnumTmStatus;
	applicationNumber		: string | null;
	applicationDate			: Date	 | null;
	registrationNumber		: string | null;
	registrationDate		: Date	 | null;
	oppositionPeriodStart	: Date 	 | null;
	oppositionPeriodEnd		: Date 	 | null;
	applicantName			: string | null;				// string can be empty if not applicant is available
}


/**------------------------------------------------------
 * Schema
 */
export class TmTrademarkEntrySchema implements ValidatorSchema<ITmTrademarkEntry> {
	trademarkId				= Validator.String().isNotEmpty();
	name					= Validator.String().isNotEmpty();
	optimizedName			= Validator.String().isNotEmpty().isLowerCase();
	office					= Validator.Enum(EnumTmOffice);
	niceClasses				= Validator.Array().each(Validator.Enum(EnumTmNiceClass));
	type			  		= Validator.Enum(EnumTmType);
	status					= Validator.Enum(EnumTmStatus);
	applicationNumber		= Validator.String().isNotEmpty().optional();
	applicationDate			= Validator.Date().optional();
	registrationNumber		= Validator.String().isNotEmpty().optional();
	registrationDate		= Validator.Date().optional();
	oppositionPeriodStart	= Validator.Date().optional();
	oppositionPeriodEnd		= Validator.Date().optional();
	applicantName			= Validator.String().isNotEmpty().optional();
}
