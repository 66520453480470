/**------------------------------------------------------
 * Keyboard Layouts / Patters
 * --------------------------
 * > see article: https://vpns.co.uk/the-20-most-common-passwords-by-keyboard-pattern/
 * --------------------------
 * > One of the most common keyboard patterns is the first
 * > five letter keys on the top row, ‘QWERTY’, after which
 * > the standard keyboard layout is named, but there are
 * > many other combinations. Keyboard pattern passwords
 * > are easy to remember, but they’re also very easy to
 * > guess by both humans and computers.
 */
export const VALIDATOR_KEYBOARD_LAYOUTS: Record<string, string[]> = {

	// US: English QWERTY
	'US': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\'', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/'],

	// GER: German QWERTZ
	'GER': ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'ü', '+', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä', '#', 'y', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '-', 'ß'],

	// FR: French AZERTY
	'FR': ['a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'é', 'è', 'ç', 'à', 'q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'ù', '*', 'w', 'x', 'c', 'v', 'b', 'n', ',', ';', ':', '!'],

	// SPA: Spanish QWERTY
	'SPA': ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', 'á', 'é', 'í', 'ó', 'ú'],

	// ITA: Italian QWERTY
	'ITA': ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\'', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', 'à', 'è', 'ì', 'ò', 'ù'],

	// SWE: Swedish QWERTY
	'SWE': ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/'],

	// DAN: Danish QWERTY
	'DAN': ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø', '\'', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/'],

	// NOR: Norwegian QWERTY
	'NOR': ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ø', 'æ', '\'', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/']
};
