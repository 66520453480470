import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgbDropdownModule, NgbModule, NgbPopoverModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { NgTooltipDirective } from './tooltip';
import { NgConformModalComponent, NgConformModalAssistant } from './conform-modal';
import { NgContentPopoverComponent, NgInfoPopoverComponent } from './popover';
import { NgTemplateModalAssistant, NgTemplateModalComponent } from './template-modal';
import { NgStylingComponentsModule } from '../../styling-components';
import { NgContentAssistantModule } from '../../services/content-assistants/content-assistants.module';
import { NgMessageTimeComponent, NgMessageContainerComponent, NgMessageToastComponent, NgMessageToastAssistant } from './message-toast';


@NgModule({
	declarations: [
		NgConformModalComponent,
		NgTooltipDirective,
		NgTemplateModalComponent,
		NgInfoPopoverComponent,
		NgContentPopoverComponent,
		NgMessageContainerComponent,
		NgMessageToastComponent,
		NgMessageTimeComponent
	],
	imports: [
		CommonModule,
		OverlayModule,
		NgbPopoverModule,
		NgContentAssistantModule,
		NgbModule,
		NgbToastModule,
		NgbDropdownModule,
		NgStylingComponentsModule
	],
	providers: [
		NgTemplateModalAssistant,
		NgConformModalAssistant,
		NgMessageToastAssistant
	],
	exports: [
		NgConformModalComponent,
		NgTooltipDirective,
		NgInfoPopoverComponent,
		NgContentPopoverComponent,
		NgMessageContainerComponent,
		NgMessageToastComponent
	]
})
export class NgDialogsModule {

	constructor(
		private messageToastAssistant: NgMessageToastAssistant
	) {
		this.messageToastAssistant.loadToastContainers();
	}
}
