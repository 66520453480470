<!-- NgbPopover Content -->
<div
	#popoverTemp="ngbPopover"
	[ngbPopover]="popoverContent"
	[popoverTitle]="popoverTitle"
	popoverClass="tour-popover-custom-styles"
	triggers="manual"
	[autoClose]="false"
	container="body"
	class="w-100 h-100"
></div>

<!-- Popover title -->
<ng-template #popoverTitle>
	<div
		class="d-flex justify-content-between align-items-center"
		*ngIf="tourStepData"
	>
		<span
			class="step-title"
			[innerHTML]="tourStepData.index + '. ' + tourStepData.hint.title"
		></span>
		<span
			class="close-btn"
			(click)="onClose(tourStepData.index)"
			*ngIf="showCloseButton"
		>
			×
		</span>
	</div>
</ng-template>

<!-- Popover body with content -->
<ng-template #popoverContent>
	<!-- Tour with hint (message, buttons, image) -->
	<div
		class="popover-content"
		*ngIf="tourStepData?.hint"
	>
		<div
			class="img-block"
			*ngIf="tourStepData?.hint?.image"
		>
			<img
				class="p-2"
				[src]="tourStepData?.hint?.image"
				[alt]="tourStepData?.hint?.title"
			/>
		</div>

		<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
		<div
			class="message-block pt-1 pb-2 px-3"
			[style.min-height]="!tourStepData?.hint?.image ? '100px' : null"
			[innerHTML]="tourStepData?.hint?.message"
		></div>

		<div
			*ngIf="tourStepData?.hint?.actions?.length! > 0"
			class="d-flex flex-wrap justify-content-around align-items-center pt-1 pb-3 px-3"
		>
			<ng-container *ngFor="let btn of tourStepData?.hint?.actions">
				<button
					class="btn btn-light my-2 text-truncate"
					[class.action-btn-styles-1]="tourStepData?.hint?.actions?.length === 1"
					[class.action-btn-styles-2]="tourStepData?.hint?.actions?.length === 2"
					[class.action-btn-styles-3]="tourStepData?.hint?.actions?.length === 3"
					[class.action-btn-styles-4]="tourStepData?.hint?.actions?.length === 4"
					[ngTooltip]="btn.buttonText"
					onlyTruncate
					(click)="btn.execute()"
				>
					{{ btn.buttonText }}
				</button>
			</ng-container>
		</div>

		<div
			class="tour-navigation-bullets"
			*ngIf="showTourProgressBullets"
		>
			<div class="bullets-list">
				<ul>
					<li *ngFor="let step of progressSteps; let i = index">
						<a
							href="javascript:void(0);"
							[class.is-active]="i + 1 === tourStepData?.index"
							(click)="onNavigationBullet(i + 1)"
						>
							{{ i }}
						</a>
					</li>
				</ul>
			</div>
		</div>

		<!-- Tour next, back, done event buttons on footer -->
		<div class="tour-footer">
			<div
				class="d-flex align-items-center btns"
				*ngIf="tourStepData"
				[ngClass]="isTourFirstStep || isTourLastStep ? 'justify-content-center' : 'justify-content-between'"
			>
				<button
					class="btn btn-outline-primary state-btn-styles"
					(click)="onBack(tourStepData.index)"
					*ngIf="!isTourFirstStep && !isTourLastStep"
				>
					Back
				</button>
				<button
					class="btn btn-outline-primary state-btn-styles"
					(click)="onNext(tourStepData.index)"
					*ngIf="!isTourLastStep"
				>
					Next
				</button>
				<button
					class="btn btn-primary state-btn-styles"
					(click)="onComplete(tourStepData.index)"
					*ngIf="isTourLastStep"
				>
					Done
				</button>
			</div>
		</div>
	</div>
</ng-template>
