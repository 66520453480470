/**------------------------------------------------------
 * Navbar Tagging Options
 */
export enum EnumNavbarOptions {
	ScheduleUpload 			= 'navbar_schedule_upload',
	UploadBatch 			= 'navbar_upload_batch',
	Save 					= 'navbar_save',
	Undo 					= 'navbar_undo',
	Redo 					= 'navbar_redo',
	KwSearch 				= 'navbar_kw_search',
	AiListingByVision	 	= 'navbar_ai_listing_by_vision',
	AiListingByKeywords 	= 'navbar_ai_listing_by_keywords',
	AiListingByTemplate 	= 'navbar_ai_listing_by_template',
	TmSearch 				= 'navbar_tm_search',
	TmSingleCheck 			= 'navbar_tm_single_check',
	TmBulkCheck 			= 'navbar_tm_bulk_check',
	TmBlacklistCheck		= 'navbar_tm_blacklist_check',
	CopyTagsFrom 			= 'navbar_copy_tags_from',
	ApplyTagsTo 			= 'navbar_apply_tags_to',
	CopyListingFrom 		= 'navbar_copy_listing_from',
	ApplyListingTo 			= 'navbar_apply_listing_to',
	CopyFromListingHistory 	= 'navbar_copy_from_listing_history',
	ApplyListingHistoryTo 	= 'navbar_apply_listing_history_to',
	CopyPrevious 			= 'navbar_copy_previous',
	BulkCopyListing 		= 'navbar_bulk_copy_listing',
	ClearListing 			= 'navbar_clear_listing',
	ClearAllListings		= 'navbar_clear_all_listings',
	Delete 					= 'navbar_delete',
	SpellChecking 			= 'navbar_spell_checking',
	GenerateListing 		= 'navbar_generate_listing',
	TemplateToAll 			= 'navbar_template_to_all',
	OptionsToAll 			= 'navbar_options_to_all',
	LoadProfile 			= 'navbar_load_profile',
	SaveAsProfile 			= 'navbar_save_as_profile',
	LoadSuggestion 			= 'navbar_load_suggestion',
	SaveAsSuggestion 		= 'navbar_save_as_suggestion',
	Upload 					= 'navbar_upload',
	RemoveListing 			= 'navbar_remove_listing',
	RemoveEmpty 			= 'navbar_remove_empty',
	DivideListingEmpty		= 'navbar_divide_listing_empty',
	GroupByImage 			= 'navbar_group_by_image',
	GroupByListing 			= 'navbar_group_by_listing',
	ShuffleDesigns 			= 'navbar_shuffle_designs'
}

export enum EnumNavbarActions {
	PreviousDesign 			= 'navbar_previous_design',
	NextDesign 				= 'navbar_next_design',
	QuickSearch 			= 'navbar_quick_search',
	Duplicate 				= 'navbar_duplicate',
	Remove 					= 'navbar_remove',
	PreviousTab 			= 'navbar_previous_tab',
	NextTab 				= 'navbar_next_tab',
	JumpToSelected 			= 'navbar_jump_to_selected',
	ShowAdvanced 			= 'navbar_show_advanced',
	TextReplace 			= 'navbar_text_replace'
}
