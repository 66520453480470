import { Util } from '@libs/utilities/util';

import { ILogHistory, ILogHistoryEntry, ILogReport, ILogReportEvaluation, EnumLogLevel, AbstractLogger } from './logger.interface';


/**------------------------------------------------------
 * Base for all Loggers
 */
export abstract class AbstractLoggerService extends AbstractLogger {

	//** Configurations */
	private readonly CONSOLE_COLORING_COMMANDS: RegExp = /(\\x.{4,5}m|1b.{1,2}m|.{1}\[.{1,2}m)/gi;

	//** Logging History */
	private logHistory: ILogHistory = {
		[EnumLogLevel.Info]		: [],
		[EnumLogLevel.Warning] 	: [],
		[EnumLogLevel.Error]	: []
	};

	constructor(
		protected readonly logLevels: EnumLogLevel[]
	) {
		super();
		if (Util.Array.isEmpty(this.logLevels)) console.warn(`AbstractLoggerService => constructor => FATAL ERROR: LOG_LEVELS should not be empty`);
		if (!Util.Enum.areValid(EnumLogLevel, this.logLevels)) throw new Error(`AbstractLoggerService => constructor => FATAL ERROR: some of the LOG_LEVELS are invalid (logLevels: ${this.logLevels.join(', ')} / valid are: ${Util.Enum.values(EnumLogLevel).join(', ')})`);
	}


	/**------------------------------------------------------
	 * Functions to Overwrite
	 */
	protected abstract printLogMessage(logLevel: EnumLogLevel, ...messages: unknown[]): string;


	/**------------------------------------------------------
	 * Default Logging
	 */
	log(...messages: unknown[]): AbstractLoggerService {
		this.handleLogging(EnumLogLevel.Info, ...messages);
		return this;
	}
	warn(...messages: unknown[]): AbstractLoggerService {
		this.handleLogging(EnumLogLevel.Warning, ...messages);
		return this;
	}
	error(...messages: unknown[]): AbstractLoggerService {
		this.handleLogging(EnumLogLevel.Error, ...messages);
		return this;
	}


	/**------------------------------------------------------
	 * Conditional Logging
	 */
	logIf (condition: boolean, ...messages: unknown[]): AbstractLoggerService {
		if (condition) this.handleLogging(EnumLogLevel.Info, ...messages);
		return this;
	}
	warningIf (condition: boolean, ...messages: unknown[]): AbstractLoggerService {
		if (condition) this.handleLogging(EnumLogLevel.Warning, ...messages);
		return this;
	}
	errorIf (condition: boolean, ...messages: unknown[]): AbstractLoggerService {
		if (condition) this.handleLogging(EnumLogLevel.Error, ...messages);
		return this;
	}


	/**------------------------------------------------------
	 * Reset
	 */
	clear(): AbstractLoggerService {

		//0 - reset the log history
		this.logHistory = {
			[EnumLogLevel.Info]		: [],
			[EnumLogLevel.Warning] 	: [],
			[EnumLogLevel.Error]	: []
		};

		//1 - return instance for method chaining
		return this;
	}


	/**------------------------------------------------------
	 * Statistics & Export
	 */
	createErrorReport(): ILogReport {

		//0 - create report and remove info logs
		// > NOTE: the info logs would make the report too large
		const report: ILogReport = this.createReport();
		report[EnumLogLevel.Info].history = [];

		//1 - return the report
		return report;
	}

	createReport(): ILogReport {

		//0 - get the sum of all log messages
		const totalLogs: number = Object.keys(this.logHistory)
			.reduce((previous: number, key: string) => previous + this.logHistory[key as EnumLogLevel].length, 0);

		//1 - create the statistic report
		const report: ILogReport = {
			totalLogs				: totalLogs,
			[EnumLogLevel.Info]		: this.evaluateEntries(EnumLogLevel.Info, 	 totalLogs),
			[EnumLogLevel.Warning]	: this.evaluateEntries(EnumLogLevel.Warning, totalLogs),
			[EnumLogLevel.Error]	: this.evaluateEntries(EnumLogLevel.Error,	 totalLogs)
		};

		//2 - return the report
		return report;
	}

	private evaluateEntries(logLevel: EnumLogLevel, totalLogs: number): ILogReportEvaluation {

		//0 - create the evaluation
		const logHistory: ILogHistoryEntry[]   = this.logHistory[logLevel];
		const evaluation: ILogReportEvaluation = {
			count		: logHistory.length,
			percent		: (totalLogs === 0) ? 0 : Util.Number.roundDouble((100 / totalLogs) * logHistory.length),
			history 	: logHistory
		};

		//1 - return the evaluation
		return evaluation;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	protected handleLogging(logLevel: EnumLogLevel, ...messages: unknown[]): void {

		//0 - was the required log level set?
		if (!this.logLevels.includes(logLevel)) return;

		//1 - print the message
		const loggedText: string = this.printLogMessage(logLevel, ...messages);

		//2 - add messages to the history
		this.logHistory[logLevel].push({
			time	: new Date(),
			log		: loggedText
		});
	}

	protected messagesToText(...messages: unknown[]): string {
		return messages.map((elem: unknown) => Util.Basic.stringifyIfObject(elem)).join(' ')
			.replace(this.CONSOLE_COLORING_COMMANDS, '');
	}
}
