import { EnumContinent, EnumCountry, EnumLanguage } from '@libs/constants';

import { EnumPlatform, EnumPlatformCategory, EnumPlatformUploadType, EnumWebsiteOption } from './print-on-demand.constants';


/**------------------------------------------------------
 * Platform Specification
 */
export const PLATFORM_SPECIFICATION : IPlatform[] = [
	{
		code			: EnumPlatform.Displate,
		category		: EnumPlatformCategory.Displate,
		uploadType		: EnumPlatformUploadType.NoTemplate,
		name			: 'Displate',
		nameShort 		: '',
		description 	: 'Displate is a print on design website for metal posters. It is specialized and is only selling metal posters. The platform offers a great opportunity by generating organic sales. Please keep in mind that the uploaded designs will be used as posters and should have a high quality image (in terms of size and unique design).',
		particularity	: 'Designs need to have a high quality for the reason that they will be printed on metal posters. Uploading low quality designs will not bring any sales. To keep the platform clean and ensure the quality displate has introduced an upload limit of 10 designs a day. For getting an displate account you have to submit an application to displate which will then be reviewed by their team.',
		uploadLimit 	: {
			limit		: 10,
			period 		: 'day'
		},
		uploadTimeInMin	: 2,
		location		: EnumCountry.US,
		salesMarkets	: [EnumCountry.US, EnumContinent.Europe],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#1185ed',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Displate.com',
				platformUrl 	: 'https://displate.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.FineArtAmerica,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Fine Art America',
		nameShort 		: 'FAA',
		description 	: 'Fine Art America is a print one demand website with a variety of different products. It is a medium opportunity for pod sellers because it only offers a low number of organic sales. This can be different if you are pushing your designs and products with advertisement.',
		particularity	: 'Fine Art America is limiting your initial uploads to 25 till they approve your store. After purchasing a premium store for about 30 USD you will be free to upload any number of designs you would like to offer with the different products. Please keep in mind that the platform is focusing more on wall art and accessories than clothing.',
		uploadTimeInMin	: 3,
		location 		: EnumCountry.US,
		salesMarkets	: [EnumCountry.US],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#01a1c5',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'FineArtAmerica.com',
				platformUrl 	: 'https://fineartamerica.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.MBA,
		category		: EnumPlatformCategory.MBA,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Merch by Amazon',
		nameShort 		: 'MBA',
		description 	: 'Merch by Amazon is a print on demand printing service offered by Amazon. It allows sellers to create designs and offer them on Shirt, Hoodies, PopSockets, and many more products on the Amazon marketplaces. MBA has the highest potential of all platforms for organic sales.',
		particularity	: 'Getting a Merch by Amazon account is quite difficult. A custom application is needed and the approval process is not transparent. Some people might be approved after the first time and others after 6 months of countless applications. Furthermore, Amazon is enforcing a tier system on MBA. A new account will have the tier T10 and can only hold up to 10 designs/products for selling. After 10 designs were sold the account will be updated T25 and so on. The system consists of the following tiers T10, T25, T50, T100, T500, T1000, T2000, T4000, T6000, T8000, T10000, ... and so on.',						// ... todo => 25 if shop not approved
		uploadTimeInMin	: 4,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US, EnumCountry.UK, EnumCountry.DE, EnumCountry.FR, EnumCountry.ES, EnumCountry.JP],
		tmCountries		: [EnumCountry.US, EnumCountry.UK, EnumCountry.DE, EnumCountry.FR, EnumCountry.ES, EnumCountry.JP],
		platformColor	: '#ec9030',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_MULTI,
				name			: 'Merch.amazon.com',
				platformUrl 	: 'https://merch.amazon.com',
				languages		: [EnumLanguage.EN, EnumLanguage.DE, EnumLanguage.FR, EnumLanguage.ES, EnumLanguage.IT, EnumLanguage.JA],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.KDP,
		category		: EnumPlatformCategory.KDP,
		uploadType		: EnumPlatformUploadType.NoTemplate,
		name			: 'Kindle Direct Publishing',
		nameShort 		: 'KDP',
		description 	: 'Kindle Direct Publishing is a service of Amazon to self-publish book and paperbacks. You can publish your own books like stories, guides, notebooks and much more. The print on demand community is using the KDP program of Amazon to sell low and no content book. These are books with a great designs for the cover and a notebook, calendar, journal .... as an interior.',
		particularity	: 'Kindle Direct Publishing does not give any limitations of what you can publish as a book. So you can write your own book or just publish low and no content books, like journals, color books, lined or ruled notebooks, and much more. Amazon does not set a fix upload limit, but you are not allowed to upload more than 1000 books per week. Violating these limits might lead to Amazon blocking or removing your account permanently.',
		uploadLimit 	: {
			limit		: 1000,
			period 		: 'week'
		},
		uploadTimeInMin	: 10,
		location 		: EnumCountry.US,
		salesMarkets	: [EnumCountry.US, EnumCountry.UK, EnumCountry.DE, EnumCountry.FR, EnumCountry.ES, EnumCountry.JP, EnumCountry.CA, EnumCountry.MX],
		tmCountries		: [EnumCountry.US, EnumCountry.UK, EnumCountry.DE, EnumCountry.FR, EnumCountry.ES, EnumCountry.JP],
		platformColor	: '#ffcc7f',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_MULTI,
				name			: 'KDP.amazon.com',
				platformUrl 	: 'https://kdp.amazon.com',
				languages		: [EnumLanguage.EN, EnumLanguage.DE, EnumLanguage.FR, EnumLanguage.ES, EnumLanguage.IT, EnumLanguage.JA],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.Printful,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Printful',
		nameShort 		: '',
		description 	: 'Printful is a print on demand fulfillment service. The platforms lets you offer your designs on different products on supported shops/websites which are integrated. Platforms where you can sell your custom products include Etsy, Amazon, eBay, Shopify, and more. Printful is hereby only handling the printing and shipment of the products. You will need to take care of the traffic to your products and the promotion of them in order to generate sales. Alternatively you can choose a platform like Etsy or Amazon which have high organic traffic to generate sales.',
		particularity	: 'Uploading your designs to Printful is not enough. You will need to set up the integration between your accounts on Amazon, eBay, and so on. So when you upload your designs to Printful they will be published on the third party websites through your accounts. Choosing good platforms is essential to generate organic sales.',
		uploadTimeInMin	: 3,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US, EnumContinent.Europe],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#ed4642',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_MULTI,
				name			: 'Printful.com',
				platformUrl 	: 'https://www.printful.com',
				languages		: [EnumLanguage.EN, EnumLanguage.DE, EnumLanguage.FR, EnumLanguage.ES],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.Printify,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Printify',
		nameShort 		: '',
		description 	: 'Printify is a print on demand fulfillment service. The platforms lets you offer your designs on different products on supported shops/websites which are integrated. Platforms where you can sell your custom products include Etsy, eBay, and Shopify. Printify is hereby only handling the printing and shipment of the products. You will need to take care of the traffic to your products and the promotion of them in order to generate sales. Alternatively you can choose a platform like Etsy or eBay which have high organic traffic to generate sales.',
		particularity	: 'Uploading your designs to Printify is not enough. You will need to set up the integration between your accounts on Etsy, eBay, and so on. So when you upload your designs to Printify they will be published on the third party websites through your accounts. Choosing good platforms is essential to generate organic sales.',
		uploadTimeInMin	: 2,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US, EnumContinent.Europe],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#00ba64',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_MULTI,
				name			: 'Printify.com',
				platformUrl 	: 'https://www.printify.com',
				languages		: [EnumLanguage.EN, EnumLanguage.DE, EnumLanguage.FR, EnumLanguage.ES],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.Redbubble,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Redbubble',
		nameShort 		: 'RB',
		description 	: 'Redbubble is a global marketplace for print on demand products based on user-submitted artwork. It offers a great opportunity for artist because of is high organic traffic. Therefore, the platform is offering a great number of organic sales for high quality designs.',
		particularity	: 'Redbubble has many professional artist uploading to the platform. In order to generate organic sales you will have to upload high quality artwork to Redbubble. Low quality designs will not bring any sales only good designs sell on this platform. Furthermore, Redbubble has an upload limit of 60 designs within a time period of 24 hours.',
		uploadLimit 	: {
			limit		: 60,
			period 		: 'day'
		},
		uploadTimeInMin	: 3,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US, EnumContinent.Europe],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#e41321',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_MULTI,
				name			: 'Redbubble.com',
				platformUrl 	: 'https://www.redbubble.com',
				languages		: [EnumLanguage.EN, EnumLanguage.DE, EnumLanguage.FR, EnumLanguage.ES],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.Shirtee,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Shirtee',
		nameShort 		: '',
		description 	: 'Shirtee is a German print on demand platform which offers a high royalty compared to other platforms. The potential is comparably low in terms of organic sales. If you want to be successful with Shirtee you will need to run ads for your shirt designs through Facebook and other social media platforms.',
		particularity	: 'Shirtee does not have any upload limit. Uploading hundreds and thousands of designs however will not bring you any sales because of the low organic traffic. In order to drive sales you will need to promote your designs on social media yourself.',
		uploadTimeInMin	: 2,
		location	 	: EnumCountry.DE,
		salesMarkets	: [EnumContinent.Europe],
		tmCountries		: [EnumCountry.DE],
		platformColor	: '#6e8196',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.de_EN,
				name			: 'Shirtee.com/en',
				platformUrl 	: 'https://www.shirtee.com/en/',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.UK
			},
			{
				websiteOption	: EnumWebsiteOption.de_DE,
				name			: 'Shirtee.com/de',
				platformUrl 	: 'https://www.shirtee.com/de/',
				languages		: [EnumLanguage.DE],
				country			: EnumCountry.DE
			},
			{
				websiteOption	: EnumWebsiteOption.de_FR,
				name			: 'Shirtee.com/fr',
				platformUrl 	: 'https://www.shirtee.com/fr/',
				languages		: [EnumLanguage.FR],
				country			: EnumCountry.FR
			},
			{
				websiteOption	: EnumWebsiteOption.de_ES,
				name			: 'Shirtee.com/es',
				platformUrl 	: 'https://www.shirtee.com/es/',
				languages		: [EnumLanguage.ES],
				country			: EnumCountry.ES
			}
		]
	},
	{
		code			: EnumPlatform.Society6,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Society6',
		nameShort 		: 'S6',
		description 	: 'Society6 is a print on demand platform offering products with designs from hundreds of thousands of artists. The platform is not limited to clothing and is offering products like wall art, accessories, and much more. The platform has potential because of its high organic traffic. You should definitely upload your designs to Society6 because its potential for organic sales.',
		particularity	: 'Society6 does not enforce any upload limits like other platforms. You can also choose between many products to publish your designs. You can upload your artworks, and you can also upload pattern designs, which is extremely popular to do on Society6. Another thing worth mentioning is that the upload takes a significant amount of time if you upload to all products.',
		uploadTimeInMin	: 10,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#383838',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Society6.com',
				platformUrl 	: 'https://society6.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.SpreadshirtUS,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Spreadshirt US',
		nameShort 		: 'Sp US',
		description 	: 'Spreadshirt is probably the best platform to start print on demand. It offers a great opportunity with organic sales, and you can sell almost any product on the platform. Spreadshirt.com is operating in the United States and has provides lower sales as the one operating in Europe.',
		particularity	: 'Through Spreadshirt you can sell all kind of designs from simple text designs to professional artwork. The platform is has and upload limit of 20 designs a day and is furthermore checking all your artwork. The checks are probably carried out manual and Spreadshirt will reject scaled designs, trademarked content, and also copied artwork from third party stock image websites.',
		uploadLimit 	: {
			limit		: 20,
			period 		: 'day'
		},
		uploadTimeInMin	: 2,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#2f77b9',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Spreadshirt.com',
				platformUrl 	: 'https://www.spreadshirt.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			},
			{
				websiteOption	: EnumWebsiteOption.ca_EN,
				name			: 'Spreadshirt.ca',
				platformUrl 	: 'https://www.spreadshirt.ca',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.CA
			},
			{
				websiteOption	: EnumWebsiteOption.au_EN,
				name			: 'Spreadshirt.com.au',
				platformUrl 	: 'https://www.spreadshirt.com.au',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.AU
			}
		]
	},
	{
		code			: EnumPlatform.SpreadshirtEU,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Spreadshirt EU',
		nameShort 		: 'Sp EU',
		description 	: 'Spreadshirt is probably the best platform to start print on demand. It offers a great opportunity with organic sales and you can sell almost any product on the platform. Spreadshirt is operating from Germany but has sub websites for DE, AT, CH, FR, UK, ES, IT, and more.',
		particularity	: 'Through Spreadshirt you can sell all kind of designs from simple text designs to professional artwork. The platform is has and upload limit of 20 designs a day and is furthermore checking all your artwork. The checks are probably carried out manual and Spreadshirt will reject scaled designs, trademarked content, and also copied artwork from third party stock image websites.',
		uploadLimit 	: {
			limit		: 20,
			period 		: 'day'
		},
		uploadTimeInMin	: 2,
		location	 	: EnumCountry.DE,
		salesMarkets	: [EnumContinent.Europe],
		tmCountries		: [EnumCountry.DE],
		platformColor	: '#00b2a5',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.gb_EN,
				name			: 'Spreadshirt.co.uk',
				platformUrl 	: 'https://www.spreadshirt.co.uk',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.UK
			},
			{
				websiteOption	: EnumWebsiteOption.de_DE,
				name			: 'Spreadshirt.de',
				platformUrl 	: 'https://www.spreadshirt.de',
				languages		: [EnumLanguage.DE],
				country			: EnumCountry.DE
			},
			{
				websiteOption	: EnumWebsiteOption.at_DE,
				name			: 'Spreadshirt.at',
				platformUrl 	: 'https://www.spreadshirt.at',
				languages		: [EnumLanguage.DE],
				country			: EnumCountry.AT
			},
			{
				websiteOption	: EnumWebsiteOption.ch_DE,
				name			: 'Spreadshirt.ch',
				platformUrl 	: 'https://www.spreadshirt.ch',
				languages		: [EnumLanguage.DE],
				country			: EnumCountry.CH
			},
			{
				websiteOption	: EnumWebsiteOption.fr_FR,
				name			: 'Spreadshirt.fr',
				platformUrl 	: 'https://www.spreadshirt.fr',
				languages		: [EnumLanguage.FR],
				country			: EnumCountry.FR
			},
			{
				websiteOption	: EnumWebsiteOption.es_ES,
				name			: 'Spreadshirt.es',
				platformUrl 	: 'https://www.spreadshirt.es',
				languages		: [EnumLanguage.ES],
				country			: EnumCountry.ES
			},
			{
				websiteOption	: EnumWebsiteOption.it_IT,
				name			: 'Spreadshirt.it',
				platformUrl 	: 'https://www.spreadshirt.it',
				languages		: [EnumLanguage.IT],
				country			: EnumCountry.IT
			},
			{
				websiteOption	: EnumWebsiteOption.be_NL,
				name			: 'Spreadshirt.be',
				platformUrl 	: 'https://www.spreadshirt.be/nl',
				languages		: [EnumLanguage.NL],
				country			: EnumCountry.BE
			},
			{
				websiteOption	: EnumWebsiteOption.dk_DA,
				name			: 'Spreadshirt.dk',
				platformUrl 	: 'https://www.spreadshirt.dk',
				languages		: [EnumLanguage.DA],
				country			: EnumCountry.DK
			},
			{
				websiteOption	: EnumWebsiteOption.ie_EN,
				name			: 'Spreadshirt.ie',
				platformUrl 	: 'https://www.spreadshirt.ie',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.IE
			},
			{
				websiteOption	: EnumWebsiteOption.nl_NL,
				name			: 'Spreadshirt.nl',
				platformUrl 	: 'https://www.spreadshirt.nl',
				languages		: [EnumLanguage.DA],
				country			: EnumCountry.NL
			},
			{
				websiteOption	: EnumWebsiteOption.nb_NO,
				name			: 'Spreadshirt.no',
				platformUrl 	: 'https://www.spreadshirt.no',
				languages		: [EnumLanguage.NO],
				country			: EnumCountry.NO
			},
			{
				websiteOption	: EnumWebsiteOption.pl_PL,
				name			: 'Spreadshirt.pl',
				platformUrl 	: 'https://www.spreadshirt.pl',
				languages		: [EnumLanguage.PL],
				country			: EnumCountry.PL
			},
			{
				websiteOption	: EnumWebsiteOption.fi_FI,
				name			: 'Spreadshirt.fi',
				platformUrl 	: 'https://www.spreadshirt.fi',
				languages		: [EnumLanguage.FI],
				country			: EnumCountry.FI
			},
			{
				websiteOption	: EnumWebsiteOption.se_SV,
				name			: 'Spreadshirt.se',
				platformUrl 	: 'https://www.spreadshirt.se',
				languages		: [EnumLanguage.SV],
				country			: EnumCountry.SE
			}
		]
	},
	{
		code			: EnumPlatform.Teepublic,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Teepublic',
		nameShort 		: '',
		description 	: 'Teepublic is a print on demand websites which belongs to Redbubble. It offers a great opportunity with organic sales. This however is great, but you should be careful what you upload because Teepublic can remove accounts with trademarked artworks or low quality designs.',
		particularity	: 'Teepublic is a print on demand websites, but you should be really careful what you are uploading. Especially the first few designs will be reviewed manually by the platform. If these designs do not meet the standards of Teepublic they will remove your account permanently. In this case you can only create a new one and restart your journey. Furthermore, the platform has an upload limit of 50 designs a day.',
		uploadLimit 	: {
			limit		: 50,
			period 		: 'day'
		},
		uploadTimeInMin	: 2,
		location 		: EnumCountry.US,
		salesMarkets	: [EnumCountry.US],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#5a5a5a',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Teepublic.com',
				platformUrl 	: 'https://www.teepublic.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.Teespring,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Teespring',
		nameShort 		: '',
		description 	: 'Teespring is a print on demand websites with medium potential. You can upload quiet a few designs without having any sales. Once you got your first few sales, without organic traffic, your accounts status will be changed, and you will start to have some organic sales.',
		particularity	: 'Teespring is a platform which has some peculiarity. You can upload up to 1000 designs in the beginning. These designs however will not generate any organic sales. Once you got your first 10 sales, without organic traffic, your accounts status will be changed. After that the limitation of 1000 designs will be removed, and you will start to have organic sales. These first sales however will not happen automatically, you will need to buy your designs yourself, or you will need to push your designs through advertisement.',
		uploadTimeInMin	: 2,
		location	 	: EnumCountry.US,
		salesMarkets	: [EnumCountry.US],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#ed2560',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Teespring.com',
				platformUrl 	: 'https://teespring.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			}
		]
	},
	{
		code			: EnumPlatform.Tostadora,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Tostadora',
		nameShort 		: '',
		description 	: 'Tostadora is a small print on demand platform from Spain selling artwork all over Europe. Despite their size they have good potential due to the organic traffic they can generate organic sales for your designs. You can upload your designs to Tostadora in the following languages: EN, ES, FR, and IT.',
		particularity	: 'Tostadora will limit the amount of products you can publish in your shop. The limitation is based on products rather than designs. After you have generated your first sales your limit will be increased or removed, and you can publish more products with your designs. Another thing worth mentioning that your account will change after generating your first sales. In the beginning you are limited to the Spanish marketplace but once your account got its first sale your account will be unlocked to the other markets like the United States, United Kingdom, France, Italy, and others.',
		uploadTimeInMin	: 4,
		location	 	: EnumCountry.ES,
		salesMarkets	: [EnumContinent.Europe],
		tmCountries		: [EnumCountry.ES],
		platformColor	: '#fec34f',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Tostadora.com',
				platformUrl 	: 'https://www.tostadora.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			},
			{
				websiteOption	: EnumWebsiteOption.gb_EN,
				name			: 'Tostadora.co.uk',
				platformUrl 	: 'https://www.tostadora.co.uk',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.UK
			},
			{
				websiteOption	: EnumWebsiteOption.fr_FR,
				name			: 'Tostadora.fr',
				platformUrl 	: 'https://www.tostadora.fr',
				languages		: [EnumLanguage.FR],
				country			: EnumCountry.FR
			},
			{
				websiteOption	: EnumWebsiteOption.es_ES,
				name			: 'Tostadora.es',
				platformUrl 	: 'https://www.latostadora.com',
				languages		: [EnumLanguage.ES],
				country			: EnumCountry.ES
			},
			{
				websiteOption	: EnumWebsiteOption.it_IT,
				name			: 'Tostadora.it',
				platformUrl 	: 'https://www.latostadora.it',
				languages		: [EnumLanguage.IT],
				country			: EnumCountry.IT
			}
		]
	},
	{
		code			: EnumPlatform.Zazzle,
		category		: EnumPlatformCategory.Global,
		uploadType		: EnumPlatformUploadType.Template,
		name			: 'Zazzle',
		nameShort 		: '',
		description 	: 'Zazzle is a print on demand platform operating in the United States. The website has a great potential by generating organic sales for your designs. The products they offer include but are not limited to clothing. The product range includes wall art, a lot of different accessories, and much more.',
		particularity	: 'Zazzle does not enforce any upload limit or any other limitation.',
		uploadTimeInMin	: 3,
		location		: EnumCountry.US,
		salesMarkets	: [EnumCountry.US],
		tmCountries		: [EnumCountry.US],
		platformColor	: '#46c3e0',
		websites   	    : [
			{
				websiteOption	: EnumWebsiteOption.us_EN,
				name			: 'Zazzle.com',
				platformUrl 	: 'https://www.zazzle.com',
				languages		: [EnumLanguage.EN],
				country			: EnumCountry.US
			}
		]
	}
	/*
	{
		code			: 'th',						//Note: might be removed from flying upload because it has no organic sales
		name			: 'Threadless',
		// ... todo
	},
	{
		code			: 'tz',						// Note: added soon
		name			: 'Teezily',
		platformColor	: '55b9e9'
		// ... todo
	},
	{
		code			: 'sg',						// Note: added soon
		name			: 'Shirtigo',
		// ... todo
	}, */
];


//** Interfaces --------------------------------- */
export interface IPlatform {
	code			: EnumPlatform;
	category		: EnumPlatformCategory;
	uploadType		: EnumPlatformUploadType;
	name			: string;
	nameShort 		: string;
	description 	: string;
	particularity	: string;
	uploadLimit   ?: {
		limit		: number;
		period 		: string;
	};
	uploadTimeInMin	: number;
	location 		: EnumCountry;
	salesMarkets	: Array<(EnumCountry | EnumContinent)>;
	tmCountries		: EnumCountry[];
	platformColor	: string;
	websites   	    : IPlatformWebsite[];
}

export interface IPlatformWebsite {
	websiteOption	: EnumWebsiteOption;
	name			: string;
	platformUrl 	: string;
	languages		: EnumLanguage[];
	country			: EnumCountry;
}
