import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumPixabaySorting, IPixabaySearchOptions, PIXABAY_CONFIG, PIXABAY_DEFAULT_OPTIONS } from './pixabay-search.data';


/**------------------------------------------------------
 * Pixabay Search Url
 */
export class PixabaySearch extends AbstractSearchLinks<IPixabaySearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumPixabaySorting>[] {
		return PIXABAY_CONFIG.sortings as ISearchParameterConfig<EnumPixabaySorting>[];
	}

	constructor() {
		super(PIXABAY_CONFIG, PIXABAY_DEFAULT_OPTIONS);
	}
}
