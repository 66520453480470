import { EnumContinent, IContinentInfo } from './continent.interface';
import { EnumCountry } from '../country/country.interface';


/**------------------------------------------------------
 * Continent Infos
 */
export const CONTINENT_INFOS: Record<EnumContinent, IContinentInfo> = {
	[EnumContinent.Africa]: {
		code		: EnumContinent.Africa,
		name		: 'Africa',
		abbreviation: 'AF',
		countries	: [
			EnumCountry.DZ,
			EnumCountry.AO,
			EnumCountry.BJ,
			EnumCountry.BW,
			EnumCountry.BF,
			EnumCountry.BI,
			EnumCountry.CV,
			EnumCountry.CM,
			EnumCountry.CF,
			EnumCountry.TD,
			EnumCountry.KM,
			EnumCountry.CG,
			EnumCountry.CD,
			EnumCountry.DJ,
			EnumCountry.EG,
			EnumCountry.GQ,
			EnumCountry.ER,
			EnumCountry.SZ,
			EnumCountry.ET,
			EnumCountry.GA,
			EnumCountry.GM,
			EnumCountry.GH,
			EnumCountry.GN,
			EnumCountry.GW,
			EnumCountry.CI,
			EnumCountry.KE,
			EnumCountry.LS,
			EnumCountry.LR,
			EnumCountry.LY,
			EnumCountry.MG,
			EnumCountry.MW,
			EnumCountry.ML,
			EnumCountry.MR,
			EnumCountry.MU,
			EnumCountry.MA,
			EnumCountry.MZ,
			EnumCountry.NA,
			EnumCountry.NE,
			EnumCountry.NG,
			EnumCountry.RW,
			EnumCountry.ST,
			EnumCountry.SN,
			EnumCountry.SC,
			EnumCountry.SL,
			EnumCountry.SO,
			EnumCountry.ZA,
			EnumCountry.SS,
			EnumCountry.SD,
			EnumCountry.TZ,
			EnumCountry.TG,
			EnumCountry.TN,
			EnumCountry.UG,
			EnumCountry.ZM,
			EnumCountry.ZW
		]
	},
	[EnumContinent.Antarctica]: {
		code		: EnumContinent.Antarctica,
		name		: 'Antarctica',
		abbreviation: 'AN',
		countries	: [
			EnumCountry.AQ
		]
	},
	[EnumContinent.Asia]: {
		code		: EnumContinent.Asia,
		name		: 'Asia',
		abbreviation: 'AS',
		countries	: [
			EnumCountry.AF,
			EnumCountry.AM,
			EnumCountry.AZ,
			EnumCountry.BH,
			EnumCountry.BD,
			EnumCountry.BT,
			EnumCountry.BN,
			EnumCountry.KH,
			EnumCountry.CN,
			EnumCountry.CY,
			EnumCountry.GE,
			EnumCountry.IN,
			EnumCountry.ID,
			EnumCountry.IR,
			EnumCountry.IQ,
			EnumCountry.IL,
			EnumCountry.JP,
			EnumCountry.JO,
			EnumCountry.KZ,
			EnumCountry.KW,
			EnumCountry.KG,
			EnumCountry.LA,
			EnumCountry.LB,
			EnumCountry.MY,
			EnumCountry.MV,
			EnumCountry.MN,
			EnumCountry.MM,
			EnumCountry.NP,
			EnumCountry.KP,
			EnumCountry.OM,
			EnumCountry.PK,
			EnumCountry.PS,
			EnumCountry.PH,
			EnumCountry.QA,
			EnumCountry.SA,
			EnumCountry.SG,
			EnumCountry.KR,
			EnumCountry.LK,
			EnumCountry.SY,
			EnumCountry.TW,
			EnumCountry.TJ,
			EnumCountry.TH,
			EnumCountry.TL,
			EnumCountry.TR,
			EnumCountry.TM,
			EnumCountry.AE,
			EnumCountry.UZ,
			EnumCountry.VN,
			EnumCountry.YE
		]
	},
	[EnumContinent.Europe]: {
		code		: EnumContinent.Europe,
		name		: 'Europe',
		abbreviation: 'EU',
		countries	: [
			EnumCountry.AL,
			EnumCountry.AD,
			EnumCountry.AT,
			EnumCountry.BY,
			EnumCountry.BE,
			EnumCountry.BA,
			EnumCountry.BG,
			EnumCountry.HR,
			EnumCountry.CZ,
			EnumCountry.DK,
			EnumCountry.EE,
			EnumCountry.FI,
			EnumCountry.FR,
			EnumCountry.DE,
			EnumCountry.GR,
			EnumCountry.HU,
			EnumCountry.IS,
			EnumCountry.IE,
			EnumCountry.IT,
			EnumCountry.XK,
			EnumCountry.LV,
			EnumCountry.LI,
			EnumCountry.LT,
			EnumCountry.LU,
			EnumCountry.MT,
			EnumCountry.MD,
			EnumCountry.MC,
			EnumCountry.ME,
			EnumCountry.NL,
			EnumCountry.MK,
			EnumCountry.NO,
			EnumCountry.PL,
			EnumCountry.PT,
			EnumCountry.RO,
			EnumCountry.RU,
			EnumCountry.SM,
			EnumCountry.RS,
			EnumCountry.SK,
			EnumCountry.SI,
			EnumCountry.ES,
			EnumCountry.SE,
			EnumCountry.CH,
			EnumCountry.UA,
			EnumCountry.UK,
			EnumCountry.VA
		]
	},
	[EnumContinent.NorthAmerica]: {
		code		: EnumContinent.NorthAmerica,
		name		: 'North America',
		abbreviation: 'NA',
		countries	: [
			EnumCountry.AG,
			EnumCountry.BS,
			EnumCountry.BB,
			EnumCountry.BZ,
			EnumCountry.CA,
			EnumCountry.CR,
			EnumCountry.CU,
			EnumCountry.DM,
			EnumCountry.DO,
			EnumCountry.SV,
			EnumCountry.GD,
			EnumCountry.GT,
			EnumCountry.HT,
			EnumCountry.HN,
			EnumCountry.JM,
			EnumCountry.MX,
			EnumCountry.NI,
			EnumCountry.PA,
			EnumCountry.KN,
			EnumCountry.LC,
			EnumCountry.VC,
			EnumCountry.TT,
			EnumCountry.US
		]
	},
	[EnumContinent.SouthAmerica]: {
		code		: EnumContinent.SouthAmerica,
		name		: 'South America',
		abbreviation: 'SA',
		countries	: [
			EnumCountry.AR,
			EnumCountry.BO,
			EnumCountry.BR,
			EnumCountry.CL,
			EnumCountry.CO,
			EnumCountry.EC,
			EnumCountry.GY,
			EnumCountry.PY,
			EnumCountry.PE,
			EnumCountry.SR,
			EnumCountry.UY,
			EnumCountry.VE
		]
	},
	[EnumContinent.Oceania]: {
		code		: EnumContinent.Oceania,
		name		: 'Oceania',
		abbreviation: 'OC',
		countries	: [
			EnumCountry.AU,
			EnumCountry.FJ,
			EnumCountry.KI,
			EnumCountry.MH,
			EnumCountry.FM,
			EnumCountry.NR,
			EnumCountry.NZ,
			EnumCountry.PW,
			EnumCountry.PG,
			EnumCountry.WS,
			EnumCountry.SB,
			EnumCountry.TO,
			EnumCountry.TV,
			EnumCountry.VU
		]
	}
};
