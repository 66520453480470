import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Printful Constants
 */
export enum EnumPrintfulPlatform {
	Amazon			= 'amazon',
	eBay			= 'ebay',
	Etsy			= 'etsy',
	Shopify			= 'shopify'
}

export enum EnumPrintfulPriceType {
	FixPrice		= 'fix_price',
	Profit			= 'profit'
}

export enum EnumPrintfulAmazonSellerAccount {
	America			= 'america',
	Europe			= 'europe'
}

export enum EnumPrintfulAmazonMarketplace {
	US				= 'us',
	MX				= 'mx',
	CA				= 'ca',
	JP				= 'jp',
	DE				= 'de',
	FR				= 'fr',
	IT				= 'it',
	ES				= 'es',
	UK				= 'uk',
	NL				= 'nl'
}

export enum EnumPrintfulStyle {
	Unisex			= 'unisex',
	Men				= 'men',
	Women			= 'women'
}


/**------------------------------------------------------
 * Printful Template
 */
export interface IPrintfulTemplateOptions {
	general 	: {
		platform 	: EnumPrintfulPlatform;
		language	: EnumLanguage;
		store		: string;
		price		: {
			type	: EnumPrintfulPriceType;
			value	: number;
		};
		amazonSpecific ?: {
			sellerAccount	: EnumPrintfulAmazonSellerAccount;
			marketplaces	: EnumPrintfulAmazonMarketplace[];
		};
	};
	template	: {
		name			: string;
		mockupStyle   	: string;
		mockupSection 	: string;
		mockupImage	  	: string;
	};
	productOptions	: {
		productName		: string;
		style  	 		: EnumPrintfulStyle;
		sizeInfo 		: {
			sizeGuide	: boolean;
			imperial	: boolean;
			metric		: boolean;
		};
		freeShipping	: boolean;
	};
}
