import { RxJsArray } from './utilities/rxjs-array';
import { RxJsBasic } from './utilities/rxjs-basic';
import { RxJsDate } from './utilities/rxjs-date';
import { RxJsError } from './utilities/rxjs-error';
import { RxJsEvent } from './utilities/rxjs-event';
import { RxJsHelper } from './utilities/rxjs-helper';
import { RxJsHttp } from './utilities/rxjs-http';
import { RxJsObject } from './utilities/rxjs-object';
import { RxJsSchedule } from './utilities/rxjs-scheduler';
import { RxJsSubject } from './utilities/rxjs-subject';


/**------------------------------------------------------
 * RxJs Utilities
 * > Dependencies: rxjs, util
 */
export class RxJs {


	/**------------------------------------------------------
	 * Simple Utilities
	 */
	static readonly Basic		: RxJsBasic 	= new RxJsBasic();
	static readonly Helper		: RxJsHelper 	= new RxJsHelper();
	static readonly Date		: RxJsDate 		= new RxJsDate();


	/**------------------------------------------------------
	 * Complex Utilities
	 */
	static readonly Array		: RxJsArray 	= new RxJsArray();
	static readonly Object		: RxJsObject 	= new RxJsObject();


	/**------------------------------------------------------
	 * Functional Utilities
	 */
	static readonly Http		: RxJsHttp 		= new RxJsHttp();
	static readonly Event		: RxJsEvent 	= new RxJsEvent();
	static readonly Error		: RxJsError 	= new RxJsError();
	static readonly Schedule	: RxJsSchedule 	= new RxJsSchedule();
	static readonly Subject		: RxJsSubject 	= new RxJsSubject();
}
