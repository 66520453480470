import { EnumSpreadshirtProduct, EnumPodMarketplace } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { SPREADSHIRT_SORTING_LIST } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const SPREADSHIRT_PRODUCT_LIST_UK: ISearchParameterConfig<EnumSpreadshirtProduct>[] = [
	{
		code		: EnumSpreadshirtProduct.All,
		name		: 'All Products',
		placeholders: { '[PRODUCT]': '' }
	},
	{
		code		: EnumSpreadshirtProduct.Shirt,
		name		: 'T-Shirt',
		placeholders: { '[PRODUCT]': 'clothing/t-shirts' }
	},
	{
		code		: EnumSpreadshirtProduct.VNeck,
		name		: 'V-Neck',
		placeholders: { '[PRODUCT]': 'clothing/t-shirts' },
		parameters	: { 'neckline' : 'N1' }
	},
	{
		code		: EnumSpreadshirtProduct.Hoodie,
		name		: 'Hoodie',
		placeholders: { '[PRODUCT]': 'clothing/hoodie-sweatshirts/hoodie' }
	},
	{
		code		: EnumSpreadshirtProduct.TankTop,
		name		: 'Tank Top',
		placeholders: { '[PRODUCT]': 'clothing/tank-tops' }
	},
	{
		code		: EnumSpreadshirtProduct.LongSleeve,
		name		: 'Long Sleeve',
		placeholders: { '[PRODUCT]': 'clothing/long-sleeved-shirts' }
	},
	{
		code		: EnumSpreadshirtProduct.Sweatshirt,
		name		: 'Sweatshirt',
		placeholders: { '[PRODUCT]': 'clothing/hoodie-sweatshirts/sweatshirts' }
	}
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const SPREADSHIRT_CONFIG_UK: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.CO_UK,
	websiteUrl		: {
		home		: 'https://www.spreadshirt.co.uk/',
		login		: 'https://www.spreadshirt.co.uk/login',
		search		: 'https://www.spreadshirt.co.uk/shop/[PRODUCT]/[SEARCH]/?page=[PAGE]'
	},
	pageLimit		: 167,
	sortings		: SPREADSHIRT_SORTING_LIST,
	products		: SPREADSHIRT_PRODUCT_LIST_UK,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};
