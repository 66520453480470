import { EnumCountry } from '@libs/constants';

import { EnumAmazonMarketplace } from '../pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Google Event Search - United States
 */
export const EVENT_SEARCH_MAPPING_US: IEventSearch[] = [
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'martin luther king jr. day',
		searchQuery	: 'martin luther king'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'first day of black history month',
		searchQuery	: 'black history'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `valentine's day`,
		searchQuery	: 'valentines day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `presidents' day`,
		searchQuery	: 'presidents day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `first day of women's history month`,
		searchQuery	: 'women history'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'daylight saving time starts',
		searchQuery	: 'daylight saving'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `st. patrick's day`,
		searchQuery	: 'st patricks'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'easter sunday',
		searchQuery	: 'easter sunday'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'easter monday',
		searchQuery	: 'easter monday'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'tax day',
		searchQuery	: 'tax day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'first day of asian pacific american heritage month',
		searchQuery	: 'american heritage'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'cinco de mayo',
		searchQuery	: 'cinco de mayo'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `mother's day`,
		searchQuery	: 'mothers day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'memorial day',
		searchQuery	: 'memorial day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'first day of lgbtq+ pride month',
		searchQuery	: 'pride'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'flag day',
		searchQuery	: 'flag day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `father's day`,
		searchQuery	: 'fathers day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'juneteenth',
		searchQuery	: 'juneteenth'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'day off for juneteenth',
		searchQuery	: 'juneteenth'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'independence day',
		searchQuery	: 'independence day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'labor day',
		searchQuery	: 'labor day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'first day of hispanic heritage month',
		searchQuery	: 'hispanic heritage'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `indigenous peoples' day`,
		searchQuery	: 'indigenous peoples'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'columbus day',
		searchQuery	: 'columbus day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'halloween',
		searchQuery	: 'halloween'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'first day of american indian heritage month',
		searchQuery	: 'indian heritage'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'daylight saving time ends',
		searchQuery	: 'daylight saving'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'election day',
		searchQuery	: 'election day'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'veterans day',
		searchQuery	: 'veteran'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'thanksgiving day',
		searchQuery	: 'thanksgiving'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'native american heritage day',
		searchQuery	: 'native american heritage'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'christmas eve',
		searchQuery	: 'christmas eve'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'christmas day',
		searchQuery	: 'christmas'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: 'day off for christmas day',
		searchQuery	: 'christmas'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `new year's eve`,
		searchQuery	: 'new years eve'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `new year's day`,
		searchQuery	: 'new year'
	},
	{
		country 	: EnumCountry.US,
		marketplace : EnumAmazonMarketplace.COM,
		eventName	: `day off for new year's day`,
		searchQuery	: 'new year'
	}
];


/**------------------------------------------------------
 * Google Event Search - Great Britten
 */
export const EVENT_SEARCH_MAPPING_GB: IEventSearch[] = [
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `st patrick's day (northern ireland)`,
		searchQuery	: 'st patricks'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'daylight saving time starts',
		searchQuery	: 'daylight saving'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'good friday',
		searchQuery	: 'good friday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'easter sunday',
		searchQuery	: 'easter sunday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'easter monday (regional holiday)',
		searchQuery	: 'easter monday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `st. george's day`,
		searchQuery	: 'st george'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'early may bank holiday',
		searchQuery	: 'bank holiday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'spring bank holiday',
		searchQuery	: 'bank holiday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `queen's platinum jubilee`,
		searchQuery	: 'queens jubilee'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `queen's birthday`,
		searchQuery	: 'queens birthday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'battle of the boyne (northern ireland)',
		searchQuery	: 'battle of boyne'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'summer bank holiday (scotland)',
		searchQuery	: 'bank holiday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'summer bank holiday (regional holiday)',
		searchQuery	: 'bank holiday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'daylight saving time ends',
		searchQuery	: 'daylight saving ends'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'halloween',
		searchQuery	: 'halloween'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'guy fawkes day',
		searchQuery	: 'guy fawkes'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'remembrance sunday',
		searchQuery	: 'remembrance sunday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `st andrew's day (scotland)`,
		searchQuery	: 'st andrew'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'christmas day',
		searchQuery	: 'christmas day'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'boxing day',
		searchQuery	: 'boxing day'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: 'substitute bank holiday for christmas day',
		searchQuery	: 'substitute bank holiday'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `new year's day`,
		searchQuery	: 'new year'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: `new year's day observed`,
		searchQuery	: 'new year observed'
	},
	{
		country 	: EnumCountry.UK,
		marketplace : EnumAmazonMarketplace.CO_UK,
		eventName	: '2nd january (substitute day) (scotland)',
		searchQuery	: '2nd january'
	}
];


/**------------------------------------------------------
 * Google Event Search - Germany
 */
export const EVENT_SEARCH_MAPPING_DE: IEventSearch[] = [
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'heilige drei könige (regionaler feiertag)',
		searchQuery	: 'heilige drei könige'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'valentinstag',
		searchQuery	: 'valentinstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'rosenmontag',
		searchQuery	: 'rosenmontag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'faschingsdienstag',
		searchQuery	: 'faschingsdienstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'aschermittwoch',
		searchQuery	: 'aschermittwoch'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'internationaler frauentag (regionaler feiertag)',
		searchQuery	: 'internationaler frauentag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'beginn der sommerzeit',
		searchQuery	: 'beginn der sommerzeit'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'palmsonntag',
		searchQuery	: 'palmsonntag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'gründonnerstag (regionaler feiertag)',
		searchQuery	: 'gründonnerstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'karfreitag',
		searchQuery	: 'karfreitag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'karsamstag (regionaler feiertag)',
		searchQuery	: 'karsamstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'ostern (regionaler feiertag)',
		searchQuery	: 'ostern'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'ostermontag',
		searchQuery	: 'ostermontag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'tag der arbeit',
		searchQuery	: 'tag der arbeit'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'muttertag',
		searchQuery	: 'muttertag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'jahrestag der befreiung vom nationalsozialismus (regionaler feiertag)',
		searchQuery	: 'jahrestag der befreiung'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'christi himmelfahrt',
		searchQuery	: 'christi himmelfahrt'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'vatertag',
		searchQuery	: 'vatertag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'pfingsten (regionaler feiertag)',
		searchQuery	: 'pfingsten'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'pfingstmontag',
		searchQuery	: 'pfingstmontag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'fronleichnam (regionaler feiertag)',
		searchQuery	: 'fronleichnam'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'hohes friedensfest augsburg (bayern)',
		searchQuery	: 'hohes friedensfest augsburg'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'mariä himmelfahrt (regionaler feiertag)',
		searchQuery	: 'mariä himmelfahrt'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'weltkindertag (thüringen)',
		searchQuery	: 'weltkindertag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'tag der deutschen einheit',
		searchQuery	: 'deutschen einheit'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'ende der sommerzeit',
		searchQuery	: 'ende der sommerzeit'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'reformationstag (regionaler feiertag)',
		searchQuery	: 'reformationstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'halloween',
		searchQuery	: 'halloween'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'allerheiligen (regionaler feiertag)',
		searchQuery	: 'allerheiligen'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'st. martin',
		searchQuery	: 'st martin'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'volkstrauertag',
		searchQuery	: 'volkstrauertag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'buß- und bettag (sachsen)',
		searchQuery	: 'buß und bettag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'totensonntag',
		searchQuery	: 'totensonntag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'erster advent',
		searchQuery	: 'erster advent'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'zweiter advent',
		searchQuery	: 'zweiter advent'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'nikolaustag',
		searchQuery	: 'nikolaustag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'dritter advent',
		searchQuery	: 'dritter advent'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'vierter advent',
		searchQuery	: 'vierter advent'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'erster weihnachtstag',
		searchQuery	: 'erster weihnachtstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'zweiter weihnachtstag',
		searchQuery	: 'zweiter weihnachtstag'
	},
	{
		country 	: EnumCountry.DE,
		marketplace : EnumAmazonMarketplace.DE,
		eventName	: 'neujahrstag',
		searchQuery	: 'neujahrstag'
	}
];


/**------------------------------------------------------
 * Google Event Search - France
 */
export const EVENT_SEARCH_MAPPING_FR: IEventSearch[] = [
	// cspell:disable
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: "heure d'été",
		searchQuery	: 'heure été'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'pâques',
		searchQuery	: 'pâques'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'le lundi de pâques',
		searchQuery	: 'lundi pâques'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'la fête du travail',
		searchQuery	: 'fête travail'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'fête de la victoire 1945',
		searchQuery	: 'fête victoire'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: `l'ascension`,
		searchQuery	: 'ascension'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'fête des mères',
		searchQuery	: 'fête mères'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'pentecôte',
		searchQuery	: 'pentecôte'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'le lundi de pentecôte',
		searchQuery	: 'lundi pentecôte'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'fête des pères',
		searchQuery	: 'fête pères'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'la fête nationale',
		searchQuery	: 'fête nationale'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: `l'assomption`,
		searchQuery	: 'assomption'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: `heure d'hiver`,
		searchQuery	: 'heure hiver'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'la toussaint',
		searchQuery	: 'toussaint'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: `l'armistice`,
		searchQuery	: 'armistice'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'la veille de noël',
		searchQuery	: 'veille noël'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'noël',
		searchQuery	: 'noël'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: 'la saint-sylvestre',
		searchQuery	: 'saint sylvestre'
	},
	{
		country 	: EnumCountry.FR,
		marketplace : EnumAmazonMarketplace.FR,
		eventName	: `jour de l'an`,
		searchQuery	: 'jour de an'
	}
	// cspell:enable
];


/**------------------------------------------------------
 * Google Event Search - Italy
 */
export const EVENT_SEARCH_MAPPING_IT: IEventSearch[] = [
	// cspell:disable
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'epifania',
		searchQuery	: 'epifania'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'venerdi santo',
		searchQuery	: 'venerdi santo'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'pasqua',
		searchQuery	: 'pasqua'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'lunedì di pasquetta',
		searchQuery	: 'lunedì pasquetta'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'liberazione',
		searchQuery	: 'liberazione'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'festa del lavoro',
		searchQuery	: 'festa lavoro'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'festa della repubblica',
		searchQuery	: 'festa repubblica'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'assunzione',
		searchQuery	: 'assunzione'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'ferragosto',
		searchQuery	: 'ferragosto'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'tutti i santi',
		searchQuery	: 'tutti santi'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'immacolata',
		searchQuery	: 'immacolata'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'natale',
		searchQuery	: 'natale'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'santo stefano',
		searchQuery	: 'santo stefano'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'san silvestro',
		searchQuery	: 'san silvestro'
	},
	{
		country 	: EnumCountry.IT,
		marketplace : EnumAmazonMarketplace.IT,
		eventName	: 'capodanno',
		searchQuery	: 'capodanno'
	}
	// cspell:enable
];


/**------------------------------------------------------
 * Google Event Search - Spain
 */
export const EVENT_SEARCH_MAPPING_ES: IEventSearch[] = [
	// cspell:disable
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'epifanía del señor',
		searchQuery	: 'epifanía señor'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san ildefonso (toledo)',
		searchQuery	: 'san ildefonso'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'el día de san valero (zaragoza)',
		searchQuery	: 'san valero'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de san valentín',
		searchQuery	: 'san valentín'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de andalucía (andalucía)',
		searchQuery	: 'andalucía'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'dia de las islas baleares (baleares)',
		searchQuery	: 'islas baleares'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'miércoles de ceniza',
		searchQuery	: 'miércoles de ceniza'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'cincomarzada (zaragoza)',
		searchQuery	: 'cincomarzada'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san josé (festivo regional)',
		searchQuery	: 'san josé'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'cambio de horario de verano',
		searchQuery	: 'cambio horario verano'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'domingo de ramos',
		searchQuery	: 'domingo ramos'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'jueves santo (festivo regional)',
		searchQuery	: 'jueves santo'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'sermón de las tortillas (teruel)',
		searchQuery	: 'sermón tortillas'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'viernes santo',
		searchQuery	: 'viernes santo'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'domingo de pascua',
		searchQuery	: 'domingo pascua'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'lunes de pascua (festivo regional)',
		searchQuery	: 'lunes pascua'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'santa faz (alicante)',
		searchQuery	: 'santa faz'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de castilla y león (castilla y león)',
		searchQuery	: 'día castilla león'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san jorge. día de aragón (aragón)',
		searchQuery	: 'día aragón'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san prudencio (álava)',
		searchQuery	: 'san prudencio'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día del trabajador (festivo regional)',
		searchQuery	: 'día del trabajador'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'el dia de la madre',
		searchQuery	: 'la madre'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la communidad madrid (madrid)',
		searchQuery	: 'communidad madrid'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'lunes siguiente a la fiesta del trabajo (festivo regional)',
		searchQuery	: 'fiesta del trabajo'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'eid al-fitr (melilla)',
		searchQuery	: 'eid al-fitr'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'dia de san isidro (madrid)',
		searchQuery	: 'san isidro'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de las letras gallegas (galicia)',
		searchQuery	: 'letras gallegas'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'festividad del día de canarias (canarias)',
		searchQuery	: 'canarias'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la región de castilla-la mancha (castilla-la mancha)',
		searchQuery	: 'castilla-la mancha'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'domingo de pentecostés',
		searchQuery	: 'domingo pentecostés'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'lunes de pentecostés (cataluña)',
		searchQuery	: 'lunes pentecostés'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la región de murcia (murcia)',
		searchQuery	: 'región de murcia'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la rioja (la rioja)',
		searchQuery	: 'la rioja'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san antonio (ceuta)',
		searchQuery	: 'san antonio'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'corpus christi (castilla-la mancha)',
		searchQuery	: 'corpus christi'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san juan (festivo regional)',
		searchQuery	: 'san juan'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'la revetlla de sant joan (festivo regional)',
		searchQuery	: 'sant joan'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'eid al-adha (festivo regional)',
		searchQuery	: 'eid al-adha'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'fiestas de las vaquillas (teruel)',
		searchQuery	: 'las vaquillas'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'santiago apóstol (festivo regional)',
		searchQuery	: 'santiago apóstol'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de las instituciones (cantabria)',
		searchQuery	: 'las instituciones'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'virgen blanca (álava)',
		searchQuery	: 'virgen blanca'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de nuestra señora de áfrica (ceuta)',
		searchQuery	: 'señora de áfrica'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'la asunción de la virgen',
		searchQuery	: 'asunción virgen'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'sant magí (tarragona)',
		searchQuery	: 'sant magí'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la ciudad autónoma de ceuta (ceuta)',
		searchQuery	: 'autónoma ceuta'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'el día de elcano (país vasco)',
		searchQuery	: ''
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'virgen de la victoria (melilla)',
		searchQuery	: 'elcano'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'virgen de los llanos (albacete)',
		searchQuery	: 'virgen los llanos'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de extremadura (extremadura)',
		searchQuery	: 'extremadura'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de asturias (asturias)',
		searchQuery	: 'asturias'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día nacional de cataluña (cataluña)',
		searchQuery	: 'nacional cataluña'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'nuestra señora de la bien aparecida (cantabria)',
		searchQuery	: 'señora bien aparecida'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de melilla (melilla)',
		searchQuery	: 'melilla'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'santa tecla (tarragona)',
		searchQuery	: 'santa tecla'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'la mercè (barcelona)',
		searchQuery	: 'la mercè'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la comunidad valenciana (valenciana)',
		searchQuery	: 'comunidad valenciana'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la hispanidad',
		searchQuery	: 'la hispanidad'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'cambio de horario de invierno',
		searchQuery	: 'cambio horario invierno'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de todos los santos (festivo regional)',
		searchQuery	: 'todos los santos'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'la almudena (madrid)',
		searchQuery	: 'almudena'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de navarra (navarra)',
		searchQuery	: 'navarra'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la constitución española',
		searchQuery	: 'constitución española'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'la inmaculada concepción',
		searchQuery	: 'inmaculada concepción'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'santa leocadia (toledo)',
		searchQuery	: 'santa leocadia'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'nochebuena',
		searchQuery	: 'nochebuena'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'navidad (festivo regional)',
		searchQuery	: 'navidad'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'san esteban (festivo regional)',
		searchQuery	: 'san esteban'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'lunes posterior a la segunda fiesta de navidad (festivo regional)',
		searchQuery	: 'lunes fiesta de navidad'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la sagrada familia',
		searchQuery	: 'sagrada familia'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'nochevieja',
		searchQuery	: 'nochevieja'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'año nuevo',
		searchQuery	: 'año nuevo'
	},
	{
		country 	: EnumCountry.ES,
		marketplace : EnumAmazonMarketplace.ES,
		eventName	: 'día de la toma (granada)',
		searchQuery	: 'día de toma'
	}
	// cspell:enable
];


/**------------------------------------------------------
 * All Event Search Mappings
 */
export const EVENT_SEARCH_MAPPING: IEventSearch[] = [
	...EVENT_SEARCH_MAPPING_US,
	...EVENT_SEARCH_MAPPING_GB,
	...EVENT_SEARCH_MAPPING_DE,
	...EVENT_SEARCH_MAPPING_FR,
	...EVENT_SEARCH_MAPPING_IT,
	...EVENT_SEARCH_MAPPING_ES
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IEventSearch {
	country		: EnumCountry;
	marketplace : EnumAmazonMarketplace;
	eventName	: string;
	searchQuery	: string;
}
