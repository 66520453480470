import { INgConformModalButton } from '../conform-modal.interface';
import { INgFaIcon } from '../../../../styling-components';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgConfirmModalTypes {
	DefaultConfirm 	= 'default_confirm',
	DefaultOption	= 'default_option',
	SuccessConfirm	= 'success_confirm',
	SuccessOption	= 'success_option',
	InfoConfirm		= 'info_confirm',
	InfoOption 		= 'info_option',
	WarningConfirm	= 'warning_confirm',
	WarningOption	= 'warning_option',
	ErrorConfirm	= 'error_confirm',
	ErrorOption		= 'error_option'
}


/**---------------------------------------------
 * Modal Style / Definition
 */
export interface INgConfirmModalConfigs {
	[EnumNgConfirmModalTypes.DefaultConfirm] : INgConformModalConfig;
	[EnumNgConfirmModalTypes.DefaultOption]	 : INgConformModalConfig;
	[EnumNgConfirmModalTypes.SuccessConfirm] : INgConformModalConfig;
	[EnumNgConfirmModalTypes.SuccessOption]	 : INgConformModalConfig;
	[EnumNgConfirmModalTypes.InfoConfirm]	 : INgConformModalConfig;
	[EnumNgConfirmModalTypes.InfoOption]	 : INgConformModalConfig;
	[EnumNgConfirmModalTypes.WarningConfirm] : INgConformModalConfig;
	[EnumNgConfirmModalTypes.WarningOption]	 : INgConformModalConfig;
	[EnumNgConfirmModalTypes.ErrorConfirm]	 : INgConformModalConfig;
	[EnumNgConfirmModalTypes.ErrorOption]	 : INgConformModalConfig;
}

export interface INgConformModalConfig {					// used to define the pre defined modals
	style		: {
		color	: string;
		icon	: INgFaIcon;
	};
	buttons 	: INgConformModalButton[];
}
