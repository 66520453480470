import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractHttp } from '../../http.abstract';
import { IAuthStorageService } from '../../../services/shared/auth/auth-security/access-token/access-token.interface';
import { ILoginParams, ILoginResponse, ISignUpParams, IEmailResponse, IVerifyParams, IRecoverPassVerifyParams, IRefreshTokenParams, ITokenResponse } from './auth.interface';


@Injectable({
	providedIn: 'root'
})
export class AuthRequest extends AbstractHttp {

	constructor(
		@Inject('IAuthStorageService')
		private   authStorageService: IAuthStorageService,
		protected http				: HttpClient
	) {
		super(http);
	}


	/**------------------------------------------------------
	 * Authentication API
	 */
	login(params: ILoginParams): Observable<ILoginResponse> {
		this.authStorageService.onSignIn(params.email);
		return this.http.post<ILoginResponse>(`${this.SERVER_URL}auth/login`, params);
	}

	logout(): Observable<object> {
		return this.http.post(`${this.SERVER_URL}user/logout`, {});
	}

	signUp(params: ISignUpParams): Observable<IEmailResponse> {
		return this.http.post<IEmailResponse>(`${this.SERVER_URL}auth/signup`, params);
	}

	recoverPasswordSendOtp(params: IVerifyParams): Observable<IEmailResponse> {
		return this.http.post<IEmailResponse>(`${this.SERVER_URL}auth/recover-password/send-otp`, params);
	}

	recoverPasswordVerify(params: IRecoverPassVerifyParams): Observable<object> {
		return this.http.post(`${this.SERVER_URL}auth/recover-password`, params);
	}

	resendVerificationEmail(params: IVerifyParams): Observable<object> {
		return this.http.post(`${this.SERVER_URL}auth/resend/verify-mail`, params);
	}

	getRefreshTokenApi(params: IRefreshTokenParams): Observable<ITokenResponse> {
		return this.http.post<ITokenResponse>(`${this.SERVER_URL}user/refresh-token`, params);
	}
}
