import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { FREEPIK_CONFIG, FREEPIK_DEFAULT_OPTIONS, EnumFreepikSorting, EnumFreepikProduct, IFreepikSearchOptions } from './freepik-search.data';


/**------------------------------------------------------
 * Freepik Search Url
 */
export class FreepikSearch extends AbstractSearchLinks<IFreepikSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumFreepikSorting>[] {
		return FREEPIK_CONFIG.sortings as ISearchParameterConfig<EnumFreepikSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumFreepikProduct>[] {
		return FREEPIK_CONFIG.products as ISearchParameterConfig<EnumFreepikProduct>[];
	}

	constructor() {
		super(FREEPIK_CONFIG, FREEPIK_DEFAULT_OPTIONS);
	}
}
