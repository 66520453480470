/**------------------------------------------------------
 * Paddle Webhook alert types
 */
export enum EnumPaddleWebhook {
	SubsCreated				= 'subscription_created',
	SubsUpdated				= 'subscription_updated',
	SubsCancelled			= 'subscription_cancelled',
	SubsPaymentFailed		= 'subscription_payment_failed',
	SubsPaymentSuccess		= 'subscription_payment_succeeded',
	SubsPaymentRefund		= 'subscription_payment_refunded'
}


/**------------------------------------------------------
 * Paddle events status
 */
export enum EnumPaddleEventsStatus {
	SubsActiveStatus		= 'active',
	SubsTrialingStatus		= 'trialing',
	SubsPastDueStatus		= 'past_due',
	SubsPausedStatus		= 'paused',
	SubsDeletedStatus		= 'deleted'
}


/**------------------------------------------------------
 * Paddle Configs
 */
export enum EnumPaddleConfigs {
	IdentifierLength = 32
}
