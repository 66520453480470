import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { ITmTrademarkEntry } from '../interfaces';
import { ITmFilterOptions, TmFilterOptionsSchema } from './shared/filter-options.interface';


/**------------------------------------------------------
 * Configurations
 */
export const TM_SEARCH_LIMITS: ITmSearchConfig = {
	SEARCH_TEXT_LENGTH		: 500,
	APPLICANT_NAME_LENGTH	: 500
};

interface ITmSearchConfig {
	SEARCH_TEXT_LENGTH		: number;
	APPLICANT_NAME_LENGTH	: number;
}


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumTmSearchType {
	Is		  		= 'is',						// should be exact match to the word
	BeginsWith		= 'begins',
	EndsWith		= 'ends',
	Contains		= 'contains',				// should contain the exact word
	PhraseCheck		= 'phrase',					// multiple tm names can be exist inside give sentence or phrase
	WordCheck		= 'wordcheck'				// multiple words are searched with fuzzy search
}


/**------------------------------------------------------
 * Search Request
 */
export interface ITmSearchRequest extends ITmFilterOptions {
	searchType		 : EnumTmSearchType;		// has to be a valid search type
	searchText		 : string;					// can not be empty
	applicantName	?: string;
}


/**------------------------------------------------------
 * Search Response
 */
export interface ITmSearchResponse {
	entries			: ITmTrademarkEntry[];
	totalCount		: number;
}


/**------------------------------------------------------
 * Schemas
 */
export class TmSearchRequestSchema extends TmFilterOptionsSchema implements ValidatorSchema<ITmSearchRequest> {
	searchType		= Validator.Enum(EnumTmSearchType);
	searchText		= Validator.String().optional().isMaxLength(TM_SEARCH_LIMITS.SEARCH_TEXT_LENGTH).hasNoHtmlScripts();
	applicantName	= Validator.String().optional().isMinLength(1).isMaxLength(TM_SEARCH_LIMITS.APPLICANT_NAME_LENGTH).hasNoHtmlScripts();
}
