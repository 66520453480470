import { EnumAmazonMarketplace } from '../../../app-constants/pod-platforms/amazon.constants';
import { IGoogleKeywordData } from '../google/google-keyword.interface';
import { EnumKeywordProcess } from '../keyword.interface';


/**------------------------------------------------------
 * Composite Amazon Keyword
 * ------------------------
 * > Main KW data for Amazon Pages
 * > Combining Google and Amazon Keywords
 */
export interface IAmazonCompositeKeywordData {
	keyword     	: string;						// 'dog'
	marketplace		: EnumAmazonMarketplace;
	amazon			: IAmazonKeywordData;
	google			: IGoogleKeywordData;
}


/**------------------------------------------------------
 * Keyword Data
 */
export interface IAmazonKeywordData {
	keyword     	: string;						// 'dog'
	marketplace		: EnumAmazonMarketplace;

	// related keywords to the search term
	suggestions		: string[];

	// competition on amazon (mba & fba products)
	competition		: IAmazonKeywordCompetition;

	createdBy		: EnumKeywordProcess;
}


/**------------------------------------------------------
 * Amazon Competition
 * ------------------
 * > The competition data is scraped from amazon by
 * > analyzing the first 3 product pages. The keyword is
 * > thereby searched as "{keyword} T-Shirt", because
 * > T-Shirt is the most popular clothing term and was
 * > added by amazon for all mba products.
 * ------------------
 * Infos:
 * > In other words, Amazon’s Choice is determined by SEO,
 * > and the Best Seller badge is determined by sales velocity.
 * > src: https://www.sellerlabs.com/blog/amazon-choice/#:~:text=Amazon's%20Choice%20is%20a%20label,title%20on%20product%20detail%20pages.
 */
export interface IAmazonKeywordCompetition {
	asinLists		: {
		general		: string[];						// ['B08YNC452C', ...] >> saved in another collection
		clothing	: string[];
		book		: string[];
		sponsored 	: string[];
	};
	totalProducts	: number;
	categories		: string[];
	brands			: string[];						// extracted from ranking products
	reviews			: {
		count		: IAmazonStatistic;
		stars		: IAmazonStatistic;
	};
	price			: IAmazonStatistic;
}

export interface IAmazonStatistic {
	page1			: number;
	avg				: number;
	min				: number;
	max				: number;
}
