/**------------------------------------------------------
 * Check for Keyword Limitations
 * -----------------------------
 * > These are our own limits for the keywords
 */
export class FlyingKeywordLimitation {

	//** Configurations */
	readonly MAX_KEYWORD_LENGTH: number = 200;
	readonly MAX_KEYWORD_WORDS : number = 40;

	isValid(keyword: string): boolean {
		const wordCount : number = keyword.split(' ').length;
		const textLength: number = keyword.length;
		return wordCount <= this.MAX_KEYWORD_WORDS && textLength <= this.MAX_KEYWORD_LENGTH;
	}

	filterByLimitation(keywords: string[]): string[] {
		return keywords.filter((keyword: string) => this.isValid(keyword));
	}
}
