import { ColorCodeName } from '@libs/libraries/shared';

import { ImageHandlerService } from './img-handler.service';
import { WorkerAssistantService } from '../../worker-assistant/worker-assistant.service';
import { ColorExtractionCore } from './color-extraction/core/color-extraction.core';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class ImageHandlerServiceFactory {

	static create(): ImageHandlerService {
		return new ImageHandlerService(
			new WorkerAssistantService(),
			new ColorExtractionCore(
				new ColorCodeName()
			)
		);
	}
}
