import { EnumSpreadshirtProduct, EnumPodMarketplace } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { SPREADSHIRT_SORTING_LIST } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const SPREADSHIRT_PRODUCT_LIST_DE: ISearchParameterConfig<EnumSpreadshirtProduct>[] = [
	{
		code		: EnumSpreadshirtProduct.All,
		name		: 'All Products',
		placeholders: { '[PRODUCT]': '' }
	},
	{
		code		: EnumSpreadshirtProduct.Shirt,
		name		: 'T-Shirt',
		placeholders: { '[PRODUCT]': 'bekleidung/t-shirts' }
	},
	{
		code		: EnumSpreadshirtProduct.VNeck,
		name		: 'V-Neck',
		placeholders: { '[PRODUCT]': 'bekleidung/t-shirts' },
		parameters	: { 'neckline' : 'N1' }
	},
	{
		code		: EnumSpreadshirtProduct.Hoodie,
		name		: 'Hoodie',
		placeholders: { '[PRODUCT]': 'bekleidung/pullover-hoodie/hoodie' }
	},
	{
		code		: EnumSpreadshirtProduct.TankTop,
		name		: 'Tank Top',
		placeholders: { '[PRODUCT]': 'bekleidung/tank-tops' }
	},
	{
		code		: EnumSpreadshirtProduct.LongSleeve,
		name		: 'Long Sleeve',
		placeholders: { '[PRODUCT]': 'bekleidung/langarmshirts' }
	},
	{
		code		: EnumSpreadshirtProduct.Sweatshirt,
		name		: 'Sweatshirt',
		placeholders: { '[PRODUCT]': 'bekleidung/pullover-hoodie/pullover' }
	}
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const SPREADSHIRT_CONFIG_DE: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.DE,
	websiteUrl		: {
		home		: 'https://www.spreadshirt.de/',
		login		: 'https://www.spreadshirt.de/login',
		search		: 'https://www.spreadshirt.de/shop/[PRODUCT]/[SEARCH]/?page=[PAGE]'
	},
	pageLimit		: 167,
	sortings		: SPREADSHIRT_SORTING_LIST,
	products		: SPREADSHIRT_PRODUCT_LIST_DE,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};
