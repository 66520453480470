import { EnumSearchLinkPlatform } from '@apps/flying/shared';

import { EnumSidebarPage } from '../../../../components/layout/sidebar/sidebar-pages.interface';


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IPageStates extends Record<EnumSidebarPage, Record<string, any>> {

	//** Research Pages */
	[EnumSidebarPage.ResearchBestSeller]		: Record<string, any>;
	[EnumSidebarPage.ResearchArchive]			: Record<string, any>;
	[EnumSidebarPage.ResearchRtr]				: Record<string, any>;
	[EnumSidebarPage.ResearchSellerTrends]		: Record<string, any>;
	[EnumSidebarPage.ResearchGetInspiredPod]	: Record<string, any>;
	[EnumSidebarPage.ResearchBrands]			: Record<string, any>;
	[EnumSidebarPage.ResearchCategories]		: Record<string, any>;
	[EnumSidebarPage.ResearchNicheAnalysis]		: Record<string, any>;
	[EnumSidebarPage.TrademarkSearch]			: Record<string, any>;
	[EnumSidebarPage.ResearchDeleted]			: Record<string, any>;
	[EnumSidebarPage.KeywordWorkbenchModal]		: Record<string, any>;
	[EnumSidebarPage.KeywordBoardPinnedOptions]	: Record<string, any>;
	[EnumSidebarPage.KeywordSearch]				: Record<string, any>;
	[EnumSidebarPage.KeywordCompetition]		: Record<string, any>;
	[EnumSidebarPage.KeywordHunter]				: Record<string, any>;
	[EnumSidebarPage.ProductExplorer]			: Record<string, any>;
	[EnumSidebarPage.KeywordSuggestions]		: Record<string, any>;
	[EnumSidebarPage.KeywordWorkbench]			: Record<string, any>;
	[EnumSidebarPage.KeywordTagGenerator]		: Record<string, any>;
	[EnumSidebarPage.KeywordLists]				: Record<string, any>;
	[EnumSidebarPage.SponsoredLookup]			: Record<string, any>;
	[EnumSidebarPage.AdvertisingSuggestions]	: Record<string, any>;
	[EnumSidebarPage.AdvertisingLists]			: Record<string, any>;
	[EnumSidebarPage.RankPositioning]			: Record<string, any>;
	[EnumSidebarPage.NicheFinder]				: Record<string, any>;
	[EnumSidebarPage.PromptSearch]				: Record<string, any>;
	[EnumSidebarPage.QuotesAndPuns]				: Record<string, any>;

	//** Upload Pages */
	[EnumSidebarPage.UploadPodEdit]				: Record<string, any>;
	[EnumSidebarPage.UploadPodStatistics]		: Record<string, any>;
	[EnumSidebarPage.UploadPodDesignManagement]	: Record<string, any>;
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypePages			= IPageStates[keyof IPageStates];
export type TypePagesItemKey 	= keyof IPageStates[keyof IPageStates];
export type TypePagesItem 		= IPageStates[keyof IPageStates][keyof IPageStates[keyof IPageStates]];


/**------------------------------------------------------
 * Default Data
 */
export const INITIAL_PAGE_STATE_OBJECT: Partial<IPageStates> = {

	//** Research Pages */
	[EnumSidebarPage.ResearchBestSeller]		: {},
	[EnumSidebarPage.ResearchArchive]			: {},
	[EnumSidebarPage.ResearchRtr] 				: {},
	[EnumSidebarPage.ResearchSellerTrends]	 	: {},
	[EnumSidebarPage.ResearchGetInspiredPod]	: {
		selectedPlatforms 						: [],
		searchOpenLocation 						: '',
		[EnumSearchLinkPlatform.Amazon]			: {},
		[EnumSearchLinkPlatform.AmazonMBA]		: {},
		[EnumSearchLinkPlatform.AmazonKDP]		: {},
		[EnumSearchLinkPlatform.Displate]		: {},
		[EnumSearchLinkPlatform.Redbubble]		: {},
		[EnumSearchLinkPlatform.Society6]		: {},
		[EnumSearchLinkPlatform.Teepublic]		: {},
		[EnumSearchLinkPlatform.Zazzle]			: {},
		[EnumSearchLinkPlatform.Spreadshirt]	: {},
		[EnumSearchLinkPlatform.GoogleSearch]	: {},
		[EnumSearchLinkPlatform.GoogleImages]	: {},
		[EnumSearchLinkPlatform.GoogleTrends]	: {},
		[EnumSearchLinkPlatform.Wikipedia]		: {},
		[EnumSearchLinkPlatform.Instagram]		: {},
		[EnumSearchLinkPlatform.Pinterest]		: {},
		[EnumSearchLinkPlatform.Twitter]		: {},
		[EnumSearchLinkPlatform.Social9Gag]		: {},
		[EnumSearchLinkPlatform.Freepik]		: {},
		[EnumSearchLinkPlatform.Pexels]			: {},
		[EnumSearchLinkPlatform.Pixabay]		: {},
		[EnumSearchLinkPlatform.ShutterStock]	: {},
		[EnumSearchLinkPlatform.Vexels]			: {},
		[EnumSearchLinkPlatform.Etsy]			: {}
	},
	[EnumSidebarPage.ResearchBrands]			: {},
	[EnumSidebarPage.ResearchCategories]		: {},
	[EnumSidebarPage.ResearchNicheAnalysis]	 	: {},
	[EnumSidebarPage.TrademarkSearch]		 	: {},
	[EnumSidebarPage.ResearchDeleted]	 		: {},
	[EnumSidebarPage.KeywordWorkbenchModal]		: {},
	[EnumSidebarPage.KeywordBoardPinnedOptions]	: [],
	[EnumSidebarPage.KeywordSearch]				: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		},
		advancedOptions : {},
		kwTableColumns 	: []
	},
	[EnumSidebarPage.KeywordCompetition]		: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		},
		productColumns	: [],
		kwTableColumns 	: []
	},
	[EnumSidebarPage.KeywordHunter]				: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		},
		advancedOptions : {},
		kwTableColumns 	: []
	},
	[EnumSidebarPage.ProductExplorer]			: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		}
	},
	[EnumSidebarPage.KeywordSuggestions]		: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		}
	},
	[EnumSidebarPage.KeywordWorkbench]			: {},
	[EnumSidebarPage.KeywordTagGenerator]		: {},
	[EnumSidebarPage.KeywordLists]				: {
		kwTableColumns 	: []
	},
	[EnumSidebarPage.SponsoredLookup]			: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		},
		productColumns	: [],
		kwTableColumns 	: []
	},
	[EnumSidebarPage.AdvertisingSuggestions]	: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		},
		advancedOptions : {},
		kwTableColumns 	: []
	},
	[EnumSidebarPage.RankPositioning]			: {
		search 			: {},
		searchCooldown	: {
			cooldownTime	: 0,
			searchedDate	: new Date(null)
		},
		kwTableColumns 	: []
	},
	[EnumSidebarPage.AdvertisingLists]			: {
		kwTableColumns 	: []
	},
	[EnumSidebarPage.NicheFinder]	 			: {},
	[EnumSidebarPage.PromptSearch]	 			: {},
	[EnumSidebarPage.QuotesAndPuns]	 			: {},

	//** Upload Pages */
	[EnumSidebarPage.UploadPodEdit] 			: {
		scripted_replace: [],
		kwTableColumns 	: []
	},
	[EnumSidebarPage.UploadPodStatistics]		: {
		showModalDate	: null
	},
	[EnumSidebarPage.UploadPodDesignManagement]	: {}
};
