/**------------------------------------------------------
 * Shirtee Template
 */
export interface IShirteeTemplateOptions {
	campaignId 		: string;
	options			: {
		shop				: string;
		legalProof			: string;
		notOnMarketplace	: string;
		expressApproval		: string;
	};
}
