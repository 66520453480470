import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumAppInfoTypes } from '@apps/flying/shared';

import { AbstractHttp } from '../../http.abstract';
import { IMaintenanceFlags } from '../../../services/shared/states/sub-states/maintenance.interface';


@Injectable({
	providedIn: 'root'
})
export class AppInfoRequest extends AbstractHttp {

	constructor(
		protected http: HttpClient
	) {
		super(http);
	}

	getAppInfo(infoType: EnumAppInfoTypes): Observable<IResearchInfo | IMaintenanceFlags> {
		return this.http.get(`${this.SERVER_URL}user/app-info/${infoType}`).pipe(map((response: any) => response.info));
	}
}


//** Interfaces --------------------------------- */
export interface IResearchInfo {
	news	: INews[];
	version	: IVersion[];
	roadmap	: IRoadMap[];
}

export interface INews {
	title	: string;
	massage	: string;
	date	: string;
}

export interface IVersion {
	title	: string;
	bullets	: string[];
	isNew	: boolean;
}

export interface IRoadMap {
	title	: string;
	date	: string;
	developing: boolean;
}
