import { Util } from '@libs/utilities/util';

import { IPatienceDiff, IPatienceDiffMoved } from './text-patience-diff.interface';
import { patienceDiff as patienceDiffLibrary, patienceDiffPlus as patienceDiffPlusLibrary } from './algorithms/algorithm';


/**------------------------------------------------------
 * Text Difference
 */
export class TextPatienceDiff {

	/**------------------------------------------------------
	 * Simple Text Difference
	 */
	patienceDiff(aLines: string[], bLines: string[]): IPatienceDiff {
		this.checkInput(aLines, bLines);
		return patienceDiffLibrary(aLines, bLines, true) as IPatienceDiff;
	}

	patienceDiffChar(aText: string, bText: string): IPatienceDiff {
		if (Util.String.isEmpty(aText)) throw new Error(`TextPatienceDiff => checkInput => FATAL ERROR: provided aText of "${aText}" is empty`);
		if (Util.String.isEmpty(bText)) throw new Error(`TextPatienceDiff => checkInput => FATAL ERROR: provided bText of "${aText}" is empty`);
		return this.patienceDiff(aText.split(''), bText.split(''));
	}


	/**------------------------------------------------------
	 * Advanced Text Difference (adding moved)
	 */
	patienceDiffMoved(aLines: string[], bLines: string[]): IPatienceDiffMoved {
		this.checkInput(aLines, bLines);
		return patienceDiffPlusLibrary(aLines, bLines, true) as IPatienceDiffMoved;
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private checkInput(aLines: string[], bLines: string[]) {
		if (!Util.Array.isArray(aLines) || Util.Array.isEmpty(aLines)) throw new Error(`TextPatienceDiff => checkInput => FATAL ERROR: provided aLines is not matching an array signature or is an empty array`);
		if (!Util.Array.isArray(bLines) || Util.Array.isEmpty(bLines)) throw new Error(`TextPatienceDiff => checkInput => FATAL ERROR: provided bLines is not matching an array signature or is an empty array`);
	}
}
