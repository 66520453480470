/* eslint-disable @typescript-eslint/typedef */


/**------------------------------------------------------
 * Theme Color Configurations
 */
export const RESEARCH_THEME = {
	'gradient-dark'	 	: '#153d77',
	'gradient-light'	: '#005fe8',
	'content'	 		: '#3b7ddd',
	'content-background': '#153d77'
};

export const UPLOAD_THEME = {
	'gradient-dark'	 	: '#d92b00',
	'gradient-light'	: '#f7780f',
	'content'	 		: '#f7780f',
	'content-background': '#ff6e0d',
	'digital-vault' 	: {
		'gradient-top'		: '#d92b00d1',
		'gradient-bottom'	: '#f7780fd1'
	}
};
