import { EnumSpreadshirtProduct, EnumSpreadshirtSorting } from '../../../../../../constants/research';
import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AbstractSearchLinks } from '../../../../data/search-links.abstract';
import { SPREADSHIRT_CONFIG_FR } from './spreadshirt-fr.data';
import { ISpreadshirtSearchOptions, SPREADSHIRT_DEFAULT_OPTIONS } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Spreadshirt FR Search Links
 */
export class SpreadshirtFrSearch extends AbstractSearchLinks<ISpreadshirtSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumSpreadshirtSorting>[] {
		return SPREADSHIRT_CONFIG_FR.sortings as ISearchParameterConfig<EnumSpreadshirtSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumSpreadshirtProduct>[] {
		return SPREADSHIRT_CONFIG_FR.products as ISearchParameterConfig<EnumSpreadshirtProduct>[];
	}

	constructor() {
		super(SPREADSHIRT_CONFIG_FR, SPREADSHIRT_DEFAULT_OPTIONS);
	}
}
