import { TemplateRef } from '@angular/core';

import { EnumNgMessageToastType } from './message-toast/message-toast.interface';
import { INgFaIcon } from '../../../styling-components';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumNgToastPlacement {
	TopLeft			= 'top-left',
	TopRight		= 'top-right',
	BottomLeft		= 'bottom-left',
	BottomRight		= 'bottom-right'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface INgMessageToastInput {
	title		: string;
	message		: TemplateRef<any> | string;
	type		: EnumNgMessageToastType;
	progress	: number;
	icon		: INgFaIcon;
	placement	: EnumNgToastPlacement;
	actions		: INgMessageToastButton[];
}

export interface INgMessageToastButton {
	label		: string;
	execute		: Function;
}
