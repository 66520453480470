import { EnumFileType } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Configurations
 */
export const FLYING_DESIGNS_IMAGE_LIMITATION: IFlyingDesignImageConfig = {
	FILE_NAME_LENGTH: 266,
	MAX_FILE_SIZE	: 83886080,  	// 80 megabyte in bytes
	MAX_TOTAL_SIZE	: 83886080  	// 80 megabyte in bytes
};

interface IFlyingDesignImageConfig {
	FILE_NAME_LENGTH: number;
	MAX_FILE_SIZE	: number;
	MAX_TOTAL_SIZE	: number;
}


/**------------------------------------------------------
 * Image Data Enum Definitions
 */
export enum EnumFlyingDesignImageTypes {
	Icon 	 	= 'icon',
	Preview  	= 'preview',
	Original 	= 'original'
}

export enum EnumFlyingDesignImageColorType {
	Light 		= 'light',
	Dark 		= 'dark'
}

export enum EnumFlyingDesignImageColor {
	Aqua 		= 'aqua',
	Black 		= 'black',
	Blue 		= 'blue',
	Gray 		= 'gray',
	Green 		= 'green',
	Lime 		= 'lime',
	Maroon 		= 'maroon',
	Olive 		= 'olive',
	Orange 		= 'orange',
	Pink 		= 'pink',
	Purple 		= 'purple',
	Red 		= 'red',
	Silver 		= 'silver',
	Teal 		= 'teal',
	White 		= 'white',
	Yellow 		= 'yellow'
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingDesignImage {
	fileSize 		: number; 			// in bytes
	totalSize		: number; 			// in bytes
	mimeType		: EnumFileType;
	fileName		: string;
	imgUrl          : IFlyingDesignImageUrl;
	resolution      : IFlyingDesignImageResolution;
	colors          : IFlyingDesignImageColors;
	pHash			: string;
}

export interface IFlyingDesignImageUrl {
	[EnumFlyingDesignImageTypes.Icon] 	: string;
	[EnumFlyingDesignImageTypes.Preview]  : string;
	[EnumFlyingDesignImageTypes.Original] : string;
}

interface IFlyingDesignImageResolution {
	width       	: number;
	height      	: number;
}

export interface IFlyingDesignImageColors {
	dominant    	: EnumFlyingDesignImageColor;
	avg         	: string;
	colorType   	: EnumFlyingDesignImageColorType;
	colorList		: IFlyingDesignListColor[];
}

interface IFlyingDesignListColor {
	name			: EnumFlyingDesignImageColor;
	percentage		: number;
}


/**------------------------------------------------------
 * Schema
 */
export class FlyingDesignImageSchema implements ValidatorSchema<IFlyingDesignImage>  {
	fileSize	= Validator.Number().isGreaterThan(0).isLessThanOrEqual(FLYING_DESIGNS_IMAGE_LIMITATION.MAX_FILE_SIZE);
	totalSize	= Validator.Number().isGreaterThan(0).isLessThanOrEqual(FLYING_DESIGNS_IMAGE_LIMITATION.MAX_TOTAL_SIZE);
	mimeType	= Validator.Enum(EnumFileType).isNotEmpty();
	fileName	= Validator.String().isNotEmpty().isMaxLength(FLYING_DESIGNS_IMAGE_LIMITATION.FILE_NAME_LENGTH);
	pHash		= Validator.String().isNotEmpty();
	imgUrl		= {
		icon	: Validator.String().isNotEmpty(),
		preview	: Validator.String().isNotEmpty(),
		original: Validator.String().isNotEmpty()
	};
	resolution	= {
		width	: Validator.Number().isGreaterThan(0),
		height	: Validator.Number().isGreaterThan(0)
	};
	colors		= {
		dominant	: Validator.Enum(EnumFlyingDesignImageColor).isNotEmpty(),
		avg			: Validator.String().isHexColor().isNotEmpty(),
		colorType	: Validator.Enum(EnumFlyingDesignImageColorType).isNotEmpty(),
		colorList	: Validator.Array().each(Validator.Object().inspect<IFlyingDesignListColor>({
			name		: Validator.Enum(EnumFlyingDesignImageColor).isNotEmpty(),
			percentage	: Validator.Number().isGreaterThan(0)
		}))
	};
}
