import { EnumLanguage } from '@libs/constants';
import { ValidatorSchema, Validator } from '@libs/utilities/validator';


/**------------------------------------------------------
 * Constants
 */
export const FLYING_AI_PROMPT_SEARCH_CONFIG: IFlyingAiPromptSearchConfig = {
	MAX_SEARCH_LENGTH	: 120,
	MAX_RESULTS		 	: 25
};

interface IFlyingAiPromptSearchConfig {
	MAX_SEARCH_LENGTH	: number;
	MAX_RESULTS		 	: number;
}


/**------------------------------------------------------
 * Requests
 */
export interface IFlyingPromptSearchRequest {
	search			: string;
	language		: EnumLanguage;
}


/**------------------------------------------------------
 * Response
 */
export interface IFlyingPromptSearchResponse {
	suggestions		: string[];
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingPromptSearchRequestSchema implements ValidatorSchema<IFlyingPromptSearchRequest> {
	search			= Validator.String().isNotEmpty().isMaxLength(FLYING_AI_PROMPT_SEARCH_CONFIG.MAX_SEARCH_LENGTH)
						.hasNoHtmlScripts().toLowerCase().trim().purifyWhitespaces();
	language		= Validator.Enum(EnumLanguage);
}

export class FlyingPromptSearchResponseSchema implements ValidatorSchema<IFlyingPromptSearchResponse> {
	suggestions		= Validator.Array().each(Validator.String().isNotEmpty());
}
