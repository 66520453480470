/**------------------------------------------------------
 * Design Options
 */
export enum EnumDesignOptions {
	PreviewImage			= 'mini_dock_preview_image',
	Edit					= 'mini_dock_edit',
	EditTags				= 'mini_dock_edit_tags',
	ClearTags				= 'mini_dock_clear_tags',
	CopyImageToClipboard	= 'mini_dock_copy_image_to_clipboard',
	Delete					= 'mini_dock_delete',
	DownloadImage			= 'mini_dock_download_image',
	Duplicate				= 'mini_dock_duplicate',
	EditImage				= 'mini_dock_edit_image',
	ClearListing			= 'mini_dock_clear_listing',
	EditUploadHistory		= 'mini_dock_edit_upload_history',
	SearchSimilarByTags		= 'mini_dock_search_similar_by_tags',
	SearchSimilarByColor	= 'mini_dock_search_similar_by_color',
	TrademarkReport			= 'mini_dock_trademark_report'
}
