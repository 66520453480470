/* eslint-disable import/no-cycle */
import { Util } from '@libs/utilities/util';

import { Validator } from '../../validator';
import { ValidatorValueProcessor } from '../value-processor/value-processor';
import { AbstractValidatorValue } from '../value-processor/value.abstract';
import { IValidatorProcessResult } from '../validator-value.interface';
import { IValidatorError } from '../validator-error.interface';
import { ValidatorSchema, ValidatorSchemaRef } from '../validator-schema.interface';


export class ValidatorSchemaProcessor {

	constructor(
		private valueProcessor: ValidatorValueProcessor
	) {}


	/**------------------------------------------------------
	 * Validation
	 */
	isValid<T extends object>(object: T, objectOrClassSchema: ValidatorSchemaRef<T> | any): boolean {
		return this.validate(object, objectOrClassSchema).length === 0;
	}

	validate<T extends object>(object: T, objectOrClassSchema: ValidatorSchemaRef<T> | any): IValidatorError[] {

		//0 - deep copy the object, don't change the original
		const copyOfObject: T = Util.Basic.isDefined(object)
			? Util.Basic.deepCopy(object)
			: {} as T;

		//1 - validate the object
		const objectSchema: ValidatorSchema<T> = this.convertObjectOrClassSchame<T>(objectOrClassSchema);
		const validationErrors: IValidatorError[] = [];
		for (const propertyKey of Object.keys(objectSchema)) {

			//a. get the validator for the property
			const validatorOrSchema: AbstractValidatorValue<void> | ValidatorSchema<T> = objectSchema[propertyKey as keyof ValidatorSchema<T>] as AbstractValidatorValue<void> | ValidatorSchema<T>;
			const valueValidator   : AbstractValidatorValue<void> = (validatorOrSchema instanceof AbstractValidatorValue)

				// if we have a validator instance, execute it to validate the object
				// > example: "contact: Val.Object().inspect({ address: Val.String().isUpperCase().isLowerCase() })"
				? validatorOrSchema as AbstractValidatorValue<void>

				// in case of a schema/object, with nested validators validate children recursively
				// > example: "contact: { address: Val.String().isUpperCase().isLowerCase() }""
				: Validator.Object().inspect<T>(validatorOrSchema as ValidatorSchema<T>);

			//b. validate the value for the property
			const result: IValidatorProcessResult = this.valueProcessor.processValueByOperations(valueValidator.getOperations(), copyOfObject[propertyKey as keyof T], propertyKey);
			if (result.validationError) validationErrors.push(result.validationError);
		}

		//2 - return the validation errors
		return validationErrors;
	}

	validationMessages<T extends object>(object: T, objectOrClassSchema: ValidatorSchemaRef<T> | any): string[] {

		//0 - validate the schema
		const validationErrors: IValidatorError[] = this.validate(object, objectOrClassSchema);
		const normalizedErrors: string[] = this.normalizeErrorMessages(validationErrors);

		//1 - return the validation errors
		return normalizedErrors;
	}

	throwIfInvalid<T extends object>(object: T, objectOrClassSchema: ValidatorSchemaRef<T> | any): void {
		const normalizedErrors: string[] = this.validationMessages<T>(object, objectOrClassSchema);
		if (normalizedErrors.length !== 0) throw new Error(`SchemaValidator => throwIfInvalid => FATAL ERROR: invalid object (errors: ${normalizedErrors.join(', ')})`);
	}

	transform<T extends object>(object: T, objectOrClassSchema: ValidatorSchemaRef<T> | any, options?: { disableCheck: boolean }): T {

		//0 - process and check if there were any validation issues?
		if (options?.disableCheck !== true && !this.isValid(object, objectOrClassSchema)) throw new Error(`SchemaValidator => transform => FATAL ERROR: deny transformation as object is invalid and not matching the conditions (make sure to validate the data first)`);

		//1 - deep copy the object, don't change the original
		object = Util.Basic.deepCopy(object);

		//2 - transform all properties of the object
		const objectSchema: ValidatorSchema<T> = this.convertObjectOrClassSchame<T>(objectOrClassSchema);
		for (const propertyKey of Object.keys(objectSchema)) {

			//a. get the object validator or schema and calculate the new value
			const validatorOrSchemaOfProperty: AbstractValidatorValue<void> | ValidatorSchema<T> = objectSchema[propertyKey as keyof ValidatorSchema<T>] as AbstractValidatorValue<void> | ValidatorSchema<T>;
			const newValue: any = (validatorOrSchemaOfProperty instanceof AbstractValidatorValue)
				? this.valueProcessor.processValueByOperations(validatorOrSchemaOfProperty.getOperations(), object[propertyKey as keyof T], propertyKey).transformedValue			// if we have a validator instance, execute it to transform the object
				: this.transform(object[propertyKey as keyof T] as object, validatorOrSchemaOfProperty as ValidatorSchema<T>, options);	// // in case of a schema/object, with nested validators run them recursively

			//b. assign the new value
			object[propertyKey as keyof T] = newValue;
		}

		//3 - return the transformed object
		return object;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private convertObjectOrClassSchame<T extends object>(objectOrClassSchema: ValidatorSchemaRef<T>): ValidatorSchema<T> {

		//0 - validate the object
		// > Note: the "Class Schema" is just a class definition, therefore in TS it is a
		// > typeof "function". Wheres the "Object Schema" is either an schema definition
		// > of a Value instance, but in both cases it is typeof "object" in JavaScript.
		const objectSchema: ValidatorSchema<T> = (typeof objectOrClassSchema === 'function' && typeof objectOrClassSchema !== 'object')
			? new (objectOrClassSchema as TypeValidatorClassSchema<T>)() as ValidatorSchema<T>
			: objectOrClassSchema as ValidatorSchema<T>;

		//1 - return the object schema
		return objectSchema;
	}

	private normalizeErrorMessages(validationErrors: IValidatorError[], parentPropertyPath: string = ''): string[] {

		//0 - were there any errors?
		const errorMessages: string[] = [];
		for (const validationError of validationErrors) {

			//a. get the property path for better messages
			const propertyPath: string = Util.String.isNotEmpty(parentPropertyPath)
				? `${parentPropertyPath}.${validationError.property}`
				: validationError.property;

			//b. is there a child error, if so, go deeper
			if (Util.Array.isNotEmpty(validationError.children)) {
				const childrenErrors: string[] = this.normalizeErrorMessages(validationError.children, propertyPath);
				errorMessages.push(...childrenErrors);
				continue;
			}

			//c. create the error message
			const errorMessage: string = `Validation of property '${propertyPath}' with value '${validationError.value}' failed, as '${validationError.errors.join(', ')}'`;
			errorMessages.push(errorMessage);
		}

		//1 - return the found problems
		return errorMessages;
	}
}


//** Types -------------------------------------- */
type TypeValidatorClassSchema<T extends object> = new () => ValidatorSchema<T>;
