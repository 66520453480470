import { EnumAmazonMarketplace } from '@apps/flying/shared';
import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingNicheSize {
	Small 			= 'small',
	Large 			= 'large'
}


/**------------------------------------------------------
 * Data Structure
 */
export interface IFlyingNicheSuggestions {
	search			: string;
	size			: EnumFlyingNicheSize;
	market			: EnumAmazonMarketplace;
	language		: EnumLanguage;
	suggestions		: string[];
}
