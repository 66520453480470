/* cspell:ignore webos, iemobile */
import { EnumDevice, EnumOperatingSystem } from '@libs/constants';


/**------------------------------------------------------
 * UserAgent Utilities
 * -------------------
 * > Info: This is used to detect the device and operating
 * > system through the user-agent
 */
export class UtilUserAgent {

	//** Configurations */
	private readonly MOBILE_CHECK_REGEX		: RegExp = /(android|webos|iphone|ipod|blackberry|iemobile|opera mini)(?=.*mobile)/;
	private readonly TABLET_CHECK_REGEX		: RegExp = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
	private readonly WINDOWS_OS_CHECK_REGEX	: RegExp = /win/;
	private readonly MAC_OS_CHECK_REGEX		: RegExp = /mac/;
	private readonly LINUX_OS_CHECK_REGEX	: RegExp = /linux(?!.*android)/;
	private readonly ANDROID_CHECK_REGEX	: RegExp = /linux(?=.*android)/;
	private readonly IOS_CHECK_REGEX		: RegExp = /iphone/;
	private readonly IPAD_OS_CHECK_REGEX	: RegExp = /ipad/;


	determineDevice(userAgent: string): EnumDevice {

		//0 - check if mobile
		const userAgentValue: string  = userAgent.toLowerCase();
		const isMobileDevice: boolean = this.MOBILE_CHECK_REGEX.test(userAgentValue);
		if (isMobileDevice) return EnumDevice.Mobile;

		//1 - check is tablet
		const isTablet: boolean = this.TABLET_CHECK_REGEX.test(userAgentValue);
		if (isTablet) return EnumDevice.Tablet;

		//2 - return desktop
		return EnumDevice.Desktop;
	}

	determineOperatingSystem(userAgent: string): EnumOperatingSystem {

		//0 - detect the operating system
		const userAgentValue: string  = userAgent.toLowerCase();
		if (this.WINDOWS_OS_CHECK_REGEX.test(userAgentValue)) return EnumOperatingSystem.Windows;
		if (this.IOS_CHECK_REGEX.test(userAgentValue))		 return EnumOperatingSystem.IOS;
		if (this.IPAD_OS_CHECK_REGEX.test(userAgentValue))	 return EnumOperatingSystem.IPadOS;
		if (this.MAC_OS_CHECK_REGEX.test(userAgentValue)) 	 return EnumOperatingSystem.MacOS;
		if (this.LINUX_OS_CHECK_REGEX.test(userAgentValue))  return EnumOperatingSystem.Linux;
		if (this.ANDROID_CHECK_REGEX.test(userAgentValue)) 	 return EnumOperatingSystem.Android;

		//1 - if none match, define it as other
		return EnumOperatingSystem.Other;
	}
}
