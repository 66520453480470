import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchParameterConfig, IUrlParameters } from '../../../data/search-links.interface';
import { EnumGoogleTrendsGeoLocation, EnumGoogleTrendsTrendDuration, GOOGLE_TRENDS_CONFIG, GOOGLE_TRENDS_DEFAULT_OPTIONS, GOOGLE_TRENDS_GEO_LOCATION_LIST, GOOGLE_TRENDS_TREND_DURATION_LIST, IGoogleTrendsSearchOptions } from './google-trends-search.data';


/**------------------------------------------------------
 * Google Trends Search Url
 */
export class GoogleTrendsSearch implements ISearchLinks {

	//** Configurations */
	private readonly MAX_TREND_WORDS: number = 5;

	//** Helper Getters */
	get durationList(): ISearchParameterConfig<EnumGoogleTrendsTrendDuration>[] {
		return GOOGLE_TRENDS_TREND_DURATION_LIST as ISearchParameterConfig<EnumGoogleTrendsTrendDuration>[];
	}

	get geoLocationList(): ISearchParameterConfig<EnumGoogleTrendsGeoLocation>[] {
		return GOOGLE_TRENDS_GEO_LOCATION_LIST as ISearchParameterConfig<EnumGoogleTrendsGeoLocation>[];
	}


	//** Home & Login */
	home() : string { return GOOGLE_TRENDS_CONFIG.home; }
	login(): string { return GOOGLE_TRENDS_CONFIG.login; }

	//** Search */
	search(searchQuery: string | string[], options?: Partial<IGoogleTrendsSearchOptions> | unknown): string {

		//0 - convert the search queries to an array of words
		const searchTerms	  : string[] 		= Util.Array.asArray(searchQuery);
		const searchParameters: IUrlParameters 	= { q: searchTerms.join(',') };
		if (searchTerms.length > this.MAX_TREND_WORDS) throw new Error(`GoogleTrendsSearch => search => FATAL ERROR: the max amount of searchQuery terms is "${this.MAX_TREND_WORDS}" (searchTerms.length: ${searchTerms.length})`);

		//1 - set the location
		const purifiedOptions: IGoogleTrendsSearchOptions = Util.Function.assignOptions(GOOGLE_TRENDS_DEFAULT_OPTIONS, options as Partial<IGoogleTrendsSearchOptions>);
		if (purifiedOptions.duration !== EnumGoogleTrendsTrendDuration.PastYear) {
			const durationConfig: ISearchParameterConfig<EnumGoogleTrendsTrendDuration> = this.getDuration(purifiedOptions.duration);
			searchParameters['date'] = (durationConfig.parameters as any)['date'] as string;	// eslint-disable-line @typescript-eslint/dot-notation
		}

		//2 - add the trend geo location
		if (purifiedOptions.geoLocation !== EnumGoogleTrendsGeoLocation.World) {
			const geoLocationConfig: ISearchParameterConfig<EnumGoogleTrendsGeoLocation> = this.getGeoLocation(purifiedOptions.geoLocation);
			searchParameters['geo'] = (geoLocationConfig.parameters as any)['geo'] as string;	// eslint-disable-line @typescript-eslint/dot-notation,
		}

		//3 - create the search link
		const searchLink: string = Util.Http.urlJoinWithParams([GOOGLE_TRENDS_CONFIG.search], searchParameters);
		return searchLink;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getDuration(durationCode: EnumGoogleTrendsTrendDuration): ISearchParameterConfig<EnumGoogleTrendsTrendDuration> {

		//0 - try to get the duration
		const matchingDuration: ISearchParameterConfig<EnumGoogleTrendsTrendDuration> = GOOGLE_TRENDS_TREND_DURATION_LIST.find((elem: ISearchParameterConfig<EnumGoogleTrendsTrendDuration>) => elem.code === durationCode)!;
		if (!matchingDuration) throw new Error(`GoogleTrendsSearch => getDuration => FATAL ERROR: no matching duration found within the configured list for the durationCode of "${durationCode}" (valid are: ${GOOGLE_TRENDS_TREND_DURATION_LIST.map((elem: ISearchParameterConfig<EnumGoogleTrendsTrendDuration>) => elem.code).join(', ')})`);

		//1 - return the matching duration
		return matchingDuration;
	}

	private getGeoLocation(locationCode: EnumGoogleTrendsGeoLocation): ISearchParameterConfig<EnumGoogleTrendsGeoLocation> {

		//0 - try to get the duration
		const matchingLocation: ISearchParameterConfig<EnumGoogleTrendsGeoLocation> = GOOGLE_TRENDS_GEO_LOCATION_LIST.find((elem: ISearchParameterConfig<EnumGoogleTrendsGeoLocation>) => elem.code === locationCode)!;
		if (!matchingLocation) throw new Error(`GoogleTrendsSearch => getDuration => FATAL ERROR: no matching geo location found within the configured list for the locationCode of "${locationCode}" (valid are: ${GOOGLE_TRENDS_TREND_DURATION_LIST.map((elem: ISearchParameterConfig<EnumGoogleTrendsTrendDuration>) => elem.code).join(', ')})`);

		//1 - return the matching duration
		return matchingLocation;
	}
}
