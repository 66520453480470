import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_IT: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'it_IT'
		}
	},
	{
		// https://www.amazon.it/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&hidden-keywords=Leggera+taglio+classico+maniche+con+doppia+cucitura+e+orlo+inferiore+Maglietta+Tinta+unita+100+Cotone+Grigio+Cenere+90+Cotone+10+Poliestere+Altri+Toni+50+Cotone+50+Poliestere
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT',
			'hidden-keywords': 'Leggera+taglio+classico+maniche+con+doppia+cucitura+e+orlo+inferiore+Maglietta+Tinta+unita+100+Cotone+Grigio+Cenere+90+Cotone+10+Poliestere+Altri+Toni+50+Cotone+50+Poliestere' // cspell:disable-line
		}
	},
	/* {
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameter 	: {
			language	: 'it_IT',
		},
	}, */
	{
		// https://www.amazon.it/s?hidden-keywords=Tinta+unita%3A+100%25+Cotone%3B+Grigio+Cenere%3A+90%25+Cotone%2C+10%25+Poliestere%3B+Altri+Toni%3A+50%25+Cotone%2C+50%25+Poliestere+Maglietta+Collo
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT',
			'hidden-keywords': 'Tinta+unita%3A+100%25+Cotone%3B+Grigio+Cenere%3A+90%25+Cotone%2C+10%25+Poliestere%3B+Altri+Toni%3A+50%25+Cotone%2C+50%25+Poliestere+Maglietta+Collo' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.it/s?hidden-keywords=Tinta+unita%3A+100%25+Cotone%3B+Grigio+cenere%3A+90%25+Cotone%2C+10%25+Poliestere%3B+Altri+toni%3A+50%25+Cotone%2C+50%25+Poliestere+%2BCanotta
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT',
			'hidden-keywords': 'Tinta+unita%3A+100%25+Cotone%3B+Grigio+cenere%3A+90%25+Cotone%2C+10%25+Poliestere%3B+Altri+toni%3A+50%25+Cotone%2C+50%25+Poliestere+%2BCanotta' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.it/s?hidden-keywords=%2B%22Maglia+a+Manica%22+Tinta+unita%3A+100%25+Cotone%3B+Grigio+cenere%3A+90%25+Cotone%2C+10%25+Poliestere%3B+Altre+toni%3A+50%25+Cotone%2C+50%25+Poliestere+-Raglan
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT',
			'hidden-keywords': '%2B%22Maglia+a+Manica%22+Tinta+unita%3A+100%25+Cotone%3B+Grigio+cenere%3A+90%25+Cotone%2C+10%25+Poliestere%3B+Altre+toni%3A+50%25+Cotone%2C+50%25+Poliestere+-Raglan' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.it/s?k=dog+Raglan
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT'
		},
		queryOptions: {
			postfix		: '+Raglan',
			prefix		: ''
		}
	},
	{
		// https://www.amazon.it/s?hidden-keywords=Tinta+unita%3A+80%25+Cotone%2C+20%25+Poliestere%3B+Grigio+cenere%3A+78%25+Cotone%2C+22%25+Poly%3B+Grigio+scuro%3A+50%25+Cotone%2C+50%25+Poliestere+%2BFelpa+-Cappuccio
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT',
			'hidden-keywords': 'Tinta+unita%3A+80%25+Cotone%2C+20%25+Poliestere%3B+Grigio+cenere%3A+78%25+Cotone%2C+22%25+Poly%3B+Grigio+scuro%3A+50%25+Cotone%2C+50%25+Poliestere+%2BFelpa+-Cappuccio' // cspell:disable-line

		}
	},
	{
		// https://www.amazon.it/s?hidden-keywords=Tinta+unita%3A+80%25+Cotone%2C+20%25+Poliestere%3B+Grigio+cenere%3A+78%25+Cotone%2C+22%25+Poly%3B+Grigio+scuro%3A+50%25+Cotone%2C+50%25+Poliestere+%2B%22Felpa+con+Cappuccio%22
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			i			: 'fashion',
			language	: 'it_IT',
			'hidden-keywords': 'Tinta+unita%3A+80%25+Cotone%2C+20%25+Poliestere%3B+Grigio+cenere%3A+78%25+Cotone%2C+22%25+Poly%3B+Grigio+scuro%3A+50%25+Cotone%2C+50%25+Poliestere+%2B%22Felpa+con+Cappuccio%22' // cspell:disable-line
		}
	},
	{
		// https://www.amazon.it/s?k=dog+PopSockets+Supporto+e+Impugnatura+per+Smartphone+e+Tablet
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			language	: 'it_IT'
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+PopSockets+Supporto+e+Impugnatura+per+Smartphone+e+Tablet' // cspell:disable-line
		}
	}
	/* {
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameter 	: {
			language	: 'it_IT',
		},
	},
	{
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameter 	: {
			language	: 'it_IT',
		},
	},
	{
		code		: EnumAmazonProduct.ThrowPillows,
		name		: 'Throw Pillows',
		parameter 	: {
			language	: 'it_IT',
		},
	},
	{
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameter 	: {
			language	: 'it_IT',
		},
	}, */
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_IT: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.IT,
	websiteUrl		: {
		home		: 'https://www.amazon.it/',
		login		: 'https://www.amazon.it/ap/signin?openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=itflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0',
		search		: 'https://www.amazon.it/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_IT,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
