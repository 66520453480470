import { EnumAmazonCategory, EnumAmazonMarketplace } from '../../../app-constants';


/**------------------------------------------------------
 * API Request & Response
 */
export interface IAmazonSuggestionsRequest {
	keyword		: string;
	marketplace	: EnumAmazonMarketplace;
	category	: EnumAmazonCategory;
	prefix		: string[];
	postfix		: string[];
}

export interface IAmazonSuggestionsResponse {
	suggestions	: string[];
}
