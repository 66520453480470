import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Util } from '@libs/utilities/util';
import { WebHelperService } from '@libs/libraries/frontend';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NgProductTourInfoComponent } from './product-tour-info/product-tour-info.component';
import { NG_PRODUCT_TOUR_THEME_CONFIG, INgProductTourThemeConfig } from './data/product-tour-config.data';
import { EnumNgDomElement, INgElementProps, INgElementBoundValues } from './data/product-tour-config.interface';
import { EnumNgProductTourTheme, INgProductTour, INgProductTourStep, INgTourStepIndexed } from './product-tour.interface';


@Injectable()
export class NgProductTourService {

	//** Helper Variables */
	private viewContainerRef	: ViewContainerRef 	= null!;
	private isTourStarted 		: boolean 			= false;
	private isTourFirstStep 	: boolean 			= false;
	private isTourLastStep 		: boolean 			= false;
	private isParentScrollBody	: boolean 			= false;

	//** Subscribable Helpers */
	private closeSub$ 	 	  	: Subscription		= null!;			// will be initialized later
	private nextSub$ 	 	  	: Subscription		= null!;
	private prevSub$ 	 	  	: Subscription		= null!;
	private completeSub$ 		: Subscription		= null!;
	private onResizeSub$ 		: Subscription		= null!;
	private onBulletSub$ 		: Subscription		= null!;

	//** Navigation start observable */
	navigationStart$			: Observable<any>;

	//** Local Product Tour */
	private incomingTour 		  : INgProductTour | null = null;
	private tourSteps			  : INgTourStepIndexed[]	= [];
	private highlighterBoxShadow  : string 				= null!;
	private overlayBackgroundColor: string 				= null!;

	//** Setters and Getters */
	get isTourStartedFlag()			: boolean 			{ return this.isTourStarted; }
	set tooltipViewRef(containerRef	: ViewContainerRef) { this.viewContainerRef = containerRef; }

	constructor(
		private router 			: Router,
		private webHelperService: WebHelperService
	) {
		this.navigationStart$ = this.router.events.pipe(filter((event: unknown) => event instanceof NavigationStart));
	}


	/**------------------------------------------------------
	 * Initialize & Start Tour
	 */
	startTour(productTour: INgProductTour, startingStep: number = 0): void {

		//0 - if no product tour steps present
		if (Util.Array.isEmpty(productTour.tourSteps))   throw new Error(`NgProductTourService => startTour => FATAL ERROR: Product Tour Steps are not defined: ${productTour.tourSteps}`);
		if (productTour.tourSteps.length < startingStep) throw new Error(`NgProductTourService => startTour => FATAL ERROR: startingStep of "${startingStep}" is higher then the amount of tourSteps (max tourSteps: "${productTour.tourSteps.length}")`);

		//1 - mark tour as started, add current product tour locally
		productTour.hooks?.onStart!();
		this.incomingTour 	= productTour;
		this.tourSteps 		= productTour.tourSteps.map((elem: INgProductTourStep, index: number) => ({ index: index + 1, ...elem }));
		this.isTourStarted	= true;

		//2 - handle theme color
		this.handleThemeColors();

		//3 - add overlay for disabling interactions except page scroll,
		const pageOverlay: Partial<INgElementProps> = {
			classSelector 	: 'product-tour-overlay-element',
			idSelector 		: EnumNgDomElement.Overlay,
			cssStyles 		: `inset: 0px; position: fixed; z-index: 99;`
		};
		document.body.classList.add('overflow-hidden');
		document.body.classList.add('pe-3');
		document.body.append(this.createDynamicDomElement(pageOverlay));
		document.getElementById(EnumNgDomElement.Overlay)!.style.background = this.overlayBackgroundColor;

		//4 - execute tour step (start with first step)
		this.executeTourStep(this.tourSteps[startingStep]);
	}


	/**------------------------------------------------------
	 * Handle Tour Steps
	 */
	private executeTourStep(tourStep: INgTourStepIndexed) {

		//0 - check the tour step has element id
		if (Util.Basic.isUndefined(tourStep?.id)) throw new Error(`AppProductTourService => executeTourStep => FATAL ERROR: Selected is invalid. Selector: ${tourStep.id}`);

		//1 - check the page url and tour step url and handle showing the tour step on dynamic content
		// > used timeout to wait for 100ms to get the route url after navigation ended
		setTimeout(() => {
			this.handlePageLoading(tourStep, this.router.url);
		}, 100);
	}

	//** Create Tour Step */
	private async createTourStep(tourStep: INgTourStepIndexed) {

		//0 - reset body scroll
		if (this.isParentScrollBody) document.documentElement.scrollTop = 0;

		//1 - if wontedly dummy element passed as element id then show floating element
		if (tourStep?.id as EnumNgDomElement === EnumNgDomElement.DummyDomElement) {
			this.createFloatingElement(tourStep);
			return;
		}

		//2 - get the element by id, if element does not exist then check the DOM till element is found on the DOM
		let htmlElement: HTMLElement = document.getElementById(tourStep?.id)!;
		if (Util.Basic.isUndefined(htmlElement)) {
			htmlElement = await this.webHelperService.waitForElement(tourStep?.id);
		}

		//3 - get scroll offset for the parent scroll element
		const scrollOffset: number = this.getScrollOffset(htmlElement);

		//4 - scroll parent by offset if offset is defined
		if (Util.Basic.isDefined(scrollOffset)) {
			const parentScrollElement: HTMLElement = this.getParentScrollElement(htmlElement);
			this.handleScroll(parentScrollElement, scrollOffset);
		}

		//5 - create highlighter around the target element
		const elementBounds 	: INgElementBoundValues = this.getElementBoundValues(htmlElement);
		const scrollOffsetTopPx	: string = (scrollOffset && this.isParentScrollBody) ? `${htmlElement.getBoundingClientRect().top + scrollOffset}px` : elementBounds.elementTop;
		const highlighterProps 	: Partial<INgElementProps> = {
			classSelector  	: 'product-tour-overlay-element', 			// Note: used this class for not to add dark mode to this div, this class is also used in 'dark-mode.data.ts' file
			idSelector 		: EnumNgDomElement.Highlighter,
			cssStyles  		: `
				top			: ${scrollOffsetTopPx}; left: ${elementBounds.elementLeft};
				width		: ${elementBounds.elementWidth}; height: ${elementBounds.elementHeight};
				z-index		: 9;
				position	: absolute;
				border-radius: 15px;
				box-shadow	: ${this.highlighterBoxShadow};
			`
		};

		//6 - append the element to the DOM
		const highlighterElement: HTMLElement = this.createDynamicDomElement(highlighterProps);
		document.body.append(highlighterElement);

		//7 - create dynamic component
		this.generateTourStepComponent(tourStep, elementBounds, scrollOffsetTopPx);
	}

	//** Generate product tour step component */
	private generateTourStepComponent(tourStep: INgTourStepIndexed, elementBounds: INgElementBoundValues, scrollOffsetTopPx: string | null = null) {

		//0 - create the tour step component, pass tour step into component for rendering the view, remove background color of the overlay
		const componentRef: ComponentRef<NgProductTourInfoComponent> = this.viewContainerRef.createComponent(NgProductTourInfoComponent);
		document.getElementById(EnumNgDomElement.Overlay)!.style.background = 'none';

		//1 - tour progress data
		const incomingTour: INgProductTour = this.incomingTour!;
		this.isTourFirstStep = tourStep.index === 1;
		this.isTourLastStep  = incomingTour.tourSteps.length === tourStep.index;

		//2 - component input data
		componentRef.instance.tourStepData 	  		  = tourStep;
		componentRef.instance.isTourFirstStep 		  = this.isTourFirstStep;
		componentRef.instance.isTourLastStep  		  = this.isTourLastStep;
		componentRef.instance.totalSteps 	  		  = incomingTour.tourSteps.length;
		componentRef.instance.showCloseButton 		  = incomingTour.showCloseButton;
		componentRef.instance.showTourProgressBullets = incomingTour.showTourProgressBullets;

		//3 - add custom styling to the component
		componentRef.location.nativeElement.style.cssText = `
			top 	 : ${scrollOffsetTopPx || elementBounds.elementTop};
			left 	 : ${elementBounds.elementLeft};
			width 	 : ${elementBounds.elementWidth};
			height 	 : ${elementBounds.elementHeight};
			position : absolute;
		`;

		//4 - subscriptions on events inside the tour component
		this.closeSub$ 	  = this.onTourClose(componentRef, tourStep); 			// execute on tour closed by "x" button
		this.nextSub$ 	  = this.onTourNext(componentRef, tourStep);			// execute on tour step "next"
		this.prevSub$ 	  = this.onTourBack(componentRef, tourStep); 			// execute on tour step "previous"
		this.completeSub$ = this.onTourComplete(componentRef, tourStep); 		// execute on tour completion
		this.onResizeSub$ = this.onWindowResize(componentRef, tourStep); 		// execute on tour completion
		this.onBulletSub$ = this.onNavBullet(componentRef, tourStep); 			// execute on navigation bullet "."
	}


	/**------------------------------------------------------
	 * Tour Handlers (close, next, back, complete, navigation bullet)
	 */
	onTourClose(componentRef: ComponentRef<NgProductTourInfoComponent>, currentTourStep: INgTourStepIndexed): Subscription {
		return componentRef.instance.closeEmitter.subscribe(async () => {

			//0 - close the tour
			if (Util.Basic.isDefined(currentTourStep?.hooks?.onClose)) await currentTourStep.hooks!.onClose!();
			this.incomingTour?.hooks?.onClose!();

			//1 - remove tour
			this.removeRenderedTour(this.closeSub$, this.viewContainerRef);
		});
	}

	onTourNext(componentRef: ComponentRef<NgProductTourInfoComponent>, currentTourStep: INgTourStepIndexed): Subscription {
		return componentRef.instance.nextEmitter.subscribe(async (response: number) => {

			//0 - get next tour step
			const nextTourStep: INgTourStepIndexed = this.tourSteps.find((tourStep: INgTourStepIndexed) => tourStep.index === response)!;
			if (!nextTourStep) throw new Error(`NgProductTourService => onTourNext => FATAL ERROR: nextTourStep was not found`);

			//1 - remove current tour step and render next step
			this.removeTourPopover(this.nextSub$, this.viewContainerRef);

			//2 - wait till afterStep is executed
			if (Util.Basic.isDefined(currentTourStep?.hooks?.afterStep)) await currentTourStep.hooks!.afterStep!();
			await Util.Runtime.delay(1000); 		// wait for 1000ms if any animated elements exists and render it

			//3 - start showing tour step
			this.executeTourStep(nextTourStep);
		});
	}

	onTourBack(componentRef: ComponentRef<NgProductTourInfoComponent>, currentTourStep: INgTourStepIndexed): Subscription {
		return componentRef.instance.backEmitter.subscribe(async (response: number) => {

			//0 - get previous tour step
			const prevTourStep: INgTourStepIndexed = this.tourSteps.find((tourStep: INgTourStepIndexed) => tourStep.index === response)!;
			if (!prevTourStep) throw new Error(`NgProductTourService => onTourBack => FATAL ERROR: prevTourStep was not found`);

			//1 - remove already rendered tour step from the view
			this.removeTourPopover(this.prevSub$, this.viewContainerRef);

			//2 - navigate to the component
			if (prevTourStep.pageUrl !== currentTourStep.pageUrl) this.router.navigateByUrl(prevTourStep.pageUrl);

			//3 - check if before step hook was defined and execute beforeStep hook
			if (Util.Basic.isDefined(currentTourStep?.hooks?.beforeStep)) await currentTourStep.hooks!.beforeStep!();
			await Util.Runtime.delay(1000); 				// wait for 1000ms if any animated elements exists and render it

			//4 - start showing tour step
			this.executeTourStep(prevTourStep);
		});
	}

	onTourComplete(componentRef: ComponentRef<NgProductTourInfoComponent>, currentTourStep: INgTourStepIndexed): Subscription {
		return componentRef.instance.completeEmitter.subscribe(async () => {

			//0 - on finish remove the tour
			if (Util.Basic.isDefined(currentTourStep?.hooks?.onClose)) await currentTourStep.hooks!.onClose!();
			this.removeRenderedTour(this.completeSub$, this.viewContainerRef);

			//1 - call the hook that the tour is completed
			this.incomingTour?.hooks?.onComplete!();
		});
	}

	onWindowResize(componentRef: ComponentRef<NgProductTourInfoComponent>, currentTourStep: INgTourStepIndexed): Subscription {
		return componentRef.instance.resizeEmitter.subscribe(() => {

			//0 - remove the rendered element first, then render it again
			this.removeTourPopover(this.onResizeSub$, this.viewContainerRef);

			//1 - re render the tooltip
			this.createTourStep(currentTourStep);
		});
	}

	onNavBullet(componentRef: ComponentRef<NgProductTourInfoComponent>, currentTourStep: INgTourStepIndexed): Subscription {
		return componentRef.instance.bulletEmitter.subscribe(async (response: number) => {

			//0 - if the current step and navigationBullet are same step then don't reload the component
			if (response === currentTourStep.index) return;

			//1 - remove the rendered element and re render the tooltip
			this.removeTourPopover(this.onBulletSub$, this.viewContainerRef);

			//2 - get the element by step number & render the tour step and navigate to the component
			const dynamicTourStep: INgTourStepIndexed = this.tourSteps.find((tourStep: INgTourStepIndexed) => tourStep.index === response)!;
			if (dynamicTourStep.pageUrl !== currentTourStep.pageUrl) this.router.navigateByUrl(dynamicTourStep.pageUrl);

			//3 - execute onStepLoad
			if (Util.Basic.isDefined(dynamicTourStep?.hooks?.onStepLoad)) await dynamicTourStep.hooks!.onStepLoad!();
			await Util.Runtime.delay(1000); 			// wait for 1000ms if any animated elements exists and render it

			//4 - start showing tour step
			this.executeTourStep(dynamicTourStep);
		});
	}


	/**------------------------------------------------------
	 * Page Scroll handlers
	 */
	private getScrollOffset(element: HTMLElement): number {

		//0 - get element bound values
		const elementBounds	: DOMRect = element.getBoundingClientRect();
		const elementBottom	: number  = elementBounds.bottom;
		const elementTop	: number  = elementBounds.top;

		//1 - get viewport height
		const viewportHeight: number = window.innerHeight;

		//2 - get offset if element is below the viewport
		if (elementBottom > viewportHeight) return elementBottom - viewportHeight;

		//3 - get offset if element is above the viewport
		if (elementTop < 0) return elementTop;
		return 0;
	}

	private getParentScrollElement(element: HTMLElement): HTMLElement {

		//0 - get element clientHeight and scrollHeight
		const elementClientHeight: number = element?.clientHeight || 0;
		const elementScrollHeight: number = element?.scrollHeight || 1;

		//1 - check if the element is scrollable
		if (elementClientHeight < elementScrollHeight) return element;

		//2 - otherwise get element parent and check for scroll again
		const parentElement: HTMLElement = element.parentElement!;
		return this.getParentScrollElement(parentElement);
	}

	//** Handle scroll if element is not in window viewport */
	private handleScroll(parentScrollElement: HTMLElement, scrollOffset: number) {

		//0 - handle scroll if parent is body
		if (parentScrollElement === document.body) {
			document.documentElement.scrollTop += scrollOffset;
			this.isParentScrollBody = true;
			return;
		}

		//1 - check for parent's top position
		if (scrollOffset < parentScrollElement.offsetTop) scrollOffset -= parentScrollElement.offsetTop;

		//2 - scroll element by offset
		parentScrollElement.scrollTop += scrollOffset;
		this.isParentScrollBody = false;
	}


	/**------------------------------------------------------
	 * UI Helper Functions
	 */
	private removeRenderedTour(subscription: Subscription, viewContainerRef: ViewContainerRef) {

		//0 - remove the popover
		this.removeTourPopover(subscription, viewContainerRef);

		//1 - if includeOverlay is true then remove the overlay element too..
		const overlayElement: HTMLElement = document.getElementById(EnumNgDomElement.Overlay)!;
		overlayElement.remove();
		document.body.classList.remove('overflow-hidden');
		document.body.classList.remove('pe-3');
		this.isTourStarted = false;
	}

	private removeTourPopover(subscription: Subscription, viewContainerRef: ViewContainerRef) {

		//0 - unsubscribe the subscription, clear viewContainerReference
		subscription.unsubscribe();
		viewContainerRef.clear();

		//1 - get the rendered DOM element and remove
		const renderedElement: HTMLElement = (document.getElementById(EnumNgDomElement.Highlighter) || document.getElementById(EnumNgDomElement.Floating))!;
		renderedElement.remove();
		document.getElementById(EnumNgDomElement.Overlay)!.style.background = this.overlayBackgroundColor;
	}

	//** Create Floating Element on DOM when the target element is not found */
	private createFloatingElement(tourStep: INgTourStepIndexed) {

		//0 - create a dummy element for showing the tooltip when the target element is not available
		const floatingElementProps: Partial<INgElementProps> = {
			idSelector : EnumNgDomElement.Floating,
			cssStyles  : `
				top			: 50%;
				left		: 50%;
				z-index		: 9;
				position	: absolute;
				box-shadow	: ${this.highlighterBoxShadow};
			`
		};
		const floatingElement: HTMLElement = this.createDynamicDomElement(floatingElementProps);
		document.body.append(floatingElement);

		//1 - get the element bound element (left, top, right.. positions) and how the tour step
		const elementBounds: INgElementBoundValues = this.getElementBoundValues(floatingElement);
		this.generateTourStepComponent(tourStep, elementBounds);
	}


	/**------------------------------------------------------
	 * Dom Helper Functions
	 */
	private createDynamicDomElement(element: Partial<INgElementProps>): HTMLElement {

		//0 - create element, add attributes, styles
		const dynamicElement: HTMLElement = document.createElement('div');
		if (element?.idSelector) 	dynamicElement.id 			 = element.idSelector;
		if (element?.classSelector) dynamicElement.className 	 = element.classSelector;
		if (element?.cssStyles)		dynamicElement.style.cssText = element.cssStyles;

		//1 - return the created element
		return dynamicElement;
	}

	//** Get the target element's bounded values */
	private getElementBoundValues(targetElement: HTMLElement): INgElementBoundValues {

		//0 - get DOM bound value
		const elementBounds: DOMRect = targetElement.getBoundingClientRect();

		//1 - generate values in pixels
		const elementBoundValues: INgElementBoundValues = {
			elementTop 		: `${elementBounds.top}px`,
			elementBottom 	: `${elementBounds.bottom}px`,
			elementLeft 	:` ${elementBounds.left}px`,
			elementRight 	: `${elementBounds.right}px`,
			elementHeight 	: `${elementBounds.height}px`,
			elementWidth 	: `${elementBounds.width}px`
		};

		//2 - return the new DOM bound values
		return elementBoundValues;
	}

	//** Get Number from Alphanumeric String */
	getNumberFromAlphanumericString(alphaNumericString: string): number {

		//0 - ascii values of numbers, dot "."
		const asciiNumbersStarts: number = 48;
		const asciiNumbersEnds  : number = 57;
		const asciiSymbolDot	: number = 46;

		//1 - check input value by ascii code, if number and dot (".") then add to the array
		const stringArray: string[] = [];
		for (let i: number = 0; i < alphaNumericString.length; i++) {
			if ((alphaNumericString.charCodeAt(i) >= asciiNumbersStarts && alphaNumericString.charCodeAt(i) <= asciiNumbersEnds) || alphaNumericString.charCodeAt(i) === asciiSymbolDot) {
				stringArray.push(alphaNumericString[i]);
			}
		}

		//2 - return number
		return Util.Number.toNumber(stringArray.join(''));
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */

	//** Handles Dynamically Rendering Components */
	private handlePageLoading(tourStep: INgTourStepIndexed, currentPageUrl: string) {

		//0 - check the tour step and currently loaded component are same
		if (currentPageUrl === tourStep?.pageUrl) {
			this.createTourStep(tourStep);
			return;
		}

		//1 - if different component is already loaded then load the relevant component and load step
		if (Util.Basic.isDefined(tourStep?.pageUrl)) this.router.navigateByUrl(tourStep.pageUrl);
		this.createTourStep(tourStep);
	}

	//** Add overlay and background colors to tour dialog */
	private handleThemeColors() {

		//0 - check the app theme
		const themeConfig: INgProductTourThemeConfig = NG_PRODUCT_TOUR_THEME_CONFIG[EnumNgProductTourTheme.Light];
		if (!themeConfig) throw new Error(`NgProductTourService => handleThemeColors => FATAL ERROR: themeConfig not available for "${EnumNgProductTourTheme.Light}"`);

		//1 - add color by dark mode and light mode
		this.highlighterBoxShadow	 = themeConfig.boxShadow;
		this.overlayBackgroundColor	 = themeConfig.backgroundColor;
	}
}
