import { EnumLanguage } from '@libs/constants';

import { EnumPodMarketplace } from '../../../app-constants';


/**------------------------------------------------------
 * Spreadshirt Tag Data
 */
export interface ISpreadshirtTagData {
	keyword     : string;
	language    : EnumLanguage;
	marketplace	: EnumPodMarketplace;
	tags		: ISpreadshirtTag[];
}

export interface ISpreadshirtTag {
	keyword		: string;
	count		: number;
}
