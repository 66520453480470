import { AfterViewInit, Component, Output, ViewChild, EventEmitter, Input, ViewEncapsulation, HostListener, OnInit, OnDestroy } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardMouseService } from '@libs/libraries/frontend';
import { interval, Observable, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { INgTourStepIndexed } from '../product-tour.interface';


@Component({
	selector 		: 'ng-product-tour-info',
	templateUrl		: './product-tour-info.component.html',
	styleUrls 		: ['./product-tour-info.component.scss'],
	encapsulation 	: ViewEncapsulation.None
})
export class NgProductTourInfoComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('popoverTemp') popoverTemp! : NgbPopover;

	//** Input Parameters */
	@Input()  tourStepData 	  		  	: INgTourStepIndexed | null = null;
	@Input()  isTourFirstStep 		  	: boolean = null!;
	@Input()  isTourLastStep  		  	: boolean = null!;
	@Input()  totalSteps	  		  	: number  = null!;
	@Input()  showTourProgressBullets 	: boolean = null!;
	@Input()  showCloseButton		 	: boolean = null!;

	//** Output Parameters */
	@Output() readonly nextEmitter	    : EventEmitter<number> = new EventEmitter<number>();
	@Output() readonly backEmitter	    : EventEmitter<number> = new EventEmitter<number>();
	@Output() readonly closeEmitter     : EventEmitter<number> = new EventEmitter<number>();
	@Output() readonly completeEmitter  : EventEmitter<number> = new EventEmitter<number>();
	@Output() readonly resizeEmitter    : EventEmitter<number> = new EventEmitter<number>();
	@Output() readonly bulletEmitter    : EventEmitter<number> = new EventEmitter<number>();

	//** Helper Variables */
	progressSteps 			  : string[] | null 	 = null;
	sizeChanged 			 !: Subject<boolean>;
	sizeChangedDebounced 	 !: Observable<boolean | Subject<boolean>>;

	constructor(
		private keyboardMouseService : KeyboardMouseService
	) {}


	/**------------------------------------------------------
	 * Live Cycle Hooks
	 */
	ngOnInit(): void {

		//0 - debounce on window resize event
		this.sizeChanged 		  = new Subject<boolean>();
		this.sizeChangedDebounced = this.sizeChanged.pipe(debounce(() => interval(500)));
		this.sizeChangedDebounced.subscribe(() => {
			if (this.tourStepData) this.onResize(this.tourStepData.index);
		});

		//1 - create an array with total steps
		this.progressSteps = new Array(this.totalSteps);
	}

	ngAfterViewInit(): void {

		// open tooltip after loading the data
		this.popoverTemp.open();
	}

	ngOnDestroy(): void {
		this.progressSteps = null;
		this.popoverTemp.close();
	}


	/**------------------------------------------------------
	 * Button Event Emitters
	 */
	onBack(index: number)	 : void { this.backEmitter.emit(index - 1); }
	onNext(index: number)	 : void { this.nextEmitter.emit(index + 1); }
	onComplete(index: number): void { this.completeEmitter.emit(index); }
	onClose(index: number)	 : void	{ this.closeEmitter.emit(index); }
	onResize(index: number)	 : void { this.resizeEmitter.emit(index); }
	onNavigationBullet(index: number): void { this.bulletEmitter.emit(index); }


	/**------------------------------------------------------
	 * Keyboard Shortcuts, Window Resize
	 */
	@HostListener('window:keydown', ['$event'])
	keyupEvent(event: KeyboardEvent): void {

		//0 - prevent default keyboard events
		event.preventDefault();

		//1 - are specific keys pressed
		const isBackStep	: boolean = this.keyboardMouseService.isKeyArrowLeft(event)  || this.keyboardMouseService.isKeyArrowUp(event);
		const isNextStep 	: boolean = this.keyboardMouseService.isKeyArrowRight(event) || this.keyboardMouseService.isKeyArrowDown(event);
		const isCancelTour 	: boolean = this.keyboardMouseService.isKeyEscape(event);

		//2 - is left or top key pressed and tour step is not first step then navigation to back step
		if (isBackStep && !this.isTourFirstStep && this.tourStepData) this.onBack(this.tourStepData.index);

		//3 - is right or down key pressed and tour step is not last step then navigation to next step
		if (isNextStep && !this.isTourLastStep && this.tourStepData) this.onNext(this.tourStepData.index);

		//4 - is right or down key pressed and tour step is last step then mark as complete tour
		if (isNextStep && this.isTourLastStep && this.tourStepData) this.onComplete(this.tourStepData.index);

		//5 - is ESC key pressed then close the tour
		if (isCancelTour && this.tourStepData) this.onComplete(this.tourStepData.index);
	}

	@HostListener('window:resize')
	onResizes(): void {
		this.sizeChanged.next(true);
	}
}
