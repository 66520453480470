/**------------------------------------------------------
 * FAQ data
 */
export * from './common/faq.data';


/**------------------------------------------------------
 * Flying Constants
 */
export * from './common/flying-app-constants.data';
export * from './common/legal-pages.data';


/**------------------------------------------------------
 * Testimonials data
 */
export * from './common/testimonials.data';


/**------------------------------------------------------
 * Trademarks
 */
export * from './trademarks/trademarks.data';
