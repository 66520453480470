import { ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';
import { EnumNgFaStyle } from '@libs/frameworks/angular';

import { EnumSidebarPage } from '../../sidebar-pages.interface';


/**------------------------------------------------------
 * Shared Pages
 */
export const SHARED_PAGE_LIST: ISparkSidebarSubMenu[] = [
	{
		id 			: EnumSidebarPage.Home,
		label		: 'Home',
		routePath	: '/',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'house'
		}
	},
	{
		id 			: EnumSidebarPage.Tutorials,
		label		: 'Tutorials',
		routePath	: '/tutorials',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'book-open-cover'
		}
	},
	{
		id 			: EnumSidebarPage.Support,
		label		: 'Support',
		routePath	: '/support',
		icon 		: {
			style 	: EnumNgFaStyle.Solid,
			name 	: 'square-question'
		}
	}
];
