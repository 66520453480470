/**------------------------------------------------------
 * Trademark Status
 */
export enum EnumTmStatus {
	Filed 			= 'filed',
	Registered 		= 'registered',
	Ended 			= 'ended',
	Expired 		= 'expired',
	Active 			= 'active',
	Inactive 		= 'inactive'
}


/**------------------------------------------------------
 * Definitions & Configurations
 */
export const TM_STATUS_INFOS: Record<EnumTmStatus, ITmStatusInfo> = {
	[EnumTmStatus.Filed]: {
		code			: EnumTmStatus.Filed,
		name			: 'Filed',
		description		: 'The trademark has been filed with the trademark office, but it has not yet been examined and approved',
		color			: '#ED7A29',
		tmViewValue		: 'Filed'
	},
	[EnumTmStatus.Registered]: {
		code			: EnumTmStatus.Registered,
		name			: 'Registered',
		description		: 'The trademark has been approved by the trademark office and is now protected under the law',
		color			: '#C73F40',
		tmViewValue		: 'Registered'
	},
	[EnumTmStatus.Ended]: {
		code			: EnumTmStatus.Ended,
		name			: 'Ended',
		description		: 'The trademark registration has come to an end for a variety of reasons, such as the trademark owner failing to renew the registration, or the trademark office finding that it violates other trademarks',
		color			: '#007820',
		tmViewValue		: 'Ended'
	},
	[EnumTmStatus.Expired]: {
		code			: EnumTmStatus.Expired,
		name			: 'Expired',
		description		: 'The trademark registration has lapsed due to the trademark owner failing to renew it in a timely manner. The trademark may be eligible for renewal, but the trademark owner will need to go through the renewal process',
		color			: '#C73F40',
		tmViewValue		: 'Expired'
	},
	[EnumTmStatus.Active]: {
		code			: EnumTmStatus.Active,
		name			: 'Active',
		description		: 'The trademark is currently active and has ongoing legal protection',
		color			: '#3437eb',
		tmViewValue		: 'A'
	},
	[EnumTmStatus.Inactive]: {
		code			: EnumTmStatus.Inactive,
		name			: 'Inactive',
		description		: 'The trademark is currently inactive and no longer has legal protection',
		color			: '#8334eb',
		tmViewValue		: 'I'
	}
};


//** Interfaces --------------------------------- */
export interface ITmStatusInfo {
	code			: EnumTmStatus;
	name			: string;
	description		: string;
	color			: string;
	tmViewValue		: string;
}
