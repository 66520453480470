import { CookiesStorageObject } from './cookies-storage-object';
import { CookiesStorageHandler } from './cookies-storage.handler';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class CookiesStorageFactory {

	static create<T extends object>(config: ICookiesStorageFactoryConfig<T>, document: Document): CookiesStorageHandler {
		const cookie		: CookiesStorageObject  = new CookiesStorageObject(config.expiry, document);
		const cookieHandler	: CookiesStorageHandler = new CookiesStorageHandler(cookie);
		return cookieHandler;
	}
}


//** Interfaces --------------------------------- */
export interface ICookiesStorageFactoryConfig<T> {
	id		: string;
	data	: T;
	expiry	: Date;
}
