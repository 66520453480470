import { Util, EnumUtilRemainingTimeScale } from '@libs/utilities/util';
import { EnumConsoleSetting, EnumConsoleTextColor } from '@libs/constants';

import { IFileLoggerConfig } from './file-logger.interface';
import { AbstractLoggerService } from '../shared/logger.abstract';
import { ALL_LOG_LEVELS, EnumLogLevel } from '../shared/logger.interface';


/**------------------------------------------------------
 * Logger for Production & Development
 */
export class FileLoggerService extends AbstractLoggerService {

	//** Helper Variables */
	private progressStartTime: Date = null!;

	constructor(
		private config: IFileLoggerConfig
	) {
		//0 - initialize the abstract parent
		const logLevels: EnumLogLevel[] = Util.Array.isNotEmpty(config?.logLevels || []) ? config.logLevels! : ALL_LOG_LEVELS;
		super(logLevels);

		//1 - check the file path for the log file
		if (!this.config.filePath.endsWith('.log')) throw new Error(`FileLoggerService => constructor => FATAL ERROR: the log file has to use the ".log" ending (provided path: "${this.config.filePath}")`);
	}


	/**------------------------------------------------------
	 * Progress Logging
	 */
	setProgressStartTime(): FileLoggerService {
		this.progressStartTime = new Date();
		return this;
	}

	logProgressByIndex(index: number, max: number, ...messages: unknown[]): FileLoggerService {

		//0 - calculate the percent
		const relativeIndex	 : number = (index !== 0) ? index : 1;
		const relativePercent: number = Util.Number.percent(relativeIndex, max);
		const progressPercent: number = Util.Number.toSaveNumber(relativePercent, 0, 100);

		//1 - log the progress
		return this.logProgressByPercent(progressPercent, ...messages);
	}

	logProgressByPercent(progressPercent: number, ...messages: unknown[]): FileLoggerService {

		//0 - was the start time set?
		if (!this.progressStartTime) throw new Error(`FileLoggerService => logProgress => FATAL ERROR: the progressStartTime was not set yet. Before the progress can be logged the start time has to be set by calling the "setProgressStartTime()" method.`);

		//1 - create the progress string
		const estimatedTime  : number = Util.Date.calculateRemainingTime(this.progressStartTime, progressPercent, EnumUtilRemainingTimeScale.Minute);
		const progressPostfix: string = `(${progressPercent.toFixed(2)}%, remain ${Util.Date.formatDurationShort(estimatedTime)})`;

		//2 - trigger the logging
		this.handleLogging(EnumLogLevel.Info, ...messages, progressPostfix);
		return this;
	}


	/**------------------------------------------------------
	 * Logging the Messages
	 */
	protected printLogMessage(logLevel: EnumLogLevel, ...messages: unknown[]): string {

		//0 - get time stamp and message
		const timestamp		: string = Util.Date.format('YYYY-MM-DD HH:mm:ss');
		const logMessageText: string = this.messagesToText(...messages);
		const logFilePath	: string = Util.Date.formatPlaceholders(this.config.filePath);		// will add the date to the file path if placeholder is set in the path
		const errorFilePath	: string = Util.String.replaceLast(logFilePath, '.', '.error.');	// log all errors extra to an error log file

		//1 - log the message
		switch (logLevel) {

			case EnumLogLevel.Info:
				// eslint-disable-next-line no-console
				console.log(EnumConsoleTextColor.Green, `✓ [${timestamp}] [INFO]`, EnumConsoleSetting.Reset, ...messages);
				this.config.writeToFile(logFilePath, `[${timestamp}] [INFO]  ${logMessageText}\n`);
				break;

			case EnumLogLevel.Warning:
				console.warn(EnumConsoleTextColor.Yellow, `⚠️ [${timestamp}] [WARN]`, EnumConsoleSetting.Reset, ...messages);
				this.config.writeToFile(logFilePath, `[${timestamp}] [WARN]  ${logMessageText}\n`);
				break;

			case EnumLogLevel.Error:
				console.error(EnumConsoleTextColor.Red, `✗ [${timestamp}] [ERROR]`, EnumConsoleSetting.Reset, ...messages);
				this.config.writeToFile(logFilePath,   `[${timestamp}] [ERROR] ${logMessageText}\n`);
				this.config.writeToFile(errorFilePath, `[${timestamp}] [ERROR] ${logMessageText}\n`);
				break;

			default:
				throw new Error(`FileLoggerService => printLogMessage => FATAL ERROR: the logging level of "${logLevel}" was not defined`);
		}

		//2 - return the logged text for the history
		return logMessageText;
	}
}
