import { ValidatorSchema, Validator } from '@libs/utilities/validator';

import { FlyingDesignSchema, FlyingDesignUploadSchema, IFlyingDesignUpload } from '../../../interfaces';


/**------------------------------------------------------
 * Request Interface
 */
export interface IFlyingTrackUploadRequest {
	userEmail	: string;
	flyId		: string;
	upload		: IFlyingDesignUpload;
}


/**------------------------------------------------------
 * Schemas
 */
export class FlyingTrackUploadsSchema implements ValidatorSchema<IFlyingTrackUploadRequest> {
	userEmail	= Validator.String().isNotEmpty();
	flyId		= new FlyingDesignSchema().flyId;
	upload	 	= Validator.Object().inspect<IFlyingDesignUpload>(FlyingDesignUploadSchema);
}
