import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';
import { EnumDesignOptions } from '../../upload/app-constants/design-management-options/design-options.constants';
import { EnumToolbarOptions } from '../../upload/app-constants/design-management-options/toolbar-options.constants';


/**------------------------------------------------------
 * Limitations of the Design Management
 */
export const DESIGN_MANAGEMENT_LIMITATIONS: IDesignManagementLimitation[] = [
	{
		module		: EnumSubscriptionModule.FreeVersion,
		storageSize	: 2147483648				// 2 GB in bytes
	},
	{
		module		: EnumSubscriptionModule.DesignManagement25GB,
		storageSize	: 26843545600				// 25 GB in bytes
	},
	{
		module		: EnumSubscriptionModule.DesignManagement100GB,
		storageSize	: 107374182400				// 100 GB in bytes
	},
	{
		module		: EnumSubscriptionModule.DesignManagement500GB,
		storageSize	: 536870912000				// 500 GB in bytes
	}
];


/**------------------------------------------------------
 * Design Management Options Limitations
 */
export const DESIGN_OPTION_LIMITATIONS: IDesignOptionLimitation[] = [

	// ---------------------- free version / beginner
	{
		modules		: [
			EnumSubscriptionModule.FreeVersion,
			EnumSubscriptionModule.UploadPodSpreadshirt,	EnumSubscriptionModule.UploadPodRedbubble,
			EnumSubscriptionModule.UploadPodMba,			EnumSubscriptionModule.UploadPodDisplate,
			EnumSubscriptionModule.UploadPodFineArtAmerica,	EnumSubscriptionModule.UploadPodPrintful,
			EnumSubscriptionModule.UploadPodPrintify,		EnumSubscriptionModule.UploadPodShirtee,
			EnumSubscriptionModule.UploadPodSociety6,		EnumSubscriptionModule.UploadPodTeepublic,
			EnumSubscriptionModule.UploadPodTeespring,		EnumSubscriptionModule.UploadPodTostadora,
			EnumSubscriptionModule.UploadPodZazzle
		],
		excludedOptions  : {
			toolbar 	 : [
				EnumToolbarOptions.TmListingCheck,			EnumToolbarOptions.TmBlacklistCheck
			],
			designOptions: []
		}
	},

	// ---------------------- design management / advanced
	{
		modules		: [
			EnumSubscriptionModule.DesignManagement25GB, 	EnumSubscriptionModule.DesignManagement100GB,
			EnumSubscriptionModule.DesignManagement500GB,	EnumSubscriptionModule.UploadPodAdvanced,
			EnumSubscriptionModule.UploadPodAdvancedShop
		],
		excludedOptions  : {
			toolbar 	 : [],
			designOptions: []
		}
	},

	// ---------------------- pro
	{
		modules		: [EnumSubscriptionModule.UploadPodPro],
		excludedOptions  : {
			toolbar 	 : [],
			designOptions: []
		}
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IDesignManagementLimitation {
	module		: EnumSubscriptionModule;
	storageSize	: number;				// storage in bytes
}

export interface IDesignOptionLimitation {
	modules			: EnumSubscriptionModule[];
	excludedOptions : {
		toolbar 	 : EnumToolbarOptions[];
		designOptions: EnumDesignOptions[];
	};
}
