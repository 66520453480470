/**------------------------------------------------------
 * Upload Task Status
 */
export enum EnumUploadException {

	// Unknown Error / Exception
	UnknownException 				= 'unknown_exception',						// if the platform got changed and the uploads fail

	// General Errors
	GeneralNoAccount				= 'general_no_account',
	GeneralNoTemplate				= 'general_no_template',					// ... todo => template check logic (similar to the listing check)
	GeneralNoListing				= 'general_no_listing',
	GeneralInvalidListing			= 'general_invalid_listing',
	GeneralInvalidLogin				= 'general_invalid_login',
	GeneralImageScalingError		= 'general_image_scaling_error',

	// KDP
	Kdp2StepNotApproved 			= 'kdp_2step_verification_not_approved',
	KdpInvalidManuscript			= 'kdp_invalid_manuscript',
	KdpUnsupportedTrimSize			= 'kdp_unsupported_trim_size',
	KdpRequirementRejection			= 'kdp_requirement_rejection',				// book has errors, it won't meet amazon's\nstandards
	KdpInvalidPrice					= 'kdp_invalid_price',

	// Mba
	Mba2StepNotApproved 			= 'mba_2step_verification_not_approved',
	MbaNoProducts					= 'mba_no_products',
	MbaNoMarketplace				= 'mba_no_marketplace',

	// Displate
	DisplateRequirementRejection	= 'displate_requirement_rejection',			// artwork does not meet the requirement of displate (probably the image was already uploaded)

	// Fine Art America
	// FineArtAmerica 				= 'fine_art_america_...',

	// Printful
	PrintfulStoreNotFound			= 'printful_store_not_found',				// store not found (wrong store name? no add product button? wrong store type?)
	PrintfulTemplateNotFound		= 'printful_template_not_found',			// template not found (wrong template name?)
	PrintfulNoProductsLeft			= 'printful_no_products_left',				// no products left after removing the unavailable ones!

	// Printify
	PrintifyStoreNotFound			= 'printify_store_not_found',				// store not found (wrong store name? no add product button? wrong store type?)
	PrintifyTemplateNotFound		= 'printify_template_not_found',			// template not found (wrong template name?)
	PrintifyNoProductsLeft			= 'printify_no_products_left',				// no products left after removing the unavailable ones!

	// Redbubble
	RedbubbleTemplateNotFound 		= 'redbubble_template_not_found',

	// Shirtee
	ShirteeTemplateNotFound 		= 'shirtee_template_not_found',

	// Society6
	Society6TitleTrademarkViolation = 'society6_title_trademark_violation',		// title contains wrong text, trademark violation, skip this design
	Society6DescTrademarkViolation 	= 'society6_desc_trademark_violation',

	// Spreadshirt
	SpreadshirtTemplateNotFound 	= 'spreadshirt_template_not_found',
	SpreadshirtUnsupportedImage		= 'spreadshirt_unsupported_image_format',	// error occurred because the file format is not supported
	SpreadshirtImageProcessingIssue = 'spreadshirt_image_processing_issue',
	SpreadshirtRejectedListingText 	= 'spreadshirt_rejected_listing_text',

	// Teepublic
	TeepublicRejectImageSize 		= 'teepublic_reject_image_size',

	// Teespring
	TeespringTemplateNotFound 		= 'teespring_template_not_found',

	// Tostadora
	TostadoraStoreNotFound 			= 'tostadora_store_not_found',
	TostadoraFullStore		 		= 'tostadora_full_store',

	// Zazzle
	ZazzleStoreNotFound 			= 'zazzle_store_not_found',
	ZazzleTemplateNotFound 			= 'zazzle_template_not_found',
	ZazzleEmptyCategory 			= 'zazzle_empty_category'					// the category you selected does not contain any templates. Please select another category
}
