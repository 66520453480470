import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * Mock User Interactions / Scripting
 * ----------------------------------
 * > Info: This mock class is for scripting user actions,
 * > it can be used to automat manual inputs for testing
 * > for quicker development and more convenience
 * ----------------------------------
 * > Angular pick changes: https://stackoverflow.com/questions/17109850/update-angular-model-after-setting-input-value-with-jquery
 */
export class MockUserAction {


	setText(elementId: string, value: string): MockUserAction {
		this.getElement<HTMLInputElement>(elementId).value = value;
		this.detectChanges(elementId);
		return this;
	}

	click(elementId: string): MockUserAction {
		this.getElement(elementId).click();
		return this;
	}

	// ... add more if needed


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getElement<T extends HTMLElement>(elementId: string): T {

		//0 - try to get the element
		const htmlElement: T = document.getElementById(elementId) as T;
		if (Util.Basic.isUndefined(htmlElement)) throw new Error(`MockUserAction => getElement => FATAL ERROR: no element with the elementId of "${elementId}" was found`);

		//1 - return the element
		return htmlElement;
	}

	private detectChanges(elementId: string) {
		this.getElement(elementId).dispatchEvent(new Event('input',  { bubbles: true }));		// Use for Chrome/Firefox/Edge
		this.getElement(elementId).dispatchEvent(new Event('change', { bubbles: true }));		// Use for Chrome/Firefox/Edge + IE11
	}
}
