/* eslint-disable import/no-cycle, @typescript-eslint/naming-convention */
import { ValidatorValueArray } from '../values/value-array';
import { ValidatorValueObject } from '../values/value-object';
import { ValidatorValueBoolean } from '../values/value-boolean';
import { ValidatorValueDate } from '../values/value-date';
import { ValidatorValueNumber } from '../values/value-number';
import { ValidatorValueString } from '../values/value-string';
import { AbstractValidatorValue } from './value-processor/value.abstract';


/**------------------------------------------------------
 * Schema Definitions
 */
export type ValidatorSchemaRef<T extends object> = ValidatorSchema<T> | (new () => ValidatorSchema<T>);

export type ValidatorSchema<T extends object> = {
	[K in keyof T]-?: T[K] extends (infer U)[]						// eslint-disable-line @typescript-eslint/no-unused-vars
		? ValidatorValueArray 										// must be ValidatorArray for arrays
		: T[K] extends Date
			? ValidatorValueDate
			: T[K] extends object
				? ValidatorValueObject | ValidatorSchema<T[K]> 		// must be ValidatorSchema or ValidatorObject for objects
				: T[K] extends string
					? ValidatorValueString | ValidatorValueDate
					: T[K] extends number
						? ValidatorValueNumber
						: T[K] extends boolean
							? ValidatorValueBoolean
							: AbstractValidatorValue<void> 			// ... add others
};
