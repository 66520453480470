import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumTwitterSorting, ITwitterSearchOptions, TWITTER_CONFIG, TWITTER_DEFAULT_OPTIONS } from './twitter-search.data';


/**------------------------------------------------------
 * Twitter Search Url
 */
export class TwitterSearch extends AbstractSearchLinks<ITwitterSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumTwitterSorting>[] {
		return TWITTER_CONFIG.sortings as ISearchParameterConfig<EnumTwitterSorting>[];
	}

	constructor() {
		super(TWITTER_CONFIG, TWITTER_DEFAULT_OPTIONS);
	}
}
