import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { IAddonTapDetectOptions, TypeAddonWindowTapfiliate } from './tapfiliate.interface';


@Injectable()
export class AddonTapfiliateService {

	//** Window object to access the script functions */
	private readonly window: TypeAddonWindowTapfiliate;

	constructor(
		@Inject(DOCUMENT)
		private document: Document
	) {
		this.window = this.document.defaultView as TypeAddonWindowTapfiliate;
	}

	//** Track purchase conversion in tapfiliate */
	trackConversion(id: string): void {
		try {
			this.window.tapConversion(id);
		} catch (error: unknown) {
			console.error('Purchasing Error (T-TF-493578997): ', error);
		}
	}

	//** Set Affiliate Referral Cookie */
	detect(ref: string): void {

		//0 - create the referral object
		const tapDetectOptions: Partial<IAddonTapDetectOptions> = {
			referral_code	: ref
		};

		//1 - set it in the cookies through the tapfiliate js
		this.window.tapDetect(tapDetectOptions, () => {});
	}
}
