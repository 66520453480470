import { Injectable } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { ObjectStorage } from '@libs/libraries/shared';

import { EnumAddonDarkMode, IAddonDarkModeStorage } from './dark-mode.interface';


@Injectable()
export class AddonDarkModeStorageHandler {

	constructor(
		private objectStorageHandler: ObjectStorage<IAddonDarkModeStorage>
	) {}

	/**------------------------------------------------------
	 * Save & Load Dark Mode
	 */
	getDarkMode(): EnumAddonDarkMode | null {
		const mode: EnumAddonDarkMode | null = this.objectStorageHandler.getStorageObject('darkMode');
		return (mode && Util.Enum.isValid(EnumAddonDarkMode, mode)) ? mode : null;
	}
	setDarkMode(mode: EnumAddonDarkMode): void {
		this.objectStorageHandler.setStorageObject('darkMode', mode);
	}


	/**------------------------------------------------------
	 * Save & Load auto system theme
	 */
	getAutoSystemTheme(): boolean {
		const mode: boolean | null = this.objectStorageHandler.getStorageObject('autoSystemTheme');
		return Boolean(mode);
	}
	setAutoSystemTheme(isAuto: boolean): void {
		this.objectStorageHandler.setStorageObject('autoSystemTheme', isAuto);
	}
}
