import { ComponentRef, EventEmitter } from '@angular/core';


/**------------------------------------------------------
 * Component Wrapper
 */
export class NgComponentWrapper<T extends object> {

	constructor(
		private componentRef: ComponentRef<T>
	) {
		//0 - create event emitter
		(this.componentRef.instance as any).close = new EventEmitter<void>();

		//1 - destroy the reference on close
		this.subscribe('close', () => {
			this.componentRef.destroy();
		});
	}


	/**------------------------------------------------------
	 * Set the input parameters of the component
	 */
	set<P extends object>(params: P): NgComponentWrapper<T> {

		//0 - set the parameters
		for (const [key, value] of Object.entries(params)) {

			//a. check if property is valid
			if (!(key in this.componentRef.instance)) throw new Error(`NgComponentWrapper => set => FATAL ERROR: key of "${key}" is not defined / exists in the component (please set all @Input parameters to null or an initial value, then this check can pass)`);

			//b. set the value to the input property
			(this.componentRef.instance as any)[key] = value;
		}

		//1 - return the wrapper instance for method chaining
		return this;
	}


	/**------------------------------------------------------
	 * Subscribe to Events
	 */

	//** Subscribe to output event */
	subscribe<P>(eventEmitter: string, callbackFunction: Function): NgComponentWrapper<T> {

		//0 - check if output property exists
		if (!(eventEmitter in this.componentRef.instance)) throw new Error(`NgComponentWrapper => subscribe => FATAL ERROR: eventEmitter / output property of "${eventEmitter}" does not exist for the component which is used as a modal`);

		//1 - subscribe to the event emitter
		(this.componentRef.instance as any)[eventEmitter].subscribe((params: P) => {
			callbackFunction(params);
		});
		return this;
	}

	onDestroy(callback: Function): NgComponentWrapper<T> {
		this.componentRef.onDestroy(() => callback());
		return this;
	}


	/**------------------------------------------------------
	 * Helper Methods
	 */
	getComponentRef(): ComponentRef<T> {
		return this.componentRef;
	}

	destroy(): NgComponentWrapper<T> {
		this.componentRef.destroy();
		return this;
	}
}
