import { EnumAmazonCategory, EnumAmazonProduct, EnumAmazonSorting } from '../../../../../constants/research/app-constants';
import { ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export const AMAZON_SORTING_LIST: ISearchParameterConfig<EnumAmazonSorting>[] = [
	{
		code		: EnumAmazonSorting.Featured,
		name		: 'Featured',
		parameters	: { s: 'featured' }
	},
	{
		code		: EnumAmazonSorting.PriceAsc,
		name		: 'Price: Low to High',
		parameters	: { s: 'price-asc-rank' }
	},
	{
		code		: EnumAmazonSorting.PriceDesc,
		name		: 'Price: Hight to Low',
		parameters	: { s: 'price-desc-rank' }
	},
	{
		code		: EnumAmazonSorting.ReviewDesc,
		name		: 'Avg. Customer Review',
		parameters	: { s: 'review-rank' }
	},
	{
		code		: EnumAmazonSorting.Newest,
		name		: 'Newest Arrivals',
		parameters	: { s: 'date-desc-rank' }
	}
];


/**------------------------------------------------------
 * Category Configurations
 * --------------------------- com
 * > All         => https://www.amazon.com/s?k=dog
 * > Clothing    => https://www.amazon.com/s?k=dog&i=fashion
 * > Book        => https://www.amazon.com/s?k=dog&i=stripbooks
 * >
 * --------------------------- uk
 * > All         => https://www.amazon.es/s?k=dog
 * > Clothing    => https://www.amazon.es/s?k=dog&i=fashion
 * > Book        => https://www.amazon.es/s?k=dog&i=stripbooks
 * >
 * --------------------------- de
 * > All         => https://www.amazon.de/s?k=dog
 * > Clothing    => https://www.amazon.de/s?k=dog&i=fashion
 * > Book        => https://www.amazon.de/s?k=dog&i=stripbooks
 * >
 * --------------------------- fr
 * > All         => https://www.amazon.co.uk/s?k=dog
 * > Clothing    => https://www.amazon.co.uk/s?k=dog&i=fashion
 * > Book        => https://www.amazon.co.uk/s?k=dog&i=stripbooks
 * >
 * --------------------------- es
 * > All         => https://www.amazon.it/s?k=dog
 * > Clothing    => https://www.amazon.it/s?k=dog&i=fashion
 * > Book        => https://www.amazon.it/s?k=dog&i=stripbooks
 * >
 * --------------------------- it
 * > All         => https://www.amazon.fr/s?k=dog
 * > Clothing    => https://www.amazon.fr/s?k=dog&i=fashion
 * > Book        => https://www.amazon.fr/s?k=dog&i=stripbooks
 */
export const AMAZON_CATEGORY_LIST: ISearchParameterConfig<EnumAmazonCategory>[] = [
	{
		code		: EnumAmazonCategory.All,
		name		: 'All',
		parameters	: { }
	},
	{
		code		: EnumAmazonCategory.Clothing,
		name		: 'Clothing',
		parameters	: { i: 'fashion' }
	},
	{
		code		: EnumAmazonCategory.Book,
		name		: 'Book',
		parameters	: { i: 'stripbooks' }
	}
];


/**------------------------------------------------------
 * Amazon Search Options
 */
export const AMAZON_DEFAULT_OPTIONS: IAmazonSearchOptions = {
	page			: 1,
	sorting			: EnumAmazonSorting.Featured,
	product			: EnumAmazonProduct.All,
	category		: EnumAmazonCategory.All
};

export interface IAmazonSearchOptions {
	page			: number;
	sorting			: EnumAmazonSorting;
	product			: EnumAmazonProduct;
	category		: EnumAmazonCategory;
}
