import { AMAZON_DEFAULT_OPTIONS } from '../amazon-search.data';
import { AbstractAmazonSearch } from '../helpers/amazon-search.abstract';
import { AMAZON_CONFIG_AU } from './amazon-au.data';
import { ISearchParameterConfig } from '../../../../data/search-links.interface';
import { EnumAmazonSorting, EnumAmazonProduct, EnumAmazonMarketplace } from '../../../../../../constants/research/app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Amazon AU Search Links
 */
export class AmazonAuSearch extends AbstractAmazonSearch {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumAmazonSorting>[] {
		return AMAZON_CONFIG_AU.sortings as ISearchParameterConfig<EnumAmazonSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumAmazonProduct>[] {
		return AMAZON_CONFIG_AU.products as ISearchParameterConfig<EnumAmazonProduct>[];
	}

	constructor() {
		super(EnumAmazonMarketplace.CA, AMAZON_CONFIG_AU, AMAZON_DEFAULT_OPTIONS);
	}
}
