import { EnumCurrency } from '../currency/currency.interface';
import { EnumLanguage } from '../language/language.interface';


/**------------------------------------------------------
 * Country Definitions
 */
export enum EnumCountry {
	// cspell:disable

	// Africa
	DZ = 'dz', // Algeria
	AO = 'ao', // Angola
	BJ = 'bj', // Benin
	BW = 'bw', // Botswana
	BF = 'bf', // Burkina Faso
	BI = 'bi', // Burundi
	CV = 'cv', // Cape Verde
	CM = 'cm', // Cameroon
	CF = 'cf', // Central African Republic
	TD = 'td', // Chad
	KM = 'km', // Comoros
	CG = 'cg', // Congo
	CD = 'cd', // DR Congo
	DJ = 'dj', // Djibouti
	EG = 'eg', // Egypt
	GQ = 'gq', // Equatorial Guinea
	ER = 'er', // Eritrea
	SZ = 'sz', // Eswatini
	ET = 'et', // Ethiopia
	GA = 'ga', // Gabon
	GM = 'gm', // Gambia
	GH = 'gh', // Ghana
	GN = 'gn', // Guinea
	GW = 'gw', // Guinea-Bissau
	CI = 'ci', // Ivory Coast
	KE = 'ke', // Kenya
	LS = 'ls', // Lesotho
	LR = 'lr', // Liberia
	LY = 'ly', // Libya
	MG = 'mg', // Madagascar
	MW = 'mw', // Malawi
	ML = 'ml', // Mali
	MR = 'mr', // Mauritania
	MU = 'mu', // Mauritius
	MA = 'ma', // Morocco
	MZ = 'mz', // Mozambique
	NA = 'na', // Namibia
	NE = 'ne', // Niger
	NG = 'ng', // Nigeria
	RW = 'rw', // Rwanda
	ST = 'st', // São Tomé and Príncipe
	SN = 'sn', // Senegal
	SC = 'sc', // Seychelles
	SL = 'sl', // Sierra Leone
	SO = 'so', // Somalia
	ZA = 'za', // South Africa
	SS = 'ss', // South Sudan
	SD = 'sd', // Sudan
	TZ = 'tz', // Tanzania
	TG = 'tg', // Togo
	TN = 'tn', // Tunisia
	UG = 'ug', // Uganda
	ZM = 'zm', // Zambia
	ZW = 'zw', // Zimbabwe

	// Antarctica
	AQ = 'aq', // Antarctica

	// Asia
	AF = 'af', // Afghanistan
	AM = 'am', // Armenia
	AZ = 'az', // Azerbaijan
	BH = 'bh', // Bahrain
	BD = 'bd', // Bangladesh
	BT = 'bt', // Bhutan
	BN = 'bn', // Brunei
	KH = 'kh', // Cambodia
	CN = 'cn', // China
	CY = 'cy', // Cyprus
	GE = 'ge', // Georgia
	IN = 'in', // India
	ID = 'id', // Indonesia
	IR = 'ir', // Iran
	IQ = 'iq', // Iraq
	IL = 'il', // Israel
	JP = 'jp', // Japan
	JO = 'jo', // Jordan
	KZ = 'kz', // Kazakhstan
	KW = 'kw', // Kuwait
	KG = 'kg', // Kyrgyzstan
	LA = 'la', // Laos
	LB = 'lb', // Lebanon
	MY = 'my', // Malaysia
	MV = 'mv', // Maldives
	MN = 'mn', // Mongolia
	MM = 'mm', // Myanmar
	NP = 'np', // Nepal
	KP = 'kp', // North Korea
	OM = 'om', // Oman
	PK = 'pk', // Pakistan
	PS = 'ps', // Palestine
	PH = 'ph', // Philippines
	QA = 'qa', // Qatar
	SA = 'sa', // Saudi Arabia
	SG = 'sg', // Singapore
	KR = 'kr', // South Korea
	LK = 'lk', // Sri Lanka
	SY = 'sy', // Syria
	TW = 'tw', // Taiwan
	TJ = 'tj', // Tajikistan
	TH = 'th', // Thailand
	TL = 'tl', // Timor-Leste
	TR = 'tr', // Turkey
	TM = 'tm', // Turkmenistan
	AE = 'ae', // United Arab Emirates
	UZ = 'uz', // Uzbekistan
	VN = 'vn', // Vietnam
	YE = 'ye', // Yemen

	// Europe
	AL = 'al', // Albania
	AD = 'ad', // Andorra
	AT = 'at', // Austria
	BY = 'by', // Belarus
	BE = 'be', // Belgium
	BA = 'ba', // Bosnia and Herzegovina
	BG = 'bg', // Bulgaria
	HR = 'hr', // Croatia
	CZ = 'cz', // Czechia
	DK = 'dk', // Denmark
	EE = 'ee', // Estonia
	FI = 'fi', // Finland
	FR = 'fr', // France
	DE = 'de', // Germany
	GR = 'gr', // Greece
	HU = 'hu', // Hungary
	IS = 'is', // Iceland
	IE = 'ie', // Ireland
	IT = 'it', // Italy
	XK = 'xk', // Kosovo
	LV = 'lv', // Latvia
	LI = 'li', // Liechtenstein
	LT = 'lt', // Lithuania
	LU = 'lu', // Luxembourg
	MT = 'mt', // Malta
	MD = 'md', // Moldova
	MC = 'mc', // Monaco
	ME = 'me', // Montenegro
	NL = 'nl', // Netherlands
	MK = 'mk', // North Macedonia
	NO = 'no', // Norway
	PL = 'pl', // Poland
	PT = 'pt', // Portugal
	RO = 'ro', // Romania
	RU = 'ru', // Russia
	SM = 'sm', // San Marino
	RS = 'rs', // Serbia
	SK = 'sk', // Slovakia
	SI = 'si', // Slovenia
	ES = 'es', // Spain
	SE = 'se', // Sweden
	CH = 'ch', // Switzerland
	UA = 'ua', // Ukraine
	UK = 'gb', // United Kingdom (we have used gb, as gb is used in database and image names)
	VA = 'va', // Vatican City

	// North America
	AG = 'ag', // Antigua and Barbuda
	BS = 'bs', // Bahamas
	BB = 'bb', // Barbados
	BZ = 'bz', // Belize
	CA = 'ca', // Canada
	CR = 'cr', // Costa Rica
	CU = 'cu', // Cuba
	DM = 'dm', // Dominica
	DO = 'do', // Dominican Republic
	SV = 'sv', // El Salvador
	GD = 'gd', // Grenada
	GT = 'gt', // Guatemala
	HT = 'ht', // Haiti
	HN = 'hn', // Honduras
	JM = 'jm', // Jamaica
	MX = 'mx', // Mexico
	NI = 'ni', // Nicaragua
	PA = 'pa', // Panama
	KN = 'kn', // Saint Kitts and Nevis
	LC = 'lc', // Saint Lucia
	VC = 'vc', // Saint Vincent and the Grenadines
	TT = 'tt', // Trinidad and Tobago
	US = 'us', // United States

	// Oceania
	AU = 'au', // Australia
	FJ = 'fj', // Fiji
	KI = 'ki', // Kiribati
	MH = 'mh', // Marshall Islands
	FM = 'fm', // Micronesia
	NR = 'nr', // Nauru
	NZ = 'nz', // New Zealand
	PW = 'pw', // Palau
	PG = 'pg', // Papua New Guinea
	WS = 'ws', // Samoa
	SB = 'sb', // Solomon Islands
	TO = 'to', // Tonga
	TV = 'tv', // Tuvalu
	VU = 'vu', // Vanuatu

	// South America
	AR = 'ar', // Argentina
	BO = 'bo', // Bolivia
	BR = 'br', // Brazil
	CL = 'cl', // Chile
	CO = 'co', // Colombia
	EC = 'ec', // Ecuador
	GY = 'gy', // Guyana
	PY = 'py', // Paraguay
	PE = 'pe', // Peru
	SR = 'sr', // Suriname
	UY = 'uy', // Uruguay
	VE = 've'  // Venezuela
	// cspell:enable
}


/**------------------------------------------------------
 * Interface Definitions
 */
export interface ICountryInfo {
	code			: EnumCountry;
	name			: string;
	nameShort		: string;
	nameLong		: string;
	nameNative 		: string;
	languageCode	: EnumLanguage[];
	flagIcon 		: ICountryFlagIcons;
	dialCode		: string;
	capital			: string;
	domainTld		: string;
	currencyCode	: EnumCurrency;
}

export interface ICountryFlagIcons {
	ratio1x1		: string;
	ratio4x3		: string;
}
