import { Util } from '@libs/utilities/util';

import { ISearchLinks, ISearchLinkUrlConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Instagram Search Url
 */
export class InstagramSearch implements ISearchLinks {

	//** Configurations */
	private readonly INSTAGRAM_CONFIG: ISearchLinkUrlConfig = {
		home		: 'https://www.instagram.com',
		login		: 'https://www.instagram.com/accounts/login',
		search		: 'https://www.instagram.com/explore/tags/[SEARCH]'
	};


	//** Home & Login */
	home() : string { return this.INSTAGRAM_CONFIG.home; }
	login(): string { return this.INSTAGRAM_CONFIG.login; }

	//** Search */
	search(searchQuery: string): string {

		//0 - if no search string existing then return main or home page
		if (Util.String.isEmpty(searchQuery)) return 'https://www.instagram.com/explore';

		//1 - else return the url with search query
		return this.INSTAGRAM_CONFIG.search.replace('[SEARCH]', searchQuery);
	}
}
