import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumFlyingQuoteType {
	Puns			= 'puns',
	Quotes			= 'quotes',
	Sayings			= 'sayings',
	Jokes			= 'jokes',
	Rhymes2Words	= 'rhymes_2_words',
	Rhymes3Words	= 'rhymes_3_words',
	Memes			= 'memes'
}


/**------------------------------------------------------
 * Data Structure
 */
export interface IFlyingQuoteSuggestions {
	search			: string;
	type			: EnumFlyingQuoteType;
	language		: EnumLanguage;
	suggestions		: string[];
}
