/**------------------------------------------------------
 * Mouse Enums
 */
export enum EnumMouseButton {
	Left	= 'left',
	Middle	= 'middle',
	Right	= 'right'
}

/**------------------------------------------------------
 * Mouse click Definitions
 */
export const MOUSE_CODES: IMouseClick = {
	[EnumMouseButton.Left]	 : 0,
	[EnumMouseButton.Middle] : 1,
	[EnumMouseButton.Right]	 : 2
};


//** Interfaces --------------------------------- */
export interface IMouseClick {
	[EnumMouseButton.Left]		: number;
	[EnumMouseButton.Middle]	: number;
	[EnumMouseButton.Right]		: number;
}
