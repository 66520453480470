import { EnumCountry } from '../country/country.interface';
import { ILanguageFlagIcons } from './language.interface';


/**------------------------------------------------------
 * Country Specific Languages
 * --------------------------
 * > source: http://www.lingoes.net/en/translator/langcode.htm
 */
export enum EnumLanguageLocale {
	// cspell:disable
	afZA	= 'af-ZA',	// Afrikaans (South Africa)
	arAE	= 'ar-AE',	// Arabic (U.A.E.)
	arBH	= 'ar-BH',	// Arabic (Bahrain)
	arDZ	= 'ar-DZ',	// Arabic (Algeria)
	arEG	= 'ar-EG',	// Arabic (Egypt)
	arIQ	= 'ar-IQ',	// Arabic (Iraq)
	arJO	= 'ar-JO',	// Arabic (Jordan)
	arKW	= 'ar-KW',	// Arabic (Kuwait)
	arLB	= 'ar-LB',	// Arabic (Lebanon)
	arLY	= 'ar-LY',	// Arabic (Libya)
	arMA	= 'ar-MA',	// Arabic (Morocco)
	arOM	= 'ar-OM',	// Arabic (Oman)
	arQA	= 'ar-QA',	// Arabic (Qatar)
	arSA	= 'ar-SA',	// Arabic (Saudi Arabia)
	arSY	= 'ar-SY',	// Arabic (Syria)
	arTN	= 'ar-TN',	// Arabic (Tunisia)
	arYE	= 'ar-YE',	// Arabic (Yemen)
	azAZ	= 'az-AZ',	// Azeri (Azerbaijan)
	beBY	= 'be-BY',	// Belarusian (Belarus)
	bgBG	= 'bg-BG',	// Bulgarian (Bulgaria)
	bsBA	= 'bs-BA',	// Bosnian (Bosnia and Herzegovina)
	caES	= 'ca-ES',	// Catalan (Spain)
	csCZ	= 'cs-CZ',	// Czech (Czech Republic)
	cyGB	= 'cy-GB',	// Welsh (United Kingdom)
	daDK	= 'da-DK',	// Danish (Denmark)
	deAT	= 'de-AT',	// German (Austria)
	deCH	= 'de-CH',	// German (Switzerland)
	deDE	= 'de-DE',	// German (Germany)
	deLI	= 'de-LI',	// German (Liechtenstein)
	deLU	= 'de-LU',	// German (Luxembourg)
	dvMV	= 'dv-MV',	// Divehi (Maldives)
	elGR	= 'el-GR',	// Greek (Greece)
	enAU	= 'en-AU',	// English (Australia)
	enBZ	= 'en-BZ',	// English (Belize)
	enCA	= 'en-CA',	// English (Canada)
	enCB	= 'en-CB',	// English (Caribbean)
	enGB	= 'en-GB',	// English (United Kingdom)
	enIE	= 'en-IE',	// English (Ireland)
	enJM	= 'en-JM',	// English (Jamaica)
	enNZ	= 'en-NZ',	// English (New Zealand)
	enPH	= 'en-PH',	// English (Republic of the Philippines)
	enTT	= 'en-TT',	// English (Trinidad and Tobago)
	enUS	= 'en-US',	// English (United States)
	enZA	= 'en-ZA',	// English (South Africa)
	enZW	= 'en-ZW',	// English (Zimbabwe)
	esAR	= 'es-AR',	// Spanish (Argentina)
	esBO	= 'es-BO',	// Spanish (Bolivia)
	esCL	= 'es-CL',	// Spanish (Chile)
	esCO	= 'es-CO',	// Spanish (Colombia)
	esCR	= 'es-CR',	// Spanish (Costa Rica)
	esDO	= 'es-DO',	// Spanish (Dominican Republic)
	esEC	= 'es-EC',	// Spanish (Ecuador)
	esES	= 'es-ES',	// Spanish (Spain)
	esGT	= 'es-GT',	// Spanish (Guatemala)
	esHN	= 'es-HN',	// Spanish (Honduras)
	esMX	= 'es-MX',	// Spanish (Mexico)
	esNI	= 'es-NI',	// Spanish (Nicaragua)
	esPA	= 'es-PA',	// Spanish (Panama)
	esPE	= 'es-PE',	// Spanish (Peru)
	esPR	= 'es-PR',	// Spanish (Puerto Rico)
	esPY	= 'es-PY',	// Spanish (Paraguay)
	esSV	= 'es-SV',	// Spanish (El Salvador)
	esUY	= 'es-UY',	// Spanish (Uruguay)
	esVE	= 'es-VE',	// Spanish (Venezuela)
	etEE	= 'et-EE',	// Estonian (Estonia)
	euES	= 'eu-ES',	// Basque (Spain)
	faIR	= 'fa-IR',	// Farsi (Iran)
	fiFI	= 'fi-FI',	// Finnish (Finland)
	foFO	= 'fo-FO',	// Faroese (Faroe Islands)
	frBE	= 'fr-BE',	// French (Belgium)
	frCA	= 'fr-CA',	// French (Canada)
	frCH	= 'fr-CH',	// French (Switzerland)
	frFR	= 'fr-FR',	// French (France)
	frLU	= 'fr-LU',	// French (Luxembourg)
	frMC	= 'fr-MC',	// French (Principality of Monaco)
	glES	= 'gl-ES',	// Galician (Spain)
	guIN	= 'gu-IN',	// Gujarati (India)
	heIL	= 'he-IL',	// Hebrew (Israel)
	hiIN	= 'hi-IN',	// Hindi (India)
	hrBA	= 'hr-BA',	// Croatian (Bosnia and Herzegovina)
	hrHR	= 'hr-HR',	// Croatian (Croatia)
	huHU	= 'hu-HU',	// Hungarian (Hungary)
	hyAM	= 'hy-AM',	// Armenian (Armenia)
	idID	= 'id-ID',	// Indonesian (Indonesia)
	isIS	= 'is-IS',	// Icelandic (Iceland)
	itCH	= 'it-CH',	// Italian (Switzerland)
	itIT	= 'it-IT',	// Italian (Italy)
	jaJP	= 'ja-JP',	// Japanese (Japan)
	kaGE	= 'ka-GE',	// Georgian (Georgia)
	kkKZ	= 'kk-KZ',	// Kazakh (Kazakhstan)
	knIN	= 'kn-IN',	// Kannada (India)
	koKR	= 'ko-KR',	// Korean (Korea)
	kokIN	= 'kok-IN',	// Konkani (India)
	kyKG	= 'ky-KG',	// Kyrgyz (Kyrgyzstan)
	ltLT	= 'lt-LT',	// Lithuanian (Lithuania)
	lvLV	= 'lv-LV',	// Latvian (Latvia)
	miNZ	= 'mi-NZ',	// Maori (New Zealand)
	mkMK	= 'mk-MK',	// FYRO Macedonian (Former Yugoslav Republic of Macedonia)
	mnMN	= 'mn-MN',	// Mongolian (Mongolia)
	mrIN	= 'mr-IN',	// Marathi (India)
	msBN	= 'ms-BN',	// Malay (Brunei Darussalam)
	msMY	= 'ms-MY',	// Malay (Malaysia)
	mtMT	= 'mt-MT',	// Maltese (Malta)
	nbNO	= 'nb-NO',	// Norwegian (Norway)
	nlBE	= 'nl-BE',	// Dutch (Belgium)
	nlNL	= 'nl-NL',	// Dutch (Netherlands)
	nnNO	= 'nn-NO',	// Norwegian (Nynorsk) (Norway)
	nsZA	= 'ns-ZA',	// Northern Sotho (South Africa)
	paIN	= 'pa-IN',	// Punjabi (India)
	plPL	= 'pl-PL',	// Polish (Poland)
	psAR	= 'ps-AR',	// Pashto (Afghanistan)
	ptBR	= 'pt-BR',	// Portuguese (Brazil)
	ptPT	= 'pt-PT',	// Portuguese (Portugal)
	quBO	= 'qu-BO',	// Quechua (Bolivia)
	quEC	= 'qu-EC',	// Quechua (Ecuador)
	quPE	= 'qu-PE',	// Quechua (Peru)
	roRO	= 'ro-RO',	// Romanian (Romania)
	ruRU	= 'ru-RU',	// Russian (Russia)
	saIN	= 'sa-IN',	// Sanskrit (India)
	seFI	= 'se-FI',	// Sami (Finland)
	seNO	= 'se-NO',	// Sami (Norway)
	seSE	= 'se-SE',	// Sami (Sweden)
	skSK	= 'sk-SK',	// Slovak (Slovakia)
	slSI	= 'sl-SI',	// Slovenian (Slovenia)
	sqAL	= 'sq-AL',	// Albanian (Albania)
	srBA	= 'sr-BA',	// Serbian (Bosnia and Herzegovina)
	srSP	= 'sr-SP',	// Serbian (Serbia and Montenegro)
	svFI	= 'sv-FI',	// Swedish (Finland)
	svSE	= 'sv-SE',	// Swedish (Sweden)
	swKE	= 'sw-KE',	// Swahili (Kenya)
	syrSY	= 'syr-SY',	// Syriac (Syria)
	taIN	= 'ta-IN',	// Tamil (India)
	teIN	= 'te-IN',	// Telugu (India)
	thTH	= 'th-TH',	// Thai (Thailand)
	tlPH	= 'tl-PH',	// Tagalog (Philippines)
	tnZA	= 'tn-ZA',	// Tswana (South Africa)
	trTR	= 'tr-TR',	// Turkish (Turkey)
	ttRU	= 'tt-RU',	// Tatar (Russia)
	ukUA	= 'uk-UA',	// Ukrainian (Ukraine)
	urPK	= 'ur-PK',	// Urdu (Islamic Republic of Pakistan)
	uzUZ	= 'uz-UZ',	// Uzbek (Uzbekistan)
	viVN	= 'vi-VN',	// Vietnamese (Viet Nam)
	xhZA	= 'xh-ZA',	// Xhosa (South Africa)
	zhCN	= 'zh-CN',	// Chinese (S)
	zhHK	= 'zh-HK',	// Chinese (Hong Kong)
	zhMO	= 'zh-MO',	// Chinese (Macau)
	zhSG	= 'zh-SG',	// Chinese (Singapore)
	zhTW	= 'zh-TW',	// Chinese (T)
	zuZA	= 'zu-ZA'	// Zulu (South Africa)
	// cspell:enable
}


/**------------------------------------------------------
 * Language Locale Info Interface
 */
export interface ILanguageLocaleInfo {
	code		: EnumLanguageLocale;
	name		: string;
	country		: EnumCountry;
	flagIcon 	: ILanguageFlagIcons;
}
