import { Util } from '@libs/utilities/util';
import { ObjectStorage } from '@libs/libraries/shared';

import { IAppStates, EnumAppStateCategory, EnumInfoMessages } from './app.interface';


/**------------------------------------------------------
 * App Storage
 */
export class AppStorageHelper {

	constructor(
		private storageAccessHelper: ObjectStorage<IAppStates>
	) {}


	/**------------------------------------------------------
	 * Login / Logout Hooks
	 */
	onSignIn(userMail: string): void {

		//0 - if user is different, clear the storage
		if (!this.isUserSame(userMail)) localStorage.clear();

		//1 - set the user mail
		this.setUserHash(userMail);
	}

	onSignOut(): void {

		// clear access and refresh tokens
		this.setAccessToken('');
		this.setRefreshToken('');
	}


	/**------------------------------------------------------
	 * User / Owner of Storage Data
	 */
	isUserSame(userMail: string): boolean {
		const mailHash: string = Util.Crypto.quickHash(userMail);
		const oldHash : string = this.storageAccessHelper.getStorageItem(EnumAppStateCategory.Auth, 'userHash');
		return mailHash === oldHash;
	}
	setUserHash(userMail: string): void {
		const mailHash: string = Util.Crypto.quickHash(userMail);
		this.storageAccessHelper.setStorageItem(EnumAppStateCategory.Auth, 'userHash', mailHash);
	}


	/**------------------------------------------------------
	 * Authentication
	 */
	getAccessToken(): string {
		return this.storageAccessHelper.getStorageItem(EnumAppStateCategory.Auth, 'accessToken');
	}
	setAccessToken(accessToken: string): void {
		this.storageAccessHelper.setStorageItem(EnumAppStateCategory.Auth, 'accessToken', accessToken);
	}

	getRefreshToken(): string {
		return this.storageAccessHelper.getStorageItem(EnumAppStateCategory.Auth, 'refreshToken');
	}
	setRefreshToken(refreshToken: string): void {
		this.storageAccessHelper.setStorageItem(EnumAppStateCategory.Auth, 'refreshToken', refreshToken);
	}


	/**------------------------------------------------------
	 * Save & Load Scaling Layout
	 */
	getScaling(): number {
		return this.storageAccessHelper.getStorageItem(EnumAppStateCategory.Styling, 'layoutScale');
	}
	setScaling(scale: number): void {
		this.storageAccessHelper.setStorageItem(EnumAppStateCategory.Styling, 'layoutScale', scale);
	}


	/**------------------------------------------------------
	 * Info Messages (Confirmations & Infos)
	 */
	getInfoMessageValue<T extends string | boolean>(infoMessageName: EnumInfoMessages): T | null {
		return this.storageAccessHelper.getStorageItem(EnumAppStateCategory.InfoMessages, infoMessageName) as T | null;
	}
	setInfoMessageValue(infoMessageName: EnumInfoMessages, value: string | boolean): void {
		this.storageAccessHelper.setStorageItem(EnumAppStateCategory.InfoMessages, infoMessageName, value);
	}

	disableInfoMessage(infoMessageName: EnumInfoMessages): void {
		this.storageAccessHelper.setStorageItem(EnumAppStateCategory.InfoMessages, infoMessageName, false);
	}
	isInfoMessageActive(infoMessageName: EnumInfoMessages): boolean {
		return this.storageAccessHelper.getStorageItem(EnumAppStateCategory.InfoMessages, infoMessageName) as boolean;
	}
}
