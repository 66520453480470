import { Util } from '@libs/utilities/util';

import { IAmazonSearchOptions } from '../amazon-search.data';
import { EnumAmazonMarketplace } from '../../../../../../constants/research/app-constants';
import { AbstractSearchLinks } from '../../../../data/search-links.abstract';
import { ISearchLinkConfig } from '../../../../data/search-links.interface';
import { EnumMbaMarketplace, IAmazonMbaMarket, MBA_MARKETPLACE_SPECIFICATION } from '../../../../../../constants/upload';


/**------------------------------------------------------
 * Amazon US Search Links
 */
export abstract class AbstractAmazonSearch extends AbstractSearchLinks<IAmazonSearchOptions> {

	constructor(
		protected marketplace		: EnumAmazonMarketplace,
		override searchLinkConfig	: ISearchLinkConfig,
		override defaultOptions		: Partial<IAmazonSearchOptions>
	) {
		super(searchLinkConfig, defaultOptions);
	}


	/**------------------------------------------------------
	 * Brand & ASIN Links
	 */
	brandUrl(brand: string): string {
		return this.getMbaConfiguration().brandUrl.replace('[BRAND]', brand);
	}

	asinUrl(asin: string): string {
		return this.getMbaConfiguration().asinUrl.replace('[ASIN]', asin);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getMbaConfiguration(): IAmazonMbaMarket {

		//0 - find the matching marketplace config
		const marketConfig: IAmazonMbaMarket | undefined = MBA_MARKETPLACE_SPECIFICATION.find((elem: IAmazonMbaMarket) => elem.code === Util.Basic.castType<EnumMbaMarketplace>(this.marketplace));
		if (!marketConfig) throw new Error(`AmazonComSearch => getMbaSpecification => FATAL ERROR: marketConfig not defined for marketplace of "${this.marketplace}"`);

		//1 - return the config
		return marketConfig;
	}
}
