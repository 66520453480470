import { Component, Input, OnInit } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { Subject, Subscription, combineLatest, interval, take } from 'rxjs';

import { INgFaIcon, EnumNgFaStyle } from '../../../../styling-components';
import { fadeInRightOnEnterAnimation } from '../../../../animations';
import { EnumNgTimeOptions } from './message-time/message-time.interface';
import { EnumNgToastPlacement, INgMessageToastButton, INgMessageToastInput } from '../message-toast.interface';
import { EnumNgMessageToastType, INgMessageToastConfig, INgMessageToastType, NG_MESSAGE_TOAST_TYPE_INFO } from './message-toast.interface';


@Component({
	selector	: 'ng-message-toast',
	templateUrl	: './message-toast.component.html',
	styleUrls	: ['./message-toast.component.scss'],
	animations	: [
		fadeInRightOnEnterAnimation()
	]
})
export class NgMessageToastComponent implements OnInit {

	//** Configurations */
	readonly DEFAULT_PROGRESS_FILL_TIME : number = 4000;
	readonly MAX_BUTTON_LABEL_LENGTH	: number = 10;
	readonly PROGRESS_INTERVAL_TIME 	: number = 25;
	readonly DEFAULT_TOAST_CONFIG		: INgMessageToastConfig = {
		animation	: true,
		autohide	: true,
		delay		: 5000
	};

	//** Enum Helper */
	readonly EnumTimeOptions   : typeof EnumNgTimeOptions    = EnumNgTimeOptions;
	readonly EnumToastPlacement: typeof EnumNgToastPlacement = EnumNgToastPlacement;

	//** Decorators */
	@Input() messageToast!: INgMessageToastInput;
	@Input() placement	  : EnumNgToastPlacement = null!;

	//** Helper Variables */
	messageToastConfig	: INgMessageToastConfig = Util.Basic.deepCopy(this.DEFAULT_TOAST_CONFIG);
	remainingTime		: Date = null!;
	timeAgo				: Date = null!;
	progressBarSubscribe: Subscription | null = null;
	messageToastType	: INgMessageToastType = null!;

	//** Actions */
	onClose$			: Subject<void>   = new Subject<void>();
	onCloseAll$			: Subject<void>   = new Subject<void>();
	onAction$			: Subject<string> = new Subject<string>();


	ngOnInit(): void {
		this.messageToastType = NG_MESSAGE_TOAST_TYPE_INFO[this.messageToast.type];
	}


	/**------------------------------------------------------
	 * Getter & Setter Helpers
	 */
	setTitle(title: string): NgMessageToastComponent {
		this.messageToast.title = title;
		return this;
	}

	setMessage(message: string): NgMessageToastComponent {
		this.messageToast.message = message;
		return this;
	}

	setSolidIcon(iconName: string): NgMessageToastComponent {
		this.messageToast.icon = { style: EnumNgFaStyle.Solid, name: iconName };
		return this;
	}

	setIcon(icon: INgFaIcon): NgMessageToastComponent {
		this.messageToast.icon = icon;
		return this;
	}


	/**------------------------------------------------------
	 * Action Buttons
	 */
	setActions(actions: INgMessageToastButton[]): NgMessageToastComponent {
		if (Util.Array.isEmpty(actions)) throw new Error(`NgMessageToastComponent => setActions => FATAL ERROR: provided actions can not be empty`);
		this.messageToast.actions = actions;
		return this;
	}

	removeActions(): NgMessageToastComponent {
		this.messageToast.actions = [];
		return this;
	}

	addAction(action: INgMessageToastButton): NgMessageToastComponent {
		this.messageToast.actions.push(action);
		return this;
	}


	/**------------------------------------------------------
	 * Setter for progress bar
	 */
	startProgressBar(loadingTimeMs: number = this.DEFAULT_PROGRESS_FILL_TIME, autoClose: boolean = true): NgMessageToastComponent {

		//0 - define helper variables
		const progressMaxValue: number = Math.ceil(loadingTimeMs / this.PROGRESS_INTERVAL_TIME);
		let progressCounter : number = 0;

		//1 - create an interval for smooth loading
		this.progressBarSubscribe = interval(this.PROGRESS_INTERVAL_TIME).subscribe(() => {

			//a. set the progress bar
			const progressValue: number = 100 / progressMaxValue * progressCounter++;
			this.setProgressBar(progressValue, autoClose);

			//b. stopping the interval
			if (progressValue > 100) this.resetProgressBar();
		});

		//2 - disable auto close if required
		if (!autoClose) this.disableAutoClose();
		return this;
	}

	resetProgressBar(): NgMessageToastComponent {
		if (this.progressBarSubscribe) this.progressBarSubscribe.unsubscribe();
		this.setProgressBar(0);
		return this;
	}

	setProgressBar(progress: number, autoClose: boolean = true): NgMessageToastComponent {

		//0 - check if progress value is correct
		if (progress < 0) throw new Error(`NgMessageToastComponent => setProgressBar => FATAL ERROR: progress value of ${progress} is invalid (only values 0 greater are allowed)`);

		//1 - disable auto close
		if (!autoClose) this.disableAutoClose();

		//3 - update the progress value (if 100% is reached close modal)
		if (autoClose && progress >= 100) this.close();
		this.messageToast.progress = progress;
		return this;
	}


	/**------------------------------------------------------
	 * Show Time Options
	 */
	showTimeStamp(): NgMessageToastComponent {
		this.disableAutoClose();
		this.timeAgo = new Date();
		return this;
	}

	showCountdown(seconds: number): NgMessageToastComponent {

		//0 - set the remaining time
		const endDateTime: Date = new Date();
		endDateTime.setSeconds(endDateTime.getSeconds() + seconds);

		//1 - disable auto hide and set the remaining time
		this.disableAutoClose();
		this.remainingTime = endDateTime;
		return this;
	}

	exactTimeOver(event: boolean): void {
		if (event) this.onClose$.next();
	}


	/**------------------------------------------------------
	 * Close Options
	 */
	closeAfter(timeMs: number): NgMessageToastComponent {
		this.messageToastConfig.autohide = true;
		this.messageToastConfig.delay    = timeMs;
		return this;
	}

	closeAfterDefault(): NgMessageToastComponent {
		return this.closeAfter(this.messageToastConfig.delay!);
	}

	close(): NgMessageToastComponent {
		this.onClose$.next();
		return this;
	}

	closeAll(): NgMessageToastComponent {
		this.onCloseAll$.next();
		return this;
	}

	disableAutoClose(): NgMessageToastComponent {
		this.messageToastConfig.autohide = false;
		return this;
	}


	/**------------------------------------------------------
	 * Subscribe
	 */
	subscribe(callbackFunction: Function): NgMessageToastComponent {
		combineLatest([this.onClose$, this.onCloseAll$, this.onAction$]).pipe(take(1)).subscribe(() => callbackFunction());
		return this;
	}

	onAction(callbackFunction: Function): NgMessageToastComponent {
		this.onAction$.pipe(take(1)).subscribe((action: string) => callbackFunction(action));
		return this;
	}

	onClose(callbackFunction: Function): NgMessageToastComponent {
		combineLatest([this.onClose$, this.onCloseAll$]).pipe(take(1)).subscribe(() => callbackFunction());
		return this;
	}


	/**------------------------------------------------------
	 * Change Toast Type
	 */
	toDefault(): NgMessageToastComponent {
		this.setType(EnumNgMessageToastType.ToastDefault);
		return this;
	}

	toSuccess(): NgMessageToastComponent {
		this.setType(EnumNgMessageToastType.ToastSuccess);
		return this;
	}

	toWarning(): NgMessageToastComponent {
		this.setType(EnumNgMessageToastType.ToastWarning);
		return this;
	}

	toError(): NgMessageToastComponent {
		this.setType(EnumNgMessageToastType.ToastError);
		return this;
	}

	private setType(type: EnumNgMessageToastType) {

		//0 - is the type valid
		if (!Util.Enum.isValid(EnumNgMessageToastType, type)) throw new Error(`NgMessageToastComponent => setType => FATAL ERROR: type ${type} is not valid`);

		//1 - change the type of the modal
		this.messageToast.type = type;
		this.messageToastType  = NG_MESSAGE_TOAST_TYPE_INFO[type];
	}
}
