/**------------------------------------------------------
 * Language Constants
 */
export enum EnumLanguage {
	// cspell:disable
	AF = 'af',      // Afrikaans
	AM = 'am',      // Amharic
	AR = 'ar',      // Arabic
	AZ = 'az',      // Azerbaijani
	BE = 'be',      // Belarusian
	BG = 'bg',      // Bulgarian
	BI = 'bi',      // Bislama
	BN = 'bn',      // Bengali
	BR = 'br',      // Breton
	BS = 'bs',      // Bosnian
	CA = 'ca',      // Catalan
	CS = 'cs',      // Czech
	CY = 'cy',      // Welsh
	DA = 'da',      // Danish
	DE = 'de',      // German
	DV = 'dv',      // Divehi
	DZ = 'dz',      // Dzongkha
	EL = 'el',      // Greek
	EN = 'en',      // English
	EO = 'eo',      // Esperanto
	ES = 'es',      // Spanish
	ET = 'et',      // Estonian
	EU = 'eu',      // Basque
	FA = 'fa',      // Persian
	FI = 'fi',      // Finnish
	FJ = 'fj',      // Fijian
	FR = 'fr',      // French
	GA = 'ga',      // Irish
	GD = 'gd',      // Scots Gaelic
	GL = 'gl',      // Galician
	GN = 'gn',      // Guarani
	GU = 'gu',      // Gujarati
	HE = 'he',      // Hebrew
	HI = 'hi',      // Hindi
	HR = 'hr',      // Croatian
	HT = 'ht',      // Haitian Creole
	HU = 'hu',      // Hungarian
	HY = 'hy',      // Armenian
	ID = 'id',      // Indonesian
	IS = 'is',      // Icelandic
	IT = 'it',      // Italian
	JA = 'ja',      // Japanese
	KA = 'ka',      // Georgian
	KK = 'kk',      // Kazakh
	KM = 'km',      // Khmer
	KN = 'kn',      // Kannada
	KO = 'ko',      // Korean
	KU = 'ku',      // Kurdish
	KY = 'ky',      // Kyrgyz
	LA = 'la',      // Latin
	LB = 'lb',      // Luxembourgish
	LO = 'lo',      // Lao
	LT = 'lt',      // Lithuanian
	LV = 'lv',      // Latvian
	MG = 'mg',      // Malagasy
	MH = 'mh',      // Marshallese
	MI = 'mi',      // Maori
	MK = 'mk',      // Macedonian
	ML = 'ml',      // Malayalam
	MN = 'mn',      // Mongolian
	MR = 'mr',      // Marathi
	MS = 'ms',      // Malay
	MT = 'mt',      // Maltese
	MY = 'my',      // Burmese
	NE = 'ne',      // Nepali
	NL = 'nl',      // Dutch
	NO = 'no',      // Norwegian
	NR = 'nr',      // Nauruan
	NY = 'ny',      // Nyanja (Chichewa)
	PA = 'pa',      // Punjabi
	PL = 'pl',      // Polish
	PS = 'ps',      // Pashto
	PT = 'pt',      // Portuguese
	RO = 'ro',      // Romanian
	RU = 'ru',      // Russian
	SD = 'sd',      // Sindhi
	SI = 'si',      // Sinhala
	SK = 'sk',      // Slovak
	SL = 'sl',      // Slovenian
	SM = 'sm',      // Samoan
	SN = 'sn',      // Shona
	SO = 'so',      // Somali
	SQ = 'sq',      // Albanian
	SR = 'sr',      // Serbian
	SV = 'sv',      // Swedish
	SW = 'sw',      // Swahili
	TA = 'ta',      // Tamil
	TE = 'te',      // Telugu
	TG = 'tg',      // Tajik
	TH = 'th',      // Thai
	TI = 'ti',      // Tigrinya
	TK = 'tk',      // Turkmen
	TL = 'tl',      // Tagalog (Filipino)
	TO = 'to',      // Tongan
	TR = 'tr',      // Turkish
	TT = 'tt',      // Tatar
	TW = 'tw',      // Twi
	UK = 'uk',      // Ukrainian
	UR = 'ur',      // Urdu
	UZ = 'uz',      // Uzbek
	VI = 'vi',      // Vietnamese
	XH = 'xh',      // Xhosa
	YI = 'yi',      // Yiddish
	YO = 'yo',      // Yoruba
	ZH = 'zh',      // Chinese
	ZU = 'zu'       // Zulu
	// cspell:enable
}


/**------------------------------------------------------
 * Language Info Interface
 */
export interface ILanguageInfo {
	code		: EnumLanguage;
	name		: string;
	nameNative 	: string;
	flagIcon 	: ILanguageFlagIcons;
}

export interface ILanguageFlagIcons {
	ratio1x1	: string;
	ratio4x3	: string;
}
