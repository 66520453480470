/* eslint-disable import/no-cycle */
import { Util } from '@libs/utilities/util';

import { AbstractValidatorValue } from '../shared/value-processor/value.abstract';
import { EnumValidatorOperationType } from '../shared/validator-value.interface';
import { IValidatorError } from '../shared/validator-error.interface';
import { ValidatorSchemaRef } from '../shared/validator-schema.interface';
import { Validator } from '../validator';


export class ValidatorValueArray extends AbstractValidatorValue<ValidatorValueArray> {

	//** Overwrite */
	protected requiredTypeName()		  : string  { return 'array'; }
	protected isValueTypeValid(value: any): boolean { return Util.Basic.isArray(value); }


	/**------------------------------------------------------
	 * Validate Array Items
	 */
	each(validator: AbstractValidatorValue<void>): ValidatorValueArray {

		//0 - add validation to pipeline
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => {

				//a. validate all items
				const itemValidationErrors: IValidatorError[] = values
					.map((item: any, index: number) => this.valueProcessor.processValueByOperations(validator.getOperations(), item, `${index}`).validationError)
					.filter((result: IValidatorError | null) => result !== null) as IValidatorError[];

				//b. prepare the result
				return {
					isValid		: itemValidationErrors.length === 0,
					error		: 'Not all items in the array are valid',
					children	: itemValidationErrors
				};
			}
		});

		//1 - add transformation
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (values: any[]) => {
				const processedValues: any[] = values.map((item: any, index: number) => this.valueProcessor.processValueByOperations(validator.getOperations(), item, `${index}`).transformedValue);
				return processedValues;
			}
		});
		return this;
	}

	inspectEach<T extends object>(objectOrClassSchema: ValidatorSchemaRef<T>): ValidatorValueArray {

		//0 - add validation to pipeline
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (objects: T[]) => {

				//a. validate all items
				const itemValidationErrors: IValidatorError[] = objects
					.map((item: T, index: number) => {

						// validate the object, if we have no errors stop here
						const validationErrors: IValidatorError[] = Validator.Schema.validate<T>(item, objectOrClassSchema);
						if (validationErrors.length === 0) return null;

						// convert the result into a validation error
						const validationError: IValidatorError = {
							property	: `${index}`,
							value		: item,
							errors		: ['Object is invalid'],
							children	: validationErrors
						};
						return validationError;
					})
					.filter((result: IValidatorError | null) => result !== null) as IValidatorError[];

				//b. prepare the result
				return {
					isValid		: itemValidationErrors.length === 0,
					error		: 'Not all items in the array are valid',
					children	: itemValidationErrors
				};
			}
		});

		//1 - add transformation
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (objects: T[]) => {
				const processedValues: T[] = objects.map((item: T, index: number) => Validator.Schema.transform<T>(item, objectOrClassSchema, { disableCheck: true }));
				return processedValues;
			}
		});
		return this;
	}


	/**------------------------------------------------------
	 * Empty
	 */
	isEmpty(): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: Util.Array.isEmpty(values),
				error		: 'The array is not empty'
			})
		});
		return this;
	}

	isNotEmpty(): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: Util.Array.isNotEmpty(values),
				error		: 'The array is empty'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Array Size
	 */
	isSize(size: number): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: values.length === size,
				error		: `The array must have ${size} items`
			})
		});
		return this;
	}

	isMinSize(min: number): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: values.length >= min,
				error		: `The array must have at least ${min} items`
			})
		});
		return this;
	}

	isMaxSize(max: number): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: values.length <= max,
				error		: `The array must have at most ${max} items`
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Unique
	 */
	isUnique(): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: !Util.Array.hasDuplicates(values),
				error		: 'The array must not have duplicate items'
			})
		});
		return this;
	}
	toUnique(): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (values: any[]) => Util.Array.unique(values)
		});
		return this;
	}


	/**------------------------------------------------------
	 * Contains / Includes
	 */
	contains(element: any): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: Util.Array.contains(values, element),
				error		: 'The array must contain the element'
			})
		});
		return this;
	}

	notContains(element: any): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: !Util.Array.contains(values, element),
				error		: 'The array must not contain the element'
			})
		});
		return this;
	}

	includesAny(element: any[]): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: Util.Array.includesAny(values, element),
				error		: 'The array must include at least one of the elements'
			})
		});
		return this;
	}

	includesAll(element: any[]): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Validate,
			validateFn	: (values: any[]) => ({
				isValid		: Util.Array.includesAll(values, element),
				error		: 'The array must include at least one of the elements'
			})
		});
		return this;
	}


	/**------------------------------------------------------
	 * Transformers
	 */
	sort(compareFn: (a: any, b: any) => number): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (values: any[]) => values.sort(compareFn)
		});
		return this;
	}

	reverse(): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (values: any[]) => values.reverse()
		});
		return this;
	}

	shuffle(): ValidatorValueArray {
		this.operations.push({
			type		: EnumValidatorOperationType.Transform,
			transformFn	: (values: any[]) => Util.Array.shuffle(values)
		});
		return this;
	}
}
