import { IAdvertisingList } from '../../../data-structures';


/**------------------------------------------------------
 * Advertising List Request API-Interfaces
 */
export interface ICreateAdvertisingListRequest {
	advertisingList		: IAdvertisingList[];
}

export interface IDeleteAdvertisingListRequest {
	advertisingListIds	: string[];
}


/**------------------------------------------------------
 * Advertising List Response API-Interfaces
 */
export interface IGetAdvertisingListsResponse {
	response	: IAdvertisingList[];
}

export interface ICreateAdvertisingListResponse {
	updated		: number;
	created		: number;
}
