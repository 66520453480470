/**------------------------------------------------------
 * Chunk Text Into Blocks
 */
export class UtilTextToBlocks {

	//** Configurations / Constants */
	private readonly MIN_BLOCK_SIZE: number = 1;


	/**------------------------------------------------------
	 * Chunking Texts
	 */
	chunkTextToBlocks(text: string, blockSize: number): string[] {

		//0 - check the parameters
		if (this.isEmpty(text))  			 throw new Error(`TextToBlocks => chunkTextToBlocks => FATAL ERROR: provided text of "${text}" is empty`);
		if (blockSize < this.MIN_BLOCK_SIZE) throw new Error(`TextToBlocks => chunkTextToBlocks => FATAL ERROR: provided blockSize of "${blockSize}" is smaller as the MIN_BLOCK_SIZE of "${this.MIN_BLOCK_SIZE}"`);

		//1 - is the text shorter
		if (text.length < blockSize) return [text];

		//2 - split the text by sentences
		const sentenceMiniBlocks: IUtilSplittedTextBlocks = this.splitTextBlocks([text], blockSize, (splitTextBlock: string) => splitTextBlock.match(/[^.!?,]+[.!?,]+/g));
		if (sentenceMiniBlocks.isValid) return sentenceMiniBlocks.textBlocks;

		//3 - split the text by words
		const wordMiniBlocks: IUtilSplittedTextBlocks = this.splitTextBlocks(sentenceMiniBlocks.textBlocks, blockSize, (splitTextBlock: string) => splitTextBlock.split(' '));
		if (wordMiniBlocks.isValid) return wordMiniBlocks.textBlocks;

		//4 - split the text by chars
		const charMiniBlocks: IUtilSplittedTextBlocks = this.splitTextBlocks(wordMiniBlocks.textBlocks, blockSize, (splitTextBlock: string) => splitTextBlock.match(new RegExp(`.{1,${blockSize}}`, 'g')));
		if (charMiniBlocks.isValid) return charMiniBlocks.textBlocks;

		//5 - if the text was not been chunked properly throw an error
		throw new Error(`TextToBlocks => chunkTextToBlocks => FATAL ERROR: the text of "${text}" was not been able to chunked properly to a block size of "${blockSize}" (charMiniBlocks: "${charMiniBlocks}")`);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private optimizeTextBlocks(textBlocks: string[], blockSize: number): string[] {

		//0 - are any blocks available of is any block to large?
		if (!textBlocks || textBlocks.length === 0) throw new Error(`chunkTextToBlocks => optimizeTextBlocks => FATAL ERROR: the provided textBlocks of "${textBlocks}" is empty or not defined`);

		//1 - optimize the blocks
		const optimizedBlocks: string[] = [''];
		for (const textBlock of textBlocks) {

			//a. can we fit one more part in the block?
			const index		  : number = optimizedBlocks.length - 1;
			const newBlockText: string = `${optimizedBlocks[index]} ${textBlock}`;
			if (newBlockText.length <= blockSize) {
				optimizedBlocks[index] = newBlockText;
				continue;
			}

			//b. if not add the text to the new block
			optimizedBlocks.push(textBlock);
		}

		//2 - return the optimized blocks
		return this.purifyText(optimizedBlocks);
	}

	private splitTextBlocks(textBlocks: string[], blockSize: number, splitFunction: (textBlocks: string) => string[] | null): IUtilSplittedTextBlocks {

		//0 - split the text into sentences
		const newTextBlocks: string[] = [];
		for (const textBlock of textBlocks) {

			//a. is the text shorter then max
			if (textBlock.length < blockSize) {
				newTextBlocks.push(textBlock);
				continue;
			}

			//b. if longer it needs to be splitted
			const blockSplit: string[] | null = splitFunction(textBlock);
			if (!blockSplit || blockSplit.length <= 1) {
				newTextBlocks.push(textBlock);
				continue;
			}

			//c. add the new blocks
			const purifiedBlocks: string[] = this.purifyText(blockSplit);
			newTextBlocks.push(...purifiedBlocks);
		}

		//1 - check if all blocks are valid
		let isValid: boolean = true;
		for (const textBlock of newTextBlocks) {
			if (textBlock.length > blockSize) isValid = false;
		}

		//2 - return the result
		const result: IUtilSplittedTextBlocks = {
			textBlocks	: this.optimizeTextBlocks(newTextBlocks, blockSize),
			isValid		: isValid
		};

		return result;
	}

	private purifyText(textBlocks: string[]): string[] {
		return textBlocks.map((elem: string) => elem.trim()).filter((elem: string) => elem.length > 0);
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private isEmpty(value: string | any): boolean {
		const isString: boolean = typeof value === 'string' || value instanceof String;
		if (!isString || (value as string).trim().length === 0) return true;						// duplication of the string util functionality
		return false;
	}
}


//** Interfaces --------------------------------- */
export interface IUtilSplittedTextBlocks {
	textBlocks	: string[];
	isValid		: boolean;
}
