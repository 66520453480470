import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen, shareReplay } from 'rxjs/operators';
import { Util } from '@libs/utilities/util';


/**------------------------------------------------------
 * RxJs Http
 * ---------
 * ...
 */
export class RxJsHttp {

	//** Configurations */
	private readonly RETRY_OPTIONS: IRxJsRetryOptions = {
		attempts		: 2,
		delayPeriod		: 2000,
		onFailCallback	: (error: any, attempt: number) => {}
	};

	//** Helper Variables */
	private readonly replayCache: Map<string, object> = new Map<string, object>();


	//** Retry Observable */
	retry<T>(request: Observable<T>, options?: Partial<IRxJsRetryOptions>): Observable<T> {
		return request.pipe(retryWhen((errors: Observable<unknown>) => {
			return errors.pipe(concatMap((error: any, i: number) => {

				//0 - define the options
				const retryOptions: IRxJsRetryOptions = Util.Function.assignOptions(this.RETRY_OPTIONS, options);

				//1 - are there any retries left
				if (i >= retryOptions.attempts - 1) return throwError(() => new Error(error));

				//2 - retry & use the callback to show logs, ...
				retryOptions?.onFailCallback?.(error, i + 1);
				return of(error).pipe(delay(retryOptions.delayPeriod));
			}));
		}));
	}

	replay<T>(id: string, request: Observable<T>): Observable<T> {

		//0 - was the value cached
		const cachedValue: Observable<T> = this.replayCache.get(id) as Observable<T>;
		if (cachedValue) return cachedValue;

		//1 - add the new request to the cache
		const replayRequest: Observable<T> = request.pipe(shareReplay());
		this.replayCache.set(id, replayRequest);
		return replayRequest;
	}
}


//** Interfaces --------------------------------- */
export interface IRxJsRetryOptions {
	attempts		: number;
	delayPeriod		: number;
	onFailCallback	: (error: any, attempt: number) => void;
}
