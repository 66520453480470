import { ISecureHttpSecurityOptions } from '@domains/security/shared';
import { TIME_DURATION } from '@libs/constants';


/**------------------------------------------------------
 * Flying Endpoint Routes
 */
export enum EnumFlyingEndpointRoute {

	/**------------------------------------------------------
	 * AI Research
	 */
	AiResearchNicheFinder 	= '/flying/ai-research/niche-finder',
	AiResearchQuotesAndPuns = '/flying/ai-research/quotes-and-puns',
	AiResearchPromptSearch 	= '/flying/ai-research/prompt-search',


	/**------------------------------------------------------
	 * Trademarks
	 */
	TrademarkSearch 		= '/flying/trademark/search',
	TrademarkBulkSearch		= '/flying/trademark/bulk-search',
	TrademarkApplicantSearch = '/flying/trademark/applicant',
	TrademarkListingCheck 	= '/flying/trademark/listing-check',

	//** Watchlist, Whitelist, and Blacklist */
	TrademarkWatchlist 		= '/flying/trademark/watchlist',
	TrademarkWatchlistAdd 	= '/flying/trademark/watchlist/add',
	TrademarkWhitelist 		= '/flying/trademark/whitelist',
	TrademarkWhitelistAdd 	= '/flying/trademark/whitelist/add',
	TrademarkBlacklist 		= '/flying/trademark/blacklist',
	TrademarkBlacklistAdd 	= '/flying/trademark/blacklist/add',


	/**------------------------------------------------------
	 * Settings
	 */
	ResearchSettings 		= '/flying/research/settings',
	UploadSettings 			= '/flying/upload/settings',


	/**------------------------------------------------------
	 * Others
	 */
	EditTranslate			= '/flying/upload/edit-translate',
	BugReport				= '/flying/bug-report',


	/**------------------------------------------------------
	 * Design Management
	 */
	ImportDesigns			= '/flying/upload/design-import/import-designs',
	ActivateDesigns			= '/flying/upload/design-import/activate-designs',
	DuplicateDesigns		= '/flying/upload/design-import/duplicate-designs',

	DesignsFingerprints		= '/flying/upload/design-management/designs-fingerprints',
	DesignsInfo				= '/flying/upload/design-management/designs-info',
	DesignsSearch			= '/flying/upload/design-management/designs-search',
	DesignsList				= '/flying/upload/design-management/designs-list',

	DeleteDesigns			= '/flying/upload/design-edit/delete-designs',
	ClearDesigns			= '/flying/upload/design-edit/clear-designs',
	UpdateListing			= '/flying/upload/design-edit/update-listings',
	UpdateUploads			= '/flying/upload/design-edit/update-uploads',


	/**------------------------------------------------------
	 * Upload
	 */
	UploadListingByVision 	= '/sse/flying/upload/ai-listing/vision',
	UploadListingByKeywords = '/sse/flying/upload/ai-listing/keywords',
	UploadListingByTemplate = '/sse/flying/upload/ai-listing/template',
	UploadAiTemplates 		= '/flying/upload/ai-templates',
	UploadFreeUploads		= '/flying/upload/local-uploader/free-uploads',
	UploadPodUploads		= '/flying/upload/local-uploader/pod-uploads'
}


/**------------------------------------------------------
 * Security Configurations
 */
export const FLYING_ENDPOINT_SECURITY_CONFIG: Partial<Record<EnumFlyingEndpointRoute, Partial<ISecureHttpSecurityOptions>>> = {
	// ... overwrite the default security options here
};

// eslint-disable-next-line @typescript-eslint/typedef
export const FLYING_AI_RESEARCH_RATE_LIMIT = {
	endpoint	: '/flying/ai-research/*',
	rateLimit	: 200,
	timeWindow	: TIME_DURATION.PERIOD_1_DAY
};
