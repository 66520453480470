import { EnumPlatform, EnumWebsiteOption } from '../../app-constants/platforms/print-on-demand.constants';
import { IPlatformTemplate, TypePlatformTemplate } from './platform-template.interface';


/**------------------------------------------------------
 * Platform Account
 */
export interface IPlatformAccount {
	name        	: string;				// 'mySpreadshirt',
	description 	: string;				// 'My main spreadshirt account with ...',
	platform    	: EnumPlatform;			// code/id 'spredshirt_eu'
	websiteOption   : EnumWebsiteOption;	// code/id 'gb-EN',
	login 			: {
		user  		: string;				// encrypted: 'MrGreen'
		password  	: string;				// encrypted: 'Rn2Ryn84jhNeBHBvd2NzMS7sFwUf4tdf',
	};
	templates 		: IPlatformTemplate<TypePlatformTemplate>[];
}
