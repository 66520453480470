/**------------------------------------------------------
 * Society6 Constants
 */
export enum EnumSociety6TemplateType {
	Simplified		= 'simplified',
	Custom			= 'custom',
	Pattern			= 'pattern'
}

export enum EnumSociety6ProductGroup {
	WallArt 		= 'wall_art',
	Accessories 	= 'accessories',
	Clothing 		= 'clothing',
	Tech 			= 'tech'
}

export enum EnumSociety6BackgroundType {
	None			= 'none',
	Color			= 'color'
}

export enum EnumSociety6ProductType {
	WallArt			= 'wall_art',
	HomeDecor		= 'home_decor',
	Furniture		= 'furniture',
	BedAndBath		= 'bed_and_bath',
	Tabletop		= 'tabletop',
	Office			= 'office',
	Tech			= 'tech',
	ApparelAndBags	= 'apparel_and_bags',
	OutdoorAndLifestyle = 'outdoor_and_lifestyle'
}

export enum EnumSociety6ProductName {
	MetalPrint		= 'metal_print'
	// ...
}

export enum EnumSociety6Colors {
	Black			= 'black'
	// ...
}


/**------------------------------------------------------
 * Society6 Template
 */
export interface ISociety6TemplateOptions {
	type 			: EnumSociety6TemplateType;
	template		: ISociety6SimplifiedTemplate | ISociety6CustomTemplate | ISociety6PatternTemplate;
}

export interface ISociety6SimplifiedTemplate {				// Uploading by pre configured product settings
	productGroups	: EnumSociety6ProductGroup;
	background		: {
		type		: EnumSociety6BackgroundType;
		color		: string;						// hex code of the color
	};
}

export interface ISociety6CustomTemplate {					// Uploading defined products
	products		: Array<{
		name		: EnumSociety6ProductName;
		scale		: number;
		colors		: EnumSociety6Colors;
	}>;
}

export interface ISociety6PatternTemplate {				// Uploading a pattern design
	productGroups	: EnumSociety6ProductGroup;
}
