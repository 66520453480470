import { ISparkSidebarConfig, ISparkSidebarSubMenu } from '@domains/frontend/templates/spark';
import { EnumNgFaStyle } from '@libs/frameworks/angular';

import { SHARED_PAGE_LIST } from '../page-lists/shared-pages/shared-pages.data';
import { EnumSidebarPage } from '../sidebar-pages.interface';
import { EnumWebAppTool } from '../../../../shared/constants/app.constant';
import { ADVERTISEMENT_PAGE_LIST, AI_RESEARCH_PAGE_LIST, KEYWORD_PAGE_LIST, RESEARCH_EVENT_PAGE_LIST, RESEARCH_PAGE_LIST, TRADEMARK_PAGE_LIST } from '../page-lists/research-pages/research-pages.data';


/**------------------------------------------------------
 * Research Sidebar Menu
 */
export const RESEARCH_SIDEBAR_LIST: ISparkSidebarConfig = {
	appInfo			: {
		id			: EnumWebAppTool.Research,
		label		: 'Flying Research',
		logoWide	: 'assets/research/logos/research-logo-wide.svg',
		logoSmall	: 'assets/research/logos/research-logo.svg'
	},
	pageLists	: [
		{
			label: 'Menu',
			menus: [
				{
					id		: EnumSidebarPage.Dashboard,
					label	: 'Dashboards',
					icon	: {
						style 	: EnumNgFaStyle.Solid,
						name 	: 'gauge'
					},
					isCollapsed	: false,
					subMenus	: SHARED_PAGE_LIST
				}
			]
		},
		{
			label: 'Research',
			menus: [
				{
					id		: EnumSidebarPage.ResearchMenuResearch,
					label	: 'Research',
					icon	: {
						style 	: EnumNgFaStyle.Solid,
						name 	: 'flask'
					},
					isCollapsed	: false,
					subMenus	: RESEARCH_PAGE_LIST
				},
				{
					id		: EnumSidebarPage.ResearchMenuAiResearch,
					label	: 'AI Research',
					icon	: {
						style 	: EnumNgFaStyle.Solid,
						name 	: 'robot-astromech'
					},
					isCollapsed	: false,
					subMenus	: AI_RESEARCH_PAGE_LIST
				},
				{
					id		: EnumSidebarPage.ResearchMenuKeywords,
					label	: 'Keywords',
					icon	: {
						style 	: EnumNgFaStyle.Solid,
						name 	: 'tags'
					},
					isCollapsed	: false,
					subMenus	: KEYWORD_PAGE_LIST
				},
				{
					id		: EnumSidebarPage.ResearchMenuAdvertisement,
					label	: 'Advertising',
					icon	: {
						style 	: EnumNgFaStyle.Solid,
						name 	: 'chart-line'
					},
					isCollapsed	: false,
					subMenus	: ADVERTISEMENT_PAGE_LIST
				},
				{
					id		: EnumSidebarPage.ResearchMenuEvents,
					label	: 'Events',
					icon	: {
						style 	: EnumNgFaStyle.Regular,
						name 	: 'calendar-days'
					},
					isCollapsed	: false,
					subMenus	: RESEARCH_EVENT_PAGE_LIST
				},
				{
					id		: EnumSidebarPage.ResearchMenuTrademark,
					label	: 'Trademark',
					icon	: {
						style 	: EnumNgFaStyle.Solid,
						name 	: 'trademark'
					},
					isCollapsed	: false,
					subMenus	: TRADEMARK_PAGE_LIST
				}
			]
		}
		// {
		// 	label: 'Management',
		// 	menus: [
		// 		{
		// 			label	: 'Statistics',
		// 			icon	: {
		// 				style 	: EnumNgFaStyle.Solid,
		// 				name 	: 'chart-pie'
		// 			},
		// 			isCollapsed	: false,
		// 			subMenus	: STATISTICS_PAGE_LIST
		// 		},
		// 		{
		// 			label	: 'Designer/Team',
		// 			icon	: {
		// 				style 	: EnumNgFaStyle.Solid,
		// 				name 	: 'chart-pie'
		// 			},
		// 			isCollapsed	: false,
		// 			subMenus	: MANAGEMENT_PAGE_LIST
		// 		}
		// 	]
		// }
	]
};


/**------------------------------------------------------
 * All Research Pages
 */
export const ALL_RESEARCH_PAGES_LIST: ISparkSidebarSubMenu[] = [
	...RESEARCH_PAGE_LIST,
	...RESEARCH_EVENT_PAGE_LIST,
	...TRADEMARK_PAGE_LIST,
	...KEYWORD_PAGE_LIST,
	...ADVERTISEMENT_PAGE_LIST
];
