/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import { UtilBasic } from './util-basic';


/**------------------------------------------------------
 * Boolean Utilities
 * -----------------
 * > Info: Containing all functionalities related boolean
 * > conversions and functionalities.
 */
export class UtilBoolean {

	constructor(
		private utilBasic: UtilBasic
	) {}


	/**------------------------------------------------------
	 * Check for Boolean Value
	 */
	isBoolean<T>(value: T): boolean {
		return typeof value === 'boolean';
	}

	isTrue<T>(value: T): boolean {
		return this.isBoolean(value) && (this.utilBasic.castType<boolean>(value) === true);
	}
	isFalse<T>(value: T): boolean {
		return this.isBoolean(value) && (this.utilBasic.castType<boolean>(value) === false);
	}


	/**------------------------------------------------------
	 * Convert to Boolean
	 */
	toBoolean<T>(value: T): boolean {
		if (!this.utilBasic.isUndefined(value) && ((value as unknown) === true || (value as unknown) === 'true')) return true;
		return false;
	}
}
