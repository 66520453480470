/**------------------------------------------------------
 * Search Link Configuration
 */
export interface ISearchLinkConfig {
	marketplace		: string;
	websiteUrl		: ISearchLinkUrlConfig;
	pageLimit		: number;
	sortings	    : ISearchParameterConfig<string>[];
	products	    : ISearchParameterConfig<string>[];
	category	    : ISearchParameterConfig<string>[];
	options 		: {
		emptySearch : boolean;
	};
}

export interface ISearchLinkUrlConfig {
	home		: string;
	login		: string;
	search		: string;
}


/**------------------------------------------------------
 * Parameter Configuration
 */
export interface ISearchParameterConfig<T> {
	code			: T;
	name			: string;
	parameters	   ?: IUrlParameters;
	placeholders   ?: IUrlParameters;
	queryOptions   ?: IQueryParameters;
}

export interface IUrlParameters extends Record<string, string> {}

export interface IQueryParameters {
	prefix		: string;
	postfix		: string;
}


/**------------------------------------------------------
 * Options for Search Links
 */
export interface ISearchLinkOptions {
	page			: number;
	sorting			: string;
	product			: string;
	category		: string;
}


/**------------------------------------------------------
 * Enforcing Search Structure
 */
export interface ISearchLinks {
	home() : string;
	login(): string;
	search(searchQuery: string, options?: Partial<ISearchLinkOptions>): string;
}

export interface IAbstractSearchLinks extends ISearchLinks {

	//** Configurations Getters */
	getConfig()		: ISearchLinkConfig;
	getPageLimit()	: number;
	getMarketplace(): string;

	//** Sorting & Product Check */
	hasSortings(): boolean;
	hasProducts(): boolean;

	//** Sorting Getters */
	getSorting<T extends string>(sortingCode: T): ISearchParameterConfig<T>;
	getSortingList<T extends string>()			: ISearchParameterConfig<T>[];

	//** Product Getters */
	getProduct<T extends string>(productCode: T): ISearchParameterConfig<T>;
	getProductList<T extends string>()			: ISearchParameterConfig<T>[];
}

export interface IMarketSearchLinks<T extends string> {

	//** Get Search Handler for Marketplace */
	getMarketplace(marketplace: T): IAbstractSearchLinks;

	//** Links */
	home  (marketplace: T): string;
	login (marketplace: T): string;
	search(marketplace: T, searchQuery: string, options?: Partial<ISearchLinkOptions>): string;
}
