import { EnumColorName } from '@libs/constants';

import { EnumMbaProduct, EnumMbaMarketplace, EnumMbaProductType, EnumMbaFitType, EnumMbaPrint, EnumMbaColor, EnumMbaScalingType } from './mba-amazon.constants';


/**------------------------------------------------------
 * MBA Products Configurations
 * ---------------------------
 * > Amazon Royalties: https://merch.amazon.com/resource/201858580?language=en_US
 * > Linear Curve Fit: https://www.wolframalpha.com/input?i=linear+fit+%7B%7B13.38%2C+0%7D%2C+%7B35.00%2C+15.99%7D%7D
 */
export const MBA_PRODUCT_SPECIFICATION: IAmazonMbaProduct[] = [

	// Standard t-shirt --------------------
	{
		code			: EnumMbaProduct.StandardShirt,
		name			: 'Standard T-Shirt',
		nameShort		: 'Standard T-Shirt',
		chartColor		: EnumColorName.Darkgray,
		oldCode			: 'Standard t-shirt',
		productorCode	: 'STANDARD_TSHIRT',
		keywords		: [
			'standard_shirt', 'standard t-shirt', 'standard shirt', 'standard_shirt',
			'standard_shirts', 'standard t-shirts', 'standard shirts', 'standard_shirts',
			'standard_tshirt', 'shirt', 't-shirt', 't shirt'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 13.38,
				priceMax	: 34.99,
				getRoyalty	: (price: number) => 0.739593 * price - 9.89575			// linear fit {{13.38, 0}, {35.00, 15.99}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 12.08,
				priceMax	: 17.49,
				getRoyalty	: (price: number) => 0.660714 * price - 7.98143			// linear fit {{12.07, 0}, {30, 11.84}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 13.71,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => 0.631062 * price - 8.65186			// linear fit {{13.71, 0}, {30, 10.28}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 13.75,
				priceMax	: 19.49,
				getRoyalty	: (price: number) => 0.655385 * price - 9.01154			// linear fit {{13.75, 0}, {30, 10.65}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 13.75,
				priceMax	: 19.49,
				getRoyalty	: (price: number) => 0.643692 * price - 8.85077 		// linear fit {{13.75, 0}, {30, 10.46}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 13.31,
				priceMax	: 19.49,
				getRoyalty	: (price: number) => 0.6429 * price - 8.557 			// linear fit {{13.31, 0}, {30, 10.73}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 1615.3,
				priceMax	: 6500,
				getRoyalty	: (price: number) => Math.round(0.740271 * price - 1195.76) // linear fit {{1615.3, 0}, {6500, 3616}} // Note: Yen is rounding off the number
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: true,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women, EnumMbaFitType.Youth]
		},
		colors			: {
			all 		: [
				EnumMbaColor.Asphalt, EnumMbaColor.BabyBlue, EnumMbaColor.Black, EnumMbaColor.Brown, EnumMbaColor.Cranberry,
				EnumMbaColor.DarkHeather, EnumMbaColor.Grass, EnumMbaColor.HeatherBlue, EnumMbaColor.HeatherGrey, EnumMbaColor.KellyGreen,
				EnumMbaColor.Lemon, EnumMbaColor.Navy, EnumMbaColor.Olive, EnumMbaColor.Orange, EnumMbaColor.Pink, EnumMbaColor.Purple,
				EnumMbaColor.Red, EnumMbaColor.Royal, EnumMbaColor.Silver, EnumMbaColor.Slate, EnumMbaColor.White, EnumMbaColor.DarkGreen,
				EnumMbaColor.Burgundy, EnumMbaColor.GoldenYellow, EnumMbaColor.PurpleHeather, EnumMbaColor.RedHeather, EnumMbaColor.OliveHeather,
				EnumMbaColor.PinkHeather, EnumMbaColor.Sapphire
			],
			light 		:[
				EnumMbaColor.BabyBlue, EnumMbaColor.Grass, EnumMbaColor.HeatherGrey, EnumMbaColor.Lemon, EnumMbaColor.Orange,
				EnumMbaColor.Pink, EnumMbaColor.Red, EnumMbaColor.Silver, EnumMbaColor.Slate, EnumMbaColor.White, EnumMbaColor.GoldenYellow,
				EnumMbaColor.RedHeather, EnumMbaColor.OliveHeather, EnumMbaColor.PinkHeather, EnumMbaColor.Sapphire
			],
			dark 		: [
				EnumMbaColor.Asphalt, EnumMbaColor.Black, EnumMbaColor.Brown, EnumMbaColor.Cranberry, EnumMbaColor.DarkHeather,
				EnumMbaColor.HeatherBlue, EnumMbaColor.KellyGreen, EnumMbaColor.Navy, EnumMbaColor.Olive,
				EnumMbaColor.Royal, EnumMbaColor.Purple, EnumMbaColor.DarkGreen, EnumMbaColor.Burgundy, EnumMbaColor.PurpleHeather
			],
			background	: false
		}
	},

	// Premium t-shirt ---------------------
	{
		code			: EnumMbaProduct.PremiumShirt,
		name			: 'Premium T-Shirt',
		nameShort		: 'Premium T-Shirt',
		chartColor		: EnumColorName.Gray,
		oldCode			: 'Premium t-shirt',
		productorCode	: 'PREMIUM_TSHIRT',
		keywords		: [
			'premium_shirt', 'premium t-shirt', 'premium shirt', 'premium_tshirt',
			'premium_shirts', 'premium t-shirts', 'premium shirts', 'premium_tshirts'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 15.05,
				priceMax	: 45,
				getRoyalty	: (price: number) => (0.755593 * price) - 11.3717 			// linear fit {{15.05, 0}, {45, 22.63}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: true,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women, EnumMbaFitType.Youth]
		},
		colors			: {
			all 		: [
				EnumMbaColor.Asphalt, EnumMbaColor.BabyBlue, EnumMbaColor.Black, EnumMbaColor.Brown, EnumMbaColor.Cranberry,
				EnumMbaColor.DarkHeather, EnumMbaColor.Grass, EnumMbaColor.HeatherBlue, EnumMbaColor.HeatherGrey, EnumMbaColor.KellyGreen,
				EnumMbaColor.Lemon, EnumMbaColor.Navy, EnumMbaColor.Olive, EnumMbaColor.Orange, EnumMbaColor.Pink, EnumMbaColor.Purple,
				EnumMbaColor.Red, EnumMbaColor.Royal, EnumMbaColor.Silver, EnumMbaColor.Slate, EnumMbaColor.White
			],
			light 		:[
				EnumMbaColor.BabyBlue, EnumMbaColor.Grass, EnumMbaColor.HeatherGrey, EnumMbaColor.Lemon, EnumMbaColor.Orange,
				EnumMbaColor.Pink, EnumMbaColor.Red, EnumMbaColor.Silver, EnumMbaColor.Slate, EnumMbaColor.White
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.Brown, EnumMbaColor.Cranberry, EnumMbaColor.DarkHeather,
				EnumMbaColor.HeatherBlue, EnumMbaColor.KellyGreen, EnumMbaColor.Navy, EnumMbaColor.Olive,
				EnumMbaColor.Royal, EnumMbaColor.Purple
			],
			background	: false
		}
	},

	// V-neck t-shirt ----------------------
	{
		code			: EnumMbaProduct.VNeckShirt,
		name			: 'V-Neck T-Shirt',
		nameShort		: 'V-Neck T-Shirt',
		chartColor		: EnumColorName.Lightgray,
		oldCode			: 'V-neck t-shirt',
		productorCode	: 'VNECK',
		keywords		: [
			'vneck_shirt', 'v-neck t-shirt', 'vneck', 'vneck shirt',
			'vneck_shirts', 'v-neck t-shirts', 'vnecks', 'vneck shirts'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 15.18,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.74773 * price) - 11.3505 		// linear fit {{15.18, 0}, {35, 14.82}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 12.91,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.650068 * price) - 8.39238 		// linear fit {{12.91, 0}, {35, 14.36}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 13.54,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.621622 * price) - 8.41676 		// linear fit {{13.54, 0}, {35, 13.34}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 14.51,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.645681 * price) - 9.36883 		// linear fit {{14.51, 0}, {35, 13.23}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 14.49,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.63335 * price) - 9.17724 		// linear fit {{14.49, 0}, {35, 12.99}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 14.05,
				priceMax	: 35,
				getRoyalty	: (price: number) =>  (0.630072 * price) - 8.85251 		// linear fit {{14.05, 0}, {35, 13.20}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: false,
			options		: [EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [
				EnumMbaColor.BabyBlue, EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.ForestGreen, EnumMbaColor.HeatherGrey,
				EnumMbaColor.Navy, EnumMbaColor.Pink, EnumMbaColor.Purple, EnumMbaColor.Red, EnumMbaColor.Sapphire
			],
			light 		:[
				EnumMbaColor.BabyBlue, EnumMbaColor.HeatherGrey, EnumMbaColor.Pink, EnumMbaColor.Red, EnumMbaColor.Sapphire
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.ForestGreen, EnumMbaColor.Navy, EnumMbaColor.Red, EnumMbaColor.Sapphire
			],
			background	: false
		}
	},

	// Tank top ----------------------------
	{
		code			: EnumMbaProduct.TankTop,
		name			: 'Tank Top',
		nameShort		: 'Tank Top',
		chartColor		: EnumColorName.Royalblue,
		oldCode			: 'Tank top',
		productorCode	: 'TANK_TOP',
		keywords		: [
			'tank_top', 'tank_top', 'tank top', 'tanktop',
			'tank_tops', 'tank_tops', 'tank tops', 'tanktops'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 15.27,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.762798 * price) - 11.6479 		// linear fit {{15.27, 0}, {35, 15.05}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 13.38,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.631167 * price) - 8.44502 		// linear fit {{13.38, 0}, {30, 10.49}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 14.23,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.604312 * price) - 8.59936 		// linear fit {{14.23, 0}, {30, 9.53}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 13.83,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.674088 * price) - 9.32263 		// linear fit {{13.83, 0}, {30, 10.90}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 13.83,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.661719 * price) - 9.15158 		// linear fit {{13.83, 0}, {30, 10.70}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 13.73,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.645974 * price) - 8.86923 		// linear fit {{13.73, 0}, {30, 10.51}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: true,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.HeatherGrey, EnumMbaColor.Navy, EnumMbaColor.NeonPink,
				EnumMbaColor.Purple, EnumMbaColor.Red, EnumMbaColor.Royal, EnumMbaColor.Sapphire, EnumMbaColor.White
			],
			light 		:[
				EnumMbaColor.HeatherGrey, EnumMbaColor.NeonPink, EnumMbaColor.Red, EnumMbaColor.Sapphire, EnumMbaColor.White
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.Navy, EnumMbaColor.Red, EnumMbaColor.Royal,
				EnumMbaColor.Purple, EnumMbaColor.Sapphire
			],
			background	: false
		}
	},

	// Long sleeve t-shirt -----------------
	{
		code			: EnumMbaProduct.LongSleeveShirt,
		name			: 'Long Sleeve T-Shirt',
		nameShort		: 'Long Sleeve',
		chartColor		: EnumColorName.Blue,
		oldCode			: 'Long sleeve t-shirt',
		productorCode	: 'STANDARD_LONG_SLEEVE',
		keywords		: [
			'long_sleeve_shirt', 'long sleeve t-shirt', 'sleeve t-shirt', 'long sleeve shirt',
			'long_sleeve_shirts', 'long sleeve t-shirts', 'sleeve t-shirts', 'long sleeve shirts',
			'long sleeve', 'standard_long_sleeve', 'sleeve shirt'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 17.68,
				priceMax	: 40,
				getRoyalty	: (price: number) => (0.717294 * price) - 12.6818 				// linear fit {{17.68, 0}, {40, 16.01}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 16.51,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.632774 * price) - 10.4471 				// linear fit {{16.51, 0}, {35, 11.70}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 17.78,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.601045 * price) - 10.6866 				// linear fit {{17.78, 0}, {35, 10.35}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 16.19,
				priceMax	: 40,
				getRoyalty	: (price: number) => (0.656566 * price) - 10.6298 				// linear fit {{16.19, 0}, {35, 12.35}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 16.20,
				priceMax	: 40,
				getRoyalty	: (price: number) => (0.648319 * price) - 10.5028 				// linear fit {{16.20, 0}, {40, 15.43}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 16.02,
				priceMax	: 40,
				getRoyalty	: (price: number) => (0.62427 * price) - 10.0008 				// linear fit {{16.02, 0}, {40, 14.97}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 2148.6,
				priceMax	: 6500,
				getRoyalty	: (price: number) => Math.round((0.623478 * price) - 1339.6) 	// linear fit {{2148.6, 0}, {6500, 2713}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: false,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.HeatherGrey, EnumMbaColor.Navy, EnumMbaColor.Royal],
			light 		: [
				EnumMbaColor.HeatherGrey
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.Navy, EnumMbaColor.Royal
			],
			background	: false
		}
	},

	// Raglan ------------------------------
	{
		code			: EnumMbaProduct.Raglan,
		name			: 'Raglan',
		nameShort		: 'Raglan',
		chartColor		: EnumColorName.Darkblue,
		oldCode			: 'Raglan',
		productorCode	: 'RAGLAN',
		keywords		: ['raglan', 'raglans', 'raglan shirt', 'raglan_shirt', 'raglan-shirt'],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 18.77,
				priceMax	: 45,
				getRoyalty	: (price: number) => (0.733892 * price) - 13.7752 				// linear fit {{18.77, 0}, {45, 19.25}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 14.61,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.666017 * price) - 9.73051 				// linear fit {{14.61, 0}, {30, 10.25}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 15.95,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.623097 * price) - 9.9384 				// linear fit {{15.95, 0}, {35, 11.87}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 16.86,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.635061 * price) - 10.7071 				// linear fit {{16.86, 0}, {35, 11.52}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 16.86,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.625138 * price) - 10.5398 				// linear fit {{16.86, 0}, {35, 11.34}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 16.72,
				priceMax	: 35,
				getRoyalty	: (price: number) => (0.609956 * price) - 10.1985 				// linear fit {{16.72, 0}, {35, 11.15}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: true,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [
				EnumMbaColor.BlackAthleticHeather, EnumMbaColor.BlackWhite, EnumMbaColor.DarkHeatherWhite, EnumMbaColor.NavyAthleticHeather,
				EnumMbaColor.NavyWhite, EnumMbaColor.RedWhite, EnumMbaColor.RoyalBlueWhite
			],
			light 		: [
				EnumMbaColor.BlackAthleticHeather, EnumMbaColor.BlackWhite, EnumMbaColor.DarkHeatherWhite, EnumMbaColor.NavyAthleticHeather,
				EnumMbaColor.NavyWhite, EnumMbaColor.RedWhite, EnumMbaColor.RoyalBlueWhite
			],
			dark 		: [EnumMbaColor.BlackAthleticHeather, EnumMbaColor.NavyAthleticHeather],
			background	: false
		}
	},

	// Sweatshirt --------------------------
	{
		code			: EnumMbaProduct.Sweatshirt,
		name			: 'Sweatshirt',
		nameShort		: 'Sweatshirt',
		chartColor		: EnumColorName.Skyblue,
		oldCode			: 'Sweatshirt',
		productorCode	: 'STANDARD_SWEATSHIRT',
		keywords		: [
			'sweatshirt', 'standard_sweatshirt', 'standard sweatshirt',
			'sweatshirts', 'standard_sweatshirts', 'standard sweatshirts'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Default,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 25.15,
				priceMax	: 33.99,
				getRoyalty	: (price: number) => (0.747202 * price) - 18.7921 				// linear fit {{25.15, 0}, {60, 26.04}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 23.99,
				priceMax	: 45,
				getRoyalty	: (price: number) => (0.6297 * price) - 15.1065 				// linear fit {{23.99, 0}, {45, 13.23}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 26.31,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.602786 * price) - 15.8593 				// linear fit {{26.31, 0}, {50, 14.28}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 22.98,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.646188 * price) - 14.8494 				// linear fit {{22.98, 0}, {50, 17.46}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 23.35,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.63227 * price) - 14.7635 				// linear fit {{23.35, 0}, {50, 16.85}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 23.29,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.63085 * price) - 14.6925 				// linear fit {{23.29, 0}, {50, 16.85}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 2975.6,
				priceMax	: 8000,
				getRoyalty	: (price: number) => Math.round((0.548523 * price) - 1632.19) 	// linear fit {{2975.6, 0}, {8000, 2756}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 5400
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: false,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.HeatherGrey, EnumMbaColor.Navy, EnumMbaColor.Royal],
			light 		: [
				EnumMbaColor.HeatherGrey
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.Navy, EnumMbaColor.Royal
			],
			background	: false
		}
	},

	// Pullover hoodie ---------------------
	{
		code			: EnumMbaProduct.PulloverHoodie,
		name			: 'Pullover Hoodie',
		nameShort		: 'Pullover Hoodie',
		chartColor		: EnumColorName.Orange,
		oldCode			: 'Pullover hoodie',
		productorCode	: 'STANDARD_PULLOVER_HOODIE',
		keywords		: [
			'pullover_hoodie', 'pullover hoodie', 'standard_pullover_hoodie', 'hoodie',
			'pullover_hoodies', 'pullover hoodies', 'standard_pullover_hoodies', 'hoodies'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 25.04,
				priceMax	: 35.99,
				getRoyalty	: (price: number) => (0.72111 * price) - 18.0566 		// linear fit {{25.04, 0}, {60, 25.21}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 25.62,
				priceMax	: 33.99,
				getRoyalty	: (price: number) => (0.639835 * price) - 16.3926 		// linear fit {{25.62, 0}, {45.00, 12.40}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 28.65,
				priceMax	: 38.99,
				getRoyalty	: (price: number) => (0.604215 * price) - 17.3108 		// linear fit {{28.65, 0}, {50, 12.90}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 25.52,
				priceMax	: 33.99,
				getRoyalty	: (price: number) => (0.659722 * price) - 16.8361 		// linear fit {{25.52, 0}, {50, 16.15}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 25.92,
				priceMax	: 33.99,
				getRoyalty	: (price: number) => (0.648671 * price) - 16.8136 		// linear fit {{25.92, 0}, {50, 15.62}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 25.42,
				priceMax	: 33.99,
				getRoyalty	: (price: number) => (0.643613 * price) - 16.3606 		// linear fit {{25.42, 0}, {50, 15.82}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 3347,
				priceMax	: 8000,
				getRoyalty	: (price: number) => Math.round((0.56974 * price) - 1906.92) // linear fit {{3347, 0}, {8000, 2651}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 4050
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: false,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.HeatherGrey, EnumMbaColor.Navy, EnumMbaColor.Royal,
				EnumMbaColor.Asphalt, EnumMbaColor.Burgundy, EnumMbaColor.SageGreen, EnumMbaColor.KellyGreen, EnumMbaColor.Purple,
				EnumMbaColor.BrightPink, EnumMbaColor.DustyBlue, EnumMbaColor.DarkGreen, EnumMbaColor.Red, EnumMbaColor.Lemon
			],
			light 		: [
				EnumMbaColor.HeatherGrey, EnumMbaColor.BrightPink, EnumMbaColor.DustyBlue, EnumMbaColor.Lemon
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.Navy, EnumMbaColor.Royal, EnumMbaColor.Asphalt, EnumMbaColor.Burgundy,
				EnumMbaColor.KellyGreen, EnumMbaColor.Purple, EnumMbaColor.DarkGreen, EnumMbaColor.Red
			],
			background	: false
		}
	},

	// Zip hoodie --------------------------
	{
		code			: EnumMbaProduct.ZipHoodie,
		name			: 'Zip Hoodie',
		nameShort		: 'Zip Hoodie',
		chartColor		: EnumColorName.Orangered,
		oldCode			: 'Zip hoodie',
		productorCode	: 'ZIP_HOODIE',
		keywords		: [
			'zip_hoodie', 'zip hoodie', 'zip', 'hoodie zip', 'hoodie_zip',
			'zip_hoodies', 'zip hoodies', 'zip', 'hoodies zip', 'hoodies_zip'
		],
		productType		: EnumMbaProductType.Clothing,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 26.99,
				priceMax	: 37.99,
				getRoyalty	: (price: number) => (0.710455 * price) - 19.0473 		// linear fit {{26.81, 0}, {60, 23.58}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 23.06,
				priceMax	: 45,
				getRoyalty	: (price: number) => (0.611668 * price) - 14.1051 		// linear fit {{23.06, 0}, {45, 13.42}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 25.86,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.583264 * price) - 15.0832 		// linear fit {{25.86, 0}, {50, 14.08}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 25.28,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.6339 * price) - 16.025 			// linear fit {{25.28, 0}, {50, 15.67}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 25.63,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.618383 * price) - 15.8492 		// linear fit {{25.63, 0}, {50, 15.07}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 25.14,
				priceMax	: 50,
				getRoyalty	: (price: number) => (0.615447 * price) - 15.4723 		// linear fit {{25.14, 0}, {50, 15.30}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 3757,
				priceMax	: 8000,
				getRoyalty	: (price: number) => Math.round((0.743578 * price) - 2793.62)	// linear fit {{3757, 0}, {8000, 3155}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 4500,
				height	: 4050
			},
			{
				area	: EnumMbaPrint.Back,
				width 	: 4500,
				height	: 5400
			}
		],
		fitTypes		: {
			editable	: false,
			options		: [EnumMbaFitType.Men, EnumMbaFitType.Women]
		},
		colors			: {
			all 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.ForestGreen, EnumMbaColor.HeatherGrey,
				EnumMbaColor.Navy, EnumMbaColor.Purple, EnumMbaColor.Red, EnumMbaColor.Royal
			],
			light 		: [
				EnumMbaColor.HeatherGrey, EnumMbaColor.Purple
			],
			dark 		: [
				EnumMbaColor.Black, EnumMbaColor.DarkHeather, EnumMbaColor.ForestGreen, EnumMbaColor.Navy, EnumMbaColor.Red, EnumMbaColor.Royal
			],
			background	: false
		}
	},

	// PopSockets Grip ---------------------
	{
		code			: EnumMbaProduct.PopSockets,
		name			: 'PopSockets Grip',
		nameShort		: 'PopSockets Grip',
		chartColor		: EnumColorName.Purple,
		oldCode			: 'PopSockets grip',
		productorCode	: 'POP_SOCKET',
		keywords		: [
			'popsockets', 'popsockets grip', 'pop_socket', 'popsocket', 'pop-socket', 'socket', 'phone socket', 'phone_socket',
			'pop sockets grip', 'sockets grip', 'pop-sockets grip', 'pop_sockets_grip'
		],
		productType		: EnumMbaProductType.Accessory,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 11.72,
				priceMax	: 20,
				getRoyalty	: (price: number) => (0.140232 * price) - 0.00304037 	// linear fit {{11.72, 1.64}, {15.85, 2.22}, {18.55, 2.60}, {20, 2.80}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 9.68,
				priceMax	: 20,
				getRoyalty	: (price: number) => (0.116412 * price) - 0.00294493 	// linear fit {{9.68, 1.13}, {15.88, 1.85}, {18.25, 2.13}, {20, 2.33}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 10.34,
				priceMax	: 20,
				getRoyalty	: (price: number) => (0.11792 * price) - 0.00502856 	// linear fit {{11.25, 1.32}, {15.63, 1.84}, {18.25, 2.15}, {20, 2.35}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 12.29,
				priceMax	: 20,
				getRoyalty	: (price: number) => (0.116917 * price) - 0.00504976 	// linear fit {{12.29, 1.43}, {16.86, 1.97}, {18.25, 2.13}, {20, 2.33}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 12.28,
				priceMax	: 20,
				getRoyalty	: (price: number) => (0.114019 * price) - 0.0103138 	// linear fit {{12.28, 1.41}, {13.25, 1.52}, {15.85, 1.82}, {17.36, 1.99}, {19.21, 2.20}, {20, 2.29}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 11.86,
				priceMax	: 20,
				getRoyalty	: (price: number) => 0.115686 * price - 0.00214442 		// linear fit {{11.86, 1.37}, {13.25, 1.53}, {15.85, 1.83}, {17.36, 2.01}, {19.21, 2.22}, {20, 2.31}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 485,
				height	: 485
			}
		],
		colors			: {
			all 		: [],
			light 		: [],
			dark 		: [],
			background	: true
		}
	},

	// iPhone cases ------------------------
	{
		code			: EnumMbaProduct.IPhoneCases,
		name			: 'iPhone Phone Cases',
		nameShort		: 'iPhone Cases',
		chartColor		: EnumColorName.Lightcyan,
		oldCode			: 'iPhone cases',
		productorCode	: 'PHONE_CASE_APPLE_IPHONE',
		keywords		: [
			'iphone_cases', 'apple iphone phone cases', 'apple_iphone_phone_cases', 'apple iphone cases', 'apple_iphone_cases',
			'apple phone case', 'apple case', 'apple phone', 'apple_phone_case', 'phone_case_apple_iphone'
		],
		productType		: EnumMbaProductType.Accessory,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 13.84,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.696163 * price) - 9.6349 	// linear fit {{13.84, 0}, {30, 11.25}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 13.99,
				priceMax	: 17.99,
				getRoyalty	: (price: number) => (0.125 * price) + 0.00125 		// linear fit {{13.99, 1.75}, {17.99, 2.25}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 14.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.126 * price) + 0.00126 		// linear fit {{14.99, 1.89}, {19.99, 2.52}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 15.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.125 * price) + 0.00125 		// linear fit {{15.99, 2.00}, {19.99, 2.50}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 15.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.1225 * price) + 0.011225 	// linear fit {{15.99, 1.97}, {19.99, 2.46}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 15.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.125 * price) - 0.01875 		// linear fit {{15.99, 1.98}, {19.99, 2.48}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 1618,
				priceMax	: 6500,
				getRoyalty	: (price: number) => Math.round((0.714052 * price) - 1155.34) // linear fit {{1618, 0}, {6500, 3486}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 1800,
				height	: 3200
			}
		],
		colors			: {
			all 		: [],
			light 		: [],
			dark 		: [],
			background	: true
		}
	},

	// Samsung cases -----------------------
	{
		code			: EnumMbaProduct.SamsungCases,
		name			: 'Samsung Phone Cases',
		nameShort		: 'Samsung Cases',
		chartColor		: EnumColorName.Cyan,
		oldCode			: 'Samsung cases',
		productorCode	: 'PHONE_CASE_SAMSUNG_GALAXY',
		keywords		: [
			'samsung_cases', 'samsung galaxy phone cases', 'samsung_galaxy_phone_cases', 'samsung galaxy cases',
			'samsung_galaxy_cases', 'phone_case_samsung_galaxy', 'galaxy cases', 'samsung cases'
		],
		productType		: EnumMbaProductType.Accessory,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 13.84,
				priceMax	: 30,
				getRoyalty	: (price: number) => (0.696163 * price) - 9.6349 	// linear fit {{13.84, 0}, {30, 11.25}}
			},
			{
				market		: EnumMbaMarketplace.CO_UK,
				priceMin	: 13.99,
				priceMax	: 17.99,
				getRoyalty	: (price: number) => (0.125 * price) + 0.00125 		// linear fit {{13.99, 1.75}, {17.99, 2.25}}
			},
			{
				market		: EnumMbaMarketplace.DE,
				priceMin	: 14.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.126 * price) + 0.00126 		// linear fit {{14.99, 1.89}, {19.99, 2.52}}
			},
			{
				market		: EnumMbaMarketplace.FR,
				priceMin	: 15.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.125 * price) + 0.00125 		// linear fit {{15.99, 2.00}, {19.99, 2.50}}
			},
			{
				market		: EnumMbaMarketplace.IT,
				priceMin	: 15.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.1225 * price) + 0.011225 	// linear fit {{15.99, 1.97}, {19.99, 2.46}}
			},
			{
				market		: EnumMbaMarketplace.ES,
				priceMin	: 15.99,
				priceMax	: 19.99,
				getRoyalty	: (price: number) => (0.125 * price) - 0.01875 		// linear fit {{15.99, 1.98}, {19.99, 2.48}}
			},
			{
				market		: EnumMbaMarketplace.JP,
				priceMin	: 1618,
				priceMax	: 6500,
				getRoyalty	: (price: number) => Math.round((0.714052 * price) - 1155.34) // linear fit {{1618, 0}, {6500, 3486}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 1800,
				height	: 3200
			}
		],
		colors			: {
			all 		: [],
			light 		: [],
			dark 		: [],
			background	: true
		}
	},

	// Tote bag ----------------------------
	{
		code			: EnumMbaProduct.ToteBag,
		name			: 'Tote Bag',
		nameShort		: 'Tote Bag',
		chartColor		: EnumColorName.Darkgreen,
		oldCode			: 'Tote bag',
		productorCode	: 'TOTE_BAG',
		keywords		: [
			'tote_bag', 'tote bag', 'tote bags', 'bag', 'tote',
			'tote_bags', 'tote bags', 'tot bags', 'bags', 'totes'
		],
		productType		: EnumMbaProductType.Accessory,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 15.54,
				priceMax	: 25,
				getRoyalty	: (price: number) => (0.82981 * price) - 12.8952 	// linear fit {{15.54, 0}, {25, 7.85}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 2925,
				height	: 2925
			}
		],
		colors			: {
			all 		: [],
			light 		: [],
			dark 		: [],
			background	: true
		}
	},

	// Throw pillows -----------------------
	{
		code			: EnumMbaProduct.ThrowPillows,
		name			: 'Throw Pillows',
		nameShort		: 'Throw Pillows',
		chartColor		: EnumColorName.Darkred,
		oldCode			: 'Throw pillows',
		productorCode	: 'THROW_PILLOW',
		keywords		: [
			'throw_pillows', 'throw pillows', 'throw_pillow', 'throw pillow', 'throw pillows'
		],
		productType		: EnumMbaProductType.Accessory,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 17.39,
				priceMax	: 45,
				getRoyalty	: (price: number) => (0.80623 * price) - 14.0203 	// linear fit {{17.39, 0}, {45, 22.26}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 2925,
				height	: 2925
			}
		],
		colors			: {
			all 		: [],
			light 		: [],
			dark 		: [],
			background	: true
		}
	},

	// Tumbler -----------------------------
	{
		code			: EnumMbaProduct.Tumbler,
		name			: 'Tumbler',
		nameShort		: 'Tumbler',
		chartColor		: EnumColorName.Darkred,
		oldCode			: 'Tumbler',
		productorCode	: 'TUMBLER',
		keywords		: [
			'tumbler', 'tumbler', 'tumblers', 'tumbler'
		],
		productType		: EnumMbaProductType.Accessory,
		scalingType		: EnumMbaScalingType.Special,
		marketplaces 	: [
			{
				market		: EnumMbaMarketplace.COM,
				priceMin	: 20.99,
				priceMax	: 22.99,
				getRoyalty	: (price: number) => (0.14 * price) + 0.0014 	// linear fit {{20.99, 2.94}, {21.99, 3.08}, {22.99, 3.22}}
			}
		],
		printAreas		: [
			{
				area	: EnumMbaPrint.Front,
				width 	: 1800,
				height	: 2400
			}
		],
		colors			: {
			all 		: [EnumMbaColor.Black, EnumMbaColor.BrushedSteel, EnumMbaColor.White],
			light 		: [EnumMbaColor.White, EnumMbaColor.BrushedSteel],
			dark 		: [EnumMbaColor.Black],
			background	: false
		}
	}
];


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IAmazonMbaProduct {
	code			: EnumMbaProduct;
	name			: string;
	nameShort		: string;
	chartColor		: EnumColorName;
	oldCode			: string;					// used for the import from Flying Upload v1.0
	productorCode	: string;
	keywords		: string[];					// used for the excel import, to map the user input to the product
	productType		: EnumMbaProductType;
	scalingType		: EnumMbaScalingType;
	marketplaces 	: IAmazonMbaMarketplace[];
	printAreas		: IAmazonMbaPrint[];
	fitTypes	   ?: IAmazonMbaFitTypes;		// not needed for none clothing products
	colors			: IColors;
}

export interface IAmazonMbaMarketplace {
	market		: EnumMbaMarketplace;
	priceMin	: number;
	priceMax	: number;
	getRoyalty	: (number: number) => number;	// calculation of the royalty
}

export interface IAmazonMbaPrint {
	area		: EnumMbaPrint;					// example: front
	width		: number;						// example: 5400 pixel
	height		: number;						// example: 4500 pixel
}

export interface IAmazonMbaFitTypes {
	editable	: boolean;
	options		: EnumMbaFitType[];
}

export interface IColors {
	all 		: EnumMbaColor[];
	light 		: EnumMbaColor[];
	dark 		: EnumMbaColor[];
	background	: boolean;
}
