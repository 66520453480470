/**------------------------------------------------------
 * Get Inspired POD constants
 */
export enum EnumInspiredPodPlatform {
	All				= 'all',
	Redbubble_COM	= 'redbubble.com',
	Spreadshirt_COM	= 'spreadshirt.com',
	Spreadshirt_DE	= 'spreadshirt.de',
	Spreadshirt_FR	= 'spreadshirt.fr',
	Spreadshirt_ES	= 'spreadshirt.es',
	Spreadshirt_IT	= 'spreadshirt.it'
}

export enum EnumInspiredPodSorting {
	Newest			= 'newest',
	Relevance		= 'relevance',
	Random			= 'random'
}
