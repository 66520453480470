import { FLYING_APP_CONSTANTS } from './flying-app-constants.data';


/**------------------------------------------------------
 * FAQ Data
 */
export const FLYING_FAQ_QUESTIONS: IFlyingFaqQuestion[] = [
	{
		question 	: `1. Why should I choose Flying at all?`,
		answer		: `The original since 2020. A all-in-one solution that concentrates on several aspects of the Print on Demand area. Not just one part. From Research, Trademarks till Design Management, storing your listing and automated upload. When you click around flyingresearch.net you will already see that the look and feel is on a whole other level than everything out there. And also in the backend we have the most data, live data and we are also super fast. Ten thousands of registered users who trust us. And you are very welcome to be the next one!`
	},
	{
		question 	: `2. Can I test you?`,
		answer		: `Yes! Create an account on flyingresearch.net, and you have instant access to parts of the research, trademark search, a 2GB free Design Management, edit of your designs and also limited uploads for our upload automation. All in one account, all accessible everywhere, every time. Sing up and get started right away for free.`
	},
	{
		question 	: `3. What is "Flying Research"?`,
		answer		: `Flying Research is the Merch By Amazon research for Print On Demand. See the bestsellers, seller trends, get an awesome niche analysis, see upcoming events, get keywords and statistics for basically everything, and much more. The research is however not limited to Merch By Amazon, the platforms Spreadshirt and Redbubble are also integrated. Furthermore, we are offering all trademark functionalities to protect you from trademark infringements.`
	},
	{
		question 	: `4. Why is Flying Research better than other research applications?`,
		answer		: `Let's be honest. Every research tool after us tried to copy Flying Research in some kind. It's annoying, but also a mark of quality. Flying Research has qualities like no one out there. An integrated upload area for design management, editing your listings or a personal trademark black- and whitelist. In the research area we have a live data research without any limitations. We only use REAL data from Amazon because we are able to do so, many others just simulating it and give wrong data.`
	},
	{
		question 	: `5. What is "Flying Upload"?`,
		answer		: `Flying Upload is the automation for Print On Demand and Kindle Direct Publishing. Create your listings and let us upload the designs to all platforms fully automated. You will be suppressed how simple and fast it is. No more manual uploads, everything is automated.`
	},
	{
		question 	: `6. Why is Flying Upload better than other automation tools?`,
		answer		: `Flying Upload is ahead of all other upload automation software because it offers the most platforms and the most stable uploads. The support team is regularly checking all upload of all platforms and can fix changes of the platforms in less than one day. Furthermore, we have the most knowledge of all platforms because we are in the Print On Demand business as well and are uploading to all platforms on a daily basis. The listings you create are stored in local files or a database, which lets you reuse the data at any time in the future, giving you the option to upload it to further platforms at any time. `
	},
	{
		question 	: `7. What workflow do you cover?`,
		answer		: `We are the only application which offers the full workflow from research till uploading your designs to all the platforms. Starting with the research you can research new ideas by the bestsellers, niche trends and much more. The after creating your designs, you can import them into the design management and create the listings in the edit page. For your account safety, we have an integrated trademark check for listings, and you can use the trademark search to for checking all offices. Once the listing is finished, you can automatically upload all your designs to the different Print On Demand platforms with the upload automation. Whenever you forgot where the designs were uploaded or what designs you already created, you can go back to the design management. The full workflow of Print On Demand, simple and easy. Research --> Design Management --> Listing Edit --> TM Check --> Upload --> Finding data and old designs again.`
	},
	{
		question 	: `8. What can I do when I have problems?`,
		answer		: `Contact our support. People choose us not just because of the technical side, also our support is outstanding. Contact us via mail ${FLYING_APP_CONSTANTS.SOCIAL_LINKS.SUPPORT.MAIL} or simply join our Discord server. You will have your answer within 24 hours - always. Most times much faster.`
	},
	{
		question 	: `9. Can I import my listings with a spreadsheet?`,
		answer		: `Yes, you certainly can. Import Excel files with just simply drag and dropping the Excel sheet into the upload application. The images will automatically import and the XML files created. Then you can import these images and XML files directly into the design management. A direct Excel import into the design management will come in the beginning of 2022, till then you can import the Excel sheet with the listings into the uploader and the imported data into the design management.`
	},
	{
		question 	: `10. Can I transfer my already uploaded listings from MBA to Flying Upload?`,
		answer		: `Yes, you certainly can. We will integrate a full Merch By Amazon data import with just a single click in the future. For now, however, you can use the Chrome extension of "Productor for Merch by Amazon". It offers an Excel export for all your listings and designs, this exported data can then be imported by simply drag and dropping the files into the upload application. Simple and easy, import all your Merch By Amazon designs to flying upload. Check out the "Productor & Excel Import" tutorial in the tutorial section of "POD Upload" for more details.`
	},
	{
		question 	: `11. Is my data safe?`,
		answer		: `Yes, your data is certainly safe. If you work with the older xml standard, the data is encrypted and locally stored at your pc, as well as the login details of the Print On Demand accounts. If you work with the design management only, you have access to your designs and listings. The exported data is furthermore encrypted. We apply the highest data protection standards of Germany for all our applications.`
	},
	{
		question 	: `12. Do you offer anything else?`,
		answer		: `Yes, we do. We started with the whole development of flying upload in March 2019, and we are on the market exactly two years. Since then, we try to build a solution for your full POD / KDP business. This includes obviously the research, the trademarks, the workflow and of course also the upload. For this reason, we have developed flying research in 2020, and created a design management for all your designs in 2021. No one out there is bringing so many new functions and updates on a regular basis. Fast-growing and trusted by a Discord community channel with 2.000+ people. We are working towards a full Print On Demand solution for your business and will continue to create new functionalities and revolutionize your workflow. Now 2022 is coming, and we are already working on something new.`
	},
	{
		question 	: `13. Can I view the user interface in my native languages?`,
		answer		: `By default, flying research only supports the English language. However, there is certainly a way to view all pages in other languages like German, French, Spanish, and others. This can be achieved with an extension of "Google Translate". After installing the translate extension you can click on its icon and use the provided option "Translate This Page". After selecting the language it will translate all content of the website to your chosen language. Please find a video tutorial of how to add the extension <a target="_blank" href="${FLYING_APP_CONSTANTS.GOOGLE_TRANSLATE_EXTENSION.TUTORIAL_VIDEO}">here</a>, and a written documentation <a target="_blank" href="${FLYING_APP_CONSTANTS.GOOGLE_TRANSLATE_EXTENSION.TUTORIAL_HELP}">here</a>. If you want to get directly to the "Google Translate" extension use the following links: for <a target="_blank" href="${FLYING_APP_CONSTANTS.GOOGLE_TRANSLATE_EXTENSION.WEB_STORE_CHROME}">Google Chrome</a>, for <a target="_blank" href="${FLYING_APP_CONSTANTS.GOOGLE_TRANSLATE_EXTENSION.WEB_STORE_FIREFOX}">Firefox</a>.`
	},
	{
		question 	: `14. Do you offer Lifetime deals?`,
		answer		: `No. Software is a constant process of development. Changes in the market, regardless of whether Research, POD or KDP, have to allow us to react variably. We want to offer users and also ourselves the greatest security and the best perspective. Lifetime deals are usually done when you want to fund something or when you don't plan to update the software much. We do neither of these things, that's why we don't and will never offer lifetime packages for our software.`
	},
	{
		question 	: `15. Ok, but my question was not answered above!`,
		answer		: `If you still want to know something else, please write us a mail <a href="${FLYING_APP_CONSTANTS.SOCIAL_LINKS.SUPPORT.MAILTO}">${FLYING_APP_CONSTANTS.SOCIAL_LINKS.SUPPORT.MAIL}</a> or join our <a target="_blank" href="${FLYING_APP_CONSTANTS.SOCIAL_LINKS.DISCORD}">Discord</a>. The team will help you!`
	}
];


//** Interfaces --------------------------------- */
interface IFlyingFaqQuestion {
	question: string;
	answer 	: string;
}
