import { Util } from '@libs/utilities/util';
import { EnumFileType, TypeReject, TypeResolve } from '@libs/constants';

import { ColorExtractionHelper } from './color-extraction/color-extraction.helper';
import { ColorExtractionCore } from './color-extraction/core/color-extraction.core';
import { ConvertImageHelper } from './convert-image/convert-image.helper';
import { ResizeImageHelper } from './resize-image/resize-image.helper';
import { WorkerAssistantService } from '../../worker-assistant';


export class ImageHandlerService {

	//** Configurations */
	readonly IMAGE_ACCEPTED_FILE_TYPES: EnumFileType[] = [EnumFileType.JPEG, EnumFileType.JPG, EnumFileType.PNG];

	//** Image Color Extraction */
	readonly colorExtraction: ColorExtractionHelper;

	//** Convert Image */
	readonly convert		: ConvertImageHelper 	= new ConvertImageHelper();

	//** Resize Image */
	readonly resize			: ResizeImageHelper 	= new ResizeImageHelper();


	constructor(
		workerAssistantService  : WorkerAssistantService,
		colorExtractionCore		: ColorExtractionCore
	) {
		this.colorExtraction = new ColorExtractionHelper(workerAssistantService, colorExtractionCore);
	}


	/**------------------------------------------------------
	 * Image action helpers
	 */
	//** Load image data asynchronously */
	loadImageElement(base64Image: string): Promise<HTMLImageElement> {
		return new Promise((resolve: TypeResolve<HTMLImageElement>, reject: TypeReject) => {

			//0 - create image element
			const image: HTMLImageElement = new Image();
			image.src = base64Image;

			//1 - load image
			image.onload = () => {
				resolve(image);
			};

			//2 - on image load fail
			image.onerror = (error: unknown) => {
				reject(error);
			};
		});
	}

	//** Download image (using base64 data) */
	downloadToLocal(fileName: string, fileData: string): void {

		//0 - create html anchor element
		const link: HTMLAnchorElement = document.createElement('a');

		//1 - set filename and associate image data to it
		link.download 	= fileName;
		link.href 		= fileData;

		//2 - click to download, then remove the element
		link.click();
		link.remove();
	}

	//** Copy image */
	copyImage(image: Blob, successCallback: Function, errorCallback: Function): void {

		// use navigator clipboard API to copy image data
		const clipboardItemData	: Record<string, ClipboardItemData> = { 'image/png': new Promise((r: TypeResolve<Blob | string>) => { r(image); }) };
		const clipboardItem		: ClipboardItem = new ClipboardItem(clipboardItemData);
		navigator.clipboard
			.write([clipboardItem])
			.then(() 				=> successCallback())
			.catch((error: unknown) => errorCallback());
	}

	isImageFile(file: File): RegExpMatchArray | null {
		if (Util.Basic.isUndefined(file?.type)) throw new Error(`ImageHandlerService => isImageFile => FATAL ERROR: the provided file of "${file?.name}" does not have a mime type (type: ${file?.type})`);
		return file.type.match('image.*');
	}


	/**------------------------------------------------------
	 * Canvas Helpers
	 */

	//** Create canvas */
	createCanvas(width: number, height: number, image?: any): HTMLCanvasElement {

		//0 - create canvas
		const canvas: HTMLCanvasElement = document.createElement('canvas');

		//1 - update canvas to provided width and height
		canvas.width  = width;
		canvas.height = height;

		//2 - get the canvas 2d context
		const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
		ctx!.rect(0, 0, width, height);

		//3 - put image data if available
		if (image instanceof Image) 	ctx!.drawImage(image, 0, 0, width, height);
		if (image instanceof ImageData) ctx!.putImageData(image, 0, 0);

		//4 - return the created canvas
		return canvas;
	}

	//** Get blob from provided canvas */
	getBlobFromCanvas(canvas: HTMLCanvasElement): Promise<Blob | null> {
		return new Promise<Blob | null>((resolve: TypeResolve<Blob>) => {
			canvas.toBlob((blob: Blob | null) => { resolve(blob!); });
		});
	}
}
