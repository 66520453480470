import { ModuleWithProviders, NgModule } from '@angular/core';
import { Util } from '@libs/utilities/util';
import { SecureTimeToken } from '@domains/security/shared';

import { SecureSocket } from './socket/secure-socket';
import { SecureEncryptionService } from '../services/encryption.service';
import { SecureReCaptchaService } from '../services/re-captcha.service';
import { SecureTimeTokenService } from '../services/time-token.service';
import { EnumSecureSocketInjection, ISecureSocketConfig } from './secure-socket.config';
import { EnumSecureServiceInjection, ISecureServicesConfig } from '../services/security-services.config';


@NgModule()
export class SecureSocketModule {

	static register(config: ISecureSocketConfig): ModuleWithProviders<SecureSocketModule> {

		//0 - check the server url
		if (!Util.Http.isUrlValid(config.SOCKET_URL)) throw new Error(`SecureSocketModule => register => FATAL ERROR: the SOCKET_URL is not a valid url (value: ${config.SOCKET_URL})`);

		//1 - create the module
		return {
			ngModule	: SecureSocketModule,
			providers	: [
				SecureSocket,
				SecureEncryptionService,
				SecureReCaptchaService,
				SecureTimeTokenService,
				{
					provide		: EnumSecureSocketInjection.Config,
					useValue	: config as ISecureSocketConfig
				},
				{
					provide		: EnumSecureServiceInjection.Config,
					useValue	: config as ISecureServicesConfig
				},
				{
					provide		: SecureTimeToken,
					useClass	: SecureTimeToken
				}
			]
		};
	}
}
