import { EnumSpreadshirtProduct, EnumPodMarketplace } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';
import { SPREADSHIRT_SORTING_LIST } from '../spreadshirt-search.data';


/**------------------------------------------------------
 * Product Configurations
 */
const SPREADSHIRT_PRODUCT_LIST_IT: ISearchParameterConfig<EnumSpreadshirtProduct>[] = [
	{
		code		: EnumSpreadshirtProduct.All,
		name		: 'All Products',
		placeholders: { '[PRODUCT]': '' }
	},
	{
		code		: EnumSpreadshirtProduct.Shirt,
		name		: 'T-Shirt',
		placeholders: { '[PRODUCT]': 'abbigliamento/magliette' } // cspell:disable-line
	},
	{
		code		: EnumSpreadshirtProduct.VNeck,
		name		: 'V-Neck',
		placeholders: { '[PRODUCT]': 'abbigliamento/magliette' }, // cspell:disable-line
		parameters	: { 'neckline' : 'N1' }
	},
	{
		code		: EnumSpreadshirtProduct.Hoodie,
		name		: 'Hoodie',
		placeholders: { '[PRODUCT]': 'abbigliamento/felpe/felpe-con-cappuccio' } // cspell:disable-line
	},
	{
		code		: EnumSpreadshirtProduct.TankTop,
		name		: 'Tank Top',
		placeholders: { '[PRODUCT]': 'abbigliamento/canotte' } // cspell:disable-line
	},
	{
		code		: EnumSpreadshirtProduct.LongSleeve,
		name		: 'Long Sleeve',
		placeholders: { '[PRODUCT]': 'abbigliamento/maglie-a-maniche-lunghe' } // cspell:disable-line
	},
	{
		code		: EnumSpreadshirtProduct.Sweatshirt,
		name		: 'Sweatshirt',
		placeholders: { '[PRODUCT]': 'abbigliamento/felpe/felpe-senza-cappuccio' } // cspell:disable-line
	}
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const SPREADSHIRT_CONFIG_IT: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.IT,
	websiteUrl		: {
		home		: 'https://www.spreadshirt.it/',
		login		: 'https://www.spreadshirt.it/login',
		search		: 'https://www.spreadshirt.it/shop/[PRODUCT]/[SEARCH]/?page=[PAGE]'
	},
	pageLimit		: 167,
	sortings		: SPREADSHIRT_SORTING_LIST,
	products		: SPREADSHIRT_PRODUCT_LIST_IT,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};
