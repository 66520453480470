import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class LocationService {

	//** Configurations */
	private readonly IP_GEOLOCATION_API_URL: string = 'https://pro.ip-api.com/json/?fields=61439&key=qCDMttw19wa3C3x';
	private readonly COUNTRY_CODES_UK = ['UK', 'GB'];
	private readonly COUNTRY_CODES_EU = [
		'AL', 'AD', 'AM', 'AT', 'BY', 'BE', 'BA', 'BG', 'CH', 'CY', 'DE',
		'DK', 'EE', 'ES', 'FO', 'FI', 'FR', 'GE', 'GI', 'GR', 'HR', 'IE',
		'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MK', 'MT', 'NO',
		'NL', 'PT', 'RO', 'RU', 'SI', 'SK', 'SM', 'TR', 'UA', 'VA'
	];

	private location: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private currency: BehaviorSubject<any> = new BehaviorSubject<ICurrency>({ name: 'USD', symbol:'$' });
	private http	: HttpClient;

	constructor(backend: HttpBackend) {
		this.http = new HttpClient(backend);
	}

	setLocation(data: any): void {
		this.location.next(data);
	}

	getLocation(): any {
		return this.location;
	}

	setCurrency(countryCode: string): ICurrency {
		if (this.COUNTRY_CODES_EU.includes(countryCode)) return { name: 'EUR', symbol:'€' };
		if (this.COUNTRY_CODES_UK.includes(countryCode)) return { name: 'GBP', symbol:'£' };
		return { name: 'USD', symbol: '$' };
	}

	getCurrency(): ICurrency {
		return this.currency.getValue();
	}

	getLocationApi(): Observable<void> {
		return this.http.get(this.IP_GEOLOCATION_API_URL).pipe(map((data:any) => {
			this.setLocation(data);
			this.currency.next(this.setCurrency(data.countryCode));
		}));
	}
}


//** Interfaces --------------------------------- */
export interface ICurrency {
	name	: string;
	symbol	: string;
}
