import { animate, animation, AnimationReferenceMetadata, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

import { INgAnimationOptions } from '../shared/state-animation.interface';
import { useAnimationIncludingChildren } from '../shared/state-animation.helper';


/**------------------------------------------------------
 * Animation Definition
 */
function fadeIn(): AnimationReferenceMetadata {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({
					visibility 	: 'visible',
					opacity 	: 0,
					easing 		: 'ease',
					offset 		: 0
				}),
				style({
					opacity 	: 1,
					easing 		: 'ease',
					offset 		: 1
				})
			])
		)
	]);
}


/**------------------------------------------------------
 * Configuration
 */
const NG_DEFAULT_DURATION: number = 1000;


/**------------------------------------------------------
 * FadeIn Animation On State
 */
export function fadeInAnimation(options?: INgAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeIn', [
		transition(
			'0 => 1',
			[
				style({ visibility: 'hidden' }),
				...useAnimationIncludingChildren(fadeIn(), options)
			],
			{
				params: {
					delay 	: options?.delay 	|| 0,
					duration: options?.duration || NG_DEFAULT_DURATION
				}
			}
		)
	]);
}


/**------------------------------------------------------
 * FadeIn Animation On Enter
 */
export function fadeInOnEnterAnimation(options?: INgAnimationOptions): AnimationTriggerMetadata {
	return trigger(options?.anchor || 'fadeInOnEnter', [
		transition(
			':enter',
			[
				style({ visibility: 'hidden' }),
				...useAnimationIncludingChildren(fadeIn(), options)
			],
			{
				params: {
					delay 	: options?.delay 	|| 0,
					duration: options?.duration || NG_DEFAULT_DURATION
				}
			}
		)
	]);
}
