import { EnumNotification, EnumReminderInterval } from './research-settings.constants';


/**------------------------------------------------------
 * Research Settings
 */
export interface IResearchSettings {
	notifications	: INotificationSettings;
}


/**------------------------------------------------------
 * Notifications
 */
export interface INotificationSettings {
	tmWatchlist 	: ITmWatchlistNotificationSettings;
	events   		: IEventNotificationSettings;
}

export interface ITmWatchlistNotificationSettings {
	type     		: EnumNotification;
	mail     		: string;
	active   		: boolean;
}

export interface IEventNotificationSettings {
	type     		: EnumNotification;
	mail     		: string;
	active   		: boolean;
	settings 		: {
		countries 	: string;
		remind    	: EnumReminderInterval;
	};
}
