import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { DISPLATE_CONFIG, DISPLATE_DEFAULT_OPTIONS, EnumDisplateSorting, IDisplateSearchOptions } from './displate-search.data';


/**------------------------------------------------------
 * Displate Search Url
 */
export class DisplateSearch extends AbstractSearchLinks<IDisplateSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumDisplateSorting>[] {
		return DISPLATE_CONFIG.sortings as ISearchParameterConfig<EnumDisplateSorting>[];
	}

	constructor() {
		super(DISPLATE_CONFIG, DISPLATE_DEFAULT_OPTIONS);
	}


	/**------------------------------------------------------
	 * Overwrite Search
	 * > Info: search links require a "-" instead of the
	 * > default "%20" at displate, for this reason the
	 * > "%20" of the search link is replaced with "-"
	 */
	override search(searchQuery: string, options?: Partial<IDisplateSearchOptions>): string {
		return super.search(searchQuery, options).replace(/%20/g, '-');
	}
}
