/**------------------------------------------------------
 * Data Interfaces
 */
export interface IExportSheetTable {
	sheetName	: string;
	rows		: IExportSheetRow[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type IExportSheetRow = IExportSheetValue[];				// pretend to be an interface

export interface IExportSheetValue {
	heading		: string;
	value		: string | string[] | boolean | number;
}


/**------------------------------------------------------
 * Options Interface
 */
export interface IExportSheetOptions {
	outputType		: EnumOutputType;
	columnWidth		: number;
}


/**------------------------------------------------------
 * Enums
 */
export enum EnumOutputType {
	Base64 	= 'base64',
	Buffer 	= 'buffer',
	File  	= 'file'
}
