import { EnumFileType } from '@libs/constants';

import { ExcelSheetWrapper } from './sheet-wrapper/excel-sheet.wrapper';
import { ExcelSheetHelper } from './sheet-wrapper/excel-sheet.helper';
import { ExcelTableSheetWrapper } from './sheet-wrapper/excel-table-sheet.wrapper';
import { ExcelDataHelper } from './helper/excel-data.helper';
import { ExcelReaderHelper } from './helper/excel-reader.helper';
import { ExcelSheetReaderHelper } from './helper/readers/excel-sheet-reader.helper';
import { CsvReaderHelper, IImportCsvData } from './helper/readers/csv-reader.helper';
import { ExcelFormulaeReaderHelper, IImportExcelFormulaeData } from './helper/readers/excel-formulae-reader.helper';
import { EXCEL_ACCEPTED_FILE_TYPES, IExcelImportOptions, IImportExcelData } from './helper/excel-import.interface';
import { ExcelHtmlReaderHelper, IImportExcelHtmlOptions, IImportExcelHtmlData } from './helper/readers/excel-html-reader.helper';


export class ExcelImportService {

	constructor(
		private csvReaderHelper	 		 : CsvReaderHelper,
		private excelDataHelper			 : ExcelDataHelper,
		private excelSheetHelper		 : ExcelSheetHelper,
		private excelReaderHelper		 : ExcelReaderHelper,
		private excelHtmlReaderHelper	 : ExcelHtmlReaderHelper,
		private excelSheetReaderHelper	 : ExcelSheetReaderHelper,
		private excelFormulaeReaderHelper: ExcelFormulaeReaderHelper
	) {}


	/**------------------------------------------------------
	 * Information
	 */
	getAcceptedFileTypes(): EnumFileType[] {
		return EXCEL_ACCEPTED_FILE_TYPES;
	}


	/**------------------------------------------------------
	 * Check Excel Sheet
	 */
	isAcceptedExcelType(file: File): boolean {
		return this.excelReaderHelper.isAcceptedExcelType(file);
	}

	async isCorruptedExcel(file: File): Promise<boolean> {

		//0 - try to import the data
		const excelData: IImportExcelData | null = await this.excelSheetReaderHelper.importExcelFile(file);
		if (excelData !== null) return false;

		//1 - if the read worked the data is valid
		return true;
	}

	async isTableSheet(file: File): Promise<boolean> {
		const tableSheetData: ExcelTableSheetWrapper | null = await this.importTableExcel(file);
		return tableSheetData?.isTableSheet() || false;
	}


	/**------------------------------------------------------
	 * Import Excel
	 */
	async importExcel(file: File, options: Partial<IExcelImportOptions> | null = null): Promise<ExcelSheetWrapper | null> {

		//0 - try to read the sheet data
		let excelData: IImportExcelData | null = await this.excelSheetReaderHelper.importExcelFile(file);
		if (!excelData) return null;

		//1 - filter out all leading & trailing empty rows
		excelData = this.excelDataHelper.removeEmptyRows(excelData, options);

		//2 - create a new wrapper around the sheet data
		return new ExcelSheetWrapper(excelData, this.excelSheetHelper);
	}

	async importTableExcel(file: File, options?: Partial<IExcelImportOptions>): Promise<ExcelTableSheetWrapper | null> {

		//0 - try to read the sheet data
		let excelData: IImportExcelData | null = await this.excelSheetReaderHelper.importExcelFile(file);
		if (!excelData) return null;

		//1 - filter out all leading & trailing empty rows
		excelData = this.excelDataHelper.removeEmptyRows(excelData, options!);

		//2 - create a new wrapper around the sheet data
		const tableSheet: ExcelTableSheetWrapper = new ExcelTableSheetWrapper(excelData, this.excelSheetHelper);
		if (!tableSheet.isTableSheet()) return null;

		//3 - return the table sheet
		return tableSheet;
	}


	/**------------------------------------------------------
	 * Import Excel as Csv/Html/Formula
	 */
	async importExcelAsCsv(file: File): Promise<IImportCsvData | null> {

		//0 - try to read the sheet data
		const csvData: IImportCsvData | null = await this.csvReaderHelper.importExcelFileAsCsv(file);
		if (!csvData) return null;

		//1 - create a new wrapper around the sheet data
		return csvData;
	}

	async importExcelAsHtml(file: File, options?: Partial<IImportExcelHtmlOptions>): Promise<IImportExcelHtmlData | null> {

		//0 - try to read the sheet data
		const htmlData: IImportExcelHtmlData | null = await this.excelHtmlReaderHelper.importExcelFileAsHtml(file, options!);
		if (!htmlData) return null;

		//1 - create a new wrapper around the sheet data
		return htmlData;
	}

	async importExcelAsFormulae(file: File): Promise<IImportExcelFormulaeData | null> {

		//0 - try to read the sheet data
		const formulaeData: IImportExcelFormulaeData | null = await this.excelFormulaeReaderHelper.importExcelFileAsFormulae(file);
		if (!formulaeData) return null;

		//1 - create a new wrapper around the sheet data
		return formulaeData;
	}
}
