import { EnumAmazonProduct, EnumAmazonMarketplace, EnumAmazonFitType, EnumAmazonColor } from '../../app-constants/pod-platforms/amazon.constants';


/**------------------------------------------------------
 * Merch By Amazon Design Data
 */
export interface IDesignDataMba {
	asin            			: string;
	brand           			: string;
	title           			: string;
	bulletPoint1    			: string;
	bulletPoint2    			: string;
	description     			: string;
	img             			: string;
	product         			: EnumAmazonProduct;
	marketplace     			: EnumAmazonMarketplace;
	fit_types     				: EnumAmazonFitType[];
	colors     					: EnumAmazonColor[];
	price           			: number;
	price_history   			: IPriceHistory[];
	bsr             			: number;
	bsr_category				: string | null;
	bsr_history     			: IBsrHistory[];
	sub_bsr         			: ISubBsrData[];
	review_stars    			: number;
	review_count    			: number;
	publish_date    			: Date;
	extracted_keywords          : IExtractedKeyword[];
	extracted_long_tail_keywords: IExtractedKeyword[];
}


/**------------------------------------------------------
 * History Object interfaces
 */
export interface IPriceHistory {
	price		: number;
	date		: Date;
}

export interface IBsrHistory 	{
	rank		: number;
	date		: Date;
}

export interface ISubBsrData {
	category 	: string;
	rank     	: number;
}

export interface IExtractedKeyword {
	keyword		: string;
	count		: number;
}
