import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Printify Constants
 */
export enum EnumPrintifyPlatform {
	eBay		= 'ebay',
	Etsy		= 'etsy',
	Shopify		= 'shopify'
}


/**------------------------------------------------------
 * Printify Template
 */
export interface IPrintifyTemplateOptions {
	general 	: {
		platform 	: EnumPrintifyPlatform;
		language	: EnumLanguage;
		store		: string;
	};
	template	: {
		name			: string;
		imageView  	 	: string;
	};
	productOptions	: {
		productName		: string;
		saveAsDraft		: boolean;
		publishInStore	: boolean;
	};
}
