import { INgYouTubeVideo } from '@libs/frameworks/angular';
import { EnumLanguage } from '@libs/constants';


/**------------------------------------------------------
 * Video Identifiers
 */
export enum EnumFlyingTutorialVideo {
	Welcome 								= 'welcome',

	ResearchBestSellers 					= 'research_best_sellers',
	ResearchArchive 						= 'research_archive',
	ResearchRealTimeResearch 				= 'research_real_time_research',
	ResearchNicheResearchWorkflow 			= 'research_niche_research_workflow',
	ResearchSellerTrends 					= 'research_seller_trends',
	ResearchDeleted 						= 'research_deleted',
	ResearchSubcategoryRanks 				= 'research_subcategory_ranks',
	ResearchGetInspired 					= 'research_get_inspired',
	ResearchBrandsCategories 				= 'research_brands_categories',
	ResearchNicheAnalysisAndList 			= 'research_niche_analysis_list',
	ResearchProductSearch 					= 'research_product_search',
	ResearchEventsUpcomingCalendar 			= 'research_events_upcoming_calendar',
	ResearchEmailNotifications 				= 'research_email_notifications',
	ResearchFavorites 						= 'research_favorites',

	KeywordSearch 							= 'keyword_search',
	KeywordCompetition 						= 'keyword_competition',
	KeywordHunter 							= 'keyword_hunter',
	KeywordProductExplorer 					= 'keyword_product_explorer',
	KeywordSuggestions 						= 'keyword_suggestions',
	KeywordWorkbench 						= 'keyword_workbench',
	KeywordTagGenerator 					= 'keyword_tag_generator',
	KeywordLists 							= 'keyword_lists',
	KeywordRankPositioning 					= 'keyword_rank_positioning',
	KeywordSponsoredLookup 					= 'keyword_sponsored_lookup',
	KeywordAdSuggestions 					= 'keyword_advertisement_suggestions',
	KeywordAdLists 							= 'keyword_advertisement_lists',

	TrademarkSearch 						= 'trademark_search',
	TrademarkRegistrations 					= 'trademark_registrations',
	TrademarkWatchlist 						= 'trademark_watchlist',
	TrademarkNotifications 					= 'trademark_notifications',

	DesignManagementImportDesigns 			= 'design_management_import_designs',
	DesignManagementImportExcel				= 'design_management_import_excel',
	DesignManagementManageDesigns 			= 'design_management_manage_designs',
	DesignManagementEditListings 			= 'design_management_editListings',
	DesignManagementTmReport				= 'design_management_tm_report',
	DesignManagementListingBlacklistCheck 	= 'design_management_listing_blacklist_check',

	PodUploadNewSettingsListings 			= 'pod_upload_new_settings_listings',
	PodUploadOptionsAndPossibilities		= 'pod_upload_options_and_possibilities',
	PodUploadInstallation 					= 'pod_upload_installation',
	PodUploadOverview 						= 'pod_upload_overview',
	PodUploadListingsAndPossibilities 		= 'pod_upload_listings_and_possibilities',
	PodUploadXML 							= 'pod_upload_xml',
	PodUploadSettingsAndTemplates 			= 'pod_upload_settings_and_templates',
	PodUploadUpload 		 				= 'pod_upload_upload',
	PodUploadExtraFeatures 					= 'pod_upload_extra_features',
	PodUploadPROVersionKeywordsAndProfiles 	= 'pod_upload_pro_version_keywords_and_profiles',
	PodUploadProfileManager 				= 'pod_upload_profile_manager',
	PodUploadProductorAndExcelImport 		= 'pod_upload_productor_and_excel_import',
	PodUploadTextAndKeywordSuggestions 		= 'pod_upload_text_and_keyword_suggestions',
	PodUploadUploadManagerAndOverview 		= 'pod_upload_upload_manager_and_overview',
	PodUploadVAIntegration 					= 'pod_upload_va_integration',
	PodUploadStatistics						= 'pod_upload_statistics',
	PodUploadAiTemplates					= 'pod_upload_ai_templates',

	KdpUploadInstallation 					= 'kdp_upload_installation',
	KdpUploadOverview 						= 'kdp_upload_overview',
	KdpUploadListingsAndPossibilities 		= 'kdp_upload_listings_and_possibilities',
	KdpUploadXML 							= 'kdp_upload_xml',
	KdpUploadSettings 						= 'kdp_upload_settings',
	KdpUploadUpload 						= 'kdp_upload_upload',
	KdpUploadExtraFeatures 					= 'kdp_upload_extra_features',
	KdpUploadPROVersionKeywordsAndProfiles 	= 'kdp_upload_pro_version_keywords_and_profiles',
	KdpUploadColorBookGenerator 			= 'kdp_upload_color_book_generator'
}


/**------------------------------------------------------
 * YouTube Videos of Flying
 */
export const FLYING_VIDEOS: Partial<Record<EnumFlyingTutorialVideo, INgYouTubeVideo>> = {
	// cspell:disable
	[EnumFlyingTutorialVideo.Welcome]: {
		title		: 'Welcome',
		videos		: [
			{
				language 	: EnumLanguage.EN,
				videoId		: 'iGiyAt5936A'
			},
			{
				language	: EnumLanguage.DE,
				videoId		: '3AVwhcQOirI'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchBestSellers]: {
		title 		: 'Best Sellers',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'tJ-dFgxNsew'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'GZ8hpnPDUHs'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchArchive]: {
		title 		: 'Archive',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'YcgfT3PUgeI'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '3zh3n1z0oPU'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchRealTimeResearch]: {
		title 		: 'Real Time Research',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '0Q_E6RIJut8'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'vxxy0Q0mf5I'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchNicheResearchWorkflow]: {
		title 		: 'Niche Research Workflow',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '1Oqg4tkL8l4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'wMIs7YVECmc'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchSellerTrends]: {
		title 		: 'Seller Trends',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'd_uL-WoqSSg'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'yBKpXuQErdY'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchDeleted]: {
		title 		: 'Deleted',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'kY1bbwuDr3w'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'yDti2MrqDwg'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchBrandsCategories]: {
		title 		: 'Brands + Categories',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '8QJrj6_7R9w'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'ywIGXeCjimI'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchNicheAnalysisAndList]: {
		title 		: 'Niche Analysis and List',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'Aw5wJNwFhUk'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'VWG3qsK2ZwQ'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchProductSearch]: {
		title 		: 'Product Search',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'X4KJ6T8MnZ4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '8SFX8Bfzmzo'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchEventsUpcomingCalendar]: {
		title 		: 'Events & Calendar',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'ndFbJ8PfmVM'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'gXIovv1kigc'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchEmailNotifications]: {
		title 		: 'Email Notifications',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '7gclWF09HGk'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '-rdm9JvQAAg'
			}
		]
	},
	[EnumFlyingTutorialVideo.ResearchFavorites]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'rApXMmVUKXs'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'K6T45qKRYBI'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordSearch]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '3w0V55aq-i8'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'ITHhyYLzVXM'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordCompetition]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'c7GnZ2gXDaY'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'vv7dzzxm68Y'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordHunter]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'f67SN-gULB4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '_4TD8hnCXJk'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordProductExplorer]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'hr675X-Xdb8'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'Hp1E3aDVhrM'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordSuggestions]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '5H0QF4ZIvCE'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'c_P92ClRb0U'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordWorkbench]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'ImM8368Z2Q4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'rvUpRwFbeaQ'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordTagGenerator]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '3i3dIE9ZLGo'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '0Oie7cWFRDY'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordLists]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'GQ26hUibALQ'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '4Atm4tZjnJs'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordRankPositioning]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '0ss50XMWp0M'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '9N0lKTAoUbQ'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordSponsoredLookup]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'gUdrhHOJDqQ'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'x1Y85itb85g'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordAdSuggestions]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'QAW9m25cAS4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'ObYixAppJ1g'
			}
		]
	},
	[EnumFlyingTutorialVideo.KeywordAdLists]: {
		title 		: 'Favorites',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'J6SboMvhr1U'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'ytVEZPhQlH4'
			}
		]
	},
	[EnumFlyingTutorialVideo.TrademarkSearch]: {
		title 		: 'Trademark Search',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'KM2j-Bc8bXg'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'rT1BgJrX5Og'
			}
		]
	},
	[EnumFlyingTutorialVideo.TrademarkRegistrations]: {
		title 		: 'Trademark Registrations',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'HBeQekcNjpo'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '-2MdMDlDUaU'
			}
		]
	},
	[EnumFlyingTutorialVideo.TrademarkWatchlist]: {
		title 		: 'Trademark Watchlist',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'kOej6QRBkR0'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '3JKNc8bU1HQ'
			}
		]
	},
	[EnumFlyingTutorialVideo.TrademarkNotifications]: {
		title 		: 'Email Notifications',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '7gclWF09HGk'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '-rdm9JvQAAg'
			}
		]
	},
	[EnumFlyingTutorialVideo.DesignManagementImportDesigns]: {
		title 		: 'Import Designs',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'ftMGS-pKD5g'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'RExT7y6xLb4'
			}
		]
	},
	[EnumFlyingTutorialVideo.DesignManagementImportExcel]: {
		title 		: 'Excel Import',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'wghcW5NS7JY'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'KKns86hEnxs'
			}
		]
	},
	[EnumFlyingTutorialVideo.DesignManagementManageDesigns]: {
		title 		: 'Manage Designs',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '69O_3eI859M'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'cHkMaqCbXF0'
			}
		]
	},
	[EnumFlyingTutorialVideo.DesignManagementEditListings]: {
		title 		: 'Edit Listings',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'qjjjMsxjFiw'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'qFb87mEPjRY'
			}
		]
	},
	[EnumFlyingTutorialVideo.DesignManagementTmReport]: {
		title 		: 'TM Report',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '3Njut01AdoE'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'EyhYJdwGN48'
			}
		]
	},
	[EnumFlyingTutorialVideo.DesignManagementListingBlacklistCheck]: {
		title 		: 'Listing and Blacklist Check',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'x-UJZFMqRes'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'OxqB6n6W20U'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadNewSettingsListings]: {
		title 		: 'New Settings For Your Listings',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'LcVghHe2ASo'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'a65qyrjASLk'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadOptionsAndPossibilities]: {
		title 		: 'Endless Options And Possibilities',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '6Tc54czs4lo'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'g2NnNV79QgI'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadInstallation]: {
		title 		: 'Installation',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '26aWCempubc'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '95eLwm5_RkQ'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadOverview]: {
		title 		: 'Overview',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '5xIFh3mYGAA'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'vWfQgEtq6P8'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadListingsAndPossibilities]: {
		title 		: 'Listings & Possibilities',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '5vstUXXsUkM'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'OL1I6A0rWQs'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadXML]: {
		title 		: 'XML Concept to Manage your Data',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'fjuFb9EdHu0'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'rkBvH1--rVk'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadSettingsAndTemplates]: {
		title 		: 'Settings And Templates',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'p3Ka4nAmPqU'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '6CDpZXEtvGo'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadUpload]: {
		title 		: 'Upload',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'XO2G0iAN-js'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'C7Z9sFGZwwg'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadExtraFeatures]: {
		title 		: 'Mega Cool Extra Features',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'JpAMS3cWgOw'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'LBYBQObYSso'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadPROVersionKeywordsAndProfiles]: {
		title 		: 'PRO Version (Keywords & Profiles)',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'p7FBmXBwbk4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'MBVY0Mf3ytE'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadProfileManager]: {
		title 		: 'Profile Manager',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '-AhQKNfL2Rs'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'Ul8jS9Suw6s'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadProductorAndExcelImport]: {
		title 		: 'Productor & Excel Import',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'ZosmNv6HGtU'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'VOVhTAFPDuU'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadTextAndKeywordSuggestions]: {
		title 		: 'Text & Keyword Suggestions',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'cwTAlDmIAa4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '1ugeZFGPqSk'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadUploadManagerAndOverview]: {
		title 		: 'Upload Manager / Overview',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'ak_npgCjB8k'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'w0QAwYw0JpE'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadVAIntegration]: {
		title 		: 'VA Integration',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'N65vdRujvEU'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'xLrDrYDwyjU'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadStatistics]: {
		title 		: 'Statistics',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '_RcJs_Opzck'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'qPBSztiLrA0'
			}
		]
	},
	[EnumFlyingTutorialVideo.PodUploadAiTemplates]: {
		title 		: 'AI Listings',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'MgLKU9zO3Rg'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'Ac_WEDokNCU'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadInstallation]: {
		title 		: 'Installation',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'UjGesFT8DNM'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'p9PBWttJ0N8'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadOverview]: {
		title 		: 'Overview',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'DO7jcHbsJ0A'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: '0_HXgy3xRn0'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadListingsAndPossibilities]: {
		title 		: 'Listings & Possibilities',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'Q0HUatDgods'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'vunRbOLacXk'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadXML]: {
		title 		: 'XML Concept to Manage your Data',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: '4WjGqYA8KiU'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'za-hj551qlA'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadSettings]: {
		title 		: 'Settings',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'harbgwAlaT4'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'zDGRA6cIgxw'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadUpload]: {
		title 		: 'Upload',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'WpjtlDG52CM'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'SNm0jrmbbb0'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadExtraFeatures]: {
		title 		: 'Mega Cool Extra Features',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'Vo7cIt5wblg'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'CRUMm7O8Q7o'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadPROVersionKeywordsAndProfiles]: {
		title 		: 'PRO Version (Keywords & Profiles)',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'hDs_R42ZZjg'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'Om5OcDK9DOI'
			}
		]
	},
	[EnumFlyingTutorialVideo.KdpUploadColorBookGenerator]: {
		title 		: 'Color Book Generator',
		videos 		: [
			{
				language 	: EnumLanguage.EN,
				videoId 	: 'XPBdUe61KOI'
			},
			{
				language 	: EnumLanguage.DE,
				videoId 	: 'jaPg3CEiWmg'
			}
		]
	}
	// cspell:enable
};
