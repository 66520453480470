import { Observable, interval, map } from 'rxjs';


/**------------------------------------------------------
 * RxJs Date
 * ----------
 * ...
 */
export class RxJsDate {

	epochTime(intervalInMs: number): Observable<number> {
		return interval(intervalInMs).pipe(map(() => new Date().getTime()));
	}
}
