import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SecureTimeToken } from '@domains/security/shared';
import { SecureHttpModule } from '@domains/security/frontend';
import { NgDialogsModule, NgProductTourModule } from '@libs/frameworks/angular';

import { ENVIRONMENT } from '../environments';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouteGuardsModule } from './shared/guards/route-guards/route-guards.module';
import { AuthInterceptorService } from './services/shared/auth/auth-interceptor/auth-interceptor.service';
import { LocationService } from './services/shared/payment/location/location.service';
import { ANGULAR_FRAMEWORK_MODULES, AUTH_SECURITY_PROVIDERS, ProviderHelperService, SECURITY_MODULES, SHARED_SERVICE_PROVIDERS } from './app.data';


//** Set global language */
registerLocaleData(localeDe);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		SecureHttpModule.register(ENVIRONMENT.SECURE_BACKEND),
		NgbModule,
		NgDialogsModule,
		NgProductTourModule,
		RouteGuardsModule,
		...ANGULAR_FRAMEWORK_MODULES,
		...SECURITY_MODULES,
		...ENVIRONMENT.PAGE_ADDONS_MODULES
	],
	providers: [
		...AUTH_SECURITY_PROVIDERS,
		...SHARED_SERVICE_PROVIDERS,
		{
			provide		: LOCALE_ID,
			useValue	: ENVIRONMENT.FORMATTING.LOCAL_FORMAT
		},
		{
			provide	 	: HTTP_INTERCEPTORS,
			useClass	: AuthInterceptorService,
			multi	   	: true
		},
		LocationService,
		{
			provide		: SecureTimeToken,
			useClass	: SecureTimeToken
		}
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule {

	//** Create AppInjector instance */
	constructor(
		private injector				: Injector,
		private providerHelperService	: ProviderHelperService
	) {
		this.providerHelperService.initializeAppInjector(this.injector);
	}
}
