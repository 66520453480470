import { IAmazonSearchOptions } from './amazon-search.data';
import { AbstractAmazonSearch } from './helpers/amazon-search.abstract';
import { EnumAmazonMarketplace } from '../../../../../constants/research';
import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { SearchLinksHelper } from '../../../data/search-links.helper';
import { IMarketSearchLinks } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Amazon Search Links
 */
export class AmazonSearch implements IMarketSearchLinks<EnumAmazonMarketplace> {

	constructor(
		private searchLinksHelper: SearchLinksHelper,
		private searchHandlers	 : AbstractSearchLinks<IAmazonSearchOptions>[]
	) { }


	/**------------------------------------------------------
	 * Get Search Handler
	 */
	getMarketplace(marketplace: EnumAmazonMarketplace): AbstractAmazonSearch {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers) as AbstractAmazonSearch;
	}


	/**------------------------------------------------------
	 * Links & Search Links
	 */
	home(marketplace: EnumAmazonMarketplace): string {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers).home();
	}

	login(marketplace: EnumAmazonMarketplace): string {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers).login();
	}

	//** Search */
	search(marketplace: EnumAmazonMarketplace, searchKeyword: string, options?: Partial<IAmazonSearchOptions>): string {
		return this.searchLinksHelper.getMarketplace(marketplace, this.searchHandlers).search(searchKeyword, options);
	}
}
