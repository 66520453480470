import { CsvHandlerService } from './csv-handler/csv-handler.service';
import { ExcelExportService } from './excel-export/excel-export.service';
import { ExcelHandlerService } from './excel-handler.service';
import { ExcelImportService } from './excel-import/excel-import.service';
import { ExcelDataHelper } from './excel-import/helper/excel-data.helper';
import { ExcelReaderHelper } from './excel-import/helper/excel-reader.helper';
import { CsvReaderHelper } from './excel-import/helper/readers/csv-reader.helper';
import { ExcelHtmlReaderHelper } from './excel-import/helper/readers/excel-html-reader.helper';
import { ExcelSheetReaderHelper } from './excel-import/helper/readers/excel-sheet-reader.helper';
import { ExcelSheetHelper } from './excel-import/sheet-wrapper/excel-sheet.helper';
import { ExcelFormulaeReaderHelper } from './excel-import/helper/readers/excel-formulae-reader.helper';


/**------------------------------------------------------
 * Factory to Create the Instance
 */
export class ExcelHandlerServiceFactory {

	static create(): ExcelHandlerService {
		const excelReaderHelper: ExcelReaderHelper = new ExcelReaderHelper();
		return new ExcelHandlerService(
			excelReaderHelper,
			new CsvHandlerService(),
			new ExcelExportService(),
			new ExcelImportService(
				new CsvReaderHelper(
					excelReaderHelper
				),
				new ExcelDataHelper(),
				new ExcelSheetHelper(),
				excelReaderHelper,
				new ExcelHtmlReaderHelper(
					excelReaderHelper
				),
				new ExcelSheetReaderHelper(
					excelReaderHelper
				),
				new ExcelFormulaeReaderHelper(
					excelReaderHelper
				)
			)
		);
	}
}
