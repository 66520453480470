import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


/**------------------------------------------------------
 * Modal Wrapper (contains the modal instance)
 */
export class NgModalWrapper {

	constructor(
		private modalRef : NgbModalRef
	) {}


	//** Set the input parameters of the component */
	set<T extends object>(params: T): NgModalWrapper {
		for (const [key, value] of Object.entries(params)) {

			//a. check if property is valid
			if (!(key in this.modalRef.componentInstance)) throw new Error(`NgModalAssistantService/NgModalWrapper => set => FATAL ERROR: key of "${key}" is not defined / exists in the component (please set all @Input parameters to null or an initial value, then this check can pass)`);

			//b. set the value to the input property
			this.modalRef.componentInstance[key] = value;
		}
		return this;
	}


	/**------------------------------------------------------
	 * Subscript to modal close event
	 */
	onSuccess<T>(callbackFunction: Function): NgModalWrapper {
		this.modalRef.result.then(
			(params: T) => callbackFunction(params),
			() => { /* no action */ }
		);
		return this;
	}

	onDismiss(callbackFunction: Function): NgModalWrapper {
		this.modalRef.result.then(
			() => { /* no action */ },
			(message: string) => callbackFunction(message)
		);
		return this;
	}

	onClose<T>(callbackFunction: Function): NgModalWrapper {
		this.modalRef.result.then(
			(params: T)		  => callbackFunction(params),
			() => callbackFunction()
		);
		return this;
	}


	/**------------------------------------------------------
	 * Helpers
	 */
	getComponentInstance<T>(): T {
		return this.modalRef.componentInstance as T;
	}

	getModal(): NgbModalRef {
		return this.modalRef;
	}

	close(): NgModalWrapper {
		this.modalRef.close();
		return this;
	}

	//** Subscribe to output event */
	subscribe<T>(eventEmitter: string, callbackFunction: Function): NgModalWrapper {

		//0 - check if output property exists
		if (!(eventEmitter in this.modalRef.componentInstance)) throw new Error(`NgModalAssistantService/NgModalWrapper => subscribe => FATAL ERROR: eventEmitter / output property of "${eventEmitter}" does not exist for the component which is used as a modal`);

		//1 - subscribe to the event emitter
		this.modalRef.componentInstance[eventEmitter].subscribe((params: T) => {
			callbackFunction(params);
		});
		return this;
	}
}
