import { EnumKdpMarketplace } from './kdp-amazon.constants';


/**------------------------------------------------------
 * KDP Marketplace Specifications
 */
export const KDP_MARKETPLACE_SPECIFICATION: IAmazonKdpMarketplace[] = [
	{
		code      	: EnumKdpMarketplace.COM,
		name		: 'Amazon.com',
		price   	: {
			min		: 1,
			max   	: 250
		},
		other		: {
			extendedSales 	: true,
			currency		: {
				code		: 'usd',
				symbol		: '$'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.CO_UK,
		name		: 'Amazon.co.uk',
		price   	: {
			min		: 1,
			max   	: 250
		},
		other		: {
			extendedSales 	: false,
			currency		: {
				code		: 'gbp',
				symbol		: '£'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.DE,
		name		: 'Amazon.de',
		price   	: {
			min		: 1,
			max   	: 250
		},
		other		: {
			extendedSales 	: false,
			currency		: {
				code		: 'eur',
				symbol		: '€'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.FR,
		name		: 'Amazon.fr',
		price   	: {
			min		: 1,
			max   	: 250
		},
		other		: {
			extendedSales 	: false,
			currency		: {
				code		: 'eur',
				symbol		: '€'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.ES,
		name		: 'Amazon.es',
		price   	: {
			min		: 1,
			max   	: 250
		},
		other		: {
			extendedSales 	: false,
			currency		: {
				code		: 'eur',
				symbol		: '€'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.IT,
		name		: 'Amazon.it',
		price   	: {
			min		: 1,
			max   	: 250
		},
		other		: {
			extendedSales 	: false,
			currency		: {
				code		: 'eur',
				symbol		: '€'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.JP,
		name		: 'Amazon.co.jp',
		price   	: {
			min		: 1,
			max   	: 30000
		},
		other		: {
			extendedSales 	: false,
			currency		: {
				code		: 'yen',
				symbol		: '¥'
			}
		}
	},
	{
		code      	: EnumKdpMarketplace.CA,
		name		: 'Amazon.ca',
		price   	: {
			min		: 1,
			max   	: 350
		},
		other		: {
			extendedSales 	: true,
			currency		: {
				code		: 'cad',
				symbol		: '$'
			}
		}
	}
];


//** Interfaces --------------------------------- */
interface IAmazonKdpMarketplace {
	code      	: EnumKdpMarketplace;
	name		: string;
	price   	: {
		min		: number;
		max   	: number;
	};
	other		: {
		extendedSales 	: boolean;
		currency		: {
			code		: string;
			symbol		: string;
		};
	};
}
