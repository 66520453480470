import { EnumFileType } from '@libs/constants';

import { CsvHandlerService } from './csv-handler/csv-handler.service';
import { ExcelImportService } from './excel-import/excel-import.service';
import { ExcelExportService } from './excel-export/excel-export.service';
import { ExcelReaderHelper } from './excel-import/helper/excel-reader.helper';
import { EXCEL_ACCEPTED_FILE_TYPES } from './excel-import/helper/excel-import.interface';


/**------------------------------------------------------
 * Excel Handler
 * -------------
 * > Handler for Library "xlsx" : https://www.npmjs.com/package/xlsx
 */
export class ExcelHandlerService {

	//** Configurations */
	readonly EXCEL_ACCEPTED_FILE_TYPES: EnumFileType[] = EXCEL_ACCEPTED_FILE_TYPES;

	constructor(
		private excelReaderHelper	: ExcelReaderHelper,
		private csvHandlerService	: CsvHandlerService,
		private excelExportService	: ExcelExportService,
		private excelImportService	: ExcelImportService
	) {}

	/**------------------------------------------------------
	 * CSV
	 */
	get csv(): CsvHandlerService { return this.csvHandlerService; }


	/**------------------------------------------------------
	 * Excel Export/Import
	 */
	get excelExport(): ExcelExportService { return this.excelExportService; }
	get excelImport(): ExcelImportService { return this.excelImportService; }
}
