import { EnumSpeed } from './speed.interface';
import { EnumSystem, EnumType, IUnitRecord } from '../shared/unit-system.interface';


/**------------------------------------------------------
 * Speed Infos
 */
export const SPEED_INFOS: Record<EnumSpeed, IUnitRecord> = {

	// -------------------------------- Speed (Metric)
	[EnumSpeed.MetrePerSecond]: {
		code		: EnumSpeed.MetrePerSecond,
		type		: EnumType.Speed,
		system		: EnumSystem.Metric,
		name		: 'Metre per second',
		namePlural	: 'Metres per second',
		symbol		: 'm/s',
		toBase		: 3.6
	},
	[EnumSpeed.KilometerPerHour]: {
		code		: EnumSpeed.KilometerPerHour,
		type		: EnumType.Speed,
		system		: EnumSystem.Metric,
		name		: 'Kilometer per hour',
		namePlural	: 'Kilometers per hour',
		symbol		: 'km/h',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumSpeed.MilePerHour,
			toType		: EnumType.Speed,
			toSystem	: EnumSystem.Imperial,
			convert		: (value: number) => 1 / 1.609344 * value
		}
	},

	// -------------------------------- Speed (Imperial)
	[EnumSpeed.MilePerHour]: {
		code		: EnumSpeed.MilePerHour,
		type		: EnumType.Speed,
		system		: EnumSystem.Imperial,
		name		: 'Mile per hour',
		namePlural	: 'Miles per hour',
		symbol		: 'mph',
		toBase		: 1,
		toSystem	: {
			toCode		: EnumSpeed.KilometerPerHour,
			toType		: EnumType.Speed,
			toSystem	: EnumSystem.Metric,
			convert		: (value: number) => 1.609344 * value
		}
	},
	[EnumSpeed.Knot]: {
		code		: EnumSpeed.Knot,
		type		: EnumType.Speed,
		system		: EnumSystem.Imperial,
		name		: 'Knot',
		namePlural	: 'Knots',
		symbol		: 'knot',
		toBase		: 1.150779
	},
	[EnumSpeed.FootPerSecond]: {
		code		: EnumSpeed.FootPerSecond,
		type		: EnumType.Speed,
		system		: EnumSystem.Imperial,
		name		: 'Foot per second',
		namePlural	: 'Feet per second',
		symbol		: 'ft/s',
		toBase		: 0.681818
	}
};
