import { Injectable } from '@angular/core';
import { ObjectStorage } from '@libs/libraries/shared';

import { AppStorageHelper } from './sub-storages/app.helper';
import { PageStorageHelper } from './sub-storages/page.helper';
import { IAppStates, INITIAL_APP_STATE_OBJECT } from './sub-storages/app.interface';
import { IPageStates, INITIAL_PAGE_STATE_OBJECT } from './sub-storages/page.interface';


@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	//** App Storage */
	readonly app 	: AppStorageHelper 	= new AppStorageHelper(
		ObjectStorage.create<IAppStates>('appStates', INITIAL_APP_STATE_OBJECT, localStorage)
	);

	//** Page Storage */
	readonly page	: PageStorageHelper = new PageStorageHelper(
		ObjectStorage.create<IPageStates>('pageState', INITIAL_PAGE_STATE_OBJECT as IPageStates, localStorage)
	);
}
