import { Util } from '@libs/utilities/util';


export class ValidatorPath {

	//** Configurations */
	private readonly VALIDATE_UNIX_PATH	  	: RegExp = /^((\/|~\/|\.\/|\.\.\/)(([a-zA-Z0-9._-]+|[a-zA-Z0-9._-]+[a-zA-Z0-9\s._-]*[a-zA-Z0-9._-]+)\/?)*)?$/;
	private readonly VALIDATE_WINDOWS_PATH	: RegExp = /^(?:(?:[a-zA-Z]:[\\/])|(?:\\\\[\w\s.-]+\\[\w\s.-]+)|[.]{1,2}[\\/]|%[\w\s]+%[\\/])?(?:[\\/]?[^\\/:*?"<>|]+)*[\\/]?$/;
	private readonly VALIDATE_RELATIVE_PATH : RegExp = /^(\.\/|\.\.\/)+[\w\s-.]*(\/[\w\s-.]*)*(\/)?$/;
	private readonly VALIDATE_FILE_ENDING   : RegExp = /^.*\/[^/]+\.[A-Za-z0-9]+$/;


	/**------------------------------------------------------
	 * Folder Path
	 */
	isValidRelativePath(path: string): boolean {
		return this.validateRelativePath(path).length === 0;
	}

	validateRelativePath(path: string): string[] {

		//0 - is it an empty path
		if (Util.String.isEmpty(path)) return ['Path can not be empty.'];

		//1 - is the path valid for all os
		const validationErrors: string[] = [];
		if (!this.isValidMacOsPath(path))	validationErrors.push('Path must be valid on macOS.');
		if (!this.isValidUnixPath(path))	validationErrors.push('Path must be valid on Unix.');
		if (!this.isValidWindowsPath(path)) validationErrors.push('Path must be valid on Windows.');

		//2 - is the path relative
		if (!this.VALIDATE_RELATIVE_PATH.test(path)) validationErrors.push('Path must be relative path.');

		//3 - the path was valid
		return validationErrors;
	}


	/**------------------------------------------------------
	 * File Path
	 */
	isValidRelativeFilePath(filePath: string): boolean {
		return this.validateRelativeFilePath(filePath).length === 0;
	}

	validateRelativeFilePath(filePath: string): string[] {

		//0 - is the path valid in general
		const validationErrors: string[] = this.validateRelativePath(filePath);

		//1 - is it a file path?
		if (!this.VALIDATE_FILE_ENDING.test(filePath)) validationErrors.push('File path must end with a file.');

		//2 - the path was valid
		return validationErrors;
	}


	/**------------------------------------------------------
	 * OS Specific Path Validations
	 */
	isValidMacOsPath(path: string): boolean {
		return this.isValidUnixPath(path);
	}

	isValidUnixPath(path: string): boolean {

		//0 - is it an empty path
		if (Util.String.isEmpty(path)) return false;

		//1 - is the mac os path valid
		return this.VALIDATE_UNIX_PATH.test(path);
	}

	isValidWindowsPath(path: string): boolean {

		//0 - is it an empty path
		if (Util.String.isEmpty(path)) return false;

		//1 - is the mac os path valid
		return this.VALIDATE_WINDOWS_PATH.test(path);
	}
}
