import { EnumLanguage } from '../../international';


/**------------------------------------------------------
 * Available Options for Prefix Postfix Behavior
 */
export const SUGGESTIONS_PREFIX_POSTFIX_LIST: ISuggestionPrefixPostfixList = {
	EMPTY		: [' '],
	ALPHABET	: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
	NUMBERS 	: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
	LANGUAGE	: {
		QUESTION	: {
			[EnumLanguage.EN]: [
				'what', 'where', 'when', 'how', 'why', 'who', 'is', 'can', 'should', 'will',
				'must', 'did', 'do', 'does', 'was', 'whose', 'would', 'could'
			],
			[EnumLanguage.DE]: [
				'wer', 'wie', 'was', 'wieso', 'weshalb', 'warum', 'wo', 'wann', 'wie viel', 'kann',
				'muss', 'müsste', 'darf', 'soll', 'sollen', 'wessen', 'würde', 'wird'
			],
			[EnumLanguage.FR]: [],
			[EnumLanguage.ES]: [],
			[EnumLanguage.IT]: []
		},
		ARTICLE	: {
			[EnumLanguage.EN]: ['the', 'a'],
			[EnumLanguage.DE]: ['der', 'die', 'das', 'ein', 'eine'],
			[EnumLanguage.FR]: [],
			[EnumLanguage.ES]: [],
			[EnumLanguage.IT]: []
		},
		QUALITY_WORDS	: {
			[EnumLanguage.EN]: ['best', 'worst', 'top'],
			[EnumLanguage.DE]: ['bestes', 'schlechtestes', 'top'],
			[EnumLanguage.FR]: [],
			[EnumLanguage.ES]: [],
			[EnumLanguage.IT]: []
		},
		BINDING_WORDS	: {
			[EnumLanguage.EN]: ['for', 'and', 'with'],
			[EnumLanguage.DE]: ['für', 'und', 'mit'],
			[EnumLanguage.FR]: [],
			[EnumLanguage.ES]: [],
			[EnumLanguage.IT]: []
		}
	}
};


//** Interfaces --------------------------------- */
export interface ISuggestionPrefixPostfixList {
	EMPTY		: string[];
	ALPHABET	: string[];
	NUMBERS 	: string[];
	LANGUAGE	: {
		QUESTION		: Partial<Record<EnumLanguage, string[]>>;
		ARTICLE			: Partial<Record<EnumLanguage, string[]>>;
		QUALITY_WORDS	: Partial<Record<EnumLanguage, string[]>>;
		BINDING_WORDS	: Partial<Record<EnumLanguage, string[]>>;
	};
}
