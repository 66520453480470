/**------------------------------------------------------
 * Deleted designs constants
 */
export enum EnumDeletedDesignSearchType {
	Default	 	= 'default',
	Specific	= 'specific'
}

export enum EnumDeletedDesignSorting {
	Bsr 		= 'bsr',
	Review		= 'review',
	Newest		= 'newest'
}
