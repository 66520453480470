import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AffiliateGuard } from './shared/guards/route-guards/affiliate-links/affiliate.guard';


const ROUTES: Routes = [
	{
		path					: '',
		canActivate				: [AffiliateGuard],
		loadChildren			: () => import('./pages/pages.module').then((m: typeof import('./pages/pages.module')) => m.PagesModule),
		runGuardsAndResolvers	: 'paramsOrQueryParamsChange'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {
			useHash						: true,
			scrollPositionRestoration	: 'top'
		})
	],
	exports: [
		RouterModule
	],
	providers: [
		AffiliateGuard
	]
})
export class AppRoutingModule {}
