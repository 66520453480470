import { read, utils as xlsxUtils, WorkBook, WorkSheet } from 'xlsx';
import { Util } from '@libs/utilities/util';

import { ExcelReaderHelper, IImportedSheetInfo } from '../excel-reader.helper';
import { IImportFileInfo, IImportSheetInfo } from '../excel-import.interface';


export class CsvReaderHelper {

	constructor(
		private excelReaderHelper: ExcelReaderHelper
	) {}

	/**------------------------------------------------------
	 * Import Excel File as Csv
	 */
	async importExcelFileAsCsv(file: File): Promise<IImportCsvData | null> {

		//0 - check is valid excel file
		if (!this.excelReaderHelper.isAcceptedExcelType(file)) return null;

		//1 - try to read the sheet data
		const fileBuffer: ArrayBuffer | null = await this.excelReaderHelper.readFile(file);
		if (!fileBuffer) return null;

		//2 - read the spreadsheet data with library
		const importSheetCsvData: IImportSheetCsvData[] | null = this.bufferToExcelCsvData(fileBuffer);
		if (!importSheetCsvData) return null;

		//3 - create the excel sheet data
		const excelCsvData: IImportCsvData = {
			sheets: importSheetCsvData,
			metaInfo: {
				file: this.excelReaderHelper.getFileInfo(file)
			}
		};

		//4 - return the excel formatted data
		return excelCsvData;
	}


	/**------------------------------------------------------
	 * Import Excel Sheet (Get sheet info from buffer)
	 */
	private bufferToExcelCsvData(fileBuffer: ArrayBuffer): IImportSheetCsvData[] | null {

		try {
			//0 - initialize sheet and importSheetsData
			const importSheetsData: IImportSheetCsvData[] = [];
			const workBook		  : WorkBook 			  = read(fileBuffer);

			//1 - grab sheet
			const sheetNames: string[] = workBook.SheetNames;
			for (const sheetName of sheetNames) {

				//a. get worksheet and json object
				const workSheet: WorkSheet = workBook.Sheets[sheetName];

				//b. get sheet json info and get imported sheet as csv
				const workSheetData		: object[] 			 = xlsxUtils.sheet_to_json(workSheet);
				const csvData			: string 			 = xlsxUtils.sheet_to_csv(workSheet);
				const importedSheetInfo	: IImportedSheetInfo = this.excelReaderHelper.importedSheetInfo(workSheetData);

				//c. get imported sheet data object
				const importSheetData: IImportSheetCsvData = {
					csvData		: csvData,
					metaInfo	: {
						sheet	: {
							sheetName	 : sheetName,
							columnCount  : importedSheetInfo.columnCount,
							rowCount	 : importedSheetInfo.rows.length
						}
					}
				};

				//d. update sheets data
				importSheetsData.push(importSheetData);
			}

			//2 - return the result
			return importSheetsData;

		} catch (error: unknown) {
			console.error(`ExcelCsvReaderHelper => bufferToExcelCsvData => ERROR: ${Util.Basic.stringifyObject(error)}`);
			return null;
		}
	}
}


//** Interfaces --------------------------------- */
export interface IImportCsvData {
	sheets		: IImportSheetCsvData[];
	metaInfo	: {
		file	: IImportFileInfo;
	};
}

export interface IImportSheetCsvData {
	csvData		: string;
	metaInfo	: {
		sheet	: IImportSheetInfo;
	};
}
