// eslint-disable-next-line no-restricted-imports
import * as forge from 'node-forge';


/**------------------------------------------------------
 * Hash Algorithms
 * ---------------
 * > MD5 generator		: https://www.md5hashgenerator.com/
 * > SHA3 generator		: https://codeshack.io/sha3-hash-generator/
 * > SHA256 generator	: https://emn178.github.io/online-tools/sha256.html
 */
export class CryptoHash {

	//** Configurations */
	private readonly HASH_VALE_LENGTH_SHA512: number = 128;
	private readonly HASH_VALE_LENGTH_SHA256: number = 64;
	private readonly HASH_VALE_LENGTH_MD5	: number = 32;


	/**-----------------------------------------
	 * Hashing SHA-2 (SHA256)
	 * ----------------------
	 * > SHA256 hash generator: https://emn178.github.io/online-tools/sha256.html
	 * > SHA-2 algorithm is used for cryptography such as password storage
	 * > Output will be 64 chars (SHA256)
	 */
	hashSha256(data: string): string {
		const sha256Hash: string = forge.md.sha256.create().update(data, 'utf8').digest().toHex();
		return sha256Hash;
	}

	checkHashSha256(data: string, hashValue: string): boolean {

		//0 - verify if "hashValue" is from SHA256 algorithm
		if (hashValue.length !== this.HASH_VALE_LENGTH_SHA256) throw new Error(`Hash => hashSHA256 => ERROR: wrong hash value size`);

		//1 - verify if the hash value is matching the data
		const checkHashValue: string = this.hashSha256(data);
		return checkHashValue === hashValue;
	}


	/**-----------------------------------------
	 * Hashing SHA-2/512 (SHA512)
	 * --------------------------
	 * > SHA512 hash generator: https://emn178.github.io/online-tools/sha512.html
	 * > SHA-2 algorithm is used for cryptography such as password storage
	 * > Output will be 64 chars (SHA256)
	 */
	hashSha512(data: string): string {
		const sha512Hash: string = forge.md.sha512.create().update(data, 'utf8').digest().toHex();
		return sha512Hash;
	}

	checkHashSha512(data: string, hashValue: string): boolean {

		//0 - verify if "hashValue" is from SHA512 algorithm
		if (hashValue.length !== this.HASH_VALE_LENGTH_SHA512) throw new Error(`Hash => hashSHA512 => ERROR: wrong hash value size`);

		//1 - verify if the hash value is matching the data
		const checkHashValue: string = this.hashSha512(data);
		return checkHashValue === hashValue;
	}


	/**-----------------------------------------
	 * Hashing MD5
	 * -----------
	 * > MD5 hash generator: https://emn178.github.io/online-tools/md5.html
	 * > MD5 main application is data integrity verification
	 * > Output will be 32 chars (MD5)
	 */
	hashMd5(data: string): string {
		const md5Hash: string = forge.md.md5.create().update(data, 'utf8').digest().toHex();
		return md5Hash;
	}

	checkHashMd5(data: string, hashValue: string): boolean {

		//0 - verify if "hashValue" is from MD5 algorithm
		if (hashValue.length !== this.HASH_VALE_LENGTH_MD5) throw new Error(`Hash => checkHashMD5 => ERROR: wrong hash value size`);

		//1 - verify if the hash value is matching the data
		const checkHashValue: string = this.hashMd5(data);
		return checkHashValue === hashValue;
	}
}
