import { BLOCKED_EMAIL_DOMAINS } from '@libs/constants';
import { Util } from '@libs/utilities/util';

import { ValidatorDomainName } from '../domain-name-validator/domain-name-validator';


export class ValidatorEmail {

	//** Configurations */
	private readonly VALIDATE_EMAIL_FORMAT_REGEX: RegExp = /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
	private readonly VALIDATE_FIRST_CHAR_REGEX	: RegExp = /^[a-z0-9]/;
	private readonly VALIDATE_LAST_CHAR_REGEX	: RegExp = /[a-z0-9]$/;
	private readonly VALIDATE_WHITESPACE_REGEX	: RegExp = /\s/;
	private readonly CONSECUTIVE_PERIODS_REGEX	: RegExp = /\.{2,}/;
	private readonly MAX_MAIL_LENGTH			: number = 320;						// That limit is a maximum of 64 characters (octets) in the "local part" (before the "@") and a maximum of 255 characters (octets) in the domain part (after the "@") for a total length of 320 characters.

	constructor(
		private domainNameValidator: ValidatorDomainName
	) {}


	isValid(email: string): boolean {
		return Util.Array.isEmpty(this.validate(email));
	}

	validate(email: string): string[] {

		//0 - check if the email is defined
		if (Util.String.isEmpty(email)) return [`Email address is required.`];

		//1 - check if the email is valid
		const validationErrors: string[] = this.validateEmail(email);

		//2 - get the domain from email
		const domain: string = email.substring(email.lastIndexOf('@') + 1);
		if (BLOCKED_EMAIL_DOMAINS.includes(domain)) validationErrors.push('Email provider is not allowed.');

		//3 - return no errors
		return validationErrors;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private validateEmail(email: string): string[] {

		//0 - check for whitespace, and valid first char
		if (email.length > this.MAX_MAIL_LENGTH) return ['Email is too long.'];
		if (this.VALIDATE_WHITESPACE_REGEX.test(email)) return ['Email cannot contain whitespaces.'];
		if (!this.VALIDATE_FIRST_CHAR_REGEX.test(email[0])) return ['The first character of the email must be a letter or number.'];

		//1 - check for domain validity
		const domainName			: string   = email.split('@')[1];
		const domainValidationError	: string[] = this.domainNameValidator.validate(domainName);
		if (Util.Array.isNotEmpty(domainValidationError)) return domainValidationError;

		//2 - check for email validity
		if (!this.VALIDATE_EMAIL_FORMAT_REGEX.test(email)) return ['Invalid email format. Only lowercase letters (a-z), numbers (0-9), hyphen (-), and periods (.) are allowed.'];
		if (this.CONSECUTIVE_PERIODS_REGEX.test(email)) return ['Email cannot contain consecutive periods (..).'];
		if (!this.VALIDATE_LAST_CHAR_REGEX.test(email.slice(-1))) return ['The last character of the email must be an ASCII letter (a-z) or number (0-9).'];

		//3 - no issues found
		return [];
	}
}
