import { EnumNgProductTourTheme } from '../product-tour.interface';


/**------------------------------------------------------
 * Product Tour Dark Mode Theme Configurations
 */
export const NG_PRODUCT_TOUR_THEME_CONFIG: INgProductTourThemeConfigs = {
	[EnumNgProductTourTheme.Light] : {
		backgroundColor : '#000000b0',
		boxShadow		: 'rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgba(0, 0, 0, 0.69) 0px 0px 0px 5000000px !important'
	},
	[EnumNgProductTourTheme.Dark] : {
		backgroundColor : '#d0d0d06b',
		boxShadow		: 'rgb(179, 179, 179, 0.8) 0px 0px 1px 2px, rgb(208 208 208 / 42%) 0px 0px 0px 5000000px !important'
	},
	[EnumNgProductTourTheme.DimmedDark] : {
		backgroundColor : '#d0d0d06b',
		boxShadow		: 'rgb(179, 179, 179, 0.8) 0px 0px 1px 2px, rgb(208 208 208 / 42%) 0px 0px 0px 5000000px !important'
	}
};


//** Interfaces --------------------------------- */
export interface INgProductTourThemeConfigs {
	[EnumNgProductTourTheme.Light] 		: INgProductTourThemeConfig;
	[EnumNgProductTourTheme.Dark]  		: INgProductTourThemeConfig;
	[EnumNgProductTourTheme.DimmedDark] : INgProductTourThemeConfig;
}

export interface INgProductTourThemeConfig {
	backgroundColor : string;
	boxShadow		: string;
}
