import { EnumHttpStatus, IHttpStatusInfo } from './http-status.interface';


/**------------------------------------------------------
 * Http Status Configurations
 */
export const HTTP_STATUS_INFO: Record<EnumHttpStatus, IHttpStatusInfo> = {
	[EnumHttpStatus.Continue]: {
		code		: EnumHttpStatus.Continue,
		message 	: 'Continue',
		description : 'The client can continue with its request.'
	},
	[EnumHttpStatus.SwitchingProtocols]: {
		code		: EnumHttpStatus.SwitchingProtocols,
		message 	: 'Switching Protocols',
		description : `The server is switching protocols according to the client's request`
	},
	[EnumHttpStatus.Processing]: {
		code		: EnumHttpStatus.Processing,
		message 	: 'Processing',
		description : 'The server has received the request and is processing it, but no response is available yet.'
	},
	[EnumHttpStatus.EarlyHints]: {
		code		: EnumHttpStatus.EarlyHints,
		message 	: 'Early Hints',
		description : 'The server is sending a preliminary response before the final HTTP message to provide the client with information about what headers will be sent with the final response.'
	},
	[EnumHttpStatus.Ok]: {
		code		: EnumHttpStatus.Ok,
		message 	: 'OK',
		description : 'The request has succeeded.'
	},
	[EnumHttpStatus.Created]: {
		code		: EnumHttpStatus.Created,
		message 	: 'Created',
		description : 'The request has been fulfilled and a new resource has been created.'
	},
	[EnumHttpStatus.Accepted]: {
		code		: EnumHttpStatus.Accepted,
		message 	: 'Accepted',
		description : 'The request has been accepted for processing, but the processing has not been completed yet.'
	},
	[EnumHttpStatus.NonAuthoritative]: {
		code		: EnumHttpStatus.NonAuthoritative,
		message 	: 'Non Authoritative Information',
		description : 'The server is returning non-authoritative information.'
	},
	[EnumHttpStatus.NoContent]: {
		code		: EnumHttpStatus.NoContent,
		message 	: 'No Content',
		description : 'The server successfully processed the request but is not returning any content.'
	},
	[EnumHttpStatus.ResetContent]: {
		code		: EnumHttpStatus.ResetContent,
		message 	: 'Reset Content',
		description : 'The server instructs the client to reset the document from which the request was initiated.'
	},
	[EnumHttpStatus.PartialContent]: {
		code		: EnumHttpStatus.PartialContent,
		message 	: 'Partial Content',
		description : 'The server is delivering only part of the resource due to a range header sent by the client.'
	},
	[EnumHttpStatus.MultiStatus]: {
		code		: EnumHttpStatus.MultiStatus,
		message 	: 'Multi Status',
		description : 'The message body that follows is an XML message and can contain a number of separate response codes, depending on how many sub-requests were made.'
	},
	[EnumHttpStatus.AlreadyReported]: {
		code		: EnumHttpStatus.AlreadyReported,
		message 	: 'Already Reported',
		description : 'The members of a DAV binding have already been enumerated in a preceding part of the multi status response, and are not being included again.'
	},
	[EnumHttpStatus.MultipleChoices]: {
		code		: EnumHttpStatus.MultipleChoices,
		message 	: 'Multiple Choices',
		description : 'The request has more than one possible response.'
	},
	[EnumHttpStatus.MovedPermanently]: {
		code		: EnumHttpStatus.MovedPermanently,
		message 	: 'Moved Permanently',
		description : 'The requested resource has been permanently moved to a new location.'
	},
	[EnumHttpStatus.Found]: {
		code		: EnumHttpStatus.Found,
		message 	: 'Found',
		description : 'The requested resource temporarily resides under a different URI.'
	},
	[EnumHttpStatus.SeeOther]: {
		code		: EnumHttpStatus.SeeOther,
		message 	: 'See Other',
		description : 'The response to the request can be found under a different URI and should be retrieved using a GET method on that resource.'
	},
	[EnumHttpStatus.NotModified]: {
		code		: EnumHttpStatus.NotModified,
		message 	: 'Not Modified',
		description : 'The resource has not been modified since the last request.'
	},
	[EnumHttpStatus.RedirectUseProxy]: {
		code		: EnumHttpStatus.RedirectUseProxy,
		message 	: 'Use Proxy',
		description : 'The requested resource must be accessed through the proxy given by the location field.'
	},
	[EnumHttpStatus.TemporaryRedirect]: {
		code		: EnumHttpStatus.TemporaryRedirect,
		message 	: 'Temporary Redirect',
		description : 'The request should be repeated with another URI, but future requests should still use the original URI.'
	},
	[EnumHttpStatus.PermanentRedirect]: {
		code		: EnumHttpStatus.PermanentRedirect,
		message 	: 'Permanent Redirect',
		description : 'The request and all future requests should be repeated using another URI.'
	},
	[EnumHttpStatus.BadRequest]: {
		code		: EnumHttpStatus.BadRequest,
		message 	: 'Bad Request',
		description : 'The server cannot or will not process the request due to a client error.'
	},
	[EnumHttpStatus.Unauthorized]: {
		code		: EnumHttpStatus.Unauthorized,
		message 	: 'Unauthorized',
		description : 'Unauthorized access. Please provide valid credentials to proceed.'
	},
	[EnumHttpStatus.Forbidden]: {
		code		: EnumHttpStatus.Forbidden,
		message 	: 'Forbidden',
		description : 'The client does not have access rights to the content, so the server is refusing to give the requested resource.'
	},
	[EnumHttpStatus.NotFound]: {
		code		: EnumHttpStatus.NotFound,
		message 	: 'Not Found',
		description : 'The server cannot find the requested resource.'
	},
	[EnumHttpStatus.MethodNotAllowed]: {
		code		: EnumHttpStatus.MethodNotAllowed,
		message 	: 'Method Not Allowed',
		description : 'The method specified in the request is not allowed for the resource identified by the request URI.'
	},
	[EnumHttpStatus.NotAcceptable]: {
		code		: EnumHttpStatus.NotAcceptable,
		message 	: 'Not Acceptable',
		description : 'The resource identified by the request is only capable of generating response entities that have content characteristics not acceptable according to the accept headers sent in the request.'
	},
	[EnumHttpStatus.ProxyAuthenticationRequired]: {
		code		: EnumHttpStatus.ProxyAuthenticationRequired,
		message 	: 'Proxy Authentication Required',
		description : 'Proxy authentication required. Please authenticate with the proxy to continue.'
	},
	[EnumHttpStatus.RequestTimeout]: {
		code		: EnumHttpStatus.RequestTimeout,
		message 	: 'Request Timeout',
		description : 'The server timed out waiting for the request.'
	},
	[EnumHttpStatus.Conflict]: {
		code		: EnumHttpStatus.Conflict,
		message 	: 'Conflict',
		description : 'Indicates that the request could not be processed because of conflict in the request, such as an edit conflict.'
	},
	[EnumHttpStatus.Gone]: {
		code		: EnumHttpStatus.Gone,
		message 	: 'Gone',
		description : 'The requested resource is no longer available at the server and no forwarding address is known.'
	},
	[EnumHttpStatus.LengthRequired]: {
		code		: EnumHttpStatus.LengthRequired,
		message 	: 'Length Required',
		description : 'The server requires a content-length header in the request.'
	},
	[EnumHttpStatus.PreconditionFailed]: {
		code		: EnumHttpStatus.PreconditionFailed,
		message 	: 'Precondition Failed',
		description : 'The precondition given in one or more of the request-header fields evaluated to false when it was tested on the server.'
	},
	[EnumHttpStatus.PayloadTooLarge]: {
		code		: EnumHttpStatus.PayloadTooLarge,
		message 	: 'Payload Too Large',
		description : 'The request is larger than the server is willing or able to process.'
	},
	[EnumHttpStatus.UriTooLong]: {
		code		: EnumHttpStatus.UriTooLong,
		message 	: 'URI Too Long',
		description : 'The URI provided was too long for the server to process.'
	},
	[EnumHttpStatus.UnsupportedMediaType]: {
		code		: EnumHttpStatus.UnsupportedMediaType,
		message 	: 'Unsupported Media Type',
		description : 'The server is refusing to service the request because the entity of the request is in a format not supported by the requested resource for the requested method.'
	},
	[EnumHttpStatus.RequestedRangeNotSatisfiable]: {
		code		: EnumHttpStatus.RequestedRangeNotSatisfiable,
		message 	: 'Range Not Satisfiable',
		description : 'The server cannot provide the requested range.'
	},
	[EnumHttpStatus.ExpectationFailed]: {
		code		: EnumHttpStatus.ExpectationFailed,
		message 	: 'Expectation Failed',
		description : 'The server cannot meet the requirements of the Expect request-header field.'
	},
	[EnumHttpStatus.IAmATeapot]: {
		code		: EnumHttpStatus.IAmATeapot,
		message 	: "I'm a teapot",
		description : "This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol."
	},
	[EnumHttpStatus.Misdirected]: {
		code		: EnumHttpStatus.Misdirected,
		message 	: 'Misdirected Request',
		description : 'The request was directed at a server that is not able to produce a response.'
	},
	[EnumHttpStatus.UnprocessableEntity]: {
		code		: EnumHttpStatus.UnprocessableEntity,
		message 	: 'Unprocessable Entity',
		description : 'The request was well-formed but was unable to be followed due to semantic errors.'
	},
	[EnumHttpStatus.ClientErrorLocked]: {
		code		: EnumHttpStatus.ClientErrorLocked,
		message 	: 'Locked',
		description : 'The resource that is being accessed is locked.'
	},
	[EnumHttpStatus.FailedDependency]: {
		code		: EnumHttpStatus.FailedDependency,
		message 	: 'Failed Dependency',
		description : 'The request failed because it depended on another request and that request failed.'
	},
	[EnumHttpStatus.ClientErrorUpgradeRequired]: {
		code		: EnumHttpStatus.ClientErrorUpgradeRequired,
		message 	: 'Upgrade Required',
		description : 'The client should switch to a different protocol such as TLS/1.0.'
	},
	[EnumHttpStatus.PreconditionRequired]: {
		code		: EnumHttpStatus.PreconditionRequired,
		message 	: 'Precondition Required',
		description : 'The server requires the request to be conditional.'
	},
	[EnumHttpStatus.TooManyRequests]: {
		code		: EnumHttpStatus.TooManyRequests,
		message 	: 'Too Many Requests',
		description : 'The user has sent too many requests in a given amount of time.'
	},
	[EnumHttpStatus.HeaderFieldsTooLarge]: {
		code		: EnumHttpStatus.HeaderFieldsTooLarge,
		message 	: 'Request Header Fields Too Large',
		description : 'The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.'
	},
	[EnumHttpStatus.LoginTimeOut]: {
		code		: EnumHttpStatus.LoginTimeOut,
		message 	: 'Login Time-out',
		description : "The client's session has expired and must log in again."
	},
	[EnumHttpStatus.ErrorRetryWith]: {
		code		: EnumHttpStatus.ErrorRetryWith,
		message 	: 'Retry With',
		description : 'The server cannot honour the request because the user has changed their password or there is a password change required.'
	},
	[EnumHttpStatus.UnavailableForLegalReasons]: {
		code		: EnumHttpStatus.UnavailableForLegalReasons,
		message 	: 'Unavailable For Legal Reasons',
		description : 'A server operator has received a legal demand to deny access to a resource or to a set of resources that includes the requested resource.'
	},
	[EnumHttpStatus.InternalServerError]: {
		code		: EnumHttpStatus.InternalServerError,
		message 	: 'Internal Server Error',
		description : 'A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.'
	},
	[EnumHttpStatus.NotImplemented]: {
		code		: EnumHttpStatus.NotImplemented,
		message 	: 'Not Implemented',
		description : 'The server either does not recognize the request method or lacks the ability to fulfill the request.'
	},
	[EnumHttpStatus.BadGateway]: {
		code		: EnumHttpStatus.BadGateway,
		message 	: 'Bad Gateway',
		description : 'The server was acting as a gateway or proxy and received an invalid response from the upstream server.'
	},
	[EnumHttpStatus.ServiceUnavailable]: {
		code		: EnumHttpStatus.ServiceUnavailable,
		message 	: 'Service Unavailable',
		description : 'The server is currently unavailable (overloaded or down).'
	},
	[EnumHttpStatus.GatewayTimeout]: {
		code		: EnumHttpStatus.GatewayTimeout,
		message 	: 'Gateway Timeout',
		description : 'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.'
	},
	[EnumHttpStatus.HttpVersionNotSupported]: {
		code		: EnumHttpStatus.HttpVersionNotSupported,
		message 	: 'HTTP Version Not Supported',
		description : 'The server does not support the HTTP protocol version used in the request.'
	},
	[EnumHttpStatus.VariantAlsoNegotiates]: {
		code		: EnumHttpStatus.VariantAlsoNegotiates,
		message 	: 'Variant Also Negotiates',
		description : 'Transparent content negotiation for the request results in a circular reference.'
	},
	[EnumHttpStatus.InsufficientStorage]: {
		code		: EnumHttpStatus.InsufficientStorage,
		message 	: 'Insufficient Storage',
		description : 'The server is unable to store the representation needed to complete the request.'
	},
	[EnumHttpStatus.LoopDetected]: {
		code		: EnumHttpStatus.LoopDetected,
		message 	: 'Loop Detected',
		description : 'The server detected an infinite loop while processing the request.'
	},
	[EnumHttpStatus.BandwidthLimitExceeded]: {
		code		: EnumHttpStatus.BandwidthLimitExceeded,
		message 	: 'Bandwidth Limit Exceeded',
		description : 'The server has exceeded the bandwidth specified by the server administrator; this is often used by shared hosting providers.'
	},
	[EnumHttpStatus.NotExtended]: {
		code		: EnumHttpStatus.NotExtended,
		message 	: 'Not Extended',
		description : 'Further extensions to the request are required for the server to fulfill it.'
	},
	[EnumHttpStatus.NetworkAuthRequired]: {
		code		: EnumHttpStatus.NetworkAuthRequired,
		message 	: 'Network Authentication Required',
		description : 'The client needs to authenticate to gain network access.'
	}
};
