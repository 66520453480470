import { Inject, Injectable } from '@angular/core';
import { SecureTimeToken } from '@domains/security/shared';

import { EnumSecureServiceInjection, ISecureServicesConfig } from './security-services.config';


@Injectable()
export class SecureTimeTokenService {

	constructor(
		@Inject(EnumSecureServiceInjection.Config)
		private config		: ISecureServicesConfig,
		private timeToken	: SecureTimeToken
	) {}


	getTimeToken(): string {

		//0 - create the time token
		const offsetTimestamp: string = new Date(Date.now() + this.config.TIME_TOKEN.TIME_WINDOW).toUTCString();
		const offsetDate	 : Date   = new Date(offsetTimestamp);

		//1 - add the time token to the headers
		return this.timeToken.createTimeToken(this.config.TIME_TOKEN.SALT, offsetDate);
	}
}
