import { AbstractSearchLinks } from '../../../data/search-links.abstract';
import { ISearchParameterConfig } from '../../../data/search-links.interface';
import { EnumTeepublicProduct, EnumTeepublicSorting, ITeepublicSearchOptions, TEEPUBLIC_CONFIG, TEEPUBLIC_DEFAULT_OPTIONS } from './teepublic-search.data';


/**------------------------------------------------------
 * Teepublic Search Url
 */
export class TeepublicSearch extends AbstractSearchLinks<ITeepublicSearchOptions> {

	//** Helper Getters */
	get sortingList(): ISearchParameterConfig<EnumTeepublicSorting>[] {
		return TEEPUBLIC_CONFIG.sortings as ISearchParameterConfig<EnumTeepublicSorting>[];
	}

	get productList(): ISearchParameterConfig<EnumTeepublicProduct>[] {
		return TEEPUBLIC_CONFIG.products as ISearchParameterConfig<EnumTeepublicProduct>[];
	}

	constructor() {
		super(TEEPUBLIC_CONFIG, TEEPUBLIC_DEFAULT_OPTIONS);
	}


	//** Overwriting the Search */
	override search(searchQuery: string, options?: Partial<ITeepublicSearchOptions>): string {
		return super.search(searchQuery, options).replace(/%20/g, '+');
	}
}
