import { Util } from '@libs/utilities/util';
import { Crypto } from '@libs/utilities/crypto';

import { EnumSignatureAlgorithm, IJwtHeader, IJwtPayload, IJwtSignatureOptions } from './jwt-handler.interface';


export class JwtHandlerService {

	//** Configurations */
	private readonly MIN_SECRET_LENGTH: number = 32;
	private readonly EXPORT_PART_COUNT: number = 3;				// header + payload + signature = 3 parts


	createToken<T extends IJwtPayload>(payload: T, signOptions: IJwtSignatureOptions): string {

		//0 - validate the payload data
		if (signOptions.secret.length < this.MIN_SECRET_LENGTH) throw new Error(`JwtHandlerService => createToken => FATAL ERROR: the secret needs a minium length of ${this.MIN_SECRET_LENGTH} characters`);
		if (Util.String.isEmpty(payload.issuer))		throw new Error(`JwtHandlerService => createToken => FATAL ERROR: the issuer can not be empty`);
		if (Util.Number.isNotNumber(payload.issuedAt))	throw new Error(`JwtHandlerService => createToken => FATAL ERROR: the issuedAt is not a valid number`);
		if (Util.Number.isNotNumber(payload.expiresAt))	throw new Error(`JwtHandlerService => createToken => FATAL ERROR: the expiresAt is not a valid number`);

		//1 - create the signature by encoding the concatenated header and payload with the secret
		const encodedHeader : string = Util.String.toBase64Url(Util.Basic.stringifyObject<IJwtHeader>({ alg: signOptions.algorithm, typ: 'JWT' }));
		const encodedPayload: string = Util.String.toBase64Url(Util.Basic.stringifyObject(payload));
		const signature		: string = this.createSignature(`${encodedHeader}.${encodedPayload}`, signOptions);

		//2 - create the jst by concatenating the header, payload, and signature
		const encodedSignature: string = Util.String.toBase64Url(signature);
		const jsonWebToken    : string = `${encodedHeader}.${encodedPayload}.${encodedSignature}`;
		return jsonWebToken;
	}

	validateToken<T extends IJwtPayload>(token: string, issuer: string, signOptions: IJwtSignatureOptions): string[] {

		try {
			//0 - extract the content
			const validationErrors: string[] = [];
			if (token.split('.').length !== this.EXPORT_PART_COUNT) return [`Invalid format (does not have all parts)`];

			//1 - validate the signature
			const [headerBase64Url, payloadBase64Url, signatureBase64Url]: string[] = token.split('.');
			const signatureHex	  : string  = Util.String.fromBase64Url(signatureBase64Url);
			const isSignatureValid: boolean = this.verifySignature(`${headerBase64Url}.${payloadBase64Url}`, signatureHex, signOptions);
			if (!isSignatureValid) validationErrors.push(`Signature is invalid`);

			//2 - validate the header
			const header: IJwtHeader = Util.Basic.parseJson<IJwtHeader>(Util.String.fromBase64Url(headerBase64Url));
			if (header.alg as EnumSignatureAlgorithm !== signOptions.algorithm) validationErrors.push(`Invalid signature algorithm was used`);

			//3 - validate the payload
			const payload: T = Util.Basic.parseJson<T>(Util.String.fromBase64Url(payloadBase64Url));
			if (payload.issuer !== issuer) 		validationErrors.push(`Token issuer is not matching`);
			if (payload.issuedAt > Date.now())	validationErrors.push(`Token issue date can not be in future`);
			if (payload.expiresAt < Date.now())	validationErrors.push(`Token is expired`);

			//4 - return all validation errors
			return validationErrors;

		} catch (error) {
			return [`Unknown error at validation: ${error?.toString()}`];
		}
	}

	isExpired(token: string): boolean {

		//0 - extract the payload
		const payloadData: IJwtPayload = this.extractPayload(token);

		//1 - check if the token was expired
		const isExpired: boolean = payloadData.expiresAt < Date.now();
		return isExpired;
	}

	extractPayload<T extends IJwtPayload>(token: string): T {

		//0 - is the token valid?
		const payloadBase64: string   = token.split('.')?.[1];
		const payloadData  : T | null = Util.Basic.parseIfValidJson<T>(Util.String.fromBase64Url(payloadBase64));
		if (!payloadData) throw new Error(`JwtHandlerService => extractPayload => FATAL ERROR: the JWT has an invalid format, can not extract the payload`);

		//1 - return the payload
		return payloadData;
	}


	/**------------------------------------------------------
	 * Crate and Validate Signature
	 */
	private createSignature(tokenData: string, signOptions: IJwtSignatureOptions): string {
		switch (signOptions.algorithm) {

			//a. HS256 (HMAC with SHA-256)
			case EnumSignatureAlgorithm.HS256:
				return Crypto.SymmetricSignature.signHmacSha256(tokenData, signOptions.secret);

			//b. RS256 (RSA Signature with SHA-256)
			case EnumSignatureAlgorithm.RS256:
				return Crypto.AsymmetricSignature.signRsaPkcsSha256(tokenData, signOptions.secret);

			//c. unsupported
			default:
				throw new Error(`JwtHandlerService => createSignature => FATAL ERROR: signatureAlgorithm of "${signOptions.algorithm}" is not supported`);
		}
	}

	private verifySignature(tokenData: string, signature: string, signOptions: IJwtSignatureOptions): boolean {
		switch (signOptions.algorithm) {

			//a. HS256 (HMAC with SHA-256)
			case EnumSignatureAlgorithm.HS256:
				return Crypto.SymmetricSignature.verifyHmacSha256(tokenData, signature, signOptions.secret);

			//b. RS256 (RSA Signature with SHA-256)
			case EnumSignatureAlgorithm.RS256:
				return Crypto.AsymmetricSignature.verifyRsaPkcsSha256(tokenData, signature, signOptions.secret);

			//c. unsupported
			default:
				throw new Error(`JwtHandlerService => verifySignature => FATAL ERROR: signatureAlgorithm of "${signOptions.algorithm}" is not supported`);
		}
	}
}
