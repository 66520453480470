
import { Util } from '@libs/utilities/util';

import { IMailService, IReportMailOptions } from './mail-logger-report.interface';
import { ILogReport, EnumLogLevel, ILogHistoryEntry } from '../logger.interface';


/**------------------------------------------------------
 * Mailing the final Log Report
 */
export class MailLoggerReportService {

	constructor(
		private mailService: IMailService
	) {
		if (!this.mailService) throw new Error(`MailLoggerReportService => constructor => FATAL ERROR: injected mailService is undefined`);
	}


	//** Mail Statistics */
	mailReport(options: IReportMailOptions): void {

		//0 - generate the report text
		const reportText: string = this.createTextReport(options.heading, options.reportData);

		//1 - mail the report text
		this.mailService.sendMail({
			to			: options.mailTo,
			subject		: options.subject,
			body		: reportText
		});
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private createTextReport(heading: string, reportData: ILogReport): string {

		//0 - create the evaluation massage/info for the mail
		const reportText: string = `
		<h2>${heading}</h2>
		<hr>
		<h3>Statistics</h3>
		 • Error Rate: ${reportData[EnumLogLevel.Error].percent}% failed (${reportData[EnumLogLevel.Error].count} of ${reportData.totalLogs})<br>
		 • Warning Count: ${reportData[EnumLogLevel.Warning].percent}% failed (${reportData[EnumLogLevel.Warning].count} of ${reportData.totalLogs})
		<br><br><hr>
		<h3>Error Logs (errors: ${reportData[EnumLogLevel.Error].count})</h3>
		${reportData[EnumLogLevel.Error].history.map((entry: ILogHistoryEntry) => ` • ${entry.log}`).join('<br>')}
		<br><br><hr>
		<h3>Warning Logs (warnings: ${reportData[EnumLogLevel.Warning].count})</h3>
		${reportData[EnumLogLevel.Warning].history.map((entry: ILogHistoryEntry) => ` • ${entry.log}`).join('<br>')}
		`;

		//1 - remove unnecessary whitespaces and return the report text
		return Util.String.purifyWhitespaces(reportText);
	}
}
