import { EnumSubscriptionModule } from '../../../_access/subscriptions/module.constants';


/**------------------------------------------------------
 * Feature Definitions
 */
export enum EnumBestSellerFeatures {
	Search 				= 'best_sellers_search',
	AdvancedOptions 	= 'best_sellers_advanced_options',
	StatisticKeywords 	= 'best_sellers_statistic_keywords'
}

// TODO(Seshu, Tom) Check for limitations
export enum EnumArchiveFeatures {
	Search 				= 'archive_search',
	AdvancedOptions 	= 'archive_advanced_options',
	StatisticKeywords 	= 'archive_statistic_keywords'
}

export enum EnumNicheListFeatures {
	SearchInBestSeller 	= 'niche_list_search_in_best_Seller'
}

export enum EnumGetInspiredPodFeatures {
	SearchOptions 		= 'get_inspired_pod_search_options'
}

export enum EnumBrandsFeatures {
	Search 				= 'brands_search',
	SearchInBestSeller 	= 'brands_search_in_best_Seller'
}

export enum EnumFavoritesFeatures {
	AddFavorite 		= 'favorite_add'
}

export enum EnumDesignDetailsFeatures {
	Keywords 			= 'design_details_keywords',
	SimilarDesigns 		= 'design_details_similar_designs',
	SameBrand 			= 'design_details_statistic_keywords',
	Competition			= 'design_details_competition',
	TmListingCheck		= 'design_details_tm_listing_check',
	Charts				= 'design_details_charts'
}

export enum EnumCalendarFeatures {
	EventDetails 		= 'calendar_event_details',
	Compare 			= 'calendar_compare'
}


/**------------------------------------------------------
 * Limitations of the Research
 */
export const RESEARCH_LIMITATIONS: IResearchLimitations[] = [

	// ---------------------- free version
	{
		modules			: [EnumSubscriptionModule.FreeVersion],
		excludeResearch : {
			bestSeller 	 	: [EnumBestSellerFeatures.Search, EnumBestSellerFeatures.AdvancedOptions, EnumBestSellerFeatures.StatisticKeywords],
			archive 	 	: [EnumArchiveFeatures.Search, EnumArchiveFeatures.AdvancedOptions, EnumArchiveFeatures.StatisticKeywords],
			nicheList	 	: [EnumNicheListFeatures.SearchInBestSeller],
			getInspiredPod	: [EnumGetInspiredPodFeatures.SearchOptions],
			brands			: [EnumBrandsFeatures.Search, EnumBrandsFeatures.SearchInBestSeller],
			favorite	 	: [EnumFavoritesFeatures.AddFavorite],
			designDetails	: [
				EnumDesignDetailsFeatures.Keywords, EnumDesignDetailsFeatures.SimilarDesigns, EnumDesignDetailsFeatures.SameBrand,
				EnumDesignDetailsFeatures.Competition, EnumDesignDetailsFeatures.Charts, EnumDesignDetailsFeatures.TmListingCheck
			]
		},
		excludeEvent	: {
			calendar		: [EnumCalendarFeatures.EventDetails]
		}
	},

	// ---------------------- research module
	{
		modules			: [EnumSubscriptionModule.Research],
		excludeResearch : {
			bestSeller 	 	: [],
			archive 		: [],
			nicheList	 	: [],
			getInspiredPod	: [],
			brands			: [],
			favorite	 	: [],
			designDetails	: []
		},
		excludeEvent	: {
			calendar		: []
		}
	}
];


/**------------------------------------------------------
 * Interfaces Definitions
 */
export interface IResearchLimitations {
	modules			: EnumSubscriptionModule[];
	excludeResearch : {
		bestSeller 	 	: EnumBestSellerFeatures[];
		archive 	 	: EnumArchiveFeatures[];
		nicheList	 	: EnumNicheListFeatures[];
		getInspiredPod	: EnumGetInspiredPodFeatures[];
		brands			: EnumBrandsFeatures[];
		favorite	 	: EnumFavoritesFeatures[];
		designDetails	: EnumDesignDetailsFeatures[];
	};
	excludeEvent	: {
		calendar		: EnumCalendarFeatures[];
	};
}
