import { AfterViewInit, Component, isDevMode, OnInit, ViewContainerRef } from '@angular/core';
import { AddonDarkModeService } from '@domains/frontend/page-addons';
import { Util } from '@libs/utilities/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgMessageToastAssistant } from '@libs/frameworks/angular';
import { DeviceDetectionService } from '@libs/libraries/frontend';
import { JwtHandlerService } from '@libs/libraries/shared';
import { IUserAccessTokenPayload } from '@domains/user/shared';

import { AffiliateRootParamService } from './shared/guards/route-guards/affiliate-links/affiliate-root-param.service';
import { IUserInfo } from './requests/shared/user/user.request';
import { ThemeService } from './services/shared/helpers/theme/theme.service';
import { StatesService } from './services/shared/states/states.service';
import { LocalStorageService } from './services/shared/local-storage/local-storage.service';
import { ProviderHelperService } from './app.data';
import { ENVIRONMENT } from '../environments';


@Component({
	selector   	: 'app-root',
	templateUrl : './app.component.html',
	styleUrls	: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

	//** Configurations */
	private readonly DEFAULT_SYSTEM_THEME: boolean = ENVIRONMENT.FEATURE_FLAGS.DEFAULT_SYSTEM_THEME;
	private readonly AUTO_MODE_INFO_TIME : number  = 30000;

	//** Helper Variables */
	private userStateSubscription: Subject<any>  = new Subject<any>();
	title: string = 'Flying Research & Upload';

	constructor(
		private themeService			 : ThemeService,
		private statesService    		 : StatesService,
		private affiliateRootParamService: AffiliateRootParamService,
		private viewContainerRef 		 : ViewContainerRef,
		private providerHelperService	 : ProviderHelperService,
		private darkModeService			 : AddonDarkModeService,
		private messageToastAssistant	 : NgMessageToastAssistant,
		private deviceDetectionService	 : DeviceDetectionService,
		private localStorageService		 : LocalStorageService,
		private jwtHelper				 : JwtHandlerService
	) {
		// handle the legacy jwt token, it got upgraded on 09.11.2023
		this.handleLegacyAuthToken();
	}

	ngOnInit(): void {

		//0 - handle Affiliate route
		this.affiliateRootParamService.handleParams();

		//1 - set theme
		this.themeService.setColors();

		//2 - set dark mode service default
		this.applySystemTheme();

		//3 - console info message for chrome dev tools in production
		if (!isDevMode()) this.logUserInteractionWarning();
	}

	ngAfterViewInit(): void {

		// set the container reference to the product tour
		this.providerHelperService.initializeProductTour(this.viewContainerRef);
	}


	/**------------------------------------------------------
	 * System Theme Applied Helper
	 */
	private applySystemTheme() {

		//0 - check system theme feature flag & check if system theme enabled
		if (!this.DEFAULT_SYSTEM_THEME || !this.darkModeService.isAutoSystemThemeEnabled()) return;

		//1 - subscribe on system theme change
		this.deviceDetectionService.onSystemThemeChange(() => {
			if (this.darkModeService.isAutoSystemThemeEnabled()) this.darkModeService.applySystemTheme();
		});

		//2 - is the right theme already set?, if not: apply system theme
		if (this.darkModeService.isSystemThemeSet()) return;
		this.darkModeService.applySystemTheme();

		//3 - show toast confirmation (only if the user is on the home page, login, ...)
		this.messageToastAssistant.showDefault('System Theme Applied', 'Deactivate the auto theme with cancel')
			.addAction({
				label	: 'Cancel',
				execute	: () => {
					this.darkModeService.resetAndDisableSystemTheme();
				}
			})
			.closeAfter(this.AUTO_MODE_INFO_TIME);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private handleLegacyAuthToken() {

		//0 - is any token set?
		const token: string = this.localStorageService.app.getAccessToken();
		if (Util.String.isEmpty(token)) return;

		//1 - clear if it is legacy
		const data 		   : object  = this.jwtHelper.extractPayload<IUserAccessTokenPayload<object>>(token);
		const isLegacyToken: boolean = Object.keys(data).includes('access_rights');
		if (isLegacyToken) {
			console.warn('Legacy JWT token, clear auth token');
			this.localStorageService.app.setAccessToken('');
			this.localStorageService.app.setRefreshToken('');
		}
	}

	private logUserInteractionWarning() {
		this.statesService.user.getUserInfo().pipe(takeUntil(this.userStateSubscription)).subscribe((user: IUserInfo) => {

			//a. check for default value
			if (Util.String.isEmpty(user.firstname)) return;

			//b. log warning
			/* eslint-disable no-console */
			const userName: string = Util.String.firstCharUpper(user.firstname);
			console.log('%c_____________________________', 'color: red; font-size: 2em; font-weight: bold;');
			console.log(`%c⚠ Hold up ${userName} - are you sure about what you are doing here!`, 'color: red; font-size: 2em; font-weight: bold;');
			console.log(`%cInfo: The website is monitoring interactions for recognizing suspicious behavior. If your behavior is triggering a certain threshold you will be considered as a bot. This can result in an account ban or other legal consequences in exceptional cases. If your account has been baned please contact the support.`, 'color: orange; font-size: 1.2em;');
			console.log(`%cDev: Please note that only accounts having the developer flag are exceptions of these conditions (please double check your account for the developer flag).`, 'color: #969696; font-size: 1em; font-style: italic;');
			console.log(`%c\n“Powerful you have become, the dark side I sense in you ${userName}.” - Yoda`, 'color: #06ad00; font-size: 1.1em; font-family: Comic Sans MS; font-style: bold;');
			console.log('%c_____________________________\n\n', 'color: red; font-size: 2em; font-weight: bold;');

			//c. complete on logging
			this.userStateSubscription.next(null);
			this.userStateSubscription.complete();
		});
	}
}
