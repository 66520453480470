import { Subject } from 'rxjs';


/**------------------------------------------------------
 * Enum Definitions
 */
export enum EnumZipOutputEncoding {
	Base64			= 'base64',
	Uint8Array		= 'uint8array',
	Blob			= 'blob'
}


/**------------------------------------------------------
 * Type Definitions
 */
export type TypeZipInputType  = keyof IZipInputType;
export type TypeZipOutputType = keyof IZipOutputByType;


/**------------------------------------------------------
 * Interface Definitions
 */
export interface IZipExportFile<T extends TypeZipInputType> {
	path 			: string;
	data			: IZipInputType[T] | Promise<IZipInputType[T]>;
	options		   ?: IZipFileOptions;
}

export interface IZipInputType {
	base64			: string;
	blob			: Blob;
	// string		: string;
	// text			: string;
	// binarystring	: string;
	// array		: number[];
	// uint8array	: Uint8Array;
	// arraybuffer	: ArrayBuffer;
	// stream		: NodeJS.ReadableStream;
}

export interface IZipOutputByType {
	base64			: string;
	uint8array		: Uint8Array;
	blob			: Blob;
	// arraybuffer	: ArrayBuffer;
	// text			: string;
	// string		: string;
	// binarystring	: string;
	// array		: number[];
	// nodebuffer	: Buffer;
}

export interface IZipFileOptions {
	base64			?: boolean;
	binary			?: boolean;
	date			?: Date;
	optimizedBinaryString?: boolean;
	createFolders	?: boolean;
	dir				?: boolean;
	comment			?: string;
	compression		?: string;
	dosPermissions	?: number | null;
	unixPermissions	?: number | string | null;
}

export interface IZipCompressOptions {
	chunkSize		?: number;
	progress$		?: Subject<number>;
}
