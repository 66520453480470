import { AMAZON_CATEGORY_LIST, AMAZON_SORTING_LIST } from '../amazon-search.data';
import { EnumAmazonMarketplace, EnumAmazonProduct } from '../../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../../data/search-links.interface';


/**------------------------------------------------------
 * Product Configurations
 */
const AMAZON_PRODUCT_LIST_JP: ISearchParameterConfig<EnumAmazonProduct>[] = [
	{
		code		: EnumAmazonProduct.All,
		name		: 'All Products',
		parameters 	: {
			language	: 'en_GB'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=[SEARCH]&s=[SORTING]&page=[PAGE]&i=clothing&dc&hidden-keywords=Solid+colors%3A+100%25+Cotton%3B+Heather+Grey%3A+90%25+Cotton%2C+10%25+Polyester%3B+All+Other+Heathers%3A+Classic+Fit+-Sweatshirt
		code		: EnumAmazonProduct.Shirt,
		name		: 'T-Shirt',
		parameters 	: {
			language	: 'en_GB',
			i			: 'clothing',
			'hidden-keywords': 'Solid+colors%3A+100%25+Cotton%3B+Heather+Grey%3A+90%25+Cotton%2C+10%25+Polyester%3B+All+Other+Heathers%3A+Classic+Fit+-Sweatshirt'
		}
	},
	/* {
		code		: EnumAmazonProduct.PremiumShirt,
		name		: 'Premium T-Shirt',
		parameter 	: {
			language	: 'en_GB',
		},
	}, */
	{
		// https://www.amazon.co.uk/s?k=dog&hidden-keywords=Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+%22v-neck%22
		code		: EnumAmazonProduct.VNeck,
		name		: 'V-Neck',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+%22v-neck%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog&hidden-keywords=Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+%22tank+top%22
		code		: EnumAmazonProduct.TankTop,
		name		: 'Tank Top',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Lightweight%2C+Classic+fit%2C+Double-needle+sleeve+and+bottom+hem+%22tank+top%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog&hidden-keywords=Solid+colors%3A+100%25+Cotton%3B+Heather+Grey%3A+90%25+Cotton%2C+10%25+Polyester%3B+All+Other+Heathers%3A+50%25+Cotton%2C+50%25+Polyester+%22long+sleeve%22
		code		: EnumAmazonProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Solid+colors%3A+100%25+Cotton%3B+Heather+Grey%3A+90%25+Cotton%2C+10%25+Polyester%3B+All+Other+Heathers%3A+50%25+Cotton%2C+50%25+Polyester+%22long+sleeve%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog+raglon&i=clothing
		code		: EnumAmazonProduct.Raglan,
		name		: 'Raglan',
		queryOptions	: {
			prefix	: '',
			postfix	: '+raglon' // cspell:disable-line
		},
		parameters 	: {
			language	: 'en_GB',
			i			: 'clothing'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog&hidden-keywords=Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22Sweatshirt%22
		code		: EnumAmazonProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22Sweatshirt%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog&hidden-keywords=Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22hoodie%22
		code		: EnumAmazonProduct.Hoodie,
		name		: 'Hoodie',
		parameters 	: {
			language	: 'en_GB',
			'hidden-keywords': 'Solid+colors%3A+80%25+Cotton%2C+20%25+Polyester%3B+Heather+Grey%3A+78%25+Cotton%2C+22%25+Poly%3B+Dark+Heather%3A+50%25+Cotton%2C+50%25+Polyester+%22hoodie%22'
		}
	},
	{
		// https://www.amazon.co.uk/s?k=dog+PopSockets+Grip+and+Stand+for+Phones+and+Tablets&rh=p_76%3A419158031&dc&qid=1573821601&rnid=419157031
		code		: EnumAmazonProduct.PopSocket,
		name		: 'PopSocket',
		parameters 	: {
			language	: 'en_GB',
			rh			: 'p_76%3A419158031',
			dc			: '',
			qid			: '1573821601',
			rnid		: '419157031' // cspell:disable-line
		},
		queryOptions: {
			prefix		: '',
			postfix		: '+PopSockets+Grip+and+Stand+for+Phones+and+Tablets'
		}
	}
	/* {
		code		: EnumAmazonProduct.PhoneCase,
		name		: 'Phone Case',
		parameter 	: {
			language	: 'en_GB',
		},
	},
	{
		code		: EnumAmazonProduct.ToteBag,
		name		: 'Tote Bag',
		parameter 	: {
			language	: 'en_GB',
		},
	},
	{
		code		: EnumAmazonProduct.ThrowPillows,
		name		: 'Throw Pillows',
		parameter 	: {
			language	: 'en_GB',
		},
	},
	{
		code		: EnumAmazonProduct.KDP,
		name		: 'Kindle Book (KDP)',
		parameter 	: {
			language	: 'en_GB',
		},
	}, */
];


/**------------------------------------------------------
 * Spreadshirt Configuration
 */
export const AMAZON_CONFIG_JP: ISearchLinkConfig = {
	marketplace		: EnumAmazonMarketplace.JP,
	websiteUrl		: {
		home		: 'https://www.amazon.co.jp/',
		login		: 'https://www.amazon.co.jp/-/en/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fwww.amazon.co.jp%2F%3Fref_%3Dnav_signin&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=jpflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&',
		search		: 'https://www.amazon.co.jp/s?k=[SEARCH]&page=[PAGE]'
	},
	pageLimit		: 400,
	sortings		: AMAZON_SORTING_LIST,
	products		: AMAZON_PRODUCT_LIST_JP,
	category	    : AMAZON_CATEGORY_LIST,
	options 		: {
		emptySearch : true
	}
};
