/* cspell:ignore ＡＮＶＩＬＯＹ, ANVILOY */
import { UtilBasic } from './util-basic';
import { UtilUtf8Encoder } from './algorithms/utf8-encoder';
import { UtilUnicodeRegex } from './algorithms/unicode-regex';
import { UtilBase32Encoder } from './algorithms/base32-encoder';


/**------------------------------------------------------
 * String Utilities
 * ----------------
 * > Info: Containing all functionalities related to string
 * > operations. These are the basic string manipulations,
 * > the more abstract ones are located in the "util-text".
 * ----------------
 * Testing Conversions:
 * > Text Compare		: https://text-compare.com/
 * > Binary Converter	: https://www.rapidtables.com/convert/number/ascii-to-binary.html
 */
export class UtilString {

	//** Injections for Algorithm Extensions */
	private utf8Encoder	 : UtilUtf8Encoder	 = new UtilUtf8Encoder();
	private base32Encoder: UtilBase32Encoder = new UtilBase32Encoder(this.utf8Encoder);
	private unicodeRegex : UtilUnicodeRegex	 = new UtilUnicodeRegex();

	constructor(
		private utilBasic: UtilBasic
	) {}


	/**------------------------------------------------------
	 * Check String Type
	 */
	isString<T>(value: T): boolean {
		return typeof value === 'string' || value instanceof String;
	}
	isNotString<T>(value: T): boolean {
		return !this.isString(value);
	}
	areStrings<T>(values: T[]): boolean {

		//0 - check the function call
		if (!this.isArray(values)) throw new Error(`UtilString => areStrings => FATAL ERROR: provided parameter is not an array`);

		//1 - check if all elements in the array are of type string
		for (const value of values) {
			if (!this.isString(value)) return false;
		}
		return true;
	}


	/**------------------------------------------------------
	 * Check Data
	 */
	hasIgnoreCase(array: string[], elem: string): boolean {
		this.checkStrings([...array, elem]);
		for (const arrElem of array) {
			if (this.equalsIgnoreCase(arrElem, elem)) return true;
		}
		return false;
	}


	/**------------------------------------------------------
	 * Check for Empty string
	 */
	isEmpty(value: string | any): boolean {
		if (this.utilBasic.isUndefined(value) || !this.isString(value) || (value as string).trim().length === 0) return true;
		return false;
	}
	isNotEmpty(value: string | any): boolean {
		return !this.isEmpty(value);
	}

	areEmpty(values: string[]): boolean {

		//0 - check the function call
		this.checkStrings(values);
		if (!this.isArray(values)) throw new Error(`UtilString => areEmpty => FATAL ERROR: provided parameter is not an array`);

		//1 - check if any element in the array is not empty
		for (const value of values) {
			if (!this.isEmpty(value)) return false;
		}
		return true;
	}
	hasEmptyValues(values: string[]): boolean {

		//0 - check the function call
		this.checkStrings(values);
		if (!this.isArray(values)) throw new Error(`UtilString => hasEmptyValues => FATAL ERROR: provided parameter is not an array`);

		//1 - check if any element in the array is empty
		for (const value of values) {
			if (this.isEmpty(value)) return true;
		}
		return false;
	}


	/**------------------------------------------------------
	 * Check Upper / Lowercase
	 */
	isLowerCase(value: string): boolean {
		this.checkString(value);
		return value === value.toLowerCase();
	}
	isUpperCase(value: string): boolean {
		this.checkString(value);
		return value === value.toUpperCase();
	}


	/**------------------------------------------------------
	 * String Helpers
	 */
	firstCharUpper(word: string): string {
		this.checkString(word);
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}
	titleCase(text: string): string {
		this.checkString(text);
		return text.split(' ').map((elem: string) => this.firstCharUpper(elem)).join(' ');
	}
	dashCase(text: string): string {
		this.checkString(text);
		return text.toLowerCase().replace(/\s/g, '-');
	}
	sentenceCase(text: string): string {
		this.checkString(text);
		const result: string = this.toSentenceArray(text)
			.map((elem: string) => elem.charAt(0).toUpperCase() + elem.slice(1))
			.join(' ');
		return result;
	}
	truncateString(text: string, maxLength: number, postFix: string = '...'): string {

		//0 - check the values
		this.checkEmptyStrings([text, postFix]);
		if (maxLength <= postFix.length) throw new Error(`UtilString => truncateString => FATAL ERROR: maxLength is smaller/equal to postFix length (maxLength: "${maxLength}" / postFix: "${postFix.length}")`);

		//1 - is the text short enough?
		const maxTextLength: number = maxLength - postFix.length;
		if (text.length <= maxTextLength) return text;

		//2 - truncate the string
		return text.slice(0, maxTextLength) + postFix;
	}

	countSubString(text: string, subStr: string): number {
		return (text.match(new RegExp(subStr, 'ig')) || []).length;
	}


	/**------------------------------------------------------
	 * Mapping / Changing Array of String
	 */
	toLowerCaseArray(values: string[]): string[] {
		this.hasEmptyValues(values);
		return values.map((elem: string) => elem.toLowerCase());
	}
	toUpperCaseArray(values: string[]): string[] {
		this.hasEmptyValues(values);
		return values.map((elem: string) => elem.toUpperCase());
	}
	purifyTexts(values: string[]): string[] {
		this.hasEmptyValues(values);
		return values.map((elem: string) => this.purifyWhitespaces(elem.trim()));
	}


	/**------------------------------------------------------
	 * String Variable Name Conversions
	 */
	camelToSnakeCase(varName: string): string {
		return this.camelToVariableName(varName, '_');
	}
	camelToUnderscore(varName: string): string {
		return this.camelToVariableName(varName, '_');
	}
	camelToHyphen(varName: string): string {
		return this.camelToVariableName(varName, '-');
	}
	camelToVariableName(varName: string, separator: string): string {
		this.checkStrings([varName, separator]);
		const result: string = varName.replace(/([A-Z])/g, ' $1').trim();
		return result.split(' ').join(separator).toLowerCase();
	}


	/**------------------------------------------------------
	 * Compare & Includes as case insensitive
	 */
	equalsIgnoreCase(value1: string, value2: string): boolean {
		this.checkStrings([value1, value2]);
		return value1.trim().toLowerCase() === value2.trim().toLowerCase();
	}
	includesIgnoreCase(text: string, word: string): boolean {
		this.checkStrings([text, word]);
		return text.toLowerCase().includes(word.toLowerCase());
	}


	/**------------------------------------------------------
	 * Text to Sentence Array
	 */
	toSentenceArray(text: string): string[] {
		this.checkString(text);
		let result: string[] = text.match(/[^.!?]+[.!?]+/g)!;	// example: ['this is my text.', 'are you here?', 'no never!', 'this is regex.']
		if (result === null) return [];
		result = result.map((elem: string) => elem.trim())
						.filter((elem: string) => elem.length > 0);
		return result;
	}
	toTextArray(text: string): string[] {
		this.checkString(text);
		const result: string[] = text.split(/[.!?]/)			// example: [ 'this is my text', 'are you here', 'no never', 'this is regex' ]
			.map((elem: string) => elem.trim())
			.filter((elem: string) => elem.length > 0);
		return result;
	}
	toCharArray(text: string): string[] {
		this.checkString(text);
		return text.split('');
	}


	/**------------------------------------------------------
	 * Sort
	 */
	sortAsc(values: string[]): string[] {
		this.checkStrings(values);
		return values.sort((a: string, b: string) => a.localeCompare(b));
	}
	sortDesc(values: string[]): string[] {
		this.checkStrings(values);
		return values.sort((a: string, b: string) => b.localeCompare(a));
	}
	uniqueAndSortByOccurrence(values: string[]): string[] {

		//0 - find the counts using reduce
		const occurrenceCounts: any = values.reduce((object: any, value: string) => {
			object[value] = (object[value] || 0) + 1;
			return object;
		}, {});

		//1 - use the keys of the object to get all the values of the array & sort those keys by their counts
		const sortedValues: string[] = Object.keys(occurrenceCounts).sort((a: string, b: string) => occurrenceCounts[b] - occurrenceCounts[a]);
		return sortedValues;
	}


	/**------------------------------------------------------
	 * Basic Convert
	 */
	toString<T>(value: T): string {

		//0 - is the value null or undefined?
		if (this.utilBasic.isUndefined(value)) return '';

		//1 - convert the value to a string
		const stringValue: string = `${value}`;
		if (!this.isString(stringValue)) throw new Error(`UtilString => toString => FATAL ERROR: failed to convert the value of "${value}" to a string`);
		return stringValue.trim();
	}
	toLowerString<T>(value: T): string {
		return this.toString(value).toLowerCase();
	}
	toUpperString<T>(value: T): string {
		return this.toString(value).toUpperCase();
	}


	/**------------------------------------------------------
	 * Convert Hex/Strings
	 *  => source: https://stackoverflow.com/questions/21647928/javascript-unicode-string-to-hex
	 */
	isHex(stringValue: string): boolean {
		const hexColorRegex: RegExp = /^[A-Fa-f0-9]+$/;
		return hexColorRegex.test(stringValue);
	}
	toHex(stringValue: string): string {
		this.checkString(stringValue);
		return Buffer.from(stringValue, 'utf8').toString('hex');
	}
	fromHex(hexValue: string): string {
		this.checkString(hexValue);
		return Buffer.from(hexValue, 'hex').toString('utf8');
	}


	/**------------------------------------------------------
	 * Convert Binary/Strings
	 * => source: https://stackoverflow.com/questions/14430633/how-to-convert-text-to-binary-code-in-javascript/14430733
	 */
	isBinary(value: string): boolean {
		const binaryRegex: RegExp = /^[01]+$/;
		return binaryRegex.test(value);
	}
	toBinary(stringValue: string): string {
		return this.toBinaryArray(stringValue).join('');
	}
	fromBinary(binaryValues: string, chunkSize: number = 8): string {
		const binaryArray: string[] | null = binaryValues.match(new RegExp(`.{1,${chunkSize}}`, 'g'));
		if (!binaryArray) throw new Error(`UtilString => fromBinary => FATAL ERROR: value of "${binaryValues}" is not a binary string`);
		return this.fromBinaryArray(binaryArray);
	}

	toBinaryArray(stringValue: string): string[] {

		//0 - check the input
		this.checkString(stringValue);

		//1 - try to convert the text to binary
		const binaryValues: string[] = [];
		for (const char of stringValue.split('')) {
			const bin: string = char.charCodeAt(0).toString(2);
			binaryValues.push(Array(8 - bin.length + 1).join('0') + bin);
		}
		return binaryValues;
	}
	fromBinaryArray(binaryValues: string[]): string {

		//0 - check the input
		this.checkStrings(binaryValues);

		//1 - try to convert the binary to text
		let stringValues: string = '';
		for (const binaryValue of binaryValues) {
			stringValues += String.fromCharCode(parseInt(binaryValue, 2));
		}
		return stringValues;
	}


	/**------------------------------------------------------
	 * Convert Base64/Strings
	 * > source: https://stackoverflow.com/questions/41972330/base-64-encode-and-decode-a-string-in-angular-2
	 */
	isBase64(base64Value: string): boolean {
		// const base64Regex: RegExp = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
		return base64Value === this.toBase64(this.fromBase64(base64Value));
	}

	toBase64(stringValue: string): string {
		this.checkString(stringValue);
		if (this.utilBasic.isBrowser()) return btoa(stringValue);
		return Buffer.from(stringValue).toString('base64');
	}

	fromBase64(base64Value: string): string {

		//0 - do we have valid base64?
		// this.checkString(base64Value);
		// if (!this.isBase64(base64Value)) throw new Error(`UtilString => fromBase64 => FATAL ERROR: provided base64Value of "${base64Value}" is not a valid base64 string`);

		//1 - convert base64 back to normal string
		if (this.utilBasic.isBrowser()) return atob(base64Value);
		return Buffer.from(base64Value, 'base64').toString();
	}

	/**------------------------------------------------------
	 * Convert Base32/Strings
	 */
	isBase32(base32Value: string): boolean {
		const base32Regex: RegExp = /^[A-Z2-7]+={0,6}$/;		// base32 regex pattern (standard Base32, excludes 0, 1, 8, 9, and lowercase)
		return base32Regex.test(base32Value);
	}

	toBase32(stringValue: string): string {
		this.checkString(stringValue);
		return this.base32Encoder.toBase32(stringValue);
	}

	fromBase32(base32Value: string): string {
		return this.base32Encoder.fromBase32(base32Value);
	}

	fromBase32ToUint8Array(base32Value: string): Uint8Array {
		return this.base32Encoder.fromBase32ToUint8Array(base32Value);
	}


	/**------------------------------------------------------
	 * Convert Base64Uri Encoding
	 */
	isBase64Uri(base64UriValue: string): boolean {
		const base64UriRegex: RegExp = /^(data:[\w\W]+;base64,)[\w+/=]+$/;
		return base64UriRegex.test(base64UriValue);
	}


	/**------------------------------------------------------
	 * Convert Base64UrlEncoding
	 */
	isBase64Url(base64UrlValue: string): boolean {
		const base64UrlRegex: RegExp = /^(?:[A-Za-z0-9-_]{4})*(?:[A-Za-z0-9-_]{2}(==)?|[A-Za-z0-9-_]{3}=?)?$/;
		return base64UrlRegex.test(base64UrlValue);
	}

	toBase64Url(stringValue: string): string {
		return this.toBase64(stringValue)
			.replace('+', '-')
			.replace('/', '_')
			.replace(/=+$/, '');
	}

	fromBase64Url(base64UrlValue: string): string {

		//0 - do we have valid base64?
		this.checkString(base64UrlValue);
		if (!this.isBase64Url(base64UrlValue)) throw new Error(`UtilString => fromBase64Url => FATAL ERROR: provided base64Value of "${base64UrlValue}" is not a valid base64Url string`);

		//1 - pad with '=' to make the string length a multiple of 4
		while (base64UrlValue.length % 4) base64UrlValue += '=';
		const base64Data: string = base64UrlValue
			.replace(/-/g, '+')
			.replace(/_/g, '/');

		//2 - convert it back
		return this.fromBase64(base64Data);
	}


	/**------------------------------------------------------
	 * Encode / Decode UTF-8
	 * >
	 */
	decodeUtf8(byteString: string): string {
		return this.utf8Encoder.decode(byteString);
	}
	decodeUtf8FromUint8Array(uint8Array: Uint8Array): string {
		return this.utf8Encoder.decodeFromUint8Array(uint8Array);
	}
	encodeUtf8(text: string): string {
		return this.utf8Encoder.encode(text);
	}
	encodeUtf8ToUint8Array(text: string): Uint8Array {
		return this.utf8Encoder.encodeToUint8Array(text);
	}


	/**------------------------------------------------------
	 * Functionalities
	 */
	reverse(stringValue: string): string {
		this.checkString(stringValue);
		return [...stringValue].reverse().join('');
	}

	//** Extract all Numbers from String */
	extractNumbers(stringValue: string): string {
		this.checkString(stringValue);
		return stringValue.replace(/[^0-9]/g, '');
	}


	/**------------------------------------------------------
	 * Check for Special Chars
	 */
	hasNonAscii(text: string): boolean {
		this.checkString(text);
		return /[^\x20-\x7E]/g.test(text);
	}
	hasNonNumeric(text: string): boolean {
		this.checkString(text);
		return /[^0-9 ]/gi.test(text);
	}
	hasNonAlphabetic(text: string): boolean {
		this.checkString(text);
		return /[^a-z ]/gi.test(text);
	}
	hasNonAlphanumeric(text: string): boolean {
		this.checkString(text);
		return /[^a-z0-9 ]/gi.test(text);
	}
	hasBrackets(text: string): boolean {
		this.checkString(text);
		return /[{()}]/g.test(text);
	}


	/**------------------------------------------------------
	 * Remove Special Chars
	 */
	hasOnlyLatinCharacters(text: string): boolean {
		return !this.hasNonLatinCharacters(text);
	}
	hasNonLatinCharacters(text: string): boolean {
		return /[^\u0000-\u007F]/.test(text); // eslint-disable-line no-control-regex
	}

	removeSpecialCharacters(text: string): string {
		return this.unicodeRegex.removeSpecialCharacters(text);
	}

	//** Numeric Text */
	removeNonNumeric(text: string): string {
		return this.unicodeRegex.removeNonNumeric(text);
	}
	removeNumbers(text: string): string {
		return this.unicodeRegex.removeNumbers(text);
	}

	//** Alphabetic */
	removeNonAlphabetic(text: string): string {
		return this.unicodeRegex.removeNonAlphabetic(text);
	}
	removeAlphabetic(text: string): string {
		return this.unicodeRegex.removeAlphabetic(text);
	}

	//** Alphanumeric */
	removeNonAscii(text: string): string {
		return this.unicodeRegex.removeNonAscii(text);
	}
	removeNonAlphanumeric(text: string, exceptionCharacters: string = ''): string {
		return this.unicodeRegex.removeNonAlphanumeric(text, exceptionCharacters);
	}
	removeAlphanumeric(text: string): string {
		return this.unicodeRegex.removeAlphanumeric(text);
	}

	//** Whitespaces */
	removeWhitespaces(text: string): string {
		return this.unicodeRegex.removeWhitespaces(text);
	}
	purifyWhitespaces(text: string): string {
		return this.unicodeRegex.purifyWhitespaces(text);
	}

	//** Symbols & Others */
	removeSymbols(text: string): string {
		return this.unicodeRegex.removeSymbols(text);
	}
	removeBrackets(text: string): string {
		this.checkString(text);
		return text.replace(/[{()}]/g, '');
	}


	/**------------------------------------------------------
	 * Split Strings
	 */
	splitByFirst(text: string, splitChar: string): IUtilSplitPair {

		//0 - check the function call
		this.checkStrings([text, splitChar]);
		if (!text.includes(splitChar)) throw new Error(`UtilString => splitLast => FATAL ERROR: the provided text does not contain the splitChar (text: "${text}" / splitChar: "${splitChar}")`);

		//1 - split string by the first occurrence
		const lastIndex: number = text.indexOf(splitChar);
		const splitPair: IUtilSplitPair = {
			first  	: text.substring(0, lastIndex),
			second 	: text.substring(lastIndex + splitChar.length)
		};
		return splitPair;
	}
	splitByLast(text: string, splitChar: string): IUtilSplitPair {

		//0 - check the function call
		this.checkStrings([text, splitChar]);
		if (!text.includes(splitChar)) throw new Error(`UtilString => splitLast => FATAL ERROR: the provided text does not contain the splitChar (text: "${text}" / splitChar: "${splitChar}")`);

		//1 - split string by the last occurrence
		const lastIndex: number = text.lastIndexOf(splitChar);
		const splitPair: IUtilSplitPair = {
			first  	: text.substring(0, lastIndex),
			second 	: text.substring(lastIndex + splitChar.length)
		};
		return splitPair;
	}


	/**------------------------------------------------------
	 * String Replace
	 */
	replaceLast(text: string, oldStr: string, newStr: string): string {
		const lastIndex: number = text.lastIndexOf(oldStr);
		if (!text.includes(oldStr)) throw new Error(`UtilString => replaceLast => FATAL ERROR: oldStr of "${oldStr}" is not in the text of "${text}"`);
		return text.substring(0, lastIndex) + newStr + text.substring(lastIndex + 1);
	}


	/**------------------------------------------------------
	 * Texts Filters
	 */
	removeDateFromString(text: string): string {
		this.checkString(text);
		return text.replace(/(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}/g, '')	// remove DD-MM-YYYY
					.replace(/\d{2,4}[-|.|/]\d{1,2}[-|.|/]\d{1,2}/g, '');						// remove YYYY-MM-DD
	}
	removeLeadingNumbers(text: string): string {
		this.checkString(text);
		return text.replace(/^[0-9]+/g, '').trim();
	}
	removeTextDividers(text: string): string {
		this.checkString(text);
		return text.replace(/(\s+|_|-|\.)/g, ' ').trim();
	}
	normalizeLineEndings(text: string, normalized: string = '\r\n'): string {
		return text.replace(/\r?\n/g, normalized);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	stringifyPrimitive(value: string | number | boolean): string {
		switch (typeof value) {
			case 'string':
				return value;
			case 'boolean':
				return value ? 'true' : 'false';
			case 'number':
				return isFinite(value) ? String(value) : '';
			default:
				return '';
		}
	}

	addLeadingZeros(value: number | string, digitCount: number): string {
		return `00000000000000000000${value}`.slice(-digitCount);
	}

	truncate(text: string, maxLength: number): string {
		this.checkString(text);
		if (maxLength < 0) throw new Error(`UtilString => truncateString => FATAL ERROR: provided max length of "${maxLength}" is negative`);
		if (text.length > maxLength) return text.slice(0, maxLength);
		return text;
	}

	setCharAt(text: string, index: number, char: string): string {

		//0 - validate index anc char
		if (text.length <= index) throw new Error(`UtilString => setCharAt => FATAL ERROR: index of "${index}" is too large for the text of length ${text.length}`);
		if (char.length !== 1)    throw new Error(`UtilString => setCharAt => FATAL ERROR: provided char of "${char}" is not a single character`);

		//1 - set the char
		const charArray: string[] = text.split('');
		charArray[index] = char;
		return charArray.join('');
	}


	/**------------------------------------------------------
	 * Masking a String
	 * > source  : https://decipher.dev/30-seconds-of-typescript/docs/mask/
	 * > example : 1234567890 => '*******890' (with showLast of 3)
	 */
	mask(text: string, showLast: number = 4, mask: string = '*'): string {

		//0 - check the parameters
		if (mask.length !== 1) 	    throw new Error(`UtilString => mask => FATAL ERROR: mask can only be a single char (example: '*')`);
		if (text.length < showLast) throw new Error(`UtilString => mask => FATAL ERROR: text is shorter then showLast, by doing so nothing wold be masked (text.length: "${text.length}" / showLast: "${showLast}")`);

		//1 - mask the string
		return text.slice(-showLast).padStart(text.length, mask);
	}


	/**------------------------------------------------------
	 * ASCII Formatting
	 * > Converts full-width ASCII characters to their regular ASCII
	 * > counterparts while preserving other characters.
	 * > Full-width characters, such as ＡＮＶＩＬＯＹ, are transformed
	 * > to their equivalent ASCII characters: ANVILOY.
	 * > The function replaces full-width characters with their ASCII
	 * > counterparts using Unicode code point manipulation.
	 * > Non-full-width characters are left unchanged.
	 */
	convertFullWidthToAscii(text: string): string {
		return text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (char: string) => {
			const code: number = char.charCodeAt(0);
			if (code >= 65281 && code <= 65374) {
				return String.fromCharCode(code - 65248);
			}
			return char;
		});
	}


	/**------------------------------------------------------
	 * Array String helpers
	 */
	filterEmpty(array: string[]): string[] {
		if (!this.isArray(array)) 	 throw new Error(`UtilString => removeEmpty => FATAL ERROR: provided parameter is not an array`);
		if (!this.areStrings(array)) throw new Error(`UtilString => removeEmpty => FATAL ERROR: elements inside the array are not all strings (array: ${array})`);
		return array.map((elem: string) => elem.trim()).filter((elem: string) => !this.isEmpty(elem));
	}


	/**------------------------------------------------------
	 * Helper Validation Checks
	 */
	checkString<T>(text: T): void {
		this.checkStrings([text]);
	}
	checkStrings<T>(texts: T[]): void {
		for (const text of texts) {
			if (!this.isString(text)) throw new Error(`UtilString => checkStrings => FATAL ERROR: provided text of "${text}" is not a valid string`);
		}
	}
	checkEmptyStrings<T>(texts: T[]): void {
		for (const text of texts) {
			if (this.isEmpty(text)) throw new Error(`UtilString => checkEmptyStrings => FATAL ERROR: provided text of "${text}" is empty`);
		}
	}

	private isArray(array: any): boolean {
		return Boolean(array) && array.constructor === Array;			// duplication of the array util functionality
	}
}


//** Interfaces --------------------------------- */
export interface IUtilSplitPair {
	first  : string;
	second : string;
}
