export * from './trademarks/watchlist.interface';
export * from './trademarks/whitelist.interface';
export * from './trademarks/blacklist.interface';
export * from './settings/upload-settings.interface';
export * from './settings/research-settings.interface';
export * from './upload/ai-template.interface';
export * from './ai-research/niches.interface';
export * from './ai-research/quotes.interface';
export * from './upload/design/design.interface';
export * from './upload/design/design-image.interface';
export * from './upload/design/design-listing-global.interface';
export * from './upload/design/design-listing-mba.interface';
export * from './upload/design/design-listing-displate.interface';
export * from './upload/design/design-upload.interface';
export * from './upload/free-uploads.interface';
export * from './auth/auth.interface';
