import { UtilArray } from './util-array';
import { UtilString } from './util-string';
import { UtilText } from './util-text';


/**------------------------------------------------------
 * Keyword Utilities
 * -----------------
 * > Info: Containing all functionalities related tags and
 * > keywords.
 */
export class UtilKeywords {

	constructor(
		private utilString	: UtilString,
		private utilArray	: UtilArray,
		private utilText	: UtilText
	) {}


	/**------------------------------------------------------
	 * Extract Tags from Text
	 */
	extractFromText(text: string, thresholdCount: number = 2, thresholdLength: number = 8): string[] {

		//0 - check function call
		if (this.utilString.isEmpty(text)) 	throw new Error(`UtilKeywords => extractFromText => FATAL ERROR: provided text of "${text}" is empty`);
		if (thresholdCount < 1) 			throw new Error(`UtilKeywords => extractFromText => FATAL ERROR: provided thresholdCount of "${thresholdCount}" is smaller 1`);
		if (thresholdLength < 1) 			throw new Error(`UtilKeywords => extractFromText => FATAL ERROR: provided thresholdLength of "${thresholdLength}" is smaller 1`);

		//1 - count every single word
		const wordsList: any = {};
		for (let word of text.split(' ')) {

			//a. is the word empty?
			word = word.replace('.', '').replace(',', '').replace('!', '').trim();
			if (this.utilString.isEmpty(word)) continue;

			//b. if not empty add it to the word list
			wordsList[word] = wordsList[word] ? ++wordsList[word] : 1;
		}

		//2 - check which words fulfill the criteria
		const keywords: string[] = [];
		for (const word of Object.keys(wordsList)) {
			const count: number = wordsList[word];
			if (count >= thresholdCount && word.length >= thresholdLength) keywords.push(word);
		}

		return keywords;
	}

	getKeywordsFromText(text: string, splitExp: string | RegExp = /(?:,|;|:|\?|!|\n)+/): string[] {

		//0 - try to split text into words
		const keywordSplit: string[] = text.split(splitExp);
		if (this.utilArray.isEmpty(keywordSplit)) return [];

		//1 - cleanup the words
		return this.utilText.cleanTexts(keywordSplit);
	}

	getWordsFromText(text: string, splitExp: string | RegExp = /(?:,|;|:|\?|!|\.|\n|\s)+/): string[] {

		//0 - try to split text into words
		const wordSplit: string[] = text.split(splitExp);
		if (this.utilArray.isEmpty(wordSplit)) return [];

		//1 - cleanup the words
		return this.utilText.cleanTexts(wordSplit);
	}


	/**------------------------------------------------------
	 * Cleaning Helpers
	 */
	shortTailKeywords(keywords: string[]): string[] {
		return this.utilText.cleanTexts(keywords).filter((elem: string) => elem.split(' ').length === 1);
	}

	longTailKeywords(keywords: string[]): string[] {
		return this.utilText.cleanTexts(keywords).filter((elem: string) => elem.split(' ').length > 1);
	}


	/**------------------------------------------------------
	 * Filter
	 */
	filterByMinLength(tags: string[], minTagLength: number): string[] {
		return this.filterByLength(tags, minTagLength, Number.MAX_SAFE_INTEGER);
	}
	filterByMaxLength(tags: string[], maxTagLength: number): string[] {
		return this.filterByLength(tags, 0, maxTagLength);
	}
	filterByLength(tags: string[], minTagLength: number, maxTagLength: number): string[] {

		//0 - check function call
		if (!this.utilString.areStrings(tags))  throw new Error(`UtilKeywords => filterByLength => FATAL ERROR: some of the provided tags are not of type string (values: ${tags})`);
		if (minTagLength < 0) 					throw new Error(`UtilKeywords => filterByLength => FATAL ERROR: minTagLength is smaller then 0 (value: ${minTagLength})`);
		if (maxTagLength < 0) 					throw new Error(`UtilKeywords => filterByLength => FATAL ERROR: maxTagLength is smaller then 0 (value: ${maxTagLength})`);
		if (minTagLength > maxTagLength) 		throw new Error(`UtilKeywords => filterByLength => FATAL ERROR: minTagLength is larger then maxTagLength (${minTagLength} > ${maxTagLength})`);

		//1 - filter the values
		return tags.filter((tag: string) => tag.length >= minTagLength && tag.length <= maxTagLength);
	}


	/**------------------------------------------------------
	 * Sort
	 */
	sortByOccurrenceAsc(tags: string[], caseInsensitive: boolean = true): string[] {
		return this.sortByOccurrenceDesc(tags, caseInsensitive).reverse();
	}
	sortByOccurrenceDesc(tags: string[], caseInsensitive: boolean = true): string[] {

		//0 - check the function call
		if (!this.utilString.areStrings(tags) || tags.length === 0) throw new Error(`UtilKeywords => sortByOccurrence => FATAL ERROR: tags are not an array or empty (provided tags: ${tags})`);

		//1 - count the occurrences
		const mappedTags: any = {};
		for (let tag of tags) {
			if (caseInsensitive) tag = tag.toLowerCase();
			mappedTags[tag] = mappedTags[tag] ? mappedTags[tag] + 1 : 1;
		}

		//2 - return the result
		return Object.keys(mappedTags).sort((a: string, b: string) => mappedTags[b] - mappedTags[a]);
	}

	sortByLengthAsc(tags: string[]): string[] {
		if (!this.utilString.areStrings(tags) || tags.length === 0) throw new Error(`UtilKeywords => sortByLengthAsc => FATAL ERROR: tags are not an array or empty (provided tags: ${tags})`);
		return tags.sort((a: string, b: string) => a.length - b.length);
	}
	sortByLengthDesc(tags: string[]): string[] {
		if (!this.utilString.areStrings(tags) || tags.length === 0) throw new Error(`UtilKeywords => sortByLengthDesc => FATAL ERROR: tags are not an array or empty (provided tags: ${tags})`);
		return tags.sort((a: string, b: string) => b.length - a.length);
	}

	shuffle(tags: string[]): string[] {
		if (!this.utilString.areStrings(tags) || tags.length === 0) throw new Error(`UtilKeywords => shuffle => FATAL ERROR: tags are not an array or empty (provided tags: ${tags})`);
		return this.utilArray.shuffle(tags);
	}


	/**------------------------------------------------------
	 * Get Infos
	 */

	//** Get the max length of longest keywords */
	marginalLength(tags: string[]): IUtilMarginalLength {

		//0 - check the function call
		if (!this.utilString.areStrings(tags) || tags.length === 0) throw new Error(`UtilKeywords => marginalLength => FATAL ERROR: tags are not an array or empty (provided tags: ${tags})`);

		//1 - get the min/max length of all keywords
		let maxLength: number = -1;
		let minLength: number = Number.MAX_SAFE_INTEGER;
		for (const tag of tags) {
			if (tag.length < minLength) minLength = tag.length;
			if (tag.length > maxLength) maxLength = tag.length;
		}

		//2 - return the keywords info
		const result: IUtilMarginalLength = {
			min : minLength,
			max : maxLength
		};
		return result;
	}


	/**------------------------------------------------------
	 * Formatting
	 */

	//** Get array of keywords as a string */
	arrayAsString(keywords: string[], spaceChar: string = ', '): string {

		//0 - check the function call
		if (!this.utilString.areStrings(keywords) || keywords.length === 0) throw new Error(`UtilKeywords => arrayAsString => FATAL ERROR: keywords are not an array or empty`);
		if (!this.utilString.isString(spaceChar)) throw new Error(`UtilKeywords => arrayAsString => FATAL ERROR: spaceChar of "${spaceChar}" is not a valid string/char`);

		//1 - create keyword string
		let keywordString: string = '';
		for (let i: number = 0; i < keywords.length; ++i) {
			keywordString += keywords[i] + (i !== keywords.length - 1 ? spaceChar : '');
		}
		return keywordString;
	}

	stringToArray(text: string, splitChar: string): string[] {
		const keywords: string[] = [];

		//0 - check for wrong function call
		if (this.utilString.isEmpty(text)) 	 	throw new Error(`UtilKeywords => stringToArray => FATAL ERROR: text is empty or not defined`);
		if (this.utilString.isEmpty(splitChar)) throw new Error(`UtilKeywords => stringToArray => FATAL ERROR: splitChar is empty or not defined`);

		//1 - purify text (remove new line, ... and make to lower case)
		const textPurified: string = text.replace(/\r?\n/g, '').toLocaleLowerCase();

		//2 - extract the keywords from the text
		for (const elem of textPurified.split(splitChar)) {
			if (this.utilString.isEmpty(elem)) continue;
			keywords.push(elem.trim());
		}

		return keywords;
	}

	purifyKeywords(keywords: string[]): string[] {

		//0 - check the function call
		if (!this.utilString.areStrings(keywords)) throw new Error(`UtilKeywords => purifyKeywords => FATAL ERROR: keywords are not an array or not string`);

		//1 - trim all keywords & filter out empty keywords
		return keywords.map((word: string) => word.trim().toLowerCase())
						.filter((word: string) => !this.utilString.isEmpty(word));
	}


	/**------------------------------------------------------
	 * Randomize Keyword Array
	 */
	randomizeNonDeterministic(tags: string[]): string[] {

		//0 - check the function call
		if (!this.utilString.areStrings(tags) || tags.length === 0) throw new Error(`UtilKeywords => randomizeKeywords => FATAL ERROR: tags are not an array or empty (provided tags: ${tags})`);

		//1 - give all keywords a rating from 0.1 - 0.6
		const mappedTags: any = {};
		const tagsCount: number = tags.length;
		for (const [i, tag] of tags.entries()) {
			mappedTags[tag] = (1 / (tagsCount * 2)) * (i + 1) + 0.1;
		}

		//2 - delete some of the keywords by random
		for (const tag of Object.keys(mappedTags)) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			if (Math.random() < mappedTags[tag]) delete mappedTags[tag];
		}

		//3 - swap by a random number
		let keyword: string[] = Object.keys(mappedTags);
		const swapIterations: number = (Math.floor(Math.random() * 10) * 2) + 5;
		for (let i: number = 0; i < swapIterations; ++i) {
			// Pick a random index
			const indexA : number = Math.floor(Math.random() * keyword.length);
			const indexB : number = Math.floor(Math.random() * keyword.length);
			const swapVar: string = keyword[indexA];
			keyword[indexA] = keyword[indexB];
			keyword[indexB] = swapVar;
		}
		keyword = keyword.filter((elem: string) => elem);
		return keyword;
	}
}


//** Interfaces --------------------------------- */
interface IUtilMarginalLength {
	min : number;
	max : number;
}
