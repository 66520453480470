/**------------------------------------------------------
 * Interface Definitions
 */
export interface IFlyingFreeUploads {
	userEmail	: string;
	month		: number;
	countPod	: number;
	countKdp	: number;
	countStock	: number;
}
