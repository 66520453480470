import { Component, HostListener, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Util } from '@libs/utilities/util';
import { IKeyCode, KeyboardMouseService } from '@libs/libraries/frontend';

import { EnumNgTemplateAction } from './template-modal.data';
import { EnumNgTemplateSize, INgTemplateModalButton, INgTemplateModalConfig, INgTemplateModalContent } from './template-modal.interface';


@Component({
	selector	: 'ng-template-modal',
	templateUrl	: './template-modal.component.html',
	styleUrls	: ['./template-modal.component.scss']
})
export class NgTemplateModalComponent {

	//** Template Modal Object */
	@Input() modalConfig : INgTemplateModalConfig | null  = null;
	@Input() modalContent: INgTemplateModalContent | null = null;
	@Input() modalRef 	!: NgbModalRef;

	//** Getter Helpers */
	readonly EnumNgTemplateSize: typeof EnumNgTemplateSize = EnumNgTemplateSize;

	//** Handle the Events */
	private readonly actionEvent$: Subject<EnumNgTemplateAction | string> = new Subject<EnumNgTemplateAction | string>();

	constructor(
		private ngbActiveModal		: NgbActiveModal,
		private keyboardMouseService: KeyboardMouseService
	) {}


	/**------------------------------------------------------
	 * Subscribe
	 */
	subscribe(callbackFunction: Function): NgTemplateModalComponent {
		this.actionEvent$.pipe(take(1)).subscribe((action: EnumNgTemplateAction | string) => {
			callbackFunction(action);
		});
		return this;
	}

	onAgree(callbackFunction: Function): NgTemplateModalComponent {
		this.actionEvent$.pipe(take(1)).subscribe((action: EnumNgTemplateAction | string) => {
			if (action as EnumNgTemplateAction === EnumNgTemplateAction.Continue) callbackFunction();
		});
		return this;
	}

	onClose(callbackFunction: Function): NgTemplateModalComponent {
		this.actionEvent$.pipe(take(1)).subscribe((action: EnumNgTemplateAction | string) => {
			if (action as EnumNgTemplateAction === EnumNgTemplateAction.Close) callbackFunction();
		});
		return this;
	}


	/**------------------------------------------------------
	 * Getter & Setter Helpers
	 */
	setTitle(title: string): NgTemplateModalComponent {
		if (this.modalContent) this.modalContent.text.title = title;
		return this;
	}

	setContent(templateRef: TemplateRef<HTMLElement>): NgTemplateModalComponent {
		if (this.modalContent) this.modalContent.text.templateRef = templateRef;
		return this;
	}


	/**------------------------------------------------------
	 * Close Options
	 */
	closeAfter(seconds: number): NgTemplateModalComponent {

		//0 - check the provided value
		if (Util.Number.isNegative(seconds)) throw new Error(`TemplateModalComponent => closeAfter => FATAL ERROR: second of "${seconds}" not valid`);

		//1 - start a timeout to close the modal
		setTimeout(() => {
			this.ngbActiveModal.close();
		}, seconds);
		return this;
	}

	close(): NgTemplateModalComponent {

		//0 - emit the close event
		this.actionEvent$.next(EnumNgTemplateAction.Close);

		//1 - close the modal
		this.ngbActiveModal.close();
		return this;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */

	//** Emit the actions */
	emitButtonAction(actionCode: string): void {
		this.actionEvent$.next(actionCode);
		this.ngbActiveModal.close();
	}

	//** HostListener to close modal on escape click */
	@HostListener('document:keydown', ['$event'])
	onKeyEscapeHandler(event: KeyboardEvent): void {
		if (this.keyboardMouseService.isKeyEscape(event) || this.keyboardMouseService.isKeyEnter(event)) {
			const actionKey: string = event.code;
			this.triggerButtonEvent(actionKey);
		}
	}

	//** Function will trigger once ESCAPE and ENTER key will click */
	triggerButtonEvent(actionKey: string): void {

		//0 - check for modal content
		if (!this.modalContent) throw new Error(`TemplateModalComponent => triggerButtonEvent => modal content is not provided`);

		//1 - find the button action
		const triggeredButton: INgTemplateModalButton = this.modalContent.buttons.find((button: INgTemplateModalButton) => button.actionKey?.find((action: IKeyCode) => action.code === actionKey))!;
		if (!triggeredButton) throw new Error(`TemplateModalComponent => triggerButtonEvent => ${actionKey} not found`);

		//2 - emit the action code of that button
		this.emitButtonAction(triggeredButton.actionCode);
	}

	getBackgroundColor(index: number): string | null {

		//0 - is there any modal configuration
		if (!this.modalConfig) return null;

		//1 - return the color for the button
		const backgroundColor: string = index === 0
			? `var(${this.modalConfig.buttons.primary.color})`
			: `var(${this.modalConfig.buttons.secondary.color})`;
		return backgroundColor;
	}
}
