/**------------------------------------------------------
 * Encoding / Decoding Options
 */
export interface IJdtEncodingOptions {
	headerInfo	: any;
	encryption  : IJdtEncryptionOptions;
	salt		: string;
}

export interface IJdtDecodingOptions {
	encryption  : IJdtEncryptionOptions;
	salt		: string;
}

export interface IJdtEncryptionOptions {
	active	: boolean;
	key		: string;
}


/**------------------------------------------------------
 * Imported IDE Data
 */
export interface IJdtData<TInfo, TData> {
	header 	: {
		type 	: string;
		date	: Date;
		info 	: TInfo;
	};
	data	: TData;
}


/**------------------------------------------------------
 * Exporting format
 */
export interface IJdtHeader<TInfo> {
	type 	: string;		// defining the JDE standard
	date 	: Date;
	info 	: TInfo;
	verify	: {
		encrypted	: boolean;
		keyHash		: string;
		saltHash	: string;
	};
}

export interface IJdtPayload {
	data 	: string;		// base64
}


/**------------------------------------------------------
 * Helper Constants
 */
export const VERIFICATION_HASH_LENGTH: number = 10;
