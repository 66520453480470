import { Util } from '@libs/utilities/util';

import { IBatchTaskExecutorParams } from './bulk-task-executor.interface';


/**------------------------------------------------------
 * Batch Task Executor
 * -------------------
 * > Batch operation query wrapper. This helper is used in
 * > queries to save resources for the DB. The execute
 * > function is called with single items, but internally
 * > the items are collected in a cache. Only when the cache
 * > reaches the desired size, the action will be executed
 * > with all items as a bulk operation.
 */
export class BatchTaskExecutor<T extends object | string | number> {

	//** Helper Variables */
	private batchCache: T[] = [];

	//** Helper to Create Instance */
	static create<T extends object | string | number>(params: IBatchTaskExecutorParams<T>): BatchTaskExecutor<T> {
		return new BatchTaskExecutor<T>(params.processBatch, params.maxBatchSize || Number.MAX_SAFE_INTEGER, params.executeInterval || null);
	}

	private constructor(
		private processBatch			 : (elements: T[]) => Promise<void> | void,
		private readonly MAX_BATCH_SIZE	 : number,
		private readonly EXECUTE_INTERVAL: number | null
	) {
		this.setExecutionInterval();
	}


	/**------------------------------------------------------
	 * Batch Execute
	 */
	async execute(item: T): Promise<void> {

		//0 - add item to cache
		this.batchCache.push(item);

		//1 - is the batch full, so we can execute?
		if (this.batchCache.length < this.MAX_BATCH_SIZE) return;

		//2 - execute and clear the batch
		await this.processBatch(this.batchCache);
		this.batchCache = [];
	}

	async executeRemaining(): Promise<void> {

		// execute and clear the batch
		await this.processBatch(this.batchCache);
		this.batchCache = [];
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	getCurrentBatchSize(): number {
		return this.batchCache.length;
	}

	private setExecutionInterval(): void {

		//0 - is an interval required, to save data periodically?
		if (!this.EXECUTE_INTERVAL || !Util.Number.isNumber(this.EXECUTE_INTERVAL)) return;

		//1 - set the interval
		setInterval(() => this.executeRemaining(), this.EXECUTE_INTERVAL);
	}
}
