import { EnumTmNiceClass, EnumTmOffice, EnumTmStatus, EnumTmType } from '../constants';


/**------------------------------------------------------
 * Trademark Entry Optimized for Querying
 */
export interface ITmTrademarkEntryOptimized {
	optimizedName	: string;					// optimized for better query (old tmSearch)
	niceClasses		: EnumTmNiceClass[];
	office			: EnumTmOffice;
	status			: EnumTmStatus;
	type			: EnumTmType.Word;
}
