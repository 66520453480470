/**------------------------------------------------------
 * Interface Definitions
 */
export interface IImageColorInfo {
	colorList	: IImageColorList[];
	dominant	: string;
	average		: string;
	border		: {
		colorList	: IImageColorList[];
		dominant	: string | null;
		average		: string | null;
	};
}

export interface IImageColorList {
	name		: string;
	percentage	: number;
}
