import { EnumCurrency, ICurrencyInfo } from './currency.interface';


/**------------------------------------------------------
 * Currency Infos
 */
export const CURRENCY_INFOS: Record<EnumCurrency, ICurrencyInfo> = {
	// cspell:disable
	[EnumCurrency.AED]: {
		code			: EnumCurrency.AED,
		name			: 'United Arab Emirates Dirham',
		abbreviation	: 'AED',
		symbol			: 'د.إ',
		decimalPlaces	: 2,
		rateToUSD		: 3.67
	},
	[EnumCurrency.AFN]: {
		code			: EnumCurrency.AFN,
		name			: 'Afghan Afghani',
		abbreviation	: 'AFN',
		symbol			: '؋',
		decimalPlaces	: 2,
		rateToUSD		: 72.34
	},
	[EnumCurrency.ALL]: {
		code			: EnumCurrency.ALL,
		name			: 'Albanian Lek',
		abbreviation	: 'ALL',
		symbol			: 'L',
		decimalPlaces	: 2,
		rateToUSD		: 93.67
	},
	[EnumCurrency.AMD]: {
		code			: EnumCurrency.AMD,
		name			: 'Armenian Dram',
		abbreviation	: 'AMD',
		symbol			: '֏',
		decimalPlaces	: 2,
		rateToUSD		: 388.63
	},
	[EnumCurrency.ANG]: {
		code			: EnumCurrency.ANG,
		name			: 'Netherlands Antillean Guilder',
		abbreviation	: 'ANG',
		symbol			: 'ƒ',
		decimalPlaces	: 2,
		rateToUSD		: 1.81
	},
	[EnumCurrency.AOA]: {
		code			: EnumCurrency.AOA,
		name			: 'Angolan Kwanza',
		abbreviation	: 'AOA',
		symbol			: 'Kz',
		decimalPlaces	: 2,
		rateToUSD		: 838.50
	},
	[EnumCurrency.ARS]: {
		code			: EnumCurrency.ARS,
		name			: 'Argentine Peso',
		abbreviation	: 'ARS',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 880.75
	},
	[EnumCurrency.AUD]: {
		code			: EnumCurrency.AUD,
		name			: 'Australian Dollar',
		abbreviation	: 'AUD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.52
	},
	[EnumCurrency.AWG]: {
		code			: EnumCurrency.AWG,
		name			: 'Aruban Florin',
		abbreviation	: 'AWG',
		symbol			: 'ƒ',
		decimalPlaces	: 2,
		rateToUSD		: 1.80
	},
	[EnumCurrency.AZN]: {
		code			: EnumCurrency.AZN,
		name			: 'Azerbaijani Manat',
		abbreviation	: 'AZN',
		symbol			: '₼',
		decimalPlaces	: 2,
		rateToUSD		: 1.70
	},
	[EnumCurrency.BAM]: {
		code			: EnumCurrency.BAM,
		name			: 'Bosnia-Herzegovina Convertible Mark',
		abbreviation	: 'BAM',
		symbol			: 'KM',
		decimalPlaces	: 2,
		rateToUSD		: 1.82
	},
	[EnumCurrency.BBD]: {
		code			: EnumCurrency.BBD,
		name			: 'Barbadian Dollar',
		abbreviation	: 'BBD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 2.02
	},
	[EnumCurrency.BDT]: {
		code			: EnumCurrency.BDT,
		name			: 'Bangladeshi Taka',
		abbreviation	: 'BDT',
		symbol			: '৳',
		decimalPlaces	: 2,
		rateToUSD		: 109.97
	},
	[EnumCurrency.BGN]: {
		code			: EnumCurrency.BGN,
		name			: 'Bulgarian Lev',
		abbreviation	: 'BGN',
		symbol			: 'лв',
		decimalPlaces	: 2,
		rateToUSD		: 1.82
	},
	[EnumCurrency.BHD]: {
		code			: EnumCurrency.BHD,
		name			: 'Bahraini Dinar',
		abbreviation	: 'BHD',
		symbol			: 'ب.د',
		decimalPlaces	: 3,
		rateToUSD		: 0.38
	},
	[EnumCurrency.BIF]: {
		code			: EnumCurrency.BIF,
		name			: 'Burundian Franc',
		abbreviation	: 'BIF',
		symbol			: 'FBu',
		decimalPlaces	: 0,
		rateToUSD		: 2873.65
	},
	[EnumCurrency.BMD]: {
		code			: EnumCurrency.BMD,
		name			: 'Bermudian Dollar',
		abbreviation	: 'BMD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.00
	},
	[EnumCurrency.BND]: {
		code			: EnumCurrency.BND,
		name			: 'Brunei Dollar',
		abbreviation	: 'BND',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.35
	},
	[EnumCurrency.BOB]: {
		code			: EnumCurrency.BOB,
		name			: 'Bolivian Boliviano',
		abbreviation	: 'BOB',
		symbol			: 'Bs.',
		decimalPlaces	: 2,
		rateToUSD		: 6.92
	},
	[EnumCurrency.BRL]: {
		code			: EnumCurrency.BRL,
		name			: 'Brazilian Real',
		abbreviation	: 'BRL',
		symbol			: 'R$',
		decimalPlaces	: 2,
		rateToUSD		: 5.07
	},
	[EnumCurrency.BSD]: {
		code			: EnumCurrency.BSD,
		name			: 'Bahamian Dollar',
		abbreviation	: 'BSD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.00
	},
	[EnumCurrency.BTN]: {
		code			: EnumCurrency.BTN,
		name			: 'Bhutanese Ngultrum',
		abbreviation	: 'BTN',
		symbol			: 'Nu.',
		decimalPlaces	: 2,
		rateToUSD		: 83.67
	},
	[EnumCurrency.BWP]: {
		code			: EnumCurrency.BWP,
		name			: 'Botswana Pula',
		abbreviation	: 'BWP',
		symbol			: 'P',
		decimalPlaces	: 2,
		rateToUSD		: 13.63
	},
	[EnumCurrency.BYN]: {
		code			: EnumCurrency.BYN,
		name			: 'Belarusian Ruble',
		abbreviation	: 'BYN',
		symbol			: 'Br',
		decimalPlaces	: 2,
		rateToUSD		: 3.28
	},
	[EnumCurrency.BZD]: {
		code			: EnumCurrency.BZD,
		name			: 'Belize Dollar',
		abbreviation	: 'BZD',
		symbol			: 'BZ$',
		decimalPlaces	: 2,
		rateToUSD		: 2.02
	},
	[EnumCurrency.CAD]: {
		code			: EnumCurrency.CAD,
		name			: 'Canadian Dollar',
		abbreviation	: 'CAD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.38
	},
	[EnumCurrency.CDF]: {
		code			: EnumCurrency.CDF,
		name			: 'Congolese Franc',
		abbreviation	: 'CDF',
		symbol			: 'FC',
		decimalPlaces	: 2,
		rateToUSD		: 2800.00
	},
	[EnumCurrency.CHF]: {
		code			: EnumCurrency.CHF,
		name			: 'Swiss Franc',
		abbreviation	: 'CHF',
		symbol			: 'CHF',
		decimalPlaces	: 2,
		rateToUSD		: 0.91
	},
	[EnumCurrency.CLP]: {
		code			: EnumCurrency.CLP,
		name			: 'Chilean Peso',
		abbreviation	: 'CLP',
		symbol			: '$',
		decimalPlaces	: 0,
		rateToUSD		: 937.45
	},
	[EnumCurrency.CNY]: {
		code			: EnumCurrency.CNY,
		name			: 'Chinese Yuan',
		abbreviation	: 'CNY',
		symbol			: '¥',
		decimalPlaces	: 2,
		rateToUSD		: 7.23
	},
	[EnumCurrency.COP]: {
		code			: EnumCurrency.COP,
		name			: 'Colombian Peso',
		abbreviation	: 'COP',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 3886.31
	},
	[EnumCurrency.CRC]: {
		code			: EnumCurrency.CRC,
		name			: 'Costa Rican Colón',
		abbreviation	: 'CRC',
		symbol			: '₡',
		decimalPlaces	: 2,
		rateToUSD		: 512.39
	},
	[EnumCurrency.CUC]: {
		code			: EnumCurrency.CUC,
		name			: 'Cuban Convertible Peso',
		abbreviation	: 'CUC',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.00
	},
	[EnumCurrency.CUP]: {
		code			: EnumCurrency.CUP,
		name			: 'Cuban Peso',
		abbreviation	: 'CUP',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 24.05
	},
	[EnumCurrency.CVE]: {
		code			: EnumCurrency.CVE,
		name			: 'Cape Verdean Escudo',
		abbreviation	: 'CVE',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 102.59
	},
	[EnumCurrency.CZK]: {
		code			: EnumCurrency.CZK,
		name			: 'Czech Koruna',
		abbreviation	: 'CZK',
		symbol			: 'Kč',
		decimalPlaces	: 2,
		rateToUSD		: 23.30
	},
	[EnumCurrency.DJF]: {
		code			: EnumCurrency.DJF,
		name			: 'Djiboutian Franc',
		abbreviation	: 'DJF',
		symbol			: 'Fdj',
		decimalPlaces	: 0,
		rateToUSD		: 178.43
	},
	[EnumCurrency.DKK]: {
		code			: EnumCurrency.DKK,
		name			: 'Danish Krone',
		abbreviation	: 'DKK',
		symbol			: 'kr',
		decimalPlaces	: 2,
		rateToUSD		: 6.95
	},
	[EnumCurrency.DOP]: {
		code			: EnumCurrency.DOP,
		name			: 'Dominican Peso',
		abbreviation	: 'DOP',
		symbol			: 'RD$',
		decimalPlaces	: 2,
		rateToUSD		: 58.17
	},
	[EnumCurrency.DZD]: {
		code			: EnumCurrency.DZD,
		name			: 'Algerian Dinar',
		abbreviation	: 'DZD',
		symbol			: 'دج',
		decimalPlaces	: 2,
		rateToUSD		: 133.00
	},
	[EnumCurrency.EGP]: {
		code			: EnumCurrency.EGP,
		name			: 'Egyptian Pound',
		abbreviation	: 'EGP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 15.69
	},
	[EnumCurrency.ERN]: {
		code			: EnumCurrency.ERN,
		name			: 'Eritrean Nakfa',
		abbreviation	: 'ERN',
		symbol			: 'Nfk',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.ETB]: {
		code			: EnumCurrency.ETB,
		name			: 'Ethiopian Birr',
		abbreviation	: 'ETB',
		symbol			: 'Br',
		decimalPlaces	: 2,
		rateToUSD		: 43.00
	},
	[EnumCurrency.EUR]: {
		code			: EnumCurrency.EUR,
		name			: 'Euro',
		abbreviation	: 'EUR',
		symbol			: '€',
		decimalPlaces	: 2,
		rateToUSD		: 0.93
	},
	[EnumCurrency.FJD]: {
		code			: EnumCurrency.FJD,
		name			: 'Fijian Dollar',
		abbreviation	: 'FJD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 2.07
	},
	[EnumCurrency.FKP]: {
		code			: EnumCurrency.FKP,
		name			: 'Falkland Islands Pound',
		abbreviation	: 'FKP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.78
	},
	[EnumCurrency.GBP]: {
		code			: EnumCurrency.GBP,
		name			: 'British Pound Sterling',
		abbreviation	: 'GBP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.80
	},
	[EnumCurrency.GEL]: {
		code			: EnumCurrency.GEL,
		name			: 'Georgian Lari',
		abbreviation	: 'GEL',
		symbol			: '₾',
		decimalPlaces	: 2,
		rateToUSD		: 3.10
	},
	[EnumCurrency.GGP]: {
		code			: EnumCurrency.GGP,
		name			: 'Guernsey Pound',
		abbreviation	: 'GGP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.78
	},
	[EnumCurrency.GHS]: {
		code			: EnumCurrency.GHS,
		name			: 'Ghanaian Cedi',
		abbreviation	: 'GHS',
		symbol			: '₵',
		decimalPlaces	: 2,
		rateToUSD		: 5.76
	},
	[EnumCurrency.GIP]: {
		code			: EnumCurrency.GIP,
		name			: 'Gibraltar Pound',
		abbreviation	: 'GIP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.78
	},
	[EnumCurrency.GMD]: {
		code			: EnumCurrency.GMD,
		name			: 'Gambian Dalasi',
		abbreviation	: 'GMD',
		symbol			: 'D',
		decimalPlaces	: 2,
		rateToUSD		: 51.00
	},
	[EnumCurrency.GNF]: {
		code			: EnumCurrency.GNF,
		name			: 'Guinean Franc',
		abbreviation	: 'GNF',
		symbol			: 'FG',
		decimalPlaces	: 0,
		rateToUSD		: 10000.00
	},
	[EnumCurrency.GTQ]: {
		code			: EnumCurrency.GTQ,
		name			: 'Guatemalan Quetzal',
		abbreviation	: 'GTQ',
		symbol			: 'Q',
		decimalPlaces	: 2,
		rateToUSD		: 7.70
	},
	[EnumCurrency.GYD]: {
		code			: EnumCurrency.GYD,
		name			: 'Guyanese Dollar',
		abbreviation	: 'GYD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 209.00
	},
	[EnumCurrency.HKD]: {
		code			: EnumCurrency.HKD,
		name			: 'Hong Kong Dollar',
		abbreviation	: 'HKD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 7.75
	},
	[EnumCurrency.HNL]: {
		code			: EnumCurrency.HNL,
		name			: 'Honduran Lempira',
		abbreviation	: 'HNL',
		symbol			: 'L',
		decimalPlaces	: 2,
		rateToUSD		: 24.00
	},
	[EnumCurrency.HRK]: {
		code			: EnumCurrency.HRK,
		name			: 'Croatian Kuna',
		abbreviation	: 'HRK',
		symbol			: 'kn',
		decimalPlaces	: 2,
		rateToUSD		: 6.95
	},
	[EnumCurrency.HTG]: {
		code			: EnumCurrency.HTG,
		name			: 'Haitian Gourde',
		abbreviation	: 'HTG',
		symbol			: 'G',
		decimalPlaces	: 2,
		rateToUSD		: 97.00
	},
	[EnumCurrency.HUF]: {
		code			: EnumCurrency.HUF,
		name			: 'Hungarian Forint',
		abbreviation	: 'HUF',
		symbol			: 'Ft',
		decimalPlaces	: 2,
		rateToUSD		: 310.00
	},
	[EnumCurrency.IDR]: {
		code			: EnumCurrency.IDR,
		name			: 'Indonesian Rupiah',
		abbreviation	: 'IDR',
		symbol			: 'Rp',
		decimalPlaces	: 0,
		rateToUSD		: 14400.00
	},
	[EnumCurrency.ILS]: {
		code			: EnumCurrency.ILS,
		name			: 'Israeli New Shekel',
		abbreviation	: 'ILS',
		symbol			: '₪',
		decimalPlaces	: 2,
		rateToUSD		: 3.41
	},
	[EnumCurrency.IMP]: {
		code			: EnumCurrency.IMP,
		name			: 'Isle of Man Pound',
		abbreviation	: 'IMP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.79
	},
	[EnumCurrency.INR]: {
		code			: EnumCurrency.INR,
		name			: 'Indian Rupee',
		abbreviation	: 'INR',
		symbol			: '₹',
		decimalPlaces	: 2,
		rateToUSD		: 83.51
	},
	[EnumCurrency.IQD]: {
		code			: EnumCurrency.IQD,
		name			: 'Iraqi Dinar',
		abbreviation	: 'IQD',
		symbol			: 'ع.د',
		decimalPlaces	: 3,
		rateToUSD		: 1190.00
	},
	[EnumCurrency.IRR]: {
		code			: EnumCurrency.IRR,
		name			: 'Iranian Rial',
		abbreviation	: 'IRR',
		symbol			: '﷼',
		decimalPlaces	: 2,
		rateToUSD		: 42105.00
	},
	[EnumCurrency.ISK]: {
		code			: EnumCurrency.ISK,
		name			: 'Icelandic Króna',
		abbreviation	: 'ISK',
		symbol			: 'kr',
		decimalPlaces	: 0,
		rateToUSD		: 140.00
	},
	[EnumCurrency.JEP]: {
		code			: EnumCurrency.JEP,
		name			: 'Jersey Pound',
		abbreviation	: 'JEP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.79
	},
	[EnumCurrency.JMD]: {
		code			: EnumCurrency.JMD,
		name			: 'Jamaican Dollar',
		abbreviation	: 'JMD',
		symbol			: 'J$',
		decimalPlaces	: 2,
		rateToUSD		: 145.00
	},
	[EnumCurrency.JOD]: {
		code			: EnumCurrency.JOD,
		name			: 'Jordanian Dinar',
		abbreviation	: 'JOD',
		symbol			: 'د.ا',
		decimalPlaces	: 3,
		rateToUSD		: 0.71
	},
	[EnumCurrency.JPY]: {
		code			: EnumCurrency.JPY,
		name			: 'Japanese Yen',
		abbreviation	: 'JPY',
		symbol			: '¥',
		decimalPlaces	: 0,
		rateToUSD		: 107.00
	},
	[EnumCurrency.KES]: {
		code			: EnumCurrency.KES,
		name			: 'Kenyan Shilling',
		abbreviation	: 'KES',
		symbol			: 'Sh',
		decimalPlaces	: 2,
		rateToUSD		: 107.00
	},
	[EnumCurrency.KGS]: {
		code			: EnumCurrency.KGS,
		name			: 'Kyrgyzstani Som',
		abbreviation	: 'KGS',
		symbol			: 'лв',
		decimalPlaces	: 2,
		rateToUSD		: 84.00
	},
	[EnumCurrency.KHR]: {
		code			: EnumCurrency.KHR,
		name			: 'Cambodian Riel',
		abbreviation	: 'KHR',
		symbol			: '៛',
		decimalPlaces	: 2,
		rateToUSD		: 4100.00
	},
	[EnumCurrency.KMF]: {
		code			: EnumCurrency.KMF,
		name			: 'Comorian Franc',
		abbreviation	: 'KMF',
		symbol			: 'CF',
		decimalPlaces	: 0,
		rateToUSD		: 400.00
	},
	[EnumCurrency.KPW]: {
		code			: EnumCurrency.KPW,
		name			: 'North Korean Won',
		abbreviation	: 'KPW',
		symbol			: '₩',
		decimalPlaces	: 2,
		rateToUSD		: 900.00
	},
	[EnumCurrency.KRW]: {
		code			: EnumCurrency.KRW,
		name			: 'South Korean Won',
		abbreviation	: 'KRW',
		symbol			: '₩',
		decimalPlaces	: 0,
		rateToUSD		: 1200.00
	},
	[EnumCurrency.KWD]: {
		code			: EnumCurrency.KWD,
		name			: 'Kuwaiti Dinar',
		abbreviation	: 'KWD',
		symbol			: 'د.ك',
		decimalPlaces	: 3,
		rateToUSD		: 0.30
	},
	[EnumCurrency.KYD]: {
		code			: EnumCurrency.KYD,
		name			: 'Cayman Islands Dollar',
		abbreviation	: 'KYD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 0.82
	},
	[EnumCurrency.KZT]: {
		code			: EnumCurrency.KZT,
		name			: 'Kazakhstani Tenge',
		abbreviation	: 'KZT',
		symbol			: '₸',
		decimalPlaces	: 2,
		rateToUSD		: 420.00
	},
	[EnumCurrency.LAK]: {
		code			: EnumCurrency.LAK,
		name			: 'Laotian Kip',
		abbreviation	: 'LAK',
		symbol			: '₭',
		decimalPlaces	: 2,
		rateToUSD		: 9000.00
	},
	[EnumCurrency.LBP]: {
		code			: EnumCurrency.LBP,
		name			: 'Lebanese Pound',
		abbreviation	: 'LBP',
		symbol			: 'ل.ل',
		decimalPlaces	: 2,
		rateToUSD		: 1500.00
	},
	[EnumCurrency.LKR]: {
		code			: EnumCurrency.LKR,
		name			: 'Sri Lankan Rupee',
		abbreviation	: 'LKR',
		symbol			: 'Rs',
		decimalPlaces	: 2,
		rateToUSD		: 185.00
	},
	[EnumCurrency.LRD]: {
		code			: EnumCurrency.LRD,
		name			: 'Liberian Dollar',
		abbreviation	: 'LRD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 200.00
	},
	[EnumCurrency.LSL]: {
		code			: EnumCurrency.LSL,
		name			: 'Lesotho Loti',
		abbreviation	: 'LSL',
		symbol			: 'L',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.LYD]: {
		code			: EnumCurrency.LYD,
		name			: 'Libyan Dinar',
		abbreviation	: 'LYD',
		symbol			: 'ل.د',
		decimalPlaces	: 3,
		rateToUSD		: 1.41
	},
	[EnumCurrency.MAD]: {
		code			: EnumCurrency.MAD,
		name			: 'Moroccan Dirham',
		abbreviation	: 'MAD',
		symbol			: 'د.م.',
		decimalPlaces	: 2,
		rateToUSD		: 9.50
	},
	[EnumCurrency.MDL]: {
		code			: EnumCurrency.MDL,
		name			: 'Moldovan Leu',
		abbreviation	: 'MDL',
		symbol			: 'L',
		decimalPlaces	: 2,
		rateToUSD		: 17.00
	},
	[EnumCurrency.MGA]: {
		code			: EnumCurrency.MGA,
		name			: 'Malagasy Ariary',
		abbreviation	: 'MGA',
		symbol			: 'Ar',
		decimalPlaces	: 2,
		rateToUSD		: 3800.00
	},
	[EnumCurrency.MKD]: {
		code			: EnumCurrency.MKD,
		name			: 'Macedonian Denar',
		abbreviation	: 'MKD',
		symbol			: 'ден',
		decimalPlaces	: 2,
		rateToUSD		: 52.00
	},
	[EnumCurrency.MMK]: {
		code			: EnumCurrency.MMK,
		name			: 'Myanmar Kyat',
		abbreviation	: 'MMK',
		symbol			: 'K',
		decimalPlaces	: 2,
		rateToUSD		: 1400.00
	},
	[EnumCurrency.MNT]: {
		code			: EnumCurrency.MNT,
		name			: 'Mongolian Tugrik',
		abbreviation	: 'MNT',
		symbol			: '₮',
		decimalPlaces	: 2,
		rateToUSD		: 2850.00
	},
	[EnumCurrency.MOP]: {
		code			: EnumCurrency.MOP,
		name			: 'Macanese Pataca',
		abbreviation	: 'MOP',
		symbol			: 'P',
		decimalPlaces	: 2,
		rateToUSD		: 8.00
	},
	[EnumCurrency.MRU]: {
		code			: EnumCurrency.MRU,
		name			: 'Mauritanian Ouguiya',
		abbreviation	: 'MRU',
		symbol			: 'UM',
		decimalPlaces	: 2,
		rateToUSD		: 37.00
	},
	[EnumCurrency.MUR]: {
		code			: EnumCurrency.MUR,
		name			: 'Mauritian Rupee',
		abbreviation	: 'MUR',
		symbol			: '₨',
		decimalPlaces	: 2,
		rateToUSD		: 40.00
	},
	[EnumCurrency.MVR]: {
		code			: EnumCurrency.MVR,
		name			: 'Maldivian Rufiyaa',
		abbreviation	: 'MVR',
		symbol			: 'Rf',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.MWK]: {
		code			: EnumCurrency.MWK,
		name			: 'Malawian Kwacha',
		abbreviation	: 'MWK',
		symbol			: 'MK',
		decimalPlaces	: 2,
		rateToUSD		: 725.00
	},
	[EnumCurrency.MXN]: {
		code			: EnumCurrency.MXN,
		name			: 'Mexican Peso',
		abbreviation	: 'MXN',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 23.00
	},
	[EnumCurrency.MYR]: {
		code			: EnumCurrency.MYR,
		name			: 'Malaysian Ringgit',
		abbreviation	: 'MYR',
		symbol			: 'RM',
		decimalPlaces	: 2,
		rateToUSD		: 4.30
	},
	[EnumCurrency.MZN]: {
		code			: EnumCurrency.MZN,
		name			: 'Mozambican Metical',
		abbreviation	: 'MZN',
		symbol			: 'MT',
		decimalPlaces	: 2,
		rateToUSD		: 70.00
	},
	[EnumCurrency.NAD]: {
		code			: EnumCurrency.NAD,
		name			: 'Namibian Dollar',
		abbreviation	: 'NAD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.NGN]: {
		code			: EnumCurrency.NGN,
		name			: 'Nigerian Naira',
		abbreviation	: 'NGN',
		symbol			: '₦',
		decimalPlaces	: 2,
		rateToUSD		: 380.00
	},
	[EnumCurrency.NIO]: {
		code			: EnumCurrency.NIO,
		name			: 'Nicaraguan Córdoba',
		abbreviation	: 'NIO',
		symbol			: 'C$',
		decimalPlaces	: 2,
		rateToUSD		: 35.00
	},
	[EnumCurrency.NOK]: {
		code			: EnumCurrency.NOK,
		name			: 'Norwegian Krone',
		abbreviation	: 'NOK',
		symbol			: 'kr',
		decimalPlaces	: 2,
		rateToUSD		: 10.00
	},
	[EnumCurrency.NPR]: {
		code			: EnumCurrency.NPR,
		name			: 'Nepalese Rupee',
		abbreviation	: 'NPR',
		symbol			: '₨',
		decimalPlaces	: 2,
		rateToUSD		: 120.00
	},
	[EnumCurrency.NZD]: {
		code			: EnumCurrency.NZD,
		name			: 'New Zealand Dollar',
		abbreviation	: 'NZD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.50
	},
	[EnumCurrency.OMR]: {
		code			: EnumCurrency.OMR,
		name			: 'Omani Rial',
		abbreviation	: 'OMR',
		symbol			: 'ر.ع.',
		decimalPlaces	: 3,
		rateToUSD		: 0.39
	},
	[EnumCurrency.PAB]: {
		code			: EnumCurrency.PAB,
		name			: 'Panamanian Balboa',
		abbreviation	: 'PAB',
		symbol			: 'B/.',
		decimalPlaces	: 2,
		rateToUSD		: 1.00
	},
	[EnumCurrency.PEN]: {
		code			: EnumCurrency.PEN,
		name			: 'Peruvian Sol',
		abbreviation	: 'PEN',
		symbol			: 'S/',
		decimalPlaces	: 2,
		rateToUSD		: 3.50
	},
	[EnumCurrency.PGK]: {
		code			: EnumCurrency.PGK,
		name			: 'Papua New Guinean Kina',
		abbreviation	: 'PGK',
		symbol			: 'K',
		decimalPlaces	: 2,
		rateToUSD		: 3.50
	},
	[EnumCurrency.PHP]: {
		code			: EnumCurrency.PHP,
		name			: 'Philippine Peso',
		abbreviation	: 'PHP',
		symbol			: '₱',
		decimalPlaces	: 2,
		rateToUSD		: 50.00
	},
	[EnumCurrency.PKR]: {
		code			: EnumCurrency.PKR,
		name			: 'Pakistani Rupee',
		abbreviation	: 'PKR',
		symbol			: 'Rs',
		decimalPlaces	: 2,
		rateToUSD		: 165.00
	},
	[EnumCurrency.PLN]: {
		code			: EnumCurrency.PLN,
		name			: 'Polish Złoty',
		abbreviation	: 'PLN',
		symbol			: 'zł',
		decimalPlaces	: 2,
		rateToUSD		: 4.00
	},
	[EnumCurrency.PYG]: {
		code			: EnumCurrency.PYG,
		name			: 'Paraguayan Guarani',
		abbreviation	: 'PYG',
		symbol			: '₲',
		decimalPlaces	: 0,
		rateToUSD		: 6500.00
	},
	[EnumCurrency.QAR]: {
		code			: EnumCurrency.QAR,
		name			: 'Qatari Rial',
		abbreviation	: 'QAR',
		symbol			: 'ر.ق',
		decimalPlaces	: 2,
		rateToUSD		: 3.64
	},
	[EnumCurrency.RON]: {
		code			: EnumCurrency.RON,
		name			: 'Romanian Leu',
		abbreviation	: 'RON',
		symbol			: 'lei',
		decimalPlaces	: 2,
		rateToUSD		: 4.20
	},
	[EnumCurrency.RSD]: {
		code			: EnumCurrency.RSD,
		name			: 'Serbian Dinar',
		abbreviation	: 'RSD',
		symbol			: 'din',
		decimalPlaces	: 2,
		rateToUSD		: 100.00
	},
	[EnumCurrency.RUB]: {
		code			: EnumCurrency.RUB,
		name			: 'Russian Ruble',
		abbreviation	: 'RUB',
		symbol			: '₽',
		decimalPlaces	: 2,
		rateToUSD		: 70.00
	},
	[EnumCurrency.RWF]: {
		code			: EnumCurrency.RWF,
		name			: 'Rwandan Franc',
		abbreviation	: 'RWF',
		symbol			: 'FRw',
		decimalPlaces	: 0,
		rateToUSD		: 950.00
	},
	[EnumCurrency.SAR]: {
		code			: EnumCurrency.SAR,
		name			: 'Saudi Riyal',
		abbreviation	: 'SAR',
		symbol			: 'ر.س',
		decimalPlaces	: 2,
		rateToUSD		: 3.75
	},
	[EnumCurrency.SBD]: {
		code			: EnumCurrency.SBD,
		name			: 'Solomon Islands Dollar',
		abbreviation	: 'SBD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 8.00
	},
	[EnumCurrency.SCR]: {
		code			: EnumCurrency.SCR,
		name			: 'Seychellois Rupee',
		abbreviation	: 'SCR',
		symbol			: '₨',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.SDG]: {
		code			: EnumCurrency.SDG,
		name			: 'Sudanese Pound',
		abbreviation	: 'SDG',
		symbol			: 'ج.س.',
		decimalPlaces	: 2,
		rateToUSD		: 55.00
	},
	[EnumCurrency.SEK]: {
		code			: EnumCurrency.SEK,
		name			: 'Swedish Krona',
		abbreviation	: 'SEK',
		symbol			: 'kr',
		decimalPlaces	: 2,
		rateToUSD		: 10.00
	},
	[EnumCurrency.SGD]: {
		code			: EnumCurrency.SGD,
		name			: 'Singapore Dollar',
		abbreviation	: 'SGD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.40
	},
	[EnumCurrency.SHP]: {
		code			: EnumCurrency.SHP,
		name			: 'Saint Helena Pound',
		abbreviation	: 'SHP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 0.78
	},
	[EnumCurrency.SLL]: {
		code			: EnumCurrency.SLL,
		name			: 'Sierra Leonean Leone',
		abbreviation	: 'SLL',
		symbol			: 'Le',
		decimalPlaces	: 2,
		rateToUSD		: 9800.00
	},
	[EnumCurrency.SOS]: {
		code			: EnumCurrency.SOS,
		name			: 'Somali Shilling',
		abbreviation	: 'SOS',
		symbol			: 'Sh',
		decimalPlaces	: 2,
		rateToUSD		: 580.00
	},
	[EnumCurrency.SPL]: {
		code			: EnumCurrency.SPL,
		name			: 'Seborga Luigino',
		abbreviation	: 'SPL',
		symbol			: '₤',
		decimalPlaces	: 2,
		rateToUSD		: 1.00
	},
	[EnumCurrency.SRD]: {
		code			: EnumCurrency.SRD,
		name			: 'Surinamese Dollar',
		abbreviation	: 'SRD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 7.00
	},
	[EnumCurrency.SSP]: {
		code			: EnumCurrency.SSP,
		name			: 'South Sudanese Pound',
		abbreviation	: 'SSP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 130.00
	},
	[EnumCurrency.STN]: {
		code			: EnumCurrency.STN,
		name			: 'São Tomé and Príncipe Dobra',
		abbreviation	: 'STN',
		symbol			: 'Db',
		decimalPlaces	: 2,
		rateToUSD		: 22.00
	},
	[EnumCurrency.SVC]: {
		code			: EnumCurrency.SVC,
		name			: 'Salvadoran Colón',
		abbreviation	: 'SVC',
		symbol			: '₡',
		decimalPlaces	: 2,
		rateToUSD		: 8.75
	},
	[EnumCurrency.SYP]: {
		code			: EnumCurrency.SYP,
		name			: 'Syrian Pound',
		abbreviation	: 'SYP',
		symbol			: '£',
		decimalPlaces	: 2,
		rateToUSD		: 515.00
	},
	[EnumCurrency.SZL]: {
		code			: EnumCurrency.SZL,
		name			: 'Eswatini Lilangeni',
		abbreviation	: 'SZL',
		symbol			: 'L',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.THB]: {
		code			: EnumCurrency.THB,
		name			: 'Thai Baht',
		abbreviation	: 'THB',
		symbol			: '฿',
		decimalPlaces	: 2,
		rateToUSD		: 30.00
	},
	[EnumCurrency.TJS]: {
		code			: EnumCurrency.TJS,
		name			: 'Tajikistani Somoni',
		abbreviation	: 'TJS',
		symbol			: 'ЅМ',
		decimalPlaces	: 2,
		rateToUSD		: 11.00
	},
	[EnumCurrency.TMT]: {
		code			: EnumCurrency.TMT,
		name			: 'Turkmenistan Manat',
		abbreviation	: 'TMT',
		symbol			: 'T',
		decimalPlaces	: 2,
		rateToUSD		: 3.50
	},
	[EnumCurrency.TND]: {
		code			: EnumCurrency.TND,
		name			: 'Tunisian Dinar',
		abbreviation	: 'TND',
		symbol			: 'د.ت',
		decimalPlaces	: 3,
		rateToUSD		: 2.80
	},
	[EnumCurrency.TOP]: {
		code			: EnumCurrency.TOP,
		name			: 'Tongan Pa\'anga',
		abbreviation	: 'TOP',
		symbol			: 'T$',
		decimalPlaces	: 2,
		rateToUSD		: 2.30
	},
	[EnumCurrency.TRY]: {
		code			: EnumCurrency.TRY,
		name			: 'Turkish Lira',
		abbreviation	: 'TRY',
		symbol			: '₺',
		decimalPlaces	: 2,
		rateToUSD		: 6.80
	},
	[EnumCurrency.TTD]: {
		code			: EnumCurrency.TTD,
		name			: 'Trinidad and Tobago Dollar',
		abbreviation	: 'TTD',
		symbol			: 'TT$',
		decimalPlaces	: 2,
		rateToUSD		: 6.80
	},
	[EnumCurrency.TVD]: {
		code			: EnumCurrency.TVD,
		name			: 'Tuvaluan Dollar',
		abbreviation	: 'TVD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.40
	},
	[EnumCurrency.TWD]: {
		code			: EnumCurrency.TWD,
		name			: 'New Taiwan Dollar',
		abbreviation	: 'TWD',
		symbol			: 'NT$',
		decimalPlaces	: 2,
		rateToUSD		: 30.00
	},
	[EnumCurrency.TZS]: {
		code			: EnumCurrency.TZS,
		name			: 'Tanzanian Shilling',
		abbreviation	: 'TZS',
		symbol			: 'Sh',
		decimalPlaces	: 2,
		rateToUSD		: 2300.00
	},
	[EnumCurrency.UAH]: {
		code			: EnumCurrency.UAH,
		name			: 'Ukrainian Hryvnia',
		abbreviation	: 'UAH',
		symbol			: '₴',
		decimalPlaces	: 2,
		rateToUSD		: 27.00
	},
	[EnumCurrency.UGX]: {
		code			: EnumCurrency.UGX,
		name			: 'Ugandan Shilling',
		abbreviation	: 'UGX',
		symbol			: 'USh',
		decimalPlaces	: 0,
		rateToUSD		: 3700.00
	},
	[EnumCurrency.USD]: {
		code			: EnumCurrency.USD,
		name			: 'United States Dollar',
		abbreviation	: 'USD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 1.00
	},
	[EnumCurrency.UYU]: {
		code			: EnumCurrency.UYU,
		name			: 'Uruguayan Peso',
		abbreviation	: 'UYU',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 43.00
	},
	[EnumCurrency.UZS]: {
		code			: EnumCurrency.UZS,
		name			: 'Uzbekistani Som',
		abbreviation	: 'UZS',
		symbol			: 'лв',
		decimalPlaces	: 2,
		rateToUSD		: 10500.00
	},
	[EnumCurrency.VEF]: {
		code			: EnumCurrency.VEF,
		name			: 'Venezuelan Bolívar',
		abbreviation	: 'VEF',
		symbol			: 'Bs',
		decimalPlaces	: 2,
		rateToUSD		: 10.00
	},
	[EnumCurrency.VND]: {
		code			: EnumCurrency.VND,
		name			: 'Vietnamese Dong',
		abbreviation	: 'VND',
		symbol			: '₫',
		decimalPlaces	: 0,
		rateToUSD		: 23000.00
	},
	[EnumCurrency.VUV]: {
		code			: EnumCurrency.VUV,
		name			: 'Vanuatu Vatu',
		abbreviation	: 'VUV',
		symbol			: 'VT',
		decimalPlaces	: 0,
		rateToUSD		: 110.00
	},
	[EnumCurrency.WST]: {
		code			: EnumCurrency.WST,
		name			: 'Samoan Tala',
		abbreviation	: 'WST',
		symbol			: 'WS$',
		decimalPlaces	: 2,
		rateToUSD		: 2.60
	},
	[EnumCurrency.XAF]: {
		code			: EnumCurrency.XAF,
		name			: 'Central African CFA Franc',
		abbreviation	: 'XAF',
		symbol			: 'FCFA',
		decimalPlaces	: 0,
		rateToUSD		: 600.00
	},
	[EnumCurrency.XCD]: {
		code			: EnumCurrency.XCD,
		name			: 'East Caribbean Dollar',
		abbreviation	: 'XCD',
		symbol			: '$',
		decimalPlaces	: 2,
		rateToUSD		: 2.70
	},
	[EnumCurrency.XDR]: {
		code			: EnumCurrency.XDR,
		name			: 'Special Drawing Rights (International Monetary Fund)',
		abbreviation	: 'XDR',
		symbol			: 'SDR',
		decimalPlaces	: 2,
		rateToUSD		: 0.70
	},
	[EnumCurrency.XOF]: {
		code			: EnumCurrency.XOF,
		name			: 'West African CFA Franc',
		abbreviation	: 'XOF',
		symbol			: 'CFA',
		decimalPlaces	: 0,
		rateToUSD		: 600.00
	},
	[EnumCurrency.XPF]: {
		code			: EnumCurrency.XPF,
		name			: 'CFP Franc',
		abbreviation	: 'XPF',
		symbol			: '₣',
		decimalPlaces	: 0,
		rateToUSD		: 100.00
	},
	[EnumCurrency.YER]: {
		code			: EnumCurrency.YER,
		name			: 'Yemeni Rial',
		abbreviation	: 'YER',
		symbol			: '﷼',
		decimalPlaces	: 2,
		rateToUSD		: 250.00
	},
	[EnumCurrency.ZAR]: {
		code			: EnumCurrency.ZAR,
		name			: 'South African Rand',
		abbreviation	: 'ZAR',
		symbol			: 'R',
		decimalPlaces	: 2,
		rateToUSD		: 15.00
	},
	[EnumCurrency.ZMW]: {
		code			: EnumCurrency.ZMW,
		name			: 'Zambian Kwacha',
		abbreviation	: 'ZMW',
		symbol			: 'ZK',
		decimalPlaces	: 2,
		rateToUSD		: 20.00
	},
	[EnumCurrency.ZWD]: {
		code			: EnumCurrency.ZWD,
		name			: 'Zimbabwean Dollar',
		abbreviation	: 'ZWD',
		symbol			: 'Z$',
		decimalPlaces	: 2,
		rateToUSD		: 380.00
	}
	// cspell:disable
};
