/**------------------------------------------------------
 * Deep Copy Objects
 * -----------------
 * > source : https://stackoverflow.com/questions/28876300/deep-copying-array-of-nested-objects-in-javascript
 */
export class UtilDeepCopy {

	deepCopy<T>(data: T): T {

		//0 - is it a primitive value?
		if (typeof data !== 'object' || data === null) return data;

		//1 - deep copy the object
		const objectType: string = Object.prototype.toString.call(data);
		switch (objectType) {

			//a. for arrays, create a new array deep copies of the entries
			case '[object Array]':
				return (data as any).map((elem: T) => this.deepCopy(elem)) as T;

			//b. clone the date
			case '[object Date]':
				return new Date(data as unknown as Date) as unknown as T;

			//c. clone the RegExp
			case '[object RegExp]':
				return new RegExp(data as unknown as RegExp) as unknown as T;

			//d. clone a File
			case '[object File]':
				const file: File = data as unknown as File;
				return new File([file], file.name, { type: file.type }) as unknown as T;

			//e. clone a Blob
			case '[object Blob]':
				const blob: Blob = data as unknown as Blob;
				return new Blob([blob], { type: blob.type }) as unknown as T;

				// ... add others if required

			//f. normal JSON object, deep-copy its properties into a new object
			case '[object Object]':
				return Object.keys(data).reduce((prev: any, key: string) => {
					prev[key] = this.deepCopy((data as any)[key]);
					return prev;
				}, {});

			default:
				throw new Error(`DeepCopy => deepCopy => FATAL ERROR: type of "${objectType}" is not defined in the deep copy, please add it`);
		}
	}
}
