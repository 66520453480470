import { UtilBasic } from '../util-basic';
import { UtilString } from '../util-string';


/**------------------------------------------------------
 * Search Relevance Compare
 * ------------------------
 * > Compare the Search against the Text
 */
export class UtilTextSearchRelevance {

	constructor(
		private utilBasic	: UtilBasic,
		private utilString	: UtilString
	) {}


	/**------------------------------------------------------
	 * Sort Texts by Relevance
	 */
	sortByRelevance<T>(search: string, textObjects: T[], getTextFn?: TypeUtilGetTextFn<T>): T[] {

		//0 - check and get text function
		const textFn: Function = this.utilBasic.fallbackValue(getTextFn, (value: T) => this.utilString.toString(value));

		//1 - sort array based on the matched score
		const sortedTextObjects: T[] = textObjects.sort((a: T, b: T) => {

			//a. get relevance score for both a and b
			const relevanceScoreA: number = this.compare(search, textFn(a));
			const relevanceScoreB: number = this.compare(search, textFn(b));

			//b. sort in descending order
			return relevanceScoreB - relevanceScoreA;
		});

		//2 - return sorted texts
		return sortedTextObjects;
	}


	/**------------------------------------------------------
	 * Determine the Relevance of a Text
	 */
	compare(search: string, text: string): number {

		//0 - get the search components
		// > 'crazy nike' => ['crazy', 'nike']
		const searchWords: string[] = search.split(' ');

		//1 - check the relevance for the full search text
		const searchRelevance: number = this.getRelevanceScore(search, text);

		//2 - check the relevance for the individual words
		let searchWordsRelevanceCounter: number = 0;
		for (const searchWord of searchWords) searchWordsRelevanceCounter += this.getRelevanceScore(searchWord, text);
		const searchWordsRelevance: number = searchWordsRelevanceCounter / searchWords.length;

		//3 - is the text relevant at all to the search
		if (searchRelevance === 0 && searchWordsRelevance === 0) return 0;

		//3 - calculate the length score (length can be 0, thats why +1)
		const unmatchedTextSearch	  : string = this.getUnmatchedText([search], text);
		const unmatchedTextSearchWords: string = this.getUnmatchedText(searchWords, text);
		const lengthScore  : number = 1 / (unmatchedTextSearch.length + unmatchedTextSearchWords.length + 1);

		//4 - combine everything to the final score
		const score: number = (searchRelevance * 0.5) + (searchWordsRelevance * 0.4) + (lengthScore * 0.1);
		return score;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getRelevanceScore(searchWord: string, text: string): number {

		//0 - exact match (relevance 1) 	>> 'nike' > 'nike'
		if (searchWord.toLowerCase() === text.toLowerCase()) return 1;

		//1 - word contains (relevance 1) 	>> 'nike' > 'the nike' or 'nike dog'
		const exactMatchRegex: RegExp = new RegExp(`\\b${searchWord}\\b`, 'i');
		if (exactMatchRegex.test(text)) return 0.8;

		//2 - word includes (relevance 1) 	>> 'nike' > 'craNIKE' or 'the craNIKE is crazy'
		const wordIncludesRegex: RegExp = new RegExp(searchWord, 'i');
		if (wordIncludesRegex.test(text)) return 0.5;

		//3 - not match was found
		return 0;
	}

	private getUnmatchedText(searchWords: string[], text: string) {

		//0 - remove the searchWord form the text
		for (const searchWord of searchWords) {
			const wordIncludes: RegExp = new RegExp(searchWord, 'i');
			text = text.replace(wordIncludes, '');
		}

		//1 - return the text without the search words
		return text;
	}
}


//** Types -------------------------------------- */
type TypeUtilGetTextFn<T> = (object: T) => string;
