/**------------------------------------------------------
 * Enum Addition
 */
export enum EnumAddition {
	All 	= 'all',
	None 	= 'none'
	// ...
}


/**------------------------------------------------------
 * Infos Interface
 */
export interface IEnumAdditionInfo {
	code	: EnumAddition;
	name	: string;
}
