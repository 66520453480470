import { Util } from '@libs/utilities/util';
import { Crypto } from '@libs/utilities/crypto';

import { IJdtEncodingOptions, IJdtHeader, IJdtPayload, VERIFICATION_HASH_LENGTH } from './jdt-data-token.interface';


export class JdtEncodingHelper {


	/**------------------------------------------------------
	 * Encode JDT (JSON Data Token)
	 */
	encodeDataToJdt<TInfo, TData>(data: TData, encodeOptions: IJdtEncodingOptions): string {

		//0 - check the provided options
		if (!Util.String.isString(encodeOptions.salt)) throw new Error(`JDTEncodingHandler => encodeDataToJdt => FATAL ERROR: provided salt of "${encodeOptions.salt}" is not a string (the salt is optional and used to protect the exported data against manipulations)`);
		if (encodeOptions.encryption.active  && Util.String.isEmpty(encodeOptions.encryption.key))  throw new Error(`JDTEncodingHandler => encodeDataToJdt => FATAL ERROR: encryption key of "${encodeOptions.encryption.key}" is empty (when the encryption is set to active for the export and encryption key needs to be provided)`);
		if (!encodeOptions.encryption.active && !Util.String.isEmpty(encodeOptions.encryption.key)) throw new Error(`JDTEncodingHandler => encodeDataToJdt => FATAL ERROR: if the encryption is deactivated, the encryption key has to be empty`);

		//1 - prepare the header
		const keyHash : string = Util.String.truncate(Crypto.Hash.hashSha256(encodeOptions.encryption.key), VERIFICATION_HASH_LENGTH);
		const saltHash: string = Util.String.truncate(Crypto.Hash.hashSha256(encodeOptions.salt), VERIFICATION_HASH_LENGTH);
		const header  : IJdtHeader<TInfo> = {
			type 	: 'jdt_v1.0',
			info 	: encodeOptions.headerInfo,
			date 	: new Date(),
			verify	: {
				encrypted	: encodeOptions.encryption.active,
				keyHash		: keyHash,
				saltHash	: saltHash
			}
		};

		//2 - prepare the payload (including the encryption)
		const dataString: string =  Util.Basic.stringifyObject<TData>(data);
		const payload   : IJdtPayload = {
			data : encodeOptions.encryption.active ? this.encryptData<TData>(data, encodeOptions.encryption.key) : dataString
		};

		//3 - convert the header & payload to base64
		const headerBase64   : string = this.toBase64(header);
		const payloadBase64  : string = this.toBase64(payload);
		const signatureBase64: string = this.createBase64Signature(headerBase64, payloadBase64, encodeOptions.salt);

		//4 - return the raw string for the export
		const rawDataString: string = `${headerBase64}.${payloadBase64}.${signatureBase64}`;
		return rawDataString;
	}

	createBase64Signature(headerBase64: string, payloadBase64: string, salt: string): string {

		//0 - create the signature
		const rawData: string = headerBase64 + payloadBase64 + salt;					// adding the salt to the hash (fingerprint) will protect it against manipulations
		const hash   : string = Crypto.Hash.hashSha256(rawData);

		//1 - convert to base64
		return Util.String.toBase64(hash);
	}


	/**------------------------------------------------------
	 * Helper Function
	 */
	private toBase64<T>(object: T): string {
		const jsonString: string = Util.Basic.stringifyObject(object);
		return Util.String.toBase64(jsonString);
	}

	private encryptData<T>(data: T, encryptionKey: string): string {
		const dataString   : string = Util.Basic.stringifyObject<T>(data);
		const encryptedData: string = Crypto.SymmetricEncryption.encryptAes256(dataString, encryptionKey);
		return encryptedData;
	}
}
