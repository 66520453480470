import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Util } from '@libs/utilities/util';

import { EnumNgTimeOptions } from './message-time.interface';
import { NgExactRemainingTimePipe, NgTimeAgoPipe } from '../../../../../pipes';


@Component({
	selector	: 'ng-message-time',
	template	: '<p>{{value}}</p>',
	styles		: [],
	providers	: [
		NgTimeAgoPipe,
		NgExactRemainingTimePipe
	]
})
export class NgMessageTimeComponent implements OnInit, OnDestroy {

	//** Decorators */
	@Input() option!: EnumNgTimeOptions;
	@Input() time 	!: Date;

	@Output() readonly exactTimeOver: EventEmitter<boolean> = new EventEmitter<boolean>();

	//** Helper Variables */
	value!: string;
	timer!: NodeJS.Timer;

	constructor(
		private exactRemainingTime: NgExactRemainingTimePipe,
		private timeAgoPipe 	  : NgTimeAgoPipe
	) {}


	/**------------------------------------------------------
	 * Lifecycle
	 */
	ngOnInit(): void {

		//0 - validating inputs
		if (!Util.Date.isValidDate(this.time)) throw new Error(`MessageTimeComponent => ngOnInit => FATAL ERROR: the provided time with "${this.time}" is not valid`);

		//1 - configure the component based on the provided option
		switch (this.option) {
			case EnumNgTimeOptions.pastTime:
				this.iniPastTime();
				break;

			case EnumNgTimeOptions.remainingTime:
				this.iniRemainingTime();
				break;

			default:
				throw new Error(`MessageTimeComponent => ngOnInit => FATAL ERROR: the provided option with "${this.option}" is invalid`);
		}
	}

	ngOnDestroy(): void {

		// clear all intervals after component destroyed
		clearInterval(this.timer);
	}


	/**------------------------------------------------------
	 * Initialize the timer
	 */
	private iniPastTime() {

		//0 - set current value (otherwise it would only be shown after 1 sec)
		this.value = this.timeAgoPipe.transform(new Date(this.time).toISOString());

		//1 - create timer and update every second
		this.timer = setInterval(() => {
			this.value = this.timeAgoPipe.transform(new Date(this.time).toISOString());
		}, 1000);
	}

	private iniRemainingTime() {

		//0 - set current value (otherwise it would only be shown after 1 sec)
		this.value = this.exactRemainingTime.transform(new Date(this.time).toISOString());

		//1 - create timer and update every second
		this.timer = setInterval(() => {
			this.value = this.exactRemainingTime.transform(new Date(this.time).toISOString());
			if (!this.value || this.value === '0 sec') this.exactTimeOver.emit(true);
		}, 1000);
	}
}
