/* eslint-disable @typescript-eslint/no-useless-template-literals */
import { UtilRegex } from '../util-regex';
import { UtilString } from '../util-string';


/**------------------------------------------------------
 * Text Replacer
 * -------------
 * > Smart Replace: replaces words/characters based on:
 * >   - replaces numbers in any combination
 * >   - replaces complete words
 * >   - maintains the character casing while replacing new string
 * > Starts With: replaces starting characters in a word
 * > Ends With: replaces ending characters in a word
 * > Case Insensitive: replaces case insensitive words only
 * > Case Sensitive: replaces case sensitive words only
 */
export class UtilTextReplacer {

	constructor(
		private utilRegex : UtilRegex,
		private utilString: UtilString
	) {}


	smartReplace(params: IUtilTextReplaceParams): string {

		//0 - check if text provided is empty
		this.utilString.checkEmptyStrings([params.text]);

		//1 - deep copy the string
		// > Note: keep the `${params.text}`, it's too deep copy the sting (this can very between browsers)
		// > source info: https://stackoverflow.com/questions/31712808/how-to-force-javascript-to-deep-copy-a-string
		let text: string = `${params.text}`;

		//2 - replaces numbers
		text = text.replace(/(?=^|[a-z0-9\\s])\d+(?<=$|[a-z0-9\\s])/g, (matchedWord: string) => {
			return matchedWord.replace(new RegExp(`${params.oldStr}`), params.newStr);
		});

		//3 - replaces letters/words
		text = text.replace(this.utilRegex.textBoundCaptureGroup(params.oldStr, 'gi'), (matchedString: string) => {

			//a. get replaced string by updating it with same case
			const replacedString: string = this.replaceWordWithCase(matchedString, params);

			//b. return replaced string
			return replacedString;
		});

		//4 - return updated text
		return text;
	}

	replaceStartsWith(params: IUtilTextReplaceParams): string {

		//0 - replace starts string with new string
		const startsWithRegex: RegExp = this.utilRegex.textStartBoundCaptureGroup(this.utilRegex.escapeRegExp(params.oldStr), 'g');
		const replacedText	 : string = `${params.text}`.replace(startsWithRegex, params.newStr);

		//1 - return replaced text
		return replacedText;
	}

	replaceEndsWith(params: IUtilTextReplaceParams): string {

		//0 - replace ends string with new string
		const endsWithRegex: RegExp = this.utilRegex.textEndBoundCaptureGroup(this.utilRegex.escapeRegExp(params.oldStr), 'g');
		const replacedText : string = `${params.text}`.replace(endsWithRegex, params.newStr);

		//1 - return replaced text
		return replacedText;
	}

	replaceCaseInsensitive(params: IUtilTextReplaceParams): string {

		//0 - replace case insensitive string with new string
		const caseInsensitiveRegex: RegExp = new RegExp(`(${this.utilRegex.escapeRegExp(params.oldStr)})`, 'ig');
		const replacedText		  : string = `${params.text}`.replace(caseInsensitiveRegex, params.newStr);

		//1 - return replaced text
		return replacedText;
	}

	replaceCaseSensitive(params: IUtilTextReplaceParams): string {

		//0 - replace case sensitive string with new string
		const caseSensitiveRegex: RegExp = new RegExp(`(${this.utilRegex.escapeRegExp(params.oldStr)})`, 'g');
		const replacedText		: string = `${params.text}`.replace(caseSensitiveRegex, params.newStr);

		//1 - return replaced text
		return replacedText;
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private replaceWordWithCase(matchedString: string, params: IUtilTextReplaceParams): string {

		//0 - get each word in matched and new string
		const wordRegex: RegExp = this.utilRegex.textBoundCaptureGroup(`[\\w\\p{L}-]+`, 'giu');
		const matchedWords	: string[] = matchedString.match(wordRegex) || [];
		const newStrWords	: string[] = params.newStr.match(wordRegex) || [];

		//1 - replace each word
		let replacedString: string = matchedString.replace(wordRegex, (matchedWord: string) => {

			//a. get index of word in matched string, if word not present return matched word
			let replacedWord: string = '';
			const wordIndex: number = matchedWords.findIndex((word: string) => word === matchedWord);
			if (wordIndex < 0) return matchedWord;

			//b. loop over all characters and update according to their case
			const newStrWord: string = newStrWords[wordIndex] || '';		// get the new word from the new string with the same index as that of the old word
			for (const [charIndex, char] of Array.from(matchedWord).entries()) {

				// handle lowercase, uppercase and numeric characters
				const newStrChar: string =  Array.from(newStrWord)[charIndex] || '';
				replacedWord += this.replaceCharacter(char, newStrChar);
			}

			//c. add extra characters if any
			replacedWord += newStrWord.substring(matchedWord.length);

			//d. return replaced word
			return replacedWord;
		});

		//1 - if new string has more characters than old string
		if (newStrWords.length <= matchedWords.length) return replacedString.slice(0, params.newStr.length);

		//2 - if new string has more characters than old string
		replacedString += params.newStr.substring(replacedString.length);

		//3 - return replaced string
		return replacedString;
	}

	private replaceCharacter(oldChar: string, newChar: string) {

		//0 - check for non-ascii characters
		if (/[\p{L}-]/u.test(oldChar)) return this.convertCase(oldChar, newChar);

		//1 - check for numbers
		if (/[0-9]/.test(oldChar)) return newChar;

		//2 - check for case sensitivity
		return this.convertCase(oldChar, newChar);
	}

	//** Convert character case */
	private convertCase(oldChar: string, newChar: string) {

		//0 - check for lowercase
		if (oldChar === oldChar.toLowerCase()) return newChar.toLowerCase();

		//1 - check for uppercase
		if (oldChar === oldChar.toUpperCase()) return newChar.toUpperCase();

		//2 - if none matches return old char
		return oldChar;
	}
}


//** Interfaces --------------------------------- */
export interface IUtilTextReplaceParams {
	text	: string;
	oldStr	: string;
	newStr	: string;
}
