/**------------------------------------------------------
 * Color List
 * ----------
 * > A structured collection of colors with associated information
 * > like names, hexadecimal values, and codes. Used in software
 * > development and design, such lists provide a convenient reference
 * > for easily accessing and utilizing specific colors within code,
 * > ensuring consistency and accuracy in visual representations.
 */
export * from './color-list/color-list.data';
export * from './color-list/color-list.interface';
export * from './color-list/console-color.data';


/**------------------------------------------------------
 * File Types
 * ----------
 * > Organized collection of file formats, including attributes like
 * > extensions and MIME types. Crucial in software development and data
 * > management to accurately handle and recognize different file formats.
 */
export * from './file-types/file-type.data';
export * from './file-types/file-type.interface';
