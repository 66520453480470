import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Util } from '@libs/utilities/util';
import { KEY_CODES } from '@libs/libraries/frontend';

import { NgTemplateModalComponent } from './template-modal.component';
import { NG_TEMPLATE_MODAL_STYLES, EnumNgTemplateModalType, EnumNgTemplateAction } from './template-modal.data';
import { EnumNgTemplateSize, INgTemplateModalButton, INgTemplateModalConfig, INgTemplateModalContent, INgTemplateModalText } from './template-modal.interface';


@Injectable()
export class NgTemplateModalAssistant {

	//** Configurations */
	private readonly MAX_BUTTONS		 : number 			= 10;
	private readonly DEFAULT_MODAL_CONFIG: NgbModalOptions 	= {
		centered	: true,
		keyboard	: false,
		backdrop	: 'static',
		scrollable 	: true,
		size 		: EnumNgTemplateSize.Medium
	};

	constructor(
		private modalService: NgbModal
	) {}


	/**----------------------------------------------------------
	 * Open the Custom Modals
	 * > Default, Info, Success, Warning, Error
	 */
	showDefault	(modalContent: INgTemplateModalContent): NgTemplateModalComponent { return this.open(modalContent, EnumNgTemplateModalType.DefaultTemplate); }
	showInfo	(modalContent: INgTemplateModalContent): NgTemplateModalComponent { return this.open(modalContent, EnumNgTemplateModalType.InfoTemplate); }
	showSuccess	(modalContent: INgTemplateModalContent): NgTemplateModalComponent { return this.open(modalContent, EnumNgTemplateModalType.SuccessTemplate); }
	showWarning	(modalContent: INgTemplateModalContent): NgTemplateModalComponent { return this.open(modalContent, EnumNgTemplateModalType.WarningTemplate); }
	showError	(modalContent: INgTemplateModalContent): NgTemplateModalComponent { return this.open(modalContent, EnumNgTemplateModalType.ErrorTemplate); }


	/**----------------------------------------------------------
	 * Open the Simple Modals
	 */
	showDefaultSimple(title: string, templateRef: TemplateRef<HTMLElement>, size: EnumNgTemplateSize = EnumNgTemplateSize.Medium): NgTemplateModalComponent {
		return this.showDefault(this.getSimpleContent(title, templateRef, size));
	}

	//** Info */
	showInfoSimple(title: string, templateRef: TemplateRef<HTMLElement>, size: EnumNgTemplateSize = EnumNgTemplateSize.Medium): NgTemplateModalComponent {
		return this.showInfo(this.getSimpleContent(title, templateRef, size));
	}

	//** Success */
	showSuccessSimple(title: string, templateRef: TemplateRef<HTMLElement>, size: EnumNgTemplateSize = EnumNgTemplateSize.Medium): NgTemplateModalComponent {
		return this.showSuccess(this.getSimpleContent(title, templateRef, size));
	}

	//** Warning */
	showWarningSimple(title: string, templateRef: TemplateRef<HTMLElement>, size: EnumNgTemplateSize = EnumNgTemplateSize.Medium): NgTemplateModalComponent {
		return this.showWarning(this.getSimpleContent(title, templateRef, size));
	}

	//** Error */
	showErrorSimple(title: string, templateRef: TemplateRef<HTMLElement>, size: EnumNgTemplateSize = EnumNgTemplateSize.Medium): NgTemplateModalComponent {
		return this.showError(this.getSimpleContent(title, templateRef, size));
	}


	/**----------------------------------------------------------
	 * Handle Opening of Modal
	 */
	private open(modalContent: INgTemplateModalContent, modalType: EnumNgTemplateModalType): NgTemplateModalComponent {

		//0 - get the configuration of the modal before using it
		const modalConfig: INgTemplateModalConfig = NG_TEMPLATE_MODAL_STYLES[modalType];
		if (!modalConfig) throw new Error(`NgTemplateModalAssistant => open => FATAL ERROR: no configuration found for modalType of "${modalType}"`);
		this.checkConfiguration(modalConfig, modalContent);

		//1 - set template modal options before creating the TemplateModalComponent
		const ngbConfig: NgbModalOptions = Util.Basic.deepCopy(this.DEFAULT_MODAL_CONFIG);
		ngbConfig.size = modalContent.options.size;

		//2 - open the modal & set the parameters inside
		const modalRef: NgbModalRef = this.modalService.open(NgTemplateModalComponent, ngbConfig);
		modalRef.componentInstance.modalConfig 	= modalConfig;
		modalRef.componentInstance.modalContent = modalContent;
		modalRef.componentInstance.modalRef		= modalRef;

		//3 - return the component instance
		return modalRef.componentInstance;
	}

	private checkConfiguration(modalConfig: INgTemplateModalConfig, modalContent: INgTemplateModalContent) {

		//0 - checking the configurations
		if (Util.String.isEmpty(modalConfig.style.color)) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a style.color configuration`);
		if (Util.Object.isEmpty(modalConfig.style.icon))  throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a style.icon configuration`);

		//1 - check the content
		const modalText: INgTemplateModalText = modalContent.text;
		if (Util.String.isEmpty(modalText.title)) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a title`);
		if (Util.Basic.isUndefined(modalText.templateRef) && Util.Basic.isUndefined(modalText.htmlString)) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without templateRef or HTML content`);
		if (Util.Basic.isDefined(modalText.templateRef)   && Util.Basic.isDefined(modalText.htmlString))   throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can be used with either a templateRef or HTML content as string`);

		//2 - check the defined options
		if (!Util.Enum.isValid(EnumNgTemplateSize, modalContent.options.size)) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal size option of "${modalContent.options.size}" is invalid`);

		//3 - check the amount of buttons
		const buttons: INgTemplateModalButton[] = modalContent.buttons;
		if (Util.Array.isEmpty(buttons))	   throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without at least one mainButton`);
		if (buttons.length > this.MAX_BUTTONS) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used with more then "${this.MAX_BUTTONS}" (buttons.length: "${buttons.length}")`);

		//4 - check the button data
		for (let i: number = 0; i < buttons.length; i++) {
			if (!buttons[i].actionCode) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a button ${i + 1} actionCode`);
			if (!buttons[i].buttonText) throw new Error(`NgTemplateModalAssistant => checkConfiguration => FATAL ERROR: modal can't be used without a button ${i + 1} buttonText`);
		}
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getSimpleContent(title: string, templateRef: TemplateRef<any>, size: EnumNgTemplateSize): INgTemplateModalContent {

		//0 - create the default content object
		const defaultContent: INgTemplateModalContent = {
			text 	: {
				title		: title,
				templateRef : templateRef
			},
			options : {
				size		: size
			},
			buttons : [
				{
					buttonText	 : 'Ok',
					actionCode	 : EnumNgTemplateAction.Continue,
					actionKey	 : [KEY_CODES.escape, KEY_CODES.enter]
				}
			]
		};

		//1 - return the created content
		return defaultContent;
	}
}
