/**------------------------------------------------------
 * Enums
 * -----
 * > Enum definitions that are used globally around the apps
 */
export * from './enums';


/**------------------------------------------------------
 * Http
 * ----
 * > All http standards like status codes, ...
 */
export * from './http';


/**------------------------------------------------------
 * Global Characteristics
 * ----------------------
 * > International standards encompassing languages, countries,
 * > continents, and currencies, providing consistent references
 * > for global data representation.
 */
export * from './international';


/**------------------------------------------------------
 * Standards
 * ---------
 * > Specifications and conventions for various aspects,
 * > including colors, file types, and more, ensuring uniformity
 * > and compatibility across systems.
 */
export * from './standards';


/**------------------------------------------------------
 * Textual
 * -------
 * > Constants dedicated to text-specific elements like stop words,
 * > regular expressions, and language-specific character sets,
 * > optimizing text processing and analysis.
 */
export * from './textual';


/**------------------------------------------------------
 * Type Definitions
 * ----------------
 * > Globally used TypeScript type definitions that facilitate
 * > consistent data structuring and validation throughout a codebase.
 */
export * from './types';


/**------------------------------------------------------
 * Units / Measurements
 * --------------------
 * > Measurement units for length, mass, speed, temperature,
 * > time, and other quantifiable attributes, enabling precise
 * > data representation and calculation.
 */
export * from './units';


/**------------------------------------------------------
 * Browser, Devices, and OS
 * ------------------------
 * > Containing the enums and data for the browser and device
 * > detections values.
 */
export * from './system';


/**------------------------------------------------------
 * Miscellaneous
 * -------------
 * > A collection of various constants that do not fit into
 * > specific categories, accommodating diverse constants
 * > that don't have a dedicated classification.
 */
export * from './miscellaneous';
