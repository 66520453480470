import { EnumPodMarketplace } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export enum EnumFreepikSorting {
	Popular			= 'popular',		// default value
	Recent			= 'recent'
}

export const FREEPIK_SORTING_LIST: ISearchParameterConfig<EnumFreepikSorting>[] = [
	{
		code 		: EnumFreepikSorting.Popular,
		name 		: 'Popular',
		parameters 	: {}
	},
	{
		code 		: EnumFreepikSorting.Recent,
		name 		: 'Recent',
		parameters 	: { sort: 'recent' }
	}
];


/**------------------------------------------------------
 * Category/Product Configurations
 */
export enum EnumFreepikProduct {
	All				= 'all',				// default value
	Vectors			= 'vectors',
	Photos			= 'photos',
	Psd				= 'psd',
	Icons			= 'icons'
}

export const FREEPIK_PRODUCT_LIST: ISearchParameterConfig<EnumFreepikProduct>[] = [
	{
		code 		: EnumFreepikProduct.All,
		name        : 'All',
		parameters  : {}
	},
	{
		code 		: EnumFreepikProduct.Vectors,
		name        : 'Vector',
		parameters  : {
			type 	: 'vector'
		}
	},
	{
		code 		: EnumFreepikProduct.Photos,
		name        : 'Photos',
		parameters  : {
			type 	: 'photo'
		}
	},
	{
		code 		: EnumFreepikProduct.Psd,
		name        : 'Psd',
		parameters  : {
			type 	: 'psd'
		}
	},
	{
		code 		: EnumFreepikProduct.Icons,
		name        : 'Icons',
		parameters  : {
			type 	: 'icon'
		}
	}
];


/**------------------------------------------------------
 * Freepik Configuration
 */
export const FREEPIK_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.freepik.com',
		login		: 'https://id.freepikcompany.com/login?client_id=freepik&lang=en',
		search		: 'https://www.freepik.com/search?format=search&page=[PAGE]&query=[SEARCH]'
	},
	pageLimit		: 200,
	sortings		: FREEPIK_SORTING_LIST,
	products		: FREEPIK_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Freepik Search Options
 */
export const FREEPIK_DEFAULT_OPTIONS: IFreepikSearchOptions = {
	page			: 1,
	sorting			: EnumFreepikSorting.Popular,
	product			: EnumFreepikProduct.All
};

export interface IFreepikSearchOptions {
	page			: number;
	sorting			: EnumFreepikSorting;
	product			: EnumFreepikProduct;
}
