import { EnumCountry, EnumLanguage } from '@libs/constants';

import { EnumKeywordProcess } from '../keyword.interface';


/**------------------------------------------------------
 * Keyword Data
 */
export interface IGoogleKeywordData {
	keyword     	: string;						// 'dog'
	language        : EnumLanguage;					// 'en'		>> for "EN => COM", "DE => .DE", ... (marketplace for scraping depends on the language)
	country			: EnumCountry;

	// related keywords to the search term
	suggestions		: IGoogleKeywordRelated;

	// search volume and data from google
	searchVolume 	: IGoogleKeywordSearchVolume;

	createdBy		: EnumKeywordProcess;
}


/**------------------------------------------------------
 * Search Volumes
 * --------------
 * > The search volume is collected by the "keyword-tool.io"
 * > library / service. The history of the last 12 month
 * > is hereby provided. The statistics are calculated for
 * > better DB access and usage.
 */
export interface IGoogleKeywordSearchVolume {
	cmp				: number;					// 0.985058267
	cpc				: number;					// 0.23431
	history			: IGoogleSearchVolumeValue[];
}

export interface IGoogleSearchVolumeValue {
	volume			: number;						// 40000
	month			: number;						// 0
	year			: number;						// 2022		>> created by provided "_month" and "_year"
}


/**------------------------------------------------------
 * Linked & Related Keywords
 * -------------------------
 * Linked
 * ------
 * > These linked keywords are the suggestions for the
 * > main one and are extracted out of all related
 * > keyword. This is the building block for the network
 * > of the keywords.
 * -------------------------
 * Related
 * -------
 * > These linked keywords which are collected from
 * > all different sources and are used to define the
 * > linked keywords based on importance.
 */
export interface IGoogleKeywordRelated {
	webSearch		: string[];
	shopping		: string[];
}
