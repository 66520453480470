import { ILogReport } from '../logger.interface';


/**------------------------------------------------------
 * Mail Service Interface
 */
export interface ISendMailParams {
	to			: string | string[];
	subject		: string;
	body		: string;
}

export interface IMailService {
	sendMail(params: ISendMailParams): unknown | Promise<unknown>;
}


/**------------------------------------------------------
 * Options
 */
export interface IReportMailOptions {
	mailTo		: string | string[];
	subject		: string;
	heading		: string;
	reportData	: ILogReport;
}
