import { EnumPodMarketplace, EnumRedbubbleProduct, EnumRedbubbleSorting } from '../../../../../constants/research';
import { ISearchLinkConfig, ISearchParameterConfig } from '../../../data/search-links.interface';


/**------------------------------------------------------
 * Sorting Configurations
 */
export const REDBUBBLE_SORTING_LIST: ISearchParameterConfig<EnumRedbubbleSorting>[] = [
	{
		code		: EnumRedbubbleSorting.Relevant,
		name		: 'Most Relevant',
		parameters	: { sortOrder: 'relevant' }
	},
	{
		code		: EnumRedbubbleSorting.Trending,
		name		: 'Trending',
		parameters	: { sortOrder: 'trending' }
	},
	{
		code		: EnumRedbubbleSorting.Recent,
		name		: 'Newest',
		parameters	: { sortOrder: 'recent' }
	},
	{
		code		: EnumRedbubbleSorting.TopSelling,
		name		: 'Best Selling',
		parameters	: { sortOrder: 'top%20selling' }
	}
];


/**------------------------------------------------------
 * Product Configurations
 */
export const REDBUBBLE_PRODUCT_LIST: ISearchParameterConfig<EnumRedbubbleProduct>[] = [
	{
		code		: EnumRedbubbleProduct.All,
		name		: 'All Products',
		parameters	: {}
	},
	{
		code		: EnumRedbubbleProduct.Shirt,
		name		: 'T-Shirt',
		parameters	: {
			iaCode	: 'u-tees'
		}
	},
	{
		code		: EnumRedbubbleProduct.VNeck,
		name		: 'V-Neck',
		parameters	: {
			iaCode	: 'u-tees',
			neckType: 'neckType-v-neck'
		}
	},
	{
		code		: EnumRedbubbleProduct.Premium,
		name		: 'Premium T-Shirt',
		parameters	: {
			iaCode	: 'u-tees',
			style	: 'u-tee-premium-crew'
		}
	},
	{
		code		: EnumRedbubbleProduct.Hoodie,
		name		: 'Hoodie',
		parameters	: {
			iaCode	: 'u-sweatshirts',
			style	: 'u-sweatshirt-hooded'
		}
	},
	{
		code		: EnumRedbubbleProduct.TankTop,
		name		: 'Tank Top',
		parameters	: {
			iaCode	: 'u-tanks'
		}
	},
	{
		code		: EnumRedbubbleProduct.LongSleeve,
		name		: 'Long Sleeve',
		parameters	: {
			iaCode	: 'u-tees',
			style	: 'u-tee-slim-crew-long'
		}
	},
	{
		code		: EnumRedbubbleProduct.Sweatshirt,
		name		: 'Sweatshirt',
		parameters	: {
			iaCode	: 'u-sweatshirts',
			style	: 'u-sweatshirt-pullover'
		}
	}
];


/**------------------------------------------------------
 * Redbubble Configuration
 */
export const REDBUBBLE_CONFIG: ISearchLinkConfig = {
	marketplace		: EnumPodMarketplace.COM,
	websiteUrl		: {
		home		: 'https://www.redbubble.com/',
		login		: 'https://www.redbubble.com/auth/login',
		search		: 'https://www.redbubble.com/shop/?page=[PAGE]&query=[SEARCH]'
	},
	pageLimit		: 100,
	sortings		: REDBUBBLE_SORTING_LIST,
	products		: REDBUBBLE_PRODUCT_LIST,
	category	    : [],
	options 		: {
		emptySearch : true
	}
};


/**------------------------------------------------------
 * Redbubble Search Options
 */
export const REDBUBBLE_DEFAULT_OPTIONS: IRedbubbleSearchOptions = {
	page			: 1,
	sorting			: EnumRedbubbleSorting.Relevant,
	product			: EnumRedbubbleProduct.All
};

export interface IRedbubbleSearchOptions {
	page			: number;
	sorting			: EnumRedbubbleSorting;
	product			: EnumRedbubbleProduct;
}
