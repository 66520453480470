import { EnumAmazonProduct, EnumAmazonMarketplace } from '../../app-constants/pod-platforms/amazon.constants';
import { IBsrHistory, IExtractedKeyword, IPriceHistory, ISubBsrData } from './amazon-mba-design.interface';


/**------------------------------------------------------
 * Kindle Direct Publishing Book Data
 */
export interface IDesignDataKdp {
	asin            			: string;
	product         			: EnumAmazonProduct;
	marketplace     			: EnumAmazonMarketplace;
	img             			: string;
	title           			: string;
	subtitle        			: string;
	description     			: string;
	keywords        			: string[];
	categories      			: string[];
	dimensions      			: string;
	pages           			: number;
	isbn_10         			: string;
	isbn_13         			: string;
	price           			: number;
	price_history   			: IPriceHistory[];
	bsr             			: number;
	bsr_category				: string | null;
	bsr_history     			: IBsrHistory[];
	sub_bsr         			: ISubBsrData[];
	review_stars    			: number;
	review_count    			: number;
	publish_date    			: Date;
	extracted_keywords          : IExtractedKeyword[];
	extracted_long_tail_keywords: IExtractedKeyword[];
}
