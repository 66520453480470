import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Util } from '@libs/utilities/util';
import { SecureTimeToken } from '@domains/security/shared';

import { SecureHttp } from './http/secure-http';
import { SecureSseRequest } from './http/sse-request/sse-request';
import { AbstractSecureHttpClient } from './http/secure-http.interface';
import { SecureEncryptionService } from '../services/encryption.service';
import { SecureReCaptchaService } from '../services/re-captcha.service';
import { SecureTimeTokenService } from '../services/time-token.service';
import { EnumSecureHttpInjection, ISecureHttpConfig } from './secure-http.config';
import { EnumSecureServiceInjection, ISecureServicesConfig } from '../services/security-services.config';


@NgModule()
export class SecureHttpModule {

	static register(config: ISecureHttpConfig): ModuleWithProviders<SecureHttpModule> {

		//0 - check the server url
		if (!Util.Http.isUrlValid(config.SERVER_URL)) throw new Error(`SecureHttpModule => register => FATAL ERROR: the SERVER_URL is not a valid url (value: ${config.SERVER_URL})`);

		//1 - create the module
		return {
			ngModule	: SecureHttpModule,
			providers	: [
				SecureHttp,
				SecureSseRequest,
				SecureEncryptionService,
				SecureReCaptchaService,
				SecureTimeTokenService,
				{
					provide		: EnumSecureHttpInjection.Config,
					useValue	: config as ISecureHttpConfig
				},
				{
					provide		: EnumSecureServiceInjection.Config,
					useValue	: config as ISecureServicesConfig
				},
				{
					provide 	: AbstractSecureHttpClient,
					deps		: [HttpBackend],
					useFactory	: (httpBackend: HttpBackend) => {
						return new HttpClient(httpBackend);
					}
				},
				{
					provide		: SecureTimeToken,
					useClass	: SecureTimeToken
				}
			]
		};
	}
}
