import { Util } from '@libs/utilities/util';

import { ISearchLinkConfig, ISearchLinkOptions, ISearchParameterConfig, IUrlParameters, IQueryParameters, IAbstractSearchLinks } from './search-links.interface';


/**------------------------------------------------------
 * Search Link Creation Helper
 */
export abstract class AbstractSearchLinks<TSearchLinkOptions extends Partial<ISearchLinkOptions>> implements IAbstractSearchLinks {

	//** Configurations */
	private readonly HAS_STRING_PLACEHOLDERS: RegExp = new RegExp(/\[.*?\]/g);
	private readonly PAGE_PLACEHOLDER		: string = '[PAGE]';
	private readonly SEARCH_PLACEHOLDER		: string = '[SEARCH]';

	constructor(
		protected searchLinkConfig	: ISearchLinkConfig,
		protected defaultOptions	: Partial<TSearchLinkOptions>
	) {

		//0 - check if the configuration is valid
		if (Util.Object.isEmpty(this.searchLinkConfig)) throw new Error(`AbstractSearchLink => constructor => FATAL ERROR: the provided searchLinkConfig of "${searchLinkConfig}" is an empty object`);
		if (!this.searchLinkConfig.pageLimit)    		throw new Error(`AbstractSearchLink => constructor => FATAL ERROR: no page limit was defined`);
		if (this.searchLinkConfig.pageLimit !== -1 && this.searchLinkConfig.pageLimit < 1) throw new Error(`AbstractSearchLink => constructor => FATAL ERROR: page limit of "${this.searchLinkConfig.pageLimit}" is invalid (please check the configuration)`);

		//1 - check if the default options are valid
		if (Util.Object.isEmpty(this.defaultOptions)) throw new Error(`AbstractSearchLink => constructor => FATAL ERROR: the provided defaultOptions of "${defaultOptions}" are empty`);
	}


	/**------------------------------------------------------
	 * Create Search Links
	 */
	home() : string { return this.searchLinkConfig.websiteUrl.home; }
	login(): string { return this.searchLinkConfig.websiteUrl.login; }

	search(searchQuery: string, options?: Partial<TSearchLinkOptions>): string {

		//0 - purify the options
		const optionParams: Partial<TSearchLinkOptions>	= Util.Function.assignOptions(this.defaultOptions, options);

		//1 - create the search link (check if any placeholder is left in the link)
		const searchLink: string = this.getSearchLink(searchQuery, optionParams);
		if (this.HAS_STRING_PLACEHOLDERS.test(searchLink)) throw new Error(`AbstractSearchLink => search => FATAL ERROR: the search link still has placeholders, identified by the brackets of "[PLACEHOLDER_EXAMPLE]" (searchLink: "${searchLink}")`);

		//2 - return the search link
		return searchLink;
	}


	/**------------------------------------------------------
	 * Configurations Getters
	 */
	getConfig()		: ISearchLinkConfig { return this.searchLinkConfig; }
	getPageLimit()	: number 			{ return this.searchLinkConfig.pageLimit; }
	getMarketplace(): string 			{ return this.searchLinkConfig.marketplace; }


	/**------------------------------------------------------
	 * Sorting Getters
	 */
	hasSortings(): boolean {
		return !Util.Array.isEmpty(this.searchLinkConfig.sortings);
	}

	getSortingList<T extends string>(): ISearchParameterConfig<T>[] {
		return this.searchLinkConfig.sortings as ISearchParameterConfig<T>[];
	}

	getSorting<T extends string>(sortingCode: T): ISearchParameterConfig<T> {
		return this.getConfigList<T>(sortingCode, this.getSortingList());
	}


	/**------------------------------------------------------
	 * Product Getters
	 */
	hasProducts(): boolean {
		return !Util.Array.isEmpty(this.searchLinkConfig.products);
	}

	getProductList<T extends string>(): ISearchParameterConfig<T>[] {
		return this.searchLinkConfig.products as ISearchParameterConfig<T>[];
	}

	getProduct<T extends string>(productCode: T): ISearchParameterConfig<T> {
		return this.getConfigList<T>(productCode, this.getProductList());
	}


	/**------------------------------------------------------
	 * Create a Search Link
	 */
	private getSearchLink(searchQuery: string, options: Partial<TSearchLinkOptions>): string {

		//0 - get the search link & check search keyword
		const searchUrl: string = this.searchLinkConfig.websiteUrl.search;
		if (Util.String.isEmpty(searchUrl)) throw new Error(`AbstractSearchLink => createSearchLink => FATAL ERROR: baseUrl is empty and was therefore not defined`);

		//1 - if search query is not existing then open home url
		if (Util.String.isEmpty(searchQuery) && !this.searchLinkConfig.options.emptySearch) {
			return this.home();
		}

		//2 - define search parameters
		const searchParameters: IUrlParameters = {

			//a. add keyword search & page search
			...Util.Http.getUrlParameters(searchUrl) as IUrlParameters,

			//b. add sorting
			...this.addParameter(options?.sorting! as string, this.searchLinkConfig.sortings),

			//c. add category
			...this.addParameter(options?.category! as string, this.searchLinkConfig.category),

			//d. add product
			// > Note: keep the product after the category!
			// > This is needed because of the amazon links. Like popsockets define the
			// > type mobile and category would overwrite it with general or clothing.
			...this.addParameter(options?.product! as string, this.searchLinkConfig.products)
		};

		//3 - create the search link
		const domain  : string = Util.Http.extractDomain(searchUrl);
		let searchLink: string = Util.Http.urlJoinWithParams([`https://${domain}`], searchParameters);

		//4 - replace all placeholders for sorting, product, ...
		searchLink = this.setPlaceholder(searchLink, options?.sorting! as string, this.searchLinkConfig.sortings);
		searchLink = this.setPlaceholder(searchLink, options?.product! as string, this.searchLinkConfig.products);

		//5 - set the search query & page number
		searchLink = this.setSearchQuery(searchLink, searchQuery, options);
		searchLink = this.setPage(searchLink, options);

		//6 - purify the link
		searchLink.replace('//', '/');
		return searchLink;
	}


	/**------------------------------------------------------
	 * Set Parameters & Placeholders
	 */
	private addParameter(option: string, configList: ISearchParameterConfig<string>[]): IUrlParameters {

		//0 - was any option defined?
		if (Util.String.isEmpty(option)) return {};

		//1 - was the config defined for the option (if not throw an error)
		if (Util.Array.isEmpty(configList)) throw new Error(`AbstractSearchLink => addParameter => FATAL ERROR: configList can not be empty if the option of "${option}" requires it`);

		//2 - try to find the config for the option
		const configForOption: ISearchParameterConfig<string> = configList.find((elem: ISearchParameterConfig<string>) => elem.code === option)!;
		if (!configForOption) throw new Error(`AbstractSearchLink => addParameter => FATAL ERROR: configForOption could not be found for the option of "${option}" (defined configs are: "${configList.map((elem: ISearchParameterConfig<string>) => elem.code).join(', ')}")`);

		//3 - was the option defined as a parameter (if not skip it)?
		if (Util.Basic.isUndefined(configForOption?.parameters)) return {};

		//4 - return the found parameter
		return configForOption.parameters!;
	}

	private setPlaceholder(searchLink: string, option: string, configList: ISearchParameterConfig<string>[]): string {

		//0 - was any option defined?
		if (Util.String.isEmpty(option)) return searchLink;

		//1 - check if configList was defined
		if (Util.Array.isEmpty(configList)) throw new Error(`AbstractSearchLink => setPathPlaceholder => FATAL ERROR: configList can not be empty if the option of "${option}" requires it`);

		//2 - try to find the config for the option
		const configForOption: ISearchParameterConfig<string> = configList.find((elem: ISearchParameterConfig<string>) => elem.code === option)!;
		if (!configForOption) throw new Error(`AbstractSearchLink => setPathPlaceholder => FATAL ERROR: configForOption could not be found for the option of "${option}" (defined configs are: "${configList.map((elem: ISearchParameterConfig<string>) => elem.code).join(', ')}")`);

		//3 - was the option defined as a placeholder (if not skip it)?
		if (Util.Basic.isUndefined(configForOption?.placeholders)) return searchLink;

		//4 - replace the placeholders in the text
		for (const [placeholder, urlValue] of Object.entries(configForOption.placeholders!)) {

			//a. does the link contain the placeholder?
			if (!searchLink.includes(placeholder)) throw new Error(`AbstractSearchLink => setPathPlaceholder => FATAL ERROR: the searchLink does not support the page placeholder of "${placeholder}" (searchLink: "${searchLink}")`);

			//b. replace the placeholder in the text with the new value
			searchLink = searchLink.replace(placeholder, urlValue);
		}

		//5 - return the search link
		return searchLink;
	}


	/**------------------------------------------------------
	 * Set Page & Url
	 */
	private setPage(searchLink: string, options: Partial<TSearchLinkOptions>): string {

		//0 - was any page defined?
		const page: number = options?.page!;
		if (Util.Basic.isUndefined(page)) return searchLink.replace(this.PAGE_PLACEHOLDER, Util.String.toString(1));

		//1 - check if page is valid & url support the placeholder
		if (page < 1) 				  					 throw new Error(`AbstractSearchLink => setPage => FATAL ERROR: page of "${page}" (the page number value can not be smaller then 1)`);
		if (page > this.searchLinkConfig.pageLimit) 	 throw new Error(`AbstractSearchLink => setPage => FATAL ERROR: page of "${page}" (the page number value can not be greater then the pageLimit of "${this.searchLinkConfig.pageLimit}")`);
		if (!searchLink.includes(this.PAGE_PLACEHOLDER)) throw new Error(`AbstractSearchLink => setPage => FATAL ERROR: the searchLink does not support the page placeholder of "${this.PAGE_PLACEHOLDER}" (searchLink: "${searchLink}")`);

		//2 - add the page to the page link
		return searchLink.replace(this.PAGE_PLACEHOLDER, Util.String.toString(page));
	}

	private setSearchQuery(searchLink: string, searchQuery: string, options: Partial<TSearchLinkOptions>): string {

		//0 - does the link support the search placeholder?
		if (!searchLink.includes(this.SEARCH_PLACEHOLDER)) throw new Error(`AbstractSearchLink = setPathValue => FATAL ERROR: the searchLink does not support the search placeholder of "${this.SEARCH_PLACEHOLDER}" (searchLink: "${searchLink}")`);
		if (Util.String.isEmpty(searchQuery)) searchQuery = '';

		//1 - add prefix & postfix if required by the configuration
		const queryParameters: IQueryParameters = this.getQueryPrefixPostfix(options);
		const encodedQuery	 : string			= encodeURIComponent(Util.String.purifyWhitespaces(searchQuery));
		const searchValue	 : string 			= queryParameters.prefix + encodedQuery + queryParameters.postfix;

		//2 - add the search value to the link
		return searchLink.replace(this.SEARCH_PLACEHOLDER, searchValue);
	}

	private getQueryPrefixPostfix(options: Partial<TSearchLinkOptions>): IQueryParameters {

		//0 - get query options based on selected product & sorting
		const configForProduct: ISearchParameterConfig<string> | null = this.searchLinkConfig.products?.find((elem: ISearchParameterConfig<string>) => elem.code === options?.product) as ISearchParameterConfig<string> | null;
		const configForSorting: ISearchParameterConfig<string> | null = this.searchLinkConfig.sortings?.find((elem: ISearchParameterConfig<string>) => elem.code === options?.sorting) as ISearchParameterConfig<string> | null;

		//1 - were no query options defined?
		const isQueryDefinedForProduct: boolean = Util.Basic.isDefined(configForProduct) && Util.Basic.isDefined(configForProduct?.queryOptions);
		const isQueryDefinedForSorting: boolean = Util.Basic.isDefined(configForSorting) && Util.Basic.isDefined(configForSorting?.queryOptions);

		//2 - handle the different cases
		switch (true) {

			//a. were no query options defined?, return the dummy query parameter data (null object patter)
			case !isQueryDefinedForProduct && !isQueryDefinedForSorting:
				return { prefix: '', postfix: '' } as IQueryParameters;

			//b. query option can only be defined for one (not both product and sorting)
			case isQueryDefinedForProduct && isQueryDefinedForSorting:
				throw new Error(`AbstractSearchLink => getQueryParameters => FATAL ERROR: the search query can not be defined for bot product and sorting (please check the defined configuration for product of "${options.product}" and sorting of "${options.sorting}" and make sure only one of the both defines "queryOptions")`);

			//c. was the query option defined for the product?
			case isQueryDefinedForProduct:
				return configForProduct?.queryOptions!;

			//d. was the query option defined for the sorting?
			case isQueryDefinedForSorting:
				return configForSorting?.queryOptions!;

			default:
				throw new Error(`AbstractSearchLink => getQueryParameters => FATAL ERROR: no case was matching for getting the query parameter (please check the logic of the code)`);

		}
	}


	/**------------------------------------------------------
	 * Helper Functions
	 */
	private getConfigList<T extends string>(configCode: T, configList: ISearchParameterConfig<T>[]): ISearchParameterConfig<T> {

		//0 - was any config list defined
		if (Util.Array.isEmpty(configList)) throw new Error(`AbstractSearchLink => getConfigList => FATAL ERROR: there is no defined entries in the config list (therefor the configCode of "${configCode}" can not be found)`);

		//1 - find the requested one
		const configElem: ISearchParameterConfig<T> = configList.find((elem: ISearchParameterConfig<T>) => elem.code === configCode)!;
		if (!configElem) throw new Error(`AbstractSearchLink => getConfigList => FATAL ERROR: configCode of "${configCode}" is not configured in the configList of "${configList.map((elem: ISearchParameterConfig<T>) => elem.code).join(', ')}" (please check the configurations)`);

		//2 - return the config element
		return configElem;
	}
}
