import { ModuleWithProviders, NgModule } from '@angular/core';
import { DeviceDetectionService } from '@libs/libraries/frontend';
import { ObjectStorage } from '@libs/libraries/shared';

import { AddonDarkModeService } from './dark-model.service';
import { AddonDarkModeStorageHandler } from './dark-mode-storage.handler';
import { EnumAddonPageAddonInjection } from '../../shared/page-addons.interface';
import { IAddonDarkModeStorage, ADDON_INITIAL_DARK_MODE_STORAGE, IAddonDarkModeConfig } from './dark-mode.interface';


@NgModule()
export class AddonDarkModeModule {

	static register(config: IAddonDarkModeConfig): ModuleWithProviders<AddonDarkModeModule> {
		return {
			ngModule	: AddonDarkModeModule,
			providers	: [
				{
					provide		: EnumAddonPageAddonInjection.DarkModeConfig,
					useValue	: config as IAddonDarkModeConfig
				},
				{
					provide		: AddonDarkModeStorageHandler,
					useFactory	: () => {
						return new AddonDarkModeStorageHandler(
							ObjectStorage.create<IAddonDarkModeStorage>(
								'darkMode',
								ADDON_INITIAL_DARK_MODE_STORAGE,
								localStorage
							)
						);
					}
				},
				DeviceDetectionService,
				AddonDarkModeService
			]
		};
	}
}
