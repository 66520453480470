/**------------------------------------------------------
 * Seller Ranks constants
 */
export enum EnumRanksSorting {
	SellerRank		= 'seller_rank',
	Review			= 'review',
	Price			= 'price',
	Newest			= 'newest',
	Oldest			= 'oldest',
	Random			= 'random'
}
