/**------------------------------------------------------
 * Spreadshirt & Redbubble Design
 */
export interface IDesignDataPod {
	platform		: string;
	marketplace		: string;
	category		: string;
	id				: string;
	title			: string;
	description		: string;
	tags			: string[];
	img				: string;
	link			: string;
	date			: Date;
	index			: number;
}
